<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin ">
      <div class="col-xs-12 mb-5">
          <h1><strong>{{ t('admin_page.integration') | titlecase}} </strong><span class="">{{data.name}}</span></h1>
          <button type="button" class="close " (click)="close()"><span aria-hidden="true">
        <mat-icon class="d-text-middle">close</mat-icon>
      </span><span class="sr-only">Close</span></button>
      </div>
  </div>

  <div class="popup-body" appScroll>
      <!-- <mat-progress-bar *ngIf="!isProcess" mode="indeterminate"></mat-progress-bar> -->
      <div class="col-xs-12 mb-5">
          <h5 class="mt-15"> {{ t('settings.arcgis.arcgis_solution')}}</h5>
          <div class="subtext mb-5">{{ t('settings.arcgis.arcgis_solution_subtext')}}</div>
          <div class="set-border-connection p-10">
              <mat-button-toggle-group [(ngModel)]="archisToggleSelection" (ngModelChange)="toggle()" [disabled]="togglerDisabler">
                  <mat-button-toggle value="arcgis">{{ t('settings.arcgis.label_online')}}</mat-button-toggle>
                  <mat-button-toggle value="arcgis_enterprise">{{ t('settings.arcgis.label_enterprise')}}</mat-button-toggle>
              </mat-button-toggle-group>
          </div>
      </div>
      <div class="col-xs-12 mb-5" *ngIf="enterpriseField">
          <h5 class="mt-15"> {{ t('settings.arcgis.enterprise_credentials')}}</h5>
          <div class="subtext mb-5">{{ t('settings.arcgis.enterprise_credentials_subtext')}}</div>
          <div class="set-border-connection-remove-flex plr-10">
              <mat-form-field class="full-width set-border-connection1 mb-10 mt-15">
                  <input type="url" matInput class="" placeholder='URL' pattern="https?://.+" id="url" name="url" [(ngModel)]="globals.projectConfigs['services.integration.arcgis_enterprise.url']" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)"
                      (ngModelChange)="modelChangedUrl($event)" />
              </mat-form-field>
              <mat-form-field class="full-width set-border-connection1 mb-10">
                  <input type="clientid" matInput class="" placeholder='Client ID' name="clientid" [(ngModel)]="globals.projectConfigs['services.integration.arcgis_enterprise.client_id']" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" />
              </mat-form-field>
              <mat-form-field class="full-width set-border-connection1 mb-10">
                  <input type="clientsecret" matInput class="" placeholder='Client Secret' name="clientsecret" [ngModel]="globals.projectConfigs['services.integration.arcgis_enterprise.client_secret']" (keyup)="onSecretChange($event)" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)"
                  />
              </mat-form-field>
          </div>
      </div>
      <div class="col-xs-12 mb-5">
          <h5 class="mt-15"> {{ t('settings.connection') | titlecase}}</h5>
          <div class="set-border-connection">
              <span class="align-set" *ngIf="!arhgisenable && !errorMessage">{{ t('arcgis_status.arcgis_integration') | capitaliz}}</span>
              <div class="align-set display-flex" *ngIf="errorMessage">
                  <mat-icon class="d-text-middle">error</mat-icon>
                  <span class="error-message align-set"> {{ t('error.arcgis_integration') | capitaliz}}</span>
              </div>
              <div class="align-set display-flex" *ngIf="arhgisenable && !errorMessage">
                  <mat-icon class="successfully-message d-text-middle">check_circle</mat-icon>
                  <span class="align-set"> {{ t('success.arcgis_integration') | capitaliz}}</span>
              </div>
              <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate) || disableConnectionEnterprise" *ngIf="arhgisenable" class="btn btn-primary color-btn-primary-mapa align-set" type="button" (click)='arcgicRediect()'>
        <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
        {{ t('settings.disable') | titlecase}}
      </button>
              <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate) || disableConnectionEnterprise" *ngIf="!arhgisenable" class="btn btn-primary color-btn-primary-map align-set" type="button" (click)='arcgicRediect()'>
        <mat-icon class="d-text-middle icon-connect">add_circle</mat-icon>
        {{ t('settings.connect_me') }}
      </button>
          </div>
      </div>
          <div class="col-xs-12 mb-5">
              <h5 class="mt-15"> {{ t('settings.arcgis.arcgis_layers')}}</h5>
              <div class="subtext mb-5">{{ t('settings.arcgis.arcgis_layers_subtext')}}</div>
              <div class="set-border-connection">
                  <div class="col-xs-12 mb-5 mt-10" *ngIf="isProcessLayers">
                      <mat-progress-bar mode="indeterminate" class="mb-10"></mat-progress-bar>
                      <span>{{broadcastWaitMessage}}</span>
                  </div>
                  <div class="w-100" *ngIf="!isProcessLayers">
                      <div class="col-xs-12" *ngIf="!retryButton">
                          <app-generic-multiselect-checkbox [disabled]="connectedDisable || (level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)) || retryButton" [placeholder]="t('settings.arcgis.select_arcgis_layers')" name="featurelayers" [(ngModel)]="updatedLayers.featurelayers" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' feature layers ...'"
                              (ngModelChange)="updateLayers()">
                              <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                              </app-generic-multiselect-checkbox-option-all>
                              <app-generic-multiselect-checkbox-option *ngFor="let item of layerFeatures; trackBy: trackByFn" [value]="item">
                                  {{removeSpace(item.layer_name)}}
                              </app-generic-multiselect-checkbox-option>
                          </app-generic-multiselect-checkbox>
                      </div>
                      <div class="col-md-12 align-set error-message-style" *ngIf="retryButton">
                          <span class="align-set"><mat-icon class="d-text-middle mr-10 text-red">cancel</mat-icon>{{'settings.arcgis.broadcasting_error' | transloco}}</span>
                          <button class="btn btn-primary color-btn-primary-mapa align-set pull-right" (click)="onRetry()">
                              <mat-icon class="d-text-middle">replay</mat-icon><span class="ml-10">{{'settings.arcgis.retry' | transloco}}</span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-xs-12 mb-5">
              <h5 class="mt-15"> {{ t('settings.arcgis.dialog_items')}}</h5>
              <div class="subtext mb-5">{{ t('settings.arcgis.dialog_items_subtext')}}</div>
              <div class="set-border-connection">
                  <div class="col-xs-12" *ngIf="isProcess">
                      <mat-progress-bar mode="indeterminate" class="mtb-20"></mat-progress-bar>
                  </div>
                  <div class="col-xs-12" *ngIf="!isProcess">
                      <app-generic-multiselect-checkbox [disabled]="connectedDisable || (level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)) || isProcessLayers" [placeholder]="t('settings.arcgis.select_dialog_layers')" name="featurelayers" [(ngModel)]="updatedLayers.layers" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' feature layers ...'"
                          (ngModelChange)="updateLayers()">
                          <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                          </app-generic-multiselect-checkbox-option-all>
                          <app-generic-multiselect-checkbox-option *ngFor="let item of layerEntity; trackBy: trackByFn" [value]="item">
                              {{ item.name+'.labels' | transloco | capitaliz}}
                          </app-generic-multiselect-checkbox-option>
                      </app-generic-multiselect-checkbox>
                  </div>
              </div>
          </div>
      </div>
</div>
