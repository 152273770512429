import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { ProjectConfigsService } from '../../../../akita/project-configs/state/project-configs.service';
import { showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-integrations-modal-pim',
  templateUrl: './integrations-modal-pim.component.html',
  styleUrls: ['./integrations-modal-pim.component.sass']
})
export class IntegrationsModalPimComponent extends IntegrationsModalComponent implements OnInit {
  private serviceGroup: string = 'pim';
  public errorMessage:boolean=false;
  public pimSelection:any=[];
  public level:any='';
  public isPIMSecret: boolean = false;
  private secretDebouncer: Subject<any> = new Subject();
  public connectionSelect=[
    {
      id:0,
      value:'Stakeholder',
      enableKey:'services.integration.pim.stakeholders.enabled'
    },
    {
      id:1,
      value:'Issues',
      enableKey:'services.integration.pim.issues.enabled'
    }
  ];
  @ViewChild('form_integration', { static: false }) form_integration: FormGroup;
  public pimenable:boolean=false;
  constructor(private _route: Router,@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, public globals: Globals, public _projectConfigsService: ProjectConfigsService) {
    super(globals, _projectConfigsService);
  }

  public ngOnInit(): void {
    if(this._route.url.includes('beheer')){
      this.level='company'
    }
    this.secretDebouncer.pipe(
      debounceTime(300)
    ).subscribe((value) => {
      if (value) {
        this.setPIMSecret(value);
      } else {
        this.setPIMSecret('');
      }
    });
    this.pimenable=this.globals.projectConfigs['services.integration.pim.enabled'];
    this.errorMessage=this.globals.projectConfigs['services.integration.pim.connection_error'];
    this.valueShow();
    this.getConfig(this.serviceGroup);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public submit(): void {
    if (this.form_integration.invalid) {
      return;
    }
    this.setConfig(this.serviceGroup, () => {
      this.updateConfig();
      showFeedbackSaved();
    });
  }
  protected updateConfig() {
    let getKey= this.globals.projectConfigs['services.integration.pim.enabled'];
    getKey=!getKey;
    this.globals.projectConfigs['services.integration.pim.enabled'] = getKey;
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        if(this.globals.projectConfigs['services.integration.pim.enabled']){
          this._projectConfigsService.integrationSync(this.serviceGroup).subscribe();
        }
        this.ngOnInit();
      });
  }

  public onSecretChange(event: any): void {
    this.secretDebouncer.next(event.target?.value);
  }

  public setPIMSecret(secret: string): void {
    // remove previous secret
    if (!this.isPIMSecret && this.globals.projectConfigs['services.integration.pim.client_secret'] ) {
      this.isPIMSecret = true;
      this.globals.projectConfigs['services.integration.pim.client_secret'] = '';
      this.setConfig(this.serviceGroup);
    } else {
      // update new secret
      this.globals.projectConfigs['services.integration.pim.client_secret'] = secret;
      this.setConfig(this.serviceGroup);
    }
  }
  public valueShow(){
    if(this.globals.projectConfigs['services.integration.pim.stakeholders.enabled'] || this.globals.projectConfigs['services.integration.pim.issues.enabled']){
      if(this.globals.projectConfigs['services.integration.pim.stakeholders.enabled']){
        this.pimSelection.push(this.connectionSelect[0]);
      }
      if(this.globals.projectConfigs['services.integration.pim.issues.enabled']){
        this.pimSelection.push(this.connectionSelect[1]);
      }
    }
  }
  public update() {
    const self=this;
    const selection=[];
       this.connectionSelect.forEach((element)=>{
         if(self.pimSelection.length==0){
          this.globals.projectConfigs[element.enableKey]=false;
         }
         if(self.pimSelection.length > 0){
          self.pimSelection.forEach(element => {
            selection.push(element.value);
          });
         if(selection.includes(element.value)){
          this.globals.projectConfigs[element.enableKey]=true;
         }else{
          this.globals.projectConfigs[element.enableKey]=false;
         }
        }
       });
       this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
        () => {
          this.ngOnInit();
          showFeedbackSaved();
        });
  }
}
