<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
      <app-breadcrumb></app-breadcrumb>
      <button
        type="button"
        class="close no-margin"
        (click)="close()"
        matTooltip="{{ t('close') | capitaliz }}"
      >
        <span aria-hidden="true">
          <mat-icon class="d-text-middle ui uil-multiply"></mat-icon>
        </span>
        <span class="sr-only">{{ t("close") }}</span>
      </button>
      <div *hasPermission="[Permission.PermitsDelete]">
        <mat-icon
          *ngIf="permit"
          (click)="deletePermit(permit)"
          class="d-cursor-hand uil uil-trash delete pull-right permits-delete"
          matTooltip="{{ t('delete') | capitaliz }}"
        ></mat-icon>
      </div>
      <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
        <button  *ngIf="permit" type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
           <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
        </button>
    </div>
    </div>
    <div class="col-xs-12 popup-header-title">
      <h1>
        <strong>{{ t("permits.label") | capitaliz }} </strong>
      </h1>
      <h1 [innerHTML]="name | safeHtml" class="html-content"></h1>
    </div>
  </div>
  <mat-progress-bar
    *ngIf="!permit && !reducer"
    mode="indeterminate"
  ></mat-progress-bar>
  <div class="popup-body" appScroll>
    <app-workflow-generic *ngIf="permit && permit.workflows && permit.workflows.length && hasModule('workflow')" [margin]="false" parentType="permits" [deadline]="permit.deadline" [workflows]="permit.workflows" [permitId]="permit.id"></app-workflow-generic>
    <!-- left col -->
    <div class="col-md-7 col-xs-12">
      <h3>{{ "decisions.connections" | transloco | capitaliz }}</h3>
      <div
        class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component"
      >
        <div>
          <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
          <app-add-connect-generic
            parentType="permits"
            *ngIf="loaded"
            (onChange)="updateEntities($event)"
            [parent]="permit"
            [data]="entities"
            [types]="['issues', 'requirements', 'contactmoments','tasks','activities','documents','layers']"
          >
          </app-add-connect-generic>
        </div>
      </div>

      <!-- Contact -->
      <div class="col-xs-12 pd-0">
        <h3>{{ "permits.authority" | transloco }}</h3>
      </div>
      <div class="col-xs-12 panel z-depth-2 panel-border">
        <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
        <div *ngIf="loaded">
          <div class="col-xs-12">
          <app-generic-multiselect-checkbox
            type="stakeholders"
            [ngClass]="{ disable_enitiy: reducer.stakeholder }"
            #stakeholderMultiselect
            [placeholder]="'permits.authority' | transloco"
            name="stakeholders"
            [(ngModel)]="permit.stakeholders"
            [multiple]="false"
            (ngModelChange)="stakeholderSet()"
            [maxChipMessage]="
              '+ {count} ' +
              ('reusable.more' | transloco) +
              ' ' +
              ('stakeholders.labels' | transloco) +
              ' ...'
            "
            [matTooltip]="
              reducer.stakeholder ? t('settings.relatic.disable_field') : null
            "
          >
            <app-generic-multiselect-checkbox-option-all>{{
              "filter_select_all" | transloco | capitaliz
            }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option
              *ngFor="let stakeholder of stakeholders"
              [value]="stakeholder"
            >
              {{ stakeholder.name }}
            </app-generic-multiselect-checkbox-option>
            <app-generic-multiselect-checkbox-option-new-action
              type="stakeholder"
            ></app-generic-multiselect-checkbox-option-new-action>
            <app-generic-multiselect-search-project
              entity="stakeholders"
            ></app-generic-multiselect-search-project>
          </app-generic-multiselect-checkbox>
        </div>
        <mat-form-field class="col-xs-12 full-width" [matTooltip]="reducer.name ? t('settings.relatic.disable_field') : null">
          <input matInput type="text" id="authority_id" name="authority_id" [placeholder]="'permits.authority_id' | transloco" (change)="updatePermit()" [(ngModel)]="permit.id_authority">
        </mat-form-field>
        </div>

      </div>
    </div>

    <!-- right col -->
    <div class="col-md-5 col-xs-12">
      <!-- Afhandeling-->

      <h3 class="text-capitalize">
        {{ "Status" }}
      </h3>
      <div class="col-xs-12 panel z-depth-2 panel-border map-div">
        <mat-progress-bar *ngIf="!loaded" mode="indeterminate"></mat-progress-bar>
        <div *ngIf="loaded">
          <div class="px-1 d-flex" style="align-items: center;">
            <div >{{ 'status' | transloco | capitaliz }}</div>

            <div *ngIf="permit.status" class="decision-status" style="right: 0; position: absolute; margin-right: 20px;">
                <span [class]="getStatusClass(permit.status)" (click)="showStatusField = !showStatusField" *transloco="let t; read: 'permits'">
                    {{ ('permits.status.'+permit.status) | transloco}}
                        <i *ngIf="!showStatusField" class="d-text-middle uil uil-arrow-down unicon-icon mat-icon"></i>
                        <i *ngIf="showStatusField" class="d-text-middle uil-arrow-up unicon-icon mat-icon"></i>
                </span>
            </div>
        </div>

        <mat-form-field *ngIf="showStatusField" class="col-xs-12" id="status" [matTooltip]="reducer.status ? a('settings.relatic.disable_field') : null">
            <mat-select [placeholder]="t('status') | capitaliz" [(value)]="permit.status" (valueChange)="updatePermit()" [disabled]="!hasPermission(Permission.DecisionsStatusUpdate) || reducer.status">
                <mat-option value="open">{{ 'permits.status.open' | transloco}}</mat-option>
                <mat-option value="in_progress">{{'permits.status.in_progress' | transloco}}</mat-option>
                <mat-option value="rejected">{{'permits.status.rejected' | transloco}}</mat-option>
                <mat-option value="approved">{{ 'permits.status.approved' | transloco}}</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
      </div>

      <h3>{{ 'text.general' | transloco | capitaliz }}</h3>
      <mat-progress-bar *ngIf="!reducer" mode="indeterminate"></mat-progress-bar>
      <div class="col-xs-12 panel z-depth-2 panel-border">
        <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
          <div *ngIf="loaded">
              <mat-form-field class="col-xs-12 full-width" [matTooltip]="reducer.name ? t('settings.relatic.disable_field') : null">
                  <input matInput type="text" id="name" name="name" [placeholder]="'reusable.name' | transloco" (change)="updatePermit()" [(ngModel)]="permit.name" required>
              </mat-form-field>
              <mat-form-field class="col-xs-12 full-width" [matTooltip]="reducer.description ? t('settings.relatic.disable_field') : null">
                <textarea matInput name="description" id="description" [placeholder]="'reusable.description' | transloco" (change)="updatePermit()" cols="30" rows="2" [ngModelOptions]="{standalone: true}" [(ngModel)]="permit.description"></textarea>
              </mat-form-field>

            <mat-form-field class="col-xs-6">
              <input matInput [matDatepicker]="date" [placeholder]="'permits.permit_deadline' | transloco" (ngModelChange)="updatePermit()"
                  name="date" [(ngModel)]="permit.deadline" (focus)="date.open()" required>
              <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
              <mat-datepicker #date></mat-datepicker>
            </mat-form-field>

              <div class="col-xs-12">
                <app-add-connect-themes [mulitple]="true" [editButton]="false" [editable]="false" [type]="themeType" [textMultiple]="'type'" [textSingle]="'type'" [selectedTheme]="permit.permitstypes" (onThemeSelected)="updateSelectedTypes($event)" [uniqueId]="'add-themes'"></app-add-connect-themes>
              </div>
              <div class="col-xs-12">
                <app-add-connect-themes [mulitple]="true" [editButton]="false" [editable]="false" [type]="requesterType" [textMultiple]="'permits.requesters' | transloco" [textSingle]="'permits.requesters' | transloco" [selectedTheme]="permit.permitsrequesters" (onThemeSelected)="updateSelectedRequester($event)"></app-add-connect-themes>
              </div>
              <div class="col-xs-12">
                <app-add-connect-themes [mulitple]="true" [editButton]="false" [editable]="false" [type]="clusterType" [textMultiple]="'permits.cluster' | transloco" [textSingle]="'permits.cluster' | transloco" [selectedTheme]="permit.permitsclusters" (onThemeSelected)="updateSelectedCluseter($event)"></app-add-connect-themes>
              </div>
              <div class="col-xs-12">
                <app-add-connect-themes [mulitple]="true" [editButton]="false" [editable]="false" [type]="validityType" [textMultiple]="'permits.validity' | transloco" [textSingle]="'permits.validity' | transloco" [selectedTheme]="permit.permitsvalidities" (onThemeSelected)="updateSelectedValidity($event)"></app-add-connect-themes>
              </div>
              <mat-form-field class="col-xs-12 full-width" [matTooltip]="reducer.description ? t('settings.relatic.disable_field') : null">
                <textarea matInput name="legal_basis" id="legal_basis" [placeholder]="'permits.legal_basis' | transloco" (change)="updatePermit()" cols="30" rows="2" [ngModelOptions]="{standalone: true}" [(ngModel)]="permit.legal_basis"></textarea>
              </mat-form-field>

              <div class="col-xs-12">
                <app-add-connect-themes [mulitple]="true" [editButton]="false" [editable]="false" [type]="permitFilinyType" [textMultiple]="'permits.requirements' | transloco" [textSingle]="'permits.requirements' | transloco" [selectedTheme]="permit.permitsfillingrequirements" (onThemeSelected)="updateSelectedFilingReq($event)"></app-add-connect-themes>
              </div>

              <div [ngClass]="{'disable_enitiy': (!hasPermission(Permission.DecisionsUpdate))}">
                <app-generic-multiselect-checkbox [chipDisabler]="chipDisablerUser" [disabled]="!hasPermission(Permission.DecisionsUpdate)" [parent]="permit" parentType="permits" class="col-xs-12" [placeholder]="'reusable.select_user_change' | transloco" name="user"
                    [(ngModel)]="permit.users" [multiple]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('reusable.accounthouders' | transloco)+' ...'" (ngModelChange)="updatePermit()" [uniqueId]="'responsible-users'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                    </app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let user of users" [value]="user">
                        {{ user.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
              </div>

              <div [ngClass]="{'disable_enitiy': (!hasPermission(Permission.DecisionsUpdate))}">
                <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [parent]="permit" parentType="permits" class="subprojects col-xs-12" type="subprojects" [maxChipItems]="3" *ngIf="hasModule('subprojects')" (ngModelChange)="updateSubprojects()" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco"
                    name="subprojects" [(ngModel)]="permit.subprojects" [multiple]="true" [maxChipLength]="20" [disabled]="!hasPermission(Permission.DecisionsUpdate) || globals.subprojects.length === 0" [uniqueId]="'sub-projects'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let subproject of subprojectsSet" [value]="subproject">
                        {{ subproject.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
            </div>

          </div>
      </div>
      <div class="row">
        <div *ngIf="loaded">
          <app-comment-box [users]="users" type="permits" [type_id]="permit.id"></app-comment-box>
        </div>
    </div>
    </div>
  </div>
</div>
