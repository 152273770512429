import {Injectable} from '@angular/core';
import {ComplaintsStore} from './complaints.store';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from '../../../core/service/common/abstract.service';
import {Complaint} from './complaint.model';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PaginationParams} from '../../../core/service/common/pagination-params';
import {PaginatedResponse} from '../../../core/service/common/paginated-response';
import {ComplaintsQuery} from './complaints.query';
import {deepCopy} from '../../../core/util/deepCopy';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {ID} from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class ComplaintsService extends AbstractService {

  constructor(private complaintsStore: ComplaintsStore, private complaintQuery: ComplaintsQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams): Observable<Complaint[]> {
    return this.http.get(`/api/complaints`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Complaint>) => this.complaintsStore.set(response.data)),
        map((response: PaginatedResponse<Complaint>) => response.data)
      );
  }

  @ServiceErrorHandler()
  public getSummary(id): Observable<any> {
    return this.http.get(`/api/map/summary/complaints/${id}`)
      .pipe(
        map((response:Complaint) => response)
      );
  }

  @ServiceErrorHandler()
  public find(id: ID, withs: string[] = []): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }

    const request = this.http.get(`/api/complaints/${id}`, {
      headers: this.headers,
      params: params
    })
      .pipe(
        tap ((complaint: Complaint) => {
          if (this.complaintsStore.getValue().entities && Object.entries(this.complaintsStore.getValue().entities).length > 0) {
            this.complaintsStore.update(complaint);
          } else {
            this.complaintsStore.set([deepCopy(complaint)]);
          }

          this.complaintsStore.setActive(complaint.id);
        })
    );

    return request;
  }

  @ServiceErrorHandler()
  public update(complaint: Complaint): Observable<Complaint> {
    this.complaintsStore.update(complaint.id, complaint);
    return this.http.put(`/api/complaints/${complaint.id}`, complaint, this.httpOptions)
      .pipe(map((response: Complaint) => response) );
  }

  @ServiceErrorHandler()
  public create(complaint: Complaint): Observable<Complaint> {
    return this.http.put('/api/complaints', complaint, this.httpOptions)
      .pipe(tap((response: Complaint) => {
        this.complaintsStore.add(complaint);
      }));
  }

  @ServiceErrorHandler()
  public delete(complaint: Complaint): Observable<any> {
    return this.http.delete(`/api/complaints/${complaint.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.complaintsStore.remove(complaint.id);
      }));
  }
}
