import { PasswordLogoutComponent } from './../../../shared/components/modal/personal-setting-edit/components/password-logout/password-logout.component';
import { ModalEnum, modalConfig } from "src/app/core/util/modalConfig";
import { PersonalSettingEditComponent } from "src/app/shared/components/modal/personal-setting-edit/personal-setting-edit.component";

//personal setting tab enabal modal
export function openPersonalSettingModal(dialog, data: any = null): void {
  dialog.open(PersonalSettingEditComponent, modalConfig({
    data: data,
    disableClose: true,
    closeOnNavigation: true,
    panelClass: 'customized-dialog-container',
  }, ModalEnum.ModalDefault));
}

//personal setting tab enabal modal
export function openPasswordLogoutModal(dialog, data: any = null): void {
  dialog.open(PasswordLogoutComponent, modalConfig({
    data: data,
    disableClose: true,
    closeOnNavigation: true,
    panelClass: 'customized-dialog-container',
  }, ModalEnum.ModalSmall));
}
