import { collect } from 'collect.js';

export type ArcGis = {
  mainLayers: any[],
  subLayers: any[],
  features: any[],
}

export function integrationError(globals,integration): boolean{
    if(globals.user && globals.user.configs){
        if(globals.user.configs.config){
          if(globals.user.configs.config.connection_error){
                if(globals.user.configs.config.connection_error[integration]){
                    return true;
                }
            }
        }
    }
    return false;
}
export function integrationValidation(global,integration,user_error,is_error): string{
    if(global.projectConfigs[`services.integration.${integration}.enabled`] && global.projectConfigs[`services.integration.${integration}.connection_error`]==false){
      if(global.projectConfigs[`services.integration.${integration}.user`]==global.user.global_id){
          return 'project';
      }
      if(global.user.configs[user_error]==1 && !is_error){
          return 'personal';
      }
    }
}

export function integrationConnection(globals,integration, entity) {
    const tenant_codes = [];
    // store the tenant_codes from subprojects to verify the complaint/decision is still connected with bouwapp
    if (globals.subprojects && globals.subprojects.length) {
        globals.subprojects.forEach((subproject: any) => {
            if (subproject.tenant_code) {
                tenant_codes.push(subproject.tenant_code);
            }
        })
    }
    if(globals.projectConfigs[`services.integration.${integration}.project.code`] === entity.project_code || tenant_codes.includes(entity.project_code) || integration === 'freshdesk'){
        return true
    }
    return false
}

export function integrationValidationArcGis(global,integration,integration_enterprise,user_error,user_error_enterprise,is_error,is_error_enterprise): string{
    if(global.projectConfigs && (global.projectConfigs[`services.integration.${integration}.enabled`] || global.projectConfigs[`services.integration.${integration_enterprise}.enabled`]) && (global.projectConfigs[`services.integration.${integration}.connection_error`]==false || global.projectConfigs[`services.integration.${integration_enterprise}.connection_error`]==false)){
      if((global.projectConfigs[`services.integration.${integration}.user`]==global.user.global_id || global.projectConfigs[`services.integration.${integration_enterprise}.user`]==global.user.global_id)){
          return 'project';
      }
      if((global.user.configs[user_error]==1 || global.user.configs[user_error_enterprise]==1) && (!is_error || !is_error_enterprise)){
          return 'personal';
      }
    }
}

export function globalUserNotificationSetup(globals,type){
    if(globals.user.configs.config){
        if(globals.user.configs.config.notifications){
            if(globals.user.configs.config.notifications[type]){
                return globals.user.configs.config.notifications[type];
            }
        }
    }
    return false;
}
export function freshdeskPersonalIntegration(globals,integration): string{
    if(globals.user.configs){
        if(globals.user.configs.config){
          if(globals.user.configs.config.services){
            if(globals.user.configs.config.services.integration){
             if(globals.user.configs.config.services.integration[integration]){
               if(globals.user.configs.config.services.integration[integration].apikey){
                return globals.user.configs.config.services.integration[integration].apikey
                }
             }
           }
         }
       }
    }
    return '';
}

export function userConfigsSetting(globals,data,type): any{
    if(globals.user.configs){
        if(globals.user.configs.config){
            if(type=='notification'){
                globals.user.configs.config.notifications=data
            }else{
                globals.user.configs.config.services={integration:{freshdesk:{apikey:data}}};
            }
        }else{
            if(type=='notification'){
                globals.user.configs={config:{notifications:data}};
            }else{
                globals.user.configs={config:{services:{integration:{freshdesk:{apikey:data}}}}};
            }
        }
    }
    return globals.user.configs;
}
// in app integration chat setup
export function integrationChatSetupWithComplaints(complaint): any{
    if((complaint.entry_type=='chat' || complaint.entry_type=='smart_reporting') && complaint.source=='api'){
        return {type:'bouwapp', error_type:'bouwapp_enabled', chatShow: true, chatClosed: Number(complaint.is_closed), chatIcon:'assets/png/integrations/bouwappchat.png'}
    }else if(complaint.source=='freshdesk'){
        return {type:'freshdesk', error_type:'freshdesk_enabled', chatShow: true, chatClosed: Number(complaint.is_closed), chatIcon:'assets/png/integrations/freshdeskoverview.png'}
    } else if(complaint.source=='whatsapp') {
      return {type:'whatsapp', error_type:'whatsapp_enabled', chatShow: true, chatClosed: Number(complaint.is_closed), chatIcon:'assets/png/integrations/whatsapp-small.png'}
    }
    return false;

}
//in app chat complaint parem set
export function inAppChatComplaintParam(complaint): any{
    return {ticket_id: complaint.ticket_id, type: complaint.entry_type=='chat' ? 'ticket' : complaint.entry_type}
}
//dashboard integration error handler
export function dashboardIntegrationBothLevelErrorHandler(globals,integration,type): any{
    if(globals.projectConfigs[integration[type].enabledKey]){
        if(globals.projectConfigs[integration[type].connection_error]==true){
          if(globals.projectConfigs[integration[type].userId]==globals.user.global_id){
              return {project:true,personal:false,user:false};
          }
            return {project:false,personal:false,user:true};

        }
            if(globals.user.configs[`${type}_enabled`]==1){
                if(globals.user.configs){
                  if(globals.user.configs.config){
                    if((globals.user.configs.config.connection_error) && globals.user.configs.config.connection_error[type]==true){
                        return {project:false,personal:true,user:false};
                    }
                    }
                }
            }

    }else{
        if(globals.projectConfigs[integration[type].connection_error]==true){
            if(globals.projectConfigs[integration[type].userId]==globals.user.global_id || globals.projectConfigs[integration[type].userId]==0){
                return {project:true,personal:false,user:false};
            }
        }
    }
    return {project:false,personal:false,user:false};
}
export function dashboardIntegrationSingleLevelErrorHandler(globals,integration,type): any{
    if(globals.projectConfigs[integration[type].enabledKey]){
        if(globals.projectConfigs[integration[type].connection_error]){
            return true;
        }
    }
    return false;
}
//freashdesk integration document set
export function freshdeskIntegrationDocumentAttached(ticket): any{
    const documents=[];
    if(ticket.attachments.length){
        ticket.attachments.forEach(attachment => {
            documents.push({id:attachment.id,path:attachment.attachment_url,name:attachment.name,type:'freshdesk'})
        });
    }
    if(ticket.conversations.length){
        ticket.conversations.forEach(conversation => {
            if(conversation.attachments.length){
                conversation.attachments.forEach(attachment => {
                    documents.push({id:attachment.id,path:attachment.attachment_url,name:attachment.name,type:'freshdesk'})
                });
            }
        });
    }
    return documents;
}
//attachments files array split
export function attachmentSplice(attachments,value): any{
    const files=[];
    attachments.forEach(element => {
        if(element.name!==value){
          files.push(element)
        }
      });

    return files;
}
//freshdesk integration replay map
export function freshdeskIntegrationReplayDataMap(message){
    const formData= new FormData();
    formData.append('body',message.message);
    formData.append('private',"true");
    formData.append('incoming',"true");
    if(message.attachment){
        message.attachment.forEach(element => {
            formData.append('files[]',element);
        });
    }
    return formData;
}
//arcgis type define
export function arcgisTypeDefine(globals){
    if(globals.projectConfigs && globals.projectConfigs["services.integration.arcgis_enterprise.enabled"]){
        return 'arcgis_enterprise';
    }
    return 'arcgis'
}
//set default integration notification toggle enable
export function integrationsNotificationToggle(globals,level){
    const notifications={
        daily_mail:false,
        weekly_mail:false,
        item_assigned:false,
        item_status_changed:false,
        comment_tagged:false,
        comment_posted:false,
        bouwapp_integration:false,
        freshdesk_integration:false
      }
    if(globals.user.configs && globals.user.configs.config){
        if(globals.user.configs.config.notifications){
            if(!globals.user.configs.config.notifications[level]){
                notifications[level]=true;
            }
        }else{
            notifications[level]=true;
        }
    }else{
        notifications[level]=true;
    }
    return notifications;
}
//integration arcgis service defination logic added
export function getArcGiUniqueResponce(layers){
  return collect(layers).unique(item => item.name + item.layer_name).all();
}
//arcgis layers into sublayers pattrens
export function arcGisResponceIntoSublayers(layers): any{
  let uniqueLayerName;
  // eslint-disable-next-line prefer-const
  uniqueLayerName=collect(layers).unique('name').all();
  return uniqueLayerName;
}
//arcgis layer subpayer responce to orignal responce
export function sublayerToLayers(latest,old): any{
  const responce=[];
  latest.forEach(layer => {
    old.forEach(el => {
      if(layer.name==el.name){
        responce.push({
          id: el.id,
          name: el.name,
          type: el.type,
          url: el.url,
          layer_name: el.layer_name,
          layer_id: el.layer_id,
          geometry_type: el.geometry_type,
          enabled: el.enabled,
          created_at: el.created_at,
          updated_at: el.updated_at,
          metadata: el.metadata,
          sort_id: el.sort_id
        });
      }
    });
  });
  return responce;
}
