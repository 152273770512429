import { Location } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { TranslocoService } from '@jsverse/transloco';
import { saveAs } from 'file-saver';
import { finalize } from "rxjs/operators";
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { LogNewComponent } from 'src/app/shared/components/modal/contact-moments-new/log-new.component';
import { FormatdatePipe } from 'src/app/shared/pipes/formatdate.pipe';
import { LogListingType } from "../../../../core/enum/log-listing-type";
import { Permission } from "../../../../core/enum/permission";
import { LogsService } from '../../../../core/service/api/logs.service';
import { ReportResponse, ReportService } from "../../../../core/service/api/report.service";
import { PaginatedResponse } from "../../../../core/service/common/paginated-response";
import { PaginationParams } from "../../../../core/service/common/pagination-params";
import { GenericComponent } from '../../../../core/util/abstract/generic-component';
import { dateIso } from "../../../../core/util/dateIso";
import { ModalEnum, modalConfig } from "../../../../core/util/modalConfig";
import { Globals } from '../../../../globals';
import { DeletionModalComponent } from "../../../../shared/components/modal/deletion-modal/deletion-modal.component";
import { LogEditComponent, LogEditData } from "../../modal/contact-moments-edit/log-edit.component";



@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.sass']
})
export class LogsComponent extends GenericComponent implements OnInit {

  private project: any;
  public logs = {
    total: 0,
    data: []
  };

  public selectedPopoverConnection: any[] = [];
  public selectedPopoverType: string = 'documents';

  protected editIconCls = '';
  public logsLoaded = false;

  @Input() logListingType: LogListingType;
  @Input() entityIdOrCode: any;
  @Input() connectStakeholders = true;
  @Input() stakeholderMandatory = true;
  @Input() connectDecisions = true;
  @Input() pagesize = 10;
  @Input() showRelations: boolean = true;
  @Output() logsLoad: EventEmitter<any> = new EventEmitter();
  @Output() logAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() logRemoved: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('container') container: any;
  private currentPage: number = 1;

  public showLogs = true;


  constructor(private _logsService: LogsService, public globals: Globals,
    private dialog: MatDialog, private _reportsService: ReportService,
    private _subProjectsQuery: SubprojectQuery,
    private pubsub: PubsubService,
    private translocoService: TranslocoService,public location: Location) {
    super(globals);
    this.project = this.globals.project;
  }

  ngOnInit() {
    this.editIconCls = '';
    if (this.logListingType === LogListingType.StakeholderAll || this.logListingType === LogListingType.StakeholderDetail) {
      if (!this.hasPermission(Permission.LogsUpdate)) {
        this.editIconCls = 'md-inactive';
      }
    } else if (this.logListingType === LogListingType.ResidentDetail) {
      if (!this.hasPermission(Permission.LogsUpdate)) {
        this.editIconCls = 'md-inactive';
      }
    }

    this.loadLogs();
  }

  public deleteLog(log: any, event: MouseEvent): void {
    event.stopPropagation();

    if (!this.hasPermission(Permission.LogsDelete)) {
      return;
    }

    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: this.translocoService.translate('text.delete.log')
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe( (remove: boolean) => {
      if (remove) {
        this._logsService.removeLog(log.id).subscribe(
          () => {
            this.logs.data = this.logs.data.filter(l => l.id !== log.id);
            this.logRemoved.emit();
          });
      }
    });
  }

  public loadLogs(count = this.pagesize, page = this.currentPage, entityOrCode = null) {
    if (entityOrCode) {
      this.entityIdOrCode = entityOrCode;
    }

     const paginationParams = new PaginationParams(page, count)
       .with(['stakeholders', 'tasks', 'issues', 'complaints', 'contactpersons', 'documents', 'series', 'requirements']);
    let params = new HttpParams();

    switch (this.logListingType) {
      case LogListingType.ResidentDetail:
        params = params.append('has', 'residents')
          .append('whereHas[0]', `residents,id,${this.entityIdOrCode}`);
        break;

      case LogListingType.ComplaintDetail:
        params = params.append('has', 'complaints')
          .append('whereHas[0]', `complaints,id,${this.entityIdOrCode}`);
        break;

      case LogListingType.IssueDetail:
        params = params.append('has', 'issues')
          .append('whereHas[0]', `issues,id,${this.entityIdOrCode}`);
        break;

      case LogListingType.StakeholderDetail:
        params = params.append('has', 'stakeholders')
          .append('whereHas[0]', `stakeholders,id,${this.entityIdOrCode}`);
        break;

      case LogListingType.EmployeeDetail:
        params = params.append('has', 'employees')
          .append('whereHas[0]', `employees,id,${this.entityIdOrCode}`);
        break;

      default:

        break;
    }

    this._logsService.getLogs(paginationParams, params)
      .pipe(finalize(() => this.logsLoaded = true))
      .subscribe(
      (response: PaginatedResponse) => {
        this.logs.data = this.logs.data.concat(response.data);
        this.logs.total = response.total;
        this.logs.data = this.logs.data.map(log => this.toLocalDate(log));
        this.globals.cdRef.detectChanges();
        this.logsLoad.emit(this.logs.data);

        // Keep the size of the container so that you don't loose the scroll
        this.container.nativeElement.style.minHeight = `${this.container.nativeElement.offsetHeight}px`;
      });
  }

  // Click action
  public showConnectionInfo(type: string = 'documents', log: any, event: MouseEvent): void {
    event.stopPropagation();
    this.selectedPopoverType = type;

    switch (type) {
      case 'actions':
        this.selectedPopoverConnection = log.actions;
        break;
      case 'issues':
        this.selectedPopoverConnection = log.issues;
        break;
      case 'complaints':
        this.selectedPopoverConnection = log.complaints;
        break;
      case 'activities':
        this.selectedPopoverConnection = log.activities;
        break;
      case 'decisions':
        this.selectedPopoverConnection = log.decisions;
        break;
      case 'documents':
        this.selectedPopoverConnection = log.documents;
        break;
    }
  }

  private toLocalDate(log: any) {
    if (log.contact_moment_timestamp) {
      log.contact_moment_timestamp = dateIso(log.contact_moment_timestamp, true, this.globals.timezone);
    }

    return log;
  }

  public editLog(log?: any, event?: MouseEvent, extraParams?: any, extraOptions?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  }): void {
    if (event) {
      event.stopPropagation();
    }

    const isNewLog: boolean = !log;

    // Disable displaying the mat-expansion-panel because it's heavy and slows down the application when combined with edit log modal
    // this.showLogs = false;
    const data: LogEditData = {
      log: log,
      connectStakeholders: this.connectStakeholders,
      stakehoderMandatory: this.stakeholderMandatory,
      params: extraParams,
      options: extraOptions,
    };
    const activeSubProject = this._subProjectsQuery.getActive();
    let component: any = LogEditComponent;
    let size = ModalEnum.SidebarLargeResponsive;
    let panelClass = ['animate__animated', 'animate__slideInRight'];
    if (isNewLog) {
      component = LogNewComponent;
      size = ModalEnum.ModalDefault;
      panelClass = []
    }
    const editDialog = this.dialog.open(component, modalConfig({
      data: data,
      panelClass:panelClass,
      disableClose:true,
    }, size));
    if (log) {
      if (activeSubProject) {
        this.location.go(`/${this.globals.project.code}/sub/${activeSubProject.code}/contactmomenten/${log.id}`);
      } else {
        this.location.go(`/${this.globals.project.code}/stakeholders/contactmomenten/${log.id}`);
      }
      const formatDatePipe = new FormatdatePipe(this.globals.locale);
      const name = `${formatDatePipe.transform(log.contact_moment_timestamp, 'dd-MM-yyy') }:${log.title ? log.title : '(Contactmoment zonder titel)'}`
      this.pubsub.updateHistory(editDialog,LogEditComponent,data,name)

    }
    if (editDialog.componentInstance["onUpdated"]) {
      editDialog.componentInstance["onUpdated"].subscribe((log) => {
        const i = this.logs.data.findIndex(el => el.id === log.id)
        this.logs.data[i].title = log.title;
        this.logs.data[i].description = log.description;
        this.logs.data[i].contact_moment_timestamp = log.contact_moment_timestamp;
      })
    }

    editDialog.afterClosed().subscribe( (dialogData: {
      log: any,
      updated: boolean,
      deleted: boolean,
    }) => {
      // Display the mat-expansion-panel elements again that was previously hidden
      // this.showLogs = true;
      if (!isNewLog) {
        if (dialogData && dialogData.deleted)
          this.logs.data = this.logs.data.filter(l=>l.id!=dialogData.log.id)
      }
      if (activeSubProject) {
        this.location.go(`/${this.globals.project.code}/sub/${activeSubProject.code}/contactmomenten`);
      } else {
        this.location.go(`/${this.globals.project.code}/stakeholders/contactmomenten`);
      }
      if (isNewLog && dialogData && dialogData.updated) {
        this.logs.data.unshift(dialogData.log);
        this.logAdded.emit();
      }
    });
  }

  public downloadLog(logId: number, event: MouseEvent): void {
    event.stopPropagation();
    this._reportsService.download('log', 'word', {
      id: logId
    }).subscribe(
        (report: ReportResponse) => {
          saveAs(report.body, report.filename);
        });
  }

  public onScrollDown(ev: { currentScrollPosition: number }) {
    this.currentPage++;
    this.loadLogs(this.pagesize, this.currentPage);
  }
}
