  <div>
    <h4 >Project is aangemaakt</h4>

  </div>

  <!-- modal body -->
  <div class="popup-body" *transloco="let t">
      Het project is succesvol aangemaakt. U kunt nu
      gebruikers toevoegen
  </div><!--end of modal body -->

  <div class="popup-footer">
    <div class="dialog-button-row">
      <button type="button" mat-stroked-button  (click)="close()">{{'close' | transloco | capitaliz}}</button>
      <button [routerLink]="['beheer',data.code,'gebruikers']" (click)="close()" type="button" mat-flat-button>
        <span>
            Gebruikers toevoegen
        </span>
      </button>
    </div>
  </div><!--end of modal footer -->
