import { Injectable } from '@angular/core';
import { AbstractService } from "../common/abstract.service";
import { HttpClient } from "@angular/common/http";
import { ThemeType } from "../../../core/enum/theme-type";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
const ThemeApi = {
  themeissues: "themeissues/crud",
  stakeholdertypes: "stakeholders-types/crud",
  complaintthemes: "complaintthemes/crud",
  themedecisions: "themedecisions/crud",
  phases: "phases/crud",
  interests: "interests/crud",
  themetype: "",
  permitstypes: "types/crud?group=permitstypes",
  permitsrequesters: "types/crud?group=permitsrequesters",
  permitsclusters: "types/crud?group=permitsclusters",
  permitsvalidities: "types/crud?group=permitsvalidities",
  permitsfillingrequirements: "types/crud?group=permitsfillingrequirements"
}
@Injectable({
  providedIn: 'root'
})
export class ThemesService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getThemes(type: ThemeType) {
    if (type && type.includes('permits')) {
      return this.http.get(`/api/types?group=${type}`, this.httpOptions)
    }
    return this.http.get(`/api/${type}`, this.httpOptions)
  }

  @ServiceErrorHandler()
  submitTheme(type, names: any) {
    return this.http.post(`/api/${ThemeApi[type]}`, names, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateTheme(type: ThemeType, themes) {
    let url = ""
    if (type.indexOf("?") > -1) {
      url = type.replace("types", "types/crud")
    }
    else {
      url = ThemeApi[type]
    }
    return this.http.post(`/api/${url}`, themes, this.httpOptions);
  }

  @ServiceErrorHandler()
  deleteTheme(type: ThemeType, themes) {
    return this.http.post(`/api/${ThemeApi[type]}`, themes, this.httpOptions);
  }
}
