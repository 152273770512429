import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicLayers } from 'src/app/core/util/openDataModals';

@Component({
  selector: 'app-authentication-modal',
  templateUrl: './authentication-modal.component.html',
  styleUrls: ['./authentication-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthenticationModalComponent {
  hide: boolean = true;
  // eslint-disable-next-line no-empty-function
  constructor(public dialogRef: MatDialogRef<AuthenticationModalComponent>,@Inject(MAT_DIALOG_DATA) public data: PublicLayers,) { }
  /*
    On Close
  */
  public onClose(): void{
    this.dialogRef.close();
  }
  /*
    On Credentials Save
  */
  public onSubmit(): void{
    this.dialogRef.close(this.data);
  }
}
