import { Component, Input } from '@angular/core';
import {ChildTabbedPage} from "../../../../../../core/util/abstract/child-tabbed-page";
import {PubsubService} from "../../../../../../core/service/api/pubsub.service";
import {Globals} from "../../../../../../globals";
import {StakeholdersService} from "../../../../../../core/service/api/stakeholders.service";
import {ActivatedRoute, Event, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {get} from "scriptjs";
import {googleMapAddressParse} from "../../../../../../core/util/googleMapAddressParse";
import {PaginationParams} from "../../../../../../core/service/common/pagination-params";
import {PaginatedResponse} from "../../../../../../core/service/common/paginated-response";
import {showFeedbackSaved} from '../../../../../../core/util/notification';
import {Stakeholder} from '../../../../../../akita/stakeholders/state/stakeholder.model';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { Permission } from 'src/app/core/enum/permission';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { globalSubprojectUserSaprator, selectedSubprojectUsersSaprator, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';

@Component({
  selector: 'app-tab-stakeholder-detail-general',
  templateUrl: './tab-stakeholder-detail-general.component.html',
  styleUrls: ['./tab-stakeholder-detail-general.component.sass']
})
export class TabStakeholderDetailGeneralComponent extends ChildTabbedPage {

  pageSubTitle: string;
  pageTitle: string;
  tabName = '';
  userload:boolean=false;
  themeType = ThemeType.Stakeholders;
  selectedTheme: any[] = [];
  public showRightSide: boolean = true;
  subprojectsSet:any[]=[];
  chipDisabler:any[]=[];
  chipDisablerUser:any[]=[];
  userSelection:any[]=[];
  protected stakeholderTypes = [];
  public users: any[];
  protected googleMapOptions = {
    types: [],
  };
  protected enableManualAddress = false;
  @Input() stakeholder: Stakeholder;
  @Input() relatic={name:''};
  constructor(
    public pubsub: PubsubService,
    public globals: Globals,
    private _cachedService: CachedService,
    private route: ActivatedRoute,
    private subProjectQuery: SubprojectQuery,
    private router: Router,
    private _stakeholdersService: StakeholdersService) {
    super(pubsub, globals);
  }

  ngOnInit() {
    this.subprojectSetup();
    this.setLodedSubproject();
    this._cachedService.getUsers().subscribe(
      (users) => {this.users = users; this.userload=true;this.usersSetup();this.setLodedSubprojectUsers();}
    );
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showRightSide = true;
      } else if (event instanceof NavigationStart) {
        this.showRightSide = false;
      }
    });

    this.selectedTheme = this.stakeholder.stakeholdertypes && this.stakeholder.stakeholdertypes.length > 0? this.stakeholder.stakeholdertypes: null;

    // if (!this.globals.googleMapLibLoaded) {
    //   get(`https://maps.googleapis.com/maps/api/js?key=${ this.globals.googleMapApiKey }&libraries=places&sensor=false&language=nl`, () => {
    //     this.globals.googleMapLibLoaded = true;
    //   });
    // }

    this._stakeholdersService.getStakeholderTypes(new PaginationParams()).subscribe(
      (response: PaginatedResponse) => this.stakeholderTypes = response.data
    );
  }

  public compareType(type1: any, type2: any): boolean {
    if (type1 && type2) {
      return type1.id === type2.id;
    }

    return false;
  }

  public load() {
    this._stakeholdersService.getStakeholderSummary(this.stakeholder.code).subscribe(
      (stakeholder:any) => this.stakeholder = stakeholder
    );
  }

  protected updateAddress() {
    this.stakeholder.locations = null;
    this.updateStakeholder();
  }

  protected handleAddressChange(event): void {
    const googleAddress = googleMapAddressParse(event);
    this.stakeholder.address = googleAddress.address;
    this.stakeholder.postcode = googleAddress.postcode;
    this.stakeholder.city = googleAddress.city;
    if (googleAddress.latitude && googleAddress.longitude) {
      this.stakeholder.locations = {
        points: {
          type: 'MultiPoint',
          coordinates: [
            [parseFloat(googleAddress.longitude), parseFloat(googleAddress.latitude)],
          ]
        },
        locatable_type: 'stakeholders',
      };
    }

    this.updateStakeholder();
  }

  protected updateStakeholder(showSavedFeedback = true) {
    this._stakeholdersService.updateStakeholder(this.stakeholder.code, this.stakeholder).subscribe(
      () => {

        if (showSavedFeedback) {
          showFeedbackSaved();
        }
      });
  }

  updateSubprojects(){
    this.pubsub.updateSubprojects(this,"updateStakeholder",this.stakeholder.subprojects)
  }

  @Allowed(Permission.StakeholdersUpdate)
  public updateSelectedTheme(theme) {
    this.stakeholder.stakeholdertypes = theme;
    this.updateStakeholder();
  }

  public subprojectSetup(){
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects,'stakeholders:update')
  }
  public setLodedSubproject(){
    this.chipDisabler=[];
    this.chipDisabler=selectedSubprojectSaprator(this.subprojectsSet,this.stakeholder.subprojects)
  }
  public usersSetup(){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
      this.users=globalSubprojectUserSaprator(this.users,activeSubProject)
    }
  }
  public setLodedSubprojectUsers(){
    if(this.users){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
    this.chipDisablerUser=[];
    this.chipDisablerUser=selectedSubprojectUsersSaprator(this.users,this.stakeholder.users);
    }
  }
}
}
