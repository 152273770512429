<section *transloco="let t">
  <div class="row">
    <div class="col-md-12 mt-10">
      <h5 class="text-black">{{'settings.auth.profile' | transloco}}</h5>
    </div>
    <div class="col-md-6">
      <mat-form-field class="full-width">
        <mat-label>{{ 'settings.name' | transloco}}</mat-label>
        <input type="text" matInput [(ngModel)]="name"  class="" name="name" (change)="submit()" required/>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="full-width">
        <mat-label>{{ 'email' | transloco}}</mat-label>
        <input type="email" matInput class="" [disabled]="true" [(ngModel)]="globals.user.email" name="email" required />
      </mat-form-field>
      <mat-hint>{{'settings.personal.email_disable' | transloco}}</mat-hint>
    </div>
  </div>
  <div class="row" *ngIf="!globals.user.is_sso_enabled">
    <div class="col-md-12">
      <a mat-stroked-button (click)="onClickChangePasswordModal()">
        <span class="">{{'settings.personal.change_password' | transloco}}</span>
      </a>
    </div>
  </div>
  <div class="row" *ngIf="!globals.user.is_sso_enabled">
    <div class="col-md-12 mt-10">
      <h5 class="text-black">{{'settings.auth.two_factor_authentication' | transloco}}</h5>
    </div>
    <div class="col-md-12 mt-10">
      <mat-hint>{{ twoFactorStatus ? ('settings.auth.two_factor_authentication_info_enable' | transloco) : ('settings.auth.two_factor_authentication_info' | transloco)}}</mat-hint>
    </div>
    <div class="col-md-12 mt-10">
      <a mat-stroked-button (click)="onClickTowFactorAuthenticationModal()">
        <span *ngIf="!twoFactorStatus" class="">{{'settings.personal.enable' | transloco}} 2FA</span>
        <span *ngIf="twoFactorStatus" class="">{{'settings.personal.disable' | transloco}} 2FA</span>
      </a>
    </div>

    <div *ngIf="twoFactorStatus" class="col-md-12 mt-10">
      <h5 class="text-black">{{ 'settings.personal.trusted_devices_title' | transloco}}</h5>
      <p *ngIf="!trustedBrowsers.length && !this.isProcessing">{{ 'settings.personal.no_trusted_device' | transloco }}</p>
      <mat-progress-bar *ngIf="!trustedBrowsers.length && this.isProcessing" mode="buffer"></mat-progress-bar>
      <mat-table *ngIf="trustedBrowsers.length && !this.isProcessing" [dataSource]="trustedBrowsers" class="mat-elevation-z1 trusted-devices-table" matSort>
        <ng-container matColumnDef="browser">
          <mat-header-cell *matHeaderCellDef [style.min-width.%]="40">{{ 'settings.personal.trusted_devices' | transloco}}</mat-header-cell>
          <mat-cell *matCellDef="let device" [style.min-width.%]="40"> {{ device.platform }} - {{ device.browser }} </mat-cell>
        </ng-container>
    
        <!-- Timestamp of Trust Column -->
        <ng-container matColumnDef="timestamp">
          <mat-header-cell *matHeaderCellDef [style.min-width.%]="40">{{ 'settings.personal.trusted_datetime' | transloco}}</mat-header-cell>
          <mat-cell *matCellDef="let device" [style.min-width.%]="40"> {{ device.created_at | date:'short' }} </mat-cell>
        </ng-container>
    
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef [style.min-width.%]="20"></mat-header-cell>
          <mat-cell *matCellDef="let device" [style.min-width.%]="20">
            <span class="delete-button" matTooltip="{{'settings.personal.delete_trusted_device' | transloco}}"><mat-icon  class="uil uil-trash" (click)="removeBrowser(device)"></mat-icon></span>
          </mat-cell>
        </ng-container>
    
        <!-- Header and Row Definitions -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>    

  </div>
  <!-- <div class="row">
    <div class="col-md-12 mt-10">
      <h5 class="text-black">{{'settings.auth.language' | transloco}}</h5>
    </div>
    <div class="col-md-12 mt-10">
      <mat-hint>{{'settings.auth.language_info' | transloco}}</mat-hint>
    </div>
    <div class="col-md-6 mt-10">
      <app-generic-multiselect-checkbox [hideCancleIcon]="true" [multiple]="false" [placeholder]="t('settings.language')" name="multilingual"  [(ngModel)]="language" (ngModelChange)="onChangeMultilingual()" [required]="false">
        <app-generic-multiselect-checkbox-option *ngFor="let lang of multilingual; trackBy: trackByFn" [value]="lang">
            {{ lang.title | capitaliz }}
        </app-generic-multiselect-checkbox-option>
      </app-generic-multiselect-checkbox>
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-12 mt-10">
      <h5 class="text-black">{{'settings.auth.api_key' | transloco}}</h5>
    </div>
    <div class="col-md-12 mt-10">
      <mat-hint>{{'settings.auth.api_key_info' | transloco}}</mat-hint>
    </div>
    <div class="col-md-6 mt-10">
      <div class="d-flex j-space-between api-key-custome-set">
        <span class="a-self-center">{{randomNumber}}</span>
        <span class="a-self-center"><a class="pointer" (click)="generateRandomNumber()">{{randomNumber ? ('settings.personal.renew_api_key' | transloco) : ('settings.personal.api_key_not_set' | transloco)}}</a></span>
      </div>
    </div>
  </div>
</section>
