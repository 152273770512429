
export const AvatarCustomStyle=
{
  "border-width": "3px",
  "font-weight": "bold",
  "width": "28px",
  "height": "28px",
  "font": "bold 10px / 20px Helvetica, Arial, sans-serif"
};

export const AvatarNotificationCustomStyle=
{
  "border-width": "3px",
  "font-weight": "bold",
  "width": "35px",
  "height": "35px",
};