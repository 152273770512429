<mat-form-field appearance="fill" [matTooltip]="tooltip" [style.width]="matFormFieldWidth">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    [ngxMatDatetimePicker]="picker"
    [(ngModel)]="selectedDateTime"
    [disabled]="isDisabled"
    (dateInput)="onDateTimeChange($event)"
    (focus)="picker.open()"
  >
  <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
</mat-form-field>
