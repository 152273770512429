<section *ngIf="icon; else button">
  <span *ngIf="option !== 'actionArchive'" sharedModal="recycleBin"  class="menu-option-title" [data]="item" (onClosed)="onClosed.emit()"><mat-icon >restore_from_trash</mat-icon>{{"recycle_bin" | transloco | capitaliz}}</span>
  <span *ngIf="option === 'actionArchive'" sharedModal="recycleBin"  class="menu-option-title" [data]="item" (onClosed)="onClosed.emit()"><mat-icon >restore_from_trash</mat-icon></span>
</section>
<ng-template #button>
  <button mat-stroked-button *ngIf="isExist" sharedModal="recycleBin" [data]="item" (onClosed)="onClosed.emit()">
    <mat-icon class="d-text-middle">restore_from_trash</mat-icon>
    <span class="hidden-xs hidden-sm">{{'recycle_bin' | transloco}}</span>
  </button>
</ng-template>
