import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import moment from 'moment';
import { Observable } from "rxjs";
import { Permission } from 'src/app/core/enum/permission';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { Typeables } from "../../../../../core/enum/typeables";
import { TypesService } from "../../../../../core/service/api/types.service";
import { GenericComponent } from "../../../../../core/util/abstract/generic-component";
import { showFeedback } from '../../../../../core/util/notification';
import { Globals } from "../../../../../globals";
import { MapService } from 'src/app/core/service/api/map.service';

@Component({
  selector: 'app-calendar-create-event-activity',
  templateUrl: './calendar-create-event-activity.component.html',
  styleUrls: ['./calendar-create-event-activity.component.sass']
})
export class CalendarCreateEventActivityComponent extends GenericComponent implements OnInit {

  @Input() prefilledDate: any;
  @Input() entity: any;
  @Input() showPreviousStep: boolean = true;
  @Output() formReset: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();

  filteredActivitiesTypes: Observable<string[]>;
  activitiesTypes: string[] = [];

  activityForm: FormGroup;
  dateMinActivity: any;
  themeType = ThemeType.Activities;

  constructor(public globals: Globals, private formBuilder: FormBuilder, private _typesService: TypesService, public _mapService: MapService) {
    super(globals);

  }

  ngOnInit() {
    this._mapService.setData('activity');
    this.activityForm = this.formBuilder.group({
      name: ['', Validators.required],
      types: [''],
      calendars: this.formBuilder.group({
        title: [''],
        type: [''],
        date: [''],
        start: [''],
        end: ['']
      }),
      line: [''],
      point: [''],
      locations: [''],
    });
    if (this.entity) {
      const layerData = this.entity.layers
      if (layerData) {
        if (layerData.title) {
          this.activityForm.get('name').setValue(layerData.title);
        }
        else {
          this.activityForm.get('name').setValue(`${layerData.layer_id }-${ layerData.layer_name}`);
        }
      }
      this.activityForm.get('locations').setValue(this.entity.locations);
    }
    this.showCreateEvent();
    this.loadTypes();
    this.getMinDate();
  }

  // SHOW MODAL
  private showCreateEvent() {
    if (!this.prefilledDate || (!this.prefilledDate.start && !this.prefilledDate.date)) {
      this.activityForm.get('calendars.start').setValue(new Date());
      return;
    }

    this.activityForm.get('calendars.start').setValue(this.prefilledDate.start || this.prefilledDate.date);
    const endDate = this.prefilledDate.end ? moment(this.prefilledDate.end).subtract(1, 'days').toDate() : '';
    this.activityForm.get('calendars.end').setValue(endDate);
  }

  // MODAL LOADS
  resetForm() {
    this.activityForm.reset();
    this.formReset.emit();
  }

  // MODAL CONTROLS
  getMinDate() {
    this.dateMinActivity = this.activityForm.get('calendars.start').value || new Date();
  }

  public createEvent() {
    if (!this.activityForm.valid) {
      return;
    }

    // Activity
    this.activityForm.get('calendars.title').setValue(this.activityForm.controls['name'].value);

    this.create.emit(this.activityForm.value);

    // setTimeout(() => this.resetForm(), 1000);
  }


  public warningMapMaxReached(type: string) {
    showFeedback('Geselecteerde oppervlak te groot', 'error', null, false);
  }

  private loadTypes() {
    this._typesService.getTypes(Typeables.activities).subscribe(
      (types: any[]) => this.activitiesTypes = types
    );
  }
  @Allowed(Permission.ActionUpdate)
  public updateSelectedTheme(theme) {
    this.activityForm.get('types').setValue(theme);
  }
}
