import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Decision } from './decision.model';

export interface DecisionState extends EntityState<Decision>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'decisions' })
export class DecisionsStore extends EntityStore<DecisionState, Decision> {

  constructor() {
    super();
  }

}

