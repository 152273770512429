import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import collect from 'collect.js';
import moment from 'moment';
import { finalize, tap } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { EmployeesService } from 'src/app/core/service/api/employees.service';
import { setActionIdsInPovit, setContactPersonIdsInPovit } from 'src/app/core/util/deepCopy';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { ActionListingType } from '../../../../core/enum/action-listing-type';
import { Entities } from '../../../../core/enum/entities';
import { ActionsService } from '../../../../core/service/api/actions.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { StakeholdersService } from '../../../../core/service/api/stakeholders.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';

export interface NewActionConfig {
  stakeholder_code?: string;
  log_id?: number;
  action_type: ActionListingType;
}

@Component({
  selector: 'app-add-stakeholders-action',
  templateUrl: './add-stakeholders-action.component.html',
  styleUrls: ['./add-stakeholders-action.component.sass']
})
export class AddStakeholdersActionComponent extends NewEntityModal implements OnInit {

  declare public entity: any;
  public isProcessing: boolean = false;
  public stakeholders: any[];
  public users: any[];
  public stakeholderLoad:boolean=false;
  public helperText:boolean=false;
  chipDisablerUser:any[]=[];
  public contactPerson: any[]=[];
  private actionListingType: ActionListingType = ActionListingType.StakeholderAll;
  private stakeholderCode: string;
  public emailNotifier:boolean=false;
  public selectReferUser:boolean=false;
  public isContactPersonExist:boolean = false;
  public selectedContactPerson: any = [];
  public relationActionContactPerson = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddStakeholdersActionComponent>,
    private _actionsService: ActionsService, private _stakeholdersService: StakeholdersService, public globals: Globals,private cd: ChangeDetectorRef,
    private _cachedService: CachedService, private pubsub: PubsubService, private employeesService: EmployeesService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if(this.data.batch){
      this.selectReferUser=true;
    }
    super.ngOnInit();
    if (this.data.options) {
      this.stakeholderCode = this.data.options.stakeholder_code;
      this.actionListingType = this.data.options.action_type;
    }
    this._cachedService.getUsers().subscribe(users => {
      this.users = users;
      if(this.data.batch){
         this.users.unshift({id:this.users.length,name:"Selecteer stakeholder(s) accounthouder"})
       }
      });
    this._cachedService.getStakeholders().subscribe(stakeholders => {
      this.stakeholders = stakeholders;
      this.stakeholderLoad=true;
      if (this.stakeholders && this.actionListingType === ActionListingType.StakeholderDetail && this.stakeholderCode) {
        this.entity.stakeholders.push(this.stakeholders
          .filter(stakeholder => stakeholder.code === this.stakeholderCode)
          .shift());
      }
    });
    if(this.data.params && this.data.params.contactmoments){
      this.onLoadContactPerson();
      if(this.data.params.contactmoments.length){
        if(this.data.params.contactpersons && this.data.params.contactpersons.length){
          if(this.data.params.relationActionContactPerson){
            this.relationActionContactPerson = this.data.params.relationActionContactPerson;
            this.selectedContactPerson = [...this.data.params.contactpersons];
          }
        }
      }
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public initializeEntity(): void {
    this.entity = {
      text: '',
      timestamp: moment().add(1, 'weeks').toDate(),
      handled: false,
      stakeholders: [],
      users: [],
      contactmoments: [],
      direct_mail:false
    };
    if(this.data.batch){
      this.entity.stakeholders=this.data.stakeholders;
      this.entity.batch=this.data.batch;
    }
  }

  public submit(): void {
    this.isProcessing = true;
    this.entity.timestamp = moment(this.entity.timestamp).endOf('day');
    this.entity.timestamp = moment(this.entity.timestamp).subtract(1, 'seconds')
    this._actionsService.submitAction(this.entity)
    .pipe(finalize(() => this.isProcessing = false))
    .subscribe(
      data => {
        if(this.data.batch){
          showFeedbackSaved();
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.tasks, data);
        }else{
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          if (snackbar) {
            snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:data,
                message: translate('error.stored'),
                type:'openItems',
                item:'acties',
                url:'acties'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          if(this.isContactPersonExist)
            this.onChangeConnectContactPerson(data);
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.tasks, data);
          }
        }
      }
    );
  }
  public selectionChange(){
    this.chipDisablerUser=[];
    if(this.entity.batch){
      if(this.entity.users.length>0){
        this.entity.users.forEach(element => {
          if(element.name=="Selecteer stakeholder(s) accounthouder"){
            this.entity.stakeholder_users=true;
            this.entity.users=[element];
            this.users.forEach(el => {
              if(el.name!=="Selecteer stakeholder(s) accounthouder"){
                this.chipDisablerUser.push(el.name);
              }
            });
            this.helperText=true;
            this.cd.detectChanges();
          }else{
            if(this.chipDisablerUser.length==0){
              this.helperText=false;
            }
          }
        });
      }else{
          this.helperText=false;
          this.cd.detectChanges();
      }
    }
    this.onUserSelectNotify();
  }
  public onChangeRequired(){
    this.onUserSelectNotify();
  }
  public onUserSelectNotify(): void{
    if(this.entity.users.length>0 && (this.entity.complaints && this.entity.complaints.length>0)){
      if(this.entity.users.length==1 && collect(this.entity.users).contains((value, key) => value.id == this.globals.user.id)){
        this.emailNotifier=false;
      }else{
        this.emailNotifier=true;
      }
    }else{
      this.emailNotifier=false;
    }
  }
  //get contact person
  public onLoadContactPerson(): void{
    this._cachedService.getEmployees()
    .pipe(
      tap(res =>{
        this.contactPerson=res;
        this.isContactPersonExist = true;
      })
    ).subscribe();
  }
  //create relation between action and contact person
  public onChangeConnectContactPerson(data): void{
    const contactpersons_ids = [];
    this.selectedContactPerson.forEach(element => {
      contactpersons_ids.push(element.id);
    })
    if(contactpersons_ids.length) {
      this.employeesService.connectRelationFromTask({
        tasks_id: data.id,
        contactmoments_id:  this.data.params.contactmoments[0].id,
        contactpersons_ids: contactpersons_ids,
      }).subscribe(res => {
          data.contactpersons_contactmoments=setContactPersonIdsInPovit(this.selectedContactPerson,this.data.params.contactmoments[0]);
          data.pivot=setActionIdsInPovit(data,this.data.params.contactmoments[0]);
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.tasks, data);
      });
    }
  }
  public connectContactPerson(data): void{
    const contactpersons_ids = [];
    this.selectedContactPerson.forEach(element => {
      contactpersons_ids.push(element.id);
    })
    if(this.data.params.contactmoments.length){
      this.employeesService.connectRelationFromTask({
        tasks_id: this.relationActionContactPerson? this.data.params.id: data.id,
        contactmoments_id:  this.data.params.contactmoments[0].id,
        contactpersons_ids: contactpersons_ids,
      }).subscribe(res => {
        if(this.relationActionContactPerson){
          this.data.params.contactpersons_contactmoments=setContactPersonIdsInPovit(this.selectedContactPerson,this.data.params.contactmoments[0]);
          this.pubsub.pushNewEntity(Entities.tasks, this.data.params);
          this.dialogRef.close(this.data.params);
        }
      });
    }
  }
}
