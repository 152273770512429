import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatie'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (!value || typeof(value) !== 'string') {
      return value;
    }

    if (completeWords) {
      limit = value.substr(0, 13).lastIndexOf(' ');
    }

    if (value.length <= limit) {
      return value;
    }

    return `${value.substr(0, limit)}${ellipsis}`;
  }

}
