import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import collect from 'collect.js';
import { catchError, tap } from 'rxjs/operators';
import { onPaginationSelection } from 'src/app/akita/batch/batch';
import { setLocalePagination } from 'src/app/akita/multilingual/multilingual.model';
import { SearchService } from 'src/app/core/service/api/search.service';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';
import { Globals } from 'src/app/globals';
@Component({
  selector: 'app-recycle-bin',
  templateUrl: './recycle-bin.component.html',
  styleUrls: ['./recycle-bin.component.sass'],
})

export class RecycleBinComponent implements OnInit {
  public displayedColumns = ['select', 'id', 'name', 'deleted_at', 'by'];
  public loading: boolean = false;
  public entities = new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: false }) _paginator: MatPaginator;
  @ViewChild(MatFormField, { static: false }) matFormField: MatFormField;
  @ViewChild(MatSort, { static: false }) sortView: MatSort;
  public paginator = new PaginationParams(1, 10)
  .orderBy('deleted_at')
  .deleteBy('1');
  pageSizeOptions: number[] = [5, 10, 20, 100];
  //batch selection
  public onSelectAll: boolean = false;
  public batchLoad: boolean = true;
  public selectionItems: any[] = [];
  public batchPaginator = new PaginationParams(1, 1000)
  .orderBy('deleted_at')
  .deleteBy('1');

  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RecycleBinComponent>, private _searchService: SearchService,public globals: Globals,) { }

  ngOnInit(): void {
    if(this.data === 'calendars')
      this.displayedColumns.push('calendarable_type');
    this.onLoadEntity();
  }
  //on close modal
  public onClose(): void{
    this.dialogRef.close();
  }
  //on load entity pagination
  public onLoadEntity(batch?): void{
    this.loading=true;
    if(batch){
      this.onSelectAll = false;
      this.selectionItems = [];
      this.dialogRef.close(true);
    }
    this._searchService.getRecycleBinPagination(this.paginator, this.data)
    .pipe(
      tap(response=>{
        if(response.data.length){
          this.entities.data = response.data;
          this.paginator.total = response.total;
        }else {
          this.entities.data = [];
          this.paginator.total = 0;
          if (this.paginator.page != 1) {
            this._paginator.firstPage();
            this.paginator.page = 1;
            this.onLoadEntity();
          }
        }
        this.loading=false;
        this._paginator._intl=setLocalePagination(this._paginator._intl,this.globals);
      }),
      catchError(async (err) => this.loading = false)
    ).subscribe();
  }
  //set pagination
  public getServerPage(event?: PageEvent) {
    this.paginator.paginate(event);
    this.onLoadEntity();
  }
  //Selects single row from the overview
  public isSelected(event, item) {
    if(event.checked){
      this.selectionItems = [...collect(this.selectionItems).push(item).all()];
    }else{
      this.selectionItems = collect(this.selectionItems).filter((value) => value.id != item.id).all();
    }
  }
  //Selects all hit api to get all records
  public masterSelected(event): void {
    this.batchLoad = false;
    // this.batchPaginator._keyword=this.paginator._keyword;
    if(event){
      this._searchService.getRecycleBinPagination(this.batchPaginator, this.data)
      .pipe(
        tap((res:any)=>{
          this.selectionItems = res.data;
          this.onSelectItems(event);
          this.batchLoad=true;
        }),
        catchError((async (err) => this.batchLoad = true))
      ).subscribe();
    }else{
      this.batchLoad = true;
      this.selectionItems = [];
      this.onSelectItems(event);
    }
  }
  //Selects all rows if they are not all selected; otherwise clear selection
  public onSelectItems(event): void{
    this.entities.data=onPaginationSelection(this.entities.data,this.selectionItems,event);
  }
}
