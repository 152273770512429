import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tick-checkbox',
  templateUrl: './tick-checkbox.component.html',
  styleUrls: ['./tick-checkbox.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: TickCheckboxComponent,
    multi: true
  }]
})
export class TickCheckboxComponent implements ControlValueAccessor {

  // eslint-disable-next-line no-empty-function
  private onChange: (value: any) => void = value => {};
  // eslint-disable-next-line no-empty-function
  private onTouched: (touched: boolean) => void = touched => {};
  public value: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input() public tooltip: string;
  @Input() public location: string;

  // eslint-disable-next-line no-empty-function
  constructor() { }


  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.value.next(obj);
  }

  public handle(checked: boolean): void {
    this.writeValue(checked);
    this.onChange(checked);
    this.onTouched(true);
  }

}
