import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AbstractService} from "../common/abstract.service";
import {PaginationParams} from "../common/pagination-params";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../common/paginated-response";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class ComplaintsService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getComplaint(complaintId,skipSubproject:boolean=false) {
    let params = new HttpParams();
    if(skipSubproject)
      params = params.append('skip_subproject','0')
    return this.http.get(`/api/complaints/${complaintId}`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  exportDecisions(options) {
    return this.http.get(`/file/export/complaint?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  getComplaints(pagination: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/complaints', pagination.get());
  }

  @ServiceErrorHandler()
  getComplaintsRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/complaints?dropdown=1', this.httpOptions);
  }

  @ServiceErrorHandler()
  getComplaintsWidget(pagination: PaginationParams,is_mine=false,is_closed=false){
    let params = new HttpParams();
    if(is_mine){
      params = params.append('is_mine','0');
    }else{
      params = params.append('is_mine','1');
    }
    if(is_closed){
      params = params.append('filter_array[is_closed][]','0');
    }
    params = params.append('direction','asc');
    params = params.append('order_by_status','1');
    params = params.append('order_by','date');
    return this.http.get(`/api/complaints`, pagination.get(params));
  }
  @ServiceErrorHandler()
  deleteComplaints(complaint) {
    return this.http.delete(`/api/complaints/${complaint.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitComplaint(complaint) {
    return this.http.post(`/api/complaints`, complaint, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateComplaint(complaintId, complaint) {
    return this.http.put(`/api/complaints/${complaintId}`, complaint, this.httpOptions);
  }

  @ServiceErrorHandler()
  getComplaintsByResident(residentId, count, page) {
    return this.http.get(`/api/residents/${residentId}/complaints`, {
      headers: this.headers,
      params: new HttpParams()
        .set('count', count)
        .set('page', page)
    });
  }

  @ServiceErrorHandler()
  getComplaintsByStakeholder(stakeholderCode, count, page) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/complaints`, {
      headers: this.headers,
      params: new HttpParams()
        .set('count', count)
        .set('page', page)
    });
  }

  @ServiceErrorHandler()
  getComplaintThemes() {
    return this.http.get(`/api/complaintthemes`, this.httpOptions);
  }
  @ServiceErrorHandler()
  getComplaintsWithDatePicker(pagination: PaginationParams,start_date='',end_date=''){
    let params = new HttpParams();
    params = params.append('direction','asc');
    params = params.append('order_by_status','1');
    params = params.append('order_by','date');
    if(start_date){
      params = params.append('start_date',start_date);
      params = params.append('end_date',end_date);
    }
    return this.http.get(`/api/complaints`, pagination.get(params));
  }
}
