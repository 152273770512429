import {Permission} from "../core/enum/permission";
import {of} from "rxjs";
import {Globals} from '../globals';
import {AppInjector} from '../../app-injector';

/**
 * Allowed method decorator, meant for methods that are member of component that has hasPermission implementation
 * Used for child components of Page or GenericComponent
 * @param permission
 * @class
 */
export function Allowed(permission: Permission, returnObservable = false): any {
  return function(target: any, name: string, descriptor: PropertyDescriptor) {
    const globals: Globals = AppInjector ? AppInjector.get(Globals) : null;
    const method = descriptor.value;

    // Override the method so if the user is not allowed to execute the method we can stop the method invocation
    descriptor.value = function(...args) {
      // TODO: SOmetimes globals is not defined here. E.g. on issue creation.
      if (!globals || globals.permissions.includes(permission.toString())) {
        return method.apply(this, args);
      } 
        console.error(`Debug: Cannot invoke method ${name} with permission ${permission}`);
        if (returnObservable) {
          return of({});
        }
      
    };
  };
}
