import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
  // eslint-disable-next-line no-empty-function
  ) { }

  //close modal
  public close(): void{
    this.dialogRef.close();
  }
}
