<form #form="ngForm" (ngSubmit)="updateLog()" class="log-edit" *transloco="let t;">
  <div mat-dialog-title>
    <h4 class="mb-10 inline-block">{{ 'new_e' | transloco | capitaliz }} {{'logs.label' | transloco }}</h4>
    <button type="button" class="close modal-close-button" (click)="close()"><span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
      </span><span class="sr-only">Close</span></button>
  </div>

  <!-- modal body -->
  <mat-dialog-content>
    <div class="col-xs-12 col-sm-12 pd-0 contactmoment-notes-container height-100vh">
      <div class="col-sm-6 col-xs-12">
        <mat-form-field class="modal-newlog-date">
          <mat-label>{{ 'reusable.choose_date' | transloco }}</mat-label>
          <input name="new_log_timestamp" matInput [matDatepicker]="logDatePicker"
            (click)="logDatePicker.open()" required [(ngModel)]="log.contact_moment_timestamp">
          <mat-datepicker-toggle matSuffix [for]="logDatePicker" class="custom-datepicker-toggle"></mat-datepicker-toggle>
          <mat-datepicker #logDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-xs-12 text-right">
        <mat-button-toggle-group name="type" ngDefaultControl aria-label="Font Style" [(ngModel)]="log.contact_moment_type"
          (change)="changeType($event)">

          <mat-button-toggle value="email" ariaLabelledby="Email" [matTooltip]="'Email'" checked="true">
            <mat-icon aria-label="Email">email</mat-icon>
          </mat-button-toggle>

          <mat-button-toggle value="phone" [matTooltip]="'reusable.telephone' | transloco">
            <mat-icon aria-label="Telefoon">phone</mat-icon>
          </mat-button-toggle>

          <mat-button-toggle value="meeting" [matTooltip]="'Meeting'">
            <mat-icon aria-label="Meeting">people_outline</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>{{ 'reusable.title' | transloco }}</mat-label>
          <input name="title" matInput [(ngModel)]="log.title" />
        </mat-form-field>
      </div>

      <div class="col-xs-12">
        <span matTooltip="Selecteer eerst meeting als type contactmoment"
          [matTooltipDisabled]="log.contact_moment_type === 'meeting'">
          <app-generic-multiselect-checkbox [placeholder]="'reusable.select_meeting_series' | transloco" name="series" ngDefaultControl [(ngModel)]="log.series"
            [multiple]="false" (ngModelChange)="fetchActionsPerSeries()" [disabled]="log.contact_moment_type !== 'meeting'" [uniqueId]="'meeting-series'">
            <app-generic-multiselect-checkbox-option *ngFor="let serie of series; trackBy: trackByFn" [value]="serie"
              [accessKey]="'id'">
              {{ serie.name }}
            </app-generic-multiselect-checkbox-option>
            <app-generic-multiselect-checkbox-option-new-action type="generic_type">
            </app-generic-multiselect-checkbox-option-new-action>
          </app-generic-multiselect-checkbox>
        </span>
      </div>

      <div class="col-xs-12" *ngIf="connectStakeholders">
        <app-multiselect-checkbox [placeholder]="'reusable.selecteer_contactpersoon' | transloco" name="employees" type="contactpersons" [log]="log" [logData]="logData"
          [(ngModel)]="log.contactpersons" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('employees.labels' | transloco)+' ...'"
          (onStakeholdersUpdated)="onStakeholdersUpdated($event)" (ngModelChange)="employeeSelected()" [uniqueId]="'select-contactperson'">
          <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
          <app-generic-multiselect-checkbox-option *ngFor="let employee of contactpersons" [value]="employee">
            {{ employee.first_name }} {{ employee.last_name }}
          </app-generic-multiselect-checkbox-option>
          <app-generic-multiselect-checkbox-option-new-action type="contactpersons"></app-generic-multiselect-checkbox-option-new-action>
          <app-generic-multiselect-search-project entity="contactpersons.stakeholders">
          </app-generic-multiselect-search-project>
        </app-multiselect-checkbox>
      </div>

      <div class="col-xs-12" *ngIf="connectStakeholders">
        <app-generic-multiselect-checkbox #stakeholderMultiselect [placeholder]="'reusable.selecteer_stakeholder' | transloco"
          name="stakeholders" [(ngModel)]="log.stakeholders" [required]="stakeholderMandatory"
          [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [uniqueId]="'select-stakeholders'">
          <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
          <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
            {{ stakeholder.name }}
          </app-generic-multiselect-checkbox-option>
          <app-generic-multiselect-checkbox-option-new-action type="stakeholder">
          </app-generic-multiselect-checkbox-option-new-action>
          <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
        </app-generic-multiselect-checkbox>
        <a *ngIf="sidebarEmployees.emails.unregistered.length > 0"
          class="d-cursor-hand" (click)="showEmployeeSidebar()">
          {{ sidebarEmployees.emails.unregistered.length }} {{'reusable.email_address' | transloco}} {{'reusable.unknown' | transloco | lowercase }}
        </a>
      </div>

      <div class="col-xs-12" *ngIf="globals.projectConfigs && !globals.projectConfigs['residents.disabled']">
        <app-generic-multiselect-checkbox [placeholder]="'reusable.selecteer_omwonende' | transloco" name="residents"
          [(ngModel)]="log.residents" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('reusable.local_residents' | transloco)+' ...'">
          <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
          <app-generic-multiselect-checkbox-option *ngFor="let resident of residents" [value]="resident">
            {{ resident.name }}
          </app-generic-multiselect-checkbox-option>
          <app-generic-multiselect-search-project entity="residents"></app-generic-multiselect-search-project>
        </app-generic-multiselect-checkbox>
      </div>

    </div>

    <div class="col-xs-12 col-sm-12 ">
      <h4>{{ 'notes' | transloco | capitaliz }}</h4>
      <div class="col-xs-12 mt-5 row contactmoment-notes-container modal-white-background">
        <editor [apiKey]="apiKey" [(ngModel)]="log.description" ngDefaultControl name="description" [init]="{
                plugins: 'lists table autoresize',
                branding: false,
                menubar: false,
                language: haslanguageCode() === 'en' ? haslanguageCode()+'_GB' : haslanguageCode(),
                language_url: 'assets/js/tinymce.nl.js',
                autoresize_on_init: true,
                statusbar: false,
                min_height: 350,
                block_unsupported_drop: false,
                paste_block_drop: false,
                paste_data_images: true,
                paste_as_text: true,
                font_formats: 'Work Sans, sans-serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,times new roman,times,serif;Verdana=verdana,geneva,sans-serif',
                fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
                content_style:'@font-face{font-family: Work Sans;  src: url(../../../../../../../assets/fonts/WorkSans-Regular.ttf)} body { font-family: Work Sans; font-size: 10pt; max-width: 100% } .mce-content-body{ line-height: 1.2 !important; margin-bottom: 0 !important;  margin-right: 30px; !important; max-width: 100%; overflow-x: hidden; word-break: break-word;} .mce-content-body[data-mce-placeholder] {font-size: 13px; font-family: Work Sans} p{margin-block-start: 0px !important; margin-block-end: 0px !important} .mce-content-body table {max-width: 100%;}'
                }"
          [toolbar]="['undo redo blocks fontsize table bold italic underline strikethrough lineheight alignleft aligncenter alignright indent numlist bullist removeformat']">

        </editor>
      </div>
    </div>
  </mat-dialog-content>
  <!--end of modal body -->
  <mat-dialog-actions align="end">
    <mat-progress-bar *ngIf="saving && upload && upload.upload" mode="determinate" [value]="upload.upload.progress">
    </mat-progress-bar>
    <div class="dialog-button-row">
      <button type="button" mat-stroked-button (click)="close()">{{ 'close' | transloco | capitaliz }}</button>
      <button type="submit" mat-flat-button [disabled]="saving || form.invalid">
        <mat-icon class="d-text-middle">save</mat-icon>
        <ng-container *ngIf="!saving">{{ 'add' | transloco | capitaliz }}</ng-container>
        <ng-container *ngIf="saving">{{ 'text.please_wait' | transloco | capitaliz }}</ng-container>
      </button>
    </div>
  </mat-dialog-actions>
  <!--end of modal footer -->
</form>
