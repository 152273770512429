import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import collect from 'collect.js';
import posthog from 'posthog-js';
import { Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { isParamsExist, onPaginationSelection } from 'src/app/akita/batch/batch';
import { setDefaultLanguage, setLocalePagination } from 'src/app/akita/multilingual/multilingual.model';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { AvatarCustomStyle } from 'src/app/core/enum/styles';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { ExcelServiceService } from 'src/app/core/service/api/excel-service.service';
import { countRelations } from 'src/app/core/util/countRelations';
import { deleteConfirmationResponce } from 'src/app/core/util/deepCopy';
import { generateColor } from 'src/app/core/util/generateColor';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { TabIssueDetailGeneralComponent } from 'src/app/features/main-app/issues/page/issues-detail/tab-issue-detail-general/tab-issue-detail-general.component';
import { Issue } from '../../../../akita/issues/state/issue.model';
import { Entities } from '../../../../core/enum/entities';
import { IssuesService } from "../../../../core/service/api/issues.service";
import { PubsubService } from "../../../../core/service/api/pubsub.service";
import { PaginationParams } from "../../../../core/service/common/pagination-params";
import { Page } from "../../../../core/util/abstract/page";
import { showFeedback, showFeedbackRemoved } from '../../../../core/util/notification';
import { Globals } from "../../../../globals";
import { DeletionModalComponent } from '../../modal/deletion-modal/deletion-modal.component';

@Component({
  selector: 'app-issues-list',
  templateUrl: './issues-list.component.html',
  styleUrls: ['./issues-list.component.sass']
})
export class IssuesListComponent extends Page implements OnInit, OnDestroy {

  pageSubTitle = 'Overzicht';
  pageTitle = 'Issues';
  project: any;
  loading = true;
  exportStatus = 'init';
  issues = new MatTableDataSource([]);
  paginator = new PaginationParams(1, 20)
    .with(['stakeholders', 'subprojects', 'users', 'tasks', 'requirements', 'layers', 'issues_stakeholder_matrices', 'workflows'])
    .orderBy('urgency')
    .withFilters(true);
  pageSizeOptions: number[] = [5, 10, 20, 100];
  availableFilters: any;
  statusFilter = 'open';
  keyword: string = '';
  displayedColumns: string[] = [];
  @ViewChild(MatFormField) matFormField: MatFormField;
  @ViewChild(MatSort) sortView: MatSort;
  @ViewChild(MatPaginator) _paginator: MatPaginator;

  private searchTimeout: any;
  private newEntitySubscription: Subscription;
  public urgency: string;
  public AvatarCustomStyle = AvatarCustomStyle

  columnWidths = {
    select: null,
    id: null,
    name: 250,
    stakeholders: 200,
    rationale: 200,
    "themeissues.name": 200,
    workflow: 200,
    description: 200,
    reason_closure: 200,
    deadline: 200,
    // ...add initial widths for other columns as needed
  };

  resizingColumn: string | null = null;

  //batch selection
  public onSelectAll: boolean = false;
  public batchLoad: boolean = true;
  public selectionItems: any[] = [];
  public filterColumnKeys: any[] = [];
  public menuToggle: boolean = false;
  public showColumnOptions: boolean = false;
  public optionalColumns : object[] = [];
  public extraColumns: string[] = [];
  public defaultColumns: string[] = [];
  public stickyEnabled: boolean = false;
  batchPaginator = new PaginationParams(1, 1000)
  .with(['stakeholders', 'subprojects', 'users', 'tasks', 'requirements'])
  .orderBy('urgency')
  .withFilters(true);
  public exportFilter: boolean = false;
  constructor(
    private excelService: ExcelServiceService,
    private issuesService: IssuesService,
    private route: ActivatedRoute,
    public router: Router,
    private _subProjectsQuery: SubprojectQuery,
    public location: Location,
    public pubsub: PubsubService,
    private dialog: MatDialog,
    private dateAdapter: DateAdapter<Date>,
    public globals: Globals,
    private configs: ConfigsService,
    private cd: ChangeDetectorRef,) {
    super(pubsub, globals);
  }
  @HostListener('window:resize', [])
  onResize() {
    this.stickyOnResize();
  }
  stickyOnResize() {
    if (window.innerWidth < 796) {
      this.stickyEnabled = false;
    } else {
      this.stickyEnabled = true;
    }
  }

  startResize(event: MouseEvent, column: string) {
    this.resizingColumn = column;
    event.preventDefault();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.resizingColumn) {
      if ((this.columnWidths[this.resizingColumn] + event.movementX) >= 200)
        this.columnWidths[this.resizingColumn] += event.movementX;
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.resizingColumn = null;
  }

  ngOnInit() {
    const status = this.route.snapshot.queryParamMap.get('status');
    if (status) {
      this.statusFilter = status;
    }
    if (this.globals.modules && !this.globals.modules.some(module => module.module_id === 'subprojects'))
      this.displayedColumns = this.displayedColumns.filter(c => c != 'subprojects')

    this.urgency = this.route.snapshot.queryParamMap.get('urgency');
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.pubsub.updateTabName('');
      this.urgency = paramMap.get('urgency');
      this.statusFilter = paramMap.get('status') || 'open';
      if (this.statusFilter == 'gesloten') {
        this.pubsub.updateTabName('gesloten');
        if (this.paginator._filters && this.paginator._filters.urgency)
          this.paginator._filters.urgency = []
      }
      this.loadIssues();
    });
    super.ngOnInit();
    this.newEntitySubscription = this.pubsub.newEntitiyPush.subscribe(data => {
      if (data.type && data.type === Entities.issues) {
        this.loadIssues();
      }
    });
    posthog.capture('issues')
    this.dateAdapter.setLocale(setDefaultLanguage(this.globals,'user'));
    this.customColumns();
  }

  public overviewTableColumns(){
    this.displayedColumns = ['select','id','code', 'name', 'stakeholders', 'themeissues.name','relatics', 'relations', 'subprojects', 'user', 'urgency', 'action'];
    this.defaultColumns = ['select','id','code', 'name', 'stakeholders', 'themeissues.name','relatics', 'relations', 'subprojects', 'user', 'urgency', 'action'];
    if (this.hasModule('workflow')) {
      this.displayedColumns.splice(5, 0,'workflow');
      this.defaultColumns.splice(5, 0,'workflow');
    }
    this.loadIssues();
  }

  public ngOnDestroy(): void {
    this.newEntitySubscription.unsubscribe();
  }

  public applyFilter(filterValue: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = this.searchTimeout = setTimeout(() => {
      this.paginator.keyword(filterValue.trim().toLowerCase());
      this.loadIssues();
    }, 350);

  }

  exportReport(){
    this.exportStatus = 'downloading';
    this.issuesService.exportDecisions(this.paginator).pipe(finalize(() => this.exportStatus = 'init'))
    .subscribe(
      (res) => {

        let fileName = 'issues';

        if (res.type == 'application/octet-stream') {
          fileName += '.zip';
        } else {
          fileName += '.xls';
        }

        this.excelService.saveBlobAsFile(res, fileName);
      });
  }
  cancelSearch() {
    this.keyword = ''
    this.paginator.keyword('');
    this.loadIssues();
  }

  public sort(sort: Sort) {
    if (sort.active === 'action') {
      return;
    }

    this.paginator.orderBy(sort.active);
    this.paginator.descending(sort.direction);
    this.loadIssues();
  }

  public getServerPage(event?: PageEvent) {
    this.paginator.paginate(event);
    this.loadIssues();
  }

  public switchStatus(status: string) {
    this.router.navigate(['issues'], {
      queryParams: {
        status: status,
      }
    });
  }

  public deleteIssue(issue) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(issue,this.router,'issues'),
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.issuesService.removeIssueByCode(issue).subscribe(() => {
          if (this.issues.data.length <= 1) {
            this.paginator.previousPage();
          }
          showFeedbackRemoved();
          this.loadIssues();
        },
          () => showFeedback('Verwijder eerst koppelingen met dit issue in issue dialoog')
        );
      }
    });
  }

  public issueAdded(issue: any): void {
    if (issue) {
      this.issues.data.unshift(issue);

      // Need to fully re-assign otherwise the changes won't be reflected
      this.issues.data = this.issues.data;
    }
  }

  public loadIssues(batch?) {
    this.loading = true;
    this.exportFilter = false;
    if(batch){
      this.onSelectAll = false;
      this.selectionItems = [];
    }
    this.issuesService.getIssues(this.paginator, this.statusFilter, false, this.urgency)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (response: any) => {
          if (response.paginate && response.paginate.data && response.paginate.data.length) {
            response.paginate.data.map(issue => this.parseIssue(issue));
            this.issues.data = response.paginate.data;
            this.issues.data.forEach(item => {
              item.users = item.users.filter(user => user.is_removed === false);
            });
            this.paginator.total = response.paginate.total;
            this.availableFilters = [
              {
                field: 'themeissues.name',
                options: response.theme_issues,
                label: 'theme'
              },
              {
                field: 'urgency',
                options: response.urgency,
                label: 'issues.urgency',
                entity: 'issues',
              },
              {
                field: 'users.name',
                options: response.users,
                label: 'users'
              },
              {
                field: 'workflows',
                options: response.workflows,
                label: 'workflows'
              },

            ]
          }
          else {
            this.issues.data = [];
            this.paginator.total = 0;
            if (this.paginator.page != 1) {
              this._paginator.firstPage();
              this.paginator.page = 1;
              this.loadIssues();
            }
          }
          this._paginator._intl=setLocalePagination(this._paginator._intl,this.globals);
          if(this.paginator._keyword || isParamsExist(this.paginator)){
            this.exportFilter = true;
            if(this.onSelectAll)
              this.masterSelected(this.onSelectAll);
          }
          this.onSelectItems(this.onSelectAll);
        });
        this.filterColumnKeys = [];
        // eslint-disable-next-line no-unused-expressions
        this.paginator._filters? this.filterColumnKeys = Object.keys(this.paginator._filters): '';
  }

  parseIssue(issue:Issue){
    if(issue.subprojects && issue.subprojects.length){
      issue.subprojects[0].color = generateColor(issue.subprojects[0].name)
    }
    countRelations(issue)
    return issue;
  }

  public issueListNotFiltered() {
    this.router.navigate(['issues']);
    this.loadIssues();
  }

  public nav(issue: Issue): void {
    const activeSubProject = this._subProjectsQuery.getActive();
    if (activeSubProject) {
      this.location.go(`/${this.globals.project?.code}/sub/${activeSubProject.code}/issues/${issue.id}`);
    } else {
      this.location.go(`/${this.globals.project?.code}/issues/${issue.id}`);
    }

    const modal = this.dialog.open(TabIssueDetailGeneralComponent, modalConfig({
      data: issue,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
    }, ModalEnum.SidebarLargeResponsive));
    this.pubsub.updateHistory(modal, TabIssueDetailGeneralComponent, issue, issue.name)
    modal?.afterClosed().subscribe(() => {
      if (this.statusFilter == 'gesloten')
        this.pubsub.updateTabName('gesloten');
      const activeSubProject = this._subProjectsQuery.getActive();
      if (activeSubProject) {
        this.location.go(`/${this.globals.project?.code}/sub/${activeSubProject.code}/issues`);
      } else {
        this.location.go(`/${this.globals.project?.code}/issues?status=${this.statusFilter}`);
      }
      this.loadIssues();
    });

  }

  ngAfterViewInit() {
    this.route.data.subscribe((data: any) => {
      if (data.issue) {
        this.nav(data.issue);
      }
    });
  }
  //Selects single row from the overview
  public isSelected(event, item) {
    if(event.checked){
      this.selectionItems = [...collect(this.selectionItems).push(item).all()];
    }else{
      this.selectionItems = collect(this.selectionItems).filter((value) => value.id != item.id).all();
    }
  }
  //Selects all hit api to get all records
  public masterSelected(event): void {
    this.batchLoad = false;
    this.batchPaginator._filters=this.paginator._filters;
    this.batchPaginator._keyword=this.paginator._keyword;
    if(event){
      this.issuesService.getIssues(this.batchPaginator, this.statusFilter)
      .pipe(
        tap((res:any)=>{
          this.selectionItems = res.paginate.data;
          this.onSelectItems(event);
          this.batchLoad=true;
        }),
        catchError((async (err) => this.batchLoad = true))
      ).subscribe();
    }else{
      this.batchLoad = true;
      this.selectionItems = [];
      this.onSelectItems(event);
    }
  }
  //Selects all rows if they are not all selected; otherwise clear selection
  public onSelectItems(event): void{
    this.issues.data=onPaginationSelection(this.issues.data,this.selectionItems,event);
  }

  customColumns() {
    this.configs.getConfig_deprecated('columns').subscribe( columns => {
      this.configs.getConfigs({configs: "project.columns"}).subscribe((response: any) => {
        this.overviewTableColumns();
        this.extraColumns = response.issues.optional;
        this.extraColumns.forEach(item => {
          const key = `project.columns.issues.${ item}`;
          if(columns.hasOwnProperty(key)) {
            this.optionalColumns.push({name: item, value: columns[key]})
          } else {
            this.optionalColumns.push({name: item, value: false})
          }
        })

        const stakeholders = 'project.columns.issues.stakeholders';
        if(columns.hasOwnProperty(stakeholders)) {
          this.optionalColumns.push({name: 'stakeholders', value: columns[stakeholders]})
          if(!columns[stakeholders]) {
            const index = this.displayedColumns.indexOf('stakeholders')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'stakeholders', value: true})
        }
        const themeissues = 'project.columns.issues.themeissues.name';
        if(columns.hasOwnProperty(themeissues)) {
          this.optionalColumns.push({name: 'themeissues.name', value: columns[themeissues]})
          if(!columns[themeissues]) {
            const index = this.displayedColumns.indexOf('themeissues.name')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'themeissues.name', value: true})
        }

        const workflow = 'project.columns.issues.workflow';
        if(columns.hasOwnProperty(workflow)) {
          this.optionalColumns.push({name: 'workflow', value: columns[workflow]})
          if(!columns[workflow]) {
            const index = this.displayedColumns.indexOf('workflow')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'workflow', value: false})
        }

        const id = 'project.columns.issues.id';
        if(columns.hasOwnProperty(id)) {
          this.optionalColumns.push({name: 'id', value: columns[id]})
          if(!columns[id]) {
            const index = this.displayedColumns.indexOf('id')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'id', value: true})
        }
        this.optionalColumns = collect(this.optionalColumns).unique(item => item.name).all();
        this.optionalColumns.forEach((optCol: any) => {
          if (optCol.value && optCol.name !== 'themeissues.name' && optCol.name !== 'id' && optCol.name !== 'stakeholders' && optCol.name !== 'workflow') {
            const index = this.displayedColumns.indexOf('name');
            this.displayedColumns.splice(index +1,0,optCol.name)
          }
        })
        if (window.innerWidth < 796) {
          this.stickyEnabled = false;
          this.cd.detectChanges();
        }else {
          this.stickyEnabled = true;
          this.cd.detectChanges();
        }
      })
    })
  }
  closeOptColModal(show: boolean) {
    this.showColumnOptions = show;
  }
  columnsUpdated(columns: any[]) {
    this.cd.detectChanges();
    columns.forEach((column) => {
      if(column.value && !this.displayedColumns.includes(column.name)) {
        if (column.name === 'id') {
          if(this.displayedColumns.includes('select')) {
            this.displayedColumns.splice(1,0,column.name)
          } else {
            this.displayedColumns.splice(0,0,column.name)
          }
        } else {
          const index = this.displayedColumns.indexOf('name');
          this.displayedColumns.splice(index+1,0,column.name)
        }
      }
      else if(!column.value && this.displayedColumns.includes(column.name)) {
        const index = this.displayedColumns.indexOf(column.name);
        if (index > -1) {
          this.displayedColumns.splice(index,1);
        }
      }
    });
    if (window.innerWidth < 796) {
      this.stickyEnabled = false;
      this.cd.detectChanges();
    }else {
      this.stickyEnabled = true;
      this.cd.detectChanges();
    }
    this.issues = new MatTableDataSource([]);
    this.loadIssues();
    this.showColumnOptions = false;
  }
}

