import { translate } from '@jsverse/transloco';
import { bugsnagFactory } from './bugsnagFactory';
import { AppInjector } from '../../../app-injector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClosableSnackbarComponent } from '../../shared/components/material/closable-snackbar/closable-snackbar.component';

export function showFeedback(message, type?, error?, notifyBugsnag = true, options = null): void {
  const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;

  type = (type) ? type : '';
  if (type === 'error') {
    if (snackbar) {
      snackbar.openFromComponent(ClosableSnackbarComponent, {
        data: {
          message: message,
        },
        duration: 20000,
        horizontalPosition: 'start',
        panelClass: 'snackbar-background-red',
      });
    }

    if (!notifyBugsnag) {
      return;
    }

    if (error) {
      if (typeof error === 'string') {
        bugsnagFactory().notify(error, options);
      } else {
        bugsnagFactory().notify(JSON.stringify(error, options));
      }
    } else {
      bugsnagFactory().notify(message || 'An error has occurred');
    }
  } else {
    if (snackbar) {
      snackbar.openFromComponent(ClosableSnackbarComponent, {
        data: {
          message: message,
        },
        duration: 4000,
        horizontalPosition: 'start',
        panelClass: 'snackbar-background-green',
      });
    }

  }
}

export function showFeedbackSaved(message?: string): void {
  showFeedback(message || translate('error.stored'), null, null, false);
}

export function showFeedbackRemoved(message?: string): void {
  showFeedback(message || translate('error.delete'), null, null, false);
}

export function showFeedbackError(e, message?: string, notifyBugsnag = true, options = null): void {
  showFeedback(message || translate('error.error_occure'), 'error', e, notifyBugsnag, options);
}

export type Notifications = {
  type?: string,
  data?: any
};
