<div class="main" *transloco="let t">
  <div>
    <h4 class="title">{{ t("whatsapp_mfa.title") }}</h4>
    <h5 class="subtitle">{{ t("whatsapp_mfa.subtitle") }}</h5>
  </div>
  <div class="popup-body">
    <div *ngIf="showPincodeInput">
      <mat-form-field appearance="fill">
        <mat-label>{{ t("whatsapp_mfa.subtitle") }}</mat-label>
        <input
          matInput
          [(ngModel)]="pincode"
          name="pincode"
          (ngModelChange)="validatePincode()"
          maxlength="6"
          minlength="6"
          required
        />
        <mat-error *ngIf="pincodeError">{{ pincodeError }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="popup-footer">
    <div class="dialog-button-row">
      <button type="button" mat-stroked-button (click)="submit(false)">
        {{ t("whatsapp_mfa.cancel_integration") }}
      </button>
      <button
        type="button"
        class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised btn-danger"
        [disabled]="!isPincodeValid"
        (click)="submit(true)"
      >
      {{ t("whatsapp_mfa.set_pincode") }}
      </button>
    </div>
  </div>
</div>
