import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import moment from "moment";
import posthog from 'posthog-js';
import { forkJoin, of } from 'rxjs';
import { Permission } from 'src/app/core/enum/permission';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { deleteConfirmationResponce } from 'src/app/core/util/deepCopy';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { Typeables } from "../../../../core/enum/typeables";
import { ActivitiesService } from '../../../../core/service/api/activities.service';
import { CalendarService } from '../../../../core/service/api/calendar.service';
import { LogsService } from '../../../../core/service/api/logs.service';
import { TypesService } from "../../../../core/service/api/types.service";
import { PaginationParams } from "../../../../core/service/common/pagination-params";
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { dateIso } from "../../../../core/util/dateIso";
import { showFeedback, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { DeletionModalComponent } from "../deletion-modal/deletion-modal.component";
import { MapService } from 'src/app/core/service/api/map.service';
import { modalConfig, ModalEnum } from 'src/app/core/util/modalConfig';
import { NewWorkflowComponent } from '../new-workflow/new-workflow.component';

@Component({
  selector: 'app-calendar-activity-detail',
  templateUrl: './calendar-activity-detail.component.html',
  styleUrls: ['./calendar-activity-detail.component.sass'],
})

export class CalendarActivityDetailComponent extends GenericComponent implements OnInit, AfterViewInit {

  activitiesTypes: string[] = [];

  pageSubTitle = 'Terug naar kalender';
  pageTitle = 'Middel';
  subprojectsSet:any[]=[];
  chipDisabler:any[]=[];
  logs = new FormControl();
  logList: any;

  activityDetailsForm: FormGroup;

  formControlDate: string;
  activity: any;
  minDate: any;
  activityId: number;
  calendarId: number;
  activityTitle: string;
  name: string;
  activityList: any;
  showCalendar: any;
  themeType = ThemeType.Activities;
  selectedTheme: any[] = [];
  relatedMiddel = {
    upcoming: [],
    sent: [],
    recurring: [],
    overdue: [],
  };
  public reducer:any=true;
  hasRelatedMiddel = false;
  loaded: boolean = false;
  entities: any;
  public users: any[];
  parentType: string = '';
  public subprojectUpdate: boolean = false;

  public defaultDrawTool: string = 'point';
  @Output() removeActivity = new EventEmitter<any>();
  @Output() updateActivity = new EventEmitter<any>();
  @Output() showMiddelDetail = new EventEmitter<any>();
  @Output() rendered = new EventEmitter<any>();

  constructor(
    private _activitiesService: ActivitiesService,
    private _logsService: LogsService,
    private cachedService: CachedService,
    private _calendarService: CalendarService,
    private _typesService: TypesService,
    private activityDetails: FormBuilder,
    public globals: Globals,
    public pubsub: PubsubService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CalendarActivityDetailComponent>,
    public _mapService: MapService
  ) {
    super(globals);
  }

  ngOnInit() {
    this.cachedService.getUsers().subscribe(
      (users) => {this.users = users;});
    this.subprojectSetup();
    this.loadActivityList();
    this.loadTypes();
    this.formControlDate = 'start';
    this.name = this.data.title || this.data.name;
    let calendarable_id;
    if (this.data && this.data.extendedProps) {
      this._mapService.setData(this.data.extendedProps.locations)
      calendarable_id = this.data.extendedProps.relation.id;
    }else if(this.data.calendarable_id){
      calendarable_id = this.data.calendarable_id;
    }
    else {
      calendarable_id = this.data.id;
    }
    this.showActivityDetail(calendarable_id, this.data);
    posthog.capture('activity-detail');
  }

  ngAfterViewInit() {
    this.rendered.emit();
  }

  public showActivityDetail(id, event): void {
    if (!event.end) {
      this.formControlDate = 'date';
    } else {
      this.formControlDate = 'start';
    }
    this._activitiesService.getActivity(id, true).subscribe(
      (res: any) => {
        /* this below check is for the updation case
           that not to use the content which are in the response of get request
        */
        if (this.activity && this.activity.contents) {
          const contentsBeforeUpdation = this.activity.contents;
          this.activity = res;
          this.activity.contents = contentsBeforeUpdation;
        } else {
          this.activity = res;
        }

        this.selectedTheme = res.types;
        if (res.types.length)
          this.selectedTheme = res.types;
        if (this.activity.calendar && this.activity.calendar.locations) {
          this.defaultDrawTool = this.activity.calendar.locations.lines ? 'length' : 'point';
        }

        const startDate = dateIso(this.activity.calendar.started_at, true, this.globals.timezone);
        this.minDate = startDate;
        this.calendarId = this.activity.calendars[0].id;
        this.activityId = this.activity.id;
        this.activityTitle = this.activity.name;

        this.activityDetailsForm = this.activityDetails.group({
          name: this.activity.name,
          types: this.activity.types,
          calendars: this.activityDetails.group({
            title: this.activity.name,
            start: [startDate],
            end: [dateIso(this.activity.calendar.ended_at, true, this.globals.timezone)]
          }),
          // logs: this.activity.logs,
          locations: this.activity.calendar.locations,
        });

        forkJoin([
          this.cachedService.getActionsRelations(),
          this.cachedService.getLogsRelations(),
          this._activitiesService.getRelatedMiddel(id, true),
          this.cachedService.getMiddelRelations(),
          // Check if 'permit' exists in the modules, otherwise skip the API call
          this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
          ? this.cachedService.getPermitsRelations() 
          : of([])
        ]
        ).subscribe(([tasks, contactmoments, middel, content, permits]) => {
          const middels: any = middel
          this.relatedMiddel = middels;
          if (this.relatedMiddel.upcoming
            .concat(this.relatedMiddel.overdue)
            .concat(this.relatedMiddel.recurring)
            .concat(this.relatedMiddel.sent)
            .length > 0) {
            this.hasRelatedMiddel = true;
            this.relatedMiddel.sent = this.relatedMiddel.sent.map((middel: any) => { middel.subproject_exist = middel.extendedProps.relation.subproject_exist; return middel });
            this.relatedMiddel.recurring = this.relatedMiddel.recurring.map((middel: any) => { middel.subproject_exist = middel.extendedProps.relation.subproject_exist; return middel });
            this.relatedMiddel.upcoming = this.relatedMiddel.upcoming.map((middel: any) => this.setMiddelDayDiff(middel));
            this.relatedMiddel.overdue = this.relatedMiddel.overdue.map((middel: any) => this.setMiddelDayDiff(middel));
          }
          this.activity.middel = this.relatedMiddel;
          this.activity.contents = this.relatedMiddel.upcoming
            .concat(this.relatedMiddel.overdue)
            .concat(this.relatedMiddel.recurring)
            .concat(this.relatedMiddel.sent);
          this.activity.contents.forEach(content => {
            content.title = content.extendedProps.metadata.type;
          });

          /*middels.contents says all related contents
            override all the upcoming recurring sent overdue*/
          if (middels && middels.contents && middels.contents.length){
            this.activity.contents = middels.contents;
          }

          this.entities = { tasks, contactmoments: contactmoments, contents: content, permits: permits};
          this.loaded = true;
        });
        this.relaticsValidation();
        this.setLodedSubproject();
        this.permissionDisabled();
      }
    );
    this.loadLogsList();
  }

  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'activities', parent: this.activityDetailsForm.value},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.activity.workflows.push(response);
        this.updateActivityDetails();
      }
    });
  }

  // UPDATE ACTIVITY
  public updateActivityDetails() {
    this.minDate = this.activityDetailsForm.get('calendars.start').value;
    let maxDate= moment(this.activityDetailsForm.get('calendars.end').value).endOf('day');
    maxDate=moment(maxDate).subtract(1, 'seconds')
    let minDate= moment(this.activityDetailsForm.get('calendars.start').value).endOf('day');
    minDate=moment(minDate).subtract(1, 'seconds')
    // To show validation error if calendars.end < calendars.start. This has to be set manually on the controller
    // because the loop method doesn't work
    this.activityDetailsForm.get('calendars.end').markAsTouched({ onlySelf: true });
    Object.keys(this.activityDetailsForm.controls).forEach(
      key => this.activityDetailsForm.get(key).markAsTouched({ onlySelf: true })
    );

    if (this.activityDetailsForm.valid) {
      this.activityDetailsForm.value.calendars.end = maxDate;
      this.activityDetailsForm.value.calendars.start = minDate;
      this.activityDetailsForm.value.types = this.activity.types;
      this.activityDetailsForm.value.subprojects = this.activity.subprojects;
      this.activityDetailsForm.value.workflows = this.activity.workflows;
      this._activitiesService.updateActivity(this.activityId.toString(), this.activityDetailsForm.value).subscribe(
        (response: any) => {
          if (this._mapService.circleVectorSource && this._mapService.circleVectorSource.getFeatures().length > 0) {
            this._mapService.circleVectorSource.removeFeature(this._mapService.searchFeature);
          }
          if (!this.subprojectUpdate) {
            this.showActivityDetail(response.id, response)
          }
          this.subprojectUpdate = false;
          if (response.related_updated) {
            showFeedbackSaved(translate('error.stored_activity'))
          } else {
            showFeedbackSaved();
          }
        }
      );
    }
  }

  public delete() {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(this.activity,this.router,'activities')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._activitiesService.deleteActivity(this.activityId).subscribe(
          () => {
            showFeedbackSaved();
            this.close();
          }
        );
      }
    });
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  public updateCoordinate(): void {
    this.updateActivityDetails();
  }

  // ACTIVITY LOADS
  loadLogsList() {
    this._logsService.getLogs(new PaginationParams()).subscribe(
      (res: any) => {
        this.logList = res;
      }
    );
  }

  loadActivityList() {
    this._activitiesService.getAllActivities().subscribe(
      (res: any) => {
        this.activityList = res.data;
      }
    );
  }

  private loadTypes() {
    this._typesService.getTypes(Typeables.activities).subscribe(
      (types: any[]) => {
        this.activitiesTypes = types;
      }
    );
  }

  public warningMapMaxReached(): void {
    showFeedback('Geselecteerde oppervlak te groot', 'error', null, false);
  }

  public showRelatedMiddelDetail(middel) {
    this.showMiddelDetail.emit(middel);
  }

  private setMiddelDayDiff(middel: any) {
    middel.day_diff = moment(dateIso(middel.start, true, this.globals.timezone)).diff({ hour: 0 }, 'days');
    middel.subproject_exist = middel.extendedProps.relation.subproject_exist
    return middel;
  }

  @Allowed(Permission.ActivitiesUpdate)
  public updateSelectedTheme(theme) {
    this.activity.types = theme;
    this.selectedTheme = theme;
    this.updateActivityDetails();
  }

  updateSubprojects() {
    setTimeout(() => {
      this.subprojectUpdate = true;
      this.pubsub.updateSubprojects(this, "updateActivityDetails", this.activity.subprojects)

    }, 500);
  }

  updateEntities(event: any) {

    this.activityDetailsForm.value[event.type] = event.selected;
    this.updateActivityDetails();
  }
  public relaticsValidation(){
    if(this.activity.relatics_uuid){
      if(this.globals.relaticsColumn.activities){
        let columns;
        const dialogcolumns=[];
        // eslint-disable-next-line prefer-const
        columns = this.globals.relaticsColumn.activities.columns;
        columns.map((element)=>{
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer=dialogcolumns.reduce((ac,a) => ({...ac,[a]:a}),{});
        this.cd.detectChanges();
      }
    }
  }
  public permissionDisabled(){
    if(!this.hasPermission(this.Permission.ActivitiesUpdate)){
      this.activityDetailsForm.get('name').disable();
      this.activityDetailsForm.get('types').disable();
      this.activityDetailsForm.get('group').disable();
      this.activityDetailsForm.get('calendars.title').disable();
      this.activityDetailsForm.get('calendars.start').disable();
      this.activityDetailsForm.get('calendars.end').disable();
      this.activityDetailsForm.get('locations').disable();
    }
  }
  public subprojectSetup(){
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects,'activities:update')
  }
  public setLodedSubproject(){
    this.chipDisabler=[];
    this.chipDisabler=selectedSubprojectSaprator(this.subprojectsSet,this.activity.subprojects)
  }
}
