<!-- Relatics -->
<div class="table-responsive" *ngIf="relatics && relatics_grouped">
  <ng-container *ngFor="let table of relaticsTables">
    <div class="relatics-connection-title"><b>{{ table | specialchartospace | titlecase }}</b></div>
    <table class="table table-hover table-condensed full-width table-relatics-connection">
      <thead>
      <tr>
        <th class="col-xs-2">ID</th>
        <ng-container *ngFor="let column of relaticsColumns[table].attributes">
          <th class="col-xs-3">{{ column | specialchartospace | titlecase }}</th>
        </ng-container>

        <ng-container *ngFor="let column of relaticsColumns[table].properties">
          <th class="col-xs-3">{{ column | specialchartospace | titlecase }}</th>
        </ng-container>

        <ng-container *ngFor="let column of relaticsColumns[table].relations">
          <th class="col-xs-3">{{ column | specialchartospace | titlecase }}</th>
        </ng-container>
        <th class="hidden-xs"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="relatics_grouped[table] && relatics_grouped[table].length === 0">
        <td><span>Geen koppelingen</span></td>
      </tr>
      <ng-container *ngIf="relatics_grouped[table]">
        <tr *ngFor="let instance of relatics_grouped[table]">
          <td>{{ instance.sid }}</td>
          <td *ngFor="let column of relaticsColumns[table].attributes">
            <span *ngIf="instance.data.attributes">{{ instance.data.attributes[column] }}</span></td>
          <td *ngFor="let column of relaticsColumns[table].properties">
            <span *ngIf="instance.data.properties && instance.data.properties[column]">{{ instance.data.properties[column].value }}</span>
          </td>
          <td *ngFor="let column of relaticsColumns[table].relations">
            <ng-container *ngIf="instance.relations && instance.relations[column]">
              <span *ngFor="let relation of instance.relations[column]">
                {{ relation.value }}
              </span>
            </ng-container>
          </td>
          <td>
            <a class="btn btn-xs pull-right" *ngIf="editable"
               title="Verwijder" [matTooltip]="'Verwijder koppeling met Relatics'" (click)="removeRelatics(instance)">
              <i class="material-icons">delete</i>
            </a>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>
