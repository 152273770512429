<div class="modal-header">
    <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
    </span>
</button>
    <h4 class="mb-10 inline-block">{{ 'new' | transloco | capitaliz }} {{ 'permits.label' | transloco }}</h4>
</div>

<form #form="ngForm" (ngSubmit)="submit()">
    <mat-dialog-content>
        <div>
            <mat-form-field class="full-width">
                <mat-label>{{'reusable.title' | transloco}}</mat-label>
                <input matInput type="text" name="name" [(ngModel)]="entity.name" required />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'reusable.description' | transloco}}</mat-label>
                <input matInput type="text" name="description" [(ngModel)]="entity.description">
            </mat-form-field>
            <app-add-connect-themes [mulitple]="true" [editButton]="false" [editable]="false" [type]="themeType" [textMultiple]="'type'" [textSingle]="'type'" (onThemeSelected)="updateSelectedTheme($event)"></app-add-connect-themes>

            <div class="mt-10">
                <app-generic-multiselect-checkbox [placeholder]="'reusable.select_user_change' | transloco" name="users" ngDefaultControl [(ngModel)]="entity.users" name="users" [required]="false" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' users ...'" [uniqueId]="'responsible-users'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let user of users; trackBy: trackByFn" [value]="user">
                        {{ user.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
            </div>

            <mat-form-field class="full-width">
                <mat-label>{{'Deadline' | transloco}}</mat-label>
                <input matInput [matDatepicker]="datePickerStart" [(ngModel)]="entity.deadline" (focus)="datePickerStart.open()" (click)="datePickerStart.open()" name="newDecision_startDate" required>
                <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                <mat-datepicker #datePickerStart></mat-datepicker>
            </mat-form-field>
            <div class="mt-10">
                <app-generic-multiselect-checkbox [placeholder]="'permits.attach_existing_workflow' | transloco" name="workflows" [(ngModel)]="entity.workflows" ngDefaultControl name="users" [required]="false" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' users ...'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let workflow of workflows; trackBy: trackByFn" [value]="workflow">
                        {{ workflow.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
            </div>
        </div>

    </mat-dialog-content>
    <!--end of modal body-->

    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
            <button type="button" mat-stroked-button data-dismiss="modal" [disabled]="isProcessing" (click)="close()">{{'cancel' | transloco | capitaliz}}</button>
            <button type="submit" mat-flat-button [disabled]="isProcessing || form.invalid">
        <span *ngIf="!isProcessing">
        <mat-icon class="material-icons d-text-middle">save</mat-icon>
        {{ 'add' | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
    </button>
        </div>
    </mat-dialog-actions>
    <!--end of modal footer -->
</form>

