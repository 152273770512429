import { translate } from "@jsverse/transloco";
import moment from "moment-timezone";

export function deepCopy(obj: any, recursion: number = 0): any {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  if (moment.isMoment(obj)) {
    return obj.clone();
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr) && recursion < 5000) {
        recursion++;
        copy[attr] = deepCopy(obj[attr], recursion);
      }
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}
export function opecityArraySet(points:any){
  const opecityArray=[];
  points = points.filter((el) => {
    return el != null;
  });
  if(points.length>0){
    points.forEach(element => {
      if(element){
        opecityArray.push(
          {
            data: [{
              'code': element.code,
              'dataLabels':element.dataLabels,
              'name': element.name,
              'stakeholderId': element.stakeholderId,
              'x': element.x,
              'y': element.y,
              'z': element.z,
              'color':element.color,
            }],
            dataLabels: {
              enabled: true,
              color: '#000000',
              style: {
                  fontWeight: 'bold',
                  textOutline: 'none',
                  minSize: 200,
                  maxSize: 400,
                  fontFamily: 'work_sans_regular'
              }
            },
          }
        )
      }
    });
  }else{
    opecityArray.push(
      {
        data: [{
          'x': -200,
          'y': -200,
          'z': 80,
          'color':'#FFF0'
        }],
        dataLabels: {
          enabled: false,
          color: '#000000',
          style: {
              fontWeight: 'bold',
              textOutline: 'none',
              size: '13px',
              fontFamily: 'work_sans_regular'
          }
        },
      }
    )
  }
  return opecityArray;
}
export function complaintType(data:any){
  const complaintTypes=[];
  Object.entries(data).map(el=>{
    el[0] = el[0] === 'public_api' ? 'Public Facing Api' : el[0].charAt(0).toUpperCase() + el[0].slice(1)
    complaintTypes.push({name:el[0],y:el[1]})
  });
  return complaintTypes
}
export function moduleValidation(data:any,modules: any[]){
  if (!Array.isArray(modules)) {
    // Handle the case where modules is not an array, e.g., return false or throw an error
    return false;
  }
  const communication=[];
  for (const module of modules) {
    communication.push(module.module_name);
  }
  if(!communication.includes(data)){
    return false;
  }
    return true;

}
export function forOverPassLayer(data){
  const tag=data.tag;
  const keys=["email","phone","addr:city", "addr:country", "addr:district", "addr:housenumber", "addr:street", "addr:place", "addr:postcode", "addr:state", "admin_level", "amenity", "barrier", "boundary", "building", "building:levels", "from", "height", "highway", "landuse", "maxspeed", "name", "natural", "network", "oneway", "operator", "place", "power", "public_transport", "ref", "restriction", "route", "service", "start_date", "surface", "to", "type", "waterway", "wetland", "wikidata", "wikipedia"];
  const feature=[];
  Object.keys(tag).filter(element=>{
    if(keys.includes(element)){
      feature.push({name:element,value:tag[element]});
    }
  });
  return feature;
}
export function issuesScoreLogic(deadline,impact){
  let score=0;
  let time=0;
  let totalDays=0;
  if (moment(deadline).isBetween(moment().add(1, 'months'), moment().add(3, 'months'))) {
    totalDays=moment(deadline).diff(moment().add(1, 'M'), 'days')
    time=3-(totalDays)/60
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  } else if (moment(deadline).isBetween(moment().add(3, 'months'), moment().add(6, 'months'))) {
      totalDays=moment(deadline).diff(moment().add(3, 'M'), 'days')
      time=2-(totalDays)/90
      score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
      return score
  } else if (moment(deadline).isBetween(moment().add(6, 'months'), moment().add(12, 'months'))) {
      totalDays=moment(deadline).diff(moment().add(6, 'M'), 'days')
      if(totalDays > 185){
        totalDays=185;
      }
      time=1-(totalDays)/185
      score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
      return score
  } else if (moment(deadline).isAfter(moment().add(12, 'months'))) {
    time=0
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  }
    totalDays=moment(deadline).diff(moment(), 'days')
    if(totalDays<0){
      time=4-0/30
      score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
      return score
    }
      time=4-(totalDays)/30
      score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
      return score


}
export function issueSliderScoreLogic(deadline,impact,i) {
  let score=0;
  let time=0;

  if (i <= 30) {
    time=4-(i)/30
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  }
  if (i > 30 && i <= 90) {
    time=3-(i-30)/60
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  }
  if (i > 90 && i <= 180) {
    time=2-(i-90)/90
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  }
  if (i > 180 && i <= 365) {
    time=1-(i-180)/185
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  }
  if (i > 365) {
    time=0
    score=(((1*time)+(2*impact))/((1*4)+(2*4)))*100;
    return score
  }
}
export function dateRangeCalculate(){
  const start=new Date();
  const end=new Date();
  start.setMonth(start.getMonth() - 2);
  end.setMonth(end.getMonth() + 2);
  return [start,end]
}
export function impactScoring(impact){
  switch(impact){
      case'negligible':
      return 0;
      case 'low':
      return 1;
      case 'middle':
      return 2;
      case 'high':
      return 3;
      case 'catastrophic':
      return 4;
  }
}
export function phoneNumberCode(code): string{
  if(code=='+31' || code=='+32'){
    return code
  }
  return '+31'
}
//remove html tags
export function removeHTMLTags(code): string{
   code= code.replace(/<[^>]*>/g, '');
   return code.substring(0,25)
}

//delete confirmation for project level and subproject level
export function deleteConfirmationResponce(data,router,type): string{
  if(router.url.includes('sub')){
    return translate(`${type}.delete_message_for_subproject`);
  }
  return translate(`${type}.delete_message`);
}
export function setContactPersonIdsInPovit(data,person): any{
  data.forEach(element => {
    if(!element.pivot){
      element.pivot = {
        contactmoments_id: person.id,
        contactpersons_id:element.id,
        contactmoments: JSON.parse(JSON.stringify(person))
      }
    }
  });
  return data;
}
export function setActionIdsInPovit(data,person): any{
  return {
    contactmoments_id: person.id,
    contact_momentable_id:data.id,
    contact_momentable_type:"tasks"
  }
}
// contact_momentable_type	"tasks"
