<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
      <app-breadcrumb></app-breadcrumb>
      <button
        type="button"
        class="close no-margin"
        (click)="close()"
        matTooltip="{{ 'close' | transloco | capitaliz }}"
      >
        <span aria-hidden="true">
          <mat-icon class="d-text-middle uil uil-multiply"></mat-icon> </span
        ><span class="sr-only">Close</span>
      </button>
      <div *hasPermission="[Permission.EmployeesDelete]">
        <mat-icon
          (click)="deleteEmployee(employeeCopy)"
          class="d-cursor-hand uil uil-trash delete pull-right contact-persons-delete"
          matTooltip="{{ 'delete' | transloco | capitaliz }}"
        ></mat-icon>
      </div>
    </div>
    <div class="col-xs-12 popup-header-title">
      <h1>
        <strong>{{ "employees.label" | transloco | capitaliz }} </strong
        >{{ name }}
      </h1>
    </div>
  </div>
  <mat-progress-bar *ngIf="!reducer" mode="indeterminate"></mat-progress-bar>
  <div class="popup-body" appScrol l *ngIf="reducer">
    <div class="col-xs-12">
      <h3 class="mb-15">{{ "contact" | transloco | capitaliz }}</h3>
      <div class="col-xs-12 panel z-depth-2 panel-border">
        <form #form="ngForm" (ngSubmit)="submit()">
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12">
                <div class="row">
                  <mat-radio-group
                    [(ngModel)]="employeeCopy.title"
                    name="title"
                    class="full-width"
                    (change)="submit()"
                    [disabled]="
                      reducer.title ||
                      !hasPermission(Permission.EmployeesUpdate)
                    "
                    [matTooltip]="
                      reducer.title ? t('settings.relatic.disable_field') : null
                    "
                  >
                    <mat-radio-button value="mr" class="col-md-2 ml-3">{{
                      "reusable.mr" | transloco
                    }}</mat-radio-button>
                    <mat-radio-button value="mrs" class="col-md-8">{{
                      "reusable.mrs" | transloco
                    }}</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="row">
                  <div class="hidden-xs hidden-sm col-md-2">
                    <mat-icon class="mt-14">person_pin</mat-icon>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5">
                    <mat-form-field
                      [matTooltip]="
                        reducer.first_name
                          ? t('settings.relatic.disable_field')
                          : null
                      "
                    >
                      <mat-label>{{
                        "reusable.first_name" | transloco
                      }}</mat-label>
                      <input
                        matInput
                        (keyup)="trigger($event)"
                        [ngModel]="employeeCopy.first_name | titlecase"
                        (ngModelChange)="(employeeCopy.first_name)"
                        [disabled]="
                          reducer.first_name ||
                          !hasPermission(Permission.EmployeesUpdate)
                        "
                        name="first_name"
                        (change)="submit()"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5">
                    <mat-form-field
                      [matTooltip]="
                        reducer.last_name
                          ? t('settings.relatic.disable_field')
                          : null
                      "
                    >
                      <mat-label>{{
                        "reusable.last_name" | transloco
                      }}</mat-label>
                      <input
                        matInput
                        (keyup)="trigger1($event)"
                        [ngModel]="employeeCopy.last_name"
                        (ngModelChange)="(employeeCopy.last_name)"
                        [disabled]="
                          reducer.last_name ||
                          !hasPermission(Permission.EmployeesUpdate)
                        "
                        name="last_name"
                        (change)="submit()"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="!employeeCopy.last_name"
                      class="text-red mat-int-custome-position"
                      >{{ "employees.lastname_required" | transloco }}</mat-hint
                    >
                  </div>
                </div>

                <div class="row">
                  <div class="hidden-xs hidden-sm col-md-2">
                    <mat-icon class="mt-14 uil uil-users-alt"></mat-icon>
                  </div>
                  <div
                    class="col-xs-12 col-sm-12 col-md-5"
                    [matTooltip]="
                      reducer.stakeholder
                        ? t('settings.relatic.disable_field')
                        : null
                    "
                  >
                    <app-generic-multiselect-checkbox
                      type="stakeholders"
                      [placeholder]="
                        'reusable.selecteer_stakeholder' | transloco
                      "
                      name="stakeholders"
                      [(ngModel)]="employeeCopy.stakeholders"
                      [multiple]="true"
                      [maxChipLength]="20"
                      (ngModelChange)="submit()"
                      [disabled]="
                        reducer.stakeholder ||
                        !hasPermission(Permission.EmployeesUpdate)
                      "
                      [uniqueId]="'select-stakeholders'"
                    >
                      <app-generic-multiselect-checkbox-option
                        *ngFor="let stakeholder of stakeholders"
                        [value]="stakeholder"
                      >
                        {{ stakeholder.name }}
                      </app-generic-multiselect-checkbox-option>
                      <app-generic-multiselect-checkbox-option-new-action
                        type="stakeholder"
                      >
                      </app-generic-multiselect-checkbox-option-new-action>
                      <app-generic-multiselect-search-project
                        entity="stakeholders"
                      >
                      </app-generic-multiselect-search-project>
                    </app-generic-multiselect-checkbox>
                    <!-- <mat-hint *ngIf="!employeeCopy.stakeholders.length" class="text-red mat-int-custome-position">{{'employees.stakeholder_required' | transloco}}</mat-hint> -->
                  </div>
                </div>

                <div class="row">
                  <div class="hidden-xs hidden-sm col-md-2">
                    <mat-icon class="mt-14">department</mat-icon>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-3">
                    <mat-form-field
                      [matTooltip]="
                        reducer.department
                          ? t('settings.relatic.disable_field')
                          : null
                      "
                    >
                      <mat-label>{{
                        "reusable.department" | transloco
                      }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="employeeCopy.department"
                        name="department"
                        (change)="submit()"
                        [disabled]="
                          reducer.department ||
                          !hasPermission(Permission.EmployeesUpdate)
                        "
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5">
                    <mat-form-field
                      [matTooltip]="
                        reducer.function
                          ? t('settings.relatic.disable_field')
                          : null
                      "
                    >
                      <mat-label>{{
                        "reusable.job_title" | transloco
                      }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="employeeCopy.function"
                        name="function"
                        (change)="submit()"
                        [disabled]="
                          reducer.function ||
                          !hasPermission(Permission.EmployeesUpdate)
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="hidden-xs hidden-sm col-md-2">
                    <mat-icon class="mt-14 uil uil-user-square"></mat-icon>
                  </div>
                  <div
                    class="col-xs-12 col-sm-12 col-md-5"
                    [matTooltip]="
                      reducer.phone ? t('settings.relatic.disable_field') : null
                    "
                  >
                    <mat-form-field class="phone">
                      <ngx-mat-intl-tel-input
                        [ngModel]="employeeCopy.phone"
                        [ngModelOptions]="{ standalone: true }"
                        name="businessPhone"
                        autocomplete="off"
                        [preferredCountries]="['nl']"
                        #phone
                        (change)="phoneChange(phone)"
                        [disableControl]="
                        reducer.phone ||
                        !hasPermission(Permission.EmployeesPhoneUpdate)
                      "
                      ></ngx-mat-intl-tel-input>
                    </mat-form-field>
                  </div>
                  <div
                    class="col-xs-12 col-sm-12 col-md-5"
                    [matTooltip]="
                      reducer.phone ? t('settings.relatic.disable_field') : null
                    "
                  >
                    <mat-form-field  class="phone" >
                      <ngx-mat-intl-tel-input
                        [ngModel]="employeeCopy.personal_phone"
                        [ngModelOptions]="{ standalone: true }"
                        name="personalPhone"
                        [preferredCountries]="['nl']"
                        [enablePlaceholder]="true"
                        [enableSearch]="true"
                        (change)="personalPhoneChange(personalPhone)"
                        #personalPhone
                        [disableControl]="
                        reducer.phone ||
                        !hasPermission(Permission.EmployeesPhoneUpdate)
                      "
                        #personalPhoneNumber="ngModel"
                      ></ngx-mat-intl-tel-input>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="hidden-xs hidden-sm col-md-2"></div>
                  <div class="col-xs-12 col-sm-12 col-md-5">
                    <mat-form-field
                      [matTooltip]="
                        reducer.email
                          ? t('settings.relatic.disable_field')
                          : null
                      "
                    >
                      <mat-label>Email</mat-label>
                      <input
                        matInput
                        type="email"
                        email
                        email="true"
                        [(ngModel)]="employeeCopy.email"
                        name="email"
                        (change)="submit()"
                        [disabled]="
                          reducer.email ||
                          !hasPermission(Permission.EmployeesEmailUpdate)
                        "
                      />
                    </mat-form-field>
                    <mat-hint *ngIf="(form && form.controls && form.controls.email && !form.controls.email.valid)" class="text-red mat-int-custome-position">{{'employees.email_required' | transloco}}</mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="hidden-xs hidden-md col-md-2"></div>
                  <div
                    class="col-xs-12 col-sm-12 col-md-10"
                    [ngClass]="{
                      disable_enitiy: !hasPermission(
                        Permission.EmployeesPhoneUpdate
                      )
                    }"
                  >
                    <app-generic-multiselect-checkbox
                      [disabled]="
                        !hasPermission(Permission.EmployeesPhoneUpdate) ||
                        globals.subprojects.length === 0
                      "
                      [parent]="employeeCopy"
                      parentType="employee"
                      class="subprojects"
                      type="subprojects"
                      [maxChipItems]="3"
                      *ngIf="hasModule('subprojects')"
                      [placeholder]="
                        this.globals.projectConfigs[
                          'project.labels.subprojects'
                        ] || 'subprojects.label' | transloco
                      "
                      name="subprojects"
                      [(ngModel)]="employeeCopy.subprojects"
                      [multiple]="true"
                      [maxChipLength]="20"
                      (ngModelChange)="updateSubprojects()"
                      [uniqueId]="'sub-projects'"
                    >
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                      <app-generic-multiselect-checkbox-option
                        *ngFor="let subproject of globals.subprojects"
                        [value]="subproject"
                      >
                        {{ subproject.name }}
                      </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="hidden-xs hidden-md col-md-2"></div>
                  <div class="col-xs-12 col-sm-12 col-md-10">
                    <mat-form-field
                      class="full-width"
                      [matTooltip]="
                        reducer.note
                          ? t('settings.relatic.disable_field')
                          : null
                      "
                    >
                      <mat-label>{{ "reusable.notes" | transloco }}</mat-label>
                      <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="8"
                        name="note"
                        [(ngModel)]="employeeCopy.note"
                        (change)="submit()"
                        [disabled]="
                          reducer.note ||
                          !hasPermission(Permission.EmployeesUpdate)
                        "
                      >
                      </textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-comment-box
            [users]="users"
            type="contactpersons"
            [type_id]="employee.id"
          ></app-comment-box>
        </div>
      </div>
      <h3>{{ "decisions.connections" | transloco | capitaliz }}</h3>
      <div class="col-xs-12 panel z-depth-2 panel-border">
        <div>
          <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
          <app-add-connect-generic
            parentType="contactpersons"
            (onChange)="updateEntities($event)"
            *ngIf="loaded"
            [parent]="employeeCopy"
            [data]="entities"
            [types]="['contactmoments']"
          ></app-add-connect-generic>
        </div>
      </div>
    </div>
  </div>
</div>
