<h1 *ngIf="!selected" class="sidebar-header">Items</h1>
<div *ngIf="searchBar">
    <h3 style="margin-bottom: 5px; margin-top: 3px; margin-left: 3px;">{{'text.search_all_in_map' | transloco}}</h3>
    <div class="panel search-holder" style="border-radius: 15px">
        <i class="uil uil-search uil-icon" style="font-size: 19px; color: #bbb;"></i>
        <input class="search-box" [(ngModel)]="keyword" placeholder="{{'text.search_map_item' | transloco}}" value="" (ngModelChange)='filterLayer(keyword)'>
    </div>
</div>
<div (click)="onBack()" *ngIf="selected && (sidebar.enableItemsLayer['activity'] || sidebar.enableItemsLayer['issue'] || sidebar.enableItemsLayer['stakeholder'] || sidebar.enableItemsLayer['complaints'] || sidebar.enableItemsLayer['requirements'] || openLayerData || Pontential_Stakeholder)"
    class="back-link">
    <span (click)='hideSerch()'>< {{'reusable.back_to_items' | transloco}}</span>
</div>
<mat-accordion *transloco="let t;" [ngClass]="selected ? 'hidden' : ''">
    <mat-expansion-panel [expanded]="!sidebar.enableItemsLayer['activity'] && !sidebar.enableItemsLayer['issue'] && !sidebar.enableItemsLayer['complaints'] && !sidebar.enableItemsLayer['requirements']" *ngIf="sidebar.enableItemsLayer['stakeholder']">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <img class="pull-left group_svg" src="/assets/png/stakeholders.png" alt="Stakeholders" />
                <span *ngIf="!stakeholders.length">{{t('text.no_items_in_list')}}</span>
                <span *ngIf="stakeholders.length ==1">{{stakeholders.length+' '+t('stakeholders.label')}}</span>
                <span *ngIf="stakeholders.length >1">{{stakeholders.length+' '+t('stakeholders.labels')}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <p (click)="onItemClick(f)" (mouseenter)="onItemHover(f)" [ngClass]="f.id.toString()" class="item-title stakeholders" *ngFor="let f of stakeholders">{{f.name}}</p>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="!sidebar.enableItemsLayer['activity'] && !sidebar.enableItemsLayer['complaints'] && !sidebar.enableItemsLayer['requirements'] && !sidebar.enableItemsLayer['stakeholder']" *ngIf="sidebar.enableItemsLayer['issue']">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <img class="pull-left group_svg" src="/assets/png/issues.png" alt="issues" />
                <span *ngIf="!issues.length">{{t('text.no_items_in_list')}}</span>
                <span *ngIf="issues.length ==1">{{issues.length+' '+t('issues.label')}}</span>
                <span *ngIf="issues.length >1">{{issues.length+' '+t('issues.labels')}}</span>

            </mat-panel-title>
        </mat-expansion-panel-header>
        <p (click)="onItemClick(f)" (mouseenter)="onItemHover(f)" [ngClass]="f.id.toString()" class="item-title issues" *ngFor="let f of issues">{{f.name}}</p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="!sidebar.enableItemsLayer['activity'] && !sidebar.enableItemsLayer['issue'] && !sidebar.enableItemsLayer['requirements'] && !sidebar.enableItemsLayer['stakeholder']" *ngIf="sidebar.enableItemsLayer['complaints']">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <img class="pull-left group_svg" src="/assets/png/complaints.png" alt="complaints" />
                <span *ngIf="!complaints.length">{{t('text.no_items_in_list')}}</span>
                <span *ngIf="complaints.length ==1">{{complaints.length+' '+t('complaints.label')}}</span>
                <span *ngIf="complaints.length >1">{{complaints.length+' '+t('complaints.labels')}}</span>

            </mat-panel-title>
        </mat-expansion-panel-header>
        <p (click)="onItemClick(f)" (mouseenter)="onItemHover(f)" [ngClass]="f.id.toString()" class="item-title complaints" *ngFor="let f of complaints">{{f.name}}</p>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="!sidebar.enableItemsLayer['activity'] && !sidebar.enableItemsLayer['issue'] && !sidebar.enableItemsLayer['complaints'] && !sidebar.enableItemsLayer['stakeholder']" *ngIf="sidebar.enableItemsLayer['requirements']">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <img class="pull-left group_svg" src="/assets/png/requirements.png" alt="requirements" />
                <span *ngIf="!decisions.length">{{t('text.no_items_in_list')}}</span>
                <span *ngIf="decisions.length ==1">{{decisions.length+' '+t('decisions.label')}}</span>
                <span *ngIf="decisions.length >1">{{decisions.length+' '+t('decisions.labels')}}</span>

            </mat-panel-title>
        </mat-expansion-panel-header>
        <p (click)="onItemClick(f)" (mouseenter)="onItemHover(f)" [ngClass]="f.id.toString()" class="item-title decisions" *ngFor="let f of decisions">{{f.name}}</p>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="!sidebar.enableItemsLayer['issue'] && !sidebar.enableItemsLayer['complaints'] && !sidebar.enableItemsLayer['requirements'] && !sidebar.enableItemsLayer['stakeholder']" *ngIf="sidebar.enableItemsLayer['activity']">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <img class="pull-left group_svg" src="/assets/png/calendars.png" alt="calendars" />
                <span *ngIf="!calendars.length">{{t('text.no_items_in_list')}}</span>
                <span *ngIf="calendars.length ==1">{{calendars.length+' '+t('activities.label')}}</span>
                <span *ngIf="calendars.length >1">{{calendars.length+' '+t('activities.labels')}}</span>

            </mat-panel-title>
        </mat-expansion-panel-header>
        <p (click)="onItemClick(f)" (mouseenter)="onItemHover(f)" [ngClass]="f.id.toString()" class="item-title calendars" *ngFor="let f of calendars">{{f.name}}</p>
    </mat-expansion-panel>
    <!-- Overpass Api List -->
    <mat-expansion-panel *ngIf="Pontential_Stakeholder && Pontential_StakeholderList">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span *ngIf="!Pontential_StakeholderList.length">{{t('text.no_items_in_list')}}</span>
                <span *ngIf="Pontential_StakeholderList.length >0">{{Pontential_StakeholderList.length+' '+t('potential_stakeholder')}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div *ngFor="let item of overpassGroups">
            <div *ngIf="item.count">
              <span class="item-group-title">{{'maps.overpass_Groups.'+item.type | transloco}}:</span>
              <div *ngFor="let f of Pontential_StakeholderList">
                <p class="item-title-auto d-flex-space" *ngIf="f.group_type==item.type" (mouseenter)="itemOpenlayerHover(f)">
                  <span class="align-set truncate" (click)="openLayerFull(f)">{{f.title}}</span>
                  <span *ngIf="permissionCreate">
                  <span *ngIf="!f.exist"><button class="btn btn-outline-primary color-btn-primary-outline-map" (click)="overPassStakeholderCreation(f)" [matTooltip]="t('maps.add_stakeholder')"><mat-icon class="d-text-middle mat-icon notranslate material-icons mat-icon-no-color">add_circle</mat-icon></button></span>
                  <span *ngIf="f.exist"><button disabled class="btn btn-outline-primary color-btn-primary-outline-map"><mat-icon class="d-text-middle mat-icon notranslate material-icons mat-icon-no-color" [matTooltip]="t('maps.already_created')">check_circle</mat-icon></button></span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
    </mat-expansion-panel>
    <!-- Open Data Layers -->
    <div *ngFor="let item of openLayerData">
        <mat-expansion-panel [expanded]=true class="custom-expand-map mat-elevation-z0" style="margin-top: 10px">
            <mat-expansion-panel-header>
                <mat-panel-title >
                    <div>
                        <h3 style="margin-bottom: 0px;" class="truncate-items-name" *ngIf="!item.data.length">{{t('text.no_items_in_list')}}</h3>
                        <h3 style="margin-bottom: 0px;" class="truncate-items-name" *ngIf="item.data.length >0" [matTooltip]="item.layer | camelCaseToWords">{{item.layer | camelCaseToWords| truncatie: 35}}</h3>
                        <p *ngIf="item.data.length">{{item.data.length}} {{'maps.labels' | transloco }}</p>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="position: absolute; right: 0; top: 40px;">
                <app-batch-overview-setting type="layers" overviewType="stakeholder-overview" modal="layers" [items]="selectionItems" (onloadItem)="loadItemsList($event, item.visibleFeatures)"></app-batch-overview-setting>
            </div>
            <div class="mb-error-set" *ngIf="item.data.length == 4000">
              <mat-hint class="text-red">{{t('maps.layer_limit')}}</mat-hint>
            </div>
            <div style="margin-top: 20px;" *ngIf="item.visibleFeatures.length >0">
                <div style="margin-bottom: 2px; display: flex; align-items: center;">
                    <mat-checkbox style="margin-bottom: 3px;" [(ngModel)]="allSelected" (change)="masterSelected($event, item.layer)"></mat-checkbox>
                    <span style="margin-left: 10px;">{{ 'filter_select_all' | transloco | capitaliz }}</span>
                </div> 
                <mat-divider></mat-divider>                 
                <div style="height: 300px; overflow-y: auto" *ngIf="item.visibleFeatures" (scroll)="onScroll($event,item.layer)">
                    <div *ngFor="let f of item.visibleFeatures;let i=index">
                        <p style="margin-top: 2px; margin-bottom: 2px; display: flex; align-items: center;" class="item-title ">
                            <mat-checkbox style="margin-bottom: 3px;" [(ngModel)]="f.isSelected" (change)="isSelected($event,f)"></mat-checkbox>
                            <span style="margin-left: 10px;" (mouseenter)="itemOpenlayerHoverArcGis(f)" (click)="openLayerArcGisFull(f)" [matTooltip]="f.title">{{f.title | truncatie: 35}}</span>
                        </p>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</mat-accordion>

<app-item-detail #itemdetail (itemDetail)="itemDetailChange($event)"></app-item-detail>
