<div class="color-selector">
  <div class="selected-color" (click)="show = !show" [ngStyle]="{ 'background-color': selectedColor }"></div>
  <div *ngIf="show" class="colors-grid">
    <div class="colors-row">
      <span class="color-box" (click)="selectColor('#FF7669')" style="background-color: #FF7669;">
        <mat-icon *ngIf="selectedColor=='#FF7669'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#F7F050')" style="background-color: #F7F050;">
        <mat-icon *ngIf="selectedColor=='#F7F050'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#F1C40F')" style="background-color: #F1C40F;">
        <mat-icon *ngIf="selectedColor=='#F1C40F'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#136175')" style="background-color: #136175;">
        <mat-icon *ngIf="selectedColor=='#136175'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#4CBDB3')" style="background-color: #4CBDB3;">
        <mat-icon *ngIf="selectedColor=='#4CBDB3'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#525252')" style="background-color: #525252;">
        <mat-icon *ngIf="selectedColor=='#525252'">check</mat-icon>
      </span>
    </div>
    <div class="colors-row">
      <span class="color-box" (click)="selectColor('#003367')" style="background-color: #003367;">
        <mat-icon *ngIf="selectedColor=='#003367'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#1A5C8F')" style="background-color: #1A5C8F;">
        <mat-icon *ngIf="selectedColor=='#1A5C8F'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#4A9ED6')" style="background-color: #4A9ED6;">
        <mat-icon *ngIf="selectedColor=='#4A9ED6'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#753EA2')" style="background-color: #753EA2;">
        <mat-icon *ngIf="selectedColor=='#753EA2'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#DD758F')" style="background-color: #DD758F;">
        <mat-icon *ngIf="selectedColor=='#DD758F'">check</mat-icon>
      </span>
      <span class="color-box" (click)="selectColor('#FDB6C8')" style="background-color: #FDB6C8;">
        <mat-icon *ngIf="selectedColor=='#FDB6C8'">check</mat-icon>
      </span>
    </div>
  </div>
</div>