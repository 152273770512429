<div *transloco="let t">
    <div class="display-flex justify-center">
        <h3 class="modal-title"><strong>{{ t('admin_page.integration') | titlecase}} </strong>{{ t('settings.sharepoint.title') }}</h3>
        <button type="button" class="close " (click)="onNoClick()">
            <span aria-hidden="true">
                <mat-icon class="d-text-middle">close</mat-icon>
            </span>
            <span class="sr-only">Close</span>
        </button>
    </div>
    <div class="row z-index-set mb-10">
        <div class="col-xs-12 mb-5">
            <h5> {{ t('settings.connection') | titlecase}}</h5>
            <div class="subtext mb-5">{{ t('settings.sharepoint.connection_subtext')}}</div>
            <div class="set-border-connection">
                <form #form_integration="ngForm">
                <mat-form-field class="full-width mb-10 mt-15">
                    <input matInput [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="url" pattern="https?://.+" id="url" name="url" [(ngModel)]="configuration['services.sharepoint.url']" (ngModelChange)="setRealm()" placeholder="Sharepoint URL" required>
                </mat-form-field>
                <mat-form-field class="full-width mb-10">
                    <input matInput [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" id="clientId" name="clientId" [(ngModel)]="configuration['services.sharepoint.client_id']" (ngModelChange)="setSharepointConfig()" placeholder="Client ID">
                </mat-form-field>
                <mat-form-field class="full-width mb-10">
                    <input matInput [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" id="secretKey" name="secretKey" [ngModel]="configuration['services.sharepoint.client_secret']" (keyup)="onSecretChange($event)" placeholder="Client secret">
                </mat-form-field>
            </form>
            <div class="display-flex">
                <button
                [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate) || form_integration.invalid"
                [class]="(!sharepointenable) ? 'mb-20 btn btn-primary align-set color-btn-primary-map' : (sharepointenable && errorMessage) ? 'mb-20 btn btn-primary align-set color-btn-primary-map-error ' : 'mb-20 btn btn-primary align-set color-btn-primary-mapa'"
                type="button" (click)='connectService()'>
                    <span *ngIf="!sharepointenable"><mat-icon class="icon-connect mr-5 d-text-middle">add_circle</mat-icon>{{ t('settings.sharepoint.authorize') | capitaliz}}</span>
                    <span *ngIf="sharepointenable && errorMessage"><mat-icon class="icon-connect mr-5 d-text-middle">error</mat-icon>{{ t('settings.connect_error') | capitaliz}}</span>
                    <span *ngIf="sharepointenable && !errorMessage"><mat-icon class="icon-connect mr-5 d-text-middle">remove_circle</mat-icon>{{ t('settings.disable') | capitaliz}}</span>
                </button>
                <div class="align-set display-flex prt-10" *ngIf="sharepointenable && errorMessage">
                    <mat-icon class="d-text-middle mr-5">error</mat-icon>
                    <span class="error-message align-set"> {{ t('settings.not_connected')}}</span>
                </div>
                <div class="align-set display-flex prt-10" *ngIf="showPopupBlockedMessage">
                    <mat-icon class="d-text-middle mr-5">error</mat-icon>
                    <span class="error-message align-set"> {{t('settings.block_popups')}}</span>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row z-index-set mb-10" *ngIf="(sharepointenable && !errorMessage)">
        <div class="col-xs-12 mb-5">
            <h5> {{ t('settings.sharepoint.select_folder')}}</h5>
            <div class="subtext mb-5">{{ t('settings.sharepoint.select_folder_subtext')}}</div>
            <div class="set-border-connection">
                <mat-progress-bar *ngIf="!directories" class="mtb-10" mode="indeterminate"></mat-progress-bar>
                <div class="input-group full-width" *ngIf="directories">
                    <mat-form-field *ngIf="directories" class="full-width mt-10">
                        <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [placeholder]="'settings.sharepoint.select_folder' | transloco | capitaliz" [(value)]="configuration['services.sharepoint.directory']" (valueChange)="onClickSetSharePointDirectories()" required>
                            <mat-option *ngFor="let directory of directories" [value]="directory">
                                {{directory}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <p *ngIf="migrationProcess"><span>
                <mat-icon *ngIf="migrationStatus && migrationCompleted" class="successfully-message d-text-middle">check_circle</mat-icon>
                {{this.migrationMessage}}
            </span></p>
        </div>
    </div>
</div>
