import { ID } from '@datorama/akita';
import {Location} from '../../locations/state/location.model';
import {Stakeholder} from '../../stakeholders/state/stakeholder.model';
import {Log} from '../../contact-moments/state/log.model';
import {Resident} from '../../residents/state/resident.model';
import {Issue} from '../../issues/state/issue.model';
import {Decision} from '../../requirements/state/decision.model';
import {Activity} from '../../activities/state/activity.model';
import {Middel} from '../../content/state/middel.model';
import {Action} from '../../tasks/state/action.model';
import {generateColor} from '../../../core/util/generateColor';
import {Complaint} from '../../complaints/state/complaint.model';
declare let ol: any;
export type Subproject = {
  id?: ID,
  name: string,
  code: string,
  locations: Location,
  all?: boolean,
  stakeholders?: Stakeholder[],
  residents?: Resident[],
  logs?: Log[],
  issues?: Issue[],
  decisions?: Decision[],
  complaints?: Complaint[],
  activities?: Activity[],
  middel?: Middel[],
  actions?: Action[],
  color?: string,
  users?:[],
  layers?: any[],
  features?: any[],
};

export function createSubproject(name: string, location: Location): Subproject {
  return {
    name: name,
    locations: location,
    color: generateColor(name),
    code: 'dummy',
    layers: [],
  };
}
/*
  Validate If the Coord are Polygon or Multipolygon
*/
export function getGeometryType(coords){
  if (!Array.isArray(coords)) {
    return null;
  }

  if (coords.length === 0) {
    return null;
  }

  if (Array.isArray(coords[0]) && Array.isArray(coords[0][0]) && Array.isArray(coords[0][0][0])) {
    // It's a MultiPolygon
    return 'MultiPolygon';
  } else if (Array.isArray(coords[0])) {
    // It's a Polygon
    return 'Polygon';
  }

  return null;
}
/*
  Set Subproject Features Geometery
*/
export function geometeryChangeRingFormate(coordinates) {
  const mapFormat = [];
  for (const geom of coordinates) {
    for (const poly of geom) {
      mapFormat.push([poly[0], poly[1]]);
    }
  }
  const transformedRings = mapFormat.map((geom) =>ol.proj.transform(geom, 'EPSG:3857', 'EPSG:4326'));
  return [transformedRings];
}
/*
  Set Sub-Layers Response Parameters
*/
export function setSubLayersResponseParameters(layer: any, subLayer: any): any {
  const response = [];
  subLayer.forEach((item, index) => {
    response.push({
      id: layer.id+index,
      main_id: layer.id,
      name: layer.name,
      layer_name: item.name,
      layer_id: item.id,
      type: item.type,
      geometry_type: item.geometryType,
      url: layer.url,
      owner: layer.owner,
      enabled: layer.enabled
    });
  });
  return response;
}
/*
  Set Subproject Features Response and Set these Coordinates
*/
export function setSubprojectsFeatureResponse(type: string, features: any, layername: string, open?: any, layer?: any): any{
  const response = [];
  switch(type) {
    case 'arcGis':
      features.forEach((feature, index) => {
        if(feature.attributes.FID) {
          response.push({
            id: index,
            type: type,
            credentials: true,
            geomType: getGeometryType(geometeryChangeRingFormate(feature.geometry ? feature.geometry.rings : [])),
            id_field: 'FID',
            layer_id: feature.attributes.FID,
            layer_name: layername,
            main_layer_id: layer.main_id,
            sub_layer_id: layer.layer_id,
            sub_layer_name: layer.layer_name,
            title: feature.attributes.name ? feature.attributes.name : (feature.attributes.naam ? feature.attributes.naam : feature.attributes.Naam ? feature.attributes.Naam : `${feature.attributes.FID }-${ layername}`),
            geometery: geometeryChangeRingFormate(feature.geometry ? feature.geometry.rings : []),
          })
        }
      });
      break;
    case 'openData':
      features.forEach((feature, index) => {
        if(feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
          if(getGeometryType(feature.geometry ? feature.geometry.coordinates.length === 1 ? feature.geometry.coordinates[0] : feature.geometry.coordinates : []) === 'Polygon') {
            response.push({
              id: index,
              type: type,
              credentials: open ? open.credentials ? open.credentials.username ? true : false : false : false,
              geomType: getGeometryType(feature.geometry ? feature.geometry.coordinates.length === 1 ? feature.geometry.coordinates[0] : feature.geometry.coordinates : []),
              id_field: 'FID',
              layer_id: feature.properties.fid ? feature.properties.fid : feature.properties.FID ? feature.properties.FID : index,
              layer_name: layername,
              title: feature.properties.name ? feature.properties.name : feature.properties.naam ? feature.properties.naam : feature.properties.Naam ? feature.properties.Naam : feature.properties.fid ? `${feature.properties.fid }-${ layername}` : feature.properties.FID ? `${feature.properties.FID }-${ layername}` : `${index }-${ layername}`,
              geometery: feature.geometry ? feature.geometry.coordinates.length === 1 ? feature.geometry.coordinates[0] : feature.geometry.coordinates : [],
            })
          }
        }
      });
      break;
  }
  return response;
}
