import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-service-level-agreement-modal',
  templateUrl: './service-level-agreement-modal.component.html',
  styleUrls: ['./service-level-agreement-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceLevelAgreementModalComponent implements OnInit {

  public priority:any={};

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // eslint-disable-next-line no-empty-function
    ) { }

  ngOnInit(): void {
    if(!this.data){
      this.priority={
        sla:{
          priorities:{
            urgent:0,
            high: 0,
            medium: 0,
            low: 0
          }
        }
      }
    }else{
      this.priority=this.data
    }
  }
  //modal close function
  public close(): void{
    this.dialogRef.close();
  }
  //on submit priorty
  public submit(): void{
    this.dialogRef.close(this.priority)
  }
}
