import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { WorkflowService } from 'src/app/core/service/api/workflow.service';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-verify-dependency-change',
  templateUrl: './verify-dependency-change.component.html',
  styleUrls: ['./verify-dependency-change.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyDependencyChangeComponent implements OnInit {

  displayedColumns: string[] = [];
  loading: boolean = false;
  isProcessing: boolean = false;
  klantwensen = new MatTableDataSource([]);

  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: {dependents: any, newEndDate: any}, public dialogRef: MatDialogRef<VerifyDependencyChangeComponent>, private _workflowService: WorkflowService, public globals: Globals) { }

  ngOnInit(): void {
    this.displayedColumns = ['id', 'title', 'change', 'deadline'];
    this.klantwensen = this.data.dependents;
  }

  submit() {
    this.data.dependents.forEach( item => {
      item.timestamp = item.newTimestamp;
    })
    this._workflowService.batchApprove({tasks: this.data.dependents}).subscribe( res => {
      showFeedbackSaved();
      this.dialogRef.close(res);
    })
  }

  close(){
    this.dialogRef.close();
  }
}
