import {Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DynamicService {
  searchText: Subject<string> = new Subject<string>();
  allLogs: any

  public getSearchText(value: string) {
    this.searchText.next(value);
  }

  // public getAllLogs(data: any) {
  //   this.allLogs.next(data);
  // }

}
