import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ProjectConfigs} from './project-configs.model';

export interface ProjectConfigsState extends EntityState<ProjectConfigs>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'project-configs' })
export class ProjectConfigsStore extends EntityStore<ProjectConfigsState, ProjectConfigs> {

  constructor() {
    super();
  }

  public set(projectConfigs: [ProjectConfigs]): void {
    super.set(projectConfigs);
  }

}

