import {HttpHeaders, HttpParams} from "@angular/common/http";

export class WithParams {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  });

  private _with: string[];

  constructor(withs: string[] = []) {
    this._with = withs;
  }

  public with(relations: string[]): WithParams {
    this._with = relations;
    return this;
  }

  get(extraParams?: HttpParams): { headers: HttpHeaders, params: HttpParams } {
    let params = new HttpParams();

    if (this._with.length > 0) {
      params = params.append('with', this._with.join(','))
    }

    if (extraParams && extraParams.keys().length > 0) {
      extraParams.keys().forEach(key => params = params.append(key, extraParams.get(key)));
    }

    return {
      headers: this.headers,
      params: params
    };
  }
}
