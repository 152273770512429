import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { MatTabGroup } from '@angular/material/tabs';
import { DynamicService } from 'src/app/core/service/common/dynamic.service';
import { Globals } from 'src/app/globals';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { EmployeesService } from 'src/app/core/service/api/employees.service';
import { OutlookService } from 'src/app/core/service/api/outlook.service';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInjector } from 'src/app-injector';
import { UndoSnackbarComponent } from '../../material/undo-snackbar/undo-snackbar.component';
import { translate } from '@jsverse/transloco';
import { HttpClient, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { showFeedbackSaved, showFeedback } from 'src/app/core/util/notification';
import { catchError, Observable, throwError } from 'rxjs';
interface EmailAddress {
  name: string;
  address: string;
}

interface Message {
  id: string;
  createdDateTime: string;
  body: {
    contentType: string;
    content: string;
  };
  bodyPreview: string;
  categories: string[];
  changeKey: string;
  conversationId: string;
  conversationIndex: string;
  flag: {
    flagStatus: string;
  };
  from: {
    emailAddress: EmailAddress;
  };
  hasAttachments: boolean;
  importance: string;
  inferenceClassification: string;
  internetMessageId: string;
  isDeliveryReceiptRequested: boolean;
  isDraft: boolean;
  isRead: boolean;
  isReadReceiptRequested: boolean;
  lastModifiedDateTime: string;
  parentFolderId: string;
  receivedDateTime: string;
  replyTo: EmailAddress[];
  sender: {
    emailAddress: EmailAddress;
  };
  toRecipients: EmailAddress[];
  ccRecipients: EmailAddress[];
  sentDateTime: string;
  subject: string;
  webLink: string;
}
@Component({
  selector: 'app-import-email-modal',
  templateUrl: './import-email-modal.component.html',
  styleUrls: ['./import-email-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportEmailModalComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  searchText: string = ''; // Search query
  tabIndexCom: number = 0
  public loadingSearch: boolean = false;
  tabs = [
    { label: 'settings.outlook.contact_moments_overview.inbox' },
    { label: 'settings.outlook.contact_moments_overview.outbox' },
  ];

  messages: Message[] = [];
  disabledStates: boolean[] = [];
  loadingStates: boolean[] = [];
  showPanel: number | null = null;
  allContactmoment: any;
  loader: boolean = false
  emailArray = [];
  paginator = new PaginationParams(1, 10)
  constructor(
    public pubsub: PubsubService,
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<any>,
    private dynamicService: DynamicService,
    public globals: Globals,
    private outlookService: OutlookService,
    private _logsService: LogsService,
    public _employeesService: EmployeesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private _xsrfTokenExtractor: HttpXsrfTokenExtractor,
    ) {
      // empty consturctor lint prevent
    }

  ngOnInit(): void {
    if(this.data) {
      this.dynamicService.allLogs = this.data
    }
  }

  ngAfterViewInit() {
    this.getAllMessages();
  }

  tabChange() {
    this.getAllMessages();
  }

  getSelectedTabIndex(): number {
    if (this.tabGroup) {
      return this.tabGroup.selectedIndex;
    }
    return -1;
  }


  search(value: string) { // Update searchText property when input value changes
    this.dynamicService.getSearchText(value)
  }

  getAllMessages() {
    this.loader = true;
    const tab = this.tabGroup.selectedIndex === 1? 'SentItems': 'Inbox';
    this.outlookService.getInboxMessages(this.paginator, tab).subscribe(
      (response) => {
        if (response['@odata.nextLink'] && this.paginator._keyword) {
          this.paginator._nextLink = response['@odata.nextLink'];
          this.paginator.total = 10;
        }
        this.paginator.total = response['@odata.count']? response['@odata.count'] : 10;
        this.paginator.page = 1;
        this.messages = response.value; // Assuming messages are returned in 'value' property

        this.findDisabledStates(this.dynamicService.allLogs)
        this.loader = false
        this.cd.detectChanges();
      },
      (error) => {
        console.error('Error fetching messages:', error);
      }
    );
  }




  public getServerPage(event?: PageEvent) {
    this.paginator.paginate(event);
    this.getAllMessages();
  }

  expandPanel(index: number): void {
    this.showPanel = this.showPanel === index ? null : index;
  }

  addEmailToArray(email, adName) {
    const parts = adName.split('@')[0].split('.');
    let firstName = '';
    let lastName = '';

    if (parts.length >= 1) {
      firstName = parts[0];
    }

    if (parts.length === 2) {
      lastName = parts[1];
    }
    const name = firstName + lastName
    this.emailArray.push({ email, name });
  }

  createContactmoment(log: Message, i) {
    // Add from email
    this.addEmailToArray(log.from.emailAddress.address, log.from.emailAddress.name);

    log.toRecipients.forEach((recipient: any) => {
      this.addEmailToArray(recipient.emailAddress.address, recipient.emailAddress.name);
    });

    // Add ccRecipients emails
    log.ccRecipients.forEach((recipient: any) => {
      this.addEmailToArray(recipient.emailAddress.address, recipient.emailAddress.name);
    });
    if (this.disabledStates[i]) {
      // If already disabled, return
      return;
    }
    this.disabledStates[i] = true
    this.loadingStates[i] = true; // Show loader and hide plus-circle
    const emails = this.emailArray;
    this.emailArray = [];
      // Create employees and handle attachments once they are created
    this.createEmployee(emails, log, i);

  }

  getMessageAttachments(log: Message, employees: any, i) {
    this.outlookService.getSafeHtmlEmail(log.id).subscribe( res => {
      log.body.content = res.body.content;
      this.outlookService.getMessageAttachments(log.id).subscribe(attachments => {
        const files = attachments.value;
        if(files && files.length > 0 && files[0].contentType === 'message/rfc822') {
          this.outlookService.downloadAttachment(log.id, files[0].id).subscribe(blob => {
            // this.outlookService.saveAttachment(blob, 'email-message.eml');
            this.uploadDocuments(blob).subscribe((uploadedFiles: any) => {
              // After attachments are uploaded, proceed to create the log
              this.createLog(employees, log, uploadedFiles, i);
            }, error => {
              this.createLog(employees, log, null, i); // If attachment upload fails, create the log without them
            });
          });
        } else {
  
          if (files && files.length > 0) {
            // If there are attachments, upload them first
  
            this.uploadDocuments(files).subscribe((uploadedFiles: any) => {
              // After attachments are uploaded, proceed to create the log
              this.createLog(employees, log, uploadedFiles, i);
            }, error => {
              this.createLog(employees, log, null, i); // If attachment upload fails, create the log without them
            });
          } else {
            // No attachments, just create the log
            this.createLog(employees, log, null, i);
          }
        }
  
      }, error => {
        console.error('Error fetching attachments:', error);
        this.createLog(employees, log, null, i); // Create log even if fetching attachments fails
      });

    })
  }

  uploadDocuments(attachments): Observable<any> {
    const formData = new FormData();
    if(attachments && attachments.length) {
      attachments.forEach((attachment, index) => {
        const blob = this.base64ToBlob(attachment.contentBytes, attachment.contentType);
        formData.append(`files[${index}]`, blob, attachment.name);
      });
    } else {
      formData.append(`files[0]`, attachments, 'eml');
    }


    // Return an Observable for the file upload
    return this.http.post('/api/documents/upload', formData).pipe(
      catchError((error) => {
        // Handle the error, for example by logging it and/or displaying a message
        // showFeedback(error.error.message, 'error', null, false);

        return throwError(() => showFeedback(error.error.message, 'error', null, false));
      })
    );

  }

  // Helper function to convert base64 string to Blob
  private base64ToBlob(base64: string, contentType: string = ''): Blob {
    const byteCharacters = atob(base64); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  createEmployee(emails, log, i) {
    this._employeesService.createEmailEmployees(emails)
      .subscribe(
        (employees: any) => {
          // remove the users form employees array that are the project users already
          employees = employees.filter(employee => employee.is_removed !== false);
          this.getMessageAttachments(log, employees, i);
        })
  }

  createLog(employees: any, log: Message, uploadedFiles: any, i) {
    const stakeholders = [];
    if (employees && employees.length) {
      employees.forEach(employee => {
        if (employee && employee.stakeholders && Array.isArray(employee.stakeholders) && employee.stakeholders.length > 0) {
            employee.stakeholders.forEach(stakeholder => stakeholders.push(stakeholder));
        }
      })
    }

    const logData = {
      users_id: this.globals.user.id,
      message_id: log.id,
      contact_moment_type: 'outlook',
      title: log.subject,
      description: log.body.content,
      contact_moment_timestamp: log.receivedDateTime,
      contactpersons: employees,
      stakeholders: stakeholders,
      documents: uploadedFiles || []
    }
    this._logsService.submitLog(logData)
      // .pipe(finalize(() => this.disabledStates[i] = true))
      .subscribe(
        (log: any) => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:log,
                message: translate('error.stored'),
                type:'openItems',
                item:'contactmomenten',
                url:'stakeholders/contactmomenten'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          this.loadingStates[i] = false;
          this.cd.detectChanges();
        })
  }

  findDisabledStates(originalMessages): void {
    this.messages.forEach((message, index) => {
      const matchedOriginalMessage = originalMessages.find(originalMessage => originalMessage.message_id === message.id);
      this.disabledStates[index] = !!matchedOriginalMessage;
    });
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }


  sendToBackend(extraParams: any) {
    console.log('File uploaded payload:', extraParams);
  }
}
