<div class="row popup-container" *transloco="let a">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
          <mat-icon class="d-text-middle uil uil-multiply"></mat-icon>
        </span><span class="sr-only">Close</span></button>
            <div *hasPermission="[Permission.DecisionsDelete]">
                <mat-icon *ngIf="decision" (click)="deleteDecision(decision)" class="d-cursor-hand uil uil-trash delete pull-right requirements-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}">
                </mat-icon>
            </div>
            <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
                <button  *ngIf="decision" type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
                   <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
                </button>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1><strong>{{ 'decisions.label' | transloco | capitaliz }} </strong>{{name}}</h1>
        </div>


    </div>
    <mat-progress-bar *ngIf="!decision && !reducer" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="decision && reducer">
        <app-workflow-generic *ngIf="decision && decision.workflows && decision.workflows.length && hasModule('workflow')" [margin]="false" parentType="requirements" [workflows]="decision.workflows" [permitId]="decision.id"></app-workflow-generic>
        <!-- left col -->
        <div class="col-md-7 col-xs-12" *transloco="let t; read: 'decisions'">
            <h3>{{ 'decisions.connections' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component">
                <div>
                    <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                    <app-add-connect-generic (onChange)="updateEntities($event)" parentType="requirements" *ngIf="loaded" [parent]="decision" [data]="entities" [types]="['issues','requirements_by', 'complaints', 'tasks','contactmoments','documents','permits','layers']"></app-add-connect-generic>
                </div>
            </div>

            <!-- Feasibility -->
            <h3>{{ 'decisions.feasibility' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border">
                <div>
                    <mat-form-field class="col-xs-12" [matTooltip]="reducer.impact ? a('settings.relatic.disable_field') : null">
                      <mat-label>{{ t('impact') | capitaliz }}</mat-label>
                        <textarea matInput type="text" name="impact" [(ngModel)]="decision.impact" (change)="updateDecision()"[disableControl]="reducer.impact || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                    </mat-form-field>

                    <mat-form-field class="col-xs-12" [matTooltip]="reducer.feasibility ? a('settings.relatic.disable_field') : null">
                        <mat-label>{{ t('feasibility') | capitaliz }}</mat-label>
                        <textarea matInput type="text" name="feasibility" [(ngModel)]="decision.feasibility" (change)="updateDecision()" [disableControl]="reducer.feasibility || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                    </mat-form-field>

                </div>
            </div>
            <!-- Acceptance -->
            <h3>{{ 'decisions.acceptance' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border">
                <div>
                    <app-show-more>
                        <div summary>
                            <mat-form-field class="col-xs-12">
                              <mat-label>{{ t('advice') | capitaliz }}</mat-label>
                                <textarea matInput type="text" name="advice" [(ngModel)]="decision.advice" (change)="updateDecision()" [disableControl]="!hasPermission(Permission.DecisionsUpdate)"></textarea>
                            </mat-form-field>
                        </div>

                        <div content>
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.motivation ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ t('motivation') | capitaliz }}</mat-label>
                                <textarea matInput type="text" name="motivation" [(ngModel)]="decision.motivation" (change)="updateDecision()" [disableControl]="reducer.motivation || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                            </mat-form-field>
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.note ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ 'decisions.note' | transloco | capitaliz }}</mat-label>
                                <textarea matInput type="text" name="note" [(ngModel)]="decision.note" (change)="updateDecision()" [disableControl]="reducer.note || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                            </mat-form-field>
                        </div>
                    </app-show-more>
                </div>
            </div>

            <!-- Guarantee table-->
            <h3 class="mb-15 text-capitalize">{{ 'decisions.guarantee' | transloco }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border">
                <div class="table-responsive">
                    <app-show-more>
                        <div summary>
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.requirement_concept ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ 'decisions.requirement_concept' | transloco | capitaliz}}</mat-label>
                                <textarea matInput type="text" name="requirement_concept" [(ngModel)]="decision.requirement_concept" (change)="updateDecision()" [disableControl]="reducer.requirement_concept || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                            </mat-form-field>
                        </div>

                        <div content>
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.vv_information ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ 'decisions.vv_info' | transloco | capitaliz }}</mat-label>
                                <textarea matInput type="text" name="vv_information" [(ngModel)]="decision.vv_information" (change)="updateDecision()" [disableControl]="reducer.vv_information || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                            </mat-form-field>
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.guarantee ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ 'decisions.guarantee' | transloco | capitaliz }}</mat-label>
                                <textarea matInput type="text" name="guarantee" [(ngModel)]="decision.guarantee" (change)="updateDecision()" [disableControl]="reducer.guarantee || !hasPermission(Permission.DecisionsUpdate)"></textarea>
                            </mat-form-field>
                        </div>
                    </app-show-more>
                </div>
            </div>

            <!-- Manual contact information -->
            <h3 *ngIf="decision.entry_type === 'smart_reporting'">{{'reusable.contact_info' | transloco}}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border" *ngIf="decision.entry_type === 'smart_reporting'">
                <div class="col-xs-12">
                    <app-multiselect-checkbox #employeeMultiselect type="contactpersons" [placeholder]="'reusable.selecteer_contactpersoon' | transloco" name="employees" [(ngModel)]="decision.contactpersons" (onStakeholdersUpdated)="onStakeholdersUpdated($event)" valueType="object" [multiple]="false" (ngModelChange)="employeeSet()">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                            <app-generic-multiselect-checkbox-option *ngFor="let employee of employees" [value]="employee">
                            {{ employee.first_name }} {{ employee.last_name }}
                            </app-generic-multiselect-checkbox-option>
                        <app-generic-multiselect-search-project entity="contactpersons"></app-generic-multiselect-search-project>
                    </app-multiselect-checkbox>
                </div>
                <div class="col-xs-12">
                    <app-generic-multiselect-checkbox type="stakeholders" [ngClass]="{'disable_enitiy': reducer.stakeholder}" #stakeholderMultiselect [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" [(ngModel)]="decision.stakeholders" [multiple]="true"
                        (ngModelChange)="stakeholderSet()"  [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [matTooltip]="reducer.stakeholder ? t('settings.relatic.disable_field') : null">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                            {{ stakeholder.name }}
                        </app-generic-multiselect-checkbox-option>
                        <app-generic-multiselect-checkbox-option-new-action type="stakeholder"></app-generic-multiselect-checkbox-option-new-action>
                        <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
                    </app-generic-multiselect-checkbox>
                </div>
                <div *ngIf="decision.manual_contact_name">
                    <h4>{{'Manual Contact Information'}}</h4>
                    <div class="contact-row ">
                        <div class="col-xs-4 inline-flex d-cursor-hand">
                            <mat-icon class="ashgrey">person_pin</mat-icon>
                            <span class="ml-5">{{ decision.manual_contact_name | truncatie }}</span>
                        </div>
                        <ng-container>
                            <div class="col-xs-4 inline-flex">
                                <mat-icon class="ashgrey">email</mat-icon>
                                <span class="ml-5" *ngIf="decision.manual_contact_email">
                                    <a matTooltip="{{'reusable.reply_to_notification' | transloco}}">
                                        {{ decision.manual_contact_email | truncatie }}
                                    </a>
                                </span>
                                <span class="ml-5" *ngIf="!decision.manual_contact_email"><i>{{'reusable.unknown' | transloco}}</i></span>
                            </div>
                        </ng-container>
                        <ng-container>
                            <div class="col-xs-3 inline-flex">
                                <mat-icon class="ashgrey">phone</mat-icon>
                                <span class="ml-5" *ngIf="decision.manual_contact_telephone">
                                    <a>
                                        {{ decision.manual_contact_telephone }}
                                    </a>
                                </span>
                                <span class="ml-5" *ngIf="!decision.manual_contact_telephone"><i>{{'reusable.unknown' | transloco}}</i></span>
                            </div>
                        </ng-container>
                        <div class="col-xs-1 inline-flex" style="justify-content: center;">
                            <i class="uil uil-pen d-flex mr-1" (click)="editContact()" style="font-size:22px !important; color: blue; cursor: pointer"></i>
                            <i class="uil uil-plus-circle d-flex" (click)="addManualInformationAsContactperson()" style="font-size:22px !important; color: blue; cursor: pointer"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right col -->
        <div class="col-md-5 col-xs-12" *transloco="let t; read: 'decisions'">
            <h3>{{ 'status' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border">
                <mat-progress-bar *ngIf="!reducer" mode="indeterminate"></mat-progress-bar>
                <div *ngIf="reducer">
                    <!-- Status -->
                    <div class="px-1 d-flex" style="justify-content: space-evenly; align-items: center;">
                        <div >{{ 'decisions.label' | transloco | capitaliz }} {{ 'status' | transloco | capitaliz }}</div>

                        <div style="text-align: right; padding:0px; flex:1">
                            <div *ngIf="integrationType.chatShow">
                                <div *ngIf="!integrationsEnabled" class="disableDiv p-relative" [matTooltip]="['settings.'+integrationType.type+'.not_access' | transloco]">
                                    <button disabled=true (click)="openChat()" type="button" class="span-bouwapp"><img [src]='integrationType.chatIcon' class="integration-chat">{{'settings.open_chat'| transloco}}</button>
                                    <span [class]="decision.source_status === 'open' ? 'on badge status r-0' : 'off badge status text-black r-0'"><i [class]="decision.source_status === 'open' ? 'uil uil-comment-dots' : 'uil uil-check'"></i></span>
                                </div>
                                <div *ngIf="integrationsEnabled" class="p-relative">
                                    <button (click)="openChat()" type="button" class="span-bouwapp"><img [src]='integrationType.chatIcon' class="integration-chat">{{'settings.open_chat'| transloco}}</button>
                                    <span [class]="decision.source_status === 'open' ? 'on badge status r-0' : 'off badge status text-black r-0'"><i [class]="decision.source_status === 'open' ? 'uil uil-comment-dots' : 'uil uil-check'"></i></span>
                                </div>
                            </div>
                        </div>

                        <div class="decision-status">
                            <span [class]="getStatusClass(decision.status)" (click)="showStatusField = !showStatusField" *transloco="let t; read: 'decisions'">
                                {{ t(decision.status) | capitaliz}}
                                    <i *ngIf="!showStatusField" class="d-text-middle uil uil-arrow-down unicon-icon mat-icon"></i>
                                    <i *ngIf="showStatusField" class="d-text-middle uil-arrow-up unicon-icon mat-icon"></i>
                            </span>
                        </div>
                    </div>

                    <mat-form-field *ngIf="showStatusField" class="col-xs-12" id="status" [matTooltip]="reducer.status ? a('settings.relatic.disable_field') : null">
                      <mat-label>{{ t('status') | capitaliz }}</mat-label>
                        <mat-select [(value)]="decision.status" (valueChange)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsStatusUpdate) || reducer.status">
                            <mat-option value="initial">{{ 'decisions.initial' | transloco | capitaliz }}</mat-option>
                            <mat-option value="gehonoreerd">{{'decisions.gehonoreerd' | transloco | capitaliz}}</mat-option>
                            <mat-option value="afgewezen">{{'decisions.rejected' | transloco | capitaliz}}</mat-option>
                            <mat-option value="expired">{{ 'decisions.expired' | transloco | capitaliz }}</mat-option>
                            <mat-option value="next_phase">{{ 'decisions.next_phase' | transloco | capitaliz }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Phases -->
                    <ng-container *ngIf="showStatusField && decision.status === 'next_phase'">
                      <app-add-connect-themes [mulitple]="false" [editButton]="true" [buttonText]="'edit_phase'" [disabled]="!hasPermission(Permission.DecisionsUpdate)" [editable]="false" class="col-xs-12" [textMultiple]="'decisions.phases' | transloco" [textSingle]="'decisions.phase' | transloco" [type]="requirementsPhases" [selectedTheme]="selectedPhases" (onThemeSelected)="updateSelectedPhases($event)"></app-add-connect-themes>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <app-comment-box [users]="users" type="requirements" [type_id]="decision.id"></app-comment-box>
                </div>
            </div>
            <h3 class="mb-15">Algemeen</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border">
                <form name="wishForm" (ngSubmit)="updateDecision()" #form_editDecision="ngForm">
                    <app-show-more [text]="t('show_more_text') | capitaliz">
                        <div summary>
                            <!-- Title -->
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.title ? a('settings.relatic.disable_field') : integrationType.chatShow ? a('settings.'+integrationType.type+'.not_access') : null">
                              <mat-label>{{ t('title') | capitaliz }}</mat-label>
                                <input matInput type="text" name="title" [(ngModel)]="decision.title" (change)="updateDecision()" [disableControl]="integrationType.chatShow || reducer.title || !hasPermission(Permission.DecisionsUpdate)" />
                            </mat-form-field>

                            <!-- Description -->
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.description ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ t('description') | capitaliz }}</mat-label>
                                <textarea matInput type="text" name="description" [disabled]="decision.text_status == 'definitive' || reducer.description  || !hasPermission(Permission.DecisionsUpdate)" [(ngModel)]="decision.description" (change)="updateDecision()"></textarea>
                            </mat-form-field>

                            <!-- Status -->
                            <mat-form-field class="col-xs-12" [matTooltip]="reducer.text_status ? a('settings.relatic.disable_field') : null">
                              <mat-label>{{ t('description_status') | capitaliz }}</mat-label>
                                <mat-select [(value)]="decision.text_status" (valueChange)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate) || reducer.text_status">
                                    <mat-option value="concept">Concept</mat-option>
                                    <mat-option value="definitive">{{ 'decisions.definitive' | transloco | capitaliz }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- Users  -->
                            <div [ngClass]="{'disable_enitiy': (!hasPermission(Permission.DecisionsUpdate))}">
                                <app-generic-multiselect-checkbox [chipDisabler]="chipDisablerUser" [disabled]="!hasPermission(Permission.DecisionsUpdate)" *ngIf="userload" [parent]="decision" parentType="decisions" class="col-xs-12" [placeholder]="'reusable.select_user_change' | transloco" name="user"
                                    [(ngModel)]="decision.users" [multiple]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('reusable.accounthouders' | transloco)+' ...'" (ngModelChange)="updateDecision()" [uniqueId]="'responsible-users'">
                                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                    </app-generic-multiselect-checkbox-option-all>
                                    <app-generic-multiselect-checkbox-option *ngFor="let user of users" [value]="user">
                                        {{ user.name }}
                                    </app-generic-multiselect-checkbox-option>
                                </app-generic-multiselect-checkbox>
                            </div>
                            <div [matTooltip]="reducer.stakeholder ? a('settings.relatic.disable_field') : null">
                                <app-generic-multiselect-checkbox type="stakeholders" class="col-xs-12" *hasPermission="[Permission.DecisionsUpdate]" #stakeholderMultiselect placeholder="Stakeholder(s)" name="stakeholders" [(ngModel)]="decision.stakeholders" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'"
                                    (ngModelChange)="updateDecision()" [ngClass]="{'disable_enitiy': reducer.stakeholder}" [uniqueId]="'select-stakeholders'">
                                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                    </app-generic-multiselect-checkbox-option-all>
                                    <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                                        {{ stakeholder.name }}
                                    </app-generic-multiselect-checkbox-option>
                                    <app-generic-multiselect-checkbox-option-new-action type="stakeholder">
                                    </app-generic-multiselect-checkbox-option-new-action>
                                    <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
                                </app-generic-multiselect-checkbox>
                            </div>
                            <div class="col-xs-12 mb-15" *ngIf="!decision.stakeholders || decision.stakeholders.length === 0">
                                <span class="decision-detail--stakeholders-warning">
                  <mat-icon class="d-text-middle">error</mat-icon>
                  <span class="decision-detail--stakeholders-warning-text">{{'reusable.Floating_requirements' | transloco}}</span>
                                </span>
                            </div>
                            <div [ngClass]="{'disable_enitiy': (!hasPermission(Permission.DecisionsUpdate))}">
                                <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [parent]="decision" parentType="decisions" class="subprojects col-xs-12" type="subprojects" [maxChipItems]="3" *ngIf="hasModule('subprojects')" (ngModelChange)="updateSubprojects()" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco"
                                    name="subprojects" [(ngModel)]="decision.subprojects" [multiple]="true" [maxChipLength]="20" [disabled]="!hasPermission(Permission.DecisionsUpdate) || globals.subprojects.length === 0" [uniqueId]="'sub-projects'">
                                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                                    <app-generic-multiselect-checkbox-option *ngFor="let subproject of subprojectsSet" [value]="subproject">
                                        {{ subproject.name }}
                                    </app-generic-multiselect-checkbox-option>
                                </app-generic-multiselect-checkbox>
                            </div>
                        </div>

                        <div content>
                            <!-- Object -->
                            <app-add-connect-themes [editable]="true" [disabled]="!hasPermission(Permission.DecisionsUpdate) || !hasPermission(Permission.DecisionsTypeCRUD)" class="col-xs-12" [buttonText]="'object_theme_button_text'" [textMultiple]="'objects'" [textSingle]="'object'" [type]="'types?group=decisions'" [selectedTheme]="selectedObject" (onThemeSelected)="updateSelectedObject($event)"></app-add-connect-themes>
                            <!-- Theme -->
                            <app-add-connect-themes [editButton]="true" [editable]="false" valueType="object" class="col-xs-12" [disabled]="!hasPermission(Permission.DecisionsUpdate)" [type]="themeType" [selectedTheme]="selectedTheme" (onThemeSelected)="updateSelectedTheme($event)" [uniqueId]="'add-themes'"></app-add-connect-themes>
                            <!-- Date filter -->
                            <div class="col-xs-12 mb-15">
                                <mat-radio-group name="newDecision_dateFilter" [(ngModel)]="dateFilter" (change)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate)">
                                    <mat-radio-button value="no-date">{{'reusable.no_data_restriction' | transloco}}</mat-radio-button>
                                    <mat-radio-button class="date-restriction" value="fixed-date">{{'reusable.fixed_date' | transloco}}</mat-radio-button>
                                    <mat-radio-button class="date-restriction" value="ranged-date">{{'reusable.date_range' | transloco}}</mat-radio-button>
                                </mat-radio-group>
                                <div *ngIf="dateFilter === 'fixed-date'">
                                    <mat-form-field [matTooltip]="reducer.start_date ? a('settings.relatic.disable_field') : null">
                                      <mat-label>{{ 'reusable.choose_date' | transloco }}</mat-label>
                                        <input matInput [matDatepicker]="datePickerStart" (focus)="datePickerStart.open()" (click)="datePickerStart.open()"  name="newDecision_startDate" [(ngModel)]="decision.start_date" (dateChange)="updateDecision()"
                                            (change)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate) || reducer.start_date">
                                        <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                                        <mat-datepicker #datePickerStart></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="dateFilter === 'ranged-date'">
                                    <mat-form-field [matTooltip]="reducer.start_date ? a('settings.relatic.disable_field') : null">
                                      <mat-label>{{ 'reusable.choose_start_date' | transloco }}</mat-label>
                                        <input matInput [matDatepicker]="datePickerStart" (focus)="datePickerStart.open()" (click)="datePickerStart.open()" name="newDecision_startDate" [(ngModel)]="decision.start_date" (dateChange)="updateDecision()"
                                            (change)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate) || reducer.start_date">
                                        <mat-datepicker-toggle matSuffix [for]="datePickerStart "></mat-datepicker-toggle>
                                        <mat-datepicker #datePickerStart></mat-datepicker>
                                    </mat-form-field>
                                    <span class="tm ">t/m</span>
                                    <mat-form-field [matTooltip]="reducer.end_date ? a('settings.relatic.disable_field') : null">
                                      <mat-label>{{ 'reusable.choose_end_date' | transloco }}</mat-label>
                                        <input matInput [matDatepicker]="datePickerEnd " [min]="decision.start_date " (focus)="datePickerEnd.open() " (click)="datePickerEnd.open()" name="newDecision_endDate
                                            " [(ngModel)]="decision.end_date " (dateChange)="updateDecision() " (change)="updateDecision() " [disabled]="!hasPermission(Permission.DecisionsUpdate) || reducer.end_date">
                                        <mat-datepicker-toggle matSuffix [for]="datePickerEnd "></mat-datepicker-toggle>
                                        <mat-datepicker #datePickerEnd></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </app-show-more>
                </form>
            </div>

            <!-- map-->
            <h3 class="mb-15 text-capitalize ">{{ 'location' | transloco }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border map-div">
                <div [matTooltip]="reducer.coordinates ? a('settings.relatic.disable_field') : null" [ngClass]="{'disable_enitiy': (reducer.coordinates || !hasPermission(Permission.IssuesUpdate))}">
                    <app-openlayers-map [(ngModel)]="decision.locations" [includeSidebar]="false" [defaultCoordinate]="projectConfig.default_map" [showMapReset]="true" [maxPoints]="1" (ngModelChange)="updateCoordinate()" name="locations" locatableType="requirements" defaultDrawTool="point"
                        clickStartDrawingMsg="Teken klantwenspunt" [disabled]="reducer.coordinates || !hasPermission(Permission.DecisionsUpdate)"></app-openlayers-map>
                </div>
            </div>
        </div>
    </div>
</div>
