import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from '../../../core/service/common/abstract.service';
import { Observable, of } from 'rxjs';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { Subproject } from './subproject.model';
import { SubprojectsStore } from './subprojects.store';
import { SubprojectQuery } from './subprojects.query';
import { catchError, finalize, tap } from 'rxjs/operators';
import { WithParams } from '../../../core/service/common/with-params';
import { ID } from '@datorama/akita';
import { generateColor } from '../../../core/util/generateColor';
import { Globals } from 'src/app/globals';
import { MatDialog } from '@angular/material/dialog';
import { ProjectUnauthorizedModalComponent } from 'src/app/features/projects-overview/component/project-unauthorized-modal/project-unauthorized-modal.component';
import { sortingSubprojects } from 'src/app/core/util/subprojectDropdownPermission';

@Injectable({ providedIn: 'root' })
export class SubprojectsService extends AbstractService {

  constructor(private dialog: MatDialog,globals: Globals, private subProjectStore: SubprojectsStore, private subprojectQuery: SubprojectQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(subproject=false): Observable<Subproject[]> {
    if(subproject){
      return <Observable<Subproject[]>>this.http.get(`/api/subprojects?with=auth_permissions`, this.httpOptions)
      .pipe(
        tap((response: Subproject[]) => {
          response=sortingSubprojects(response);
          this.getGlobals().subprojectsPermission=response;
          this.getGlobals().subprojects=response;
          const subProjects = response.map(s => {
            s.color = generateColor(s.name);
            return s;
          });
          this.subProjectStore.set(subProjects);
        }),
        catchError((val) => {
          if(val.status==401)
            this.openUnauthorizedModal();
          return of(val);
        }),
      );
    }
      return <Observable<Subproject[]>>this.http.get(`/api/subprojects`, this.httpOptions)
      .pipe(
        tap((response: Subproject[]) => {
          const subProjects = response.map(s => {
            s.color = generateColor(s.name);
            return s;
          });
          this.subProjectStore.set(subProjects);
        }),
        catchError((val) => {
          if(val.status==401)
            this.openUnauthorizedModal();
          return of(val);
        }),
      );


  }

  public select(subProjectId: ID, withParams?: WithParams): Observable<Subproject> {
    return <Observable<Subproject>>this.http.get(`/api/subprojects/${subProjectId}`, withParams ? withParams.get(
      new HttpParams().append('skip_subproject', '1')
    ) : this.httpOptions)
      .pipe(
        tap((response: Subproject) => {
          this.subProjectStore.updateWithColor(response.id, response);
          this.subProjectStore.update(response.id, response);
        })
      );
  }

  public set(subProjectId: ID): void {
    if (subProjectId) {
      this.subProjectStore.setActive(subProjectId);
    } else {
      this.subProjectStore.setActive(null);
    }
  }

  public setFromUrl(url: String): void {
    const routes: string[] = url.split('/');
    const [dummy, projectCode, sub, subProjectCode] = routes;
    if (sub && sub === 'sub' && subProjectCode) {
      const subProject = this.findByCode(subProjectCode);
      if (subProject) {
        this.set(subProject.id);
        this.getGlobals().subProjectInitialized.next(true);
        return;
      }
    }

    this.set(null);
  }

  public findByCode(code: string): Subproject {
    return this.subprojectQuery.getAll().find(s => s.code === code);
  }

  public findById(id: ID): Subproject {
    return this.subprojectQuery.getAll().find(s => s.id === id);
  }

  @ServiceErrorHandler()
  public create(subProject: Subproject): Observable<Subproject> {
    return <Observable<Subproject>>this.http.post('/api/subprojects', subProject, this.httpOptions)
      .pipe(
        tap((response: Subproject) => subProject = response),
        catchError(() => subProject = null),
        finalize(() => {
          subProject.color = generateColor(subProject.name);
          subProject.locations.metadata = subProject.locations.metadata || {};
          subProject.locations.metadata.color = subProject.color;
          this.subProjectStore.add(subProject);
          this.getGlobals().subprojects.push(subProject);

        })
      );
  }

  @ServiceErrorHandler()
  public update(subProject: Subproject): Observable<Subproject> {
    return <Observable<Subproject>>this.http.put(`/api/subprojects/${subProject.id}`, subProject, this.httpOptions)
      .pipe(
        tap((response: Subproject) => {
          subProject = { ...subProject, ...response };
        }),
        finalize(() => {
          this.subProjectStore.updateWithColor(subProject.id, subProject);
        })
      );
  }

  public updateOnclick(subProject: Subproject, fn): void {
    this.subProjectStore.update(subProject.id, {
      locations: {
        id: subProject.locations.id,
        link: subProject.locations.link,
        name: subProject.locations.name,
        points: subProject.locations.points,
        lines: subProject.locations.lines,
        polygons: subProject.locations.polygons,
        metadata: {
          color: subProject.locations.metadata.color || generateColor(subProject.locations.name),
          onmouseover: subProject.locations.metadata.onmouseover,
          onclick: fn,
        },
        locatable_type: subProject.locations.locatable_type,
      },
    });
  }
  @ServiceErrorHandler()
  public deletesubproject(subProject: Subproject): Observable<Subproject> {
    const deleteSubproject = subProject;
    return <Observable<Subproject>>this.http.delete(`/api/subprojects/${subProject.id}`, this.httpOptions)
      .pipe(
        tap((response: Subproject) => subProject = response),
        catchError(() => subProject = null),
        finalize(() => {
          this.subProjectStore.remove(deleteSubproject.id);
          this.getGlobals().subprojects.push(subProject);
        })
      );
  }
  @ServiceErrorHandler()
  public getSubprojectUsers(){
    return this.http.get(`/api/users`);
  }
  //open unauthorized modal is user is not belog to it
  openUnauthorizedModal(){
    const dialog = this.dialog.open(ProjectUnauthorizedModalComponent, {
      minWidth: 500
    });
    dialog.disableClose = true;
  }
}
