import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToWords'
})
export class CamelCaseToWordsPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Ignore everything after a hyphen
    const hyphenIndex = value.indexOf('-');
    let processedValue = hyphenIndex !== -1 ? value.substring(0, hyphenIndex) : value;

    // Replace underscores with spaces
    if (processedValue.includes('_')) {
      processedValue = processedValue.replace(/_/g, ' ');
    } else {
      processedValue = processedValue.replace(/([A-Z])/g, ' $1');
    }
    return processedValue;

  }

}
