import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Project } from './project.model';
import {ProjectState, ProjectsStore} from './projects.store';

@Injectable({ providedIn: 'root' })
export class ProjectQuery extends QueryEntity<ProjectState, Project> {
  constructor(protected store: ProjectsStore) {
    super(store);
  }

}
