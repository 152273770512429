<div *ngIf="pageSubTitle || pageTitle" class="row fixed full-width">
  <div class="col-xs-12 text-left full-background no-padding no-margin">
    <!-- <a *ngIf="routeInterceptor.previousUrl" class="d-cursor-hand" (click)="back()">
      <mat-icon class="material-icons d-text-middle inline-block">keyboard_arrow_left</mat-icon>
      <h5 class="mb-0 inline-block">Terug</h5>
    </a> -->
  </div>

  <div *ngIf="pageTitle" class="col-xs-12 text-left full-background no-padding no-margin">
    <h1 class="pull-left">{{ pageTitle }}</h1>
  </div>
</div>
