import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { openPasswordLogoutModal } from 'src/app/akita/personal-setting/state/personal.modal';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { getPasswordValidationLogic } from 'src/app/core/util/auth/sso.modal';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass'],
})
export class ChangePasswordComponent implements OnInit {
  public entity: any;
  public passwordToggle: boolean = false;
  public confirmPasswordToggle: boolean = false;
  enableButton: boolean = true
  public validations = {
    minimum: false,
    lowercase: false,
    specialcharacters: false,
    passwordMatch: false
  }
  // eslint-disable-next-line no-empty-function
  constructor(public globals: Globals,@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ChangePasswordComponent>,private dialog: MatDialog, private _authService: AuthService, private _projectsService: ProjectsService) { }

  ngOnInit(): void {
    this.entity = {
      email: this.globals.user.email,
      password: null,
      password_confirmation: null
    }
  }
  //on close modal
  public onClose(): void {
    this.dialogRef.close();
  }
  //password validation logic
  public onPasswordValidation(): void {
    this.validations = getPasswordValidationLogic(this.validations, this.entity.password, this.entity.password_confirmation);
    if(this.validations.lowercase && this.validations.minimum && this.validations.passwordMatch && this.validations.specialcharacters) {
      this.enableButton = false
    } else {
      this.enableButton = true

    }
  }
  //on submit
  public submit(): void {
    this._authService.changePasswordPSModal(this.entity)
    .pipe(
      tap(res=>{
        this.onClose();
        showFeedbackSaved();
        this._projectsService.setActivityLogs("user_password_changed").subscribe();
        openPasswordLogoutModal(this.dialog);
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
}
