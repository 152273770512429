<div *transloco="let t">

    <div>
        <h4>{{ data.title }}</h4>
    </div>
    <div class="popup-body">
        {{ data.body}}
    </div>
    <div *ngIf="!data.confirmCheck && !data.feature && data.type !== 'remove_subprject'">
        <mat-checkbox [checked]="confirmCheckSelected" (change)="confirmCheck($event.checked)">
            {{ data.confirmCheckText }}
        </mat-checkbox>
    </div>
    <div *ngIf="data.feature">
        <div class="d-flex justify-content-center">
            <mat-button-toggle-group [(ngModel)]="selectedFeature" class="full-width" (ngModelChange)="onFeatureSelection($event)">
                <mat-button-toggle class="full-width" value="requirements">{{'requirements.label' | transloco | capitaliz}}</mat-button-toggle>
                <mat-button-toggle class="full-width" value="complaints">{{'complaints.label' | transloco | capitaliz}}</mat-button-toggle>
            </mat-button-toggle-group>
            <br>
            <br>
        </div>
        <div class="popup-footer">
            <div class="dialog-button-row">
                <button type="button" mat-stroked-button (click)="selectFeature(false)">{{'cancel' | transloco }}</button>
                <button type="button" class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised" [disabled]="featureDisabled" (click)="selectFeature(true)">{{'save' | transloco | capitaliz}}</button>
            </div>
        </div>
    </div>

    <div *ngIf="!data.feature && data.type!== 'remove_subprject'" class="popup-footer">
        <div class="dialog-button-row">
            <button type="button" class="btn mdl-button  mdl-js-button mdl-button--raised" (click)="submit(false)">{{'cancel' | transloco | capitaliz}}</button>
            <button *ngIf="!data.confirmButton" type="button" class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised" [disabled]="!confirmCheckSelected" (click)="submit(true)">{{'confirm' | transloco | capitaliz}}</button>
        </div>
    </div>

    <div *ngIf="data.type == 'remove_subprject'" class="popup-footer">
        <button type="button" class="btn mdl-button  mdl-js-button mdl-button--raised" (click)="submit(false)">{{'cancel' | transloco | capitaliz}}</button>
        <button *ngIf="!data.confirmButton" type="button" class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised" (click)="submit(true)">{{'confirm' | transloco | capitaliz}}</button>
    </div>

</div>