import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { showFeedbackError, showFeedbackSaved } from 'src/app/core/util/notification';
import { relaticError } from 'src/app/core/util/relaticsErrorHandler';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-integration-modal-relatic',
  templateUrl: './integration-modal-relatic.component.html',
  styleUrls: ['./integration-modal-relatic.component.sass'],
})
export class IntegrationModalRelaticComponent extends IntegrationsModalComponent implements OnInit{
  private serviceGroup: string = 'relatics';
  isProcess:boolean=false;
  columnProcess:boolean=false;
  public tableColumns:any;
  public dialogKey:any;
  public syncelement:any;
  public relatic:any;
  public validation:any;
  public errorMessage;
  public relaticsenable:boolean=false;
  public communication:any=[];
  public hideCommunication:boolean=true;
  public columnValidation:boolean=false;
  public level:any='';
  public dialogEntity=['stakeholders','issues','requirements','activities','complaints','tasks','contactpersons','contents'];
  constructor(public projectDataGet:ProjectsService,private cd: ChangeDetectorRef,public _projectConfigsService: ProjectConfigsService,public pubsub: PubsubService,@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, public globals: Globals, public _route: Router) {
    super(globals, _projectConfigsService);
  }

  ngOnInit() {
    if(this._route.url.includes('beheer')){
      this.level='company'
      const projectName = this._route.url.split("/")[2];
      this.projectDataGet.getProjectByCode(projectName).subscribe((response: any) => {
        if (response) {
          response.modules.forEach(element => {
            this.communication.push(element.module_name);
          });
        }
      })
    }else{
      this.globals.modules.forEach(element => {
        this.communication.push(element.module_name);
      });
    }
    if(!this.communication.includes('Communicatie')){
      this.hideCommunication=false;
    }
    this.relaticsenable=this.globals.projectConfigs['services.relatics.enabled'];
    this.errorMessage=this.globals.projectConfigs['services.integration.relatics.connection_error'];
    this.initializeRelatic();
    this.gettablesColumn();
    this._projectConfigsService.getRelatic().subscribe((res:any)=>{
      if(Object.keys(res).length !== 0){
        // eslint-disable-next-line no-unused-expressions
        this.dialogKey=res.data.exportitems,
        this.syncelement=res.data.syncitems,
        this.validationCheck(this.dialogKey,this.syncelement)
        this.relatic = {
          workspaceid: res.data.workspaceid,
          workspace: res.data.workspace,
          exportitems:this.dialogKey,
          syncitems:this.syncelement
        };
      }
      this.isProcess=true;
    });
  }
  public gettablesColumn(){
    this._projectConfigsService.getTablesColumn().subscribe((res)=>{
      this.tableColumns=res;
      this.columnProcess=true;
    })
  }
  protected initializeRelatic(): void {
    this.initialization();
    this.relatic = {
      workspaceid: null,
      workspace: '',
      exportitems:this.dialogKey,
      syncitems:this.syncelement
    };
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  onNoClick(): void {
    this.dialogRef.close();
    this._projectConfigsService.integrationSync(this.serviceGroup).subscribe();
  }

  public addSyncFiled(value){
    switch(value){
      case 'stakeholders': {
        this.syncelement.stakeholders.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'issues': {
        this.syncelement.issues.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'requirements': {
        this.syncelement.requirements.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'activities': {
        this.syncelement.activities.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'complaints': {
        this.syncelement.complaints.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'tasks': {
        this.syncelement.tasks.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'contactpersons': {
        this.syncelement.contactpersons.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
      case 'contents': {
        this.syncelement.contents.columns.push({
          relaticscolumn:'',
          dialogcolumn:'',
          error:''
        });
        break;
      }
    }
    this.cd.detectChanges();
  }
  public submit(){
    this.columnValidation=false;
    this.dialogEntity.forEach(element => {
      if(this.relatic.syncitems[element].columns.length>0){
        this.relatic.syncitems[element].columns.forEach(element => {
          if(element.relaticscolumn=='' || element.dialogcolumn==''){
            this.columnValidation=true;
            showFeedbackError('',translate('settings.relatic.field_required'));

          }
        });
      }
    });
    if(!this.columnValidation){
      this.validationOnSubmit();
      this._projectConfigsService.updateRelatic(this.relatic).subscribe((res:any)=>{
        showFeedbackSaved();
        if(Object.keys(res).length !== 0){
          this.globals.relaticsColumn=res.data.syncitems;
          }
        this.updateConfigs(true);
      });
    }
  }
  public updateConfigs(action=false){
    let getKey
    if(action){
      this.globals.projectConfigs['services.relatics.enabled']=true;
      getKey= this.globals.projectConfigs['services.relatics.enabled'];
      this.relaticsenable=this.globals.projectConfigs['services.relatics.enabled']
    }else{
      this.globals.projectConfigs['services.integration.relatics.connection_error']=false;
      getKey= this.globals.projectConfigs['services.relatics.enabled'];
      getKey=!getKey;
      this.globals.projectConfigs['services.relatics.enabled'] = getKey;
    }
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        this.cd.detectChanges();
        if(!action){
          this.dialogRef.close(true);
        }else{
          this.onNoClick();
        }
      });
  }
  public deletesyncElement(value,index){
    switch(value){
      case 'stakeholders': {
        this.syncelement.stakeholders.columns.splice(index, 1);
        break;
      }
      case 'issues': {
        this.syncelement.issues.columns.splice(index, 1);
        break;
      }
      case 'requirements': {
        this.syncelement.requirements.columns.splice(index, 1);
        break;
      }
      case 'activities': {
        this.syncelement.activities.columns.splice(index, 1);
        break;
      }
      case 'complaints': {
        this.syncelement.complaints.columns.splice(index, 1);
        break;
      }
      case 'tasks': {
        this.syncelement.tasks.columns.splice(index, 1);
        break;
      }
      case 'contactpersons': {
        this.syncelement.contactpersons.columns.splice(index, 1);
        break;
      }
      case 'contents': {
        this.syncelement.contents.columns.splice(index, 1);
        break;
      }
    }
    this.cd.detectChanges();
  }
  public initialization(){
    this.dialogKey={
        "stakeholders": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "issues": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "requirements": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "activities": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "complaints": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "tasks": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "contactpersons": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
        "contents": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "haserror": false
        },
      }
      this.syncelement={
        "stakeholders": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
        "issues": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false

        },
        "requirements": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
        "activities": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
        "complaints": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
        "tasks": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
        "contactpersons": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
        "contents": {
          "operation": "",
          "entrycode": "",
          "error":"",
          "columns":[],
          "haserror": false
        },
      }
  }
  public errorHendle(error){
    return relaticError(error)
  }
  public validationCheck(item,synxc){
    this.dialogEntity.forEach(element => {
      if(!item[element].operation || !item[element].entrycode){
        item[element].validation=false;
        item[element].haserror=false;
        item[element].error="";
      }else{
        item[element].validation=true;
      }
      });
    this.dialogEntity.forEach(element => {
      if(!synxc[element].operation || !synxc[element].entrycode){
        synxc[element].validation=false;
        synxc[element].haserror=false;
        synxc[element].error="";
      }else{
        synxc[element].validation=true;
      }
    });
  }
  public validationOnSubmit(){
    if(this.relatic.exportitems.stakeholders.entrycode.length==0 || this.relatic.exportitems.stakeholders.operation.length==0){
      this.relatic.exportitems.stakeholders.entrycode='';
      this.relatic.exportitems.stakeholders.operation='';
    }
    if(this.relatic.exportitems.issues.entrycode.length==0 || this.relatic.exportitems.issues.operation.length==0){
      this.relatic.exportitems.issues.entrycode='';
      this.relatic.exportitems.issues.operation='';
    }
    if(this.relatic.exportitems.requirements.entrycode.length==0 || this.relatic.exportitems.requirements.operation.length==0){
      this.relatic.exportitems.requirements.entrycode='';
      this.relatic.exportitems.requirements.operation='';
    }
    if(this.relatic.exportitems.activities.entrycode.length==0 || this.relatic.exportitems.activities.operation.length==0){
      this.relatic.exportitems.activities.entrycode='';
      this.relatic.exportitems.activities.operation='';
    }
    if(this.relatic.exportitems.complaints.entrycode.length==0 || this.relatic.exportitems.complaints.operation.length==0){
      this.relatic.exportitems.complaints.entrycode='';
      this.relatic.exportitems.complaints.operation='';
    }
    if(this.relatic.exportitems.tasks.entrycode.length==0 || this.relatic.exportitems.tasks.operation.length==0){
      this.relatic.exportitems.tasks.entrycode='';
      this.relatic.exportitems.tasks.operation='';
    }
    if(this.relatic.exportitems.contactpersons.entrycode.length==0 || this.relatic.exportitems.contactpersons.operation.length==0){
      this.relatic.exportitems.contactpersons.entrycode='';
      this.relatic.exportitems.contactpersons.operation='';
    }
    if(this.relatic.exportitems.contents.entrycode.length==0 || this.relatic.exportitems.contents.operation.length==0){
      this.relatic.exportitems.contents.entrycode='';
      this.relatic.exportitems.contents.operation='';
    }
  }
}
