<div mat-dialog-title>
    <div class="row">
        <div class="col-md-9">
            <h4>{{ ('subprojects.new' | transloco) + " " + (this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco) }}</h4>
        </div>
        <div class="col-md-3">
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
                <mat-icon class="d-text-middle">close</mat-icon>
            </span><span class="sr-only">Close</span></button>
        </div>
    </div>
</div>
<div *transloco="let t">
    <mat-horizontal-stepper linnear #stepper>
        <mat-step completed="false">
            <form #form1="ngForm" (submit)="submitStep1()">
                <div mat-dialog-content>
                    <ng-template matStepLabel>{{ (this.globals.projectConfigs['project.labels.subprojects'] || ( 'subprojects.label-1'| transloco)) +' '+ ('subprojects.area' | transloco) }}</ng-template>
                    <mat-form-field class="full-width">
                        <mat-label>{{(this.globals.projectConfigs['project.labels.subprojects'] || ( 'subprojects.label-1'| transloco)) +' '+ ('subprojects.name' | transloco)}}</mat-label>
                        <input matInput type="text" name="name" [(ngModel)]="subproject.name" (keyup)="subprojectExists()" required/>
                    </mat-form-field>
                    <span class="message" *ngIf="nameExists">{{'subprojects.nameInUse' | transloco}}</span>
                     <!-- On Select Feature  -->
                    <app-select-map-features *ngIf="integrationEnable" [integrationType]="integrationCheck" [featureType]="'arcgis'" [newFeatures]="subprojectMapFeatures.arcgis" (selectFeatures)="onSelectIntegrationFeautes($event)"></app-select-map-features>
                    <app-select-map-features [featureType]="'opendata'" [newFeatures]="subprojectMapFeatures.opendata" (selectFeatures)="onSelectOpenDataFeautes($event)"></app-select-map-features>
                    <!-- Users  -->
                    <label class="material-style-label text-capitalize">{{ 'users.labels' | transloco }}</label>
                    <div *ngIf="!isLoader">
                        <mat-progress-bar mode="buffer"></mat-progress-bar>
                    </div>
                    <table class="table" *ngIf="isLoader">
                        <tbody class="roles-text-end">
                            <tr *ngFor="let item of users; let i=index">
                                <span class="roles-header">{{item.name}}</span>
                                <td class="roles-header">
                                    <app-generic-multiselect-checkbox [placeholder]="'users.access_fields' | transloco | capitaliz" [name]="item.name" [(ngModel)]="selectedSubprojectRoles[i]" (ngModelChange)="subprojectRolesSelect(item.id,i)">
                                        <app-generic-multiselect-checkbox-option *ngFor="let role of subprojectRoles" [value]="role">
                                            {{ role.name }}
                                        </app-generic-multiselect-checkbox-option>
                                    </app-generic-multiselect-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <mat-accordion>
                    </mat-accordion>
                    <label class="material-style-label text-capitalize">
                        {{ t('area') }}
                    </label>
                    <div class="map-container">
                        <app-openlayers-map [(ngModel)]="subproject.locations" [featureSelectType]="featureSelectedType" [typeAction]="'new'" [addFeature]="subproject.features" [actionSelect]="selectType" name="locations" [includeSidebar]="false" [defaultCoordinate]="globals.projectConfig.default_map" [showMapReset]="true" [showPinTooltip]="false" [singleEntityType]="true" locatableType="subprojects"
                            defaultDrawTool="area" clickStartDrawingMsg="Teken subprojectgebied" required>
                        </app-openlayers-map>
                    </div>
                </div>
                <div mat-dialog-actions class="pull-right bottom-buttons-margin">
                    <button type="submit" mat-flat-button class="text-capitalize pull-right ml-10 mt-10">{{ t('next') }}</button>
                    <button mat-stroked-button class="text-capitalize pull-right mt-10" (click)="close()">{{ t('close') }}</button>
                </div>
            </form>
        </mat-step>

        <mat-step [completed]="false" [editable]="false">
            <form #form2="ngForm" (submit)="submitStep2()">
                <div mat-dialog-content>
                    <mat-slide-toggle labelPosition="before" [(ngModel)]="subproject.all" name="all" (ngModelChange)="toggleChange($event)">{{ 'subprojects.import-data-all' | transloco | capitaliz }} {{ globals.project.name }}</mat-slide-toggle>
                    <ng-template matStepLabel>{{ 'subprojects.import-data' | transloco | capitaliz }}</ng-template>

                    <app-generic-multiselect-checkbox [placeholder]="'stakeholders.labels' | transloco | capitaliz" name="stakeholders" [(ngModel)]="subproject.stakeholders" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                            {{ stakeholder.name }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>

                    <app-generic-multiselect-checkbox [placeholder]="'actions.labels' | transloco | capitaliz" name="actions" [(ngModel)]="subproject.actions" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('actions.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let action of actions" [value]="action">
                            {{ action.text | truncatie : 30 }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>

                    <app-generic-multiselect-checkbox [placeholder]="'issues.labels' | transloco | capitaliz" name="issues" [(ngModel)]="subproject.issues" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('issues.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let issue of issues" [value]="issue">
                            {{ issue.code }} {{ issue.name }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>

                    <app-generic-multiselect-checkbox [placeholder]="'decisions.labels' | transloco | capitaliz" name="decisions" [(ngModel)]="subproject.decisions" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('decisions.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let decision of decisions" [value]="decision">
                            {{ decision.title }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>

                    <app-generic-multiselect-checkbox [placeholder]="'complaints.labels' | transloco | capitaliz" name="complaints" [(ngModel)]="subproject.complaints" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('complaints.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let complaint of complaints" [value]="complaint">
                            {{ complaint.text | truncatie: 30 }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>

                    <app-generic-multiselect-checkbox [placeholder]="'activities.labels' | transloco | capitaliz" name="activities" [(ngModel)]="subproject.activities" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('activities.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let activity of activities" [value]="activity">
                            {{ activity.name | truncatie: 30 }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>

                    <app-generic-multiselect-checkbox [placeholder]="'middel.labels' | transloco | capitaliz" name="middel" [(ngModel)]="subproject.middel" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('middel.labels' | transloco)+' ...'" [disabled]="subproject.all">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let m of middel" [value]="m">
                            {{ m.name }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>
                </div>
                <div mat-dialog-actions class="pull-right bottom-buttons-margin">
                    <button type="submit" mat-flat-button class="text-capitalize pull-right mt-10 ml-10" [disabled]="this.processing">{{ t('create') }}</button>
                    <button type="button" mat-stroked-button matStepperPrevious class="text-capitalize pull-right mt-10 ml-10" [disabled]="this.processing">{{ t('previous') }}</button>
                    <button type="button" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="close()" [disabled]="this.processing">{{ t('close') }}</button>
                </div>
            </form>
        </mat-step>
    </mat-horizontal-stepper>
</div>
