import { Observable } from "rxjs";
import { InAppChatService } from "src/app/core/service/api/in-app-chat.service";
import { InAppChat } from "src/app/core/util/inappmessages";
import { Globals } from "src/app/globals";

export class BouwAppIntegrationChatModal {
  public messages$: Observable<InAppChat>;
  public responce$: Observable<any>;

  constructor(
    public _inAppChatService: InAppChatService,
    public globals: Globals,
    // eslint-disable-next-line no-empty-function
  ) { }
  //load in app messages
  public loadMessages(ticketID, type, personal = false) {
    if (!personal) {
      this.messages$ = this._inAppChatService.getMessages(ticketID, type)
    } else {
      this.messages$ = this._inAppChatService.getUserMessages(ticketID, type, this.globals.user.id)
    }
    return this.messages$;
  }

    //load in app whatsapp messages
    public loadWhatsAppMessages() {
      const body = {
        id: this.globals.project.id
      }
      return this.messages$= this._inAppChatService.getWhatsAppMessages(body)
    }
  //send messages
  public sendMessage(message, type, personal = false) {
    if (!personal) {
      this.responce$ = this._inAppChatService.sendMessage(message, type);
    } else {
      this.responce$ = this._inAppChatService.sendMessageByUser(message, type, this.globals.user.id)
    }
    return this.responce$;
  }


  //send messages
  public whatsappMessage(message) {
    return this.responce$ = this._inAppChatService.whatsappMessage(message);
  }
}
