import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Complaint } from './complaint.model';

export interface ComplaintState extends EntityState<Complaint>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'complaints' })
export class ComplaintsStore extends EntityStore<ComplaintState, Complaint> {

  constructor() {
    super();
  }

}

