import {deepCopy} from "../core/util/deepCopy";

export class SidebarEmployees {
  private _stakeholders: any;
  private _emails = {
    unregistered: [],
    registered: [],
  };

  get stakeholders(): any {
    return this._stakeholders;
  }

  set stakeholders(stakeholders) {
    this._stakeholders = stakeholders;
  }

  get emails(): {
    unregistered: any[],
    registered: any[],
  } {
    return this._emails;
  }

  set emails(emails) {
    this._emails = emails;
  }

  public clone(): SidebarEmployees {
    const clone = new SidebarEmployees();
    clone.stakeholders = deepCopy(this.stakeholders);
    clone.emails = {
      unregistered: deepCopy(this.emails.unregistered),
      registered: deepCopy(this.emails.registered)
    };

    return clone;
  }
}
