import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { translate } from '@jsverse/transloco';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { dateIso } from 'src/app/core/util/dateIso';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { environment } from 'src/environments/environment';
import { Log } from '../../../../akita/contact-moments/state/log.model';
import { ActionListingType } from '../../../../core/enum/action-listing-type';
import { ActionsService } from '../../../../core/service/api/actions.service';
import { EmployeesService } from '../../../../core/service/api/employees.service';
import { LogsService } from '../../../../core/service/api/logs.service';
import { StakeholdersService } from '../../../../core/service/api/stakeholders.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { PaginatedResponse } from '../../../../core/service/common/paginated-response';
import { GenericComponent } from '../../../../core/util/abstract/generic-component';
import { deepCopy } from '../../../../core/util/deepCopy';
import { ngModelCopy } from '../../../../core/util/ngModelCopy';
import { showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { SidebarEmployees } from '../../../../pubsub/sidebarEmployees';
import { GenericMultiselectCheckboxComponent } from '../../../../shared/components/other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { SidebarEmployeesComponent } from '../../sidebar-employees/sidebar-employees.component';
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';
import { ActionEditComponent } from '../tasks-edit/action-edit.component';


export interface LogEditData {
  log: any;
  connectStakeholders?: boolean;
  stakehoderMandatory?: boolean,
  params?: any;
  options?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  };
};

@Component({
  selector: 'app-log-new',
  templateUrl: './log-new.component.html',
  styleUrls: ['./log-new.component.sass']
})
export class LogNewComponent extends GenericComponent implements OnInit {

  public ActionListingType = ActionListingType;

  public connectStakeholders: boolean = false;
  public stakeholderMandatory: boolean = true;
  public saving: boolean = false;
  public log: any;
  private originalLog: any;
  public upload: any;
  public series: any[];
  public contactpersons: any[];
  public stakeholders: any[];
  public residents: any[];
  public newEntityParams: any;
  private isNewLog: boolean = false;
  public sidebarEmployees: SidebarEmployees = new SidebarEmployees();

  // Entities that can be connected
  public openedTab: string = 'actions';
  public actions: any[];
  public issues: any[];
  public complaints: any[];
  public activities: any[];
  public decisions: any[];
  private searchActionTimeout: any;
  public apiKey: string = environment.tinymce.apiKey;

  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;
  @ViewChild('form') public form: NgForm;


  constructor(public globals: Globals, private _cachedService: CachedService, private _actionsService: ActionsService, private _employeesService: EmployeesService,
    private _stakeholdersService: StakeholdersService, @Inject(MAT_DIALOG_DATA) public logData: LogEditData, private dialogRef: MatDialogRef<LogNewComponent>, private _logsService: LogsService,
    private dialog: MatDialog) {
    super(globals);
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.isNewLog = !this.logData.log;
    this.log = this.logData.log || {
      users_id: this.globals.user?.id,
      title: '',
      description: '',
      contact_moment_timestamp: '',
      contact_moment_type: 'email',
      series: null,
      issues: [],
      complaints: [],
      tasks: [],
      stakeholders: [],
      contactpersons: [],
      activities: [],
      requirements: [],
      documents: [],
      residents: [],
    };

    if (!this.log.contact_moment_type) {
      this.log.contact_moment_type = 'email';
    }

    this.newEntityParams = {
      users: [this.globals.user],
    };

    if (this.log.id) {
      this.newEntityParams.logs = [{
        id: this.log.id
      }];
    }

    //Extra parameters for adding logs in detail view
    if (this.isNewLog && this.logData.params) {
      Object.keys(this.logData.params).forEach((key: string) => this.log[key] = this.logData.params[key]);
    }

    if (this.isNewLog && this.logData.options && this.logData.options.droppedEmails && this.logData.options.droppedEmails.length > 0) {
      const addresses: string[] = this.logData.options.droppedEmails
        .map(data => data.address);

      let foundEmployeeEmails: any[] = [];
      forkJoin([this._stakeholdersService.findStakeholdersByEmployees(addresses), this._employeesService.findEmployees(addresses)])
        .subscribe((response: [PaginatedResponse, PaginatedResponse]) => {
          this.log.stakeholders = [...this.log.stakeholders, ...response[0].data];
          this.log.contactpersons = [...this.log.contactpersons, ...response[1].data];
          this.log.contactpersons.forEach(e => {
            e.selected = e.stakeholders.filter(x => this.log.stakeholders.find(s => s.id == x.id));
          });
          this.originalLog.stakeholders = [...this.log.stakeholders, ...response[0].data];
          foundEmployeeEmails = response[0].data
            .map(stakeholder => stakeholder.contactpersons)
            .reduce((accumulator, employee) => accumulator.concat(employee), [])
            .map(employee => employee.email ? employee.email.toLowerCase() : null);
          foundEmployeeEmails = foundEmployeeEmails.concat(response[1].data
            .map(employee => employee.email ? employee.email.toLowerCase() : null));
          this.logData.options.droppedEmails.forEach(email => {
            if (foundEmployeeEmails.filter(e => e === email.address).length === 0) { // Check if email is not arleady registered
              this.sidebarEmployees.emails.unregistered.push(email);
            }
          });
        });
    }

    this.log.tasks = this.log.tasks.map(action => this.parseActions(action));

    // Store original log for restoring value
    this.originalLog = deepCopy(this.log);

    if (this.logData && this.logData.connectStakeholders) {
      this.connectStakeholders = this.logData.connectStakeholders;
      this.stakeholderMandatory = this.logData.stakehoderMandatory;

      this._cachedService.getStakeholders().subscribe(
        stakeholders => {
          this.stakeholders = stakeholders;
          this.sidebarEmployees.stakeholders = stakeholders;
        }
      );
      this._cachedService.getEmployees().subscribe(
        contactpersons => this.contactpersons = contactpersons
      );
    }

    if (this.globals.projectConfigs && !this.globals.projectConfigs['residents.disabled']) {
      this._cachedService.getResidents().subscribe(
        residents => this.residents = residents
      );
    }

    this._cachedService.getSeries().subscribe(
      series => this.series = series
    );

    this.loadActions();
  }

  public changeType(change: MatButtonToggleChange) {
    if (change.value !== 'meeting') {
      this.log.series = null;
    }
  }

  public employeeSelected(): void {
    if (!this.log.contactpersons || this.log.contactpersons.length === 0) {
      return;
    }

    this.log.contactpersons.forEach((e: any) => {
      e.selected = e.stakeholders.filter(x => this.log.stakeholders.find(s => s.id == x.id));
      if (!e.stakeholders || e.stakeholders.length != 1) {
        return;
      }

      if (!this.log.stakeholders || this.log.stakeholders.length === 0) {
        this.log.stakeholders.push(e.stakeholders[0]);
        this.stakeholderMultiselect.writeValue(this.log.stakeholders);
        this.stakeholderMultiselect.onValidatorChange();
        return;
      }

      const found = this.log.stakeholders.find(s => s.id === e.stakeholders[0].id);
      if (!found) {
        this.log.stakeholders.push(e.stakeholders[0]);
        this.stakeholderMultiselect.writeValue(this.log.stakeholders);
        this.stakeholderMultiselect.onValidatorChange();
      }
    });
  }

  onStakeholdersUpdated(event) {
    if (event.action == "add") {
      this.log.stakeholders.push(event.data);
      this.stakeholderMultiselect.writeValue(this.log.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    else {
      this.log.stakeholders = this.log.stakeholders.filter(s => s.id != event.data.id);
      this.stakeholderMultiselect.writeValue(this.log.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
  }

  public handleAction(action: any): void {
    this._actionsService.updateActionById(action.id, action).subscribe(() => {
      if (action.handled) {
        showFeedbackSaved('Actie afgehandeld en gearchiveerd');
      } else {
        showFeedbackSaved('Actie is teruggezet');
      }
    });
  }

  public close(): void {
    // Revert values
    if( this.log ){
      Object.keys(this.originalLog).forEach((key: string) => {
        this.log[key] = this.originalLog[key];
      });
    }

    this.dialogRef.close({
      log: this.originalLog,
      updated: false,
    });
  }

  public updateLog(closeModal: boolean = true): void {
    if (this.form.invalid) {
      return;
    }

    this.saving = true;
    // TODO: refactor this
    const params = deepCopy(this.log);

    if (!this.isNewLog && this.logData.log.id) {
      params.contact_moment_timestamp = new Date(moment(dateIso(params.contact_moment_timestamp, true, this.globals.timezone)).format('YYYY-MM-DD'))
      this._logsService.updateLog(params.id, params)
        .pipe(finalize(() => this.saving = false))
        .subscribe(
          (log: Log) => {
            const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
            snackbar.openFromComponent(UndoSnackbarComponent, {
                data: {
                  data:log,
                  message: translate('error.stored'),
                  type:'openItems',
                  item:'contactmomenten',
                  url:'stakeholders/contactmomenten'
                },
                duration: 4000,
                horizontalPosition: 'start',
                panelClass: 'snackbar-background-green',
            });
            this.log.series = log.series;

            if (closeModal) {
              this.dialogRef.close({
                log: this.log,
                updated: true,
              });
            }
          }
        );
    } else {
      params.contact_moment_timestamp = new Date(moment(dateIso(params.contact_moment_timestamp, true, this.globals.timezone)).format('YYYY-MM-DD'))
      this._logsService.submitLog(params)
        .pipe(finalize(() => this.saving = false))
        .subscribe(
          (log: any) => {
            const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
            snackbar.openFromComponent(UndoSnackbarComponent, {
                data: {
                  data:log,
                  message: translate('error.stored'),
                  type:'openItems',
                  item:'contactmomenten',
                  url:'stakeholders/contactmomenten'
                },
                duration: 4000,
                horizontalPosition: 'start',
                panelClass: 'snackbar-background-green',
            });
            ngModelCopy(this.log, log);

            if (closeModal) {
              this.dialogRef.close({
                log: this.log,
                updated: true,
              });
            }
          }
        );
    }
  }

  public editAction(action: any): void {
    const editModal = this.dialog.open(ActionEditComponent, {
      width: '60vw',
      data: action
    });

    editModal.afterClosed().subscribe((updatedAction: any) => {
      ngModelCopy(action, updatedAction);
      let originalAction = this.originalLog.actions.find(a => a.id == action.id);
      if (originalAction) {
        originalAction = deepCopy(updatedAction);
      }
    })
  }

  public removeConnection(entityName: string, entity: any): void {
    let entityMessage: string;
    switch (entityName) {
      case 'actions':
        entityMessage = 'de actie';
        break;
      case 'issues':
        entityMessage = 'het issue';
        break;
      case 'complaints':
        entityMessage = 'het melding';
        break;
      case 'decisions':
        entityMessage = 'het klanteis';
        break;
    }

    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: `Weet u het zeker dat u ${entityMessage} wilt verwijderen?`
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.log[entityName] = this.log[entityName].filter((e: any) => e.id != entity.id);
        this.originalLog[entityName] = this.originalLog[entityName].filter((e: any) => e.id != entity.id);

        if (!this.isNewLog) {
          this.updateLog(false);
        }
      }
    });
  }

  // New entity triggers
  public onNewActionClosed(newAction: any): void {
    if (newAction) {
      const action = this.parseActions(newAction);
      this.log.tasks.unshift(action);
      this.originalLog.actions.unshift(action);
      this.actions.unshift(newAction);
    }
  }

  public onNewIssueClosed(newIssue: any): void {
    if (newIssue) {
      this.log.issues.unshift(newIssue);
      this.originalLog.issues.unshift(newIssue);
      this.issues.unshift(newIssue);
    }
  }

  public onNewComplaintClosed(newComplaint: any): void {
    if (newComplaint) {
      this.log.complaints.unshift(newComplaint);
      this.originalLog.complaints.unshift(newComplaint);
      this.complaints.unshift(newComplaint);
    }
  }

  public onNewActivityClosed(newActivity: any): void {
    if (newActivity) {
      this.log.activities.unshift(newActivity);
      this.originalLog.activities.unshift(newActivity);
      this.activities.unshift(newActivity);
    }
  }

  public onNewDecisionClosed(newDecision: any): void {
    if (newDecision) {
      this.log.requirements.unshift(newDecision);
      this.originalLog.decisions.unshift(newDecision);
      this.decisions.unshift(newDecision);
    }
  }

  public onDocumentSidebarClosed(): void {
    this.originalLog.documents = deepCopy(this.log.documents);
  }

  public onDocumentRemoved(documentId: number): void {
    if (documentId) {
      this.log.documents = this.log.documents.filter(document => document.id !== documentId);
      this.originalLog.documents = this.originalLog.documents.filter(document => document.id !== documentId);
    }
  }

  public showEmployeeSidebar(): void {
    const modal = this.dialog.open(SidebarEmployeesComponent, {
      data: this.sidebarEmployees,
      width: '303px',
      height: '100vh',
      maxHeight: '100vh',
      position: {
        right: '0px'
      }
    });

    modal.afterClosed().subscribe((response: { sidebar: SidebarEmployees, stakeholders: any[], employees: any[] }) => {
      if (response) {
        this.contactpersons = this.contactpersons.concat(response.employees);
        this.stakeholders = this.stakeholders.concat(response.stakeholders);
        this.log.stakeholders = this.log.stakeholders.concat(response.stakeholders);
        this.log.contactpersons = this.log.contactpersons.concat(response.employees);
      }
    });
  }

  public fetchActionsPerSeries(): void {
    clearTimeout(this.searchActionTimeout);
    this.searchActionTimeout = setTimeout(() => {
      if (!this.log.series) {
        this.log.tasks = this.log.tasks.filter(action => !action.inherited);
        return;
      }
      const stakeholderName = this.log.series.length == 0 ? this.log.series.name : this.log.series[0].name;
      this._logsService.getConnectionsPerSeries(stakeholderName).subscribe(
        (connections: {
          tasks: any[], stakeholders: any[], contactpersons: any[],
        }) => {
          if (this.log.tasks && this.log.tasks.length > 0) {
            this.log.tasks = this.log.tasks.filter(action => !action.inherited);
          }

          if (this.log.stakeholders && this.log.stakeholders.length > 0) {
            this.log.stakeholders = this.log.stakeholders.filter(stakeholder => !stakeholder.inherited);
          }

          if (this.log.contactpersons && this.log.contactpersons.length > 0) {
            this.log.contactpersons = this.log.contactpersons.filter(employee => !employee.inherited);
          }

          connections.tasks.forEach(action => {
            action.inherited = true;
            if (!this.log.tasks || this.log.tasks.length === 0 || !this.log.tasks.some(a => action.id === a.id)) {
              this.log.tasks.push(action);
            }
          });

          const stakeholders: any[] = [];
          connections.stakeholders.forEach(stakeholder => {
            stakeholder.inherited = true;
            if (!this.log.stakeholders || this.log.stakeholders.length === 0 || !this.log.stakeholders.some(s => stakeholder.id === s.id)) {
              stakeholders.push(stakeholder);
            }
          });
          this.log.stakeholders = this.log.stakeholders.concat(stakeholders);

          const contactpersons: any[] = [];
          connections.contactpersons.forEach(employee => {
            employee.inherited = true;
            if (!this.log.contactpersons || this.log.contactpersons.length === 0 || !this.log.contactpersons.some(e => employee.id === e.id)) {
              contactpersons.push(employee);
            }
          });
          this.log.contactpersons = this.log.contactpersons.concat(contactpersons);
        }
      );
    }, 500);
  }

  private parseActions(action): any {
    action.late = false;
    if (moment(action.timestamp).isBefore(new Date(), 'day')) {
      action.late = true;
    }

    return action;
  }

  // Load entities
  public loadEntity(event: MatTabChangeEvent): void {
    this.openedTab = event.tab.textLabel;
    switch (this.openedTab) {
      default:
      case 'actions':
        this.loadActions();
        break;
      case 'issues':
        this._cachedService.getIssues().subscribe(issues => this.issues = issues);
        break;
      case 'complaints':
        this._cachedService.getComplaints().subscribe(complaints => this.complaints = complaints);
        break;
      case 'activities':
        this._cachedService.getActivities().subscribe(activities => this.activities = activities);
        break;
      case 'decisions':
        this._cachedService.getDecisions().subscribe(decisions => this.decisions = decisions);
        break;
    }
  }

  private loadActions(): void {
    this._cachedService.getActions().subscribe(actions => this.actions = actions);
  }
}
