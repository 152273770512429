import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import {Globals} from "../../../globals";

declare let $: any;

@Component({
  selector: 'app-simple-mat-pagination',
  templateUrl: './simple-mat-pagination.component.html',
  styleUrls: ['./simple-mat-pagination.component.css']
})
export class SimpleMatPaginationComponent implements AfterViewInit, OnInit, OnChanges {

  @Input() entities: any;
  @Input() pageSize: number;
  @Input() search: string;
  @Input() searchKeys: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  private fullEntities: any;
  private initialized = false;
  entitiesLength: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // eslint-disable-next-line no-empty-function
  constructor(private globals: Globals) {}

  ngOnInit(): void {
    this.fullEntities = $.extend([], this.entities);
  }

  ngAfterViewInit(): void {
    if (!this.paginator) {
      return;
    }

    this.paginator.page.subscribe(
      () => {
        this.load();
      }
    );

    this.load();
  }

  private load() {
    this.entities = $.extend([], this.fullEntities);

    // Code block for performing filter based on search
    if (this.search && this.entities && this.entities.length > 0) {
      this.entities = this.entities.filter((entity) => {
        if (this.searchKeys && this.searchKeys.length > 0) {
          for (let i = 0; i < this.searchKeys.length; i++) {
            const key = this.searchKeys[i];

            // This is where the magic happen
            // Deep search based on keys, which support dot (.)
            const keys = key.split('.');
            let temp = $.extend({}, entity);
            for (const index in keys) {
              const deepKey = keys[index];
              if (temp[deepKey]) {
                temp = temp[deepKey];
              }
            }

            if (temp && typeof temp === 'string' && temp.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) !== -1) {
              return true;
            }
          }

          return false;
        }
          return entity.indexOf(this.search) > -1;

      });
    }
    // Reset page length if list of entities change because of searching
    this.entitiesLength = this.entities.length;

    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = (this.paginator.pageIndex + 1) * this.paginator.pageSize;
    this.entities = this.entities.slice(startIndex, endIndex);
    this.globals.cdRef.detectChanges();
    this.onPageChange.emit(this.entities);
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }

    let entitiesChange = false;
    for (const prop in changes) {
      if (prop === 'entities') {
        this.fullEntities = $.extend([], this.entities);
        entitiesChange = true;
      }

      // Go to first page when user is still typing for search keyword
      if (prop === 'search') {
        entitiesChange = true;
        this.paginator.firstPage();
      }
    }

    if (entitiesChange && this.entities) {
      this.load();
    }
  }
}
