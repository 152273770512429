import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { translate } from "@jsverse/transloco";
import { ActionsService } from 'src/app/akita/tasks/state/actions.service';
import { ActivitiesService } from 'src/app/core/service/api/activities.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { StakeholdersService } from 'src/app/core/service/api/stakeholders.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedback } from 'src/app/core/util/notification';
import { StakeholdersDetailComponent } from 'src/app/features/main-app/stakeholders/page/stakeholders-detail/stakeholders-detail.component';
import { Globals } from 'src/app/globals';
import { EmployeeEditComponent } from 'src/app/shared/components/modal/contact-persons-edit/employee-edit.component';
import { FormatdatePipe } from 'src/app/shared/pipes/formatdate.pipe';
import { LogEditComponent } from '../../modal/contact-moments-edit/log-edit.component';

@Component({
  selector: 'app-undo-snackbar',
  templateUrl: './undo-snackbar.component.html',
  styleUrls: ['./undo-snackbar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UndoSnackbarComponent{
public action:any;
  constructor(
    public _stakeholder: StakeholdersService,
    private _actionDash:ActivitiesService,
    private _actionsService: ActionsService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<UndoSnackbarComponent>,
    public _router:Router,
    private _cachedService: CachedService,
    private dialog: MatDialog,
    private pubsub: PubsubService,
    public globals: Globals,
    // eslint-disable-next-line no-empty-function
    ) { }

  ngOnInit() {
    this.action=this.data.actionData;
    this.pubsub.mapDetail=false;
  }
  public Undo(){
    let key;
    key=this.action.handled;
    key=!key;
    this.action.handled=key;
    this._actionsService.update(this.action).subscribe(()=>{
      this._actionsService.onClickUndo(this.action);
      this._actionsService.onClickUndoPer(this.action);
      this._actionsService.onClickUndoArc(this.action);
      this._actionDash.onClickUndoDashboard(this.action);
      if(this.action.handled){
        showFeedback(translate("text.action_is_handled"));
      }else{
        showFeedback(translate("text.action_is_recovered"));
      }
      this.snackBarRef.dismissWithAction();
    });
  }
  public addToChart(){
    this._stakeholder.onCreationGetStakeholder(this.data.data);
    this._stakeholder.onCreationGetStakeholderTrust(this.data.data);
  }
  public openDetailPanel(){
    switch(this.data.item){
      case "stakeholders":
        case "issues":{
        const url = `${this.data.url }/${ this.data.data.code}`;
        this._router.navigate(url.split("/"));
        break;
      }
      case "contactpersonen":{
        this._router.navigate(['stakeholders','contactpersonen'])
        this._cachedService.getEmployeesWithLogs()
        .subscribe(employees => {
           this.data.data = employees.find(e => e.id === this.data.data.id);
          const editDialog = this.dialog.open(EmployeeEditComponent, modalConfig({
            data: this.data.data,
            panelClass: ['animate__animated', 'animate__slideInRight'],
            disableClose:true,
          }, ModalEnum.SidebarSmallResponsive));
          const data = this.data.data;
          if(data){
            const name = `${data.first_name? data.first_name : ''} ${data.last_name? data.last_name : ''}`;
            this.pubsub.updateHistory(editDialog,EmployeeEditComponent,this.data.data,name,ModalEnum.SidebarSmallResponsive);
          }
        });
        break;
      }
      case "klanteisen":
        case "omwonenden":
          case "acties":
            case "meldingen":
              case "omwonenden":
                case "permits": {
        const url = `${this.data.url }/${ this.data.data.id}`;
        this._router.navigate(url.split("/"));
        break;
      }
      case "contactmomenten":
        const url = `${this.data.url }/${ this.data.data.id}`;
        this._router.navigate(url.split("/"));
        const editDialog = this.dialog.open(LogEditComponent, modalConfig({
          data: this.data.data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose:true,
        }, ModalEnum.SidebarLargeResponsive));
        const formatDatePipe = new FormatdatePipe(this.globals.locale);
        const name = `${formatDatePipe.transform(this.data.data.contact_moment_timestamp, 'dd-MM-yyy') }:${ this.data.data.title ? this.data.data.title : '(Contactmoment zonder titel)'}`
        this.pubsub.updateHistory(editDialog,LogEditComponent,this.data.data,name,ModalEnum.SidebarLargeResponsive)
        break;
      case "communicatiekalender":
        case "activiteit":{
        const url = `${this.data.url }/${ this.data.data.calendars[0].id}`;
        this._router.navigate(url.split("/"));
        break;
      }
      case "overpassstakeholders":{
        this.pubsub.mapDetail=true;
        const modal = this.dialog.open(StakeholdersDetailComponent, modalConfig({
          data: this.data.data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
          closeOnNavigation: true
        }, ModalEnum.SidebarLargeResponsive));
        this.pubsub.updateHistory(modal, StakeholdersDetailComponent, this.data.data, this.data.data.name)
        modal.afterClosed().subscribe(() => {
          // this.onClickLayersGet();
          // if(!action){
          //   this.getData(this.laterSave);
          // }
          // this.cd.detectChanges();
        });
        break;
      }
    }

  }
}
