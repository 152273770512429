import { Location } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import collect from 'collect.js';
import { Subscription } from 'rxjs';
import { catchError, finalize, tap } from "rxjs/operators";
import { isParamsExist, onPaginationSelection } from 'src/app/akita/batch/batch';
import { setLocalePagination } from 'src/app/akita/multilingual/multilingual.model';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { ExcelServiceService } from 'src/app/core/service/api/excel-service.service';
import { showFeedbackRemoved } from 'src/app/core/util/notification';
import { Entities } from '../../../../../core/enum/entities';
import { EmployeesService } from "../../../../../core/service/api/employees.service";
import { PubsubService } from '../../../../../core/service/api/pubsub.service';
import { CachedService } from "../../../../../core/service/common/cached.service";
import { PaginatedResponse } from "../../../../../core/service/common/paginated-response";
import { PaginationParams } from "../../../../../core/service/common/pagination-params";
import { GenericComponent } from "../../../../../core/util/abstract/generic-component";
import { ModalEnum, modalConfig } from "../../../../../core/util/modalConfig";
import { ngModelCopy } from "../../../../../core/util/ngModelCopy";
import { Globals } from "../../../../../globals";
import { EmployeeEditComponent } from "../../../../../shared/components/modal/contact-persons-edit/employee-edit.component";
import { DeletionModalComponent } from "../../../../../shared/components/modal/deletion-modal/deletion-modal.component";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.sass']
})
export class EmployeesComponent extends GenericComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() listingType: string = 'all';
  @Input() stakeholderId: number;
  @Input() showSearch: boolean = true;
  @Input() showLogsCount: boolean = true;
  @Input() showStakeholder: boolean = true;
  @Input() addButtonText: string = 'Nieuw';
  @Input() newEntityParams: any;
  public displayedColumns: string[] = [];
  public employees: MatTableDataSource<any> = new MatTableDataSource([]);
  public loading: boolean = true;

  exportStatus = 'init';
  public toolbar: string = 'search';
  public emailLink: string = '';
  private newEntitySubscription: Subscription;
  private employeeParam: number;
  pageSizeOptions: number[] = [5, 10, 20, 100];
  paginator = new PaginationParams(1, 20)
    .with(['stakeholders', 'contactmoments', 'subprojects'])
    .searchBy(['name', 'function', 'stakeholders_name'])
    .withFilters(true)
    .orderBy('function');
  private statusFilter: string;
  availableFilters: any;
  private searchTimeout: any;
  keyword: string = '';
  @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
  @ViewChild(MatSort, {static: false}) sortView: MatSort;
  @ViewChild(MatPaginator, { static: false }) _paginator: MatPaginator;
  @Output() employeesUpdated: EventEmitter<any> = new EventEmitter<any>();


  columnWidths = {
    select: null,
    id: null,
    name: 250,
    first_name: 200,
    last_name: 200,
    stakeholders_name: 200,
    function: 200,
    email: 200,
    phone: 200,
    personal_phone: 200,
    note: 200

    // ...add initial widths for other columns as needed
  };

  resizingColumn: string | null = null;

  //batch selection
  public onSelectAll: boolean = false;
  public batchLoad: boolean = true;
  public selectionItems: any[] = [];
  public filterColumnKeys: any[] = [];
  public menuToggle: boolean = false;
  public showColumnOptions: boolean = false;
  public stickyEnabled: boolean = true;
  public optionalColumns : object[] = [];
  public extraColumns: string[] = [];
  public defaultColumns: string[] = [];
  batchPaginator = new PaginationParams(1, 1000)
  .with(['stakeholders', 'contactmoments'])
  .searchBy(['name', 'function', 'stakeholders_name'])
  .withFilters(true)
  .orderBy('function');
  public exportFilter: boolean = false;
  constructor(
    private excelService: ExcelServiceService,
    public globals: Globals, private _cachedService: CachedService,
    private _employeesService: EmployeesService,
    private activatedRoute: ActivatedRoute,
    private _subProjectsQuery: SubprojectQuery,
    public location: Location,
    private dialog: MatDialog, private route: Router,
    private pubsub: PubsubService,
    private cd: ChangeDetectorRef,
    private configs: ConfigsService,) {
    super(globals);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.stickyOnResize();
  }
  stickyOnResize() {
    if (window.innerWidth < 796) {
      this.stickyEnabled = false;
    } else {
      this.stickyEnabled = true;
    }
  }

  startResize(event: MouseEvent, column: string) {
    this.resizingColumn = column;
    event.preventDefault();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.resizingColumn) {
      if ((this.columnWidths[this.resizingColumn] + event.movementX) >= 200)
        this.columnWidths[this.resizingColumn] += event.movementX;
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.resizingColumn = null;
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      const employeeId = params.get('employee');
      if (employeeId) {
        this.employeeParam = parseInt(employeeId);
        this.showEditOnInit();
        this.route.navigate([]);
      }
    });

    if(this.listingType === 'stakeholder') {
      this.loadEmployees();
    } else {
      this.customColumns();
    }
  }

  overviewTableColumns() {
    this.displayedColumns = ['select','id','name', 'stakeholders_name', 'function','relatics', 'subprojects', 'delete'];
    this.defaultColumns = ['select','id','name', 'stakeholders_name', 'function','relatics', 'subprojects', 'delete'];
    if (!this.showStakeholder) {
      this.displayedColumns = this.displayedColumns.filter(e => e !== 'stakeholders_name');
    }
    if (!this.showLogsCount) {
      this.displayedColumns = this.displayedColumns.filter(e => e !== 'logs_count');
    }
    this.loadEmployees();

    this.setToolbar();
  }

  private showEditOnInit() {
    if (this.employeeParam) {
      const employee = this.employees.data.find(employee => employee.id === this.employeeParam);
      if (employee) {
        this.editEmployee(employee);
      }
    }
  }

  public ngOnDestroy(): void {
    this.newEntitySubscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.employees.sort = this.sortView;
    this.newEntitySubscription = this.pubsub.newEntitiyPush.subscribe(data => {
      if (data.type && data.type === Entities.contactpersons) {
        this.ngOnInit();
      }
    });
  }

  private parseEmployee(employee: any): any {
    const titleCase = (value: string): string => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';
    employee.logs_count = employee.logs ? employee.logs.length : 0;
    employee.stakeholders_name = '';
    employee.name = `${titleCase(employee.first_name) || ''} ${titleCase(employee.last_name) || ''}`.trim();
    const stakeholderNames = []
    if(employee.stakeholders && employee.stakeholders.length){
      employee.stakeholders.forEach(s => {
        stakeholderNames.push(s.name)
      });
      employee.stakeholders_name = stakeholderNames.join(',')
    }

    return employee;
  }

  public applyFilter(filterValue: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = this.searchTimeout = setTimeout(() => {
      this.paginator.keyword(filterValue.trim().toLowerCase());
      this.loadEmployees();
    }, 350);
  }

  exportReport(){
    this.exportStatus = 'downloading';
    this._employeesService.exportEmployees(this.paginator).pipe(finalize(() => this.exportStatus = 'init'))
    .subscribe(
      (res) => {

        let fileName = 'contactpersons';

        if (res.type == 'application/octet-stream') {
          fileName += '.zip';
        } else {
          fileName += '.xls';
        }

        this.excelService.saveBlobAsFile(res, fileName);
      });
  }
  cancelSearch() {
    this.keyword = ''
    this.paginator.keyword('');
    this.loadEmployees();
  }
  public getServerPage(event?: PageEvent) {
    this.paginator.paginate(event);
    this.loadEmployees();
  }
  public editEmployee(employee: any): void {
    const activeSubProject = this._subProjectsQuery.getActive();
    if (this.listingType != "stakeholder") {
      if (activeSubProject) {
        this.location.go(`/${this.globals.project?.code}/sub/${activeSubProject.code}/stakeholders/contactpersonen?employee=${employee.id}`);
      } else {
        this.location.go(`/${this.globals.project?.code}/stakeholders/contactpersonen?employee=${employee.id}`);
      }
    }
    else {
      this.pubsub.closeModal(this.pubsub.currentDialog)
      document.body.classList.add("overflow-hidden");
    }
    const editDialog = this.dialog.open(EmployeeEditComponent, modalConfig({
      data: employee,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose:true,
    }, ModalEnum.SidebarSmallResponsive));
    const name = `${(employee.first_name || "")}" "${(employee.last_name || "")}`
    this.pubsub?.updateHistory(editDialog,EmployeeEditComponent,employee,name,ModalEnum.SidebarSmallResponsive)
    editDialog?.componentInstance.onFormValidated.subscribe((valids: boolean) => {
      if (valids) {
        editDialog.disableClose = false;
      } else {
        editDialog.disableClose = true;
      }
    });

    editDialog?.afterClosed().subscribe( () => {
      ngModelCopy(employee, this.parseEmployee(employee));
      this.ngOnInit()
      if (this.listingType != "stakeholder") {
        if (activeSubProject) {
          this.location.go(`/${this.globals?.project.code}/sub/${activeSubProject.code}/stakeholders/contactpersonen`);
        } else {
          this.location.go(`/${this.globals?.project.code}/stakeholders/contactpersonen`);
        }
       }

    });
  }

  parseEmployee1(employee: any): any {
    const titleCase = (value: string): string => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';
    employee.first_name = `${titleCase(employee.first_name) || ''}`.trim();
    employee.last_name = `${titleCase(employee.last_name) || ''}`.trim();
  }

  public loadEmployees(batch?){
    this.loading = true;
    this.exportFilter = false;
    if(batch){
      this.onSelectAll = false;
      this.selectionItems = [];
    }
    if (this.listingType === 'all') {
    this._employeesService.getEmployeesByFilters(this.paginator, this.statusFilter)
    .pipe(finalize(() => this.loading = false))
    .subscribe((response:any)=>{
      if (response.paginate && response.paginate.data && response.paginate.data.length) {
        response.paginate.data.map(employees => this.parseEmployee(employees));
        this.employees.data = response.paginate.data;
        this.paginator.total = response.paginate.total;
        this.availableFilters = [
          {
            field: 'stakeholders.name',
            options: response.stakeholders,
            label: 'stakeholders'
          }
        ]
      }
      else {
        this.employees.data = [];
        this.paginator.total = 0;
        if (this.paginator.page != 1) {
          this._paginator.firstPage();
          this.paginator.page = 1;
          this.loadEmployees();
        }
      }
      this._paginator._intl=setLocalePagination(this._paginator._intl,this.globals);
      if(this.paginator._keyword || isParamsExist(this.paginator)){
        this.exportFilter = true;
        if(this.onSelectAll)
          this.masterSelected(this.onSelectAll);
      }
      this.onSelectItems(this.onSelectAll);
    });
    this.filterColumnKeys = [];
    // eslint-disable-next-line no-unused-expressions
    this.paginator._filters? this.filterColumnKeys = Object.keys(this.paginator._filters): '';
  }
  else if (this.listingType === 'stakeholder' && this.stakeholderId) {
    this.displayedColumns = ['select', 'name', 'function', 'stakeholders_name','email', 'phone', 'personal_phone','logs_count', 'delete'];
    if (!this.showStakeholder) {
      this.displayedColumns = this.displayedColumns.filter(e => e !== 'stakeholders_name');
    }
    if (!this.showLogsCount) {
      this.displayedColumns = this.displayedColumns.filter(e => e !== 'logs_count');
    }
      this._employeesService.getEmployees(new PaginationParams()
        .with(['stakeholders', 'contactmoments','subprojects'])
        .has('stakeholders', 'id', this.stakeholderId)
      )
        .pipe(finalize(() => this.loading = false))
        .subscribe((employees: PaginatedResponse) => {
          this.employees.data = employees.data.map(this.parseEmployee);
          this.showEditOnInit();
        });
    }
}
  public onNewEmployeeClosed(dialogData: {
    employee: any,
    updated: boolean,
  }): void {
    if (dialogData && dialogData.updated) {
      this.employees.data.unshift(this.parseEmployee(dialogData.employee));

      // Need to fully re-assign otherwise the changes won't be reflected
      this.employees.data = this.employees.data;
      this.employeesUpdated.emit(this.employees.data);
    }
  }

  public delete(employee: any): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u de contactpersoon wilt verwijderen?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe( (remove: boolean) => {
      if (remove) {
        this._employeesService.removeEmployee(employee.id).subscribe(
          () =>{
            this.employeesUpdated.emit(this.employees.data.filter((e: any) => e.id !== employee.id));
            this.ngOnInit();
            showFeedbackRemoved();
          }
        );
      }
    });
  }

  public navigateStakeholderDetail(employee: any): void {
    if (employee && employee.stakeholders) {
      this.route.navigate(['stakeholders', employee.stakeholders.code]);
    }
  }

  private setToolbar(): void {
    if (this.selectionItems.length === 0) {
      if (this.showSearch) {
        this.toolbar = 'search';
      } else {
        this.toolbar = '';
      }
    } else {
      this.toolbar = 'actions';
      this.emailLink = `mailto:${
        this.selectionItems
          .filter((employee: any) => employee.email)
          .map(e => e.email).join(';')
      }`;
    }
  }
  public employeeName(data){
    if(data.first_name && data.last_name){
      return `${data.first_name} ${data.last_name}`;
    }
    else if(!data.first_name && data.last_name){
      return data.last_name;
    }
    else if(data.first_name && !data.last_name){
      return data.first_name;
    }
      return '';

  }
  //Selects single row from the overview
  public isSelected(event, item) {
    if(event.checked){
      this.selectionItems = [...collect(this.selectionItems).push(item).all()];
    }else{
      this.selectionItems = collect(this.selectionItems).filter((value) => value.id != item.id).all();
    }
    this.setToolbar();
  }
  //Selects all hit api to get all records
  public masterSelected(event): void {
    this.batchLoad = false;
    this.batchPaginator._filters=this.paginator._filters;
    this.batchPaginator._keyword=this.paginator._keyword;
    if(event){
      this._employeesService.getEmployeesByFilters(this.batchPaginator, this.statusFilter)
      .pipe(
        tap((res:any)=>{
          this.selectionItems = res.paginate.data;
          this.onSelectItems(event);
          this.batchLoad=true;
          this.setToolbar();
        }),
        catchError((async (err) => this.batchLoad = true))
      ).subscribe();
    }else{
      this.batchLoad = true;
      this.selectionItems = [];
      this.setToolbar();
      this.onSelectItems(event);
    }
  }
  public masterSelectInner(event): void{
    this.batchLoad = false;
    if(event){
      this.selectionItems = this.employees.data;
      this.onSelectItems(event);
      this.batchLoad=true;
      this.setToolbar();
    }else{
      this.batchLoad = true;
      this.selectionItems = [];
      this.setToolbar();
      this.onSelectItems(event);
    }
  }
  //Selects all rows if they are not all selected; otherwise clear selection
  public onSelectItems(event): void{
    this.employees.data=onPaginationSelection(this.employees.data,this.selectionItems,event);
  }
  
  customColumns() {
    this.configs.getConfig_deprecated('columns').subscribe( columns => {
      this.configs.getConfigs({configs: "project.columns"}).subscribe((response: any) => {
        this.overviewTableColumns();
        this.extraColumns = response.contactpersons.optional;
        this.extraColumns.forEach(item => {
          const key = `project.columns.contactpersons.${ item}`;
          if(columns.hasOwnProperty(key)) {
            this.optionalColumns.push({name: item, value: columns[key]})
          } else {
            this.optionalColumns.push({name: item, value: false})
          }
        })

        const functions = 'project.columns.contactpersons.function';
        if(columns.hasOwnProperty(functions)) {
          this.optionalColumns.push({name: 'function', value: columns[functions]})
          if(!columns[functions]) {
            const index = this.displayedColumns.indexOf('function')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'function', value: true})
        }

        const name = 'project.columns.contactpersons.name';
        if(columns.hasOwnProperty(name)) {
          this.optionalColumns.push({name: 'name', value: columns[name]})
          if(!columns[name]) {
            const index = this.displayedColumns.indexOf('name')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'name', value: true})
        }

        const id = 'project.columns.contactpersons.id';
        if(columns.hasOwnProperty(id)) {
          this.optionalColumns.push({name: 'id', value: columns[id]})
          if(!columns[id]) {
            const index = this.displayedColumns.indexOf('id')
            this.displayedColumns.splice(index,1);
          }
        } else {
          this.optionalColumns.push({name: 'id', value: true})
        }
        this.optionalColumns = collect(this.optionalColumns).unique(item => item.name).all();
        this.optionalColumns.forEach((optCol: any) => {
          if (optCol.value && optCol.name !== 'function' && optCol.name !== 'id' && optCol.name !== 'name') {
            if (optCol.name === 'last_name' || optCol.name === 'first_name' ) {
              if (this.displayedColumns.includes('name')) {
                const index = this.displayedColumns.indexOf('name');
                this.displayedColumns.splice(index +1,0,optCol.name)
              } else {
                const index = this.displayedColumns.indexOf('id');
                this.displayedColumns.splice(index +1,0,optCol.name)
              }
              // swap first_name last_name if last_name occur first
              if (this.displayedColumns.includes('first_name') && this.displayedColumns.includes('last_name')) {
                const lastNameIndex = this.displayedColumns.indexOf('last_name');
                const firstNameIndex = this.displayedColumns.indexOf('first_name');
                if (lastNameIndex !== -1 && firstNameIndex !== -1) {
                  if (lastNameIndex < firstNameIndex) {
                    [this.displayedColumns[lastNameIndex], this.displayedColumns[firstNameIndex]] = [this.displayedColumns[firstNameIndex], this.displayedColumns[lastNameIndex]];
                  }
                }
              }
            } else {
              const index = this.displayedColumns.indexOf('stakeholders_name');
              this.displayedColumns.splice(index +1,0,optCol.name)
            }
          }
        })
        if (window.innerWidth < 796) {
          this.stickyEnabled = false;
          this.cd.detectChanges();
        }else {
          this.stickyEnabled = true;
          this.cd.detectChanges();
        }
      })
    })
  }
  closeOptColModal(show: boolean) {
    this.showColumnOptions = show;
  }
  columnsUpdated(columns: any[]) {
    this.cd.detectChanges();
    columns.forEach((column) => {
      if(column.value && !this.displayedColumns.includes(column.name)) {
        if (column.name === 'id') {
          if(this.displayedColumns.includes('select')) {
            this.displayedColumns.splice(1,0,column.name)
          } else {
            this.displayedColumns.splice(0,0,column.name)
          }
        }
        else if (column.name === 'function') {
          const index = this.displayedColumns.indexOf('relatics');
          this.displayedColumns.splice(index,0,column.name)
        }
        else if (column.name === 'name') {
          if(this.displayedColumns.includes('id')) {
            this.displayedColumns.splice(2,0,column.name)
          } else {
            this.displayedColumns.splice(1,0,column.name)
          }
        }
        else if (column.name === 'last_name' || column.name === 'first_name') {
          if (this.displayedColumns.includes('name')) {
            const index = this.displayedColumns.indexOf('name');
            this.displayedColumns.splice(index +1,0,column.name)
          } else {
            const index = this.displayedColumns.indexOf('id');
            this.displayedColumns.splice(index +1,0,column.name)
          }
          // swap first_name last_name if last_name occur first
          if (this.displayedColumns.includes('first_name') && this.displayedColumns.includes('last_name')) {
            const lastNameIndex = this.displayedColumns.indexOf('last_name');
            const firstNameIndex = this.displayedColumns.indexOf('first_name');
            if (lastNameIndex !== -1 && firstNameIndex !== -1) {
              if (lastNameIndex < firstNameIndex) {
                [this.displayedColumns[lastNameIndex], this.displayedColumns[firstNameIndex]] = [this.displayedColumns[firstNameIndex], this.displayedColumns[lastNameIndex]];
              }
            }
          }
        }
        else {
          const index = this.displayedColumns.indexOf('stakeholders_name');
          this.displayedColumns.splice(index+1,0,column.name)
        }
      }
      else if(!column.value && this.displayedColumns.includes(column.name)) {
        const index = this.displayedColumns.indexOf(column.name);
        if (index > -1) {
          this.displayedColumns.splice(index,1);
        }
      }
    });
    if (window.innerWidth < 796) {
      this.stickyEnabled = false;
      this.cd.detectChanges();
    }else {
      this.stickyEnabled = true;
      this.cd.detectChanges();
    }
    this.employees = new MatTableDataSource([]);
    this.loadEmployees();
    this.showColumnOptions = false;
  }
}
