import {Injectable} from '@angular/core';
import {IssuesStore} from './issues.store';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from '../../../core/service/common/abstract.service';
import {Issue} from './issue.model';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PaginationParams} from '../../../core/service/common/pagination-params';
import {PaginatedResponse} from '../../../core/service/common/paginated-response';
import {IssuesQuery} from './issues.query';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({ providedIn: 'root' })
export class IssuesService extends AbstractService {

  constructor(private issuesStore: IssuesStore, private issueQuery: IssuesQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams, status = 'open', myIssues = false): Observable<Issue[]> {
    return this.http.get(`/api/issues?issues_status=${status}&is_mine=${myIssues}`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Issue>) => this.issuesStore.set(response.data)),
        map((response: PaginatedResponse<Issue>) => response.data)
      );
  }

  getUnfilteredIssuesRelation(): Observable<any> {
    return <Observable<any>> this.http.get(`/api/issues?dropdown=1`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getSummary(id): Observable<any> {
    return this.http.get(`/api/map/summary/issues/${id}`)
      .pipe(
        map((response:Issue) => response)
      );
  }

  @ServiceErrorHandler()
  public find(code: string, withs: string[] = [],skipSubproject:boolean = false): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }
    if(skipSubproject)
      params = params.append('skip_subproject','0')

    return this.http.get(`/api/issues/${code}`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  public update(issue: Issue): Observable<Issue> {
    this.issuesStore.update(issue.id, issue);
    return this.http.put(`/api/issues/${issue.code}`, issue, this.httpOptions)
      .pipe(map((response: Issue) => response) );
  }

  @ServiceErrorHandler()
  public create(issue: Issue): Observable<Issue> {
    return this.http.put('/api/issues', issue, this.httpOptions)
      .pipe(tap((response: Issue) => {
        this.issuesStore.add(issue);
      }));
  }

  @ServiceErrorHandler()
  public delete(issue: Issue): Observable<any> {
    return this.http.delete(`/api/issues/${issue.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.issuesStore.remove(issue.id);
      }));
  }
}
