<ng-container *transloco="let t">
  <div class="popup-header row no-margin ">
    <div class="col-xs-12 mb-5 d-flex j-space-between">
      <h2>{{ 'settings.personal.change_password' | transloco}}</h2>
      <button type="button" class="close a-self-start" (click)="onClose()">
        <span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon>
        </span>
        <span class="sr-only">Close</span>
      </button>
    </div>
  </div>
  <form  #form="ngForm" (submit)="submit()">
    <div class="popup-body" appScroll>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6">
            <p>{{'settings.auth.reset_password_required' | transloco}}</p>
            <mat-form-field class="full-width">
              <input [type]="passwordToggle ? 'text' : 'password'" matInput class="" [(ngModel)]="entity.password" name="password" [placeholder]="('password') | transloco | capitaliz" (ngModelChange)="onPasswordValidation()" required/>
              <mat-icon class="d-cursor-hand" matSuffix (click)="passwordToggle=!passwordToggle">remove_red_eye</mat-icon>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input [type]="confirmPasswordToggle ? 'text' : 'password'" matInput class="" [(ngModel)]="entity.password_confirmation" name="confirm password" [placeholder]="('confirm_password') | transloco | capitaliz" (ngModelChange)="onPasswordValidation()" required/>
              <mat-icon class="d-cursor-hand" matSuffix (click)="confirmPasswordToggle=!confirmPasswordToggle">remove_red_eye</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <p>{{'settings.auth.password_policy' | transloco}}</p>
            <ul class="p-null">
              <li class="d-flex" ><mat-icon [ngClass]="{'success': validations.minimum }">done</mat-icon> <span class="a-self-center ml-10" >{{'settings.auth.minimum_characters' | transloco}}</span></li>
              <li class="d-flex" ><mat-icon [ngClass]="{'success': validations.lowercase }">done</mat-icon> <span class="a-self-center ml-10" >{{'settings.auth.case_validation' | transloco}}</span></li>
              <li class="d-flex" ><mat-icon [ngClass]="{'success': validations.specialcharacters }">done</mat-icon> <span class="a-self-center ml-10" >{{'settings.auth.special_characters' | transloco}} {{'(!&#64;#$%^&*)'}}</span></li>
              <li class="d-flex" ><mat-icon [ngClass]="{'success': validations.passwordMatch }">done</mat-icon> <span class="a-self-center ml-10" >{{'settings.auth.password_match' | transloco}}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer" >
      <button type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">{{ t('close') | capitaliz }}</button>
      <button type="submit" mat-flat-button class="ml-10" [disabled]="enableButton">{{ t('save') | capitaliz }}</button>
    </div>
  </form>
</ng-container>
