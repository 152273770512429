import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { Observable} from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { InAppChatService } from 'src/app/core/service/api/in-app-chat.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { InAppChat, integrationLightBoxOpen, sendMessageFormate, sendResponseFormate, sendResponseFormateWhatsApp, setReference } from 'src/app/core/util/inappmessages';
import { attachmentSplice, integrationError, integrationValidation } from 'src/app/core/util/integration';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { Globals } from 'src/app/globals';
import { ChatdocumentuploadComponent } from '../chatdocumentupload/chatdocumentupload.component';
import { PdfViewComponent } from '../pdf-view/pdf-view.component';
import { BouwAppIntegrationChatModal } from './in-app-chat-integrations/bouwapp-integration-chat-modal';

@Component({
  selector: 'app-in-app-chat',
  templateUrl: './in-app-chat.component.html',
  styleUrls: ['./in-app-chat.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InAppChatComponent implements OnInit {
  @ViewChild('message') inputName;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isLoading = false;
  public messages$: Observable<InAppChat>;
  public responce$: Observable<any>;
  messagesRes$: Observable<any>
  public senderMessages: any = [];
  public lastmessage: any;
  public usermessage: any = '';
  public ticketID: any;
  public type: any;
  public integrationType: any;
  numbersOfRows = 1;
  complaintId: any
  public loader: boolean = false;
  public attachment: boolean = false;
  public chatdisable: boolean = false;
  public closebutton: boolean = false;
  errorMessage_userleve: boolean = false;
  personPhone: any
  public swapsendButton: boolean = false;
  public keywordsChecker: any;
  public files: any[] = [];
  public emptyState: boolean = false;
  public integrationRefrance: BouwAppIntegrationChatModal;

  constructor(
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public _inAppChatService: InAppChatService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globals: Globals,
    public dialogRef: MatDialogRef<any>,
    private _lightbox: Lightbox,
    private _integrationService: IntegrationsService,
    // eslint-disable-next-line no-empty-function
  ) { }

  ngOnInit() {
    this.init();
    this.chatIntegrationValidation();
  }

  public close() {
    this.dialogRef.close();
  }

  public init(): void {
    this.ticketID = this.data.complaintMessagesParams.ticket_id;
    this.type = this.data.complaintMessagesParams.type;
    this.integrationType = this.data.integrationType;
    this.usermessage = this.data.usermessage;
    this.numbersOfRows = this.data.numbersOfRows
    this.swapsendButton = this.data.usermessage?.length ? this.data.swapsendButton : false;
    this.chatdisable = this.data.isclose;
    this.files = this.data.files?.length ? this.data.files : [];
    this.attachment = this.data.files ? this.data.attachment : this.attachment;
    this.integrationRefrance = setReference(this.integrationType, this._inAppChatService, this.globals);
    this.complaintId = this.data.complaintId
    this.personPhone = this.data.phone
  }

  public submit() {
    this.beforeSave();
    if (this.integrationType === 'whatsapp') {
      const body = {
        "complaint_id": this.complaintId,
        "messaging_product": "whatsapp",
        "recipient_type": "individual",
        "to": this.personPhone,
        "type": "text",
        "full_name": this.globals.user.name,
        "text": {
          "preview_url": false,
          "body": this.usermessage
        }
      }
      this.responce$ = this.integrationRefrance.whatsappMessage(body);
      this.sendMessagesResponce();
    } else {
      const message = sendMessageFormate(this.files, this.usermessage, this.ticketID, this.type)
      if (integrationValidation(this.globals, this.integrationType, `${this.integrationType}_enabled`, this.errorMessage_userleve) === 'project') {
        this.responce$ = this.integrationRefrance.sendMessage(message, this.type);
        this.sendMessagesResponce();
      } else {
        this.responce$ = this.integrationRefrance.sendMessage(message, this.type, true);
        this.sendMessagesResponce();
      }
    }

  }

  public onKey(keyword) {
    this.keywordsChecker = keyword;
    if (keyword) {
      this.swapsendButton = true;
    } else {
      this.swapsendButton = false;
      this.numbersOfRows = 1;
    }
    this.cd.detectChanges();
  }

  public attachmentUpload() {
    const dialog = this.dialog.open(ChatdocumentuploadComponent, modalConfig({
      data: { id: this.ticketID, type: this.integrationType, complaintId: this.complaintId, to: this.personPhone, },
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
      closeOnNavigation: true
    }, ModalEnum.SidebarDocumentSmallResponsive));
    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        if(this.integrationType === 'whatsapp') {
          this.files = response;
          this.chatIntegrationValidation()
          this.scrollToBottom();
          this.cd.detectChanges();
        } else {
          this.files = response;
          this.attachment = true;
          this.swapsendButton = true;
          this.scrollToBottom();
          this.cd.detectChanges();
        }

      }
    });
  }

  public statusCloses() {
    const status = {
      status: "CLOSED",
      reason: this.lastmessage.content
    }
    this._inAppChatService.messagesStatusClosed(this.ticketID, this.type, status).subscribe((res) => {
      if (this.data.expand) {
        this.dialogRef.close({ closed_date: this.lastmessage.createdAt, closed_description: this.lastmessage.content, is_closed: 1 });
      } else {
        this._integrationService.afterLargeChatClosed({ closed_date: this.lastmessage.createdAt, closed_description: this.lastmessage.content, is_closed: 1 });
        this.dialogRef.close();
      }
    })
  }

  public lightboxOpen(document) {
    if (this.integrationType === 'whatsapp') {
      if(document?.content?.document) {
        this.download(document?.content?.document?.id)
      } else {
        this.download(document?.content?.image?.id)
      }

    } else {
      this._lightbox.open(integrationLightBoxOpen(this.integrationType, document));
    }
  }

  download(documentId, ): void {
    const body = {
      "document_id": documentId,
      "complaint_id": this.complaintId
    }
    this._inAppChatService.attachmentDownloadWhatsApp(body).subscribe((blob: any) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded_document'; // You can set a specific file name here
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => {
      console.error('Download failed:', error);
    });
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        // eslint-disable-next-line no-empty
      } catch (err) { }
    }, 100);
  }

  public removeFile() {
    this.files = null;
    this.attachment = false;
    if (!this.keywordsChecker) {
      this.swapsendButton = false;
    }
    this.cd.detectChanges();
  }
  public removeFilePerIndex(value) {
    this.files = attachmentSplice(this.files, value);
    this.attachment = this.files.length ? true : false;
    if (!this.keywordsChecker) {
      this.swapsendButton = false;
    }
    this.cd.detectChanges();
  }
  public onEnter(keyword) {
    if (this.numbersOfRows <= 3) {
      this.numbersOfRows = this.numbersOfRows + 1;
      this.cd.detectChanges();
    }
  }
  //integration validation
  public chatIntegrationValidation(): void {
    this.errorMessage_userleve = integrationError(this.globals, this.integrationType);
    if (integrationValidation(this.globals, this.integrationType, `${this.integrationType}_enabled`, this.errorMessage_userleve) === 'project') {
      if (this.integrationType === 'whatsapp') {
        const body = {
          id: this.complaintId
        }
        this.messages$ = this._inAppChatService.getWhatsAppMessages(body);
      } else {
        this.messages$ = this.integrationRefrance.loadMessages(this.ticketID, this.type);
      }
    } else {
      this.messages$ = this.integrationRefrance.loadMessages(this.ticketID, this.type, true);
    }
    this.loadMessagesResponce();
  }
  //before save chat
  public beforeSave() {
    this.emptyState = true;
    this.loader = true;
    this.attachment = false;
    this.inputName.nativeElement.value = '';
    this.swapsendButton = false;
  }
  //after save chat
  public afterSave(message) {
    this.loader = false;
    if (message && this.integrationType === 'whatsapp') {
      this.senderMessages.push(sendResponseFormateWhatsApp(message.message.id, message.message.content, message.message.is_sent, message.message.full_name));
      this.lastmessage = sendResponseFormateWhatsApp(message.message.id, message.message.content, message.message.is_sent, message.message.full_name);
    } else {
      this.senderMessages.push(sendResponseFormate(message, this.type));
      this.lastmessage = sendResponseFormate(message, this.type);
    }


    this.files = null;
    this.closebutton = true;
    this.numbersOfRows = 1;
    this.usermessage = '';
    this.cd.detectChanges();
  }
  //after chat close
  public closeChat(message) {
    if (message[message.length - 1].is_sent && !this.chatdisable) {
      this.lastmessage = message[message.length - 1];
      this.closebutton = true;
    }
  }
  //after message load set responce
  public loadMessagesResponce(): void {
    this.messages$.pipe(
      tap((res: any) => { if (res) { if (res.length) { this.closeChat(res); this.emptyState = true; } } }),
      finalize(() => { this.scrollToBottom(); this.cd.detectChanges(); }
      )
    ).subscribe();
  }
  //after message send get responce
  public sendMessagesResponce(): void {
    this.responce$.pipe(
      tap((res: any) => {
        if (res) {
          this.afterSave(res)
        } else {
          this.loader = false;
          this.cd.detectChanges();
        }
      }),
    ).subscribe();
  }
  //expand modal
  public modalExpand(action) {
    this.close();
    this.data.expand = !action;
    this.data.swapsendButton = this.swapsendButton;
    this.data.files = this.files;
    this.data.attachment = this.attachment;
    this.data.usermessage = this.usermessage;
    this.data.numbersOfRows = this.numbersOfRows;
    this.dialog.open(InAppChatComponent, modalConfig({
      data: this.data,
      panelClass: ['animate__animated', 'animate__slideInUp'],
      disableClose: true,
      closeOnNavigation: true
    }, action ? ModalEnum.SidebarChatLargeResponsive : ModalEnum.SidebarChatSmallResponsive));
  }
  //pdf viewer modal
  public onPDFView(data) {
    this.dialog.open(PdfViewComponent, modalConfig({
      data: data,
      panelClass: ['animate__animated', 'animate__slideInUp', 'transparent_bg'],
    }, ModalEnum.ModalDefault));
  }
}
