import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { finalize } from 'rxjs';
import { ActionsService } from 'src/app/core/service/api/actions.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { WorkflowService } from 'src/app/core/service/api/workflow.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { NewEntityModal } from 'src/app/core/util/abstract/new-entity-modal';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-add-workflow-task',
  templateUrl: './add-workflow-task.component.html',
  styleUrls: ['./add-workflow-task.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddWorkflowTaskComponent extends NewEntityModal implements OnInit {
  entity = {
    text: '',
    start_date: null,
    timestamp: null,
    workflows: [],
    users: [],
    handled: false,
    direct_mail:	false
  };
  workflows: any[] = [];
  public users: any[];
  isProcessing = false;
  public selectReferUser: boolean = false;
  chipDisablerUser: any[] = [];
  public helperText: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddWorkflowTaskComponent>, public globals: Globals, private pubsub: PubsubService, public _workflowService: WorkflowService, private _cachedService: CachedService, private _actionsService: ActionsService, private cd: ChangeDetectorRef) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._cachedService.getUsers().subscribe(users => {
      this.users = users;
      if (this.data.batch) {
        this.users.unshift({ id: this.users.length, name: "Selecteer stakeholder(s) accounthouder" })
      }
    });
  }

  protected initializeEntity(): void {

    // Initialize entity with defaults, using data from the dialog if available
    this.entity = {
      text: '',
      start_date: this.data?.startDate || null,
      timestamp: '',
      workflows: [],
      users: [],
      handled: false,
      direct_mail:	false,
    };

    // If necessary, ensure that startDate has the correct time (00:00:00)
    if (this.entity.start_date) {
      this.entity.start_date = this.setStartTime(this.entity.start_date);
    }
  }



  // Called when the start date is changed
  onStartDateChange(event: any) {
    if (!this.entity.timestamp) {
      // If no end date is selected, set the end date to the start date
      this.entity.timestamp = this.entity.start_date;
    }
  }

  addWorkflow(idWorkflow: any) {
    let id = String(idWorkflow.id); // Ensure id is a string

    if (id.includes('workflow')) {
      id = id.split('-')[1]; // Reassign id when it includes 'workflow'
    }

    const newWorkflow = { id }; // Create newWorkflow object
    this.entity.workflows.push(newWorkflow); // Push newWorkflow to workflows array
  }


  // Submit form method
  submit() {
    if (!this.entity.timestamp) {
      // If the end date is missing, set it to be the same as the start date
      this.entity.timestamp = this.entity.start_date;
    }
    this.addWorkflow(this.data)
    // Set start date time to 00:00:00
    this.entity.start_date = this.setStartTime(this.entity.start_date);

    // Set end date time to 23:59:00
    this.entity.timestamp = this.setEndTime(this.entity.timestamp);
    // Simulate processing
    this.isProcessing = true;
    this._actionsService.submitAction(this.entity)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        (data: any) => {
          this.dialogRef.close(); // Close the modal
          this.dialogRef.close(data);

        })
  }

  public selectionChange() {
    this.chipDisablerUser = [];
    if (this.entity.users.length > 0) {
      this.entity.users.forEach(element => {
        if (element.name == "Selecteer stakeholder(s) accounthouder") {
          this.entity.users = [element];
          this.users.forEach(el => {
            if (el.name !== "Selecteer stakeholder(s) accounthouder") {
              this.chipDisablerUser.push(el.name);
            }
          });
          this.helperText = true;
          this.cd.detectChanges();
        } else {
          if (this.chipDisablerUser.length == 0) {
            this.helperText = false;
          }
        }
      });
    } else {
      this.helperText = false;
      this.cd.detectChanges();
    }
  }

  onChangeRequired() {
    console.log('on change...');
  }

  // Utility method to set start date to 00:00:00
  setStartTime(date) {
    let startDate = new Date(date);

    // Get the date part (year, month, day) in the local timezone
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();

    // Create a new Date object with the local timezone and time set to 00:00:00
    startDate = new Date(year, month, day, 19, 0, 0, 0);

    return startDate;
  }

  // Utility method to set end date to 23:59:00
  setEndTime(date) {
    let endDate = new Date(date);

    // Get the date part (year, month, day) in the local timezone
    const year = endDate.getFullYear();
    const month = endDate.getMonth();
    const day = endDate.getDate();

    // Create a new Date object with the local timezone and time set to 23:59:59.999
    endDate = new Date(year, month, day, 19, 0, 0, 0);

    return endDate;
  }


}
