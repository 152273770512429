import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { WorkflowService } from 'src/app/core/service/api/workflow.service';
import { ChildTabbedPage } from 'src/app/core/util/abstract/child-tabbed-page';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowsComponent extends ChildTabbedPage implements OnInit {

  public pageSubTitle: string;
  public pageTitle: string = 'menu.tasks';
  public tabName: string = 'workflows';
  public workflows: any[] = [];
  public isProcess: boolean = false;

  constructor(public pubsub: PubsubService, public globals: Globals, public _workflowService: WorkflowService, public cdRef: ChangeDetectorRef) {
    super(pubsub,globals);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
