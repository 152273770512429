<ng-container>
  <section>
    <div class="row d-flex j-space-between">
      <div class="w-100 mr-10">
        <mat-form-field appearance="outline" class="w-100 custome-padding">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput [(ngModel)]="keyword" placeholder="{{'text.search_table' | transloco}}" value="" (keyup)="applyFilter($event.target.value)">
          <mat-icon matSuffix *ngIf="keyword" style="cursor: pointer;" (click)="cancelSearch()">close</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="no-padding overview-margin-bottom-space col-xs-12 text-left box-shadow">
      <div class="table-responsive">
        <table mat-table [dataSource]="entities" class="set-border-firefox-table overview-filter-widget-overflow" matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">{{'ID' | transloco | capitaliz}}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{entity.id}}
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-6 col-xs-6 col-lg-6">
              <div class="display-flex-set">{{'name' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-6 col-xs-6 col-lg-6">
                <span *ngIf="entity.type!='bouwapp' && entity.type!='freshdesk'">
                    <span *ngIf="entity.mimetype else default">
                        <a *ngIf="(entity.mimetype.includes('image') && entity.type=='s3') else default" [versiondownload]="entity" class="d-cursor-hand">{{ entity.name | truncatie : 50 }}</a>
                    </span>
                    <ng-template #default>
                        <a [versiondownload]="entity" [lightBox]="type=='complaints'?true:false" class="d-cursor-hand">{{ entity.name | truncatie : 50 }}</a>
                    </ng-template>
                </span>
                <a *ngIf="entity.type=='bouwapp'" class="d-cursor-hand" (click)='lightboxOpen(entity)'>{{ entity.name | truncatie : 50 }}</a>
                <div *ngIf="entity.type=='freshdesk'">
                    <div *ngIf="(entity.name.includes('.png') || entity.name.includes('.gif') || entity.name.includes('.jpeg')  || entity.name.includes('.jpg') || entity.name.includes('.pdf')) else defaultView">
                        <a class="d-cursor-hand" (click)='onPDFView(entity)' *ngIf="entity.name.includes('.pdf') else showImage">{{ entity.name | truncatie : 50 }}</a>
                        <ng-template #showImage>
                            <a class="d-cursor-hand" (click)='lightboxOpen(entity)'>{{ entity.name | truncatie : 50 }}</a>
                        </ng-template>
                    </div>
                    <ng-template #defaultView>
                        <a class="d-cursor-hand" [href]="entity.path">{{ entity.name | truncatie : 50 }}</a>
                    </ng-template>
                </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set"></div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              <a class="delete-button pull-right" (click)="removeDoc(entity)" *ngIf="entity.type!='bouwapp' && entity.type!='freshdesk'">
                <mat-icon [matTooltip]="'text.delete.connection' | transloco" class="delete-button uil uil-trash"></mat-icon>
              </a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="clickable-row delete-on-hover-tr"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable-row delete-on-hover-tr d-cursor-hand"></tr>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="12">{{'reusable.no' | transloco | capitaliz}} {{'documents.labels' | transloco}} {{'reusable.found' | transloco}}</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</ng-container>
