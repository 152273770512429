import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject, LOCALE_ID,
  OnInit,
  SecurityContext,
  ViewChild
} from '@angular/core';
import { MatDatepicker } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { Lightbox } from "ngx-lightbox";
import posthog from 'posthog-js';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Log } from 'src/app/akita/contact-moments/state/log.model';
import { Issue } from 'src/app/akita/issues/state/issue.model';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { Action } from 'src/app/akita/tasks/state/action.model';
import { Permission } from 'src/app/core/enum/permission';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { InAppChatService } from 'src/app/core/service/api/in-app-chat.service';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { deadlineWithKPI, setKPIStyle } from 'src/app/core/util/complaintModal';
import { InAppChat, integrationDataMapInertial } from 'src/app/core/util/inappmessages';
import { freshdeskIntegrationDocumentAttached, inAppChatComplaintParam, integrationChatSetupWithComplaints, integrationConnection, integrationError, integrationValidation } from 'src/app/core/util/integration';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { deletedUsersForClosedTickets, globalSubprojectUserSaprator, selectedSubprojectUsersSaprator, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { EmployeeEditComponent } from 'src/app/shared/components/modal/contact-persons-edit/employee-edit.component';
import { EmployeeNewComponent } from 'src/app/shared/components/modal/contact-persons-new/employee-new.component';
import { DeletionModalComponent } from 'src/app/shared/components/modal/deletion-modal/deletion-modal.component';
import { InAppChatComponent } from 'src/app/shared/components/modal/in-app-chat/in-app-chat.component';
import { GenericMultiselectCheckboxComponent } from 'src/app/shared/components/other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { NewEntityData } from 'src/app/shared/directive/new-entity.directive';
import { environment } from 'src/environments/environment';
import { Complaint } from '../../../../akita/complaints/state/complaint.model';
import { LogListingType } from "../../../../core/enum/log-listing-type";
import { ComplaintsService } from "../../../../core/service/api/complaints.service";
import { PubsubService } from "../../../../core/service/api/pubsub.service";
import { UsersService } from "../../../../core/service/api/users.service";
import { CachedService } from '../../../../core/service/common/cached.service';
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { dateIso } from "../../../../core/util/dateIso";
import { deleteConfirmationResponce, moduleValidation } from '../../../../core/util/deepCopy';
import { ModalEnum, modalConfig } from '../../../../core/util/modalConfig';
import { ngModelCopy } from "../../../../core/util/ngModelCopy";
import { showFeedbackRemoved, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from "../../../../globals";
import { ComplaintContactModalComponent } from '../../../../shared/components/modal/complaint-contact-modal/complaint-contact-modal.component';
import { FormatdatePipe } from "../../../../shared/pipes/formatdate.pipe";
import { MapService } from 'src/app/core/service/api/map.service';
import { NewWorkflowComponent } from 'src/app/shared/components/modal/new-workflow/new-workflow.component';

@Component({
  selector: 'app-complaints-detail',
  templateUrl: './complaints-detail.component.html',
  styleUrls: ['./complaints-detail.component.sass'],
})
export class ComplaintsDetailComponent extends GenericComponent implements OnInit {

  pageSubTitle: string;
  pageTitle = 'Melding';

  @ViewChild('closed_date') closedDatePicker: MatDatepicker<Date>;
  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;
  @ViewChild('log') _logs: any; // Set to any instead of LogsComponent to prevent circular dependency error
  public complaintId: any;
  public reducer: any = true;
  public complaint: Complaint;
  public selectedComplainee: any;
  public complaintThemes = [];
  public users: any;
  private updateComplaintTimeout: any;
  public logListingType: LogListingType = LogListingType.ComplaintDetail;
  public disableLog: boolean = false;
  public apiKey: string = environment.tinymce.apiKey;
  public integrationType: any = { type: '', error_type: '', chatShow: false, chatClosed: 0, chatIcon: '' };
  public integrationsEnabled: boolean = false;
  public stakeholders: any[];
  errorMessage_userleve: boolean = false;
  public residents: any[];
  public employees: any[];
  public inAppChatRes$: Observable<InAppChat>;
  public firstMessage: any[] = [];
  themeType = ThemeType.Complaints;
  selectedTheme: any[] = [];
  name: string;
  logs: Log[] = [];
  actions: Action[] = [];
  issues: Issue[] = [];
  entities: any
  loaded: Boolean = false;
  is_load: Boolean = true;
  disanleString: string;
  public integrationDocuments: any[] = [];
  complaintMessagesParams = {
    ticket_id: 0,
    type: ''
  }
  firstload: boolean = false;
  integration_themeDisable: boolean = false;
  statusclosebutton: boolean = false;
  kpiSet: boolean = false;
  subprojectsSet: any[] = [];
  chipDisabler: any[] = [];
  chipDisablerUser: any[] = [];
  userSelection: any[] = [];
  sentiments = [{ value: 1, icon: 'uil uil-frown' },
  { value: 3, icon: 'uil uil-meh' },
  { value: 5, icon: 'uil uil-smile' }];
  selectedIcon = { value: 1, icon: 'uil uil-meh' };
  menuToggle = false;
  public sentimentEnable = false;
  complaintStatusButton: boolean = false;
  public subprojectUpdate: boolean = false;
  @ViewChild('mentionColor') myNameElem: ElementRef;
  constructor(public pubsub: PubsubService, public globals: Globals,
    private _complaintsService: ComplaintsService,
    public _inAppChatService: InAppChatService,
    private cachedService: CachedService,
    private _usersService: UsersService,
    private _logsService: LogsService,
    private configs: ConfigsService,
    private _lightbox: Lightbox, private _sanitizer: DomSanitizer,
    private subProjectQuery: SubprojectQuery,
    public dialog: MatDialog,
    public router: Router,
    private cd: ChangeDetectorRef,
    private _integrationService: IntegrationsService,
    public _projectConfigsService: ProjectConfigsService,
    private _pusherService: PusherService,
    @Inject(LOCALE_ID) private locale: string, private _mapService: MapService, @Inject(MAT_DIALOG_DATA) public data: Complaint, private dialogRef: MatDialogRef<ComplaintsDetailComponent>) {
    super();
  }

  ngOnInit(): void {
    this._mapService.setData(this.data.locations);
    this.subprojectSetup();
    this.complaintId = this.data.id;
    this.name = this.data.text;
    if (moduleValidation('Communicatie', this.globals.modules) && this.globals.project.complaints_kpi) {
      this.kpiSet = true;
    }
    this._complaintsService.getComplaintThemes().subscribe(
      (themes: any) => this.complaintThemes = themes
    );

    this._usersService.getUsers().subscribe(
      (users: any) => {
        this.users = users.data;
      }
    );
    this.cachedService.getStakeholdersRelations().subscribe(
      stakeholders => this.stakeholders = stakeholders
    );
    this.cachedService.getEmployees().subscribe(
      employees => this.employees = employees
    );

    if (!this.globals.projectConfigs['residents.disabled']) {
      this.cachedService.getResidents().subscribe(
        residents => this.residents = residents
      );
    }
    if (this._integrationService.chatExpandClosed == undefined) {
      this._integrationService.chatExpandClosed = this._integrationService.onChatExpandClosed.subscribe((res) => {
        this.onCloseChat(res)
        this._integrationService.chatExpandClosed = undefined;
      });
    }
    this.load(this.complaintId);
    posthog.capture('complaint-detail');
  }

  private load(complaintId): void {

    this.complaintId = complaintId;
    this._complaintsService.getComplaint(this.complaintId, true).subscribe(
      (complaint: any) => {

        this.parseComplaintResponse(complaint);
        forkJoin([
          this.cachedService.getActionsRelations(),
          this.cachedService.getIssuesRelations(),
          this.cachedService.getLogsRelations(),
          this.cachedService.getDecisionsRelations(),
          // this.cachedService.getComplaintsRelations(),
          this.cachedService.getDocumentsRelations()
        ]
        ).subscribe(([tasks, issues, contactmoments, requirements, documents]) => {
          this.entities = { tasks, issues, requirements, contactmoments: contactmoments, documents: documents }
          this.loaded = true;
        });
        //date parse
        complaint.deadline = this.parseDate(complaint.deadline)
        //In App Chat Setup
        this.integrationType = integrationChatSetupWithComplaints(complaint);
        if (this.integrationType) {
          this.complaintMessagesParams = inAppChatComplaintParam(complaint)
          if (integrationValidation(this.globals, this.integrationType.type, this.integrationType.error_type, this.errorMessage_userleve) === 'project') {

            if (this.integrationType.chatShow) {
              this.integrationsEnabled = true;

              if (this.integrationType.type !== 'whatsapp') {

                // Call integrationConnection function
                if (!integrationConnection(this.globals, this.integrationType.type, this.complaint)) {
                    this.integrationsEnabled = false;
                }
            }
            }

            if (complaint.ticket_id && this.integrationsEnabled) {
              if (complaint.entry_type == 'smart_reporting') {
                this.smartReporting();
              }
              this.getInAppMessages(this.complaintMessagesParams, this.firstload);
              this.firstload = true;

              this.cd.detectChanges();
            }
          } else {
            this.errorMessage_userleve = integrationError(this.globals, this.integrationType.type);
            if (this.globals.user.configs[this.integrationType.error_type] == 1 && !this.errorMessage_userleve) {
              if (this.integrationType.chatShow) {
                this.integrationsEnabled = true;
                if (!integrationConnection(this.globals, this.integrationType.type, this.complaint)) {
                  this.integrationsEnabled = false;
                }
              }
              if (complaint.ticket_id && this.integrationsEnabled) {
                if (complaint.entry_type == 'smart_reporting') {
                  this.smartReporting();
                }
                this.userAuthenticate();
              }
              this.cd.detectChanges();
            }
          }
        }
        //In App Chat Setup End
        this.setLodedSubproject();
        this.usersSetup();
        this.setLodedSubprojectUsers();
        this.relaticsValidation();
        this.complaintStatusButton = false;
      });
  }

  private parseComplaintResponse(complaint) {
    if (complaint.date) {
      complaint.date = dateIso(complaint.date, true, this.globals.timezone);
    }

    if (complaint.closed_date) {
      complaint.closed_date = dateIso(complaint.closed_date, true, this.globals.timezone);
    }

    complaint.is_closed = complaint.is_closed === 1 || complaint.is_closed == true;
    if (this.complaint) {
      complaint.actions = this.complaint.tasks
      complaint.logs = this.complaint.contactmoments
      if (!complaint.documents)
        complaint.documents = this.complaint.documents

    }
    this.complaint = complaint;
    this.configs.getConfig_deprecated("sentiments").subscribe((res: any) => {
      const key = 'microservices.sentiments.enabled';
      // show sentiments if enabled
      if (res) {
        if (res[key]) {
          this.sentimentEnable = true;
          this.sentimentValue();
        }
      }
    })

    if (this.complaint.contactpersons)
      this.complaint.contactpersons.selected = this.complaint.contactpersons.stakeholders.filter(x => this.complaint.stakeholders.find(s => s.id == x.id));
    if (this.complaint.complaintthemes) {
      this.selectedTheme = [];
      this.complaint.complaintthemes.forEach((item) => {
        const theme = this.complaintThemes.filter((c) => c.id == item.id)
        this.selectedTheme.push(theme[0])
      })
    }
  }

  public closedDateLoaded() {
    // Make sure that the mat-datepicker is disabled when complaint.is_closed = true after refresh
    if (this.closedDatePicker) {
      this.closedDatePicker.disabled = this.complaint.is_closed;
    }
  }

  onDateTimeChange(newDateTime: Date): void {
    this.complaint.deadline = newDateTime;
    this.updateComplaint();
  }

  public updateComplaint(showFeedback = true, parseUpdateResponse = true): void {
    if (this.globals.permissions.includes(this.Permission.ComplaintsUpdate)) {
      clearTimeout(this.updateComplaintTimeout);
      this.updateComplaintTimeout = setTimeout(() => this.executeUpdate(showFeedback, parseUpdateResponse), 350);
    }
  }

  private executeUpdate(showFeedback: boolean = true, parseUpdateResponse = true): void {
    this.onSaveDocumentRemoval();
    this._complaintsService.updateComplaint(this.complaintId, this.complaint).subscribe(
      complaint => {
        // stop this.load() when updating subprjects items on subproject level
        if (!this.subprojectUpdate) {
          this.load(this.complaintId)
        }
        this.subprojectUpdate = false;
        if (this._mapService.circleVectorSource && this._mapService.circleVectorSource.getFeatures().length > 0) {
          this._mapService.circleVectorSource.removeFeature(this._mapService.searchFeature);
        }
        ngModelCopy(this.data, complaint);
        if (showFeedback) {
          this._pusherService.onCangeStatus();
          showFeedbackSaved();
        }
      }
    );
  }

  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'complaints', parent: this.complaint},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.complaint.workflows.push(response);
        this.load(this.complaintId);
      }
    });
  }
  public updateComplainee() {
    if (!this.selectedComplainee) {
      this.complaint.residents = null;
      this.complaint.stakeholders = null;
    } else if (this.selectedComplainee.type === 'stakeholder') {
      this.complaint.residents = null;
      this.complaint.stakeholders = this.selectedComplainee;
    } else if (this.selectedComplainee.type === 'resident') {
      this.complaint.stakeholders = null;
      this.complaint.residents = this.selectedComplainee;
    }

    this.updateComplaint();
  }

  public updateCoordinate() {
    this.updateComplaint();
  }

  public updateUser(users) {
    this.complaint.users = users;
    this.updateComplaint();
  }

  public toggleHandleComplaint() {
    this.complaintStatusButton = true;
    this.complaint.is_closed = !this.complaint.is_closed;
    if (this.complaint.is_closed && this.integrationType && this.integrationType.type !== 'whatsapp') {
      this.onCloseChatFromDetailPanel();
    }

    this.updateComplaint();
  }

  public showPictures() {
    if (this.complaint.documents) {
      const albums = [];
      this.complaint.documents.forEach(doc => {
        if (doc.mimetype.includes('image') && doc.base64) {
          albums.push({
            src: doc.base64,
            caption: doc.name,
            thumb: doc.link,
          });
        }
      });

      this._lightbox.open(albums);
    }
  }

  public addLog(extraParams: any = {}, extraOptions?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  }): void {
    extraParams.complaints = [this.complaint];
    if (this.complaint.stakeholders) {
      if (extraParams.stakeholders) {
        extraParams.stakeholders = [...extraParams.stakeholders, this.complaint.stakeholders];
      } else {
        extraParams.stakeholders = [this.complaint.stakeholders];
      }
    }

    if (this.complaint.residents) {
      extraParams.residents = [this.complaint.residents];
    }

    this._logs.editLog(null, null, extraParams, extraOptions);
  }

  public addLogDropzone(extraParams?: any, extraOptions?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  }): void {
    this.addLog(extraParams, extraOptions);
  }

  public generateMailtoLink(complaint: any, email: string): string {
    const url = `mailto:${email}?subject=Over de melding van ${new FormatdatePipe(this.locale).transform(complaint.date, 'dd-MM-yyyy', this.globals.timezone)}&body=`;
    const body = this._sanitizer.sanitize(SecurityContext.HTML, complaint.text);
    return `${url}-------------------%0D%0A%0D%0ABericht van melder: %0D%0A${body}`;
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  public editContact(): void {
    const modal = this.dialog.open(ComplaintContactModalComponent, modalConfig({
      data: {
        type: 'complaints',
        complaint: this.complaint,
        employees: this.employees,
        stakeholders: this.stakeholders,
        residents: this.residents,
        relatics: this.reducer,
      }
    }, ModalEnum.ModalDefault));

    modal.afterClosed().subscribe((complaint: Complaint) => {
      if (complaint) {
        this.parseComplaintResponse(complaint);
        this.updateComplaint(true);
      }
    });
  }

  public deleteComplaint(complaint): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(complaint, this.router, 'complaints')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._complaintsService.deleteComplaints(complaint).subscribe(
          () => {
            showFeedbackRemoved();
            this.close();
          });
      }
    });
  }

  updateSubprojects() {
    setTimeout(() => {
      this.subprojectUpdate = true;
      this.pubsub.updateSubprojects(this, "updateComplaint", this.complaint.subprojects)

    }, 500);
  }

  updateEntities(event: any) {
    if (event) {
      this.complaint[event.type] = event.selected;
      this.updateComplaint(true, false);
    }
    else {
      this.complaint.documents = []
      this.load(this.complaintId)
    }

  }
  //employed Set
  public employeeSet(): void {
    if (this.complaint.contactpersons) {
      this.complaint.contactpersons.selected = this.complaint.contactpersons.stakeholders.filter(x => this.complaint.stakeholders.find(s => s.id == x.id));
      if (this.complaint.contactpersons.stakeholders && this.complaint.contactpersons.stakeholders.length == 1) {
        this.complaint.stakeholders = this.complaint.contactpersons.stakeholders;
        if (!this.stakeholders.some(s => this.complaint.stakeholders[0].id === s.id)) {
          this.stakeholders.push(this.complaint.stakeholders[0]);
        }
      }
      this.complaint.residents = null;
    }
    this.updateComplaint();
  }
  //stakeholder update
  public onStakeholdersUpdated(event): void {
    if (event.action == "add") {
      this.complaint.stakeholders.push(event.data);
      this.stakeholderMultiselect.writeValue(this.complaint.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    else {
      this.complaint.stakeholders = this.complaint.stakeholders.filter(s => s.id != event.data.id);
      this.stakeholderMultiselect.writeValue(this.complaint.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    this.updateComplaint();
  }
  //stakeholder Set
  public stakeholderSet(): void {
    if (this.complaint.stakeholders) {
      this.complaint.residents = null;
    }
    // not to laod the whole complaint on stakeholder update
    this.subprojectUpdate = true;
    this.updateComplaint();
  }
  //resident Set
  public residentSet(): void {
    if (this.complaint.residents) {
      this.complaint.stakeholders = null;
      this.complaint.contactpersons = null;
    }
    this.updateComplaint();
  }
  @Allowed(Permission.ComplaintsUpdate)
  public updateSelectedTheme(theme) {
    if (this.complaint.complaintthemes === null) {
      this.complaint.complaintthemes = []
    }
    this.complaint.complaintthemes = theme;
    this.updateComplaint(true, false);
  }

  public editEmployee(employee: any): void {
    this.cachedService.getEmployeesWithLogs()
      .subscribe(employees => {
        employee = employees.find(e => e.id === employee.id);
        this.pubsub.closeModal(this.pubsub.currentDialog)
        document.body.classList.add("overflow-hidden");
        const editDialog = this.dialog.open(EmployeeEditComponent, modalConfig({
          data: employee,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
        }, ModalEnum.SidebarSmallResponsive));
        const name = `${employee.first_name || ""} ${employee.last_name || ""}`
        this.pubsub.updateHistory(editDialog, EmployeeEditComponent, employee, name, ModalEnum.SidebarSmallResponsive)

      });
  }
  //chat open modal
  public openChat() {
    const dialog = this.dialog.open(InAppChatComponent, modalConfig({
      data: {
        complaintMessagesParams: this.complaintMessagesParams,
        complaintId: this.complaint.id,
        isclose: this.complaint.is_closed,
        integrationType: this.integrationType.type,
        inAppIcon: this.integrationType.chatIcon,
        firstMessage: this.firstMessage,
        phone: this.complaint.complainant_phone,
        expand: true
      },
      panelClass: ['animate__animated', 'animate__slideInUp'],
      disableClose: true,
      closeOnNavigation: true
    }, ModalEnum.SidebarChatSmallResponsive));
    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.onCloseChat(response);
      }
    });
  }
  //get in app messages in chat
  public getInAppMessages(message, value) {
    if (!value) {
      if (this.integrationType.type == 'bouwapp') {
        this.getBouwappMessages(message)
      } else if(this.integrationType.type === 'whatsapp') {
        this.getWhatsappMessages(message)
      } else {
        this.getFreshdeskMessages(message)
      }
    }
  }
  // chat document attached
  public attachedDocumentToComplaint(inAppMessages, smart_reporting = false) {
    const documentset = []
    if (smart_reporting) {
      documentset.push({
        id: inAppMessages.id,
        path: inAppMessages.attachmentThumbnails.full,
        name: inAppMessages.attachment,
        type: 'bouwapp'
      })
    } else {
      inAppMessages.filter(element => {
        if (element.attachment != null) {
          documentset.push({
            id: element.id,
            path: element.attachmentThumbnails.full,
            name: element.attachment,
            type: 'bouwapp'
          })
        }
      })
    }
    documentset.forEach(element => {
      this.complaint.documents.push(element);
      this.integrationDocuments.push(element.id)
    })
  }
  public relaticsValidation() {
    if (this.complaint.relatics_uuid) {
      if (this.globals.relaticsColumn.complaints) {
        let columns;
        const dialogcolumns = [];
        // eslint-disable-next-line prefer-const
        columns = this.globals.relaticsColumn.complaints.columns;
        columns.map((element) => {
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer = dialogcolumns.reduce((ac, a) => ({ ...ac, [a]: a }), {});
        this.cd.detectChanges();
      }
    }
  }
  public loadKPIcolorSet(type, value) {
    return setKPIStyle(type, value, this.globals);
  }
  public subprojectSetup() {
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects, 'complaints:update')
  }
  public setLodedSubproject() {
    this.chipDisabler = [];
    this.chipDisabler = selectedSubprojectSaprator(this.subprojectsSet, this.complaint.subprojects)
  }
  public usersSetup() {
    const activeSubProject = this.subProjectQuery.getActive();
    if (activeSubProject && userRoleFinder(this.globals)) {
      this.users = globalSubprojectUserSaprator(this.users, activeSubProject)
    }
  }
  public setLodedSubprojectUsers() {
    this.chipDisablerUser = [];
    if (this.users) {
      const activeSubProject = this.subProjectQuery.getActive();
      if (activeSubProject && userRoleFinder(this.globals)) {
        this.chipDisablerUser = selectedSubprojectUsersSaprator(this.users, this.complaint.users);
      }
      const deletedUsers = deletedUsersForClosedTickets(this.complaint.is_closed, this.complaint.users);
      this.chipDisablerUser = [...this.chipDisablerUser, ...deletedUsers]
    }
  }

  addManualInformationAsContactperson() {
    const data: NewEntityData = {
      params: {
        name: this.complaint.complainant_name,
        email: this.complaint.complainant_email,
        telephone: this.complaint.complainant_phone,
        type: 'complaints'
      },
      options: {},
    };
    // Open modal to Create newEmployee
    const dialog = this.dialog.open(EmployeeNewComponent, modalConfig({
      data: data,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));

    dialog.afterClosed().subscribe((response: any) => {
      this.cachedService.getEmployees().subscribe(
        employees => {
          this.employees = employees;
          for (const employee of employees) {
            if (employee.id === response.employee.id) {
              this.complaint.contactpersons = employee;
              this.complaint.stakeholders = [...this.complaint.stakeholders, ...response.employee.stakeholders];
              this.complaint.complainant_name = null;
              this.complaint.complainant_email = null;
              this.complaint.complainant_phone = null;
              this.employeeSet();
            }
          }
        }
      );
    })
  }

  // smart reporting document
  public smartReporting(): void {
    this.integration_themeDisable = true;
    this._inAppChatService.getSmartReporting(this.complaintMessagesParams.type, this.complaintMessagesParams.ticket_id)
      .subscribe(res => {
        this.firstMessage = integrationDataMapInertial(res);
        if (res.attachment) {
          this.attachedDocumentToComplaint(res, true)
        }
      })
  }
  // on save remove document
  public onSaveDocumentRemoval() {
    if (this.complaint.documents.length) {
      this.complaint.documents = this.complaint.documents.filter(element => {
        if (!this.integrationDocuments.includes(element.id)) {
          return element;
        }
      })
    }
  }
  //get bouwapp messages
  public getBouwappMessages(message) {
    if (integrationValidation(this.globals, this.integrationType.type, this.integrationType.error_type, this.errorMessage_userleve) === 'project') {
      this._inAppChatService.getMessages(message.ticket_id, message.type)
        .pipe(
          tap((res) => {
            this.attachedDocumentToComplaint(res);
          }),
        ).subscribe();
    } else {
      this._inAppChatService.getUserMessages(message.ticket_id, message.type, this.globals.user.id)
        .pipe(
          tap((res) => {
            this.attachedDocumentToComplaint(res);
          }),
        ).subscribe();
    }
  }
  //get freshdesk messages
  public getFreshdeskMessages(message) {
    if (integrationValidation(this.globals, this.integrationType.type, this.integrationType.error_type, this.errorMessage_userleve) === 'project') {
      this._inAppChatService.getticket(message.ticket_id)
        .pipe(
          tap((res: any) => {
            if (res) {
              this.firstMessage = integrationDataMapInertial(res);
              const documentset = freshdeskIntegrationDocumentAttached(res);
              documentset.forEach(element => {
                this.complaint.documents.push(element);
                this.integrationDocuments.push(element.id)
              })
              if (this.data.enableChatModal) {
                this.openChat();
              }
            } else {
              this.integrationsEnabled = false;
            }
          })
        ).subscribe();
    }
  }

  getWhatsappMessages(message) {
    if (integrationValidation(this.globals, this.integrationType.type, this.integrationType.error_type, this.errorMessage_userleve) === 'project') {
      const body = {
        id: this.complaintId
      }
      this._inAppChatService.getWhatsAppMessages(body).pipe(
        tap((res: any) => {
          if (res) {
            // this.firstMessage = integrationDataMapInertial(res);
            if (this.data.enableChatModal) {
              this.openChat();
            }
          } else {
            this.integrationsEnabled = false;
          }
        })
      ).subscribe();
    }

  }
  // bouwapp user authenticate function
  public userAuthenticate(): void {
    if (this.integrationType.type == "bouwapp") {
      this._inAppChatService.userAuthenticate(this.globals.user.id, this.complaint.project_code).subscribe((res: any) => {
        if (res.authorized)
          this.userChatButtonShow()
      })
    } else {
      this._projectConfigsService.getConfigsErrorMessage('freshdesk')
        .pipe(
          tap((res: any) => {
            if (res.enabled && res.authorized) {
              this.userChatButtonShow()
            } else {
              this.integrationsEnabled = false;
            }
          })
        ).subscribe();
    }
  }
  // integration in app chat user show
  public userChatButtonShow() {
    if (this.integrationType.chatShow) {
      this.integrationsEnabled = true;
      if (!integrationConnection(this.globals, this.integrationType.type, this.complaint)) {
        this.integrationsEnabled = false;
      }
    }
    if (this.integrationsEnabled) {
      this.getInAppMessages(this.complaintMessagesParams, this.firstload);
    }
    this.firstload = true;
  }
  public parseDate(dateString: string): Date {
    if (dateString) {
      return dateIso(dateString, true, this.globals.timezone)
    }
    return null;
  }
  public onDeadlineChange() {
    this.complaint.deadline = deadlineWithKPI(this.globals, this.complaint.priority, this.complaint.date);
    this.updateComplaint();
  }
  //after chat closed
  public onCloseChat(response): void {
    if (response.is_closed) {
      this.complaint.is_closed = response.is_closed;
      this.complaint.closed_date = response.closed_date;
      this.complaint.closed_description = response.closed_description;
      this.updateComplaint();
    }
  }
  //integration close chat function
  public onCloseChatFromDetailPanel(): void {
    this._inAppChatService.messagesStatusClosed(this.complaintMessagesParams.ticket_id, this.complaintMessagesParams.type, { status: "CLOSED", reason: this.complaint.closed_description }).subscribe();
  }

  sentimentValue() {
    if (this.complaint.sentiments && this.complaint.sentiments.length) {
      const sentimentScore: number = this.complaint.sentiments[0].user_score ?
        this.complaint.sentiments[0].user_score :
        this.complaint.sentiments[0].sentiment_score;

      if (sentimentScore === 1 || sentimentScore === 2) {
        this.selectedIcon = this.sentiments[0];
      }
      else if (sentimentScore === 3) {
        this.selectedIcon = this.sentiments[1];
      }
      else if (sentimentScore === 4 || sentimentScore === 5) {
        this.selectedIcon = this.sentiments[2];
      }
    }
  }
  public sentimentChange(selected) {
    this.selectedIcon = selected;
    this.complaint.sentiments[0].user_score = this.selectedIcon.value;
    this.updateComplaint();
  }
}
