<div mat-dialog-title *ngIf="stakeholders && stakeholderTypes">
  <h4 class="row mb-10 inline-block">Stakeholders aanmaken</h4>
  <button type="button" class="close" (click)="close()"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
</div>

<div class="loading" *ngIf="!stakeholders || !stakeholderTypes">
  <h4 class="pb-13">{{ 'text.stakeholder_batch_create_loading' | transloco }}</h4>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div [hidden]="!stakeholders || !stakeholderTypes">
  <form #form="ngForm" (ngSubmit)="submit()" autocomplete="off">
    <div class="col-md-6 divider-vertical">
      <div style="display: flex; align-items: center;">
        <h4>Omwonenden</h4>
        <mat-paginator #paginatorResidents [pageSize]="10" [length]="stakeholders.residents.data.length" [hidden]="stakeholders.residents.data.length === 0"></mat-paginator>
      </div>
      <div>
        <i *ngIf="stakeholders.residents.data.length === 0">{{ 'text.stakeholder_batch_not_exist_resident' | transloco }}</i>
        <table mat-table [dataSource]="stakeholders.residents">
          <ng-container matColumnDef="residents">
            <td mat-cell *matCellDef="let resident">
              <mat-checkbox [checked]="resident.checked" class="col-md-8 mt-15">{{ resident.name | truncatie: 30 }}</mat-checkbox>
              <mat-form-field  class="col-md-4" *ngIf="defaultResidentType">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="resident.stakeholdertypes" [compareWith]="idComparator" [name]="resident.id">
                  <mat-option *ngFor="let type of stakeholderTypes; trackBy: trackByFn" [value]="type">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: ['residents'];">
        </table>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex" style="align-items: center;">
        <h4>Andere</h4>
        <mat-paginator #paginatorOthers [pageSize]="10" [length]="stakeholders.others.data.length" [hidden]="stakeholders.others.data.length === 0"></mat-paginator>
        <mat-dialog-actions align="end" style="margin-bottom: 0px;">
          <div class="dialog-button-row">
            <button type="submit" mat-flat-button class="btn d-btn-dialog mdl-button mdl-js-button mdl-button--raised" style="left: auto" [disabled]="isProcessing">
              <span *ngIf="!isProcessing">
                <mat-icon class="material-icons d-text-middle">save</mat-icon>
                Voeg toe
              </span>
              <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
            </button>
          </div>
        </mat-dialog-actions>
      </div>
      <div>
        <i *ngIf="stakeholders.others.data.length === 0">{{ 'text.stakeholder_batch_not_exist_other' | transloco }}</i>
        <table mat-table [dataSource]="stakeholders.others">
          <ng-container matColumnDef="others">
            <td mat-cell *matCellDef="let other">
              <mat-checkbox [checked]="other.checked" class="col-md-8 mt-15">{{ other.name | truncatie: 30}}</mat-checkbox>
              <mat-form-field class="col-md-4" *ngIf="defaultResidentType">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="other.stakeholdertypes" [compareWith]="idComparator" [name]="other.id">
                  <mat-option *ngFor="let type of stakeholderTypes; trackBy: trackByFn" [value]="type">
                    {{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: ['others'];">
        </table>
      </div>
    </div>
  </form>
</div>
