<div>
  <mat-radio-group aria-label="Select an option" style="display: flex; flex-direction: column; margin: 15px 0;" #option>
    <mat-radio-button *ngFor="let option of options" [value]="option.value" [checked]="option.checked" (change)="updateOption(option.value)">{{ option.title }}</mat-radio-button>
  </mat-radio-group>
</div>
<div>
  <div class="dialog-button-row">
    <button type="button" mat-stroked-button (click)="cancel()">Annuleren</button>
    <button type="button" mat-flat-button (click)="submit()">Opslaan</button>
  </div>
</div>
