// facebook.service.ts
import { Injectable } from '@angular/core';
import { WhatsappService } from '../api/whatsapp.service';
import { Globals } from 'src/app/globals';
import { GenericComponent } from '../../util/abstract/generic-component';
import { EventBusService } from './event-bus-service.service';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    fbq: any;
  }
}

declare let FB: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookService extends GenericComponent {
  public projectCode;
  public usersNumbers: any
  public mobileNumberId: any
  constructor(private _whatsAppService: WhatsappService, public globals: Globals, private eventBus: EventBusService) {
    super(globals);
    // Listen for messages
    window.addEventListener('message', this.sessionInfoListener.bind(this))
  }


  login(): Promise<any> {

    return new Promise((resolve, reject) => {
      // Conversion tracking code
      if (window.fbq) {
        window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: this.globals.whatsapppAppId, feature: 'whatsapp_embedded_signup' });
      } else {
        console.error('Facebook Pixel (fbq) not initialized');
      }
      FB.login(response => {
        if (response.authResponse) {
          if (response.authResponse.code) {
            this.getCode(response.authResponse.code)
          }
          resolve(response);
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      }, {
        config_id: this.globals.whatsapppConfigId, // configuration ID goes here
        response_type: 'code', //must be set to 'code' for System User access token
        scope: "business_management, whatsapp_business_management, whatsapp_business_messaging",
        override_default_response_type: true, //when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          sessionInfoVersion: 2, //Receive Session Logging Info
        }
      });
    });
  }

  private sessionInfoListener(event: MessageEvent) {
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          if (phone_number_id && waba_id) {
            this.mobileNumberId = phone_number_id
            this.whatsappintegration(waba_id, phone_number_id)

          }
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          console.log(current_step)
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log('Non JSON Response', event.data);
    }
  }

  // Additional methods like getStatus, logout, etc.


  whatsappintegration(wabaId: any, phoneNumberId: any) {
    const requestBody = {
      "waba_id": wabaId,
      "phone_number_id": phoneNumberId
    };

    this._whatsAppService.getWhatsappintegration(requestBody).subscribe(
      (response) => {
        // Handle successful response here
        console.log('updated...')
      },
      (error) => {
        // Handle error response here
        console.error(error);
      }
    );
  }


  getCode(code: any) {
    this._whatsAppService.getTokken(code).subscribe(
      (response) => {
        this.eventBus.emitVerifyDataConfigs('verify');
      },
      (error) => {
        // Handle error response here
        console.error(error);
      }
    );
  }

}
