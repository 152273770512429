import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  @Input() scrollClass: string="scrolled";

  // eslint-disable-next-line no-empty-function
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener("scroll", [])
  onScroll() {
    if (this.el.nativeElement.scrollTop > 20) {
      document.querySelector(".popup-header").classList.add(this.scrollClass)
    }
    else {
      document.querySelector(".popup-header").classList.remove(this.scrollClass)

    }
  }

}
