<div class="accordion-container" *transloco="let t">
    <mat-accordion class="custom-accordion">
        <ng-container *ngFor="let type of types; let i = index;">
            <ng-container *ngIf="parent && parent[type]">
                <mat-expansion-panel [disabled]="type==='tasks'? !openTasksExists : !parent[type].length" [ngClass]="type" class="custom-expand" #connectionPanel>
                    <mat-expansion-panel-header *transloco="let t;">
                        <mat-icon class="d-text-middle col-md-1 col-xs-1 entity-icon" [ngClass]="[entityTypes[type].icon, type === 'tasks' ? (openTasksExists ? '' : 'entity-icon-disabled') : (!parent[type].length ? 'entity-icon-disabled' : '')]"></mat-icon>
                        <span class="col-md-1 col-xs-1 entity-count">
                            <span class="badge" [ngClass]="{'badge-disabled' : type==='tasks'? !openTasksExists : !parent[type].length}">{{type==='tasks'? openTasksCount : parent[type].length}}</span>
                        </span>
                        <span class="col-md-4 col-xs-2 header-title" style="margin-left: 5px;">
                            {{entityTypes[type].title | transloco | capitaliz }}
                        </span>
                        <span class="col-md-4 col-xs-6" *ngIf="parentType !== 'layers'">
                            <div *hasPermission="[Permission.LogsCreate]">
                                <app-email-log-dropzone *ngIf="type == 'contactmoments'" class="dropzone-small" text="{{'logs.drop_email' | transloco | capitaliz}}"
                                (click)="$event.stopPropagation()"
                                (onFileUploaded)="addLog(null,i, $event.extraParams, $event.extraOptions)"></app-email-log-dropzone>
                            </div>
                        <span class="badge pull-right label">{{getLabel(type)}}</span>
                            <mat-icon *ngIf="hasImages(type)" class="pull-right picture-icon d-text-middle d-cursor-hand">panorama
                            </mat-icon>
                        </span>
                        <span *ngIf="parentType === 'layers'" class="col-md-2 col-xs-6"></span>
                        <span *transloco="let t;" class="col-md-1 col-xs-1 mr-1">
                            <div *ngIf="(hasPermission(Permission.PermitsCreate) && type=='permits') || (hasPermission(Permission.IssuesCreate) && type=='issues') || (hasPermission(Permission.StakeholdersCreate) && type=='stakeholders') || (hasPermission(Permission.DecisionsCreate) && (type=='requirements_by' || type == 'requirements')) || (hasPermission(Permission.ActionCreate) && type == 'tasks') || (hasPermission(Permission.LogsCreate) && type == 'contactmoments') || (hasPermission(Permission.ComplaintsCreate) && type == 'complaints') || (hasPermission(Permission.MiddelCreate) && type == 'contents') || (hasPermission(Permission.ActivitiesCreate) && type == 'activities') || (hasPermission(Permission.DocumentsCreate) && type == 'documents')">
                            <mat-icon *ngIf="type != 'layers'" class="uil uil-link"
                            [matTooltip]="languageBasedOnType(entityTypes[type].label, 'connect')+' '+t(entityTypes[type].label)" (click)="toggle($event,type,i)" data-link-icon>
                            </mat-icon>
                        </div>
                        </span>
                        <span *transloco="let t;" class="col-md-1 col-xs-1">
                            <div *ngIf="(hasPermission(Permission.PermitsCreate) && type=='permits') || (hasPermission(Permission.IssuesUpdate) && type=='issues') || (hasPermission(Permission.StakeholdersCreate) && type=='stakeholders') || (hasPermission(Permission.DecisionsUpdate) && (type=='requirements_by' || type == 'requirements')) || (hasPermission(Permission.ActionUpdate) && type == 'tasks') || (hasPermission(Permission.LogsUpdate) && type == 'contactmoments') || (hasPermission(Permission.ComplaintsUpdate) && type == 'complaints') || (hasPermission(Permission.MiddelUpdate) && type == 'contents') || (hasPermission(Permission.ActivitiesUpdate) && type == 'activities') || (hasPermission(Permission.DocumentsUpdate) && type == 'documents')">
                                <mat-icon  *ngIf=" type != 'documents' && type != 'layers' && type != 'relatics'"  class="uil uil-plus-circle"
                                [matTooltip]="languageBasedOnType(entityTypes[type].label, 'new')+' '+t(entityTypes[type].label)" (click)="add($event,type,i)" data-add-icon>
                              </mat-icon>
                              <mat-icon *ngIf="type == 'documents'" class="uil uil-plus-circle"
                              [matTooltip]="languageBasedOnType(entityTypes[type].label, 'new')+' '+t(entityTypes[type].label)"
                              [documentSidebar]="entityTypes[parentType].docType" [documentSidebarDocuments]="parent.documents"
                              [documentSidebarId]="parent.id" (documentUploaded)="onDocumentUploaded($event,i)"
                              (documentRemoved)="onDocumentRemoved($event)"></mat-icon>
                            </div>
                        </span>
                        <span [matTooltip]="!parent[type].length?t('text.no')+' '+t(entityTypes[type].title):''" *ngIf="type==='tasks'? !openTasksExists : !parent[type].length" class="mat-expansion-indicator  disabled ng-tns-c42-28 ng-trigger ng-trigger-indicatorRotate ng-star-inserted"></span>

                    </mat-expansion-panel-header>
                    <!-- Contact Moments -->
                    <div *ngIf="type == 'contactmoments'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','type', 'date', 'description', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- ISSUES -->
                    <div *ngIf="type == 'issues'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id', 'name', 'urgency', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Stakeholders -->
                    <div *ngIf="type == 'stakeholders'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id', 'name','action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Requirements -->
                    <div *ngIf="type == 'requirements_by' || type == 'requirements'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id', 'name', 'status', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Tasks -->
                    <div *ngIf="type == 'tasks'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [isExpension]="entityTypes[type].showMultiselect" [entities]="parent[type]" [displayedColumns]="(parent[type].contactpersons_contactmoments && parent[type].contactpersons_contactmoments.length) ? ['id','handled','name', 'deadline', 'action'] : ['id','handled','name', 'contact_person', 'deadline', 'action']" (openDetail)="onDetail($event)" (itemHandled)="onUpdateHandle($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Complaints -->
                    <div *ngIf="type == 'complaints'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','date', 'name', 'status', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Permits -->
                    <div *ngIf="type == 'permits'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','name', 'permit_status', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Contents -->
                    <div *ngIf="type == 'contents'">
                      <ng-container *ngIf="parentType == 'stakeholders' || parentType == 'residents'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','started_at', 'name', 'group', 'contents_status', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                      </ng-container>
                      <ng-container *ngIf="parentType == 'tasks'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','started_at', 'name', 'contents_status', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                      </ng-container>
                      <ng-container *ngIf="parentType == 'activities'">
                        <app-relation-overview [type]="type" [entities]="parent[type]" [displayedColumns]="['id','started_at', 'name', 'contents_status']" (openDetail)="onDetail($event)"></app-relation-overview>
                      </ng-container>
                    </div>
                    <!-- Activities -->
                    <div *ngIf="type == 'activities'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','date', 'name', 'action']" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
                    <!-- Documents -->
                    <div *ngIf="type == 'documents'">
                        <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                        <app-documents-overview *ngIf="!itemRefresh" [entities]="parent.documents" [documents]="parent.documents" (onDocumentRemoved)="onDocumentRemoved($event)" [connectedToExistingEntity]="!!parent.id" [type]="entityTypes[parentType].docType" [id]="parent.id">
                        </app-documents-overview>
                    </div>
                    <!-- Layers -->
                    <div *ngIf="type == 'layers'">
                      <mat-progress-bar *ngIf="itemRefresh" mode="indeterminate"></mat-progress-bar>
                      <app-relation-overview *ngIf="!itemRefresh" [type]="type" [entities]="parent[type]" [displayedColumns]="['id','layers', 'layer_name', 'layer_item_description','action']" [isExist]="isExist" (openDetail)="onDetail($event)" (itemDelete)="onDelete($event)"></app-relation-overview>
                    </div>
          </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>
</div>


<div #filterSort class="filter-selector" *ngIf="type">
    
    <div *ngIf="entityTypes[type].showMultiselect" class="filter-dropdown animate__animated animate__fadeIn" [style.top.px]="position.top" [style.left.px]="position.left">
        <app-generic-multiselect-checkbox placeholder="{{'text.select' | transloco}} {{type+'.labels' | transloco}}" [(ngModel)]="parent[type]" (ngModelChange)="link(type)" name="type" [multiple]="true" [maxChipItems]="4" [maxChipLength]="20">
        <div *ngIf="type === 'issues' || type === 'activities' || type === 'documents' || type === 'stakeholders' || type === 'permits'">
            <app-generic-multiselect-checkbox-option *ngFor="let entity of filteredOptions" [value]="entity">
                {{  entity.name | truncatie: 30 }}
            </app-generic-multiselect-checkbox-option>
        </div>
        <div *ngIf="type === 'tasks'">
            <app-generic-multiselect-checkbox-option *ngFor="let entity of filteredOptions" [value]="entity">
                {{ entity.text | truncatie: 30 }}
            </app-generic-multiselect-checkbox-option>
        </div>
        <div *ngIf="type === 'contents'">
            <app-generic-multiselect-checkbox-option *ngFor="let entity of filteredOptions" [value]="entity">
                {{ entity.type | truncatie: 30 }}
            </app-generic-multiselect-checkbox-option>
        </div>
        <div *ngIf="type == 'requirements_by' || type == 'requirements'">
            <app-generic-multiselect-checkbox-option *ngFor="let entity of filteredOptions" [value]="entity">
                {{ entity.title | truncatie: 30 }}
            </app-generic-multiselect-checkbox-option>
        </div>
        <div *ngIf="type === 'complaints'">
            <app-generic-multiselect-checkbox-option *ngFor="let entity of filteredOptions" [value]="entity" [innerHTML]="entity.text | safeHtml" class="html-content">
    
            </app-generic-multiselect-checkbox-option>
        </div>
        <div *ngIf="type === 'contactmoments'">
            <app-generic-multiselect-checkbox-option *ngFor="let entity of filteredOptions" [value]="entity">
                {{ entity.contact_moment_timestamp | formatdate : 'dd-MM-yyyy' : globals.timezone }} {{ entity.title ? ': ' + (entity.title | truncatie: 20) : ':(Contactmoment zonder titel)' }}
            </app-generic-multiselect-checkbox-option>
        </div>
        </app-generic-multiselect-checkbox>
    </div>
    
</div>
