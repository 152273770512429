import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {DictionaryService} from "../../core/service/api/dictionary.service";

@Directive({
  selector: '[highlightText]'
})
export class HighlightTextDirective implements OnInit {

  @Input() highlightText: string;
  @Input() highlightTextSize = 20;
  @Input() highlightTextObject: any;
  @Input() highlightTextFields = [];

  // eslint-disable-next-line no-empty-function
  constructor(private el: ElementRef, private _dictionaryService: DictionaryService) { }

  ngOnInit(): void {
    this.updateView();
  }


  private updateView() {
    this.highlightTextFields.forEach((field: string) => {
      if (!this.highlightTextObject[field] || typeof this.highlightTextObject[field] !== 'string') {
        return;
      }

      const foundText = this.searchText(this.highlightTextObject[field], this.highlightText);
      if (foundText) {
        const column = this._dictionaryService.translate(field);
        if (!column) {
          this.el.nativeElement.innerHTML = foundText;
        } else {
          this.el.nativeElement.innerHTML = `${column.substr(0, 1).toUpperCase()}${column.substr(1)}: ${foundText}`;
        }
      }

    });
  }

  private searchText(text: string, search: string) {
    const index = text.toLowerCase().indexOf(search.toLowerCase());
    if (index > -1) {
      const start = text.substr(0, index);
      const highlight = `<b>${text.substr(index, search.length)}</b>`;
      const end = text.substr(index + search.length);
      let finalText = `${start}${highlight}${end}`;

      if (finalText.length > (this.highlightTextSize + search.length)) {
        if (start.length < 5) {
          const truncatedText = `${start}${highlight}`;
          if (truncatedText.length > this.highlightTextSize) {
            finalText = truncatedText;
          } else {
            finalText = `${`${truncatedText}${end}`.substr(0, this.highlightTextSize + 7) }...` ;
          }
        } else if (start.length >= 5 && `${highlight}${end}`.length < (this.highlightTextSize + 7)) {
          finalText = `...${highlight}${end}`;
        } else {
          if (highlight.length < (this.highlightTextSize + 7)) {
            finalText = `...${highlight}${end.substr(0, this.highlightTextSize - search.length)}...`;
          } else {
            finalText = `...${highlight}...`;
          }
        }
      }

      return finalText;
    }

    return null;
  }

}
