<div mat-dialog-title>
  <h4 class="mb-10 inline-block">{{ 'new_calender' | transloco | capitaliz }} {{'activities.label' | transloco }}</h4>
  <button (click)="resetForm()" aria-label="Close" class="close modal-close-button" type="button">
    <span aria-hidden="true"><i class="d-text-middle uil uil-multiply uncion-icon"></i></span><span class="sr-only">Close</span>
  </button>
</div>

<form class="form-inline" [formGroup]="activityForm" (ngSubmit)="createEvent()">

  <mat-dialog-content>
    <div class="col-xs-12 col-sm-12 col-md-6">
      <div class="col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>{{ 'reusable.activity_naam' | transloco }}</mat-label>
          <input matInput formControlName="name" required>
        </mat-form-field>
      </div>
      <div class="col-xs-12">
          <app-add-connect-themes [type]="themeType" [buttonText]="'activities.theme_button_text'" [textMultiple]="'types'" [textSingle]="'type'" [editable]="false"
          (onThemeSelected)="updateSelectedTheme($event)"></app-add-connect-themes>

      </div>

      <div class="form-inline" formGroupName="calendars" class="form-group gd-auto-horizontal mt-10">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <mat-form-field class="full-width">
            <mat-label>{{ 'reusable.begindatum' | transloco }}</mat-label>
            <input matInput #inputStart [matDatepicker]="pickerStart" formControlName="start" (dateChange)="getMinDate()" (click)="pickerStart.open()" required>
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <mat-form-field class="full-width">
            <mat-label>{{ 'reusable.einddatum' | transloco }}</mat-label>
            <input matInput [min]="dateMinActivity" [matDatepicker]="pickerEnd" formControlName="end" (click)="pickerEnd.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6">
      <!-- Label Section -->
      <div class="col-xs-12 mb-3">
        <label>
          {{ 'reusable.activiteit_locatie' | transloco }}
        </label>
      </div>

      <!-- Map Section -->
      <div class="col-xs-12">
        <app-openlayers-map
          type="activities"
          [includeSidebar]="true"
          [collapseSidebar]="true"
          [sidebarButtons]="['length', 'point']"
          [defaultCoordinate]="globals.projectConfig.default_map"
          [showMapReset]="true"
          [maxPoints]="1"
          [maxLines]="1"
          formControlName="locations"
          name="locations"
          locatableType="calendars"
          defaultDrawTool="point"
          clickStartDrawingMsg="Teken activiteitpunt"
        ></app-openlayers-map>
      </div>
    </div>

  </mat-dialog-content><!--modal body end-->

  <mat-dialog-actions align="end" class="full-width">
    <div class="dialog-button-row">
      <button *ngIf="showPreviousStep" type="button" mat-stroked-button (click)="resetForm()">
        {{'previous_step' | transloco}}
      </button>
      <button type="button" mat-stroked-button data-dismiss="modal" (click)="resetForm()">{{'close' | transloco | capitaliz}}</button>
      <button type="submit" mat-flat-button>
        <mat-icon class="material-icons d-text-middle">save</mat-icon>
        {{ 'add' | transloco | capitaliz }}
      </button>
    </div>
  </mat-dialog-actions><!--end of modal footer -->
</form>
