import {Component, OnInit} from '@angular/core';
import {ResidentsService} from "../../../../../core/service/api/residents.service";
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {GenericComponent} from "../../../../../core/util/abstract/generic-component";
import {Globals} from "../../../../../globals";
import {Router} from "@angular/router";
import {StakeholdersService} from "../../../../../core/service/api/stakeholders.service";
import {finalize} from 'rxjs/operators';
import {ProjectConfigsService} from '../../../../../akita/project-configs/state/project-configs.service';


@Component({
  selector: 'app-residents-migraterow-popup',
  templateUrl: './residents-migraterow-popup.component.html',
  styleUrls: ['./residents-migraterow-popup.component.scss']
})

export class ResidentsMigraterowPopupComponent extends GenericComponent implements OnInit {

  DataTypes: any;
  isLoadingResults = true;

  StakeHolderForm: FormGroup;
  submitted = false;


  constructor(
    private formBuilder: FormBuilder,
    private residentsService: ResidentsService,
    private stakeholdersService: StakeholdersService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ResidentsMigraterowPopupComponent>,
    public globals: Globals,
    private _configsService: ProjectConfigsService,
    private router: Router
  ) {
    super(globals);
  }

  ngOnInit() {

    this.StakeHolderForm = this.formBuilder.group({
      type_id: ['', Validators.required]
    });

    this.getTypeStakeholders();
  }

  get f() {
    return this.StakeHolderForm.controls;
  }

  // Type Stakeholders
  getTypeStakeholders() {
    const result = this.stakeholdersService.getAllStakeholderType()
      .subscribe((res: any) => {
        this.DataTypes = res.data;
      });
    return result;
  }

  // Migrate All Resident
  migrateAllResidents(form: NgForm) {
    this.submitted = true;
    this.isLoadingResults = true;

    if (!this.StakeHolderForm.invalid) {
      this.residentsService.migrateAllResident(form)
      .pipe(finalize((() => this.isLoadingResults = false)))
      .subscribe((res: any) => {
        this._configsService.get()
          .subscribe(() => {
            if (this.globals.projectConfigs['residents.disabled']) {
              this.router.navigate(['stakeholders']);
            }
          });
        this.isLoadingResults = false;
        this.StakeHolderForm.reset();
        this.dialogRef.close();
      });
    }
  }
}
