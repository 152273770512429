import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BatchOverviewSettingComponent } from '../batch-overview-setting/batch-overview-setting.component';
import { StakeholdersService } from 'src/app/core/service/api/stakeholders.service';
import { MapService } from 'src/app/core/service/api/map.service';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { DecisionsService } from 'src/app/akita/requirements/state/decisions.service';
import { ComplaintsService } from 'src/app/core/service/api/complaints.service';
import moment from 'moment';
import { IssuesService } from 'src/app/core/service/api/issues.service';
import { ActivitiesService } from 'src/app/core/service/api/activities.service';
import { translate } from '@jsverse/transloco';
import { concatMap, from, Observable, reduce, tap } from 'rxjs';

@Component({
  selector: 'app-create-batch-dialog-items',
  templateUrl: './create-batch-dialog-items.component.html',
  styleUrls: ['./create-batch-dialog-items.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateBatchDialogItemsComponent {

  public reports = [
    {type: 'stakeholders'},
    {type: 'issues'},
    {type: 'activities'},
    {type: 'complaints'},
    {type: 'requirements'},
  ];

  public isProcessing: boolean = false;
  public stakeholders: any;
  public dialogItems: any = [];
  public selectedItemType: string = '';
  public createdDialogItems: any[] = [];
  public attachInfo: string = '';

  constructor(public dialogRef: MatDialogRef<BatchOverviewSettingComponent>,
      // eslint-disable-next-line no-empty-function
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _mapService: MapService,
      private _configsService: ConfigsService,
      private cd: ChangeDetectorRef,
      private _stakeholdersService: StakeholdersService,
      private _decisionsService: DecisionsService,
      private _complaintsService: ComplaintsService,
      private _issuesService: IssuesService,
      private _activityService: ActivitiesService,
  ) { 
    console.log('create-batch-dialog-items...')
  }

  updateSelectedItem() {
    // eslint-disable-next-line prefer-template
    this.attachInfo = `${translate('create_dialog_items.creating_item_1')} ${translate(this.selectedItemType + '.labels')} ${translate('create_dialog_items.creating_item_2')}`
    this.cd.detectChanges();
  }

  create() {
    this.isProcessing = true;
  
    const configMap = {
      stakeholders: {
        serviceMethod: (entity: any) => this._stakeholdersService.submitStakeholder(entity),
        createEntity: (layer: any) => ({ name: layer.title })
      },
      requirements: {
        serviceMethod: (entity: any) => this._decisionsService.create(entity),
        createEntity: (layer: any) => ({ title: layer.title })
      },
      complaints: {
        serviceMethod: (entity: any) => this._complaintsService.submitComplaint(entity),
        createEntity: (layer: any) => ({ text: layer.title, date: new Date() })
      },
      issues: {
        serviceMethod: (entity: any) => this._issuesService.submitIssue(entity),
        createEntity: (layer: any) => ({
          name: layer.title,
          issues_date: new Date(),
          urgency: 'middle',
          issues_status: 'open',
          impact: 'low',
          deadline: moment(new Date()).add(1, 'months'),
          issues_score: 41.66
        })
      },
      activities: {
        serviceMethod: (entity: any) => this._activityService.submitActivity(entity),
        createEntity: (layer: any) => ({
          calendars: {
            start: new Date(),
            title: layer.title
          },
          name: layer.title
        })
      }
    };
  
    const config = configMap[this.selectedItemType];
    if (config) {
      this.submitEntities(
        this.data.layers,
        (layer) => config.serviceMethod(config.createEntity(layer))
      );
    } else {
      console.error('Invalid item type selected:', this.selectedItemType);
      this.isProcessing = false;
    }
  }
  
  submitEntities(layers: any[], submitFn: (entity: any) => Observable<any>): void {
    from(layers)
      .pipe(
        concatMap((layer: any) =>
          submitFn(layer).pipe(
            tap((response) => {
              // Process each response immediately
              layer['type'] = this._mapService.mainLayers.values_.layer_type;
              layer['main_layer_id'] = this._mapService.mainLayerId;
              layer['sub_layer_id'] = 0;
              layer['sub_layer_name'] = this._mapService.mainLayers.values_.name;
              this.data[this.selectedItemType] = [response];
              const data = {layers: [layer], [this.selectedItemType]: [response]}
              delete this.data.dialogItems;
  
              // Call batchLayerConnection for each response
              this._configsService.batchLayerConection(data).subscribe();
            })
          )
        )
      )
      .subscribe({
        error: (err) => {
          console.error('An error occurred:', err);
          this.isProcessing = false;
        },
        complete: () => {
          showFeedbackSaved();
          this.isProcessing = false;
          this.dialogRef.close();
        }
      });
  }

}
