import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { itemsMergeLogic } from 'src/app/akita/batch/batch';
import { SearchService } from 'src/app/core/service/api/search.service';
import { showFeedbackError, showFeedbackSaved } from 'src/app/core/util/notification';

@Component({
  selector: 'app-batch-merge',
  templateUrl: './batch-merge.component.html',
  styleUrls: ['./batch-merge.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchMergeComponent {
  public primaryItem: number;
  public approval: boolean = false;
  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<BatchMergeComponent>,private searchService: SearchService) { }

  //on close modal
  public onClose(): void{
    this.dialogRef.close();
  }
  //on submit
  public submit(): void{
    this.searchService.batchMerge(this.data.type,itemsMergeLogic(this.data.type,this.data.items,this.primaryItem))
    .pipe(
      tap(() => {
        showFeedbackSaved();
        this.dialogRef.close(true);
      }),
      catchError(async (err) => showFeedbackError(err))
    ).subscribe();
  }
}
