import { ImportEmailModalComponent } from 'src/app/shared/components/modal/import-email-modal/import-email-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MATERIALS } from '../app.material';
import { provideTransloco, TRANSLOCO_CONFIG, TranslocoConfig } from '@jsverse/transloco';
import { HeaderDefaultComponent } from './components/headers/header-default/header-default.component';
import { HeaderEmptyComponent } from './components/headers/header-empty/header-empty.component';
import { DownloadSharepointVersionComponent } from '../shared/components/modal/download-sharepoint-version/download-sharepoint-version.component';
import { ResidentsMigraterowPopupComponent } from '../features/main-app/communication/residents-list/residents-migraterow-popup/residents-migraterow-popup.component';
import { ResidentsMigrateidPopupComponent } from '../features/main-app/communication/residents-list/residents-migrateid-popup/residents-migrateid-popup.component';
import { ReportComponent } from './components/report/report/report.component';
import { SHARED_MODULES_ANGULAR } from './shared.angular.module';
import { SHARED_3RD_PARTY_ANGULAR } from './shared.3rd.party.module';
import { SHARED_COMPONENTS } from './shared.component';
import { HeaderFixedComponent } from './components/headers/header-fixed/header-fixed.component';
import { ComplaintContactModalComponent } from './components/modal/complaint-contact-modal/complaint-contact-modal.component';
import { SubprojectSelectorModalComponent } from './components/subproject-selector-modal/subproject-selector-modal.component';
import { SubprojectNewComponent } from './components/modal/subproject-new/subproject-new.component';
import { SubprojectMapOverviewModalComponent } from './components/modal/subproject-map-overview-modal/subproject-map-overview-modal.component';
import { SubprojectEditComponent } from './components/modal/subproject-edit/subproject-edit.component';
import { ProjectNewComponent } from './components/modal/project-new/project-new.component';
import { ProjectCreatedModalComponent } from './components/modal/project-new/project-created-modal/project-created-modal.component';
import { LightboxModule } from 'ngx-lightbox';
import { CalendarMiddelDetailComponent } from 'src/app/shared/components/modal/calendar-content-detail/calendar-middel-detail.component';
import { CalendarActivityDetailComponent } from 'src/app/shared/components/modal/calendar-activity-detail/calendar-activity-detail.component';
import { MessageConfirmComponent } from 'src/app/shared/components/modal/message-confirm/message-confirm.component';
import { ArchiveVerificationModalComponent } from 'src/app/shared/components/modal/archive-verification-modal/archive-verification-modal.component';
import {BouwappChatComponent} from './components/modal/bouwapp-chat/bouwapp-chat.component';
import {ChatdocumentuploadComponent} from './components/modal/chatdocumentupload/chatdocumentupload.component';
import { BouwappPublishModalComponent } from 'src/app/shared/components/modal/bouwapp-publish-modal/bouwapp-publish-modal.component';
import { InAppChatComponent } from './components/modal/in-app-chat/in-app-chat.component';
import { PdfViewComponent } from './components/modal/pdf-view/pdf-view.component';
import { AddOpenDataLayersComponent } from './components/modal/add-open-data-layers/add-open-data-layers.component';
import { ProgressSpinnerComponent } from './components/modal/progress-spinner/progress-spinner.component';
import { InAppInfoWindowComponent } from './components/modal/in-app-info-window/in-app-info-window.component';
import { SHARED_PIPES } from './pipes/shared.pipe';
import { SHARED_DIRECTIVES } from './directive/shared.directive';
import { PersonalisationModalReportComponent } from '../features/main-app/project-config/page/project-config/project-config-personalisation/personalisation-modal-report/personalisation-modal-report.component';
import { MiddelSubmitComponent } from './components/modal/content-submit/middel-submit.component';
import { DeletionModalComponent } from '../shared/components/modal/deletion-modal/deletion-modal.component';
import { ActionEditComponent } from './components/modal/tasks-edit/action-edit.component';
import { LogEditComponent } from './components/modal/contact-moments-edit/log-edit.component';
import { UrgencyStatusComponent } from '../features/main-app/issues/component/urgency-status/urgency-status.component';
import { TabIssueDetailGeneralComponent } from '../features/main-app/issues/page/issues-detail/tab-issue-detail-general/tab-issue-detail-general.component';
import { LogNewComponent } from '../shared/components/modal/contact-moments-new/log-new.component';
import { AddStakeholdersActionComponent } from './components/modal/add-stakeholders-task/add-stakeholders-action.component';
import { IssueNewComponent } from '../shared/components/modal/issue-new/issue-new.component';
import { ComplaintsDetailComponent } from '../features/main-app/complaints/complaints-detail/complaints-detail.component';
import { ComplaintNewComponent } from '../shared/components/modal/complaint-new/complaint-new.component';
import { ActivityNewComponent } from '../shared/components/modal/activity-new/activity-new.component';
import { MiddelNewComponent } from '../shared/components/modal/content-new/middel-new.component';
import { DecisionNewComponent } from './components/modal/requirement-new/decision-new.component';
import { EmployeeNewComponent } from './components/modal/contact-persons-new/employee-new.component';
import { EmployeeEditComponent } from './components/modal/contact-persons-edit/employee-edit.component';
import { StakeholderNewComponent } from '../shared/components/modal/stakeholder-new/stakeholder-new.component';
import { ResidentNewComponent } from '../shared/components/modal/resident-new/resident-new.component';
import { CalendarCreateEventComponent } from '../shared/components/modal/calendar-create-event/calendar-create-event.component';
import { DecisionEditComponent } from './components/modal/requirement-edit/decision-edit.component';
import { DeleteModalComponent } from '../shared/components/modal/delete-modal/delete-modal.component';
import { MessageModalComponent } from '../shared/components/modal/message-modal/message-modal.component';
import { StakeholderNewMapComponent } from '../shared/components/modal/stakeholder-new-map/stakeholder-new-map.component';
import { TabResidentDetailGeneralComponent } from '../features/main-app/communication/residents-detail/tab-resident-detail-general/tab-resident-detail-general.component';
import { KlantwensenDetailComponent } from '../features/main-app/requirements/page/requirement-detail/klantwensen-detail.component';
import { DocumentsDetailComponent } from '../features/main-app/documents/page/documents-detail/documents-detail.component';
import { StakeholdersDetailComponent } from '../features/main-app/stakeholders/page/stakeholders-detail/stakeholders-detail.component';
import { TabStakeholderDetailGeneralComponent } from '../features/main-app/stakeholders/page/stakeholders-detail/tab-stakeholder-detail-general/tab-stakeholder-detail-general.component';
import { TabStakeholderDetailHoudingComponent } from '../features/main-app/stakeholders/page/stakeholders-detail/tab-stakeholder-detail-houding/tab-stakeholder-detail-houding.component';
import { EmployeesComponent } from '../features/main-app/stakeholders/component/contact-persons/employees.component';
import { GeneralVerantwoordelijkenComponent } from '../features/main-app/stakeholders/page/stakeholders-detail/tab-stakeholder-detail-general/general-verantwoordelijken/general-verantwoordelijken.component';
import { environment } from 'src/environments/environment';
import { HttpLoader } from '../transloco.loader';
import { SidebarEmployeesComponent } from './components/sidebar-employees/sidebar-employees.component';
import { Docupload2Component } from './components/docupload2/docupload2.component';
import { IntegrationModalFreshdeskProjectComponent } from './components/modal/integration-modal-freshdesk-project/integration-modal-freshdesk-project.component';
import { IntegrationsModalPimComponent } from './components/modal/integrations-modal-pim/integrations-modal-pim.component';
import { IntegrationsModalSharepointComponent } from './components/modal/integrations-modal-sharepoint/integrations-modal-sharepoint.component';
import { IntegrationModalRelaticComponent } from './components/modal/integration-modal-relatic/integration-modal-relatic.component';
import { IntegrationModalBouwappProjectLevelComponent } from './components/modal/integration-modal-bouwapp-project-level/integration-modal-bouwapp-project-level.component';
import { IntegrationsModalArcgisComponent } from './components/modal/integrations-modal-arcgis/integrations-modal-arcgis.component';
import { MapDetailsComponent } from '../features/main-app/map/page/map-details/map-details.component';
import { PersonalSettingEditComponent } from './components/modal/personal-setting-edit/personal-setting-edit.component';
import { ProfileTabComponent } from './components/modal/personal-setting-edit/components/profile-tab/profile-tab.component';
import { NotificationTabComponent } from './components/modal/personal-setting-edit/components/notification-tab/notification-tab.component';
import { IndividualProjectSettingComponent } from './components/modal/personal-setting-edit/components/individual-project-setting/individual-project-setting.component';
import { SsoNewComponent } from './components/modal/sso-new/sso-new.component';
import { TwoFactorAuthenticationComponent } from './components/modal/personal-setting-edit/components/two-factor-authentication/two-factor-authentication.component';
import { ChangePasswordComponent } from './components/modal/personal-setting-edit/components/change-password/change-password.component';
import { PasswordLogoutComponent } from './components/modal/personal-setting-edit/components/password-logout/password-logout.component';
import { RecycleBinComponent } from './components/modal/recycle-bin/recycle-bin.component';
import { BatchOverviewSettingComponent } from './components/modal/batch-overview-setting/batch-overview-setting.component';
import { RelationOverviewComponent } from './components/add-connect-generic/components/relation-overview/relation-overview.component';
import { RecycleBinIconComponent } from './components/modal/recycle-bin/components/recycle-bin-icon/recycle-bin-icon.component';
import { BatchMergeComponent } from './components/modal/batch-overview-setting/components/batch-merge/batch-merge.component';
import { AuthenticationModalComponent } from './components/modal/authentication-modal/authentication-modal.component';
import { RequiredMsgModalComponent } from './components/modal/required-msg-modal/required-msg-modal.component';
import { CreateAnalysisItemComponent } from './components/modal/create-analysis-item/create-analysis-item.component';
import { StakeholderStrategyModalComponent } from './components/modal/stakeholder-strategy-modal/stakeholder-strategy-modal.component';
import { WhatsappIntegrationModalComponent } from './components/modal/whatsapp-integration-modal/whatsapp-integration-modal.component';
import { ImapIntegrationModalComponent } from './components/modal/imap-integration-modal/imap-integration-modal.component';
import { OutlookIntegrationModalComponent } from './components/modal/outlook-integration-modal/outlook-integration-modal.component';
import { PersonalOutlookIntegrationModalComponent } from './components/modal/personal-outlook-integration-modal/personal-outlook-integration-modal.component';
import { OutlookCalenderInboxComponent } from './components/modal/outlook-calender-inbox/outlook-calender-inbox.component';
import { PrivacyPolicyModalComponent } from './components/modal/privacy-policy-modal/privacy-policy-modal.component';
import { WhatsappMfaComponent } from './components/modal/whatsapp-mfa/whatsapp-mfa.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { PermitNewComponent } from './components/modal/permit-new/permit-new.component';
import { NewWorkflowComponent } from './components/modal/new-workflow/new-workflow.component';

@NgModule({
  declarations: [
    ...SHARED_PIPES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
  ],
  imports: [
    CommonModule,
    ...MATERIALS,
    ...SHARED_MODULES_ANGULAR,
    ...SHARED_3RD_PARTY_ANGULAR,
    LightboxModule
  ],
  // entryComponents: [
  //   HeaderDefaultComponent,
  //   HeaderEmptyComponent,
  //   HeaderFixedComponent,
  //   DownloadSharepointVersionComponent,
  //   ResidentsMigraterowPopupComponent,
  //   ResidentsMigrateidPopupComponent,
  //   ReportComponent,
  //   PersonalisationModalReportComponent,
  //   MiddelSubmitComponent,
  //   DeletionModalComponent,
  //   ArchiveVerificationModalComponent,
  //   ActionEditComponent,
  //   WhatsappMfaComponent,
  //   LogEditComponent,
  //   UrgencyStatusComponent,
  //   TabIssueDetailGeneralComponent,
  //   LogNewComponent,
  //   AddStakeholdersActionComponent,
  //   IssueNewComponent,
  //   ComplaintsDetailComponent,
  //   MapDetailsComponent,
  //   ComplaintNewComponent,
  //   ActivityNewComponent,
  //   MiddelNewComponent,
  //   DecisionNewComponent,
  //   EmployeeNewComponent,
  //   EmployeeEditComponent,
  //   StakeholderNewComponent,
  //   ResidentNewComponent,
  //   SidebarEmployeesComponent,
  //   CalendarCreateEventComponent,
  //   CreateAnalysisItemComponent,
  //   StakeholderStrategyModalComponent,
  //   DecisionEditComponent,
  //   ComplaintContactModalComponent,
  //   BouwappChatComponent,
  //   InAppChatComponent,
  //   InAppInfoWindowComponent,
  //   AddOpenDataLayersComponent,
  //   PdfViewComponent,
  //   ChatdocumentuploadComponent,
  //   SubprojectSelectorModalComponent,
  //   SubprojectNewComponent,
  //   SubprojectMapOverviewModalComponent,
  //   SubprojectEditComponent,
  //   ProjectNewComponent,
  //   ProjectCreatedModalComponent,
  //   DeleteModalComponent,
  //   MessageModalComponent,
  //   MessageConfirmComponent,
  //   RequiredMsgModalComponent,
  //   Docupload2Component,
  //   StakeholderNewMapComponent,
  //   TabResidentDetailGeneralComponent,
  //   KlantwensenDetailComponent,
  //   DocumentsDetailComponent,
  //   StakeholdersDetailComponent,
  //   TabStakeholderDetailGeneralComponent,
  //   TabStakeholderDetailHoudingComponent,
  //   EmployeesComponent,
  //   GeneralVerantwoordelijkenComponent,
  //   CalendarActivityDetailComponent,
  //   CalendarMiddelDetailComponent,
  //   BouwappPublishModalComponent,
  //   WhatsappIntegrationModalComponent,
  //   ImapIntegrationModalComponent,
  //   OutlookIntegrationModalComponent,
  //   PersonalOutlookIntegrationModalComponent,
  //   OutlookCalenderInboxComponent,
  //   ImportEmailModalComponent,
  //   ProgressSpinnerComponent,
  //   IntegrationModalFreshdeskProjectComponent,
  //   IntegrationsModalPimComponent,
  //   IntegrationsModalSharepointComponent,
  //   IntegrationModalRelaticComponent,
  //   IntegrationModalBouwappProjectLevelComponent,
  //   IntegrationsModalArcgisComponent,
  //   PersonalSettingEditComponent,
  //   ProfileTabComponent,
  //   NotificationTabComponent,
  //   IndividualProjectSettingComponent,
  //   SsoNewComponent,
  //   TwoFactorAuthenticationComponent,
  //   ChangePasswordComponent,
  //   PasswordLogoutComponent,
  //   RecycleBinComponent,
  //   BatchOverviewSettingComponent,
  //   RelationOverviewComponent,
  //   RecycleBinIconComponent,
  //   BatchMergeComponent,
  //   PrivacyPolicyModalComponent,
  //   AuthenticationModalComponent
  // ],
  exports: [
    ...MATERIALS,
    ...SHARED_PIPES,
    ...SHARED_DIRECTIVES,
    ...SHARED_MODULES_ANGULAR,
    ...SHARED_3RD_PARTY_ANGULAR,
    ...SHARED_COMPONENTS
    ],
  providers: [
    provideTransloco({
      config: {
          availableLangs: ['en', 'nl'],
          defaultLang: 'nl',
          reRenderOnLangChange: true,
          prodMode: environment.production,
      },
      loader: HttpLoader
  }),
  ]
})
export class SharedModule { }
