<div mat-dialog-title>
  <mat-icon class="row mb-10 inline-block" [style.color]="subProject.color">texture</mat-icon>
  <h4 class="row mb-10 ml-20 inline-block">{{ subProject.name }}</h4>
  <button type="button" class="close" (click)="close()">
    <span aria-hidden="true">
      <mat-icon class="d-text-middle">close</mat-icon>
    </span>
    <span class="sr-only">Close</span>
  </button>
</div>

<div class="modal-body no-padding">
  Content of subproject
</div><!--end of modal body -->
