import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProjectConfigs } from './project-configs.model';
import {ProjectConfigsState, ProjectConfigsStore} from './project-configs.store';

@Injectable({ providedIn: 'root' })
export class ProjectConfigsQuery extends QueryEntity<ProjectConfigsState, ProjectConfigs> {
  constructor(protected store: ProjectConfigsStore) {
    super(store);
  }

}
