<div mat-dialog-title>
  <div class="row">
    <div class="col-md-9">
        <h4>{{'settings.layer_authentication' | transloco }}</h4>
    </div>
    <div class="col-md-3">
        <button type="button" class="close no-margin" (click)="onClose()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
            <mat-icon class="d-text-middle">close</mat-icon>
          </span><span class="sr-only">Close</span>
        </button>
    </div>
  </div>
</div>
<form #form="ngForm" (submit)="onSubmit()" class="w-100">
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field class="w-100">
        <mat-label>{{'settings.username' | transloco | capitaliz}}</mat-label>
        <input matInput type="text" name="username" [(ngModel)]="data.username" required="">
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field class="w-100">
        <mat-label>{{'password' | transloco | capitaliz}}</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" name="password" [(ngModel)]="data.password" required="">
        <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility"}}</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions class="pull-right">
  <button type="button" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="onClose()">{{ 'close' | transloco | capitaliz}}</button>
  <button type="submit" [disabled]="form.invalid" mat-flat-button class="text-capitalize pull-right ml-10 mt-10">{{ 'save' | transloco | capitaliz }}</button>
</div>
</form>
