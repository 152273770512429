import { HttpParams } from '@angular/common/http';

export function getBatchIds(data): any{
  const ids=[];
  if(data.length){
    data.forEach(element => {
      ids.push(element.id);
    });
  }
  return ids;
}
//Batch helper function it select the items according to pagenation
export function onPaginationSelection(paginationItems,selectedItems,event): any{
  if(paginationItems.length){
    paginationItems.map(element => {
      if(selectedItems.length){
        element.checked = false;
        selectedItems.forEach(selected => {
          if(element.id == selected.id){
            element.checked = true;
          }
        });
      }else{
        element.checked = event;
      }
    });
  }
  return paginationItems;
}
//Batch merge function for set the required fields
export function itemsMergeLogic(type,data,primaryItem): any{
  const secondaryIds = [];
  switch(type){
    case 'stakeholders':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          primaryItem.name = primaryItem.name ? primaryItem.name : item.name;
          primaryItem.users = primaryItem.users ? primaryItem.users.length ? primaryItem.users : item.users : item.users;
        }
      });
      break;
    case 'decisions':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          // mandatory fields
          primaryItem.title = primaryItem.title ? primaryItem.title : item.title;
          primaryItem.stakeholders = primaryItem.stakeholders ? primaryItem.stakeholders.length ? primaryItem.stakeholders : item.stakeholders : item.stakeholders;

          // additional fields
          primaryItem.impact = primaryItem.impact ? primaryItem.impact: item.impact;
          primaryItem.feasibility = primaryItem.feasibility ? primaryItem.feasibility: item.feasibility;
          primaryItem.advice = primaryItem.advice ? primaryItem.advice : item.advice;
          primaryItem.motivation = primaryItem.motivation ? primaryItem.motivation : item.motivation;
          primaryItem.note = primaryItem.note ? primaryItem.note : item.note;
          primaryItem.requirement_concept = primaryItem.requirement_concept ? primaryItem.requirement_concept : item.requirement_concept;
          primaryItem.vv_information = primaryItem.vv_information ? primaryItem.vv_information : item.vv_information;
          primaryItem.guarantee = primaryItem.guarantee ? primaryItem.guarantee : item.guarantee;
        }
      });
      break;
    case 'issues':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          primaryItem.name = primaryItem.name ? primaryItem.name : item.name;
          primaryItem.description = primaryItem.description ? primaryItem.description : item.description;
          primaryItem.users = primaryItem.users ? primaryItem.users.length ? primaryItem.users : item.users : item.users;
        }
      });
      break;
    case 'actions':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          primaryItem.text = primaryItem.text ? primaryItem.text : item.text;
          primaryItem.users = primaryItem.users ? primaryItem.users.length ? primaryItem.users : item.users : item.users;
        }
      });
      break;
    case 'logs':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          primaryItem.contact_moment_timestamp = primaryItem.contact_moment_timestamp ? primaryItem.contact_moment_timestamp : item.contact_moment_timestamp;
          primaryItem.stakeholders = primaryItem.stakeholders ? primaryItem.stakeholders.length ? primaryItem.stakeholders : item.stakeholders : item.stakeholders;
        }
      });
      break;
    case 'employees':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          primaryItem.last_name = primaryItem.last_name ? primaryItem.last_name : item.last_name;
          primaryItem.stakeholders = primaryItem.stakeholders ? primaryItem.stakeholders.length ? primaryItem.stakeholders : item.stakeholders : item.stakeholders;
        }
      });
      break;
    case 'complaints':
      data.forEach(item => {
        if(item.id !== primaryItem.id){
          secondaryIds.push({id: item.id});
          primaryItem.contact_moment_timestamp = primaryItem.contact_moment_timestamp ? primaryItem.contact_moment_timestamp : item.contact_moment_timestamp;
          primaryItem.stakeholders = primaryItem.stakeholders ? primaryItem.stakeholders.length ? primaryItem.stakeholders : item.stakeholders : item.stakeholders;
        }
      });
      break;
  }
  return {primary_item: primaryItem, secondary_items: secondaryIds};
}
//check if filter paramaters are empty or not
export function isParamsExist(params): boolean{
  let isExist = false;
  Object.keys(params._filters).forEach((key) => {
    if (params._filters[key] && params._filters[key].length) {
      isExist = true;
    }
  });
  return isExist;
}
//overview export filters
export function overviewFilterSet(paginator,batch?,list?,extra?,type?): any{
  let params = new HttpParams()
  if(batch){
    params = params.append('ids', list);
  }else{
    Object.keys(paginator._filters).forEach((key) => {
      if (paginator._filters[key] && paginator._filters[key].length) {
        paginator._filters[key].forEach(value => {
          params = params.append(key != 'filter_field' ? `filter_array[${key}][]` : `filter_field[]`, value)
        });
      }
    });

    // for stakeholders strategies filter when applied
    if( type === 'stakeholders' ) {
      if (paginator._filters.filter_field){
        params = params.append('filter_by', 'matrices.strategie')
      }
      else {
      paginator.removeExtra('filter_by')
      }
    }

    if(paginator._keyword){
      params = params.append('keyword', paginator._keyword);
    }
    if(paginator._direction){
      params = params.append('direction', paginator._direction);
    }
    if(extra){
      if(type === 'issues')
        params = params.append('issues_status', extra);
    }
  }
  return params;
}
//theme export filters
export function themeFilterSet(filter,key): any{
  let params = new HttpParams()
  if(filter.length){
    filter.forEach(element => {
      params = params.append(`filter_array[${key}.name][]`, element.name)
    });
  }
  return params
}
