import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { Permission } from 'src/app/core/enum/permission';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { dateIso } from 'src/app/core/util/dateIso';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { Issue, calculateUrgency, createIssue } from '../../../../akita/issues/state/issue.model';
import { Entities } from '../../../../core/enum/entities';
import { IssuesService } from '../../../../core/service/api/issues.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { deepCopy, impactScoring, issuesScoreLogic } from '../../../../core/util/deepCopy';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../../shared/directive/new-entity.directive';
import { OpenlayersMapComponent } from '../../openlayers-map/openlayers-map.component';
import { MapService } from 'src/app/core/service/api/map.service';

@Component({
  selector: 'app-issue-new',
  templateUrl: './issue-new.component.html',
  styleUrls: ['./issue-new.component.sass']
})
export class IssueNewComponent extends NewEntityModal {
  themeType = ThemeType.Issues;
  declare public entity: Issue;
  public responseLayers: any;
  public users: any[];
  @ViewChild(OpenlayersMapComponent) openlayerMap: OpenlayersMapComponent;


  constructor(private _cachedService: CachedService,@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<IssueNewComponent>,
    public globals: Globals, private _issuesService: IssuesService, private pubsub: PubsubService, private _mapService: MapService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
    this._mapService.setData(this.data.params);
    if (this.data.params && this.data.params.locations) {
      if (!this.data.params.locations.locatable_type || this.data.params.locations.locatable_type === 'undefined') {
        this.data.params.locations.locatable_type = 'issues'
      }
    }

  }


  public submit() {
    this.isProcessing = true;
    const params = deepCopy(this.entity);
    params.deadline = new Date(moment(dateIso(params.deadline, true, this.globals.timezone)).format('YYYY-MM-DD'))
    params.issues_date = this.entity.issues_date.toJSON();
    params.issues_score=issuesScoreLogic(params.deadline,impactScoring(params.impact))
    params.urgency = calculateUrgency(this.entity,params.issues_score);
    this._issuesService.submitIssue(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          if (snackbar) {
            snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:data,
                message: translate('error.stored'),
                type:'openItems',
                item:'issues',
                url:'issues'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.issues, data);
          }
        });
  }

  protected initializeEntity(): void {
    this.entity = createIssue('', new Date());
    this.getUsers();
  }
  public getUsers(){
    this._cachedService.getUsers().subscribe(users => this.users = users);
  }
  @Allowed(Permission.IssuesCreate)
  public updateSelectedTheme(theme) {
    if(theme){
      this.entity.themeissues = theme;
    }else{
      this.entity.themeissues = null;
    }
  }
}
