import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  private language = 'nl';
  private words = {
    name: {
      nl: 'naam'
    },
    description: {
      nl: 'omschrijving'
    },
    code: {
      nl: 'ID/afkorting'
    },
    text: {
      nl: 'omschrijving',
    },
    closed_description: {
      nl: 'afhandeling',
    },
    email: {
      nl: 'e-mail'
    },
    title: {
      nl: 'titel'
    },
  };

  public translate(key: string): string {
    return this.words[key] ? this.words[key][this.language] : '';
  }
}
