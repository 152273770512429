import { ID } from '@datorama/akita';

export type Project = {
  id: ID,
  code: ID,
  name: string,
  identifier: string,
  modules?: any[]
  default_map?: string
  polygons?: any
  users_count?: string,
  max_users?:string,
  complaints_kpi?:any,
  sla_enabled?: boolean,
  configs?:any,
  personalization?:any
  personalization_id?:number
};

export function generateProjectCode(name: string): string {
  return name.replace(/[^a-z0-9]/gmi, '')
    .replace(/\s+/g, '')
    .toUpperCase();
}

export function createProject(name: string): Project {
  const code = generateProjectCode(name);
  return {
    code: code,
    id: code,
    name: name,
    identifier: '',
    modules:[]
  };
}
