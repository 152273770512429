import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import moment from 'moment';
import { DropzoneComponent } from 'ngx-dropzone-wrapper';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { ComplaintsService } from 'src/app/core/service/api/complaints.service';
import { InAppChatService } from 'src/app/core/service/api/in-app-chat.service';
import { MiddelsService } from 'src/app/core/service/api/middels.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { showFeedbackError, showFeedbackRemoved, showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { DocumentSidebarData } from 'src/app/shared/directive/document-sidebar.directive';
import { environment } from 'src/environments/environment';
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';

@Component({
  selector: 'app-bouwapp-publish-modal',
  templateUrl: './bouwapp-publish-modal.component.html',
  styleUrls: ['./bouwapp-publish-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BouwappPublishModalComponent extends GenericComponent implements OnInit {
  @ViewChild('dropzone', {static: false}) dropzone: DropzoneComponent;
  protected dropzoneTemplate = `<div class="mt-10">${translate('reusable.drop_email')}<br><i class="material-icons">cloud_upload</i></div>`;
  upload: any;
  public file: File = null;
  protected dropzoneConfig: any;
  public attachments:any[]=[];
  public entity:any;
  public type='update-attachment-file-path';
  public apiKey: string = environment.tinymce.apiKey;
  public tempdata:any[]=[];
  public bouwAppPublish:any;
  public dialogAttachments:any[]=[];
  public is_upload:boolean=false;
  public documentAttachment:boolean=false;
  public minDate=new Date();
  constructor(
    private subProjectQuery: SubprojectQuery,
    private _middelService: MiddelsService,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<BouwappPublishModalComponent>,
    @Inject(MAT_DIALOG_DATA) public docSidebarData: DocumentSidebarData,
    public globals: Globals,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _complainsServices:ComplaintsService,
    public _InAppChatService:InAppChatService,
    public _rout: Router,
    public dialog: MatDialog,
    ) {
      super();
    }

  ngOnInit() {
    this.initializeEntity();
    this.dropZone();
    this.projectAdd();
    this.bouwAppPublish=this.data.bouwapp;
    if(this.bouwAppPublish){
      if(!this.bouwAppPublish.code){
        if(this.bouwAppPublish.attachments.length > 0){
          this.bouwappAttachmentAdd();
        }
      this.entity=this.bouwAppPublish;
    }
      if(this.entity.attachments.length==0){
      this.documentAttachment=true;
      }
    }
    if(this.data.calender){
      if(this.data.calender.documents.length == 0){
        this.documentAttachment=true;
      }else{
        this.documentAttachment=false;
      }
    }
  }
  public initializeEntity(): void {
    this.entity={
      attachments:[],
      publicationDate: this.data.calender.contents.created_at,
      content:'',
      sendPush:true,
      poll:null,
      targets:[],
      visibleToTargetsOnly:false,
      project:''
    }
  }
  public submit(){
    const publishdate= moment(this.entity.publicationDate).set({
      hour:this.minDate.getHours(),
      minute:this.minDate.getMinutes(),
      second:this.minDate.getSeconds(),
    }).format();
    this.removalfunction();
    // this.attachments.forEach(element => {
    //   this.entity.attachments.push(element);
    // });
    this.entity.publicationDate=publishdate;
    this._middelService.createPublish(this.data.calender.contents.id,this.entity).subscribe(res=>{
      showFeedbackSaved();
      this.close('submit');
    })
  }
  public close(action=''): void {
    this.dialogRef.close(action);
  }
  public documentAttached(file:any){
    this.tempdata=file;
    const flag=[];
    this.entity.attachments.forEach(el=>{
      flag.push(el.fileName)
    });
    file.forEach(element => {
      if(element.type=='s3'){
        if(this.entity.attachments.length==0){
          this._InAppChatService.dialogItemsUpload(element.id,this.type).subscribe((res:any)=>{
            this.entity.attachments.push({
              fileName:element.name,
              filePath:res.file,
              is360:false,
              title:element.name
            })
          })
        }else{
           if(!flag.includes(element.name)){
            this._InAppChatService.dialogItemsUpload(element.id,this.type).subscribe((res:any)=>{
              this.entity.attachments.push({
                fileName:element.name,
                filePath:res.file,
                is360:false,
                title:element.name
              })
            })
           }
        }
      }
    });
  }
  public dropZone(){
    this.dropzoneConfig = {
      url: `${window.location.protocol }//${ window.location.host}`,
      uploadMultiple: false,
      previewsContainer: '.dropzone-previews'
    };
  }
  public DocumentUpLoad(file){
    this.file = file.target.files[0];
    this.updateDocumentUpload(this.file);
    this.cd.detectChanges();
  }
  public updateDocumentUpload(file){
    this.is_upload=true;
    this._InAppChatService.attachmentUpload(file,this.type).subscribe((res:any)=>{
      this.documentAttachment=false;
      this.updateUploadList(file,res);
      this.cd.detectChanges();
    },(error)=>{
      this.is_upload=false;
      showFeedbackError(error);
      this.cd.detectChanges();
    });
  }
  public updateUploadList(file,res){
      const filename=file.name;
      this.entity.attachments.push({
        fileName:filename,
        filePath:res.file,
        is360:false,
        title:filename
      });
      this.data.calender.documents.push({id:file.size,name:filename});
      this.dialogAttachments.push({id:file.size,name:filename});
      this.is_upload=false;
  }
  public bouwappAttachmentAdd(){
    const temp=[];
    this.data.calender.documents.forEach(el => {
        temp.push(el.name);
    });
    this.bouwAppPublish.attachments.forEach(element => {
      if(!temp.includes(element.title)){
        this.data.calender.documents.push({id:element.id,name:element.fileName});
      }
      this.dialogAttachments.push({id:element.id,name:element.fileName});
      this.cd.detectChanges();
    });
    this.data.calender.documents.forEach(ele => {
      this.dialogAttachments.forEach(el=>{
        if(ele.name==el.name){
          el.id=ele.id;
        }
      })
    });
  }
  public projectAdd(){
    const sub:any=this.subProjectQuery.getActive();
    if(this._rout.url.includes('sub')){
      if(sub.tenant_code){
        this.entity.project=`/api/projects/${sub.tenant_code}`;
      }else{
        this.entity.project=`/api/projects/${this.globals.projectConfigs['services.integration.bouwapp.project.code']}`;
      }
    }
    else{
      this.entity.project=`/api/projects/${this.globals.projectConfigs['services.integration.bouwapp.project.code']}`;
    }
  }
  public update(){
    const publishdate= moment(this.entity.publicationDate).set({
      hour:this.minDate.getHours(),
      minute:this.minDate.getMinutes(),
      second:this.minDate.getSeconds(),
    }).format();
    this.removalfunction();
    this.attachments.forEach(element => {
      this.entity.attachments.push(element);
    });
    this.entity.publicationDate=publishdate;
    this._middelService.publishUpdate(this.bouwAppPublish.id,this.entity).subscribe(res=>{
      showFeedbackSaved();
      this.close('update');
    })
  }
  public deleteP() {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u de Update wilt verwijderen?'
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.deletePublish();
      }
    });
  }
  public deletePublish(){
    this._middelService.publishDelete(this.data.calender.contents.id).subscribe(res=>{
      showFeedbackRemoved();
      this.close('delete');
    })
  }
  public removalfunction(){
    if(this.dialogAttachments.length==0){
      this.entity.attachments=[];
    }else{
      const temp=[];
      this.dialogAttachments.forEach(el=>{
        temp.push(el.name)
      });
      this.entity.attachments=this.entity.attachments.filter(element => {
        if(temp.includes(element.fileName)){
          return element;
        }
      });
    }
  }
}
