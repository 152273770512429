import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy, OnInit,
} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {IssuesQuery} from '../../../../../akita/issues/state/issues.query';
import {Issue, calculateUrgency} from '../../../../../akita/issues/state/issue.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { impactScoring, issuesScoreLogic } from 'src/app/core/util/deepCopy';

@Component({
  selector: 'app-urgency-status',
  templateUrl: './urgency-status.component.html',
  styleUrls: ['./urgency-status.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: UrgencyStatusComponent,
    multi: true
  }]
})
export class UrgencyStatusComponent implements ControlValueAccessor, OnInit, OnDestroy {

  // eslint-disable-next-line no-empty-function
  public onChange: (value: any) => void = value => {};
  // eslint-disable-next-line no-empty-function
  public onTouched: (touched: boolean) => void = touched => {};

  @Input() issue$: Observable<Issue>;
  public status: BehaviorSubject<string> = new BehaviorSubject(null);
  private subscription: Subscription;
  // eslint-disable-next-line no-empty-function
  constructor (private issueQuery: IssuesQuery) { }

  public ngOnInit(): void {
    this.issue$ = this.issue$ || this.issueQuery.selectActive();
    this.subscription = this.issue$.subscribe((issue: Issue)=> {
      if (!issue) {
        return;
      }
      const score=issuesScoreLogic(issue.deadline,impactScoring(issue.impact))
      const urgency = calculateUrgency(issue,score);
      this.status.next(urgency);

      this.onChange(urgency);
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.status.next(obj);
  }
}
