import { ID } from '@datorama/akita';
import moment from 'moment';
import { Decision } from '../../requirements/state/decision.model';
import { Location } from '../../locations/state/location.model';
import { Action } from '../../tasks/state/action.model';
import { Complaint } from '../../complaints/state/complaint.model';
import { Permit } from '../../permits/state/permit.model';

export type Issue = {
  id?: ID,
  name: string,
  code?: string,
  issues_date: Date,
  description: string,
  rationale: string,
  urgency: string,
  issues_status: string,
  relatics_issue_guid?: string,
  reason_closure: string,
  impact: string,
  deadline: Date,
  users?: any[],
  stakeholders?: any[],
  requirements?: Decision[],
  complaints?:Complaint[],
  tasks?: Action[],
  interests?: any[],
  themeissues?: any,
  locations?: Location,
  documents?: any[],
  subprojects?: any[],
  relatics?: any[],
  contactmoments?: any[],
  layers?: [],
  permits?: Permit[],
  workflows?: any,
  relatics_uuid?:boolean,
  issues_score?: number,
  closed_description?: string,
  closed_date?: Date,
  closed_by?: {}
};

export function createIssue(name: string, date: Date): Issue {
  return {
    name: name,
    issues_date: date,
    description: null,
    rationale: null,
    urgency: null,
    issues_status: 'open',
    reason_closure: null,
    impact: 'low',
    deadline: moment().add(1, 'months').toDate(),
  };
}

export function calculateUrgency(issue: Issue,score:any): string {
  if(score || score===0){
    issue.issues_score=score
  }
  switch (true) {
    case (0 <= issue.issues_score && issue.issues_score <= 24.99):
      return 'low';
    case (25 <= issue.issues_score && issue.issues_score <= 49.99):
      return 'middle';
    case (50 <= issue.issues_score && issue.issues_score <= 74.99):
      return 'high';
    case (75 <= issue.issues_score && issue.issues_score <= 100):
      return 'very-high';
  }
}
