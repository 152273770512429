<ng-container>
    <div class="row">
        <div class="col-md-12">
            <h5>{{ 'users.unauthorized_project_modal_title' | transloco | capitaliz }}</h5>
            <p>{{ 'users.unauthorized_project_modal_text' | transloco | capitaliz }}</p>
            <a class="pull-right" mat-flat-button routerLink="/" (click)="openProjectSelectionModal()">
                <span>{{ 'users.unauthorized_project_modal_button' | transloco | capitaliz }}</span>
            </a>
        </div>
    </div>
</ng-container>