import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AbstractService } from "../common/abstract.service";
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { PaginationParams } from '../common/pagination-params';
import { Observable } from "rxjs";
import { PaginatedResponse } from "../common/paginated-response";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  getDocument(documentId: number, isSharepoint: boolean = false, data?: {
    filename: string,
    filenameSimple: string
  }) {
    if (isSharepoint && data) {
      return this.http.get(`/api/documents/${documentId}?type=sharepoint`, {
        headers: this.headers,
        params: new HttpParams()
          .set('filename', data.filename)
          .set('filename_simple', data.filenameSimple)
      });
    }

    return this.http.get(`/api/documents/${documentId}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getDocuments(count, page) {
    return this.http.get('/api/documents', {
      headers: this.headers,
      params: new HttpParams()
        .set('count', count)
        .set('page', page)
    });
  }
  @ServiceErrorHandler()
  getFilteredDocuments(pagination: PaginationParams, status?: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/documents', pagination.get(status ? new HttpParams().set('status', status) : null));
  }
  @ServiceErrorHandler()
  getDocumentsByID(id){
    return this.http.get(`/api/documents/${id}`, this.httpOptions);
  }

  submitReference(type, id, reference) {
    return this.http.post(`/api/documents/edoc`, {
      type: type,
      id: id,
      reference: reference
    }, this.httpOptions);
  }

  submitUrl(type, idOrCode, link) {
    return this.http.post(`/api/${type}/${idOrCode}/documents`, {
      type: 'link',
      link: link
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  removeDocument(type, id, docId) {
    return this.http.delete(`/api/documents/${docId}/${type}/${id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  deleteDocument(document) {
    return this.http.delete(`/api/documents/${ document.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  connectDocument(document, documenyType, modelId) {
    return this.http.put('/api/documents/assign', {
      document: document,
      type: documenyType,
      id: modelId
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  searchDocument(isSharepoint: boolean, keyword: string, page = 1, limit = 5) {
    let disk = 's3';
    if (isSharepoint) {
      disk = 'sharepoint';
    }

    return this.http.get(`/file/search?disk=${disk}&keyword=${keyword}&limit=${limit}&page=${page}`, this.httpOptions);
  }

  getDocumentVersions(id: number) {
    return this.http.get(`/file/${id}/versions`, this.httpOptions);
  }

  downloadSharPointDocument(doc: any, version: any) {
    let params = ""
    if (version)
      params = `?version_id=${version.id}&version=${version.version}&filesize=${version.size}`
    return this.http.get(`/${this.getGlobals().projectCode}/documents/${doc.id}${ params}`, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      tap((response: HttpResponse<any>) => {
        const disposition = response.headers.get('content-disposition');
        let filename = doc.name;
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        saveAs(response.body, filename);
      })
    );
  }

  @ServiceErrorHandler()
  updateDocument(id: number, document: any) {
    return this.http.put(`/api/documents/${id}`, document, this.httpOptions);
  }

  downloadDocument(id: number,skipSubproject: boolean = false) {
    let params = new HttpParams();
    if(skipSubproject)
      params = params.append('skip_subproject','0')
    return this.http.get(`/${this.getGlobals().projectCode}/documents/${id}`, {
      responseType: `blob`,
      observe: 'response',
      params: params
    }).pipe(
      tap((response: HttpResponse<any>) => {
        const disposition = response.headers.get('content-disposition');
        let filename = `document-${id}`;
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        saveAs(response.body, filename);
      })
    );
  }

  @ServiceErrorHandler()
  getDocumentsRelations() {
    return this.http.get('/api/documents?dropdown=1',this.httpOptions);
  }
  @ServiceErrorHandler()
  saveDocumentLink(doc: any) {
    return this.http.post('/api/documents/upload', doc);
  }

}
