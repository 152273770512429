<div *ngIf="stakeholder">
    <form name="stakeholderForm" #form_editStakeholder="ngForm" (submit)="updateStakeholder()" autocomplete="off" *transloco="let a">
        <div *transloco="let t; read: 'stakeholders'">
            <!-- <app-show-more [expandText]="t('expand_text') | capitaliz"> -->
            <div summary>
                <!-- NAME -->
                <mat-form-field class="col-xs-12" [matTooltip]="relatic.name ? a('settings.relatic.disable_field') : null">
                  <mat-label>{{ t('name') | capitaliz }}</mat-label>
                    <input matInput type="text" name="title" [(ngModel)]="stakeholder.name" (change)="updateStakeholder()" [disabled]="!hasPermission(Permission.StakeholdersUpdate) || relatic.name" />
                </mat-form-field>
                <!-- DESCRIPTION -->
                <mat-form-field class="col-xs-12" [matTooltip]="relatic.description ? a('settings.relatic.disable_field') : null">
                  <mat-label>{{ t('description') | capitaliz }}</mat-label>
                    <textarea matInput type="text" name="description" [(ngModel)]="stakeholder.description" (change)="updateStakeholder()" [disabled]="!hasPermission(Permission.StakeholdersUpdate)  || relatic.description" ></textarea>
                </mat-form-field>
                <!-- TYPE -->
                <app-add-connect-themes [editButton]="true" [editable]="false" valueType="object" class="col-xs-12" [type]="themeType" [buttonText]="'stakeholders.theme_button_text'" [textMultiple]="'stakeholder type'" [textSingle]="'stakeholder type'" [selectedTheme]="selectedTheme"
                    (onThemeSelected)="updateSelectedTheme($event)" [disabled]="relatic.type || !hasPermission(Permission.StakeholdersUpdate)" [matTooltip]="relatic.type ? a('settings.relatic.disable_field') : null" [uniqueId]="'stakeholder-type'"></app-add-connect-themes>
                <!-- Holder -->
                <div>
                    <app-generic-multiselect-checkbox [chipDisabler]="chipDisablerUser" [disabled]="relatic.user || !hasPermission(Permission.StakeholdersUpdate)" *ngIf="userload" [parent]="stakeholder" parentType="stakeholders" class="col-xs-12" [placeholder]="'reusable.accounthouder' | transloco"
                        name="user" [(ngModel)]="stakeholder.users" [multiple]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('reusable.accounthouders' | transloco)+' ...'" (ngModelChange)="updateStakeholder()" [matTooltip]="relatic.user ? a('settings.relatic.disable_field') : null" [uniqueId]="'account-holders'">
                        <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                        </app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let user of users" [value]="user">
                            {{ user.name }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>
                </div>
                <!-- -------- -->
                <div [ngClass]="{'disable_enitiy': !hasPermission(Permission.StakeholdersUpdate)}">
                    <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [disabled]="relatic.user || !hasPermission(Permission.StakeholdersUpdate) || globals.subprojects.length === 0" [parent]="stakeholder" parentType="stakeholders" class="subprojects col-xs-12" type="subprojects" [maxChipItems]="3"
                        *ngIf="hasModule('subprojects')" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco" name="subprojects" [(ngModel)]="stakeholder.subprojects" [multiple]="true" [maxChipLength]="20" (ngModelChange)="updateSubprojects()" [uniqueId]="'sub-projects'">
                        <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                            {{ subproject.name }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>
                </div>
            </div>
        </div>
    </form>
</div>
