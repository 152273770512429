
<ng-container>
  <section *transloco="let t">
    <div class="popup-header row no-margin ">
      <div class="col-xs-12 mb-5 d-flex j-space-between">
        <div>
          <h1>{{'settings.sso.add_sso' | transloco }}</h1>
          <p class="mt-10">{{'settings.sso.add_sso_info' | transloco }}</p>
          <mat-hint class="error" *ngIf="ssoVerifyCredentials">{{'settings.sso.verify_credentials_info' | transloco }}</mat-hint>
        </div>
        <button type="button" class="close a-self-start" (click)="onClose()">
          <span aria-hidden="true"><mat-icon class="uil uil-multiply"></mat-icon>
          </span>
          <span class="sr-only">Close</span>
        </button>
      </div>
    </div>
    <form #form="ngForm">
      <div class="popup-body" appScroll>
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>{{('settings.sso.domain') | transloco}}</mat-label>
                <input type="text" matInput class="" [(ngModel)]="entity.domain" name="domain" (change)="onChangeDomainValidation()" required />
              </mat-form-field>
              <mat-hint class="error" *ngIf="domainValidation.is_exists">{{'settings.sso.domain_exist' | transloco }}</mat-hint>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>{{('settings.sso.client_id') | transloco}}</mat-label>
                <input type="text" matInput class="" [(ngModel)]="entity.client_id" name="client id" required />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>{{('settings.sso.client_secret') | transloco}}</mat-label>
                <input type="text" matInput class="" [(ngModel)]="entity.client_secret" (keyup)="onSecretChange($event)" name="client secret" required />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>{{('settings.sso.tenant_id') | transloco}}</mat-label>
                <input type="text" matInput class="" [(ngModel)]="entity.tenant_id" name="tenant id" required />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>{{('settings.sso.identity_provider') | transloco}}</mat-label>
                <input type="text" matInput class="" [(ngModel)]="entity.identity_provider" name="identity provider" (ngModelChange)="onChangeUserDomainCheck()" [required]="!domainValidation.is_exists && !domainValidation.is_user_email_matched"/>
              </mat-form-field>
              <mat-hint *ngIf="!domainValidation.is_exists && !domainValidation.is_user_email_matched">{{'settings.sso.enter_domain' | transloco }} <span *ngIf="!domainValidation.is_user_email_matched && !userDomain" class="error">Domain not matched with user identity provider</span></mat-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <button type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">{{ t('close') | capitaliz }}</button>
        <button *ngIf="data.sso" class="delete ml-10" type="button" mat-stroked-button data-dismiss="modal" (click)="deleteConnection()">{{ t('delete') | capitaliz }}</button>
        <button [disabled]="form.invalid || domainValidation.is_exists || processing || (!domainValidation.is_user_email_matched && !userDomain)" type="submit" mat-flat-button  [ngClass]="processing ? 't-5' : ''" class="ml-10" (click)="!ssoValidation ? ssoCredentialsValidation(): submit()">
          <span *ngIf="!ssoValidation && !processing">{{'settings.sso.verify_credentials' | transloco }}</span>
          <span *ngIf="ssoValidation && !processing">{{'settings.sso.enable' | transloco }}</span>
          <span *ngIf="processing" class="p-set">
            <img class="pull-left group_svg spinner" src="/assets/png/spinner.png" alt="decisions" />
          </span>
        </button>
      </div>
    </form>
  </section>
</ng-container>
