<section *transloco="let t">
  <div class="popup-header row no-margin ">
    <div class="col-xs-12 mb-5">
      <button type="button" class="back" *ngIf="showIndicidualProject" (click)="previousState()">
        <span aria-hidden="true"><mat-icon class="d-text-middle">keyboard_backspace</mat-icon>
        </span>
        <span class="sr-only">Back</span>
      </button>
        <h1>{{'settings.auth.personal_settings' | transloco}}</h1>
        <button type="button" class="close" (click)="onClose()">
          <span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon>
          </span>
          <span class="sr-only">Close</span>
        </button>
    </div>
  </div>
  <div class="popup-body" appScroll>
    <mat-tab-group *ngIf="!showIndicidualProject" [selectedIndex]="activeTab">
      <mat-tab [label]="t('settings.auth.profile')">
        <app-profile-tab (towFactorAuthenticationModal)="onClickTwoFactorAuthenticationModal()" (changePasswordModal)="onClickChangePasswordModal()" [twoFactorStatus]="twoFactorStatus"></app-profile-tab>
      </mat-tab>
      <mat-tab [label]="t('settings.auth.notification')">
        <app-notification-tab (onSelectIndicidualProject)="onSelectIndicidualProject()"></app-notification-tab>
      </mat-tab>
    </mat-tab-group>
    <app-individual-project-setting *ngIf="showIndicidualProject"></app-individual-project-setting>
  </div>
  <div class="popup-footer">
    <button type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">{{ t('close') | capitaliz }}</button>
  </div>
</section>
