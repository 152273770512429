<form #form="ngForm" (ngSubmit)="submit()">
  <div mat-dialog-title>
    <h4 class="mb-10 inline-block">
      {{ "new" | transloco | capitaliz }}
      {{ "menu.contactpersons" | transloco | capitaliz }}
    </h4>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
      </span>
      <span class="sr-only"> Close </span>
    </button>
  </div>

  <!-- modal body -->
  <mat-dialog-content>
    <div class="row">
      <mat-radio-group
        [(ngModel)]="entity.title"
        name="title"
        ngDefaultControl
        class="full-width"
      >
        <mat-radio-button value="mr" class="col-md-2 ml-3">{{
          "reusable.mr" | transloco | capitaliz
        }}</mat-radio-button>
        <mat-radio-button value="mrs" class="col-md-8">{{
          "reusable.mrs" | transloco | capitaliz
        }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="row">
      <div class="hidden-xs hidden-sm col-md-2">
        <mat-icon class="mt-14">person_pin</mat-icon>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5">
        <mat-form-field>
          <mat-label>{{ "reusable.first_name" | transloco }}</mat-label>
          <input
            matInput
            [ngModel]="entity.first_name"
            (keyup)="trigger($event)"
            (ngModelChange)="(entity.first_name)"
            name="first_name"
          />
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5">
        <mat-form-field>
          <mat-label>{{ "reusable.last_name" | transloco }}</mat-label>
          <input
            matInput
            [ngModel]="entity.last_name"
            (keyup)="trigger1($event)"
            (ngModelChange)="(entity.last_name)"
            name="last_name"
            required
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="hidden-xs hidden-sm col-md-2">
        <mat-icon class="mt-14">supervisor_account</mat-icon>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5">
        <app-generic-multiselect-checkbox
          [placeholder]="'reusable.selecteer_stakeholder' | transloco"
          name="stakeholders"
          ngDefaultControl
          [(ngModel)]="entity.stakeholders"
          [required]="true"
          [multiple]="true"
          [uniqueId]="'select-stakeholders'"
        >
          <app-generic-multiselect-checkbox-option
            *ngFor="let stakeholder of stakeholders"
            [value]="stakeholder"
          >
            {{ stakeholder.name }}
          </app-generic-multiselect-checkbox-option>
          <app-generic-multiselect-checkbox-option-new-action
            type="stakeholder"
          ></app-generic-multiselect-checkbox-option-new-action>
          <app-generic-multiselect-search-project
            entity="stakeholders"
          ></app-generic-multiselect-search-project>
        </app-generic-multiselect-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="hidden-xs hidden-sm col-md-2">
        <mat-icon class="mt-14">department</mat-icon>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3">
        <mat-form-field>
          <mat-label>{{ "reusable.department" | transloco }}</mat-label>
          <input matInput [(ngModel)]="entity.department" name="department" />
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5">
        <mat-form-field>
          <mat-label>{{ "reusable.job_title" | transloco }}</mat-label>
          <input matInput [(ngModel)]="entity.function" name="function" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="hidden-xs hidden-sm col-md-2">
        <mat-icon class="mt-14">contacts</mat-icon>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3">
        <mat-form-field style="width: 90%;" class="phone">
          <ngx-mat-intl-tel-input
            name="phone"
            [preferredCountries]="['nl']"
            [enablePlaceholder]="true"
            customPlaceholder="Mobile Number"
            [enableSearch]="true"
            (countryChanged)="onChangeCountryPhone($event)"
            (change)="phoneChange(phone)"
            #phone
            matInput
            class="custom-tel-input"
          ></ngx-mat-intl-tel-input>
        </mat-form-field>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-3">
        <mat-form-field style="width: 90%;" class="phone">
          <ngx-mat-intl-tel-input
            name="personalPhone"
            [preferredCountries]="['nl']"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            (countryChanged)="onChangeCountryPersonal($event)"
            (change)="personalPhoneChange(personalPhone)"
            #personalPhone
            matInput
          ></ngx-mat-intl-tel-input>
        </mat-form-field>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 mt-10">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            [(ngModel)]="entity.email"
            name="email"
            email="true"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="hidden-xs hidden-md col-md-2"></div>
      <div class="col-xs-12 col-sm-12 col-md-10">
        <mat-form-field class="full-width">
          <mat-label>{{ "reusable.notes" | transloco }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="8"
            name="note"
            [(ngModel)]="entity.note"
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <!--end of modal body -->

  <mat-dialog-actions align="end">
    <div class="dialog-button-row">
      <button type="button" mat-stroked-button (click)="close()">
        {{ "reusable.close_doc" | transloco }}
      </button>
      <button
        type="submit"
        mat-flat-button
        [disabled]="isProcessing || form.invalid"
      >
        <span>
          <mat-icon class="d-text-middle">save</mat-icon>
          {{ "add" | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{
          "text.please_wait" | transloco | capitaliz
        }}</span>
      </button>
    </div>
  </mat-dialog-actions>
  <!--end of modal footer -->
</form>
<!--end of modal content -->
