<div class="row popup-container" *transloco="let a;">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
          <mat-icon class="d-text-middle uil uil-multiply"></mat-icon>
        </span><span class="sr-only">Close</span></button>
            <div *hasPermission="[Permission.ActionDelete]">
                <mat-icon *ngIf="action" (click)="deleteAction(action)" class="d-cursor-hand uil uil-trash delete pull-right tasks-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}"></mat-icon>
            </div>
            <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
                <button  *ngIf="action" type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
                   <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
                </button>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1><strong>{{ 'actions.label' | transloco | capitaliz }} </strong> {{name}}
            </h1>
        </div>


    </div>
    <mat-progress-bar *ngIf="!action && !reducer" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="action && reducer">
        <app-workflow-generic *ngIf="action && action.workflows && action.workflows.length && hasModule('workflow')" [margin]="false" parentType="actions" [deadline]="action.timestamp" [workflows]="action.workflows" [permitId]="action.id"></app-workflow-generic>
        <!-- left col -->
        <div class="col-md-6 col-xs-12">
          <h4 class="mb-15">{{ 'actions.label' | transloco | capitaliz }} details</h4>

          <div class="col-xs-12 panel z-depth-2 panel-border" *transloco="let t; read: 'stakeholders'">
            <form [formGroup]="actionForm" (submit)="submit()">
              <!-- modal body -->
              <div class="modal-body">
                <div class="row" *transloco="let t; read: 'actions'">
                  <!-- Left Column -->
                  <div class="col-md-6 col-xs-12">
                    <div [matTooltip]="reducer.handled ? a('settings.relatic.disable_field') : null" style="margin-bottom: 10px;">
                      <app-tick-checkbox
                        [ngClass]="{'disable_enitiy': reducer.handled}"
                        class="app-tick-checkbox"
                        [(ngModel)]="action.handled"
                        [ngModelOptions]="{standalone: true}"
                        [name]="'handle-' + action.id"
                        [matTooltip]="(action.handled ? t('set_recovered') : t('set_resolved')) | capitaliz"
                        (ngModelChange)="handleAction(action)"
                        matListIcon>
                      </app-tick-checkbox>
                      <span *ngIf="action.handled">{{ 'actions.set_recovered' | transloco | capitaliz }}</span>
                      <span *ngIf="!action.handled">{{ 'actions.set_resolved' | transloco | capitaliz }}</span>
                    </div>

                    <mat-form-field class="full-width" [matTooltip]="reducer.text ? a('settings.relatic.disable_field') : null">
                      <mat-label>{{ 'reusable.action' | transloco }}</mat-label>
                      <textarea
                        [disabled]="reducer.text || !hasPermission(Permission.ActionUpdate)"
                        matInput autofocus (change)="submit()"
                        formControlName="text" required>
                      </textarea>
                    </mat-form-field>

                    <div [matTooltip]="reducer.user ? a('settings.relatic.disable_field') : null">
                      <app-multiselect-users
                        [chipDisabler]="chipDisablerUser"
                        [disabled]="!hasPermission(Permission.ComplaintsUpdate)"
                        *ngIf="users"
                        [placeholder]="'reusable.responsible' | transloco"
                        [multipleUsers]="true"
                        [selectedUsers]="action.users"
                        [users]="users"
                        (onSelectedUsersUpdated)="updateUsers($event)">
                      </app-multiselect-users>
                    </div>
                  </div>

                  <!-- Right Column -->
                  <div class="col-md-6 col-xs-12">
                    <mat-form-field class="full-width" [matTooltip]="reducer.timestamp ? a('settings.relatic.disable_field') : null">
                      <mat-label>{{ 'reusable.notification_date' | transloco }}</mat-label>
                      <input
                        [disabled]="reducer.timestamp || !hasPermission(Permission.ActionUpdate)"
                        matInput formControlName="timestamp"
                        [matDatepicker]="actionDate"
                        (click)="actionDate.open()"
                        (dateChange)="submit()" required>
                      <mat-datepicker-toggle matSuffix [for]="actionDate">
                        <mat-icon matDatepickerToggleIcon class="uil uil-calender unicon-icon"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #actionDate></mat-datepicker>
                    </mat-form-field>

                    <app-generic-multiselect-checkbox
                      [disabled]="!hasPermission(Permission.ActionUpdate)"
                      type="stakeholders"
                      [placeholder]="'reusable.selecteer_stakeholder' | transloco"
                      name="stakeholders"
                      formControlName="stakeholders"
                      [required]="false"
                      (ngModelChange)="submit()"
                      [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [uniqueId]="'select-stakeholders'">
                      <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                      </app-generic-multiselect-checkbox-option-all>
                      <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                        {{ stakeholder.name }}
                      </app-generic-multiselect-checkbox-option>
                      <app-generic-multiselect-checkbox-option-new-action type="stakeholder">
                      </app-generic-multiselect-checkbox-option-new-action>
                    </app-generic-multiselect-checkbox>
                  </div>
                </div>

                <div class="row mt-10">
                  <app-generic-multiselect-checkbox
                    [chipDisabler]="chipDisabler"
                    [disabled]="!hasPermission(Permission.ActionUpdate) || globals.subprojects.length === 0"
                    [parent]="action" parentType="actions" class="subprojects"
                    type="subprojects"
                    [maxChipItems]="3"
                    *ngIf="hasModule('subprojects')"
                    (ngModelChange)="updateSubprojects()"
                    [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco"
                    name="subprojects"
                    [(ngModel)]="action.subprojects"
                    [ngModelOptions]="{standalone: true}"
                    [multiple]="true"
                    [maxChipLength]="20"
                    [uniqueId]="'sub-projects'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                      {{ subproject.name }}
                    </app-generic-multiselect-checkbox-option>
                  </app-generic-multiselect-checkbox>
                </div>
              </div>
              <!--end of modal body -->
            </form>
          </div>


          <h4>{{ 'decisions.connections' | transloco | capitaliz }}</h4>
          <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component">
            <div>
              <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
              <app-add-connect-generic
                parentType="tasks"
                (onChange)="updateEntities($event)"
                *ngIf="loaded"
                [parent]="action"
                [data]="entities"
                [types]="['issues', 'requirements', 'contactmoments', 'complaints','activities','permits','documents']">
              </app-add-connect-generic>
            </div>
          </div>

        </div>
        <!-- Right Column -->
        <div class="col-md-6 col-xs-12">
          <div class="row">
            <div class="col-md-12">
              <app-comment-box [users]="users" type="tasks" [type_id]="action.id"></app-comment-box>
            </div>
          </div>
        </div>
    </div>
</div>
