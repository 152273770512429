<div mat-dialog-title>
    <div class="row">
        <div class="col-md-9">
            <h4>{{ 'complaints.service_level.label' | transloco | capitaliz }}</h4>
        </div>
        <div class="col-md-3">
            <button type="button" class="close no-margin" matTooltip="{{ 'close' | transloco | capitaliz }}" (click)="close()"><span aria-hidden="true">
                <mat-icon class="d-text-middle">close</mat-icon>
              </span><span class="sr-only">Close</span></button>
        </div>
    </div>
</div>
<form #form="ngForm" (ngSubmit)="submit()">
    <div mat-dialog-content *transloco="let t">
            <table class="table table-borderless">
                <thead>
                <tr>
                    <th>{{ 'complaints.service_level.priority' | transloco | capitaliz }}</th>
                    <th>{{ 'complaints.service_level.resolution_time' | transloco | capitaliz }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="vertical-center">{{ 'complaints.service_level.priority_label_urgent' | transloco | capitaliz }}</th>
                    <td>
                        <div class="d-flex">
                            <mat-form-field>
                                <input matInput type="number" name="Urgent" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" [placeholder]="'complaints.service_level.time' | transloco | capitaliz" [(ngModel)]="priority.sla.priorities.urgent" required />
                            </mat-form-field>
                            <span class="a-self-center ml-20">{{ 'complaints.service_level.time' | transloco | capitaliz }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="vertical-center">{{ 'complaints.service_level.priority_label_high' | transloco | capitaliz }}</th>
                    <td>
                        <div class="d-flex">
                            <mat-form-field>
                                <input matInput type="number" name="High" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" [placeholder]="'complaints.service_level.time' | transloco | capitaliz" [(ngModel)]="priority.sla.priorities.high" required />
                            </mat-form-field>
                            <span class="a-self-center ml-20">{{ 'complaints.service_level.time' | transloco | capitaliz }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="vertical-center">{{ 'complaints.service_level.priority_label_medium' | transloco | capitaliz }}</th>
                    <td>
                        <div class="d-flex">
                            <mat-form-field>
                                <input matInput type="number" name="Medium" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" [placeholder]="'complaints.service_level.time' | transloco | capitaliz" [(ngModel)]="priority.sla.priorities.medium" required />
                            </mat-form-field>
                            <span class="a-self-center ml-20">{{ 'complaints.service_level.time' | transloco | capitaliz }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="vertical-center">{{ 'complaints.service_level.priority_label_low' | transloco | capitaliz }}</th>
                    <td>
                        <div class="d-flex">
                            <mat-form-field>
                                <input matInput type="number" name="Low" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" [placeholder]="'complaints.service_level.time' | transloco | capitaliz" [(ngModel)]="priority.sla.priorities.low" required />
                            </mat-form-field>
                            <span class="a-self-center ml-20">{{ 'complaints.service_level.time' | transloco | capitaliz }}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
    </div>
    <div mat-dialog-actions *transloco="let t" class="j-ceontent-end">
        <button  type="button" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="close()">{{ t('close') }}</button>
        <button  type="submit" mat-flat-button class="text-capitalize pull-right ml-10 mt-10" [disabled]="form.invalid">{{ t('save') }}</button>
    </div>
</form>