import { Injectable } from '@angular/core';
import { UsersService } from "../api/users.service";
import { StakeholdersService } from "../api/stakeholders.service";
import { PaginatedResponse } from "./paginated-response";
import { GenericComponent } from "../../../core/util/abstract/generic-component";
import { PaginationParams } from "./pagination-params";
import { Globals } from "../../../globals";
import { Observable } from "rxjs";
import { map, publishReplay, refCount } from "rxjs/operators";
import { EmployeesService } from "../api/employees.service";
import { LogsService } from "../api/logs.service";
import { ComplaintsService } from "../api/complaints.service";
import { ResidentsService } from "../api/residents.service";
import { IssuesService } from "../api/issues.service";
import { ActionsService } from "../api/actions.service";
import { ActivitiesService } from "../api/activities.service";
import { DecisionsService } from "../api/decisions.service";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { Stakeholder } from '../../../akita/stakeholders/state/stakeholder.model';
import { Resident } from '../../../akita/residents/state/resident.model';
import { Issue } from '../../../akita/issues/state/issue.model';
import { Decision } from '../../../akita/requirements/state/decision.model';
import { Activity } from '../../../akita/activities/state/activity.model';
import { Employee } from '../../../akita/contact-persons/state/employee.model';
import { User } from '../../../akita/users/state/user.model';
import { Action } from '../../../akita/tasks/state/action.model';
import { Middel } from '../../../akita/content/state/middel.model';
import { MiddelsService } from '../api/middels.service';
import { Complaint } from '../../../akita/complaints/state/complaint.model';
import { Log } from 'src/app/akita/contact-moments/state/log.model';
import { DocumentsService } from '../api/documents.service';
import { Document } from 'src/app/akita/documents/state/document.model';
import { WorkflowService } from '../api/workflow.service';
import { PermitsService } from '../api/permits.service';
import { Permit } from 'src/app/akita/permits/state/permit.model';
@Injectable({
  providedIn: 'root'
})
export class CachedService extends GenericComponent {

  private users: Observable<User[]>;
  private stakeholders: Observable<Stakeholder[]>;
  private residents: Observable<Resident[]>;
  private employees: Observable<Employee[]>;
  private series: Observable<any[]>;
  private complaintThemes: Observable<any[]>;
  private issues: Observable<Issue[]>;
  private complaints: Observable<Complaint[]>;
  private actions: Observable<Action[]>;
  private permits: Observable<Permit[]>;
  private activities: Observable<Activity[]>;
  private decisions: Observable<Decision[]>;
  private logs: Observable<Log[]>;
  private documents: Observable<Document[]>;
  private middel: Observable<Middel[]>;

  private _ignoreSubProject: boolean = false;

  constructor(public globals: Globals, private _usersService: UsersService, private _stakeholdersService: StakeholdersService, private _employeesService: EmployeesService, private _logsService: LogsService,
    private _complaintsService: ComplaintsService, private _residentsService: ResidentsService, private _issuesService: IssuesService, private _actionsService: ActionsService, private _permitsService: PermitsService,
    private _middelService: MiddelsService,private _workflowService: WorkflowService, private _activitiesService: ActivitiesService, private _decisionsService: DecisionsService,public _documents:DocumentsService) {
    super(globals);
  }

  set ignoreSubProject(bool: boolean) {
    this._ignoreSubProject = bool;
  }

  @ServiceErrorHandler()
  public getUsers(): Observable<User[]> {
    this.users = this._usersService.getUsers()
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.users;
  }

  @ServiceErrorHandler()
  public getStakeholders(columns: string[] = [], skipSubProject = this._ignoreSubProject): Observable<Stakeholder[]> {
    if (skipSubProject)
      this._ignoreSubProject = skipSubProject
    this.stakeholders = this._stakeholdersService.getStakeholders(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => {
          if (columns.length === 0) {
            return response.data;
          }

          return this.truncateArray(response.data, columns);
        }),
        publishReplay(1),
        refCount()
      );

    return this.stakeholders;
  }

  @ServiceErrorHandler()
  public getStakeholdersRelations(): Observable<Stakeholder[]> {
    this.stakeholders = this._stakeholdersService.getStakeholdersRelations()
      .pipe(
        map((response: any) => response),
      );
    return this.stakeholders;
  }

  public getResidents(): Observable<Resident[]> {
    this.residents = this._residentsService.getResidents(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.residents;
  }

  public getEmployees(): Observable<Employee[]> {
    this.employees = this._employeesService.getEmployees(new PaginationParams().with(['stakeholders']).skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.employees;
  }

  public getEmployeesRelations(): Observable<Employee[]> {
    this.employees = this._employeesService.getEmployeesRelations()
      .pipe(
        map((response: any) => response),
      );
    return this.employees;
  }

  public getEmployeesWithLogs(): Observable<Employee[]> {
    this.employees = this._employeesService.getEmployees(new PaginationParams().with(['stakeholders', 'contactmoments']).skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.employees;
  }

  public getSeries(): Observable<any[]> {
    this.series = this._logsService.getSeries()
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.series;
  }

  public getComplaintThemes(): Observable<any[]> {
    this.complaintThemes = this._complaintsService.getComplaintThemes()
      .pipe(
        map((response: any[]) => response),
        publishReplay(1),
        refCount()
      );

    return this.complaintThemes;
  }

  public getIssues(): Observable<Issue[]> {
    this.issues = this._issuesService.getUnfilteredIssues(new PaginationParams().orderBy('urgency').skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.issues;
  }

  public getIssuesRelations(): Observable<Issue[]> {
    this.issues = this._issuesService.getUnfilteredIssuesRelation()
      .pipe(
        map((response: any) => response),
      );

    return this.issues;
  }

  public getComplaints(): Observable<Complaint[]> {
    this.complaints = this._complaintsService.getComplaints(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.complaints;
  }

  public getComplaintsRelations(): Observable<Complaint[]> {
    this.complaints = this._complaintsService.getComplaintsRelations()
      .pipe(
        map((response: any) => response),
      );

    return this.complaints;
  }

  public getActions(): Observable<Action[]> {
    this.actions = this._actionsService.getActions(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.actions;
  }

  public getActionsRelations(): Observable<Action[]> {
    this.actions = this._actionsService.getActionsRelations()
      .pipe(
        map((response: any) => response),
      );
    return this.actions;
  }

  public getWorkflows(): Observable<Action[]> {
    this.actions = this._workflowService.getAllWorksflows()
      .pipe(
        map((response: any) => response),
      );
    return this.actions;
  }
  public getPermitsRelations(): Observable<Permit[]> {
    this.permits = this._permitsService.getPermitsRelations()
      .pipe(
        map((response: any) => response),
      );
    return this.permits;
  }

  public getActivities(): Observable<Activity[]> {
    this.activities = this._activitiesService.getActivities(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.activities;
  }

  public getActivitiesRelations(): Observable<Activity[]> {
    this.activities = this._activitiesService.getActivitiesRelations()
      .pipe(
        map((response: any) => response),
      );

    return this.activities;
  }

  public getMiddelRelations(): Observable<any> {
    this.middel = this._activitiesService.getMiddelRelations()
      .pipe(
        map((response: any) => response),
      );

    return this.middel;
  }

  public getMiddel(): Observable<Middel[]> {
    this.middel = this._middelService.getMiddels(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.middel;
  }

  public getDecisions(): Observable<Decision[]> {
    this.decisions = this._decisionsService.getFilteredDecisions(new PaginationParams().skipSubpProject(this._ignoreSubProject))
      .pipe(
        map((response: PaginatedResponse) => response.data),
        publishReplay(1),
        refCount()
      );

    return this.decisions;
  }

  public getDecisionsRelations(): Observable<Decision[]> {
    this.decisions = this._decisionsService.getFilteredDecisionsRelations()
      .pipe(
        map((response: any) => response),
      );

    return this.decisions;
  }

  public getLogsRelations(): Observable<Log[]> {
    this.logs = this._logsService.getLogsRelations()
      .pipe(
        map((response: any) => response),
      );
    return this.logs;
  }

  public getDocumentsRelations(): Observable<Document[]> {
    this.documents = this._documents.getDocumentsRelations()
      .pipe(
        map((response: any) => response),
      );
    return this.documents;
  }

  private truncateArray(data: any[], columns: string[]): any[] {
    data = data.map(entry => {
      const d = {};
      columns.forEach(column => {
        if (entry[column]) {
          d[column] = entry[column];
        }
      });
      return d;
    });

    return data;
  }
}
