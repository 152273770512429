export function htmlRemoval(text){
  const htmlTags = /(<([^>]+)>)/ig;
  let result=text.replace(htmlTags, "");
  // result=result.replace(spaces, "");
  result=result.substring(0, 30);
  return result;
}
export function itemsAlgo(res): any {
    const sortingAlgo=[];
    if(res.issues){
        res.issues.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:element.searchable.code,item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.contactmoments){
        res.contactmoments.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:'',item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.complaints){
        res.complaints.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:'',item:true,title_name:`${htmlRemoval(element.title)} (${element.type})`})
        });
      }
      if(res.requirements){
        res.requirements.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:'',item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.contents){
        res.contents.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:(element.searchable.calendars[0] ? element.searchable.calendars[0] : '').id,item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.stakeholders){
        res.stakeholders.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:element.searchable.code,item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.contactpersons){
        res.contactpersons.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:'',item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.activities){
        res.activities.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:'',item:true,title_name:`${element.title} (${element.type})`})
        });
      }
      if(res.tasks){
        res.tasks.map(element => {
          sortingAlgo.push({id:element.searchable.id,name:element.title,type:element.type,url:element.url,code:'',item:true,title_name:`${element.title} (${element.type})`})
        });
      }
    return sortingAlgo
}

//replace spaces and special ch
export function removeAndpaces(text){
  const result=text.replaceAll('amp;', "");
  return result;
}
