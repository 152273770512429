<div mat-dialog-title>
    <h3 class="row mb-10 inline-block text-capitalize">{{ 'workflows.dependency-change' | transloco }}</h3>
    <button type="button" class="close" (click)="close()"><span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon></span><span class="sr-only">Close</span></button>
</div>
<mat-dialog-content>
    <div class="no-padding table-scrolling-set col-xs-12 text-left box-shadow overview-margin-bottom-space">
        <div class="th-border"></div>
        <table class="overview-panel set-border-firefox-table overview-filter-widget-overflow" mat-table [dataSource]="klantwensen" matSort matSortActive="id" matSortDisableClear matSortDirection="desc">
          <!-- Checkbox Column -->
          
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>
                  <span class="d-cursor-hand th mr-6">ID</span>
              </th>
              <td mat-cell *matCellDef="let klantwens" class="col-md-1">{{ klantwens.id}}</td>
          </ng-container>
          <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>
                  <span class="d-cursor-hand th mr-9">{{ 'actions.label' | transloco | capitaliz }}</span>
              </th>
              <td mat-cell *matCellDef="let klantwens" class="col-md-3" (click)="nav(klantwens)" >
                  <a class="ellipsis-text3">{{ klantwens.text}}</a>
              </td>
          </ng-container>
          <ng-container matColumnDef="change">
              <th mat-header-cell *matHeaderCellDef>
                  <span class="d-cursor-hand th mr-6">{{ 'workflows.change' | transloco }}</span>
              </th>
              <td mat-cell *matCellDef="let klantwens" class="col-md-6" (click)="nav(klantwens)">
                    <span>{{ 'workflows.deadline-change' | transloco }} {{ (klantwens.timestamp | formatdate: 'dd-MM-yyyy' : globals.timezone) }}</span>
              </td>
          </ng-container>
          
          <ng-container matColumnDef="deadline">
              <th mat-header-cell *matHeaderCellDef>
                  <span class="d-cursor-hand th mr-6">Deadline</span>
              </th>
              <td mat-cell *matCellDef="let klantwens" class="col-md-2" (click)="nav(klantwens)">
                  <span>
                      {{klantwens.newTimestamp | formatdate: 'dd-MM-yyyy' : globals.timezone  }}</span>
              </td>
          </ng-container>
          
          <ng-container matColumnDef="empty">
              <td colspan="50" mat-cell *matFooterCellDef>
                  <div *ngIf="!loading" class="empty-oc-height-set w-100 text-center empty-padding">
                      <div>
                          <img src="assets/png/empty-state-no-customer-requirements.png" class="empty-state-image">
                          <h2>{{'dashboard.nodata.customer_requirements_header' | transloco}}</h2>
                      </div>
                  </div>
                  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
              </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;" class="clickable-row delete-on-hover-tr"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable-row delete-on-hover-tr d-cursor-hand">
          </tr>
          <tr mat-footer-row *matFooterRowDef="['empty']" [class]="klantwensen.length > 0 ? 'hidden' : ''"></tr>
        </table>
    </div>
</mat-dialog-content>

<div>
    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
          <button
            type="button"
            mat-stroked-button
            data-dismiss="modal"
            [disabled]="isProcessing"
            (click)="close()"
          >
            {{ "cancel" | transloco | capitaliz }}
          </button>
          <button type="submit" mat-flat-button (click)="submit()">
            <span *ngIf="!isProcessing">
              {{"Approve"| transloco | capitaliz}}
            </span>
            <span *ngIf="isProcessing">{{
    "text.please_wait" | transloco | capitaliz}}</span>
          </button>
        </div>
      </mat-dialog-actions>
</div>