<form #form="ngForm" (ngSubmit)="submit()" autocomplete="off" #newStakeHolderModal>
    <div mat-dialog-title>
        <h4 class="mb-10 inline-block">{{ 'new' | transloco | capitaliz }} {{ 'stakeholders.label' | transloco }}</h4>
        <button type="button" class="close modal-close-button" (click)="closeModal($event)"><span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
      </span><span class="sr-only">Close</span></button>
    </div>

    <!-- modal body -->
    <mat-dialog-content>
        <div [ngClass]="!setOpenlayerMapViewer?'':'col-xs-12 col-md-6'">
            <div>
                <mat-form-field class="full-width" floatLabel="auto">
                  <mat-label>{{ 'reusable.name' | transloco }}</mat-label>
                    <input matInput name="name"  [(ngModel)]="entity.name" required>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'reusable.description' | transloco }}</mat-label>
                    <textarea matInput name="description" [(ngModel)]="entity.description" rows="3">
        </textarea>
                </mat-form-field>
            </div>

            <div class="mt-10">
                <app-generic-multiselect-checkbox [parentLocationTaggingAttributes]="stakeholderOverlay" [placeholder]="'reusable.accounthouder' | transloco" name="users" ngDefaultControl [(ngModel)]="entity.users" name="users" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('reusable.accounthouders' | transloco)+' ...'" [uniqueId]="'account-holders'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let user of users; trackBy: trackByFn" [value]="user">
                        {{ user.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
            </div>

            <div>
              <app-add-connect-themes (onChange)="trackSelectedStakeholderType($event)" [type]="themeType" [buttonText]="'stakeholders.theme_button_text'" [textMultiple]="'stakeholder types'" [textSingle]="'stakeholder type'" [editable]="false" (onThemeSelected)="updateSelectedTheme($event)" [uniqueId]="'stakeholder-type'"></app-add-connect-themes>
            </div>
            <div *ngIf='!setOpenlayerMapViewer'>
                <label class="mb-15">{{'reusable.contact_info' | transloco}}</label>
                <div class="form-group">
                    <label class="control-label" for="google-address">{{'reusable.search_address' | transloco}}</label>
                    <div>
                      <input style="width: 100%; margin-left: 0px !important; border: 1px solid #ccc !important;" #autocomplete type="text" id="autocomplete" placeholder="" />
                      <!-- <input *ngIf="globals.googleMapLibLoaded" type="text" class="form-control" id="google-address" name="google-address" ngx-google-places-autocomplete [options]="googleMapOptions" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" autocomplete="off" placeholder="" > -->
                    </div>
                </div>
                <div class="form-group mt-20">
                    <label class="control-label" for="address">{{'reusable.address' | transloco}}</label>
                    <mat-icon *ngIf="entity.found_coordinates !== 'found'" class="warning-address-icon" matTooltip="GPS coördinaten onbekend door handmatig ingevuld adres">warning</mat-icon>
                    <mat-slide-toggle class="pull-right manual-address-toggle" [(ngModel)]="enableManualAddress" name="manualAddress" ngDefaultControl matTooltip="Handmatige invoer in-/uitschakelen">
                      {{'reusable.manual_entry' | transloco}}
                    </mat-slide-toggle>
                    <div>
                        <input type="text" style="margin-left: 0px !important;" class="form-control" id="address" name="address" [(ngModel)]="entity.address" (change)="updateAddress()" [disabled]="!enableManualAddress">
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" for="postcode">{{'maps.addr:postcode' | transloco}}</label>
                    <div>
                        <input type="text" style="margin-left: 0px !important;" class="form-control" id="postcode" name="postcode" [(ngModel)]="entity.postcode" (change)="updateAddress()" [disabled]="!enableManualAddress">
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" for="city"> {{'reusable.city' | transloco}}</label>
                    <div>
                        <input type="text" style="margin-left: 0px !important;" class="form-control" id="city" name="city" [(ngModel)]="entity.city" (change)="updateAddress()" [disabled]="!enableManualAddress">
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="!setOpenlayerMapViewer?'':'col-xs-12 col-md-6'" *ngIf='setOpenlayerMapViewer'>
            <label class="mb-15">Locatie</label>
            <app-openlayers-map [(ngModel)]="entity.locations" [includeSidebar]="false" [defaultCoordinate]="globals.projectConfig.default_map" [showMapReset]="true" [maxPoints]="1" name="locations" locatableType="stakeholders" defaultDrawTool="point" clickStartDrawingMsg="Teken stakeholder punt">
            </app-openlayers-map>
        </div>
    </mat-dialog-content>
    <!--end of modal body -->

    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
            <button type="button" mat-stroked-button data-dismiss="modal" [disabled]="isProcessing" (click)="close()">{{'close' | transloco | capitaliz}}</button>
            <button type="submit" mat-flat-button [disabled]="isProcessing || form.invalid">
        <span *ngIf="!isProcessing">
          <mat-icon class="material-icons d-text-middle">save</mat-icon>
          {{ 'add' | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
      </button>
        </div>
    </mat-dialog-actions>
    <!--end of modal footer -->

</form>
