import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WhatsappService } from 'src/app/core/service/api/whatsapp.service';
import { FacebookService } from 'src/app/core/service/common/facebook.service';
import { showFeedbackError, showFeedbackRemoved, showFeedbackSaved } from 'src/app/core/util/notification';

@Component({
  selector: 'app-whatsapp-mfa',
  templateUrl: './whatsapp-mfa.component.html',
  styleUrls: ['./whatsapp-mfa.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappMfaComponent {
  pincode: string = '';
  pincodeError: string | null = null;
  isPincodeValid: boolean = false;
  showPincodeInput= true;

  constructor(
    private cd: ChangeDetectorRef,
    private _whatsAppService: WhatsappService,
    private _facebookService: FacebookService,
    public dialogRef: MatDialogRef<WhatsappMfaComponent>,
  ) {
//
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  validatePincode() {
    const pincodeRegex = /^\d{6}$/;
    if (!pincodeRegex.test(this.pincode)) {
      this.pincodeError = 'Pincode must be a 6-digit number';
      this.isPincodeValid = false;
    } else {
      this.pincodeError = null;
      this.isPincodeValid = true;
    }
  }

  submit(isConfirmed: boolean) {
    if (isConfirmed) {
      // Handle setting the pincode
      if (this.isPincodeValid) {
        const requestBody = {
          "messaging_product": "whatsapp",
          "pin": this.pincode,
        };

        this._whatsAppService.setPinCode(requestBody).subscribe(
          (response) => {
            showFeedbackSaved('Pincode successfully set');
            this.dialogRef.close(isConfirmed);
          },
          (error) => {
            // Handle error response here
            showFeedbackError('Failed to set pincode');
            this.dialogRef.close(false);
          }
        );
      } else {
        showFeedbackError('Invalid pincode');
        this.dialogRef.close(isConfirmed);
      }
    } else {
      showFeedbackRemoved('Action cancelled');
      this.dialogRef.close(isConfirmed);
    }
  }

}
