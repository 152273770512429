import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PubsubService } from "../../../core/service/api/pubsub.service";
import { Globals } from "../../../globals";
import { HeaderDefaultComponent } from "../../../shared/components/headers/header-default/header-default.component";
import { GenericComponent } from "./generic-component";

declare let document: any;
@Directive({
})

export abstract class Page extends GenericComponent implements OnInit, OnDestroy {
  abstract pageSubTitle: string;
  abstract pageTitle: string;
  header : any;
  mainClass = '';
  backToOverview = false;
  backUrl = null;
  backLink= false;
  subscriptions: Subscription[] = [];

  protected constructor(public pubsub?: PubsubService, public oldGlobals?: Globals) {
    super(oldGlobals);
  }

  public ngOnInit(): void {
    this.init();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  init() {
    if (!this.header) {
      this.header = HeaderDefaultComponent;
    }

    this.pubsub.setHeaderComponent(this.header);
    this.pubsub.setPageTitle(this.pageTitle);
    this.pubsub.setPageSubTitle(this.pageSubTitle);
    this.pubsub.setPageMainClass(this.mainClass);

    if (this.backToOverview) {
      if (!this.backUrl) {
        this.backUrl = document.location.pathname.split('/')[2];
      }

      this.pubsub.setBackOverviewUrl(this.backUrl);
    } else {
      this.pubsub.setBackOverviewUrl(null);
    }
  }
}
