import moment from "moment";

function toTimestamp(strDate){
  const date = moment(strDate).valueOf();
  // var datum = Date.UTC(date.getFullYear(),date.getMonth(),date.getDate());
  return date;
}
export function lineMoniterData(data:any,type) {
    const highChart=[];
    const today=new Date();
    if(type=='issues'){
        today.setDate(today.getDate()+1);
    }
    data.forEach(element => {
        const otherdate=new Date(element.date);
        if(today.toDateString() == otherdate.toDateString()){
            highChart.push({
                x: toTimestamp(element.date),
                y: Math.ceil(element.value),
                color: "#35a9f3",
                marker: {
                    symbol: 'url(/assets/png/moniter.png)',
                    width: 24,
                    height: 24,
                    enabled: true
                 },
            })
        }else{
            highChart.push({
                x: toTimestamp(element.date),
                y: Math.ceil(element.value),
                color: "#35a9f3",
                marker: {
                  enabled: false
                }
            })
        }
    });
    return highChart;
}

export function complaintBarData(data:any) {
    const simplicityBarData=[];
    const barData=[];
    const allThemes=Object.keys(data);
    allThemes.forEach(element => {
        simplicityBarData.push({type:element,values:data[element],monthData:[]})
    });
    simplicityBarData.forEach(element => {
        if(element.values){
            for (let index = 1; index <= 12; index++) {
                if(element.values[index]){
                    element.monthData.push(element.values[index]);
                }else{
                    element.monthData.push(0)
                }
            }
        }
    });
    simplicityBarData.forEach(element => {
        barData.push(
            {
                name: element.type,
                data: element.monthData
            }
        )
    });
    return barData;
}
export function getBelangMatrixBubbleSize(size,trust,power_interest,power) {
    let min=32;
    let max=64;
    if (size === 'small') {
        min = 14 ; max = 28;
    }
    //if default set then value will be min
    if (power_interest === 50 && power === 50) {
        return min;
    }
    // formula for getting the size of bubble
    return max - ((max - min) / 100) * trust ;
}

export function getBelangMatrixBubbleColor(trust) {

    const trust_interest = Math.round(trust);

    if (0 < trust_interest && trust_interest < 45) {
        return '#FF7669';
    }
    if (44 < trust_interest && trust_interest < 56) {
        return '#E0E1E3';
    }
    if (55 < trust_interest && trust_interest < 101) {
        return '#12A67C';
    }
}

export function getKpiColorOfComplaint(kpi,global_kpi) {
    if(global_kpi){
        switch(true){
            case kpi < global_kpi:
            return 'kpi_low'
            case kpi == global_kpi:
            return 'kpi_equal'
            case kpi > global_kpi:
            return 'kpi_above'
        }
    }else{
        return 'kpi_low'
    }
}
