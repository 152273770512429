import {Injectable} from '@angular/core';
import {ResidentsStore} from './residents.store';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from '../../../core/service/common/abstract.service';
import {Resident} from './resident.model';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PaginationParams} from '../../../core/service/common/pagination-params';
import {PaginatedResponse} from '../../../core/service/common/paginated-response';
import {ResidentsQuery} from './residents.query';
import {deepCopy} from '../../../core/util/deepCopy';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({ providedIn: 'root' })
export class ResidentsService extends AbstractService {

  constructor(private residentsStore: ResidentsStore, private residentQuery: ResidentsQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams): Observable<Resident[]> {
    return this.http.get(`/api/residents`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Resident>) => this.residentsStore.set(response.data)),
        map((response: PaginatedResponse<Resident>) => response.data)
      );
  }

  @ServiceErrorHandler()
  public find(id: number, withs: string[] = []): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }

    const request = this.http.get(`/api/residents/${id}`, {
      headers: this.headers,
      params: params
    })
      .pipe(
        tap ((resident: Resident) => {
          if (this.residentsStore.getValue().entities && Object.entries(this.residentsStore.getValue().entities).length > 0) {
            this.residentsStore.update(resident);
          } else {
            this.residentsStore.set([deepCopy(resident)]);
          }

          this.residentsStore.setActive(resident.id);
        })
    );

    return request;
  }

  @ServiceErrorHandler()
  public update(resident: Resident): Observable<Resident> {
    this.residentsStore.update(resident.id, resident);
    return this.http.put(`/api/residents/${resident.id}`, resident, this.httpOptions)
      .pipe(map((response: Resident) => response) );
  }

  @ServiceErrorHandler()
  public create(resident: Resident): Observable<Resident> {
    return this.http.put('/api/residents', resident, this.httpOptions)
      .pipe(tap((response: Resident) => {
        this.residentsStore.add(resident);
      }));
  }

  @ServiceErrorHandler()
  public delete(resident: Resident): Observable<any> {
    return this.http.delete(`/api/residents/${resident.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.residentsStore.remove(resident.id);
      }));
  }
}
