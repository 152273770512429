<div class="modal-header">
    <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
    </span>
</button>
    <h4 class="mb-10 inline-block">{{ 'new' | transloco | capitaliz }} {{ 'decisions.label' | transloco }}</h4>
</div>

<form #form="ngForm" (ngSubmit)="submit()">
    <mat-dialog-content>
        <div class="col-xs-12 col-md-6">
            <mat-form-field class="full-width">
              <mat-label>{{ 'reusable.title' | transloco }}</mat-label>
                <input matInput type="text" name="title"  [(ngModel)]="entity.title" required [disabled]="!hasPermission(Permission.DecisionsCreate)" />
            </mat-form-field>
            <app-add-connect-themes [type]="themeType" [editable]="false" (onThemeSelected)="updateSelectedTheme($event)" [uniqueId]="'add-themes'"></app-add-connect-themes>
            <div class="form-group">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'reusable.eistekst' | transloco }}</mat-label>
                    <textarea matInput name="description" rows="5" [(ngModel)]="entity.description">
                    </textarea>
                </mat-form-field>
            </div>
            <div class="mt-10">
                <app-generic-multiselect-checkbox [placeholder]="'reusable.select_user_change' | transloco" name="users" ngDefaultControl [(ngModel)]="entity.users" name="users" [required]="false" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' users ...'" [uniqueId]="'responsible-users'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let user of users; trackBy: trackByFn" [value]="user">
                        {{ user.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
            </div>

            <div class="form-group">
                <app-generic-multiselect-checkbox [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" ngDefaultControl [(ngModel)]="entity.stakeholders" *ngIf="stakeholders" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [uniqueId]="'select-stakeholders'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                        {{ stakeholder.name }}
                    </app-generic-multiselect-checkbox-option>
                    <app-generic-multiselect-checkbox-option-new-action type="stakeholder"></app-generic-multiselect-checkbox-option-new-action>
                    <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
                </app-generic-multiselect-checkbox>
            </div>

            <div class="form-group">
                <mat-radio-group name="newDecision_dateFilter" ngDefaultControl [(ngModel)]="dateFilter">
                    <mat-radio-button value="no-date">{{'reusable.no_data_restriction' | transloco}}</mat-radio-button>
                    <mat-radio-button class="date-restriction" value="fixed-date">{{'reusable.fixed_date' | transloco}}</mat-radio-button>
                    <mat-radio-button class="date-restriction-2nd" value="ranged-date">{{'reusable.date_range' | transloco}}</mat-radio-button>
                </mat-radio-group>
                <div class="mt-5" *ngIf="dateFilter === 'fixed-date'">
                    <mat-form-field>
                      <mat-label>{{ 'reusable.choose_date' | transloco }}</mat-label>
                        <input matInput [matDatepicker]="datePickerStart" (focus)="datePickerStart.open()" (click)="datePickerStart.open()" name="newDecision_startDate" [(ngModel)]="entity.start_date">
                        <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #datePickerStart></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="mt-5" *ngIf="dateFilter === 'ranged-date'">
                    <mat-form-field>
                      <mat-label>{{ 'reusable.choose_start_date' | transloco }}</mat-label>
                        <input matInput [matDatepicker]="datePickerStart" (focus)="datePickerStart.open()" (click)="datePickerStart.open()"  name="newDecision_startDate" [(ngModel)]="entity.start_date">
                        <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #datePickerStart></mat-datepicker>
                    </mat-form-field>
                    <span class="tm">t/m</span>
                    <mat-form-field>
                      <mat-label>{{ 'reusable.choose_end_date' | transloco }}</mat-label>
                        <input matInput [matDatepicker]="datePickerEnd" [min]="entity.start_date" (focus)="datePickerEnd.open()" (click)="datePickerEnd.open()"  name="newDecision_endDate" [(ngModel)]="entity.end_date">
                        <mat-datepicker-toggle matSuffix [for]="datePickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #datePickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-xs-12 col-md-6 form-group">
            <h5>{{'reusable.location' | transloco}}</h5>
            <div class="map-container">
                <app-openlayers-map [(ngModel)]="entity.locations" ngDefaultControl [includeSidebar]="false" [defaultCoordinate]="projectConfig.default_map" [showMapReset]="true" [maxPoints]="1" name="locations" locatableType="requirements" defaultDrawTool="point" clickStartDrawingMsg="Teken klantwenspunt"></app-openlayers-map>
            </div>
        </div>

    </mat-dialog-content>
    <!--end of modal body-->

    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
            <button type="button" mat-stroked-button data-dismiss="modal" [disabled]="isProcessing" (click)="close()">{{'cancel' | transloco | capitaliz}}</button>
            <button type="submit" mat-flat-button [disabled]="isProcessing || form.invalid">
        <span *ngIf="!isProcessing">
        <mat-icon class="material-icons d-text-middle">save</mat-icon>
        {{ 'add' | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
    </button>
        </div>
    </mat-dialog-actions>
    <!--end of modal footer -->
</form>

