import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Globals } from "../../../../globals";
import { MatDialog } from "@angular/material/dialog";
import { SubprojectNewComponent } from "../subproject-new/subproject-new.component";
import { ModalEnum, modalConfig } from "../../../../core/util/modalConfig";
import { Subproject } from "../../../../akita/subproject/state/subproject.model";
import { SubprojectQuery } from "../../../../akita/subproject/state/subprojects.query";
import { SubprojectsService } from "../../../../akita/subproject/state/subprojects.service";
import { Observable } from "rxjs";
import { SubprojectEditComponent } from "../subproject-edit/subproject-edit.component";
import { ID } from "@datorama/akita";
import { ActivatedRoute, Router } from "@angular/router";
import { showFeedbackError } from "../../../../core/util/notification";
import { Permission } from "../../../../core/enum/permission";
import { extrectSubprojects, levelValidation } from 'src/app/core/util/rbac';
import { SubprojectsStore } from 'src/app/akita/subproject/state/subprojects.store';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { finalize, tap } from "rxjs/operators";

@Component({
  selector: "app-subproject-selector",
  templateUrl: "./subproject-selector.component.html",
  styleUrls: ["./subproject-selector.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubprojectSelectorComponent implements OnInit {
  AvatarCustomStyleSelector = {
    "border-width": "3px",
    "font-weight": "bold",
    width: "27px",
  };

  AvatarCustomStyle = {
    "border-width": "3px",
    "font-weight": "bold",
    width: "37px",
  };

  public subProjects$: Observable<Subproject[]>;
  public subProject$: Observable<Subproject>;
  public Permission = Permission;
  public userPrivilegeSubprojects:any[]=[];
  public userSubprojectsAllot:any[]=[];
  constructor(
    public globals: Globals,
    private dialog: MatDialog,
    private subProjectQuery: SubprojectQuery,
    private activatedRoute: ActivatedRoute,
    private subProjectsService: SubprojectsService,
    private router: Router,
    private subProjectStore: SubprojectsStore,
    private _projectsService: ProjectsService,
  // eslint-disable-next-line no-empty-function
  ) {}

  public ngOnInit(): void {
    this.subProjects$ = this.subProjectQuery.selectAll();
    this.subProject$ = this.subProjectQuery.selectActive();
    if(!this.router.url.includes('sub')){
      if(this.globals.user.scope.level=='subproject'){
        this.switchSubProject(levelValidation(this.globals.user.scope).id);
        this.userPrivilegeSubprojects=extrectSubprojects(this.globals.user.scope);
      }else{
        this.globals.permissions=this.globals.user.permissions;
      }
    }
    this.onLoadSetPermission();
  }

  public switchSubProject(subProjectId: ID): void {
    this.subProjectsService.get(true).subscribe(res=>{
      this.globals.subprojectsPermission=res;
      this.afterSelect(subProjectId)
    });
  }
  public afterSelect(subProjectId: ID){
    const activeSubProject = this.subProjectQuery.getActive();
    if(subProjectId && this.globals.subprojectsPermission?.length){
      this.globals.subprojectsPermission.forEach(element => {
          if(element.id==subProjectId){
            this.globals.permissions=element.permissions;
          }
      });
    }else{
      this.globals.permissions=this.globals.user.permissions;
    }
    if (!subProjectId && !activeSubProject) {
      this.subProjectsService.set(null);
      return;
    }

    if (activeSubProject && activeSubProject.id === subProjectId) {
      return;
    }

    // eslint-disable-next-line prefer-const
    let [dummy, projectCode, ...rest] = this.router.url.split("/");
    if (activeSubProject) {
      rest = this.router.url.split("/").slice(4);
    }

    if (!subProjectId) {
      this.router.navigate([...rest], {
        state: {
          selectSubproject: true,
        },
      });
      return;
    }

    const subProject = this.subProjectQuery.getEntity(subProjectId);
    if (!subProject) {
      showFeedbackError(
        `Sub-project ${subProjectId} bestaat niet`,
        `Sub-project ${subProjectId} bestaat niet`
      );
      return;
    }

    this.subProjectsService.set(subProjectId);
    const navigateUrl = `/${this.globals.projectCode}/sub/${subProject.code}`;

    // This will briefly reload the route before redirecting back to the intended path
      this.router.navigateByUrl(navigateUrl).then(() => {
        this.router.navigate([...rest], {
          state: {
            selectSubproject: true,
            subprojectCode: subProject.code,
          },
        });
      });
  }
  public edit(subProject: Subproject): void {
    this.dialog.open(
      SubprojectEditComponent,
      modalConfig(
        {
          data: subProject,
          disableClose: true,
          panelClass: 'customized-dialog-container',
        },
        ModalEnum.ModalDefault
      )
    );
  }

  public create(): void {
    this.dialog.open(
      SubprojectNewComponent,
      modalConfig({disableClose: true}, ModalEnum.ModalDefault)
    );
  }

  public userAllowSubproject(){
    const subprojects_id=[];
    this.userSubprojectsAllot=[];
    if(this.globals.user.scope.level=='subproject'){
      this.userPrivilegeSubprojects=extrectSubprojects(this.globals.user.scope);
      this.userPrivilegeSubprojects.forEach(element => {
        subprojects_id.push(element.id);
      });
      this.subProjectQuery.getAll().forEach(element => {
        if(subprojects_id.includes(element.id)){
          this.userSubprojectsAllot.push(element);
        }
      });
      this.subProjectStore.set(this.userSubprojectsAllot);
    }
  }
  public onLoadSetPermission(){
    if(this.router.url.includes('sub')){
      this.subProjectsService.get(true)
      .pipe(
        tap(res=>{
          this.globals.subprojectsPermission=res;
          const activeSubProject = this.subProjectQuery.getActive();
          if (this.globals.subprojectsPermission && this.globals.subprojectsPermission.length){
            this.globals.subprojectsPermission.forEach(element => {
              if(element.id==activeSubProject.id){
                this.globals.permissions=element.permissions;
              }
            });
          }
        }),
        finalize(()=>{
          this.userAllowSubproject();
        })
      ).subscribe();
    }else{
      this.userAllowSubproject();
    }
  }
}
