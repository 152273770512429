import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { SubprojectsService } from 'src/app/akita/subproject/state/subprojects.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { WithParams } from 'src/app/core/service/common/with-params';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { EmployeeEditComponent } from 'src/app/shared/components/modal/contact-persons-edit/employee-edit.component';
import { MessageConfirmComponent } from 'src/app/shared/components/modal/message-confirm/message-confirm.component';
import { MultiselectOptionType } from '../../../core/enum/multiselect-option-type';
import { deepCopy } from '../../../core/util/deepCopy';
import { NewEntityData } from '../../directive/new-entity.directive';
import { LogNewComponent } from '../modal/contact-moments-new/log-new.component';
import { EmployeeNewComponent } from '../modal/contact-persons-new/employee-new.component';
import { GenericMultiselectCheckboxOptionAllComponent } from '../other/generic-multiselect/generic-multiselect-checkbox-option-all/generic-multiselect-checkbox-option-all.component';
import { GenericMultiselectCheckboxOptionNewActionComponent } from '../other/generic-multiselect/generic-multiselect-checkbox-option-new-action/generic-multiselect-checkbox-option-new-action.component';
import { GenericMultiselectCheckboxOptionComponent } from '../other/generic-multiselect/generic-multiselect-checkbox-option/generic-multiselect-checkbox-option.component';
import { GenericMultiselectSearchProjectComponent } from '../other/generic-multiselect/generic-multiselect-search-project/generic-multiselect-search-project.component';

export interface GenericMultiselectOption {
  content: string;
  value: any;
  checked: boolean;
  optionType: MultiselectOptionType;
}

@Component({
  selector: 'app-multiselect-checkbox',
  templateUrl: './multiselect-checkbox.component.html',
  styleUrls: ['./multiselect-checkbox.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: MultiselectCheckboxComponent,
    multi: true
  }, {
    provide: NG_VALIDATORS,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => MultiselectCheckboxComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectCheckboxComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy {

  public MultiselectOptionType = MultiselectOptionType;

  @Input() public placeholder: string = '';
  @Input() public value: any;
  @Input() public required: boolean = false;
  @Input() public multiple: boolean = true;
  @Input() public valueType: string = 'array';
  @Input() public hint: string;
  @Input() public comparatorKey: string = 'id';
  @Input() public shownItems: number = 1000;
  @Input() public maxChipItems: number = 10;
  @Input() public maxChipLength: number;
  @Input() public maxChipMessage: string = '+ {count} meer items ...';
  @Input() public showChips: boolean = true;
  @Input() public type: string = "";
  @Input() public parentType: string;
  @Input() public parent: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onStakeholdersUpdated: EventEmitter<any> = new EventEmitter();
  @Input() public log;
  @Input() public logData;

  // eslint-disable-next-line no-empty-function
  public onChange: (value: any[]) => void = value => {};
    // eslint-disable-next-line no-empty-function
  public onTouched: (touched: boolean) => void = touched => {};
    // eslint-disable-next-line no-empty-function
  public onValidatorChange: () => void = () => {};
  public isDisabled = false;
  @ViewChildren('select') select: any;
  @ContentChildren(GenericMultiselectCheckboxOptionComponent, {descendants: true}) optionsCmp: QueryList<GenericMultiselectCheckboxOptionComponent>;
  @ContentChild(GenericMultiselectCheckboxOptionAllComponent, {static: false}) optionAllCmp: GenericMultiselectCheckboxOptionAllComponent;
  @ContentChild(GenericMultiselectCheckboxOptionNewActionComponent, {static: false}) newActionCmp: GenericMultiselectCheckboxOptionNewActionComponent;
  @ContentChild(GenericMultiselectSearchProjectComponent, {static: false}) searchProjectCmp: GenericMultiselectSearchProjectComponent;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autoTrigger: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete, {static: false}) autocomplete: MatAutocomplete;

  /**
   * This extra option used to store new value that is create on the fly (GenericMultiselectCheckboxOptionNewActionComponent)
   * Currently it's not possible to programmatically append child in content children (optionsCmp) thus we need an extra variable
   * to hold value of newly added entity
   * @type {any[]}
   */
  private extraOptions: GenericMultiselectOption[] = [];

  protected options: GenericMultiselectOption[] = [];
  public filteredOptions: GenericMultiselectOption[] = [];
  public searchResponse$: Observable<GenericMultiselectOption[]>;
  public selectedOptions: GenericMultiselectOption[] = [];
  public form: FormGroup;
  public showAddAction: boolean = false;
  public showSearchProject: boolean = false;
  public showSelectAll: boolean = false;
  public selectAllOption: GenericMultiselectOption;
  public addActionOption: GenericMultiselectOption;
  public searchType: string;
  public message: string;
  public AvatarCustomStyle=
  {
    "border-width": "3px",
    "font-weight": "bold",
    "width": "25px",
    "height": "25px",
    "font": "bold 10px / 20px Helvetica, Arial, sans-serif"
  };
  private optionsSubscription: Subscription;

  constructor (private formBuilder: FormBuilder,
     private cdRef: ChangeDetectorRef,
     public pubsub: PubsubService,
     private _subprojectsService: SubprojectsService,
     private _subProjectsQuery: SubprojectQuery,
    private dialog: MatDialog,
    private _cachedService: CachedService,
    public dialogRef: MatDialogRef<LogNewComponent>,
     // eslint-disable-next-line no-empty-function
     ) { }
  // eslint-disable-next-line no-empty-function, @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    this.placeholder = this.required ? `${this.placeholder}*` : this.placeholder;
    this.form = this.formBuilder.group({
      text : [''],
      keyword : [''],
    });

    if (this.multiple || this.valueType !== 'object') {
      this.valueType = 'array';
    }

    this.form.get('text').valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(150),
      )
      .subscribe(input => this.filterOptionsByKeyword());
  }

  private filterOptionsByKeyword(): void {
    this.onTouched(true);
    this.form.get('keyword').reset()
    const input = this.form.get('text').value;

    if (!input) {
      this.filteredOptions = Array.from(this.options);
      this.truncateFilteredOptions();
      this.showAddAction = false;
      this.showSearchProject = false;
      return;
    }

    if (typeof input !== 'string') {
      this.filteredOptions = Array.from(this.filteredOptions);
      this.truncateFilteredOptions();
      this.form.get('text').setValue(null);

      return;
    }

    this.filteredOptions = this.options.filter(option => option.content.toLowerCase().includes(input.toLowerCase()));
    this.truncateFilteredOptions();

    if (this.filteredOptions.length === 0) {
      if (this.newActionCmp) {
        this.newActionCmp.setValue(input);
        this.addActionOption = {
          value: this.newActionCmp.value,
          content: this.newActionCmp.content,
          checked: false,
          optionType: this.newActionCmp.optionType,
        };
        this.showAddAction = true;
      }

      if (this.searchProjectCmp) {
        this.showSearchProject = true;
        this.searchType = this.searchProjectCmp.entityText;
        // allow search only when user on project level has atleat editor role
        const userProjectLevelRoles = this.searchProjectCmp.userProjectLevelRoles()
        if (userProjectLevelRoles.length > 0)
          this.searchResponse$ = this.searchProjectCmp.search(input);
      }
    } else {
      if (this.newActionCmp) {
        this.showAddAction = false;
      }

      if (this.searchProjectCmp) {
        this.showSearchProject = false;
      }
    }

    if (this.options.length === 0 || (this.filteredOptions.length === 0 && this.optionAllCmp)) {
      this.showSelectAll = false;
    } else if (this.filteredOptions.length > 0 && this.optionAllCmp) {
      this.showSelectAll = true;
    }

    this.cdRef.detectChanges();
  }

  public resetInput(): void {
    this.filterOptionsByKeyword();
  }

  private truncateFilteredOptions(): void {
    if (this.filteredOptions.length > this.shownItems) {
      this.filteredOptions = this.filteredOptions.splice(0, this.shownItems);
    }
  }

  public ngAfterViewInit(): void {
    this.optionsSubscription = this.optionsCmp.changes.subscribe(() => {
      this.initializeAutocomplete(true);
    });

    this.initializeAutocomplete();
  }

  private initializeAutocomplete(reload: boolean = false): void {
    if (this.options.length > 0 && !reload) {
      return;
    } else if (reload) {
      this.options = deepCopy(this.extraOptions);
      this.filteredOptions = deepCopy(this.extraOptions);
    }


    if (this.optionsCmp && this.options) {
      this.optionsCmp.forEach((option: GenericMultiselectCheckboxOptionComponent) => {
        if (this.valueType === 'array') {
          option.checked = this.value && !!(this.value.find((v: any) => v[this.comparatorKey] === option.value[this.comparatorKey]));
        } else if (this.valueType === 'object') {
          option.checked = this.value ? this.value[this.comparatorKey] === option.value[this.comparatorKey] : false;
        }

        const exist = this.options.some( (o: GenericMultiselectOption) => {
          return o.value[this.comparatorKey] === option.value[this.comparatorKey];
        });

        if (!exist) {
          this.options.push({
            content: option.content,
            value: option.value,
            checked: option.checked,
            optionType: option.optionType,
          });
        }

        if (this.filteredOptions.length < this.shownItems) {
          this.filteredOptions.push({
            content: option.content,
            value: option.value,
            checked: option.checked,
            optionType: option.optionType,
          });
        }

        if (option.checked) {
          const exist = this.selectedOptions.some( (o: GenericMultiselectOption) => {
            if (!o.value || !option.value) {
              return false;
            }

            return o.value[this.comparatorKey] === option.value[this.comparatorKey];
          });

          if (!exist) {
            this.selectedOptions.push(option);
          }
        }
      });

      this.cdRef.detectChanges();
    }

    if (this.optionAllCmp) {
      this.selectAllOption = {
        value: this.optionAllCmp.value,
        content: this.optionAllCmp.content,
        checked: false,
        optionType: this.optionAllCmp.optionType,
      };

      if (this.filteredOptions.length > 0) {
        this.showSelectAll = true;
      }
    }

    if(this.type === 'contactpersons'){
      this.showSelectAll = true;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = (touched: boolean) => {
      if (this.required && (!this.value
        || (this.valueType === 'array' && this.value.length === 0)
        || (this.valueType === 'object' && !this.value[this.comparatorKey]
        ))
      ) {
        this.form.get('text').setErrors({
          required: true
        });
      }

      this.form.get('text').markAsTouched();

      fn(touched);
      this.cdRef.detectChanges();
    };
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('text').disable();
      this.isDisabled = true;
    } else {
      this.form.get('text').enable();
      this.isDisabled = false;
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.errors) {
      if (!this.required && control.errors.required) {
        return null;
      } else if (this.required && this.value &&
        ((this.valueType === 'array' && this.value.length > 0) || (this.valueType === 'object' && this.value[this.comparatorKey]))
      ) {
        return null;
      } else if (this.required && control.errors.required) {
        this.form.get('text').setErrors({
          required: true
        });
      }

      return control.errors;
    }

    return null;
  }

  public writeValue(obj: any[]): void {
    if (!obj) {
      this.message = "";
      if (this.valueType === 'array') {
        obj = [];
      } else {
        obj = null;
      }
    }

    this.value = obj;

    if (this.options) {
      // Re-initialize the selected options
      this.options.forEach((option: GenericMultiselectOption) => {
        if (this.value && this.valueType === 'array') {
          option.checked = !!this.value.find((o: any) => o[this.comparatorKey] === option.value[this.comparatorKey]);
        } else if (this.value && this.valueType === 'object') {
          option.checked = this.value[this.comparatorKey] === option.value[this.comparatorKey];
        }
      });
      this.setMessage()
      this.initializeAutocomplete(true);
      this.updateSelectedCheckedStatus(false);
    }
  }

  setMessage(){
    this.message = ""
    if (this.value && this.valueType === 'array') {
      this.value.forEach(e => {
        if(e.stakeholders && e.stakeholders.length > 1 && (!e.selected || e.selected && !e.selected.length)){
          this.message = translate('text.multiple_stakeholder_in_employee')
        }
      });

    } else if (this.value && this.valueType === 'object') {
      if(this.value.stakeholders && this.value.stakeholders.length > 1 && (!this.value.selected || this.value.selected && !this.value.selected.length)){
        this.message = translate('text.multiple_stakeholder_in_employee')
      }
    }
  }

  public selectSearchedOption(clickedOption: GenericMultiselectOption): void {
    if (!this.searchProjectCmp) {
      return;
    }

    this.form.get('text').reset();
    this.form.get('keyword').reset();
    const isAdd = this.searchProjectCmp.selectOption(clickedOption);
    if (isAdd) {
      this.selectedOptions.push(clickedOption);
      if (this.valueType === 'array') {
        this.value.push(clickedOption.value);
      } else {
        this.value = clickedOption.value;
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(o => o.value.id !== clickedOption.value.id);

      if (this.valueType === 'array') {
        this.value = this.value.filter(o => o.value.id !== clickedOption.value.id);
      } else {
        this.value = null;
      }
    }

    this.onChange(this.value);
  }

  public toggleOption(clickedOption: GenericMultiselectOption,event?:Event): void {
    if (event && this.multiple) {
      event.stopPropagation();
      this.autoTrigger.openPanel();
    }
    this.form.get('text').reset();
    this.form.get('keyword').reset();

    if (clickedOption.optionType === MultiselectOptionType.entry || clickedOption.optionType === MultiselectOptionType.project_search) {
      // If it's one value only, remove all existing check and assign with the newly selected checbox
      if (!clickedOption.checked && (
        (!this.multiple && this.valueType === 'array' && this.value && this.value.length > 0) || this.valueType === 'object' && this.value && this.value[this.comparatorKey])
      ) {
        this.options.forEach(option => option.checked = false);
        this.filteredOptions.forEach(option => option.checked = false);
      }

      const foundOption = this.options.find(option => option.value[this.comparatorKey] === clickedOption.value[this.comparatorKey]);
      const foundExtraOption = this.extraOptions.find(option => option.value[this.comparatorKey] === clickedOption.value[this.comparatorKey]);

      if (this.multiple) {
        this.autoTrigger.openPanel(); // keep autocomplete open
      }

      if (clickedOption.optionType !== MultiselectOptionType.project_search) {
        foundOption.checked = !clickedOption.checked; // toggle the value
        if(this.type == "subprojects"){
          if(foundOption.checked)
            this.updateSubproject(foundOption.value)
          else
            this.delete(foundOption)
        }
      }

      if (foundExtraOption) {
        foundExtraOption.checked = foundOption.checked;
      }

      this.resetOptionalAllCheckbox();
      this.updateSelectedCheckedStatus();

      if (!this.value || !this.options) {
        return;
      }

      if (this.value.length >= this.options.length) {
        this.autoTrigger.closePanel() // close autocomplete panel because all of them are selected
      }
    } else if (clickedOption.optionType === MultiselectOptionType.new && this.newActionCmp) {
      this.showAddAction = false;

      // If it's one value only, remove all existing check and assign with the newly selected checbox
      if (!clickedOption.checked && (
        (!this.multiple && this.valueType === 'array' && this.value && this.value.length > 0) || this.valueType === 'object' && this.value && this.value[this.comparatorKey])
      ) {
        this.options.forEach(option => option.checked = false);
        this.filteredOptions.forEach(option => option.checked = false);
      }

      this.newActionCmp.create().subscribe((newEntries: any[]) => {
        const newOptions: GenericMultiselectOption[] = newEntries.map( (entry: any) => {
          return {
            value: entry,
            content: this.newActionCmp.input,
            checked: true,
            optionType: MultiselectOptionType.entry,
          };
        });

        this.options = [...this.options, ...newOptions];
        this.extraOptions = [...this.extraOptions, ...newOptions];
        this.filteredOptions = Array.from(this.options);
        this.updateSelectedCheckedStatus();
      });

      this.resetOptionalAllCheckbox();
    } else if (clickedOption.optionType === MultiselectOptionType.all) {
      // clickedOption.checked = !clickedOption.checked;
      if (this.value.length < this.options.length) {
        this.options.forEach(option => option.checked = true);
        this.filteredOptions.forEach(option => option.checked = true);
        this.value = this.options.map(option => option.value);
        if (this.valueType === 'object') {
          this.value = this.value[0];
        }

        this.showSelectedOptions(this.options);
      } else {
        this.options.forEach(option => option.checked = false);
        this.filteredOptions.forEach(option => option.checked = false);
        if (this.valueType === 'array') {
          this.value = [];
        } else {
          this.value = null;
        }
        this.selectedOptions = [];
      }

      this.markChangeTouched();
    }
    this.setMessage();
    this.cdRef.detectChanges();
  }

  delete(option: GenericMultiselectOption){
    if(this.type == "subprojects"){
      const activeSubProject = this._subProjectsQuery.getActive();
      if (activeSubProject && activeSubProject.code == option.value.code) {
        const modal = this.dialog.open(MessageConfirmComponent,{
          data:{title:translate("text.deselect_current_subproject"),body:translate("text.deselect_current_subproject_text"), type: "remove_subprject"},
          width:"30vw"
        });
        modal.afterClosed().subscribe((res)=>{
          if(res){
            this.removeSubproject(option.value,true)

          }
        })

      }
      else{
        this.removeSubproject(option.value)
        this.removeOption(option)
      }
    }
    else{
      this.removeOption(option)
    }
    this.setMessage();
  }

  public removeOption(option: GenericMultiselectOption): void {
    const foundOption = this.options.find(o => option[this.comparatorKey] === o.value[this.comparatorKey]);
    if (foundOption) {
      foundOption.checked = false;
    }

    const extraFoundOption = this.extraOptions.find(o => option[this.comparatorKey] === o.value[this.comparatorKey]);
    if (extraFoundOption) {
      extraFoundOption.checked = false;
    }

    this.updateSelectedCheckedStatus();
  }

  public updateSelectedCheckedStatus(markChangedTouched: boolean = true): void {
    if (this.options.length === 0) {
      return;
    }

    const selected: GenericMultiselectOption[] = [];

    this.options.forEach(option => {
      const foundFiltered: GenericMultiselectOption = this.filteredOptions.find( o => o.value[this.comparatorKey] === option.value[this.comparatorKey]);
      if (foundFiltered) {
        foundFiltered.checked = option.checked;
      }

      if (option.checked) {
        selected.push(option);
      }
    });
    let _selected = []
    if (this.value && this.valueType === 'object') {
      if(this.value.selected)
        _selected = this.value.selected;
    }

    this.showSelectedOptions(selected);
    this.value = selected.map(option => option.value);
    if (this.valueType === 'object') {
      this.value = this.value[0];
      if(this.value)
        this.value.selected = this.value.stakeholders.filter(x => _selected.find(s => s.id == x.id));

    }

    if (this.value && this.options && this.selectAllOption) { 
      if (this.value.length >= this.options.length) {
        this.selectAllOption.content = translate('deselect_all');
      } else {
        this.selectAllOption.content = translate('select_all');
      }
    }

    if (markChangedTouched) {
      this.markChangeTouched();
    }

    if (this.optionAllCmp && this.filteredOptions.length > 0) {
      this.showSelectAll = true;
    }

    this.cdRef.detectChanges();
  }

  private resetOptionalAllCheckbox(): void {
    if (this.optionAllCmp) {
      this.optionAllCmp.checked = false;
    }
  }

  private showSelectedOptions(options: GenericMultiselectOption[]): void {
    const copy: GenericMultiselectOption[] = deepCopy(options);
    if (copy.length <= this.maxChipItems) {
      this.selectedOptions = copy;
    } else {
      this.selectedOptions = copy.slice(0, this.maxChipItems);
      this.selectedOptions.push({
        content: `${this.maxChipMessage.replace('{count}', (copy.length - this.maxChipItems).toString())}`,
        value: null,
        checked: true,
        optionType: MultiselectOptionType.moremarker,
      });
    }
  }

  private markChangeTouched(): void {
    if (typeof this.onChange === 'function') {
      this.onChange(this.value);
    }
    if (typeof this.onTouched === 'function') {
      this.onTouched(true);
    }
    this.form.get('text').markAsTouched();
  }

  openSelect(option,i){
    if(option.stakeholders && option.stakeholders.length>1)
      this.select.toArray()[i].open()
    else{
      if(this.type == "contactpersons")
        this.nav(option);
    }
  }

  clickStakeholder(data,option){
    if(option.selected.find(s => s.id == data.id))
      this.onStakeholdersUpdated.emit({data,action:"add"})
    else
      this.onStakeholdersUpdated.emit({data,action:"remove"})
    this.setMessage()
  }

  nav(data){
    const component = EmployeeEditComponent;
    const size = ModalEnum.SidebarSmallResponsive;
    this._cachedService.getEmployeesWithLogs()
        .subscribe(employees => {
          data = employees.find(e => e.id === data.id);
          this.pubsub.closeModal(this.pubsub.currentDialog)
          const modal = this.dialog.open(component, modalConfig({
            data: data,
            panelClass: ['animate__animated', 'animate__slideInRight'],
            disableClose:true,
            closeOnNavigation:true
          }, size));
          let name = data.name;
          if(this.type == 'contactpersons'){
            name = `${data.first_name || ""} ${ data.last_name || ""}`
          }
          this.pubsub.updateHistory(modal,component,data,name,size)
        });

  }

  removeSubproject(subProject,closeModal=false){

    this._subprojectsService.select(subProject.id, new WithParams().with(['stakeholders', 'activities', 'complaints', 'requirements', 'issues', 'contents', 'residents', 'tasks',]))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const res = this._subProjectsQuery.getEntity(subProject.id);
        res[this.parentType] = res[this.parentType].filter((s)=>s.id != this.parent.id)
        this._subprojectsService.update(res)
          .subscribe(
          subProject => {
            if (!subProject) {
              return;
            }
            if(closeModal){
              this.pubsub.closeModal(this.pubsub.currentDialog)
            }
            showFeedbackSaved();
          }
        );
      });


  }

  updateSubproject(subProject){

    this._subprojectsService.select(subProject.id, new WithParams().with(['stakeholders', 'activities', 'complaints', 'requirements', 'issues', 'contents', 'residents', 'tasks',]))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const res = this._subProjectsQuery.getEntity(subProject.id);
        res[this.parentType].push(this.parent)
        this._subprojectsService.update(res)
          .subscribe(
          subProject => {
            if (!subProject) {
              return;
            }
            showFeedbackSaved();
          }
        );
      });


  }
  addContactPerson(event: Event){
    // close new contact moment modal
    const newContactMoment = this.log.created_at;
    // eslint-disable-next-line no-unused-expressions
    newContactMoment? null: this.dialogRef.close();

    const params = {name: this.newActionCmp.input, type: this.type? this.type: ''}
    const data: NewEntityData = {
      params: params,
      options: {},
    };
    // Open modal to Create newEmployee
    const dialog = this.dialog.open(EmployeeNewComponent, modalConfig({
      data: data,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));

    // catch response after employee created
    dialog.afterClosed().subscribe((response: any) => {

      if(newContactMoment){
        // edit contactMement contactpersons
        // prepare and add employee object in contactpersons selected array
        const option = {
          content: response.employee? response.employee.first_name: `${'' + ' '}${response.employee}`? response.employee.last_name: '',
          checked: false,
          optionType:0,
          value: response.employee
        };
        this.options.push(option);
        this.filteredOptions.push(option);
        // toggle the selected employee into contactpersons
        this.toggleOption(option);

      }

      // check newContactMoment
      if(!newContactMoment){

        // filter and add employee stakeholders to contact moment stakholders array
        if(response.employee){
          this.log.contactpersons.push(response.employee);
        }

        const stakeholders: any[] = response.employee ? response.employee.stakeholders : '';
        stakeholders.forEach(s => {
          // avoid duplication
          const found = this.log.stakeholders.some(el => el.id === s.id);
          if(!found) {this.log.stakeholders.push(s)}
        })

        // open new contactMoement modal
        const origin = this.log.origin ? this.log.origin : '';
        delete this.log.origin;
        this.logData.log = this.log;
        if (origin === 'connect-generic') {
          this.pubsub.newLogEntity.next(this.logData)
        } else {
          this.dialog.open(LogNewComponent, modalConfig({
            data: this.logData,
            panelClass: 'customized-dialog-container',
          }, ModalEnum.ModalDefault))
        }

        // dialog.afterClosed().subscribe((dialogData: {
        //   log: any,
        //   updated: boolean,
        // }) => {
        //   debugger
        //   if (dialogData && dialogData.updated && dialogData.log) {
        //     this.pubsub.newLogEntity.next(dialogData.log)
        //   }
        // });
      }
    });
  }

  //on dropdown search filter
  public searchFilter(text): void {
    // filter contactpersons -> search by stakeholders name

    const input = this.form.get('keyword').value;

    if (input === '') {
      this.filteredOptions = this.options;
    }
    if(input?.length > 1){
      this.filteredOptions = [];
    this.options.forEach(item => {
      const options = item.value.stakeholders.filter(option => option.name.toLowerCase().includes(input.toLowerCase()));
      if(options.length){
        const found = this.filteredOptions.some(el => el.content === item.content);
        if(!found){ this.filteredOptions.push(item);}
      }
    })
    }
    this.cdRef.detectChanges()
  }
}
