<form class="nav gd-navbar-center navbar-form full-width no-padding" *transloco="let t">
    <div class="has-feedback full-width">
        <mat-form-field class="full-width">
            <input name="keywords" [(ngModel)]="keywords" matInput type="text" [placeholder]="t('actions.relation') | capitaliz" [matAutocomplete]="auto" #input (ngModelChange)="searchInput()" />
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete" (click)="$event.preventDefault(); $event.stopPropagation()">
            <mat-optgroup *ngFor="let items of models" [label]="items.oprationname | capitaliz">
                <mat-option *ngFor="let result of items.enities " [value]="result.name" (click)="selectItem(result)">
                    <mat-checkbox (click)="$event.preventDefault()">
                        <ng-container>
                            {{ result.name }}
                        </ng-container>
                    </mat-checkbox>
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
    </div>
</form>