import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Stakeholder } from 'src/app/akita/stakeholders/state/stakeholder.model';

@Component({
  selector: 'app-stakeholder-strategy-modal',
  templateUrl: './stakeholder-strategy-modal.component.html',
  styleUrls: ['./stakeholder-strategy-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StakeholderStrategyModalComponent implements OnInit {

  public stakeholder: Stakeholder;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<StakeholderStrategyModalComponent>) {
    //
  }


  ngOnInit(): void {
    this.stakeholder = this.data.stakeholder;
  }

}
