import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import moment from 'moment';
import posthog from 'posthog-js';
import { forkJoin, of } from 'rxjs';
import { finalize } from "rxjs/operators";
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { Action } from 'src/app/akita/tasks/state/action.model';
import { ActionsService as _ActionsService } from 'src/app/akita/tasks/state/actions.service';
import { Permission } from 'src/app/core/enum/permission';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { deleteConfirmationResponce } from 'src/app/core/util/deepCopy';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { deletedUsersForClosedTickets, globalSubprojectUserSaprator, selectedSubprojectUsersSaprator, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { ActionsService } from "../../../../core/service/api/actions.service";
import { DocumentsService } from '../../../../core/service/api/documents.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { CachedService } from "../../../../core/service/common/cached.service";
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { dateIso } from "../../../../core/util/dateIso";
import { showFeedbackRemoved, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from "../../../../globals";
import { MultiselectUsersComponent } from "../../form/multiselect/multiselect-users/multiselect-users.component";
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';
import { NewWorkflowComponent } from '../new-workflow/new-workflow.component';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';

@Component({
  selector: 'app-action-edit',
  templateUrl: './action-edit.component.html',
  styleUrls: ['./action-edit.component.sass']
})
export class ActionEditComponent extends GenericComponent implements OnInit {

  public stakeholders: any[];
  public users: any[];
  public actionForm: FormGroup;
  public isProcessing = false;
  public action: Action;
  public name: string;
  entities: any
  loaded: boolean = false;
  public reducer:any=true;
  actionCode: string;
  subprojectsSet:any[]=[];
  chipDisabler:any[]=[];
  chipDisablerUser:any[]=[];
  userSelection:any[]=[];
  @ViewChild(MultiselectUsersComponent) private usersComponent: MultiselectUsersComponent;

  constructor(public globals: Globals, private formBuilder: FormBuilder,
    private _cachedService: CachedService,
    private _actionsService: ActionsService,
    private _logsService: LogsService,
    public dialog: MatDialog,
    private subProjectQuery: SubprojectQuery,
    private _documentsService: DocumentsService,
    private cd: ChangeDetectorRef,
    private actionsService: _ActionsService,
    public router: Router,
    private _pusherService: PusherService,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ActionEditComponent>, private pubsub: PubsubService) {
    super();
  }

  public ngOnInit(): void {
    this.subprojectSetup();
    this.name = this.data? this.data.text? this.data.text : '' : '';
    this._cachedService.getUsers().subscribe(
      users => {this.users = users; this.usersSetup();}
    );

    this._cachedService.getStakeholdersRelations().subscribe(
      stakeholders => this.stakeholders = stakeholders
    );

    this.actionsService.find(this.data.id, ['contactmoments', 'issues', 'requirements', 'complaints', 'contents', 'stakeholders', 'documents'], true).subscribe((action) => {
      this.action = action;
      this.actionCode = action.code;
      this.action.contents = action.middel
      this.actionForm = this.formBuilder.group({
        users: [this.action.users, Validators.required],
        stakeholders: [this.action.stakeholders],
        text: [this.action.text, Validators.required],
        timestamp: [dateIso(this.action.timestamp, true, this.globals.timezone), Validators.required],
        handled: [this.action.handled],
        action: [this.action],
      });
      forkJoin([
        this._cachedService.getComplaintsRelations(),
        this._cachedService.getIssuesRelations(),
        this._cachedService.getDecisionsRelations(),
        this._cachedService.getLogsRelations(),
        this._cachedService.getActivities(),
        this._cachedService.getDocumentsRelations(),
         // Check if 'permit' exists in the modules, otherwise skip the API call
         this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
         ? this._cachedService.getPermitsRelations() 
         : of([])
      ]
      ).subscribe(([complaints, issues, requirements, contactmoments, activities,permits, documents]) => {
        this.entities = { complaints, issues, requirements, contactmoments: contactmoments, activities, documents: documents, permits: permits }
        this.loaded = true;
      });
      this.relaticsValidation();
      this.setLodedSubprojectUsers();
      this.setLodedSubproject();
      this.permissionDisabled();
    })
    posthog.capture('task-detail');
  }
  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  public deleteAction(action: Action): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(action,this.router,'actions'),
      disableClose: true,
    });

    dialogRef.afterClosed()
      .subscribe((remove: boolean) => {
        if (remove) {
          this.actionsService.delete(action).subscribe(
            () => {
              showFeedbackRemoved();
              this.close();
            }
          );
        }
      });
  }

  public handleAction(action: Action): void {
    this.actionsService.update(action)
      .subscribe(
        () => {
          const message: string = action.handled ? translate('text.action_is_handled') : translate('text.action_is_recovered');
          this._pusherService.onCangeStatus();
          this.setLodedSubprojectUsers();
          showFeedbackSaved(message);
        },
      );
  }

  public submit(): void {
    if (this.actionForm.invalid) {
      return;
    }
    this.actionForm.value.timestamp = moment(this.actionForm.value.timestamp).endOf('day');
    this.actionForm.value.timestamp = moment(this.actionForm.value.timestamp).subtract(1, 'seconds')
    this.isProcessing = true;
    if (this.actionForm.value.timestamp.toJSON)
      this.actionForm.value.timestamp = this.actionForm.value.timestamp.toJSON();
    this.actionForm.value.middel = this.actionForm.value.action.middel;
    this.actionForm.value.subprojects = this.action.subprojects;

    this._actionsService.updateActionById(this.action.id, this.actionForm.value)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        () => {
          // this.action = this.actionForm.value;
          showFeedbackSaved();
          this.pubsub.setActionEdited(this.action);
        }
      );
  }

  updateSubprojects() {
    setTimeout(() => {
      this.pubsub.updateSubprojects(this, "submit", this.action.subprojects)

    }, 500);
  }

  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'tasks', parent: this.action},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.action.workflows.push(response);
        this.submit();
      }
    });
  }

  updateUsers(users) {
    this.actionForm.value.users = users;
    this.submit()
  }
  updateEntities(event: any) {
    this.actionForm.value[event.type] = event.selected;
    this.submit();
  }
  public relaticsValidation(){
    if(this.action.relatics_uuid){
      if(this.globals.relaticsColumn.actions){
        let columns;
        const dialogcolumns=[];
        // eslint-disable-next-line prefer-const
        columns=this.globals.relaticsColumn.actions.columns;
        columns.map((element)=>{
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer=dialogcolumns.reduce((ac,a) => ({...ac,[a]:a}),{});
        this.cd.detectChanges();
      }
    }
  }
  public permissionDisabled(){
    if(!this.hasPermission(Permission.ActionUpdate)){
      this.actionForm.get('text').disable();
      this.actionForm.get('users').disable();
      this.actionForm.get('stakeholders').disable();
      this.actionForm.get('timestamp').disable();
      this.actionForm.get('handled').disable();
      this.actionForm.get('action').disable();
    }
  }
  public subprojectSetup(){
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects,'actions:update')
  }
  public setLodedSubproject(){
    this.chipDisabler=[];
    this.chipDisabler=selectedSubprojectSaprator(this.subprojectsSet,this.action.subprojects)
  }
  public usersSetup(){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
      this.users=globalSubprojectUserSaprator(this.users,activeSubProject)
    }
  }
  public setLodedSubprojectUsers(){
    this.chipDisablerUser=[];
    if(this.users){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
    this.chipDisablerUser=selectedSubprojectUsersSaprator(this.users,this.action.users);
    }
    const deletedUsers = deletedUsersForClosedTickets(this.action.handled, this.action.users);
    this.chipDisablerUser = [...this.chipDisablerUser, ...deletedUsers]
  }
  }
}
