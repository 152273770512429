import {GoogleAddress} from "./map/GoogleAddress";
import {AddressType} from "../../core/enum/address-type";

export function googleMapAddressParse($event: any): GoogleAddress {
  const googleAddress = new GoogleAddress();
  let addressName = '';
  let addressNumber = '';
  if($event){
    if($event.address_components){
      $event.address_components.forEach(component => {
        if (component.types.includes('route')) {
          addressName = component.long_name;
        }

        if (component.types.includes('street_number')) {
          addressNumber = component.long_name;
        }

        if (component.types.includes('postal_code')) {
          googleAddress.postcode = component.long_name;
        }

        if (component.types.includes('locality')) {
          googleAddress.city = component.long_name;
        }

        if (component.types.includes('administrative_area_level_1')) {
          googleAddress.province = component.long_name;
        }

        if (component.types.includes('country')) {
          googleAddress.country = component.long_name;
        }
      });
    }
  }

  googleAddress.address = addressName;
  if (addressNumber) {
    googleAddress.address += ` ${ addressNumber}`;
  }

  if ($event.formatted_phone_number) {
    googleAddress.phone = $event.formatted_phone_number;
  }

  if ($event.geometry && $event.geometry.location) {
    googleAddress.latitude = $event.geometry.location.lat();
    googleAddress.longitude = $event.geometry.location.lng();
  }

  if ($event.types &&
    ($event.types.includes('establishment') ||
      $event.types.includes('restaurant') ||
      $event.types.includes('food') )) {
    googleAddress.addressType = AddressType.Company;
  } else {
    googleAddress.addressType = AddressType.Resident;
  }

  return googleAddress;
}
