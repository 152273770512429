import { Injectable } from '@angular/core';
import { AbstractService } from '../common/abstract.service';
import { HttpClient } from '@angular/common/http';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService extends AbstractService {
  projectCode: any
  constructor(private http: HttpClient,private route: ActivatedRoute) {
    super();
    this.projectCode = localStorage.getItem('project_name')
  }

  @ServiceErrorHandler()
  getWhatsappintegration(body: any) {
    return this.http.post(`/api/integrations/whatsapp/project/connect/${this.projectCode}`, body);
  }

  @ServiceErrorHandler()
  getTokken(code: any) {
    const platform = 'whatsapp'
    return this.http.get(`/api/integrationconfigs/gettoken?platform=${platform}&code=${code}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getPhoneNumbers() {
    return this.http.get(`/api/integrations/whatsapp/phone_numbers`, this.httpOptions);
  }

  @ServiceErrorHandler()
  setPinCode(body) {
    return this.http.post(`/api/integrations/whatsapp/register/phone_number`, body);
  }

}
