<div class="main">
    <div>
      <h4 class="title">{{ 'confirmation_issue_interest.title' | transloco | capitaliz }}</h4>
    </div>
  
    <div class="popup-body">
      {{ 'confirmation_issue_interest.body' | transloco  }}
    </div>
  
    <div class="popup-footer">
      <div class="dialog-button-row">
        <button type="button" mat-stroked-button (click)="submit(false)">
          {{ 'confirmation_issue_interest.cancel' | transloco | capitaliz }}
        </button>
        <button
          type="button"
          class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised btn-danger"
          (click)="submit(true)"
        >
        {{ 'confirmation_issue_interest.disconnect' | transloco | capitaliz }}
        </button>
      </div>
    </div>
  </div>
  