import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Pusher from 'pusher-js';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PusherService {
  public commentBoxPusher = new EventEmitter();
  public commentBox: Subscription;
  pusher: any;
  channel: any;
  code:any;
  // eslint-disable-next-line no-empty-function
  constructor(private http: HttpClient,public globals: Globals,public _rout: Router,) {}
  // pusher active
   public pusherGetActive(){
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster
    });
    if(this.globals.projectCode=='beheer'){
      this.code=this._rout.url.split("/")[2];;
    }else{
      this.code=this.globals.projectCode;
    }
    this.channel = this.pusher.subscribe(this.code);
   }
   //on change any status call the collaboration api
   onCangeStatus(){
    this.commentBoxPusher.emit();
  }
}
