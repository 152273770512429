<div *transloco="let t">
    <div class="main" *ngIf="!data.successful">

        <div>
            <h4 class="title">{{data.title}}</h4>

        </div>
        <div class="popup-body">
            {{ data.text }}
        </div>

        <mat-checkbox labelPosition="before" class="confirm-checkbox" *ngIf="data.showCheckbox" [(ngModel)]="confirm" name="confirm"> {{ t('text.archive.project_archive')}}</mat-checkbox>
        <p *ngIf="showerror" class="text-red">Something went wrong. Please try again or contact support</p>
        <div class="popup-footer">
            <div class="dialog-button-row">
                <button *ngIf="!loader" type="button" mat-stroked-button (click)="submit(false)">Annuleren</button>
                <button *ngIf="!loader" type="button" class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised btn-danger" [disabled]="!confirm && data.showCheckbox" (click)="submit(true)">
                <mat-icon class="set-arcg-icon">archive</mat-icon>
                <span>Archiveren</span>
              </button>
                <button *ngIf="loader" type="button" class="text-black-50 btn mdl-button mat-flat-button mdl-js-button mdl-button--raised btn-danger" [disabled]="true" (click)="submit(true)">
                  <i class="fa fa-circle-o-notch fa-spin button-icon-loder"></i>
                <span>Archiveren</span>
              </button>
            </div>
        </div>
    </div>

    <div class="main" *ngIf="data.successful">
        <div>
            <h4 class="title">{{data.title}}</h4>
        </div>
        <div class="popup-body">
            <p *ngIf="!data.credential.message">{{ data.text }}</p>
            <p *ngIf="data.credential.message">{{ data.credential.text }}</p>
            <div *ngIf="!data.credential.message">
                <p>Username: {{data.credential.username}}</p>
                <p>Password: {{data.credential.password}}</p>
            </div>
        </div>
        <mat-checkbox labelPosition="before" class="confirm-checkbox" *ngIf="data.showCheckbox && !data.credential.message" [(ngModel)]="confirm" name="confirm">{{ t('text.archive.project_archive')}}</mat-checkbox>
        <div class="popup-footer">
            <div class="dialog-button-row">
                <button type="button" class="btn mdl-button mat-flat-button-close mdl-js-button mdl-button--raised " [disabled]="!confirm && data.showCheckbox && !data.credential.message" (click)="submit(false)">
                  <span>close</span>
                </button>
            </div>
        </div>
    </div>
</div>