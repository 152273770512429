import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-outlook-redirect',
  templateUrl: './outlook-redirect.component.html',
  styleUrls: ['./outlook-redirect.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutlookRedirectComponent implements OnInit {

  constructor(private _rout: Router, public globals: Globals,) {
    // prevent lint for empty contstructor
   }

  ngOnInit(): void {
    const projectcode = localStorage.getItem('project_name');

    const currentURL = window.location.href;

    // Create a URL object
    const urlObject = new URL(currentURL);

    // Get the value of the 'code' parameter
    const code = urlObject.searchParams.get('code');

    // Get the value of the 'state' parameter
    // const state = urlObject.searchParams.get('state');

    localStorage.setItem('outlook_code', code);
    const url = `${window.location.protocol }//${ window.location.host }/${ projectcode }/instellingen/integraties`
    window.location.href = url;
  }

}
