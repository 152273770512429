import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {Globals} from '../../../globals';
import {MatDialogRef} from '@angular/material/dialog';
import {Subproject} from '../../../akita/subproject/state/subproject.model';
import {SubprojectQuery} from '../../../akita/subproject/state/subprojects.query';
import {Observable} from 'rxjs';
import {SubprojectsService} from '../../../akita/subproject/state/subprojects.service';
import {ID} from '@datorama/akita';
import {ActivatedRoute, Router} from '@angular/router';
import {showFeedbackError} from '../../../core/util/notification';

@Component({
  selector: 'app-subproject-selector-modal',
  templateUrl: './subproject-selector-modal.component.html',
  styleUrls: ['./subproject-selector-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubprojectSelectorModalComponent implements OnInit {

  AvatarCustomStyle = {
    "border-width": "3px",
    "font-weight": "bold",
    "width": "37px"
  };

  public subProjects$: Observable<Subproject[]>;
  constructor(public dialogRef: MatDialogRef<SubprojectSelectorModalComponent>, public globals: Globals, private activatedRoute: ActivatedRoute,
              // eslint-disable-next-line no-empty-function
              private subProjectsService: SubprojectsService, private subProjectQuery: SubprojectQuery, private router: Router,) { }

  public ngOnInit(): void {
    this.subProjects$ = this.subProjectQuery.selectAll();
  }

  public switchSubProject(subProjectId: ID): void {
    const activeSubProject = this.subProjectQuery.getActive();

    if (!subProjectId && !activeSubProject) {
      this.dialogRef.close();
      return;
    }

    // eslint-disable-next-line prefer-const
    let [dummy, projectCode, ...rest] = this.router.url.split('/');
    if (activeSubProject) {
      rest = this.router.url.split('/').slice(4);
    }

    if (!subProjectId) {
      this.router.navigate([...rest], {
        state: {
          selectSubproject: true
        }
      });
      return;
    }

    const subProject = this.subProjectQuery.getEntity(subProjectId);
    if (!subProject) {
      showFeedbackError(`Sub-project ${subProjectId} bestaat niet`, `Sub-project ${subProjectId} bestaat niet`);
      return;
    }

    this.subProjectsService.set(subProjectId);
    this.router.navigate([...rest], {
      state: {
        selectSubproject: true,
        subprojectCode: subProject.code,
      }
    });

    this.dialogRef.close();
  }
}
