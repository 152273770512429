import {AddressType} from "../../enum/address-type";

export class GoogleAddress {
  private _address: string;
  private _postcode: string;
  private _city: string;
  private _province: string;
  private _country: string;
  private _phone: string;
  private _latitude: string;
  private _longitude: string;
  private _addressType: AddressType;

  get address(): string {
    return this._address;
  }

  set address(address: string) {
    this._address = address;
  }

  get postcode(): string {
    return this._postcode;
  }

  set postcode(postcode: string) {
    this._postcode = postcode.replace(new RegExp(' ', 'g'), '');
  }

  get city(): string {
    return this._city;
  }

  set city(city: string) {
    this._city = city;
  }

  get province(): string {
    return this._province;
  }

  set province(province: string) {
    this._province = province;
  }

  get country(): string {
    return this._country;
  }

  set country(country: string) {
    this._country = country;
  }

  get phone(): string {
    return this._country;
  }

  set phone(phone: string) {
    this._phone = phone;
  }

  get latitude(): string {
    return this._latitude;
  }

  set latitude(latitude: string) {
    this._latitude = latitude;
  }

  get longitude(): string {
    return this._longitude;
  }

  set longitude(longitude: string) {
    this._longitude = longitude;
  }

  get addressType(): AddressType {
    return this._addressType;
  }

  set addressType(addressType: AddressType) {
    this._addressType = addressType;
  }
}
