import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionsService } from 'src/app/akita/tasks/state/actions.service';
import { StakeholdersService } from 'src/app/core/service/api/stakeholders.service';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.sass'],
})
export class FilterDropdownComponent implements OnInit {
  show: boolean = false;
  filtershow:boolean=true;
  sortField:boolean=true;
  selectedEntity: any = [];
  disable:boolean=false;
  @Input() public entity: string;
  @Input() public type: string;
  @Input() public chartType: string;
  @Output() syncuncategorised: EventEmitter<any> = new EventEmitter();
  @ViewChild('filterSort', { static: false }) filterSort;
  constructor(private _stakeholdersService: StakeholdersService,private _actionsService: ActionsService,private cdRef: ChangeDetectorRef) {
    document.addEventListener('click', this.offClickHandler.bind(this));
   }

  ngOnInit() {
    this._stakeholdersService.toggle.subscribe(res=>{
      this.toggle();
    });
    if(this.entity.length==0){
      this.disable=true;
    }
  }

  toggle(){
    this.show = !this.show;
    this.cdRef.detectChanges();
  }
  public cancel(){
    this.selectedEntity=[]
    if(this.chartType=='power'){
      this.syncuncategorised.emit(this.selectedEntity);
    }
    if(this.chartType=='trust'){
      this.syncuncategorised.emit(this.selectedEntity);
    }
    this.cdRef.detectChanges();
    this.toggle();
  }
  public apply(){
    if(this.type=='matrix'){
      if(this.selectedEntity.length>0){
        let matrixAlog=this.selectedEntity;
        matrixAlog=matrixAlog.map(
          (element,key)=>{
            return {
              x: 50,
              y: 50,
              z: 80,
              color:'#b4b4b5',
              name: null,
              stakeholderId: element.id,
              code:element.code,
              by_filter:'true',
              dataLabels: {
                enabled: true,
                format: element.dataLabels.format,
                labelrank:key,
              }
            };
          }
        );
        if(this.chartType=='power'){
          this.syncuncategorised.emit(matrixAlog);
        }
        if(this.chartType=='trust'){
          this.syncuncategorised.emit(matrixAlog);
        }
      }else{
        if(this.chartType=='power'){
          this.syncuncategorised.emit(this.selectedEntity);
        }
        if(this.chartType=='trust'){
          this.syncuncategorised.emit(this.selectedEntity);

        }
      }
      this.cdRef.detectChanges();
      this.toggle();
    }
  }
  offClickHandler(event: any) {
    if (!this.filterSort.nativeElement.contains(event.target)) {
      if (event.target.className.includes && !event.target.className.includes('overlay') &&
        !event.target.className.includes('mat-option') &&
        this.filterSort.nativeElement.parentElement.parentElement != event.target.parentElement
        && event.target.parentElement &&
        this.filterSort.nativeElement.parentElement.parentElement != event.target.parentElement.parentElement) {
        this.show = false;
        this.cdRef.detectChanges();
      }
  }
}
}
