import {PubsubService} from "../../../../../core/service/api/pubsub.service";
import {ActivatedRoute} from "@angular/router";
import {ResidentsService} from "../../../../../core/service/api/residents.service";
import {ChildTabbedPage} from "../../../../../core/util/abstract/child-tabbed-page";
import {Globals} from "../../../../../globals";
import { Resident } from "src/app/akita/residents/state/resident.model";
import { Directive } from "@angular/core";
@Directive({
})
export abstract class TabResidentsDetailComponent extends ChildTabbedPage {

  pageSubTitle = 'Terug naar overzicht';
  pageTitle = 'Omwonende';
  backToOverview = true;
  abstract tabName: string;

  public resident: any;
  public residentId: any;

  constructor(public pubsub: PubsubService, public globals: Globals, public route: ActivatedRoute, public _residentsService: ResidentsService) {
    super(pubsub, globals);
  }

  subscribeRoute(parent = false) {
    const r: ActivatedRoute = parent ? this.route.parent : this.route;
    r.params.subscribe(params => {
      this.residentId = params['residentId'];
      this.loadResident();
      this.run();
    });
  }

  setResident(resident:Resident) {
    this.residentId = resident.id;
      this.loadResident();
      this.run();
  }

  private loadResident() {
    this._residentsService.getResident(parseInt(this.residentId)).subscribe(
      (resident: any) => {
        this.resident = resident;
        this.pageTitle = resident.name;
        super.ngOnInit();
      });
  }

  /**
   * Method that is called when the resident id is taken from the route
   */
  // eslint-disable-next-line no-empty-function
  public run() {}
}
