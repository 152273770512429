<div mat-dialog-title>
    <h4 class="row mb-10 inline-block">Document(en)</h4>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">
        <mat-icon class="d-text-middle">close</mat-icon>
      </span>
      <span class="sr-only">Close</span>
    </button>
</div>
<div class="modal-body no-padding">
    <!-- Dropzone  -->
    <ng-container>
        <div class="row">
            <div class="form-group col-xs-12">
                <label>Upload nieuw document</label>
                <div class="dropzone-div">
                    <dropzone *ngIf="dropzoneConfig" #dropzone [config]="dropzoneConfig" [message]="dropzoneTemplate">
                    </dropzone>
                    <div *ngIf="integrationType=='bouwapp'; else otherInput">
                      <input type="file" accept="image/png, image/gif, image/jpeg" #file class="file_upload" (change)="DocumentUpLoad($event)">
                    </div>
                    <ng-template #otherInput>
                      <div *ngIf="integrationType=='whatsapp'; else freshdeskInput">
                        <input type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.gif" #file class="file_upload" (change)="DocumentUpLoad($event)">
                      </div>
                    </ng-template>
                    <ng-template #freshdeskInput>
                      <input type="file" #file class="file_upload" (change)="DocumentUpLoad($event)" multiple>
                    </ng-template>

                </div>
            </div>
            <div class="form-group col-xs-12">
                <mat-progress-bar *ngIf="isProcess" mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="integrationType == 'bouwapp'; else otherDocument">
              <div class="form-group col-xs-12" *ngIf="attachments.length && !isProcess">
                <label>Gekoppelde documenten</label>
                <ul class="list-group">
                  <li class="list-group-item docupload-core-listitem">
                    <span *ngIf="attachments.length" class="col-xs-10 col-md-10">
                      <a class="text-left non-link" *ngIf="attachments.length > 25">
                        {{ attachments | truncatie : 25 }}
                      </a>
                      <a class="text-left non-link" *ngIf="attachments.length <= 25">
                        {{ attachments }}
                      </a>
                    </span>
                    <span class="col-xs-2 col-md-2">
                      <mat-icon class="pull-right d-cursor-hand text-right" (click)="removeDocument()">delete</mat-icon>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <ng-template #otherDocument>
              <div *ngIf="integrationType == 'whatsapp'; else freshdeskDocument">
                <div class="form-group col-xs-12" *ngIf="attachments.message && !isProcess">
                  <label>Gekoppelde documenten</label>
                  <ul class="list-group">
                    <li class="list-group-item docupload-core-listitem">
                      <span *ngIf="attachments" class="col-xs-10 col-md-10">
                        <a class="text-left non-link">
                          {{ attachments.message.id | truncatie : 25 }}
                        </a>
                      </span>
                      <span class="col-xs-2 col-md-2">
                        <!-- <mat-icon class="pull-right d-cursor-hand text-right" (click)="removeDocument()">delete</mat-icon> -->
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>

            <ng-template #freshdeskDocument>
              <div class="form-group col-xs-12" *ngIf="attachments.length && !isProcess">
                <label>Gekoppelde documenten</label>
                <ul class="list-group">
                  <li class="list-group-item docupload-core-listitem" *ngFor="let item of attachments">
                    <span *ngIf="item.name" class="col-xs-10 col-md-10">
                      <a class="text-left non-link" *ngIf="item.name.length > 25">
                        {{ item.name | truncatie : 25 }}
                      </a>
                      <a class="text-left non-link" *ngIf="item.name.length <= 25">
                        {{ item.name }}
                      </a>
                    </span>
                    <span class="col-xs-2 col-md-2">
                      <mat-icon class="pull-right d-cursor-hand text-right" (click)="removeDocumentPerIndex(item.name)">delete</mat-icon>
                    </span>
                  </li>
                </ul>
              </div>
            </ng-template>

          </div>
    </ng-container>
</div>
<div class="modal-footer">
    <div class="row pull-right">
        <button (click)="submit()" mat-flat-button>{{'close' | transloco | capitaliz}}</button>
    </div>
</div>
