import { HttpXsrfTokenExtractor } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { translate } from "@jsverse/transloco";
import { DropzoneComponent } from 'ngx-dropzone-wrapper';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { ActionsService } from 'src/app/akita/tasks/state/actions.service';
import { DeletionModalComponent } from 'src/app/shared/components/modal/deletion-modal/deletion-modal.component';
import { AuthConstants } from '../../../auth-constants';
import { DocuploadType } from "../../../core/enum/docupload-type";
import { DocumentsService } from "../../../core/service/api/documents.service";
import { GenericComponent } from "../../../core/util/abstract/generic-component";
import { bugsnagFactory } from "../../../core/util/bugsnagFactory";
import { showFeedback, showFeedbackSaved } from '../../../core/util/notification';
import { Globals } from "../../../globals";
import { DocumentSidebarData } from "../../../shared/directive/document-sidebar.directive";
import { setDefaultLanguage } from "src/app/akita/multilingual/multilingual.model";


@Component({
  selector: 'app-docupload2',
  templateUrl: './docupload2.component.html',
  styleUrls: ['./docupload2.component.sass']
})
export class Docupload2Component extends GenericComponent implements OnInit {

  protected dropzoneConfig: any;
  public isReadOnly = false;
  public searchKeyword: string;
  public search = {
    keyword: null,
    data: [],
    total: 0,
    size: 5,
  };
  private searchTimeout: any;
  upload: any;
  isError = false;
  public fileSize:string;
  message: string;
  setDefaultHeader: any;
  protected docUrlValid = true;
  public notuploadList=[];
  public documentnoexistAdd:any;
  public documentName: string;
  public documentLink: string;
  protected dropzoneTemplate = `<div class="mt-10">${translate('reusable.drop_email')}<br><i class="material-icons">cloud_upload</i></div>`;

  protected reference = '';

  @Output() public documentUploaded: EventEmitter<any> = new EventEmitter();
  @Output() public documentRemoved: EventEmitter<number> = new EventEmitter();

  @ViewChild('form_url') form_url;
  @ViewChild('form_reference') form_reference;
  @ViewChild('form_docUrl') form_docUrl;
  @ViewChild('dropzone') dropzone: DropzoneComponent;

  constructor(private _actionsService:ActionsService,
              private _documentsService: DocumentsService,
              private dialog: MatDialog, private authConstant: AuthConstants,
              @Inject(MAT_DIALOG_DATA) public docSidebarData: DocumentSidebarData, private dialogRef: MatDialogRef<Docupload2Component>,
              private _xsrfTokenExtractor: HttpXsrfTokenExtractor,
              private cd: ChangeDetectorRef,
              public globals: Globals,private router: Router, private subProjectQuery: SubprojectQuery) {
    super();
  }

  ngOnInit() {
    if(this._actionsService.subVarPanelHide==undefined){
      this._actionsService.subVarPanelHide=this._actionsService.invokeFunctionPanelHide.subscribe((response)=>{
        this._actionsService.subVarPanelHide=undefined;
      });
    }
    if(this.router.url.includes('sub')){
      const activeSubProject = this.subProjectQuery.getActive();
      this.setDefaultHeader={
        'X-XSRF-TOKEN': this._xsrfTokenExtractor.getToken(),
        'X-Project': this.globals.projectCode,
        'X-Subproject': activeSubProject.id.toString(),
        'X-Lang': setDefaultLanguage(this.globals, 'user'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }else{
      this.setDefaultHeader={
        'X-XSRF-TOKEN': this._xsrfTokenExtractor.getToken(),
        'X-Project': this.globals.projectCode,
        'X-Lang': setDefaultLanguage(this.globals, 'user'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    this.validateComponent();
    const self = this;
    this.dropzoneConfig = {
      url: '/api/documents/upload',
      paramName: 'files',
      headers: this.setDefaultHeader,
      init: function () {
        this.on('uploadprogress', (file) => {
          self.upload = file;
        });
      },
      uploadMultiple: true,
      params: () => {
        if (this.docSidebarData.id) {
          return {
            type: this.docSidebarData.type,
            id: this.docSidebarData.id,
          }
        }

        return {};
      },
      previewsContainer: '.dropzone-previews' // Don't display preview by displaying it in a hidden element
    };
  }

  public reload(type: DocuploadType, id: number, documents?: any[]) {
    this.docSidebarData.type = type;
    this.docSidebarData.id = id;

    if (documents) {
      this.docSidebarData.documents = documents;
    }
  }

  private validateComponent() {
    if (this.docSidebarData.skipValidation) {
      return;
    }

    if (!this.docSidebarData.type) {
      throw new TypeError('Attribute "type" is mandatory');
    }
  }

  public reset(documents: any[]) {
    if (this.form_docUrl) {
      this.form_docUrl.reset();
    }

    this.docUrlValid = true;

    if (!documents) {
      console.log('Documents  is not defined. Usually happens when initializing the component');
      return;
    }

    this.docSidebarData.documents = documents;
  }

  public searchDocument() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (!this.searchKeyword || this.searchKeyword.length <= 2) {
        return;
      }

      if (typeof this.searchKeyword === 'string') {
        const filterValue = this.searchKeyword.toLowerCase();
        this.loadSearch(filterValue);
      }
    }, 350);
  }

  private loadSearch(keyword: string, page:number = 1, limit:number = 5) {
    this._documentsService.searchDocument(this.globals.projectConfigs['services.sharepoint.enabled'], keyword, page, limit).subscribe(
      (response: any) => {
        if (response && response.total && response.per_page && response.data) {
          if (!Array.isArray(response.data)) {
            response.data = Object.values(response.data);
          }
          this.search = {
            keyword: keyword,
            data: response.data.map((data: any) => {
              data.filename_simple = data.filename.split('/').pop();
              return data;
            }),
            total: response.total,
            size: response.per_page
          };
        } else {
          this.search = {
            keyword: keyword,
            data: [],
            total: 0,
            size: 0
          }
        }
      });
  }

  public updateSearchPage(event) {
    this.loadSearch(this.search.keyword, event.pageIndex + 1, this.search.size);
  }

  openAlert(message: string) {
    this.isError = true;
    this.message = message;
  }

  removeAlert() {
    this.isError = false;
  }

  protected onUploadFileSuccess(data: [File, {}, ProgressEvent]|any) {
    let file: File = null;
    let files: File[] = [];
    let existsArray;
    let notExistsArray;
    let helperList=[];
    const responseArray = data[1];
    const filesChecker = data[0];
    let setExisting=[]
    // eslint-disable-next-line prefer-const
    existsArray = responseArray.filter(element => {
      if(element.exists){
        return element;
      }
    });
    // eslint-disable-next-line prefer-const
    notExistsArray = responseArray.filter(element => {
      if(!element.exists){
        return element;
      }
    });
    this.notuploadList = existsArray;
    data[1]=notExistsArray;
    this.cd.detectChanges();
    helperList=this.notuploadList.map(el=>{
      if(el){
        return el.name
      }
    });
    this.cd.detectChanges();
    if(!helperList.includes(filesChecker.name)){
    data.forEach( d => {
      if (d instanceof File) {
        file = d;
      }

      if (d instanceof Array) {
        files = d;
      }
    });
    if (!this.docSidebarData.disableSuccessMessage) {
      this.upload = undefined;
      showFeedbackSaved();
    }

    if (file && files.length > 0) {
      // When it's multiple files, this method would be called multiple times
      try {
        this.docSidebarData.documents.push(files.find(d => d.name === file.name));
      } catch (e) {
        // If the property is readonly, re-assign the object. This is the impact of having double implementation of template driven vs reactive form
        this.docSidebarData.documents = [...this.docSidebarData.documents, files.find(d => d.name === file.name)];
      }

    } else {
      bugsnagFactory().notify(JSON.stringify(data));
    }
  }
  if(this.docSidebarData.documents.length>0){
    this.documentnoexistAdd=this.docSidebarData.documents;
    setExisting=this.documentnoexistAdd.map(el=>{
      if(el){
        return el.name
      }
    });
    this.notuploadList.forEach(el=>{
      if(!setExisting.includes(el.name)){
        this.documentnoexistAdd.push(el)
      }
    });
    this.documentUploaded.emit(this.documentnoexistAdd);
  }else{
    this.documentnoexistAdd=this.docSidebarData.documents;
    this.notuploadList.forEach(el=>{
      this.documentnoexistAdd.push(el)
    });
    this.documentUploaded.emit(this.documentnoexistAdd);
  }
  }

  protected onUploadDropzoneError(event) {
    let message = 'Error tijdens uploaden van document';
    if (event && event[1] && event[1].message) {
      message = event[1].message;
    }
    //set 25 MB logic
    this.fileSize = (event[0].size / (1024 * 1024)).toString();
    this.fileSize=parseInt(this.fileSize).toFixed(0);
    if(Number(this.fileSize) >= 25){
      message=translate('documents.uploading_limit');
    }
    this.openAlert(message);
    showFeedback(message, 'error', null, false);
    this.upload = undefined;
  }

  get notExistsDocuments() {
    return this.docSidebarData.documents.filter(doc => !doc.exists);
  }
  get alreadyExistsDocuments() {
    return this.docSidebarData.documents.filter(doc => doc.exists);
  }
  get dateNow() {
    return new Date();
  }

  protected removeDoc(doc, del=false) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u het document wilt verwijderen?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe( (remove: boolean) => {
      if (remove) {
        if (!this.docSidebarData.id) {
          const docs = this.docSidebarData.documents.filter(document => document.id == doc.id);
          if(docs.length){
            const i = this.docSidebarData.documents.indexOf(docs[0])
            this.docSidebarData.documents.splice(i,1)
          }
          this.documentRemoved.emit(doc.id);
          if(del) {
            this._documentsService.deleteDocument(doc).subscribe(() => {
              showFeedbackSaved();
            })
          }
          return;
        }

        this._documentsService.removeDocument(this.docSidebarData.type, this.docSidebarData.id, doc.id)
          .subscribe(
            () => {
              const docs = this.docSidebarData.documents.filter(document => document.id == doc.id);
              if(docs.length){
                const i = this.docSidebarData.documents.indexOf(docs[0])
                this.docSidebarData.documents.splice(i,1)
              }
              this.documentRemoved.emit(doc.id);
            }
          );
      }
    });
  }

  public addDocument() {
    const doc = {'doc_name': this.documentName, 'doc_link': this.documentLink};
    if (this.docSidebarData.id) {
      doc["type"] = this.docSidebarData.type;
      doc["id"] = this.docSidebarData.id;
    }
    this.documentName = '';
    this.documentLink = '';
    this._documentsService.saveDocumentLink(doc).subscribe((document: any) => {
      this.docSidebarData.documents.push(document[0]);
      this.documentUploaded.emit(this.docSidebarData.documents);
      showFeedbackSaved();
    },
    (error) => {
      console.log({error})
    })
  }

  public connectDocument(data, input: HTMLElement): void {

    // Set out of focus so we can easily re-add document, and clear the input value
    input.blur();
    this.searchKeyword = null;

    if (!this.docSidebarData.id) {
      this._documentsService.getDocument(data.id, this.globals.projectConfigs['services.sharepoint.enabled'], {
        filename: data.filename,
        filenameSimple: data.filename_simple,
      }).subscribe(
        (document: any) => {
          this.docSidebarData.documents.push(document);
          this.documentUploaded.emit(this.docSidebarData.documents);
        }
      );

      return;
    }

    this._documentsService.connectDocument(data, this.docSidebarData.type, this.docSidebarData.id).subscribe(
      (response: any) => {
        this.search.data = this.search.data.filter(entry => entry.id != data.id);
        this.docSidebarData.documents.push(response);
        this.documentUploaded.emit(this.docSidebarData.documents);
        showFeedbackSaved();
      });
  }

  public close(): void {
    this.docSidebarData.documents = [];
    this.dialogRef.close();
  }

}
