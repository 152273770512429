import {Component, Inject, OnInit} from '@angular/core';
import { GenericComponent } from '../../../../core/util/abstract/generic-component';
import { Globals } from '../../../../globals';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MapService } from 'src/app/core/service/api/map.service';

export interface NewEventData {
  date?: Date;
}

@Component({
  selector: 'app-calendar-create-event',
  templateUrl: './calendar-create-event.component.html',
  styleUrls: ['./calendar-create-event.component.sass']
})

export class CalendarCreateEventComponent extends GenericComponent implements OnInit {

  public selectedDate: Date;
  public typeCal: string;

  constructor(public globals: Globals, @Inject(MAT_DIALOG_DATA) public data: NewEventData, public _mapService: MapService, public dialogRef: MatDialogRef<CalendarCreateEventComponent>) {
    super();
  }

  ngOnInit(): void {
    this._mapService.setData('null');
    if (this.data.date) {
      this.selectedDate = this.data.date;
    }
  }

  public switchEvent(type: string): void {
    this.typeCal = type;
    this.dialogRef.updateSize('60vw', 'auto');
  }

  public resetForm(): void {
    this.dialogRef.updateSize(null, null);
    this.typeCal = null;
  }

  public createEvent(event): void {
    this.dialogRef.close({
      event: event,
      type: this.typeCal
    });

    this.resetForm();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
