<h3 class="mb-15">{{ 'commentbox.heading' | transloco | capitaliz }}</h3>
<section class="col-xs-12 panel panel-border z-depth-2">
    <mat-progress-bar *ngIf="message_loading" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="!message_loading && messagesList.length>0" class="fixed-height" #infinitescroll infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scroll)="onScrollDown($event)" [scrollWindow]="false">
        <table class="table mb-0 mytable">
            <tbody>
                <tr *ngFor="let message of messagesList; let i = index">
                  <ng-container [ngSwitch]="message.type">
                    <td *ngSwitchCase="'comment'" class="ptb-20" [ngClass]="i==0?'comment-box-table':''">
                      <div class="d-flex j-content-spaceb" *ngIf="!message.is_edit">
                          <div class="d-flex">
                              <ngx-avatars [name]="message.users.name" size="30" textSizeRatio="2.5" borderColor="#EEEEEE" bgColor="#EEEEEE" fgColor="#0f0f0f">
                              </ngx-avatars>
                              <div class="ml-20">
                                  <div><span class="user-style">{{message.users.name}}</span><span class="ml-20 date-edit">{{ message.created_at | formatdate: 'dd-MM-yyyy' : globals.timezone }}</span><span class="ml-20 date-edit" *ngIf="message.created_at!=message.updated_at">({{'commentbox.edited' | transloco | capitaliz}})</span></div>
                                  <div [innerHTML]="messageFormat(message.message)" class="textarea-width-set"></div>
                              </div>
                          </div>

                          <div class="d-flex" *ngIf="(hasPermission(Permission.StakeholdersUpdate) && type=='permits') || (hasPermission(Permission.StakeholdersUpdate) && type=='stakeholders') || (hasPermission(Permission.IssuesUpdate) && type=='issues') || (hasPermission(Permission.DecisionsUpdate) && (type=='requirements_by' || type == 'requirements')) || (hasPermission(Permission.ActionUpdate) && type == 'tasks') || (hasPermission(Permission.LogsUpdate) && type == 'contactmoments') || (hasPermission(Permission.ComplaintsUpdate) && type == 'complaints') || (hasPermission(Permission.MiddelUpdate) && type == 'calendars') || (hasPermission(Permission.ActivitiesUpdate) && type == 'calendars') || (hasPermission(Permission.EmployeesUpdate) && type=='contactpersons')">
                              <a (click)="updateLike(message)" class="mr-2">
                                  <span *ngIf="message.liked_users_count" class="text-black">{{message.liked_users_count}}</span>
                                  <span class="material-icons edit-icons-like d-cursor-hand likeicon" [ngClass]="message.liked ? 'activelike' : 'nonactivelike'">
                                  thumb_up_off_alt
                                  </span>
                              </a>
                              <div *ngIf="message.users && globals.user && message.users.id==globals.user.id" class="dropdown">
                                  <a class="dropdown-toggle d-cursor-hand" data-toggle="dropdown" *ngIf="!updateActive">
                                    <mat-icon class="add-colab">more_vert</mat-icon>
                                  </a>
                                  <ul class="dropdown-menu set-edit-dropdown">
                                    <li><a (click)="updateComment(message)">{{'commentbox.edit' | transloco | capitaliz}}</a></li>
                                    <li><a (click)="deleteComment(message)" class="text-red">{{'commentbox.delete' | transloco | capitaliz}}</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div class="d-flex j-content-spaceb" *ngIf="message.is_edit">
                          <ngx-avatars [name]="message.users.name" size="30" textSizeRatio="2.5" borderColor="#EEEEEE" bgColor="#EEEEEE" fgColor="#0f0f0f">
                          </ngx-avatars>
                          <div class="ml-20 w-100">
                              <div #textareaRefEdit class="divtextarea-edit custome-textarea" contenteditable="true" [mentionConfig]="mentionConfig" (searchTerm)="loadItems($event)" (itemSelected)="itemSelected($event)" (input)="onChangeEditComment($event.target.innerHTML)"></div>
                              <div class="pull-right">
                                  <a class="mt-10 mr-5" mat-stroked-button (click)="cancleEdit(message)">
                                      {{'cancel' | transloco | capitaliz}}
                                  </a>
                                  <a class="mt-10" mat-flat-button [ngClass]="{'disable-anchor-tag-button': processing }" (click)="onSend()">
                                      <mat-icon>send</mat-icon>
                                  </a>
                              </div>
                          </div>
                      </div>
                    </td>
                    <td *ngSwitchCase="'item_status_changed'" class="ptb-28" [ngClass]="i==0?'comment-box-table':''">
                      <div class="d-flex j-content-spaceb">
                          <div class="d-flex">
                              <div class="ml-20 align-self-center">
                                <span>{{message.users.name}} {{'commentbox.changed_status' | transloco}} {{ 'status.'+message.message.status | transloco | capitaliz}} <span class="ml-20 date-edit">{{ message.created_at | formatdate: 'dd-MM-yyyy' : globals.timezone }}</span></span>
                              </div>
                          </div>
                        </div>
                    </td>
                    <td *ngSwitchCase="'item_text_changed'" class="ptb-28" [ngClass]="i==0?'comment-box-table':''">
                      <div class="d-flex j-content-spaceb">
                          <div class="d-flex">
                              <div class="ml-20 align-self-center">
                                <span>{{message.users.name}} {{'commentbox.changed_text' | transloco}}</span>
                                <p>{{ message.message.text}} <span class="ml-20 date-edit">{{ message.created_at | formatdate: 'dd-MM-yyyy' : globals.timezone }}</span></p>
                              </div>
                          </div>
                        </div>
                    </td>
                    <td *ngSwitchCase="'item_created'" class="ptb-28" [ngClass]="i==0?'comment-box-table':''">
                      <div class="d-flex j-content-spaceb">
                          <div class="d-flex">
                              <div class="ml-20 align-self-center">
                                <span>{{message.users.name}} {{( (parentType === 'contents' || message.commentable_type === 'issues' || message.commentable_type === 'contactmoments') ? 'commentbox.created_this_word' : 'commentbox.created_this') | transloco}} {{ parentType === 'contents'? ('contents.label' | transloco) :  parentType === 'activity'? ('activities.label' | transloco) : (message.commentable_type+'.label' | transloco)}} <span *ngIf="lang === 'nl'">{{ 'commentbox.created' | transloco}}</span><span class="ml-20 date-edit">{{ message.created_at | formatdate: 'dd-MM-yyyy' : globals.timezone }}</span></span>
                              </div>
                          </div>
                        </div>
                    </td>
                    <td *ngSwitchCase="'collaborator'" class="ptb-28" [ngClass]="i==0?'comment-box-table':''">
                      <div class="d-flex j-content-spaceb">
                          <div class="d-flex">
                              <div class="ml-20 align-self-center">
                                <span><span *ngIf="message.message.by">{{message.message.by.name}}</span> {{'commentbox.added' | transloco}} {{message.users.name}} {{'commentbox.as_collaborator' | transloco}} <span class="ml-20 date-edit">{{ message.created_at | formatdate: 'dd-MM-yyyy' : globals.timezone }}</span></span>
                              </div>
                          </div>
                        </div>
                    </td>
                  </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!message_loading && messagesList.length==0" class="text-center">
        <h4><strong>{{'commentbox.emptystatehead' | transloco}}</strong></h4>
        <p>{{'commentbox.emptystateinfo' | transloco}}</p>
    </div>
    <hr class="m-null">
    <div *ngIf="(hasPermission(Permission.StakeholdersUpdate) && type=='permits') || (hasPermission(Permission.StakeholdersUpdate) && type=='stakeholders') || (hasPermission(Permission.IssuesUpdate) && type=='issues') || (hasPermission(Permission.DecisionsUpdate) && (type=='requirements_by' || type == 'requirements')) || (hasPermission(Permission.ActionUpdate) && type == 'tasks') || (hasPermission(Permission.LogsUpdate) && type == 'contactmoments') || (hasPermission(Permission.ComplaintsUpdate) && type == 'complaints') || (hasPermission(Permission.MiddelUpdate) && type == 'calendars') || (hasPermission(Permission.ActivitiesUpdate) && type == 'calendars') || (hasPermission(Permission.EmployeesUpdate) && type=='contactpersons')">
        <div class="relative-block-container" *transloco="let t">
            <div class="col-md-10">
                <div #textareaRef id="message-text-area" class="divtextarea custome-textarea" contenteditable="true"contenteditable-disabled="orderedList formatBold formatFontName" [mentionConfig]="mentionConfig" (searchTerm)="loadItems($event)" (itemSelected)="itemSelected($event)" (input)="onChangeComment($event.target.innerHTML)" [data-placeholder]="t('commentbox.postupdate')" [ngClass]="{'disable_enitiy': updateActive}"></div>
            </div>
            <div class="col-md-2">
                <span *ngIf="processing" class="mt-10 mb-10 set-loaded-button pull-right loader-span">
                    <img class="pull-left group_svg spinner" src="/assets/png/spinner.png"/>
                </span>
                <a *ngIf="!processing" class="pull-right message-send-button" [ngClass]="(message && !updateActive) ? '' : 'disable-anchor-tag-button'" mat-stroked-button (click)="onSend()">
                    <mat-icon style="margin-left: 0px !important; margin-right: 0px !important" [ngStyle]="{ color: (message && !updateActive) ? '#2800D2' : '#ccc' }">send</mat-icon>
                </a>
            </div>
        </div>
    </div>
    <div class="row mb-10">
        <div class="col-md-12">
            <div>
                <span class="date-edit">{{'commentbox.taginfo' | transloco}}</span>
            </div>
            <div class="d-flex" *transloco="let t">
                <span class="align-self-center">{{'commentbox.collaborators' | transloco | capitaliz}}</span>
                <div *ngFor="let colab of collaboratorList" class=" ml-5">
                    <ngx-avatars [name]="colab.name" size="25" textSizeRatio="2.5" borderColor="#EEEEEE" bgColor="#EEEEEE" fgColor="#0f0f0f">
                    </ngx-avatars>
                </div>
                <div [matTooltip]="collaboratorUsers ? collaboratorUsers.length ? null : t('reusable.no_date') :  t('reusable.no_date')" class="dropdown" *ngIf="(hasPermission(Permission.StakeholdersUpdate) && type=='permits') || (hasPermission(Permission.StakeholdersUpdate) && type=='stakeholders') || (hasPermission(Permission.IssuesUpdate) && type=='issues') || (hasPermission(Permission.DecisionsUpdate) && (type=='requirements_by' || type == 'requirements')) || (hasPermission(Permission.ActionUpdate) && type == 'tasks') || (hasPermission(Permission.LogsUpdate) && type == 'contactmoments') || (hasPermission(Permission.ComplaintsUpdate) && type == 'complaints') || (hasPermission(Permission.MiddelUpdate) && type == 'calendars') || (hasPermission(Permission.ActivitiesUpdate) && type == 'calendars') || (hasPermission(Permission.EmployeesUpdate) && type=='contactpersons')">
                    <a class="dropdown-toggle d-cursor-hand" data-toggle="dropdown" [ngClass]="collaboratorUsers ? collaboratorUsers.length ? '' : 'disabled-link' : 'disabled-link'">
                        <oi class="add-colab uil uil-plus-circle unicon-icon"></oi>
                    </a>
                    <ul class="dropdown-menu">
                        <li *ngFor="let user of collaboratorUsers"><a (click)="collaboratorDropdown(user)">{{user.name}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
