import { Observable } from "rxjs";
import { InAppChatService } from "src/app/core/service/api/in-app-chat.service";
import { InAppChat } from "src/app/core/util/inappmessages";
import { freshdeskIntegrationReplayDataMap } from "src/app/core/util/integration";
import { Globals } from "src/app/globals";

export class FreshdeskIntegrationChatModal {
    public messages$:Observable<InAppChat>;
    public responce$:Observable<any>;

    constructor(
        public _inAppChatService:InAppChatService,
        public globals: Globals,
        // eslint-disable-next-line no-empty-function
        ){ }
    //load in app messages
    public loadMessages(ticketID,type,personal=false){
        if(!personal){
          this.messages$=this._inAppChatService.getticketConversations(ticketID,this.globals)
        }else{
          this.messages$=this._inAppChatService.getUserticketConversations(ticketID,this.globals.user.id,this.globals)
        }
        return this.messages$;
    }
    //send messages
    public sendMessage(message,type,personal=false){
        const ticket_id=message.ticket;
        message=freshdeskIntegrationReplayDataMap(message);
        if(!personal){
            this.responce$=this._inAppChatService.sendticketConversations(ticket_id,message,'notes',this.globals);
        }else{
            this.responce$=this._inAppChatService.sendUserticketConversations(ticket_id,message,'notes',this.globals.user.id,this.globals)
        }
        return this.responce$;
    }
}
