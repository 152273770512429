import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageConfirmComponent } from '../message-confirm/message-confirm.component';

@Component({
  selector: 'app-email-mismatch',
  templateUrl: './email-mismatch.component.html',
  styleUrls: ['./email-mismatch.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailMismatchComponent {
  
  constructor(public dialogRef: MatDialogRef<MessageConfirmComponent>,
              // eslint-disable-next-line no-empty-function
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  
  
  public close() {
    this.dialogRef.close(true)
  }


}
