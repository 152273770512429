import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { AbstractService } from '../common/abstract.service';
import { PaginatedResponse } from '../common/paginated-response';
import { PaginationParams } from '../common/pagination-params';

@Injectable({
  providedIn: 'root'
})
export class PermitsService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getPermit(permitId,skipSubproject:boolean=false) {
    let params = new HttpParams();
    if(skipSubproject)
      params = params.append('skip_subproject','0')
    return this.http.get(`/api/permits/${permitId}`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  getFilteredPermits(pagination: PaginationParams, status?: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/permits', pagination.get(status ? new HttpParams().set('status', status) : null));
  }

  @ServiceErrorHandler()
  public submitPermit(permit) {
    return this.http.post('/api/permits', permit, this.httpOptions);
  }

  @ServiceErrorHandler()
  updatePermit(permitId, permit) {
    return this.http.put(`/api/permits/${permitId}`, permit, this.httpOptions);
  }

  @ServiceErrorHandler()
  deletePermits(permit) {
    return this.http.delete(`/api/permits/${permit.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getPermitsRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/permits?dropdown=1`, this.httpOptions);
  }

  @ServiceErrorHandler()
  exportDecisions(options) {
    return this.http.get(`/file/export/complaint?`, {
      params: options,
      responseType: `blob`
    });
  }
}
