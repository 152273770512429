<div *ngIf="!isLoader; else loader">
  <div class="d-flex w-100" *ngIf="featureType === 'arcgis'">
    <!-- Main-Layers -->
    <app-generic-multiselect-checkbox class="w-100" [placeholder]="('maps.select_feature_layer') | transloco" name="mainLayers" [(ngModel)]="arcGisSelect.mainLayers" (ngModelChange)="getArcGisSubLayers()" [disabled]="!arcGis.mainLayers.length">
      <app-generic-multiselect-checkbox-option *ngFor="let item of arcGis.mainLayers" [value]="item">
          {{ item.name }}
      </app-generic-multiselect-checkbox-option>
    </app-generic-multiselect-checkbox>
    <!-- Sub-Layers -->
    <div class="pl-10 w-100" *ngIf="arcGis.subLayers.length && arcGisSelect.mainLayers.length">
      <app-generic-multiselect-checkbox [placeholder]="('maps.select_feature_sublayer') | transloco" name="subLayers" [(ngModel)]="arcGisSelect.subLayers" (ngModelChange)="getSubLayersFeatures()"  [disabled]="!arcGis.subLayers.length">
        <app-generic-multiselect-checkbox-option *ngFor="let item of arcGis.subLayers" [value]="item">
            {{ item.layer_name }}
        </app-generic-multiselect-checkbox-option>
      </app-generic-multiselect-checkbox>
      <mat-hint align="start" class="red position-hint" *ngIf="arcGisSelect.subLayers.length && !arcGis.features.length && isFeatureLoader">{{ 'no_features' | transloco }}</mat-hint>
    </div>
    <!-- Features-Layers -->
    <app-generic-multiselect-checkbox class="pl-10 w-100" *ngIf="arcGis.features.length  && arcGisSelect.subLayers.length && arcGisSelect.mainLayers.length" [placeholder]="('maps.select_feature') | transloco" name="features" [(ngModel)]="arcGisSelect.features"  (ngModelChange)="onSelect()"  [disabled]="!arcGis.features.length">
      <app-generic-multiselect-checkbox-option *ngFor="let item of arcGis.features" [value]="item">
          {{ item.layer_name }}
      </app-generic-multiselect-checkbox-option>
    </app-generic-multiselect-checkbox>
  </div>
  <!-- Open Data Layers -->
  <div *ngIf="featureType === 'opendata'">
    <div *ngIf="features.length">
      <app-generic-multiselect-checkbox [placeholder]="('maps.connect_opendata_features') | transloco" name="layerFeatures" name="features" [(ngModel)]="selectedFeatures" (ngModelChange)="onSelect()" >
        <app-generic-multiselect-checkbox-option *ngFor="let feature of features" [value]="feature">
            {{ feature.title }}
        </app-generic-multiselect-checkbox-option>
      </app-generic-multiselect-checkbox>
    </div>
  </div>
</div>
<ng-template #loader>
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</ng-template>
