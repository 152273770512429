import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import moment from 'moment';
import posthog from 'posthog-js';
import { forkJoin } from 'rxjs';
import { finalize } from "rxjs/operators";
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { deleteConfirmationResponce } from 'src/app/core/util/deepCopy';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { globalSubprojectUserSaprator, selectedSubprojectUsersSaprator, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { ActivitiesService } from '../../../../core/service/api/activities.service';
import { CalendarService } from '../../../../core/service/api/calendar.service';
import { GeneralService } from '../../../../core/service/api/general.service';
import { MiddelsService } from '../../../../core/service/api/middels.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { ReportResponse, ReportService } from "../../../../core/service/api/report.service";
import { UsersService } from '../../../../core/service/api/users.service';
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { dateIso } from "../../../../core/util/dateIso";
import { showFeedback, showFeedbackError, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { MultiselectUsersComponent } from '../../form/multiselect/multiselect-users/multiselect-users.component';
import { BouwappPublishModalComponent } from '../bouwapp-publish-modal/bouwapp-publish-modal.component';
import { MiddelSubmitComponent } from '../content-submit/middel-submit.component';
import { DeletionModalComponent } from "../deletion-modal/deletion-modal.component";
import { MapService } from 'src/app/core/service/api/map.service';
import { NewWorkflowComponent } from '../new-workflow/new-workflow.component';

@Component({
  selector: 'app-calendar-middel-detail',
  templateUrl: './calendar-middel-detail.component.html',
  styleUrls: ['./calendar-middel-detail.component.sass']
})

export class CalendarMiddelDetailComponent extends GenericComponent implements AfterViewInit {
  @Input() public showChips: boolean = true;
  middelId: number;
  calendarId: number;
  isExporting = false;
  isProcsing = false;
  isExportingEmail = false;
  activityList: any[];
  selectedAddressCount = 0;
  selectedEmailCount = 0;
  bouwappData:any;
  subprojectsSet:any[]=[];
  chipDisabler:any[]=[];
  middelForm: FormGroup;
  users = [];
  type: string;
  parentType: string;
  name: string;
  middelPerson = [];
  calendarsFilter: any;
  entities: any;
  loaded: boolean = false;
  chipDisablerUser:any[]=[];
  workflows: any[] = []; // Array to store workflows dynamically
  userSelection:any[]=[];
  public reducer:any=true;
  public bouwappEnable:boolean=false;
  public bouwappEnableError:boolean=false;
  @ViewChild(MultiselectUsersComponent, { static: false }) usersComponent: MultiselectUsersComponent;

  @Output() removeMiddel = new EventEmitter<any>();
  @Output() updateMiddel = new EventEmitter<any>();
  @Output() promptClosed = new EventEmitter<boolean>();
  @Output() formDirty = new EventEmitter<any>();
  @Output() rendered = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    public pubsub: PubsubService,
    public globals: Globals,
    private _middelService: MiddelsService,
    private cachedService: CachedService,
    private _usersService: UsersService,
    private _generalService: GeneralService,
    private _reportService: ReportService,
    private _activitiesService: ActivitiesService,
    private _calendarService: CalendarService,
    private dialog: MatDialog,
    private subProjectQuery: SubprojectQuery,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef,
    private _pusherService: PusherService,
    public router: Router,
    private dialogRef: MatDialogRef<CalendarMiddelDetailComponent>,
    public _mapService: MapService
  ) {
    super(globals);
  }

  ngOnInit() {
    this._mapService.setData('null');
    this.subprojectSetup();
    if(this.globals.projectConfigs['services.integration.bouwapp.enabled']){
      this.bouwappEnable=true;
    }
    this._usersService.getUsers().subscribe(
      (users: any) => {
        this.users = users.data;
        this.usersSetup();
        if (this.usersComponent) {
          this.usersComponent.setUsers(this.users);
        }
      }
    );
    if(this.globals.projectConfigs['services.integration.bouwapp.enabled'] && !this.globals.projectConfigs['services.integration.bouwapp.connection_error']){
      if(this.globals.user.isAdmin){
        this.bouwappEnableError=true;
      }else{
        if(this.globals.user.configs){
          if(this.globals.user.configs.config){
            if(this.globals.user.configs.config.connection_error && this.globals.user.configs.config.connection_error.bouwapp==true){
              this.bouwappEnableError=false;
            }else{
              this.bouwappEnableError=true;
            }
          }
        }
      }
    }

    let calendarable_id;
    let middel_id = this.data.id;
    if (this.data.extendedProps) {
      calendarable_id = this.data.extendedProps.relation.id;
      this.name = this.data.extendedProps.metadata.type;
    }
    else if (this.data.calendarable_id) {
      calendarable_id = this.data.calendarable_id;
      if(this.data.metadata){
        this.name = this.data.metadata.type;
      }else{
        this.name=this.data.text
      }
    }
    else {
      calendarable_id = this.data.id;
      middel_id = this.data.calendars[0].id;
      this.name = this.data.type;
    }
    this.showMiddelDetail(calendarable_id, middel_id)
    posthog.capture('content-detail');
  }

  ngAfterViewInit(): void {
    this.rendered.emit();

  }

  public exportAddresses() {
    this.isExporting = true;
    const coordinates = this.middelForm.get('locations').value;
    this._generalService.getAddresses(this.middelId, coordinates, 'spreadsheet')
      .pipe(finalize(() => this.isExporting = false))
      .subscribe(
        (report: ReportResponse) => saveAs(report.body, report.filename)
      );
  }

  public exportEmail() {
    this.isExportingEmail = true;
    const location = this.middelForm.get('locations').value;
    this._reportService.download('multipolygon_to_email', 'spreadsheet', location)
      .pipe(finalize(() => this.isExportingEmail = false))
      .subscribe(
        (report: ReportResponse) => saveAs(report.body, report.filename)
      );
  }

  public updateSelectedCoordinates(notifyFormDirty: boolean = true) {
    this.isProcsing = true;
    const coordinates = this.middelForm.get('locations').value;
    this._generalService.getAddressCount(coordinates).subscribe(
      (count: number) => { this.isProcsing = false; this.selectedAddressCount = count }
    );

    this._generalService.getEmailCount(coordinates).subscribe(
      (response: {
        total: number
      }) => { this.selectedEmailCount = response.total }
    );

    if (notifyFormDirty) {
      this.formDirty.emit();
    }
    this.updateMiddelDetail()
  }

  public showMaxAreaError() {
    showFeedback('Geselecteerde oppervlak te groot', 'error', null, false);
  }

  // LOAD MIDDEL DATA
  showMiddelDetail(middelId: number, calendarId: number) {
    this.middelId = middelId;
    this.calendarId = calendarId;

    this.getMiddelDetail();
  }

  public getMiddelDetail() {

    this._calendarService.showCalendar(this.calendarId, false, true).subscribe(
      (res: any) => {
        this.calendarsFilter = res;
        this.calendarsFilter.tasks = res.contents.tasks;
        this.middelForm = this.formBuilder.group({
          activities: res.activities ? res.activities[0] : null,
          type: res.metadata.type,
          group: res.metadata.group,
          users: res.users ? this.formBuilder.array(res.users) : this.formBuilder.array([]),
          is_sent: res.is_sent,
          sent_date: res.sent_date,
          locations: res.locations,
          calendars: this.formBuilder.group({
            id: res.id,
            started_at: dateIso(res.started_at, true, this.globals.timezone),
          }),
          update_related: 'self',
          workflows: res.workflows
        });

        this.middelPerson = res.users;

        this.middelForm.valueChanges.subscribe(() => this.notifyFormChanged());

        if (res.locations && res.locations.polygons) {
          this.isProcsing = true;
          this._generalService.getAddressCount(res.locations).subscribe(
            (count: number) => { this.isProcsing = false; this.selectedAddressCount = count }
          );

          this._generalService.getEmailCount(res.locations).subscribe(
            (response: {
              total: number
            }) => this.selectedEmailCount = response.total
          );
        }

        this.globals.cdRef.detectChanges();
        this.loadActivityList();
        this.setLodedSubproject();
        this.setLodedSubprojectUsers();
        this.relaticsValidation();
        if(this.bouwappEnableError && this.bouwappEnable){
          this.bouwappUpdate();
        }
        this.permissionDisabled();
      }
    );
  }

  public compareActivity(value1: any, value2: any) {
    if (value1 && value2) {
      return value1.id === value2.id;
    }

    if ((value1 && !value2) || (!value1 && value2)) {
      return false;
    }

    return true;
  }

  // LOAD MIDDEL NEEDS
  // ACTIVITY FORM
  public loadActivityList() {
    this._activitiesService.getAllActivities().subscribe(
      (res: any) => {
        this.activityList = res.data;
        forkJoin([
          this.cachedService.getActionsRelations(),
          this.cachedService.getDocumentsRelations(),
        ]
        ).subscribe(([tasks,documents]) => {
          this.entities = { tasks, activities: this.activityList, documents:documents }
          this.loaded = true;
        });
      },
      e => showFeedbackError(e)
    );
  }

  // LOAD CALENDARS
  selectUser(selected: any[]) {
    this.middelPerson = selected;
    this.middelForm.setControl('users', this.formBuilder.array(selected));
    this.middelForm.get('users').markAsDirty();
    this.middelForm.markAsDirty();
    this.updateMiddelDetail()
  }


  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'contents', parent: this.calendarsFilter.contents},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
         // Push the response into the workflows array
        this.workflows.push(response);

        // Dynamically add the workflows array to the form
        // this.middelForm.patchValue({ workflows: this.workflows });

        this.getMiddelDetail();
      }
    });
  }

  // UPDATE MIDDEL
  updateMiddelDetail() {
    if (!this.middelForm) {
      return;
    }
    if (this.middelForm.controls['calendars'].value) {
      this.middelForm.value.calendars.started_at = new Date(this.middelForm.controls['calendars'].value.started_at);
    }
    let maxDate= moment(this.middelForm.value.calendars.started_at).endOf('day');
    maxDate=moment(maxDate).subtract(1, 'seconds')
    this.middelForm.value.calendars.started_at=maxDate;
    this.middelForm.value.subprojects = this.calendarsFilter.subprojects;
    setTimeout(() => {
      this._middelService.updateMiddel(this.middelId, this.middelForm.value).subscribe(
        (res: any) => {

          if (this._mapService.circleVectorSource && this._mapService.circleVectorSource.getFeatures().length > 0) {
            this._mapService.circleVectorSource.removeFeature(this._mapService.searchFeature);

          }
          this._pusherService.onCangeStatus();
          showFeedbackSaved();
        }
      );
    }, 50);

  }

  delete() {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(this.middelForm,this.router,'middel')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        if (this.calendarsFilter.metadata && this.calendarsFilter.metadata.is_recurring) {
          this.showMiddelPrompt('delete');
        } else {
          this._middelService.deleteMiddel(this.middelId, Number(this.calendarId), 'self').subscribe(
            () => {
              showFeedbackSaved();
              this.close();
            }
          );

        }
      }
    });
  }

  // SUBMIT VALIDATION
  showMiddelPrompt(type: string = 'update') {
    const dialogRef = this.dialog.open(MiddelSubmitComponent);
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((selection: string) => {
      if (selection) {
        this.middelForm.value.update_related = selection;

        if (type === 'update') {
          this.updateMiddelDetail();
        } else if (type === 'delete') {

          this._middelService.deleteMiddel(this.middelId, Number(this.calendarId), selection).subscribe(
            () => {
              showFeedbackSaved();
              this.close();
            }
          );
        }

        // Quick fix for quick deploy ...
        this.promptClosed.emit(true);
      } else {

        // Quick fix for quick deploy ...
        this.promptClosed.emit(false);
      }
    });
  }

  notifyFormChanged() {
    this.formDirty.emit();
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  updateSubprojects() {
    setTimeout(() => {
      this.pubsub.updateSubprojects(this, "updateMiddelDetail", this.calendarsFilter.subprojects)

    }, 500);
  }

  updateEntities(event: any) {
    this.middelForm.value[event.type] = event.selected;
    if (event.type == "activities")
      this.middelForm.value[event.type] = event.selected;
    this.updateMiddelDetail();
  }
  public relaticsValidation(){
    if(this.calendarsFilter.contents.relatics_uuid){
      if(this.globals.relaticsColumn.middel){
        let columns;
        const dialogcolumns=[];
        // eslint-disable-next-line prefer-const
        columns = this.globals.relaticsColumn.middel.columns;
        columns.map((element)=>{
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer=dialogcolumns.reduce((ac,a) => ({...ac,[a]:a}),{});
        this.cd.detectChanges();
      }
    }
  }
  public bouwappUpdate(){
    if(this.calendarsFilter.contents.ticket_id){
      this._middelService.getBouwappUpdate(this.calendarsFilter.contents.ticket_id).subscribe((res:any)=>{
        if(res.code==404){
          this.bouwappData=null;
        }else{
          this.bouwappData=res;
        }
      })
    }
  }
  public bouwAppPublishModal(){
    const calender=this.calendarsFilter;
    let attachment=calender.documents;
    attachment=attachment.filter(el=>{
      if(el.name.includes('.png') || el.name.includes('.jpg') || el.name.includes('.gif') || el.name.includes('.jpeg') || el.name.includes('.pdf')){
        return el;
      }
    });
    calender.documents=attachment;
    const dialog = this.dialog.open(BouwappPublishModalComponent, modalConfig({
      data: {
        calender:calender,
        bouwapp:this.bouwappData
      },
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));
    dialog.afterClosed().subscribe((res) => {
      switch(res){
        case 'submit':
          this.ngOnInit();
          break;
        case 'update':
          this.ngOnInit();
          break;
        case 'delete':
          this.isProcsing=true;
          this.bouwappUpdate();
          this.isProcsing=false;
          break;
        default:
          break;
      }
    });
  }
  public permissionDisabled(){
    if(!this.hasPermission(this.Permission.MiddelUpdate)){
      this.middelForm.get('activities').disable();
      this.middelForm.get('type').disable();
      this.middelForm.get('group').disable();
      this.middelForm.get('users').disable();
      this.middelForm.get('is_sent').disable();
      this.middelForm.get('sent_date').disable();
      this.middelForm.get('locations').disable();
      this.middelForm.get('calendars').disable();
      this.middelForm.get('update_related').disable();
    }
  }
  public subprojectSetup(){
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects,'middel:update')
  }
  public setLodedSubproject(){
    this.chipDisabler=[];
    this.chipDisabler=selectedSubprojectSaprator(this.subprojectsSet,this.calendarsFilter.subprojects)
  }
  public usersSetup(){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
      this.users=globalSubprojectUserSaprator(this.users,activeSubProject)
    }
  }
  public setLodedSubprojectUsers(){
    if(this.users){
      const activeSubProject = this.subProjectQuery.getActive();
      if(activeSubProject && userRoleFinder(this.globals)){
      this.chipDisablerUser=[];
      this.chipDisablerUser=selectedSubprojectUsersSaprator(this.users,this.calendarsFilter.users);
      }
    }
  }
}
