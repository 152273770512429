import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ComplaintsService } from '../../../../akita/complaints/state/complaints.service';
import { IssuesService } from '../../../../akita/issues/state/issues.service';
import { DecisionsService } from '../../../../akita/requirements/state/decisions.service';
import { ResidentsService } from '../../../../akita/residents/state/residents.service';
import { StakeholdersService } from '../../../../akita/stakeholders/state/stakeholders.service';
import { SubprojectQuery } from '../../../../akita/subproject/state/subprojects.query';
import { SubprojectsService } from '../../../../akita/subproject/state/subprojects.service';
import { CalendarService } from "../../../../core/service/api/calendar.service";
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { Globals } from "../../../../globals";

declare let ol: any;
declare let $: any;

@Component({
  selector: 'app-openlayers-rightpanel',
  templateUrl: './openlayers-rightpanel.component.html',
  styleUrls: ['./openlayers-rightpanel.component.sass']
})
export class OpenlayersRightPanelComponent extends GenericComponent implements OnInit {
  @ViewChild('closeLayer') closeLayer: ElementRef;
  @Input() showToggle: Boolean;
  @Input() hideBack: Boolean;
  public element: any;
  public _tabitems: any;
  private _options: any;
  private _container: any;
  private _panes = [];
  private _closeButtons = [];



  constructor(public globals: Globals, private _complaintsService: ComplaintsService, private _stakeholdersService: StakeholdersService, private _issuesService: IssuesService,
    private _residentsService: ResidentsService, private _decisionsService: DecisionsService, private _calendarsService: CalendarService,
    private subProjectsService: SubprojectsService, private subProjectQuery: SubprojectQuery) {
    super(globals);
  }

  ngOnInit() {
    this.initControlSidebar();
    this.initSidebarClickEvents();
    this.initSidebarOpen();
    this.initSidebarClose();
  }

  private initControlSidebar(): void {
    const self = this;
    ol.control.Rightpanel = function (settings) {

      const defaults = {
        element: null,
        position: 'right'
      };

      self._options = Object.assign({}, defaults, settings);

      self.element = document.getElementById(self._options.element);
      ol.control.Control.call(this, {
        element: self.element,
        target: self._options.target
      });

      // Attach .sidebar-left/right class
      self.element.classList.add(`sidebar-${ self._options.position}`);

      // Find sidebar > div.sidebar-content
      for (let i = self.element.children.length - 1; i >= 0; i--) {
        const child = self.element.children[i];
        if (child.tagName === 'DIV' &&
          child.classList.contains('sidebar-content')) {
          self._container = child;
        }
      }

      // Find sidebar ul.sidebar-tabs > li, sidebar .sidebar-tabs > ul > li
      self._tabitems = self.element.querySelectorAll('ul.sidebar-tabs > li, .sidebar-tabs > ul > li');
      for (let i = self._tabitems.length - 1; i >= 0; i--) {
        self._tabitems[i]._sidebar = this;
      }

      // Find sidebar > div.sidebar-content > div.sidebar-pane
      for (let i = self._container.children.length - 1; i >= 0; i--) {
        const child = self._container.children[i];
        if (child.tagName == 'DIV' &&
          child.classList.contains('sidebar-pane')) {
          self._panes.push(child);

          const closeButtons = child.querySelectorAll('.sidebar-close');
          for (let j = 0, len = closeButtons.length; j < len; j++) {
            self._closeButtons.push(closeButtons[j]);
          }
        }
      }

      for (let i = self._tabitems.length - 1; i >= 0; i--) {
        const child = self._tabitems[i];
        const sub = child.querySelector('a');
        if (sub.hasAttribute('href') && sub.getAttribute('href').slice(0, 1) == '#') {
          sub.onclick = this._onClick.bind(child);
        }
      }

      for (let i = self._closeButtons.length - 1; i >= 0; i--) {
        const child = self._closeButtons[i];
        child.onclick = this._onCloseClick.bind(this);
      }
    };

    ol.inherits(ol.control.Rightpanel, ol.control.Control);
  }

  private initSidebarClickEvents(): void {
    ol.control.Rightpanel.prototype._onClick = function (evt) {
      evt.preventDefault();
      if (this.classList.contains('active')) {
        this._sidebar.close();
      } else if (!this.classList.contains('disabled')) {
        this._sidebar.open(this.querySelector('a').hash.slice(1));
      }
    };

    ol.control.Rightpanel.prototype._onCloseClick = function () {
      this.close();
    };
  }

  private initSidebarOpen(): void {
    const self = this;
    ol.control.Rightpanel.prototype.open = function (id) {
      let i, child;

      // hide old active contents and show new content
      for (i = self._panes.length - 1; i >= 0; i--) {
        child = self._panes[i];
        if (child.id == id)
          child.classList.add('active');
        else if (child.classList.contains('active'))
          child.classList.remove('active');
      }

      // remove old active highlights and set new highlight
      for (i = self._tabitems.length - 1; i >= 0; i--) {
        child = self._tabitems[i];
        if (child.querySelector('a').hash == `#${ id}`)
          child.classList.add('active');
        else if (child.classList.contains('active'))
          child.classList.remove('active');
      }

      // open sidebar (if necessary)
      if (self.element.classList.contains('collapsed')) {
        self.element.classList.remove('collapsed');
      }
      $('.ol-full-screen,.ol-custom-control_zoom,.ol-zoom').toggleClass('open')

      return this;
    };
  }

  private initSidebarClose(): void {
    const self = this;
    ol.control.Rightpanel.prototype.close = function () {
      // remove old active highlights
      for (let i = self._tabitems.length - 1; i >= 0; i--) {
        const child = self._tabitems[i];
        if (child.classList.contains('active'))
          child.classList.remove('active');
      }

      // close sidebar
      if (!self.element.classList.contains('collapsed')) {
        self.element.classList.add('collapsed');
      }
      $('.ol-full-screen,.ol-custom-control_zoom,.ol-zoom').toggleClass('open')

      return this;

    };
  }



}
