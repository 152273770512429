import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenlayersSidebarV2Component } from './openlayers-sidebar-v2.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
import {FormsModule} from "@angular/forms";
import {NouisliderModule} from "ng2-nouislider";
import { TranslocoModule } from '@jsverse/transloco';
import { MatExpansionModule } from '@angular/material/expansion';
import { FeatureLayersAccordionComponent } from './feature-layers-accordion/feature-layers-accordion.component';
import { ItemLayersAccordionComponent } from './item-layers-accordion/item-layers-accordion.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObjectivTrackerModule } from '@objectiv/tracker-angular';
import { MatListModule } from '@angular/material/list';
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatCardModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatExpansionModule,
    DragDropModule,
    TranslocoModule,
    NouisliderModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    // ObjectivTrackerModule,
    MatListModule
  ],
  declarations: [OpenlayersSidebarV2Component, FeatureLayersAccordionComponent, ItemLayersAccordionComponent],
  exports: [OpenlayersSidebarV2Component],
})
export class OpenlayersSidebarV2Module {
  // eslint-disable-next-line no-empty-function
  constructor() {}
}
