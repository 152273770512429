import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private verifyDataConfigsSource = new Subject<string>();

  verifyDataConfigs$ = this.verifyDataConfigsSource.asObservable();

  emitVerifyDataConfigs(action: string) {
    this.verifyDataConfigsSource.next(action);
  }
}
