import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-project-unauthorized-modal',
  templateUrl: './project-unauthorized-modal.component.html',
  styleUrls: ['./project-unauthorized-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectUnauthorizedModalComponent {
  public projects: any[] = [];
  public action: boolean=true;

  // eslint-disable-next-line no-empty-function
  constructor(private dialog: MatDialog,public dialogRef: MatDialogRef<any>) { }

  //open project selection modal
  public openProjectSelectionModal(){
    this.dialogRef.close();
  }
}
