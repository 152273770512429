import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { finalize, tap } from 'rxjs/operators';
import { Complaint } from 'src/app/akita/complaints/state/complaint.model';
import { ComplaintsService } from 'src/app/akita/complaints/state/complaints.service';
import { Decision } from 'src/app/akita/requirements/state/decision.model';
import { DecisionsService } from 'src/app/akita/requirements/state/decisions.service';
import { Issue } from 'src/app/akita/issues/state/issue.model';
import { IssuesService } from 'src/app/akita/issues/state/issues.service';
import { Location } from 'src/app/akita/locations/state/location.model';
import { Stakeholder } from 'src/app/akita/stakeholders/state/stakeholder.model';
import { StakeholdersService } from 'src/app/akita/stakeholders/state/stakeholders.service';
import { CalendarService } from 'src/app/core/service/api/calendar.service';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';
import { getItemStatusMapToggles } from 'src/app/core/util/itemsStatus';
import collect from 'collect.js';
import { Globals } from 'src/app/globals';
@Component({
  selector: 'app-item-layers-accordion',
  templateUrl: './item-layers-accordion.component.html',
  styleUrls: ['./item-layers-accordion.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemLayersAccordionComponent implements OnInit {
  public layer: any[]=[];
  @Input() type: any[]=[];
  @Input() showToggles: boolean=true;
  @Output() toggleChangeItem: EventEmitter<any> = new EventEmitter();
  paginator=new PaginationParams().with(["locations"]).whereHas(["locations"]);
  public emitValues={
    location:[],
    item:{},
    type:'',
    action:false,
    toggle:false
  }
  constructor(
    private _stakeholdersService: StakeholdersService,
    private _issuesService: IssuesService,
    private _complaintsService: ComplaintsService,
    private _decisionsService: DecisionsService,
    private _calendarsService: CalendarService,
    public globals: Globals,
    private cd: ChangeDetectorRef,
  // eslint-disable-next-line no-empty-function
  ) { }

  ngOnInit(): void {
    this.type.forEach(items => {
      let isStrategic = false;
      if (this.globals.projectConfigs['project.stakeholder_strategy.is_new'])
        isStrategic = true;
      this.layer.push(getItemStatusMapToggles(items,isStrategic));
    });
  }
  //on toggle show items
  public ontoggleChangeItem(event,type,action=false,mainEnable=false){
    this.emitValues.type = type;
    this.emitValues.action = action;
    this.emitValues.toggle=true
    if(mainEnable){
      this.enableMainItem(type);
    }
    if(!event.checked){
      this.layer.forEach(item=>{
        if(item.name==type){
          if(!collect(item.status).contains((value, key) => value.is_active == true)){
            this.emitValues.item = { layer: type, status: event.checked };
            this.emitValues.location=[];
            this.toggleChangeItem.emit(this.emitValues);
          }else{
            this.inAppItemsGet(type,false);
            this.emitValues.item = { layer: type, status: true };
          }
        }
      });
      return;
    }
    this.emitValues.item = { layer: type, status: event.checked };
    this.inAppItemsGet(type);
  }
  //logic for get items
  public inAppItemsGet(type,toggle=true): void{
    this.showToggles=false;
    switch(type){
      case 'stakeholder':
      case 'stakeholders':
        this.paginator._filters={filter_field:[]};
        this.layer.forEach(item=>{
          if(item.name==type){
            item.status.forEach(el => {
              if(el.is_active){
                this.paginator.extra('filter_by', 'matrices.strategie')
                this.paginator._filters.filter_field.push(el.filter)
              }
            });
          }
        })
        this._stakeholdersService.get(this.paginator)
        .pipe(
          tap((response: Stakeholder[]) => {
            this.emitValues.location = response.filter((stakeholder) =>stakeholder.locations && stakeholder.locations.points).map((stakeholder: Stakeholder) => {
              stakeholder.locations.id = stakeholder.id;
              stakeholder.locations.name = stakeholder.name;
              stakeholder.locations.matrices = stakeholder.matrices.strategie;
              stakeholder.locations.link = `stakeholders/${stakeholder.code}`;
              return stakeholder.locations;
            });
          }),
          finalize(()=>{
            this.emitValues.toggle=toggle;
            this.showToggles=true;
            this.toggleChangeItem.emit(this.emitValues);
          })
        ).subscribe();
      break;
      case 'issue':
      case 'issues':
        this.paginator._filters={urgency:[]};
        this.layer.forEach(item=>{
          if(item.name==type){
            item.status.forEach(el => {
              if(el.is_active){
                this.paginator.extra("issues_status", "open");
                this.paginator._filters.urgency.push(el.filter);
              }
            });
          }
        });
        this._issuesService.get(this.paginator)
        .pipe(
          tap((response: Issue[])=>{
            this.emitValues.location = response.filter((issue) =>issue.locations && issue.locations.points).map((issue: Issue) => {
              issue.locations.date = issue.issues_date;
              issue.locations.id = issue.id;
              issue.locations.name = issue.name;
              issue.locations.urgency = issue.urgency;
              issue.locations.link = `issues/${issue.code}`;
              return issue.locations;
            })
          }),
          finalize(()=>{
            this.emitValues.toggle=toggle;
            this.showToggles=true;
            this.toggleChangeItem.emit(this.emitValues);
          })
        ).subscribe();
      break;
      case 'complaints':
        this.paginator._filters={is_closed:[]};
        this.layer.forEach(item=>{
          if(item.name==type){
            item.status.forEach(el => {
              if(el.is_active){
                this.paginator.with(["locations"]);
                this.paginator._filters.is_closed.push(el.filter);
              }
            });
          }
        });
        this._complaintsService.get(this.paginator)
        .pipe(
          tap((response: Complaint[])=>{
            this.emitValues.location = response.filter((complaint) => complaint.locations && complaint.locations.points).map((complaint: Complaint) => {
              return this.complaintLocation(complaint);
            })
          }),
          finalize(()=>{
            this.emitValues.toggle=toggle;
            this.showToggles=true;
            this.toggleChangeItem.emit(this.emitValues);
          })
        ).subscribe();
      break;
      case 'requirement':
      case 'requirements':
        this.paginator._filters={status:[]};
        this.layer.forEach(item=>{
          if(item.name==type){
            item.status.forEach(el => {
              if(el.is_active){
                this.paginator._filters.status.push(el.filter)
              }
            });
          }
        })
        this._decisionsService.get(this.paginator)
        .pipe(
          tap((response: Decision[])=>{
            this.emitValues.location = response.filter((decision) => decision.locations && decision.locations.points).map((decision: Decision) => {
              decision.locations.date = decision.start_date;
              decision.locations.end_date = decision.end_date;
              decision.locations.id = decision.id;
              decision.locations.name = decision.title || `Klantwens ${decision.id}`;
              decision.locations.link = `klanteisen/${decision.id}`;
              decision.locations.status = decision.status;
              return decision.locations;
            })
          }),
          finalize(()=>{
            this.emitValues.toggle=toggle;
            this.showToggles=true;
            this.toggleChangeItem.emit(this.emitValues);
          })
        ).subscribe();
      break;
      case 'activity':
      case 'calendars':
        this._calendarsService.getCalendar("", "", false, "activities", ["locations"])
        .pipe(
          tap((response: any[]) => {
            if(response && response.length){
              this.emitValues.location = response.filter((activity) => activity.locations && (activity.locations.points || activity.locations.lines)).map((activity: any) => {
                activity.locations.date = activity.started_at;
                activity.locations.end_date = activity.ended_at;
                activity.locations.id = activity.id;
                activity.locations.calendarable_id = activity.calendarable_id;
                activity.locations.name = activity.metadata.title;
                activity.locations.link = `communicatiekalender/activiteit/${activity.id}`;
                return activity.locations;
              })
            }
          }),
          finalize(()=>{
            this.emitValues.toggle=toggle;
            this.showToggles=true;
            this.toggleChangeItem.emit(this.emitValues);
          })
        ).subscribe();
      break;
    }
  }
  // get locastion params from complaints
  public complaintLocation(complaint: Complaint): Location {
    let name = complaint.complainant_name;
    if (!name && complaint.stakeholders.length) {
      name = complaint.stakeholders[0].name;
    }else if (!name && complaint.residents) {
      name = complaint.residents.name;
    }
    complaint.locations.id = complaint.id;
    const themeName = complaint.complaintthemes ? complaint.complaintthemes.name: null;
    complaint.locations.name = name ? themeName ? `${name} - ${themeName}` : name : themeName ? themeName : `Melding op ${moment(complaint.date).format("D MMM YYYY")}`;
    complaint.locations.link = `meldingen/${complaint.id}`;
    complaint.locations.is_closed = complaint.is_closed;
    complaint.locations.date = complaint.date ? moment(complaint.date) : "";
    return complaint.locations;
  }
  //get all toggle show items
  public ontoggleChangeAllItem(event,type,action=false){
    this.layer.forEach(item=>{
      if(item.name==type){
        item.is_active=event.checked;
        item.status.forEach(el => {
          el.is_active=event.checked
        });
      }
    });
  this.ontoggleChangeItem(event,type,action);
  }
  //enable the main item toggle
  public enableMainItem(name){
    this.layer.forEach(item=>{
      if(item.name==name){
        item.is_active=collect(item.status).contains((value, key) => value.is_active == true)
      }
    });
  }
}
