import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { MapService } from 'src/app/core/service/api/map.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { arcgisTypeDefine, getArcGiUniqueResponce } from 'src/app/core/util/integration';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { ProjectConfigsService } from '../../../../akita/project-configs/state/project-configs.service';
import { showFeedbackError, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
@Component({
  selector: 'app-integrations-modal-arcgis',
  templateUrl: './integrations-modal-arcgis.component.html',
  styleUrls: ['./integrations-modal-arcgis.component.sass']
})
export class IntegrationsModalArcgisComponent extends IntegrationsModalComponent implements OnInit {
  private serviceGroup: string = 'integration';
  public isProcess: boolean = false;
  public isProcessLayers: boolean = false;
  public arhgisenable:boolean=false;
  public featureLayers:any={};
  public layerEntity: any[] = [];
  public layerFeatures:any[]=[];
  public enableLayers:any={};
  public updatedLayers:any;
  public errorMessage:boolean=false;
  public retryButton:boolean=false;
  public connectedDisable:boolean=false;
  public archisToggleSelection:string='arcgis';
  public enterpriseField:boolean=false;
  public enterpriseURL:string='https://assetstest.duravermeer.nl/portal/sharing/oauth2/authorize';
  public clientid:string='';
  public clientsecret:string='';
  public disableConnectionEnterprise:boolean=false;
  public togglerDisabler:boolean=false;
  public broadcastWaitMessage=translate('settings.arcgis.broadcasting_wait');
  public activeArcGis:string='arcgis'
  public isArcgisSecret: boolean = false;
  private secretDebouncer: Subject<any> = new Subject();
  @ViewChild('form_integration', { static: false }) form_integration: FormGroup;
  public level:any='';
  public projectname:any;
  public arcGisLayers: any[] = [];
  public arcGisToken: string;
  public count: number = 0;
 constructor(
   private cd: ChangeDetectorRef,
   private location: Location,
   public _rout:Router,
   public pubsub: PubsubService,
   @Inject(MAT_DIALOG_DATA) public data: any,
   public dialogRef: MatDialogRef<any>,
   public globals: Globals,
   public _projectConfigsService: ProjectConfigsService,
   private _integrationsService: IntegrationsService,
   private _mapServices: MapService
   ) {
    super(globals, _projectConfigsService);
  }
  ngOnInit() {
    if(this._rout.url.includes('beheer')){
      this.level='company';
      this.projectname = this._rout.url.split("/")[2];
    }
    this.secretDebouncer.pipe(
      debounceTime(300)
    ).subscribe((value) => {
      if (value) {
        this.setEnterpriseSecret(value);
      } else {
        this.setEnterpriseSecret('');
      }
    });
    if(this._integrationsService.layers==undefined){
      this._integrationsService.layers=this._integrationsService.layerBroadcasting.subscribe((res)=>{
        this.afterBroadCastingFeatureLayers(res)
        this._integrationsService.layers=undefined;
      });
    }
    this.connectedDisable=true;
     this.updatedLayers={
      featurelayers:[],
      layers:[],
    };
    this.arcgisValidator();
    this.getConfig(this.serviceGroup);
    this.arhgisenable=(this.globals.projectConfigs['services.integration.arcgis.enabled'] || this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled']);
    this.errorMessage=(this.globals.projectConfigs['services.integration.arcgis.connection_error'] || this.globals.projectConfigs['services.integration.arcgis_enterprise.connection_error']);
    this.onLoadFeatureLayers();
  }
  //on load arcgis layers
  public onLoadFeatureLayers(){
    if(this.arhgisenable && !this.errorMessage){
      this.isProcess=true;
      this.isProcessLayers=true;
      if(!this.globals.featuresLayers){
          this.getFeaturesLayersFunction();
      }else{
        this.loadBroadcastingMessage();
        this.featureLayers=this.globals.featuresLayers;
        this.layerEntity=this.featureLayers.layers;
        this.connectedDisable=false;
        this.isProcess=false;
        if(this.featureLayers.featurelayers){
          this.layerFeatures=this.featureLayers.featurelayers;
          this.updatedLayers.featurelayers = this.layerFeatures.filter((item) => {
            return item.enabled==1;
          });
          this.isProcessLayers=false;
          this.retryButton=false;
        }else{
          this.broadCastingProcessCheck();
        }
        this.updatedLayers.layers = this.layerEntity.filter((item) => {
          return item.value==1;
        });
      }
      this.cd.detectChanges();
    }
  }
  public getFeaturesLayersFunction(){
    this._projectConfigsService.getFeatureLayers(this.archisToggleSelection).subscribe((response:any)=>{
      this.globals.featureLayersProcessID=response.feature_layer_process_id;
      this.globals.featuresLayers=response;
      this.featureLayers=response;
      this.layerEntity=this.featureLayers.layers;
      this.connectedDisable=false;
      this.isProcess=false;
      this.updatedLayers.layers = this.layerEntity.filter((item) => {
        return item.value==1;
      });
      this.loadBroadcastingMessage();
    },(error)=>{
      this.errorMessage=true;
      this.isProcess=false;
      this.connectedDisable=true;
      this.globals.projectConfigs['services.integration.arcgis.connection_error']=true;
      this.cd.detectChanges();
      showFeedbackError(error);
    });
  }
  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }
  arcgicRediect(){
    if(this.arhgisenable){
      this.location.replaceState(`/${this.level}` == 'company' ? 'beheer/' : `${ this.level}` == 'company' ? this.projectname : `${this.globals.projectCode}/instellingen/integraties`);
      this.arhgisenable=false;
      this.setConfig(this.serviceGroup, () => {
        this.dialogRef.close();
        showFeedbackSaved();
      });
      this.arcgisCongigsLogs();
    }
    else{
      const expiration=-1;
      if(this.archisToggleSelection=='arcgis_enterprise'){
        localStorage.setItem('redirectType', 'arcgis_enterprise');
        localStorage.setItem('state', this.level == 'company' ? this.projectname : this.globals.projectCode);
        this._projectConfigsService.update('arcgis_enterprise', this.globals.projectConfigs).subscribe();
        window.open(`${this.globals.projectConfigs['services.integration.arcgis_enterprise.url']}/portal/sharing/oauth2/authorize?client_id=${this.globals.projectConfigs['services.integration.arcgis_enterprise.client_id']}&response_type=code&expiration=${expiration}&redirect_uri=${window.location.protocol }//${ window.location.host }/instellingen/integraties`)
      }else{
        localStorage.setItem('redirectType', 'arcgis');
        localStorage.setItem('state', this.level == 'company' ? this.projectname : this.globals.projectCode);
        window.open(`https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${this.globals.clientId}&response_type=code&expiration=${expiration}&redirect_uri=${window.location.protocol }//${ window.location.host }/instellingen/integraties`)
      }
    }
  }

  public onSecretChange(event: any): void {
    this.secretDebouncer.next(event.target.value);
  }

  public setEnterpriseSecret(secret: string): void {
    // remove previous secret
    if (!this.isArcgisSecret && this.globals.projectConfigs['services.integration.arcgis_enterprise.client_secret']) {
      this.isArcgisSecret = true;
      this.globals.projectConfigs['services.integration.arcgis_enterprise.client_secret'] = '';
      this.setConfig(this.serviceGroup);
    } else {
      // update new secret
      this.globals.projectConfigs['services.integration.arcgis_enterprise.client_secret'] = secret
      this.setConfig(this.serviceGroup);
    }
  }
  public saveupdateFunction(layers){
    this.showAllInGlobel(layers);
    this._projectConfigsService.updateFeatureLayers(layers,this.archisToggleSelection)
    .pipe(
      tap(async (res: any) => {
        await this.integrationSetup();
        showFeedbackSaved();
      })
    ).subscribe();
  }
  public async updateLayers() {
    await this.onResetLayerSelection();
    this.updatedLayers.featurelayers.map((key)=>{
      key['enabled']=1;
    });
    this.updatedLayers.layers.map((key)=>{
      key['value']=1;
    });
    await this.saveupdateFunction(this.updatedLayers);
  }
  public modelChangedUrl(data){
    if(!data){
      this.disableConnectionEnterprise=true;
    }else{
      this.disableConnectionEnterprise=false;
    }
  }
  public toggle(){
    if(this.archisToggleSelection=='arcgis_enterprise'){
      this.enterpriseField=true;
      if(!this.globals.projectConfigs['services.integration.arcgis_enterprise.url']){
        this.disableConnectionEnterprise=true;
      }else{
        this.disableConnectionEnterprise=false;
      }
    }else{
      this.enterpriseField=false;
      this.disableConnectionEnterprise=false;
    }
  }
  public arcgisCongigsLogs(){
    // Argis Setup
    this.globals.projectConfigs['services.integration.arcgis.configlevel']='open';
    this.globals.projectConfigs['services.integration.arcgis.user']='0';
    if(this.globals.user){
      if(this.globals.user.configs){
        this.globals.user.configs.arcgis_enabled=0;
      }
    }
    this.globals.projectConfigs['services.integration.arcgis.enabled']=false;
    this.globals.projectConfigs['services.integration.arcgis.connection_error']=false;
    // Argis Setup End
    // Arcgis Enterprise Setup
    this.globals.projectConfigs['services.integration.arcgis_enterprise.configlevel']='open';
    this.globals.projectConfigs['services.integration.arcgis_enterprise.user']='0';
    if(this.globals.user){
      if(this.globals.user.configs){
        this.globals.user.configs.arcgis_enterprise_enabled=0;
      }
    }
    this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled']=false;
    this.globals.projectConfigs['services.integration.arcgis_enterprise.connection_error']=false;
    // Arcgis Enterprise Setup End
  }
  public arcgisValidator(){
    if(this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled']){
      this.togglerDisabler=true;
      this.enterpriseField=true;
      this.archisToggleSelection='arcgis_enterprise';
    }else if(this.globals.projectConfigs['services.integration.arcgis.enabled']){
      this.togglerDisabler=true;
      this.enterpriseField=false;
      this.archisToggleSelection='arcgis';
    }else{
      this.enterpriseField=false;
      this.togglerDisabler=false;
    }
  }
  public removeSpace(layername){
    return layername.replace(/\s/g, '');
  }
  //broad casting api
  public afterBroadCastingFeatureLayers(status){
    if(status=='completed'){
      this.onLoadFeatureLayers();
      this.retryButton=false;
      return;
    }
    this.broadCastingProcessCheck();
  }
  //process check
  public broadCastingProcessCheck(){
    this._projectConfigsService.broadcastingProcess(this.globals.featureLayersProcessID)
      .pipe(
        tap((res:any)=>{
          if(!res.ok){
            this.activeArcGis=arcgisTypeDefine(this.globals);
              this._projectConfigsService.getBroadcastingFeatureLayers(this.activeArcGis)
              .pipe(
                tap(layers=>{
                  this.globals.featuresLayers.featurelayers=getArcGiUniqueResponce(layers);
                  this.cd.detectChanges();
                  this.globals.features_Exist = true;
                  if(this.globals.features_Exist){
                    if(!this.globals.featuresLayers.featurelayers){
                      this.retryButton=true;
                      this.isProcessLayers=false;
                    }else{
                      this.onLoadFeatureLayers();
                      this.isProcessLayers=false;
                      this.cd.detectChanges();
                    }
                  }else{
                    this.count = this.count + 1;
                    if(this.count === 10){
                      this.retryButton=true;
                      this.isProcessLayers=false;
                      return
                    }
                    this.broadCastingProcessCheck();
                  }
                })
            ).subscribe();

          }else{
            setTimeout(() => {
              this.broadCastingProcessCheck();
            }, 5000);
          }
        })
      ).subscribe();
  }
  //on retry
  public onRetry(){
    if(this.arhgisenable && !this.errorMessage){
      this.retryButton=false;
      this.isProcessLayers=true;
      this.getFeaturesLayersFunction();
    }
  }
  //after 10 second load message
  public loadBroadcastingMessage(): void{
    if(!this.globals.featuresLayers.featurelayers){
      setTimeout(() => {
        this.broadcastWaitMessage=translate('settings.arcgis.broadcasting_wait_late');
      }, 10000);
    }
  }
  //show all layers logic
  public showAllInGlobel(layers){
    this.globals.featuresLayers.featurelayers.forEach(items => {
      if(layers.featurelayers){
        if(layers.featurelayers.length){
          layers.featurelayers.forEach(obj => {
            if(obj.id==items.id){
              items.enabled=obj.enabled;
            }
          });
        }else{
          items.enabled=0;
        }
      }else{
        items.enabled=0;
      }
    });
  }
  /*
    Integration ArGis Reset Layers Selection
  */
  public onResetLayerSelection(): void{
    this.globals.featuresLayers.featurelayers.forEach(obj1 => {
      const matchingObj = this.updatedLayers.featurelayers.find(obj2 => obj2.id === obj1.id);
      obj1.enabled = matchingObj ? 1 : 0;
    });
    this.globals.featuresLayers.layers.forEach(obj1 => {
      const matchingObj = this.updatedLayers.featurelayers.find(obj2 => obj2.id === obj1.id);
      obj1.value = matchingObj ? 1 : 0;
    });
  }
  /*
    Integration ArGis MainLayers
  */
  public integrationSetup(): void {
    this.activeArcGis=arcgisTypeDefine(this.globals);
    combineLatest([
      this._projectConfigsService.getEnableFeatureLayers(this.activeArcGis),
      this._projectConfigsService.getFreshToken(this.activeArcGis)
    ])
    .pipe(
      tap(async ([layers, arcgis]) => {
        this.setArcGisSubLayers(layers, arcgis);
      })
    ).subscribe();
  }
  /*
    ArcGis Layers Into Sub Layers
  */
  public async setArcGisSubLayers(layers: any, arcgis: any): Promise<void> {
    this.arcGisLayers = [];
    layers.forEach(layer => {
      this._mapServices.getArcGisSubLayers(layer.url, arcgis.token)
      .pipe(
        tap(async (res: any) => {
          if(res && res.length) {
            res.forEach(item => {
              let foundObject = null;
              if(layer.metadata){
                if(layer.metadata.length) {
                  foundObject = layer.metadata.find(data => data.layerId === item.id);
                }
              }
              this.arcGisLayers.push({
                name: layer.name,
                main_layer_id: layer.id,
                layer_name: item.name,
                layer_id: item.id,
                type: item.type,
                geometry_type: item.geometryType,
                url: layer.url,
                owner: layer.owner,
                enabled: layer.enabled,
                metadata: foundObject ? foundObject.metadata : null,
                alldata: layer.metadata ? layer.metadata : null,
              });
            });
          }
          this.arcGisToken = arcgis.token;
          this.globals.enableLayers = this.arcGisLayers;
          this.globals.arcgisToken = this.arcGisToken;
        })
      ).subscribe();
    });
  }
}
