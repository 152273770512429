import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { translate } from "@jsverse/transloco";
import collect from 'collect.js';
import { untilDestroyed } from "ngx-take-until-destroy";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { arcgisTypeDefine, integrationError, integrationValidationArcGis } from "src/app/core/util/integration";
import { Activity } from "../../../../akita/activities/state/activity.model";
import { Complaint } from "../../../../akita/complaints/state/complaint.model";
import { Middel } from "../../../../akita/content/state/middel.model";
import { Issue } from "../../../../akita/issues/state/issue.model";
import { Decision } from "../../../../akita/requirements/state/decision.model";
import { Resident } from "../../../../akita/residents/state/resident.model";
import { Stakeholder } from "../../../../akita/stakeholders/state/stakeholder.model";
import {
  Subproject,
  createSubproject,
} from "../../../../akita/subproject/state/subproject.model";
import { SubprojectQuery } from "../../../../akita/subproject/state/subprojects.query";
import { SubprojectsService } from "../../../../akita/subproject/state/subprojects.service";
import { Action } from "../../../../akita/tasks/state/action.model";
import { CachedService } from "../../../../core/service/common/cached.service";
import { WithParams } from "../../../../core/service/common/with-params";
import { scrollToError } from "../../../../core/util/form";
import {
  showFeedbackRemoved,
  showFeedbackSaved,
} from "../../../../core/util/notification";
import { Globals } from "../../../../globals";
import { DeletionModalComponent } from "../../../../shared/components/modal/deletion-modal/deletion-modal.component";
import { OpenlayersMapComponent } from "../../openlayers-map/openlayers-map.component";
import { GenericComponent } from "src/app/core/util/abstract/generic-component";


@Component({
  selector: "app-subproject-edit",
  templateUrl: "./subproject-edit.component.html",
  styleUrls: ["./subproject-edit.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubprojectEditComponent extends GenericComponent implements OnInit, OnDestroy {
  public stakeholders: Observable<Stakeholder[]>;
  public residents: Observable<Resident[]>;
  public issues: Observable<Issue[]>;
  public decisions: Observable<Decision[]>;
  public complaints: Observable<Complaint[]>;
  public activities: Observable<Activity[]>;
  public middel: Observable<Middel[]>;
  public actions: Observable<Action[]>;
  public formGroup: FormGroup;
  public roles:any=[];
  public isLoader=false;
  public users:any=[];
  public selectedSubprojectRoles:any=[];
  public mainProjectRoles:any=[];
  public subProjectCopy: Subproject = createSubproject("Copy", null);
  public subprojectRoles:any=[];
  public subprojectUsers:any=[];
  public layerFeatures: any = [];
  public selectedFeatues: any = [];
  chipDisabler:any=[];
  public selectedFeatures:any[] = [];
  public previousLayer:any = null;
  public selectType:string = 'add';
  public integrationEnable: boolean=false;
  public activeArcGis:string='arcgis';
  public errorIntegration: boolean=false;
  public errorIntegrationEnterprise: boolean=false;
  public nameExists: boolean = false;
  public integrationCheck: string;
  public subprojectMapFeatures = {
    arcgis: [],
    opendata: []
  };
  public featureSelectedType!: string;
  @ViewChild(OpenlayersMapComponent, {static: false}) map: OpenlayersMapComponent;
  @ViewChild("form", { static: false }) form: NgForm;

  private processingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private subProjectRelations: string[] = [
    "locations","layers"
  ];
  public processing$: Observable<boolean> = this.processingSubject.asObservable();
  constructor(
    @Inject(MAT_DIALOG_DATA) public subProject: Subproject,
    public globals: Globals,
    public dialogRef: MatDialogRef<any>,
    private subProjectQuery: SubprojectQuery,
    private formBuilder: FormBuilder,
    private _cachedService: CachedService,
    private _subprojectsService: SubprojectsService,
    private confgs: ConfigsService,
    public subpro: MatDialog,
    private cd: ChangeDetectorRef,
    private projectsService: ProjectsService
  ) {
    super();
  }

  ngOnInit() {
    this.activeArcGis=arcgisTypeDefine(this.globals);
    this.errorIntegration=integrationError(this.globals,'arcgis_enabled');
    this.errorIntegrationEnterprise=integrationError(this.globals,'arcgis_enterprise_enabled');
    this.integrationCheck=integrationValidationArcGis(this.globals,'arcgis','arcgis_enterprise','arcgis_enabled','arcgis_enterprise_enabled',this.errorIntegration,this.errorIntegrationEnterprise);
    if(this.integrationCheck==='project'){
      this.integrationEnable=true;
    }
    if(this.integrationCheck==='personal'){
      this.integrationEnable=true;
    }
    this.mainroleFinder();
    this.getRoles();
    this._subprojectsService
      .select(
        this.subProject.id,
        new WithParams().with(this.subProjectRelations)
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.subProject = this.subProjectQuery.getEntity(this.subProject.id);
        this.initForm();
        this.getSubprojectUsers()
      });

    this.initForm();

    this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((change: Subproject) => {
        this.subProject = change;
      });

    this._cachedService.ignoreSubProject = true;

    this.stakeholders = this._cachedService.getStakeholders();
    this.residents = this._cachedService.getResidents();
    this.issues = this._cachedService.getIssues();
    this.decisions = this._cachedService.getDecisions();
    this.complaints = this._cachedService.getComplaints();
    this.activities = this._cachedService.getActivities();
    this.middel = this._cachedService.getMiddel().pipe(
      map((middel: Middel[]) =>
        middel.map((m) => {
          if (m.activities) {
            m.name = `${m.type} ${translate('reusable.for_activity')} ${m.activities.name}`;
          } else {
            m.name = m.type;
          }
          return m;
        })
      )
    );
    this.actions = this._cachedService.getActions();
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      id: [this.subProject.id, Validators.required],
      name: [this.subProject.name, Validators.required],
      locations: [this.subProject.locations, Validators.required],
      layers: [this.subProject.layers],
      users:[],
      stakeholders: [
        {
          value: this.subProject.all ? null : this.subProject.stakeholders,
          disabled: this.subProject.all,
        },
      ],
      residents: [
        {
          value: this.subProject.all ? null : this.subProject.residents,
          disabled: this.subProject.all,
        },
      ],
      issues: [
        {
          value: this.subProject.all ? null : this.subProject.issues,
          disabled: this.subProject.all,
        },
      ],
      decisions: [
        {
          value: this.subProject.all ? null : this.subProject.decisions,
          disabled: this.subProject.all,
        },
      ],
      complaints: [
        {
          value: this.subProject.all ? null : this.subProject.complaints,
          disabled: this.subProject.all,
        },
      ],
      activities: [
        {
          value: this.subProject.all ? null : this.subProject.activities,
          disabled: this.subProject.all,
        },
      ],
      middel: [
        {
          value: this.subProject.all ? null : this.subProject.middel,
          disabled: this.subProject.all,
        },
      ],
      actions: [
        {
          value: this.subProject.all ? null : this.subProject.actions,
          disabled: this.subProject.all,
        },
      ],
      all: [this.subProject.all || false, Validators.required],
    });
    this.formGroup.value.layers.forEach(feature => {
      switch(feature.type){
        case 'arcGis':
          this.subprojectMapFeatures.arcgis.push(feature);
          break;
        case 'openData':
          this.subprojectMapFeatures.opendata.push(feature);
          break;
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public subprojectExists() {
    this.nameExists = false;
    const name = this.formGroup.value.name;
    this.nameExists = this.globals.subprojects.some(project => project.name.toLowerCase() === name.toLowerCase() && this.subProject.name !== name);
  }

  public update(): void {
    if (this.form.invalid || this.nameExists) {
      scrollToError();
      return;
    }
    if (this.subProject.layers.length) {
      const layers = this.subProject.layers;
      let layersCount = 0;
      this.selectedFeatues = [];
      layers.forEach((layer: any) => {
        const singleLayer = {
            type: layer.type,
            id_field: layer.id_field,
            layer_id: layer.layer_id,
            layer_name: layer.layer_name,
            title: layer.title,
            main_layer_id: layer.main_layer_id,
            sub_layer_id: layer.sub_layer_id,
            sub_layer_name: layer.sub_layer_name
          }
        this.confgs.setLayerConection({layers: singleLayer}).subscribe( (res: any) => {
          layersCount++;
          const idLayer = {
            id: res.id,
            id_field: res.id_field,
            layer_id: res.layer_id,
            layer_name: res.layer_name,
            title: res.title,
            type: layer.type,
            main_layer_id: res.main_layer_id,
            sub_layer_id: res.sub_layer_id,
            sub_layer_name: res.sub_layer_name
          }
          this.selectedFeatues.push(idLayer);
          if(layersCount === layers.length) {
            this.updateSubproject()
          }
        })
      })
    }else{
      this.updateSubproject()
    }
  }
  updateSubproject() {
    this.formGroup.patchValue({layers: this.selectedFeatues.length ? this.selectedFeatues : []});
    this.formGroup.value.users=this.subProject.users;
    this.processingSubject.next(true);
    this._subprojectsService
      .update(this.formGroup.value)
      .pipe(finalize(() => this.processingSubject.next(false)))
      .subscribe((subProject) => {
        if (!subProject) {
          return;
        }
        this.loderIntoGlobal();
        showFeedbackSaved();
        this.dialogRef.close(subProject);
      });
  }

  public ngOnDestroy(): void {
    this._cachedService.ignoreSubProject = false;
  }
  public deleteSubproject(subProject) {
    const label = this.globals.projectConfigs['project.labels.subprojects']
    const data = label? `Weet u zeker dat u het ${ label } wilt verwijderen?` : `Weet u zeker dat u het ${ translate('subprojects.label_small') } wilt verwijderen?`
    const dialogRef = this.subpro.open(DeletionModalComponent, {
      data: data,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.processingSubject.next(true);
        this._subprojectsService
          .deletesubproject(subProject)
          .pipe(finalize(() => this.processingSubject.next(false)))
          .subscribe(() => {
            this.loderIntoGlobal();
            showFeedbackRemoved();
            this.dialogRef.close(subProject);
          });
      }
    });
  }
  public subprojectRolesSelect(){
    if(this.subProject.users.length>0){
      this.subProject.users.forEach((element:any,key) => {
          element.roles=this.selectedSubprojectRoles[key];
      });
    }
  }
  public getSubprojectUsers(){
    this._subprojectsService.getSubprojectUsers().subscribe((res:any)=>{
      this.users=res.data;
      this.isLoader=true;
      this.rolesSeparator();
      this.cd.detectChanges();
    })
  }
  public getRoles(){
    this.projectsService.getUserRoles().subscribe(res=>{
      this.roles=res;
      this.roles.forEach(element => {
        if(element.name!=='Project Admin'){
          this.subprojectRoles.push(element);
        }
      });
    })
  }
  public rolesSeparator(){
    this.userLogic();
    this.subprojectUsers.forEach((element:any,key) => {
      if(element.roles){
        this.selectedSubprojectRoles[key]=element.roles;
      }
      else{
        this.selectedSubprojectRoles[key]=[];
      }
    });
    this.rolesDeclare();
    this.cd.detectChanges();
  }
  public mainroleFinder(){
    if(this.globals.user.scope){
      this.globals.user.scope.data.forEach(element => {
        if(!element.is_subproject){
          this.mainProjectRoles=element.roles;
        }
      });
    }
  }
  public rolesDeclare(){
    this.chipDisabler=[];
    const rolesFromPrents=[];
    this.mainProjectRoles.forEach(element => {
      if(element.name!=='Project Admin'){
        this.chipDisabler.push(element.name);
        rolesFromPrents.push(element);
      }
    });
    this.subprojectUsers.forEach((element,index)=> {
      if(this.selectedSubprojectRoles[index].length>0){
        this.selectedSubprojectRoles[index].forEach((elem) => {
          rolesFromPrents.forEach((el,key) => {
            if(elem.name!==el.name){
              if(this.selectedSubprojectRoles[index][key]){
                this.selectedSubprojectRoles[index][key+1]=el;
              }else{
                this.selectedSubprojectRoles[index][key]=el;
              }
            }
          });
        });
      }else{
        this.selectedSubprojectRoles[index]=rolesFromPrents;
      }
    });
  }
  public userLogic(){
    const temp=[];
    const subProject_id=[];
    this.subProject.users.forEach((element:any) => {
      subProject_id.push(element.id);
    });
    this.users.forEach(element => {
        if(!subProject_id.includes(element.id)){
          temp.push(element);
        }
    });
    this.subprojectUsers=this.subProject.users;
    temp.forEach(element => {
      this.subprojectUsers.push(element);
    });
  }
  public loderIntoGlobal(){
    this._subprojectsService.get(true).subscribe(res=>{
      this.globals.subprojects=res;
    })
  }
  /*
    On Select Integration Feature Add Add to Map
  */
  public onSelectIntegrationFeautes(features: any) {
    this.subprojectMapFeatures.arcgis = features;
    this.featureSelectedType = 'arcgis';
    this.onSetAllFeatureIntoOne();
  }
  /*
    On Select Open Data Feature Add Add to Map
  */
  public onSelectOpenDataFeautes(features: any) {
    this.subprojectMapFeatures.opendata = features;
    this.featureSelectedType = 'opendata';
    this.onSetAllFeatureIntoOne();
  }
  /*
    On Select Feature Add Add to Map
  */
  public onSetAllFeatureIntoOne() {
    this.subProject.layers = [...this.subprojectMapFeatures.arcgis, ...this.subprojectMapFeatures.opendata];
    this.subProject.layers = collect(this.subProject.layers).map((obj, index) => { return { ...obj, id: index };}).all();
    this.subProject.layers = collect(this.subProject.layers).unique('title').all();
    if(this.previousLayer != null){
      if(this.previousLayer.length > this.subProject.layers.length){
        this.selectType = 'remove';
      }else{
        this.selectType = 'add';
      }
    }
    this.selectedFeatures = this.subProject.layers;
    setTimeout(() => {
      this.map.addFeatureDynamically();
    }, 500);
    this.previousLayer = this.subProject.layers;
  }
}
