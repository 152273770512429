import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from '../../../core/service/common/abstract.service';
import { Observable, pipe } from 'rxjs';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { ProjectConfigs } from './project-configs.model';
import { ProjectConfigsStore } from './project-configs.store';
import { ProjectConfigsQuery } from './project-configs.query';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Notifications } from 'src/app/core/util/notification';

@Injectable({ providedIn: 'root' })
export class ProjectConfigsService extends AbstractService {

  constructor(private projectConfigsStore: ProjectConfigsStore, private projectConfigsQuery: ProjectConfigsQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(): Observable<ProjectConfigs> {
    return <Observable<ProjectConfigs>>this.http.get(`/api/configs`, this.httpOptions)
      .pipe(
        tap((response: ProjectConfigs) => {
          this.projectConfigsStore.set([response]);
          this.getGlobals().projectConfigs = response;
        }),
        catchError(() => []),
      );
  }

  @ServiceErrorHandler()
  public update(group: string, projectConfigs: ProjectConfigs): Observable<ProjectConfigs> {
    this.projectConfigsStore.update(1, projectConfigs);
    return this.http.put(`/api/configs?group=${group}`, projectConfigs, this.httpOptions)
      .pipe(map((response: ProjectConfigs) => response));
  }



  @ServiceErrorHandler()
  getAllFolders() {
    return this.http.get(`/api/integrations/imap/folders/`, this.httpOptions);
  }
  //verify Folders
  @ServiceErrorHandler()
  getVerifyFolders() {
    return this.http.get(`/api/integrations/imap/validate/folders`, this.httpOptions);
  }

  //project Encrpt
  @ServiceErrorHandler()
  setEncryptPassword(body: any) {
    return this.http.post(`/api/encrypt`, body, this.httpOptions);
  }

  // Migrated from old service

  @ServiceErrorHandler()
  getStatus(driver: string) {
    return this.http.get(`/api/service/status/${driver}`, this.httpOptions);
  }

  validateService(driver: string, configuration: any) {
    return this.http.put(`/api/service/validate/${driver}`, configuration, this.httpOptions);
  }

  fetchService(driver: string) {
    return this.http.get(`/api/service/fetch/${driver}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  fetchSharepointDirectories() {
    return this.http.get(`/file/directory?disk=sharepoint`, this.httpOptions);
  }

  @ServiceErrorHandler()
  assignSharepointRealm(url) {
    return this.http.post(`/configs/realm`, { domain: url }, this.httpOptions);
  }

  @ServiceErrorHandler()
  getUserConfigs() {
    return this.http.get(`/api/my/userconfigs`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getUser(id: any) {
    return this.http.get(`/api/${id}/users`, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateUserConfigs(config) {
    return this.http.post(`/api/my/userconfigs`, config, this.httpOptions);
  }

  @ServiceErrorHandler()
  getOauhtRedirect(driver: string) {
    return this.http.get(`/api/connect/${driver}`, this.httpOptions);
  }
  @ServiceErrorHandler()
  processOauthCallback(driver: string, params: HttpParams) {
    return this.http.get(`/api/connect/${driver}/callback`, {
      headers: this.headers,
      params: params,
    });
  }
  @ServiceErrorHandler()
  public getAdminConfigs(driver: string): Observable<ProjectConfigs> {
    return <Observable<ProjectConfigs>>this.http.get(`/api/configs/`, { headers: { 'X-Project': driver } })
      .pipe(
        tap((response: ProjectConfigs) => {
          this.projectConfigsStore.set([response]);
          this.getGlobals().projectConfigs = response;
        }),
        catchError(() => []),
      );
  }
  @ServiceErrorHandler()
  public updateAdminConfigs(group: string, projectConfigs: ProjectConfigs, driver: string): Observable<ProjectConfigs> {
    this.projectConfigsStore.update(1, projectConfigs);
    return this.http.put(`/api/configs?group=${group}`, projectConfigs, { headers: { 'X-Project': driver } })
      .pipe(map((response: ProjectConfigs) => response));
  }
  @ServiceErrorHandler()
  public getoauth2Token(code, type) {
    const formData = new FormData();
    switch (type) {
      case 'arcgis':
        formData.append("client_id", this.getGlobals().clientId);
        formData.append("code", code);
        formData.append("redirect_uri", `${window.location.protocol}//${window.location.host}/instellingen/integraties`);
        formData.append("grant_type", 'authorization_code');
        return this.http.post('https://www.arcgis.com/sharing/rest/oauth2/token/', formData);
        break;
      case 'arcgis_enterprise':
        formData.append("client_id", this.getGlobals().projectConfigs['services.integration.arcgis_enterprise.client_id']);
        formData.append("code", code);
        formData.append("redirect_uri", `${window.location.protocol}//${window.location.host}/instellingen/integraties`);
        formData.append("grant_type", 'authorization_code');
        return this.http.post(`${this.getGlobals().projectConfigs['services.integration.arcgis_enterprise.url']}/portal/sharing/oauth2/token/`, formData);
        break;
      default:
      // code block
    }

  }
  saveoauth2Token(tokenconfigs, platform) {
    return this.http.post(`/api/integrationconfigs/addtoken?platform=${platform}`, tokenconfigs, this.httpOptions);
  }
  getAccessToken(platform: string, code: string, userId?: number) {
    if (!userId){
      return this.http.get(`/api/integrationconfigs/gettoken?platform=${platform}&code=${code}`, this.httpOptions);
    }
    return this.http.get(`/api/integrationconfigs/gettoken?user_id=${userId}&platform=${platform}&code=${code}`, this.httpOptions);
  }

  getRedirectLink(platform, usrId?: number) {
    // for user level add usrId param
    if (usrId)
      return this.http.get(`/api/integrationconfigs/getloginlink?platform=${platform}&user_id=${usrId}`, this.httpOptions);
    return this.http.get(`/api/integrationconfigs/getloginlink?platform=${platform}`, this.httpOptions);
  }
  saveoauth2TokenUser(id: number, tokenconfigs, platform) {
    return this.http.post(`/api/integrationconfigs/addtoken?user_id=${id}&platform=${platform}`, tokenconfigs, this.httpOptions);
  }
  getFeatureLayers(platform) {
    return this.http.get(`/api/integrationconfigs?platform=${platform}`, this.httpOptions);
  }
  @ServiceErrorHandler()
  updateFeatureLayers(featureslayers, platform) {
    return this.http.put(`/api/integrationconfigs?platform=${platform}`, featureslayers, this.httpOptions);
  }
  //broadcasting api
  @ServiceErrorHandler()
  getBroadcastingFeatureLayers(platform) {
    return this.http.get(`/api/integrationconfigs/layers?platform=${platform}`, this.httpOptions)
      .pipe(
        shareReplay()
      );
  }
  @ServiceErrorHandler()
  getClientId(value) {
    return this.http.get(`/api/integrationsecrets?group=${value}`, this.httpOptions);
  }
  getEnableFeatureLayers(platform, is_user = false) {
    if (is_user) {
      return this.http.get(`/api/getenabledlayers?user_id=${this.getGlobals().user.id}&platform=${platform}`, this.httpOptions);
    }
    return this.http.get(`/api/getenabledlayers?platform=${platform}`, this.httpOptions);
  }

  getMapAddress(keyword: string, boxData: any): Observable<any> {
    let url = `/api/addresses/search?params=${keyword}`;
    // Check if boxData is provided
    if (boxData) {
      url += `&viewbox=${boxData}`;
    }
    return this.http.get(url, this.httpOptions);
  }

  @ServiceErrorHandler()
  getFreshToken(platform, is_user = false) {
    if (is_user) {
      return this.http.get(`/api/getrefreshtoken?user_id=${this.getGlobals().user.id}&platform=${platform}`, this.httpOptions);
    }
    return this.http.get(`/api/getrefreshtoken?platform=${platform}`, this.httpOptions);
  }
  getEnableFeatureUserLayers(id: number, platform) {
    return this.http.get(`/api/getenabledlayers?user_id=${id}&platform=${platform}`, this.httpOptions);
  }
  @ServiceErrorHandler()
  getFreshUserToken(id: number, platform) {
    return this.http.get(`/api/getrefreshtoken?user_id=${id}&platform=${platform}`, this.httpOptions);
  }
  //process checking api
  @ServiceErrorHandler()
  broadcastingProcess(id: number) {
    return this.http.get(`/api/processes/${id}`, this.httpOptions).pipe(
      pipe(
        shareReplay()
      )
    );
  }
  getUserName(id: number) {
    return this.http.get(`/api/${id}/users`, this.httpOptions);
  }
  getConfigsErrorMessage(integration) {
    return this.http.get(`/api/service/status/${integration}`, this.httpOptions);
  }
  integrationSync(type, extraPatam = false) {
    let code;
    if (extraPatam) {
      code = { 'platform': type, 'allNotifications': extraPatam }
    } else {
      code = { 'platform': type }
    }
    return this.http.post(`/api/integrations/sync`, code, this.httpOptions);
  }
  // BouwApp
  public getBouwAppToken(code) {
    const formData = new FormData();
    formData.append("client_id", this.getGlobals().clientIdBouwapp);
    formData.append("code", code);
    formData.append("client_secret", this.getGlobals().clientsecretBouwapp);
    formData.append("redirect_uri", `${window.location.protocol}//${window.location.host}/connect/bouwapp`);
    formData.append("grant_type", 'authorization_code');
    return this.http.post(`${this.getGlobals().bouwapp_url}/auth/token`, formData)
      .pipe(
        shareReplay()
      );
  }
  @ServiceErrorHandler()
  public getBouwAppProjects() {
    return this.http.get(`/api/integrations/bouwapp/projects`, this.httpOptions);
  }
  @ServiceErrorHandler()
  public putBouwAppProjects(projects) {
    const subBouwApp = { subprojects: projects }
    return this.http.put(`/api/subprojects/tenantupdate`, subBouwApp, this.httpOptions);
  }
  @ServiceErrorHandler()
  public updateRelatic(relatic) {
    const data = { data: relatic }
    return this.http.post(`/api/relaticsintegrations`, data, this.httpOptions);
  }
  @ServiceErrorHandler()
  public getRelatic() {
    return this.http.get(`/api/relaticsintegrations`, this.httpOptions);
  }
  @ServiceErrorHandler()
  public getTablesColumn() {
    return this.http.get(`/api/relaticsintegrations/tablecolumns`, this.httpOptions);
  }
  @ServiceErrorHandler()
  public getComplaintTypeForChart(status = 0, param, start_date = '', end_date = '',) {
    if ((status == 0 || status == 1) && !start_date) {
      return this.http.get(`/api/summary/complaints/piechart?status=${status}`, {
        headers: this.headers,
        params: param
      });
    } else if (!(status == 0 || status == 1) && start_date) {
      return this.http.get(`/api/summary/complaints/piechart?start_date=${start_date}&end_date=${end_date}`, {
        headers: this.headers,
        params: param
      });
    }
    else if ((status == 0 || status == 1) && start_date) {
      return this.http.get(`/api/summary/complaints/piechart?status=${status}&start_date=${start_date}&end_date=${end_date}`, {
        headers: this.headers,
        params: param
      });
    }
    return this.http.get(`/api/summary/complaints/piechart`, {
      headers: this.headers,
      params: param
    });

  }

  public getComplaintTypeForChartBar(status = 0, start_date = '', end_date = '') {
    if ((status == 0 || status == 1) && !start_date) {
      return this.http.get(`/api/summary/complaints/barchart?status=${status}`, this.httpOptions);
    } else if (!(status == 0 || status == 1) && start_date) {
      return this.http.get(`/api/summary/complaints/barchart?start_date=${start_date}&end_date=${end_date}`, this.httpOptions);
    }
    else if ((status == 0 || status == 1) && start_date) {
      return this.http.get(`/api/summary/complaints/barchart?status=${status}&start_date=${start_date}&end_date=${end_date}`, this.httpOptions);
    }
    return this.http.get(`/api/summary/complaints/barchart`, this.httpOptions);

  }

  @ServiceErrorHandler()
  public getInAppNotifications(): Observable<Notifications> {
    return this.http.get<Notifications>(`/api/notifications/personal`, this.httpOptions)
      .pipe(map(res => res));
  }
  //personalisation set Apis
  @ServiceErrorHandler()
  public getPersonalisation(data): Observable<any> {
    return this.http.post<any>(`/api/personalizations`, data, this.httpOptions)
      .pipe(
        map(res => res),
        shareReplay()
      );
  }
  //update personalisation
  @ServiceErrorHandler()
  public savePersonalisation(data, id): Observable<any> {
    return this.http.put<any>(`/api/personalizations/${id}`, data, this.httpOptions)
      .pipe(
        map(res => res),
        shareReplay()
      );
  }
  //attach user with personalisation
  @ServiceErrorHandler()
  public attachUserPersonalisation(data, id): Observable<any> {
    return this.http.put<any>(`/api/${id}/users`, data, this.httpOptions)
      .pipe(
        map(res => res),
        shareReplay()
      );
  }
  //attach project with personalisation
  @ServiceErrorHandler()
  public attachProjectPersonalisation(data, code): Observable<any> {
    return this.http.put<any>(`/api/admin/projects/${code}`, data, this.httpOptions)
      .pipe(
        map(res => res),
        shareReplay()
      );
  }
  //get Login link
  @ServiceErrorHandler()
  public getLoginLink(): Observable<any> {
    return this.http.get<any>(`/api/integrations/microsoft/login-link`, this.httpOptions)
      .pipe(map(res => res));
  }
  @ServiceErrorHandler()
  public getMicrosoftToken(token: string): Observable<any> {
    return this.http.post('/api/integrations/microsoft/access-token/', token, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getTrustedBrowsers(): Observable<any> {
    return this.http.get('/api/admin/trusted-devices/',this.httpOptions);
  }

  @ServiceErrorHandler()
  public deleteTrustedBrowser(id: number): Observable<any> {
    return this.http.delete(`/api/admin/trusted-devices/${id}`,this.httpOptions);
  }


}

