import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { AbstractService } from "../common/abstract.service";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class RelaticsService extends AbstractService {

  constructor(private http: HttpClient) {
    super()
  }

  /**
   * Search relatics based on provided keyword.
   *
   * @param keyword string
   */
  search (keyword) {
    return this.http.get('api/relatics/search', {
      headers: this.headers,
      params: new HttpParams()
        .set('keyword', keyword)
        .set('type', 'demands')
    })
  }

  /**
   * Attach demands to Relatics.
   *
   * @param id number
   */
  attachRelatics(id) {
    this.http.post(`api/demands/relatics/${ id}`, {
      headers: this.headers,
      params: new HttpParams()
        .set('id', id)
    })
  }

  @ServiceErrorHandler()
  public getRelatics(type?: string, search?: string) {
    let params = new HttpParams();
    if (type) {
      params = params.append('type', type);
    }

    if (search) {
      params = params.append('search', search)
    }

    return this.http.get(`/api/relatics`, {
      headers: this.headers,
      params: params
    });

  }

  public getRelaticsBlueprints() {
    return this.http.get('/api/relatics/blueprints', this.httpOptions);
  }

  public pushNewRelatics(type, instance) {
    return this.http.post(`/api/relatics?type=${type}`, instance, this.httpOptions);
  }
}
