import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterbymap'
})
export class FilterbymapPipe implements PipeTransform {
    transform(value: any, input: any): any {
        if (input) {
            return value.filter(val => val.indexOf(input)) >= 0;
        } 
            return value;
        

    }
}
