import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { MultiselectOptionType } from "../../../../../core/enum/multiselect-option-type";
import { MultiselectOption } from "../../../form/multiselect/multiselect-option";
import { Observable, of } from "rxjs";
import { StakeholdersService } from "../../../../../core/service/api/stakeholders.service";
import { ResidentsService } from '../../../../../core/service/api/residents.service';
import { ThemesService } from '../../../../../core/service/api/themes.service';
import { ThemeType } from "../../../../../core/enum/theme-type";
import { BatchMapItemsService } from 'src/app/core/service/api/batch-map-items.service';
export enum GenericNewAction {
  Stakeholder = 'stakeholder', Resident = 'resident', Generic_Type = 'generic_type', Theme_Typedecisions = 'themedecisions',
  Theme_Typecomplaintthemes = 'complaintthemes', Theme_Typethemeissues = 'themeissues', Interests = 'interests', Phases = 'phases',
  activites = 'types?group=activities', Stakeholders = 'stakeholdertypes', CalendarType = 'themetype', Permits = 'permitstypes',
  Requester = 'permitsrequesters',Cluster = 'permitsclusters',Validity = 'permitsvalidities',PermitFiling = 'permitsfillingrequirements',
  Issues = 'issues', Activities = 'activities', Complaints = 'complaints', Requirements = 'requirements', MapBatchStakeholders = 'stakeholders'
}
@Component({
  selector: 'app-generic-multiselect-checkbox-option-new-action',
  templateUrl: './generic-multiselect-checkbox-option-new-action.component.html',
  styleUrls: ['./generic-multiselect-checkbox-option-new-action.component.sass']
})
export class GenericMultiselectCheckboxOptionNewActionComponent implements OnInit, MultiselectOption {

  @Input() public type: GenericNewAction;
  public input: string = '';
  public save: any;
  public value: any;
  public content: string = '';
  public checked: boolean = false;
  public mappingResponsive: any;
  public optionType: MultiselectOptionType = MultiselectOptionType.new;

  constructor(private ref: ElementRef, private _stakeholdersService: StakeholdersService,
    private _residentsService: ResidentsService, private cdRef: ChangeDetectorRef,
    private _batchMapItemsService: BatchMapItemsService,
    // eslint-disable-next-line no-empty-function
    private _themeservice: ThemesService) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // if (!this.type) {
    //   throw new TypeError('Attribute "type" is mandatory');
    // }
  }

  public setValue(value: string): void {
    this.input = value;

    switch (this.type) {
      case GenericNewAction.Resident:
      case GenericNewAction.Stakeholder:
      case GenericNewAction.Issues:
      case GenericNewAction.Activities:
      case GenericNewAction.Complaints:
      case GenericNewAction.Requirements:
      case GenericNewAction.MapBatchStakeholders:
      case GenericNewAction.Generic_Type:
        this.value = {
          name: value,
        };
        break;
      case GenericNewAction.Theme_Typedecisions:
      case GenericNewAction.Theme_Typethemeissues:
      case GenericNewAction.Interests:
      case GenericNewAction.Phases:
      case GenericNewAction.Permits:
      case GenericNewAction.Requester:
      case GenericNewAction.Cluster:
      case GenericNewAction.Validity:
      case GenericNewAction.PermitFiling:
      case GenericNewAction.Theme_Typecomplaintthemes:
      case GenericNewAction.activites:
      case GenericNewAction.Stakeholders:
      case GenericNewAction.CalendarType:
        if(this.type !== 'interests') {
          this.value = {
            "save": [{ "name": value, "color": "#4A9ED6" }], "delete": [], "update": []
          }
        } else {
          this.value = {
            "save": [{ "description": value, "color": "#4A9ED6" }], "delete": [], "update": []
          }
        }
        break;
    }
    this.cdRef.detectChanges();
    this.content = this.ref.nativeElement.innerHTML.trim();
  }
  public create(): Observable<any> {
    if (this.type === GenericNewAction.Stakeholder) {
      return this._stakeholdersService.createMultipleStakeholders([this.value]);
    } else if (this.type === GenericNewAction.Resident) {
      return this._residentsService.createMultipleResidents([this.value])
    } else if (this.type === GenericNewAction.Theme_Typedecisions) {
      return this._themeservice.submitTheme(this.type, this.value);
    } else if (this.type === GenericNewAction.Theme_Typethemeissues) {
      return this._themeservice.submitTheme(this.type, this.value);

    } else if (this.type === GenericNewAction.Permits || this.type === GenericNewAction.Requester || this.type === GenericNewAction.Cluster || this.type === GenericNewAction.Validity || this.type === GenericNewAction.PermitFiling){
      return this._themeservice.submitTheme(this.type, this.value);
    }else if (this.type === GenericNewAction.Theme_Typecomplaintthemes) {
      return this._themeservice.updateTheme(ThemeType.Complaints, this.value);
    } else if (this.type === GenericNewAction.activites) {
      return this._themeservice.updateTheme(ThemeType.Activities, this.value);
    } else if (this.type === GenericNewAction.Stakeholders) {
      return this._themeservice.updateTheme(ThemeType.Stakeholders, this.value);
    } else if (this.type === GenericNewAction.CalendarType) {
      return this._themeservice.updateTheme(ThemeType.CalendarType, this.value);
    } else if (this.type === GenericNewAction.Interests) {
      return this._themeservice.updateTheme(ThemeType.Interests, this.value);
    } else if (this.type === GenericNewAction.Phases) {
      return this._themeservice.updateTheme(ThemeType.RequirementsPhases, this.value);
    } else if (this.type === GenericNewAction.Issues || this.type === GenericNewAction.Activities || this.type === GenericNewAction.Complaints || this.type === GenericNewAction.Requirements || this.type === GenericNewAction.MapBatchStakeholders) {
      return this._batchMapItemsService.createItem(this.value.name, this.type);
    }
    return of([this.value]);
  }

}
