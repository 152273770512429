import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  selector: '[validatorIsset]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: IssetValidatorDirective,
      multi: true
    }
  ]
})
export class IssetValidatorDirective implements Validator{
  @Input('validatorIsset') value: any;

  validate(control: AbstractControl): {[key: string]: any} | null {
    if (this.value) {
      return null;
    }

    return { validatorIsset: true };
  }

}
