import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AbstractService} from "../common/abstract.service";
import {PaginationParams} from "../common/pagination-params";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../common/paginated-response";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  getEmployees(pagination: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/employees', pagination.get());
  }

  getEmployeesRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/employees?dropdown=1', this.httpOptions);
  }

  @ServiceErrorHandler()
  exportEmployees(options) {
    return this.http.get(`/file/export/contactperson?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  getEmployeesByFilters(pagination: PaginationParams, status?: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/employees', pagination.get(status ? new HttpParams().set('status', status) : null));
  }

  findEmployees(emails: string[]) {
    const params = new HttpParams()
      .set('emails', emails.join());

    return this.http.get('/api/search/employees', {
      headers: this.headers,
      params: params
    });
  }

  submitEmployee(employee: any) {
    return this.http.post('/api/employees', employee, this.httpOptions);
  }

  @ServiceErrorHandler()
  removeEmployee(employeeId: number) {
    return this.http.delete(`/api/employees/${employeeId}`, this.httpOptions);
  }

  updateEmployee(employeeId: number, employee: any) {
    return this.http.put(`/api/employees/${employeeId}`, employee, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitEmployees(employees: any[]) {
    return this.http.post('/api/batch/employees', {
      employees: employees
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  createEmailEmployees(employees: any[]) {
    return this.http.post('/api/batch/employees', {
      contactpersons: employees
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  removeEmployees(emails: string[]) {
    return this.http.delete(`/api/batch/employees/${emails.join()}` , this.httpOptions);
  }

  @ServiceErrorHandler()
  removeEmployeesByIds(ids: string[]) {
    return this.http.delete(`/api/batch/employeesbyid/${ids.join()}` , this.httpOptions);
  }

  @ServiceErrorHandler()
  connectRelationFromTask(data: any) {
    return this.http.post(`/api/cm-cp-actions`, data, this.httpOptions);
  }
}
