import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Globals } from './globals';
import { ProjectConfigsService } from './akita/project-configs/state/project-configs.service';
import { AuthService } from './core/service/auth/auth.service';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    fbq: any;
  }
}

declare let FB: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  private intervalId: any;
  // eslint-disable-next-line no-empty-function
  constructor(public globals: Globals, private cdRef: ChangeDetectorRef, private projectConfigs: ProjectConfigsService, private authService: AuthService) {
    // document.documentElement.lang = this.globals?.project_personalization?.metadata?.lang
  }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    // Check if the token exists and is not null
    if (token && this.authService.tokenExpiryValidation(token)) {
      this.getWhatIds();
    }

    // set this logic to check sso token expiry in every 5 minutes
    if (localStorage.getItem('is_sso') == 'true') {
      this.checkRefreshToken();
    }

    this.globals.cdRef = this.cdRef;

  }


  getWhatIds() {
    this.projectConfigs.getClientId('whatsapp').subscribe((response: any) => {
      const appId = response.client_id;

      (function (d, s, id) {
        // eslint-disable-next-line
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      // Initialize the SDK upon load
      window.fbAsyncInit = function () {
        FB.init({
          appId: appId, // Dynamically bind the appId here
          cookie: true,
          xfbml: true,
          version: "v19.0",
        });
      };

    })
  }

  private checkRefreshToken(): void {
    const intervalDuration = 5 * 60 * 1000; // 5 minutes in milliseconds
    this.intervalId = setInterval(() => {
      console.log('check sso token neary to expiry?...')
      this.authService.getAccessTokenStatus();
    }, intervalDuration);
  }

  // Cleanup to prevent memory leaks
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
