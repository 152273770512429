import { environment } from "src/environments/environment";
import SSOUsers from '../../constants/auth-existing-sso.json';
export type Auth0Token = {
  access_token: string,
  token_type: string,
  expires_in: number,
  id_token: string,
  scope: string,
}
export type AuthUserIdentifier = {
  auth0Id: string,
  sso: boolean,
  redirect: string,
  customer_id: number,
  user_id: number,
  is_mfa_enabled: boolean,
  is_company_mfa_enabled: boolean,
  is_trusted_device?: boolean,
}
export type SSO = {
  platform: string,
  domain: string,
  client_id: string,
  client_secret: string,
  tenant_id: string,
  identity_provider: string
}
//Get Access Token
export function getAccessToken(code): any {
  return {
    client_id: environment.auth0.clientId,
    code: code,
    grant_type: "authorization_code",
    redirect_uri: `${window.location.origin}/${environment.auth0.redirectURI}`
  }
}

//Get Access Token
export function getTokenFromLogin(email, password): any {
  return {
    grant_type: `http://auth0.com/oauth/grant-type/password-realm`,
    username: email,
    password: password,
    realm:environment.auth0.connection,
    client_id: environment.auth0.clientId,
    audience: environment.auth0.audience,
    scope: 'openid profile email',
  }
}
//change passoword response
export function changePasswordResponse(password): any {
  return {
    password: password,
    connection: environment.auth0.connection,
  }
}
//check auth existing sso users
export function checkAuthExistingSSOs(email): any{
  let ssoInfo = null;
  SSOUsers.forEach(item=>{
    if((document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.local')){
      if(item.environment === 'dev'){
        if(email.includes(item.domain) || email.includes(item.identity_provider)){
          ssoInfo=item;
        }
      }
    }else{
      if(item.environment === 'prod'){
        if(email.includes(item.domain) || email.includes(item.identity_provider)){
          ssoInfo=item;
        }
      }
    }
  });
  return ssoInfo;
}
//make existing sso users redirect url
export function getExistingSSOsURL(sso,email): string{
  return `https://${environment.auth0.domain}/authorize?response_type=token&client_id=${sso.client_id}&connection=${sso.connection}&redirect_uri=${window.location.origin}/${environment.auth0.redirectURI}&scope=openid profile email&audience=${environment.auth0.audience}&login_hint=${email}`
}
