import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { SubprojectsService } from 'src/app/akita/subproject/state/subprojects.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedback, showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { MessageConfirmComponent } from '../message-confirm/message-confirm.component';
@Component({
  selector: 'app-integration-modal-bouwapp-project-level',
  templateUrl: './integration-modal-bouwapp-project-level.component.html',
  styleUrls: ['./integration-modal-bouwapp-project-level.component.sass'],
})
export class IntegrationModalBouwappProjectLevelComponent extends IntegrationsModalComponent implements OnInit{
  public errorMessage:boolean=false;
  private serviceGroup: string = 'bouwapp';
  public projectsync:boolean=false;
  public bouwappValidation:any;
  public compair=[];
  public arreadyExist:boolean=false;
  public mainprojectExist:boolean=false;
  public mainProject=[];
  public dialogProjects=[];
  public bouwappProjects=[];
  public subprojects=[];
  public bouwsubprojects=[];
  public updateBouwAppProjects=[];
  meldingDisable:boolean=false;
  public connectionSelect=[
    {
      id:0,
      value:translate("settings.bouwapp.tickets"),
      enableKey:'services.integration.bouwapp.complaints.enabled'
    },
    {
      id:1,
      value:translate("settings.bouwapp.updates"),
      enableKey:'services.integration.bouwapp.contents.enabled'
    },
    {
      id:2,
      value:translate("settings.bouwapp.smart_reporting"),
      enableKey:'services.integration.bouwapp.smart_reporting.enabled'
    }
  ];
  public bouwappsyncSelection:any=[];
  public previousBouwappsyncSelection: any=[];
  private serviceKey='services.integration.bouwapp.enabled'
  public isProcess: boolean = false;
  public bouwappenable:boolean=false;
  public smartReportingEnabled:boolean = false;
  public smartReportingAs: any;
  public level:any='';
  public projectname:any;
  constructor(
    public _subproject: SubprojectsService,
    private location: Location,
    public _projectConfigsService: ProjectConfigsService,
    private cd: ChangeDetectorRef,
    public pubsub: PubsubService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    public globals: Globals,
    private projectsService: ProjectsService,
    private _route: Router) {
    super(globals, _projectConfigsService);
   }

  ngOnInit() {
    if(this._route.url.includes('beheer')){
      this.level='company';
      const projectName = this._route.url.split("/")[2];
      this.projectsService.getProjectByCode(projectName).subscribe((response: any) => {
        if (response) {
          this.projectname=response.name;
        }
      })
    }
    this.bouwappenable=this.globals.projectConfigs['services.integration.bouwapp.enabled'];
    this.errorMessage=this.globals.projectConfigs['services.integration.bouwapp.connection_error'];
    if(!this.errorMessage){
      this.getDialogProject();
      this.getBouwAppProject();
      this.valueShow();
      this.onloadMainProjectShow();
    }
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  public valueShow(){
    if(this.globals.projectConfigs['services.integration.bouwapp.complaints.enabled'] || this.globals.projectConfigs['services.integration.bouwapp.contents.enabled'] || this.globals.projectConfigs['services.integration.bouwapp.smart_reporting.enabled']){
      this.bouwappsyncSelection=[];
      this.connectionSelect.forEach(element => {
        if(this.globals.projectConfigs[element.enableKey]){
          this.bouwappsyncSelection.push(element);
        }
      });
      this.smartReportingEnabled = this.bouwappsyncSelection.some(obj => obj.enableKey === 'services.integration.bouwapp.smart_reporting.enabled');
      if (this.smartReportingEnabled) {
        const key = 'services.integration.bouwapp.smart_reporting.switch';
        if (this.globals.projectConfigs[key] === 'requirements' || this.globals.projectConfigs[key] === 'complaints') {
          this.smartReportingAs = this.globals.projectConfigs[key];
        } else {
          this.smartReportingAs = 'complaints'
        }

      }
      this.previousBouwappsyncSelection = [...this.bouwappsyncSelection];
    }
  }

  redirectURL(){
    if(this.bouwappenable){
      this.location.replaceState(`/${this.level}` == 'company' ? 'beheer/' : `${this.level}` == 'company'? this.projectname : `${this.globals.projectCode}/instellingen/integraties`);
      this.bouwappenable=false;
      this.globals.projectConfigs[this.serviceKey]=false;
      this.setConfig(this.serviceGroup, () => {
        this.dialogRef.close(true);
        showFeedbackSaved();
      });
      this.globals.projectConfigs['services.integration.bouwapp.user']='0';
      this.globals.user.configs.bouwapp_enabled=0;
      this.globals.projectConfigs['services.integration.bouwapp.enabled']=false;
    }else{
      localStorage.setItem('redirectType', 'bouwapp');
      localStorage.setItem('state', this.level == 'company'? this.projectname : this.globals.projectCode);
      window.open(`${this.globals.bouwapp_url}/auth/authorize?client_id=${this.globals.clientIdBouwapp}&response_type=code&redirect_uri=${window.location.protocol }//${ window.location.host }/connect/bouwapp`)
    }
  }

  public getDialogProject(){
    if(this.bouwappenable){
      this._subproject.get().subscribe((res:any)=>{
        this.dialogProjects=res;
        this.projectValidation();
        this.enableProjectShow(this.dialogProjects);
       });
    }
  }

  public getBouwAppProject(){
    if(this.bouwappenable){
      this.isProcess=true;
      this._projectConfigsService.getBouwAppProjects().subscribe((res:any)=>{
        this.bouwappProjects=res;
        this.isProcess=false;
      });
    }
  }

  public projectSet(id,bProjects){
    if(bProjects.length==0){
      this.subProjectAdd(id, true);
    }else{
      this.subProjectAdd(id);
    }
  }

  public subProjectAdd(dialogID,action=false){
    const self=this;
    this.dialogProjects.map((element,key)=>{
      if(action){
        if(element.id==dialogID){
          element.tenant_id=null
          element.tenant_code=null;
          element.tenant_name=null;
          element.tenant_type=null;
          this.updateBouwAppProjects.push(element);
        }
      }else{
        if(element.id==dialogID){
          this.bouwappValidation={id:self.bouwsubprojects[key][0].id,slug:self.bouwsubprojects[key][0].slug,name:self.bouwsubprojects[key][0].name}
          this.validation();
          element.tenant_id=self.bouwsubprojects[key][0].id;
          element.tenant_code=self.bouwsubprojects[key][0].slug;
          element.tenant_name=self.bouwsubprojects[key][0].name;
          element.tenant_type='bouwapp';
          if(!this.arreadyExist){
            this.updateBouwAppProjects.push(element);
          }
        }
        if(self.bouwsubprojects[key]){
          if(self.bouwsubprojects[key][0].id){
            this.updateBouwAppProjects.push(element);
          }
        }
      }
    });
    if(!this.arreadyExist){
    this._projectConfigsService.putBouwAppProjects(this.updateBouwAppProjects).subscribe(((res:any)=>{
      this.dialogProjects=res;
      showFeedbackSaved();
    }));
  }else{
    this.arreadyExist=false;
  }
  }

  public enableProjectShow(subprojects){
    subprojects.map((element,key)=>{
      if(element.tenant_id){
        this.bouwsubprojects[key]=[
          {
            id:element.tenant_id,
            name:element.tenant_name,
            slug:element.tenant_code
          }
        ]
      }
    });
    this.globals.cdRef.detectChanges();
  }

  public syncBouwAppProject(event: any){
    const enableKey = 'services.integration.bouwapp.smart_reporting.enabled';
    const newlyAdded = this.bouwappsyncSelection.filter(
      (feature) => !this.previousBouwappsyncSelection.includes(feature)
    );

    this.previousBouwappsyncSelection = [...this.bouwappsyncSelection];
    if (newlyAdded.length && newlyAdded[0].enableKey === enableKey) {
      const data = {
        title: translate('select_option'),
        body: translate('settings.bouwapp.smart_reporting_message'),
        feature: "bouwapSmartReporting",
        type: ''
      }
      const dialog = this.dialog.open(MessageConfirmComponent, modalConfig({
        data:data,
        panelClass: ['animate__animated', 'animate__slideInUp'],
        disableClose: true,
        closeOnNavigation: true
      }, ModalEnum.ModalSmall));
      dialog.afterClosed().subscribe(res => {
        if(res === 'complaints' || res === 'requirements') {
          const key = 'services.integration.bouwapp.smart_reporting.switch';
          this.smartReportingEnabled = true;
          this.smartReportingAs = res;
          this.globals.projectConfigs[key]= res;
          this.updateSyncBouwappProject();
        } if (!res) {
          // remove selected item if user cancel the modal
          this.bouwappsyncSelection = this.bouwappsyncSelection.filter(obj => obj.enableKey !== 'services.integration.bouwapp.smart_reporting.enabled');
          this.previousBouwappsyncSelection = [...this.bouwappsyncSelection];
        }
      })
    } else {
      this.updateSyncBouwappProject();
    }
  }

  updateSyncBouwappProject() {
    const selection=[];
    this.connectionSelect.forEach(element => {
      if(this.bouwappsyncSelection.length==0){
        this.globals.projectConfigs[element.enableKey]=false;
       }
       if(this.bouwappsyncSelection.length > 0){
        this.bouwappsyncSelection.forEach(element => {
          selection.push(element.value);
        });
        this.smartReportingEnabled = this.bouwappsyncSelection.some(obj => obj.enableKey === 'services.integration.bouwapp.smart_reporting.enabled');
        if (this.smartReportingEnabled) {
          const key = 'services.integration.bouwapp.smart_reporting.switch';
          this.globals.projectConfigs[key] = this.smartReportingAs;
        }
       if(selection.includes(element.value)){
        this.globals.projectConfigs[element.enableKey]=true;
       }else{
        this.globals.projectConfigs[element.enableKey]=false;
       }
      } else {
        this.smartReportingEnabled = false;
      }
    });
    this.configsSetup();
  }

  public configsSetup(){
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        showFeedbackSaved();
      });
  }

  public mainProjectCall(){
    if(this.mainProject.length>0){
      this.validation(true);
      if(!this.mainprojectExist){
      this.globals.projectConfigs['services.integration.bouwapp.project.id']=this.mainProject[0].id;
      this.globals.projectConfigs['services.integration.bouwapp.project.name']=this.mainProject[0].name;
      this.globals.projectConfigs['services.integration.bouwapp.project.code']=this.mainProject[0].slug;
      this.configsSetup();
      }else{
        this.mainprojectExist=false;
      }
    }else{
      this.globals.projectConfigs['services.integration.bouwapp.project.id']=null;
      this.globals.projectConfigs['services.integration.bouwapp.project.name']=null;
      this.globals.projectConfigs['services.integration.bouwapp.project.code']=null;
      this.configsSetup();
    }
  }

  public onloadMainProjectShow(){
    if(this.globals.projectConfigs['services.integration.bouwapp.project.id']){
      this.mainProject=[{
        id:this.globals.projectConfigs['services.integration.bouwapp.project.id'],
        name:this.globals.projectConfigs['services.integration.bouwapp.project.name'],
        slug:this.globals.projectConfigs['services.integration.bouwapp.project.code'],
      }]
    }
  }

  public projectValidation(){
    if(this.globals.projectConfigs['services.integration.bouwapp.project.id']){
      this.compair[0]={
        id:this.globals.projectConfigs['services.integration.bouwapp.project.id'],
        name:this.globals.projectConfigs['services.integration.bouwapp.project.name'],
        slug:this.globals.projectConfigs['services.integration.bouwapp.project.code']
      }
    }
    this.dialogProjects.forEach((element,key)=>{
      this.compair[key+1]={id:element.tenant_id,name:element.tenant_name,slug:element.tenant_code}
    });
  }

  public validation(action=false){
    this.projectValidation();
    if(this.compair.length==0){
      this.arreadyExist=false;

    }else{
      if(!action){
        this.compair.forEach((el)=>{
          if(el.id==this.bouwappValidation.id){
            this.arreadyExist=true;
            this.mainprojectExist=true;
            showFeedback('Het project is al geselecteerd', 'error');
          }
        })
      }else{
        this.compair.forEach((el)=>{
          if(el.id==this.mainProject[0].id){
            this.arreadyExist=true;
            this.mainprojectExist=true;
            showFeedback('Het project is al geselecteerd', 'error');
          }
        })
      }
    }
  }
}
