<div #container infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="250"
     (scrolled)="onScrollDown($event)">

  <mat-progress-bar *ngIf="!logsLoaded" mode="indeterminate"></mat-progress-bar>

  <!--<div *ngIf="logsLoaded && logs.data.length === 0">Nog geen contactmomenten</div>-->
    <div *ngIf="logsLoaded && logs.data.length === 0"class="empty-oc-height-set w-100 text-center empty-padding">
        <div>
            <img src="assets/png/empty-state-no-contactmoments.png" class="empty-state-image">
            <h2>{{'dashboard.nodata.contactmoments_header' | transloco}}</h2>
              <div *hasPermission="[Permission.LogsCreate]">
                <p class="pb-10">{{'dashboard.nodata.contactmoments_description' | transloco}}</p>
                  <div class="btn-group inline-block" *hasPermission="[Permission.LogsCreate]" >
                    <a class="d-cursor-hand" (click)="addLog()">
                        <mat-icon class="d-text-middle width-auto">forum</mat-icon>{{'logs.label' | transloco | capitaliz}}
                    </a>
                  </div>
              </div>
        </div>
    </div>

  <mat-accordion *ngIf="showLogs">
    <mat-expansion-panel *ngFor="let log of logs.data; trackBy: trackByFn" [disabled]="!log.description">
      <mat-expansion-panel-header class="pl-10 pr-10" collapsedHeight="63px" expandedHeight="63px" [ngClass]="{'initial-cursor': !log.description}">

        <mat-panel-title class="row inline-flex">
          <div class="log-type-div">
            <mat-icon class="log_type-icon" *ngIf="log.contact_moment_type === 'email'" matTooltip="Email">email</mat-icon>
            <mat-icon class="log_type-icon" *ngIf="log.contact_moment_type === 'phone'" matTooltip="Telefoon">phone</mat-icon>
            <mat-icon class="log_type-icon" *ngIf="log.contact_moment_type === 'meeting'" matTooltip="Meeting">group</mat-icon>
          </div>
          <div class="log-stakeholders-div">
            <div class="col-xs-12 log-date-div">
              {{ log.contact_moment_timestamp | formatdate : 'dd MMM yyyy' : globals.timezone }} {{ log.title ? ': ' + log.title : '' }} <i>{{ log.series && log.series[0] ? '- ' + log.series[0].name : '' }}</i>
            </div>
            <div class="col-xs-12 log-stakeholders-row-div">
              <span class="line-height-2em">
                <ng-container class="log-stakeholders-div-ellipsis" *ngIf="log.stakeholders && log.stakeholders.length > 0">
                  <a *ngFor="let stakeholder of log.stakeholders; let first = first; let last = last; trackBy: trackByFn" [projectRouterLink]="['stakeholders', stakeholder.code]" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="last && log.stakeholders.length > 1"> en </ng-container>
                    <ng-container *ngIf="!first && !last">, </ng-container>
                    {{ stakeholder.name }}<ng-container *ngIf="last">.</ng-container>
                  </a>
                </ng-container>
              </span>
            </div>
            <div class="col-xs-12 log-stakeholders-row-div">
              <span class="line-height-2em">
                <ng-container class="log-stakeholders-div-ellipsis" *ngIf="log.residents && log.residents.length > 0">
                  <a *ngFor="let resident of log.residents; let first = first; let last = last; trackBy: trackByFn" [projectRouterLink]="['omwonenden', resident.id]" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="last && log.residents.length > 1"> en </ng-container>
                    <ng-container *ngIf="!first && !last">, </ng-container>
                    {{ resident.name }}<ng-container *ngIf="last">.</ng-container>
                  </a>
                </ng-container>
              </span>
            </div>
          </div>

          <!-- contactmoment relations icons -->
          <div class="log-edit-buttons-div" [ngClass]="{'mr-8': !log.description}">
            <a class="d-cursor-hand pull-right" (click)="deleteLog(log, $event)">
              <mat-icon matTooltip="Verwijder contactmoment" class="contacts-card-edit ml-10 d-cursor-hand">delete</mat-icon>
            </a>

            <a class="d-cursor-hand pull-right" (click)="editLog(log, $event)">
              <mat-icon matTooltip="Wijzig contactmoment" class="contacts-card-edit ml-10 d-cursor-hand">edit</mat-icon>
            </a>

            <a class="d-cursor-hand pull-right" (click)="downloadLog(log.id, $event)">
              <mat-icon matTooltip="Genereer verslag van contactmoment" class="contacts-card-edit ml-10 d-cursor-hand">arrow_downward</mat-icon>
            </a>

            <span class="pull-right ml-10 d-cursor-auto" [matTooltip]="log.users.name">
              <ngx-avatars
                [name]="log.users.name"
                size="20"
                textSizeRatio="2.5"
                borderColor="#ebebeb"
                bgColor="#e3e3e3"
                fgColor="#0f0f0f">
              </ngx-avatars>
            </span>

            <span *ngIf="log.documents.length > 0 || log.decisions.length > 0 || log.activities && log.activities.length > 0 || log.complaints.length > 0 || log.issues.length > 0 || log.actions.length > 0" class="icon-divider d-cursor-auto pull-right ml-10 mr-10">
              |
            </span>

            <span class="pull-right" *ngIf="log.documents.length > 0">
              <mat-icon class="icon-full ml-10 contacts-card-edit icon-blue" matTooltip="Document(en)" (click)="showConnectionInfo('documents', log, $event)"
                        [popper]="popperContent"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        popperTrigger="click"
                        popperPlacement="right"
                        popperApplyClass="popper-dialog"
                        popperDisableStyle="true">
                attach_file
              </mat-icon>
            </span>

            <span class="pull-right" *ngIf="log.decisions.length > 0">
              <mat-icon class="icon-full ml-10 contacts-card-edit icon-blue" matTooltip="klanteis(en)" (click)="showConnectionInfo('decisions', log, $event)"
                        [popper]="popperContent"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        popperTrigger="click"
                        popperPlacement="right"
                        popperApplyClass="popper-dialog"
                        popperDisableStyle="true">
                gavel
              </mat-icon>
            </span>

            <span class="pull-right" *ngIf="log.activities && log.activities.length > 0">
              <mat-icon class="icon-full ml-10 contacts-card-edit icon-blue" matTooltip="Activiteit(en)" (click)="showConnectionInfo('activities', log, $event)"
                        [popper]="popperContent"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        popperTrigger="click"
                        popperPlacement="right"
                        popperApplyClass="popper-dialog"
                        popperDisableStyle="true">
                calendar_today
              </mat-icon>
            </span>

            <span class="pull-right" *ngIf="log.complaints.length > 0">
              <mat-icon class="icon-full ml-10 contacts-card-edit icon-blue" matTooltip="Melding(en)" (click)="showConnectionInfo('complaints', log, $event)"
                        [popper]="popperContent"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        popperTrigger="click"
                        popperPlacement="right"
                        popperApplyClass="popper-dialog"
                        popperDisableStyle="true">
                feedback
              </mat-icon>
            </span>

            <span class="pull-right" *ngIf="log.issues.length > 0">
              <mat-icon class="icon-full ml-10 contacts-card-edit icon-blue uil uil-puzzle-piece" matTooltip="Issue(s)" (click)="showConnectionInfo('issues', log, $event)"
                        [popper]="popperContent"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        popperTrigger="click"
                        popperPlacement="right"
                        popperApplyClass="popper-dialog"
                        popperDisableStyle="true">
              </mat-icon>
            </span>

            <span class="pull-right" *ngIf="log.actions.length > 0">
              <mat-icon class="icon-full ml-10 contacts-card-edit icon-blue" matTooltip="Actie(s)" (click)="showConnectionInfo('actions', log, $event)"
                        [popper]="popperContent"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        popperTrigger="click"
                        popperPlacement="right"
                        popperApplyClass="popper-dialog"
                        popperDisableStyle="true">
                assignment
              </mat-icon>
            </span>


          </div>

        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [innerHTML]="log.description | safeHtml" class="preserve-white-space"></div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Put outside the loop to reduce DOM elements -->
  <popper-content #popperContent>
    <mat-card>
      <mat-list *ngIf="selectedPopoverType === 'actions'">
        <h5 class="ml-15">Actie(s)</h5>
        <mat-list-item *ngFor="let action of selectedPopoverConnection; trackBy: trackByFn">
          <a class="pr-20" mat-line [projectRouterLink]="['acties', action.id]">{{ action.text | truncatie: 30 }}</a>
          <span class="list-info">{{ action.timestamp | formatdate : 'dd-MM-yyyy' : globals.timezone }}</span>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="selectedPopoverType === 'issues'">
        <h5 class="ml-15">Issue(s)</h5>
        <mat-list-item *ngFor="let issue of selectedPopoverConnection; trackBy: trackByFn">
          <a class="pr-20" mat-line [projectRouterLink]="['issues', issue.code]">{{ issue.name }}</a>
          <span class="list-info">{{ issue.issues_date | formatdate : 'dd-MM-yyyy' : globals.timezone }}</span>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="selectedPopoverType === 'complaints'">
        <h5 class="ml-15">Melding(en)</h5>
        <mat-list-item *ngFor="let complaint of selectedPopoverConnection; trackBy: trackByFn">
          <a class="pr-20" mat-line [projectRouterLink]="['meldingen', complaint.id]">{{ complaint.text | truncatie: 30 }}</a>
          <span class="list-info">{{ complaint.date | formatdate : 'dd-MM-yyyy' : globals.timezone }}</span>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="selectedPopoverType === 'activities'">
        <h5 class="ml-15">Activiteit(en)</h5>
        <mat-list-item *ngFor="let activity of selectedPopoverConnection; trackBy: trackByFn">
          <a class="pr-20" mat-line [projectRouterLink]="['communicatiekalender', 'activiteit', activity.calendars[0].id]">{{ activity.name | truncatie: 30 }}</a>
          <span class="list-info">{{ activity.date | formatdate : 'dd-MM-yyyy' : globals.timezone }}</span>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="selectedPopoverType === 'decisions'">
        <h5 class="ml-15">klanteis(en)</h5>
        <mat-list-item *ngFor="let decision of selectedPopoverConnection; trackBy: trackByFn">
          <a class="pr-20" mat-line [projectRouterLink]="['klanteisen', decision.id]">{{ decision.title | truncatie: 30 }}</a>
          <span class="list-info">{{ decision.start_date | formatdate : 'dd-MM-yyyy' : globals.timezone }}</span>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="selectedPopoverType === 'documents'">
        <h5 class="ml-15">Document(en)</h5>
        <mat-list-item *ngFor="let document of selectedPopoverConnection; trackBy: trackByFn">
          <a class="pr-20 d-cursor-hand" mat-line [versiondownload]="document">{{ document.name }}</a>
          <span class="list-info">{{ document.size | bytes }}</span>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </popper-content>

</div>
