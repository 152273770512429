import { Injectable } from '@angular/core';
import {AbstractService} from "../common/abstract.service";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {map} from "rxjs/operators";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {Location} from '../../../akita/locations/state/location.model';

export interface ReportResponse {
  filename: string;
  body: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  /**
   * Download report. Returns
   * @param type Type of the report. e.g. management, logbook, issue_overview
   * @param format word,excel,pdf
   */
  @ServiceErrorHandler()
  download(type: string, format: string, options: any = {},notes=0,handled=false,filterParam?,relations=[]) {
    if (type === 'stakeholder' && format === 'spreadsheet') {
      return this.downloadStakehoder((<any>options).stakeholders,filterParam);
    } else if (type === 'logbook' && format === 'spreadsheet') {
      return this.downloadLogbook(options);
    } else if ((type === 'complaint_overview' || type === 'complaint') && format === 'spreadsheet') {
      return this.downloadComplaintsSpreadsheet(filterParam);
    } else if (type === 'analysis_overview' && format === 'spreadsheet') {
      return this.downloadAnalyasisSpreadSheet(filterParam);
    }else if ((type === 'issue_overview' || type === 'issue') && format === 'spreadsheet') {
      return this.downloadIssuesSpreadsheet(filterParam);
    } else if (type === 'decision_overview' && format === 'spreadsheet') {
      return this.downloadDecisionsSpreadsheet(filterParam);
    } else if (type === 'permit_overview' && format === 'spreadsheet') {
      return this.downloadPermitsSpreadsheet(filterParam);
    } else if (type === 'project') {
      return this.downloadProject();
    } else if (type === 'calendar') {
      return this.downloadCalendar();
    }else if (type === 'stakeholder_overview' && format === 'spreadsheet') {
      return this.downloadStakeholderoverview(filterParam);
    }else if (type === 'multipolygon_to_email') {
      return this.downloadEmailSpreadsheet(options);
    }else if (type === 'action_per_item' && format === 'spreadsheet' ) {
      return this.downloadActionPerItemSpreadsheet();
    }
    if(handled){
      const relation=[];
      if(relations.length>0){
        relations.forEach(element => {
          relation.push(element.name)
        });
      }
      return this.http.post(`/report?type=${format}&content=${type}&notes=${notes}&handled=${handled}`, options, {
        params: filterParam,
        responseType: 'blob',
        observe: 'response',
      }).pipe(
        map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
      );
    }
      return this.http.post(`/report?type=${format}&content=${type}&notes=${notes}`, options, {
        params: filterParam,
        responseType: 'blob',
        observe: 'response',
      }).pipe(
        map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
      );

  }

  private downloadStakehoder(stakeholders = [], filterParam?) {
    let params = new HttpParams()
      .set('options[0]', 'algemeen')
      .set('options[1]', 'logenacties')
      .set('options[2]', 'issues')
      .set('options[3]', 'klantwens');

    for (let i = 0; i < stakeholders.length; i++) {
      params = params.append(`ids[${i}]`, stakeholders[i]);
    }
    if(filterParam){
      params = params.appendAll(filterParam);
    }
    return this.http.get(`/file/export/stakeholder`, {
      params: params,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadLogbook(options: {
    stakeholders: any[],
    offset: any,
  }) {
    return this.http.post(`/file/export/stakeholder/logbook`, options, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadProject() {
    return this.http.get(`/file/export/project`, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadCalendar() {
    return this.http.get(`/file/export/calendar`, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }
  private downloadActionPerItemSpreadsheet(){
    return this.http.get(`/file/export/actions_per_item`, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }
  private downloadStakeholderoverview(filterParam?) {
    return this.http.get(`/file/export/stakeholder-overview`, {
      params: filterParam,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }


  private downloadComplaintsSpreadsheet(filterParam?) {
    return this.http.get(`/file/export/complaint`, {
      params: filterParam,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadAnalyasisSpreadSheet(filterParam?) {
    return this.http.get(`/file/export/analysis-overview`, {
      params: filterParam,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    )
  }

  private downloadIssuesSpreadsheet(filterParam?) {
    return this.http.get(`/file/export/issue`, {
      params: filterParam,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadDecisionsSpreadsheet(filterParam?) {
    return this.http.get(`/file/export/decision`, {
      params: filterParam,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadPermitsSpreadsheet(filterParam?) {
    return this.http.get(`/file/export/permit`, {
      params: filterParam,
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private downloadEmailSpreadsheet(location: Location) {
    return this.http.post(`/file/export/emailaddresses`, location, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => ReportService.mapReportResponse(response))
    );
  }

  private static mapReportResponse(response: HttpResponse<any>): ReportResponse {
    const disposition = response.headers.get('content-disposition');
    let filename = 'Report';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return <ReportResponse> {
      filename: filename,
      body: response.body
    };
  }
}
