import {Component, Input} from '@angular/core';
import { translate } from '@jsverse/transloco';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.sass']
})
export class ShowMoreComponent {
  expanded = false;
  @Input() public text: string = '';
  @Input() public expandText: string = translate('text.show_more');

}
