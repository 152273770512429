import {ChangeDetectorRef, Injectable} from "@angular/core";
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {HttpRequest} from '@angular/common/http';
import {ProjectConfigs} from './akita/project-configs/state/project-configs.model';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  public initialized = new ReplaySubject<Globals>(1);
  public subProjectInitialized = new BehaviorSubject(false);
  projectCode: string;
  project: any;
  projects: any;
  subprojects: any;
  modules: any[];
  company: any;
  hasModule: any = {};
  moduleConfig: any = {};
  fbNotificationBasePath: string;
  user: {
    id: number,
    global_id:number,
    name: string,
    email: string,
    configs: any,
    permissions: string[],
    isAdmin: boolean,
    scope:any,
    personalization:any,
    is_mfa_enabled:boolean,
    is_sso_enabled:boolean
  };
  warningsActive:boolean=false;
  permissions:string[]=['write'];
  freshdesk_users:any[]=[]; // freshdesk user indentify
  subprojectsPermission:any={};
  headUser: any;
  adminPage: boolean
  projectConfig: any={}; // This is taken from main database
  projectConfigs: ProjectConfigs; // This is taken from tenant database
  enableLayers:any[];
  featureLayersProcessID:number;
  featuresLayers:any;
  features_Exist:boolean = false;
  arcgisToken:string;
  relaticsColumn:any;
  personalization: any;
  customer_personalization: any;
  project_personalization: any;
  cdRef: ChangeDetectorRef;
  isDevelopment = false;
  googleMapApiKey = 'AIzaSyBODIAwkW1fi8Z0n2yyUt2bajHZqJqc1ag';
  googleMapLibLoaded = false;
  relaticsBlueprints = [];
  relaticsMenus = [];
  clientId:string;
  clientIdBouwapp:string;
  clientsecretBouwapp:string;
  whatsapppConfigId: any;
  whatsapppAppId: any;
  bouwapp_url:string;
  accessToken:string;
  timezone = 'Europe/Amsterdam';
  language = 'nl';
  locale = 'nl-NL';
  countryCode: string = 'nl'
  inAppNotification:any;
  lastRequest: HttpRequest<any>;
  skipSubprojectModal: boolean = true;
  nederlandMap = "355212.0265278738,6540361.73207577,978326.6811086306,6906036.475392054"

  public loadStubs() {
    this.project = {"code":"PROTOTYPE","name":"Prototype","description":"Test project for development","users_id":1,"projects_id":1,"acl_head_user":1,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1, "days_left":100};
    this.projects = [{"code":"PROTOTYPE","name":"Prototype","description":"Test project for development","users_id":1,"projects_id":1,"acl_head_user":1,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1},{"code":"HOGEWEIDE","name":"Demo Hoge Weide","description":"Omgeving Hoge Weide","users_id":1,"projects_id":37,"acl_head_user":1,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1},{"code":"HOLLANDSSPOOR","name":"Demo Hollands Spoor","description":"Ontwikkelingen Hollands Spoor gemeente Den Ha","users_id":1,"projects_id":38,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1},{"code":"A9GAASPERDAMMERWEG","name":"A9GAASPERDAMMERWEG","description":"A9GAASPERDAMMERWEG","users_id":1,"projects_id":40,"acl_head_user":1,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1},{"code":"IMPORT","name":"Import","description":"Import test","users_id":1,"projects_id":42,"acl_head_user":1,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1}];
    this.modules = [{"projects_id":1,"modules_id":1,"config":"","module_id":"report","module_name":"Reporting"},{"projects_id":1,"modules_id":2,"config":"{\n\t\"mapping\" : {\n\t\t\"demands_text_concept\" : \"Concept_eistekst\",\n\t\t\"demands_text_final\" : \"Definitieve_eistekst\",\n\t\t\"guid\" : \"ID_Klanteis\",\n\t\t\"id\" : \"ID\",\n\t\t\"name\" : \"Klanteis\",\n\t\t\"status\" : \"Status\"\n\t},\n\t\"relaticsDataExchangeUrl\" : \"https:\/\/flevoland.relaticsonline.com\/DataExchange.asmx?WSDL\",\n\t\"relaticsEntryCode\" : \"Peeenstekers4Ever\",\n\t\"relaticsEnvironmentId\" : \"3da65e82-f9f1-4c58-96ea-4661613dad04\",\n\t\"relaticsDialogElementId\" : \"0ba671d3-8248-e811-a2c2-00155d641e00\",\n        \"relaticsDialogRelationId\": \"3f135af3-8248-e811-a2c2-00155d641e00\",\n        \"relaticsDialogCommentProperty\": \"9cd9dbdf-8248-e811-a2c2-00155d641e00\",\n        \"relaticsDialogIdProperty\": \"9ed9dbdf-8248-e811-a2c2-00155d641e00\",\n        \"relaticsDialogLinkProperty\": \"a0d9dbdf-8248-e811-a2c2-00155d641e00\",\n\t\"relaticsOperationName\" : \"DialogKE\",\n\t\"relaticsSoapApiUrl\" : \"https:\/\/flevoland.relaticsonline.com\/api\/relaticsapi.asmx?WSDL\",\n\t\"relaticsTokenPassword\" : \"De Jongste Provincie\",\n\t\"relaticsTokenUsername\" : \"Dialog\",\n\t\"relaticsWorkspaceId\" : \"7697900a-5725-4308-913d-182bf2ba5520\",\n\t\"relaticsExcludeWithStatuses\" : [\"Klanteis vervallen\"]\n}","module_id":"relatics","module_name":"Relatics"},{"projects_id":1,"modules_id":4,"config":"","module_id":"communication","module_name":"Communication"},{"projects_id":1,"modules_id":5,"config":"{\n\t\"filename\": \"RWS-#{reference}.DOC.DRF\",\n\t\"content\": \"Document;RWS;{reference};R\"\n}","module_id":"edocs","module_name":"eDOCS"}];
    this.fbNotificationBasePath = "/dialog/notification/PROTOTYPE/chrisnu_-_at_-_outlook--_dot_--com";
    this.user = {
      id: 1,
      global_id:4,
      name: 'Christophorus',
      email: 'chrisnu@outlook.com',
      configs: {"id":7,"users_id":1,"config":null,"email_notification_enabled":1},
      permissions: [
        'write'
      ],
      isAdmin: true,
      scope:{},
      personalization:{},
      is_mfa_enabled: false,
      is_sso_enabled: false
    };
    this.headUser = {"id":1,"name":"Christophorus","email":"chrisnu@outlook.com","created_at":"2017-02-14 18:08:19","updated_at":"2017-04-09 05:14:54","users_id":1,"projects_id":1,"acl_stakeholder_view":1,"acl_stakeholder_edit":1,"acl_issue_view":1,"acl_issue_edit":1,"acl_resident_view":1,"acl_resident_edit":1,"acl_head_user":1};
    this.projectConfig = {"id":1,"projects_id":1,"default_map":"355212.0265278738,6540361.73207577,978326.6811086306,6906036.475392054"};
    this.personalization = {"css":".d-sidebar .left_col, .d-sidebar.left_col, .d-sidebar .nav_title, .nav-sm ul.nav.child_menu, .main_container {background: #154b60;}","logo":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAACWCAYAAAACCNzpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABI9JREFUeNrs3YFx2jAUxnGLCbxBGYER6ASlE9SZoGxQOkHaCZxOkG5AOgFsAJ0ANqAmda4psbGf9WTZj/9358ull9iuf0jIz1JIEkIIIYQQQgghhBBCCCGEEEIIIYQQMpK4MZzk6XSaFl+mzyfs3BNsI0sBmBbb+vR/DsW24uqMB3FWotUl5yqNHxFMQ4hgGkIE0xAimIYQwTSECKYhRDANIYJpCBFMQ4hgGkIE0xAimIYQwTSEaBrThUYsvqyLLfXYzb7YfhTbU/n9eZ8fim3usc8H59wdzay/lri8sv8FtdlxIGYtj0M3GwhxqoD4KDjeCswwkGuFAclMcLxU4XgZcrpd3XMivHg2Y7/2E+X9zRT2cYxwHWZA6ift6XdMRRvySamLXkgGVwotagvk6+qCc3slzM+BfrYuXxnhVI8iNwpjnmVPx7tHLTzm+R4xDXg87iF7xFxX3OI8XgJ3OB6IPWNeQs5f7v08MEGMgFkH2RUzF5z7udyYF9vu5VxuugrkiXkNsg6zrt4rQbxW+M/B1Iesw7yE0EIEsyNmG8gmTG1EMDtgtoWs7WYDIYIpxJRAVmIGRARTgCmFFD+WUprREBUz2tMP59z5cdX7JEzBeitBTPwniJ2T0TKvt0xpi8x7bol0sy0xJZCxEaNhDuLBslI3K52rui+3EKGbrWiZbVpkHqAnoJtVxmyClHanKZhxMK9BdnlP3IAZB7MO0mdgA2YEzEUFysIDEcwRvhCabjHANIDYhHl/uXShXAG2BnN4iJ0K7eWsATAHhgjmABF9l/RJRsKpUolPZV7txJjll8TvKcYvYVnxp8I5LyUPv28FcuH5+9La6++BnLc5yPRWz9sapO9DamkX924g521usLPUnNXe4ngag50Dg523A5Bvnq/uvQBxpdSVf6QJ1t8WbALfemRK5boMMV3MvOaetGopQg7iMDHzhurQutw0C+kgKmM2IYZIdqsQ+cW/zasWunbABLHv1lQBKSpyV2CCGKNLrIHsiglirPe1K5CdF+2AGGFw0gAZawUWiNIRZgvILg+Gp5YQJ0NBTP6uiPL5U2TPq6oEmMck3JKBO+fcAy2xW4s8KYxm6U5DVl+EkDExoyFORt6d1mVbTsVojOKCW7pT5RYZY2UW3akyZN3NfshuFkRlyGsVm1DvmSAqQ7Ypu2ljgqgMKamdSjEfq+bYSFaDgdgOsksBXFoBmpWTulblIp4URF1In6cYm5sEiYlYzlZ7UydVKICD2RPi4dTyo5X6WoEFYrfMWx7P9ykGmDUXVmO6oHTG9451ivqQvRefy2Xivhl1q5woI86VdrUX/rzG8rYZkP9yjPT/SEd87sODdM5tlS6ItHX4Lm87lucO5KtofGDYJ8koOfFf8fud0U24kWvbDzm7Z8Q6fMys4RhLEMeDmQf6C1QgRsB8yU5pPyAOABNEMEG0gAmiAUwQDWCCaAATRAOYIBrABNEAJogWynlcyfFjgmgAE0QDmCAawATRACaII8PMLp5D7k78mUxCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQa/kjwACy5ECnyEAV9AAAAABJRU5ErkJggg=="};
    this.customer_personalization = {"css":".d-sidebar .left_col, .d-sidebar.left_col, .d-sidebar .nav_title, .nav-sm ul.nav.child_menu, .main_container {background: #154b60;}","logo":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAACWCAYAAAACCNzpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABI9JREFUeNrs3YFx2jAUxnGLCbxBGYER6ASlE9SZoGxQOkHaCZxOkG5AOgFsAJ0ANqAmda4psbGf9WTZj/9358ull9iuf0jIz1JIEkIIIYQQQgghhBBCCCGEEEIIIYQQMpK4MZzk6XSaFl+mzyfs3BNsI0sBmBbb+vR/DsW24uqMB3FWotUl5yqNHxFMQ4hgGkIE0xAimIYQwTSECKYhRDANIYJpCBFMQ4hgGkIE0xAimIYQwTSEaBrThUYsvqyLLfXYzb7YfhTbU/n9eZ8fim3usc8H59wdzay/lri8sv8FtdlxIGYtj0M3GwhxqoD4KDjeCswwkGuFAclMcLxU4XgZcrpd3XMivHg2Y7/2E+X9zRT2cYxwHWZA6ift6XdMRRvySamLXkgGVwotagvk6+qCc3slzM+BfrYuXxnhVI8iNwpjnmVPx7tHLTzm+R4xDXg87iF7xFxX3OI8XgJ3OB6IPWNeQs5f7v08MEGMgFkH2RUzF5z7udyYF9vu5VxuugrkiXkNsg6zrt4rQbxW+M/B1Iesw7yE0EIEsyNmG8gmTG1EMDtgtoWs7WYDIYIpxJRAVmIGRARTgCmFFD+WUprREBUz2tMP59z5cdX7JEzBeitBTPwniJ2T0TKvt0xpi8x7bol0sy0xJZCxEaNhDuLBslI3K52rui+3EKGbrWiZbVpkHqAnoJtVxmyClHanKZhxMK9BdnlP3IAZB7MO0mdgA2YEzEUFysIDEcwRvhCabjHANIDYhHl/uXShXAG2BnN4iJ0K7eWsATAHhgjmABF9l/RJRsKpUolPZV7txJjll8TvKcYvYVnxp8I5LyUPv28FcuH5+9La6++BnLc5yPRWz9sapO9DamkX924g521usLPUnNXe4ngag50Dg523A5Bvnq/uvQBxpdSVf6QJ1t8WbALfemRK5boMMV3MvOaetGopQg7iMDHzhurQutw0C+kgKmM2IYZIdqsQ+cW/zasWunbABLHv1lQBKSpyV2CCGKNLrIHsiglirPe1K5CdF+2AGGFw0gAZawUWiNIRZgvILg+Gp5YQJ0NBTP6uiPL5U2TPq6oEmMck3JKBO+fcAy2xW4s8KYxm6U5DVl+EkDExoyFORt6d1mVbTsVojOKCW7pT5RYZY2UW3akyZN3NfshuFkRlyGsVm1DvmSAqQ7Ypu2ljgqgMKamdSjEfq+bYSFaDgdgOsksBXFoBmpWTulblIp4URF1In6cYm5sEiYlYzlZ7UydVKICD2RPi4dTyo5X6WoEFYrfMWx7P9ykGmDUXVmO6oHTG9451ivqQvRefy2Xivhl1q5woI86VdrUX/rzG8rYZkP9yjPT/SEd87sODdM5tlS6ItHX4Lm87lucO5KtofGDYJ8koOfFf8fud0U24kWvbDzm7Z8Q6fMys4RhLEMeDmQf6C1QgRsB8yU5pPyAOABNEMEG0gAmiAUwQDWCCaAATRAOYIBrABNEAJogWynlcyfFjgmgAE0QDmCAawATRACaII8PMLp5D7k78mUxCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQa/kjwACy5ECnyEAV9AAAAABJRU5ErkJggg=="};
    this.project_personalization = {"css":".d-sidebar .left_col, .d-sidebar.left_col, .d-sidebar .nav_title, .nav-sm ul.nav.child_menu, .main_container {background: #154b60;}","logo":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAACWCAYAAAACCNzpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABI9JREFUeNrs3YFx2jAUxnGLCbxBGYER6ASlE9SZoGxQOkHaCZxOkG5AOgFsAJ0ANqAmda4psbGf9WTZj/9358ull9iuf0jIz1JIEkIIIYQQQgghhBBCCCGEEEIIIYQQMpK4MZzk6XSaFl+mzyfs3BNsI0sBmBbb+vR/DsW24uqMB3FWotUl5yqNHxFMQ4hgGkIE0xAimIYQwTSECKYhRDANIYJpCBFMQ4hgGkIE0xAimIYQwTSEaBrThUYsvqyLLfXYzb7YfhTbU/n9eZ8fim3usc8H59wdzay/lri8sv8FtdlxIGYtj0M3GwhxqoD4KDjeCswwkGuFAclMcLxU4XgZcrpd3XMivHg2Y7/2E+X9zRT2cYxwHWZA6ift6XdMRRvySamLXkgGVwotagvk6+qCc3slzM+BfrYuXxnhVI8iNwpjnmVPx7tHLTzm+R4xDXg87iF7xFxX3OI8XgJ3OB6IPWNeQs5f7v08MEGMgFkH2RUzF5z7udyYF9vu5VxuugrkiXkNsg6zrt4rQbxW+M/B1Iesw7yE0EIEsyNmG8gmTG1EMDtgtoWs7WYDIYIpxJRAVmIGRARTgCmFFD+WUprREBUz2tMP59z5cdX7JEzBeitBTPwniJ2T0TKvt0xpi8x7bol0sy0xJZCxEaNhDuLBslI3K52rui+3EKGbrWiZbVpkHqAnoJtVxmyClHanKZhxMK9BdnlP3IAZB7MO0mdgA2YEzEUFysIDEcwRvhCabjHANIDYhHl/uXShXAG2BnN4iJ0K7eWsATAHhgjmABF9l/RJRsKpUolPZV7txJjll8TvKcYvYVnxp8I5LyUPv28FcuH5+9La6++BnLc5yPRWz9sapO9DamkX924g521usLPUnNXe4ngag50Dg523A5Bvnq/uvQBxpdSVf6QJ1t8WbALfemRK5boMMV3MvOaetGopQg7iMDHzhurQutw0C+kgKmM2IYZIdqsQ+cW/zasWunbABLHv1lQBKSpyV2CCGKNLrIHsiglirPe1K5CdF+2AGGFw0gAZawUWiNIRZgvILg+Gp5YQJ0NBTP6uiPL5U2TPq6oEmMck3JKBO+fcAy2xW4s8KYxm6U5DVl+EkDExoyFORt6d1mVbTsVojOKCW7pT5RYZY2UW3akyZN3NfshuFkRlyGsVm1DvmSAqQ7Ypu2ljgqgMKamdSjEfq+bYSFaDgdgOsksBXFoBmpWTulblIp4URF1In6cYm5sEiYlYzlZ7UydVKICD2RPi4dTyo5X6WoEFYrfMWx7P9ykGmDUXVmO6oHTG9451ivqQvRefy2Xivhl1q5woI86VdrUX/rzG8rYZkP9yjPT/SEd87sODdM5tlS6ItHX4Lm87lucO5KtofGDYJ8koOfFf8fud0U24kWvbDzm7Z8Q6fMys4RhLEMeDmQf6C1QgRsB8yU5pPyAOABNEMEG0gAmiAUwQDWCCaAATRAOYIBrABNEAJogWynlcyfFjgmgAE0QDmCAawATRACaII8PMLp5D7k78mUxCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQa/kjwACy5ECnyEAV9AAAAABJRU5ErkJggg=="};
    this.isDevelopment = true;
  }
}
