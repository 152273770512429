import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Stakeholder } from './stakeholder.model';

export interface StakeholderState extends EntityState<Stakeholder>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stakeholders' })
export class StakeholdersStore extends EntityStore<StakeholderState, Stakeholder> {

  constructor() {
    super();
  }

}

