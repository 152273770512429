import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { AvatarNotificationCustomStyle } from 'src/app/core/enum/styles';
import { GeneralService } from 'src/app/core/service/api/general.service';
import { InAppChatService } from 'src/app/core/service/api/in-app-chat.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { openModal } from 'src/app/core/util/dashboardModalLogic';
import { Notifications, showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { setDefaultLanguage } from 'src/app/akita/multilingual/multilingual.model';
@Component({
  selector: 'app-in-app-notification',
  templateUrl: './in-app-notification.component.html',
  styleUrls: ['./in-app-notification.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InAppNotificationComponent {
  public AvatarNotificationCustomStyle = AvatarNotificationCustomStyle;
  @Input() notification:Observable<Notifications>;
  @Output() bouwappNotificationConfirmation: EventEmitter<any> = new EventEmitter();
  @Output() loadNotification: EventEmitter<any> = new EventEmitter();
  public integrationLevel;
  public language: any;
  errorMessage_userleve:boolean=false;
  // eslint-disable-next-line no-empty-function
  constructor(private cd: ChangeDetectorRef,private _integrationsService: IntegrationsService ,public _inAppChatService: InAppChatService,private _generalService: GeneralService,private globals: Globals,private projectConfigs:ProjectConfigsService,public dialog: MatDialog,private pubsub: PubsubService) { }
  ngOnInit(): void {
    this.language = setDefaultLanguage(this.globals,'user')
  }


  public updateBouwappMiddle(data,action){
    this._generalService.updateNotification(data,action).subscribe(res=>{
      this.loadNotification.emit();
      showFeedbackSaved();
    });
  }
  public bouwappNotificationConform(message){
    this.bouwappNotificationConfirmation.emit(message);
  }
  public middleRoute(route){
    if (route.id) {
      return `/${this.globals.projectCode}/communicatiekalender/middel/${route.id}`;
    }
    return `/${this.globals.projectCode}/communicatiekalender/middel`;
      
  }
  public nav(type,data,chat=false): void{
    openModal(type,data,this.dialog,this.pubsub,chat);
  }
}
