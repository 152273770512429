export function generateColor(string: string): string {
  let hash = 0;
  if (!string) {
    return '#000000';
  }
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += (`00${ value.toString(16)}`).substr(-2);
  }

  return color;
}
