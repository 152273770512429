import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { AbstractService } from "../common/abstract.service";
import { PaginatedResponse } from "../common/paginated-response";

@Injectable({
  providedIn: 'root'
})
export class UsersService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getUsers(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/users', this.httpOptions);
  }
}
