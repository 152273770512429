<ng-container *ngIf="globals.user.configs.microsoft"></ng-container>
<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
      <h1>
        <span class="">{{ t("settings.outlook.contact_moments_overview.personal_email_inbox") }}</span>
      </h1>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon> </span
        ><span class="sr-only">Close</span>
      </button>
    </div>
  </div>
  <div class="popup-body" appScroll>
    <div class="col-xs-12 mb-5">
      <!-- Search  -->
      <div class="map-search mb-5 mt-2">
        <!-- <input
          type="text"
          class="form-control universal-search ng-pristine ng-valid ng-touched"
          placeholder="{{'settings.outlook.contact_moments_overview.search_for_email' | transloco}}"
          [(ngModel)]="searchText"
          (input)="search($event.target.value)"
        />
        <div class="ng-star-inserted">
          <i *ngIf="loadingSearch" class="uil uil-spinner-alt uil-icon spinner-animation form-control-map-spinner"></i>
          <span *ngIf="!loadingSearch" class="uil uil-search form-control-map-search"></span>
        </div> -->
      </div>
      <mat-tab-group (selectedTabChange)="tabChange()">
        <mat-tab *ngFor="let tab of tabs" [label]="tab.label | transloco">
          <ng-template matTabContent>
              <div *ngIf="loader">
                <mat-progress-bar mode="buffer"></mat-progress-bar>
              </div>

              <ng-container *ngFor="let message of messages; let i = index">
                <div class="set-border-connection-relatic">
                  <div class="main full-width">
                    <div class="item">
                      <div class="timestamp">
                        <span class="date">{{
                          message.receivedDateTime | date : "dd-MM"
                        }}</span>
                        <span class="time">{{
                          message.receivedDateTime | date : "HH:mm"
                        }}</span>
                      </div>
                      <div class="user">
                        <span class="username">{{ message.sender.emailAddress.name }}</span>
                        <span class="action">{{ message.sender.emailAddress.address }}</span>
                      </div>
                    </div>
                    <div class="data" >
                      <div class="icons" style="display: flex;">
                        <i
                          class="uil uil-arrow-down unicon-icon d-text-middle"
                          style="color: black"
                          (click)="expandPanel(i)"
                        ></i>
                        <i
                        class="uil uil-plus-circle unicon-icon d-text-middle"
                         [class.disabled]="disabledStates[i]"
                         *ngIf="!loadingStates[i]"
                          matTooltip="{{ 'settings.outlook.contact_moments_overview.add_moment' | transloco | capitaliz }}"
                           (click)="createContactmoment(message, i)"></i>
                           <span *ngIf="loadingStates[i]" class="fa-spin loader" style="margin-top: 5px;"></span>
                      </div>
                    </div>
                  </div>
                  <div class="panel" *ngIf="showPanel === i">
                    <span class="subject">{{ message.subject }}</span>
                    <div class="body" [innerHTML]="message.bodyPreview"></div>

                  </div>
                </div>


              </ng-container>

              <div class="pagination-top">
                <mat-paginator class="table-pagination-set list-view-paginator pagination-border" [length]="paginator.total" [pageSize]="10" (page)="getServerPage($event)" [tagPressable]="{ id: 'table-paginator' }">
                </mat-paginator>
              </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
