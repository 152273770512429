import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StakeholderState, StakeholdersStore } from './stakeholders.store';
import { Stakeholder } from './stakeholder.model';

@Injectable({ providedIn: 'root' })
export class StakeholdersQuery extends QueryEntity<StakeholderState, Stakeholder> {
  constructor(protected store: StakeholdersStore) {
    super(store);
  }

}
