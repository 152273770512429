import { Directive, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Globals } from "../../../globals";
import { NewEntityData } from "../../../shared/directive/new-entity.directive";
import { GenericComponent } from "./generic-component";
@Directive({
})

export abstract class NewEntityModal extends GenericComponent implements OnInit {

  public entity: any;
  public isProcessing: boolean = false;

  protected constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<any>, public globals: Globals) {
    super(globals);
  }

  ngOnInit(): void {
    this.initializeEntity();
    if (this.data && this.data.params) {
      Object.keys(this.data.params).forEach((key: string) => this.entity[key] = this.data.params[key]);
    }
  }

  protected abstract initializeEntity(): void;
  protected abstract submit(): void;

  public close(): void {
    this.dialogRef.close();
  }
}
