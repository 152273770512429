import {AfterViewInit, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {finalize} from 'rxjs/operators';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { DocumentsService } from 'src/app/core/service/api/documents.service';
import { showFeedbackError } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-download-sharepoint-version',
  templateUrl: './download-sharepoint-version.component.html',
  styleUrls: ['./download-sharepoint-version.component.css']
})
export class DownloadSharepointVersionComponent extends GenericComponent implements AfterViewInit {

  public versions = [];
  public initialized = false;
  public baseFilename: string;

  constructor(public dialogRef: MatDialogRef<DownloadSharepointVersionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private _documentsService: DocumentsService,
              private _lightbox: Lightbox,
              public globals: Globals, private snackBar: MatSnackBar) {
    super(globals);
    this.baseFilename = data.link;
  }

  ngAfterViewInit(): void {
    this._documentsService.getDocumentVersions(this.data.id).subscribe(
      (versions: any) => {
        this.versions = versions.reverse();
        this.initialized = true;
      },
      e => {
        if (e.status === 404) {
          if (!this.data.pivot.documentable_type || !this.data.pivot.documentable_id) {
            this.snackBar.open('Het bestand kan niet gevonden worden', 'Sluiten', {
              duration: 5000
            });

            return;
          }

          this.snackBar.open('Het bestand kan niet gevonden worden en wordt verwijderd', 'Sluiten', {
            duration: 5000
          });

          this._documentsService.removeDocument(this.data.pivot.documentable_type, this.data.pivot.documentable_id, this.data.id)
            .pipe(finalize(() => this.close({
                error: true
              }))
            )
            .subscribe();
        } else {
          showFeedbackError(e);
          this.close();
        }
      }
    );
  }

  public download(data?): void {
    this._documentsService.downloadSharPointDocument(this.data,data).subscribe()
    this.dialogRef.close(data);
  }

  public close(data?): void {
    this.dialogRef.close(data);
  }
  public lightboxOpen(document){
    const albums = [];
    albums.push({
      src: document.path ? document.public_link : document.url,
      caption: document.path ? document.name : document.version,
      thumb: document.path ? document.path : document.version,
    });
    this._lightbox.open(albums);
  }
}
