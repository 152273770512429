import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts/highcharts-gantt';
import { Globals } from 'src/app/globals';
import more from 'highcharts/highcharts-more';
import HighchartsGantt from 'highcharts/modules/gantt';
import Gantt from 'highcharts/modules/gantt';
import DraggablePoints from 'highcharts/modules/draggable-points';
import { WorkflowService } from 'src/app/core/service/api/workflow.service';
import HighchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import { ActionsService } from 'src/app/core/service/api/actions.service';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { VerifyDependencyChangeComponent } from '../modal/verify-dependency-change/verify-dependency-change.component';
import { MatDialog } from '@angular/material/dialog';
import { EditWorkflowComponent } from '../modal/edit-workflow/edit-workflow.component';
import { AddWorkflowTaskComponent } from '../modal/add-workflow-task/add-workflow-task.component';
import { ActionEditComponent } from '../modal/tasks-edit/action-edit.component';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { PermitsService } from 'src/app/core/service/api/permits.service';
import { Permit } from 'src/app/akita/permits/state/permit.model';
import { translate } from '@jsverse/transloco';
import { Chart } from 'highcharts';
import { Permission } from 'src/app/core/enum/permission';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import {DeletionModalComponent} from "../modal/deletion-modal/deletion-modal.component";
Gantt(Highcharts);
HighchartsGantt(Highcharts);
HighchartsMore(Highcharts);
more(Highcharts);
DraggablePoints(Highcharts);


declare module 'highcharts' {
    interface GanttPointOptionsObject {
      owner?: string;
      pointWidth?: number;
    }

    interface GanttPoint {
      owner?: string;
      pointWidth?: number;
    }
  }
  function debounce(func: Function, wait: number) {
    let timeout: any;
    return function (...args: any[]) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }
@Component({
  selector: 'app-workflow-generic',
  templateUrl: './workflow-generic.component.html',
  styleUrls: ['./workflow-generic.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowGenericComponent extends GenericComponent implements OnInit, OnChanges {

  // Add a flag to track dependency status
  isAddingDependency: boolean = false;
  isToggled: boolean = false; // Initially set to false
  Highcharts: typeof Highcharts = Highcharts;
  chart: Chart;

  public isProcess: boolean = false;
  public isFullScreenMode: boolean = false;
  public isExpanded: boolean = true;
  public modalData: any = null
  public permit: Permit;
  public tableStyle: {} = {};

  // public workflows: any[] = [];
  @Input() margin: boolean = true;
  @Input() parentType: string = 'overview';
  @Input() workflows: any[] = [];
  @Input() deadline: any;
  @Input() permitId: any[] = [];
  @Input() load: boolean = false;
  currentLanguage = 'en';


  constructor(public globals: Globals, public pubsub: PubsubService,private _permitsService: PermitsService, public _workflowService: WorkflowService, public cdRef: ChangeDetectorRef, private _actionsService: ActionsService, public dialog?: MatDialog,
  ) {
    super(globals);
    more(this.Highcharts);
    this.currentLanguage = globals.language;
  }

  ngOnInit(): void {
    this._actionsService.loadWorkflows$.subscribe(res => {
      this.loadWorkflows(true);
    })
    // Add a listener to resize the chart when the window resizes
    this.setLanguage(this.currentLanguage)
  }

  chartCallback(chart: Chart) {
    this.chart = chart;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isProcess = true;
    if (this.load) {
      this.loadWorkflows(this.load);
    } else {
      this.loadWorkflows();
    }
  }

  loadWorkflows(load?: boolean) {
    this.isProcess = true;
    this.chartOptions.series = [];
    this.cdRef.detectChanges();
    const series: Highcharts.SeriesOptionsType = {
        type: 'gantt',
        borderWidth: 0,
        data: []
    }

    // Since you don't want to fetch workflows again, we'll use the existing this.workflows

    // If there's no need to reload (based on 'overview' or 'load' flag), proceed with Gantt chart data loading
    if (this.parentType !== 'overview' && !load) {
      this.tableStyle = { 'height': '50vh' }        
      this.loadGanttChartData();
    } else {
      this.tableStyle = { 'height': '70vh' }        
      this._workflowService.getWorkflows().subscribe((res: any) => {
        // Filter `res.data` based on the IDs from `this.workflows`
        const existingWorkflowIds = this.workflows.map((workflow: any) => workflow.id);
        const filteredWorkflows = res.data.filter((resWorkflow: any) =>
            existingWorkflowIds.includes(resWorkflow.id)
        );
        // Assign the filtered workflows to `this.workflows2`
        if(filteredWorkflows.length) {
          this.workflows = filteredWorkflows;
        } else {
          this.workflows = res.data
        }

        this.loadGanttChartData()
      })
    }
  }

  expandedWorkflowId = null;

  loadGanttChartData() {
    const series: Highcharts.SeriesOptionsType = {
      type: 'gantt',
      borderWidth: 0,
      data: []
    }

    let startDate: any = new Date();
    startDate.setDate(startDate.getDate() - 14);
    startDate = new Date(startDate);
    let endDate: any = startDate.setDate(startDate.getDate() + 14);
    endDate = new Date(endDate);
    this.workflows.forEach((workflow, index) => {
      const taskId = `workflow-${workflow.id}`;
      // Initialize taskIdsFromLocalStorage as an empty array
      const taskIdFromLocalStorage: any | null = this.getTaskIdsFromLocalStorage(); // single ID or null

      // Prepend 'workflow-' to the single ID if it exists
      const fullTaskIdFromLocalStorage = taskIdFromLocalStorage ? `workflow-${taskIdFromLocalStorage}` : null;
      const data = {
        name: workflow.name,
        id: `workflow-${workflow.id}`,
        pointWidth: 0,
        start: startDate,
        end: endDate,
        isTask: false,
        // Collapse if the task ID is found in local storage
        // collapsed: taskIdsFromLocalStorage.includes(taskId) ? false : true
        collapsed: fullTaskIdFromLocalStorage
        ? fullTaskIdFromLocalStorage !== taskId // Collapse if it doesn’t match the ID from local storage
        : this.expandedWorkflowId ? this.expandedWorkflowId !== taskId : index !== 0

      }

      series.data.push(data);
      if (workflow && workflow.tasks && workflow.tasks.length) {
        workflow.tasks.forEach(task => {
          const start = new Date(task.timestamp);
          const end = new Date(task.timestamp);
          startDate = (!startDate || start < startDate) ? start : startDate;
          endDate = (!endDate || end > endDate) ? end : endDate;

          const itemEndDate = task.startDate === task.timeStamp
            ? moment.utc(task.timestamp).endOf('day').valueOf()
            : moment.utc(task.timestamp).endOf('day').valueOf();

          const item = {
            name: task.text,
            id: `${task.id}-${workflow.id}`,
            parent:  `workflow-${workflow.id}`,
            start: moment.utc(task.start_date).startOf('day').valueOf(),
            end: itemEndDate,
            color: this.setColorBasedOnDeadlines(task.timestamp, task.handled),
            dependency: this.addTasksDependencies(task, workflow.id),
            handled: task.handled,
            duration: (moment(task.timestamp).diff(moment(task.start_date? task.start_date: task.timestamp),'days')+1).toString(),
            days: 0,
            isTask: true,
          }
          // Ensure moment is imported
          // import moment from 'moment'; // Uncomment this if using a module system
          moment.locale('en');

          const s2 = moment(task.timestamp);
          const s1 = moment(task.start_date? task.start_date : task.timestamp);

          // Format both dates to "MMM D" (e.g., Dec 18)
          const formattedStartDate = s1.format("MMM D");
          const formattedTimestamp = s2.format("MMM D");

          const formattedRange = `${formattedStartDate} - ${formattedTimestamp}`;
          item["formattedRange"] = formattedRange;

          series.data.push(item);
        })
      }
    })
    this.chartOptions.series.push(series);
    const srt = moment(new Date()).startOf('day').add(-180, 'days').valueOf();
    const end = moment(new Date()).endOf('day').add(180, 'days').valueOf();
    this.chartOptions.xAxis[0].min = srt;
    this.chartOptions.xAxis[0].max = end;
    this.isProcess = false;
    this.cdRef.detectChanges();
    const updatedSeries = [...(this.chartOptions.series || []), series];

    // Update the chart options with the new series array
    this.chartOptions = {
      ...this.chartOptions,
      series: updatedSeries
    };
    this.plotBands(endDate);
    localStorage.removeItem('taskIds');

    // this.dailyPlotBands(startDate,endDate);
    setTimeout(() => {
      this.yAxisLabel = '';
      this.addDeleteClickEvent();
      this.addYAxisLabelClick();
    },2000);
    this.cdRef.detectChanges();
  }
  addTasksDependencies(task, workflowId) {
    const dependencies = [];
    if (task.tasks && task.tasks.length) {
      task.tasks.forEach(item => {
        const id = `${item.id}-${workflowId}`
        dependencies.push(id)
      })
      return dependencies;
    }
    return []
  }
  setColorBasedOnDeadlines(endDate, handled) {
    if (handled) {
      return '#e0e1e3';
    }
    const diff = moment.utc(endDate).diff(moment.utc(new Date()), 'days');
    if (diff > 7) {
      return '#2800d2';
    } else if (diff <=7 && diff >0) {
      return '#f7f050';
    } else if (diff <= 0) {
      return '#ff7669';
    }
  }
  dailyPlotBands(startDate, endDate) {
    let currentDate = moment(new Date(startDate));
    endDate = moment(new Date(endDate)).add(30, 'days');

    while (currentDate < endDate) {
      const band = {
          color: '#f7f8f9',
          borderWidth: 1,
          borderColor: '#fff',
          from: currentDate.startOf('day').valueOf(),
          to: currentDate.endOf('day'),
          zIndex: 0
      };

      this.chartOptions.xAxis[0].plotBands.push(band);
      currentDate = currentDate.add(1, 'days');
    }
  }

  plotBands(endDate) {
    this.chartOptions.xAxis[0].plotBands = [];
    // deadline plot band
    const deadline = moment.utc(this.deadline? this.deadline : endDate);
    this.addPlotBandElements(deadline, translate('time.deadline'), '#e3e3e3', '#000')

    // current date plot band
    const currentDate = moment.utc(new Date());
    this.addPlotBandElements(currentDate, translate('time.today'), '#daf2f0', '#67d9c8')

  }

  addPlotBandElements(date, text, color, border) {
    const band = {
      color: color,
      borderWidth: 1,
      borderColor: border,
      from: date.valueOf(),
      to: moment(date).add(1, 'days').valueOf(),
      zIndex: 1,
      className: 'deadline-plot-band',
      label: {
        text: text,
        style: {
          fontFamily: 'work_sans_regular',
          fontSize: '13px',
          backgroundColor: border,
          color: '#fff',
          padding: '2px 5px 2px 10px',
          borderRadius: '10px',
          width: 'auto'
        },
        useHTML: true,
        verticalAlign: 'top',
        x: 10
      }
    };
    this.chartOptions.xAxis[0].plotBands.push(band);
  }

  openWorkflowTaskModal(modalData: { id: string, name: string, startDate: Date }) {

    const dialogRef = this.dialog.open(AddWorkflowTaskComponent, {
      width: '600px',
      data: modalData // Pass the clicked date to the modal
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
      this._actionsService.setLoadWorkflow(true);
      this.cdRef.detectChanges();
      // Handle any result if need
      }

    });
  }

 // Get Task IDs from Local Storage
  getTaskIdsFromLocalStorage(): string[] {
    // Retrieve the task IDs and parse them into an array (or return an empty array if nothing is stored)
    const taskIds = localStorage.getItem('taskIds');

    // Check if taskIds exists and parse it. If it's not found, return an empty array.
    return taskIds ? JSON.parse(taskIds) : '';
  }


  day = 24* 36e5;
  today = Math.floor(Date.now() / this.day)* this.day;
  getLanguageOptions(lang: string) {
    if (lang === 'en') {
      return {
        lang: {
          months: [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
          ],
          shortMonths: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ],
          weekdays: [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
          ]
        }
      };
    } else if (lang === 'nl') {
      return {
        lang: {
          months: [
            'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'
          ],
          shortMonths: [
            'Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'
          ],
          weekdays: [
            'Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'
          ]
        }
      };
    }
  }

  setLanguage(lang: string) {
    const languageOptions = this.getLanguageOptions(lang);
    Highcharts.setOptions(languageOptions); // Set language options globally
  }

  getChartOptions(){
    const componentContext = this; // Capture Angular component context
    const options: Highcharts.Options = {

      tooltip: {
        enabled: false
      },
      chart: {
        type: 'gantt',
        scrollablePlotArea: {
          minHeight: 1000,
          scrollPositionY: 0,
          opacity: 1
        },
        events: {
          click: (event: any) => {
            // const chart = this;
            // Check if a dependency is being created and prevent modal from opening
            if (this.isAddingDependency) {
              console.log('Dependency creation in process, not opening modal.');
              return; // Exit early if creating a dependency
            }
            const yAxis = event.yAxis[0];

            if (yAxis) {
               // Get the Y-axis value from the click event
               const clickedYValue = event.yAxis[0].value; 
               const yAxis = event.yAxis[0].axis;

               // Find the closest category to the clicked Y-axis value
               const categories = yAxis.categories;
               const categoryIndex = Math.round(clickedYValue); // Get the nearest index
                
               // Ensure the index is within the range of available categories
               if (categoryIndex >= 0 && categoryIndex < categories.length) {
                   const clickedCategory = categories[categoryIndex]; // The category clicked
                   
                   // Output the category (or row) the user clicked
                  //  console.log('Category at clicked position:', clickedCategory);
                   if (categoryIndex !== null && categoryIndex !== undefined && categoryIndex <= categories.length) {
                    event.yAxis[0].axis.series.forEach((series: any) => {
                      series.data.forEach((point: any, pointIndex: number) => {
                        let parentWorkflow = null;
    
                        // Check if the point index matches the clicked category index
                        if (pointIndex === categoryIndex) {
                          // If the task is found and has a parent workflow, retrieve it
                          if (point.parent) {
                            parentWorkflow = point.name; // Set the workflow name
                            const parentId = point.parent.replace("workflow-", "");
                            // Convert parentWorkflowId to a number (since parentIds array contains numbers)
                            const numericParentId = parseInt(parentId, 10);
                            this.workflows.forEach(workflow => {
                              if (workflow.id === numericParentId) {
                                this.modalData = {
                                  id: workflow.id,
                                  name: workflow.name,
                                  startDate: new Date(event.xAxis[0].value), // Pass the clicked X-axis date
                                };
                                localStorage.setItem('taskIds', JSON.stringify(workflow.id));
                              }
                            })
                          } else {
                            this.modalData = {
                              id: point.id,
                              name: point.name,
                              startDate: new Date(event.xAxis[0].value), // Pass the clicked X-axis date
                            };
                            localStorage.setItem('taskIds', JSON.stringify(point.id));
                          }
                        }
                      });
                    });
                    if (this.modalData) {
                      this.openWorkflowTaskModal(this.modalData);
                    } else {
                      console.log('No parent data found for any point.');
                    }
    
                  } else {
                    console.log('No category found at the clicked Y-pixel.');
                  }
               } else {
                   console.log('No category found at clicked position.');
               }
             
            }else {
              console.log('No date available on click');
            }

          },
          load: function () {
            const startZoomRange = moment(new Date()).startOf('day').add(-14, 'days').valueOf()
            const endZoomRange = moment(new Date()).startOf('day').add(14, 'days').valueOf();
            this.xAxis[0].setExtremes(startZoomRange, endZoomRange);
            const chart = this;
            // eslint-disable-next-line prefer-arrow-callback
            Highcharts.addEvent(chart.container, 'mousemove', function (e: MouseEvent) {
              const customChart = chart as any;
              if (customChart.dependencyStart) {
                const startPoint = customChart.dependencyStart;

                // Get the mouse position relative to the chart container
                const container = chart.container.getBoundingClientRect();
                const chartX = e.clientX - container.left;
                const chartY = e.clientY - container.top;

                const startX = startPoint.plotX + chart.plotLeft + startPoint.shapeArgs.width;
                const startY = startPoint.plotY + chart.plotTop + startPoint.shapeArgs.height / 2;

                if (customChart.tempLine) {
                  customChart.tempLine.attr({
                    d: [
                      'M', startX, startY,
                      'L', chartX, startY,
                      'L', chartX, chartY
                    ]
                  });
                }
              }
            });

          }
        }
      },
      plotOptions: {
        series: {
          dragDrop: {
            draggableX: true,
            draggableY: true,
            dragMinY: 0,
            dragMaxY: 2,
            dragPrecisionX: 24 * 36e5,
            dragHandle: {
              cursor: 'ew-resize',
              lineWidth: 1,
              // lineColor: 'red',
            },

            liveRedraw: false,
          },
          allowPointSelect: true,
          cursor: 'pointer',
          point: {
            events: {
              click: (event: any) => {
                const clickedTask = this;
                const point = event.point;
                const chart = point.series.chart;
                const customChart = chart as any;
                this.isAddingDependency = true;
                // If toggle is true, we are adding a dependency
                if (this.isToggled) {
                  if (!customChart.dependencyStart) {
                    // Start the dependency creation process
                    customChart.dependencyStart = point;
                    customChart.tempLine = chart.renderer.path()
                      .attr({
                        'stroke-width': 1,
                        stroke: 'blue',
                        zIndex: 1
                      })
                      .add(); // Create a temporary line for visualizing the connection
                  } else {
                    // Complete the dependency creation
                    const fromPoint = customChart.dependencyStart;
                    const toPoint = point;
                    customChart.dependencyStart = null;
                    customChart.tempLine.destroy();


                    // Ensure that the points are not the same
                    if (fromPoint.id !== toPoint.id) {
                      toPoint.dependency.push(fromPoint.id);

                      // Update the chart with the new dependency
                      const series = chart.series[0] as Highcharts.Series & { options: Highcharts.SeriesGanttOptions };
                      series.setData(series.options.data as Highcharts.GanttPointOptionsObject[], true);

                      // Update the dependency in the backend or state
                      this.updateDependency(
                        parseInt(fromPoint.id.split('-')[0]),
                        parseInt(toPoint.id.split('-')[0]),
                        parseInt(toPoint.id.split('-')[1])
                      );
                    } else {
                      console.log('Cannot create a dependency between the same point.');
                    }

                    // Reset the dependency mode after completing the process
                    this.isAddingDependency = false;
                  }
                } else {
                  // If toggle is false, open the ActionEditComponent modal
                  const component = ActionEditComponent;
                  const data = {
                    id: point.id,
                    text: point.name,
                    timestamp: point.start,
                    endDate: point.end
                  };
                  const size = ModalEnum.SidebarLargeResponsive;
                  this.nav(data, component, size)
                  this.isAddingDependency = false;

                }
              },
              drag: debounce((event: any) => {
                const point = event.target;
                const newStart = Highcharts.dateFormat('%Y-%m-%d', point.start);
                const newEnd = Highcharts.dateFormat('%Y-%m-%d', point.end);
              }, 1000),
              drop: debounce((event: any) => {
                const point = event.target;
                const newStart = Highcharts.dateFormat('%Y-%m-%d', point.start);
                const newEnd = Highcharts.dateFormat('%Y-%m-%d', point.end - (24 * 60 * 60 * 1000));

                const s2 = moment(newEnd);
                const s1 = moment(newStart);
                // Format both dates to "MMM D" (e.g., Dec 18)
                const formattedStartDate = s1.format("MMM D");
                const formattedTimestamp = s2.format("MMM D");
                const formattedRange = `${formattedStartDate} - ${formattedTimestamp}`;
                point.update({
                  color: this.setColorBasedOnDeadlines(point.end, point.handled),
                  duration: moment(newEnd).diff(moment(newStart), 'days')+1,
                  formattedRange: formattedRange

                });
                this.updateTaskDates(point.id, newStart, newEnd);
              }, 1000)
            }
          }
        }
      },
      xAxis: [
        {
          alignTicks: false,
          grid: {
            borderWidth: 0,
            cellHeight: 15,
          },
          type: 'datetime',
          alternateGridColor: '#f7f8f9',
          tickInterval: 24 * 3600 * 1000, // One day interval
          labels: {
            formatter: function () {
              // 'this' refers to the Highcharts object here, 'component' is the Angular component context
              const date = new Date(this.value);
              const dayName = date.toLocaleDateString(componentContext.currentLanguage, { weekday: 'short' }).charAt(0);
              const dayOfMonth = date.getUTCDate();
              return `${dayName.toUpperCase()}${dayOfMonth}`;
            }
          },
          plotBands: [],
        },
        {
          grid: {
            borderWidth: 0,
            cellHeight: 1,
          },
          type: 'datetime',
          opposite: true,
          labels: {
            format: '{value:W%W}', // Show weeks
            align: 'left',
            style: {
              fontWeight: 'bold',
              padding: '10px',
            },
          },
        },
        {
          grid: {
            borderWidth: 0,
            cellHeight: 1,
          },
          type: 'datetime',
          opposite: true,
          tickInterval: 24 * 3600 * 1000 * 30, // Approximate one month interval
          labels: {
            format: '{value:%b %Y}', // Show month and year, e.g., Jan 2024
            align: 'center',
            style: {
              fontSize: '13px',
              fontWeight: 'bold',
            },
          },
        },
      ],
      yAxis: {
        staticScale: 35,
        grid: {
          enabled: true,
          borderWidth: 0,
          borderColor: 'rgba(128,128,128,0.2)',
          columns: [
            {
              title: {
                text: 'Task Details',
                align: 'high',
                x: 20,
                style: {
                  position: 'sticky',
                },
              },
              labels: {
                formatter: (ctx => {
                  function truncate(value: string, limit = 7) {
                    if (!value) {
                      return value;
                    }
                  const ellipsis = '...';

                    if (value.length <= limit) {
                      return value;
                    }

                    return `${value.slice(0, limit)}${ellipsis}`;
                  }

                  const highcharts_td = 'font-family: work_sans_regular; font-size: 13px; cursor: pointer; padding-right: 10px;'
                  if (this.chartOptions.series[0]) {
                    const seriesData: any = this.chartOptions.series[0];
                    const itemData: any = seriesData.data.filter(f => f.name === ctx.value)[0];

                    // Proportional widths for each column
                    const taskNameWidth = '40%'; // 40% of 400px
                    const dateRangeWidth = '35%'; // 35% of 400px
                    const durationWidth = '25%'; // 25% of 400px

                    // Task Name
                    const taskName = itemData.parent ? truncate(itemData?.name) : truncate(itemData?.name, 25);
                    const taskNameHtml = `<td id=${itemData?.id || ''} class="yaxis-label"
                            style="${highcharts_td} width: ${taskNameWidth}; max-width: 76px;"
                            title="${itemData?.name || ''}"> <!-- This adds the tooltip with full task name -->
                            ${taskName || ''}
                           </td>`;

                    // Date Range
                    const dateRangeHtml = itemData?.formattedRange
                      ? `<td style="${highcharts_td} width: ${dateRangeWidth};">
                ${itemData.formattedRange}
              </td>`
                      : `<td style="${highcharts_td} width: ${dateRangeWidth};"></td>`;

                    // Duration
                    const durationHtml = itemData?.duration
                      ? `<td style="${highcharts_td} width: ${durationWidth};">
                ${itemData.duration} days
              </td>`
                      : this.hasPermission(Permission.WorkflowsDelete)? `<td style="width: ${durationWidth}; text-align-last: center">
                <i class="uil uil-trash unicon-icon delete-icon" id="${itemData?.id || ''}" style="cursor: pointer;"></i>
              </td>` : `<td style="${highcharts_td} width: ${durationWidth};"></td>`;

                    // Combine the HTML into a table row with a total width of 400px
                    return `<table style="border-collapse: collapse; min-width: 250px; max-width: 400px"><tr style="vertical-align: top">${taskNameHtml}${dateRangeHtml}${durationHtml}</tr></table>`;
                  }
                  return '';
                }),
                useHTML: true,
                align: 'left',
                y: 10,
              },
            },
          ],
        }
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        handles: {
          enabled: true
        },
        //liveRedraw: true,
        series: {
          type: 'gantt',
          //pointPlacement: 0.5,
          pointPadding: 0.25,
          accessibility: {
            enabled: false
          },
        },
      },
      scrollbar: {
        enabled: true
      },
      rangeSelector: {
        enabled: false,
        selected: 0
      },
      series: [],
    };
    return options;
  }

  chartOptions = this.getChartOptions();


  nav(data, component, size) {
    this.pubsub.closeModal(this.pubsub.currentDialog)
    const modal = this.dialog.open(component, modalConfig({
      data: data,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
      closeOnNavigation: true
    }, size));
    const name = data.text;
    this.pubsub.updateHistory(modal, component, data, name, size)
  }

  updateTaskDates(id, newStart, newEndDate) {

    const tskId = parseInt(id.split('-')[0]);
    const daysDiff = this.calculateDaysDiff(id, newEndDate);
    // calculate the days diff
    const component = VerifyDependencyChangeComponent;
    let dependents = [];
    this._workflowService.getDependents(tskId).subscribe(res => {
      const dependencyChange = [];
      if (res && res.children && res.children.length > 1) {
        dependents = res.children;
        dependents.forEach( (item, index) => {
          let diff;
          if(dependents[index -1] && dependents[index -1].newTimestamp) {
            diff = moment(dependents[index -1].newTimestamp).diff(moment(item.start_date ? item.start_date : item.timestamp), 'days');
          } else {
            diff = moment(newEndDate).diff(moment(item.start_date ? item.start_date : item.timestamp), 'days');
          }
          if (diff >= 0 && index !==0){
            item['start_date'] = moment(item.start_date ? item.start_date : item.timestamp).add(diff+1, 'days').format('YYYY-MM-DD')
            item['newTimestamp'] = moment(item.timestamp).add(diff+1, 'days').format('YYYY-MM-DD');
            dependencyChange.push(item);
          }
        })
        
      }
      if (res && res.parents && res.parents.length > 1) {
        dependents = res.parents;
        dependents.forEach( (item, index) => {
          let diff;
          if(dependents[index -1] && dependents[index -1].newTimestamp) {
            diff = moment(dependents[index -1].newTimestamp).diff(moment(item.start_date ? item.start_date : item.timestamp), 'days');
          } else {
            diff = moment(newStart).diff(moment(item.timestamp), 'days');
          }
          if (diff <= 0 && index !== (dependents.length - 1)){
            item['start_date'] = moment(item.start_date ? item.start_date : item.timestamp).add(diff-1, 'days').format('YYYY-MM-DD')
            item['newTimestamp'] = moment(item.timestamp).add(diff-1, 'days').format('YYYY-MM-DD');
            dependencyChange.push(item);
          }
        })
      }
      if (dependencyChange && dependencyChange.length) {
        const dialog = this.dialog.open(component, modalConfig({
          data: {dependents: [...dependencyChange], newEndDate},
          panelClass: 'customized-dialog-container',
        }, ModalEnum.ModalDefault));

        dialog.afterClosed().subscribe((response: any) => {
          if (response && response.ok === true) {
            const draggedItem = dependents[0];
            draggedItem.start_date = newStart;
            draggedItem.timestamp = newEndDate;
            this._workflowService.batchApprove({tasks: [draggedItem]}).subscribe( res => {
              this.reloadChart(true)
            })
          } else {
            this.reloadChart(true);
          }
        });
      } 
      if (res && res.children.length > 0 && !dependencyChange.length) {
        const draggedItem = res.children[0];
        draggedItem.start_date = newStart;
        draggedItem.timestamp = newEndDate;
        this.reloadChart();
        this._workflowService.batchApprove({tasks: [draggedItem]}).subscribe( res => {
          showFeedbackSaved();
          // this.expandedWorkflowId = `workflow-${id.split('-')[1]}`;
        });
      }
    })
  }


  reloadChart(load?: boolean) {

    // this below re-assing is for the update...
    const seriesData: any = this.chart.series[0];
    this.chartOptions.series[0] = seriesData;
    this.chart.redraw();

    if (load) {
      this._workflowService.getWorkflows().subscribe((res: any) => {
        this.isProcess = true;
        this.chartOptions.series = [];
        this.cdRef.detectChanges();
        // Filter `res.data` based on the IDs from `this.workflows`
        const existingWorkflowIds = this.workflows.map((workflow: any) => workflow.id);
        const filteredWorkflows = res.data.filter((resWorkflow: any) =>
            existingWorkflowIds.includes(resWorkflow.id)
        );
        // Assign the filtered workflows to `this.workflows`
        if(filteredWorkflows.length) {
          this.workflows = filteredWorkflows;
        } else {
          this.workflows = res.data
        }
        this.loadGanttChartData()
      })
    }
  }

  calculateDaysDiff(id, newEnd) {
    const tskId = parseInt(id.split('-')[0]);
    const workflowId = parseInt(id.split('-')[1])
    const workflow = this.workflows.filter(w => w.id === workflowId)[0];
    const task = workflow.tasks.filter(t => t.id === tskId)[0];
    const prevEnd = moment(task.timestamp);
    newEnd = moment(newEnd);
    const diffInDays = newEnd.diff(prevEnd, 'days');
    return diffInDays;
  }

  updateDependency(fromTaskId, toTaskId, workflowId) {
    this.isAddingDependency = true; // Set flag when dependency is being added
    if (this.workflows && this.workflows.length) {
      const workflow = this.workflows.filter(w => w.id === workflowId)[0];
      if (workflow && workflow.tasks && workflow.tasks.length) {
        const toTask = workflow.tasks.filter(t => t.id === toTaskId)[0];
        const fromTask = workflow.tasks.filter(t => t.id === fromTaskId)[0];
        toTask.tasks.push(fromTask);
        this._actionsService.updateActionById(toTaskId, toTask).subscribe( res => {
          showFeedbackSaved();
          this.loadWorkflows();
        })
      }
    }
    this.isAddingDependency = false; // Reset the flag once dependency is added
  }

  // Method to handle the yAxis label click
  addDeleteClickEvent(): void {
    const deleteIcon = document.querySelectorAll('.delete-icon');
    deleteIcon.forEach(icon => {
      icon.addEventListener('click', (event) => {
        const value = (event.target as HTMLElement).getAttribute('id');
        // this.handleDeleteIconClick(value);

        const dialogRef = this.dialog.open(DeletionModalComponent, {
          data: translate('workflow.delete_message')
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe( (remove: boolean) => {
          if (remove) {
            this.handleDeleteIconClick(value)
          }
        });

      });
    });
  }

  // Logic for when a yAxis label is clicked
  handleDeleteIconClick(value: string | null): void {
    if (value.includes('workflow')) {
      const id = value.split('-')[1];
      this._workflowService.deleteWorkflow(id).subscribe( res => {
        this.workflows = this.workflows.filter(f => f.id != id);
        this.loadWorkflows();
      })
    }
  }

  yAxisLabel: any;


  addYAxisLabelClick(): void {
    // Remove any existing listeners to avoid multiple attachment
    this.yAxisLabel = document.querySelectorAll('.yaxis-label');

    this.yAxisLabel.forEach(label => {
      // Clone the label element to remove all attached listeners
      const labelClone = label.cloneNode(true);
      label.replaceWith(labelClone);

      // Add a new listener to the cloned label
      labelClone.addEventListener('click', (event) => {
        this.handleYAxisLabelClick(event);
      });
    });
  }

  handleYAxisLabelClick(event: any): void {
    const value = (event.target as HTMLElement).getAttribute('id');

    // Handle click on workflow labels
    if (value && value.includes('workflow')) {
      // Set a delay to handle any UI-related updates
      setTimeout(() => {
        this.addYAxisLabelClick(); // Rebind event listeners after an action
      }, 100);
    }

    if (typeof value === 'string') {
      const seriesData: any = this.chartOptions.series[0];
      const task: any = seriesData.data.find(point => point.id === value);

      if (task) {
        this.zoomToRange(task.start, task.end); // Your zoom functionality
      }
    }
  }

  zoomToRange(start, end) {
    const startZoomRange = moment(new Date(start)).startOf('day').add(-14, 'days').valueOf()
    const endZoomRange = moment(new Date(end)).startOf('day').add(14, 'days').valueOf();

    if (this.chart) {
      this.chart.xAxis[0].setExtremes(startZoomRange, endZoomRange); // Set new zoom range
    }
  }

  toggleFullScreen() {
    const chartContainer = document.getElementById('chartContainer');
    if (!document.fullscreenElement) {
      this.isFullScreenMode = true;
      this.cdRef.detectChanges();
      chartContainer?.requestFullscreen().catch(err => {
        console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      this.isFullScreenMode = false;
      this.cdRef.detectChanges();
      document.exitFullscreen().catch(err => {
        console.log(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
      });
    }
  }


  onToggleChange(isChecked: boolean) {
    this.isToggled = isChecked;
  }

  updateWorkflows() {
    this.cdRef.detectChanges()
    const component = EditWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {workflows: this.workflows, parentType: this.parentType},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.loadWorkflows();
      }
    });
  }

}
