import { ID } from '@datorama/akita';
import { phoneNumberCode } from 'src/app/core/util/deepCopy';

export type Log = {
  id?: ID,
  title?: string,
  description?: string,
  contact_moment_type: string,
  contact_moment_timestamp: Date,
  series?: any[],
};

export function createLog(logType: string, timestamp: Date): Log {
  return {
    contact_moment_type: logType,
    contact_moment_timestamp: timestamp,
  };
}
// phone country code set
export function phoneCode(phone,code): any {
  if(phone){
    phone=phone.replaceAll(' ', '');
    if(!code){
      return {code: phoneNumberCode(phone.substring(0,code.length+1)), phone: phone.substring(code.length+1)};
    }
    return {code: code, phone: phone.replaceAll(code, '')};
  }
  return {code: '', phone: ''}
}
