import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslocoService, translate } from "@jsverse/transloco";
import moment from "moment";
import { catchError, tap } from "rxjs/operators";
import { ActionsService } from "src/app/akita/tasks/state/actions.service";
import { ConfigsService } from "src/app/core/service/api/configs.service";
import { PubsubService } from "src/app/core/service/api/pubsub.service";
import { parsemapItem } from "src/app/core/util/computemaplayers";
import { forOverPassLayer } from "src/app/core/util/deepCopy";
import { openDataLayerFormate } from "src/app/core/util/openDataModals";
import { ComplaintsDetailComponent } from "src/app/features/main-app/complaints/complaints-detail/complaints-detail.component";
import { CalendarActivityDetailComponent } from "src/app/shared/components/modal/calendar-activity-detail/calendar-activity-detail.component";
import { ActivitiesService } from "../../../../../akita/activities/state/activities.service";
import { Activity } from "../../../../../akita/activities/state/activity.model";
import { Complaint } from "../../../../../akita/complaints/state/complaint.model";
import { ComplaintsService } from "../../../../../akita/complaints/state/complaints.service";
import { Issue } from "../../../../../akita/issues/state/issue.model";
import { IssuesService } from "../../../../../akita/issues/state/issues.service";
import { Decision } from "../../../../../akita/requirements/state/decision.model";
import { DecisionsService } from "../../../../../akita/requirements/state/decisions.service";
import { Stakeholder } from "../../../../../akita/stakeholders/state/stakeholder.model";
import { StakeholdersService } from "../../../../../akita/stakeholders/state/stakeholders.service";
import { Permission } from "../../../../../core/enum/permission";
import { ModalEnum, modalConfig } from "../../../../../core/util/modalConfig";
import {
  showFeedbackSaved
} from "../../../../../core/util/notification";
import { Globals } from "../../../../../globals";
import { TabIssueDetailGeneralComponent } from "../../../issues/page/issues-detail/tab-issue-detail-general/tab-issue-detail-general.component";
import { KlantwensenDetailComponent } from "../../../requirements/page/requirement-detail/klantwensen-detail.component";
import { StakeholdersDetailComponent } from "../../../stakeholders/page/stakeholders-detail/stakeholders-detail.component";
import { MapService } from "src/app/core/service/api/map.service";
import { CachedService } from "src/app/core/service/common/cached.service";
import { forkJoin, of } from "rxjs";
@Component({
  selector: "app-item-detail",
  templateUrl: "./item-detail.component.html",
  styleUrls: ["./item-detail.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailComponent implements OnInit, OnDestroy {
  @Output() itemDetail = new EventEmitter<any>()
  stakeholder: Stakeholder;
  issue: Issue;
  public Permission = Permission;
  listload: boolean = true;
  connectionListView: any = [];
  responsiveSend: any;
  public responseLayers: any;
  public layers: any;
  listloadOverpass: boolean = true;
  layersConnection: any;
  complaint: Complaint;
  decision: Decision;
  activity: Activity;
  current: string;
  feature: any;
  overPassFeature: any;
  newMapItem: any;
  todayDate = new Date();
  buttonToggle: boolean = false;
  dropdownOption: any[];
  connectionButton: boolean = true;
  public laterSave: any;
  public noFeatureErrorMessage: boolean = false;
  public locatable_type: string = '';
  mapRelations: boolean = false;
  relationLayers: any[] = [];
  featureLayers: any[] = [];
  loaded: boolean = false;
  entities = {};
  mainLayerData: any
  showAllRelationLayers: boolean = false;
  pinItems: any[] = [];
  pinData = [];
  showIcon = false;
  @ViewChild(CalendarActivityDetailComponent, { static: false }) activityDetail: CalendarActivityDetailComponent;
  constructor(
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private stakeholderService: StakeholdersService,
    public globals: Globals,
    private _translocoService: TranslocoService,
    private decisionService: DecisionsService,
    private issuesService: IssuesService,
    private complaintService: ComplaintsService,
    private activityService: ActivitiesService,
    private router: Router,
    private layerConnection: ConfigsService,
    public pubsub: PubsubService,
    private _actionsService: ActionsService,
    private _mapService: MapService,
    private cachedService: CachedService,
    // eslint-disable-next-line no-empty-function
  ) { }

  public ngOnInit(): void {
    this.cachedService.getActivitiesRelations().subscribe((response: any) => {
      const activities = response.data
      forkJoin([
        this.cachedService.getStakeholdersRelations(),
        this.cachedService.getIssuesRelations(),
        this.cachedService.getDecisionsRelations(),
        this.cachedService.getComplaintsRelations(),
        this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
          ? this.cachedService.getPermitsRelations() 
          : of([])
      ]).subscribe(([stakeholders, issues, requirements, complaints,permits]) => {
        this.entities = { stakeholders, issues, requirements, complaints, activities, permits };
        this.loaded = true;
      });
    })
  }

  public ngOnDestroy(): void {
    this.pubsub.mapDetail = false;
  }
  public toggleshow() {
    if (this.buttonToggle == false) {
      this.buttonToggle = true;
    } else {
      this.buttonToggle = false;
    }
  }

  public updateEntities(event) {
    this.layers[event.type] = event.selected;
    this.updatelayers();
  }

  public showOpenData(feature, buttonconnect) {
    if (feature.layer == 'Pontential_Stakeholder') {
      this.overPassFeature = forOverPassLayer(feature);
    }
    this.current = "open_data";
    this.feature = openDataLayerFormate(feature);
    this.connectionButton = buttonconnect;
    if (this.feature.layer_name) {
      this.newMapItem = this.feature;
    } else {
      this.newMapItem = parsemapItem(feature);
    }
    if (!Object.keys(this.feature).length) {
      this.listload = true;
      this.noFeatureErrorMessage = true;
      this.cd.detectChanges();
    }
    this.buttonToggle = false;
    this.dropdownOption = [
      {
        image: "/assets/png/stakeholders.png",
        title: translate("stakeholders.labels"),
        newEntity: "stakeholders",
      },
      {
        image: "/assets/png/issues.png",
        title: translate("issues.labels"),
        newEntity: "issues",
      },
      {
        image: "/assets/png/complaints.png",
        title: translate("complaints.labels"),
        newEntity: "complaints",
      },
      {
        image: "/assets/png/requirements.png",
        title: translate("decisions.labels"),
        newEntity: "requirements",
      },
      {
        image: "/assets/png/calendars.png",
        title: translate("activities.labels"),
        newEntity: "activities",
      },
    ];
    if (this.newMapItem.layer_id) {
      this.onClickLayersGet();
    }
    this.cd.detectChanges();
  }

  public getData(item): void {
    // this.locatable_type is for complaints only bcz no data object
    this.locatable_type = '';
    if (item.locatable_type == "stakeholders") {
      this.laterSave = item;
      this.stakeholderService.getSummary(item.id).subscribe(
        (data) => {
          this.current = "stakeholder";
          this.stakeholder = data;
          this.stakeholder.strategy = (item.matrices && typeof(item.matrices) === 'string') ? item.matrices : data.strategy;
          this.stakeholder.id = item.id;
          this.stakeholder.name = data.data.name;
          this.stakeholder.code = item.link.replace("stakeholders/", "");
          this.addLayerRelations(this.stakeholder);
          this.cd.detectChanges();
        },
        (err) => console.error(err)
      );
    }

    if (item.locatable_type == "issues") {
      this.laterSave = item;
      this.issuesService.getSummary(item.id).subscribe(
        (data) => {
          this.current = "issue";
          this.issue = data;
          this.issue.id = item.id;
          this.issue.name = data.data.name;
          this.issue["link"] = item.link;
          this.issue.code = item.link.replace("issues/", "");
          this.addLayerRelations(this.issue);
          this.cd.detectChanges();
        },
        (err) => console.error(err)
      );
    }

    if (item.locatable_type == "requirements") {
      this.laterSave = item;
      this.decisionService.getSummary(item.id).subscribe(
        (data) => {
          this.current = "requirements";
          this.decision = data;
          this.decision.id = item.id;
          this.decision.mapDetail = true;
          this.decision.title = data.data.title;
          this.decision["link"] = item.link;
          this.addLayerRelations(this.decision);
          this.cd.detectChanges();
        },
        (err) => console.error(err)
      );
    }

    if (item.locatable_type == "complaints") {
      this.laterSave = item;
      this.complaintService.find(item.id).subscribe(
        (data) => {
          this.current = "complaint";
          this.locatable_type = 'complaints';
          this.complaint = data;
          this.complaint.id = item.id;
          const today: any = moment().startOf("day");
          const start: any = moment(item.date);
          const diff: any = today - start;
          this.complaint["days"] = Math.round(moment.duration(diff).asDays());
          this.complaint.date = data.date;
          this.complaint["link"] = item.link;
          if (data.layers) { this.addLayerRelations(this.complaint) }
          this.cd.detectChanges();
        },
        (err) => console.error(err)
      );
    }

    if (item.locatable_type == "calendars") {
      this.laterSave = item;
      this.activityService.getSummary(item.calendarable_id).subscribe(
        (data) => {
          this.current = "activity";
          this.activity = data;
          this.activity.name = data.name;
          this.activity["link"] = item.link;
          this.activity.id = item.calendarable_id;
          this.addLayerRelations(this.activity);
          this.cd.detectChanges();
        },
        (err) => console.error(err)
      );
    }
  }
  public updatelayers() {
    this.responseLayers = this.newMapItem;
    this.layers = { ...this.layers, layers: this.responseLayers };
      this.layers.layers['type'] = this._mapService.mainLayers.values_.layer_type;
      this.layers.layers['main_layer_id'] = this._mapService.mainLayerId? this._mapService.mainLayerId 
        : this.layers.main_layer_id? this.layers.main_layer_id : null ;
      this.layers.layers['sub_layer_id'] = 0;
      this.layers.layers['sub_layer_name'] = this._mapService.mainLayers.values_.name;
      this.layerConnection
        .setLayerConection(this.layers)
        .subscribe(() => {
          this.listload = true;
          this.cd.detectChanges();
          showFeedbackSaved("verbinding gemaakt")}
        );
  }
  public openDetail(url: any): void {
    this.router.navigate(url.split("/"));
    this.cd.detectChanges();
  }
  public openDetailLayer(type, data, action = false): void {
    this.pubsub.mapDetail = true;
    switch (type) {
      case 'stakeholders': {
        const modal = this.dialog.open(StakeholdersDetailComponent, modalConfig({
          data: data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
          closeOnNavigation: true
        }, ModalEnum.SidebarLargeResponsive));
        this.pubsub.updateHistory(modal, StakeholdersDetailComponent, data, data.name)
        modal.afterClosed().subscribe(() => {
          this.onClickLayersGet();
          if (!action) {
            this.getData(this.laterSave);
          }
          this.cd.detectChanges();
        });
        break;
      }
      case 'issues': {
        const modal = this.dialog.open(TabIssueDetailGeneralComponent, modalConfig({
          data: data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
        }, ModalEnum.SidebarLargeResponsive));
        this.pubsub.updateHistory(modal, TabIssueDetailGeneralComponent, data, data.name)
        modal.afterClosed().subscribe(() => {
          this.onClickLayersGet();
          if (!action) {
            this.getData(this.laterSave);
          }
          this.cd.detectChanges();
        });
        break;
      }
      case 'klanteisen': {
        data.mapDetail = true;
        const modal = this.dialog.open(KlantwensenDetailComponent, modalConfig({
          data: data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
          closeOnNavigation: true
        }, ModalEnum.SidebarLargeResponsive));
        this.pubsub.updateHistory(modal, KlantwensenDetailComponent, data, data.title)
        modal.afterClosed().subscribe(() => {
          this.onClickLayersGet();
          if (!action) {
            this.getData(this.laterSave);
          }
          this.cd.detectChanges();
        });
        break;
      }
      case 'meldingen': {
        const modal = this.dialog.open(ComplaintsDetailComponent, modalConfig({
          data: data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
        }, ModalEnum.SidebarLargeResponsive));
        this.pubsub.updateHistory(modal, ComplaintsDetailComponent, data, data.text)
        modal.afterClosed().subscribe(() => {
          this.onClickLayersGet();
          if (!action) {
            this.getData(this.laterSave);
          }
          this.cd.detectChanges();
        });
        break;
      }
      case 'communicatiekalender/activiteit': {
        const modal = this.dialog.open(CalendarActivityDetailComponent, modalConfig({
          data: data,
          panelClass: ['animate__animated', 'animate__slideInRight'],
          disableClose: true,
        }, ModalEnum.SidebarSmallResponsive));
        this.pubsub.updateHistory(modal, CalendarActivityDetailComponent, data, data.name, ModalEnum.SidebarSmallResponsive)
        modal.afterClosed().subscribe(() => {
          this.onClickLayersGet();
          if (!action) {
            this.getData(this.laterSave);
          }
          this.cd.detectChanges();

        });
        break;
      }
      default: {
        break;
      }
    }
  }
  public reset(): void {
    this.current = "";
    this.cd.detectChanges();
  }
  onChangeSubmit() {
    this.updatelayers();
  }

  updatePinItems(item){
    // check if pinItems already exists; it show layer already added in config
    if(this.pinItems && this.pinItems.length) {
      this.pinData.forEach(layer => {
        // check layer id
        if (layer.id === this._mapService.mainLayerId) {
          // if already exist remove; if not the add
          if (layer.pinItems.includes(item)) {
            const index = layer.pinItems.indexOf(item);
            if (index !== -1) {
              layer.pinItems.splice(index, 1);
            }            
          } else {
            layer.pinItems.push(item);
          }
          const data = {"project.layers.feature.pinItems": this.pinData};
          this.layerConnection.updateConfigSetting_deprecated('layers',data).subscribe(res => {
            this.getPinItems();
          })
        }
      })
    } else {
      // set the pinItem object if any pinItem is added for the first time in layer  
      const pinItem = {id:this._mapService.mainLayerId,layer_name:this._mapService.mainLayers.values_.name,pinItems:[item]}
      this.pinData.push(pinItem);
      const data = {"project.layers.feature.pinItems": JSON.stringify(this.pinData)};
      this.layerConnection.updateConfigSetting_deprecated('layers',data).subscribe(res => {
        this.getPinItems();
      })
    }
  }

  getPinItems() {
    this.layerConnection.getConfig_deprecated('layers').subscribe((response: any) => {
      if (response && response["project.layers.feature.pinItems"]) {
        this.pinData = JSON.parse(response["project.layers.feature.pinItems"]);
        if (this.pinData && this.pinData.length) {
          this.pinItems = [];
          this.pinData.forEach(layer => {
            if (layer.id === this._mapService.mainLayerId) {
              this.pinItems = layer.pinItems;
              this.cd.detectChanges();
            }
          })
        }
      }
    })
  }

  onClickLayersGet() {
    // ngOnit is called to reload dialog items
    // for the relation-overview items
    this.ngOnInit();
    this.getPinItems();
    this.listload = false;
    this.noFeatureErrorMessage = true;
    this.responseLayers = this.newMapItem;
    this.layers = { layers: this.responseLayers };
    this.layerConnection.getLayerConection(this.layers)
      .pipe(
        tap((response: any) => {
          if (!response.message){
            this.layers = response;
          } else {
            this.layers = {...this.responseLayers, stakeholders: [], requirements: [], issues: [], complaints: [], activities:[], permits: []}
          }
          this.noFeatureErrorMessage = false;
          this.listload = true;
          this.cd.detectChanges();
        }),
        catchError((err) => {
          this.listload = true;
          // this.noFeatureErrorMessage = true;
          this.cd.detectChanges();
          throw err;
        })
      ).subscribe();
  }
  public createStakeholder(f) {
    this.listloadOverpass = false;
    const values = { values: f }
    this._actionsService.onClickOverPass(values);
    f.exist = true;
    setTimeout(() => {
      this.listloadOverpass = true;
      this.cd.detectChanges();
    }, 1500);

  }

  addLayerRelations(element: any) {
    if ((element.data && element.data.layers) || this.locatable_type === 'complaints') {
      // this.featureLayers = element.data.layers;
      this.relationLayers = [];
      if (this.locatable_type !== 'complaints') {
        element.data.layers.forEach((layer: any) => {
          this.relationLayers.push({ data: layer, show: false });
        })
      }
      else if (this.locatable_type === 'complaints') {
        element.layers.forEach((layer: any) => {
          this.relationLayers.push({ data: layer, show: false });
        })
      }
    }
  }

  toggleLayer(layer) {
    layer.show = !layer.show;
    this.showAllRelationLayers = this.relationLayers.some(layer => layer.show === true);
    this.featureLayers = [];
    this.featureLayers.push(layer)
    this.itemDetail.emit(this.featureLayers);
  }
  toggleAllLayers() {
    this.showAllRelationLayers = !this.showAllRelationLayers;
    this.relationLayers.forEach((layer, index) => {
      this.relationLayers[index].show = this.showAllRelationLayers;
    });
    this.itemDetail.emit(this.relationLayers);
  }

  public itemOpenlayerHover(layer: any) {
    if (layer.show) {
      this.featureLayers = [];
      this.featureLayers.push(layer)
      this.itemDetail.emit(this.featureLayers);
    }
  }
}
