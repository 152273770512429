import {NgForm} from '@angular/forms';

export function scrollToError(form?: NgForm) {
  if (form) {
    for (const c in form.controls) {
      form.controls[c].markAllAsTouched();
    }
  }

  const errorELements = document.querySelectorAll('.ng-invalid');
  if (errorELements && errorELements.length > 0) {
    errorELements[errorELements.length - 1].scrollIntoView({ behavior: 'smooth' });
  }
}
