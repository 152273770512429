<div id="rightpanel" class="sidebar collapsed">
    <!-- Nav tabs -->
    <div class="sidebar-tabs">
        <ul role="tablist">
            <li [ngClass]="showToggle ? 'items-btn' : 'items-btn hidden'">
                <a class="d-cursor-hand open-right-panel" href="#items" role="tab">
                    <mat-icon>expand_more</mat-icon>
                    <mat-icon class="expand">expand_more</mat-icon>
                    <span class="items-btn-txt">Items</span>
                    <mat-icon class="arrow">arrow_drop_down</mat-icon>
                </a>
            </li>

        </ul>

    </div>

    <!-- Tab pane Items -->
    <div class="sidebar-content">
        <div class="sidebar-pane" id="items">
            <h1 class="sidebar-header">
                <span class="sidebar-close" *ngIf='!hideBack' #closeLayer>>></span>
            </h1>
            <ng-content></ng-content>
        </div>

    </div>
</div>