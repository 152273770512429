<div class="col-xs-12">
  <div [ngClass]="margin? 'panel-margin' : 'panel'">
    <mat-progress-bar *ngIf="isProcess" mode="indeterminate"></mat-progress-bar>
      <div class="col-xs-12 panel" *ngIf="!isProcess && workflows && workflows.length" id="chartContainer">
        <!-- <span>
          <button *ngIf="!isFullScreenMode" style="margin-top: 10px; margin-bottom: 10px;" type="button" mat-stroked-button (click)="updateWorkflows()">Update Workflows</button>
        </span> -->
        <span>
          <!-- Toggle Button for True/False -->
          <mat-slide-toggle [(ngModel)]="isToggled" (change)="onToggleChange($event.checked)">
            <span class="toggle-label ml-6">{{ 'actions.draw_dependency' | transloco }}</span>
          </mat-slide-toggle>
        </span>
        <i (click)="toggleFullScreen()" class="uil uil-expand-arrows-alt unicon-icon" style="cursor:pointer; position: absolute; top: 10px; right: 40px; z-index: 10;">
        </i>
        <i [ngClass]="isExpanded ? 'uil uil-angle-up unicon-icon toggle-expand' : 'uil uil-angle-down unicon-icon toggle-expand'" (click)="isExpanded = !isExpanded">
        </i>

        <div [ngClass]="!isExpanded ? 'hidden' : ''">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [constructorType]="'ganttChart'"
              [options]="chartOptions"
              (chartInstance)="chartCallback($event)"
              class="highcharts-figure"
              [ngStyle]="tableStyle"
            >
            </highcharts-chart>
        </div>
      </div>
      <div *ngIf="!isProcess && !workflows.length" class="empty-oc-height-set w-100 text-center empty-padding">
        <div>
            <img src="assets/png/empty-state-no-issues.png" class="empty-state-image">
            <h2>{{'dashboard.nodata.workflows_header' | transloco}}</h2>
            <div>
                <p class="pb-10">{{'dashboard.nodata.worflows_description' | transloco}}</p>
                <a (onClosed)="loadWorkflows(true)" newEntity="workflow" mat-flat-button>
                    <i class="d-text-middle uil uil-plus-circle unicon-icon"></i>
                    <span class="hidden-xs hidden-sm">Workflow</span>
                </a>
            </div>
        </div>
    </div>
  </div>

</div>
