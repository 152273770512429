<div *transloco="let t">
    <div>
        <h4>{{ data.title }}</h4>
    </div>
    <div class="popup-body">
        {{ data.body}}
    </div>
    <div class="popup-footer">
        <div class="dialog-button-row">
            <button type="button" class="mat-flat-button" (click)="close()">{{'close' | transloco | capitaliz}}</button>
        </div>
    </div>
</div>