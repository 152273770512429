import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { untilDestroyed } from 'ngx-take-until-destroy';
import posthog from 'posthog-js';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { InAppChatService } from 'src/app/core/service/api/in-app-chat.service';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { deleteConfirmationResponce } from 'src/app/core/util/deepCopy';
import { integrationDataMapInertial } from 'src/app/core/util/inappmessages';
import { inAppChatComplaintParam, integrationChatSetupWithComplaints, integrationConnection, integrationError, integrationValidation } from 'src/app/core/util/integration';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { globalSubprojectUserSaprator, selectedSubprojectUsersSaprator, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { ComplaintContactModalComponent } from 'src/app/shared/components/modal/complaint-contact-modal/complaint-contact-modal.component';
import { EmployeeNewComponent } from 'src/app/shared/components/modal/contact-persons-new/employee-new.component';
import { InAppChatComponent } from 'src/app/shared/components/modal/in-app-chat/in-app-chat.component';
import { GenericMultiselectCheckboxComponent } from 'src/app/shared/components/other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { NewEntityData } from 'src/app/shared/directive/new-entity.directive';
import { ProjectConfigsQuery } from '../../../../../akita/project-configs/state/project-configs.query';
import { Decision } from '../../../../../akita/requirements/state/decision.model';
import { Permission } from "../../../../../core/enum/permission";
import { ThemeType } from "../../../../../core/enum/theme-type";
import { DecisionsService } from "../../../../../core/service/api/decisions.service";
import { DocumentsService } from "../../../../../core/service/api/documents.service";
import { LogsService } from "../../../../../core/service/api/logs.service";
import { PubsubService } from "../../../../../core/service/api/pubsub.service";
import { CachedService } from '../../../../../core/service/common/cached.service';
import { WithParams } from "../../../../../core/service/common/with-params";
import { Page } from "../../../../../core/util/abstract/page";
import { dateIso } from "../../../../../core/util/dateIso";
import { ngModelCopy } from "../../../../../core/util/ngModelCopy";
import { showFeedback, showFeedbackError, showFeedbackSaved } from '../../../../../core/util/notification';
import { Allowed } from "../../../../../decorator/allowed-decorator";
import { Globals } from "../../../../../globals";
import { LogEditComponent, LogEditData } from "../../../../../shared/components/modal/contact-moments-edit/log-edit.component";
import { DeletionModalComponent } from "../../../../../shared/components/modal/deletion-modal/deletion-modal.component";
import { SimpleMatPaginationComponent } from "../../../../../shared/components/simple-mat-pagination/simple-mat-pagination.component";
import { MapService } from "src/app/core/service/api/map.service";
import { NewWorkflowComponent } from "src/app/shared/components/modal/new-workflow/new-workflow.component";

@Component({
  selector: 'app-klantwensen-detail',
  templateUrl: './klantwensen-detail.component.html',
  styleUrls: ['./klantwensen-detail.component.css']
})
export class KlantwensenDetailComponent extends Page {

  pageSubTitle = "Terug naar overzicht";
  pageTitle = "klanteis";
  name: string;
  backToOverview = true;
  decisionId: number;
  decision: Decision;
  subprojectsSet:any[]=[];
  public stakeholders: any[];
  shownStakeholders = [];
  shownLogs = [];
  shownIssues = [];
  shownRelatics = [];
  itemsPerPage = 5;
  themes = [];
  themeType = 'themedecisions';
  requirementsPhases = ThemeType.RequirementsPhases
  selectedTheme: any[]=[];
  selectedObject: any[]=[];
  selectedPhases: any[]=[];
  public employees: any[];
  projectConfig: any;
  integrateWithRelatics = false;
  logs = [];
  decisions = [];
  issues = [];
  actions = [];
  entities: any
  loaded = false;
  showStatusField = false;
  public reducer:any=true;
  public users: any[];
  public userload:boolean=false;
  dateFilter = 'no-date';
  chipDisabler:any[]=[];
  chipDisablerUser:any[]=[];
  userSelection:any[]=[];
  public integrationType:any = {type:'', error_type:'', chatShow: false, chatClosed: 0, chatIcon:''};
  public integrationsEnabled:boolean=false;
  errorMessage_userleve:boolean=false;
  integration_themeDisable:boolean=false;
  public integrationDocuments:any[]=[];
  complaintMessagesParams={
    ticket_id:0,
    type:''
  }
  firstload:boolean=false;
  public firstMessage: any[]=[];
  @ViewChild('pagination_decisionLogs', { static: false }) paginationLogs: SimpleMatPaginationComponent;
  @ViewChild('pagination_demands', { static: false }) paginationDemands: SimpleMatPaginationComponent;
  @ViewChild('pagination_relatics', { static: false }) paginationRelatics: SimpleMatPaginationComponent;
  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;

  constructor(
    private _decisionsService: DecisionsService,
    private _documentsService: DocumentsService,
    private cd: ChangeDetectorRef,
    public _inAppChatService: InAppChatService,
    private _logsService: LogsService,
    private cachedService: CachedService,
    private subProjectQuery: SubprojectQuery,
    public route: ActivatedRoute,
    public router: Router,
    public pubsub: PubsubService,
    public globals: Globals, private _pusherService: PusherService,
    private dialogRef: MatDialogRef<KlantwensenDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Decision,
    private _projectConfigsQuery: ProjectConfigsQuery,
    public dialog: MatDialog, private _mapService: MapService) {
    super(pubsub, globals);
    this.projectConfig = globals.projectConfig;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {

    this.subprojectSetup();
    this.name = this.data.title;
    this.cachedService.getUsers().subscribe(
      (users) => {
        this.users = users;
        this.userload=true;
        this.usersSetup();
      }
    );
    posthog.capture('customer_requirement-detail')
    this._decisionsService.getDecision(this.data.id, ['phases'], true).subscribe((decision: any) => {
      this._mapService.setData(decision.locations);
      this.decisionId = decision["id"];
      this.parseDecision(decision);
      forkJoin([
        this.cachedService.getActionsRelations(),
        this.cachedService.getIssuesRelations(),
        this.cachedService.getDecisionsRelations(),
        this.cachedService.getComplaintsRelations(),
        this.cachedService.getLogsRelations(),
        this.cachedService.getDocumentsRelations(),
         // Check if 'permit' exists in the modules, otherwise skip the API call
        this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
        ? this.cachedService.getPermitsRelations() 
        : of([])
      ]).subscribe(([tasks, issues, requirements, complaints, contactmoments, documents, permits]) => {
        this.entities = { tasks, issues, requirements, complaints, contactmoments: contactmoments, documents: documents, permits: permits }
        this.actions = tasks;
        this.issues = issues;
        this.decisions = requirements;
        this.logs = contactmoments;
        this.loaded = true;
      });
      this.integrationType=integrationChatSetupWithComplaints(decision);
      if(this.integrationType){
        this.complaintMessagesParams=inAppChatComplaintParam(decision)
        if(integrationValidation(this.globals,this.integrationType.type,this.integrationType.error_type,this.errorMessage_userleve)==='project'){
          if(this.integrationType.chatShow){
            this.integrationsEnabled=true;
            if (!integrationConnection(this.globals,this.integrationType.type, this.decision)) {
              this.integrationsEnabled= false;
            }
          }
          if(decision.ticket_id && this.integrationsEnabled){
            if(decision.entry_type=='smart_reporting'){
              this.smartReporting();
            }
            this.getInAppMessages(this.complaintMessagesParams, this.firstload);
            this.firstload=true;
            this.cd.detectChanges();
          }
        }else{
          this.errorMessage_userleve=integrationError(this.globals,this.integrationType.type);
          if(this.globals.user.configs[this.integrationType.error_type]==1 && !this.errorMessage_userleve){
            if(this.integrationType.chatShow){
              this.integrationsEnabled=true;
              if (!integrationConnection(this.globals,this.integrationType.type, this.decision)) {
                this.integrationsEnabled= false;
              }
            }
            if(decision.ticket_id && this.integrationsEnabled){
              if(decision.entry_type=='smart_reporting'){
                this.smartReporting();
              }
              this.userAuthenticate();
            }
            this.cd.detectChanges();
          }
        }
      }
      this.setLodedSubproject();
      this.setLodedSubprojectUsers();
      this.relaticsValidation();
    });

    this._projectConfigsQuery.selectFirst()
      .pipe(untilDestroyed(this))
      .subscribe(
        config => {
          if(config){
            if (config['services.relatics.decisions.enabled'] && config['services.relatics.enabled']) {
              this.integrateWithRelatics = true;
            }
          }
        }
      );

    this.cachedService.getStakeholdersRelations()
      .pipe(untilDestroyed(this))
      .subscribe(stakeholders => {
        this.stakeholders = stakeholders;
      });

    this.cachedService.getEmployees().subscribe(
      employees =>
      this.employees = employees);

  }

  private parseDecision(decision: any) {
    this.decision = decision;
    if (this.paginationDemands) {
      this.paginationDemands.paginator.firstPage();
    }

    if (this.paginationRelatics) {
      this.paginationRelatics.paginator.firstPage();
    } else if (this.decision && this.decision.relatics.length === 0) {
      this.shownRelatics = [];
    }

    this.selectedTheme = this.decision.theme_requirements && this.decision.theme_requirements.length > 0 ?
      this.decision.theme_requirements :
      [];
    this.selectedObject = this.decision.types && this.decision.types.length > 0 ?
      this.decision.types[0] :
      [];

    this.selectedPhases = this.decision.phases && this.decision.phases.length > 0 ?
      this.decision.phases :
      [];

    this.decision.start_date = dateIso(this.decision.start_date, true, this.globals.timezone);
    this.decision.end_date = dateIso(this.decision.end_date, true, this.globals.timezone);

    if (this.decision.start_date && this.decision.end_date) {
      this.dateFilter = 'ranged-date';
    } else if (this.decision.start_date) {
      this.dateFilter = 'fixed-date';
    } else {
      this.dateFilter = 'no-date';
    }

    if (this.decision.contactpersons)
      this.decision.contactpersons.selected = this.decision.contactpersons.stakeholders.filter(x => this.decision.stakeholders.find(s => s.id == x.id));

  }

  public loadDecision() {
    if (!this.decisionId) {
      return;
    }

    this._decisionsService.getDecision(this.decisionId).subscribe(
      (decision: any) => {
        this.parseDecision(decision);
      });
  }

  @Allowed(Permission.DecisionsUpdate)
  public updateDecision(reload = false) {
    if (this.dateFilter === 'no-date') {
      this.decision.start_date = null;
      this.decision.end_date = null;
    } else if (this.dateFilter === 'fixed-date') {
      this.decision.end_date = null;
    } else {
      if (this.decision.start_date && this.decision.end_date && this.decision.start_date >= this.decision.end_date) {
        showFeedback('Einddatum mag niet eerder dan begindatum', 'error');
        return;
      }
    }
    this.name = this.decision.title;
    if(!this.data.mapDetail){
      this.pubsub.navigation.current.title = this.name;
      this.pubsub.navigation.current.data.title = this.name;
    }
    this.onSaveDocumentRemoval();
    this._decisionsService.updateDecision(this.decisionId, this.decision).subscribe(
      (res: any) => {
        if (this._mapService.circleVectorSource && this._mapService.circleVectorSource.getFeatures().length > 0) {
          this._mapService.circleVectorSource.removeFeature(this._mapService.searchFeature);
        }
        // this.decision.locations = res.locations
        showFeedbackSaved();
        this._pusherService.onCangeStatus();
        if (reload) {
          this.loadDecision();
        }
      },
      (e: HttpErrorResponse) => {
        if (e.status !== 500 && e.error && e.error.message) {
          showFeedback(`Er is en error opgetreden (${e.error.message})`);
        } else {
          showFeedbackError(e);
        }
      }
    );
  }

  @Allowed(Permission.DecisionsUpdate)
  public updateCoordinate() {
    this.updateDecision();
  }

  public updateStakeholdersLogs(update) {

    const id = update.id;
    const logs = update.logs;

    return this._decisionsService.updateDecision(id, logs)
      .subscribe(() => {
        showFeedbackSaved();
        this.loadDecision();
      });

  }

  // UPDATE LOGS & STAKEHOLDERS


  public setShownLogs(logs) {
    this.shownLogs = logs;
    this.globals.cdRef.detectChanges();
  }

  public setShownIssues(issues) {
    this.shownIssues = issues;
    this.globals.cdRef.detectChanges();
  }

  public setShownStakeholders(stakeholders) {
    this.shownStakeholders = stakeholders;
    this.globals.cdRef.detectChanges();
  }

  public editLog(log: any): void {

    this._logsService.getLog(log.id, new WithParams().with(
      ['series', 'stakeholders', 'users', 'contactpersons', 'tasks', 'issues',
        'complaints', 'activities', 'requirements', 'documents', 'workflows']
    )).subscribe((editedLog: any) => {
      const data: LogEditData = {
        log: editedLog,
        connectStakeholders: true,
      };

      const editDialog = this.dialog.open(LogEditComponent, {
        data: data,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%'
      });

      editDialog.afterClosed().subscribe((dialogData: {
        log: any,
        updated: boolean,
      }) => {
        if (dialogData && dialogData.updated) {
          ngModelCopy(log, dialogData.log);
        }
      });
    });
  }

  public updateSelectedTheme(theme) {
    this.decision.theme_requirements = theme;
    this.updateDecision();
  }

  public updateSelectedObject(object) {
    this.decision.types = [object];
    this.updateDecision();
  }
  /*
    On update Selected Phases
  */
  public updateSelectedPhases(phase) {
    this.decision.phases = phase;
    this.updateDecision();
  }
  public getStatusClass(status) {
    if (status === 'initial') {
      return 'alert alert-primary';
    } else if (status === 'afgewezen') {
      return 'alert alert-danger';
    } else if (status === 'expired') {
      return 'alert alert-expired';
    } else if (status === 'next_phase') {
      return 'alert alert-next_phase';
    }
      return 'alert alert-success';

  }

  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'requirements', parent: this.decision},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.decision.workflows.push(response);
        this.updateDecision();
      }
    });
  }

  public deleteDecision(decision) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(decision,this.router,'decisions')
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._decisionsService.removeDecision(decision)
          .subscribe(
            () => {
              this.close()
            }
          );
      }
    });
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  updateSubprojects() {
    setTimeout(() => {
      this.pubsub.updateSubprojects(this, "updateDecision", this.decision.subprojects)
    }, 500);
  }

  updateEntities(event: any) {
    this.decision[event.type] = event.selected;
    this.updateDecision();
  }
  public relaticsValidation(){
    if(this.decision.relatics_uuid){
      if(this.globals.relaticsColumn.decisions){
        let columns;
        const dialogcolumns=[];
        // eslint-disable-next-line prefer-const
        columns=this.globals.relaticsColumn.decisions.columns;
        columns.map((element)=>{
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer=dialogcolumns.reduce((ac,a) => ({...ac,[a]:a}),{});
        this.cd.detectChanges();
      }
    }
  }
  public subprojectSetup(){
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects,'decisions:update')
  }
  public setLodedSubproject(){
    this.chipDisabler=[];
    this.chipDisabler=selectedSubprojectSaprator(this.subprojectsSet,this.decision.subprojects)
  }
  public usersSetup(){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
      this.users=globalSubprojectUserSaprator(this.users,activeSubProject)
    }
  }
  public setLodedSubprojectUsers(){
    if(this.users){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
    this.chipDisablerUser=[];
    this.chipDisablerUser=selectedSubprojectUsersSaprator(this.users,this.decision.users);
    }
  }
  }

  //employed Set
  public employeeSet(): void {
    if (this.decision.contactpersons) {
      this.decision.contactpersons.selected = this.decision.contactpersons.stakeholders.filter(x => this.decision.stakeholders.find(s => s.id == x.id));
      if (this.decision.contactpersons.stakeholders && this.decision.contactpersons.stakeholders.length == 1) {
        this.decision.stakeholders = this.decision.contactpersons.stakeholders;
        if (!this.stakeholders.some(s => this.decision.stakeholders[0].id === s.id)) {
          this.stakeholders.push(this.decision.stakeholders[0]);
        }
      }
      this.decision.residents = null;
    }
    this.cd.detectChanges();
    this.updateDecision();
  }
  //stakeholder Set
  public stakeholderSet(): void {
    if (this.decision.stakeholders) {
      this.decision.residents = null;
    }
    this.updateDecision();
  }

  //stakeholder update
  public onStakeholdersUpdated(event): void {
    if (event.action == "add"){
      this.decision.stakeholders.push(event.data);
      this.stakeholderMultiselect.writeValue(this.decision.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    else {
      this.decision.stakeholders = this.decision.stakeholders.filter( s=>s.id != event.data.id);
      this.stakeholderMultiselect.writeValue(this.decision.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    this.updateDecision();
  }

  addManualInformationAsContactperson() {
    const data: NewEntityData = {
      params: {
        name: this.decision.manual_contact_name,
        email: this.decision.manual_contact_email,
        telephone: this.decision.manual_contact_telephone,
        type: 'decisions'
      },
      options: {},
    };
    // Open modal to Create newEmployee
    const dialog = this.dialog.open(EmployeeNewComponent, modalConfig({
      data: data,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));

    dialog.afterClosed().subscribe((response: any) => {
      this.cachedService.getEmployees().subscribe(
        employees => {
          this.employees = employees;
          for ( const employee of employees) {
            if (employee.id === response.employee.id) {
              this.decision.contactpersons = employee;
              this.decision.stakeholders = [...this.decision.stakeholders, ...response.employee.stakeholders];
              this.decision.manual_contact_name = null;
              this.decision.manual_contact_email = null;
              this.decision.manual_contact_telephone = null;
              this.employeeSet();
            }
          }
        }
      );
    })
  }

  public editContact(): void {
    const modal = this.dialog.open(ComplaintContactModalComponent, modalConfig({
      data: {
        type: 'decisions',
        complaint: this.decision,
        employees: this.employees,
        stakeholders: this.stakeholders,
        relatics:this.reducer,
      }
    }, ModalEnum.ModalDefault));

    modal.afterClosed().subscribe((decision: Decision) => {
      if (decision) {
        this.parseDecision(decision);
        this.updateDecision();
      }
    });
  }

  //chat open modal
  public openChat(){
    const dialog = this.dialog.open(InAppChatComponent, modalConfig({
      data:{
        complaintMessagesParams:this.complaintMessagesParams,
        isclose: this.decision.source_status === 'open' ? false: true,
        integrationType:this.integrationType.type,
        inAppIcon:this.integrationType.chatIcon,
        firstMessage:this.firstMessage,
        expand: true
      },
      panelClass: ['animate__animated', 'animate__slideInUp'],
      disableClose: true,
      closeOnNavigation: true
    }, ModalEnum.SidebarChatSmallResponsive));
    dialog.afterClosed().subscribe((response: any) => {
      if(response){
        this.onCloseChat(response);
      }
    });
  }

  // smart reporting document
  public smartReporting(): void{
    this.integration_themeDisable=true;
    this._inAppChatService.getSmartReporting(this.complaintMessagesParams.type,this.complaintMessagesParams.ticket_id)
    .subscribe(res=>{
      this.firstMessage=integrationDataMapInertial(res);
      if(res.attachment){
        this.attachedDocumentToComplaint(res,true)
      }
    })
  }

  // on save remove document
  public onSaveDocumentRemoval(){
    if(this.decision.documents.length){
      this.decision.documents=this.decision.documents.filter(element=>{
        if(!this.integrationDocuments.includes(element.id)){
          return element;
        }
      })
    }
  }
  // bouwapp user authenticate function
  public userAuthenticate(): void{
    if(this.integrationType.type=="bouwapp"){
      this._inAppChatService.userAuthenticate(this.globals.user.id,this.decision.project_code).subscribe((res:any)=>{
        if(res.authorized)
          this.userChatButtonShow()
      })
    }
  }

  // integration in app chat user show
  public userChatButtonShow(){
    if(this.integrationType.chatShow){
      this.integrationsEnabled=true;
    }
    this.getInAppMessages(this.complaintMessagesParams, this.firstload);
    this.firstload=true;
  }

  //get in app messages in chat
  public getInAppMessages(message,value){
    if(!value){
      if(this.integrationType.type=='bouwapp'){
        this.getBouwappMessages(message)
      }
    }
  }

  //get bouwapp messages
  public getBouwappMessages(message){
    if(integrationValidation(this.globals,this.integrationType.type,this.integrationType.error_type,this.errorMessage_userleve)==='project'){
      this._inAppChatService.getMessages(message.ticket_id,message.type)
      .pipe(
        tap((res)=>{
          this.attachedDocumentToComplaint(res);
        }),
      ).subscribe();
    }else{
      this._inAppChatService.getUserMessages(message.ticket_id,message.type,this.globals.user.id)
      .pipe(
        tap((res)=>{
          this.attachedDocumentToComplaint(res);
        }),
      ).subscribe();
    }
  }

  // chat document attached
  public attachedDocumentToComplaint(inAppMessages,smart_reporting=false){
    const documentset=[]
    if(smart_reporting){
      documentset.push({id:inAppMessages.id,
        path:inAppMessages.attachmentThumbnails.full,
        name:inAppMessages.attachment,
        type:'bouwapp'
      })
    }else{
      inAppMessages.filter(element=>{
        if(element.attachment!=null){
          documentset.push({id:element.id,
            path:element.attachmentThumbnails.full,
            name:element.attachment,
            type:'bouwapp'
          })
        }
      })
    }
    documentset.forEach(element=>{
      this.decision.documents.push(element);
      this.integrationDocuments.push(element.id)
    })
  }

  //after chat closed
  public onCloseChat(response): void{
    if(response.is_closed){
      this.decision.source_status=response.is_closed? 'closed': 'open';
      this.decision.closed_date=response.closed_date;
      this.decision.closed_description=response.closed_description;
      this.updateDecision();
    }
  }
}
