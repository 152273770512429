export enum DocuploadType {
  issues = 'issues',
  stakeholders = 'stakeholders',
  issuedialogs = 'issuedialogs',
  logs = 'contactmoments',
  middel = 'content',
  permits ='permits',
  calendars = 'calendars',
  decisions = 'requirements',
  complaints = 'complaints',
  actions = 'tasks',
  documents = 'documents'
}
