<div class="row popup-container" *transloco="let t">
    <div class="popup-header row no-margin ">
        <div class="col-xs-12 mb-5">
            <h1><strong>{{ t('admin_page.integration') | titlecase}} </strong><span class="">{{data.name}}</span></h1>
            <button type="button" class="close " (click)="close()"><span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span><span class="sr-only">Close</span></button>
        </div>
    </div>
    <form #form="ngForm">
      <div class="popup-body" appScroll>
        <div class="col-xs-12 mb-5">
            <h5> {{ t('settings.connection') | titlecase}}</h5>
            <ng-container *ngIf="data.type=='project' || data.type=='company'; else personal">
                <div class="subtext"> {{ t('settings.freshdesk.connection_subtext')}}</div>
            </ng-container>
            <ng-template #personal>
                <div class="subtext"> {{ t('settings.freshdesk.connection_personal_subtext')}}</div>
            </ng-template>
            <div class="full-width set-border-connection-relatic mb-10">
                <div class="row mt-10">
                    <ng-container *ngIf="data.type=='project' || data.type=='company'; else personalBody">
                        <div class="col-md-12 col-sm-12 col-xs-12 display-flex">
                            <mat-form-field>
                                <input type="text" matInput class="" name="Domain" [(ngModel)]="globals.projectConfigs[integrationData.domain]" [placeholder]="('settings.freshdesk.domain_placeholder') | transloco" (change)="domainValidation()" required/>
                            </mat-form-field>
                            <span class="ml-10 align-set">.freshdesk.com</span>
                            <button *ngIf="freshdeskenable && !errorMessage" class="connection-button-position-set btn btn-primary color-btn-primary-mapa align-set" type="button" (click)='submit()'>
                                <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
                                {{ t('settings.disable') | titlecase}}
                            </button>
                            <button *ngIf="freshdeskenable && errorMessage" class="connection-button-position-set btn btn-primary color-btn-primary-map-error align-set" (click)='submit()' type="button">
                                <mat-icon class="d-text-middle icon-connect">error</mat-icon>
                                {{ t('settings.connect_error') | titlecase}}
                            </button>
                        </div>
                        <mat-hint *ngIf="validationTextShow" class="text-red p-hint-set">{{'settings.freshdesk.invalid_url' | transloco}}</mat-hint>
                        <div class="col-md-12 col-sm-12 col-xs-12 display-grid w-100">
                            <mat-form-field>
                                <input type="text" matInput class="full-width" name="key" [(ngModel)]="globals.projectConfigs[integrationData.key]" [placeholder]="('settings.freshdesk.api_key_placeholder') | transloco" required/>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-template #personalBody>
                        <div class="col-md-12 col-sm-12 col-xs-12 display-flex">
                            <mat-form-field>
                                <input type="text" matInput class="full-width" name="key" [(ngModel)]="personalData" [placeholder]="('settings.freshdesk.api_key_placeholder') | transloco" required/>
                            </mat-form-field>
                            <button *ngIf="freshdeskpersonalenable && !personalerrorMessage" class="connection-button-position-set btn btn-primary color-btn-primary-mapa align-set" type="button" (click)='submit()'>
                                <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
                                {{ t('settings.disable') | titlecase}}
                            </button>
                            <button *ngIf="freshdeskpersonalenable && personalerrorMessage" class="connection-button-position-set btn btn-primary color-btn-primary-map-error align-set" (click)='submit()' type="button">
                                <mat-icon class="d-text-middle icon-connect">error</mat-icon>
                                {{ t('settings.connect_error') | titlecase}}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
      </div>
      <div class="popup-footer">
          <button type="button" mat-stroked-button data-dismiss="modal" (click)='close()'>{{ t('close') | capitaliz }}</button>
          <button type="submit" mat-flat-button class="ml-10" (click)="submit()" [disabled]="form.invalid || domainValidate">{{ t('save') | capitaliz }}</button>
      </div>
    </form>
</div>
