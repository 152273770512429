import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsService } from '../../../../akita/tasks/state/actions.service';
import { Globals } from '../../../../globals';
import { AuthPublicPacingService } from '../../../../core/service/auth/auth-public-pacing.service';
@Component({
  selector: 'app-callback-publicfacing-api',
  templateUrl: './callback-publicfacing-api.component.html',
  styleUrls: ['./callback-publicfacing-api.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallbackPublicfacingApiComponent implements OnInit {
  public params:any;
  public code:any;
  public show:boolean=false;
  // eslint-disable-next-line no-empty-function
  constructor(private cd: ChangeDetectorRef,public auth: AuthPublicPacingService,public _rout:Router, public global: Globals,public _action:ActionsService) { }

  ngOnInit() {
    if(this._action.subPublicFacing==undefined){
      this._action.subPublicFacing=this._action.invokeFunctionPublicFacing.subscribe((response)=>{
        this.code=response[2]
        this.show=true;
        this.cd.detectChanges();
        this._action.subPublicFacing=undefined;
      });
    }
     this.params = window.location.search;
    if (!this.params.includes('code=')) {
      this.auth.login();
    }
  }
  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
