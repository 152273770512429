import collect from 'collect.js';
export function globalSubprojectUserSaprator(users,activeSubProject): any {
    collect(users).transform((item:any) => {
        item.is_permission = !(collect(activeSubProject.users).where('id' , item.id).count() !== 0);
        return item;
      }).toArray();
    return users;
}
export function selectedSubprojectUsersSaprator(users,decisionUser): any {
    const chipDisabler=[];
    users.forEach(element => {
        decisionUser.forEach((el:any) => {
          if(element.id==el.id){
            el.is_permission=element.is_permission;
          }
        });
    });
    decisionUser.forEach((element:any) => {
        if(element.is_permission){
         chipDisabler.push(element.name);
        }
    });
    return chipDisabler;
}
export function deletedUsersForClosedTickets(closed, users: any[]) {
  if(closed) {
    return users.filter(user => user.is_removed)
  }
  return [];
}
export function userRoleFinder(globals): any {
  let value=false;
  globals.user.scope.data.forEach(element => {
    if(element.project){
      if(element.roles.length==1){
        if(element.roles[0].name=='Reader'){
          value=true;
        }
      }
    }
  });
  return value;
}