<mat-progress-bar *ngIf="is_load" mode="indeterminate" class="mb-10"></mat-progress-bar>
<ng-container *ngIf="(warnings$ | async) as warnings">
  <ng-container *ngFor="let warning of warnings">
    <ng-container *ngIf="(integrationCheck === 'project' && (warning.warningable.type === arcGisintegrationType)) || (integrationCheck === 'personal' && warning.warningable.type === 'arcgis-user')">
      <div mat-dialog-title>
        <div class="row">
            <div class="col-md-9">
                <h4>{{'warnings.arcgis.'+info[warning.warningable_type].header | transloco}}</h4>
            </div>
            <div class="col-md-3">
                <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
                    <mat-icon class="d-text-middle">close</mat-icon>
                  </span><span class="sr-only">Close</span>
                </button>
            </div>
        </div>
      </div>
      <div mat-dialog-content>
        <p>{{'warnings.arcgis.'+info[warning.warningable_type].subHeader | transloco}}</p>
        <p>{{'warnings.arcgis.'+info[warning.warningable_type].description_1 | transloco}} {{getDaysDifferents(warning)}} {{'warnings.arcgis.'+info[warning.warningable_type].description_2 | transloco}}</p>
        <p>{{'warnings.arcgis.'+info[warning.warningable_type].reminder | transloco}}</p>
      </div>
      <div mat-dialog-actions class="pull-right">
        <button type="button" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="onSnooze(warning)">{{ 'snooze' | transloco | capitaliz}}</button>
        <button type="button" mat-flat-button class="text-capitalize pull-right ml-10 mt-10" (click)="integrationWarningRediect(warning)">{{ 'warnings.arcgis.'+info[warning.warningable_type].button | transloco | capitaliz }}</button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
