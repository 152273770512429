import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { SearchService } from 'src/app/core/service/api/search.service';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';

@Component({
  selector: 'app-recycle-bin-icon',
  templateUrl: './recycle-bin-icon.component.html',
  styleUrls: ['./recycle-bin-icon.component.sass'],
})
export class RecycleBinIconComponent implements OnInit {
  @Input() item: string = '';
  @Input() icon: boolean = false;
  @Input() option: string = ''

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter();

  public isExist: boolean = false;
  public paginator = new PaginationParams(1, 1)
  .orderBy('deleted_at')
  .deleteBy('1');

  // eslint-disable-next-line no-empty-function
  constructor(private _searchService: SearchService,private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.onLoadEntity();
  }
  //on load entity pagination
  public onLoadEntity(): void{
    this.isExist = false
    this._searchService.getRecycleBinPagination(this.paginator, this.item)
    .pipe(
      tap(response=>{
        if(response.data && response.data.length)
          this.isExist = true
        this.cd.detectChanges();
      }),
      catchError(async (err) => this.isExist = false)
    ).subscribe();
  }
}
