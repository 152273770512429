import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  // eslint-disable-next-line no-empty-function
  constructor(private sanitizer?:DomSanitizer){}

  transform(style) {
    let style_edit = '';
    
    if (style && style.includes('<svg')) {
      // If <img> tags are present, remove only <img> tags
      style_edit = style.replace(/<img[^>]*>/g, '');
    } else {
      // If no <img> tags, remove all other HTML tags
      style_edit = style ? style.replace(/<\/?[^>]+(>|$)/g, ''): '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(style_edit);
    //return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }

}
