import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subproject } from '../../../../akita/subproject/state/subproject.model';
import { Globals } from '../../../../globals';

@Component({
  selector: 'app-subproject-map-overview-modal',
  templateUrl: './subproject-map-overview-modal.component.html',
  styleUrls: ['./subproject-map-overview-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubprojectMapOverviewModalComponent {

  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public subProject: Subproject, public globals: Globals, public dialogRef: MatDialogRef<any>) { }

  public close(): void {
    this.dialogRef.close();
  }
}
