import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { translate } from '@jsverse/transloco';
import { collect } from 'collect.js';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { ActionsService } from 'src/app/akita/tasks/state/actions.service';
import { ReportService } from 'src/app/core/service/api/report.service';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { getFilterType, itemsFilterStatus } from '../../../../../core/util/itemsStatus';
import { AddStakeholdersActionComponent } from '../../../modal/add-stakeholders-task/add-stakeholders-action.component';

@Component({
  selector: 'app-relation-overview',
  templateUrl: './relation-overview.component.html',
  styleUrls: ['./relation-overview.component.sass'],
})
export class RelationOverviewComponent implements OnInit, AfterViewInit {
  @Input() type: string;
  @Input() entities: any = [];
  @Input() displayedColumns: any[] = [];
  @Input() isExist: boolean = false;
  @Input() isExpension: boolean = false;

  @Output() openDetail: EventEmitter<any> = new EventEmitter();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter();
  @Output() itemHandled: EventEmitter<any> = new EventEmitter()
  public filterItems: any[] = [];
  public selectFilter: string | boolean = 'all';
  public loading: boolean = false;
  public keyword: string = '';
  public filters: any = itemsFilterStatus;
  @ViewChild(MatSort) sort: MatSort;

  // eslint-disable-next-line no-empty-function
  constructor(public globals: Globals,private _actionsService: ActionsService, private cd: ChangeDetectorRef,private _reportsService: ReportService, private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.entities = new MatTableDataSource(this.entities);
    this.filterItems = this.entities.data;
    if(this.type === 'tasks') {
      this.selectFilter = false;
      this.onFilter()
    }
    if(this.type === 'issues') {
      this.selectFilter = 'open';
      this.onFilter()
    }
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.entities.sort = this.sort;
  }
  //on apply filter item
  public applyFilter(event): void{
    this.entities.filter = event.trim().toLowerCase();
  }
  //on cancle search
  public onCancel(): void{
    this.keyword='';
    this.entities.filter = '';
  }
  //on filter selection
  public onFilter(): void{
    if(this.selectFilter !== 'all'){
      this.entities.data = collect(this.filterItems).filter((value, key) => value[getFilterType(this.type)] === this.selectFilter).all();
      return;
    }
    this.entities.data = this.filterItems;

  }
  //on handleAction
  public handleAction(data): void{
    this._actionsService.update(data)
      .subscribe(
        () => {
          const message: string = data.handled ? translate('text.action_is_handled') : translate('text.action_is_recovered');
          showFeedbackSaved(message);
          this.itemHandled.emit(data)
          this.onFilter()
        },
      );
  }
  //on open detail panel
  public nav(value){
    this.openDetail.emit(value);
  }
  //on delete
  onDelete(value): void{
    this.itemDelete.emit(value);
  }
  //on set action class
  public actionClass(action): string {
    const now = moment();
    let className = "";
    if (moment(action.timestamp).isBefore(now)) {
      className = 'late';
    } else {
      const currentWeek = moment().week();
      if (moment(action.timestamp).week() !== currentWeek) {
        className = 'upcoming';
      }
      if (moment(action.timestamp).week() == currentWeek) {
        className = 'week';
      }
    }
    return className;
  }
  //on download log file
  public downloadLog(logId: number, event: MouseEvent): void {
    event.stopPropagation();
    this._reportsService.download('log', 'word', {
      id: logId
    }).subscribe(
      (report: any) => {
        saveAs(report.body, report.filename);
      });
  }
  //show contact persons name connected to tasks
  public showContactPersonsName(task): string{
    const names = [];
    if(task.contactpersons_contactmoments && task.contactpersons_contactmoments.length){
      task.contactpersons_contactmoments.forEach(element => {
        if(element.pivot.contactmoments_id){
          if(element.pivot.contactmoments_id === task.pivot.contactmoments_id){
            names.push(`${element.first_name ? element.first_name : '' } ${element.last_name}`);
          }
        }
      });
    }
    return names.toString();
  }
  // on edit
  onEdit(entity,index){
    const persons = [];
    const contactmoments = [];
    if(entity.contactpersons_contactmoments && entity.contactpersons_contactmoments.length){
      entity.contactpersons_contactmoments.forEach(element => {
        if(element.pivot.contactmoments_id){
          if(element.pivot.contactmoments_id === entity.pivot.contactmoments_id){
            persons.push(element);
            contactmoments.push(element.pivot.contactmoments);
          }
        }
      });
    }
    // eslint-disable-next-line no-unused-expressions
    persons ? entity.contactpersons = persons : '';
    // eslint-disable-next-line no-unused-expressions
    contactmoments ? entity.contactmoments = contactmoments : '';
    entity.relationActionContactPerson = true;
    const data = {
      params: entity,
      options: false,
    };
    this.dialog.open(AddStakeholdersActionComponent, modalConfig({
      data: data,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));
  }
}

