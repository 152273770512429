import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Subproject } from './subproject.model';
import {SubprojectState, SubprojectsStore} from './subprojects.store';

@Injectable({ providedIn: 'root' })
export class SubprojectQuery extends QueryEntity<SubprojectState, Subproject> {
  constructor(protected store: SubprojectsStore) {
    super(store);
  }

}
