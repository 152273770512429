import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import Feature from "ol/Feature";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import View from "ol/View";
import { FullScreen, defaults as defaultControls } from "ol/control";
import * as olExtent from "ol/extent";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";
import Point from "ol/geom/Point";
import Select from "ol/interaction/Select";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from 'ol/proj';
import { Cluster, OSM, Vector as VectorSource } from "ol/source";
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text
} from "ol/style";
import { parsemapItem } from "src/app/core/util/computemaplayers";
import {
  Coordinate,
  Location,
} from "../../../../../akita/locations/state/location.model";
import { PubsubService } from "../../../../../core/service/api/pubsub.service";
import { Page } from "../../../../../core/util/abstract/page";
import { Globals } from "../../../../../globals";

import { Router } from "@angular/router";
import collect from 'collect.js';
import moment from "moment";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { NgxXml2jsonService } from "ngx-xml2json";
import posthog from 'posthog-js';
import { BehaviorSubject, Observable, asapScheduler, combineLatest, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ProjectConfigsService } from "src/app/akita/project-configs/state/project-configs.service";
import { SubprojectQuery } from "src/app/akita/subproject/state/subprojects.query";
import { SubprojectsService } from "src/app/akita/subproject/state/subprojects.service";
import { MapService } from "src/app/core/service/api/map.service";
import { WithParams } from "src/app/core/service/common/with-params";
import { overpassApiQuery } from "src/app/core/util/Queries/overpass-query";
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { integrationError, integrationValidationArcGis } from "src/app/core/util/integration";
import { arcGisIntegrationSymbology, arcGisIntegrationSymbologyDefault, createMultiPolygon, getRegExpURL, openLayerMaping, openLayerPrivateMaping, setFeatureStyle, setItemsIcons, setMultipleSymobologyStyleColor, setTransprantFeatures } from "src/app/core/util/mapModal";
import { showFeedbackError } from "src/app/core/util/notification";
import { wmsFeatureget, wmsURLSet } from "src/app/core/util/openDataModals";
import { StakeholderNewMapComponent } from "src/app/shared/components/modal/stakeholder-new-map/stakeholder-new-map.component";
import { hexToRgb } from "../../../../../core/util/color";
import { ModalEnum, modalConfig } from "../../../../../core/util/modalConfig";
import { ActivityNewComponent } from "../../../../../shared/components/modal/activity-new/activity-new.component";
import { ComplaintNewComponent } from "../../../../../shared/components/modal/complaint-new/complaint-new.component";
import { IssueNewComponent } from "../../../../../shared/components/modal/issue-new/issue-new.component";
import { DecisionNewComponent } from "../../../../../shared/components/modal/requirement-new/decision-new.component";
import { StakeholderNewComponent } from "../../../../../shared/components/modal/stakeholder-new/stakeholder-new.component";
import { ItemsListComponent } from "../../component/items-list/items-list.component";
import { OpenlayersRightPanelComponent } from "../../openlayers-rightpanel/openlayers-rightpanel.component";
import { OpenlayersSidebarV2Component } from "../../openlayers-sidebar-v2/openlayers-sidebar-v2.component";
import { OpenDataLayers } from "./opendata-layers";
import { OpenlayersDrawing } from "./openlayers-drawing";
import { OpenlayersTooltip } from "./openlayers-tooltip";
import { MapDataService } from "src/app/core/service/api/map-data.service";
moment.locale("nl");

declare let ol: any;
declare let $: any;
export let isExtent: boolean = false;
@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent extends Page implements AfterViewInit {
  @ViewChild('popupContentContainer') popupContentContainer: ElementRef;
  public map: Map;
  public myElement: any
  public showPanel: boolean;
  public showMakerPopup: boolean = false;
  public overPassNodes: any = [];
  public overPassFeature: any;
  public sidebarButtons = ["layer", "draw"];
  public coordinate;
  public main_MapCoordinates: any;
  public randomId = Math.random().toString(36).substring(7);
  private basicColor: string = "#3C8CAA";
  private locationsSbj: BehaviorSubject<Location[]> = new BehaviorSubject<
    Location[]
  >([]);
  private layers = {};
  private overlay: Overlay;
  private vectorLayers = {};
  private kmls = {};
  relationLayer: any;
  private vector;
  private pinTooltipOverlay: any;
  private popupTimeout: any;
  private popupBeingShown = false;
  public activation = false;
  public openDataResponse: any;
  public locations$: Observable<Location[]> = this.locationsSbj.asObservable();
  public BBOX: any[] = [];
  showPopup: boolean = false;
  pageSubTitle = "";
  pageTitle = "";
  mainClass = "";
  featureNames: string[] = [];
  public showToggle: Boolean = false;
  public locale = {
    applyLabel: "Toepassen",
    cancelLabel: "Sluiten",
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: moment.localeData().firstDayOfWeek(),
  };
  public dateRange: {
    startDate: any;
    endDate: any;
  } = {
      startDate: "",
      endDate: "",
    };
  public owlDateRange: any[] = [];

  select: any;
  getCoordinate: any
  public arcGis_connectedItems = ['Activiteiten', 'Meldingen', 'Klanteisen', 'Klantwensen', 'Issues', 'Middelen', 'Stakeholders']
  public buttons: string[] = ["layer", "add-stakeholder"];
  public locatableType: string;
  public nohoverMapDetail: boolean = false;
  private drawing: OpenlayersDrawing;
  private selectedToolSbj = new BehaviorSubject(null);
  public layerLoadInterval: any[] = [];
  public layerSymbologyInterval: any[] = [];
  private selectedTool$: Observable<string> =
    this.selectedToolSbj.asObservable();
  private purposeToolSbj = new BehaviorSubject(null);
  public purposeTool$: Observable<string> = this.purposeToolSbj.asObservable();
  public arcGisLayers: any[] = [];
  @Input() mapData: any = null;
  @Input() isExtent: boolean = true;
  @Input() headerFalse: string = "";
  @Input() public hideSidePanel = false;
  @Output() openlayerralationLoader: EventEmitter<any> = new EventEmitter();
  @Output() public lineDrawEnd: EventEmitter<any> = new EventEmitter();
  @Output() public pointDrawEnd: EventEmitter<any> = new EventEmitter();
  @Output() public areaDrawEnd: EventEmitter<any> = new EventEmitter();
  @ViewChild(OpenlayersSidebarV2Component, { static: false })
  public sidebar: OpenlayersSidebarV2Component;
  @ViewChild(OpenlayersRightPanelComponent, { static: false })


  public rightpanel: OpenlayersRightPanelComponent;
  @ViewChild(ItemsListComponent, { static: false })
  public itemsList: ItemsListComponent;
  @ViewChild("mapContainer", { static: false })
  private mapContainer: ElementRef;
  @ViewChild(DaterangepickerDirective, { static: true })
  public pickerDirective: DaterangepickerDirective;
  @ViewChild("popup", { read: ElementRef }) popup: ElementRef;
  @ViewChild('customPopup') customPopupElement: ElementRef;
  public enableLayers: any[] = [];
  public arcGisToken: string;
  public errorIntegration: boolean = false;
  public errorIntegrationEnterprise: boolean = false;
  public isAdmin: boolean = false;
  public is_loadOverpassApi: boolean = false;
  public activeArcGis: string = 'arcgis';
  public geojsonArcGisType: string = 'pgeojson';
  public integrationCheck: string;
  private publicLayers: OpenDataLayers;
  private symbolicName: any[] = [];
  public openLayersResp: any[] = [];
  public tempVar: any = '1212';
  public layerItemClicked: boolean = false;
  constructor(
    public pubsub: PubsubService,
    private cd: ChangeDetectorRef,
    public globals: Globals,
    private dialog: MatDialog,
    public _rout: Router,
    public _projectConfigsService: ProjectConfigsService,
    private subProjectQuery: SubprojectQuery,
    public _subproject: SubprojectsService,
    public _mapServices: MapService,
    public _mapDataService: MapDataService,
    private ngxXml2jsonService: NgxXml2jsonService,
    private renderer: Renderer2, private el: ElementRef
  ) {
    super(pubsub, globals);

  }

  public ngOnInit(): void {
    isExtent = this.isExtent;
    this.errorIntegration = integrationError(this.globals, 'arcgis_enabled');
    this.errorIntegrationEnterprise = integrationError(this.globals, 'arcgis_enterprise_enabled');
    this.isAdmin = this.globals.user.isAdmin;
    if (this.globals.projectConfigs["services.integration.arcgis_enterprise.enabled"]) {
      this.activeArcGis = 'arcgis_enterprise';
      this.geojsonArcGisType = 'geojson'
    }
    this.integrationCheck = integrationValidationArcGis(this.globals, 'arcgis', 'arcgis_enterprise', 'arcgis_enabled', 'arcgis_enterprise_enabled', this.errorIntegration, this.errorIntegrationEnterprise);
    if (this.integrationCheck === 'project') {
      this.integrationSetup();
    }
    if (this.integrationCheck === 'personal') {
      this.integrationSetup(true);
    }
    if (this.headerFalse == "false") {
      this.mainClass = "right_col";
    } else {
      this.mainClass = "page-map_main-container";
    }
    this.pageSubTitle = '';
    this.pageTitle = '';
    if (
      document.location.hostname === "app.getdialog.nl" &&
      this.globals.project.customers &&
      this.globals.project.customers.name &&
      !this.globals.project.customers.name.toLowerCase().includes("van gelder")
    ) {
      this.buttons = ["layer"];
    }
    this.coordinate = [6.661594, 50.433237];
    if (this._mapServices.layers == undefined) {
      this._mapServices.layers = this._mapServices.publicLayers.subscribe((res) => {
        this.loadPublicLayers(res);
        this._mapServices.layers = undefined;
      });
    }
    super.ngOnInit();
    posthog.capture('map');

    this._mapServices.panLocation.subscribe((res) => {
      if (res) {
        this.panToLocation(res)
        this.showMakerPopup = true
      } else {
        this.showMakerPopup = false

      }

    })
    const popupContent: HTMLElement = this.popupContentContainer?.nativeElement;

    this._mapServices.popupOverlay = new Overlay({
      element: popupContent,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    if (this.map)
      this.map.addOverlay(this._mapServices.popupOverlay);
  }

  ngAfterViewInit(): void {
    const self = this;
    const defaultControl: any = {
      attribution: false,
    };

    defaultControl.zoomOptions = {
      className: "ol-custom-control_zoom",
    };

    // Detects/waits if the map is rendered
    const observer = new MutationObserver((mutations) => {
      if (!this.mapContainer || !this.mapContainer.nativeElement) {
        return;
      }

      if (
        !this.mapContainer.nativeElement.querySelector("div.ol-unselectable")
      ) {
        return;
      }
      this.applyPinTooltip();
      this.map.updateSize();
      // Adjust tiles size after on window resize
      const self = this;
      this.pubsub.sidebarOpen.subscribe((open) => {
        setTimeout(() => {
          self.map.updateSize();
        }, 500);
      });
      window.onresize = function () {
        setTimeout(() => {
          self.map.updateSize();
        }, 200);
      };
      observer.disconnect();
    });

    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });
    const img = document.createElement("img");
    img.src = "/assets/png/fullscreen.png";
    this.map = new Map({
      target: `map-${this.randomId}`,
      controls: defaultControls().extend([
        new FullScreen({
          // @ts-ignore
          source: document.querySelector(".nav-sm"),
          labelActive: "\u00d7",
          label: img,
        }),
      ]),

      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        // center: this.coordinate,
        // zoom: 4,
        projection: "EPSG:3857",
        center: [0, 0],
        zoom: 2,
      }),
    });
    this.initOverlay()
    this.publicLayers = new OpenDataLayers(this._mapServices);
    this.addPontentialStakeholderToKML();
    this.openDataLayers('subproject');
    setTimeout(() => {
      if (this.mapData) {
        if (this.mapData || this.enableLayers[this.mapData.layer_name]) {
          this.openMapItem(this.mapData);
        }
      }
    }, 8000);
    [
      "stakeholders",
      "issues",
      "complaints",
      "requirements",
      "calendars",
      "residents",
    ].forEach((e) => {
      this.layers[e] = new VectorSource({});
      const cluster = new Cluster({
        distance: 20,
        source: this.layers[e],
      });
      this.vectorLayers[e] = new VectorLayer({
        source: cluster,
        // @ts-ignore
        name: e,
        style: function (feature) {
          const type = feature.get("features")[0].get("type");
          const length = feature.get("features").length;
          return self.getIconStyle(
            `${type}-multiple.png`,
            feature.get("features")[0],
            length
          );
        },
      });

      // Handle click event on the map
      this.map.on("click", function (event) {
        const originalEvent = event.originalEvent;
        self._mapServices.keyword = ''
        self._mapServices.vectorSource.clear();
        self._mapServices.popupOverlay.setPosition(undefined);
        event.stopPropagation();
        const clickedFeatures = this.getFeaturesAtPixel(event.pixel);
        if (clickedFeatures && clickedFeatures.length > 0 && clickedFeatures[0].values_.features && clickedFeatures[0].values_.features.length > 1) {
          // Get the coordinates of the first clicked feature
          const referenceCoordinates = clickedFeatures[0].values_.features[0].getGeometry().getCoordinates();

          // Flag to check if all clicked features have the same coordinates
          let allCoordinatesEqual = true;
          function coordinatesAreEqual(coord1, coord2) {
            return coord1[0] === coord2[0] && coord1[1] === coord2[1];
          }
          // Check the coordinates of each clicked feature
          for (let i = 1; i < clickedFeatures[0].values_.features.length; i++) {
            const clickedCoordinates = clickedFeatures[0].values_.features[i].getGeometry().getCoordinates();

            // Check if the coordinates are the same
            if (!coordinatesAreEqual(clickedCoordinates, referenceCoordinates)) {
              allCoordinatesEqual = false;
              break; // Exit the loop if any coordinates are different
            }
          }

          if (allCoordinatesEqual) {
            self.featureNames = [];
            const element = document.getElementById('popup1');
            element.style.display = 'block';
            // Get the coordinates for placing the popup
            const popupCoordinates = referenceCoordinates;
            for (let i = 0; i < clickedFeatures[0].values_.features.length; i++) {
              const feature = clickedFeatures[0].values_.features[i];
              self.map.getView().fit(feature.getGeometry().getExtent(), {
                maxZoom: self.map.getView().getZoom(),
              });
              self.featureNames.push(feature);

            }
            self.showPopup = true;

            self.cd.detectChanges();
            // Set the position of the popup
            self.overlay.setPosition(popupCoordinates);

          } else {
            // Coordinates are different for at least one feature
            const element = document.getElementById('popup1');
            element.style.display = 'none';
            this.showPopup = false
          }
        } else {
          // eslint-disable-next-line no-empty
          if (originalEvent.target && originalEvent.target.tagName.toLowerCase() === 'ul') {
          } else {
            const element = document.getElementById('popup1');
            element.style.display = 'none';
            this.showPopup = false
          }
        }
      });

      this._mapServices.closeOverlayPopup.subscribe((res) => {
        if (Array.isArray(res) && res.length === 0) {
          const element = document.getElementById('popup1');
          element.style.display = 'none';
          this.showPopup = false;

        } else {
          this.initOverlay()
        }

      })
      this.map.addLayer(this.vectorLayers[e]);
    });
    this.layers["lines"] = new VectorSource({});
    this.map.addLayer(
      new VectorLayer({
        source: this.layers["lines"],
      })
    );
    setTimeout(() => {
      let coord;
      if (this._rout.url.includes('sub')) {
        const subprojectActive = this.subProjectQuery.getActive();
        if (subprojectActive?.locations?.polygons) {
          this.BBOX = createMultiPolygon(subprojectActive.locations).getGeometry().getExtent();
          coord = subprojectActive.locations.polygons.coordinates[0][0];
          coord = coord[0];
          if (this.map.getSize()) {
            this.map.getView().setCenter(ol.proj.transform(coord, 'EPSG:4326', 'EPSG:3857'));
            this.map.getView().setZoom(10);
          }
        }
      } else {
        if (this.globals.projectConfig.polygons) {
          coord = this.globals.projectConfig.polygons.coordinates[0][0];
          this.BBOX = createMultiPolygon(this.globals.projectConfig).getGeometry().getExtent();
          coord = coord[0];
          if (this.map.getSize()) {
            this.map.getView().setCenter(ol.proj.transform(coord, 'EPSG:4326', 'EPSG:3857'));
            this.map.getView().setZoom(10);
          }
        }
      }
    }, 100);

    const sidebar = new ol.control.Sidebar({
      element: "sidebar",
      position: "left",
    });
    // this.map.addControl(sidebar);

    asapScheduler.schedule(() => new ol.control.Rightpanel({
      element: "rightpanel",
      position: "right",
    }));

    const tooltip = new OpenlayersTooltip(
      this.map,
      "Klik om te meten",
      this.selectedTool$,
      this.purposeTool$
    );
    this.drawing = new OpenlayersDrawing(
      this.map,
      this.locationsSbj.getValue(),
      this.selectedTool$,
      tooltip,
      {
        maxPoints: 1,
        maxLines: 1,
        maxArea: 0,
      }
    );

    const pointSubscription = this.drawing.pointDrawEnd.subscribe(
      (coordinate: Coordinate) => {
        this.drawingSubscription(
          "points",
          {
            points: {
              type: "MultiPoint",
              coordinates: [],
            },
            locatable_type: this.locatableType,
          },
          coordinate,
          this.pointDrawEnd
        );
      }
    );

    const lineSubscription = this.drawing.lineDrawEnd.subscribe(
      (coordinate: Coordinate) => {
        this.drawingSubscription(
          "lines",
          {
            lines: {
              type: "MultiLineString",
              coordinates: [],
            },
            locatable_type: this.locatableType,
          },
          coordinate,
          this.lineDrawEnd
        );
      }
    );
    const areaSubscription = this.drawing.areaDrawEnd.subscribe(
      (coordinate: Coordinate) => {
        this.drawingSubscription(
          "polygons",
          {
            polygons: {
              type: "MultiPolygon",
              coordinates: [],
            },
            locatable_type: this.locatableType,
          },
          coordinate,
          this.areaDrawEnd
        );
      }
    );

    this.subscriptions = [pointSubscription, lineSubscription];
    // @ts-ignore
    this.select = new Select({ style: false, hitTolerance: 0 });
    if (!this.mapData) {
      this.map.addInteraction(this.select);
    }
    this.map.on('click', function (evt) {
      this._mapServices.popupOverlay.setPosition(undefined);
      this.forEachLayerAtPixel(evt.pixel, (layer) => {
        if (layer.values_.layer_type) {
          if (layer.values_.layer_type == 'wms') {
            self._mapServices.getwmsFeaturesOnClick(wmsURLSet(self.map, self.enableLayers, layer, evt))
              .pipe(
                tap((res: any) => {
                  const parser = new DOMParser();
                  if (self.rightpanel.element.classList.contains("collapsed"))
                    self.rightpanel._tabitems[0].querySelector("a").click();
                  self.itemsList.openDataClick(wmsFeatureget(self.ngxXml2jsonService.xmlToJson(parser.parseFromString(res, 'text/xml')), layer), true);
                }),
              ).subscribe();
          }
        }
      });
    });

    // this.map.on('moveend', (evt) => {
    //   for (const key in this.enableLayers) {
    //     const layer = this.enableLayers[key];
    //     if (layer.getVisible()) {
    //       const matchingItem = this.symbolicName.find((item: any) => item.layer_name === key);
    //       if (matchingItem && layer.getSource().getFeatures().length > 0 && layer.features) {
    //         layer.getSource().getFeatures().forEach(element => {
    //           if (!element.style_) {
    //             setMultipleSymobologyStyleColor(element, matchingItem.symobology, 'arcgis');
    //           }
    //         });
    //       }
    //     }
    //   }
    // });

    this.select.on("select", (e) => {
      if (self.selectedToolSbj.getValue()) return;
      if (
        e.selected &&
        e.selected[0] &&
        e.selected[0].values_ &&
        e.selected[0].values_.features
      ) {
        const features = e.selected[0].values_.features;
        const id = features[0].get("id");
        const type = features[0].get("type");
        if (id && id !== "" && type && features.length == 1) {
          if (self.rightpanel.element.classList.contains("collapsed"))
            self.rightpanel._tabitems[0].querySelector("a").click();
          const item = self.locationsSbj
            .getValue()
            .filter((l) => l.id == id && l.locatable_type == type);
          self.itemsList.onItemClick(item[0], false);
          self.map.getView().fit(features[0].getGeometry().getExtent(), {
            maxZoom: self.map.getView().getZoom(),
          });
        }
      } else {
        const feature = e.selected[0];
        if (!feature) return;
        const layer = self.select.getLayer(feature);
        if (layer.values_.layer_type) {
          if (layer.values_.layer_type == 'subproject') {
            return
          }
        }

        //Dont show the detail of Pontential_Stakeholder
        feature.set("layer", layer.get("name"));
        if (self.rightpanel.element.classList.contains("collapsed"))
          self.rightpanel._tabitems[0].querySelector("a").click();
        self.itemsList.openDataClick(feature.getProperties(), true);
        self.select.getFeatures().clear();
        self.overPassDataZoom(feature);
      }
    });
    this._mapServices.vectorSource = new VectorSource();
    this._mapServices.vectorLayer = new VectorLayer({
      source: this._mapServices.vectorSource
    });
    this.map.addLayer(this._mapServices.vectorLayer);


  }

  onSelectFeature(feature: any) {
    const features = feature;
    const id = features.get("id");
    const type = features.get("type");
    if (id && id !== "" && type) {
      if (this.rightpanel.element.classList.contains("collapsed"))
        this.rightpanel._tabitems[0].querySelector("a").click();
      const item = this.locationsSbj
        .getValue()
        .filter((l) => l.id == id && l.locatable_type == type);
      this.itemsList.onItemClick(item[0], false);
      this.map.getView().fit(features.getGeometry().getExtent(), {
        maxZoom: this.map.getView().getZoom(),
      });
    }
  }

  public coordinatesAreEqual(coord1: any, coord2: any) {
    return coord1[0] === coord2[0] && coord1[1] === coord2[1];
  }
  public closePopup() {
    this.showPopup = false
    const element = document.getElementById('popup1');
    element.style.display = 'none';
    this.rightpanel._tabitems[0].querySelector("a").click();

  }

  private initOverlay() {
    const element = document.getElementById('popup1');
    element.style.display = 'block';
    this.overlay = new Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, -50], // Adjust the offset based on your popup's design
    });

    this.map.addOverlay(this.overlay);
  }
  //integration setup for both user and project
  async integrationSetup(is_user = false) {
    if (this.globals.enableLayers) {
      this.arcGisLayers = this.globals.enableLayers;
      this.arcGisToken = this.globals.arcgisToken;
      await this.sleep(2000);
      await this.openDataLayers('arcgis');
      return true;
    }
    combineLatest([
      this._projectConfigsService.getEnableFeatureLayers(this.activeArcGis, is_user),
      this._projectConfigsService.getFreshToken(this.activeArcGis, is_user)
    ])
      .pipe(
        tap(async ([layers, arcgis]) => {
          this.setArcGisSubLayers(layers, arcgis);
        }),
        catchError((err) => {
          bugsnagFactory().notify(JSON.stringify({
            message: 'ArcGis Account Is Not Verified',
            data: err,
          }));
          this.globals.projectConfigs['services.integration.arcgis.enabled'] = false;
          this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled'] = false;
          showFeedbackError(err);
          return throwError(err);
        }),
      ).subscribe();
  }
  /*
    ArcGis Layers Into Sub Layers
  */
  public async setArcGisSubLayers(layers: any, arcgis: any): Promise<void> {
    this.arcGisLayers = [];
    layers.forEach(layer => {
      this._mapServices.getArcGisSubLayers(layer.url, arcgis.token)
        .pipe(
          tap(async (res: any) => {
            res.forEach(item => {
              let foundObject = null;
              if (layer.metadata) {
                if (layer.metadata.length) {
                  foundObject = layer.metadata.find(data => data.layerId === item.id);
                }
              }
              this.arcGisLayers.push({
                name: layer.name,
                layer_name: item.name,
                layer_id: item.id,
                type: item.type,
                geometry_type: item.geometryType,
                url: layer.url,
                owner: layer.owner,
                enabled: layer.enabled,
                metadata: foundObject ? foundObject.metadata : null,
                alldata: layer.metadata ? layer.metadata : null,
              });
            });
            this.arcGisToken = arcgis.token;
            this.globals.enableLayers = this.arcGisLayers;
            this.globals.arcgisToken = this.arcGisToken;
            await this.sleep(2000);
            await this.openDataLayers('arcgis');
          })
        ).subscribe();
    });
  }
  //on toggle open data layers panel
  toggleOpenData(layer: string) {
    // close the popover if open while toggling layers
    if (this.layerItemClicked) {
      const popover: any = document.querySelector(".popover");
      if (popover) popover.style.display = "none";
      this.layerItemClicked = false;
    }
    const visibility = this.kmls[layer].getVisible();
    this.kmls[layer].setVisible(!visibility);
    if (visibility) {
      if (
        !this.rightpanel.element.classList.contains("collapsed") &&
        this.itemsList.itemdetail.feature &&
        this.itemsList.itemdetail.feature.layer == layer
      ) {
        this.itemsList.onBack();
        this.rightpanel._tabitems[0].querySelector("a").click();
        this.cd.detectChanges();
      }
    }
    if (!this.mapData) {
      this.showOpenlayerInSidePanel(layer);
      if (this.rightpanel.element.classList.contains("collapsed")) {
        this.itemsList.hideSerch();
        this.rightpanel._tabitems[0].querySelector("a").click();
        this.showToggle = true;
        this.cd.detectChanges();
      }
      if (
        !collect(this.openDataGetVisible()).contains(true) &&
        !this.kmls["Pontential_Stakeholder"].getVisible() &&
        !this.sidebar.enableItemsLayer['stakeholder'] &&
        !this.sidebar.enableItemsLayer['issue'] &&
        !this.sidebar.enableItemsLayer['complaints'] &&
        !this.sidebar.enableItemsLayer['requirements'] &&
        !this.sidebar.enableItemsLayer['activity']
      ) {
        this.itemsList.onBack();
        this.rightpanel._tabitems[0].querySelector("a").click();
        this.showToggle = false;
        this.cd.detectChanges();
      }
    }
  }

  panToLocation(result: any): void {
    this._mapServices.popupOverlay.setPosition(undefined);
    const coordinates = fromLonLat([result.lon, result.lat]);

    const marker = new Feature({
      geometry: new Point(coordinates)
    });

    // Set the map view to the coordinates
    this.map.getView().animate({
      center: coordinates,
      duration: 1000
    });

    marker.setStyle(new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({ color: '#2800D2' }),
        stroke: new Stroke({ color: 'white', width: 2 })
      })
    }));

    this._mapServices.vectorSource.addFeature(marker);

    // Show the popup at the marker's coordinates
    this._mapServices.popupOverlay.setPosition(coordinates);

    this.getCoordinate = ol.proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');

    const typeData = {
      points: {
        type: 'MultiPoint',
        coordinates: [
          [this.getCoordinate[0], this.getCoordinate[1]],
        ]
      },
      locatable_type: this.locatableType,
    }

    this._mapDataService.pointData = {
      locations: typeData,
      log_timestamp: new Date(),
      stakeholdermap: true,
    }
  }

  //on toggle show side panel and layers
  toggleOpenDataLayers(data) {
    // close the popover if open while toggling layers
    if (this.layerItemClicked) {
      const popover: any = document.querySelector(".popover");
      if (popover) popover.style.display = "none";
      this.layerItemClicked = false;
    }
    //replace wide spaces from the name
    data.name = data.name.replaceAll(' ', '')
    const layer = data.name;
    const visibility = data.visibility;
    switch (data.type) {
      case 'subproject': {
        if (!this.enableLayers[layer]) {
          if (data.id) {
            if (data.visibility) {
              this._subproject.select(data.id, new WithParams().with(["locations"]))
                .pipe(
                  tap((res) => {
                    this.enableLayers[layer] = openLayerMaping(data.type, this.enableLayers, res);
                    this.map.addLayer(this.enableLayers[layer]);
                    this.enableLayers[layer].setVisible(visibility);
                    this.openDataZoomExtentLogic(data);
                    this.enableLayers[data.name].getSource().getFeatures().map((feature: any) => {
                      feature.set('name', data.name);
                      feature.set('layer', layer);
                    });
                    this.toggleExtraLayers(layer);
                  })
                ).subscribe();
            }
          }
        } else {
          this.enableLayers[layer].setVisible(visibility);
          this.openDataZoomExtentLogic(data);
          this.toggleExtraLayers(layer);
        }
        return;
      }
      case 'wms': {
        this.enableLayers[layer].setVisible(visibility);
        this.toggleExtraLayers(layer);
        return;
      }
      default: {
        this._mapServices.mainLayers = this.enableLayers[layer]
        this.enableLayers[layer].setVisible(visibility);
        this.openDataZoomExtentLogic(data);
        break;
      }
    }
    if (
      !collect(this.openDataGetVisible()).contains(true) &&
      !this.kmls["Pontential_Stakeholder"].getVisible() &&
      !this.sidebar.enableItemsLayer['stakeholder'] &&
      !this.sidebar.enableItemsLayer['issue'] &&
      !this.sidebar.enableItemsLayer['complaints'] &&
      !this.sidebar.enableItemsLayer['requirements'] &&
      !this.sidebar.enableItemsLayer['activity']
    ) {

      this.itemsList.getOpenDataLayerName(layer, false, this.enableLayers[layer]["features"]);
      if (!this.rightpanel.element.classList.contains("collapsed") && this.itemsList.itemdetail.feature && this.itemsList.itemdetail.feature.layer == layer) {
        this.itemsList.onBack();
        this.rightpanel._tabitems[0].querySelector("a").click();
      } else {
        if (!this.rightpanel.element.classList.contains("collapsed")) {
          this.itemsList.onBack();
          this.rightpanel._tabitems[0].querySelector("a").click();
        }
      }
    } else {
      this.showOpenDataInSidePanel(layer, data);
      if (this.rightpanel.element.classList.contains("collapsed")) {
        this.itemsList.hideSerch();
        if (window.innerWidth > 500) {
          this.rightpanel._tabitems[0].querySelector("a").click();
        }
        this.showToggle = true;
      }
    }
  }
  //on zoom to extend
  public zoomToOpenDataLayers(data): void {
    if (this.enableLayers[data.name].getVisible()) {
      this.layerLoadInterval.forEach(element => {
        clearInterval(element);
      });
      this.map.getView().fit(this.enableLayers[data.name].getSource().getExtent(), { maxZoom: 15 });
    }
  }
  //wms and subproject toggle handler
  toggleExtraLayers(layer): void {
    if (
      !collect(this.openDataGetVisible()).contains(true) &&
      !this.kmls["Pontential_Stakeholder"].getVisible() &&
      !this.sidebar.enableItemsLayer['stakeholder'] &&
      !this.sidebar.enableItemsLayer['issue'] &&
      !this.sidebar.enableItemsLayer['complaints'] &&
      !this.sidebar.enableItemsLayer['requirements'] &&
      !this.sidebar.enableItemsLayer['activity']
    ) {
      if (!this.rightpanel.element.classList.contains("collapsed")) {
        this.rightpanel._tabitems[0].querySelector("a").click();
      }
    }
  } /*
  On Load Private Features
*/
  public onLoadPrivateFeatures(layer: any, link: string): any {
    return this._mapServices.getPrivateFeatures(layer, link)
  }

  openMapItem(mapItem: any) {
    setTimeout(() => {
      if (this.enableLayers[mapItem.layer_name]) {
        let credentials = false;
        this.openLayersResp.forEach(layer => {
          if (layer.layer_name === mapItem.layer_name) {
            if (layer.layer) {
              if (layer.layer.credentials) {
                if (layer.layer.credentials.username && layer.layer.credentials.password) {
                  credentials = true;
                }
              }
            }
          }
        });
        this.toggleOpenDataLayers({ name: mapItem.layer_name, visibility: true, credentials: credentials });
        this.enableLayers[mapItem.layer_name].getSource().once("change", (evt) => {
          const source = evt.target;
          if (source.getState() === "ready") {
            const feature = this.enableLayers[mapItem.layer_name]
              .getSource()
              .getFeatures()
              .filter((f) => {
                return f.values_[mapItem.id_field] == mapItem.layer_id;
              })[0];
            if (!feature.values_.layer) {
              feature.values_.layer = mapItem.layer_name;
            }
            this.itemsList.openDataClick(feature.values_, false);
            if (document.querySelectorAll("#rightpanel").length == 2) {
              this.rightpanel.element.classList.add("collapsed");
              if (document.querySelectorAll("#rightpanel")[1].classList.contains("collapsed")) {
                document.querySelectorAll("#rightpanel")[1].classList.remove("collapsed");
                document.querySelectorAll("#rightpanel")[1].classList.add("sidebar-right");
                document.querySelectorAll("#items")[1].classList.add("active");
                this.openlayerralationLoader.emit();
                setTimeout(() => {
                  this.showTooltipPublicLayers(mapItem);
                }, 1000);
                this.map
                  .getView()
                  .fit(feature.getGeometry().getExtent(), { maxZoom: 15 });
              }
            }
            this.openlayerralationLoader.emit();
            this.showTooltipPublicLayers(mapItem);
            this.map
              .getView()
              .fit(feature.getGeometry().getExtent(), { maxZoom: 15 });
          }
        });
        if (credentials) {
          if (this.enableLayers[mapItem.layer_name].getSource().getState() === "ready") {
            const feature = this.enableLayers[mapItem.layer_name]
              .getSource()
              .getFeatures()
              .filter((f) => {
                return f.values_[mapItem.id_field] == mapItem.layer_id;
              })[0];
            if (!feature.values_.layer) {
              feature.values_.layer = mapItem.layer_name;
            }
            this.itemsList.openDataClick(feature.values_, false);
            if (document.querySelectorAll("#rightpanel").length == 2) {
              this.rightpanel.element.classList.add("collapsed");
              if (document.querySelectorAll("#rightpanel")[1].classList.contains("collapsed")) {
                document.querySelectorAll("#rightpanel")[1].classList.remove("collapsed");
                document.querySelectorAll("#rightpanel")[1].classList.add("sidebar-right");
                document.querySelectorAll("#items")[1].classList.add("active");
                this.openlayerralationLoader.emit();
                setTimeout(() => {
                  this.showTooltipPublicLayers(mapItem);
                }, 1000);
                this.map
                  .getView()
                  .fit(feature.getGeometry().getExtent(), { maxZoom: 15 });
              }
            }
            this.openlayerralationLoader.emit();
            this.showTooltipPublicLayers(mapItem);
            this.map
              .getView()
              .fit(feature.getGeometry().getExtent(), { maxZoom: 15 });
          }
        }
      }
    }, 1000);
  }

  openMapItemDetail(mapItem: any) {
    const feature = this.kmls[mapItem.layer_name].features.filter((f) => {
      return f.layer_id == mapItem.layer_id;
    })[0];
    if (!feature.values.layer) {
      feature.values.layer = mapItem.layer_name;
    }
    this.showTooltipOpenlayer(mapItem);
    const featurezoom = this.kmls[mapItem.layer_name]
      .getSource()
      .getFeatures()
      .filter((f) => {
        return f.values_[mapItem.id_field] == mapItem.layer_id;
      })[0];
    this.map
      .getView()
      .fit(featurezoom.getGeometry().getExtent(), { maxZoom: 15 });
    this.itemsList.openDataClick(feature.values, true);
  }

  openMapArcGisItemDetail(mapItem: any) {
    this.layerItemClicked = true;
    const feature = this.enableLayers[mapItem.layer_name].features.filter((f) => {
      return f.layer_id == mapItem.layer_id;
    })[0];
    if (!feature.values.layer) {
      feature.values.layer = mapItem.layer_name;
    }
    this.showTooltipPublicLayers(mapItem);
    const featurezoom = this.enableLayers[mapItem.layer_name].getSource().getFeatures().filter((f) => {
      return f.values_[mapItem.id_field] == mapItem.layer_id;
    })[0];
    if (featurezoom) {
      this.map.getView().fit(featurezoom.getGeometry().getExtent(), { maxZoom: 15 });
    }
    this.itemsList.openDataClick(feature.values, true);
  }
  //arcgis layers data mp
  public openDataLayers(type): void {
    switch (type) {
      case 'arcgis':
        if (this.arcGisLayers.length > 0) {
          this.arcGisLayers.forEach((key) => {
            key.name = key.name.replaceAll(' ', '');
            const layername = `${key.name}-${key.layer_name.replace(/\s/g, '')}`;
            if (!this.arcGis_connectedItems.includes(key.layer_name)) {
              this._mapServices.getSymobology(key.url, key.layer_id, this.arcGisToken)
                .pipe(
                  tap((res: any) => {
                    this._mapServices.getSymobologyArcGis(key.layer_id, res.serviceItemId, this.arcGisToken)
                      .pipe(
                        tap((response: any) => {
                          if (arcGisIntegrationSymbology(response)) {
                            if (response.layerDefinition.drawingInfo.renderer.uniqueValueInfos) {
                              response.layerDefinition.drawingInfo.renderer.uniqueValueInfos = arcGisIntegrationSymbologyDefault(response);
                              this.symbolicName.push({ layer_name: layername, symobology: response.layerDefinition.drawingInfo.renderer.uniqueValueInfos });
                              this.symbolicName = collect(this.symbolicName).unique((item => item.layer_name)).all();
                              const extent = this.map.getView().calculateExtent(this.map.getSize());
                              const extent4326 = ol.proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
                              this.enableLayers[layername] = openLayerMaping(type, this.enableLayers, key, layername, this.arcGisToken, this.geojsonArcGisType, 0, {}, extent4326, response.layerDefinition.drawingInfo.renderer.uniqueValueInfos);
                            } else {
                              this.symbolicName.push({ layer_name: layername, symobology: response.layerDefinition.drawingInfo.renderer });
                              this.symbolicName = collect(this.symbolicName).unique((item => item.layer_name)).all();
                              const extent = this.map.getView().calculateExtent(this.map.getSize());
                              const extent4326 = ol.proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
                              this.enableLayers[layername] = openLayerMaping(type, this.enableLayers, key, layername, this.arcGisToken, this.geojsonArcGisType, 0, {}, extent4326, response.layerDefinition.drawingInfo.renderer);
                            }
                          } else {
                            if (res) {
                              if (res.drawingInfo) {
                                if (res.drawingInfo.renderer) {
                                  const extent = this.map.getView().calculateExtent(this.map.getSize());
                                  const extent4326 = ol.proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
                                  this.enableLayers[layername] = openLayerMaping(type, this.enableLayers, key, layername, this.arcGisToken, this.geojsonArcGisType, 0, {}, extent4326, res.drawingInfo.renderer);
                                } else {
                                  const extent = this.map.getView().calculateExtent(this.map.getSize());
                                  const extent4326 = ol.proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
                                  this.enableLayers[layername] = openLayerMaping(type, this.enableLayers, key, layername, this.arcGisToken, this.geojsonArcGisType, 0, {}, extent4326);
                                }
                              } else {
                                const extent = this.map.getView().calculateExtent(this.map.getSize());
                                const extent4326 = ol.proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
                                this.enableLayers[layername] = openLayerMaping(type, this.enableLayers, key, layername, this.arcGisToken, this.geojsonArcGisType, 0, {}, extent4326);
                              }
                            }
                          }
                          this.map.addLayer(this.enableLayers[layername]);
                        }
                        )).subscribe();
                  }
                  )).subscribe();
            } else {
              this.enableLayers[layername] = openLayerMaping(type, this.enableLayers, key, layername, this.arcGisToken, this.geojsonArcGisType);
              this.map.addLayer(this.enableLayers[layername]);
            }
          });
        }
        break;
      case 'subproject':
        // if(this.subProjectQuery.getAll()){
        //   this.subProjectQuery.getAll().forEach(layer => {
        //     this.enableLayers[layer.name]=openLayerMaping(type,this.enableLayers,layer);
        //     this.map.addLayer(this.enableLayers[layer.name]);
        //   });
        // }
        break;
      default:
        break;
    }
  }
  //on reset filter
  public resetFilter(): void {
    this.sidebar.ontoggleChangeAllItem({ checked: false }, 'stakeholder');
    this.sidebar.ontoggleChangeAllItem({ checked: false }, 'issue');
    this.sidebar.ontoggleChangeAllItem({ checked: false }, 'complaints');
    this.sidebar.ontoggleChangeAllItem({ checked: false }, 'requirements');
    this.sidebar.ontoggleChangeAllItem({ checked: false }, 'activity');
    this.owlDateRange = [];
  }
  //right toggle panel
  public toggleRightpanel(data: any) {
    if (
      !collect(this.openDataGetVisible()).contains(true) &&
      !this.kmls["Pontential_Stakeholder"].getVisible() &&
      !this.sidebar.enableItemsLayer['stakeholder'] &&
      !this.sidebar.enableItemsLayer['issue'] &&
      !this.sidebar.enableItemsLayer['complaints'] &&
      !this.sidebar.enableItemsLayer['requirements'] &&
      !this.sidebar.enableItemsLayer['activity']
    ) {
      if (!this.rightpanel.element.classList.contains("collapsed")) {
        this.itemsList.onBack();
        this.rightpanel._tabitems[0].querySelector("a").click();
      }
      return;
    }
    if (data.layer && !data.layer.status && data.layer.layer == this.itemsList.itemdetail.current) {
      this.itemsList.onBack();
    } else {
      if (data.layer.status && this.rightpanel.element.classList.contains("collapsed")) {
        this.itemsList.onBack();
        // Hide in mobile Screen
        if (window.innerWidth > 500) {
          this.rightpanel._tabitems[0].querySelector("a").click();
        }

      }
    }
  }
  //update features layers in side panel
  public updateFeatures(obj: any): void {
    this.resetMap(false);
    if (obj.toggle)
      this.toggleRightpanel(obj);
    if (obj.resetFilter && this.dateRange.startDate) {
      this.filterByDate();
    } else {
      if (obj.data) {
        if (Array.isArray(obj.data) && obj.data.length > 0) {
          this.locationsSbj.next(obj.data);
          this.loadFeatures();
          this.showToggle = true;
          this.zoomToAllFeatures();
        }
      }
    }
  }
  //show Tool Tip on Items Features
  public showTooltip(obj: any): void {
    const feature = this.layers[obj.locatable_type]
      .getFeatures()
      .filter((f) => f.get("id") == obj.id);
    const element = document.getElementById(`popup-${this.randomId}`);
    if (feature && feature[0].get("name") && feature[0].get("link")) {
      const coordinates = feature[0].getGeometry().getCoordinates();
      if (element && $(element)) {
        this.popupBeingShown = coordinates;
        this.pinTooltipOverlay.setOffset([0, -45]);
        this.pinTooltipOverlay.setPosition(coordinates);
        $(element).popover({
          placement: "top",
          html: true,
          content: feature[0].get("name"),
        });
        $(element).attr("data-content", feature[0].get("name"));
        $(element).popover("show");
        const popover: any = document.querySelector(".popover");
        const arrow: any = document.querySelector(".popover .arrow");
        popover.style.left = "-100px";
        arrow.style.left = "50%";
      }
    }
  }
  //show Tool Tip on Potential Stakeholder Features
  public showTooltipOpenlayer(obj: any): void {
    const feature = this.kmls[obj.layer_name]
      .getSource()
      .getFeatures()
      .filter((f) => {
        return f.values_[obj.id_field] == obj.layer_id;
      })[0];
    let coordinates;
    const element = document.getElementById(`popup-${this.randomId}`);
    if (feature && feature.values_) {
      if (feature.values_.layer == "Pontential_Stakeholder") {
        coordinates = feature.getGeometry().getCoordinates();
      } else {
        coordinates = feature.getGeometry().getInteriorPoints().getCoordinates();
      }
      if (element && $(element)) {
        this.popupBeingShown = coordinates;
        if (feature.values_.layer == "Pontential_Stakeholder") {
          this.pinTooltipOverlay.setOffset([0, 0]);
          this.pinTooltipOverlay.setPosition(coordinates);
        } else {
          this.pinTooltipOverlay.setOffset([0, 0]);
          this.pinTooltipOverlay.setPosition(coordinates[0]);
        }
        $(element).popover({
          placement: "top",
          html: true,
          content: obj.title,
        });
        $(element).attr("data-content", obj.title);
        $(element).popover("show");
        const popover: any = document.querySelector(".popover");
        const arrow: any = document.querySelector(".popover .arrow");
        popover.style.left = "-100px";
        arrow.style.left = "50%";
      }
    }
  }
  //get center Logic
  getCenterOfExtent(Extent) {
    const X = Extent[0] + (Extent[2] - Extent[0]) / 2;
    const Y = Extent[1] + (Extent[3] - Extent[1]) / 2;
    return [X, Y];
  }
  //show Tool Tip on Open Features
  public showTooltipPublicLayers(obj: any): void {
    const feature = this.enableLayers[obj.layer_name]
      .getSource()
      .getFeatures()
      .filter((f) => {
        return f.values_[obj.id_field] == obj.layer_id;
      })[0];
    let coordinates;
    const element = document.getElementById(`popup-${this.randomId}`);
    if (feature && feature.values_) {
      coordinates = this.getCenterOfExtent(feature.getGeometry().getExtent());
      if (element && $(element)) {
        this.popupBeingShown = coordinates;
        if (coordinates.length > 1) {
          this.pinTooltipOverlay.setOffset([0, 0]);
          this.pinTooltipOverlay.setPosition(coordinates);
        } else {
          this.pinTooltipOverlay.setOffset([0, 0]);
          this.pinTooltipOverlay.setPosition(coordinates[0]);
        }
        $(element).popover({
          placement: "top",
          html: true,
          content: obj.title,
        });
        $(element).attr("data-content", obj.title);
        $(element).popover("show");
        const popover: any = document.querySelector(".popover");
        const arrow: any = document.querySelector(".popover .arrow");
        popover.style.left = "-100px";
        arrow.style.left = "50%";
      }
    }
  }
  //get Zoom Level
  findZoomLevel(zoom, pixel, feature, obj) {
    // let feature = this.layers[obj.locatable_type].getFeatures().filter(f => f.get('id') == obj.id);
    const coordinates = feature[0].getGeometry().getCoordinates();
    pixel = this.map.getPixelFromCoordinate(coordinates);
    const result = this.map.forEachFeatureAtPixel(
      pixel,
      (f) => {
        return f;
      },
      {
        hitTolerance: 1500,
        layerFilter: function (layer) {
          return true;
        },
      }
    );
    if (!result) {
      this.map
        .getView()
        .fit(feature[0].getGeometry().getExtent(), { maxZoom: zoom });
      this.showTooltip(obj);
      this.showAllLayers();
      return;
    }
    const features = result.get("features");
    if ((features && features.length == 1) || zoom > 20) {
      this.map
        .getView()
        .fit(feature[0].getGeometry().getExtent(), { maxZoom: zoom });
      this.showTooltip(obj);
      this.showAllLayers();
    } else {
      zoom = zoom + 2;
      this.map
        .getView()
        .fit(feature[0].getGeometry().getExtent(), { maxZoom: zoom });
      setTimeout(() => {
        this.findZoomLevel(zoom, pixel, feature, obj);
      }, 100);
    }
  }

  showAllLayers() {
    Object.values(this.vectorLayers).forEach((value: any) => {
      value.setVisible(true);
    });
  }

  public zoomToPoint(obj: any): void {
    const feature = this.layers[obj.locatable_type]
      .getFeatures()
      .filter((f) => f.get("id") == obj.id);

    const zoom = this.map.getView().getZoom();
    const coordinates = feature[0].getGeometry().getCoordinates();
    const pixel = this.map.getPixelFromCoordinate(coordinates);
    Object.values(this.vectorLayers).forEach((value: any) => {
      if (value.get("name") != feature[0].get("type")) {
        value.setVisible(false);
      }
    });
    setTimeout(() => {
      const result = this.map.forEachFeatureAtPixel(
        pixel,
        (f) => {
          return f;
        },
        {
          hitTolerance: 1500,
          layerFilter: function (layer) {
            return true;
          },
        }
      );
      if (!result) {
        this.map
          .getView()
          .fit(feature[0].getGeometry().getExtent(), { maxZoom: zoom });
        this.showAllLayers();
        return;
      }
      const features = result.get("features");
      if (features && features.length == 1) {
        this.map
          .getView()
          .fit(feature[0].getGeometry().getExtent(), { maxZoom: zoom });
        this.showAllLayers();
      } else {
        this.findZoomLevel(zoom, pixel, feature, obj);
      }

      this.showTooltip(obj);
    }, 50);
  }

  public zoomToAllFeatures(): void {
    const extent = olExtent.createEmpty();
    Object.values(this.layers).forEach((value: any) => {
      olExtent.extend(extent, value.getExtent());
    });
    let padding = [60, 340, 10, 410];
    if (this.rightpanel.element.classList.contains("collapsed")) {
      padding = [60, 10, 10, 410];
    }
    this.map.getView().fit(extent, { padding: padding });
  }

  public resetMap(notify: boolean = true): void {
    this.locationsSbj.next([]);
    this.removeLayers();
    this.showToggle = false;
  }

  public removeLayers(): void {
    Object.values(this.layers).forEach((value: any) => value.clear());
    this.layers["lines"].clear();
  }

  // Show features of active layers
  private loadFeatures(): void {
    const currentLocations = this.locationsSbj.getValue();
    currentLocations.forEach((location: Location) => {
      if (!location.locatable_type) {
        return;
      }
      if (location.points && location.points.coordinates && location.points.coordinates.length > 0) {
        this.loadPoints(location.name, location, location.points.coordinates, location.locatable_type);
      } else if (location.polygons && location.polygons.coordinates && location.polygons.coordinates.length > 0) {
        this.loadPolygons(location.polygons.coordinates, location.locatable_type, location.metadata ? location.metadata.color : null);
      } else if (location.lines && location.lines.coordinates && location.lines.coordinates.length > 0) {
        this.loadLines(location.lines.coordinates, location.locatable_type, location.metadata ? location.metadata.color : null);
        location.lines.coordinates.forEach((coordinate: any) => {
          const firstCoordinate = coordinate[0];
          const lastCoordinate = coordinate[coordinate.length - 1];
          this.loadPoints(location.name, location, [firstCoordinate, lastCoordinate], location.locatable_type);
        });
      }
    });
  }
  // Show Tooltip overlay on feature hover
  private applyPinTooltip(): void {
    const selfmap = this;
    if (this.pinTooltipOverlay) {
      return;
    }
    const element = document.getElementById(`popup-${this.randomId}`);
    this.pinTooltipOverlay = new Overlay({
      element: element,
      positioning: "top-center",
      stopEvent: false,
      offset: [0, -45],
    });
    this.map.addOverlay(this.pinTooltipOverlay);
    const target = this.map.getTarget();
    // @ts-ignore
    const cursorTarget = typeof target === "string" ? document.querySelector(`#${target}`) : document.querySelector(target);
    this.map.on("pointermove", (evt) => {
      if (evt.dragging) {
        return;
      }
      let feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        (feature) => {
          return feature;
        }
      );
      // We set timeout for showing popover to prevent error on quick switching popover
      // which caused by jquery being late on destroying and showing popover
      if (!this.mapData) {
        clearTimeout(this.popupTimeout);
        if (!feature) {
          const popover: any = document.querySelector(".popover");
          if (popover && this.layerItemClicked) popover.style.display = "block";
          if (popover && !this.layerItemClicked) popover.style.display = "none";
          this.popupBeingShown = false;
          // @ts-ignore
          cursorTarget.style.cursor = "";
          return;
        }

        this.popupTimeout = setTimeout(() => {
          const features = feature.get("features");
          const featureskml = [];
          let parsemap = { title: "" };
          // @ts-ignore
          if (feature.values_.layer) {
            // @ts-ignore
            parsemap = parsemapItem(feature.values_);
            featureskml[0] = feature;
            if (featureskml && featureskml.length == 1)
              feature = featureskml[0];
          } else {
            if (features && features.length == 1) feature = features[0];
          }
          const text =
            feature.get("name") || feature.get("Naam") || parsemap.title;
          if (text) {
            let coordinates;
            // @ts-ignore
            if (feature.values_.layer) {
              coordinates = feature.getGeometry().getExtent();
              // @ts-ignore
              if (selfmap.enableLayers[feature.values_.layer] || feature.values_.layer == "Pontential_Stakeholder") {
                coordinates = this.getCenterOfExtent(coordinates);
              } else {
                // @ts-ignore
                coordinates = feature.getGeometry().getInteriorPoints().getCoordinates();
              }
            } else {
              // @ts-ignore
              coordinates = feature.getGeometry().getCoordinates();
            }
            if (element && $(element) && this.popupBeingShown[0] != coordinates[0]) {
              this.popupBeingShown = coordinates;
              // @ts-ignore
              if (feature.values_.layer) {
                // @ts-ignore
                if (selfmap.enableLayers[feature.values_.layer] || feature.values_.layer == "Pontential_Stakeholder") {
                  this.pinTooltipOverlay.setOffset([0, 0]);
                  this.pinTooltipOverlay.setPosition(coordinates);
                } else {
                  this.pinTooltipOverlay.setOffset([0, 0]);
                  this.pinTooltipOverlay.setPosition(coordinates[0]);
                }
              } else {
                this.pinTooltipOverlay.setOffset([0, -45]);
                this.pinTooltipOverlay.setPosition(coordinates);
              }
              this.layerItemClicked = false;
              $(element).popover({
                placement: "top",
                html: true,
                content: text,
              });
              $(element).attr("data-content", text);
              $(element).popover("show");
            }
            // @ts-ignore
            cursorTarget.style.cursor = "pointer";
          } else {
            if (this.popupBeingShown) {
              $(element).popover("destroy");
              this.popupBeingShown = false;
            }
            // @ts-ignore
            cursorTarget.style.cursor = "";
          }
        }, 50);

        const metadata: any = feature.get("metadata");
        if (metadata && metadata.onmouseover) {
          metadata.onmouseover();
        }
      }
    });
  }
  //get items icons
  getImageName(feature): string {
    let isStrategicMethod = false;
    if (this.globals.projectConfigs['project.stakeholder_strategy.is_new'])
      isStrategicMethod = true;
    return setItemsIcons(feature, isStrategicMethod);
  }
  // Style for points
  private getIconStyle(iconFileName: string = "issues.png", feature: any, length, anchor: number[] = [0.5, 1]): any {
    let offset_x = 8;
    if (length == 1) {
      // image for single point
      iconFileName = iconFileName.replace("-multiple.png", ".png");
      const name = this.getImageName(feature);
      if (name) iconFileName = iconFileName.replace(".png", name);
    }
    if (length.toString().length > 2) offset_x = 8;
    if (length.toString().length == 1) offset_x = 8;
    return new Style({
      image: new Icon({
        anchor: anchor,
        scale: 0.2,
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: `/assets/png/${iconFileName}`,
      }),
      text: new Text({
        text: length == 1 ? "" : length.toString(),
        fill: new Fill({
          color: "black",
        }),
        offsetY: -35,
        offsetX: offset_x,
      }),
    });
  }
  // Add points to vector layer
  private loadPoints(name: string, location: Location, coordinates: Coordinate[], type: string): any {
    coordinates.forEach((coordinate) => {
      // @ts-ignore
      const geometry = new Point(coordinate).transform("EPSG:4326", "EPSG:3857").getCoordinates();
      const feature = new Feature({
        geometry: new Point(geometry),
        name: name,
        link: location.link,
        urgency: location.urgency,
        is_closed: location.is_closed,
        status: location.status,
        id: location.id,
        types: location.types,
        type: type,
        strategy: location.matrices
      });

      if (this.layers[type]) this.layers[type].addFeature(feature);
    });
  }

  public loadPolygons(
    coordinates: Coordinate[],
    type: string,
    color?: string
  ): any {
    if (!coordinates) {
      return;
    }
    // @ts-ignore
    const geom = new MultiPolygon(coordinates);
    // @ts-ignore
    const preloadedCoordinates = geom.transform("EPSG:4326", "EPSG:3857").getCoordinates();
    const feature = new Feature({
      geometry: new MultiPolygon(preloadedCoordinates),
    });
    feature.setStyle(this.getStyle(color));
    this.layers["lines"].addFeature(feature);
  }

  public loadLines(
    coordinates: Coordinate[],
    type: string,
    color?: string
  ): any {
    if (!coordinates) {
      return;
    }

    // @ts-ignore
    const geom = new MultiLineString(coordinates);
    // @ts-ignore
    const preloadedCoordinates = geom.transform("EPSG:4326", "EPSG:3857").getCoordinates();
    const feature = new Feature({
      geometry: new MultiLineString(preloadedCoordinates),
    });
    feature.setStyle(this.getStyle(color));
    this.layers["lines"].addFeature(feature);
  }
  // Style for lines
  private getStyle(color?: string): any {
    const rgb = hexToRgb(color || this.basicColor);
    const alphaColor = `rgba(${rgb.r},${rgb.g},${rgb.r},${0.3}`;
    const solidColor = color || this.basicColor;
    return new Style({
      fill: new Fill({
        color: alphaColor,
      }),
      stroke: new Stroke({
        color: solidColor,
        width: 5,
      }),
      image: new CircleStyle({
        radius: 2,
        stroke: new Stroke({
          color: solidColor,
        }),
        fill: new Fill({
          color: alphaColor,
        }),
      }),
    });
  }

  openRangePicker(): void {
    this.pickerDirective.open();
  }

  onChangeGetDate(dateRange: { start: string, end: string }) {
    if (dateRange.start && dateRange.end) {
      this.owlDateRange[0] = dateRange.start
      this.owlDateRange[1] = dateRange.end
      this.filterByDate()
    }
  }

  filterByDate(): void {
    if (this.owlDateRange[0] && this.owlDateRange[1]) {
      this.sidebar.updateDecisionFilter([this.owlDateRange[0],this.owlDateRange[1]]);
      this.sidebar.updateComplaintFilter([this.owlDateRange[0],this.owlDateRange[1]]);
      this.sidebar.updateIssueFilter([this.owlDateRange[0],this.owlDateRange[1]]);
      this.sidebar.updateActivityFilter([this.owlDateRange[0],this.owlDateRange[1]]);
    }
  }
  markDate(e): string {
    const date = e.format().split("T")[0];
    const _class = "";
    const self = this["_ref"]._view.parent.viewContainerParent.component;
    if (self.sidebar) {
      if (self.sidebar.enableItemsLayer['activity']) {
        for (const i in self.sidebar.activities) {
          const a = self.sidebar.activities[i];
          if (a.started_at && a.started_at.split(" ")[0] == date) {
            return "bold";
          }
          if (a.ended_at && a.ended_at.split(" ")[0] == date) {
            return "bold";
          }
        }
      }

      if (self.sidebar.enableItemsLayer['requirements']) {
        for (const i in self.sidebar.requirements) {
          const a = self.sidebar.requirements[i];
          if (a.start_date && a.start_date.split(" ")[0] == date) {
            return "bold";
          }
          if (a.end_date && a.end_date.split(" ")[0] == date) {
            return "bold";
          }
        }
      }

      if (self.sidebar.enableItemsLayer['issue'] && !_class) {
        for (const i in self.sidebar.issues) {
          const a = self.sidebar.issues[i];
          if (a.issues_date && a.issues_date.split(" ")[0] == date) {
            return "bold";
          }
        }
      }
      if (self.sidebar.enableItemsLayer['complaints'] && !_class) {
        for (const i in self.sidebar.complaints) {
          const a = self.sidebar.complaints[i];
          if (a.date && a.date.split(" ")[0] == date) {
            return "bold";
          }
        }
      }
    }
    return _class;
  }

  public ngOnDestroy(): void {
    this.drawing.destroy();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private drawingSubscription(
    type: string,
    newLocation: Location,
    coordinate: Coordinate,
    eventEmitter: EventEmitter<any>
  ) {
    newLocation = {
      ...newLocation,
      [type]: {
        ...newLocation[type],
        coordinates: [...newLocation[type].coordinates, coordinate],
      },
    };
    eventEmitter.emit();
    let component = null;

    switch (this.locatableType) {
      case "issues":
        component = IssueNewComponent;
        break;
      case "complaints":
        component = ComplaintNewComponent;
        break;
      case "calendars":
        component = ActivityNewComponent;
        break;
      case "stakeholders":
        component = StakeholderNewComponent;
        if (type == "polygons") component = StakeholderNewMapComponent;
        break;
      case "decisions":
        component = DecisionNewComponent;
        break;
    }

    let param;
    // eslint-disable-next-line prefer-const
    param = {
      params: {
        locations: newLocation,
        log_timestamp: new Date(),
        stakeholdermap: true,
      },
    };
    if (type == "polygons") {
      const coordinates: any = newLocation.polygons.coordinates[0][0];
      let coordinatesString: string = ' ';
      coordinates.forEach((coord)=> {
        coordinatesString = `${coordinatesString} ${coord[1]} ${coord[0]}`;
      })
      this.overpassAPiCall(coordinatesString);
      this.setDrawingMode(null);
      this.cd.detectChanges();
    } else {
      const dialog = this.dialog.open(component, modalConfig({
        data: param,
        panelClass: 'customized-dialog-container',
      }, ModalEnum.ModalDefault));
      dialog.afterClosed().subscribe((response: any) => {
        this.setOnCreationToggleShow(response, this.locatableType);
      this.setDrawingMode(null);
      this.cd.detectChanges();
      });
    }
    // this.emitValueChange();
  }
  public setDrawingMode(type: any): void {
    if (!type || this.locatableType == type.type || (this.overPassNodes && this.overPassNodes.length)) {
      this.locatableType = "";
      this.sidebar.tool = "";
      this.selectedToolSbj.next(null);
      this.overPassNodes = [];
      this.cd.detectChanges();
      this.toggleOverpassLayer({checked: false})
    } else {
      if (type.type == "calendars_lines") {
        this.purposeToolSbj.next("calendars_lines");
        this.locatableType = "calendars";
        this.selectedToolSbj.next("length");
      } else if (type.type == "stakeholders_polygons") {
        this.purposeToolSbj.next("stakeholders_polygons");
        this.locatableType = "stakeholders";
        this.selectedToolSbj.next("area");
      } else {
        this.purposeToolSbj.next(type.type);
        this.locatableType = type.type;
        this.selectedToolSbj.next("point");
      }
      this.map.updateSize();
    }
  }

  public showOpenDataInSidePanel(layer_name, data) {
    const featureList = [];
    const visibility = this.enableLayers[layer_name].getVisible();
    this.enableLayers[layer_name].getSource().on("change", (evt) => {
      const source = evt.target;
      if (source.getState() === "ready") {
        const feature = this.enableLayers[layer_name].getSource().getFeatures();
        feature.forEach((element, index) => {
          element.values_.layer = layer_name;
          featureList[index] = parsemapItem(element.values_);
          featureList[index].values = element.values_;
        });
        this.enableLayers[layer_name]["features"] = featureList;
      }
      this.activation = true;
      this.itemsList.getOpenDataLayerName(
        layer_name,
        visibility,
        this.enableLayers[layer_name]["features"]
      );
    });
    if (data.credentials) {
      if (this.enableLayers[layer_name].getSource().getState() === "ready") {
        const feature = this.enableLayers[layer_name].getSource().getFeatures();
        feature.forEach((element, index) => {
          element.values_.layer = layer_name;
          featureList[index] = parsemapItem(element.values_);
          featureList[index].values = element.values_;
        });
        this.enableLayers[layer_name]["features"] = featureList;
        this.activation = true;
      }
    }
    if (this.activation)
      this.itemsList.getOpenDataLayerName(layer_name, visibility, this.enableLayers[layer_name]["features"]);
  }
  public showOpenlayerInSidePanel(layer_name) {
    const featureList = [];
    const visibility = this.kmls[layer_name].getVisible();
    this.kmls[layer_name].getSource().on("change", (evt) => {
      const source = evt.target;
      if (source.getState() === "ready") {
        const feature = this.kmls[layer_name].getSource().getFeatures();
        feature.forEach((element, index) => {
          element.values_.layer = layer_name;
          featureList[index] = parsemapItem(element.values_);
          featureList[index].values = element.values_;
        });
        this.kmls[layer_name]["features"] = featureList;
      }
      this.activation = true;
      this.itemsList.onLayernameGet(
        layer_name,
        visibility,
        this.kmls[layer_name]["features"]
      );
    });
    if (layer_name == "Pontential_Stakeholder") {
      const feature = this.kmls[layer_name].getSource().getFeatures();
      feature.forEach((element, index) => {
        element.values_.layer = layer_name;
        featureList[index] = parsemapItem(element.values_);
        featureList[index].values = element.values_;
      });
      this.kmls[layer_name]["features"] = featureList;
      this.activation = true;
      this.itemsList.onLayernameGet(
        layer_name,
        visibility,
        this.kmls[layer_name]["features"]
      );
    }
    if (this.activation)
      this.itemsList.onLayernameGet(
        layer_name,
        visibility,
        this.kmls[layer_name]["features"]
      );
  }
  public setOnCreationToggleShow(response, type) {
    if (response) {
      response.locatable_type = type;
      if (type == "stakeholders") {
        this.openDataResponse = response;
        this.sidebar.ontoggleChangeAllItem({ checked: true }, 'stakeholder', true);
      } else if (type == "issues") {
        this.openDataResponse = response;
        this.sidebar.ontoggleChangeAllItem({ checked: true }, 'issue', true);
      } else if (type == "complaints") {
        this.openDataResponse = response;
        this.sidebar.ontoggleChangeAllItem({ checked: true }, 'complaints', true);
      } else if (type == "requirements") {
        this.openDataResponse = response;
        this.sidebar.ontoggleChangeAllItem({ checked: true }, 'requirements', true);
      } else if (type == "calendars") {
        response.calendarable_id = response.calendars[0].id;
        this.openDataResponse = response;
        this.sidebar.ontoggleChangeAllItem({ checked: true }, 'activity', true);
      }
      if (this.rightpanel.element.classList.contains("collapsed"))
        this.rightpanel._tabitems[0].querySelector("a").click();
    }
  }
  public perItemGet() {
    let item;
    this.locations$.subscribe(() => {
      if (this.openDataResponse.calendarable_id) {
        item = this.locationsSbj
          .getValue()
          .filter(
            (l) =>
              l.id == this.openDataResponse.calendarable_id &&
              l.locatable_type == this.openDataResponse.locatable_type
          );
      } else {
        item = this.locationsSbj
          .getValue()
          .filter(
            (l) =>
              l.id == this.openDataResponse.id &&
              l.locatable_type == this.openDataResponse.locatable_type
          );
      }
      setTimeout(() => {
        this.itemsList.onItemClick(item[0], true);
      }, 600);
    });
  }
  public onRemoveAllLayersSidePanel() {
    if (
      !collect(this.openDataGetVisible()).contains(true) &&
      !this.kmls["Pontential_Stakeholder"].getVisible() &&
      !this.sidebar.enableItemsLayer['stakeholder'] &&
      !this.sidebar.enableItemsLayer['issue'] &&
      !this.sidebar.enableItemsLayer['complaints'] &&
      !this.sidebar.enableItemsLayer['requirements'] &&
      !this.sidebar.enableItemsLayer['activity']
    ) {
      this.itemsList.onBack();
      this.rightpanel._tabitems[0].querySelector("a").click();
      this.showToggle = false;
    }
  }
  //over pass api work
  public toggleOverpassLayer(event) {
    if (!event.checked) {
      this.toggleOpenData("Pontential_Stakeholder");
      return;
    }
    this.overpassAPI();
  }
  public overpassAPI() {
    this.addPontentialStakeholder(true);
    let subprojectActive;
    this.main_MapCoordinates = '';
    if (this._rout.url.includes('sub')) {
      subprojectActive = this.subProjectQuery.getActive();
      if (subprojectActive.locations.polygons) {
        subprojectActive.locations.polygons.coordinates[0][0].forEach(element => {
          this.main_MapCoordinates = `${this.main_MapCoordinates} ${element[1]} ${element[0]}`;
        });
        this.main_MapCoordinates = this.main_MapCoordinates.replace(/,/g, ' ');
        this.overpassAPiCall(this.main_MapCoordinates);
      } else {
        this.addPontentialStakeholder(true);
      }
    } else {
      if (this.globals.projectConfig.polygons) {
        this.globals.projectConfig.polygons.coordinates[0][0].forEach(element => {
          this.main_MapCoordinates = `${this.main_MapCoordinates} ${element[1]} ${element[0]}`;
        });
        this.main_MapCoordinates = this.main_MapCoordinates.replace(/,/g, ' ');
        this.overpassAPiCall(this.main_MapCoordinates);
      } else {
        this.addPontentialStakeholder(true);
      }
    }
  }
  public addPontentialStakeholder(action = false) {
    const soure = new VectorSource();
    const fill = new Fill({
      color: "#3399CC",
    });
    const styles = [
      new Style({
        image: new CircleStyle({
          fill: fill,
          radius: 5,
        }),
        fill: fill,
      }),
    ];
    this.kmls["Pontential_Stakeholder"] = new VectorLayer({
      // @ts-ignore
      name: "Pontential_Stakeholder",
      source: soure,
      visible: false,
    });
    let feature;
    if (!action) {
      this.overPassNodes.forEach((element: any) => {
        if (element.type == 'node') {
          feature = new Feature({
            // @ts-ignore
            geometry: new Point(new fromLonLat([element.lon, element.lat])),
            naam: (element.tags.name ? element.tags.name : (element.tags.amenity ? element.tags.amenity : (element.tags.shop ? element.tags.shop : element.tags.place))),
            lat: element.lat,
            lon: element.lon,
            id: element.id,
            tag: element.tags,
            relevant: element.tags.name ? true : false,
            phone: element.tags.phone ? element.tags.phone : '',
            email: element.tags.email ? element.tags.email : '',
            layer: 'Pontential_Stakeholder'
          });
          feature.setStyle(styles)
          soure.addFeatures([feature]);
        }
      });
      this.map.addLayer(this.kmls["Pontential_Stakeholder"]);
      this.toggleOpenData("Pontential_Stakeholder");
    }
    this.is_loadOverpassApi = false;
  }
  public overPassDataZoom(feature) {
    this.layerItemClicked = true;
    if (feature.values_.layer == "Pontential_Stakeholder") {
      const featurezoom = this.kmls[feature.values_.layer]
        .getSource()
        .getFeatures()
        .filter((f) => {
          return f.values_['id'] == feature.values_.id;
        })[0];
      this.map
        .getView()
        .fit(featurezoom.getGeometry().getExtent(), { maxZoom: 15 });
    }
  }
  public overpassAPiCall(main_MapCoordinates) {
    let processID;
    this.is_loadOverpassApi = true;
    const overpass_query = overpassApiQuery(main_MapCoordinates);
    this._mapServices.potentialStakeholderBackendAPI(overpass_query)
      .pipe(
        tap((res: any) => {
          processID = res.process_id;
          if (!res.is_process) {
            this.is_loadOverpassApi = false;
            this.overpassApiBackendProcess(res)
          } else {
            if (processID) {
              this.processIDBroadcasting(processID, main_MapCoordinates);
            }
          }
        }),
        catchError(err => {
          this.addPontentialStakeholder(true);
          return throwError(err);
        })
      ).subscribe();
  }
  //broadcasting process api for overpass
  public processIDBroadcasting(processID, main_MapCoordinates): void {
    const interval = setInterval(() => {
      this._projectConfigsService.broadcastingProcess(processID)
        .pipe(
          tap((res: any) => {
            if (!res.ok) {
              clearInterval(interval);
              this.overpassAPiCall(main_MapCoordinates);
            }
          })
        ).subscribe();
    }, 4000);
  }
  //overpass backend process api calling
  public overpassApiBackendProcess(res): void {
    if (res.data) {
      if (res.data.elements.length) {
        this.overPassNodes = res.data.elements;
        this.addPontentialStakeholder();
        this.cd.detectChanges();
      } else {
        this.addPontentialStakeholder(true);
        this.cd.detectChanges();
      }
    }
  }
  //on chnage layer style
  public onChangeLayerStyle(data) {
    this.map.getLayers().forEach(layer => {
      // @ts-ignore
      if (layer.values_.sublayer_name == data.name) {
        setFeatureStyle(data, layer);
      }
    });
  }
  //public layers data load on map
  public loadPublicLayers(data) {
    this.openLayersResp.push(data);
    if (data.layer.credentials) {
      if (data.layer.credentials.username && data.layer.credentials.password) {
        this.onLoadPrivateLayers(data);
      }
    } else {
      this.enableLayers[data.layer_name] = openLayerMaping(data.type, this.enableLayers, data.layer, data.layer_name, '', 'pgeojson', data.layer_id, data.metadata, this.BBOX, data.symobology, this);
      this.map.addLayer(this.enableLayers[data.layer_name]);
      if (data.symobology) {
        if (data.symobology.uniqueValueInfos) {
          this.symbolicName.push({ layer_name: data.layer_name, symobology: data.symobology.uniqueValueInfos });
          this.symbolicName = collect(this.symbolicName).unique((item => item.layer_name)).all();
        }
      }
    }

  }
  //load public layers
  public onLoadPublicLayers(): void {
    this.publicLayers = new OpenDataLayers(this._mapServices);
  }
  //open data get visibality
  public openDataGetVisible() {
    const openDataVisible = [];
    for (const key in this.enableLayers) {
      openDataVisible.push(this.enableLayers[key].getVisible())
    }
    return openDataVisible;
  }
  // opendata multiple Symobology set
  public openDataMultipleSymobology(layer_name, symobology, type): void {
    if (this.enableLayers[layer_name].getVisible()) {
      this.layerSymbologyInterval.forEach(element => {
        clearInterval(element);
      });
      this.map.getLayers().forEach(layer => {
        // @ts-ignore
        if (layer.values_.sublayer_name == layer_name) {
          // @ts-ignore
          if (layer.features) {
            // @ts-ignore
            layer.getSource().getFeatures().forEach(element => {
              setMultipleSymobologyStyleColor(element, symobology, type)
            });
          }
        }
      });
    }
  }
  //symbology logic added
  public symbologyAdded(layer, type): void {
    if (this.enableLayers[layer].getVisible()) {
      this.symbolicName.forEach((item: any) => {
        if (item.layer_name == layer) {
          this.layerSymbologyInterval.push(setInterval(() => {
            if (this.enableLayers[layer].getSource().getFeatures().length) {
              this.openDataMultipleSymobology(item.layer_name, item.symobology, type)
            }
          }, 1000));
        }
      });
    }
  }
  //open data extend logics
  public openDataZoomExtentLogic(data): void {
    if (this.enableLayers[data.name].getVisible()) {
      this.layerLoadInterval.push(setInterval(() => {
        if (this.enableLayers[data.name].getSource().getFeatures()) {
          if (this.enableLayers[data.name].getSource().getFeatures().length) {
            this.zoomToOpenDataLayers(data);
          }
        }
      }, 1000));
    }
  }
  //add potential stakeholder in the kml array
  public addPontentialStakeholderToKML() {
    this.kmls["Pontential_Stakeholder"] = new VectorLayer({
      // @ts-ignore
      name: "Pontential_Stakeholder",
      source: new VectorSource(),
      visible: false,
    });
  }
  /*
    On click show relation feature on map
  */
  public showSingleRelationFeature(item: any, show: boolean): void {
    setTimeout(() => {
      if (this.enableLayers[item.layer_name]) {
        if (show) {
          this.enableLayers[item.layer_name].setVisible(true);
          if (this.enableLayers[item.layer_name].getSource().getFeatures().length) {
            this.enableLayers[item.layer_name].getSource().getFeatures().map((feature: any) => {
              if (feature.values_[item.id_field] !== item.layer_id) {
                feature.setStyle(setTransprantFeatures());
              } else {
                feature.setStyle(this.enableLayers[item.layer_name].getStyle());
                this.map.getView().fit(feature.getGeometry().getExtent());
                this.map.getView().setZoom(14);
                this.showTooltipPublicLayers(item);
              }
            });
          }
          else {
            this.showSingleRelationFeature(item, show);
          }
        } else {
          this.enableLayers[item.layer_name].setVisible(false);
        }
      }
    }, 1000);
  }
  /*
    On Load Private Layers On Map
  */
  public onLoadPrivateLayers(data: any): void {
    const url = getRegExpURL(data.layer.url, 'service=wfs');
    const link = `${url}service=WFS&` + `version=${data.layer.version}&request=GetFeature&typename=${data.layer_name}&` + `outputFormat=application/json&srsname=EPSG:3857&` + `bbox=${this.BBOX},EPSG:3857`;
    this._mapServices.getPrivateFeatures(data.layer, link)
      .pipe(
        tap(res => {
          this.enableLayers[data.layer_name] = openLayerPrivateMaping(data.type, this.enableLayers, data.layer, data.layer_name, '', 'pgeojson', data.metadata, this.BBOX, res);
          this.map.addLayer(this.enableLayers[data.layer_name]);
        })
      ).subscribe()
  }
  public onShowRelationFeature(layers: any) {
    layers.forEach((layer: any) => {
      this.showSingleRelationFeature(layer.data, layer.show)
    })
  }
  /*
    Sleep function for map loadding
  */
  public sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
