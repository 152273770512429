<div #filterSort class="filter-selector">
    <div *ngIf="disable">
        <div class="d-cursor-notallow" (click)="show = !show">
            <mat-icon *ngIf="sortField" class="arrow_drop_down">arrow_drop_down</mat-icon>
        </div>
    </div>
    <div *ngIf="!disable">
        <div class="d-cursor-hand" (click)="show = !show">
            <mat-icon *ngIf="sortField" class="arrow_drop_down">arrow_drop_down</mat-icon>
        </div>
    </div>
    <div *ngIf="show && !disable" class="filter-dropdown">
        <mat-icon (click)="toggle()" class="d-text-middle pull-right close">close</mat-icon>
        <!-- Filtering -->
        <div class="filter-lists mt-17" *ngIf="entity">
            <div class="header mb-10">
                {{ 'stakeholders.uncategorized_add' | transloco | capitaliz }}
            </div>
            <mat-selection-list [(ngModel)]="selectedEntity">
                <mat-list-option *ngFor="let o of entity; index as i" checkboxPosition="before" [value]="o">
                    <span>{{o.name}}</span>
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div class="action-btns">
            <button *ngIf="entity" (click)="cancel()" mat-stroked-button class="half-width mt-10">{{ 'clear' | transloco | capitaliz }}</button>
            <button (click)="apply()" [class.half-width]="entity" mat-flat-button class=" mt-10">{{ 'apply' | transloco | capitaliz }} </button>
        </div>
    </div>
</div>
