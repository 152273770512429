import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-closable-snackbar',
  templateUrl: './closable-snackbar.component.html',
  styleUrls: ['./closable-snackbar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClosableSnackbarComponent {

  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<ClosableSnackbarComponent>,) { }

}
