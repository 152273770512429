import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { translate } from '@jsverse/transloco';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Complaint } from '../../../../akita/complaints/state/complaint.model';
import { Log } from '../../../../akita/contact-moments/state/log.model';
import { Employee } from '../../../../akita/contact-persons/state/employee.model';
import { Middel } from '../../../../akita/content/state/middel.model';
import { Issue } from '../../../../akita/issues/state/issue.model';
import { Decision } from '../../../../akita/requirements/state/decision.model';
import { SearchResponse, SearchResult } from '../../../../akita/search/state/search.model';
import { Stakeholder } from '../../../../akita/stakeholders/state/stakeholder.model';
import { SearchService } from '../../../../core/service/api/search.service';
import { Globals } from '../../../../globals';


@Component({
  selector: 'app-all-search-select',
  templateUrl: './all-search-select.component.html',
  styleUrls: ['./all-search-select.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: AllSearchSelectComponent,
    multi: true
  }]
})
export class AllSearchSelectComponent implements OnInit, ControlValueAccessor,Validator, OnDestroy {

  public searchForm: FormGroup;
  public placeholder:any;
  private preLoaderSbj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public preLoader$: Observable<boolean> = this.preLoaderSbj.asObservable();
  private listSearchSbj: BehaviorSubject<SearchResponse> = new BehaviorSubject(null);
  public listSearch$: Observable<SearchResponse> = this.listSearchSbj.asObservable();
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() models: string[] = ['issues', 'complaints',];
  @Input() required: boolean=false;

  private project: any;
  private valuesSbj: BehaviorSubject<any> = new BehaviorSubject({});
  public values$: Observable<any> = this.valuesSbj.asObservable();
  public valuesSet: Set<string>;

  constructor(
    private searchService: SearchService,
    public globals: Globals,
    private cdRef: ChangeDetectorRef,
    private _eref: ElementRef,
  // eslint-disable-next-line no-empty-function
  ) { }

  // eslint-disable-next-line no-empty-function, @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    this.placeholder = this.required ? `${translate('actions.relation')}*` : translate('actions.relation');
    this.searchForm = new FormGroup({
      'keyword': new FormControl('')
    });

    this.project = this.globals.project.code;
    this.searchForm.get('keyword').valueChanges
      .pipe(
        debounceTime(350),
        untilDestroyed(this)
      )
      .subscribe(
        keyword => this.searchInput(keyword)
      );
  }

  public searchInput(keyword): Subscription {
    if (!keyword) {
      this.preLoaderSbj.next(false);
    } else {
      return this.searchService.getSearch(keyword, this.models)
        .subscribe((res: any) => {
          this.listSearchSbj.next(res);
          this.preLoaderSbj.next(false);
        });
    }
  }

  private resetSearch(): void {
    this.listSearchSbj.next(null);
    this.searchForm.reset();
  }

  public selectItem(type: string, item: SearchResult<Issue | Decision | Complaint | Middel | Log | Stakeholder | Employee>): void {
    const value = this.valuesSbj.getValue();
    if (!value[type]) {
      value[type] = [];
    }

    const exists = this.valuesSet.has(`${type}-${item.searchable.id}`);
    if (exists) {
      value[type] = value[type].filter(i => i.id !== item.searchable.id);
    } else {
      value[type] = [...value[type], item.searchable];
    }

    this.valuesSbj.next(value);
    this.writeValue(value);
    this.onChange(value)
    this.resetSearch();
  }

  public removeChip(type: string | number, item: any): void {
    const value = this.valuesSbj.getValue();
    if (!value[type]) {
      return;
    }

    value[type] = value[type].filter(v => v.id !== item.id);
    this.valuesSbj.next(value);
    this.writeValue(value);
    this.onChange(value)
  }

  // eslint-disable-next-line no-empty-function
  public onChange: (value: any) => void = value => { };
  // eslint-disable-next-line no-empty-function
  public onTouched: (touched: boolean) => void = touched => { };

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.errors) {
       if (this.required && control.errors.required) {
        this.searchForm.get('keyword').setErrors({
          required: true
        });
      }
      return control.errors;
    }

    return null;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = (touched: boolean) => {
      if (this.required) {
        this.searchForm.get('keyword').setErrors({
          required: true
        });
      }

      this.searchForm.get('keyword').markAsTouched();

      fn(touched);
      this.cdRef.detectChanges();
    };
  }

  public writeValue(obj: any): void {
    this.valuesSbj.next(obj);

    this.valuesSet = new Set<string>();
    for (const i in obj) {
      if (!this.models.some(model => model === i)) {
        continue;
      }

      if (Array.isArray(obj[i])) {
        obj[i].forEach(entry => this.valuesSet.add(`${i}-${entry.id}`));
      }
    }
  }
}
