<div [id]="'map-' + randomId" [ngClass]="showToggle ? 'show-toggle' : ''" class="map-container" #mapContainer>
    <app-openlayers-sidebar-v2 [displayProgressSpinner]="is_loadOverpassApi" [hidden]='hideSidePanel' #sidebar (ontoggleOverpassLayer)="toggleOverpassLayer($event)" (ontoggleOpenDataLayers)="toggleOpenDataLayers($event)" (layerChange)="updateFeatures($event)" [buttons]="sidebarButtons" (selectedToolChange)="setDrawingMode($event)"
    (newCreationOpenData)="perItemGet()" (changeLayerStyle)="onChangeLayerStyle($event)" (loadPublicLayers)="onLoadPublicLayers()">
    </app-openlayers-sidebar-v2>
    <app-openlayers-rightpanel [hideBack]='hideSidePanel' [showToggle]="showToggle" #rightpanel>
        <app-items-list [sidebar]="sidebar" (itemClick)="zoomToPoint($event)" (relationItemClick)="onShowRelationFeature($event)" (itemHover)="showTooltip($event)" (itemOpenLayerHover)="showTooltipOpenlayer($event)" (itemOpenLayerArcGisHover)="showTooltipPublicLayers($event)" (openLayerDataFullForm)="openMapItemDetail($event)"
            (openLayerDataArcGisFullForm)="openMapArcGisItemDetail($event)" [features]="locations$" (allRemoveSidePanel)="onRemoveAllLayersSidePanel()" (overPassDataZoom)="overPassDataZoom($event)"></app-items-list>
    </app-openlayers-rightpanel>
    <span [ngClass]="sidebar.enableItemsLayer['complaints'] || sidebar.enableItemsLayer['issue'] || sidebar.enableItemsLayer['requirements'] || sidebar.enableItemsLayer['activity'] ? '' : 'hidden'" class="filter-button ngx-daterangepicker-action">
        <div class="input-group">
          <app-datepicker-map [start]="dateRange.startDate" [end]="dateRange.endDate" [maxDate]="dateRange.endDate" [showResetIcon]="true"  (reset)="resetFilter()" (dateChange)="onChangeGetDate($event)"></app-datepicker-map>
      </div>
    </span>
    <div [id]="'popup-' + randomId"></div>

    <div #popupContentContainer class="ol-popup">
      <a id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content">
        <div >
          <h4>{{'maps.create_item_from_address' | transloco}}</h4>
          <ul id="popup-list" *ngIf="_mapDataService.pointData">
            <li data-item="stakeholders"  newEntity="stakeholders" [newEntityParams]="_mapDataService.pointData" [newEntityOptions]="{}">{{'map_popup.stakeholder' | transloco}}</li>
            <li data-item="issues"   newEntity="issues" [newEntityParams]="_mapDataService.pointData" [newEntityOptions]="{}">{{'map_popup.issue' | transloco}}</li>
            <li data-item="complaints"    newEntity="complaints" [newEntityParams]="_mapDataService.pointData" [newEntityOptions]="{}">{{'map_popup.complaints' | transloco}}</li>
            <li data-item="requirements"  newEntity="requirements" [newEntityParams]="_mapDataService.pointData" [newEntityOptions]="{}">{{'map_popup.requirements' | transloco}}</li>
            <li data-item="activities"    newEntity="activities" [newEntityParams]="_mapDataService.pointData" [newEntityOptions]="{}">{{'map_popup.activity' | transloco}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="custom-popup" id="popup1">
      <div *ngIf="showPopup">
        <h3 style="margin: 5px 5px 5px 5px; color: gray;">{{'maps.multiple_items_found' | transloco}}</h3>
        <ul
          style="
            max-height: 150px;
            overflow-y: auto;
            list-style-type: none;
            padding: 0px;
            cursor: pointer;
          "
        >
          <li
            *ngFor="let featureName of featureNames"
            style="border-bottom: 1px solid #ccc; padding: 5px; color: #48a0f3"
            (click)="onSelectFeature(featureName)"
          >
            {{ featureName.values_.name }}
          </li>
        </ul>
        <span class="close-icon" (click)="closePopup()">x</span>
      </div>
    </div>

</div>

