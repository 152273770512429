import {Component} from '@angular/core';
import {PubsubService} from "../../../../core/service/api/pubsub.service";
import {HeaderGeneralComponent} from "../header-general.component";
import {Globals} from "../../../../globals";
import {Router} from '@angular/router';
import {RouteInterceptorService} from '../../../../core/service/util/route-interceptor.service';

@Component({
  selector: 'app-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.css']
})
export class HeaderDefaultComponent extends HeaderGeneralComponent {

  constructor(public pubsub: PubsubService, public globals: Globals, public router: Router, public routeInterceptor: RouteInterceptorService) {
    super(pubsub, globals, router, routeInterceptor); 
  }

}
