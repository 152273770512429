import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { showFeedbackError, showFeedbackSaved } from 'src/app/core/util/notification';

@Component({
  selector: 'app-',
  templateUrl: './archive-verification-modal.component.html',
  styleUrls: ['./archive-verification-modal.component.sass'],
})
export class ArchiveVerificationModalComponent{
  confirm: boolean;
  loader:boolean=false;
  showerror:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<ArchiveVerificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectsService: ProjectsService
    // eslint-disable-next-line no-empty-function
    ) {}

  submit(value) {
    if(value){
      this.loader=true;
      this.projectsService.projectArchive(this.data.company,this.data.project).subscribe((res)=>{
        this.dialogRef.close(res);
        showFeedbackSaved('Project is Succesvol Gearchiveerd');
      },(error)=>{
        this.showerror=true;
        this.loader=false;
        showFeedbackError(error);
      })
    }else{
      this.dialogRef.close(value);
    }
  }

}
