import {Injectable} from '@angular/core';
import {StakeholdersStore} from './stakeholders.store';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from '../../../core/service/common/abstract.service';
import {Stakeholder} from './stakeholder.model';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PaginationParams} from '../../../core/service/common/pagination-params';
import {PaginatedResponse} from '../../../core/service/common/paginated-response';
import {StakeholdersQuery} from './stakeholders.query';
import {deepCopy} from '../../../core/util/deepCopy';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({ providedIn: 'root' })
export class StakeholdersService extends AbstractService {

  constructor(private stakeholdersStore: StakeholdersStore, private stakeholderQuery: StakeholdersQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams): Observable<Stakeholder[]> {
    return this.http.get(`/api/stakeholders`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Stakeholder>) => this.stakeholdersStore.set(response.data)),
        map((response: PaginatedResponse<Stakeholder>) => response.data)
      );
  }

  @ServiceErrorHandler()
  public getSummary(id): Observable<any> {
    return this.http.get(`/api/map/summary/stakeholders/${id}`)
      .pipe(
        map((response:Stakeholder) => response)
      );
  }

  @ServiceErrorHandler()
  public find(code: string, withs: string[] = []): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }

    const request = this.http.get(`/api/stakeholders/${code}`, {
      headers: this.headers,
      params: params
    })
      .pipe(
        tap ((stakeholder: Stakeholder) => {
          if (this.stakeholdersStore.getValue().entities && Object.entries(this.stakeholdersStore.getValue().entities).length > 0) {
            this.stakeholdersStore.update(stakeholder);
          } else {
            this.stakeholdersStore.set([deepCopy(stakeholder)]);
          }

          this.stakeholdersStore.setActive(stakeholder.id);
        })
    );

    return request;
  }

  @ServiceErrorHandler()
  public update(stakeholder: Stakeholder): Observable<Stakeholder> {
    this.stakeholdersStore.update(stakeholder.id, stakeholder);
    return this.http.put(`/api/stakeholders/${stakeholder.code}`, stakeholder, this.httpOptions)
      .pipe(map((response: Stakeholder) => response) );
  }

  @ServiceErrorHandler()
  public create(stakeholder: Stakeholder): Observable<Stakeholder> {
    return this.http.put('/api/stakeholders', stakeholder, this.httpOptions)
      .pipe(tap((response: Stakeholder) => {
        this.stakeholdersStore.add(stakeholder);
      }));
  }

  @ServiceErrorHandler()
  public delete(stakeholder: Stakeholder): Observable<any> {
    return this.http.delete(`/api/stakeholders/${stakeholder.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.stakeholdersStore.remove(stakeholder.id);
      }));
  }
}
