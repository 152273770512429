import { Globals } from './../../../../globals';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';

interface Column {
  name: string;
  value: boolean;
}
@Component({
  selector: 'app-custom-columns',
  templateUrl: './custom-columns.component.html',
  styleUrls: ['./custom-columns.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomColumnsComponent extends GenericComponent{

  // eslint-disable-next-line no-empty-function
  constructor(private cdRef: ChangeDetectorRef, private config: ConfigsService, public globals: Globals) {
    super()
   }
  @Output() selectdColumns = new EventEmitter<object>();
  @Output() closeDropdown = new EventEmitter<boolean>();
  @Input() optionalColumns: Column[] = [];
  @Input() type: string = '';
  selectedColumns: any= {};
  show: boolean = true;
  colsEnabl: boolean = true;
  getDisabledStatus(column: any): boolean {
    return this.selectedColumns.length >= 3 && !column.value;
  }

  toggle(){
    this.show = false;
    this.closeDropdown.emit(this.show);
    this.cdRef.detectChanges();
  }
  columnSelected(column: any) {
    this.optionalColumns.forEach((column: any) => {
      const key = `project.columns.${ this.type }.${ column.name}`;
      this.selectedColumns[key] = column.value;
    })
    this.config.updateConfigSetting_deprecated("columns", this.selectedColumns).subscribe();
    this.selectdColumns.emit(this.optionalColumns);
  }
}
