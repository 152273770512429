import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.detectZoom();
    // Re-detect on window resize
    window.addEventListener('resize', () => this.detectZoom());
  }

  private detectZoom(): void {
    const zoomLevel = Math.round(window.devicePixelRatio * 100);
    if (zoomLevel === 90) {
      this.renderer.addClass(document.body, 'zoom-90');
    } else {
      this.renderer.removeClass(document.body, 'zoom-90');
    }
  }
}
