import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { Globals } from 'src/app/globals';
import { NewEventData, CalendarCreateEventComponent } from '../calendar-create-event/calendar-create-event.component';

@Component({
  selector: 'app-create-analysis-item',
  templateUrl: './create-analysis-item.component.html',
  styleUrls: ['./create-analysis-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAnalysisItemComponent extends GenericComponent {

  public typeCal: string;

  constructor(public globals: Globals, @Inject(MAT_DIALOG_DATA) public data: NewEventData, public dialogRef: MatDialogRef<CalendarCreateEventComponent>) {
    super();
  }

  public switchEvent(type: string): void {
    this.typeCal = type;
    this.dialogRef.updateSize('60vw', 'auto');
  }

  public resetForm(): void {
    this.dialogRef.updateSize(null, null);
    this.typeCal = null;
  }

  public createEvent(event): void {
    this.dialogRef.close({
      event: event,
      type: this.typeCal
    });

    this.resetForm();
  }

  public close() {
    this.dialogRef.close();
  }

}
