<h2 mat-dialog-title>Versie downloaden</h2>
<mat-dialog-content *ngIf="initialized">
  <label>{{ data.name }}</label>
  <ul class="list-group">
    <a  download mat-dialog-close (click)="(data.mimetype.includes('image') && data.type.includes('s3')) ?  lightboxOpen(data) : download()">
      <li class="row list-group-item list-item d-cursor-hand" matTooltip="Laatste versie">
        <span class="col-md-2 col-xs-2">
          <mat-icon class="d-text-middle">save_alt</mat-icon>
        </span>
        <span class="col-md-10 col-xs-10 text-left">
          Laatste versie
        </span>
      </li>
    </a>
    <a *ngFor="let version of versions"  download mat-dialog-close (click)="(data.mimetype.includes('image') && data.type.includes('s3')) ?  lightboxOpen(version) : download(version)">
      <li class="row list-group-item list-item d-cursor-hand" [matTooltip]="'Versie ' + version.version">
        <span class="col-md-2 col-xs-2">
          <mat-icon class="d-text-middle">save_alt</mat-icon>
        </span>
          <span class="col-md-7 col-xs-7 text-left">
          {{ version.created | formatdate: 'dd-MM-yyyy HH:mm' : globals.timezone }}
        </span>
          <span class="col-md-3 col-xs-3 text-left">
          {{ version.size | bytes }}
        </span>
      </li>
    </a>
  </ul>
</mat-dialog-content>
<div *ngIf="!initialized" class="text-center d-text-middle row spinner-container">
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
<mat-dialog-actions>
  <button class="pull-right" mat-stroked-button mat-dialog-close (click)="close()">{{'close' | transloco | capitaliz}}</button>
</mat-dialog-actions>
