import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import {
  TagLocationReturnValue,
  tagOverlay,
  trackInputChangeEvent,
  trackPressEvent
} from '@objectiv/tracker-browser';
import { finalize } from 'rxjs/operators';
import { get } from 'scriptjs';
import { AppInjector } from 'src/app-injector';
import { Permission } from 'src/app/core/enum/permission';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { Stakeholder } from '../../../../akita/stakeholders/state/stakeholder.model';
import { Entities } from '../../../../core/enum/entities';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { StakeholdersService } from '../../../../core/service/api/stakeholders.service';
import { PaginatedResponse } from '../../../../core/service/common/paginated-response';
import { PaginationParams } from '../../../../core/service/common/pagination-params';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { deepCopy } from '../../../../core/util/deepCopy';
import { googleMapAddressParse } from '../../../../core/util/googleMapAddressParse';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../../shared/directive/new-entity.directive';
import { MapService } from 'src/app/core/service/api/map.service';
declare const google: any;

@Component({
  selector: 'app-stakeholder-new',
  templateUrl: './stakeholder-new.component.html',
  styleUrls: ['./stakeholder-new.component.sass']
})
export class StakeholderNewComponent extends NewEntityModal implements OnInit, AfterViewInit {
  @ViewChild('autocomplete', { static: false }) autocompleteInput: ElementRef<HTMLInputElement>;
  private autocomplete: any;
  private observer: MutationObserver;
  private initialized = false;

  public responseLayers: any;
  public users: any[];
  declare public entity: Stakeholder;
  public setOpenlayerMapViewer: boolean = false;
  public stakeholdertypes: any[] = [];
  public enableManualAddress: boolean = false;
  public googleMapOptions = {
    types: [],
  };
  public stakeholderOverlay: TagLocationReturnValue;

  themeType = ThemeType.Stakeholders;
  constructor(private _cachedService: CachedService, public _rout: Router, @Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<StakeholderNewComponent>,
    public globals: Globals, private _stakeholdersService: StakeholdersService, private pubsub: PubsubService, private _mapSerice: MapService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
    this._mapSerice.setData(this.data.params);
    if (this.data.params && this.data.params.locations) {
      if (!this.data.params.locations.locatable_type || this.data.params.locations.locatable_type === 'undefined') {

        this.data.params.locations.locatable_type = 'stakeholders'
      }
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._stakeholdersService.getStakeholderTypes(new PaginationParams()).subscribe(
      (response: PaginatedResponse) => this.stakeholdertypes = response.data);
    this._cachedService.getUsers().subscribe(users => this.users = users);
    this.stakeholderOverlay = tagOverlay({
      id: 'stakeholder-new',
      options: {
        trackVisibility: false
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkAndInitializeAutocomplete()
    if (!this.globals.googleMapLibLoaded) {

      this.globals.googleMapLibLoaded = true;
    }
  }

  private checkAndInitializeAutocomplete(): void {
    if (!this.initialized && this.autocompleteInput) {
      this.initializeAutocomplete();
      this.initialized = true; // Prevent re-initialization
    }
  }

  private initializeAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.nativeElement, {
      types: ['address'], // Customize the types as needed
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.handleAddressChange(place)
    });
  }

  public closeModal(event) {
    trackPressEvent({ element: event.target })
    this.close();
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  public submit(): void {
    this.isProcessing = true;
    const params = deepCopy(this.entity);
    if (params.stakeholdertypes) {
      params.stakeholdertypes = params.stakeholdertypes;
    }

    this._stakeholdersService.submitStakeholder(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          if (this._rout.url.includes('belangmachtmatrix') || this._rout.url.includes('krachtenveldmatrix')) {
            const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
            snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data: data,
                message: translate('error.stored'),
                type: 'matrix'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
            });
          } else {
            const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
            snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data: data,
                message: translate('error.stored'),
                type: 'openItems',
                item: 'stakeholders',
                url: 'stakeholders'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
            });
          }
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.stakeholders, data);
        });
  }

  protected initializeEntity(): void {
    this.entity = {
      name: null,
      description: '',
      address: null,
      postcode: null,
      city: null,
      country: 'Nederland',
      users_holder: null
    };
    if (this.data.params) {
      if (this.data.params.stakeholdermap) {
        this.setOpenlayerMapViewer = this.data.params.stakeholdermap;
      }
    }
  }
  public updateAddress(): void {
    this.entity.locations = null;
  }

  public handleAddressChange(event): void {
    const googleAddress = googleMapAddressParse(event);
    this.entity.address = googleAddress.address;
    this.entity.postcode = googleAddress.postcode;
    this.entity.city = googleAddress.city;
    if (googleAddress.latitude && googleAddress.longitude) {
      this.entity.locations = {
        points: {
          type: 'MultiPoint',
          coordinates: [
            [parseFloat(googleAddress.longitude), parseFloat(googleAddress.latitude)],
          ]
        },
        locatable_type: 'stakeholders',
      };
    }
  }

  public compareType(type1: any, type2: any): boolean {
    if (type1 && type2) {
      return type1.id === type2.id;
    }

    return false;
  }

  @Allowed(Permission.StakeholdersCreate)
  public updateSelectedTheme(theme) {
    this.entity.stakeholdertypes = theme;
  }

  public trackSelectedStakeholderType(event) {
    trackInputChangeEvent({ element: event.target });
  }

}
