/**
 * This function generate unique array of object based on selected key
 * @param array The array that needs to be unique
 * @param uniqueKey The array key which the uniqueness based on
 */
export function uniqueArray(array, uniqueKey): any[] {
  const unique = {};
  const response = [];
  array.forEach(item => {
    unique[item[uniqueKey]] = item;
  });

  for (const key in unique) {
    response.push(unique[key]);
  }

  return response;
}
