import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms"
import {ActivatedRoute} from "@angular/router";
import {StakeholdersVerantwoordelijkeService} from "../../../../../../../core/service/api/stakeholders-verantwoordelijke.service";
import {GenericComponent} from "../../../../../../../core/util/abstract/generic-component";
import {Globals} from "../../../../../../../globals";
import {finalize} from 'rxjs/operators';
import {showFeedbackError, showFeedbackSaved} from '../../../../../../../core/util/notification';
import { Stakeholder } from 'src/app/akita/stakeholders/state/stakeholder.model';

@Component({
  selector: 'stakeholder-verantwoordelijken',
  templateUrl: './general-verantwoordelijken.component.html',
  styleUrls: ['./general-verantwoordelijken.component.sass']
})

export class GeneralVerantwoordelijkenComponent extends GenericComponent implements OnInit {

  users: any[];
  usersNgxSelect: any[];
  protected stakeholderCode: string;
  selectedHolderId: number;
  selectedUserIds : number[];
  isLoadingResults = true;

  verantwoordelijkenForm: FormGroup;
  submitted = false;
  // FORM DECLARATION
  id: string = '';
  name: string = '';
  employees: string = '';
  employee_id: string = '';
  frequency: string = '';
  @Input() public stakeholder: Stakeholder;


  constructor(
    private dataVerantwoordelijkeService: StakeholdersVerantwoordelijkeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public globals: Globals
  ) {
    super(globals);
  }


  ngOnInit() {
    this.getUsers();

    // INITIALIZE FORM INPUT
    this.verantwoordelijkenForm = this.formBuilder.group({
      id: [''],
      name: [''],
      email: ['', [Validators.required, Validators.email]],
      is_removed: ['']
    });

    // LOAD CODE STAKEHOLDER
    this.stakeholderCode = this.stakeholder.code;

      // LOAD DATA
      this.verantwoordelijkenForm.reset();
      this.getUserHolder();
      this.getListVerantwoordelijken();
  }

  // GET ALL USER
  private getUsers() {
    this.dataVerantwoordelijkeService.getUser()
      .pipe(finalize(() => this.isLoadingResults = false))
      .subscribe((res: any) => {
        if (!res.data) {
          return;
        }

        this.users = res.data;
        this.usersNgxSelect = res.data.map(user => {
          user.text = user.name;
          return user;
        });
        this.isLoadingResults = false;
      });
  }

  // GET DATA HOLDER
  private getUserHolder() {
    this.dataVerantwoordelijkeService.getHolder(this.stakeholderCode)
      .pipe(finalize(() => this.isLoadingResults = false))
      .subscribe((res: any) => {
        if (res && res.id) {
          this.selectedHolderId = res.id;
        }
      });
  }

  // POST DATA HOLDER
  postHolderpostHolderVerantwoordelijken() {
    if (!this.selectedHolderId) {
      return;
    }

    const holder = this.users.filter(user => user.id === this.selectedHolderId).shift();

    // Can't find holder
    if (!holder) {
      showFeedbackError('No holder found in the array. Check the frontend code');
      return;
    }

    this.dataVerantwoordelijkeService.postHolderVerantwoordelijken(this.stakeholderCode, holder)
      .pipe(finalize(() => this.isLoadingResults = false))
      .subscribe(() => showFeedbackSaved()
    );
  }

  // GET USER
  private getListVerantwoordelijken() {
    this.dataVerantwoordelijkeService.getUsers(this.stakeholderCode)
      .pipe(finalize(() => this.isLoadingResults = false))
      .subscribe((res: any) => {
        this.selectedUserIds = res.map(u => u.id);
      });
  }

  // POST HOLDER
  postUserVerantwoordelijken() {
    this.dataVerantwoordelijkeService.postUsers(this.stakeholderCode, this.users.filter(user => this.selectedUserIds.includes(user.id)))
      .pipe(finalize(() => this.isLoadingResults = false))
      .subscribe(() => showFeedbackSaved());
  }
}
