import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map, shareReplay } from 'rxjs/operators';
import { InAppChat, freshdeskIntegrationDataMap, freshdeskIntegrationSendDataResponceMap } from 'src/app/core/util/inappmessages';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { AbstractService } from "../common/abstract.service";

@Injectable({
  providedIn: 'root'
})
export class InAppChatService extends AbstractService {
  constructor(private http:HttpClient) {
    super();
  }
  //bouwapp in app notification
  @ServiceErrorHandler()
  getMessages(id,type): Observable<InAppChat>{
    return this.http.get<InAppChat>(`/api/integrations/bouwapp/messages?type=${type}&id=${id}`, this.httpOptions)
    .pipe(
      map(res=>res['messages']),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  getUserMessages(id,type,user_id): Observable<InAppChat> {
    return this.http.get<InAppChat>(`/api/integrations/bouwapp/messages?type=${type}&id=${id}&user_id=${user_id}`, this.httpOptions)
    .pipe(
      map(res=>res['messages']),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  attachmentUpload(file,type) {
    const formData:FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('project', this.getGlobals().projectCode);
    return this.http.post(`/api/integrations/bouwapp/upload?type=${type}`,formData);
  }

  @ServiceErrorHandler()
  attachmentUploadWhatsApp(formData) {
    return this.http.post('/api/integrations/whatsapp/messages', formData);
  }

  @ServiceErrorHandler()
  attachmentDownloadWhatsApp(body) {
    return this.http.get(`/api/integrations/whatsapp/download/document?document_id=${body.document_id}&complaint_id=${body.complaint_id}`, { responseType: 'blob' });
  }

  @ServiceErrorHandler()
  sendMessage(message,type): Observable<any> {
    return this.http.post(`/api/integrations/bouwapp/messages?type=${type}`,message, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }

  sendMessageByUser(message,type,id): Observable<any> {
    return this.http.post(`/api/integrations/bouwapp/messages?type=${type}&user_id=${id}`,message, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  dialogItemsUpload(id,type) {
    return this.http.post(`/api/integrations/bouwapp/upload/documents?id=${id}&type=${type}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  messagesStatusClosed(id,type,status) {
    return this.http.put(`/api/integrations/bouwapp/status?type=${type}&id=${id}`,status,this.httpOptions);
  }

  @ServiceErrorHandler()
  userAuthenticate(id,status) {
    return this.http.get(`/api/integrations/bouwapp/authenticate?user_id=${id}&project_code=${status}`,this.httpOptions);
  }
  //smart reporting documents
  @ServiceErrorHandler()
  getSmartReporting(type,id): Observable<any> {
    return this.http.get(`/api/integrations/bouwapp/item?type=${type}&id=${id}`,this.httpOptions)
    .pipe(
      map((res:any)=>{return {id:res.id,attachmentThumbnails:res.attachmentThumbnails,attachment:res.attachment,description:res.description,created_at:res.createdAt}}),
      shareReplay()
    );
  }
  //freshdesk in app notification
  @ServiceErrorHandler()
  getticket(id): Observable<any>{
    return this.http.get<any>(`/api/integrations/freshdesk/tickets/${id}`, this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(!res.success){
          return false
        }
        return res.data
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  getticketConversations(id,globals): Observable<InAppChat>{
    return this.http.get<InAppChat>(`/api/integrations/freshdesk/tickets/${id}/conversations`, this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          const data=freshdeskIntegrationDataMap(res.data,globals,true)
          return data
        }
      }),
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  getUserticketConversations(id,user_id,globals): Observable<InAppChat>{
    return this.http.get<InAppChat>(`/api/integrations/freshdesk/tickets/${id}/conversations?user_id=${user_id}`, this.httpOptions)
    .pipe(
      map((res:any)=>{
        if(res.success){
          const data=freshdeskIntegrationDataMap(res.data,globals,false)
          return data
        }
      }),
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  sendticketConversations(id,message,type,globals): Observable<any> {
    return this.http.post(`/api/integrations/freshdesk/tickets/${id}/${type}`,message)
    .pipe(
      map((res:any)=>{
        if(res.success){
          const data=freshdeskIntegrationSendDataResponceMap(res.data,globals)
          return data
        }
      }),
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  sendUserticketConversations(id,message,type,user_id,globals): Observable<any> {
    return this.http.post(`/api/integrations/freshdesk/tickets/${id}/${type}?user_id=${user_id}`,message)
    .pipe(
      map((res:any)=>{
        if(res.success){
          const data=freshdeskIntegrationSendDataResponceMap(res.data,globals)
          return data
        }
      }),
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  whatsappMessage(message): Observable<any> {
    return this.http.post(`/api/integrations/whatsapp/messages`,message, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }

  @ServiceErrorHandler()
  getWhatsAppMessages(body: any): Observable<any>{
    return this.http.get<any>(`/api/integrations/whatsapp/messages`, { params: body },)
    .pipe(
      map(res=>res['messages']),
      shareReplay()
    );
  }


}
