import { TRANSLOCO_LOADER, TranslocoLoader } from '@jsverse/transloco';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  // eslint-disable-next-line no-empty-function
  constructor() {}

  getTranslation(langPath: string) {
    return import(`../assets/i18n/${langPath}.json`).then(res => res.default);
  }
}
