import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  selector: '[validatorUrl][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: UrlValidatorDirective,
      multi: true
    }
  ]
})
export class UrlValidatorDirective implements Validator {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validatorUrl') url: string;

  validate(control: AbstractControl): {[key: string]: any} | null {
    if (control.dirty || control.touched) {
      if (control.value && (control.value.startsWith('https://') || control.value.startsWith('http://'))) {
        return null;
      }

      return { validatorUrl: true };
    }

    return null;
  }
}
