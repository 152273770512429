import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Entities } from "../../core/enum/entities";
import { IssueNewComponent } from "../../shared/components/modal/issue-new/issue-new.component";
import { AddStakeholdersActionComponent } from "../components/modal/add-stakeholders-task/add-stakeholders-action.component";
import { ComplaintNewComponent } from "../../shared/components/modal/complaint-new/complaint-new.component";
import { ActivityNewComponent } from "../../shared/components/modal/activity-new/activity-new.component";
import { DecisionNewComponent } from "../components/modal/requirement-new/decision-new.component";
import { EmployeeNewComponent } from "../components/modal/contact-persons-new/employee-new.component";
import { ModalEnum, modalConfig } from "../../core/util/modalConfig";
import { MiddelNewComponent } from "../../shared/components/modal/content-new/middel-new.component";
import { StakeholderNewComponent } from "../../shared/components/modal/stakeholder-new/stakeholder-new.component";
import { ResidentNewComponent } from "../../shared/components/modal/resident-new/resident-new.component";
import { PermitNewComponent } from '../components/modal/permit-new/permit-new.component';
import { NewWorkflowComponent } from '../components/modal/new-workflow/new-workflow.component';
import { AddWorkflowTaskComponent } from '../components/modal/add-workflow-task/add-workflow-task.component';

export interface NewEntityData {
  params?: any;
  options?: any;
}

@Directive({
  selector: '[newEntity]'
})
export class NewEntityDirective implements AfterViewInit {

  @Input() newEntity: Entities;
  @Input() newEntityParams: any;
  @Input() newEntityOptions: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter();

  // eslint-disable-next-line no-empty-function
  constructor(private el?: ElementRef, public dialog?: MatDialog) { }

  ngAfterViewInit(): void {

    let component = null;

    switch (this.newEntity) {
      case Entities.issues:
        component = IssueNewComponent;
        break;
      case Entities.complaints:
        component = ComplaintNewComponent;
        break;
      case Entities.tasks:
        component = AddStakeholdersActionComponent;
        break;
      case Entities.activities:
        component = ActivityNewComponent;
        break;
      case Entities.requirements:
        component = DecisionNewComponent;
        break;
      case Entities.permits:
        component = PermitNewComponent;
        break;
      case Entities.contactpersons:
        component = EmployeeNewComponent;
        break;
      case Entities.contents:
        component = MiddelNewComponent;
        break;
      case Entities.stakeholders:
        component = StakeholderNewComponent;
        break;
      case Entities.residents:
        component = ResidentNewComponent;
        break;
      case Entities.workflow:
        component = NewWorkflowComponent;
        break;
      case Entities.workflowtask:
        component = AddWorkflowTaskComponent;
        break;
    }

    if (!component) {
      console.error(`Type ${this.newEntity} unknown or unsupported`);
      return;
    }

    this.el.nativeElement.onclick = (event: MouseEvent) => {
      event.stopPropagation();
      const data: NewEntityData = {
        params: this.newEntityParams,
        options: this.newEntityOptions,
      };
      const dialog = this.dialog.open(component, modalConfig({
        data: data,
        panelClass: 'customized-dialog-container',
      }, ModalEnum.ModalDefault));

      dialog.afterClosed().subscribe((response: any) => {
        this.onClosed.emit(response);
      });
    };
  }

}
