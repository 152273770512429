<div class="modal-body">
  <span *ngIf="text.type === 'restore'; else simple">{{text.text}}</span>
  <ng-template #simple> {{ text }} </ng-template>
</div>
<div class="modal-footer">
  <div class="dialog-button-row">
    <button type="button" class="cancel-btn" mat-stroked-button (click)="submit(false)">{{'no' | transloco | capitaliz}}</button>
    <ng-container *ngIf="text.type === 'restore'; else deleteButton">
      <button type="button" mat-flat-button (click)="submit(true)">
        <mat-icon class="d-text-middle">restore_from_trash</mat-icon> {{'yes' | transloco | capitaliz}}
      </button>
    </ng-container>
    <ng-template #deleteButton>
      <button type="button" mat-flat-button class="mat-flat-button" (click)="submit(true)">
        <mat-icon class="d-text-middle uil uil-trash unicon-icon"></mat-icon> {{'yes' | transloco | capitaliz}}
      </button>
    </ng-template>
  </div>
</div>
