import {Component, Inject, ViewChild} from '@angular/core';
import {PubsubService} from "../../../../../core/service/api/pubsub.service";
import {Globals} from "../../../../../globals";
import {ActivatedRoute} from "@angular/router";
import {ResidentsService} from "../../../../../core/service/api/residents.service";
import {get} from 'scriptjs';
import {googleMapAddressParse} from "../../../../../core/util/googleMapAddressParse";
import {LogListingType} from "../../../../../core/enum/log-listing-type";
import {LogsComponent} from "../../../../../shared/components/listings/logs/logs.component";
import {TabResidentsDetailComponent} from "../residents-detail-tabs/tab-residents-detail.component";
import {AddressType} from "../../../../../core/enum/address-type";
import {showFeedbackSaved} from '../../../../../core/util/notification';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Resident } from 'src/app/akita/residents/state/resident.model';
import { DeletionModalComponent } from 'src/app/shared/components/modal/deletion-modal/deletion-modal.component';
import { translate } from '@jsverse/transloco';
import { forkJoin } from 'rxjs';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';
import { Log } from 'src/app/akita/contact-moments/state/log.model';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { CachedService } from 'src/app/core/service/common/cached.service';

@Component({
  selector: 'app-tab-resident-detail-general',
  templateUrl: './tab-resident-detail-general.component.html',
  styleUrls: ['./tab-resident-detail-general.component.css']
})
export class TabResidentDetailGeneralComponent extends TabResidentsDetailComponent {

  @ViewChild(LogsComponent, {static: false}) logsComponent: LogsComponent;

  tabName = '';

  protected googleMapOptions = {
    types: [],
  };
  protected logListingType = LogListingType.ResidentDetail;
  protected enableManualAddress = false;
  name: string;
  logs: Log[] = [];
  entities: any;
  loaded:Boolean = false;
  constructor(public pubsub: PubsubService, public globals: Globals,
    public route: ActivatedRoute,
    private residentsService: ResidentsService,
    private _logsService: LogsService,
    private cachedService: CachedService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TabResidentDetailGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data:Resident,
    public _residentsService: ResidentsService
  ) {
    super(pubsub, globals, route, _residentsService);
  }

  ngOnInit() {
    // if (!this.globals.googleMapLibLoaded) {
    //   get(`https://maps.googleapis.com/maps/api/js?key=${ this.globals.googleMapApiKey }&libraries=places&sensor=false&language=nl`, () => {
    //     this.globals.googleMapLibLoaded = true;
    //   });
    // }
    this.name = this.data.name
    this._residentsService.getResident(parseInt(this.data.id.toString()),true).subscribe(
      (resident: any) => {
        this.resident = resident;
        forkJoin([
          this._logsService.getLogs(new PaginationParams()
            .with(['stakeholders'])),
            this.cachedService.getComplaints()]
        ).subscribe(([logs,complaints]) => {
          this.entities ={logs:logs,complaints}
          this.loaded = true;
        });
      });

  }

  public updateResident() {
    this._residentsService.updateResident(this.resident).subscribe(
      resident => {
        showFeedbackSaved();
      });
  }

  protected updateAddress() {
    this.resident.latitude = null;
    this.resident.longitude = null;
    this.updateResident();
  }

  protected handleAddressChange(event): void {
    const googleAddress = googleMapAddressParse(event);
    this.resident.address = googleAddress.address;
    this.resident.postcode = googleAddress.postcode;
    this.resident.city = googleAddress.city;
    if (googleAddress.latitude && googleAddress.longitude) {
      this.resident.locations = {
        points: {
          type: 'MultiPoint',
          coordinates: [
            [parseFloat(googleAddress.longitude), parseFloat(googleAddress.latitude)],
          ]
        },
        locatable_type: 'residents',
      };
    }

    if (googleAddress.addressType === AddressType.Resident) {
      this.resident.type = 'Omwonende';
    } else {
      this.resident.type = 'Bedrijf';
    }

    this.updateResident();
  }

  public addLog(event: MouseEvent): void {
    this.logsComponent.editLog(null, event, {
      residents: [this.resident]
    });
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  updateSubprojects(){
    setTimeout(() => {
      this.pubsub.updateSubprojects(this,"updateResident",this.resident.subprojects)

    }, 500);
  }

  updateEntities(event:any) {
    this.resident[event.type] = event.selected;
    this.updateResident();
  }

  public deleteResident(resident) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: translate('stakeholders.delete_message')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe( (remove: boolean) => {
      if (remove) {
        this.residentsService.removeResident(resident)
          .subscribe(
            () => {
              this.close()
            }
          );
      }
    });
  }
}
