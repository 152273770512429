import {HttpHeaders} from "@angular/common/http";
import {Globals} from "../../../globals";
import {AppInjector} from "../../../../app-injector";

export class AbstractService {

  // Conditional to set it null for BootstrapService
  private globals:Globals = AppInjector ? AppInjector.get(Globals) : null;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
   });

  httpOptions = {
    headers: this.headers
  };

  public getGlobals(): Globals {
    if (this.globals) {
      return this.globals;
    }

    this.globals = AppInjector ? AppInjector.get(Globals) : null;
    return this.globals;
  }

  public toArrayParameterObject(key: string, array: any[]): any {
    const params = {};
    for (let i = 0; i < array.length; i++) {
      params[`${key}[${i}]`] = array[i];
    }

    return params;
  }
}
