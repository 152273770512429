<div class="gd-modal-report" *transloco="let t">
  <div>
    <button class="close modal-header" type="button" mat-dialog-close>
      <span id="gd-modal-report--close">×</span>
      <span class="sr-only">Close</span>
    </button>
    <h3 class="modal-title">{{ t('settings.report.title') }}</h3>
    <hr />
  </div>

  <form [formGroup]="reportForm" (submit)="updateConfig()">
    <mat-dialog-content>
      <div>
        <mat-form-field class="full-width">
          <input matInput formControlName="color_display" style="color: #fff; padding: 8px 0 8px 8px"
            [style.background]="color" [colorPicker]="color" (colorPickerChange)="color=$event"
            cpAlphaChannel="disabled" cpOutputFormat="hex" placeholder="Kleur" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 230px">
          <input type="text" matInput id="gd-logo-path" formControlName="logo_path" />
        </mat-form-field>
        <button type="button" class="btn d-btn-dialog mdl-button mdl-js-button mdl-button--raised pull-right"
          (click)="logoInput.click()">
          <span>{{ t('settings.report.upload_logo') }}</span>
          <input #logoInput type="file" ng2FileSelect [uploader]="logoUploader" name="image"
            (onFileSelected)="fileAdded($event)" style="display: none;" accept="image/*">
        </button>
      </div>
      <div>
        <img *ngIf="logoPreview" [src]="logoPreview" />
      </div>

    </mat-dialog-content>
    <mat-dialog-actions class="pull-right">
      <button class="btn mdl-button mdl-js-button mdl-js-button mdl-button--raised"
        mat-dialog-close>{{ t('close') | capitaliz }}</button>
      <button type="submit"
        class="btn d-btn-dialog mdl-button mdl-js-button mdl-button--raised">{{ t('save') | capitaliz }}</button>
    </mat-dialog-actions>
  </form>
</div>
