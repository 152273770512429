
export type Items = {
  stakeholder: false,
  issue: false,
  complaints: false,
  requirements: false,
  activity: false,
}
const itemsIcons = {
  stakeholder: '/assets/png/stakeholders.png',
  stakeholder_strategic: '/assets/png/stakeholders.png',
  issue: '/assets/png/issues.png',
  complaints: '/assets/png/complaints.png',
  requirements: '/assets/png/requirements.png',
  activity: '/assets/png/calendars.png',
}
const itemsStatus = {
  stakeholder: [
    { name: 'Samenwerken', filter: 'Samenwerken', icon: '/assets/png/stakeholders-cooperate.png' },
    { name: 'Tevreden houden', filter: 'Tevreden houden', icon: '/assets/png/stakeholders-keepsatisfied.png' },
    { name: 'Informeren', filter: 'Informeren', icon: '/assets/png/stakeholders-inform.png' },
    { name: 'Monitoren', filter: 'Monitoren', icon: '/assets/png/stakeholders-monitor.png' },
    { name: 'Ongecategoriseerd', filter: 'Ongecategoriseerd', icon: '/assets/png/stakeholders.png' },
  ],
  stakeholder_strategic: [
    { name: 'Reactief', filter: 'Reactief', icon: '/assets/png/stakeholders-reactive.png' },
    { name: 'Informeren', filter: 'Informeren', icon: '/assets/png/stakeholders-inform-strategic.png' },
    { name: 'Adviseren', filter: 'Adviseren', icon: '/assets/png/stakeholders-advice.png' },
    { name: 'Raadplegen', filter: 'Raadplegen', icon: '/assets/png/stakeholders-consult.png' },
    { name: 'Coproduceren', filter: 'Coproduceren', icon: '/assets/png/stakeholders-coproduce.png' },
    { name: 'Meebeslissen', filter: 'Meebeslissen', icon: '/assets/png/stakeholders-codecide.png' },
    { name: 'Ongecategoriseerd', filter: 'Ongecategoriseerd', icon: '/assets/png/stakeholders-none.png' },
  ],
  issue: [
    { name: 'Zeer hoog', filter: 'very-high', icon: '/assets/png/issues-very-high.png' },
    { name: 'Hoog', filter: 'high', icon: '/assets/png/issues-high.png' },
    { name: 'Midden', filter: 'middle', icon: '/assets/png/issues-medium.png' },
    { name: 'Laag', filter: 'low', icon: '/assets/png/issues-low.png' },
  ],
  requirements: [
    { name: 'Initieel', filter: 'initial', icon: '/assets/png/requirements-open.png' },
    { name: 'Gehonoreerd', filter: 'gehonoreerd', icon: '/assets/png/requirements-honoured.png' },
    { name: 'Afgewezen', filter: 'afgewezen', icon: '/assets/png/requirements-rejected.png' },
    { name: 'Vervallen', filter: 'expired', icon: '/assets/png/requirements-expired.png' },
    { name: 'Volgende fase', filter: 'next_phase', icon: '/assets/png/requirements-next_phase.png' },
  ],
  complaints: [
    { name: 'Open', filter: 0, icon: '/assets/png/complaints-open.png' },
    { name: 'Afgehandeld', filter: 1, icon: '/assets/png/complaints-closed.png' },

  ],
  activity: []
}
export function getItemStatusMapToggles(type, stakeholder_strategic=false) {
  // stakeholder_strategic = this.globals.projectConfigs['project.stakeholder_strategy.is_new'];
  const item = [
    {
      name: type,
      is_active: false,
      icon: itemsIcons[(type === 'stakeholder' && stakeholder_strategic === true )? 'stakeholder_strategic' : type],
      status: [],
      sublayerExist: type == 'activity' ? false : true
    }];
  item.map(el => {
    if (itemsStatus[type].length) {
      itemsStatus[(type === 'stakeholder' && stakeholder_strategic === true) ? 'stakeholder_strategic' : type].forEach(status => {
        el.status.push(
          {
            name: status.name,
            is_active: false,
            filter: status.filter,
            icon: status.icon
          }
        );
      });
    }
  })
  return item[0];
}
export const itemsFilterStatus = {
  issues: [
    { name: 'Open', filter: 'open', icon: '/assets/png/complaints-open.png' },
    { name: 'Gesloten', filter: 'gesloten', icon: '/assets/png/complaints-closed.png' },
  ],
  requirements: [
    { name: 'Initieel', filter: 'initial', icon: '/assets/png/requirements-open.png' },
    { name: 'Gehonoreerd', filter: 'gehonoreerd', icon: '/assets/png/requirements-honoured.png' },
    { name: 'Afgewezen', filter: 'afgewezen', icon: '/assets/png/requirements-rejected.png' },
    { name: 'Vervallen', filter: 'expired', icon: '/assets/png/requirements-expired.png' },
    { name: 'Volgende fase', filter: 'next_phase', icon: '/assets/png/requirements-next_phase.png' },
  ],
  complaints: [
    { name: 'Open', filter: 0, icon: '/assets/png/complaints-open.png' },
    { name: 'Afgehandeld', filter: 1, icon: '/assets/png/complaints-closed.png' },
  ],
  tasks: [
    { name: 'Recover', filter: true, icon: '/assets/png/complaints-open.png' },
    { name: 'Resolve', filter: false, icon: '/assets/png/complaints-closed.png' },
  ],
}
export function getFilterType(item): string{
  switch(item){
    case 'complaints':
      return 'is_closed'
    case 'requirements':
      return 'status'
    case 'tasks':
      return 'handled'
    case 'issues':
      return 'issues_status'
}
}
