import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { Globals } from 'src/app/globals';
import { Stakeholder } from 'src/app/akita/stakeholders/state/stakeholder.model';
import { showFeedbackSaved, showFeedbackError } from 'src/app/core/util/notification';
interface FolderInBox {
  name: string;
  path: string;
  children?: FolderInBox[];
}

interface FolderOutBox {
  name: string;
  path: string;
  children?: FolderOutBox[];
}
@Component({
  selector: 'app-imap-integration-modal',
  templateUrl: './imap-integration-modal.component.html',
  styleUrls: ['./imap-integration-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImapIntegrationModalComponent extends IntegrationsModalComponent implements OnInit {
  selectedInBoxFolder: string | null = null;
  selectedOutBoxFolder: string | null = null;
  public smartReportingAs: any;
  public smartReportingEnabled: boolean = false;
  public foldersSuccess: boolean = false;
  hidePassword: boolean = true;
  public showSpinner: boolean = false;
  public forbiden: boolean = true;
  programModule: boolean = false;
  modulesAvailable: boolean = false;
  myForm: FormGroup;
  private serviceGroup: string = 'imap';
  selectedSubprojects: any
  public selectInbox: any;
  public selectOutbox: any;
  public password: any
  public bouwappsyncSelection: any = [];
  public previousBouwappsyncSelection: any = [];
  dataSource: any[];
  typeInput: string = 'password'
  dataSourceOutbox: any[];
  folders: FolderInBox[] = [];
  FolderOutBox: FolderOutBox[] = [];
  @Input() stakeholder: Stakeholder;
  @Input() relatic = { name: '' };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public _projectConfigsService: ProjectConfigsService,
    public globals: Globals,
    public pubsub: PubsubService,
    public dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    super(globals, _projectConfigsService);
    if (this.globals.projectConfigs['services.integration.imap.enabled']) {
      this.getAllModals()
    }

  }

  ngOnInit(): void {

    if (this.globals.projectConfigs['services.integration.imap.enabled']) {
      this.myForm = this.fb.group({
        email_server: [this.globals.projectConfigs['services.integration.imap.host'] ? this.globals.projectConfigs['services.integration.imap.host'] : '', Validators.required],
        port: [this.globals.projectConfigs['services.integration.imap.port'] ? this.globals.projectConfigs['services.integration.imap.port'] : 993, [Validators.required, this.validateNumber]],
        username: [this.globals.projectConfigs['services.integration.imap.username'] ? this.globals.projectConfigs['services.integration.imap.username'] : '', [Validators.required, Validators.email]],
        password: [this.globals.projectConfigs['services.integration.imap.password'] ? '' : '', Validators.required]
      });
    } else {
      const reg = '^((?!https:\/\/).)*$';
      this.myForm = this.fb.group({
        email_server: ['', [Validators.required, Validators.pattern(reg)]],
        port: [993, [Validators.required, this.validateNumber]],
        username: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });
    }

    this.selectedInBoxFolder = this.globals.projectConfigs['services.integration.imap.inbox_folder_name']
    this.selectedOutBoxFolder = this.globals.projectConfigs['services.integration.imap.sent_folder_name'];
    if (this.globals.projectConfigs['services.integration.imap.subprojects']) {
      this.selectedSubprojects = this.globals.projectConfigs['services.integration.imap.subprojects'].split(',').map(item => item.trim());
    }

    if(this.globals.modules.length>0){
      this.globals.modules.forEach(element => {
        if (element.module_id == 'communication') {
          this.modulesAvailable = true;
          this.cd.detectChanges();
        }
        if(element.module_id=='subprojects'){
          this.programModule=true;
          this.cd.detectChanges();
        }
      });
    }

    if (!this.globals.projectConfigs['services.integration.imap.contact_moments.enabled'] && this.modulesAvailable) {
      this.smartReportingAs = 'complaints'
    } else {
      this.smartReportingAs = 'contact_moments'
    }
  }


  validateNumber(control: any) {
    const numericValue = parseInt(control.value, 10);
    if (!isNaN(numericValue)) {
      return null; // It's a number, validation succeeded
    }
    return { 'notNumber': true }; // Not a number, return validation error
  }

  encryptPassword() {
    const data = {
      string: this.myForm.value.password
    }
    this._projectConfigsService.setEncryptPassword(data).subscribe(
      (res: any) => {
        this.password = res.data
        this.verifyDataConfigs('verify')
      })
  }

  public verifyDataConfigs(action: any) {
    this.showSpinner = true
    if (this.myForm.valid || this.globals.projectConfigs['services.integration.imap.enabled']) {
      if (action === 'verify') {
        this.globals.projectConfigs['services.integration.imap.connection_error'] = false;
        this.globals.projectConfigs['services.integration.imap.enabled'] = true;
        this.globals.projectConfigs['services.integration.imap.encryption'] = 'tls';
        this.globals.projectConfigs['services.integration.imap.host'] = this.myForm.value.email_server;
        this.globals.projectConfigs['services.integration.imap.password'] = this.password;
        this.globals.projectConfigs['services.integration.imap.port'] = this.myForm.value.port;
        this.globals.projectConfigs['services.integration.imap.username'] = this.myForm.value.username;
        this.globals.projectConfigs['services.integration.imap.validate_cert'] = true;
        this.globals.projectConfigs['services.integration.imap.user_id'] = this.globals?.user?.id;
        this.globals.projectConfigs['services.integration.imap.inbox_folder_name'] = '';
        this.globals.projectConfigs['services.integration.imap.sent_folder_name'] = '';
        this.globals.projectConfigs['services.integration.imap.subprojects'] = '';

      } else {
        this.globals.projectConfigs['services.integration.imap.connection_error'] = false;
        this.globals.projectConfigs['services.integration.imap.enabled'] = false;
        this.globals.projectConfigs['services.integration.imap.encryption'] = '';
        this.globals.projectConfigs['services.integration.imap.host'] = '';
        this.globals.projectConfigs['services.integration.imap.password'] = '';
        this.globals.projectConfigs['services.integration.imap.port'] = 993;
        this.globals.projectConfigs['services.integration.imap.username'] = '';
        this.globals.projectConfigs['services.integration.imap.validate_cert'] = true;
        this.globals.projectConfigs['services.integration.imap.inbox_folder_name'] = '';
        this.globals.projectConfigs['services.integration.imap.sent_folder_name'] = '';
        this.globals.projectConfigs['services.integration.imap.subprojects'] = '';
        this.globals.projectConfigs['services.integration.imap.user_id'] = this.globals?.user?.id;
        this.myForm.reset()
      }
      this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
        () => {
          if (action === 'verify') {
            this.getAllModals()
            this.cd.detectChanges();
          } else {
            this.foldersSuccess = false
            this.showSpinner = false
            this.globals.projectConfigs['services.integration.imap.enabled'] = false;
            this.configsSetup()
            this.cd.detectChanges();
          }

        });
    }
  }



  getAllModals() {
    this._projectConfigsService.getAllFolders().subscribe((res: any) => {
      if (res.code === 503) {
        showFeedbackError('d')
        this.foldersSuccess = false
        this.showSpinner = false
        this.globals.projectConfigs['services.integration.imap.enabled'] = false;
        this.configsSetup()
      } else {
        this.foldersSuccess = true
        this.showSpinner = false
        this.folders = this.mapFolders(res.folders);
        this.FolderOutBox = this.mapFolderOutBox(res.folders)
      }


      this.cd.detectChanges();
    })
  }

  onFolderSelectionChangeInbox(event): void {
    this.selectedInBoxFolder = event
    this.updateFoldersConfig(this.selectedInBoxFolder, this.selectedOutBoxFolder)
  }

  onFolderSelectionChangeOutbox(event): void {
    this.selectedOutBoxFolder = event
    this.updateFoldersConfig(this.selectedInBoxFolder, this.selectedOutBoxFolder)
  }

  updateFoldersConfig(inBox: any, outBox: any) {
    this.globals.projectConfigs['services.integration.imap.inbox_folder_name'] = inBox;
    this.globals.projectConfigs['services.integration.imap.sent_folder_name'] = outBox;
    if (inBox !== outBox) {
      this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
        () => {
          showFeedbackSaved()
          this.cd.detectChanges();
          // this._projectConfigsService.getVerifyFolders().subscribe((res: any) => {
          //   if (res.code === 422) {
          //     showFeedbackError('d')
          //   } else {
          //     showFeedbackSaved()
          //   }
          // })
        });
    } else {
      showFeedbackError('The inbox and outbox folders should not be the same.')
    }

  }

  togglePasswordVisibility(): void {
    this.typeInput = this.hidePassword ? 'text' : 'password';
    this.hidePassword = !this.hidePassword;
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.dialogRef.close(true);
    this.pubsub.resetHistory();
  }
  updateSyncBouwappProject(selection) {
    if (selection === 'complaints') {
      this.globals.projectConfigs['services.integration.imap.contact_moments.enabled'] = false;
      this.smartReportingAs = 'complaints'
    } else {
      this.globals.projectConfigs['services.integration.imap.contact_moments.enabled'] = true;
      this.smartReportingAs = 'contact_moments'
    }

    this.configsSetup();
  }

  public configsSetup() {
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        // showFeedbackSaved();
      });
  }


  updateSubprojects(event) {
    this.globals.projectConfigs['services.integration.imap.subprojects'] = event.join(', ');
    this.configsSetup();
  }

  mapFolders(foldersObj: any): FolderInBox[] {
    return Object.keys(foldersObj).map(key => {
      const folder = foldersObj[key];
      return {
        name: folder.name,
        path: folder.path,
        children: folder.children ? this.mapFolders(folder.children) : []
      };
    });
  }

  mapFolderOutBox(foldersObj: any): FolderOutBox[] {
    return Object.keys(foldersObj).map(key => {
      const folder = foldersObj[key];
      return {
        name: folder.name,
        path: folder.path,
        children: folder.children ? this.mapFolders(folder.children) : []
      };
    });
  }



}
