import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RelaticsService} from "../../../core/service/api/relatics.service";
import {Globals} from "../../../globals";

@Component({
  selector: 'app-connected-relatics',
  templateUrl: './connected-relatics.component.html',
  styleUrls: ['./connected-relatics.component.css']
})
export class ConnectedRelaticsComponent implements OnInit, OnChanges {

  @Input() relatics = [];
  @Input() editable = true;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRemoveRelatics: EventEmitter<any> = new EventEmitter();
  relatics_grouped = {};

  /** Relatics **/
  relaticsTables = [];
  relaticsColumns = {};

  // eslint-disable-next-line no-empty-function
  constructor(private _relaticsService: RelaticsService, public globals: Globals) { }

  ngOnInit() {
    if (this.relatics.length > 0) {
      this.loadRelaticsBlueprints();
    }
  }

  private loadRelaticsBlueprints() {
    this._relaticsService.getRelaticsBlueprints().subscribe(
      (blueprints : any) => {
        this.globals.relaticsBlueprints = blueprints;
        this.relaticsTables = Object.entries(this.globals.relaticsBlueprints).map(([key]) => key);
        this.relaticsTables = this.relaticsTables.filter(t => t !== 'dialog item');

        this.relatics_grouped = {};
        this.relaticsTables.forEach(t => {
          let columnsCount = 0;
          const columnsAttributes = [];
          const columnsProperties = [];
          const columnsRelations = [];

          const attributes = this.globals.relaticsBlueprints[t].data.attributes;
          const properties = this.globals.relaticsBlueprints[t].data.properties;
          const relations = this.globals.relaticsBlueprints[t].relations;

          for (const key in attributes) {
            if (columnsCount++ < 3) {
              columnsAttributes.push(key);
            }
          }

          for (const key in properties) {
            if (columnsCount++ < 3) {
              columnsProperties.push(key);
            }
          }

          for (const key in relations) {
            if (columnsCount++ < 3) {
              columnsRelations.push(key);
            }
          }

          this.relaticsColumns[t] = {
            attributes: columnsAttributes,
            properties: columnsProperties,
            relations: columnsRelations,
          };

          this.relatics_grouped[t] = this.relatics.filter(r => r.type === t);
        });
      },
    );
  }

  public removeRelatics(instance) {
    this.onRemoveRelatics.emit(instance);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const prop in changes) {
      if (prop === 'relatics') {
        this.loadRelaticsBlueprints();
      }
    }
  }

}
