<form #form="ngForm" (ngSubmit)="submit()">
  <div mat-dialog-title>
    <h4 class="mb-10 inline-block">
      {{ "workflow.label" | transloco }}
    </h4>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
      </span>
      <span class="sr-only"> Close </span>
    </button>
  </div>

  <nav
    mat-tab-nav-bar
    *transloco="let t; read: 'actions'"
    class="overview-tabs"
  >
      <a mat-tab-link [active]="isTabActive('new')" (click)="tabChanged('new')">
        {{ "new_e" | transloco | capitaliz }}
      </a>
      <a
        mat-tab-link
        [active]="isTabActive('from_existing')"
        (click)="tabChanged('from_existing')"
      >
        {{ "workflow.existing" | transloco | capitaliz }}
      </a>
  </nav>

  <div class="newWorkflowForm">
    <div *ngIf="activeTab === 'new'">
        <!-- modal body -->
        <mat-dialog-content>
          <div class="mt-10">
          <div class="col-xs-12 col-sm-12 pl-0">
            <mat-form-field class="full-width">
              <mat-label>{{'workflow.workflow_name' | transloco}}</mat-label>
              <input
                matInput
                autofocus
                name="name"
                [(ngModel)]="entity.name"
                required
              />
            </mat-form-field>
          </div>

          <!-- <app-generic-multiselect-checkbox [multiple]="true" #stakeholderMultiselect [placeholder]="'workflow.select_tasks' | transloco" name="stakeholders" [(ngModel)]="entity.tasks" [name]="data" (ngModelChange)="onSelectDialogItem($event)" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'">
            <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let item of tasks" [value]="item">
                {{ item.name? item.name : item.title? item.title : item.text? item.text : '' }}
            </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox> -->
        <app-all-search-select [required]="false" class="full-width" [models]="['issues', 'complaints', 'requirements', 'tasks','activities', 'contents', 'permits']" [(ngModel)]="entity" name="act" ngDefaultControl (ngModelChange)="onChangeRequired()"></app-all-search-select>
          </div>
        </mat-dialog-content>
        <!--end of modal body -->
    </div>
    <div *ngIf="activeTab === 'from_existing'">
       <!-- modal body -->
       <mat-dialog-content>
        <div class="mt-10">
          <div class="col-xs-12 col-sm-12 pl-0"></div>
            <mat-form-field class="full-width">
              <mat-label>{{'workflow.workflow_name' | transloco}}</mat-label>
              <input
                matInput
                autofocus
                name="name"
                [(ngModel)]="entity.name"
                required
              />
            </mat-form-field>
          <app-generic-multiselect-checkbox
            [multiple]="false"
            [placeholder]="'workflow.existing_workflow' | transloco"
            ngDefaultControl
            name="name"
            [required]="true"
            [(ngModel)]="existingWorkflow"
            [maxChipMessage]="
              '+ {count} ' + ('reusable.more' | transloco) + ' workflows ...'
            "
          >
            <app-generic-multiselect-checkbox-option-all>{{
              "filter_select_all" | transloco
            }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option
              *ngFor="let workflow of workflows; trackBy: trackByFn"
              [value]="workflow"
            >
              {{ workflow.name }}
            </app-generic-multiselect-checkbox-option>
          </app-generic-multiselect-checkbox>
          <app-all-search-select [required]="false" class="full-width" [models]="['issues', 'complaints', 'requirements', 'tasks', 'activities', 'contents', 'permits']" [(ngModel)]="entity" name="act" ngDefaultControl (ngModelChange)="onChangeRequired()"></app-all-search-select>
        </div>
      </mat-dialog-content>
      <!--end of modal body -->
    </div>
  </div>

  <mat-dialog-actions align="end">
    <div class="dialog-button-row">
      <button
        type="button"
        mat-stroked-button
        data-dismiss="modal"
        [disabled]="isProcessing"
        (click)="close()"
      >
        {{ "cancel" | transloco | capitaliz }}
      </button>
      <button type="submit" mat-flat-button [disabled]="isProcessing || form.invalid">
        <span *ngIf="!isProcessing">
          <mat-icon class="material-icons d-text-middle">save</mat-icon>
          {{
            (relationActionContactPerson ? "save" : "add")
              | transloco
              | capitaliz
          }}
        </span>
        <span *ngIf="isProcessing">{{
          "text.please_wait" | transloco | capitaliz
        }}</span>
      </button>
    </div>
  </mat-dialog-actions>
  <!--end of modal footer -->
</form>
