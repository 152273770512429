import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { concatMap, delay } from "rxjs/operators";
import { setDefaultLanguage } from "src/app/akita/multilingual/multilingual.model";
import { AuthService } from "../service/auth/auth.service";
import { Globals } from "src/app/globals";
import { SubprojectQuery } from "src/app/akita/subproject/state/subprojects.query";
import { SubprojectsService } from "src/app/akita/subproject/state/subprojects.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptorService {
  public token;
  mfaLogin: any
  constructor(
    private auth: AuthService,
    private globals: Globals,
    private subProjectQuery: SubprojectQuery,
    private subProjectsService: SubprojectsService,
    public _rout: Router
  // eslint-disable-next-line no-empty-function
  ) {

   }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const self = this;
    return this.auth.localAuthSetup().pipe(
      delay(10),
      concatMap(() => {
        if (this.auth.isLoggedIn()) {
          const token: any = localStorage.getItem('token')
          const header = { Authorization: `Bearer ${token}`, };
          if (!request.url.includes("?with=personalization,modules,users,configs") && !request.url.includes("/api/my/userconfigs/projects")
          ) {
            if (self._rout.url.includes("beheer/")) {
              const projectName = this._rout.url.split("/")[2];
              header["X-Project"] = projectName;
            } else {
              if (this.globals.projectCode && !this.globals["adminPage"]) {
                header["X-Project"] = this.globals.projectCode;
              }
            }
          }
          if (this.globals.user) {
            header["X-Lang"] = setDefaultLanguage(this.globals, 'user');
          }
          if (this.globals && this.globals.modules && this.globals.modules.some((module) => module.module_id === "subprojects")) {
            const activeSubProject = this.subProjectQuery.getActive();
            if (activeSubProject) {
              header["X-Subproject"] = activeSubProject.id.toString();
            } else {
              this.subProjectsService.setFromUrl(document.location.pathname);
            }
          }
          if (request.url.includes('user-login-update')) {
            if (header['X-Project']) {
              delete header['X-Project']
            }
          }

          if (request.url.includes("sharing/rest/oauth2/token/")
            || request.url.includes("request=getcapabilities")
            || request.url.includes("request=GetCapabilities")
            || request.url.includes("/arcgis/rest/services/")
            || request.url.includes("/ArcGIS/rest/services/")
            || request.url.includes("REQUEST=GetFeatureInfo")
            || request.url.includes("www.arcgis.com/sharing")
            || request.url.includes("sharing/oauth2/token/")
            || request.url.includes("graph.microsoft.com/v1.0")
            || request.url.includes("nominatim.openstreetmap.org")
            || request.url.includes("epsg.io/srs")
            || request.url.includes(`${this.globals.bouwapp_url }/auth/token`)
            || request.url.includes(environment.auth0.domain)
            || request.url.includes("overpass-api.de/api/interpreter")) {
            request = request.clone({
              url: request.url,
            });
          } else {
            request = request.clone({
              setHeaders: header,
              // url: `https://app.getdialog.dev${request.url}`,
            });
          }
        }
        return next.handle(request);
      })
    );
  }
}
//latest
