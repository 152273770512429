import {NgxSelectModule} from 'ngx-select-ex';
import {TranslocoModule} from '@jsverse/transloco';
import {NgMathPipesModule, NgStringPipesModule} from 'angular-pipes';
import {ColorPickerModule} from 'ngx-color-picker';
import {FileUploadModule} from 'ng2-file-upload';
import {NgxPopperjsModule} from 'ngx-popperjs';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {EditorModule} from '@tinymce/tinymce-angular';
import {OpenlayersRightPanelModule} from '../features/main-app/map/openlayers-rightpanel/openlayers-rightpanel.module';
import { AvatarModule } from 'ngx-avatars';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {HighchartsChartModule} from 'highcharts-angular';
import { ObjectivTrackerModule } from '@objectiv/tracker-angular';
import { MentionModule } from 'angular-mentions';
import { MentionsModule } from '@flxng/mentions';
import { OpenlayersSidebarV2Module } from '../features/main-app/map/openlayers-sidebar-v2/openlayers-sidebar-v2.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import {MatTreeModule} from '@angular/material/tree';

export const SHARED_3RD_PARTY_ANGULAR = [
  NgxSelectModule,
  TranslocoModule,
  //NgMathPipesModule,
  //NgStringPipesModule,
  ColorPickerModule,
  FileUploadModule,
  NgxPopperjsModule,
  // InternationalPhoneNumberModule,
  ScrollingModule,
  InfiniteScrollModule,
  EditorModule,
  OpenlayersSidebarV2Module,
  OpenlayersRightPanelModule,
  AvatarModule,
  DropzoneModule,
  GoogleMapsModule,
  HighchartsChartModule,
  //ObjectivTrackerModule,
  MentionModule,
  //MentionsModule,
  NgxMatIntlTelInputComponent,
  MatTreeModule
];
