<section>
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
        <h1>{{'merge_items' | transloco}}</h1>
        <button type="button" class="close" (click)="onClose()">
          <span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon>
          </span>
          <span class="sr-only">Close</span>
        </button>
    </div>
    <div class="col-xs-12">
      <p>{{'merge_info' | transloco}}</p>
    </div>
  </div>
  <mat-dialog-content class="popup-body" appScroll>
    <mat-list>
      <mat-radio-group [(ngModel)]="primaryItem">
        <ng-container *ngFor="let item of data.items; let i = index">
          <mat-list-item>
            <div class="d-flex">
              <span style="width: 60px;">{{ item.id }}</span>
              <div class="w-100 d-flex j-spacce-between">
                <span>{{item.title ? item.title : item.name ? item.name : item.text | truncatie: 30}}</span>
                <span class="d-grid">
                  <mat-radio-button class="radio-button-custome" [value]="item"></mat-radio-button>
                  <mat-hint class="f-12" *ngIf="primaryItem ? primaryItem.id === item.id : false">Primary</mat-hint>
                </span>
              </div>
            </div>
          </mat-list-item>
          <mat-divider *ngIf="i < data.items.length - 1"></mat-divider>
        </ng-container>
      </mat-radio-group>
    </mat-list>
    <div class="row">
      <div class="col-xs-12">
        <mat-checkbox class="example-margin" [(ngModel)]="approval">{{'merge_required' | transloco}}</mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="popup-footer pull-right">
    <button class="mr-5" type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">{{ 'close' | transloco | capitaliz }}</button>
    <button class="mr-5" type="button" mat-flat-button (click)="submit()" [disabled]="!approval || !primaryItem">{{ 'save' | transloco | capitaliz }}</button>
  </mat-dialog-actions>
</section>
