import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChoiceWithIndices } from '@flxng/mentions';
import collect from 'collect.js';
import posthog from 'posthog-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { setDefaultLanguage } from 'src/app/akita/multilingual/multilingual.model';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { SearchResponse } from 'src/app/akita/search/state/search.model';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { htmlRemoval, itemsAlgo, removeAndpaces } from 'src/app/core/util/commentBox';
import { openModal } from 'src/app/core/util/dashboardModalLogic';
import { showFeedbackRemoved, showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { DeletionModalComponent } from 'src/app/shared/components/modal/deletion-modal/deletion-modal.component';
import { environment } from 'src/environments/environment';

declare let $: any;
@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CommentBoxComponent extends GenericComponent implements OnInit {
  public loading = true;
  public message_loading = true;
  public choices: any[] = [];
  public mentions: ChoiceWithIndices[] = [];
  public mentionConfig:any;
  public message:string;
  private preLoaderSbj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public preLoader$: Observable<boolean> = this.preLoaderSbj.asObservable();
  private listSearchSbj: BehaviorSubject<SearchResponse> = new BehaviorSubject(null);
  public listSearch$: Observable<SearchResponse> = this.listSearchSbj.asObservable();
  public collaborator:any[]=[];
  public collaboratorList:any[]=[];
  public items:any[]=[];
  public itemsList:any[]=[];
  public scrollCount:number=1;
  public messagesList:any[]=[];
  public updateActive:boolean=false;
  public updateCommentID:number=0;
  public apiKey: string = environment.tinymce.apiKey;
  public htmlCode: string='';
  public htmlDoc: HTMLElement;
  public processing:boolean=false;
  public itemsOnceLoader:boolean=false;
  public collaboratorUsers:any[]=[];
  public lang: any;
  @Input() users:any;
  @Input() type:string='';
  @Input() parentType: string = '';
  @Input() type_id:number=0;
  @ViewChild('infinitescroll') private infinitescroll: ElementRef;
  @ViewChild('textareaRef') private textareaRef: ElementRef;
  @ViewChild('textareaRefEdit') private textareaRefEdit: ElementRef;
  constructor(public dialog: MatDialog,private _projectService: ProjectsService,private cd: ChangeDetectorRef,public globals: Globals, private pubsub: PubsubService,private _pusherService: PusherService) {
    super();
    this.lang = setDefaultLanguage(this.globals, 'user')
   }

  ngOnInit(): void {
    this.loadMessages(1);
    this.loadCollaborator();
    this.loadMentions();
    this.collaboratorUsers=this.users;
    this.setCollaborationInstance();
  }

  public loadMentions(firstLoad=[]): void {
    this.mentionConfig = {
      mentions: [
          {
              items: this.users,
              triggerChar: '@',
              labelKey:'name',
              mentionSelect: this.onMentionSelectUser,
              allowSpace: true
          },
          {
            items: firstLoad,
            triggerChar: '#',
            labelKey:'title_name',
            mentionSelect: this.onMentionSelectItems,
            allowSpace: true
        },
      ]
    }
  }

  public onMentionSelectUser(input){
    return `##${ input.name }##`;
  }

  public onMentionSelectItems(input){
    return `##${ input.name }##`;
  }

  public onSend(): void {
    this.processing=true;
    if(this.updateActive){
      const message={message:this.textareaRefEdit.nativeElement.innerHTML,collaborator:this.collaborator,item:this.items}
      this._projectService.updateMessage(this.updateCommentID,{message:message,comment_type:'comment'}).subscribe(res=>{
        this.loadMessages(1,true);
        this.onReset();
        this.processing=false;
      },(error=>{
        this.processing=false;
      }))
      this.scrollCount=1;
    }else{
      const message={message:this.textareaRef.nativeElement.innerHTML,collaborator:this.collaborator,item:this.items}
      this._projectService.sendMessages(this.type,this.type_id,1,{message:message,comment_type:'comment'}).subscribe((res)=>{
        this.loadMessages(1,true);
        this.onReset();
        this.processing=false;
      },(error=>{
        this.processing=false;
      }));
      this.scrollCount=1;
      posthog.capture('comment-add', {
        eventType: this.type,
        eventID: this.type_id
      });
    }
    if(this.collaborator.length>0){
      this.collaboratorValidation();
    }
    this.cd.detectChanges();
  }

  public loadMessages(page,onSave=false): void{
    this._projectService.getAllMessages(this.type,this.type_id,page).subscribe((res:any)=>{
      if(res.data.length > 0){
        if(onSave){
          this.messagesList=res.data;
        }else{
          const messages=res.data;
          messages.forEach(element => {
            element.is_edit=false;
            this.messagesList.push(element);
          });
          this.cd.detectChanges();
        }
        this.message_loading=false;
        this.cd.detectChanges();
        this.nav();
      }else{
        this.message_loading=false;
        this.cd.detectChanges();
      }
    })
  }

  public loadCollaborator(): void{
    this._projectService.getAllCollaborators(this.type,this.type_id).subscribe(((res:any)=>{
      this.collaboratorList=res.collaborators;
      this.loadCollaboratorUsers();
      this.cd.detectChanges();
    }))
  }

  public onScrollDown(event: any){
    const element = event.target;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom) {
      this.scrollCount=this.scrollCount + 1;
      this.loadMessages(this.scrollCount);
    }
  }

  public addCollaborators(){
    this._projectService.saveCollaborators(this.type,this.type_id,{collaborators:this.collaboratorList}).subscribe((res=>{
      this.loadCollaborator();
      this.loadMessages(1);
      showFeedbackSaved();
    }))
    posthog.capture('comment-add_collaborator', {
      eventType: this.type,
      eventID: this.type_id
    });
  }

  public collaboratorDropdown(collaborator){
    let contains;
    if(this.collaboratorList.length>0){
      contains=collect(this.collaboratorList).contains((value, key) => value.id == collaborator.id);
      if(!contains){
        this.collaboratorList.push({id: collaborator.id, email: collaborator.email,name:collaborator.name});
      }
    }else{
      this.collaboratorList.push({id: collaborator.id, email: collaborator.email,name:collaborator.name});
    }
    this.addCollaborators();
  }

  public messageFormat(message): void {
    return message? message.message : '';
  }

  public updateLike(message): void {
    const liked=!message.liked;
    this._projectService.updateLike(message.id,{like:liked}).subscribe(res=>{
      this.messagesList.map(element => {
        if(element.id==message.id){
          element.liked=!element.liked;
          if(element.liked){
            element.liked_users_count+=1;
          }else{
            element.liked_users_count-=1;
          }
        }
      });
      this.cd.detectChanges();
    })
    posthog.capture('comment-like', {
      eventType: this.type,
      eventID: this.type_id
    });
  }

  public collaboratorValidation(): void {
    const temp=[];
    this.collaboratorList.forEach(element => {
      temp.push(element.id);
    });
    this.collaborator.forEach(element => {
      if(!temp.includes(element.id)){
        this.collaboratorList.push({id: element.id, email: element.email,name:element.name});
        this.addCollaborators();
      }
    });
  }

  public deleteComment(comment): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet je zeker dat je de update wilt verwijderen?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._projectService.deleteMessage(comment.id).subscribe(res=>{
          this.loadMessages(1,true);
          showFeedbackRemoved();
        })
        posthog.capture('comment-remove', {
          eventType: this.type,
          eventID: this.type_id
        });
      }
    });

  }

  public updateComment(comment): void {
    comment.is_edit=true;
    setTimeout(() => {
      this.onReset(true);
      this.message=comment.message.message;
      this.textareaRefEdit.nativeElement.innerHTML=comment.message.message;
      this.updateCommentID=comment.id;
      this.collaborator=comment.message.collaborator;
      this.items=comment.message.item;
      this.updateActive=true;
      this.cd.detectChanges();
    }, 100);
    posthog.capture('comment-update', {
      eventType: this.type,
      eventId: this.type_id
    });
  }

  public loadItems(searchTerm){
    let sortingAlgo=[];
    const types=[];
    if(searchTerm.length>0){
      this._projectService.itemsSearch(searchTerm).subscribe((res:any)=>{
        sortingAlgo=itemsAlgo(res);
        this.mentionConfig.mentions[1].items.forEach(el=>{
          types.push(`${el.type}-${el.id}`);
        });
        sortingAlgo.forEach(element => {
          if(this.mentionConfig.mentions[1].items.length > 0){
              // eslint-disable-next-line no-empty
              if(types.includes(`${element.type}-${element.id}`)){
              }else{
                this.mentionConfig.mentions[1].items.push(element);
              }
          }else{
            this.mentionConfig.mentions[1].items.push(element);
          }
        });
        if(!this.itemsOnceLoader){
          this.loadMentions(this.mentionConfig.mentions[1].items);
        }
        this.cd.detectChanges();
      })
    }
  }

  public itemSelected(event: any) {
    event.name=htmlRemoval(event.name)
    if(event.item){
      this.items.push(event);
    }else{
      this.collaborator.push(event);
    }
    const code= event.code ? event.code : '';
    setTimeout(() => {
      let uniqueID;
      if(this.updateActive){
        uniqueID=`${event.id}_${event.type}_${code}_update`;
        this.textareaRefEdit.nativeElement.innerHTML=removeAndpaces(this.textareaRefEdit.nativeElement.innerHTML).replace(
          `##${ event.name }##`,
          `&nbsp<a class="blue-text d-cursor-hand items-mentions" target="${event.id}" type="${event.type}" title="${code}" >${ event.name }</a><span id="${uniqueID}">&nbsp</span>`
        );
      }else{
        uniqueID=`${event.id}_${event.type}_${code}`;
        this.textareaRef.nativeElement.innerHTML=removeAndpaces(this.textareaRef.nativeElement.innerHTML).replace(
          `##${ event.name }##`,
          `&nbsp<a class="blue-text d-cursor-hand items-mentions" target="${event.id}" type="${event.type}" title="${code}" >${ event.name }</a><span id="${uniqueID}">&nbsp</span>`
        );
      }
      // put the cursor to the end of field again...
      this.selectEnd(uniqueID);
    }, 10);
  }

  public selectEnd(id) {
    let range, selection;
    const getElement = document.getElementById(id);
    // eslint-disable-next-line prefer-const
    range = document.createRange();
    range.selectNodeContents(getElement);
    range.collapse(false);
    // eslint-disable-next-line prefer-const
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  public onChangeComment(message){
    if(message=='<br>'){
      this.onReset();
    }
    this.message=message;
  }

  public onChangeEditComment(message){
    if(message=='<br>'){
      this.onReset();
    }
    this.message=message;
  }

  public onReset(update=false){
    this.items=[];
    this.collaborator=[];
    this.message='';
    this.updateActive=false;
    this.textareaRef.nativeElement.innerHTML='<br>';
    if(this.updateActive){
      this.textareaRefEdit.nativeElement.innerHTML='<br>';
    }
  }

  public cancleEdit(comment): void {
    comment.is_edit=false;
    this.onReset();
  }

  public loadCollaboratorUsers(){
    let contains;
    const users=[];
    if(this.collaboratorList.length){
      if(this.collaboratorUsers && this.collaboratorUsers.length){
        this.collaboratorUsers.forEach(element => {
          contains=collect(this.collaboratorList).contains((value, key) => value.id == element.id);
          if(!contains){
            users.push(element);
          }
        });
        this.collaboratorUsers=users;
      }
    }
  }

  public nav(){
    setTimeout(() => {
      const dialog=this.dialog;
      const pubsub=this.pubsub;
      const dd = document.getElementsByClassName('items-mentions');
      Array.prototype.forEach.call(dd, (element) => {
        const name=element.innerText;
        element.addEventListener('click', () => {
          if(element.type!=="undefined"){
            pubsub.closeModal(pubsub.currentDialog)
            const data={id:element.target,name:name,code:element.title}
            openModal(element.type,data,dialog,pubsub)
          }
        });
      });
    }, 500);
  }
  //set collaboration instance
  public setCollaborationInstance(): void{
    if(this._pusherService.commentBox==undefined){
      this._pusherService.commentBox=this._pusherService.commentBoxPusher.subscribe((response)=>{
        this.messagesList=[];
        this.loadMessages(1);
        this._pusherService.commentBox=undefined;
      });
    }
  }
}
