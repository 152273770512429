<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin ">
      <div class="col-xs-12 mb-5">
          <h1><strong>{{ t('admin_page.integration') | titlecase}} </strong><span class="">{{t('settings.outlook.title')}}</span></h1>
          <button type="button" class="close " (click)="close()"><span aria-hidden="true">
        <mat-icon class="d-text-middle">close</mat-icon>
      </span><span class="sr-only">Close</span></button>
      </div>
  </div>

  <div class="popup-body" appScroll>
      <div class="col-xs-12 mb-5">
          <h5> {{ t('settings.connection') | titlecase}}</h5>
          <div class="set-border-connection">
              <span class="align-set" *ngIf="!outlookenable && !errorMessage">{{ t('settings.outlook.outlook_integration') | capitaliz}}</span>
              <div class="align-set display-flex" *ngIf="errorMessage">
                  <mat-icon class="d-text-middle">error</mat-icon>
                  <span class="error-message align-set"> {{ t('error.outlook_integration') | capitaliz}}</span>
              </div>
              <div class="align-set display-flex" *ngIf="outlookenable && !errorMessage">
                  <mat-icon class="successfully-message d-text-middle">check_circle</mat-icon>
                  <span class="align-set"> {{ t('success.outlook_integration') | capitaliz}}</span>
              </div>
              <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate)" *ngIf="outlookenable" class="btn btn-primary color-btn-primary-mapa align-set" type="button" (click)="getRedirctLink()">
                  <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
                      {{ t('settings.disable') | titlecase}}
              </button>
              <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate)" *ngIf="!outlookenable" class="btn btn-primary color-btn-primary-map align-set" type="button" (click)="getRedirctLink()">
                  <mat-icon class="d-text-middle icon-connect">add_circle</mat-icon>
                      {{ t('settings.connect_me') }}
              </button>
          </div>
      </div>
      <!-- Additional Setting -->
      <div
      class="col-xs-12 mb-5"
      *ngIf="globals.projectConfigs['services.integration.microsoft.enabled']"
    >
      <h5>{{ t("settings.imap.additional_settings") | titlecase }}</h5>
      <div class="full-width set-border-connection-relatic mb-10">
        <div class="row">
          <div class="col-md-12 display-grid">
            <div class="subtext mb-5">
              {{ "settings.imap.where_email_added" | transloco }}
            </div>
            <div>
              <mat-button-toggle-group
                [(ngModel)]="emailsAddedAs"
                (ngModelChange)="updateSync($event)"
              >
                <mat-button-toggle mat-flat-button value="contact_moments">{{
                  "contactmoments.label" | transloco | capitaliz
                }}</mat-button-toggle>
                <mat-button-toggle mat-flat-button value="complaints" *ngIf="modulesAvailable">{{
                  "complaints.label" | transloco | capitaliz
                }}</mat-button-toggle>
              </mat-button-toggle-group>
              <br />
              <div class="subtext mb-5">
              {{ "settings.outlook.note" | transloco }}
            </div>
            </div>
          </div>
          <!-- subProjects -->
          <div class="col-md-12 display-grid" *ngIf="programModule">
            <div class="subtext mb-5">
              {{ "settings.outlook.optional_subject" | transloco }}
            </div>
            <div class="col-xs-12">
              <mat-form-field>
                <mat-select
                  multiple
                  [(ngModel)]="selectedSubprojects"
                  (ngModelChange)="updateSubprojects($event)"
                  [placeholder]="'settings.outlook.subproject' | transloco"

                >
                  <mat-option
                    *ngFor="let subproject of globals.subprojects"
                    [value]="subproject.name"
                  >
                    {{ subproject.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
