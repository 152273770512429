import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';
import { ZoomService } from 'src/app/core/service/util/zoom.service';

@Component({
  selector: 'app-datepicker-map',
  templateUrl: './datepicker-map.component.html',
  styleUrls: ['./datepicker-map.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerMapComponent {
  public layout: string = 'chart';
  @Input() maxDate: Date; // Add input for max date
  @Input() start: string;
  @Input() end: string;
  @Input() showResetIcon: boolean = false; // Input to control icon visibility

  @Output() dateChange = new EventEmitter<{ start: string, end: string }>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() reset = new EventEmitter<void>();

  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  // eslint-disable-next-line no-empty-function
  constructor(private zoomService: ZoomService) { }

  ngOnInit(): void {
    this.range.valueChanges.subscribe(() => {
      this.onDateChange();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.start || changes.end) {
      this.setDateRange(this.start, this.end);
    }
  }

  setDateRange(start: string, end: string): void {
    this.range.setValue({
      start: new Date(start),
      end: new Date(end)
    });

  }

  onDateChange(): void {
    const dateRange = this.range.value; // Get the value from the range input

    // Initialize the formatted dates as empty strings
    let formattedStart = '';
    let formattedEnd = '';

    // Check if start date is valid and format it
    if (dateRange.start !== null) {
      formattedStart = moment(dateRange.start).format('DD-MM-YYYY');
    }

    // Check if end date is valid and format it
    if (dateRange.end !== null) {
      formattedEnd = moment(dateRange.end).format('DD-MM-YYYY');
    }

    // Emit dateChange only when both start and end dates are valid
    if (formattedStart && formattedEnd) {
      this.dateChange.emit({ start: formattedStart, end: formattedEnd });
    } else {
      console.warn('Both valid start and end dates are required to emit the dateChange event.');
    }
  }


  resetFilter(): void {
    this.range.reset(); // Reset the form controls

    // Emit the reset event to notify the parent component
    this.reset.emit();
  }
}
