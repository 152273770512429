import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-bouwapp-chat',
  templateUrl: './bouwapp-chat.component.html',
  styleUrls: ['./bouwapp-chat.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BouwappChatComponent {

}
