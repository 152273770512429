import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { BatchMergeComponent } from '../components/modal/batch-overview-setting/components/batch-merge/batch-merge.component';
import { RecycleBinComponent } from '../components/modal/recycle-bin/recycle-bin.component';

@Directive({
  selector: '[sharedModal]'
})
export class SharedModalDirective implements AfterViewInit {

  @Input() sharedModal: String = '';
  @Input() data: any = {};
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter();

  // eslint-disable-next-line no-empty-function
  constructor(private el?: ElementRef, public dialog?: MatDialog) { }

  ngAfterViewInit(): void {

    let component = null;

    switch (this.sharedModal) {
      case 'recycleBin':
        component = RecycleBinComponent;
        break;
      case 'batchMerge':
        component = BatchMergeComponent;
        break;
    }

    if (!component) {
      console.error(`Type ${this.sharedModal} unknown or unsupported`);
      return;
    }

    this.el.nativeElement.onclick = (event: MouseEvent) => {
      event.stopPropagation();
      const dialog = this.dialog.open(component, modalConfig({
        data: this.data,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'customized-dialog-container',
      }, ModalEnum.ModalDefault));
      dialog.afterClosed().subscribe((response: any) => {
        if(response){
          this.onClosed.emit(response);
        }
      });
    };
  }

}
