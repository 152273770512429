<div class="main">
    <div class="summary">
        <ng-content select="[summary]"></ng-content>
    </div>
    <div *ngIf="expanded" class="content">
        <ng-content select="[content]"></ng-content>
    </div>
    <div class="text pl-7" *ngIf="!expanded">
        <span *ngIf="text">*</span>{{text}}
    </div>
    <div class="bottom">
      <span class="action" (click)="expanded = !expanded" *ngIf="!expanded">
          <span>{{ expandText | capitaliz }}</span>
          <mat-icon  class="d-text-middle mat-icon">expand_more</mat-icon>
      </span>
      <span class="action" (click)="expanded = !expanded" *ngIf="expanded">
          <span>{{ 'text.show_less' | transloco | capitaliz }}</span>
          <mat-icon  class="d-text-middle mat-icon">expand_less</mat-icon>
      </span>
      
    </div>
</div>