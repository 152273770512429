import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-message-confirm',
  templateUrl: './message-confirm.component.html',
  styleUrls: ['./message-confirm.component.sass']
})
export class MessageConfirmComponent {
  confirm: boolean;
  confirmCheckSelected: boolean = false;
  selectedFeature: string = '';
  featureDisabled: boolean = true;
  constructor(public dialogRef: MatDialogRef<MessageConfirmComponent>,
              // eslint-disable-next-line no-empty-function
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  submit(d) {
    this.dialogRef.close(d);
  }
  confirmCheck(confirm: boolean){
    this.confirmCheckSelected = confirm;
  }
  onFeatureSelection(option: string) {
    this.selectedFeature = option;
    this.featureDisabled = false;
  }
  selectFeature(option: boolean) {
    this.dialogRef.close(option? this.selectedFeature: false);
  }
}
