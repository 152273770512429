import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import collect from 'collect.js';
import Overlay from "ol/Overlay";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PublicLayers } from 'src/app/core/util/openDataModals';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { AbstractService } from '../common/abstract.service';
@Injectable({
  providedIn: 'root'
})
export class MapService extends AbstractService {
  publicLayers=new EventEmitter();
  layers:Subscription;
  symobology: Subject<any> = new Subject<any>();
  panLocation: Subject<any> = new Subject<any>();
  locationAddress: Subject<any> = new Subject<any>();
  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();
  // get Data for rifht panel by Mujahid
  public mainLayers: any
  public mainLayerId: any
  vectorLayer: VectorLayer<any>;
  vectorSource: VectorSource;
  popupOverlay: Overlay;
  keyword: any
  searchFeature: any
  circleVectorSource: any
  closeOverlayPopup: Subject<any> = new Subject<any>();
  public markerPopup: Overlay
  constructor(private http: HttpClient,) {
    super();
  }
  @ServiceErrorHandler()
  public getBookmarks() {
    return this.http.get('/api/bookmark/locations', this.httpOptions);
  }
  public saveBookmarks(location) {
    return this.http.post('/api/bookmark/locations',location, this.httpOptions);
  }
  @ServiceErrorHandler()
  public arcGisStyle(id,platform,data) {
    return this.http.put(`/api/integrationconfigs/${id}?platform=${platform}`,data, this.httpOptions);
  }


  // get public layers
  @ServiceErrorHandler()
  public getOpenDataLayers(publicLayer) {
    if(publicLayer.type=='public_arcgis'){
      const formData=new FormData();
      formData.append('f','pjson');
      return this.http.post(`${publicLayer.url}`,formData);
    }
    let param;
    if(publicLayer.username){
      param = {
        url: publicLayer.url,
        username: publicLayer.username ? publicLayer.username : '',
        password: publicLayer.password ? publicLayer.password : ''
      }
    }else{
      param = {
        url: publicLayer.url,
      }
    }

    return this.http.post(`/layers/curl`,param,{responseType: 'text', headers: this.httpOptions.headers});
  }
  // get all public layer
  @ServiceErrorHandler()
  public getOpenData(): Observable<PublicLayers[]> {
    return this.http.get<PublicLayers[]>(`/api/openlayers`, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  // save public layer
  @ServiceErrorHandler()
  public saveOpenData(data) {
    return this.http.post(`/api/openlayers`,data, this.httpOptions);
  }
  // update public layer
  @ServiceErrorHandler()
  public updateOpenData(data) {
    return this.http.put(`/api/openlayers/${data.id}`,data, this.httpOptions);
  }
  // delete public layer
  @ServiceErrorHandler()
  public deleteOpenData(id) {
    return this.http.delete(`/api/openlayers/${id}`, this.httpOptions);
  }
  //after layers maped
  public afterLayerMaped(action){
    this.publicLayers.emit(action);
  }
  //wms single feature get
  @ServiceErrorHandler()
  public getwmsFeaturesOnClick(url){
    return this.http.get(`${url}`,{responseType: 'text'});
  }
  //get layer symobology
  @ServiceErrorHandler()
  public getSymobology(url,id,integration_token=''){
    return this.http.get(`${url}/${id}/?f=pjson&token=${integration_token}`)
  }
  @ServiceErrorHandler()
  public getSymobologyArcGis(layer_id,id,integration_token=''){
    let responce = {};
    return this.http.get(`https://www.arcgis.com/sharing/rest/content/items/${id}/data?f=json&token=${integration_token}`)
    .pipe(
      map((res: any)=> {
        if(res){
          if(res.layers){
            if(res.layers[layer_id] || (res.layers[0] && res.layers[0].id === layer_id)){
              if(res.layers.length === 1) {
                responce = res.layers[0];
              } else {
                responce = res.layers[layer_id];
              }
            }
          }
        }
        return responce;
      }),
    );
  }
  @ServiceErrorHandler()
  public getSymobologyForSubprojects(url,id,integration_token=''){
    // return this.http.get(`${url}/${id}/?f=pjson&token=${integration_token}`)
    return this.http.get(`${url}/${id}/query?f=json&token=${integration_token}&where=1=1&outFields=*`)
  }
  // save public layer
  @ServiceErrorHandler()
  public potentialStakeholderBackendAPI(query) {
    return this.http.post(`/api/stakeholders/potential`,query, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  // save sorted open data
  @ServiceErrorHandler()
  public setSortedOpenData(data,type) {
    return this.http.post(`/api/${type}/sort`,data, this.httpOptions);
  }
  //set symobology
  public setSymobology(action){
    this.symobology.next(action);
  }

  setPanLocation(data) {
    this.panLocation.next(data)
  }
  getAddress(data) {
    this.locationAddress.next(data)
  }
  public setOverlayPopup(action){
    this.closeOverlayPopup.next(action);
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }
  /*
    Layer Link Authentication
  */
  @ServiceErrorHandler()
  public setLayerAuthentication(publicLayer) {
    if(publicLayer.type=='public_arcgis'){
      const formData=new FormData();
      formData.append('f','pjson');
      return this.http.post(`${publicLayer.url}`,formData);
    }
    return this.http.post(`/api/layers/authenticate`,{url:publicLayer.url}, this.httpOptions);
  }
  @ServiceErrorHandler()
  public getPrivateFeatures(publicLayer, link) {
    let param;
    if(publicLayer.credentials && publicLayer.credentials.username){
      param = {
        url: link,
        username: publicLayer.credentials.username ? publicLayer.credentials.username : '',
        password: publicLayer.credentials.password ? publicLayer.credentials.password : ''
      }
    }else{
      param = {
        url: link,
      }
    }
    return this.http.post(`/layers/curl`, param, this.httpOptions);
  }
  /*
    Get Integration Layers
  */
  @ServiceErrorHandler()
  public getIntegrationLayers(platform: string) {
    return this.http.get(`/api/getenabledlayers?platform=${platform}`, this.httpOptions);
  }
  /*
    Get Open Data Layers For Subproject
  */
  @ServiceErrorHandler()
  public getOpenDataEnableLayers() {
    return this.http.get(`/api/openlayers`, this.httpOptions)
    .pipe(
      map((res: any) => {
        if(res.length) {
          return collect(res).map((item: any) => {item.layers = collect(item.layers).where('is_enabled', true).all(); return item;}).all();
        }
        return res;
      })
    )
  }
  /*
    Get Integration Features From Layers
  */
  @ServiceErrorHandler()
  public getIntegrationFeaturesLayers(url, id, integration_token=''){
    return this.http.get(`${url}/${id}/query?f=json&token=${integration_token}&where=1=1&outFields=*`)
  }
  /*
    Get ArcGis Integration Sub Layers
  */
  @ServiceErrorHandler()
  public getArcGisSubLayers(url, integration_token=''){
    return this.http.get(`${url}?f=json&token=${integration_token}`)
    .pipe(
      map((res: any) => res.layers)
    )
  }
}
