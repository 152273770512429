import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from "../common/abstract.service";
import {PaginationParams} from "../common/pagination-params";
import {Observable, Subject} from "rxjs";
import {PaginatedResponse} from "../common/paginated-response";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class ActionsService extends AbstractService {

  public loadWorkflows$ = new Subject<boolean>();

  constructor(private http: HttpClient) {
    super();
  }

  getAction(actionId: number): Observable<any> {
    return <Observable<any>> this.http.get(`/api/actions/${actionId}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getActions(pagination: PaginationParams, handled?: boolean, userId?: number,myIssues: boolean = false): Observable<PaginatedResponse> {
    let params = new HttpParams();
    if (typeof handled !== 'undefined') {
      params = params.append('handled', handled ? '1' : '0');
    }

    if (typeof userId !== 'undefined') {
      params = params.append('users_id', userId.toString());
    }
    params = params.append('is_mine', myIssues ? 'true' : 'false');

    return <Observable<PaginatedResponse>> this.http.get(`/api/actions`, pagination.get(params));
  }
  @ServiceErrorHandler()
  getActionsRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/actions?dropdown=1`, this.httpOptions);
  }
  @ServiceErrorHandler()
  getStakeholdersActions(stakeholderCode: string, count, page, handled?) {
    let params = new HttpParams()
      .set('count', count)
      .set('page', page);

    if (typeof handled !== 'undefined') {
      params = new HttpParams()
        .set('count', count)
        .set('page', page)
        .set('handled', handled);
    }

    return this.http.get(`/api/stakeholders/${stakeholderCode}/actions`, {
      headers: this.headers,
      params: params
    });
  }

  getAllStakeholdersActions(count, page, handled?) {
    let params = new HttpParams()
      .set('count', count)
      .set('page', page);

    if (typeof handled !== 'undefined') {
      params = new HttpParams()
        .set('count', count)
        .set('page', page)
        .set('handled', handled);
    }

    return this.http.get('/api/stakeholders/actions', {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  updateActionById(id, action) {
    return this.http.put(`/api/actions/${id}`, action, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateAction(action) {
    return this.http.put(`/api/actions/${action.id}`, action, this.httpOptions);
  }

  @ServiceErrorHandler()
  removeAction(action) {
    return this.http.delete(`/api/actions/${action.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitAction(action) {
    return this.http.post('/api/actions', action, this.httpOptions);
  }

  @ServiceErrorHandler()
  getMyActions() {
    return this.http.get('/api/my/actions');
  }

  setLoadWorkflow(value: boolean): void {
    this.loadWorkflows$.next(value);
  }
}
