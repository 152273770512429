import { EventEmitter, Injectable } from '@angular/core';
import { AbstractService } from "../common/abstract.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PaginationParams } from "../common/pagination-params";
import { Observable, Subscription } from "rxjs";
import { PaginatedResponse } from "../common/paginated-response";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService extends AbstractService {
  invokeFunctionDashboard=new EventEmitter();
  subVarDashboard:Subscription;
  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getActivities(params: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/activities', params.get());
  }

  @ServiceErrorHandler()
  getActivitiesRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/activities?dropdown=1', this.httpOptions);
  }

  @ServiceErrorHandler()
  getMiddelRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/middel?dropdown=1', this.httpOptions);
  }

  getAllActivities() {
    return this.http.get('/api/activities?count=1000&page=1', this.httpOptions);
  }

  public getActivity(id, skipSubproject: boolean = false) {
    let params = new HttpParams();
    if (skipSubproject)
      params = params.append('skip_subproject', '0')
    return this.http.get(`/api/activities/${id}?includeMiddel=1&with=contactmoments,subprojects,tasks,layers,permits,workflows`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  updateActivity(id: string, activity: any) {
    return this.http.put(`/api/activities/${id}`, activity, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitActivity(activity) {
    return this.http.post('/api/activities', activity, this.httpOptions);
  }

  @ServiceErrorHandler()
  deleteActivity(id) {
    return this.http.delete(`/api/activities/${ id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getRelatedMiddel(activityId: number, fullCalendarformat: boolean = true) {
    const format = fullCalendarformat ? 1 : 0;
    return this.http.get(`/api/activities/${activityId}/middel?format=${format}&relation=1&skip_subproject=1`, this.httpOptions).pipe(map((res: any)=>{
      res.contents.forEach(content=>{
        content.title = content.extendedProps.metadata.type;
      })
      return res;
    }));
  }
  onClickUndoDashboard(action){
    this.invokeFunctionDashboard.emit(action);
  }
}
