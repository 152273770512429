<div class="row popup-container" *transloco="let a">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}">
        <span aria-hidden="true">
          <mat-icon class="d-text-middle uil uil-multiply"></mat-icon>
        </span>
        <span class="sr-only">Close</span>
      </button>
            <div *hasPermission="[Permission.StakeholdersDelete]">
                <mat-icon *ngIf="stakeholder" (click)="deleteStakeholder(stakeholder)" class="d-cursor-hand uil uil-trash delete pull-right stakeholders-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}">
                </mat-icon>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1><strong>{{ 'stakeholders.label' | transloco | capitaliz }} </strong>{{name}}</h1>
        </div>


    </div>
    <mat-progress-bar *ngIf="!stakeholder" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="stakeholder">
        <!-- left col -->
        <div class="col-md-7 col-xs-12">
            <h3 class="mb-15">{{ 'decisions.connections' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component mb-40" *transloco="let t; read: 'stakeholders'">
                <div>
                    <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                    <app-add-connect-generic (onChange)="updateEntities($event)" *ngIf="loaded" parentType="stakeholders" [parent]="stakeholder" [data]="entities" [types]="['issues','requirements','tasks','contactmoments','complaints','contents','documents','permits','layers']"></app-add-connect-generic>
                </div>
            </div>

            <h3 class="mb-15">{{ 'employees.labels' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 pd-0">
                <div *transloco="let t; read: 'employees'">
                    <app-employees listingType="stakeholder" [stakeholderId]="stakeholder.id" [showSearch]="false" [showStakeholder]="false" [showLogsCount]="false" [addButtonText]="t('label') | capitaliz" (employeesUpdated)="updateContactpersons($event)" [newEntityParams]="{stakeholders: [stakeholder]}"></app-employees>
                </div>
            </div>
        </div>
        <!-- right col -->
        <div class="col-md-5 col-xs-12" *transloco="let t; read: 'stakeholders'">
            <h3 class="mb-15">{{ 'strategy' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel panel-border z-depth-2">
                <app-tab-stakeholder-detail-houding [stakeholder]="stakeholder"></app-tab-stakeholder-detail-houding>
            </div>
            <h3 class="mb-15">{{ 'interests' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel panel-border z-depth-2">
                <app-tab-stakeholder-detail-houding [showInterests]="true" [stakeholder]="stakeholder" (interestSelect)="onSelectInterests($event)"></app-tab-stakeholder-detail-houding>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <app-comment-box [users]="users" type="stakeholders" [type_id]="stakeholder.id"></app-comment-box>
                </div>
            </div>
            <h3 class="mb-15">{{ 'text.general' | transloco | capitaliz }}</h3>
            <mat-progress-bar *ngIf="!reducer" mode="indeterminate"></mat-progress-bar>
            <div class="col-xs-12 panel panel-border z-depth-2" *ngIf="reducer">
                <app-tab-stakeholder-detail-general [relatic]="reducer" [stakeholder]="stakeholder"></app-tab-stakeholder-detail-general>
            </div>
            <h3 class="mb-15 text-capitalize">{{ 'location' | transloco }}</h3>
            <mat-progress-bar *ngIf="!reducer" mode="indeterminate"></mat-progress-bar>
            <div class="col-xs-12 panel z-depth-2 panel-border map-div" *ngIf="reducer">
                <div class="col-xs-12" [matTooltip]="reducer.coordinates ? a('settings.relatic.disable_field') : null" [ngClass]="{'disable_map': reducer.coordinates || !hasPermission(Permission.StakeholdersUpdate)}">
                    <app-openlayers-map [includeSidebar]="false" [defaultCoordinate]="globals.projectConfig.default_map" (ngModelChange)="updateCoordinate()" [(ngModel)]="stakeholder.locations" [ngModelOptions]="{standalone: true}" [showMapReset]="true" [maxPoints]="1" locatableType="stakeholders"
                        defaultDrawTool="point" clickStartDrawingMsg="Teken stakeholder punt" name="locations" [disabled]="reducer.coordinates || !hasPermission(Permission.StakeholdersUpdate)"></app-openlayers-map>
                </div>
                <app-show-more [expandText]="t('expand_text') | capitaliz">
                    <div content>
                        <div class="form-group col-xs-12">
                            <label class="control-label control-label-custom" for="google-address">{{'reusable.search_address' | transloco}}</label>
                            <div [matTooltip]="reducer.country ? a('settings.relatic.disable_field') : null">
                              <input style="width: 100%; margin-left: 0px !important;" #autocompletedetail class="form-control form-control-custom" type="text" [disabled]="!hasPermission(Permission.StakeholdersUpdate) || reducer.country" id="autocompletedetail" placeholder="" />

                                <!-- <input placeholder="" *ngIf="globals.googleMapLibLoaded" type="text" class="form-control form-control-custom" id="google-address" name="google-address" ngx-google-places-autocomplete autocomplete="off" [options]="googleMapOptions"
                                    #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [disabled]="!hasPermission(Permission.StakeholdersUpdate) || reducer.country"> -->
                            </div>
                        </div>
                        <div class="form-group col-xs-12">
                            <label class="control-label control-label-custom">{{ 'stakeholders.address' | transloco | capitaliz }}</label>
                            <mat-icon *ngIf="stakeholder.found_coordinates !== 'found'" class="warning-address-icon" matTooltip="GPS coördinaten onbekend door handmatig ingevuld adres">warning</mat-icon>
                            <mat-slide-toggle class="pull-right manual-address-toggle" [(ngModel)]="enableManualAddress" name="manualAddress" matTooltip="Handmatige invoer in-/uitschakelen" [disabled]="!hasPermission(Permission.StakeholdersUpdate)">
                            </mat-slide-toggle>

                            <mat-form-field class="col-xs-12 pd-0 address-input" [matTooltip]="reducer.address ? a('settings.relatic.disable_field') : null">
                                <input matInput type="text" name="address" [(ngModel)]="stakeholder.address" (change)="updateStakeholder()" [disabled]="!enableManualAddress || reducer.address" />
                            </mat-form-field>
                        </div>
                        <mat-form-field class="col-xs-12" [matTooltip]="reducer.postcode ? a('settings.relatic.disable_field') : null">
                            <input matInput type="text" name="postcode" [(ngModel)]="stakeholder.postcode" (change)="updateAddress()" [disabled]="!enableManualAddress || reducer.postcode" [placeholder]="'maps.addr:postcode' | transloco" />
                        </mat-form-field>

                        <mat-form-field class="col-xs-12" [matTooltip]="reducer.city ? a('settings.relatic.disable_field') : null">
                            <input matInput type="text" name="city" [(ngModel)]="stakeholder.city" (change)="updateAddress()" [disabled]="!enableManualAddress || reducer.city" [placeholder]="'Plaats'" />
                        </mat-form-field>
                    </div>
                </app-show-more>
            </div>
        </div>
    </div>
</div>
