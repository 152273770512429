import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActionState, ActionsStore } from './actions.store';
import { Action } from './action.model';

@Injectable({ providedIn: 'root' })
export class ActionsQuery extends QueryEntity<ActionState, Action> {
  constructor(protected store: ActionsStore) {
    super(store);
  }

  add(activitie: Action): void {
    this.store.add(activitie);
  }
}
