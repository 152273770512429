<div class="main">
  
<div>
  <h4 class="title">{{data.title}}</h4>

</div>
<div class="popup-body">
  {{ data.text }}
</div>
<mat-checkbox labelPosition="before" class="confirm-checkbox" *ngIf="data.showCheckbox && !data.delete_confirm" [(ngModel)]="confirm" name="confirm">Ja, ik wil dit project verwijderen</mat-checkbox>
<mat-checkbox labelPosition="before" class="confirm-checkbox" *ngIf="data.showCheckbox && data.delete_confirm" [(ngModel)]="confirm" name="confirm">{{ data.delete_confirm }}</mat-checkbox>

<div class="popup-footer">
  <div class="dialog-button-row">
    <button type="button" mat-stroked-button (click)="submit(false)">Annuleren</button>
    <button type="button" class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised btn-danger" [disabled]="!confirm && data.showCheckbox" (click)="submit(true)">
      Verwijderen
    </button>
  </div>  
</div>

</div>