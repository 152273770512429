import collect from 'collect.js';
import { Subproject } from '../../akita/subproject/state/subproject.model';
export function globalSubprojectSaprator(subprojects,permission): any {
    let subprojectHandler=[];
    subprojectHandler=collect(subprojects).map((item:any) => {
        item.is_permission = !collect(item.permissions).contains(permission);
        return item;
    }).toArray();
    return subprojectHandler;
}
export function selectedSubprojectSaprator(subprojects,selectedSubproject): any {
    const chipDisabler=[];
    subprojects.forEach(element => {
        selectedSubproject.forEach((el:any) => {
          if(element.id==el.id){
            el.is_permission=element.is_permission;
          }
        });
      });
    selectedSubproject.forEach((element:any) => {
        if(element.is_permission){
          chipDisabler.push(element.name);
        }
    });
    return chipDisabler;
}
export function sortingSubprojects(sub): Subproject[]{
  sub.sort((first: any, second:any) => {
    const textA = first.name.toUpperCase();
    const textB = second.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  return sub;
}
