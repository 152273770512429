import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {LogEditComponent, LogEditData} from "../components/modal/contact-moments-edit/log-edit.component";

@Directive({
  selector: '[logEdit]'
})
export class LogEditDirective implements AfterViewInit {

  @Input() logEdit: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLogEditClosed:EventEmitter<boolean> = new EventEmitter();
  // eslint-disable-next-line no-empty-function
  constructor(private el: ElementRef, public dialog: MatDialog) { }

  ngAfterViewInit(): void {
    const data: LogEditData = {
      log: this.logEdit,
      connectStakeholders: true,
    };

    this.el.nativeElement.onclick = (event: MouseEvent) => {
      event.stopPropagation();
      const editDialog = this.dialog.open(LogEditComponent, {
        data: data,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%'
      });

      editDialog.afterClosed().subscribe( (isUpdated: boolean) => {
        this.onLogEditClosed.emit(isUpdated);
      });
    };
  }

}
