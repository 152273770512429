import OlMap from 'ol/Map';
import {EventEmitter} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {OpenlayersTooltip} from './openlayers-tooltip';
import {Coordinate, Location} from '../../../akita/locations/state/location.model';

declare let ol: any;

export interface OpenlayersDrawingOptions {
  maxArea?: number;
  maxPoints?: number;
  maxLines?: number;
}

export class OpenlayersDrawing {
  public source: any;
  private vector: any;
  private style: any;
  private draw: any;
  public onDraw: boolean = false;
  private selectedCoordinatesTemp: any[];
  private selectedCoordinates: any[] = [];
  private drawFeatures: any[] = [];
  private drawTooltips: any[] = [];

  public onAreaDrawEnd: EventEmitter<Coordinate> = new EventEmitter();
  public onLineDrawEnd: EventEmitter<Coordinate> = new EventEmitter();
  public onPointDrawEnd: EventEmitter<Coordinate> = new EventEmitter();
  public onMaxAreaExceeded: EventEmitter<any> = new EventEmitter();
  public onMaxPointsExceeded: EventEmitter<any> = new EventEmitter();
  public onMaxLinesExceeded: EventEmitter<any> = new EventEmitter();

  private selectedTool: string;
  private selectedToolSubscription: Subscription;

  constructor(private map: OlMap, private preloadedCoordinates: Location[], private selectedTool$: Observable<string>, private tooltip: OpenlayersTooltip, private options: OpenlayersDrawingOptions = {}) {
    this.init();
  }

  public init(): void {
    this.selectedToolSubscription = this.selectedTool$.subscribe(
      (selectedTool: string) => {
        this.map.removeInteraction(this.draw);
        this.selectedTool = selectedTool;

        if (selectedTool) {
          this.addInteraction();
        } else {
          this.removeInteraction();
        }
      }
    );

    this.source = new ol.source.Vector();
    const raster = new ol.layer.Tile({
      source: new ol.source.OSM()
    });
    raster.id = 'raster';

    this.vector = new ol.layer.Vector({
      source: this.source,
      style: this.style
    });
    this.vector.id = 'vector';

    this.map.addLayer(raster);
    this.map.addLayer(this.vector);
  }

  public destroy(): void {
    this.selectedToolSubscription.unsubscribe();
    this.tooltip.destroy();
  }

  private removeInteraction(): void {
    this.tooltip.off();
  }

  private addInteraction(): void {
    this.tooltip.on();

    let type;
    if (this.selectedTool === 'length') {
      type = 'LineString';
    } else if (this.selectedTool === 'area') {
      type = 'Polygon';
    } else if (this.selectedTool === 'point') {
      type = 'Point';
    }
    this.draw = new ol.interaction.Draw({
      source: this.source,
      type: type,
      style: this.style
    });

    let listener;
    this.draw.on('drawstart', (evt: any) => {
      /** @type {module:ol/coordinate~Coordinate|undefined} */
      let tooltipCoord = evt.coordinate;

      listener = evt.feature.getGeometry().on('change', evt => {
        const geom = evt.target;
        let output;

        if (this.selectedTool === 'area') {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          this.tooltip.write(output);
        } else if (this.selectedTool === 'length') {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
          this.selectedCoordinatesTemp = geom.getCoordinates();
          this.tooltip.write(output);
        } else if (this.selectedTool === 'point') {
          this.selectedCoordinatesTemp = geom.getCoordinates();
        }
      });
    }, this);

    this.draw.on('drawend', (evt) => {
      // Store the area coordinates so that it can be retrieved by other components
      // Used by middel.component.ts
      this.onDraw = true;
      if (this.selectedTool === 'area' && this.selectedCoordinatesTemp) {
        evt.feature.setId('area');
        const polygon = new ol.geom.Polygon(this.selectedCoordinatesTemp);
        const polygonConverted = polygon.transform('EPSG:3857', 'EPSG:4326').getCoordinates();

        this.selectedCoordinates.push(polygonConverted);
        this.selectedCoordinatesTemp = null;
        this.onAreaDrawEnd.emit(polygonConverted);
      } else if (this.selectedTool === 'point' && this.selectedCoordinatesTemp) {
        evt.feature.setId('point');
        if (this.options.maxPoints && this.options.maxPoints <= this.preloadedCoordinates.concat(this.selectedCoordinates).length) {
          this.onMaxPointsExceeded.emit();
        }

        const point = new ol.geom.Point(this.selectedCoordinatesTemp);
        const pointConverted = point.transform('EPSG:3857', 'EPSG:4326').getCoordinates();

        this.selectedCoordinates.push(pointConverted);
        this.onPointDrawEnd.emit(pointConverted);
      } else if (this.selectedTool === 'length' && this.selectedCoordinatesTemp) {
        evt.feature.setId('length');
        if (this.options.maxLines && this.options.maxLines <= this.preloadedCoordinates.concat(this.selectedCoordinates).length) {
          this.onMaxLinesExceeded.emit();
        }

        const line = new ol.geom.LineString(this.selectedCoordinatesTemp);
        const lineConverted = line.transform('EPSG:3857', 'EPSG:4326').getCoordinates();
        this.selectedCoordinates.push(lineConverted);
        this.onLineDrawEnd.emit(lineConverted);
      }

      // Store the features so we can remove it later
      this.drawFeatures.push(evt.feature);
      this.drawTooltips.push(this.tooltip.setStatic());

      ol.Observable.unByKey(listener);

      // Check the total area of drawing
      if (this.selectedTool === 'area' && this.options.maxArea) {
        const totalArea = this.calculateTotalArea();
        if (totalArea > this.options.maxArea) {
          if (this.onMaxAreaExceeded) {
            this.onMaxAreaExceeded.emit();
          }
          this.removeDrawing();
          return; // Early return to prevent emitting in the end of this function's closure
        }
      }

      if (this.options.maxPoints && this.selectedTool === 'point' && this.preloadedCoordinates.concat(this.selectedCoordinates).length > this.options.maxPoints) {
        this.removeDrawing(false);
         // Early return to prevent emitting in the end of this function's closure
      } else if (this.options.maxLines && this.selectedTool === 'length' && this.preloadedCoordinates.concat(this.selectedCoordinates).length > this.options.maxLines) {
        this.removeDrawing(false);
         // Early return to prevent emitting in the end of this function's closure
      }
    }, this);

    this.map.addInteraction(this.draw);
  }

  public removeDrawing(notify: boolean = true) {
    let lastDrawingRemoved = true;
    let layerType = 'area';

    // First remove drawn lines/polygons
    if (this.drawFeatures.length > 0 && this.drawTooltips.length > 0) {
      const poppedFeature = this.drawFeatures.pop();
      layerType = poppedFeature.getId();
      this.source.removeFeature(poppedFeature);
      this.selectedCoordinates.pop();

      // Remove the tooltip
      const tooltip = this.drawTooltips.pop();
      if (tooltip && tooltip.parentNode) {
        tooltip.parentNode.removeChild(tooltip);
      }

      lastDrawingRemoved = false;
    } else {
      // Remove the pre-loaded polygons
      this.map.getLayers().forEach((layer:any) => {
        if (layer.id === 'preloadedPolygons' || layer.id === 'preloadedPoints' || layer.id === 'preloadedLines') {
          if (layer.id === 'preloadedPolygons') {
            layerType = 'area';
          } else if (layer.id === 'preloadedPoints') {
            layerType = 'point';
          } else if (layer.id === 'preloadedLines') {
            layerType = 'length';
          }

          this.map.removeLayer(layer);
          lastDrawingRemoved = false;
        }
      });

      this.preloadedCoordinates = [];
    }

    if (!lastDrawingRemoved) {
      this.removeDrawing(notify);
    }
  }

  private calculateTotalArea() {
    return 0;
    // TODO! Fix this
    // const sphere = new ol.Sphere(6378137);
    // let area_km_total = 0;
    // const allCoordinates = this.selectedCoordinates.concat(this.preloadedCoordinates);
    // allCoordinates.forEach(coord => {
    //   const polygon = coord[0];
    //   const area_m = sphere.geodesicArea(polygon);
    //   let area_km = area_m / 1000 / 1000;
    //   area_km_total += area_km;
    // });
    //
    // return area_km_total
  }

  /**
   * Format length output.
   * @param {module:ol/geom/LineString~LineString} line The line.
   * @returns {string} The formatted length.
   */
  private formatLength (line): string {
    const length = line.getLength();
    let output;
    if (length > 100) {
      output = `${Math.round(length / 1000 * 100) / 100
        } ` + `km`;
    } else {
      output = `${Math.round(length * 100) / 100
        } ` + `m`;
    }
    return output;
  };

  /**
   * Format area output.
   * @param {module:ol/geom/Polygon~Polygon} polygon The polygon.
   * @returns {string} Formatted area.
   */
  private formatArea(polygon): string {
    // Save the coordinates temporary in a variable so we can use it to calculate residents if needed
    this.selectedCoordinatesTemp = polygon.getCoordinates();
    const area = polygon.getArea();
    let output;
    if (area > 10000) {
      output = `${Math.round(area / 1000000 * 100) / 100
        } ` + `km<sup>2</sup>`;
    } else {
      output = `${Math.round(area * 100) / 100
        } ` + `m<sup>2</sup>`;
    }
    return output;
  };
}
