import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Complaint } from '../../../../akita/complaints/state/complaint.model';
import { Stakeholder } from '../../../../akita/stakeholders/state/stakeholder.model';
import { GenericComponent } from '../../../../core/util/abstract/generic-component';
import { deepCopy } from '../../../../core/util/deepCopy';
import { Globals } from '../../../../globals';
import { GenericMultiselectCheckboxComponent } from '../../other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';

@Component({
  selector: 'app-complaint-contact-modal',
  templateUrl: './complaint-contact-modal.component.html',
  styleUrls: ['./complaint-contact-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComplaintContactModalComponent extends GenericComponent {

  public connectStakeholderText:string = 'text.complaints_connected_to_stakeholder';
  public complaint: Complaint;
  public relatics:boolean=false;
  public type: string;
  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {type: any, relatics: any,complaint: Complaint, stakeholders: Stakeholder[], residents: any[], employees: any[]}, public dialogRef: MatDialogRef<any>, public globals: Globals) {
    super();
  }

  public ngOnInit() {
    this.relatics=this.data.relatics;
    if (!this.globals.projectConfigs['residents.disabled']) {
      this.connectStakeholderText = 'text.complaints_connected_to_stakeholder_resident';
    }

    this.complaint = deepCopy(this.data.complaint);
    this.type = this.data.type;
    if (this.complaint.contactpersons) {
      this.complaint.contactpersons.selected = this.complaint.contactpersons.stakeholders.filter(x => this.complaint.stakeholders.find(s => s.id == x.id));
    }

  }

  public close(): void {
    this.dialogRef.close();
  }

  public updateComplaint(): void {
    this.dialogRef.close(this.complaint);
  }
}
