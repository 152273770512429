<div *ngIf="show" class="filter-dropdown animate__animated animate__fadeIn">
  <i (click)="toggle()" class="uil uil-times d-text-middle pull-right close"></i>
  <div class="filter-lists mt-17">
    <div class="header mb-10">
      {{'custom-columns.columns' | transloco}}
    </div>
    <mat-selection-list [(ngModel)]="optionalColumns" ngDefaultControl style="height: 200px; overflow-y: auto;">
      <mat-list-item *ngFor="let column of optionalColumns" [value]="column.value" class="mat-list-item">
          <div class="column-option">
            <mat-slide-toggle   [disabled]="column.name === 'workflow' && !hasModule('workflow')"
            [ngClass]="{'disable': column.name === 'workflow' && !hasModule('workflow')}" [(ngModel)]="column.value" ngDefaultControl ></mat-slide-toggle>
            <ng-container [ngSwitch]="column.name">
              <ng-container *ngSwitchCase="'themeissues.name'">
                <span>{{'custom-columns.themeissues_name' | transloco }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'influence'">
                <span>{{globals.projectConfigs['project.stakeholder_strategy.is_new'] ? ('custom-columns.influence' | transloco | capitaliz ) : ('reusable.power' | transloco | capitaliz )}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'interest'">
                <span>{{globals.projectConfigs['project.stakeholder_strategy.is_new'] ? ('custom-columns.interest' | transloco | capitaliz ) : ('reusable.interest' | transloco | capitaliz )}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'trust'">
                <span>{{globals.projectConfigs['project.stakeholder_strategy.is_new'] ? ('custom-columns.trust' | transloco | capitaliz ) : ('reusable.trust' | transloco | capitaliz )}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'tension'">
                <span>{{globals.projectConfigs['project.stakeholder_strategy.is_new'] ? ('custom-columns.tension' | transloco | capitaliz ) : ('reusable.type_of_interest' | transloco | capitaliz )}}</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span [ngClass]="{'disable': column.name === 'workflow' && !hasModule('workflow')}">{{('custom-columns.'+column.name)| transloco }}</span>
                <span *ngIf="column.name === 'workflow'" class="badge">NEW</span>
              </ng-container>
            </ng-container>
          </div>
      </mat-list-item>
    </mat-selection-list>
    <a mat-flat-button class="full-width" (click)="columnSelected()">
      <i class="uil uil-plus-circle unicon-icon d-text-middle"></i>
      <span class="hidden-xs hidden-sm">{{ 'save' | transloco | capitaliz}}</span>
    </a>
  </div>
</div>
