import {Injectable} from '@angular/core';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import {bugsnagFactory} from '../../../core/util/bugsnagFactory';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends BugsnagErrorHandler {

  constructor() {
    super(bugsnagFactory());
  }

  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    super.handleError(error);
  }
}
