import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, FormBuilder,
  FormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator, Validators
} from "@angular/forms";

@Component({
  selector: 'app-multiselect-users',
  templateUrl: './multiselect-users.component.html',
  styleUrls: ['./multiselect-users.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => MultiselectUsersComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => MultiselectUsersComponent),
    multi: true
  }]
})
export class MultiselectUsersComponent implements OnInit, ControlValueAccessor, Validator {

  filteredUsers: any;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('users') users = [];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedUsers') selectedUsers = [];
    // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('multipleUsers') multipleUsers = true;
    // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('placeholder') placeholder: string = 'Selecteer gebruiker(s)';
    // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabled') disabled = false;
    // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('required') required = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectedUsersUpdated: EventEmitter<any> = new EventEmitter();
  @Input() public chipDisabler: any=[];
  touched = false;
  onChange: () => void;
  onTouched: () => void;

  public usersForm: FormGroup;


  // eslint-disable-next-line no-empty-function
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (this.required) {
      this.placeholder += '*';
    }

    this.filteredUsers = this.users;
    this.updateFormRequiredValidity();

    this.usersForm.get('text').valueChanges.subscribe(
      input => this.filteredUsers = this.filterMultipleUsersByTyping(input)
    );
  }

  private filterMultipleUsersByTyping(users: any) {
    if (!users) {
      return this.users;
    }

    let temporaryUsers = this.filteredUsers;
    if (Array.isArray(users) && users.length > 0) {
      temporaryUsers = this.users.filter(s => {
        return users.filter(si => s.email === si.email).length !== 1
      });
    }

    if (typeof users === 'string') {
      if (users === '') {
        return this.filteredUsers;
      }
      temporaryUsers = this.users.filter(s => s.name.toLowerCase().includes(users.toLowerCase()));
    }

    return temporaryUsers;
  }

  public removeUser(user: any) {
    this.selectedUsers = this.selectedUsers.filter(s => {
      return user.email !== s.email;
    });

    this.onSelectedUsersUpdated.emit(this.selectedUsers);
  }

  public addUser(user: any) {

    if (!user.option ||
      this.selectedUsers.filter(u => user.option.value.email === u.email).length > 0) {
      // If this user was selected already, don't add
      this.usersForm.reset();
      return;
    }
    if (!this.multipleUsers && this.selectedUsers.length > 0) {
      this.selectedUsers.pop();
      this.selectedUsers.push(user.option.value);
      this.onSelectedUsersUpdated.emit(this.selectedUsers);
      this.usersForm.reset();
      return;
    }
    this.selectedUsers.push(user.option.value);

    this.onSelectedUsersUpdated.emit(this.selectedUsers);
    this.usersForm.reset();
  }

  public updateFormRequiredValidity(required = this.required) {
    this.required = required;
    this.usersForm = this.formBuilder.group({
      'text': ['', this.required && this.touched && (!this.selectedUsers || this.selectedUsers.length === 0) ? Validators.required : null,],
    });

    if (this.disabled) {
      this.usersForm.get('text').disable();
    }

    if (this.touched) {
      this.usersForm.get('text').markAsTouched();
    }
  }

  public setUsers(users) {
    this.users = users;
    this.filteredUsers = this.users;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = () => {
      fn();
      this.touched = true;
    };
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.usersForm.get('text').disable();
    } else {
      this.usersForm.get('text').enable();

      // Workaround because cannot find a way to trigger onTouched() from form or control
      this.touched = true;
      this.updateFormRequiredValidity();
    }
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.selectedUsers = [];
    } else {
      this.selectedUsers = obj;
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.errors) {
      if (!this.required && control.errors.required) {
        return null;
      } if (this.required && this.selectedUsers && this.selectedUsers.length > 0) {
        return null;
      } else if (this.required && control.errors.required) {
        this.usersForm.get('text').markAsTouched();
        this.usersForm.get('text').setErrors({
          required: true
        });
      }

      return control.errors;
    }

    return null;
  }

}
