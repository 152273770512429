<div mat-dialog-title>
    <h3 class="row mb-10 inline-block text-capitalize">{{ 'decisions.label' | transloco }} #{{ data.decision.id }}: {{ data.decision.title }}</h3>
    <button type="button" class="close" (click)="close()"><span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon></span><span class="sr-only">Close</span></button>
</div>

<div class="modal-body no-padding" *ngIf="decision">
    <!-- left col -->
    <div class="col-md-6 col-xs-12" *transloco="let t; read: 'decisions'">
        <div class="col-xs-12">
            <h3 class="mb-15">Algemeen</h3>
            <form name="wishForm" (ngSubmit)="updateDecision()" #form_editDecision="ngForm">

                <!-- Title -->
                <mat-form-field class="full-width">
                    <input type="text" matInput id="title" name="title" [placeholder]="t('title') | capitaliz" [(ngModel)]="decision.title" (change)="updateDecision()" required [disabled]="!hasPermission(Permission.DecisionsUpdate)" />
                </mat-form-field>

                <!-- Description -->
                <mat-form-field class="full-width">
                    <textarea matInput id="description" name="description" [placeholder]="t('description') | capitaliz" rows="5" [(ngModel)]="decision.description" (change)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate)">
        </textarea>
                </mat-form-field>

                <!-- Theme -->
                <app-add-connect-themes [type]="themeType" [selectedTheme]="selectedTheme" (onThemeSelected)="updateSelectedTheme($event)"></app-add-connect-themes>

                <!-- Status -->
                <mat-form-field class="full-width" id="status">
                    <mat-select [(value)]="decision.status" (valueChange)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate)">
                        <mat-option value="open">{{ t('open') | capitaliz }}</mat-option>
                        <mat-option value="gehonoreerd">{{ t('honored') | capitaliz }}</mat-option>
                        <mat-option value="afgewezen">{{ t('rejected') | capitaliz }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width">
                    <input matInput type="text" name="motivation" [(ngModel)]="decision.motivation" (change)="updateDecision()" [placeholder]="t('motivation') | capitaliz" />
                </mat-form-field>

                <!-- Date filter -->
                <mat-radio-group name="newDecision_dateFilter" [(ngModel)]="dateFilter" (change)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate)">
                    <mat-radio-button value="no-date">Geen datum beperking</mat-radio-button>
                    <mat-radio-button class="date-restriction pl-5" value="fixed-date">Vaste datum</mat-radio-button>
                    <mat-radio-button class="date-restriction pl-5" value="ranged-date">Datumbereik</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="dateFilter === 'fixed-date'">
                    <mat-form-field>
                        <input matInput [matDatepicker]="datePickerStart" (focus)="datePickerStart.open()" (click)="datePickerStart.open()" placeholder="Kies een datum" name="newDecision_startDate" [(ngModel)]="decision.start_date" (dateChange)="updateDecision()" (change)="updateDecision()"
                            [disabled]="!hasPermission(Permission.DecisionsUpdate)">
                        <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #datePickerStart></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="dateFilter === 'ranged-date'">
                    <mat-form-field>
                        <input matInput [matDatepicker]="datePickerStart" (focus)="datePickerStart.open()" (click)="datePickerStart.open()" placeholder="Kies begindatum" name="newDecision_startDate" [(ngModel)]="decision.start_date" (dateChange)="updateDecision()" (change)="updateDecision()"
                            [disabled]="!hasPermission(Permission.DecisionsUpdate)">
                        <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #datePickerStart></mat-datepicker>
                    </mat-form-field>
                    <span class="tm">t/m</span>
                    <mat-form-field>
                        <input matInput [matDatepicker]="datePickerEnd" [min]="decision.start_date" (focus)="datePickerEnd.open()" (click)="datePickerEnd.open()" placeholder="Kies einddatum" name="newDecision_endDate" [(ngModel)]="decision.end_date" (dateChange)="updateDecision()"
                            (change)="updateDecision()" [disabled]="!hasPermission(Permission.DecisionsUpdate)">
                        <mat-datepicker-toggle matSuffix [for]="datePickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #datePickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <!-- Location filter -->
                <div class="form-group">
                    <span class="pr-30">Locatie</span>
                    <app-openlayers-map [(ngModel)]="decision.locations" [includeSidebar]="false" [defaultCoordinate]="projectConfig.default_map" [showMapReset]="true" [maxPoints]="1" name="locations" locatableType="requirements" defaultDrawTool="point" clickStartDrawingMsg="Teken klantwenspunt"></app-openlayers-map>
                </div>

            </form>
        </div>
    </div>

    <!-- right col -->
    <div class="col-md-6 col-xs-12">

        <!-- documents -->
        <div class="col-xs-12">
            <h3 class="mb-15">Documenten</h3>
            <div class="form-group" *hasPermission="[Permission.DecisionsUpdate]">
                <div>
                    <div class="list-group">
                        <!-- Button to trigger document sidebar -->
                        <a class="btn d-btn-dialog mdl-button mdl-js-button mdl-button--raised" (closed)="loadDecision()" [documentSidebar]="DocuploadType.decisions" [documentSidebarDocuments]="decision.documents" [documentSidebarId]="decision.id">
            Upload document(en)
            </a>
                    </div>
                </div>
            </div>
            <!-- DOCS OVERVIEW -->
            <app-documents-overview *ngIf="decision" [documents]="decision.documents" (onDocumentRemoved)="loadDecision()" [type]="DocuploadType.decisions" [id]="decision.id">
            </app-documents-overview>

        </div>

        <!-- Connection tables -->
        <div class="col-xs-12">
            <ng-container *ngIf="showConnection">
                <div class="decision-detail--connections-title-container">
                    <span>
        <h3 class="decision-detail--connections-title">Is ontstaan uit</h3>
        </span>
                    <span class="decision-detail--stakeholders-warning" *ngIf="(!decision.stakeholders || decision.stakeholders.length === 0) && (!decision.logs || decision.logs.length === 0)">
        <mat-icon class="d-text-middle">error</mat-icon> {{'reusable.Floating_requirements' | transloco}}
        </span>
                </div>

                <app-generic-multiselect-checkbox type="stakeholders" *hasPermission="[Permission.DecisionsUpdate]" #stakeholderMultiselect placeholder="Selecteer stakeholder(s)" name="stakeholders" [(ngModel)]="decision.stakeholders" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'"
                    (ngModelChange)="updateDecision()">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                        {{ stakeholder.name }}
                    </app-generic-multiselect-checkbox-option>
                    <app-generic-multiselect-checkbox-option-new-action type="stakeholder"></app-generic-multiselect-checkbox-option-new-action>
                </app-generic-multiselect-checkbox>

                <!-- Stakeholders -->
                <div class="table-responsive">
                    <table class="table table-hover table-condensed full-width">
                        <thead>
                            <tr>
                                <th class="col-xs-12">klanteis van Stakeholders</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="!decision.stakeholders || decision.stakeholders.length === 0">
                                <td><span>Geen stakeholders</span></td>
                            </tr>
                            <tr *ngFor="let stakeholder of shownStakeholders; trackBy: trackByFn">
                                <td>
                                    <span>
                <a [href]="globals.project.code + '/stakeholders/' + stakeholder.code">{{ stakeholder.name | truncatie : 25 }}</a>
            </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-simple-mat-pagination *ngIf="decision.stakeholders && decision.stakeholders.length > 0" [entities]="decision.stakeholders" [pageSize]="itemsPerPage" (onPageChange)="setShownStakeholders($event)"></app-simple-mat-pagination>
                </div>

                <div class="table-responsive">
                    <table id="table-stakeholders-connection" class="table table-hover table-condensed full-width">
                        <thead>
                            <tr>
                                <th class="col-xs-1">Type</th>
                                <th class="col-xs-3">Datum</th>
                                <th class="col-xs-8">Omschrijving</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="decision.logs && decision.logs.length === 0">
                                <td colspan="2"><span>Geen Logs</span></td>
                            </tr>
                            <tr *ngFor="let log of shownLogs; trackBy: trackByFn">
                                <td>
                                    <a class="text-sml d-cursor">
                                        <mat-icon *ngIf="log.contact_moment_type === 'phone'">phone</mat-icon>
                                        <mat-icon *ngIf="log.contact_moment_type === 'email'">email</mat-icon>
                                        <mat-icon *ngIf="log.contact_moment_type === 'meeting'">group</mat-icon>
                                    </a>
                                </td>
                                <td>
                                    <span *ngIf="log.contact_moment_timestamp">{{ log.contact_moment_timestamp | date: 'dd-MM-yyyy' }}</span>
                                </td>
                                <td>
                                    <a class="d-cursor-hand" (click)="editLog(log)">
                {{ log.description | stripHtml | truncatie: 30 }}
                </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-simple-mat-pagination *ngIf="decision.logs && decision.logs.length > 0" #pagination_decisionLogs [entities]="decision.logs" [pageSize]="itemsPerPage" (onPageChange)="setShownLogs($event)"></app-simple-mat-pagination>

                    <br>

                </div>

                <!-- issue -->
                <div class="table-responsive">
                    <table id="table-issues-connections" class="table table-hover table-condensed full-width">
                        <thead>
                            <tr>
                                <th class="col-xs-12">Issues</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="decision.issues.length == 0">
                                <td><span>Geen issues</span></td>
                            </tr>
                            <tr *ngFor="let issue of shownIssues; trackBy: trackByFn">
                                <td>
                                    <span>
                <a [href]="globals.project.code + '/issues' + issue.code">{{ issue.name | truncatie: 25 }}</a>
            </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-simple-mat-pagination *ngIf="decision.issues.length > 0" [entities]="decision.issues" [pageSize]="itemsPerPage" (onPageChange)="setShownIssues($event)"></app-simple-mat-pagination>
                </div>

                <!-- LOGS MODAL -->

                <app-klantwensen-logs-modal [id]="data.decision.id" (onConnect)="updateStakeholdersLogs($event)">
                </app-klantwensen-logs-modal>
                <!--Relatics connections-->
                <div class="col-xs-12" *ngIf="integrationEnabled('relatics') && integrateWithRelatics">
                    <h3 class="mb-15">Relatics</h3>

                    <app-modal-relatics *hasPermission="[Permission.DecisionsUpdate]" [connectedRelatics]="decision.relatics" (onSubmit)="connectDecisionRelatics($event)"></app-modal-relatics>
                    <app-connected-relatics [relatics]="decision.relatics" (onRemoveRelatics)="removeRelatics($event)" [editable]="hasPermission(Permission.DecisionsUpdate)"></app-connected-relatics>
                </div>
            </ng-container>


            <div class="table-responsive">
                <h3 class="mb-15 text-capitalize">{{ 'decisions.guarantee' | transloco }}</h3>
                <mat-form-field class="full-width">
                    <input matInput type="text" name="requirement_concept" [(ngModel)]="decision.requirement_concept" (change)="updateDecision()" [placeholder]="'decisions.requirement_concept' | transloco | capitaliz" />
                </mat-form-field>
                <mat-form-field class="full-width">
                    <input matInput type="text" name="guarantee" [(ngModel)]="decision.guarantee" (change)="updateDecision()" [placeholder]="'decisions.guarantee' | transloco | capitaliz" />
                </mat-form-field>
            </div>
        </div>

    </div>
</div>

