import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';

@Component({
  selector: 'app-filter-sort',
  templateUrl: './filter-sort.component.html',
  styleUrls: ['./filter-sort.component.sass']
})
export class FilterSortComponent {
  expanded = false;
  filterText: string = '';
  @Input() public sortField: string;
  @Input() public filters: any;
  @Input() public filterFields: any = [];
  @Input() public sortFields: any;
  @Input() public totalSortField: any;
  @Input() public analysisIssueSort: any;
  @Input() public analysisSort: string[];
  @Input() public filterLabel: boolean = false;
  @Input() public paginator: PaginationParams;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onApply: EventEmitter<any> = new EventEmitter();
  show: boolean = false;
  selectedFilters: any = {};
  selectedallFilters: any = {};
  selector = true;
  @Input() public hideIcons: boolean = false;
  @Input() public hideSortLabels: boolean = false;
  @ViewChild('filterSort', { static: false }) filterSort;
  originalFilters: any[] = []; // Store the original filters array

  constructor(private cdRef: ChangeDetectorRef, private route: ActivatedRoute,) {
    document.addEventListener('click', this.offClickHandler.bind(this));

  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('strategie')) {
      this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
        const strategie = paramMap.get('strategie');
        this.selectedFilters['filter_field'] = [strategie];
      });
    }
    if (this.route.snapshot.queryParamMap.get('status')) {
      this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
        const strategie = paramMap.get('status');
        if (strategie !== 'gesloten') {
          this.selectedFilters['is_closed'] = [parseInt(strategie)];
        }
      });
    }
    if (this.filters && this.filterFields) {
      this.filters = this.filters.filter(f => this.filterFields.includes(f.field))
    }
    this.originalFilters = this.filters
    this.filterText.trim();
  }

  // This function will be called on every keyup
  onFilterChange(): void {
    // If there's no filter text, reset the filters array
    if (!this.filterText) {
      if(this.originalFilters == undefined) {
        this.filters = this.filters
      } else {
        this.filters = JSON.parse(JSON.stringify(this.originalFilters));
      }
      return;
    }
    // Filter the original array based on filterText
    this.filters = this.filters.map(filterGroup => {
      // Filter the options inside each filter group
      const filteredOptions = filterGroup.options.filter(option =>
        option.toLowerCase().includes(this.filterText.toLowerCase())
      );

      // Return the filter group with the filtered options
      return {
        ...filterGroup,
        options: filteredOptions
      };
    });
  }

  apply() {
    this.paginator._filters = { ...this.selectedFilters }
    this.onApply.emit();
    this.show = false;
  }

  cancel() {
    this.filterFields.forEach(f => {
      this.selectedFilters[f] = []
    });
    this.apply();
  }

  disabled() {
    let disabled = true;
    this.filterFields.forEach(f => {
      if (this.paginator._filters[f] && this.paginator._filters[f].length)
        disabled = false
    });
    return disabled;
  }

  changeSort(field: string, direction: string) {
    this.paginator.changeSort(field, direction)
  }

  changeAnalysisSort(field: string, direction: string) {
    const analysisSort = this.paginator._analysisSort;
    if (field.startsWith("matrices.")) {
      for (const key in analysisSort) {
        if (key.startsWith("matrices.")) {
          delete analysisSort[key];
        }
      }
      analysisSort[field] = direction;
    } else if (field.startsWith("stakeholders.")) {
      analysisSort[field] = direction;
    } else if (field.startsWith("issues.")) {
      analysisSort[field] = direction;
    }
  }


  toggle() {
    this.show = !this.show;
    this.filterText = ''
    if (!this.filterText) {
      if(this.originalFilters == undefined) {
        this.filters = this.filters
      } else {
        this.filters = JSON.parse(JSON.stringify(this.originalFilters));
      }
      return;
    }
    this.cdRef.detectChanges();
  }

  toggleAllSelection(seletedvalues) {
    const fieldfinder = seletedvalues.field
    if (!this.selectedFilters[fieldfinder] || Object.entries(this.selectedFilters[fieldfinder]).length === 0) {
      this.selectedFilters[fieldfinder] = seletedvalues.options;
    }
    else {
      this.selectedFilters[fieldfinder] = [];
    }
  }
  offClickHandler(event: any) {
    if (!this.filterSort.nativeElement.contains(event.target)) {
      if (event.target.className.includes && !event.target.className.includes('overlay') &&
        !event.target.className.includes('mat-option') &&
        this.filterSort.nativeElement.parentElement.parentElement != event.target.parentElement
        && event.target.parentElement &&
        this.filterSort.nativeElement.parentElement.parentElement != event.target.parentElement.parentElement) {
        this.show = false;
        this.filterText = ''
        if (!this.filterText) {
          if(this.originalFilters == undefined) {
            this.filters = this.filters
          } else {
            this.filters = JSON.parse(JSON.stringify(this.originalFilters));
          }
        }
         this.cdRef.detectChanges();

      }
      if (event.target.className.includes && event.target.className.includes('mat-button'))
        this.selectedFilters = { ...this.paginator._filters };
    }


  }

}
