import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationStart, Router, } from '@angular/router';
import { translate } from '@jsverse/transloco';
import collect from 'collect.js';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { catchError, tap } from 'rxjs/operators';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { SubprojectsService } from 'src/app/akita/subproject/state/subprojects.service';
import { ActionsService } from 'src/app/akita/tasks/state/actions.service';
import { DocuploadType } from 'src/app/core/enum/docupload-type';
import { Entities } from 'src/app/core/enum/entities';
import { MapService } from 'src/app/core/service/api/map.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { ReportService } from 'src/app/core/service/api/report.service';
import { dateIso } from 'src/app/core/util/dateIso';
import { arcgisTypeDefine, integrationError, integrationValidationArcGis } from 'src/app/core/util/integration';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedbackError, showFeedbackSaved } from 'src/app/core/util/notification';
import { ComplaintsDetailComponent } from 'src/app/features/main-app/complaints/complaints-detail/complaints-detail.component';
import { TabIssueDetailGeneralComponent } from 'src/app/features/main-app/issues/page/issues-detail/tab-issue-detail-general/tab-issue-detail-general.component';
import { MapDetailsComponent } from 'src/app/features/main-app/map/page/map-details/map-details.component';
import { KlantwensenDetailComponent } from 'src/app/features/main-app/requirements/page/requirement-detail/klantwensen-detail.component';
import { StakeholdersDetailComponent } from 'src/app/features/main-app/stakeholders/page/stakeholders-detail/stakeholders-detail.component';
import { LogsComponent } from 'src/app/shared/components/listings/logs/logs.component';
import { ActivityNewComponent } from 'src/app/shared/components/modal/activity-new/activity-new.component';
import { AddStakeholdersActionComponent } from 'src/app/shared/components/modal/add-stakeholders-task/add-stakeholders-action.component';
import { CalendarActivityDetailComponent } from 'src/app/shared/components/modal/calendar-activity-detail/calendar-activity-detail.component';
import { CalendarMiddelDetailComponent } from 'src/app/shared/components/modal/calendar-content-detail/calendar-middel-detail.component';
import { ComplaintNewComponent } from 'src/app/shared/components/modal/complaint-new/complaint-new.component';
import { LogEditComponent, LogEditData } from 'src/app/shared/components/modal/contact-moments-edit/log-edit.component';
import { LogNewComponent } from 'src/app/shared/components/modal/contact-moments-new/log-new.component';
import { MiddelNewComponent } from 'src/app/shared/components/modal/content-new/middel-new.component';
import { IssueNewComponent } from 'src/app/shared/components/modal/issue-new/issue-new.component';
import { MessageConfirmComponent } from 'src/app/shared/components/modal/message-confirm/message-confirm.component';
import { DecisionNewComponent } from 'src/app/shared/components/modal/requirement-new/decision-new.component';
import { ActionEditComponent } from 'src/app/shared/components/modal/tasks-edit/action-edit.component';
import { FormatdatePipe } from 'src/app/shared/pipes/formatdate.pipe';
import { ThemesService } from "../../../core/service/api/themes.service";
import { GenericComponent } from "../../../core/util/abstract/generic-component";
import { Globals } from "../../../globals";
import { DeletionModalComponent } from '../modal/deletion-modal/deletion-modal.component';
import { combineLatest, throwError } from 'rxjs';
import { StakeholderNewComponent } from '../modal/stakeholder-new/stakeholder-new.component';
import { PermitNewComponent } from '../modal/permit-new/permit-new.component';
import { NewWorkflowComponent } from '../modal/new-workflow/new-workflow.component';
import { PermitDetailComponent } from 'src/app/features/main-app/permits/page/permit-detail/permit-detail.component';
import { AddWorkflowTaskComponent } from '../modal/add-workflow-task/add-workflow-task.component';
declare let $: any;

@Component({
  selector: 'app-add-connect-generic',
  templateUrl: './add-connect-generic.component.html',
  styleUrls: ['./add-connect-generic.component.sass']
})
export class AddConnectGenericComponent extends GenericComponent implements OnInit, AfterViewInit {

  @Input() types: String[] = ['issues', 'requirements', 'tasks', 'contactmoments', 'complaints', 'contents', 'layers', 'permits']
  @Input() parent: any;
  @Input() data: any;
  @Input() parentType: string = "";
  @Input() placeholder = "thema";
  @Input() editable = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter()
  new: any;
  doc: any
  setPrams: any;
  filtered = [];
  expanded = -1;
  itemRefresh: boolean = false;
  public relatic: any = [];
  public layersCheckerVa = [];
  userOpenLayers = [];
  filteredOptions = [];
  isExist: boolean = false;
  openTasksCount: number = 0;
  openTasksExists: boolean = false;
  toggleRelationType: string = '';
  timerClear: any;
  position: any;
  type: string = '';
  text: string = '';
  index: number;
  entityTypes = {
    "contactmoments": {
      icon: "uil uil-comments-alt",
      title: "logs.labels",
      label: "logs.label",
      showMultiselect: false,
      component: LogEditComponent,
      docType: DocuploadType.logs,
      url: ["contactmomenten"]
    },
    "stakeholders": {
      icon: "uil uil-users-alt",
      title: "stakeholders.labels",
      label: "stakeholders.label",
      showMultiselect: false,
      component: StakeholdersDetailComponent,
      docType: DocuploadType.stakeholders,
      url: ["stakeholders"]
    },
    "issues": {
      icon: "uil uil-puzzle-piece",
      title: "issues.labels",
      label: "issues.label",
      showMultiselect: false,
      component: TabIssueDetailGeneralComponent,
      docType: DocuploadType.issues,
      url: ["issues"]
    },
    "requirements_by": {
      icon: "uil uil-trees",
      title: "decisions.conflicted_decisions",
      label: "decisions.label",
      showMultiselect: false,
      component: KlantwensenDetailComponent,
      docType: DocuploadType.decisions,
      url: ["klanteisen"]
    },
    "requirements": {
      icon: "uil uil-trees",
      title: "decisions.labels",
      label: "decisions.label",
      showMultiselect: false,
      component: KlantwensenDetailComponent,
      docType: DocuploadType.decisions,
      url: ["klanteisen"]

    },
    "permits": {
      icon: "uil uil-file-contract",
      title: "permits.labels",
      label: "permits.label",
      showMultiselect: false,
      component: PermitDetailComponent,
      docType: DocuploadType.permits,
      url: ["permits"]
    },

    "tasks": {
      icon: "uil uil-clipboard-notes",
      title: "actions.labels",
      label: "actions.label",
      showMultiselect: false,
      component: ActionEditComponent,
      docType: DocuploadType.actions,
      url: ["acties"]
    },
    "complaints": {
      icon: "uil uil-comment-alt-exclamation",
      title: "complaints.labels",
      label: "complaints.label",
      showMultiselect: false,
      docType: DocuploadType.complaints,
      component: ComplaintsDetailComponent,
      url: ["meldingen"]

    },
    "contents": {
      icon: "uil uil-user-square",
      title: "middel.labels",
      label: "middel.label",
      showMultiselect: false,
      docType: DocuploadType.calendars,
      component: CalendarMiddelDetailComponent,
      url: ["communicatiekalender", "middel"]

    },
    "activities": {
      icon: "uil uil-calender",
      title: "activities.labels",
      label: "activities.label",
      showMultiselect: false,
      component: CalendarActivityDetailComponent,
      url: ["communicatiekalender", "activiteit"]
    },
    "layers": {
      icon: "uil uil-map",
      title: "maps.labels",
      label: "maps.label",
      showMultiselect: false,
      component: MapDetailsComponent,
    },
    "relatics": {
      icon: "uil uil-clipboard-notes",
      title: "relatic.title",
      label: "relatic.title",
      showMultiselect: false,
    },
    "documents": {
      icon: "uil uil-folder",
      title: "documents.labels",
      label: "documents.label",
      showMultiselect: false,

    }
  };
  public arcGisLayers: any[] = [];
  public arcGisLayerCheck: boolean = false;
  public is_user: boolean = false;
  public isAdmin: boolean = false;
  public arcGisToken: string;
  public activeArcGis: string = 'arcgis';
  public errorIntegration: boolean = false;
  public errorIntegrationEnterprise: boolean = false;
  public integrationCheck: string;
  @ViewChild(LogsComponent, { static: false }) logs: LogsComponent;
  @ViewChildren('connectionPanel') connectionPanel: any;
  @ViewChild('filterSort', { static: false }) filterSort;

  constructor(private _themesService: ThemesService, public globals: Globals,
    private dialog: MatDialog, private cdRef: ChangeDetectorRef,
    private _reportsService: ReportService,
    private cd: ChangeDetectorRef,
    private router: Router,
    public pubsub: PubsubService,
    private subProjectsService: SubprojectsService,
    public _projectConfigsService: ProjectConfigsService,
    private _mapServices: MapService,
    private elementRef: ElementRef,
    private _actionsService: ActionsService) {
    super();
    document.addEventListener('click', this.offClickHandler.bind(this));
  }
  public ngOnInit(): void {
    this.isAdmin = this.globals.user?.isAdmin;
    this.activeArcGis = arcgisTypeDefine(this.globals);
    this.errorIntegration = integrationError(this.globals, 'arcgis_enabled');
    this.errorIntegrationEnterprise = integrationError(this.globals, 'arcgis_enterprise_enabled');
    this.integrationCheck = integrationValidationArcGis(this.globals, 'arcgis', 'arcgis_enterprise', 'arcgis_enabled', 'arcgis_enterprise_enabled', this.errorIntegration, this.errorIntegrationEnterprise);
    if (this.integrationCheck === 'project') {
      this.isExist = true;
      this.integrationGetLayers();
    } else if (this.integrationCheck === 'personal') {
      this.isExist = true;
      this.is_user = true;
      this.integrationGetLayers();
    } else {
      this.getopenDataLayers();
    }
    if (this.parent && this.parent.contactmoments) {
      this.parent.contactmoments = this.parent.contactmoments.sort((a, b) => {
        return new Date(b.contact_moment_timestamp).getTime() - new Date(a.contact_moment_timestamp).getTime()
      });
    }
    if (this.globals.modules && !this.globals.modules.some(module => module.module_id === 'communication'))
      this.types = this.types.filter(c => c != 'activities' && c != 'contents' && c != 'complaints')

    if (this.globals.modules && !this.globals.modules.some(module => module.module_id === 'permit'))
      this.types = this.types.filter(c => c != 'permits')

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.pubsub.currentDialog) {
          this.pubsub.closeModal(this.pubsub.currentDialog)
          this.pubsub.resetHistory();
        }
      }
    });
    this.openTasksCount = 0;
    if (this.parent && this.parent['tasks'] && this.parent['tasks'].length) {
      this.parent['tasks'].forEach((item) => {
        if (item.handled === false) {
          this.openTasksExists = true;
          this.openTasksCount++;
        }
      })
    }

    this.pubsub.newLogEntity.subscribe(res => {
      this.index = this.index >= 0 ? this.index : 0;
      this.updateLog(this.index, res);
    })
  }

  ngAfterViewInit(): void {
    this.onSort();
  }

  stripHtmlTags(text: string): string {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  filterSearch(type: any) {
    if (this.text.length) {
      if (type == "requirements_by")
        type = "requirements"
      if (type !== 'issues' && type !== 'complaints' && type !== 'documents' && type !== 'tasks' && type !== 'stakeholders' && type !== 'activities' && type !== 'contents') {
        this.filteredOptions = this.data[type].filter(option => option.title.toLowerCase().includes(this.text.toLowerCase()));
      } else if ((type === 'issues' || type === 'documents' || type === 'stakeholders' || type === 'activities') && type !== 'complaints' && type !== 'contents') {
        this.filteredOptions = this.data[type].filter(option => option.name.toLowerCase().includes(this.text.toLowerCase()));
      } else if (type === 'tasks') {
        this.filteredOptions = this.data[type].filter(option => option.text.toLowerCase().includes(this.text.toLowerCase()));
      } else if (type === 'contents') {
        this.filteredOptions = this.data[type].filter(option => option.type.toLowerCase().includes(this.text.toLowerCase()));
      } else if (type === 'complaints') {

        this.filteredOptions = this.data[type].filter(option => {
          if (option.text) {
            const searchText = this.text.toLowerCase();
            const strippedText = this.stripHtmlTags(option.text).toLowerCase();
            return strippedText.includes(searchText); // Ensure you return the result of the condition
          }
          return false; // In case option.text is undefined, exclude this option
        });
      }
    } else {

      this.filteredOptions = this.data[type];
    }
  }
  public delete(obj, type: string, i, event: MouseEvent): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: translate('text.connection_delete')
    });
    dialogRef.disableClose = true;
    let _type = type
    if (type == "requirements_by")
      _type = "requirements"
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        if (this.data[_type]) {
          this.data[_type].push(obj);
        }
        const filteredItems = this.parent[type].filter((item) => item.id !== obj.id);
        // filter handled items of tasks
        if (type === 'tasks') {
          // eslint-disable-next-line no-unused-expressions
          obj.handled === false ? this.openTasksCount-- : '';
          this.openTasksExists = this.openTasksCount === 0 ? false : true;
        }
        if (!filteredItems.length) {
          this.connectionPanel.toArray()[i].expanded = false;
          this.connectionPanel.toArray()[i].close()
        }
        this.parent[type] = filteredItems;
        this.onChange.emit({
          selected: filteredItems,
          type: type
        });
      }
      this.getopenDataLayers();
    });


  }

  toggle(event: MouseEvent, type, i) {
    this.text = '';
    event.stopPropagation();
    if (!this.entityTypes[type].showMultiselect && this.type !== type) {
      if (type === 'requirements_by') {
        this.filteredOptions = [...this.data['requirements']];
      } else {
        this.filteredOptions = [...this.data[type]];
      }
      clearTimeout(this.timerClear);
      this.connectionPanel.toArray()[i].expanded = true;
      this.entityTypes[type].showMultiselect = true;
      this.adjustOverlayPosition(event, type, 200);
    } else {
      this.entityTypes[type].showMultiselect = false;
      this.type = '';
    }
  }

  link(type: string) {
    // if (this.parentType == "contents" && type == "activities") {
    //   this.parent[type] = [this.new];
    //   this.data[type] = this.data[type].filter(x => x.id != this.new.id);
    //   this.new = [];
    //   this.onChange.emit({
    //     selected: this.parent[type],
    //     type: type
    //   });
    //   this.itemRefresh = false;
    //   return;
    // }
    this.parent[type] = (this.parent[type] && this.parent[type].length) ? this.parent[type] : [];
    this.onChange.emit({
      selected: this.parent[type],
      type: type
    });
    if (type === 'tasks') {
      // eslint-disable-next-line no-unused-expressions
      let openTaskCount = 0;
      this.parent[type].forEach((action: any) => {
        if (action.handled === false) {
          openTaskCount++;
        }
      });
      this.openTasksCount = openTaskCount;
      this.openTasksExists = this.openTasksCount === 0 ? false : true;
    }
    this.text = '';
    this.cd.detectChanges();
  }

  add(event: MouseEvent, type: string, i) {
    event.stopPropagation();
    let component = null;
    let dialog = null;
    if (this.parentType == 'issues') {
      this.setPrams = { users: [this.globals.user], issues: [this.parent] }
    }
    else if (this.parentType == 'requirements') {
      this.setPrams = { users: [this.globals.user], requirements: [this.parent] }
    }
    else if (this.parentType == 'complaints') {
      this.setPrams = { users: [this.globals.user], complaints: [this.parent] }
    }
    else if (this.parentType == 'contents') {
      this.setPrams = { users: [this.globals.user], contents: [this.parent] }
    }
    else if (this.parentType == 'contactmoments') {
      this.setPrams = { users: [this.globals.user], contactmoments: [this.parent] }
    }
    else if (this.parentType == 'stakeholders') {
      this.setPrams = { users: [this.globals.user], stakeholders: [this.parent] }
    }
    else if (this.parentType == 'activities') {
      this.setPrams = { users: [this.globals.user], activities: [this.parent] }
    }
    else if (this.parentType == 'layers') {
      this.setPrams = { users: [this.globals.user], layers: this.parent }
    }
    else {
      this.setPrams = { users: [this.globals.user] }
    }
    const data = {
      params: this.setPrams,
      options: false,
    };
    switch (type) {
      case Entities.issues:
        component = IssueNewComponent;
        break;
      case Entities.complaints:
        component = ComplaintNewComponent;
        break;
      case Entities.contents:
        component = MiddelNewComponent;
        break;
      case Entities.activities:
        component = ActivityNewComponent;
        break;
      case Entities.tasks:
        component = AddStakeholdersActionComponent;
        break;
      case Entities.stakeholders:
        component = StakeholderNewComponent;
        break;
      case "requirements_by":
        component = DecisionNewComponent;
        break;
      case "requirements":
        component = DecisionNewComponent;
        break;
      case "permits":
        component = PermitNewComponent;
        break;
      case Entities.workflow:
        component = NewWorkflowComponent;
        break;
      case Entities.workflowtask:
        component = AddWorkflowTaskComponent;
        break;
      case "contactmoments":
        const extraParams = {
          "origin": "connect-generic"
        }
        extraParams[this.parentType] = [this.parent]
        const data: LogEditData = {
          log: null,
          connectStakeholders: true,
          params: extraParams
        };
        dialog = this.dialog.open(LogNewComponent, modalConfig({
          data: data,
          panelClass: 'customized-dialog-container',
        }, ModalEnum.ModalDefault));
        dialog.afterClosed().subscribe((response: any) => {
          if (response && response.updated && response.log.id) {
            this.itemRefresh = true;
            this.cd.detectChanges();
            this.connectionPanel.toArray()[i].expanded = true;
            this.connectionPanel.toArray()[i].open()
            this.parent[type].unshift(response.log);
            this.data[type].unshift(response);
            this.filteredOptions = [...this.data[type]];
            this.onChange.emit({
              selected: this.parent[type],
              type: type
            });
            this.itemRefresh = false;
            this.cd.detectChanges();
          }
        });
        break;

    }
    if (type != "contactmoments") {
      dialog = this.dialog.open(component, modalConfig(
        {
          data: data,
          panelClass: 'customized-dialog-container',
        },
        ModalEnum.ModalDefault));
      dialog.afterClosed().subscribe((response: any) => {
        if (response) {
          this.itemRefresh = true;
          this.cd.detectChanges();
          this.connectionPanel.toArray()[i].expanded = true;
          this.connectionPanel.toArray()[i].open()
          // if (this.parentType == "contents") {
          //   this.parent[type] = [response];
          //   this.onChange.emit({
          //     selected: this.parent[type],
          //     type: type
          //   });
          //   return;
          // }
          this.parent[type].unshift(response);
          this.data[type].unshift(response);
          this.filteredOptions = [...this.data[type]];
          this.onChange.emit({
            selected: this.parent[type],
            type: type
          });
          this.itemRefresh = false;
          if (type === 'tasks') {
            // eslint-disable-next-line no-unused-expressions
            response.handled === false ? this.openTasksCount++ : '';
            this.openTasksExists = this.openTasksCount === 0 ? false : true;
          }
          this.cd.detectChanges();
        }
      });

    }

  }
  public addLog(event: MouseEvent, i, extraParams?: any, extraOptions?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  }): void {
    this.index = i;
    extraParams = { ...extraParams, "origin": "connect-generic"};
    extraParams[this.parentType] = [this.parent];
    const data: LogEditData = {
      log: null,
      connectStakeholders: true,
      // stakehoderMandatory: true,
      params: extraParams,
      options: extraOptions,
    };

    const editDialog = this.dialog.open(LogNewComponent, modalConfig({
      data: data,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));

    editDialog.afterClosed().subscribe((dialogData: {
      log: any,
      updated: boolean,
    }) => {
      if (dialogData && dialogData.updated && dialogData.log) {
        this.itemRefresh = true;
        this.cd.detectChanges();
        this.connectionPanel.toArray()[i].expanded = true;
        this.connectionPanel.toArray()[i].open()
        this.parent["contactmoments"].unshift(dialogData.log);
        this.data["contactmoments"].unshift(dialogData.log);
        this.filteredOptions = [...this.data["contactmoments"]];
        this.onChange.emit({
          selected: this.parent["contactmoments"],
          type: "contactmoments"
        });
        this.itemRefresh = false;
        this.cd.detectChanges();
      }
    });


  }

  updateLog(i, log?: any) {
    // this.pubsub.newLogEntity.unsubscribe()
    const editDialog = this.dialog.open(LogNewComponent, modalConfig({
      data: log,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));

    editDialog.afterClosed().subscribe((dialogData: {
      log: any,
      updated: boolean,
    }) => {
      if (dialogData && dialogData.updated && dialogData.log) {
        this.itemRefresh = true;
        this.cd.detectChanges();
        this.connectionPanel.toArray()[i].expanded = true;
        this.connectionPanel.toArray()[i].open()
        this.parent["contactmoments"].unshift(dialogData.log);
        this.data["contactmoments"].unshift(dialogData.log);
        this.filteredOptions = [...this.data["contactmoments"]];
        this.onChange.emit({
          selected: this.parent["contactmoments"],
          type: "contactmoments"
        });
        this.itemRefresh = false;
        this.cd.detectChanges();
      }
    });
  }

  public handleAction(action: any): void {
    this._actionsService.update(action)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          const message: string = action.handled ? translate('text.action_is_handled') : translate('text.action_is_recovered');
          showFeedbackSaved(message);
        },
      );
  }

  // TODO: This should be a separate component with better implementation
  getLabel(type: string) {
    let count = 0;
    if (type == "issues") {
      count = this.parent[type].filter(x => x.issues_status == "open").length;
      if (count) return `${count} ${translate('issues.urgent')}`;
    }
    if (type == "requirements_by" || type == "requirements") {
      count = this.parent[type].filter(x => x.status == "initial").length;
      if (count) return `${count} ${translate('decisions.initial')}`;
    }
    if (type == "tasks") {
      let overdue = 0;
      let thisWeek = 0;
      this.parent[type].forEach((action: any) => {
        action.timestamp = dateIso(action.timestamp, true, this.globals.timezone);
        action.day_diff = moment(action.timestamp).diff({ hour: 0 }, 'days');
        if (moment(action.timestamp).isSame(new Date(), 'week')) {
          if (!action.handled)
            thisWeek += 1;
        } else if (moment(action.timestamp).isBefore(new Date(), 'week')) {
          if (!action.handled)
            overdue += 1;
        }
      });
      let label = ""
      if (overdue)
        label += `${overdue} ${translate('actions.late')}`
      if (thisWeek) {
        if (overdue)
          label += ","
        label += `${thisWeek} ${translate('actions.deadlines_current')}`
      }
      return label;
    }
    if (type == "complaints") {
      count = this.parent[type].filter(x => !x.is_closed).length;
      if (count) return `${count} ${translate('complaints.status_open')}`;
    }

  }

  redirectToProject(data: any, type: string) {
    if (this.globals.user.scope.level == 'subproject') {
      this.dialog.open(MessageConfirmComponent, {
        data: { title: '', body: translate("users.no_projectRole"), confirmButton: true },
        width: "30vw"
      });
    } else {
      const modal = this.dialog.open(MessageConfirmComponent, {
        data: { title: translate("text.redirect_to_project"), body: translate("text.redirect_to_project_text"), type: ''},
        width: "30vw"
      });
      modal.afterClosed().subscribe((res) => {
        if (res) {
          let id = data.id;
          if (type == "issues")
            id = data.code
          this.pubsub.closeModal(this.pubsub.currentDialog)
          setTimeout(() => {
            this.subProjectsService.set(null);
            this.router.navigate([...this.entityTypes[type].url, id]);
          }, 1000);

        }
      })
    }
  }

  public nav(data: any, type: string, title: string): void {
    if (type != 'layers' && type != 'contents' && this.parentType !== 'layers') {
      if (!data.subproject_exist) {
        this.redirectToProject(data, type)
        return
      }
    }
    const component = this.entityTypes[type].component
    let size = ModalEnum.SidebarLargeResponsive;
    if (['tasks', 'activities', 'contents'].includes(type))
      size = ModalEnum.SidebarSmallResponsive;
    const formatDatePipe = new FormatdatePipe(this.globals.locale);
    if (type == "contactmoments")
      title = `${formatDatePipe.transform(dateIso(data.contact_moment_timestamp, true, this.globals.timezone), 'dd-MM-yyy')}:${data.title ? data.title : '(Contactmoment zonder titel)'}`
    this.pubsub.closeModal(this.pubsub.currentDialog)
    document.body.classList.add("overflow-hidden");
    const modal = this.dialog.open(component, modalConfig({
      data: data,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
    }, size));
    this.pubsub.updateHistory(modal, component, data, title, size)

  }

  public onDocumentRemoved(documentId: number): void {
    this.itemRefresh = true;
    this.cd.detectChanges();
    if (documentId) {
      this.doc = this.parent.documents.filter(document => document.id == documentId);
      if ( this.doc .length) {
        const i = this.parent.documents.indexOf( this.doc [0])
        this.parent.documents.splice(i, 1)
      }
    }
     // Use setTimeout to defer the change
    this.filteredOptions.push(... this.doc);
    this.data.documents = (this.filteredOptions);
    this.itemRefresh = false;
    this.cd.detectChanges();
  }

  public onDocumentUploaded(documents: any, i): void {
    this.itemRefresh = true;
    this.cd.detectChanges();
    if (documents) {
      this.connectionPanel.toArray()[i].expanded = true;
      if (this.parentType == 'complaints') {
        this.onChange.emit(false)
      }
    }
    this.itemRefresh = false;
    this.cd.detectChanges();
  }

  actionClass(action) {
    const now = moment();
    let className = "";
    if (moment(action.timestamp).isBefore(now)) {
      className = 'late';
    } else {
      const currentWeek = moment().week();
      if (moment(action.timestamp).week() !== currentWeek) {
        className = 'upcoming';
      }
      if (moment(action.timestamp).week() == currentWeek) {
        className = 'week';
      }
    }
    return className;
  }

  hasImages(type: string) {
    let _hasImages = false;
    if (type == 'documents' && this.parentType == 'complaints') {
      this.parent.documents.forEach(doc => {
        if (doc.mimetype && doc.mimetype.includes('image') && doc.base64) {
          if (this.parent.source == "api") { _hasImages = true }
        }
      });
    }
    return _hasImages;
  }

  /*
    ArcGis Integration Layers Setup
  */
  public async integrationGetLayers(): Promise<boolean> {
    if (this.globals.enableLayers) {
      this.getopenDataLayers();
      return true;
    }
    combineLatest([
      this._projectConfigsService.getEnableFeatureLayers(this.activeArcGis, this.is_user),
      this._projectConfigsService.getFreshToken(this.activeArcGis, this.is_user)
    ])
      .pipe(
        tap(async ([layers, arcgis]) => {
          this.setArcGisSubLayers(layers, arcgis);
        }),
        catchError((err) => {
          this.globals.projectConfigs['services.integration.arcgis.enabled'] = false;
          this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled'] = false;
          showFeedbackError(err);
          return throwError(err);
        }),
      ).subscribe();
  }
  /*
    ArcGis Layers Into Sub Layers
  */
  public async setArcGisSubLayers(layers: any, arcgis: any): Promise<void> {
    this.arcGisLayers = [];
    layers.forEach(layer => {
      this._mapServices.getArcGisSubLayers(layer.url, arcgis.token)
        .pipe(
          tap(async (res: any) => {
            res.forEach(item => {
              let foundObject = null;
              if (layer.metadata) {
                if (layer.metadata.length) {
                  foundObject = layer.metadata.find(data => data.layerId === item.id);
                }
              }
              this.arcGisLayers.push({
                name: layer.name,
                main_layer_id: layer.id,
                layer_name: item.name,
                layer_id: item.id,
                type: item.type,
                geometry_type: item.geometryType,
                url: layer.url,
                owner: layer.owner,
                enabled: layer.enabled,
                metadata: foundObject ? foundObject.metadata : null,
                alldata: layer.metadata ? layer.metadata : null,
              });
            });
            this.arcGisToken = arcgis.token;
            this.globals.enableLayers = this.arcGisLayers;
            this.globals.arcgisToken = this.arcGisToken;
            this.getopenDataLayers();
          })
        ).subscribe();
    });
  }
  //get all open data layers
  public getopenDataLayers() {
    this.userOpenLayers = [];
    this._mapServices.getOpenData()
      .pipe(
        tap((publicLayer: any) => {
          publicLayer.forEach(element => {
            element.layers.forEach(layer => {
              this.userOpenLayers.push(layer);
            });
          });
          this.layersChecker();
        }),
      ).subscribe();
  }
  public layersChecker(): void {
    if (this.parent.layers && this.parent.layers.length > 0) {
      const self = this;
      const enable_layers = this.globals.enableLayers;
      const global_LayersName = [];
      this.layersCheckerVa = this.parent.layers;
      if (!enable_layers) {
        self.layersCheckerVa.map((e) => {
          if (collect(this.userOpenLayers).contains(item => item.name == e.layer_name)) {
            e.isAuthorized = false;
          }
          else {
            e.isAuthorized = true;
          }
        });
      } else {
        enable_layers.forEach(element => {
          global_LayersName.push(`${element.name.replaceAll(" ", '')}`);
        });
        self.layersCheckerVa.map((e) => {
          if (enable_layers.some(layer => layer.main_layer_id === e.main_layer_id) || collect(this.userOpenLayers).contains(item => item.name == e.layer_name)) {
            e.isAuthorized = false;
          }
          else {
            e.isAuthorized = true;
          }
        });
      }
      this.cd.detectChanges();
    }
  }
  //on click oprn detail panel from child component
  public onDetail(value): void {
    this.nav(value.item, value.itemType, value.itemName);
  }
  //on click delete from child component
  public onDelete(value): void {
    this.delete(value.item, value.itemType, value.index, value.event);
  }
  public onUpdateHandle(value): void {
    // eslint-disable-next-line no-unused-expressions
    value.handled ? this.openTasksCount-- : this.openTasksCount++
    this.openTasksExists = this.openTasksCount === 0 ? false : true;
  }
  //on sort items
  public onSort(): void {
    this.types.forEach((el: any) => {
      switch (el) {
        case 'contactmoments':
          if (this.data) {
            this.data[el].sort((first, second) => {
              const date1 = new Date(second.contact_moment_timestamp);
              const date2 = new Date(first.contact_moment_timestamp);
              return +(date1) - +(date2)
            });
          }
          break;
      }
    });
  }

  adjustOverlayPosition(event: any, type, time) {
    this.timerClear = setTimeout(() => {
      this.type = type;
      this.toggleRelationType = type;
      const buttonRect = (event.target as HTMLElement).getBoundingClientRect();
      const panelRect = this.elementRef.nativeElement.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollX = window.scrollX || window.pageXOffset;
      const offsetTop = 30;

      // Calculate the horizontal center position
      const centerLeft = buttonRect.left + scrollX - panelRect.left + (buttonRect.width / 2);

      // Calculate the overlay's left position to center it horizontally
      const overlayWidth = 350;
      const overlayLeft = centerLeft - (overlayWidth / 2);
      if (this.parentType !== 'layers') {
        this.position = {
          top: buttonRect.bottom + scrollY - panelRect.top + offsetTop,
          left: overlayLeft,
        };
      } else if (this.parentType === 'layers') {
        this.position = {
          top: buttonRect.bottom - 50,
          left: overlayLeft - 50,
        };
      }


    }, time)
  }

  languageBasedOnType(type: string, option: string) {
    if (type === 'logs.label' || type === 'documents.label' || type === 'issues.label') {
      return option === 'connect' ? translate('text.connect_existing_singular') : translate('text.make_new_singular')
    }
    if (type === 'decisions.label' || type === 'complaints.label' || type === 'actions.label' || type === 'activities.label') {
      return option === 'connect' ? translate('text.connect_existing') : translate('text.make_new')
    }

    return option === 'connect' ? translate('text.connect_existing_singular') : translate('text.make_new_singular')

  }

  offClickHandler(event: any) {
    const clickedElement = event.target;
    const isInputFieldClicked = clickedElement.tagName === 'INPUT' || clickedElement.classList.contains('mat-input-element');
    if (!isInputFieldClicked && this.toggleRelationType) {
      if (this.filterSort) {
        if (this.filterSort.nativeElement) {
          if (!this.filterSort.nativeElement.contains(event.target)) {
            if (event.target.className.includes && !event.target.className.includes('overlay') &&
              !event.target.className.includes('mat-option') &&
              this.filterSort.nativeElement.parentElement.parentElement != event.target.parentElement
              && event.target.parentElement &&
              this.filterSort.nativeElement.parentElement.parentElement != event.target.parentElement.parentElement) {
              // this.show = false;
              this.entityTypes[this.toggleRelationType].showMultiselect = false;
              this.type = '';
              this.toggleRelationType = '';
              this.cdRef.detectChanges();
            }
          }
        }
      }
    }
  }
}
