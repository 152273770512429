import { translate } from '@jsverse/transloco';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {showFeedback, showFeedbackError} from '../core/util/notification';
import {Globals} from '../globals';
import {AppInjector} from '../../app-injector';


export function ServiceErrorHandler(): any {
  return function (target: any, name: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    descriptor.value = function (...args) {
      const call: Observable<any> = method.apply(this, args);
      return call.pipe(
        catchError((e: HttpErrorResponse) => {
          const globals: Globals = AppInjector ? AppInjector.get(Globals) : null;

          const errorObject = {
            error: e,
            request: globals && globals.lastRequest ? globals.lastRequest : null,
          };

          switch (e.status) {
            case 404:
              showFeedback(translate('error.not_found'), 'error', errorObject, false);
              break;
            case 401:
              showFeedback(translate('error.please_wait'), 'error', errorObject, false);
              window.location.reload();
              break;
            case 403:
              showFeedback(translate('error.contact_administrator'), 'error', errorObject, true);
              break;
            case 504:
              showFeedback(translate('error.server_error'), 'error', errorObject, true);
              break;
            default:
              showFeedbackError(errorObject);
              break;
          }
          return throwError(e);
        })
      );
    };
  };
}
