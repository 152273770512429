<form class="nav gd-navbar-center navbar-form full-width no-padding" *transloco="let t">
    <div class="has-feedback full-width" [formGroup]="searchForm">
        <mat-form-field class="full-width">
            <mat-label>{{ placeholder| capitaliz }}</mat-label>
            <input matInput type="text" formControlName="keyword" [matChipInputFor]="list" [matAutocomplete]="auto" #input/>
            <mat-chip-grid #list>
                <ng-container *ngIf="values$ | async as values">
                    <ng-container *ngFor="let model of models">
                        <mat-chip-row *ngFor="let item of values[model]" (removed)="removeChip(model, item)">
                            <ng-container *ngIf="model === 'issues' || model === 'permits'">
                                {{ item.name }}
                            </ng-container>
                            <ng-container *ngIf="model === 'requirements'">
                                {{ item.title }}
                            </ng-container>
                            <ng-container *ngIf="model === 'complaints'">
                                {{ item.text | truncatie:100 }}
                            </ng-container>
                            <ng-container *ngIf="model === 'contents'">
                                {{ t('middel.label') | capitaliz }} {{ item.type }}
                            </ng-container>
                            <ng-container *ngIf="model === 'contactmoments'">
                                {{ (item.title || t('logs.label')) | capitaliz }} - {{ item.contact_moment_timestamp | formatdate: 'dd MMM yyyy' : globals.timezone }}
                            </ng-container>
                            <ng-container *ngIf="model === 'stakeholders'">
                                {{ item.name }}
                            </ng-container>
                            <ng-container *ngIf="model === 'contactpersons'">
                                {{ item.first_name }} {{ item.last_name }}
                            </ng-container>
                            <ng-container *ngIf="model === 'activities'">
                                {{ item.name }}
                            </ng-container>
                            <ng-container *ngIf="model === 'tasks'">
                                {{ item.text }}
                            </ng-container>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                    </ng-container>
                </ng-container>
            </mat-chip-grid>
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete" (click)="$event.preventDefault(); $event.stopPropagation()">
            <mat-option *ngIf="(listSearch$ | async)?.length==0">{{ 'text.no_items_in_list' | transloco | capitaliz }}</mat-option>
            <mat-optgroup *ngFor="let response of listSearch$ | async | keyvalue" [label]="t(response.key + '.label') | capitaliz">
                <mat-option *ngFor="let result of response.value" [value]="result.searchable" (click)="selectItem(response.key, result)">
                    <mat-checkbox [checked]="valuesSet.has(response.key + '-'+ result.searchable.id)" (click)="$event.preventDefault()">
                        <ng-container *ngIf="response.key === 'issues' || response.key === 'permits'">
                            {{ result.searchable.name }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'requirements'">
                            {{ result.searchable.title }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'complaints'">
                            {{ result.searchable.text | truncatie:100 }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'contents'">
                            {{ t('middel.label') | capitaliz }} {{ result.searchable.type }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'contactmoments'">
                            {{ (result.searchable.title || t('logs.label')) | capitaliz }} - {{ result.searchable.contact_moment_timestamp | formatdate: 'dd MMM yyyy' : globals.timezone }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'stakeholders'">
                            {{ result.searchable.name }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'contactpersons'">
                            {{ result.searchable.first_name }} {{ result.searchable.last_name }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'activities'">
                            {{ result.searchable.name }}
                        </ng-container>
                        <ng-container *ngIf="response.key === 'tasks'">
                            {{ result.searchable.text }}
                        </ng-container>
                    </mat-checkbox>
                </mat-option>
            </mat-optgroup>
        </mat-autocomplete>
    </div>
</form>
