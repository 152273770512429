import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import posthog from 'posthog-js';
import { Subscription, forkJoin, of } from "rxjs";
import { Complaint } from "src/app/akita/complaints/state/complaint.model";
import { Log } from "src/app/akita/contact-moments/state/log.model";
import { Issue } from "src/app/akita/issues/state/issue.model";
import { ProjectConfigsService } from "src/app/akita/project-configs/state/project-configs.service";
import { Decision } from "src/app/akita/requirements/state/decision.model";
import { Stakeholder } from "src/app/akita/stakeholders/state/stakeholder.model";
import { Action } from "src/app/akita/tasks/state/action.model";
import { LogsService } from "src/app/core/service/api/logs.service";
import { CachedService } from "src/app/core/service/common/cached.service";
import { deleteConfirmationResponce } from "src/app/core/util/deepCopy";
import { showFeedbackSaved } from "src/app/core/util/notification";
import { DeletionModalComponent } from "src/app/shared/components/modal/deletion-modal/deletion-modal.component";
import { PubsubService } from "../../../../../core/service/api/pubsub.service";
import { StakeholdersService } from "../../../../../core/service/api/stakeholders.service";
import { ParentTabbedPage } from "../../../../../core/util/abstract/parent-tabbed-page";
import { googleMapAddressParse } from "../../../../../core/util/googleMapAddressParse";
import { Globals } from "../../../../../globals";
import { Interest } from "src/app/akita/interests/state/interest.model";
import { MapService } from "src/app/core/service/api/map.service";
declare const google: any;

@Component({
  selector: "app-stakeholders-detail",
  templateUrl: "./stakeholders-detail.component.html",
  styleUrls: ["./stakeholders-detail.component.sass"],
})
export class StakeholdersDetailComponent extends ParentTabbedPage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('autocompletedetail', { static: false }) autocompleteInput: ElementRef<HTMLInputElement>;
  private autocomplete: any;
  private observer: MutationObserver;
  private initialized = false;

  pageTitle = "Stakeholder";
  pageSubTitle = "Terug naar overzicht";
  backToOverview = true;

  private tabSubscription: Subscription;

  stakeholder: Stakeholder;
  name: string;
  public summary = {
    tasks: 0,
    contactmoments: 0,
    requirements: 0,
    issues: 0,
    complaints: 0,
    contents: 0,
  };
  logs: Log[] = [];
  decisions: Decision[] = [];
  issues: Issue[] = [];
  actions: Action[] = [];
  complaints: Complaint[] = [];
  entities: any;
  loaded: Boolean = false;
  reducer: any = true;
  public users: any[];
  protected googleMapOptions = {
    types: [],
  };
  protected enableManualAddress = false;

  constructor(
    public pubsub: PubsubService,
    public globals: Globals,
    private route: ActivatedRoute,
    private _stakeholdersService: StakeholdersService,
    private _logsService: LogsService,
    private cachedService: CachedService,
    public router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<StakeholdersDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Stakeholder,
    public _projectConfigsService: ProjectConfigsService,
    public cdRef: ChangeDetectorRef,
    private _mapService: MapService
  ) {
    super(pubsub, globals, cdRef);
  }

  ngOnInit(): void {
    if (this.data?.name) {
      this.name = this.data.name;
    } else {
      this.name = this.data.dataLabels?.format;
    }
    this.cachedService.getUsers().subscribe(
      (users) => {
        this.users = users;
      }
    );
    this._stakeholdersService
      .getStakeholderSummary(this.data.code, true)
      .subscribe((data: any) => {
        this._mapService.setData(data.locations);
        if (data) {
          this.showSummaryData(data);
          // super.init();
          this.tabSubscription = this.pubsub.stakeholderTabCounter.subscribe(
            () => {
              this._stakeholdersService
                .getStakeholderSummary(this.stakeholder.code)
                .subscribe();
            }
          );
        }
      });
    forkJoin([
      this.cachedService.getActionsRelations(),
      this.cachedService.getIssuesRelations(),
      this.cachedService.getDecisionsRelations(),
      this.cachedService.getLogsRelations(),
      this.cachedService.getComplaintsRelations(),
      this.cachedService.getDocumentsRelations(),
      this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
          ? this.cachedService.getPermitsRelations() 
          : of([])
    ]).subscribe(([tasks, issues, requirements, contactmoments, complaints, documents, permits]) => {
      this.entities = { tasks, issues, requirements, complaints, contactmoments: contactmoments,permits: permits, documents: documents };
      this.loaded = true;
      this.relaticsValidation();
    });
    posthog.capture('stakeholder-detail');
  }

  ngAfterViewInit(): void {
    this.checkAndInitializeAutocomplete();
  }

  ngAfterViewChecked(): void {
    // Continuously check if the element has become available
    this.checkAndInitializeAutocomplete();
  }

  private checkAndInitializeAutocomplete(): void {
    if (!this.initialized && this.autocompleteInput) {
      this.initializeAutocomplete();
      this.initialized = true; // Prevent re-initialization
    }
  }

  private initializeAutocomplete(): void {
    if (this.autocompleteInput) {
      this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.nativeElement, {
        types: ['address'], // Customize the types as needed
      });

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        this.handleAddressChange(place);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  protected handleAddressChange(event): void {
    if (!event.name) return
    const googleAddress = googleMapAddressParse(event);
    this.stakeholder.address = googleAddress.address;
    this.stakeholder.postcode = googleAddress.postcode;
    this.stakeholder.city = googleAddress.city;
    if (googleAddress.latitude && googleAddress.longitude) {
      this.stakeholder.locations = {
        points: {
          type: "MultiPoint",
          coordinates: [
            [
              parseFloat(googleAddress.longitude),
              parseFloat(googleAddress.latitude),
            ],
          ],
        },
        locatable_type: "stakeholders",
      };
    }

    this.updateStakeholder();
  }
  private showSummaryData(stakeholder: any): void {
    this.pageTitle = stakeholder.name;
    this.stakeholder = stakeholder;
    this.summary = {
      tasks: stakeholder.tasks_count,
      contactmoments: stakeholder.contactmoments_count,
      requirements: stakeholder.requirements_count,
      issues: stakeholder.issues_count,
      complaints: stakeholder.complaints_count,
      contents: this.summary.contents,
    };
    this.stakeholder.complaints = this.stakeholder.complaints.map(
      (complaint) => {
        complaint.final_logs_description = complaint.final_logs
          ? complaint.final_logs.description
          : "";
        return complaint;
      }
    );

    this._stakeholdersService
      .getMiddelCount(stakeholder.code)
      .subscribe((data: any) => (this.summary.contents = data.middel_count));
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  updateEntities(event: any) {
    console.log(event);
    this.stakeholder[event.type] = event.selected;
    this.updateStakeholder();
  }
  protected updateAddress() {
    this.stakeholder.locations = null;
    this.updateStakeholder();
  }

  public updateContactpersons(event: any) {
    console.log('contactpersons:', event)
    this.stakeholder.contactpersons = event;
    this.updateStakeholder();
  }
  protected updateStakeholder(showSavedFeedback = true) {
    this._stakeholdersService
      .updateStakeholder(this.stakeholder.code, this.stakeholder)
      .subscribe((res: any) => {
        this.stakeholder.locations = res.locations;
        if (this._mapService.circleVectorSource && this._mapService.circleVectorSource.getFeatures().length > 0) {
          this._mapService.circleVectorSource.removeFeature(this._mapService.searchFeature);
        }
        if (showSavedFeedback) {
          showFeedbackSaved();
        }
      });
  }
  public updateCoordinate() {
    this.updateStakeholder();
  }
  protected deleteStakeholder(stakeholder) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(stakeholder, this.router, 'stakeholders'),
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._stakeholdersService
          .removeStakeholders(stakeholder)
          .subscribe(() => {
            this.close();
          });
      }
    });
  }
  public relaticsValidation() {
    if (this.stakeholder && this.stakeholder.relatics_uuid) {
      if (this.globals.relaticsColumn.stakeholders) {
        let columns;
        const dialogcolumns = [];
        // eslint-disable-next-line prefer-const
        columns = this.globals.relaticsColumn.stakeholders.columns;
        columns.map((element) => {
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer = dialogcolumns.reduce((ac, a) => ({ ...ac, [a]: a }), {});
        this.cd.detectChanges();
      }
    }
  }
  /*
    On Select Interests
  */
  public onSelectInterests(interests: Interest[]) {
    this.stakeholder.interests = interests;
    this.updateStakeholder();
  }
}
