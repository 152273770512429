import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth/auth.service';

@Component({
  selector: 'app-password-logout',
  templateUrl: './password-logout.component.html',
  styleUrls: ['./password-logout.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordLogoutComponent {

  // eslint-disable-next-line no-empty-function
  constructor(private _authService: AuthService) { }

  logout(): void {
    this._authService.logout()
  }
}
