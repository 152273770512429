<ng-container>
  <section>
    <div class="row d-flex j-space-between">
      <div class="w-100 mr-10">
        <mat-form-field appearance="outline" class="mat-mdc-form-field w-100 custome-padding" style="border-color: blue;">
          <mat-icon matPrefix class="uil uil-search unicon-icon"></mat-icon>
          <input matInput [(ngModel)]="keyword" placeholder="{{'text.search_table' | transloco}}" value="" (keyup)="applyFilter($event.target.value)">
          <mat-icon class="d-cursor-hand uil uil-multiply unicon-icon" matSuffix *ngIf="keyword" (click)="onCancel()"></mat-icon>
        </mat-form-field>
      </div>
      <div class="" *ngIf="type === 'complaints' || type === 'requirements' || type === 'tasks' || type === 'issues'">
        <mat-form-field class="pull-right custome-padding custome-padding-select" appearance="outline">
          <mat-select id="mat-select-filter" [(ngModel)]="selectFilter" (ngModelChange)="onFilter()">
            <mat-option class="mat-filter-option" [value]="'all'">{{'status.all' | transloco}}</mat-option>
            <mat-option class="mat-filter-option" *ngFor="let item of filters[type]" [value]="item.filter">{{'status.'+item.filter | transloco  | capitaliz}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="no-padding overview-margin-bottom-space col-xs-12 text-left box-shadow">
      <div class="table-responsive">
        <table mat-table [dataSource]="entities" class="set-border-firefox-table overview-filter-widget-overflow" matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="id" *ngIf="displayedColumns.includes('id')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">{{'ID' | transloco | capitaliz}}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{entity.id}}
            </td>
          </ng-container>
          <ng-container matColumnDef="date" *ngIf="displayedColumns.includes('date')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-2 col-xs-2 col-lg-2">
              <div class="display-flex-set">{{'date' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-2 col-xs-2 col-lg-2">
              {{ type === 'contactmoments' ? (entity.contact_moment_timestamp | formatdate: 'dd-MM-yyyy' : globals.timezone) : (entity.date | formatdate: 'dd-MM-yyyy' : globals.timezone) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="started_at" *ngIf="displayedColumns.includes('started_at')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-2 col-xs-2 col-lg-2">
              <div class="display-flex-set">{{'date' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-2 col-xs-2 col-lg-2">
              {{entity.started_at ? entity.started_at : entity.start  | formatdate: 'dd-MM-yyyy' : globals.timezone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="handled" *ngIf="displayedColumns.includes('handled')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-1 col-xs-1 col-lg-1">
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              <app-tick-checkbox [(ngModel)]="entity.handled" [name]="'handle-' + entity.id" [matTooltip]="(entity.handled ? ('actions.set_recovered' | transloco) : ('actions.set_resolved' | transloco)) | capitaliz" (ngModelChange)="handleAction(entity)" matListIcon>
              </app-tick-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="deadline" *ngIf="displayedColumns.includes('deadline')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-2 col-xs-2 col-lg-2">
              <div class="display-flex-set">{{'time.deadline' | transloco }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-2 col-xs-2 col-lg-2 decision-status time">
              <span [class]="'deadline badge '+actionClass(entity)">{{entity.timestamp | formatdate: 'dd-MM-yyyy' : globals.timezone }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="name" *ngIf="displayedColumns.includes('name')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-5 col-xs-5 col-lg-5">
              <div class="display-flex-set">{{type+'.label' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-5 col-xs-5 col-lg-5" (click)="nav({item:entity,itemType:type,itemName:entity.metadata ? entity.metadata.type : entity.type ? entity.type : entity.title ? entity.title : entity.name ? entity.name : entity.text})">
              <a [innerHtml]="entity.metadata ? entity.metadata.type : entity.type ? entity.type : entity.title ? entity.title : entity.name ? entity.name : entity.text | truncatie: 30"></a>
              <!-- <a *ngIf="entity.text" [innerHtml]="entity.text"></a> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="layers" *ngIf="displayedColumns.includes('layers')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-7 col-xs-7 col-lg-7">
              <div class="display-flex-set">{{'maps.relation_item' | transloco }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-7 col-xs-7 col-lg-7 d-cursor-hand">
              <div *ngIf="!entity.isAuthorized; else authorized">
                  <a (click)="nav({item:entity,itemType:type,itemName:entity.title})" >{{ entity.title | truncatie: 30 }}</a>
              </div>
              <ng-template #authorized>
                <div [matTooltip]="isExist ? '' : 'projects.arcGis_not_assigned' | transloco | capitaliz" [ngClass]="isExist ? '' : 'disableClick'">
                    <a>{{ entity.title | truncatie: 30 }}</a>
              </div>
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="layer_name" *ngIf="displayedColumns.includes('layer_name')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-6 col-xs-6 col-lg-6">
              <div class="display-flex-set">{{'maps.layer_name' | transloco }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-6 col-xs-6 col-lg-6 d-cursor-hand">
              <div *ngIf="!entity.isAuthorized; else authorized">
                  <span [matTooltip]="entity.layer_name | camelCaseToWords">{{ entity.layer_name | camelCaseToWords | truncatie: 30 }}</span>
              </div>
              <ng-template #authorized>
                <div [matTooltip]="isExist ? '' : 'projects.arcGis_not_assigned' | transloco | capitaliz" [ngClass]="isExist ? '' : 'disableClick'">
                    <span [matTooltip]="entity.layer_name | camelCaseToWords">{{ entity.layer_name | camelCaseToWords | truncatie: 30 }}</span>
              </div>
            </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="layer_item_description" *ngIf="displayedColumns.includes('layer_item_description')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-6 col-xs-6 col-lg-6">
              <div class="display-flex-set">{{'logs.description' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-6 col-xs-6 col-lg-6 d-cursor-hand">
              <div>
                  <span [matTooltip]="entity.description? entity.description : '' | camelCaseToWords">{{ entity.description? entity.description : '' | camelCaseToWords | truncatie: 30 }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="type" *ngIf="displayedColumns.includes('type')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{'logs.type' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3">
              <mat-icon class="d-text-middle item-icon" *ngIf="entity.contact_moment_type === 'email'" matTooltip="{{'logs.email' | transloco}}">email</mat-icon>
              <mat-icon class="d-text-middle item-icon" *ngIf="entity.contact_moment_type === 'phone'" matTooltip="{{'logs.phone' | transloco}}">phone</mat-icon>
              <mat-icon class="d-text-middle item-icon" *ngIf="entity.contact_moment_type === 'meeting'" matTooltip="{{'logs.meeting' | transloco}}">group</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="description" *ngIf="displayedColumns.includes('description')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-7 col-xs-7 col-lg-7">
              <div class="display-flex-set">{{'logs.description' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-7 col-xs-7 col-lg-7" (click)="nav({item:entity,itemType:type,itemName:entity.name})">
                <a [ngClass]="entity.subproject_exist?'':'not-in-subproject'" class="d-cursor-hand">
                    {{ entity.title ? ': ' + entity.title : '(Contactmoment zonder titel)' }}
                </a>
                <div class="log-stakeholders-row-div">
                    <span class="line-height-2em">
                        <ng-container class="log-stakeholders-div-ellipsis" *ngIf="entity.stakeholders && entity.stakeholders.length > 0">
                            <span *ngFor="let stakeholder of entity.stakeholders; let first = first; let last = last; trackBy: trackByFn" (click)="$event.stopPropagation()">
                              <ng-container *ngIf="last && entity.stakeholders.length > 1"> en </ng-container>
                              <ng-container *ngIf="!first && !last">, </ng-container>
                              {{ stakeholder.name }}
                              <ng-container *ngIf="last">.</ng-container>
                            </span>
                        </ng-container>
                    </span>
                </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="urgency" *ngIf="displayedColumns.includes('urgency')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{type+'.urgency' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3 urgency">
              <span *ngIf="entity.issues_status == 'open'" [class]="entity.urgency + ' badge'">{{ 'issues.'+entity.urgency | transloco }}</span>
              <span *ngIf="entity.issues_status == 'gesloten'" [class]="'gesloten badge'">{{ 'issues.gesloten' | transloco }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="group" *ngIf="displayedColumns.includes('group')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{'middel.target' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3">
              {{ entity.metadata.group | truncatie: 20 }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status" *ngIf="displayedColumns.includes('status')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{type+'.status' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3" [class]="type === 'complaints' ? '' : 'decision-status' ">
              <span [class]="type === 'complaints' ? '' : entity.status + ' badge'">{{ type === 'complaints' ? entity.is_closed ? ('status.'+entity.is_closed | transloco) : ('status.0' | transloco) : ('status.'+entity.status | transloco) }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="permit_status" *ngIf="displayedColumns.includes('permit_status')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{'status' | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3" [class]="type === 'complaints' ? '' : 'decision-status' ">
              <span [class]="type === 'complaints' ? '' : entity.status + ' badge'">{{ type === 'complaints' ? entity.is_closed ? ('status.'+entity.is_closed | transloco) : ('status.0' | transloco) : ('status.'+entity.status | transloco) }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="contents_status" *ngIf="displayedColumns.includes('contents_status')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{'middel.status' | transloco | capitaliz }}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3 middel-status">
              <p [ngClass]="entity.is_sent ? 'Verzonden' : 'TeVerzonden' ">{{ entity.is_sent ? 'Verzonden' : 'Te verzenden' }}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="contact_person" *ngIf="displayedColumns.includes('contact_person')">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-3 col-xs-3 col-lg-3">
              <div class="display-flex-set">{{'task_owner' | transloco}}</div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-3 col-xs-3 col-lg-3">
              {{showContactPersonsName(entity)}}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set"></div>
            </th>
            <td mat-cell *matCellDef="let entity; let i = index" class="col-sm-1 col-xs-1 col-lg-1">
              <a *ngIf="type === 'contactmoments'" class="d-cursor-hand col-xs-1 mr-15 delete-button" (click)="downloadLog(entity.id, $event)"><mat-icon matTooltip="{{'report.contact_moment_notes' | transloco}}" class="contacts-card-edit ml-10 d-cursor-hand">text_snippet</mat-icon></a>
              <a *ngIf="entity?.contactpersons_contactmoments?.length" class="d-cursor-hand col-xs-1 mr-15 delete-button" [ngClass]="type === 'tasks' && showContactPersonsName(entity)" (click)="onEdit(entity,i)"><mat-icon class="contacts-card-edit ml-10 d-cursor-hand">edit</mat-icon></a>
              <a class="delete-button pull-right" [ngClass]="type === 'layers' ? isExist ? '' : 'disableClick' : '' " (click)="onDelete({item:entity,itemType:type,index:i,event:$event})"><mat-icon [matTooltip]="'text.delete.connection' | transloco" [ngClass]="type === 'layers' ? isExist ? '' : 'geray-set-delet' : ''" class="delete-button uil uil-trash unicon-icon"></mat-icon></a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="clickable-row delete-on-hover-tr"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable-row delete-on-hover-tr d-cursor-hand"></tr>
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="12">{{'reusable.no' | transloco | capitaliz}} {{type+'.labels' | transloco}} {{'reusable.found' | transloco}}</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</ng-container>
