import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmailData, EmailParser } from '../../../core/util/email/EmailParser';
import { showFeedback } from '../../../core/util/notification';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { AuthConstants } from '../../../auth-constants';
import { Globals } from '../../../globals';
import { TranslocoService, translate } from '@jsverse/transloco';
import { GlobalConfig } from '../../../core/util/config/global-config';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-log-dropzone',
  templateUrl: './email-log-dropzone.component.html',
  styleUrls: ['./email-log-dropzone.component.sass']
})
export class EmailLogDropzoneComponent implements OnInit {

  private mailParsingError: boolean = false;
  setDefaultHeader: any;
  @Input() text = false;
  private uploadedFile: {
    document: any, email: EmailData
  } = {
      document: null,
      email: null,
    };
  public fileSize:string;
  public dropzoneConfig;

  @ViewChild('dropzone') protected dropzone: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFileUploaded: EventEmitter<{
    extraParams?: any,
    extraOptions?: {
      droppedEmails: {
        name: string;
        address: string;
      }[]
    }
  }> = new EventEmitter();

  // eslint-disable-next-line no-empty-function
  constructor(private router: Router, private subProjectQuery: SubprojectQuery,private globals: Globals, private _xsrfTokenExtractor: HttpXsrfTokenExtractor, private authConstant: AuthConstants, private transloco: TranslocoService) { }

  ngOnInit() {
    if(this.router.url.includes('sub')){
      const activeSubProject = this.subProjectQuery.getActive();
      this.setDefaultHeader={
        'X-XSRF-TOKEN': this._xsrfTokenExtractor.getToken(),
        'X-Project': this.globals.projectCode,
        'X-Subproject': activeSubProject.id.toString(),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }else{
      this.setDefaultHeader={
        'X-XSRF-TOKEN': this._xsrfTokenExtractor.getToken(),
        'X-Project': this.globals.projectCode,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    this.dropzoneConfig = {
      url: '/api/documents/upload',
      paramName: 'files[0]',
      headers: this.setDefaultHeader,
      uploadMultiple: false,
      parallelUploads: 1,
      maxFiles: 1,
      acceptedFiles: '.msg,.eml,.ics',
      transformFile: (file, done) => {
        this.uploadedFile = {
          document: null,
          email: null,
        };

        // Parse the file
        try {
          const parser = new EmailParser(file);
          console.log('Starting to parse the email', file);
          parser.parse((data: EmailData) => {
            console.log('Parsed email', file);
            this.uploadedFile.email = data;
            this.uploadedFile.email.content = this.uploadedFile.email.content.replace(/(?:\r\n|\r|\n)/g, '<br>');
          });
        } catch (e) {
          this.mailParsingError = true;
          console.error('Mail parsing error', e);
          showFeedback('Mail parsing error', 'error', JSON.stringify(e), true);
        }

        done(file);
      },
      success: (file: File, document: any[], event: ProgressEvent) => {
        if (document && document.length > 0) {
          this.uploadedFile.document = document[0];
        }

        if (this.mailParsingError) {
          showFeedback(this.transloco.translate('error.email_cant_be_opened'), 'error', JSON.stringify({
            message: 'Unable to parse email file',
            file_id: this.uploadedFile.document.id
          }));
        }
        this.addLogDropzone();
      },
      error: (file: File, error: { message: string, errors: any }, xhr: XMLHttpRequest) => {
        let msg = error.message || error;
        if (msg === GlobalConfig.DEFAULT_DROPZONE_CONFIG.dictInvalidFileType) {
          msg = this.transloco.translate('error.email_file_invalid');
        }
        showFeedback(msg, 'error', JSON.stringify(error.errors), false);
        this.dropzone.directiveRef.instance.removeAllFiles();
      },
      previewsContainer: '.dropzone-previews' // Don't display preview by displaying it in a hidden element
    };
  }

  private addLogDropzone(retry: boolean = false): void {
    if (!this.uploadedFile.email) {
      if (!retry) {
        // It's possible that the reading of the file is very slow.
        setTimeout(() => this.addLogDropzone(true), 5000)
        return;
      }

      this.dropzone.directiveRef.instance.removeAllFiles();
      showFeedback(
        this.transloco.translate('error.email_corrupted'),
        'error',
        `Possible file corrupt in drag and drop. Check document ${this.uploadedFile.document.id} project ${this.globals.projectCode}`);
      return;
    }

    this.onFileUploaded.emit({
      extraParams: {
        contact_moment_type: this.uploadedFile.email.filetype === 'ics' ? 'meeting' : 'email',
        contact_moment_timestamp: this.uploadedFile.email.date || new Date(),
        title: this.uploadedFile.email.title,
        description: this.uploadedFile.email.content || this.uploadedFile.email.title,
        documents: this.uploadedFile.document ? [this.uploadedFile.document] : [],
      },
      extraOptions: {
        droppedEmails: this.uploadedFile.email.emails
      },
    });

    if (this.dropzone) {
      this.dropzone.directiveRef.instance.removeAllFiles();
    }
  }
  public onUploadDropzoneError(event) {
    let message = 'Error tijdens uploaden van document';
    if (event && event[1] && event[1].message) {
      message = event[1].message;
    }
    //set 25 MB logic
    this.fileSize = (event[0].size / (1024 * 1024)).toString();
    this.fileSize=parseInt(this.fileSize).toFixed(0);
    if(Number(this.fileSize) >= 25){
      message=translate('documents.uploading_limit');
    }
    showFeedback(message, 'error', null, false);
  }
}
