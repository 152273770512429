import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { AbstractService } from '../common/abstract.service';
import { PaginatedResponse } from '../common/paginated-response';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
   }

  @ServiceErrorHandler()
  public getWorkflows() {
    return this.http.get('/api/workflows?count=10000&with=tasks');
  }

  @ServiceErrorHandler()
  public submitWorkflow(workflow: any): Observable<any> {
    return this.http.post('/api/workflows', workflow, this.httpOptions);
  }

  @ServiceErrorHandler()
  getAllWorksflows(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/workflows?dropdown=1`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getWorkflow(id: number): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/workflows/${id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  deleteWorkflow(id: any): Observable<any> {
    return this.http.delete(`/api/workflows/${id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public replicateWorkflow(id: any, entity: any): Observable<any> {
    return this.http.post(`/api/workflows/${id}/replicate`,entity, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getDependents(id: number): Observable<any> {
    return this.http.get(`/api/actions/${id}/dependents`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public batchApprove(tasks): Observable<any> {
    return this.http.post(`/api/batch/update/actions/`, tasks, this.httpOptions);
  }

  @ServiceErrorHandler()
  public updateWorkflow(id, workflow): Observable<any> {
    return this.http.put(`/api/workflows/${id}`,workflow, this.httpOptions );
  }
}
