import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenericComponent} from '../../../../core/util/abstract/generic-component';
import {StakeholdersService} from '../../../../core/service/api/stakeholders.service';
import {deepCopy} from '../../../../core/util/deepCopy';
import {PaginationParams} from '../../../../core/service/common/pagination-params';
import {PaginatedResponse} from '../../../../core/service/common/paginated-response';
import {forkJoin} from 'rxjs';
import {showFeedbackSaved} from '../../../../core/util/notification';
import {Location} from '../../../../akita/locations/state/location.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-stakeholder-new-map',
  templateUrl: './stakeholder-new-map.component.html',
  styleUrls: ['./stakeholder-new-map.component.sass']
})
export class StakeholderNewMapComponent extends GenericComponent implements OnInit {

  public isProcessing: boolean = false;
  public stakeholders: {
    residents: MatTableDataSource<any>,
    others: MatTableDataSource<any>,
  } = {
    residents: new MatTableDataSource<any>(),
    others: new MatTableDataSource<any>(),
  };

  private defaultOtherType: any;
  private defaultResidentType: any;
  public stakeholderTypes: any[];

  @ViewChild('paginatorResidents') paginatorResidents: MatPaginator;
  @ViewChild('paginatorOthers') paginatorOthers: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) private locations: Location, private dialogRef: MatDialogRef<StakeholderNewMapComponent>,
              private stakeholdersService: StakeholdersService) {
    super();
  }

  ngOnInit() {
    const types$ = this.stakeholdersService.getStakeholderTypes(new PaginationParams());
    const polygons$ = this.stakeholdersService.searchStakeholdersByPolygons(this.locations);
    forkJoin([types$, polygons$]).subscribe(
      (responses: [PaginatedResponse, {residents: any[], others: any[]}]) => {
        this.stakeholderTypes = responses[0].data;

        if (this.stakeholderTypes.length > 0) {
          this.defaultResidentType = this.stakeholderTypes.reverse().filter(t => t.name === 'Omwonenden' || t.name === 'Bewonersverenigingen')[0];
          this.defaultOtherType = this.stakeholderTypes.reverse().filter(t => t.name === 'Ondernemers' || t.name === 'Bedrijfsverenigingen')[0];
          if (!this.defaultResidentType) {
            this.defaultResidentType = this.stakeholderTypes[0];
          }
          if (!this.defaultOtherType) {
            this.defaultOtherType = this.stakeholderTypes[0];
          }
        }
        this.stakeholders.residents.paginator = this.paginatorResidents;
        this.stakeholders.others.paginator = this.paginatorOthers;

        this.stakeholders.residents.data = responses[1].residents.map(
          s => {
            s.name = `${s.street} ${s.number}`;

            if (this.defaultResidentType) {
              s.stakeholdertypes = this.defaultResidentType;
            }

            s.checked = true;
            return s;
          }
        );

        this.stakeholders.others.data = responses[1].others.map(
          s => {
            if (this.defaultOtherType) {
              s.stakeholdertypes = this.defaultOtherType;
            }

            s.checked = true;
            return s;
          }
        );
      }, () => this.dialogRef.close()
    );
  }

  public close(response?: any): void {
    this.dialogRef.close(response);
  }

  public submit(): void {
    this.isProcessing = true;

    let params = this.stakeholders.residents.data.concat(this.stakeholders.others.data)
      .filter(s => s.checked);
    params = deepCopy(params)
      .map(s => {
        if (s.stakeholdertypes) {
          s.stakeholdertypes = [s.stakeholdertypes];
        }
        return s;
    });

    this.stakeholdersService.createMultipleStakeholders(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
      response => {
        showFeedbackSaved();
        this.close(response);
      }
    );
  }

}
