import * as emailAddresses from "email-addresses";
import parse from "emailjs-mime-parser";
import { bugsnagFactory } from "../bugsnagFactory";
import { MSGReader } from '../msgreader';
import { showFeedbackError } from '../notification';
import { uniqueArray } from "../uniqueArray";
import { utf8ArrayToStr } from "../utf8ArrayToStr";

export interface EmailData {
  content: string;
  emails: {
    name: string;
    address: string;
  }[];
  filetype: string;
  title: string;
  date?: Date;
  from?: string;
  to?: string;
  cc?: string;
  location?: string;
}

/**
 * Class that parse msg, eml, and ics files and produce readable objects
 */
export class EmailParser {
  private file: any;
  public emailManager=[];
  public backupArray:[];
  public resolverList=[];
  private data: EmailData;
  safeEmailREcord:any;
  constructor(file) {
    this.file = file;
    this.data = {
      content: '',
      emails: [],
      filetype: '',
      title: ','
    };
  }

  public parse(callback) {
    const fileExtension = this.getFileExtension();
    switch (fileExtension) {
      case 'msg':
        this.parseMsg(callback);
        break;
      case 'ics':
        this.parseIcs(callback);
        break;
      default:
        this.parseEml(callback);
        break;
    }
  }

  private parseMsg(callback): void {
    this.data.filetype = 'msg';
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const msgReader = new MSGReader(<ArrayBuffer>reader.result);
        const result: any = msgReader.getFileData();
        if (result.subject) {
          this.data.title = result.subject;
        }

        if (result.body) {
          this.data.content = result.body;
        }

        this.data.emails = [];
        if (result.headers) {
          const headers: any = this.parseMsgHeader(result.headers);
          if (headers.Date) {
            this.data.date = new Date(headers.Date);
          }

          if (headers.From) {
            const fromHeader = emailAddresses.parseAddressList(headers.From);
            if (fromHeader) {
              const from = fromHeader.map((f: any) => {
                return {
                  name: f.name ? f.name : f.address.toLowerCase(),
                  address: f.address.toLowerCase()
                };
              });

              this.data.emails = uniqueArray(this.data.emails.concat(from), 'address');
            }
          }

          if (headers['Delivered-To']) {
            const toHeader = emailAddresses.parseAddressList(headers['Delivered-To']);
            if (toHeader) {
              const to = toHeader.map((t: any) => {
                return {
                  name: t.name ? t.name : t.address.toLowerCase(),
                  address: t.address.toLowerCase()
                };
              });

              this.data.emails = uniqueArray(this.data.emails.concat(to), 'address');
            }
          }

          if (headers.To) {
            const header=headers.To.replace(">,",">");
            const toHeader = emailAddresses.parseAddressList(header);
            if (toHeader) {
              const to = toHeader.map((t: any) => {
                return {
                  name: t.name ? t.name : t.address.toLowerCase(),
                  address: t.address.toLowerCase()
                };
              });
              this.safeEmailREcord=to[0].address;
              this.data.emails = uniqueArray(this.data.emails.concat(to), 'address');
            }
          }

          if (headers.CC||headers.Cc||headers.cc) {
            let ccHeader;
            if(headers.CC){
              ccHeader = emailAddresses.parseAddressList(headers.CC);
            }
            if(headers.Cc){
              ccHeader = emailAddresses.parseAddressList(headers.Cc);
            }
            if(headers.cc){
              ccHeader = emailAddresses.parseAddressList(headers.cc);
            }
            if (ccHeader) {
              const cc = ccHeader.map((c: any) => {
                return {
                  name: c.name ? c.name : c.address.toLowerCase(),
                  address: c.address.toLowerCase()
                };
              });
              cc.forEach(element=>{
                this.emailManager.push(element);
              })
              this.data.emails = uniqueArray(this.data.emails.concat(cc), 'address');
            }
          }

          if (headers.Bcc||headers.BCC||headers.bcc) {
            let bccHeader;
            if(headers.Bcc){
              bccHeader = emailAddresses.parseAddressList(headers.Bcc);
            }
            if(headers.BCC){
              bccHeader = emailAddresses.parseAddressList(headers.BCC);
            }
            if(headers.bcc){
              bccHeader = emailAddresses.parseAddressList(headers.bcc);
            }
            if (bccHeader) {
              const cc = bccHeader.map((b: any) => {
                return {
                  name: b.name ? b.name : b.address.toLowerCase(),
                  address: b.address.toLowerCase()
                };
              });
              cc.forEach(element=>{
                this.emailManager.push(element);
              })
              this.data.emails = uniqueArray(this.data.emails.concat(cc), 'address');
            }
          }
        }

        if (result.recipients) {
          const recipients = result.recipients.map(item => {
            const name = item.name ? item.name : item.email;
            if (!item.email) {
              if (name.includes('@')) {
                item.email = name.toLowerCase();
              }else{
                item.email=this.emailFinder(item.name);
                if(!item.email){
                  item.email=this.emailFinderManager(item.name);
                }
              }
            } else {
              item.email = item.email.toLowerCase();
            }

            return {
              name: name
                .replace(new RegExp("'", 'g'), '')
                .split('@')[0].replace('.', ' '),
              address: item.email
            };
          });

          this.data.emails = uniqueArray(this.data.emails.concat(recipients), 'address');
        }

        if (result.senderEmail) {
          const sender = {
            name: result.senderName ? result.senderName : result.senderEmail.split('@')[0].replace('.', ' '),
            address: result.senderEmail.toLowerCase()
          };

          this.data.emails = uniqueArray(this.data.emails.concat([sender]), 'address');
        }

        if (this.data.emails.length > 0) {
          this.data.emails = this.data.emails.filter(d => d.address.toLowerCase());
        }

        if (this.data.content) {
          this.fixNewlines();
        } else {
          this.data.content = '';
        }

        callback(this.data);
      } catch (e) {
        console.error('Unable to parse the msg file', e);
        showFeedbackError(e);
      }
    };

    reader.readAsArrayBuffer(this.file);
  }

  private parseEml(callback): void {
    this.data.filetype = 'eml';
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const result = parse(reader.result);
        if (result.headers) {
          let emails = [];
          if (result.headers.date) {
            this.data.date = new Date(result.headers.date[0].value);
          }

          if (result.headers.subject) {
            this.data.title = result.headers.subject[0].value;
          }

          if (result.headers.from) {
            this.data.from = result.headers.from[0].value;
            emails = emails.concat(this.data.from);
          }

          if (result.headers.to) {
            this.data.to = result.headers.to[0].value;
            emails = emails.concat(this.data.to);
          }

          if (result.headers.cc) {
            this.data.cc = result.headers.cc[0].value;
            emails = emails.concat(this.data.cc);
          }

          this.data.emails = uniqueArray(emails, 'address');
          this.data.emails = this.data.emails.map(email => {
            const name = email.name ? email.name : email.address.toLowerCase();
            if (!email.address) {
              if (name.includes('@')) {
                email.address = name.toLowerCase();
              }
            } else {
              email.address = email.address.toLowerCase();
            }

            return {
              name: name.split('@')[0].replace('.', ' '),
              address: email.address
            };
          });
        }

        if (result.childNodes) {
          this.emailContentParcer(result);
        }

        if (this.data.emails.length > 0) {
          const incompleteData = this.data.emails.filter(d => !d.address.toLowerCase()).length > 0;
          if (incompleteData) {
            this.data.emails = [];
          }
        }

        if (this.data.content) {
          this.fixNewlines();
        } else {
          this.data.content = '';
        }

        callback(this.data);
      } catch (e) {
        console.error('Unable to parse the eml file', e);
        showFeedbackError(e);
      }
    };

    reader.readAsText(this.file);
  }

  private parseIcs(callback): void {
    this.data.filetype = 'ics';
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const result = parse(reader.result);

        if (result.headers) {
          const emails = [];
          for (const header in result.headers) {
            if (header.indexOf('dtstart') === 0 && header.indexOf('tzid') > 0 && header.indexOf(';') > 0) {
              const t = result.headers[header][0].value;
              const timestamp = `${t.substring(0, 4) }-${ t.substring(4, 6) }-${ t.substring(6, 11) }:${ t.substring(11, 13)}`;
              this.data.date = new Date(timestamp);
            }

            if (header.indexOf('attendee') === 0) {
              let address = result.headers[header][0].value
                .trim()
                .replace(' ', '')
                .replace('mailto:', '');
              let name = address.toLowerCase();

              // Get the name of attendee
              header.split(';').forEach(x => {
                if (x.trim().indexOf('cn=') === 0) {
                  name = x.replace('cn=', '')
                    .replace(new RegExp('"', 'g'), '');
                }
              });

              if (!address) {
                if (name.includes('@')) {
                  address = name.toLowerCase();
                }
              } else {
                address = address.toLowerCase();
              }

              emails.push({
                name: name.split('@')[0].replace('.', ' '),
                address: address
              });
            }
          }

          this.data.emails = uniqueArray(emails, 'address');

          if (result.headers.description) {
            this.data.content = result.headers.description[0].value;
          }

          if (result.headers.summary) {
            this.data.title = result.headers.summary[0].value;
          }

          if (result.headers.location) {
            this.data.location = result.headers.location[0].value;
          }
        }

        if (this.data.emails.length > 0) {
          const incompleteData = this.data.emails.filter(d => !d.address.toLowerCase()).length > 0;
          if (incompleteData) {
            this.data.emails = [];
          }
        }

        if (this.data.content) {
          this.fixNewlines();
        } else {
          this.data.content = '';
        }

        callback(this.data)
      } catch (e) {
        console.error('Unable to parse the ics file', e);
        showFeedbackError(e);
      }
    };

    reader.readAsText(this.file);
  }

  private getFileExtension(): string {
    const filename = this.file.upload.filename;
    return filename.split('.').pop();
  }

  private parseMsgHeader(headers: string): any {
    const parsedHeaders = {};
    if (!headers) {
      return parsedHeaders;
    }
    const headerRegEx = /(.*)\: (.*)/g;
    const sapratorRegEx=/(	("|<)(.*))|(.*)\: ("|<)(.*)/gi;
    let m;
    while (m = headerRegEx.exec(headers)) {
      // todo: Pay attention! Header can be presented many times (e.g. Received). Handle it, if needed!
      parsedHeaders[m[1]] = m[2];
    }
    const a=headers.match(sapratorRegEx);
    if(a){
      this.backupArray = this.parseCustomeEmail(a)
      this.emailMatcher();
    }
    return parsedHeaders;
  }

  public parseCustomeEmail(a){
    const regx = /^([A-Z])\w+: /g;
    a.forEach((element,key) => {
      if(!element.match(regx)){
        a[key] = element;
      }
    });
    return a;
  }

  private fixNewlines(): void {
    if (typeof this.data.content.replace === 'function') {
      this.data.content = this.data.content.replace(new RegExp('\\\\n', 'g'), '\r\n');
    } else {
      console.error('Email content is not string', this.data.content);
      bugsnagFactory().notify(JSON.stringify({
        message: 'Email content is not string or has no replace function',
        data: this.data.content,
      }));
      this.data.content = '';
    }
  }

  emailMatcher(){
    const name=[];
    const email=[];
    this.backupArray.forEach((element:string,key) => {
      name[key]=element.match(/(\")(.*)(\")/g);
      email[key]=element.match(/(\<)(.*)(\>)/g);
      if(name[key]==null){
        name[key]=[{name:'default'}]
      }
      if(email[key]==null){
        this.resolverList.push({name : "", email: ""});
      }else{
        if(/\\/g.test(name[key][0])){
          name[key][0]=name[key][0].replace(/\\/g, "");
        }
        this.resolverList.push({name : name[key][0].toString().replace(/\"/g, ""), email: email[key][0].toString().replace(/\<|>/g, "")});
      }
     });
  }
  emailFinder(name:string){
    let el;
    // eslint-disable-next-line prefer-const
    let fname, nameArray=[], lname;
    const fullname=name.split(" ");
    fullname.forEach(element => {
      nameArray.push(element.replace(/[^a-zA-Z0-9]/g,''));
    });
    const a=nameArray.length-1;
    // eslint-disable-next-line prefer-const
    fname = nameArray[0].toLocaleLowerCase();
    // eslint-disable-next-line prefer-const
    lname=nameArray[a].toLocaleLowerCase();
  let flag=0;
    this.resolverList.forEach((element)=>{
      if(element.email.toLocaleLowerCase().includes(fname) && flag==0){
        el= element.email;
        flag=1;
      }else{
        if(element.email.toLocaleLowerCase().includes(lname) && flag==0){
          el= element.email;
          flag=1;
        }
      }
    });
    return el;
  }
  emailFinderManager(name:string){
    let el;
    if(this.emailManager.length>0){
      this.emailManager.map((element)=>{
        if(element.name==name){
          el= element.address;
        }else{
          el='';
        }
      });
    }else{
      el='';
    }
    return el;
  }
  emailContentParcer(result){
    result.childNodes.forEach((node) => {
      if (node.contentType) {
        switch (node.contentType.value) {
          case "text/plain":
            this.data.content = utf8ArrayToStr(node.content);
            break;
          case "text/html":
            if(!this.data.content){
              this.data.content = utf8ArrayToStr(node.content);
            }
            break;
          case "multipart/alternative":
            case "multipart/mixed":
              case "multipart/related":
                if(node.childNodes.length){
                  this.emailContentParcer(node)
                }
                break;
        }
      }
    });
  }
}
