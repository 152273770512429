<div mat-dialog-title>
    <div class="row">
        <div class="col-md-9">
            <h4>{{ subProject.name }}
            </h4>
        </div>
        <div class="col-md-3">
            <button type="button" class="close no-margin pull-right" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
                <mat-icon class="d-text-middle">close</mat-icon>
            </span><span class="sr-only">Close</span></button>
            <mat-icon *ngIf="subProject && hasPermission(Permission.SubprojectDelete)" (click)="deleteSubproject(subProject)" class="d-cursor-hand delete pull-right">delete
            </mat-icon>
        </div>
    </div>
</div>

<div *transloco="let t">
    <form #form="ngForm" [formGroup]="formGroup" (submit)="update()">
        <div mat-dialog-content>
            <ng-template matStepLabel>{{ (this.globals.projectConfigs['project.labels.subprojects'] || ( 'subprojects.label-1'| transloco)) +' '+ ('subprojects.area' | transloco) }} </ng-template>
            <mat-form-field class="full-width">
              <mat-label>{{(this.globals.projectConfigs['project.labels.subprojects'] || ( 'subprojects.label-1'| transloco)) +' '+ ('subprojects.name' | transloco)}}</mat-label>
                <input matInput type="text" name="name" formControlName="name" (keyup)="subprojectExists()" required />
            </mat-form-field>
            <span class="message" *ngIf="nameExists">{{'subprojects.nameInUse' | transloco}}</span>

            <app-select-map-features *ngIf="integrationEnable"  [integrationType]="integrationCheck" [featureType]="'arcgis'" [selectedFeature]="subprojectMapFeatures.arcgis" (selectFeatures)="onSelectIntegrationFeautes($event)"></app-select-map-features>
            <app-select-map-features [featureType]="'opendata'" [selectedFeature]="subprojectMapFeatures.opendata" (selectFeatures)="onSelectOpenDataFeautes($event)"></app-select-map-features>

            <!-- Users  -->
            <div *ngIf="!isLoader">
                <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
            <div *ngIf="isLoader">
                <div *ngIf="subProject.users.length>0">
                    <label class="material-style-label text-capitalize">{{ 'users.labels' | transloco }}</label>
                    <table class="table" *ngIf="isLoader">
                        <tbody class="roles-text-end">
                            <tr *ngFor="let item of subprojectUsers let i=index">
                                <span class="roles-header">{{item.name}}</span>
                                <td class="roles-header">
                                    <app-generic-multiselect-checkbox [placeholder]="'users.access_fields' | transloco | capitaliz" [chipDisabler]="chipDisabler" [ngModelOptions]="{standalone: true}" [name]="item.name" [(ngModel)]="selectedSubprojectRoles[i]" (ngModelChange)="subprojectRolesSelect()">
                                        <app-generic-multiselect-checkbox-option *ngFor="let role of subprojectRoles" [value]="role">
                                            {{ role.name }}
                                        </app-generic-multiselect-checkbox-option>
                                    </app-generic-multiselect-checkbox>
                                    <mat-hint *ngIf="chipDisabler.length>0">{{ 'users.Unable_to_deselect' | transloco }} <span *ngFor="let item of chipDisabler"><span *ngIf="item!=='Project Admin'">"{{item}}" </span></span>
                                    </mat-hint>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <label class="material-style-label text-capitalize">
    {{ t('area') }}
    </label>
            <div class="map-container">
                <app-openlayers-map [addFeature]="selectedFeatures" [featureSelectType]="featureSelectedType" [typeAction]="'edit'" [actionSelect]="selectType" formControlName="locations" name="locations" [includeSidebar]="false" [defaultCoordinate]="globals.projectConfig.default_map" [showMapReset]="true" [showPinTooltip]="false" [singleEntityType]="true" locatableType="subprojects" defaultDrawTool="area"
                    clickStartDrawingMsg="Teken subprojectgebied" required>
                </app-openlayers-map>
            </div>
            <ng-template matStepLabel>{{ 'subprojects.import-data' | transloco | capitaliz }}</ng-template>
        </div>
        <div mat-dialog-actions class="pull-right">
            <button type="submit" mat-flat-button class="text-capitalize pull-right mt-10 ml-10" [disabled]="processing$ | async">{{ t('save') }}</button>
            <button type="button" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="close()" [disabled]="processing$ | async">{{ t('close') }}</button>
        </div>
    </form>
</div>
