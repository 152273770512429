import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-project-created-modal',
  templateUrl: './project-created-modal.component.html',
  styleUrls: ['./project-created-modal.component.sass']
})
export class ProjectCreatedModalComponent {

  constructor(public dialogRef: MatDialogRef<ProjectCreatedModalComponent>,
  // eslint-disable-next-line no-empty-function
  @Inject(MAT_DIALOG_DATA) public data:any) {
  }

  close() {
    this.dialogRef.close();
  }


}
