import moment from 'moment';

export function getIssues(entity: any): any {
  let issues = [];
  if (entity.issues) {
    issues = entity.issues.filter(x => x.issues_status == "open" && (x.urgency == "high" || x.urgency == "very-high"));

  }
  return issues
}

export function getDecisions(entity: any): any {
  let decisions = [];
  if (entity.decisions) {
    decisions = entity.decisions.filter(x => x.status == "initial");
  }
  return decisions
}

export function getComplaints(entity: any): any {
  let complaints = [];
  if (entity.complaints) {
    complaints = entity.complaints.filter(x => !x.is_closed);
  }
  return complaints
}

export function getMiddels(entity: any): any {
  let middels = [];
  if (entity.middels) {
    middels = entity.middels.filter((x) => {
      const date = x.started_at || x.sent_date;
      if (moment(date).isSame(new Date(), 'week') && !x.is_sent) {
        return true
      }

        return false;

    });
  }
  return middels
}

export function getActions(entity: any): any {
  let actions = [];
  if (entity.actions) {
    actions = entity.actions.filter(x => !x.handled);
  }
  return actions
}

export function countRelations(entity: any): any {
  entity.issues_count = getIssues(entity).length;
  entity.decisions_count = getDecisions(entity).length;
  entity.actions_count = getActions(entity).length;
  entity.complaints_count = getComplaints(entity).length;
  entity.middels_count = Number.isInteger(entity.middels) ? entity.middels : getMiddels(entity).length;

  const { issues_count, decisions_count, actions_count, complaints_count, middels_count } = entity;
  entity.relationsTotal = issues_count + decisions_count + actions_count +
    complaints_count + middels_count

  entity.entitiesCount = 0;
  if (issues_count) { entity.entitiesCount += 1; entity.relationIcon = 'business' }
  if (decisions_count) { entity.entitiesCount += 1; entity.relationIcon = 'gavel' }
  if (actions_count) { entity.entitiesCount += 1; entity.relationIcon = 'assignment' }
  if (complaints_count) { entity.entitiesCount += 1; entity.relationIcon = 'message' }
  if (middels_count) { entity.entitiesCount += 1; entity.relationIcon = 'campaign' }

}

