<form #form="ngForm" (ngSubmit)="submit()">
  <div mat-dialog-title>
    <h4 class="mb-10 inline-block">Nieuwe omwonende</h4>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">
        <mat-icon class="d-text-middle">close</mat-icon>
      </span>
      <span class="sr-only">
        Close
      </span>
    </button>
  </div>

  <!-- modal body -->
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field class="full-width">
          <input matInput type="text" placeholder="Naam" name="name" [(ngModel)]="entity.name" required>
        </mat-form-field>
      </div>
      <div>
        <international-phone-number [(ngModel)]="entity.phone" placeholder="Telefoonnummer" [maxlength]="20" defaultCountry="nl" name="phone_number"></international-phone-number>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input matInput type="text" name="email" placeholder="Email adres" [(ngModel)]="entity.email" email>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-select matInput [(value)]="entity.type" placeholder="Type">
            <mat-option value="Omwonende">Omwonende</mat-option>
            <mat-option value="Bedrijf">Bedrijf</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <h4 class="mb-15">Contactgegevens</h4>
      <div class="form-group">
        <label class="control-label" for="google-address">{{'reusable.search_address' | transloco}}</label>
        <div>
          <input *ngIf="globals.googleMapLibLoaded" type="text" class="form-control" id="google-address" name="google-address" ngx-google-places-autocomplete
                 [options]="googleMapOptions" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
        </div>
      </div>
      <div class="form-group">
        <label class="control-label" for="address">Adres</label>
        <mat-icon *ngIf="entity.found_coordinates !== 'found'" class="warning-address-icon" matTooltip="GPS coördinaten onbekend door handmatig ingevuld adres">warning</mat-icon>
        <mat-slide-toggle class="pull-right manual-address-toggle" [(ngModel)]="enableManualAddress" name="manualAddress" matTooltip="Handmatige invoer in-/uitschakelen"></mat-slide-toggle>
        <div>
          <input type="text" class="form-control" id="address" name="address" [(ngModel)]="entity.address" (change)="updateAddress()" [disabled]="!enableManualAddress">
        </div>
      </div>
      <div class="form-group">
        <label class="control-label" for="postcode">{{'maps.addr:postcode' | transloco}}</label>
        <div>
          <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="entity.postcode" (change)="updateAddress()" [disabled]="!enableManualAddress">
        </div>
      </div>
      <div class="form-group">
        <label class="control-label" for="city">Plaats</label>
        <div>
          <input type="text" class="form-control" id="city" name="city" [(ngModel)]="entity.city" (change)="updateAddress()" [disabled]="!enableManualAddress">
        </div>
      </div>
    </div>
  </mat-dialog-content><!--end of modal body -->

  <mat-dialog-actions align="end">
    <div class="dialog-button-row">
      <button type="button" mat-stroked-button data-dismiss="modal" [disabled]="isProcessing" (click)="close()">{{'close' | transloco | capitaliz}}</button>
      <button type="submit" mat-flat-button [disabled]="isProcessing || form.invalid" >
        <span *ngIf="!isProcessing">
          <mat-icon class="material-icons d-text-middle">save</mat-icon>
          {{ 'add' | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
      </button>
    </div>
  </mat-dialog-actions><!--end of modal footer -->

</form>
