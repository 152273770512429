<section *transloco="let t">
  <div class="row">
    <div class="col-md-12 mt-10">
      <h5 class="text-black">
        {{ "settings.personal.individual_header" | transloco }}
      </h5>
      <mat-hint>{{ "settings.personal.individual_info" | transloco }}</mat-hint>
    </div>
  </div>
  <div class="row">
    <mat-progress-bar *ngIf="!onLoad" mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="row" *ngIf="onLoad">
    <div class="col-md-12 mt-10">
      <table class="mat-table">
        <thead class="mat-header-row">
          <tr class="mat-row">
            <th scope="col" class="text-center"></th>
            <th scope="col" class="text-center">
              {{ "settings.personal.daily_mail" | transloco }}
            </th>
            <th scope="col" class="text-center">
              {{ "settings.personal.weekly_mail" | transloco }}
            </th>
            <th scope="col" class="text-center">
              {{ "settings.personal.item_mail" | transloco }}
            </th>
            <th scope="col" class="text-center">
              {{ "settings.personal.status_mail" | transloco }}
            </th>
            <th scope="col" class="text-center">
              {{ "settings.personal.tagged_mail" | transloco }}
            </th>
            <th scope="col" class="text-center">
              {{ "settings.personal.update_mail" | transloco }}
            </th>
            <th scope="col" class="text-center" *ngIf="integration.bowapp">
              {{ "settings.personal.bouwApp_mail" | transloco }}
            </th>
            <th scope="col" class="text-center" *ngIf="integration.freshdesk">
              {{ "settings.personal.freshdesk_mail" | transloco }}
            </th>
          </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of projects">
              <tr class="mat-row" *ngIf="item.name !== project.name">
                <th scope="row" class="ptb-10">{{ item.name }}</th>
                <td class="text-center">
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'daily_mail'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'daily_mail'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td class="text-center">
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'weekly_mail'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'weekly_mail'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td class="text-center">
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'item_assigned'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'item_assigned'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td class="text-center">
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'item_status_changed'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'item_status_changed'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td class="text-center">
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'comment_tagged'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'comment_tagged'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td class="text-center">
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'comment_posted'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'comment_posted'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td
                  class="text-center"
                  *ngIf="settings[item.code].bouwapp_enabled"
                >
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'bouwapp_integration'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'bouwapp_integration'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
                <td
                  class="text-center"
                  *ngIf="settings[item.code].freshdesk_enabled"
                >
                  <mat-slide-toggle
                    [ngModel]="
                      projectNotificationSettingLogic(
                        settings[item.code],
                        'freshdebouwapp_enabledsk_integration'
                      )
                    "
                    (change)="
                      updateEmailNotification(
                        $event,
                        item.code,
                        settings[item.code],
                        'freshdesk_integration'
                      )
                    "
                  ></mat-slide-toggle>
                </td>
              </tr>
            </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</section>
