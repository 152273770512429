import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService, translate } from '@jsverse/transloco';
import * as Highcharts from 'highcharts';
import Dragable from 'highcharts/modules/draggable-points';
import ExportingModule from 'highcharts/modules/exporting';
import MapModule from 'highcharts/modules/map';
import StockModule from 'highcharts/modules/stock';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { generateColor } from 'src/app/core/util/generateColor';
import { getBelangMatrixBubbleColor, getBelangMatrixBubbleSize } from 'src/app/core/util/highcharts';
import { Stakeholder } from '../../../../../../akita/stakeholders/state/stakeholder.model';
import { MatrixService } from '../../../../../../core/service/api/matrix.service';
import { PubsubService } from '../../../../../../core/service/api/pubsub.service';
import { StakeholdersService } from "../../../../../../core/service/api/stakeholders.service";
import { ChildTabbedPage } from '../../../../../../core/util/abstract/child-tabbed-page';
import { showFeedbackSaved } from '../../../../../../core/util/notification';
import { Globals } from '../../../../../../globals';
import { DeletionModalComponent } from '../../../../../../shared/components/modal/deletion-modal/deletion-modal.component';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { Interest } from 'src/app/akita/interests/state/interest.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PopperContent } from 'ngx-popper';

StockModule(Highcharts);
MapModule(Highcharts);
ExportingModule(Highcharts);
Dragable(Highcharts);

Highcharts.setOptions({
  title: {
    style: {
      color: 'blue'
    }
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
});

@Component({
  selector: 'app-tab-stakeholder-detail-houding',
  templateUrl: './tab-stakeholder-detail-houding.component.html',
  styleUrls: ['./tab-stakeholder-detail-houding.component.sass']
})
export class TabStakeholderDetailHoudingComponent extends ChildTabbedPage {
  approachStrategyText: string;
  participationLevelText: string
  pageSubTitle: string;
  pageTitle: string;
  tabName = 'houding';
  subprojectname: any;
  loaded: boolean = false;
  @Input() stakeholder: Stakeholder;
  stakeholdersCode: string;
  public updateChart: boolean = false;
  matrices: any;
  selected = {};
  hideSubproject: boolean = false;
  hideCharts: boolean = false;
  subproject: any;
  plotlines: any[]
  subprojectID: any;
  interestColor = {};
  powerColor = {};
  trustColor = {};
  newInsterest: string;
  subprojectFill: any;
  matrixlabels = null;
  @Input() showInterests = false;
  @Input() showStrategyField = false;
  @Input() showCharts = false;
  showForm = false;
  dragX: boolean = false;
  dragY: boolean = false;
  ongecategoriseerd_Valid: boolean = true;
  cursor = 'not-allowed'
  editable: number;
  stakeholdersInterests = ThemeType.StakeholdersInterests
  // uncategorised:boolean=false;
  Highcharts: typeof Highcharts = Highcharts;
  cat: [{
    value: 0,
    label: 'Laag'
  }, {
    value: 50,
    label: 'Midden'
  }, {
    value: 100,
    label: 'Hoog'
  }];

  @Input() trustX: number;
  @Input() trustY: number;
  @Output() interestSelect = new EventEmitter<Interest[]>();
  trustSeries = [];

  trust = {};

  public editedTotalMatrix: {
    stakeholder: Stakeholder,
    currentValue: number,
  };

  @Input() powerX: number;
  @Input() powerY: number;
  powerSeries = [];
  public power: any;
  stakeholderMatrices: {
    matrices: any[],
    total_value: number,
    stakeholder_value_max: number,
    stakeholder_value_min: number,
  };
  disable_toggle: boolean = false;
  suggestion: number = 0;
  programmodule: any = [];
  subprojectsPlot: boolean = false;
  isStakeholderNew: boolean = false
  private powerPointTimeout: any;
  private trustPointTimeout: any;
  stakeholderMatrixValues: any = {
    power: null,
    power_interest: null,
    trust: null,
    trust_interest: null,
    strategie: 'Ongecategoriseerd',
    approach_strategy: 'Ongecategoriseerd',
    suggest_power_interest: null,
    suggest_trust_interest: null
  };
  public offset: number = 75;
  public offsetleft: number = 160;

  interests_labels: { label: string, color: string }[] = [
    { label: 'unknown', color: '#ffffff' },
    { label: 'none', color: '#e0e0e0' },
    { label: 'low', color: '#98dbea' },
    { label: '', color: '#58b5db' },
    { label: '', color: '#178ecc' },
    { label: 'high', color: '#004f90' }
  ];
  tensions: { label: string, label_old: string,color: string }[] = [
    { label: 'unknown', label_old: 'unknown', color: '#ffffff' },
    { label: 'none', label_old: 'similar', color: '#92e8A9' },
    { label: 'low', label_old: '', color: '#ffdad3' },
    { label: '', label_old: 'neutral', color: '#ffb8aa' },
    { label: '', label_old: '', color: '#fe8f80' },
    { label: 'high', label_old: 'opposite', color: '#ff4331' },
  ];
  influences: { label: string, color: string }[] = [
    { label: 'unknown', color: '#ffffff' },
    { label: 'none', color: '#e6e6fa' },
    { label: 'low', color: '#c8a2c8' },
    { label: '', color: '#9370db' },
    { label: '', color: '#6a0dad' },
    { label: 'high', color: '#4b0082' },
  ];
  trusts: { label: string, color: string }[] = [
    { label: 'unknown', color: '#ffffff' },
    { label: 'none', color: '#feecd3' },
    { label: 'low', color: '#ffdda6' },
    { label: '', color: '#ffce7a' },
    { label: '', color: '#ffbf4d' },
    { label: 'high', color: '#fdaf00' },
  ];

  @ViewChild('popperInfluence', {static: false}) private popperInfluence: PopperContent;
  @ViewChild('popperTrust', {static: false}) private popperTrust: PopperContent;
  @ViewChild('popperInterestTotal', {static: false}) private popperInterestTotal: PopperContent;
  @ViewChild('popperTensionTotal', {static: false}) private popperTensionTotal: PopperContent;


  constructor(private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private stakeholderService: StakeholdersService,
    private matrixService: MatrixService,
    public pubsub: PubsubService,
    public globals: Globals,
    private dialog: MatDialog,
    private _rout: Router,
    private _pusherService: PusherService,
    private observer: BreakpointObserver,
    private translocoService: TranslocoService) {
    super(pubsub, globals);
    this.matrices = {
      trust_type_label: {},
      power_type_label: {}
    };

  }

  interests = new MatTableDataSource([]);

  displayedColumns: string[] = ['belangen'];

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {

    if (this.globals.permissions.includes('stakeholders:strategy:update')) {
      this.dragX = true;
      this.dragY = true;
      this.cursor = 'move'
    }
    this.isStakeholderNew = this.globals.projectConfigs['project.stakeholder_strategy.is_new'];
    this.changeText()
    this.globals.modules.forEach(element => {
      this.programmodule.push(element.module_name);
    });
    if (!this.programmodule.includes('Program')) {
      this.hideSubproject = true;
    }
    if (this._rout.url.includes('sub')) {
      this.hideSubproject = true;
      this.subprojectsPlot = true;
    }
    this.stakeholdersCode = this.stakeholder.code;
    this.trustSeries = [];
    this.powerSeries = [];
    this.loadInterests(this.stakeholdersCode);
    this.getMatrixLabels();
    this.matrixService.getIssuesStakeholderMatricesByStakeholder(this.stakeholder.id).subscribe(
      (response: any) => this.stakeholderMatrices = response
    );
    this.stakeholderService.getStakeholderSummary(this.stakeholdersCode, true).subscribe(((res: any) => {
      if (res.subproject_exist) {
        if (res.subprojects.length > 0) {
          this.subproject = res.subprojects;
        }
      }
    }))
    this.observer.observe(['(min-width: 2000px)','(min-width: 1600px)','(min-width: 1500px)','(min-width: 1400px)','(min-width: 1300px)','(min-width: 1200px)','(min-width: 1100px)']).subscribe((res) => {
      switch(true) {
        case res.breakpoints['(min-width: 2000px)']: {
          this.offset = 100;
          this.offsetleft = 160;
          break;
        }
        case res.breakpoints['(min-width: 1600px)']: {
          this.offset = 100;
          this.offsetleft = 150;
          break;
        }
        case res.breakpoints['(min-width: 1500px)']: {
          this.offset = 100;
          this.offsetleft = 150;
          break;
        }
        case res.breakpoints['(min-width: 1400px)']: {
          this.offset = 100;
          this.offsetleft = 140;
          break;
        }
        case res.breakpoints['(min-width: 1300px)']: {
          this.offset = 100;
          this.offsetleft = 120;
          break;
        }
        case res.breakpoints['(min-width: 1200px)']: {
          this.offset = 90;
          this.offsetleft = 120;
          break;
        }
        case res.breakpoints['(min-width: 1100px)']: {
          this.offset = 80;
          this.offsetleft = 100;
          break;
        }
        default: {
          this.offset = 100;
          this.offsetleft = 160;
        }
      }
    });
    this.cd.detectChanges();
  }

  changeText() {
    this.approachStrategyText = this.isStakeholderNew
      ? this.translocoService.translate('menu.approach_strategy')
      : this.translocoService.translate('menu.forcefield_matrix');

    this.participationLevelText = this.isStakeholderNew
      ? this.translocoService.translate('menu.participation_level')
      : this.translocoService.translate('menu.power_interest_matrix');
  }

  private getMatrixLabels() {
    return this.matrixService.getLabels().subscribe(
      (data: any) => {
        this.matrixlabels = data;
        this.getMatricesOfStakeholder(this.stakeholdersCode);
      });
  }

  private getMatricesOfStakeholder(code) {
    this.stakeholderService.getStakeholderMatrix(code).subscribe(
      (data: any) => {
        this.loaded = true;
        if (data && data.length) {
          this.stakeholderMatrixValues = data[0];
          let mainExist = false;
          data = data.filter(element => {
            if (!element.subprojects_id) {
              if (!mainExist) {
                mainExist = true;
                return element;
              }
            } else {
              return element;
            }
          })
        }
        if (data.length == 1) {
          if (!data.subprojects_id) {
            this.disable_toggle = true;
            this.cd.detectChanges();
          }
        }
        if (data.length > 0) {
          if (!this.subprojectsPlot) {
            data = data.filter(el => {
              if (!el.subprojects_id) {
                return el;
              }
            })
          }
          data.forEach(element => {
            if (element) {
              this.trustX = (element.trust_interest !== null && element.trust !== null) ? element.trust_interest : 50;
              this.trustY = (element.trust !== null && element.trust_interest !== null) ? element.trust : 50;
              this.powerX = (element.power_interest !== null && element.power !== null) ? element.power_interest : 50;
              this.powerY = (element.power !== null && element.power_interest !== null) ? element.power : 50;
              // show strategy unknown if any of the bubble variable is null
              if (element.power_interest === null || element.power === null ) {
                this.ongecategoriseerd_Valid = false;
              }
              if (!element.subprojects_id) {
                this.ongecategoriseerd_Valid = element.is_updated;
                this.matrices = (element) ? element : {};
                this.matrices.trust_type_label = this.matrixService.getTypeLabel(this.isStakeholderNew? this.matrixlabels.approach_strategy : this.matrixlabels.trust_type, this.trustX, this.trustY);
                this.powerColor = 'green';
                this.trustColor = '#d9d9da';
                this.subprojectID = null;
                this.subprojectname = '';
                this.subprojectFill = false;
                this.matrices.power_type_label = this.matrixService.getTypeLabel(this.isStakeholderNew? this.matrixlabels.participation_level : this.matrixlabels.power_type, this.powerX, this.powerY);
              } else {
                this.powerColor = generateColor(element.subprojects.name);
                this.trustColor = generateColor(element.subprojects.name);
                if (!this._rout.url.includes('sub')) {
                  const matches = element.subprojects.name.match(/\b(\w)/g);
                  this.subprojectname = matches.join('');
                  this.subprojectFill = '#FFFFFF';
                } else {
                  this.subprojectname = '';
                  this.subprojectFill = false;
                }
                this.subprojectID = element.subprojects_id;
              } if (this._rout.url.includes('sub')) {
                this.matrices = (element) ? element : {};
                this.matrices.trust_type_label = this.matrixService.getTypeLabel(this.isStakeholderNew? this.matrixlabels.approach_strategy : this.matrixlabels.trust_type, this.trustX, this.trustY);
                this.powerColor = 'green';
                this.trustColor = '#d9d9da';
                this.subprojectID = element.subprojects_id;
                this.matrices.power_type_label = this.matrixService.getTypeLabel(this.isStakeholderNew? this.matrixlabels.participation_level : this.matrixlabels.power_type, this.powerX, this.powerY);
              }
            } else {
              this.trustX = 50;
              this.trustY = 50;
              this.powerX = 50;
              this.powerY = 50;
              this.powerColor = 'green';
              this.trustColor = '#d9d9da';
              this.subprojectname = '';
              this.subprojectFill = false;
            }
            this.trustSeries.push({
              'x': this.trustX,
              'y': this.trustY,
              'z': 20,
              'color': this.trustColor,
              'name': this.subprojectID,
              dataLabels: {
                enabled: true,
                format: this.subprojectname,
              },
              marker: {
                fillColor: this.subprojectFill,
              },
            });
            this.powerSeries.push({
              'x': this.powerX,
              'y': this.powerY,
              'z': (this.trustX == 50 && this.trustY == 50) ? 21 : getBelangMatrixBubbleSize('small', Math.round(this.trustY), Math.round(this.powerX), Math.round(this.powerY)),
              'color': (this.trustX == 50 && this.trustY == 50) ? this.powerColor : getBelangMatrixBubbleColor(this.trustX),
              'name': this.subprojectID,
              dataLabels: {
                enabled: true,
                format: this.subprojectname,
              },
              marker: {
                fillColor: this.subprojectFill,
              },
            });
          });
          if (!this._rout.url.includes('sub') && this.subprojectsPlot) {
            if (this.subproject) {
              if (this.subproject.length + 1 > data.length) {
                this.helperSet(data);
              }
            }
          }
        } else {
          this.disable_toggle = true;
          this.cd.detectChanges();
          this.handleUncategorized();
          if (!this._rout.url.includes('sub') && this.subprojectsPlot) {
            if (this.subproject) {
              if (this.subproject.length + 1 > data.length) {
                this.helperSet(data);
              }
            }
          }
        }
        this.drawTrust();
        this.drawPower();
      }
    );
  }

  private dropPointTrust(point) {
    this.trustX = this.roundingPoints(point.newPoint.x);
    this.trustY = this.roundingPoints(point.newPoint.y);
    this.matrices.trust_type_label = this.matrixService.getTypeLabel(this.isStakeholderNew? this.matrixlabels.approach_strategy : this.matrixlabels.trust_type, point.newPoint.x, point.newPoint.y);
    clearTimeout(this.trustPointTimeout);
    this.trustPointTimeout = setTimeout(() => {
      this.stakeholderService.storeMatrices(this.stakeholdersCode, this.stakeholderMatrixValues?.power_interest, this.stakeholderMatrixValues?.power, this.trustX, this.trustY, point.target.name)
        .subscribe((data: any) => {
          this.stakeholderMatrixValues = data;
          showFeedbackSaved();
          this._pusherService.onCangeStatus();
        }
        );
      this.intrestChecker(point);
      this.updateChart = true;
    }, 250);
  }

  private dropPointPower(point) {
    this.ongecategoriseerd_Valid = true;
    this.cd.detectChanges();
    this.powerX = this.roundingPoints(point.newPoint.x);
    this.powerY = this.roundingPoints(point.newPoint.y);
    // this.uncategorised=false;
    if (point.target.name == null || this._rout.url.includes('sub')) {
      this.matrices.power_type_label =
        this.matrixService.getTypeLabel(this.isStakeholderNew? this.matrixlabels.participation_level : this.matrixlabels.power_type, point.newPoint.x, point.newPoint.y);
    }
    clearTimeout(this.powerPointTimeout);
    this.powerPointTimeout = setTimeout(() => {
      this.stakeholderService.storeMatrices(this.stakeholdersCode, this.powerX, this.powerY, this.stakeholderMatrixValues?.trust_interest, this.stakeholderMatrixValues?.trust, point.target.name)
        .subscribe(data => {
          this.stakeholderMatrixValues = data;
          showFeedbackSaved();
          this._pusherService.onCangeStatus();
        }
        );
    }, 250);
  }

  private roundingPoints(point: number) {
    if (point < 0) {
      return 0;
    }

    if (point > 100) {
      return 100;
    }

    return point;
  }

  private drawTrust() {
    const interest = this.isStakeholderNew === true ? translate('reusable.approach_strategy.bring_interests') : '';
    const voicesHeard = this.isStakeholderNew === true ? translate('reusable.approach_strategy.make_voices_heard') : '';
    const resolveConflict = this.isStakeholderNew === true ? translate('reusable.approach_strategy.resolve_conflict') : '';
    const trust = this.isStakeholderNew === true ? translate('reusable.approach_strategy.build_trust') : '';
    // eslint-disable-next-line prefer-const
    let self = this;
    this.trust = {
      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        events: {
          render: function () {
            const chart = this;

            // Update the line's position
            if (chart.consultText) {
              chart.consultText.destroy();
              chart.produceText.destroy()
            }
            chart.consultText = chart.renderer.text(voicesHeard, chart.chartWidth - self.offsetleft, self.offset).add();
            chart.produceText = chart.renderer.text(interest, self.offset - 30, self.offset).add();
            // Co-decide
            if (chart.adviceText) {
              chart.informText.destroy();
              chart.adviceText.destroy();
            }
            chart.adviceText = chart.renderer.text(resolveConflict, self.offset - 20, chart.chartHeight - self.offset).add();
            chart.informText = chart.renderer.text(trust, chart.chartWidth - self.offsetleft, chart.chartHeight - self.offset).add();
          }
        },
      },
      labels: {
        format: '{value}'
      },
      title: {
        text: ''
      },
      xAxis: {
        min: 0,
        max: 100,
        minTickInterval: 50,
        allowDecimals: true,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: this.isStakeholderNew === true ? translate('reusable.tension') : 'Type belang',
          y: 10
        },
        labels: {
          enabled: false,
        },
        plotLines: [{
          value: 0,
          color: '#F2F3F8', // hidden
          label: {
            rotation: 0,
            x: -5,
            verticalAlign: 'bottom',
            style: {
              fontStyle: 'italic'
            },
            text: this.isStakeholderNew === true ? translate('reusable.high') : translate('reusable.opposite')
          },
        }, {
          color: '#cccccc',
          width: 1,
          value: 50,
          label: {
            rotation: 0,
            x: -20,
            verticalAlign: 'bottom',
            style: {
              fontStyle: 'italic'
            },
            text: this.isStakeholderNew === true ? translate('reusable.average') : translate('reusable.neutral')
          },
        },
        {
          value: 95,
          color: '#ffffff', // hidden
          label: {
            rotation: 0,
            x: -5,
            verticalAlign: 'bottom',
            style: {
              fontStyle: 'italic'
            },
            text: this.isStakeholderNew === true ? translate('reusable.low') : translate('reusable.equal')
          },
        }]
      },
      yAxis: {
        min: 0,
        max: 100,
        minTickInterval: 50,
        allowDecimals: true,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: this.isStakeholderNew === true ? translate('reusable.trust') : translate('reusable.trust_in_relationship'),
          x: -10
        },
        maxPadding: 0.2,
        labels: {
          enabled: false,
        },
        plotLines: [{
          value: 0,
          color: '#ffffff', // hidden
          label: {
            rotation: 270,
            x: -5,
            align: 'left',
            style: {
              fontStyle: 'italic'
            },
            text: translate('reusable.low')
          },
          zIndex: 1
        }, {
          color: '#cccccc',
          dashStyle: 'line',
          width: 1,
          value: 50,
          label: {
            rotation: 270,
            x: -5,
            y: 25,
            align: 'left',
            style: {
              fontStyle: 'italic'
            },
            text: translate('reusable.average')
          },
          zIndex: 1
        }, {
          value: 95,
          color: '#ffffff', // hidden
          label: {
            rotation: 270,
            x: -5,
            align: 'left',
            style: {
              fontStyle: 'italic'
            },
            text: translate('reusable.high')
          }
        }]
      },
      plotOptions: {
        bubble: {
          minSize: 10,
          maxSize: 30
        },
        series: {
          point: {
            events: {
              drag: (e) => {
                // run function to change point color and label
                this.dropPointTrust(e);
              }
            }
          }
        }
      },
      series: [{
        type: 'bubble',
        color: '#b4b4b5',
        cursor: this.cursor,
        dragDrop: {
          draggableX: this.dragX,
          draggableY: this.dragX,
          dragMaxY: 100,
          dragMinY: 0,
          dragMaxX: 100,
          dragMinX: 0,
        },
        data: this.trustSeries
      }]
    };
  }

  private drawPower() {
    // eslint-disable-next-line prefer-const
    let self = this;
    const isStakeholder = this.isStakeholderNew
    const decide = this.isStakeholderNew === true ? translate('reusable.participation_level.co-decide') : '';
    const reactive = this.isStakeholderNew === true ? translate('reusable.participation_level.reactive') : '';
    const consult = this.isStakeholderNew === true ? translate('reusable.participation_level.consult') : '';
    const produce = this.isStakeholderNew === true ? translate('reusable.participation_level.co-produce') : '';
    const inform = this.isStakeholderNew === true ? translate('reusable.participation_level.inform') : '';
    const advice = this.isStakeholderNew === true ? translate('reusable.participation_level.advice') : '';
    const plotLine_leftVertical = {
      value: 0,
      color: 'transprent', // hidden
      label: {
        rotation: 0,
        x: -5,
        verticalAlign: 'bottom',
        style: {
          fontStyle: 'italic'
        },
        text: translate('reusable.low')
      },
      zIndex: 1
    };
    const plotLine_middleVertical = {
      color: '#cccccc',
      dashStyle: 'line',
      width: 1,
      value: 50,
      label: {
        rotation: 0,
        x: -20,
        verticalAlign: 'bottom',
        style: {
          fontStyle: 'italic'
        },
        text: translate('reusable.average')
      },
      zIndex: 1
    };
    const plotLine_rightVertical = {
      value: 95,
      color: 'transprent', // hidden
      label: {
        rotation: 0,
        x: -5,
        verticalAlign: 'bottom',
        style: {
          fontStyle: 'italic'
        },
        text: translate('reusable.high')
      }
    };
    // let plotlines: any[]
    this.plotlines = [plotLine_leftVertical, plotLine_middleVertical, plotLine_rightVertical];


    if (this.stakeholderMatrices && this.stakeholderMatrices.total_value > 0
      && (this.stakeholderMatrices.stakeholder_value_min + this.stakeholderMatrices.stakeholder_value_max) > 0) {

      this.suggestion = (this.stakeholderMatrices.total_value - this.stakeholderMatrices.stakeholder_value_min) /
        (this.stakeholderMatrices.stakeholder_value_max - this.stakeholderMatrices.stakeholder_value_min) * 99;

      this.plotlines.push(
        {
          className: 'stakeholder-matrix-suggestion-line',
          color: '#DCDCDC ',
          dashStyle: 'Solid',
          opacity: 0.3,
          width: 20,
          value: this.suggestion,
          label: {
            rotation: -90,
            y: 275,
            verticalAlign: 'top',
            style: {
              fontStyle: 'italic',
              color: '#555555',
            },
            text: this.translocoService.translate('text.suggestion_based_on_interest_matrix'),
          },
        }
      );
    } else {
      this.suggestion = -1;
    }

    this.power = {
      chart: {
        animation: false,
        plotBorderWidth: 1,
        events: {
          render: function () {
            const chart = this;
            // reactice
            if (chart.customLine) {
              chart.customLine.destroy();
            }

            if (chart.calLine) {
              chart.calLine.destroy();
            }

            if (chart.uptext) {
              chart.uptext.destroy();
              chart.consultText.destroy();
              chart.produceText.destroy()
            }
            chart.uptext = chart.renderer.text(reactive, 75, chart.chartHeight - 75).add();
            chart.consultText = chart.renderer.text(produce, chart.chartWidth - self.offsetleft, self.offset).add();
            chart.produceText = chart.renderer.text(consult, self.offset - 40, self.offset).add();

            if (isStakeholder) {

              chart.customLine = chart.renderer.path(['M', 35, chart.chartHeight - 120, 'L', 150, chart.chartHeight - 120])
                .attr({
                  'stroke': '#cccccc', // Set the line color
                  'stroke-width': 1 // Set the line width
                })
                .add();



              chart.calLine = chart.renderer.path(['M', 150, chart.chartHeight - 35, 'L', 150, chart.chartHeight - 120])
                .attr({
                  'stroke': '#cccccc', // Set the line color
                  'stroke-width': 1 // Set the line width
                })
                .add();


            }

            // Co-decide
            // Add the text to the chart
            if (chart.downLine) {
              chart.downLine.destroy();
            }

            if (chart.verlLine) {
              chart.verlLine.destroy();
            }
            if (chart.downtext) {
              chart.downtext.destroy();
              chart.informText.destroy();
              chart.adviceText.destroy();
            }
            chart.downtext = chart.renderer.text(decide, chart.chartWidth - 85, 55).add()
            chart.adviceText = chart.renderer.text(inform, self.offset - 20, chart.chartHeight - self.offset - 60).add();
            chart.informText = chart.renderer.text(advice, chart.chartWidth - self.offsetleft, chart.chartHeight - self.offset).add();

            if (isStakeholder) {
              chart.downLine = chart.renderer.path(['M', chart.chartWidth - 95 - 20, 97 - 20, 'L', chart.chartWidth + 10 - 20, 97 - 20]) // 'M' denotes the starting point, 'L' denotes the line
                .attr({
                  'stroke': '#cccccc', // Set the line color
                  'stroke-width': 1 // Set the line width
                })
                .add();
              chart.verlLine = chart.renderer.path(['M', chart.chartWidth - 95 - 20, 30 - 20, 'L', chart.chartWidth - 95 - 20, 97 - 20])
                .attr({
                  'stroke': '#cccccc',
                  'stroke-width': 1
                })
                .add();
            }


          }
        },
      },
      labels: {
        format: '{value}'
      },
      title: {
        text: ''
      },
      xAxis: {
        min: 0,
        max: 100,
        minTickInterval: 50,
        allowDecimals: true,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: translate('reusable.interest'),
          y: 10
        },
        labels: {
          enabled: false,
        },
        plotLines: this.plotlines,
      },

      yAxis: {
        min: 0,
        max: 100,
        minTickInterval: 50,
        allowDecimals: true,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: this.isStakeholderNew === true ? translate('reusable.influence') : translate('reusable.power'),
          x: -10
        },
        labels: {
          enabled: false,
        },
        maxPadding: 0.2,
        plotLines: [{
          value: 0,
          color: 'transprent', // hidden
          label: {
            rotation: 270,
            x: -5,
            align: 'left',
            style: {
              fontStyle: 'italic'
            },
            text: translate('reusable.low')
          },
          zIndex: 1
        },
        {
          color: '#cccccc',
          dashStyle: 'line',
          width: 1,
          value: 50,
          label: {
            rotation: 270,
            x: -5,
            y: 25,
            align: 'left',
            style: {
              fontStyle: 'italic'
            },
            text: translate('reusable.average')
          },
          zIndex: 1
        },
        {
          value: 95,
          color: 'transprent', // hidden
          label: {
            rotation: 270,
            x: -5,
            align: 'left',
            style: {
              fontStyle: 'italic'
            },
            text: translate('reusable.high')
          }
        }]
      },
      plotOptions: {
        bubble: {
          minSize: 14,
          maxSize: 28
        },
        dragDrop: {
          draggableX: this.dragX,
          draggableY: this.dragY,
          dragMaxY: 100,
          dragMinY: 0,
          dragMaxX: 100,
          dragMinX: 0,
        },
        series: {
          point: {
            events: {
              drag: (e) => {
                this.dropPointPower(e);
              }
            }
          }
        }
      },
      series: [{
        type: 'bubble',
        cursor: this.cursor,
        dragDrop: {
          draggableX: this.dragX,
          draggableY: this.dragY,
          dragMaxY: 100,
          dragMinY: 0,
          dragMaxX: 100,
          dragMinX: 0,
        },
        data: this.powerSeries
      }]
    };
    if (this.subprojectID == null) {
      if (this.powerSeries.length == 1) {
        if (this.trustY !== 50) {
          this.power.plotOptions.bubble.minSize = getBelangMatrixBubbleSize('small', Math.round(this.trustY), Math.round(this.powerX), Math.round(this.powerY));
          this.power.series[0].data[0].color = getBelangMatrixBubbleColor(this.trustX)
        }
      }
    }
  }

  private loadInterests(code) {
    this.stakeholderService.getInterestByStakeholder(code).subscribe(
      (data: any) => this.interests.data = data.data
    );
  }

  public addInterest() {
    if (!this.newInsterest) {
      return;
    }

    this.stakeholderService.addInterestsOfStakeholder(this.stakeholdersCode, this.newInsterest).subscribe(
      () => {
        this.newInsterest = '';
        this.loadInterests(this.stakeholdersCode);
      }
    );
  }

  public deleteInterest(id) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker, hiermee verwijdert u ook e.v.t. belangen uit het issue?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.stakeholderService.removeInterestOfStakeholder(this.stakeholdersCode, id)
          .subscribe(
            () => this.loadInterests(this.stakeholdersCode)
          );
      }
    });
  }

  public updateInterest(event: MouseEvent, id, description): void {
    event.stopPropagation();
    this.editable = null;
    this.stakeholderService.updateInterest(this.stakeholdersCode, id, description)
      .subscribe(
        () => {
          this.loadInterests(this.stakeholdersCode);
          showFeedbackSaved();
        },
      );
  }

  public cancelUpdateInterest(event: MouseEvent): void {
    event.stopPropagation();
    this.editable = null;
  }

  public getEdit(event, id) {
    this.editable = id;
  }
  public nonAPISubprojectHandle(handler) {
    this.subproject.forEach(element => {
      if (!handler.includes(element.id)) {
        const matches = element.name.match(/\b(\w)/g);
        const name = matches.join('');
        this.trustX = 50;
        this.trustY = 50;
        this.powerX = 50;
        this.powerY = 50;
        this.trustSeries.push({
          'x': this.trustX,
          'y': this.trustY,
          'z': 20,
          'color': generateColor(element.name),
          'name': element.id,
          dataLabels: {
            enabled: true,
            format: name,
          },
          marker: {
            fillColor: '#FFFFFF',
          },
        });
        this.powerSeries.push({
          'x': this.powerX,
          'y': this.powerY,
          'z': 20,
          'color': generateColor(element.name),
          'name': element.id,
          dataLabels: {
            enabled: true,
            format: name,
          },
          marker: {
            fillColor: '#FFFFFF',
          },
        });
      }
    });
  }
  public helperSet(matrix) {
    const handler = [];
    matrix.forEach(element => {
      handler.push(element.subprojects_id)
    });
    this.nonAPISubprojectHandle(handler)
  }
  addSubprojectInMain() {
    this.ngOnInit();
  }
  public handleUncategorized() {
    this.trustX = 50;
    this.trustY = 50;
    this.powerX = 50;
    this.powerY = 50;
    this.powerColor = 'green';
    this.trustColor = '#d9d9da';
    this.subprojectname = '';
    this.subprojectFill = false;
    this.matrices.power_type_label.name = 'Ongecategoriseerd'
    this.trustSeries.push({
      'x': this.trustX,
      'y': this.trustY,
      'z': 20,
      'color': this.trustColor,
      dataLabels: {
        enabled: true,
        format: this.subprojectname,
      },
      marker: {
        fillColor: this.subprojectFill,
      },
    });
    this.powerSeries.push({
      'x': this.powerX,
      'y': this.powerY,
      'z': 20,
      'color': this.powerColor,
      dataLabels: {
        enabled: true,
        format: this.subprojectname,
      },
      marker: {
        fillColor: this.subprojectFill,
      },
    });
  }
  public intrestChecker(point) {
    if (point.target.name == null && this.power.series[0].data.length == 1) {
      this.power.plotOptions.bubble.minSize = getBelangMatrixBubbleSize('small', Math.round(this.trustY), Math.round(this.powerX), Math.round(this.powerY));
      this.power.series[0].data[0].color = getBelangMatrixBubbleColor(this.trustX)
    } else {
      this.power.series[0].data.forEach(element => {
        if (element.name == point.target.name) {
          element.z = getBelangMatrixBubbleSize('small', Math.round(this.trustY), Math.round(this.powerX), Math.round(this.powerY))
          element.color = getBelangMatrixBubbleColor(this.trustX)
        }
      });
    }
    this.updateChart = true;
  }
  /*
    On Select Interests
  */
  public onSelectInterests(interests) {
    this.interestSelect.emit(interests);
  }

  public editTotal(stakeholder: Stakeholder, currentValue: number, type?) {
    currentValue = type === 'tension'? (100 - currentValue) : currentValue;
    this.editedTotalMatrix = {
      stakeholder: stakeholder,
      currentValue: (currentValue >= 0 && currentValue != (null || undefined)) ? Math.floor(currentValue/25) : -1
    }
  }
  public applyEditTotal(matrixValue: number, type: string) {
    matrixValue = matrixValue === -1? null: matrixValue*25;
    const matrixObject = {
      power: (type === 'influence') ? matrixValue : this.stakeholderMatrixValues.power,
      power_interest: (type === 'interest') ? matrixValue : this.stakeholderMatrixValues.power_interest,
      trust: (type === 'trust') ? matrixValue : this.stakeholderMatrixValues.trust,
      trust_interest: (type === 'tension') ? (matrixValue === null? matrixValue : (100 - matrixValue)) : this.stakeholderMatrixValues.trust_interest,
    };
    this.matrixService.storeMatrices(this.stakeholder.code, matrixObject).subscribe((response: any) => {
      showFeedbackSaved();
      this.ngOnInit()
    })

    this.popperInfluence.hide();
    this.popperTrust.hide();
    this.popperInterestTotal.hide();
    this.popperTensionTotal.hide();

  }
}
