<mat-progress-bar *ngIf="!relatics" mode="indeterminate"></mat-progress-bar>
<form #form="ngForm" (ngSubmit)="updateComplaint()" *ngIf="relatics">
    <div mat-dialog-title>
        <h4 class="mb-10 ml-10 inline-block">Contactgegevens melding {{ data.complaint.id }}</h4>
        <button type="button" class="close" (click)="close()">
    <span aria-hidden="true"><mat-icon>close</mat-icon></span>
    <span class="sr-only">Close</span>
    </button>
    </div>
    <!-- modal body -->
    <div *ngIf="type ==='complaints'">
        <div class="modal-body" *transloco="let t">
            <mat-form-field class="full-width mt-15" [matTooltip]="relatics.complainant_name ? t('settings.relatic.disable_field') : null">
              <mat-label>Naam</mat-label>
                <input matInput  name="name" [(ngModel)]="complaint.complainant_name" [disableControl]="relatics.complainant_name">
            </mat-form-field>
            <mat-form-field class="full-width" [matTooltip]="relatics.complainant_name ? t('settings.relatic.disable_field') : null">
              <mat-label>Email</mat-label>
                <input matInput type="email" name="email" [(ngModel)]="complaint.complainant_email" [disableControl]="relatics.complainant_email">
            </mat-form-field>
            <div [matTooltip]="relatics.complainant_phone ? t('settings.relatic.disable_field') : null">
                <div [ngClass]="{'disable_enitiy': relatics.complainant_phone}">
                    <international-phone-number placeholder="Telefoonnummer" [maxlength]="20" defaultCountry="nl" #phoneNumber="ngModel" [allowedCountries]="['nl', 'be']" name="phone_number" [(ngModel)]="complaint.complainant_phone"></international-phone-number>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="type ==='decisions'">
        <div class="modal-body" *transloco="let t">
            <mat-form-field class="full-width mt-15" [matTooltip]="relatics.manual_contact_name ? t('settings.relatic.disable_field') : null">
              <mat-label>Naam</mat-label>
                <input matInput  name="name" [(ngModel)]="complaint.manual_contact_name" [disableControl]="relatics.manual_contact_name">
            </mat-form-field>
            <mat-form-field class="full-width" [matTooltip]="relatics.complainant_name ? t('settings.relatic.disable_field') : null">
              <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Email" name="email" [(ngModel)]="complaint.manual_contact_email" [disableControl]="relatics.complainant_email">
            </mat-form-field>
            <div [matTooltip]="relatics.complainant_phone ? t('settings.relatic.disable_field') : null">
                <div>
                    <international-phone-number placeholder="Telefoonnummer" [maxlength]="20" defaultCountry="nl" #phoneNumber="ngModel" [allowedCountries]="['nl', 'be']" name="phone_number" [(ngModel)]="complaint.manual_contact_telephone"></international-phone-number>
                </div>
            </div>
        </div>
    </div>
    <!--end of modal body -->
    <div class="modal-footer" *transloco="let t">
        <button type="button" class="btn btn-secondary" (click)="close()">{{ t('close') | capitaliz }}</button>
        <button type="submit" class="btn d-btn-dialog mdl-button mdl-js-button mdl-button--raised">
    {{ t('save') | capitaliz }}
    </button>
    </div>
    <!--end of modal footer -->

</form>
