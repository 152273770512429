import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { AuthUserIdentifier } from 'src/app/core/util/auth/auth.modal';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { EmailMismatchComponent } from 'src/app/shared/components/modal/email-mismatch/email-mismatch.component';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.sass']
})
export class CallbackComponent implements OnInit {
  // eslint-disable-next-line no-empty-function
  constructor(private dialog: MatDialog, private _authService: AuthService, private _rout: Router) {}

  ngOnInit() {
    switch(true){
      case this._rout.url.includes('code='):
        this._authService.auhtIdentify(localStorage.getItem('email'))
        .pipe(
          tap((res:AuthUserIdentifier)=>{
            localStorage.setItem("customer_id", res.customer_id.toString());
            localStorage.setItem("user_id", res.user_id.toString());
            this.verfySSOCode(this._rout.url.match(/code=([^&]+)/),res.customer_id, res.user_id);
          }),
          catchError(async (err) => {
            bugsnagFactory().notify(err);
            this.returnHome();
          })
        ).subscribe();
      break;
      case this._rout.url.includes('access_token='):
        this._authService.setSession(this._rout.url.match(/access_token=([^&]+)/)[1]);
      break;
      default:
        this.returnHome();
      break;
    }
  }
  //get access token from sso
  public verfySSOCode(code,id, usrId): void{
    this._authService.handleAuthSSOCallback(code[1],id,usrId)
    .pipe(
      tap((res:any)=>{
        if (res.access_token) {
          const current_time = new Date();
          current_time.setSeconds(current_time.getSeconds() + JSON.parse(atob(res.refresh_token.split('.')[1])).expires_in);
          localStorage.setItem("exp",current_time.toString());
          localStorage.setItem("refresh_token",JSON.parse(atob(res.refresh_token.split('.')[1])).refresh_token);
          localStorage.setItem("refresh_token_unparsed", res.refresh_token);
        }
        this._authService.setSession(res.access_token);
      }),
      catchError(async (err: any) => {
        console.log(err)
        const error = err.error;
        if (error && error.message === "User is Different" && !error.status) {
          const modal = this.dialog.open(EmailMismatchComponent, {
            data: '',
            width: "50vw"
          });
          modal.afterClosed().subscribe((res) => {
            if (res)
              this.returnHome();
          })
        } else {
          bugsnagFactory().notify(err);
          this.returnHome();
        }
      })
    ).subscribe();
  }
  //return home if anything goes wrong
  public returnHome(): void{
    window.location.href = '/';
  }
}
