import {AfterViewInit, ChangeDetectorRef, Directive} from "@angular/core";
import { PubsubService } from "../../../core/service/api/pubsub.service";
import {Globals} from "../../../globals";
import {Page} from "./page";

@Directive({
})

export abstract class ParentTabbedPage extends Page implements AfterViewInit {

  protected tabName: string = '';

  protected constructor(public pubsub: PubsubService, public globals: Globals, public cdRef: ChangeDetectorRef) {
    super(pubsub, globals);
  }

  ngAfterViewInit() {
    this.pubsub.tabName.subscribe(name => {
      this.tabName = name;
      this.cdRef.detectChanges();
    });
  }

  isTabActive(path) {
    return this.tabName === path;
  }
}
