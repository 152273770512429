import { tap } from "rxjs/operators";
import { MapService } from "src/app/core/service/api/map.service";
import { PublicLayers } from "src/app/core/util/openDataModals";
import { MapComponent } from "./map.component";

export class OpenDataLayers {
  public openLayer:PublicLayers[];
  public openDataLayer:any[]=[];
  public map:MapComponent;
  public layersSymobology: any[]=[];
  public featureSymobology: any[]=[];
  public flag=0;
  constructor(private _mapServices:MapService){
      this.init();
  }
  //on it function
  public init(): void {
    this._mapServices.getOpenData()
    .pipe(
      tap((publicLayer:PublicLayers[])=>{
          this.openLayer=publicLayer;
          this.loadOpenLayers();
      }),
    ).subscribe();
  }
  // load layers on map
  public loadOpenLayers(){
    this.layersSymobology=[];
      this.openLayer.forEach(layer => {
        layer.layers.forEach(sub => {
          if(layer.type=='public_arcgis'){
            this._mapServices.getSymobology(layer.url,sub.id)
            .pipe(
              tap((res:any)=>{
                if(sub.is_enabled){
                  this._mapServices.afterLayerMaped({
                    type:layer.type,
                    layer:layer,
                    layer_name:sub.name,
                    layer_id:sub.id ? sub.id : 0,
                    metadata:sub.metadata,
                    symobology:res.drawingInfo.renderer
                  });
                }
              }),
            ).subscribe();
          }else{
            if(sub.is_enabled){
              this._mapServices.afterLayerMaped({
                type:layer.type,
                layer:layer,
                layer_name:sub.name,
                layer_id:sub.id ? sub.id : 0,
                metadata:sub.metadata,
                symobology:null
              });
            }
          }
        });
    });
  }
}
