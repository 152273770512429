<div *ngIf="showInterests" class="col-xs-12 text-left">
    <div class="d-flex">
      <app-add-connect-themes
        [mulitple]="true"
        [editButton]="true"
        [editable]="false"
        class="col-xs-12 align-self-center"
        [textMultiple]="'interests' | transloco"
        [textSingle]="'interests' | transloco"
        [buttonText]="'stakeholders.interest_button_text'"
        [type]="stakeholdersInterests"
        [selectedTheme]="stakeholder.interests"
        [issuesStakeholdersInterests]="stakeholder.issues_stakeholders_interests"
        (onThemeSelected)="onSelectInterests($event)"
        [uniqueId]="'interests'"
      ></app-add-connect-themes>
    </div>
  </div>

  <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>


<!-- Strategy -->
<div *ngIf="!showInterests && loaded">
    <div class="col-xs-7 mt-6" style="padding-left: 0px;padding-right: 0px;">
      <ul class="none-style issue-interest-new" style="display: flex;justify-content: space-between;">
        <li class="d-flex" style="align-items: center;">
          <span class="d-grid">
            <mat-icon class="icon-set text-gray align-self-center d-contents text-center uil uil-chart-pie-alt pr-5"></mat-icon>
            <span class="bottom-font-set" [matTooltip]="'reusable.interest' | transloco">{{'reusable.interest' | transloco | truncatie:8}}</span>
          </span>
          <div [ngClass]="'colorInterest-' + ( stakeholderMatrixValues?.power_interest !== null ? (stakeholderMatrixValues?.power_interest/25 | number :'1.0-0') : 'x')" (click)="editTotal(stakeholder, stakeholderMatrixValues?.power_interest)" class="mlr-3 align-self-center" [popper]="popperInterestTotal" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" popperTrigger="click" popperPlacement="left" popperApplyClass="matrix-popper-dialog"
          ></div>
        </li>
        <li class="d-flex" style="align-items: center;">
          <span class="d-grid">
            <mat-icon class="icon-set text-gray align-self-center d-contents text-center uil uil-megaphone pr-5"></mat-icon>
            <span class="bottom-font-set" [matTooltip]="isStakeholderNew? ('reusable.influence' |transloco) : ('reusable.power' | transloco)">{{ isStakeholderNew? ('reusable.influence' |transloco) : ('reusable.power' | transloco)}}</span></span>
          <div [className]="'colorInfluence-' + (stakeholderMatrixValues?.power !== null ? (stakeholderMatrixValues?.power/25 | number :'1.0-0') : 'x')" (click)="editTotal(stakeholder, stakeholderMatrixValues?.power)" class="mlr-3 align-self-center" [popper]="popperInfluence" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" popperTrigger="click" popperPlacement="left" popperApplyClass="matrix-popper-dialog"
          ></div>
        </li>
        <li class="d-flex" style="align-items: center;">
          <span class="d-grid">
            <mat-icon class="icon-set text-gray align-self-center d-contents text-center uil uil-temperature-half pr-5"></mat-icon>
            <span class="bottom-font-set" [matTooltip]="isStakeholderNew? ('reusable.tension' | transloco) : ('reusable.type_of_interest' | transloco)">{{ isStakeholderNew? ('reusable.tension' | transloco) : ('reusable.type_of_interest' | transloco | truncatie:8)}}</span></span>
          <div [ngClass]="'colorTension-' + (stakeholderMatrixValues?.trust_interest !== null ? ((100 - stakeholderMatrixValues?.trust_interest)/25 | number :'1.0-0') : 'x')" (click)="editTotal(stakeholder, stakeholderMatrixValues?.trust_interest, 'tension')" class="mlr-3 align-self-center" [popper]="popperTensionTotal" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" popperTrigger="click" popperPlacement="left" popperApplyClass="matrix-popper-dialog"
          ></div>
        </li>
        <li class="d-flex" style="align-items: center;">
          <span class="d-grid"><mat-icon class="icon-set text-gray align-self-center d-contents text-center uil uil-feedback pr-5"></mat-icon>
          <span class="bottom-font-set" [matTooltip]="'reusable.trust' | transloco">{{'reusable.trust' | transloco | truncatie:8}}</span></span>
          <div [className]="'colorTrust-' + (stakeholderMatrixValues?.trust !== null ? (stakeholderMatrixValues?.trust/25 | number :'1.0-0') : 'x')" (click)="editTotal(stakeholder, stakeholderMatrixValues?.trust)" class="mlr-3 align-self-center" [popper]="popperTrust" [popperHideOnClickOutside]="true" [popperHideOnScroll]="true" popperTrigger="click" popperPlacement="left" popperApplyClass="matrix-popper-dialog"
          ></div>
        </li>
      </ul>
    </div>
    <div *ngIf="!ongecategoriseerd_Valid" class="col-xs-5 stakeholder-strategy">
      <span
        [class]="'Ongecategoriseerd' + ' strategy-label'"
        (click)="showStrategyField = !showStrategyField"
        *transloco="let t; read: 'employees'"
      >
        {{'reusable.stakeholder.Ongecategoriseerd' | transloco | capitaliz}}
        <i
          *ngIf="!showStrategyField"
          class="d-text-middle uil uil-arrow-down unicon-icon"
        ></i>
        <i
          *ngIf="showStrategyField"
          class="d-text-middle uil-arrow-up unicon-icon"
        ></i>
      </span>
    </div>
    <div *ngIf="ongecategoriseerd_Valid" class="col-xs-5 stakeholder-strategy">
        <!-- Informeren has special treatment as it belong to both strategic method -->
        <span [class]="(isStakeholderNew && matrices.power_type_label.name === 'Informeren')? 'Informeren-strategie strategy-label' : matrices.power_type_label.name+ ' strategy-label'" (click)="showStrategyField = !showStrategyField" *transloco="let t; read: 'employees'" [matTooltip]="'reusable.stakeholder.'+matrices.power_type_label.name | transloco">
          {{ 'reusable.stakeholder.'+matrices.power_type_label.name | transloco | truncatie : 10 }}
          <i *ngIf="!showStrategyField" class="d-text-middle uil uil-arrow-down unicon-icon mat-icon"></i>
          <i *ngIf="showStrategyField" class="d-text-middle uil uil-arrow-up unicon-icon mat-icon"></i>
        </span>
    </div>
    <span class="mt-6" *ngIf="isStakeholderNew && stakeholderMatrixValues?.approach_strategy" style="text-align: right;padding-left: 0px;padding-right: 0px;float: right;" [matTooltip]="'reusable.stakeholder.' +  stakeholderMatrixValues?.approach_strategy | transloco | capitaliz">{{ 'reusable.stakeholder.' + stakeholderMatrixValues?.approach_strategy | transloco | capitaliz}}</span>

  </div>

  <div *transloco="let t">
    <div *ngIf="!showInterests && showStrategyField" class="col-xs-12">
      <div>*{{ "stakeholders.change_strategy" | transloco | capitaliz }}</div>
      <div *ngIf="!hideSubproject" class="main-div-switch">
        <mat-slide-toggle
          class="pull-left"
          [(ngModel)]="subprojectsPlot"
          [disabled]="disable_toggle"
          [matTooltip]="
            disable_toggle
              ? ('stakeholders.no_subproject_categorised' | transloco) +
                ' ' +
                (this.globals.projectConfigs['project.labels.subprojects'] ||
                  'subprojects.label' | transloco)
              : null
          "
          (change)="addSubprojectInMain()"
        >
        </mat-slide-toggle
        ><span class="align-subprojects">{{
          ("stakeholders.show" | transloco | capitaliz) +
            " " +
            (this.globals.projectConfigs["project.labels.subprojects"] ||
              "subprojects.label" | transloco) +
            " " +
            ("stakeholders.positions" | transloco | capitaliz)
        }}</span>
      </div>
      <div class="col-xs-12 panel">
        <div class="col-xs-12" *transloco="let t">
          <h3 class="pull-left">{{ participationLevelText }}</h3>
          <div class="input-group hidden pull-right mg-10">
            <input
              type="text"
              *ngIf="matrices && matrices.power_type_label"
              class="form-control input-sm"
              disabled=""
              [(ngModel)]="matrices.power_type_label.name"
            />
          </div>
          <div class="pull-right mg-10" *ngIf="suggestion < 0">
            <mat-icon>error</mat-icon>
            <span
              class="mr-10 suggestion-warning"
              [matTooltip]="t('text.fill_in_interest_matrix')"
            >
              <a [projectRouterLink]="['analysis']">
                <i>{{ t("text.no_suggestion_by_interest_matrix") }}</i>
              </a>
            </span>
          </div>
        </div>
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="power"
          [(update)]="updateChart"
          [oneToOne]="true"
          class="col-xs-12"
        >
        </highcharts-chart>
      </div>
    </div>
    <div *ngIf="!showInterests && showStrategyField" class="col-xs-12">
      <div class="col-xs-12 panel">
        <div class="col-xs-12">
          <h3 class="pull-left">{{ approachStrategyText }}</h3>
          <div class="input-group hidden pull-right mg-10">
            <input
              type="text"
              *ngIf="matrices && matrices.trust_type_label"
              class="form-control input-sm"
              disabled=""
              [(ngModel)]="matrices.trust_type_label.name"
            />
          </div>
        </div>
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="trust"
          [oneToOne]="true"
          [(update)]="updateChart"
          class="col-xs-12"
        >
        </highcharts-chart>
      </div>
    </div>
  </div>
  <popper-content #popperInterestTotal>
    <ng-container *transloco="let t; read: 'issue_interest'">
        <div class="popper" style="padding:20px;">
            <div>
                <h4>{{ ('reusable.interest' | transloco) }}</h4>
            </div>
            <div class="pr-10">
                <div style="display: flex;">
                    <div class="mr-20" style="display: inline-block; text-align: center;">
                        <mat-icon class="uil uil-chart-pie-alt" style="display: inline-block; vertical-align: center;"></mat-icon>
                        <span style="display: block">{{'reusable.interest' | transloco}}</span>
                    </div>
                    <div *ngIf="editedTotalMatrix">
                        <mat-button-toggle-group [(ngModel)]="editedTotalMatrix.currentValue">
                            <mat-button-toggle mat-flat-button [value]="i-1" class="block" *ngFor="let interest of interests_labels; let i=index" style="padding-left: 18px;" [ngClass]="{'uil-exclamation': ((stakeholderMatrixValues?.suggest_power_interest)/25 | number :'1.0-0') == i-1}" [style.background-color]="interest.color" (click)="applyEditTotal(i-1, 'interest')"></mat-button-toggle>
                        </mat-button-toggle-group>
                        <div style="display: flex;">
                            <div class="block" *ngFor="let interest of interests_labels">{{ interest.label? ('reusable.' + interest.label | transloco) : ''}}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <mat-icon class="uil uil-exclamation"></mat-icon>
                    <span>{{ 'reusable.interest_level' | transloco }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</popper-content>
<popper-content #popperTensionTotal>
    <ng-container *transloco="let t; read: 'issue_interest'">
        <div class="popper" style="padding:20px;">
            <div>
                <h4>{{ isStakeholderNew? ('reusable.tension' | transloco) : ('reusable.type_of_interest' | transloco) }}</h4>
            </div>
            <div class="pr-10">
                <div style="display: flex;">
                    <div class="mr-20" style="display: inline-block; text-align: center;">
                        <mat-icon class="uil uil-temperature-half" style="display: inline-block; vertical-align: center;"></mat-icon>
                        <span style="display: block">{{isStakeholderNew? ('reusable.tension' | transloco) : ('reusable.type_of_interest' | transloco)}}</span>
                    </div>
                    <div *ngIf="editedTotalMatrix">
                        <mat-button-toggle-group [(ngModel)]="editedTotalMatrix.currentValue">
                            <mat-button-toggle mat-flat-button [value]="i-1" class="block" *ngFor="let tension of tensions; let i=index" style="padding-left: 18px;" [ngClass]="{'uil-exclamation': ((100-stakeholderMatrixValues?.suggest_trust_interest)/25 | number :'1.0-0') == i-1}" [style.background-color]="tension.color" (click)="applyEditTotal(i-1, 'tension')"></mat-button-toggle>
                        </mat-button-toggle-group>
                        <div style="display: flex;">
                            <div class="block" *ngFor="let tension of tensions">{{ isStakeholderNew? (tension.label? ('reusable.' +  tension.label | transloco) : '') : tension.label_old? ('reusable.' +  tension.label_old | transloco) : ''}}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <mat-icon class="uil uil-exclamation"></mat-icon>
                    <span>{{ isStakeholderNew? ('reusable.tension_level_new' | transloco) : ('reusable.tension_level' | transloco) }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</popper-content>
<popper-content #popperInfluence>
    <ng-container *transloco="let t; read: 'issue_interest'">
        <div class="popper" style="padding:20px">
            <div>
                <h4>{{ isStakeholderNew? ('reusable.influence' |transloco) : ('reusable.power' | transloco)}}</h4>
            </div>
            <div class="pr-10">
                <div class= "mt-20" style="display: flex;">
                    <div class="mr-20" style="display: inline-block; text-align: center;">
                        <mat-icon class="uil uil-megaphone" style="display: inline-block; vertical-align: center;"></mat-icon>
                        <span style="display: block">{{ isStakeholderNew? ('reusable.influence' |transloco) : ('reusable.power' | transloco) }}</span>
                    </div>
                    <div *ngIf="editedTotalMatrix">
                        <mat-button-toggle-group [(ngModel)]="editedTotalMatrix.currentValue">
                            <mat-button-toggle mat-flat-button [value]="i-1" class="block" *ngFor="let influence of influences; let i=index" [style.background-color]="influence.color" (click)="applyEditTotal(i-1, 'influence')"></mat-button-toggle>
                        </mat-button-toggle-group>
                        <div style="display: flex;">
                            <div class="block" *ngFor="let influence of influences">{{ influence.label? ('reusable.' + influence.label | transloco) : ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</popper-content>

<popper-content #popperTrust>
    <ng-container *transloco="let t; read: 'issue_interest'">
        <div class="popper" style="padding:20px">
            <div>
                <h4>{{'reusable.trust' | transloco}}</h4>
            </div>
            <div class="pr-10">
                <div class= "mt-20" style="display: flex;">
                    <div class="mr-20" style="display: inline-block; text-align: center;">
                        <mat-icon class="uil uil-feedback" style="display: inline-block; vertical-align: center;"></mat-icon>
                        <span style="display: block">{{'reusable.trust' | transloco}}</span>
                    </div>
                    <div *ngIf="editedTotalMatrix">
                        <mat-button-toggle-group [(ngModel)]="editedTotalMatrix.currentValue">
                            <mat-button-toggle mat-flat-button [value]="i-1" class="block" *ngFor="let trust of trusts; let i=index" [style.background-color]="trust.color" (click)="applyEditTotal(i-1, 'trust')"></mat-button-toggle>
                        </mat-button-toggle-group>
                        <div style="display: flex;">
                            <div class="block" *ngFor="let trust of trusts">{{ trust.label? ('reusable.' + trust.label | transloco) : ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</popper-content>


