import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { TranslocoService, translate } from "@jsverse/transloco";
import { AppInjector } from 'src/app-injector';
import { Stakeholder } from "src/app/akita/stakeholders/state/stakeholder.model";
import { ActionsService } from "src/app/akita/tasks/state/actions.service";
import { EmployeesService } from "src/app/core/service/api/employees.service";
import { overpassApiSetGroups, overpassApiSetGroupsTypes } from "src/app/core/util/mapModal";
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { ActiveLocation } from "../../../../../akita/active-locations/state/active-loacations.model";
import { StakeholdersService } from '../../../../../core/service/api/stakeholders.service';
import { Globals } from "../../../../../globals";
import { ItemDetailComponent } from "../item-detail/item-detail.component";
import collect from "collect.js";
@Component({
  selector: "app-items-list",
  templateUrl: "./items-list.component.html",
  styleUrls: ["./items-list.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsListComponent implements OnInit {
  object = Object;
  public selected: Number;
  public overPassAPIStakeholder:Stakeholder;
  public overPassAPIChecker:any=[];
  Pontential_Stakeholder: boolean = false;
  backLink: boolean = true;
  stakeholders: ActiveLocation[];
  issues: ActiveLocation[];
  complaints: ActiveLocation[];
  decisions: ActiveLocation[];
  calendars: ActiveLocation[];
  residents: ActiveLocation[];
  stakeholdersFilter: ActiveLocation[];
  issuesFilter: ActiveLocation[];
  complaintsFilter: ActiveLocation[];
  decisionsFilter: ActiveLocation[];
  calendarsFilter: ActiveLocation[];
  residentsFilter: ActiveLocation[];
  Pontential_StakeholderList = [];
  Pontential_StakeholderFilter = [];
  ArcGsiLayers = [];
  ArcGsiLayersVesiblity: boolean = false;
  openLayerData = [];
  openLayerDataFilter = [];
  searchBar: boolean = true;
  permissionCreate: boolean = false;
  public employee:any;
  public overpassGroups:any[]=[];
  public enableLayers = [];
  public selectionItems: any[] = [];
  public featureLayerLater:any;
  public allSelected: boolean = false;
  visibleFeatures: any[] = [];
  pageSize = 50;
  currentPage = 0;
  searchKeyword: string = '';

  @Output() itemClick: EventEmitter<any> = new EventEmitter();
  @Output() relationItemClick: EventEmitter<any> = new EventEmitter();
  @Output() itemHover: EventEmitter<any> = new EventEmitter();
  @Output() itemOpenLayerHover: EventEmitter<any> = new EventEmitter();
  @Output() itemOpenLayerArcGisHover: EventEmitter<any> = new EventEmitter();
  @Output() openLayerDataFullForm: EventEmitter<any> = new EventEmitter();
  @Output() openLayerDataArcGisFullForm: EventEmitter<any> = new EventEmitter();
  @Output() allRemoveSidePanel: EventEmitter<any> = new EventEmitter();
  @Output() overPassDataZoom: EventEmitter<any> = new EventEmitter();
  @Input() features: any;
  @Input() sidebar: any;
  @ViewChild(ItemDetailComponent, { static: false })
  public itemdetail: ItemDetailComponent;
  constructor(
    private cd: ChangeDetectorRef,
    public globals: Globals,
    private _translocoService: TranslocoService,
    private _stakeholdersService: StakeholdersService,
    private _employeesService: EmployeesService,
    private _actionsService: ActionsService,
    public route:Router,
  // eslint-disable-next-line no-empty-function
  ) {}

  public ngOnInit(): void {
    if(this._actionsService.subOverPass==undefined){
      this._actionsService.subOverPass=this._actionsService.invokeFunctionOverPass.subscribe((response)=>{
        this.overPassStakeholderCreation(response);
        this.featureLayerLater=response;
        this.setFromDetail(response);
        this._actionsService.subOverPass=undefined;
      });
    }
    if(this.globals.permissions.includes('stakeholders:create')){
      this.permissionCreate=true;
    }
    this.stakeholderChecker();
    this.enableLayers = this.globals.enableLayers;
    this.features.subscribe(
      (data) => {
        this.stakeholders = data.filter(
          (l) => l.locatable_type == "stakeholders"
        );
        this.stakeholdersFilter = data.filter(
          (l) => l.locatable_type == "stakeholders"
        );
        this.issues = data.filter((l) => l.locatable_type == "issues");
        this.issuesFilter = data.filter((l) => l.locatable_type == "issues");
        this.complaints = data.filter((l) => l.locatable_type == "complaints");
        this.complaintsFilter = data.filter(
          (l) => l.locatable_type == "complaints"
        );
        this.decisions = data.filter((l) => l.locatable_type == "requirements");
        this.decisionsFilter = data.filter(
          (l) => l.locatable_type == "requirements"
        );
        this.calendars = data.filter((l) => l.locatable_type == "calendars");
        this.calendarsFilter = data.filter(
          (l) => l.locatable_type == "calendars"
        );
        this.residents = data.filter((l) => l.locatable_type == "residents");
        this.residentsFilter = data.filter(
          (l) => l.locatable_type == "residents"
        );
        this.cd.detectChanges();
      },
      (err) => console.error(err)
    );
  }


  onItemClick(item, zoom = true) {
    if (item) {
      this.selected = item.id;
      this.backLink = true;
      this.searchBar = false;
      this.itemdetail.getData(item);
      this.cd.detectChanges();
      if (zoom) this.itemClick.emit(item);
    }
  }

  public onItemHover(item) {
    this.itemHover.emit(item);
  }
  public itemOpenlayerHover(item) {
    this.itemOpenLayerHover.emit(item);
  }
  public itemOpenlayerHoverArcGis(item) {
    this.itemOpenLayerArcGisHover.emit(item);
  }
  public openLayerFull(item) {
    this.openLayerDataFullForm.emit(item);
  }
  public openLayerArcGisFull(item) {
    this.openLayerDataArcGisFullForm.emit(item);
  }
  openDataClick(feature, connectbutton) {
    this.selected = 1;
    this.backLink = false;
    this.searchBar = false;
    this.itemdetail.showOpenData(feature, connectbutton);
    this.cd.detectChanges();
  }
  filterLayer(keyword) {
    this.searchKeyword = keyword;
    if(keyword){
      this.stakeholders = this.stakeholdersFilter.filter((key) => {
        return key.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.issues = this.issuesFilter.filter((key) => {
        return key.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.complaints = this.complaintsFilter.filter((key) => {
        return key.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.decisions = this.decisionsFilter.filter((key) => {
        return key.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.calendars = this.calendarsFilter.filter((key) => {
        return key.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.residents = this.residentsFilter.filter((key) => {
        return key.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.Pontential_StakeholderList = this.Pontential_StakeholderFilter.filter((key) => {
        return key.title.toLowerCase().includes(keyword.toLowerCase());
      });
      const temp = [];
      this.openLayerDataFilter.forEach((element)=> {
        temp.push({layer:element.layer,visibility:element.visibility, data: [...element.data],visibleFeatures:[...element.data.filter((el)=>{
          return el.title.toLowerCase().includes(keyword.toLowerCase());
        })]})
      });
      this.openLayerData=temp;
    }else{
      this.stakeholders = this.stakeholdersFilter;
      this.issues = this.issuesFilter;
      this.complaints = this.complaintsFilter;
      this.decisions = this.decisionsFilter;
      this.calendars = this.calendarsFilter;
      this.residents = this.residentsFilter;
      this.Pontential_StakeholderList = this.Pontential_StakeholderFilter;                
      this.loadFeatures();
    }
  }
  hideSerch() {
    this.searchBar = true;
  }
  onBack() {
    this.selected = 0;
    this.itemdetail.reset();
    this.cd.detectChanges();
  }
  isSelected(event, item) {
    if(event.checked){
      this.selectionItems = [...collect(this.selectionItems).push(item).all()];
    }else{
      this.selectionItems = collect(this.selectionItems).filter((value) => value.layer_id != item.layer_id).all();
      this.allSelected = false;
    }
  }
  masterSelected(event, layer) {
    let data;
    this.openLayerData.forEach(item => {
      if (item.layer === layer) {
        data = item.visibleFeatures;
      }
    })
    
    if(event.checked) {
      data.forEach(item => item.isSelected = true);
      this.selectionItems = [...data];
    } else {
      data.forEach(item => item.isSelected = false);
      this.selectionItems = [];
    }
  }

  loadItemsList(created: any, data) {
    if (created) {
      this.allSelected = false;
      if (data) {
        data.forEach(item => item.isSelected = false);
        this.selectionItems = [];
      }
      this.cd.detectChanges();
    }

  }
  getOpenDataLayerName(layer, visibility, feature) {
    if (feature) {
      feature.forEach(item => {item.isSelected = false});
      if (visibility) {
        if (!this.openLayerData.some((f) => f.layer === layer)) {
          this.openLayerData.push({
            layer: layer,
            data: feature,
            visibility: visibility,
            visibleFeatures: feature.length > 50 ? feature.slice(this.currentPage,this.pageSize*this.currentPage+1) : feature
          });
          this.openLayerDataFilter.push({
            layer: layer,
            data: feature,
            visibility: visibility,
            visibleFeatures: feature.length > 50 ? feature.slice(this.currentPage,this.pageSize*this.currentPage+1) : feature
          });
          this.loadFeatures();
          this.cd.detectChanges();
          this.ngOnInit();
        } else {
          this.currentPage = 0;
          this.loadFeatures();
        }
      } else {
        const removeIndex = this.openLayerData.map((item) => {
          return item.layer;
        }).indexOf(layer);
        this.openLayerData.splice(removeIndex, 1);
        this.openLayerDataFilter.splice(removeIndex, 1);
        this.ngOnInit();
        if (this.openLayerData.length == 0) {
          this.allRemoveSidePanel.emit();
        }
      }
    }
    this.cd.detectChanges();
  }
  onLayernameGet(layer, visibility, feature) {
    switch (layer) {
      case "Pontential_Stakeholder":
        this.allreadyExist(feature);
        this.Pontential_Stakeholder = visibility;
        let sortedArray=overpassApiSetGroups(feature);
        this.overpassGroups=overpassApiSetGroupsTypes(feature);
        sortedArray= feature.sort((a, b) => {
          if(a.values.naam){
            return a.values.naam.localeCompare(b.values.naam);
          }
        });
        this.Pontential_StakeholderList = sortedArray;
        this.Pontential_StakeholderFilter = sortedArray;
        this.ngOnInit();
        break;
      default:
    }
  }
  onClickOverPassDataZoom(feature){
    this.overPassDataZoom.emit(feature)
  }
  public overPassStakeholderCreation(feature){
    this.overPassAPIStakeholder={
      name:feature.values.naam,
      potential_stakeholders_id:feature.values.id,
      locations:{
        locatable_type:'stakeholders',
        points:{
          type: 'MultiPoint',
          coordinates: [
            [parseFloat(feature.values.lon), parseFloat(feature.values.lat)],
          ]
        }
      }
    }
    this._stakeholdersService.submitStakeholder(this.overPassAPIStakeholder).subscribe(res=>{
      if(feature.values.phone || feature.values.phone){
        this.saveEmployee(res,feature);
      }
      const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
      if (snackbar) {
        snackbar.openFromComponent(UndoSnackbarComponent, {
          data: {
            data:res,
            message: translate('error.stored'),
            type:'openItems',
            item:'overpassstakeholders',
            url:'stakeholders'
          },
          duration: 4000,
          horizontalPosition: 'start',
          panelClass: 'snackbar-background-green',
      });
      }
      feature.exist=true;
      feature.values.exist=true;
      feature.stakeholder=res;
      feature.values.stakeholder=res;
      this.cd.detectChanges();
    })
  }
  public saveEmployee(stakeholder,feature){
    this.employee = {
      title: '',
      first_name: 'Algemeen contact',
      last_name: stakeholder.name,
      function: null,
      phone: feature.values.phone?feature.values.phone:'',
      email: feature.values.email?feature.values.email:'',
      note: null,
      stakeholder:[stakeholder],
      stakeholders_id: null,
      stakeholders: [stakeholder],
    };
    this._employeesService.submitEmployee(this.employee).subscribe();
  }
  public stakeholderChecker(){
    this._stakeholdersService.getStakeholderForOberPass().subscribe((res:any)=>{
      res.forEach(element => {
        if(element.potential_stakeholders_id)
        this.overPassAPIChecker.push(element);
      });
    });
  }
  public allreadyExist(feature){
    feature=feature.filter(element => {
      this.overPassAPIChecker.forEach(ele => {
        if(ele.potential_stakeholders_id===element.values.id){
          element.exist=true;
          element.values.exist=true;
          element.stakeholder=ele;
          element.values.stakeholder=ele;
        }
      });
    });
  }
  public setFromDetail(feature){
    this.Pontential_StakeholderList=this.Pontential_StakeholderList.filter(element => {
      if(feature.values.id==element.values.id){
        element.exist=true;
        element.values.exist=true;
        return element;
      }
        return element;

    });
    this.cd.detectChanges();
  }
  itemDetailChange(items) {
    this.relationItemClick.emit(items);
  }

  onScroll(event: Event, layer: string) {
    const target = event.target as HTMLElement;
    // Check if user has scrolled to the bottom
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
  
    // if (scrollTop === 0) {
    //   // Load more data when scrolling to the top
    //   this.currentPage >0 ? this.currentPage--: 0;
    //   this.loadFeatures(layer);
    // }
    
    if (scrollTop + clientHeight >= scrollHeight) {
      // Load more data when scrolling to the bottom
      this.currentPage++;
      this.loadFeatures();
    }
  }

  loadFeatures() {
    if (!this.searchKeyword) {
      const startIndex = this.currentPage * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.openLayerData.forEach((item, index) =>{
        item.visibleFeatures = item.data.slice(0, endIndex);

        // this.openLayerData[index].visibleFeatures.push(...item.data.slice(startIndex, endIndex));

        let visibleFeatures = this.openLayerData[index].visibleFeatures;
        const uniqueIds = {};
        // Filter out duplicate layer_ids
        visibleFeatures = visibleFeatures.filter(feature => {
            // layer_id is undefined don't filter it out
            if (!feature.layer_id) {
              return true;
            }

            // If the layer_id is not in the uniqueIds object, add it and return true to keep it in the filtered array
            if (!uniqueIds[feature.layer_id]) {
              uniqueIds[feature.layer_id] = true;
              return true;
            }
            // If the layer_id is already in the uniqueIds object, return false to remove it from the filtered array
            return false;
        });
        this.openLayerData[index].visibleFeatures = visibleFeatures;

        // check for the appropriate feature name and add first key value as fallback
        if (visibleFeatures && visibleFeatures.length) {
          visibleFeatures.forEach((f,index) => {
            visibleFeatures[index]["title"] = f.title ? f.title : '';
            if (f.values) {
              let fallbackKey = '';
              for (const key of Object.keys(f.values)) {
                if (key !== 'geometry' && f.values[key]) {
                  fallbackKey = key;
                  break; // Exit the loop as soon as a valid key is found
                }
              }
              visibleFeatures[index]["title"] = f.title? f.title :
                f.values.name ? f.values.name :
                f.values.id ? f.values.id :
                f.values[fallbackKey]? f.values[fallbackKey] : index;
            }
          })
        }
      });
    }
    this.cd.detectChanges();
  }
}
