import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import {
  TagLocationReturnValue,
  tagExpandable
} from "@objectiv/tracker-browser";
import { makeId } from "@objectiv/tracker-core";
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { SubprojectsService } from 'src/app/akita/subproject/state/subprojects.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import {
  StakeholdersDetailComponent
} from 'src/app/features/main-app/stakeholders/page/stakeholders-detail/stakeholders-detail.component';
import { EmployeeEditComponent } from 'src/app/shared/components/modal/contact-persons-edit/employee-edit.component';
import { MultiselectOptionType } from '../../../../../core/enum/multiselect-option-type';
import { deepCopy } from '../../../../../core/util/deepCopy';
import {
  GenericMultiselectCheckboxOptionAllComponent
} from '../generic-multiselect-checkbox-option-all/generic-multiselect-checkbox-option-all.component';
import {
  GenericMultiselectCheckboxOptionNewActionComponent
} from '../generic-multiselect-checkbox-option-new-action/generic-multiselect-checkbox-option-new-action.component';
import {
  GenericMultiselectCheckboxOptionComponent
} from '../generic-multiselect-checkbox-option/generic-multiselect-checkbox-option.component';
import {
  GenericMultiselectSearchProjectComponent
} from '../generic-multiselect-search-project/generic-multiselect-search-project.component';
import { translate } from '@jsverse/transloco';

export interface GenericMultiselectOption {
  content: string;
  value: any;
  checked: boolean;
  optionType: MultiselectOptionType;
  remainingOptions?: any;
}

@Component({
  selector: 'app-generic-multiselect-checkbox',
  templateUrl: './generic-multiselect-checkbox.component.html',
  styleUrls: ['./generic-multiselect-checkbox.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: GenericMultiselectCheckboxComponent,
    multi: true
  }, {
    provide: NG_VALIDATORS,
    // eslint-disable-next-line no-use-before-define
    useExisting: forwardRef(() => GenericMultiselectCheckboxComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMultiselectCheckboxComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy {

  public MultiselectOptionType = MultiselectOptionType;
  @Input() public uniqueId: string = '';
  @Input() public placeholder: string = '';
  @Input() public name: string = '';
  @Input() public value: any;
  @Input() public required: boolean = false;
  @Input() public multiple: boolean = true;
  @Input() public valueType: string = 'array';
  @Input() public hint: string;
  @Input() public comparatorKey: string = 'id';
  @Input() public shownItems: number = 1000;
  @Input() public maxChipItems: number = 10;
  @Input() public maxChipLength: number;
  @Input() public maxChipMessage: string = '+ {count} meer items ...';
  @Input() public showChips: boolean = true;
  @Input() public type: string;
  @Input() public parentType: string;
  @Input() public parent: any;
  @Input() public disabled: boolean = false;
  @Input() public hideCancleIcon: boolean = false;
  @Input() public chipDisabler: any = [];
  @Input() public selectReferUser: boolean = false;
  @Input() public parentLocationTaggingAttributes: any;

  // eslint-disable-next-line no-empty-function
  public onChange: (value: any[]) => void = value => { };
  // eslint-disable-next-line no-empty-function
  public onTouched: (touched: boolean) => void = touched => { };
  // eslint-disable-next-line no-empty-function
  public onValidatorChange: () => void = () => { };
  public isDisabled = false;
  public selfSelector: any;

  @ContentChildren(GenericMultiselectCheckboxOptionComponent, { descendants: true }) optionsCmp: QueryList<GenericMultiselectCheckboxOptionComponent>;
  @ContentChild(GenericMultiselectCheckboxOptionAllComponent) optionAllCmp: GenericMultiselectCheckboxOptionAllComponent;
  @ContentChild(GenericMultiselectCheckboxOptionNewActionComponent) newActionCmp: GenericMultiselectCheckboxOptionNewActionComponent;
  @ContentChild(GenericMultiselectSearchProjectComponent) searchProjectCmp: GenericMultiselectSearchProjectComponent;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) mySelect: MatAutocompleteTrigger;
  /**
   * This extra option used to store new value that is create on the fly (GenericMultiselectCheckboxOptionNewActionComponent)
   * Currently it's not possible to programmatically append child in content children (optionsCmp) thus we need an extra variable
   * to hold value of newly added entity
   * @type {any[]}
   */
  private extraOptions: GenericMultiselectOption[] = [];

  protected options: GenericMultiselectOption[] = [];
  public totalCount: any
  public filteredOptions: GenericMultiselectOption[] = [];
  public searchResponse$: Observable<GenericMultiselectOption[]>;
  public selectedOptions: GenericMultiselectOption[] = [];
  public form: FormGroup;
  public showAddAction: boolean = false;
  public showSearchProject: boolean = false;
  public showSelectAll: boolean = false;
  public subProjects: boolean = false;
  public selectAllOption: GenericMultiselectOption;
  public addActionOption: GenericMultiselectOption;
  public newEntriesArray: any;
  public valuearrray = [];
  public searchType: string;
  public AvatarCustomStyle =
    {
      "border-width": "3px",
      "font-weight": "bold",
      "width": "25px",
      "height": "25px",
      "font": "bold 10px / 20px Helvetica, Arial, sans-serif"
    };
  private optionsSubscription: Subscription;
  public multiSelectOverlay: TagLocationReturnValue;
  public remainingOptions: GenericMultiselectOption[];

  constructor(private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public pubsub: PubsubService,
    private _subprojectsService: SubprojectsService,
    private _subProjectsQuery: SubprojectQuery,
    private dialog: MatDialog,
    private _cachedService: CachedService,
    // eslint-disable-next-line no-empty-function
  ) { }

  public ngOnInit(): void {


    this.placeholder = this.required ? `${this.placeholder}*` : this.placeholder;
    this.form = this.formBuilder.group({
      text: [''],
    });

    if (this.multiple || this.valueType !== 'object') {
      this.valueType = 'array';
    }

    this.form.get('text').valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(150),
      )
      .subscribe(input => this.filterOptionsByKeyword());

    this.multiSelectOverlay = tagExpandable({
      id: makeId(`multi-select:${this.name}`),
      options: {
        trackVisibility: false,
        parent: this.parentLocationTaggingAttributes
      }
    });
  }
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, no-empty-function
  public ngOnDestroy(): void {
  }

  private filterOptionsByKeyword(): void {
    this.onTouched(true);

    const input = this.form.get('text').value;

    if (!input) {
      this.filteredOptions = Array.from(this.options);
      this.truncateFilteredOptions();
      this.showAddAction = false;
      this.showSearchProject = false;
      return;
    }

    if (typeof input !== 'string') {
      this.filteredOptions = Array.from(this.filteredOptions);
      this.truncateFilteredOptions();
      this.form.get('text').setValue(null);

      return;
    }

    this.filteredOptions = this.options.filter(option => option.content.toLowerCase().includes(input.toLowerCase()));
    this.truncateFilteredOptions();

    if (this.filteredOptions.length === 0) {
      if (this.newActionCmp) {
        this.newActionCmp.setValue(input);
        this.addActionOption = {
          value: this.newActionCmp.value,
          content: this.newActionCmp.content,
          checked: false,
          optionType: this.newActionCmp.optionType,
        };
        this.showAddAction = true;
      }

      if (this.searchProjectCmp) {
        this.showSearchProject = true;
        this.searchType = this.searchProjectCmp.entityText;
        // allow search only when user on project level has atleat editor role
        const userProjectLevelRoles: any[] = this.searchProjectCmp.userProjectLevelRoles();
        if (userProjectLevelRoles.length > 0)
          this.searchResponse$ = this.searchProjectCmp.search(input);
      }
    } else {
      if (this.newActionCmp) {
        this.showAddAction = false;
      }

      if (this.searchProjectCmp) {
        this.showSearchProject = false;
      }
    }

    if (this.options.length === 0 || (this.filteredOptions.length === 0 && this.optionAllCmp)) {
      this.showSelectAll = false;
    } else if (this.filteredOptions.length > 0 && this.optionAllCmp) {
      this.showSelectAll = true;
    }

    this.cdRef.detectChanges();
  }

  public resetInput(): void {
    this.filterOptionsByKeyword();
  }

  private truncateFilteredOptions(): void {
    if (this.filteredOptions.length > this.shownItems) {
      this.filteredOptions = this.filteredOptions.splice(0, this.shownItems);
    }
  }

  public ngAfterViewInit(): void {
    this.optionsSubscription = this.optionsCmp.changes.subscribe(() => {
      this.initializeAutocomplete(true);
    });

    this.initializeAutocomplete();
  }

  private initializeAutocomplete(reload: boolean = false): void {
    if (this.options.length > 0 && !reload) {
      return;
    } else if (reload) {
      this.options = deepCopy(this.extraOptions);
      this.filteredOptions = deepCopy(this.extraOptions);
    }

    if (this.optionsCmp && this.options) {
      this.optionsCmp.forEach((option: GenericMultiselectCheckboxOptionComponent) => {
        if (this.valueType === 'array' && Array.isArray(this.value)) {
          option.checked = !!this.value.find((v: any) => v[this.comparatorKey] === option.value[this.comparatorKey]);
        } else if (this.valueType === 'object' && this.value) {
          option.checked = this.value[this.comparatorKey] === option.value[this.comparatorKey];
        } else {
          option.checked = false; // Handle undefined or incorrect type
        }

        const exist = this.options.some((o: GenericMultiselectOption) => {
          return o.value[this.comparatorKey] === option.value[this.comparatorKey];
        });

        if (!exist) {
          this.options.push({
            content: option.content,
            value: option.value,
            checked: option.checked,
            optionType: option.optionType,
          });
        }

        if (this.filteredOptions.length < this.shownItems) {
          this.filteredOptions.push({
            content: option.content,
            value: option.value,
            checked: option.checked,
            optionType: option.optionType,
          });
        }

        if (option.checked) {
          const exist = this.selectedOptions.some((o: GenericMultiselectOption) => {
            if (!o.value || !option.value) {
              return false;
            }

            return o.value[this.comparatorKey] === option.value[this.comparatorKey];
          });

          if (!exist) {
            this.selectedOptions.push(option);
          }
        }
      });

      this.cdRef.detectChanges();
    }

    if (this.optionAllCmp) {
      this.selectAllOption = {
        value: this.optionAllCmp.value,
        content: this.optionAllCmp.content,
        checked: false,
        optionType: this.optionAllCmp.optionType,
      };

      if (this.filteredOptions.length > 0) {
        this.showSelectAll = true;
      }
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = (touched: boolean) => {
      if (this.required && (!this.value
        || (this.valueType === 'array' && this.value.length === 0)
        || (this.valueType === 'object' && !this.value[this.comparatorKey]
        ))
      ) {
        this.form.get('text').setErrors({
          required: true
        });
      }

      this.form.get('text').markAsTouched();

      fn(touched);
      this.cdRef.detectChanges();
    };
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('text').disable();
      this.isDisabled = true;
    } else {
      this.form.get('text').enable();
      this.isDisabled = false;
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.errors) {
      if (!this.required && control.errors.required) {
        return null;
      } else if (this.required && this.value &&
        ((this.valueType === 'array' && this.value.length > 0) || (this.valueType === 'object' && this.value[this.comparatorKey]))
      ) {
        return null;
      } else if (this.required && control.errors.required) {
        this.form.get('text').setErrors({
          required: true
        });
      }

      return control.errors;
    }

    return null;
  }

  public writeValue(obj: any[]): void {
    if (!obj) {
      if (this.valueType === 'array') {
        obj = [];
      } else {
        obj = null;
      }
    }

    this.value = obj;

    if (this.options) {
      // Re-initialize the selected options
      this.options.forEach((option: GenericMultiselectOption) => {
        if (this.value && this.valueType === 'array' && Array.isArray(this.value)) {
          option.checked = !!this.value.find((o: any) => o[this.comparatorKey] === option.value[this.comparatorKey]);
        } else if (this.value && this.valueType === 'object') {
          option.checked = this.value[this.comparatorKey] === option.value[this.comparatorKey];
        } else {
          option.checked = false; // Default case when this.value is undefined or not matching the type
        }
      });

      // this.initializeAutocomplete(true);
      if (!this.extraOptions || !this.extraOptions.length) {
        this.initializeAutocomplete(true);
      }
      this.updateSelectedCheckedStatus(false);
    }
  }

  public selectSearchedOption(clickedOption: GenericMultiselectOption): void {
    if (!this.searchProjectCmp) {
      return;
    }

    this.form.get('text').reset();
    const isAdd = this.searchProjectCmp.selectOption(clickedOption);
    if (isAdd) {
      this.selectedOptions.push(clickedOption);
      if (this.valueType === 'array') {
        this.value.push(clickedOption.value);
      } else {
        this.value = clickedOption.value;
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(o => o.value.id !== clickedOption.value.id);

      if (this.valueType === 'array') {
        this.value = this.value.filter(o => o.value.id !== clickedOption.value.id);
      } else {
        this.value = null;
      }
    }

    this.onChange(this.value);
  }

  public toggleOption(clickedOption: GenericMultiselectOption, event?: Event): void {
    if (event && this.multiple) {
      event.stopPropagation();
      this.autoTrigger.openPanel(); // Ensure the dropdown stays open
    }
    this.form.get('text').reset();
    if (clickedOption.optionType === MultiselectOptionType.entry || clickedOption.optionType === MultiselectOptionType.project_search) {
      // If it's one value only, remove all existing check and assign with the newly selected checbox
      if (!clickedOption.checked && (
        (!this.multiple && this.valueType === 'array' && this.value && this.value.length > 0) || this.valueType === 'object' && this.value && this.value[this.comparatorKey])
      ) {
        this.options.forEach(option => option.checked = false);
        this.filteredOptions.forEach(option => option.checked = false);
      }

      const foundOption = this.options.find(option => option.value[this.comparatorKey] === clickedOption.value[this.comparatorKey]);
      const foundExtraOption = this.extraOptions.find(option => option.value[this.comparatorKey] === clickedOption.value[this.comparatorKey]);

      if (this.multiple) {
        this.autoTrigger.openPanel(); // keep autocomplete open
      }

      if (clickedOption.optionType !== MultiselectOptionType.project_search) {
        foundOption.checked = !clickedOption.checked; // toggle the value
      }

      if (foundExtraOption) {
        foundExtraOption.checked = foundOption.checked;
      }

      this.resetOptionalAllCheckbox();
      this.updateSelectedCheckedStatus();

      if (!this.value || !this.options) {
        return;
      }

      if (this.value.length >= this.options.length) {
        this.autoTrigger.closePanel() // close autocomplete panel because all of them are selected
      }
    } else if (clickedOption.optionType === MultiselectOptionType.new && this.newActionCmp) {
      this.showAddAction = false;

      // If it's one value only, remove all existing check and assign with the newly selected checbox
      if (!clickedOption.checked && (
        (!this.multiple && this.valueType === 'array' && this.value && this.value.length > 0) || this.valueType === 'object' && this.value && this.value[this.comparatorKey])
      ) {
        this.options.forEach(option => option.checked = false);
        this.filteredOptions.forEach(option => option.checked = false);
      }

      this.newActionCmp.create().subscribe((newEntries: any) => {
        if (newEntries.data) {
          this.newEntriesArray = newEntries.data.reduce((prev, current) => (+prev.id > +current.id) ? prev : current);
          this.valuearrray = [{ "id": this.newEntriesArray.id, "name": this.newEntriesArray.name }]
        }
        else if (Object.keys(newEntries).length > 1) {
          this.newEntriesArray = newEntries.reduce((prev, current) => (+prev.id > +current.id) ? prev : current);
          this.valuearrray = [{ "id": this.newEntriesArray.id, "name": this.newEntriesArray.name }]
        }
        else {
          this.valuearrray = newEntries;
        }
        const newOptions: GenericMultiselectOption[] = this.valuearrray.map((entry: any) => {
          return {
            value: entry,
            content: this.newActionCmp.input,
            checked: true,
            optionType: MultiselectOptionType.entry,
          };
        });

        this.options = [...this.options, ...newOptions];
        this.extraOptions = [...this.extraOptions, ...newOptions];
        this.filteredOptions = Array.from(this.options);
        this.updateSelectedCheckedStatus();
      });

      this.resetOptionalAllCheckbox();
    } else if (clickedOption.optionType === MultiselectOptionType.all) {
      // clickedOption.checked = !clickedOption.checked;
      if (this.value.length < this.options.length) {
        this.options.forEach(option => {
          if (option.content == "Selecteer stakeholder(s) accounthouder") {
            option.checked = false
          } else {
            option.checked = true
          }
        });
        this.filteredOptions.forEach(option => {
          if (option.content == "Selecteer stakeholder(s) accounthouder") {
            option.checked = false
          } else {
            option.checked = true
          }
        });
        this.value = this.options.map(option => {
          if (option.content !== "Selecteer stakeholder(s) accounthouder") {
            return option.value
          }
        });
        this.value = this.value.filter((option) => {
          return option !== undefined;
        });
        if (this.valueType === 'object') {
          this.value = this.value[0];
        }
        this.selfSelector = this.options.filter(option => {
          if (option.checked == true) {
            return option;
          }
        });
        this.showSelectedOptions(this.selfSelector);
      } else {
        this.options.forEach(option => option.checked = false);
        this.filteredOptions.forEach(option => option.checked = false);
        if (this.valueType === 'array') {
          this.value = [];
        } else {
          this.value = null;
        }
        this.selectedOptions = [];
      }
      if (this.value && this.options && this.selectAllOption) { 
        if (this.value.length >= this.options.length) {
          this.selectAllOption.content = translate('deselect_all');
        } else {
          this.selectAllOption.content = translate('select_all');
        }
      }

      this.markChangeTouched();
      this.autoTrigger.closePanel();

    }

    this.cdRef.detectChanges();
  }

  public removeOption(option: GenericMultiselectOption): void {
    const foundOption = this.options.find(o => option.value[this.comparatorKey] === o.value[this.comparatorKey]);
    if (foundOption) {
      foundOption.checked = false;
    }

    const extraFoundOption = this.extraOptions.find(o => option.value[this.comparatorKey] === o.value[this.comparatorKey]);
    if (extraFoundOption) {
      extraFoundOption.checked = false;
    }
    this.updateSelectedCheckedStatus();
    this.mySelect.closePanel();
  }

  public updateSelectedCheckedStatus(markChangedTouched: boolean = true): void {
    if (this.options.length === 0) {
      return;
    }

    const selected: GenericMultiselectOption[] = [];

    this.options.forEach(option => {
      const foundFiltered: GenericMultiselectOption = this.filteredOptions.find(o => o.value[this.comparatorKey] === option.value[this.comparatorKey]);
      if (foundFiltered) {
        foundFiltered.checked = option.checked;
      }

      if (option.checked) {
        selected.push(option);
      }
    });

    this.showSelectedOptions(selected);
    this.value = selected.map(option => option.value);
    if (this.valueType === 'object') {
      this.value = this.value[0];
    }

    if (markChangedTouched) {
      this.markChangeTouched();
    }

    if (this.optionAllCmp && this.filteredOptions.length > 0) {
      this.showSelectAll = true;
    }

    if (this.value && this.options && this.selectAllOption) { 
      if (this.value.length >= this.options.length) {
        this.selectAllOption.content = translate('deselect_all');
      } else {
        this.selectAllOption.content = translate('select_all');
      }
    }

    this.cdRef.detectChanges();
  }

  private resetOptionalAllCheckbox(): void {
    if (this.optionAllCmp) {
      this.optionAllCmp.checked = false;
    }
  }

  showSelectedOptions(options: GenericMultiselectOption[]): void {
    const copy: GenericMultiselectOption[] = deepCopy(options);
    if (copy.length <= 4) {
      this.selectedOptions = copy;
    } else {
      const maxChipItems = Math.max(0, 4);
      this.selectedOptions = copy.slice(0, maxChipItems);
      this.remainingOptions = copy.slice(maxChipItems);
      this.selectedOptions.push({
        content: `${this.maxChipMessage.replace('{count}', (copy.length - this.maxChipItems).toString())}`,
        value: null,
        checked: true,
        optionType: MultiselectOptionType.moremarker,
        remainingOptions: this.remainingOptions.map(option => option.content).join(', '),
      });
    }
  }

  private markChangeTouched(): void {
    if (typeof this.onChange === 'function') {
      this.onChange(this.value);
    }
    if (typeof this.onTouched === 'function') {
      this.onTouched(true);
    }
    this.form.get('text').markAsTouched();
  }

  clickOption(data) {
    if (['stakeholders'].includes(this.type) && data !== null) {
      let component: any = StakeholdersDetailComponent;
      let size = ModalEnum.SidebarLargeResponsive;
      if (this.type == 'contactpersons') {
        component = EmployeeEditComponent;
        size = ModalEnum.SidebarSmallResponsive;
        this._cachedService.getEmployeesWithLogs()
          .subscribe(employees => {
            data = employees.find(e => e.id === data.id);
            this.nav(data, component, size)
          });
      } else {
        this.nav(data, component, size)
      }
    } else {
      this.mySelect.openPanel();
    }
  }

  nav(data, component, size) {
    this.pubsub.closeModal(this.pubsub.currentDialog)
    const modal = this.dialog.open(component, modalConfig({
      data: data,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
      closeOnNavigation: true
    }, size));
    let name = data.name;
    if (this.type == 'contactpersons') {
      name = `${data.first_name || ""} ${data.last_name || ""}`
    }
    this.pubsub.updateHistory(modal, component, data, name, size)
  }

  formatTooltip(options: any) {
    const values = options.split(',');

    let list = '';

    values.forEach((line: any) => {
      list += `• ${line}\n`;
    });

    return list;
  }

}
