import moment from "moment-timezone";
import {Globals} from "../../globals";
import {AppInjector} from "../../../app-injector";

export function dateIso(date: string|number|Date, utcToLocal: boolean = false, timezone? : string): Date|any {

  if (!date) {
    return date;
  }

  if (!utcToLocal) {
    return moment(date).toDate();
  } 
    if (!timezone) {
      const globals:Globals = AppInjector ? AppInjector.get(Globals) : null;
      if (!globals) {
        return moment.utc(date).toDate();
      }

      return moment.utc(date).tz(globals.timezone).toDate();
    }

    return moment.utc(date).tz(timezone).toDate();
  
}
