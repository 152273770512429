import { HttpXsrfTokenExtractor } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FileUploader } from "ng2-file-upload";
import { ProjectConfigs } from "../../../../../../../akita/project-configs/state/project-configs.model";
import { ProjectConfigsService } from "../../../../../../../akita/project-configs/state/project-configs.service";
import { AuthConstants } from "../../../../../../../auth-constants";
import { GenericComponent } from "../../../../../../../core/util/abstract/generic-component";
import { showFeedbackSaved } from "../../../../../../../core/util/notification";
import { Globals } from "../../../../../../../globals";

@Component({
  selector: "app-personalisation-modal-report",
  templateUrl: "./personalisation-modal-report.component.html",
  styleUrls: ["./personalisation-modal-report.component.sass"],
})
export class PersonalisationModalReportComponent
  extends GenericComponent
  implements OnInit {
  public reportForm: FormGroup;
  public logoUploader: FileUploader;
  public logoPreview: string;
  public color: string;

  constructor(
    public globals: Globals,
    private formBuilder: FormBuilder,
    private _xsrfTokenExtractor: HttpXsrfTokenExtractor,
    private authConstant: AuthConstants,
    private _configsService: ProjectConfigsService
  ) {
    super(globals);
  }

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      title: [""],
      logo_path: [
        {
          value: "",
          disabled: true,
        },
      ],
      color: [""],
      color_display: [""],
    });

    this.logoUploader = new FileUploader({
      url: "/api/configs/report/logo",
      itemAlias: "image",
      headers: [
        {
          name: "X-XSRF-TOKEN",
          value: this._xsrfTokenExtractor.getToken(),
        },
        {
          name: "X-Project",
          value: this.globals.projectCode,
        },
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem('token')}`,
        },
      ],
    });

    this._configsService.get().subscribe((response: ProjectConfigs) => {
      this.reportForm.get("title").setValue(response["report.title"]);

      if (response["report.logo_path"]) {
        this.logoPreview = response["report.logo_path"];
      }

      this.color = `#${ response["report.color"]}` || "#2980b9";
      this.reportForm.get("color").setValue(this.color);
      this.reportForm.get("color_display").setValue(this.color);
    });
  }

  public fileAdded(file: FileList) {
    this.reportForm.get("logo_path").setValue(file.item(0).name);
    this.getBase64(file.item(0));
  }

  public updateConfig() {
    const params = this.globals.projectConfigs;

    this.reportForm.get("color").setValue(this.color);
    for (const key in this.reportForm.value) {
      if (key !== "logo_path" && key !== "color_display") {
        params[`report.${key}`] = this.reportForm.value[key];
      }

      if (key === "color") {
        params[`report.${key}`] = this.reportForm.value[key].replace("#", "");
      }
    }
    this._configsService.update("report", params).subscribe(() => {
      this.logoUploader.uploadAll();
      showFeedbackSaved();
    });
  }

  private getBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => (this.logoPreview = <string>reader.result);
  }
}
