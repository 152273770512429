<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
      <h1>
        <strong>{{ t("admin_page.integration") | titlecase }} </strong
        ><span class="">{{ t("settings.imap.email_inbox") }}</span>
      </h1>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon> </span
        ><span class="sr-only">Close</span>
      </button>
    </div>
  </div>
  <div class="popup-body" appScroll>
    <div class="col-xs-12 mb-5">
      <h5>{{ t("settings.connection") | titlecase }}</h5>

      <div class="full-width set-border-connection-relatic mb-10">
        <div class="row">
          <div class="col-md-12 display-grid">
            <div class="subtext">
              {{ t("settings.imap.imap_info_subtext") }}
            </div>
            <form [formGroup]="myForm">
              <div class="row display-flex-reposive mt-10">
                <div class="col-md-6 col-sm-6 col-xs-6 display-grid align-set">
                  <mat-form-field>
                    <input
                      type="text"
                      matInput
                      class="full-width"
                      formControlName="email_server"
                      [placeholder]="'settings.imap.email_server' | transloco"
                      required

                    />
                    <mat-error *ngIf="myForm.get('email_server').hasError('required')">
                      Server is required
                    </mat-error>
                    <mat-error *ngIf="!myForm.get('email_server').errors?.required && myForm.get('email_server').value && myForm.get('email_server').invalid">
                      Server must not start with "https://"
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  class="col-md-6 col-sm-10 col-xs-10 display-grid align-set"
                >
                  <mat-form-field>
                    <input
                      type="number"
                      matInput
                      class="full-width"
                      formControlName="port"
                      [placeholder]="'settings.imap.port' | transloco"
                    />
                    <mat-error *ngIf="myForm.get('port').hasError('notNumber')"
                      >Port must be a number</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row display-flex-reposive mt-10">
                <div class="col-md-6 col-sm-6 col-xs-6 display-grid align-set">
                  <mat-form-field>
                    <input
                      type="text"
                      matInput
                      class="full-width"
                      formControlName="username"
                      [placeholder]="'settings.imap.username' | transloco"
                      autocomplete="off"
                      required
                    />
                    <mat-error *ngIf="myForm.get('username').hasError('email')"
                      >Username must be a valid email</mat-error
                    >
                  </mat-form-field>
                </div>
                <div
                  class="col-md-6 col-sm-10 col-xs-10 display-grid align-set"
                >
                  <mat-form-field>
                    <input
                      [type]="typeInput"
                      autocomplete="off"
                      matInput
                      class="full-width"
                      formControlName="password"
                      [placeholder]="'settings.imap.password' | transloco"
                      required
                    />
                    <button
                    style="font-size: 18px;"
                    mat-icon-button
                    matSuffix
                    (click)="togglePasswordVisibility()"
                    [attr.aria-label]="'Toggle password visibility'"
                  >
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="display-flex" style="float: right;">
                <button
                  type="button"
                  *ngIf="
                    !globals.projectConfigs['services.integration.imap.enabled'] && !foldersSuccess
                  "
                  class="align-set btn btn-primary color-btn-primary-map mb-20"
                  (click)="encryptPassword()"
                  [disabled]="!myForm.valid"
                >
                  <span class="ng-star-inserted">{{
                    "settings.imap.verify" | transloco
                  }}</span>
                </button>
                <div
                  class="row display-flex mb-20"
                  *ngIf="
                    globals.projectConfigs['services.integration.imap.enabled'] && foldersSuccess
                  "
                >
                  <div class="align-set display-flex ng-star-inserted mr-5">
                    <mat-icon
                      role="img"
                      class="mat-icon notranslate successfully-message d-text-middle material-icons mat-icon-no-color"
                      aria-hidden="true"
                      data-mat-icon-type="font"
                      >check_circle</mat-icon
                    ><span _ngcontent-iok-c445="" class="align-set">
                      {{ "settings.imap.email_verified" | transloco }}</span
                    >
                  </div>
                  <div class="col align-self-center">
                    <button
                      type="button"
                      class="align-set btn btn-outline-primary"
                      (click)="verifyDataConfigs('disable')"
                    >
                      <span class="ng-star-inserted">{{
                        "settings.imap.disable" | transloco
                      }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <mat-progress-bar *ngIf="showSpinner" mode="indeterminate"></mat-progress-bar>
        <div
          class="row"
          *ngIf="
          globals.projectConfigs['services.integration.imap.enabled'] && foldersSuccess
        "
        >
          <div class="col-md-12 display-grid">
            <div class="subtext">
              {{ t("settings.imap.select_folder") }}
            </div>
            <div class="col-md-12 col-sm-6 col-xs-6 display-grid align-set">
              <mat-form-field>
                <mat-select [placeholder]="'settings.imap.select_inbox' | transloco"  [(value)]="selectedInBoxFolder" (selectionChange)="onFolderSelectionChangeInbox($event.value)">
                  <ng-container *ngFor="let folder of folders">
                    <mat-optgroup *ngIf="folder.children && folder.children.length > 0" [label]="folder.name">
                      <mat-option *ngFor="let subfolder of folder.children" [value]="subfolder.path">
                        {{ subfolder.name }}
                      </mat-option>
                    </mat-optgroup>
                    <mat-option [value]="folder.path">{{ folder.name }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <!-- OUTBOX -->
              <mat-form-field>
                <mat-select [placeholder]="'settings.imap.select_outbox' | transloco" [(value)]="selectedOutBoxFolder" (selectionChange)="onFolderSelectionChangeOutbox($event.value)">

                  <ng-container *ngFor="let folder of FolderOutBox">
                    <mat-optgroup *ngIf="folder.children && folder.children.length > 0" [label]="folder.name">
                      <mat-option *ngFor="let subfolder of folder.children" [value]="subfolder.path">
                        {{ subfolder.name }}
                      </mat-option>
                    </mat-optgroup>
                    <mat-option [value]="folder.path">{{ folder.name }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-xs-12 mb-5"
      *ngIf="globals.projectConfigs['services.integration.imap.enabled'] && foldersSuccess"
    >
      <h5>{{ t("settings.imap.additional_settings") | titlecase }}</h5>
      <div class="full-width set-border-connection-relatic mb-10">
        <div class="row">
          <div class="col-md-12 display-grid">
            <div class="subtext mb-5">
              {{ "settings.imap.where_email_added" | transloco }}
            </div>
            <div>
              <mat-button-toggle-group
                [(ngModel)]="smartReportingAs"
                (ngModelChange)="updateSyncBouwappProject($event)"
              >
                <mat-button-toggle mat-flat-button value="contact_moments">{{
                  "contactmoments.label" | transloco | capitaliz
                }}</mat-button-toggle>
                <mat-button-toggle mat-flat-button value="complaints" *ngIf="modulesAvailable">{{
                  "complaints.label" | transloco | capitaliz
                }}</mat-button-toggle>
              </mat-button-toggle-group>
              <br />
              <div class="subtext mb-5">
              {{ "settings.imap.note" | transloco }}
            </div>
            </div>
          </div>
          <!-- subProjects -->
          <div class="col-md-12 display-grid" *ngIf="programModule">
            <div class="subtext mb-5">
              {{ "settings.imap.optional_subject" | transloco }}
            </div>
            <div class="col-xs-12">
              <mat-form-field>
                <mat-select
                  multiple
                  [(ngModel)]="selectedSubprojects"
                  (ngModelChange)="updateSubprojects($event)"
                  [placeholder]="'settings.imap.subproject' | transloco
                  "
                >
                  <mat-option
                    *ngFor="let subproject of globals.subprojects"
                    [value]="subproject.name"
                  >
                    {{ subproject.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
