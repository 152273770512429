<div class="col-xs-12 no-padding text-left top-btns-overview">
    <div class="d-xs-mb15 d-mt8 mt-9 pull-right">
        <div class="btn-group dialog-button-row inline-block">
            <app-report-button type="issue_overview"></app-report-button>
            <a *hasPermission="[Permission.IssuesCreate]" newEntity="issues" (onClosed)="issueAdded($event)" mat-flat-button>
                <i class="d-text-middle uil uil-plus-circle unicon-icon"></i>
                <span class="hidden-xs hidden-sm">Issue</span>
            </a>
        </div>
    </div>
</div>

<div class="col-xs-12 no-padding tool-box">
    <div class="search-overview">
        <div class="panel search-holder col-xs-7">
            <i *ngIf="loading" class="uil uil-spinner-alt uil-icon spinner-animation"></i>
            <i *ngIf="!loading" class="uil uil-search uil-icon"></i>
            <input class="search-box" [(ngModel)]="keyword" placeholder="{{'text.search_table' | transloco}}" value="" (keyup)="applyFilter($event.target.value)">
            <i class="uil uil-times uil-icon" *ngIf="keyword" (click)="cancelSearch()"></i>
        </div>
    </div>
    <div *ngIf="keyword && !issues.filteredData.length" class="search-create-item-overlay">
        <b>
            <span newEntity="issues" [newEntityParams]="{name: keyword}" class="truncate-ellipsis">{{ 'create_item' | transloco}} '{{keyword | truncatie: 40}}' {{ 'automatically' | transloco}}</span>
        </b>
    </div>
    <app-batch-overview-setting type="issues" [filters]="exportFilter" overviewType="issue" [paginator]="paginator" modal="overview" [items]="selectionItems" (onloadItem)="loadIssues($event)"></app-batch-overview-setting>
</div>


<div class="col-xs-12 table-scrolling-set no-padding text-left box-shadow overview-margin-bottom-space mt-12">
    <table class="overview-panel set-border-firefox-table overview-filter-widget-overflow" mat-table [dataSource]="issues">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" [sticky]="stickyEnabled">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox class="d-cursor-hand th mr-9" (change)="masterSelected($event.checked)" [(ngModel)]="onSelectAll" *ngIf="batchLoad">
            </mat-checkbox>
            <span class="fa-spin loader" *ngIf="!batchLoad">
            </span>
          </th>
          <td mat-cell *matCellDef="let issue">
            <mat-checkbox (change)="isSelected($event,issue)" [(ngModel)]="issue.checked">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                <span class="d-cursor-hand th mr-9">ID</span>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-md-1" (click)="nav(issue)"><span>{{ issue.id}}</span></td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>
                <span class="d-cursor-hand th mr-6" (click)="sortId.toggle()">#</span>
                <app-filter-sort #sortId [paginator]="paginator" [sortField]="'id'" (onApply)="loadIssues()" [hideSortLabels]="true" class="app-filter-sort right inline-block"></app-filter-sort>
            </th>
            <td mat-cell *matCellDef="let issue" (click)="nav(issue)">{{issue.code}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['name']">
                <span class="d-cursor-hand mr-6" (click)="sortName.toggle()">{{ 'issues.label' | transloco | capitaliz}}</span>
                <app-filter-sort #sortName [paginator]="paginator" [sortField]="'name'" (onApply)="loadIssues()" class="app-filter-sort inline-block"></app-filter-sort>
                <div class="resize-handle" (mousedown)="startResize($event, 'name')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" (click)="nav(issue)"><a><span class="truncate-ellipsis">
            {{ issue.name }}</span></a>
            </td>
        </ng-container>
        <ng-container matColumnDef="stakeholders">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['stakeholders']"> 
                <span>{{ 'stakeholders.labels' | transloco | capitaliz}}</span>
                <div class="resize-handle" (mousedown)="startResize($event, 'stakeholders')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-md-4 ellipsis-2" (click)="nav(issue)">
                <span class="type-list truncate-ellipsis">
                    <span *ngFor="let s of issue.stakeholders; last as isLast">{{s.name}}<span *ngIf="!isLast">,</span></span>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="rationale">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['rationale']">
                <span>Redeneerlijn</span>
                <div class="resize-handle" (mousedown)="startResize($event, 'rationale')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" (click)="nav(issue)"> <span class="truncate-ellipsis">
          {{issue.rationale}}</span> </td>
        </ng-container>
        <ng-container matColumnDef="themeissues.name">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['themeissues.name']">
                <span class="d-cursor-hand th mr-6" [class.active]="paginator._filters['themeissues.name'] && paginator._filters['themeissues.name'].length" (click)="filterThemes.toggle()">{{ 'theme' | transloco | capitaliz}}</span>
                <app-filter-sort #filterThemes [paginator]="paginator" [filters]="availableFilters" [filterFields]="['themeissues.name']" (onApply)="loadIssues()" class="app-filter-sort inline-block"></app-filter-sort>
                <div class="resize-handle" (mousedown)="startResize($event, 'themeissues.name')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-md-4 ellipsis-2" (click)="nav(issue)">
              <span class="type-list truncate-ellipsis">
                <span *ngFor="let theme of issue.themeissues; last as isLast">{{theme.name}}<span *ngIf="!isLast">,</span></span>
              </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="workflow">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['workflow']">
              <span class="badge">NEW</span>
                <span class="d-cursor-hand th mr-6" [class.active]="paginator._filters['permitsTypes.name'] && paginator._filters['permitsTypes.name'].length" (click)="filterStrategies.toggle()">Workflow</span>
                <app-filter-sort #filterStrategies [paginator]="paginator" [filters]="availableFilters" [filterFields]="['workflows']" [hideSortLabels]="false" (onApply)="loadIssues()" class="app-filter-sort"></app-filter-sort>
                <div class="resize-handle" (mousedown)="startResize($event, 'workflow')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-sm-1 col-xs-1 hidden-xs ellipsis-text3" (click)="nav(permit)">
                <span class="type-list truncate-ellipsis">
                    <span *ngFor="let workflow of issue.workflows; last as isLast">{{workflow.name}}<span *ngIf="!isLast">,</span></span>


                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="relations">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let stakeholder" class="icons" (click)="nav(stakeholder)">
                <mat-icon *ngIf="stakeholder.entitiesCount == 1" [matBadge]="stakeholder.relationsTotal" matBadgeSize="small" class="relation-count" [matTooltip]="stakeholder.relationsTotal + ' ' + ['relations.open' | transloco] + ' ' + ['relations.label' | transloco]">{{stakeholder.relationIcon}}</mat-icon>
                <mat-icon *ngIf="stakeholder.entitiesCount > 1" [matBadge]="stakeholder.relationsTotal" matBadgeSize="small" class="relation-count" [matTooltip]="stakeholder.relationsTotal + ' ' + ['relations.open' | transloco] + ' ' + ['relations.labels' | transloco]">more_horizon</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="relatics">
            <th mat-header-cell *matHeaderCellDef>
                <span class="d-cursor-hand th mr-6"></span>
            </th>
            <td mat-cell *matCellDef="let issue" class="icons" (click)="nav(issue)">
                <ul class="list-intable">
                    <a class="td-link no-style" *ngIf="issue.relatics_uuid">
                        <ngx-avatars size="30" [matTooltip]="'Relatics'" textSizeRatio="2.5" src="../../../../assets/png/integrations/overviewrelatics.png">
                        </ngx-avatars>
                    </a>
                </ul>
            </td>
        </ng-container>
        <ng-container matColumnDef="subprojects">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let issue" (click)="nav(issue)">
                <a *ngIf="issue.subprojects && issue.subprojects.length > 1" [matTooltip]="issue.subprojects.length.toString() + ' ' + [this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco]" class="td-link no-style">
                    <ngx-avatars *ngIf="hasModule('subprojects')" [value]="issue.subprojects.length.toString()" size="25" [style]="AvatarCustomStyle" initialsSize="2" textSizeRatio="2.5" borderColor="#e3e3e3" bgColor="#FFFFFF" fgColor="#0f0f0f">
                    </ngx-avatars>
                </a>
                <a *ngIf="issue.subprojects && issue.subprojects.length == 1" [matTooltip]="issue.subprojects[0].name" class="td-link no-style">
                    <ngx-avatars *ngIf="hasModule('subprojects')" [name]="issue.subprojects[0].name" size="25" [style]="AvatarCustomStyle" initialsSize="2" textSizeRatio="2.5" [borderColor]="issue.subprojects[0].color" fgColor="#000000" bgColor="#FFFFFF">
                    </ngx-avatars>
                </a>
            </td>
        </ng-container>
        <!-- Tension -->
        <ng-container matColumnDef="tension">
          <th mat-header-cell *matHeaderCellDef>{{globals.projectConfigs['project.stakeholder_strategy.is_new'] ? ('custom-columns.tension' | transloco | capitaliz) : ('reusable.type_of_interest' | transloco | capitaliz)}}</th>
          <td mat-cell *matCellDef="let issue" class="col-md-4 ellipsis-2" (click)="nav(issue)">
              <span class=" ellipsis-2">
                  <span *ngFor="let s of issue.issues_stakeholder_matrices; last as isLast">{{s.tension ? s.tension : 0}}<span *ngIf="!isLast">,</span></span>
              </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef class="text-center">
                <app-filter-sort #filterUsers [paginator]="paginator" [filters]="availableFilters" [filterFields]="['users.name']" (onApply)="loadIssues()" class="app-filter-sort inline-block">
                </app-filter-sort>
            </th>
            <td mat-cell *matCellDef="let issue" (click)="nav(issue)">
                <a *ngIf="issue.users && issue.users.length > 1" [matTooltip]="issue.users.length.toString() + ' ' + ['users.labels' | transloco]" class="td-link no-style">
                    <ngx-avatars [value]="issue.users.length.toString()" size="25" initialsSize="2" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f">
                    </ngx-avatars>
                </a>
                <a *ngIf="issue.users && issue.users.length == 1" [matTooltip]="issue.users[0].name" class="td-link no-style">
                    <ngx-avatars [name]="issue.users[0].name" size="25" initialsSize="2" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f">
                    </ngx-avatars>
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['description']">
                <span class="d-cursor-hand th mr-6">{{'custom-columns.description' | transloco | capitaliz }}</span>
                <div class="resize-handle" (mousedown)="startResize($event, 'description')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-md-3">
                <span class="truncate-ellipsis">{{issue.description}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="reason_closure">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['reason_closure']">
                <span class="d-cursor-hand th mr-6">{{'custom-columns.reason_closure' | transloco | capitaliz }}</span>
                <div class="resize-handle" (mousedown)="startResize($event, 'reason_closure')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-md-3">
                <span class="truncate-ellipsis">{{issue.rationale}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="deadline">
            <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['deadline']">
                <span class="d-cursor-hand th mr-6" >Deadline</span>
                <div class="resize-handle" (mousedown)="startResize($event, 'deadline')"></div>
            </th>
            <td mat-cell *matCellDef="let issue" class="col-md-3">
                <span class="truncate-ellipsis">{{issue.deadline | formatdate : 'dd-MM-yyyy'}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="map_relations">
            <th mat-header-cell *matHeaderCellDef>
                <span class="d-cursor-hand th mr-6" >{{'custom-columns.map_relations' | transloco | capitaliz}}</span>
            </th>
            <td mat-cell *matCellDef="let issue" style="text-align: center;">
                <span>
                    <span *ngIf="issue.layers.length" >{{issue.layers.length}}</span>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="urgency" [stickyEnd]="stickyEnabled">
            <ng-container>
                <th mat-header-cell *matHeaderCellDef>
                    <span class="d-cursor-hand th mr-9" [class.active]="paginator._filters['urgency'] && paginator._filters['urgency'].length" (click)="filterUrgency.toggle()">{{ 'issues.urgency' | transloco | capitaliz}}</span>
                    <app-filter-sort [class.hidden]="statusFilter == 'gesloten'" #filterUrgency [paginator]="paginator" [filters]="availableFilters" [filterFields]="['urgency']" [sortField]="'urgency'" [hideSortLabels]="true" (onApply)="loadIssues()" class="app-filter-sort inline-block"></app-filter-sort>
                </th>
                <td mat-cell *matCellDef="let issue" class="urgency" (click)="nav(issue)">
                    <div *ngIf="statusFilter != 'gesloten'">
                        <p [class]="issue.urgency" *transloco="let t; read: 'issues'" style="margin-bottom: 0px;">
                            {{ t(issue.urgency) }}
                        </p>
                    </div>
                    <div *ngIf="statusFilter == 'gesloten'" class="issue-closed">
                        Gesloten
                    </div>

                </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="action" [stickyEnd]="stickyEnabled">
            <th mat-header-cell *matHeaderCellDef class="text-center d-cursor-hand">
              <ng-container *hasPermission="[Permission.IssuesRestore]">
                <div class="icon-container">
                  <mat-icon *ngIf="!loading" mat-icon-button [matMenuTriggerFor]="menu" (click)="menuToggle = !menuToggle" class="uil uil-ellipsis-v"></mat-icon>
                  <span class="red-dot" ></span> <!-- Red dot element -->
                 </div>
                <mat-menu #menu="matMenu" class="mat-menu">
                    <button mat-menu-item class="mat-menu-item" (click)="menuToggle = !menuToggle">
                        <app-recycle-bin-icon *ngIf="!loading" [icon]="true" item="issues" (onClosed)="loadIssues()"></app-recycle-bin-icon>
                    </button>
                    <button mat-menu-item class="mat-menu-item" (click)="showColumnOptions = !showColumnOptions">
                        <span class="menu-option-title"><mat-icon class="uil uil-columns unicon-icon"></mat-icon>{{'custom_columns' | transloco}}</span>
                        <span class="badgeColumn">NEW</span>
                    </button>
                </mat-menu>
                <app-custom-columns *ngIf="showColumnOptions" [optionalColumns]="optionalColumns" [type]="'issues'" (selectdColumns)="columnsUpdated($event)" (closeDropdown)="closeOptColModal($event)"></app-custom-columns>
                <span class="fa-spin loader" *ngIf="loading"></span>
              </ng-container>
            </th>
            <td mat-cell *matCellDef="let issue; let index = index">
                <span *hasPermission="[Permission.IssuesDelete]" class="delete-button" matTooltip="{{'reusable.delete' | transloco}}">
                <mat-icon (click)="deleteIssue(issue)" class="uil uil-trash d-flex" [class]="'issues-delete-' + ( index + 1 )"></mat-icon>

        </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="empty">
            <td colspan="50" mat-cell *matFooterCellDef>
                <div *ngIf="!loading" class="empty-oc-height-set w-100 text-center empty-padding">
                    <div>
                        <img src="assets/png/empty-state-no-issues.png" class="empty-state-image">
                        <h2>{{'dashboard.nodata.myworkload_header_issues' | transloco}}</h2>
                        <div *hasPermission="[Permission.IssuesCreate]" >
                            <p class="pb-10">{{'dashboard.nodata.myworkload_description_issues' | transloco}}</p>
                            <a (onClosed)="loadIssues()" newEntity="issues" [newEntityParams]="{users : [globals.user], name: keyword}" mat-flat-button>
                                <i class="d-text-middle uil uil-plus-circle unicon-icon"></i>
                                <span class="hidden-xs hidden-sm">Issue</span>
                            </a>
                        </div>
                    </div>
                </div>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </td>
        </ng-container>
        <ng-container matColumnDef="footer">
          <td colspan="50" mat-cell *matFooterCellDef class="pagination-top-border">
            <mat-paginator class="table-pagination-set list-view-paginator" [length]="paginator.total" [pageSize]="paginator.count" [pageSizeOptions]="pageSizeOptions" (page)="getServerPage($event)" [tagPressable]="{ id: 'table-paginator' }">
            </mat-paginator>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="['empty']" [class]="issues.filteredData.length > 0 ? 'hidden' : ''"></tr>
        <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr>

    </table>
</div>

