import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validation-notification',
  templateUrl: './validation-notification.component.html',
  styleUrls: ['./validation-notification.component.css']
})
export class ValidationNotificationComponent {

  @Input() form;
  @Input() input;
  @Input() isRequired: boolean;
  @Input() isValidated: boolean;
  @Input() textErrorRequired: string;
  @Input() textErrorValidation: string;

  // eslint-disable-next-line no-empty-function
  constructor() { }

}
