import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { OutlookService } from 'src/app/core/service/api/outlook.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { DynamicService } from 'src/app/core/service/common/dynamic.service';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { SidebarEmployeesComponent } from '../../sidebar-employees/sidebar-employees.component';
import { MatDialog } from '@angular/material/dialog';
import { SidebarEmployees } from 'src/app/pubsub/sidebarEmployees';
import { PaginatedResponse } from 'src/app/core/service/common/paginated-response';
import { EmployeesService } from 'src/app/core/service/api/employees.service';
import { LogEditComponent, LogEditData } from '../contact-moments-edit/log-edit.component';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { FormatdatePipe } from 'src/app/shared/pipes/formatdate.pipe';
import { WithParams } from 'src/app/core/service/common/with-params';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInjector } from 'src/app-injector';
import { UndoSnackbarComponent } from '../../material/undo-snackbar/undo-snackbar.component';

import { DateTime } from 'luxon';
import { GeneralService } from 'src/app/core/service/api/general.service';
import { of } from 'rxjs';

interface Event {
  id: string;
  contactPerson: string;
  subject: string;
  bodyPreview: string;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
  attendees: [];
  attendeesEmails?: [];
  emails: { unregistered: any[], registered: any[]},
  addedAsLog?: boolean;
}
@Component({
  selector: 'app-outlook-calender-inbox',
  templateUrl: './outlook-calender-inbox.component.html',
  styleUrls: ['./outlook-calender-inbox.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutlookCalenderInboxComponent implements OnInit {
  events: Event[] = [];
  disabledStates: boolean[] = [];
  filteredEvents: Event[] = [];
  filteredOptions = [];
  emailArray: any[] = [];
  currentDate: Date = new Date();
  hasPreviousEvents: boolean = true;
  hasNextEvents: boolean = false;
  showlogs: boolean = false;
  displayDate: string | null = null;
  timerClear: any;
  position: any;
  text: string = '';
  new: any;
  public logs;
  public sidebarEmployees: SidebarEmployees = new SidebarEmployees();
  public stakeholders: any[];
  public isLoad:boolean = false;
  public updatedEventId: any;

  constructor(
    private outlookService: OutlookService,
    private cd: ChangeDetectorRef,
    private cachedService: CachedService,
    private _logsService: LogsService,
    public globals: Globals,
    public dynamicService: DynamicService,
    private dialog: MatDialog,
    private _employeesService: EmployeesService,
    private _cachedService: CachedService,
    private pubsub: PubsubService,
    private _generalService: GeneralService
    ) {
      // empty consturctor lint prevent
    }

  ngOnInit(): void {
    if (this.globals.user.configs && this.globals.user.configs.microsoft_enabled) {
      this._logsService.getOutlookLogs().subscribe((response) => {
        const token = localStorage.getItem('outlook_accessToken');
        if (token) {
          const jwtToken = JSON.parse(atob(token.split('.')[1]));
          const expiresAt = new Date(jwtToken.exp * 1000);
          if (Date.now() < expiresAt.getTime()) {
            this.logs = response;
            this.getEvents();
          } else {
            localStorage.removeItem('outlook_accessToken');
            this.globals.user.configs.microsoft_enabled=0;
            this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
          }
        }
      })
    }
  }

  getEvents(): void {
    this.outlookService.getEvents().pipe(
      tap((data: any) => {
        this.events = data.value || []; // Ensure `data.value` is an array
        this.events.forEach(event => {
          event.attendeesEmails = [];
          event.emails = { unregistered: [], registered: [] };
          event.addedAsLog = false;
        });
        this.findDisabledStates(false);
        this.filterEvents('today');
      }),
      catchError(error => {
        this.globals.user.configs.config.connection_error.microsoft= true;
        this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
        return of([]); // Return an empty array to avoid breaking the application
      })
    ).subscribe();
  }

  filterEvents(option: string): void {
    if (!this.isToday || option!=='previous') {
      this.isLoad = false;
      switch (option) {
        case 'today':
          this.currentDate = new Date();
          this.filteredEvents = this.events.filter(event => {
            const eventDate = new Date(event.start.dateTime);
            console.log(eventDate)
            return eventDate.toDateString() === this.currentDate.toDateString();
          }).sort((a, b) => new Date(a.start.dateTime).getTime() - new Date(b.start.dateTime).getTime()); // Sort by date;
          console.log('this.filteredEvents', this.filteredEvents)
          this.displayDate = translate('settings.outlook.calendar.today');
          this.hasPreviousEvents = false;
          break;
        case 'previous':
          this.currentDate.setDate(this.currentDate.getDate() - 1);
          this.filteredEvents = this.events.filter(event => {
            const eventDate = new Date(event.start.dateTime);
            return eventDate.toDateString() === this.currentDate.toDateString();
          }).sort((a, b) => new Date(a.start.dateTime).getTime() - new Date(b.start.dateTime).getTime()); // Sort by date;;
          this.displayDate = this.currentDate.toLocaleDateString();
          this.checkPreviousEvents();
          break;
        case 'next':
          this.currentDate.setDate(this.currentDate.getDate() + 1);
          this.filteredEvents = this.events.filter(event => {
            const eventDate = new Date(event.start.dateTime);
            return eventDate.toDateString() === this.currentDate.toDateString();
          }).sort((a, b) => new Date(a.start.dateTime).getTime() - new Date(b.start.dateTime).getTime()); // Sort by date;;
          this.displayDate = this.currentDate.toLocaleDateString();
          this.hasPreviousEvents = true;
          break;
        default:
          this.filteredEvents = this.events;
          break;
      }
      if (this.filteredEvents.length === 0) {
        const placeholderEvent: any = {
          id: null,
          subject: translate('settings.outlook.calendar.calendar_empty'),
          contactPerson: '',
          start: { dateTime: '00:00' },
          end: { dateTime: '00:00' }
        };
        this.filteredEvents.push(placeholderEvent);
      }
      this.seprateUnRegisteredEmployees();
      this._cachedService.getStakeholders().subscribe(
        stakeholders => {
          this.stakeholders = stakeholders;
          this.sidebarEmployees.stakeholders = stakeholders;
        }
      );
      this.cd.detectChanges();
      this.checkPreviousEvents();
      // this.checkNextEvents();
    }
  }

  get isToday(): boolean {
    const today = new Date();
    return this.currentDate.toDateString() === today.toDateString();
  }


  public showEmployeeSidebar(emails): void {
    this.sidebarEmployees.emails.unregistered = emails;
    const modal = this.dialog.open(SidebarEmployeesComponent, {
      data: this.sidebarEmployees,
      width: '303px',
      height: '100vh',
      maxHeight: '100vh',
      position: {
        right: '0px'
      }
    });

    modal.afterClosed().subscribe((response: { sidebar: SidebarEmployees, stakeholders: any[], employees: any[] }) => {
      if (response) {
        // Distinguish registered/unregistered employees
        this.seprateUnRegisteredEmployees();
      }
    });
  }

  public createEmployees(unregistered, updatedEventId) {
    this.updatedEventId = updatedEventId;
    this.showEmployeeSidebar(unregistered);
  }

  public convertEmailToName(adName: string) {
    const parts = adName.split('@')[0].split('.');
    let firstName = '';
    let lastName = '';

    if (parts.length >= 1) {
      firstName = parts[0];
    }

    if (parts.length === 2) {
      lastName = parts[1];
    }
    // eslint-disable-next-line prefer-template
    const name = firstName + ' ' + lastName;
    return name;
  }

  public seprateUnRegisteredEmployees() {
    this.isLoad = false;
    if (this.filteredEvents.length > 0) {
      this.filteredEvents.forEach((event: any, index) => {
        if (event.id) {
          event.attendeesEmails = [];
          event.emails = { unregistered: [], registered: []};
          event.attendees.forEach(attendee => {
            const name = this.convertEmailToName(attendee.emailAddress.name);
            const address = attendee.emailAddress.address;
            event.attendeesEmails.push({name, address});
          })
          const addresses: string[] = event.attendeesEmails.map(data => data.address);
          if (addresses.length) {
            this._employeesService.findEmployees(addresses)
          .subscribe((response: PaginatedResponse) => {
            let foundEmployeeEmails: any[] = [];
            foundEmployeeEmails = response.data
              .map(employee => employee.email ? employee.email.toLowerCase() : null);
            event.attendeesEmails.forEach(attendee => {
              if (foundEmployeeEmails.filter(e => e === attendee.address).length === 0) { // Check if email is not arleady registered
                event.emails.unregistered.push(attendee);
              } else {
                response.data.forEach((employee: any) => {
                  if(employee.email === attendee.address) {
                    event.emails.registered.push(employee);
                  }
                })
              }
            });
            if(this.updatedEventId === event.id && event.addedAsLog) {
              this.updateContactmoment(event,event.id,index)
            }
            if (this.filterEvents.length === index + 1) {
              this.isLoad = true;
            }
            this.cd.detectChanges();
          })
          } else {
            if(this.updatedEventId === event.id && event.addedAsLog) {
              this.updateContactmoment(event,event.id,index)
            }
            this.isLoad = true;
          }
        }else {
          this.isLoad = true;
        }
      })
    }
  }

  public updateContactmoment(log,eventId,i) {
    // eslint-disable-next-line prefer-template
    const logId = this.logs.filter(log => log.message_id === 'outlook-'+eventId)[0];
    const stakeholders = [];
    this.filteredEvents[i].emails.registered.forEach((employee:any) => {
      if (employee.stakeholders.length) {
        employee.stakeholders.forEach(stakeholder => {
          stakeholders.push(stakeholder);
        })
      }
    })
    this._logsService.getLog(logId.id, new WithParams().with(
      ['stakeholders','contactpersons']
    )).subscribe((res: any) => {
      res.contactpersons = this.filteredEvents[i].emails.registered;
      res.stakeholders = stakeholders;
      this._logsService.updateLog(logId.id, res).subscribe(updatedLog => {
        showFeedbackSaved()
      })
    })
  }

  private checkPreviousEvents(): void {
    const previousDate = new Date(this.currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    this.hasPreviousEvents = this.events.some(event => {
      const eventDate = new Date(event.start.dateTime);
      return eventDate.toDateString() === previousDate.toDateString();
    }) && previousDate < new Date(); // Check if the previous date is earlier than today
  }
  // In Feature May be use
  // private checkNextEvents(): void {
  //   const nextDate = new Date(this.currentDate);
  //   nextDate.setDate(nextDate.getDate() + 1);
  //   this.hasNextEvents = this.events.some(event => {
  //     const eventDate = new Date(event.start.dateTime);
  //     return eventDate.toDateString() === nextDate.toDateString();
  //   });
  // }

  addEmailToArray(email, adName) {
    const parts = adName.split('@')[0].split('.');
    let firstName = '';
    let lastName = '';

    if (parts.length >= 1) {
      firstName = parts[0];
    }

    if (parts.length === 2) {
      lastName = parts[1];
    }
    const name = firstName + lastName
    this.emailArray.push({ email, name });
  }

  createContactmoment(log: Event, i) {
    if (this.disabledStates[i]) {
      // If already disabled, return
      return;
    }
    this.disabledStates[i] = true;
    const stakeholders = [];
    this.filteredEvents[i].emails.registered.forEach((employee:any) => {
      if (employee.stakeholders.length) {
        employee.stakeholders.forEach(stakeholder => {
          stakeholders.push(stakeholder);
        })
      }
    })

    // check for the unregistered email if exists make them contactpersons
    if (log.emails && log.emails.unregistered && log.emails.unregistered.length) {
      log.emails.unregistered.forEach((email: {name: string, address: string}) => {
        this.addEmailToArray(email.address, email.name);
      })

      if (this.emailArray.length > 0) {
        this._employeesService.createEmailEmployees(this.emailArray)
        .subscribe(
          (res: any) => {
            this.filteredEvents[i].emails.registered = [...this.filteredEvents[i].emails.registered, ...res];
            this.filteredEvents[i].emails.unregistered = [];
            this.submitContactmoment(log, stakeholders, i);
          })
      }
    } else {
      this.submitContactmoment(log, stakeholders,i);
    }
  }

  public submitContactmoment(log: any, stakeholders: any[], i: number){
    const logData = {
      users_id: this.globals.user.id,
      // eslint-disable-next-line prefer-template
      message_id: 'outlook-'+log.id,
      contact_moment_type: 'outlook',
      title: log.subject,
      description: log.bodyPreview,
      contact_moment_timestamp: log.start.dateTime,
      stakeholders: stakeholders,
      contactpersons: this.filteredEvents[i].emails.registered
    }
    this._logsService.submitLog(logData)
      .pipe(finalize(() => this.disabledStates[i]))
      .subscribe(
        (log: any) => {
          this._logsService.getOutlookLogs().subscribe((response) => {
            this.logs = response;
            this.findDisabledStates(true);
          })
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:log,
                message: translate('error.stored'),
                type:'openItems',
                item:'contactmomenten',
                url:'stakeholders/contactmomenten'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
        })
  }

  public nav(event){
    // eslint-disable-next-line prefer-template
    if (event.addedAsLog) {
      // eslint-disable-next-line prefer-template
      const log = this.logs.filter(originalMessage => originalMessage.message_id === 'outlook-' + event.id)[0];
      this.editLog(log)
    }
  }

  public editLog(log?: any, event?: MouseEvent, extraParams?: any, extraOptions?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  }): void {
    if (event) {
      event.stopPropagation();
    }

    const data: LogEditData = {
      log: log,
      connectStakeholders: true,
      stakehoderMandatory: false,
      params: extraParams,
      options: extraOptions,
    };
    const component: any = LogEditComponent;
    const size = ModalEnum.SidebarLargeResponsive;
    const panelClass = ['animate__animated', 'animate__slideInRight'];

    const editDialog = this.dialog.open(component, modalConfig({
      data: data,
      panelClass: panelClass,
      disableClose: true,
    }, size));
    const formatDatePipe = new FormatdatePipe(this.globals.locale);
    const name = `${formatDatePipe.transform(log.contact_moment_timestamp, 'dd-MM-yyy')}:${log.title ? log.title : '(Contactmoment zonder titel)'}`
    this.pubsub.updateHistory(editDialog, LogEditComponent, data, name)

    if (editDialog.componentInstance["onUpdated"]) {
      editDialog.componentInstance["onUpdated"].subscribe((log) => {
        console.log('updated...')
      })
    }

    editDialog.afterClosed().subscribe((dialogData: {
      log: any,
      updated: boolean,
      deleted: boolean,
    }) => {
      console.log('closed...')
    });
  }


  findDisabledStates(addLog?: boolean): void {
    if (!addLog) {
      this.events.forEach((event, index) => {
        // eslint-disable-next-line prefer-template
        event.addedAsLog = this.logs.some(originalMessage => originalMessage.message_id === 'outlook-' + event.id);
      });
    } else {
      this.filteredEvents.forEach((event, index) => {
        // eslint-disable-next-line prefer-template
        event.addedAsLog = this.logs.some(originalMessage => originalMessage.message_id === 'outlook-' + event.id);
      });
    }
    this.cd.detectChanges();
  }

}
