import bugsnag from '@bugsnag/js'
import {environment} from "../../../environments/environment.prod";
import {
  Client,
  IConfig,
  IConfigSchema,
  IDelivery,
  ILogger,
  INotifyOpts,
  IPlugin,
  ISessionDelegate,
  NotifiableError
} from '@bugsnag/browser/dist/types/bugsnag-core';
let bugsnagClient: any;
let metadataSet = false;

export function bugsnagFactory(projectCode = null, email = null, name = null): Client {
  try {
    if (bugsnagClient && metadataSet) {
      return bugsnagClient;
    }

    const client = bugsnag(environment.bugsnagKey);
    // TODO: Fix environment checking
    // client.config.releaseStage = environment.production ? 'production' : 'development';
    client.config.releaseStage = location.hostname === 'app.getdialog.nl' ? 'production' : 'development';
    client.config.notifyReleaseStages = ['production'];
    if (projectCode && email && name) {
      client.metaData = {
        project_code : projectCode,
        email: email,
        name: name
      };
      metadataSet = true;
    }

    bugsnagClient = client;
    return client;
  } catch (e) {
    console.warn('Error in starting bugsnag');
  }

  return {
    BugsnagBreadcrumb: undefined,
    BugsnagReport: undefined,
    BugsnagSession: undefined,
    app: undefined,
    config: undefined,
    context: undefined,
    device: undefined,
    metaData: undefined,
    user: undefined,
    configure(opts: IConfigSchema): Client {
      return undefined;
    },
    leaveBreadcrumb(name: string, metaData?: any, type?: string, timestamp?: string): Client {
      return undefined;
    },
    logger(logger: ILogger): Client {
      return undefined;
    },
    sessionDelegate(sessionDelegate: ISessionDelegate): Client {
      return undefined;
    },
    startSession(): Client {
      return undefined;
    },
    getPlugin(name:string): Client {
      return undefined;
    },
    setOptions(opts: IConfig): Client {
      return undefined;
    },
    delivery(delivery: IDelivery): Client {
      return undefined;
    },
    // eslint-disable-next-line no-empty-function
    use(plugin: IPlugin): any {},
    notify(error: NotifiableError, opts?: INotifyOpts): boolean {
      console.error(error);
      return false;
    }
  };
}
