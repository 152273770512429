import {Component, Input} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {ReportComponent} from "../report/report.component";

@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.sass']
})
export class ReportButtonComponent {

  @Input() type = 'management';

  // eslint-disable-next-line no-empty-function
  constructor(public dialog: MatDialog) { }

  public openReportDialog() {
    this.dialog.open(ReportComponent, {
      width: '500px',
      data: this.type,
      panelClass: 'customized-dialog-container'
    })
  }

}
