import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { openPersonalSettingModal } from 'src/app/akita/personal-setting/state/personal.modal';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { Globals } from 'src/app/globals';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TwoFactorAuthenticationComponent } from './components/two-factor-authentication/two-factor-authentication.component';

@Component({
  selector: 'app-personal-setting-edit',
  templateUrl: './personal-setting-edit.component.html',
  styleUrls: ['./personal-setting-edit.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalSettingEditComponent implements OnInit {
  public showIndicidualProject: boolean=false;
  public twoFactorStatus: boolean=false;
  public activeTab: number=0;
  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PersonalSettingEditComponent>,private dialog: MatDialog,public globals: Globals,) { }

  ngOnInit(): void {
    this.twoFactorStatus=this.data ? this.data.status : this.globals.user.is_mfa_enabled;
  }
  //on close
  public onClose(): void{
    this.dialogRef.close();
  }
  //on select indicidual project
  public onSelectIndicidualProject(): void{
    this.showIndicidualProject=true;
  }
  //on click go to previous state
  public previousState(): void{
    this.showIndicidualProject=false;
    this.activeTab=1;
  }
  //on click two factor authentication modal
  public onClickTwoFactorAuthenticationModal(): void{
    this.onClose();
    const dialog = this.dialog.open(TwoFactorAuthenticationComponent, modalConfig({
      data: this.data ? {status: this.data.status, viaSettings: true} : {status: this.twoFactorStatus, viaSettings: true},
      disableClose: true,
      closeOnNavigation: true,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));
    dialog.afterClosed().subscribe((response: any) => {
      if(response){
        openPersonalSettingModal(this.dialog,{status:response.status});
      }
    });
  }
  //on click change password modal
  public onClickChangePasswordModal(): void{
    this.onClose();
    const dialog = this.dialog.open(ChangePasswordComponent, modalConfig({
      // data:this.data,
      disableClose: true,
      closeOnNavigation: true,
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));
    dialog.afterClosed().subscribe((response: any) => {
      // openPersonalSettingModal(this.dialog);

    });
  }
}
