import { StakeholdersDetailComponent } from '../../features/main-app/stakeholders/page/stakeholders-detail/stakeholders-detail.component';
import { ModalEnum, modalConfig } from './modalConfig';
import { TabIssueDetailGeneralComponent } from '../../features/main-app/issues/page/issues-detail/tab-issue-detail-general/tab-issue-detail-general.component';
import { ActionEditComponent } from '../../shared/components/modal/tasks-edit/action-edit.component';
import { KlantwensenDetailComponent } from '../../features/main-app/requirements/page/requirement-detail/klantwensen-detail.component';
import { ComplaintsDetailComponent } from '../../features/main-app/complaints/complaints-detail/complaints-detail.component';
import { CalendarMiddelDetailComponent } from '../../shared/components/modal/calendar-content-detail/calendar-middel-detail.component';
import { CalendarActivityDetailComponent } from '../../shared/components/modal/calendar-activity-detail/calendar-activity-detail.component';
import { LogEditComponent } from '../../shared/components/modal/contact-moments-edit/log-edit.component';

export function openModal(type,data,dialog,pubsub,chat=false) {
    switch(type) {
        case 'stakeholders': {
            const modal = dialog.open(StakeholdersDetailComponent, modalConfig({
              data: data,
              panelClass: ['animate__animated', 'animate__slideInRight'],
              disableClose: true,
              closeOnNavigation: true
            }, ModalEnum.SidebarLargeResponsive));
            pubsub.updateHistory(modal, StakeholdersDetailComponent, data, data.name);
            return modal;
             break;
          }
          case 'issues': {
            const modal = dialog.open(TabIssueDetailGeneralComponent, modalConfig({
              data: data,
              panelClass: ['animate__animated', 'animate__slideInRight'],
              disableClose: true,
            }, ModalEnum.SidebarLargeResponsive));
            pubsub.updateHistory(modal, TabIssueDetailGeneralComponent, data, data.name)
            return modal;
             break;
          }
          case 'action':
            case 'task':
              case 'tasks':
                case 'actions': {
                  const modal = dialog.open(ActionEditComponent, modalConfig({
                    data: data,
                    panelClass: ['animate__animated', 'animate__slideInRight'],
                    disableClose: true,
                  }, ModalEnum.SidebarSmallResponsive));
                  pubsub.updateHistory(modal, ActionEditComponent, data, data.text);
                  return modal;
                  break;
                }
          case 'klanteisen':
            case 'requirements':
              case 'decisions': {
              const modal = dialog.open(KlantwensenDetailComponent, modalConfig({
                data: data,
                panelClass: ['animate__animated', 'animate__slideInRight'],
                disableClose: true,
                closeOnNavigation: true
              }, ModalEnum.SidebarLargeResponsive));
              pubsub.updateHistory(modal, KlantwensenDetailComponent, data, data.title = (data.title ? data.title : data.name));
              return modal;
                break;
              }
          case 'meldingen':
            case "complaints": {
              if(chat){
                data.enableChatModal=chat
              }
            const modal = dialog.open(ComplaintsDetailComponent, modalConfig({
              data: data,
              panelClass: ['animate__animated', 'animate__slideInRight'],
              disableClose: true,
            }, ModalEnum.SidebarLargeResponsive));
            pubsub.updateHistory(modal, ComplaintsDetailComponent, data, data.text = (data.text ? data.text : data.name));
            return modal;
            break;
            }
          case 'content':
            case 'contents':
              case 'middel': {
                if(data.code){
                  data.calendarable_id=data.code;
                }
                const modal = dialog.open(CalendarMiddelDetailComponent, modalConfig({
                  data: data,
                  panelClass: ['animate__animated', 'animate__slideInRight'],
                  disableClose: true,
                }, ModalEnum.SidebarSmallResponsive));
                pubsub.updateHistory(modal, CalendarMiddelDetailComponent, data, data.text = (data.text ? data.text : data.name));
                return modal;
                break;
                }
          case 'activities': {
            if(data.code){
              data.calendarable_id=data.code;
            }
            const modal = dialog.open(CalendarActivityDetailComponent, modalConfig({
              data: data,
              panelClass: ['animate__animated', 'animate__slideInRight'],
              disableClose: true,
            }, ModalEnum.SidebarSmallResponsive));
            pubsub.updateHistory(modal, CalendarActivityDetailComponent, data, data.text = (data.text ? data.text : data.name));
            return modal;
            break;
            }
          case 'contactmoments':
            case 'logs': {
              const modal = dialog.open(LogEditComponent, modalConfig({
                data: data,
                panelClass: ['animate__animated', 'animate__slideInRight'],
                disableClose: true,
              }, ModalEnum.SidebarLargeResponsive));
              pubsub.updateHistory(modal, LogEditComponent, data, data.name)
              return modal;
              break;
            }
    }
}
