import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Location } from '../../../akita/locations/state/location.model';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { AbstractService } from "../common/abstract.service";
import { PaginatedResponse } from "../common/paginated-response";
import { PaginationParams } from "../common/pagination-params";

@Injectable({
  providedIn: `root`
})
export class StakeholdersService extends AbstractService {

  constructor (private http:HttpClient) {
    super();
  }
  public stakeholder=new Subject<any>();
  public stakeholdertrust=new Subject<any>();
  public toggle=new Subject<any>();
  public toggleSidepanel=new Subject<any>();
  @ServiceErrorHandler()
  submitStakeholder(stakeholder: any): Observable<any> {
    return this.http.post('/api/stakeholders', stakeholder, this.httpOptions);
  }

  @ServiceErrorHandler()
  getStakeholders(pagination: PaginationParams,strategie=''): Observable<PaginatedResponse> {
    let params = new HttpParams();
    if (strategie) {
      params = params.append('filter_by','matrices.strategie');
    }
    return <Observable<PaginatedResponse>> this.http.get(`/api/stakeholders`, pagination.get(params));
  }

  @ServiceErrorHandler()
  getStakeholdersRelations(): Observable<any> {
    return <Observable<any>> this.http.get(`/api/stakeholders?dropdown=1`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getStakeholderSummary(stakeholderCode: string,skipSubproject:boolean=false) {
    let params = new HttpParams();
    if(skipSubproject)
      params = params.append('skip_subproject','0')
    return this.http.get(`/api/stakeholders/${stakeholderCode}/summary`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  getMiddelCount(stakeholderCode: string) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/middel/summary`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getStakeholder(stakeholderCode: string, withs: string[] = []) {
    const params = new HttpParams();

    if (withs && withs.length > 0) {
      params.append('with', withs.join((',')));
    }

    return this.http.get(`/api/stakeholders/${stakeholderCode}`, {
      headers: this.headers,
      params: params
    });
  }

  findStakeholdersByEmployees(emails: string[]) {
    const params = new HttpParams()
      .set(`emails`, emails.join());

    return this.http.get(`/api/search/stakeholders`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  createMultipleStakeholders(stakeholders: any[]): Observable<any> {
    return this.http.post(`/api/batch/stakeholders`, {
      stakeholders: stakeholders
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateStakeholder(stakeholderCode: string, stakeholder: any) {
    return this.http.put(`/api/stakeholders/${stakeholderCode}`, stakeholder, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitIssueInterest(data: any) {
    return this.http.post(`/api/issuesinterestsstakeholders`, data, this.httpOptions);
  }

  @ServiceErrorHandler()
  getStakeholderTypes(paginationParams: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/stakeholdertypes`, paginationParams.get());
  }

  @ServiceErrorHandler()
  storeMatrices(stakeholderCode, powerInterest, power, trustInterest, trust,id?) {
    return this.http.post(`/api/stakeholders/${stakeholderCode}/matrices`, {
      power_interest: powerInterest,
      power: power,
      trust_interest: trustInterest,
      trust: trust,
      subprojects_id:id
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  removeStakeholders(stakeholder) {
    return this.http.delete(`/api/stakeholders/${stakeholder.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  download(options) {
    return this.http.get(`/file/export/stakeholder`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  exportStakeholders(options) {
    return this.http.get(`/file/export/stakeholder-overview?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  getIssuesByStakeholder(stakeholderCode) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/issues?count=-1`, {
      headers: this.headers
    });
  }

  @ServiceErrorHandler()
  getKlantwensenByStakeholder(stakeholderCode, paginationParams: PaginationParams) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/decisions`, paginationParams.get());
  }

  @ServiceErrorHandler()
  getAllStakeholderType(){
    return this.http.get('/api/stakeholdertypes', this.httpOptions);
  }

  getInterestOfStakeholder(id) {
    return this.http.get(`/api/stakeholders/${ id }/interests?count=-1`, {
      headers: this.headers
    });
  }

  /**@deprecated This feature doesn't exist anymore since flexible relatics
   *
   * @param id
   */
  getStakeholdersForRelatics(id) {
    return this.http.get(`/api/stakeholders/${id}/demands?count=-1`, {
      headers: this.headers
    });
  }

  @ServiceErrorHandler()
  getInterestByStakeholder(stakeholderCode) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/interests?count=-1`, {
      headers: this.headers
    });
  }

  @ServiceErrorHandler()
  removeInterestOfStakeholder(code, id) {
    return this.http.delete(`/api/stakeholders/${code}/interests/${ id}`, {
      headers: this.headers
    });
  }

  @ServiceErrorHandler()
  addInterestsOfStakeholder(code:string, description:string) {
    return this.http.post(`/api/stakeholders/${code}/interests`, {
      description: description
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateInterest(code, id, description) {
    return this.http.put(`/api/stakeholders/${code}/interests/${ id}`, {
      description: description
    }, this.httpOptions);
  }

  getStakeholderMatrix(stakeholderCode) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/matrices`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getMatrices(): Observable<{matrices: any[]}> {
    return <Observable<{matrices: any[]}>> this.http.get('/api/matrices', this.httpOptions);
  }

  @ServiceErrorHandler()
  searchStakeholdersByPolygons(locations: Location): Observable<{residents: any[], others: any[]}> {
    return <Observable<{residents: any[], others: any[]}>> this.http.post(`/api/search/stakeholders`, {
      type: 'polygon',
      locations: locations,
    }, this.httpOptions);
  }
  @ServiceErrorHandler()
  getFilteredstakeholder(pagination: PaginationParams, status?: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/stakeholders', pagination.get(status ? new HttpParams().set('status', status) : null));
  }
  onCreationGetStakeholder(entity){
    this.stakeholder.next(entity);
  }
  onCreationGetStakeholderTrust(entity){
    this.stakeholdertrust.next(entity);
  }
  onClickToggle(){
    this.toggle.next({});
  }
  onClickToggleSidePanel(){
    this.toggleSidepanel.next({});
  }
  @ServiceErrorHandler()
  getStakeholderForOberPass(){
    return this.http.get('/api/stakeholders?select=potential_stakeholders_id,name,code',this.httpOptions);
  }
  @ServiceErrorHandler()
  deleteBatchStakeholder(stakeholder){
    return this.http.delete(`/api/stakeholders/batch?ids=${stakeholder.toString()}`,this.httpOptions);
  }
  @ServiceErrorHandler()
  getStakeholdersStatusInfo(pagination: PaginationParams): Observable<PaginatedResponse> {
    let params = new HttpParams();
    params = params.append('group_by','matrices.strategie');
    params = params.append('group_with','count');
    return <Observable<PaginatedResponse>> this.http.get(`/api/stakeholders`, pagination.get(params));
  }
  @ServiceErrorHandler()
  getTopTenStakeholderOfProject(pagination: PaginationParams): Observable<PaginatedResponse> {
    const params = new HttpParams();
    return this.http.get<PaginatedResponse>(`/api/stakeholders`, pagination.get(params))
    .pipe(
      map(((res:any)=>res.paginate.data)),
      shareReplay()
    );
  }
}
