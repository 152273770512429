<script src="https://maps.googleapis.com/maps/api/js?key={{ globals.googleMapApiKey }}&libraries=places&sensor=false&language=nl"></script>
<div class="row popup-container" >
  <div class="popup-header row no-margin">
      <div class="col-xs-12 mb-5">
          <app-breadcrumb></app-breadcrumb>
          <button type="button" class="close no-margin" (click)="close()"><span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon></span><span class="sr-only">Close</span></button>
          <mat-icon *ngIf="resident" (click)="deleteResident(resident)" class="d-cursor-hand delete pull-right">delete</mat-icon>

      </div>
      <div class="col-xs-12 popup-header-title">
        <h1><strong>{{ 'residents.label' | transloco | capitaliz }} </strong>{{name}}</h1>
      </div>

  </div>
  <mat-progress-bar *ngIf="!resident" mode="indeterminate"></mat-progress-bar>
  <div class="popup-body" appScroll *ngIf="resident">
    <!-- left col -->
    <div class="col-md-7 col-xs-12" >
        <h3 class="mb-15">{{ 'decisions.connections' | transloco | capitaliz }}</h3>
        <div class="col-xs-12 panel z-depth-2 panel-border mb-40" *transloco="let t; read: 'stakeholders'">
            <div>
              <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
              <app-add-connect-generic (onChange)="updateEntities($event)" parentType="residents" *ngIf="loaded"  [parent]="resident" [data]="entities" [types]="['contactmoments','complaints','contents']"></app-add-connect-generic>

            </div>
        </div>

        <h3 class="mb-15">{{ 'stakeholders.address' | transloco | capitaliz }}</h3>
        <div class="col-xs-12 panel panel-border z-depth-2">

              <div class="form-group col-xs-12">
                <label class="control-label control-label-custom" for="google-address">{{'reusable.search_address' | transloco}}</label>
                <div>
                  <input *ngIf="globals.googleMapLibLoaded" type="text" class="form-control form-control-custom" id="google-address" name="google-address" ngx-google-places-autocomplete autocomplete="off"
                         [options]="googleMapOptions" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [disabled]="!hasPermission(Permission.write)">
                </div>
              </div>
              <div class="form-group col-xs-12">
                <label class="control-label control-label-custom" >{{ 'stakeholders.address' | transloco | capitaliz }}</label>
                <mat-icon *ngIf="resident.found_coordinates !== 'found'" class="warning-address-icon" matTooltip="GPS coördinaten onbekend door handmatig ingevuld adres">warning</mat-icon>
                <mat-slide-toggle class="pull-right manual-address-toggle" [(ngModel)]="enableManualAddress" name="manualAddress" matTooltip="Handmatige invoer in-/uitschakelen" [disabled]="!hasPermission(Permission.Write)">
                </mat-slide-toggle>

                <mat-form-field class="col-xs-12 pd-0 address-input">
                    <input matInput type="text" name="address" [(ngModel)]="resident.address" (change)="updateAddress()" [disabled]="!enableManualAddress"  />
                  </mat-form-field>
              </div>

              <mat-form-field class="col-xs-12">
                  <input matInput type="text" name="postcode" [(ngModel)]="resident.postcode" (change)="updateAddress()" [disabled]="!enableManualAddress" [placeholder]="'maps.addr:postcode' | transloco" />
                </mat-form-field>

              <mat-form-field class="col-xs-12">
                  <input matInput type="text" name="city" [(ngModel)]="resident.city" (change)="updateAddress()" [disabled]="!enableManualAddress" [placeholder]="'Plaats'" />
                </mat-form-field>

        </div>


    </div>

    <!-- right col -->
    <div class="col-md-5 col-xs-12">

        <h3 class="mb-15">{{ 'text.general' | transloco | capitaliz }}</h3>
        <div class="col-xs-12 panel panel-border z-depth-2">
              <!-- NAME -->
              <mat-form-field class="col-xs-12">
                  <input matInput type="text" name="title" [(ngModel)]="resident.name" (change)="updateResident()" [disabled]="!hasPermission(Permission.write)" placeholder="{{'users.name_field' | transloco}}" />
                </mat-form-field>
              <div class="form-group col-xs-12 resident-phone">
                <div>
                  <international-phone-number id="phone" [(ngModel)]="resident.phone" (ngModelChange)="updateResident()" placeholder="Telefoonnummer" [maxlength]="20" defaultCountry="nl" name="phone_number" [allowedCountries]="['nl', 'be']" [disabled]="!hasPermission(Permission.write)"></international-phone-number>
                </div>
              </div>
              <!-- EMAIL -->
              <mat-form-field class="col-xs-12">
                  <input matInput type="text" name="email" [(ngModel)]="resident.email" (change)="updateResident()" [disabled]="!hasPermission(Permission.write)" placeholder="{{'users.email_field' | transloco}}" />
                </mat-form-field>

              <mat-form-field class="col-xs-12">
                <mat-select [(value)]="resident.type" (valueChange)="updateResident()" placeholder="{{'residents.label' | transloco | capitaliz}} {{'type'}}" [disabled]="!hasPermission(Permission.write)">
                  <mat-option value="Omwonende">Omwonende</mat-option>
                  <mat-option value="Bedrijf">Bedrijf</mat-option>
                </mat-select>
              </mat-form-field>
              <app-generic-multiselect-checkbox [parent]="resident" parentType="residents" class="subprojects col-xs-12" type="subprojects" [maxChipItems]="3" *ngIf="hasModule('subprojects')" (ngModelChange)="updateSubprojects()" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco" name="subprojects"
            [(ngModel)]="resident.subprojects" [multiple]="true" [maxChipLength]="20" [uniqueId]="'sub-projects'"
           >
           <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
              {{ subproject.name }}
            </app-generic-multiselect-checkbox-option>
          </app-generic-multiselect-checkbox>
        </div>



    </div>
  </div>
</div>

