import {ChildrenOutletContexts, NavigationExtras, Route, Router, Routes, UrlSerializer} from '@angular/router';
import {Location} from '@angular/common';
import {Compiler, Directive, Injector, Type} from '@angular/core';
import {Globals} from '../../globals';
import {AppInjector} from '../../../app-injector';
import {SubprojectQuery} from '../../akita/subproject/state/subprojects.query';

export function projectAwareRouterFactory(rootComponentType: Type<any>, urlSerializer: UrlSerializer,
                                          rootContexts: ChildrenOutletContexts, location: Location, injector: Injector,
                                          compiler: Compiler, config: Route[][], subProjectQuery: SubprojectQuery): Router {
  // eslint-disable-next-line no-use-before-define
  return new ProjectAwareRouter(
    rootComponentType, urlSerializer, rootContexts, location, injector, compiler, Array.prototype.concat.apply([], config), subProjectQuery
  );
}
@Directive({
})
export class ProjectAwareRouter extends Router {
  constructor(rootComponentType: Type<any>, urlSerializer: UrlSerializer,
              rootContexts: ChildrenOutletContexts, location: Location, injector: Injector,
              compiler: Compiler, config: Routes, private subProjectQuery: SubprojectQuery) {
    super()
    // super(rootComponentType, urlSerializer, rootContexts, location, injector, loader, compiler, config);
  }

  navigate(commands: any[], extras: NavigationExtras = { skipLocationChange: false }): Promise<boolean> {
    const globals: Globals = AppInjector ? AppInjector.get(Globals) : null;
    const selectingSubproject = extras && extras.state && extras.state.selectSubproject;
    let selectedSubProject = extras && extras.state ? extras.state.subprojectCode : null;
    if (!selectedSubProject && !selectingSubproject) {
      const activeSubProject= this.subProjectQuery.getActive();
      if (activeSubProject) {
        selectedSubProject = activeSubProject.code;
      }
    }

    if (!globals || !globals.projectCode || (extras && extras.state && extras.state.directNavigate)) {
      super.navigate(commands, extras);
      return;
    }

    commands = commands.filter(c => c !== '/');
    if (selectedSubProject) {
      return super.navigate(['/', globals.projectCode, 'sub', selectedSubProject, ...commands], extras);
    }

    return super.navigate(['/', globals.projectCode, ...commands], extras);
  }
}
