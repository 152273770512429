import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { MapService } from 'src/app/core/service/api/map.service';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { PublicLayers, openDataURLXMLMap, urlParseParames } from 'src/app/core/util/openDataModals';
import { AuthenticationModalComponent } from '../authentication-modal/authentication-modal.component';
@Component({
  selector: 'app-add-open-data-layers',
  templateUrl: './add-open-data-layers.component.html',
  styleUrls: ['./add-open-data-layers.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddOpenDataLayersComponent implements OnInit {
  public is_load=false;
  public parser = new DOMParser();
  public jsonObject:any;
  public publicLayers:PublicLayers;
  public errorMessage=false;
  public editMode=false;
  public noLayerNameValidation:boolean=false;
  public layerName: string='';
  constructor(
    private cd: ChangeDetectorRef,
    public _mapServices:MapService,
    public dialogRef: MatDialogRef<AddOpenDataLayersComponent>,
    private ngxXml2jsonService: NgxXml2jsonService,
    @Inject(MAT_DIALOG_DATA) public data: PublicLayers,
    private dialog: MatDialog,
    // eslint-disable-next-line no-empty-function
    ) { }

  ngOnInit(): void {
    if(this.data){
      this.publicLayers=this.data[0];
      this.editMode=true;
      return;
    }
    this.loadEntity();
  }
  //making paload
  public loadEntity(): void{
    this.publicLayers={
      url:'',
      name:'',
      type:'wfs',
      version:null,
      layers:[],
      metadata:null
    }
  }
  //on close modal
  public close(): void{
    this.dialogRef.close();
  }
  //on submit
  public submit(): void{
    this._mapServices.saveOpenData(this.publicLayers)
    .pipe(
      finalize(()=>{
        this.dialogRef.close(true);
        showFeedbackSaved();
      })
    ).subscribe();

  }
  //on update
  public update(): void{
    this._mapServices.updateOpenData(this.publicLayers)
    .pipe(
      finalize(()=>{
        this.dialogRef.close(true);
        showFeedbackSaved();
      })
    ).subscribe();
  }
  //on change event
  public onChange(): void{
    this.errorMessage=false;
    this.publicLayers.layers=[];
    this.errorMessage=false;
    this.noLayerNameValidation=false;
    if(this.publicLayers.url){
      const url = new URL(this.publicLayers.url);
      const urlParams = new URLSearchParams(url.search);
      this.is_load=true;
      switch(this.publicLayers.type){
        case 'wfs':
          if(this.publicLayers.url.toLowerCase().includes('wfs') || this.publicLayers.url.toLowerCase().includes('ows')){
            this.publicLayers.url=urlParseParames(url,urlParams,this.publicLayers.type)
            this.layerVerify();
          }else{
            this.is_load=false;
            this.errorMessage=true;
            bugsnagFactory().notify(JSON.stringify({
              message: `Invalid URL: ${this.publicLayers.url}`
            }));
          }
          break;
        case 'wms':
          if(this.publicLayers.url.toLowerCase().includes('wms')){
            this.publicLayers.url=urlParseParames(url,urlParams,this.publicLayers.type)
            this.layerVerify();
          }else{
            this.is_load=false;
            this.errorMessage=true;
            bugsnagFactory().notify(JSON.stringify({
              message: `Invalid URL: ${this.publicLayers.url}`
            }));
          }
          break;
        case 'public_arcgis':
          this.arcgisPublicLayer();
          break;
        default:
          break;
      }
    }
  }
  //on change checkbox
  public checkboxChanged(event,item): void {
    item.is_enabled=event.checked
  }
  /*
    WFS and WMS Layer link verify
  */
  public layerVerify(): void{
    this._mapServices.setLayerAuthentication(this.publicLayers)
    .pipe(
      tap((res: any) => {
        if(res.ok){
          this.wsPublicLayer();
        }else{
          this.onAuthentication();
        }
      })
    ).subscribe();
  }
  //wms & wfs layers services call function
  public wsPublicLayer(): void{
    this._mapServices.getOpenDataLayers(this.publicLayers)
    .pipe(
      tap((res:any)=>{
        this.parser = new DOMParser();
        this.jsonObject = this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(res, 'text/xml'));
        this.publicLayers=openDataURLXMLMap(this.jsonObject,this.publicLayers);
        if(!Object.keys(this.publicLayers.name).length){
          this.inputValidationCheck();
        }
      }),
      finalize(()=>{
        this.is_load=false;
        this.cd.detectChanges();
      }),
      catchError((err)=>{
        this.errorMessage=true;
        bugsnagFactory().notify(JSON.stringify({
          message: `Invalid URL: ${this.publicLayers.url}`,
          data: err,
        }));
        this.cd.detectChanges();
        return throwError(err);
      })).subscribe();
  }
  //arcgis layers services call function
  public arcgisPublicLayer(): void{
    this._mapServices.getOpenDataLayers(this.publicLayers)
    .pipe(
      tap((res:any)=>{
        this.publicLayers=openDataURLXMLMap(res,this.publicLayers);
      }),
      finalize(()=>{
        this.is_load=false;
        this.cd.detectChanges();
      }),
      catchError((err)=>{
        this.errorMessage=true;
        bugsnagFactory().notify(JSON.stringify({
          message: `Invalid URL: ${this.publicLayers.url}`,
          data: err,
        }));
        this.cd.detectChanges();
        return throwError(err);
      })).subscribe();
  }
  //layer name input
  public inputValidationCheck(): void{
    this.noLayerNameValidation=true;
  }
  //layer name get
  public getLayerName(): void{
    this.publicLayers.name=this.layerName;
  }
  /*
    On authentication layer
  */
  public onAuthentication(): void{
    const modal = this.dialog.open(AuthenticationModalComponent,modalConfig(
    {
      data: this.publicLayers,
      disableClose: true,
      panelClass: 'customized-dialog-container',
    },
    ModalEnum.ModalSmall));
    modal.afterClosed().subscribe(res => {
      if(res){
        this.publicLayers = res;
        this.wsPublicLayer();
        return;
      }
      this.is_load=false;
      this.errorMessage=true;
      this.cd.detectChanges();
    })
  }
}
