import { HttpHeaders, HttpParams } from "@angular/common/http";
import { PageEvent } from "@angular/material/paginator";
import { BehaviorSubject, Observable } from 'rxjs';

export class PaginationParams {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  });

  private _page: number;
  private _count: number;
  private _orderBy: string;
  private _deleteBy: string;
  private _direction = 'desc';
  private default_orderBy: string;
  private default_direction = 'asc';
  public _keyword: string;
  private _searchBy: string[];
  private _with: string[];
  private _has: string[];
  private _whereHas: string[];
  private _skipSubProject: boolean = false;
  private _search: boolean = false;
  private _matrices: boolean =false;
  public _filters: any = {};
  public _analysisSort: any = {};
  public _analysisSearchBy: string;
  private _extraParams: HttpParams = new HttpParams();
  // eslint-disable-next-line no-use-before-define
  private paramsSbj: BehaviorSubject<PaginationParams> = new BehaviorSubject<PaginationParams>(this);
  // eslint-disable-next-line no-use-before-define
  private $params: Observable<PaginationParams>;
  public _nextLink: string;

  // Used to store value for mat-paginator only
  private _total = 0;

  constructor(page: number = 1, count: number = 2000) {
    this._page = page;
    this._count = count;
  }

  public orderBy(column: string): PaginationParams {
    this._orderBy = column;
    return this;
  }

  public deleteBy(value: string): PaginationParams {
    this._deleteBy = value;
    return this;
  }

  public descending(direction: string): PaginationParams {
    this._direction = direction;
    return this;
  }

  public changeSort(field: string, direction: string) {
    if (field == this._orderBy && direction == this._direction) {
      this._orderBy = '';
    }
    else {
      this._orderBy = field;
      this._direction = direction;
    }
  }

  public sortArray(fields: any): PaginationParams {
    this._analysisSort = fields;
    return this
  }

  public defaultSort(field: string, direction: string) {
    this._orderBy = field;
    this._direction = direction;
    this.default_orderBy = field;
    this.default_direction = direction;
    return this;
  }

  public keyword(keyword: string): PaginationParams {
    // Reset page
    this._page = 1;

    this._keyword = keyword;
    return this;
  }
  public analysisKeywordSearch(keyword: string, searchByItem): PaginationParams {
    this._page = 1;
    this._analysisSearchBy = searchByItem;
    this._keyword = keyword;
    return this;
  }

  public searchBy(searchBy: string[]): PaginationParams {
    this._searchBy = searchBy;
    return this;
  }
  //matrices
  public matrices(matrices:boolean){
    this._matrices=matrices;
    return this;
  }
  // In 1 go
  public search(keyword: string, searchBy: string[]): PaginationParams {
    // Reset page
    this._page = 1;

    this._keyword = keyword;
    this._searchBy = searchBy;
    return this;
  }

  public with(relations: string[]): PaginationParams {
    this._with = relations;
    return this;
  }

  public firstPage(): PaginationParams {
    this._page = 1;
    this.paramsSbj.next(this);
    return this;
  }

  public previousPage(): PaginationParams {
    if (this.page > 1) {
      this._page--;
    }

    this.paramsSbj.next(this);
    return this;
  }

  public nextPage(): PaginationParams {
    this._page++;
    this.paramsSbj.next(this);
    return this;
  }

  public whereHas(entities: string[]): PaginationParams {
    this._whereHas = entities;
    return this;
  }

  public skipSubpProject(bool: boolean): PaginationParams {
    this._skipSubProject = bool;
    return this;
  }

  public withFilters(bool: boolean): PaginationParams {
    this._search = bool;
    return this;
  }

  public has(entity: string, property: string, value: string | number): PaginationParams {
    if (this._with) {
      const exist = this._with.find(w => w === entity);
      if (!exist) {
        this._with.push(entity);
      }
    }

    if (!this._has) {
      this._has = [];
    }

    if (!this._whereHas) {
      this._whereHas = [];
    }

    this._has.push(entity);
    this._whereHas.push(`${entity},${property},${value.toString()}`);

    return this;
  }

  public extra(key: string, value: string | boolean | number): PaginationParams {
    if (typeof value === 'string') {
      this._extraParams = this._extraParams.append(key, value);
    } else if (typeof value === 'boolean') {
      this._extraParams = this._extraParams.append(key, value ? '1' : '0');
    } else if (typeof value === 'number') {
      this._extraParams = this._extraParams.append(key, `${value}`);
    }

    return this;
  }

  public removeExtra(key: string): PaginationParams {
    this._extraParams = this._extraParams.delete(key);
    return this;
  }

  public get page(): number {
    return this._page;
  }

  // Used for mat-paginator
  public get pageIndex(): number {
    return this._page - 1;
  }

  public get count(): number {
    return this._count;
  }

  public get order(): string {
    return this._orderBy;
  }

  public set total(total: number) {
    this._total = total;
  }

  public set page(page: number) {
    this._page = page;
  }

  public get total(): number {
    return this._total;
  }

  public paginate(pageEvent: PageEvent) {
    if (pageEvent) {
      this._page = pageEvent.pageIndex + 1;
      this._count = pageEvent.pageSize;

    }
    this.paramsSbj.next(this);
  }

  public toObservable(): Observable<PaginationParams> {
    if (!this.$params) {
      this.$params = this.paramsSbj.asObservable();
    }

    return this.$params;
  }

  public refresh(): void {
    this.paramsSbj.next(this);
  }

  public get(extraParams?: HttpParams): { headers: HttpHeaders, params: HttpParams } {
    let params = new HttpParams()
      .set('count', this._count.toString())
      .set('page', this._page.toString());

    if (this._orderBy) {
      params = params.append('order_by', this._orderBy)
        .append('direction', this._direction);
    }
    if(this._deleteBy){
      params = params.append('deleted', this._deleteBy)
    }
    if (this._with && this._with.length > 0) {
      params = params.append('with', this._with.join(','));
    }

    if (this._has && this._has.length > 0 && this._whereHas && this._whereHas.length > 0) {
      params = params.append('has', this._has.join(','));

      for (let i = 0; i < this._whereHas.length; i++) {
        params = params.append(`whereHas[${i}]`, this._whereHas[i]);
      }
    } else if (this._whereHas && this._whereHas.length > 0) {
      for (let i = 0; i < this._whereHas.length; i++) {
        params = params.append(`where_has[${i}]`, this._whereHas[i]);
      }
    }

    if (this._keyword && this._searchBy && !this._analysisSearchBy) {
      params = params.append('keyword', this._keyword);
      this._searchBy.forEach((by: string) => params = params.append('search_by[]', by))
    }

    if (this._keyword && !this._searchBy && !this._analysisSearchBy) {
      params = params.append('keyword', this._keyword);
    }

    if (this._keyword && this._analysisSearchBy) {
      if (this._analysisSearchBy === 'Stakeholder') {
        params = params.append('keywordStakeholders', this._keyword);
      } else if (this._analysisSearchBy === 'Issue') {
        params = params.append('keywordIssues', this._keyword);
      }
      if (this._searchBy){
        this._searchBy.forEach((by: string) => params = params.append('search_by[]', by));
      }
    }

    if (this._search && !this._matrices) {
      params = params.append('search', '1');
    }
    if(this._matrices){
      params = params.append('search', '0');
      params = params.append('matrices', '1');
    }
    if (this._skipSubProject) {
      params = params.append('skip_subproject', '1');
    }

    if (extraParams && extraParams.keys().length > 0) {
      extraParams.keys().forEach(key => params = params.append(key, extraParams.get(key)));
    }

    if (this._extraParams && this._extraParams.keys().length > 0) {
      this._extraParams.keys().forEach(key => params = params.append(key, this._extraParams.get(key)));
    }

    if (this._filters) {
      Object.keys(this._filters).forEach((key) => {
        if (this._filters[key] && this._filters[key].length) {
          this._filters[key].forEach(value => {
            params = params.append(key != 'filter_field' ? `filter_array[${key}][]` : `filter_field[]`, value)
          });
        }
      });
    }

    if (this._analysisSort) {
      for (const key in this._analysisSort) {
        if (this._analysisSort.hasOwnProperty(key)) {
          const value = this._analysisSort[key];
          params = params.append(`sort_array[${key}]`, value)
        }
      }
    }

    return {
      headers: this.headers,
      params: params
    };
  }
}
