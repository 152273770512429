export enum Entities {
  tasks = 'tasks',
  issues = 'issues',
  complaints = 'complaints',
  activities = 'activities',
  requirements = 'requirements',
  documents = 'documents',
  contactpersons = 'contactpersons',
  contents = 'contents',
  stakeholders = 'stakeholders',
  residents = 'residents',
  permits = 'permits',
  workflow = 'workflow',
  workflowtask = 'workflowtask'
}
