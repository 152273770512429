 <div>   
    <div mat-dialog-title>
      <h4 class="mb-10 inline-block">
        {{ "settings.sso.email_mismatch.title" | transloco }}
      </h4>
    </div>
    <!-- modal body -->
    <mat-dialog-content>
        <div style="font-size: 14px; font-family: 'work_sans_regular';">
            <p>
                {{"settings.sso.email_mismatch.text" | transloco}}
            </p>
    
            <p>
                {{"settings.sso.email_mismatch.redirect" | transloco}}
            </p>
        </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
      <div class="dialog-button-row">
        <button
          type="submit"
          mat-flat-button
          (click)="close()"
        >
          <span>
            {{ "OK" }}
          </span>
        </button>
      </div>
      <!--end of modal footer -->
    </mat-dialog-actions>
</div>
  