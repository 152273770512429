import { translate } from '@jsverse/transloco';
import OlMap from 'ol/Map';
import {Observable, Subscription} from 'rxjs';
import Overlay from 'ol/Overlay';

declare let ol: any;

export class OpenlayersTooltip {
  private map: OlMap;
  private clickStartDrawingMsg: string;
  private continuePolygonMsg: string = 'Klik om door te gaan met tekenen van de polygoon';
  private continueLineMsg: string = 'Klik om door te gaan met tekenen van de lijn';
  private addStakeholderMsg: string = 'Klik om stakeholders te importeren';

  private helpTooltip: any;
  private measureTooltip: any;

  private helpTooltipElement: HTMLElement;
  private measureTooltipElement: HTMLElement;

  private selectedTool$: Observable<string>;
  private purposeTool$: Observable<string>;
  private purposeToolSubscription: Subscription;

  constructor(map: OlMap, clickStartDrawingMsg: string, selectedTool$: Observable<string>, purposeTool$: Observable<string>) {
    this.map = map;
    this.clickStartDrawingMsg = clickStartDrawingMsg;
    this.selectedTool$ = selectedTool$;
    this.purposeTool$ = purposeTool$;
    this.init();
  }

  public on(): void {
    this.createHelpTooltip();
  }

  public off(): void {
    this.removeHelpTooltip();
  }

  public write(text: string): void {
    this.clickStartDrawingMsg = text;
  }

  /**
   * @deprecated
   * @returns {HTMLElement}
   */
  public setStatic(): HTMLElement {
    if (this.measureTooltipElement && this.measureTooltip) {
      this.measureTooltipElement.className = 'tooltip tooltip-static';
      this.measureTooltip.setOffset([0, -7]);

      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
    }

    return this.measureTooltipElement;
  }

  /**
   * @deprecated
   * @param coordinate
   */
  public position(coordinate): void {
    if (this.measureTooltip) {
      this.measureTooltip.setPosition(coordinate);
    }
  }

  private init() {

    this.purposeToolSubscription = this.purposeTool$.subscribe((purposeTool: string) => {
      const tooltips ={issues:translate('maps.Teken issue locatie'),complaints:translate('maps.Teken melding locatie'),stakeholders:translate('maps.Teken stakeholder locatie'),calendars:translate('maps.Teken activiteit locatie'),decisions:translate('maps.Teken klanteis locatie'),}
      if (purposeTool === 'calendars_lines') {
        this.clickStartDrawingMsg = translate('maps.Teken activiteit lijn');
      }
      else if (purposeTool === 'stakeholders_polygons') {
        this.clickStartDrawingMsg = translate('maps.Teken stakeholder polygon');
      }
      else {
        this.clickStartDrawingMsg = tooltips[purposeTool]
      }
    });

    this.map.on('pointermove', evt => {
      if (evt.dragging || !this.helpTooltipElement) {
        return;
      }

      this.helpTooltipElement.innerHTML = this.clickStartDrawingMsg;
      this.helpTooltip.setPosition(evt.coordinate);

      this.helpTooltipElement.classList.remove('hidden');
    });

    this.map.getViewport().addEventListener('mouseout', () => {
      if (this.helpTooltipElement) {
        this.helpTooltipElement.classList.add('hidden');
      }
    });
  }

  /**
   * Creates a new help tooltip
   */

  private removeHelpTooltip() {
    if (this.helpTooltipElement && this.helpTooltipElement.parentNode) {
      this.map.removeOverlay(this.helpTooltip);
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
      this.helpTooltipElement = null;
    }
  }

  private createHelpTooltip() {
    this.removeHelpTooltip();
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left'
    });
    this.map.addOverlay(this.helpTooltip);
  }

  /**
   * @deprecated
   * Creates a new measure tooltip
   */
  private removeMeasureTooltip() {
    if (this.measureTooltipElement && this.measureTooltipElement.parentNode) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
  }

  /**
   * @deprecated
   */
  private createMeasureTooltip() {
    this.removeMeasureTooltip();
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    });

    this.map.addOverlay(this.measureTooltip);
  }

  public destroy() {
    this.purposeToolSubscription.unsubscribe();
  }
}
