<div *ngIf="projects != 'no-project'">
    <div mat-dialog-title *transloco="let t">
        <h2 class="row mb-10 ml-2 inline-block">{{ t('select_project') | capitaliz }}</h2>
    </div>
    <div class="modal-body no-padding project-list">
        <a *ngFor="let project of projects" [routerLink]="[project.code]" (click)="close()" class="list-group-item list-group-item-action" [matTooltip]="project.name">
            <i class="d-text-middle uil uil-clipboard-notes entity-icon width-auto"></i>
            {{ project.name | truncatie:30 }}
        </a>
        <p *ngIf="!projects || projects.length === 0">U bent nog niet toegewezen aan een project, neem contact op met uw contactpersoon.</p>
    </div>

    <div *ngIf="company" class="modal-footer no-padding company-admin mt-30">
        <a class="list-group-item list-group-item-action" *transloco="let t" routerLink="beheer" (click)="close()">
      {{ t('project_management' ) | capitaliz }}
    </a>
    </div>
</div>
<div *ngIf="projects === 'no-project'">
    <div mat-dialog-title *transloco="let t">
        <h4 class="row mb-10 inline-block">{{ t('no_aaccess') }}</h4>
    </div>
    <div class="modal-body no-padding project-list" *transloco="let t">
        <p>{{ t('no_aaccess_description') }} <a href="mailto:support@getdialog.nl">{{ 'support@getdialog.nl' }}</a>.</p>
    </div>
    <div class="modal-footer no-padding company-admin mt-30">
        <a class="d-cursor-hand list-group-item list-group-item-action text-center" *transloco="let t" (click)="auth.logout()">
        {{ t('back_to_login' ) }}
      </a>
    </div>
</div>