import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../service/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthMfaGuard implements CanActivate {

  // eslint-disable-next-line no-empty-function
  constructor(private router: Router) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    const userMfa = localStorage.getItem('mfa');
    const companyMfa = localStorage.getItem('company_mfa');
    const isSso = localStorage.getItem('is_sso');

    if (!companyMfa || !userMfa){
      localStorage.clear()
      this.router.navigate(['/']);
      return false;
    }

    // Apply MFA logic:
    if (companyMfa == 'true' && userMfa == 'false' && isSso == 'false') {
      // If company MFA is true and user MFA is false, block access
      localStorage.clear()
      this.router.navigate(['/']);
      return false;
    }

    // Otherwise, allow access
    return true;
  }
}
