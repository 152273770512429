import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { configsNotification } from 'src/app/akita/multilingual/multilingual.model';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-individual-project-setting',
  templateUrl: './individual-project-setting.component.html',
  styleUrls: ['./individual-project-setting.component.sass'],
})
export class IndividualProjectSettingComponent implements OnInit {
  public onLoad: boolean=false;
  public project: any;
  public projects: any;
  public settings: any;
  public integration={
    bowapp: false,
    freshdesk: false
  }
  // eslint-disable-next-line no-empty-function
  constructor(public globals: Globals,public _configsService: ConfigsService,private cd: ChangeDetectorRef,) { 
    this.project = this.globals.project;
    this.projects = this.globals.projects;
  }

  ngOnInit(): void {
    this.onLoadAllSettings();
  }
  //on load all project settings
  public onLoadAllSettings(): void{
    this._configsService.getAllProjectsSettings()
    .pipe(
      tap(res=>{
        this.settings=res;
        this.onLoad=true;
        this.onCheckIntegrationCheck();
        this.cd.detectChanges();
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //on check integration check
  public onCheckIntegrationCheck(): void{
    for (const key in this.settings) {
      if(this.settings[key].bouwapp_enabled){
        this.integration.bowapp=true;
      }
      if(this.settings[key].freshdesk_enabled){
        this.integration.freshdesk=true;
      }
    }
  }
  //project notification setting logic
  public projectNotificationSettingLogic(data,type): boolean{
    if(data.config){
      if(data.config.notifications){
        if(data.config.notifications[type]){
          return data.config.notifications[type];
        }
      }
    }
    return false;
  }
  //update email notification
  public updateEmailNotification(event,code,data,type): void{
    if(data.config){
      if(data.config.notifications){
        data.config.notifications[type]=event.checked;
        this.attachedProjectsSettings(code,data);
      }else{
        data=configsNotification(data,type,event);
        this.attachedProjectsSettings(code,data);
      }
    }else{
      data=configsNotification(data,type,event);
      this.attachedProjectsSettings(code,data);
    }
  }
  //attached project settings
  public attachedProjectsSettings(code,data): void{
    this._configsService.attachedProjectsSettings(code,data)
    .pipe(
      tap(res=>{
        showFeedbackSaved();
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
}
