<section *transloco="let t">
  <div class="row">
    <div class="col-md-12 mt-10">
      <h5 class="text-black">{{'settings.auth.notification' | transloco}}</h5>
      <mat-hint>{{'settings.auth.project_setting_info' | transloco}}</mat-hint>
    </div>
    <div class="col-md-6">
      <table class="mat-table">
        <thead class="mat-header-row">
        </thead>
        <tbody>
            <tr class="mat-row mt-15">
                <th class="col sm-12 col-xs-12 pl-0 pt-20">
                    <strong>{{ t('notifications.email_immediately_notification_title')}}</strong>
                </th>
            </tr>
            <tr class="mat-row">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.item_assigned')}}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.item_assigned" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.status_chnaged')}}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.item_status_changed" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.tagged_me')}}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.comment_tagged" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.comment_me')}}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.comment_posted" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row" *ngIf="integrationCheck['bouwapp'] && hasModule('communication')">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.bouwapp') }}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.bouwapp_integration" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row" *ngIf="integrationCheck['freshdesk'] && hasModule('communication')">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.freshdesk') }}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.freshdesk_integration" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row">
              <th class="col sm-6 col-xs-6 pl-0">{{ t('notifications.update_on_all')}}</th>
              <th class="col-sm-1 col-xs-1 mat-cell">
                  <a class="pull-right" mat-stroked-button (click)="applyOnAllProjects()">
                      <span>{{ t('update') | capitaliz}}</span>
                  </a>
              </th>
            </tr>
          <ng-container *ngIf="projects.length > 1">
            <tr class="mat-row">
                <th class="col-sm-1 col-xs-1 mat-cell">
                    <a class="" mat-stroked-button (click)="applyOnIndividualProjects()">
                        <span>{{ t('individual_projects') | capitaliz}}</span>
                    </a>
                </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <table class="mat-table">
        <thead class="mat-header-row">
        </thead>
        <tbody>
            <tr class="mat-row">
                <th class="col sm-12 col-xs-12 pl-0">
                    <strong>{{ t('notifications.email_daily_notification_title')}}</strong>
                </th>
            </tr>
            <tr class="mat-row">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.today_notification')}}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.daily_mail" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
            <tr class="mat-row">
                <td class="col sm-6 col-xs-6 mat-cell">{{ t('notifications.week_notification')}}</td>
                <td class="col-sm-1 col-xs-1 mat-cell">
                    <mat-slide-toggle class="pull-right" [(ngModel)]="notifications.weekly_mail" (change)="updateEmailNotification()">
                    </mat-slide-toggle>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
