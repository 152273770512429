import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { translate } from '@jsverse/transloco';
import moment from 'moment';
import { catchError, finalize, tap } from 'rxjs/operators';
import { getBatchIds, overviewFilterSet } from 'src/app/akita/batch/batch';
import { ExcelServiceService } from 'src/app/core/service/api/excel-service.service';
import { SearchService } from 'src/app/core/service/api/search.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedbackError, showFeedbackRemoved, showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { AddStakeholdersActionComponent } from '../add-stakeholders-task/add-stakeholders-action.component';
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';
import { BatchAttachItemsComponent } from '../batch-attach-items/batch-attach-items.component';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { CreateBatchDialogItemsComponent } from '../create-batch-dialog-items/create-batch-dialog-items.component';

@Component({
  selector: 'app-batch-overview-setting',
  templateUrl: './batch-overview-setting.component.html',
  styleUrls: ['./batch-overview-setting.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchOverviewSettingComponent extends GenericComponent implements OnChanges {
  @Input() type: string;
  @Input() modal: string = 'overview';
  @Input() items: any[] = [];
  @Input() itemsNumbers: number = 0;
  @Input() selectAll: boolean = false;
  @Input() paginator: any;
  @Input() overviewType: any;
  @Input() filters: boolean = false;

  @Output() onloadItem: EventEmitter<any> = new EventEmitter();
  public fileName: string = '';
  public statusFilter: string = '';
  public hasNonSmartReportingEntry = false;
  constructor(
    private _searchService: SearchService,
    private dialog: MatDialog,
    private excelService: ExcelServiceService,
    public globals: Globals,
    private route: ActivatedRoute,
    private _integrationService: IntegrationsService
    ) {
      super();
    }

  ngOnChanges(changes: SimpleChanges): void {
    if ( this.type === 'decisions' || this.type === 'complaints') {
      this.hasNonSmartReportingEntry = this.items.some(obj => obj.entry_type !== "smart_reporting");
    }
  }
    //on batch deletion
  public onDelete(): void{
    const list = getBatchIds(this.items);
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: translate('delete_item')
    });
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if(remove){
        this._searchService.batchDeletion(this.type,list)
        .pipe(
          finalize(()=> {
            showFeedbackRemoved();
            this.onloadItem.emit(true);
          }),
          catchError(async (err) => showFeedbackError(err))
        ).subscribe();
      }
    })
  }
  //on batch restore
  public onRestore(): void{
    const list = getBatchIds(this.items);
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: {type: 'restore', text: translate('restore_item')}
    });
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if(remove){
        this._searchService.batchRestore(this.type,list)
        .pipe(
          tap(()=> {
            showFeedbackSaved();
            this.onloadItem.emit(true);
          }),
          catchError(async (err) => showFeedbackError(err))
        ).subscribe();
      }
    })
  }
  //on open action module
  public openActionModal(){
    const dialog = this.dialog.open(AddStakeholdersActionComponent, modalConfig({
      data: {stakeholders:this.items,batch:true},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalDefault));
    dialog.afterClosed().subscribe(() => {
      this.onloadItem.emit(true);
    });
  }
  //on export overview
  public onExport(batch?){
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.statusFilter = paramMap.get('status') || 'open';
    });
    this._searchService.exportFilterOverview(overviewFilterSet(this.paginator,batch,getBatchIds(this.items),this.statusFilter,this.type),this.overviewType)
    .pipe(
      tap(res=>{
        if (res.type == 'application/octet-stream') {
          this.fileName = `${this.globals.projectCode }_${this.type}_${moment().format('YYYY.MM.DD')}.zip`;
        }else {
          this.fileName = `${this.globals.projectCode }_${this.type}_${moment().format('YYYY.MM.DD')}.xlsx`;
        }
        this.excelService.saveBlobAsFile(res, this.fileName);
      })
    ).subscribe();
  }
  public onUpgradeDowngradeComplaint() {
    if (!this.hasNonSmartReportingEntry) {
      const ids = {ids: getBatchIds(this.items).map(id => ({ id }))};
      this._integrationService.upgradeDowngradeComplaints(this.type,ids)
      .pipe(
        tap(()=> {
          showFeedbackSaved();
          this.onloadItem.emit(true);
        }),
        catchError(async (err) => showFeedbackError(err))
      ).subscribe();
    }
  }

  public openBatchLayersModal(){
    const dialog = this.dialog.open(BatchAttachItemsComponent, modalConfig({
      data: {layers:this.items},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));
    dialog.afterClosed().subscribe(() => {
      this.onloadItem.emit(true);
    });
  }

  public createBatchDialogItems(){
    const dialog = this.dialog.open(CreateBatchDialogItemsComponent, modalConfig({
      data: {layers:this.items},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));
    dialog.afterClosed().subscribe(() => {
      this.onloadItem.emit(true);
    });
  }
}
