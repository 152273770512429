import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { SSO } from '../../util/auth/auth.modal';
import { AbstractService } from '../common/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class SsoService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
   }

  @ServiceErrorHandler()
  public update(id,data): Observable<SSO>{
    return this.http.post<SSO>(`/api/admin/customers/${id}/sso`,data, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  public delete(id,connection_id): Observable<SSO>{
    return this.http.delete<SSO>(`/api/admin/customers/${id}/sso/${connection_id}`, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  //sso credentials validation
  public validation(id,data): Observable<any>{
    return this.http.post<any>(`/api/admin/customers/${id}/sso/verify`,data, this.httpOptions)
  }
  //sso domain exist validation
  @ServiceErrorHandler()
  public domainExistValidation(id,data): Observable<any>{
    return this.http.post<any>(`/api/admin/customers/${id}/sso/verify/domain`, {domain:data.domain}, this.httpOptions)
  }
}
