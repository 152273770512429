import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkflowService } from 'src/app/core/service/api/workflow.service';
import { CachedService } from 'src/app/core/service/common/cached.service';

@Component({
  selector: 'app-edit-workflow',
  templateUrl: './edit-workflow.component.html',
  styleUrls: ['./edit-workflow.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditWorkflowComponent implements OnInit {

  isProcessing: boolean = false;
  public selectedItem: any;
  public selectedTasks: any[] = [];
  public tasks: any[] = [];
  public entity: any;

  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditWorkflowComponent>, private _workflowService: WorkflowService, private _cachedService: CachedService) {

   }

  ngOnInit(): void {
    this._cachedService.getActionsRelations().subscribe(res => {
      this.tasks = res;
    })
  }

  selectedWorkflow(){
    const workflow = this.data.workflows.filter(w => w.id === this.selectedItem.id)[0];
    this._workflowService.getWorkflow(workflow.id).subscribe( workflow => {
      this.selectedItem = workflow
    })
  }

  onSelectDialogItem(event: Event) {
    console.log('on change...')
  }

  onChangeRequired() {
    console.log('on change...')
  }

  submit() {
    this.isProcessing = true;
    this._workflowService.updateWorkflow(this.selectedItem.id, this.selectedItem).subscribe( res => {
      this.isProcessing = false;
      this.dialogRef.close('ok');
    });
  }

  close(){
    this.dialogRef.close()
  }
}
