import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Globals} from "../../globals";
import {Permission} from "../../core/enum/permission";

@Directive({
  selector: '[hasPermission]'
})

export class HasPermissionDirective implements OnInit {

  private permissions: Permission[] = [];
  // eslint-disable-next-line no-empty-function
  constructor(private globals: Globals, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {
    this.updateView();
  }

  @Input()
  set hasPermission(val: Permission[]) {
    this.permissions = val;
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (!this.permissions || this.permissions.length === 0) {
      return false;
    }
    // Check for permission by finding index in permissions array, with inner function check if each
    // element is included in global permissions array
    if (this.permissions.findIndex(p => this.globals.permissions.includes(p.toString())) >= 0) {
      return true;
    }

    return false;
  }

}
