<div class="row popup-container" *transloco="let t">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
            <mat-icon class="d-text-middle uil uil-multiply"></mat-icon>
          </span><span class="sr-only">Close</span></button>
            <div *hasPermission="[Permission.DocumentsDelete]" (click)="deleteDocument()">
                <mat-icon *ngIf="document" class="d-cursor-hand uil uil-trash delete pull-right" matTooltip="{{ 'delete' | transloco | capitaliz }}"></mat-icon>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1><strong>{{ 'documents.label' | transloco | capitaliz }} </strong>{{name}}</h1>
        </div>
    </div>
    <mat-progress-bar *ngIf="!documents" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body col-xs-12" appScroll *ngIf="documents">
        <div *ngIf="loaded" class="col-xs-12 pr-0">
            <a *hasPermission="[Permission.DocumentsRead]" class="pull-right">
                <button [versiondownload]="document" class="text-white" mat-flat-button type="button">
                    <mat-icon class="d-text-middle download-icon" *ngIf="document.type !== 'link'">save_alt</mat-icon>
                    <span class="hidden-xs hidden-sm" *ngIf="document.type !== 'link'">{{ 'documents.download' | transloco | capitaliz }}</span>
                    <span class="hidden-xs hidden-sm" *ngIf="document.type === 'link'">{{ 'documents.view' | transloco }}</span>
                </button>
            </a>
        </div>
        <h4 class="control-label mt-20" for="url">{{ 'documents.title' | transloco | capitaliz }}*:</h4>
        <mat-form-field class="full-width set-border-connection1 mb-10" [matTooltip]="(documents.type=='sharepoint') ? t('documents.document_type_sharepoint') : null">
            <input type="text" [disabled]="documents.type=='sharepoint' || !hasPermission(Permission.DocumentsUpdate)" matInput name="name" (ngModelChange)="updateDocument()" [(ngModel)]="documents.name" required />
        </mat-form-field>
        <div class="mt-20">
            <h4>{{ 'decisions.connections' | transloco | capitaliz }}</h4>
            <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component">
                <div>
                    <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                    <app-add-connect-generic parentType="documents" *ngIf="loaded" [parent]="documents" [data]="entities" [types]="['issues','requirements','tasks','contactmoments','complaints', 'permits']" (onChange)="updateEntities($event)"></app-add-connect-generic>
                </div>
            </div>
        </div>
        <div class="mt-20">
            <div *ngIf="loaded" class="col-xs-12 ml-neg-8 pr-0 pl-0">
                <app-generic-multiselect-checkbox type="stakeholders" class="col-xs-12" [disabled]="!hasPermission(Permission.DocumentsUpdate)" #stakeholderMultiselect placeholder="Stakeholder(s)" name="stakeholders" [(ngModel)]="documents.stakeholders" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'"
                    (ngModelChange)="updateDocument()">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                    </app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                        {{ stakeholder.name }}
                    </app-generic-multiselect-checkbox-option>
                    <app-generic-multiselect-checkbox-option-new-action type="stakeholder">
                    </app-generic-multiselect-checkbox-option-new-action>
                    <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
                </app-generic-multiselect-checkbox>
            </div>
        </div>
    </div>
