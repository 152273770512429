import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Auth0Client} from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import moment from 'moment';
import {Observable, Subject, of, throwError} from 'rxjs';
import { catchError, concatMap, debounceTime, tap } from 'rxjs/operators';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { environment } from '../../../../environments/environment';
import { AuthConstants } from "../../../auth-constants";
import { Auth0Token, AuthUserIdentifier, getTokenFromLogin } from '../../util/auth/auth.modal';
import { AbstractService } from '../common/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractService {
  ssoUser: boolean = false
  expiresAt: any
  private debounceTrigger$ = new Subject<any>();
  public authenticated: boolean = false;

  constructor(private constant: AuthConstants, private http: HttpClient, public router: Router) {
    super();
    this.debounceTrigger$
    .pipe(
      debounceTime(30000),
    ).subscribe(() => {
      this.getAccessTokenStatus()
    });
  }

  localAuthSetup(): Observable<any> {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.constant.isAuthenticated$.pipe(
      tap((loggedIn: boolean) => {
        if (loggedIn) {
          this.constant.auth0Client$.subscribe(
            (client: Auth0Client) => client.getTokenSilently().then(token => {
              this.constant.token = token;
            })
          );

          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.constant.getUser$();
        }
      }),
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.constant.getUser$();
        }

        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.constant.loggedIn = !!response;
    });

    return checkAuth$;
  }
  //auth0 identify
  @ServiceErrorHandler()
  public auhtIdentify(email): Observable<AuthUserIdentifier> {
    return this.http.get<AuthUserIdentifier>(`/api/login/identify?email=${email}`, this.httpOptions);
  }
  //get Token from SSO login
  @ServiceErrorHandler()
  public handleAuthSSOCallback(code, id, usrId): Observable<Auth0Token> {
    return this.http.get<Auth0Token>(`/api/verify/token?code=${code}&customer_id=${id}&user_id=${usrId}`, this.httpOptions);
  }
  //get Token from Auth0 login
  public getAuthToken(email: any, password: any): Observable<Auth0Token> {
    return this.http.post<any>(`https://${environment.auth0.domain}/oauth/token`, getTokenFromLogin(email, password));
  }
  // update last login of the user
  public updateLastLogin(): Observable<any> {
    return this.http.get<any>('/api/user-login-update', this.httpOptions);
  }
  //set change password
  @ServiceErrorHandler()
  public changePassword(data): Observable<any> {
    return this.http.post<any>(`/reset-password`, data, this.httpOptions);
  }
  //set change password
  @ServiceErrorHandler()
  public changePasswordPSModal(data): Observable<any> {
    return this.http.post<any>(`/api/change-password`, data, this.httpOptions);
  }
  //send reset password email
  @ServiceErrorHandler()
  public sendResetPasswordEmail(email): Observable<any> {
    return this.http.get<any>(`/api/reset/password/send?email=${email}`, this.httpOptions)
  }
  //check reset password token validate
  @ServiceErrorHandler()
  public checkResetPasswordTokenValidate(data): Observable<any> {
    return this.http.get<any>(`/api/reset/password?email=${data.email}&token=${data.token}`, this.httpOptions)
  }
  //enable multi factor authentification code
  @ServiceErrorHandler()
  public enableMultiFactorAuthentification(): Observable<any> {
    return this.http.post<any>(`/api/user/two-factor-authentication`, this.httpOptions)
  }
  //disable multi factor authentification code
  @ServiceErrorHandler()
  public disableMultiFactorAuthentification(): Observable<any> {
    return this.http.delete<any>(`/api/user/two-factor-authentication`, this.httpOptions)
  }
  //get multi factor authentification QR code
  @ServiceErrorHandler()
  public getMultiFactorAuthentificationQR(): Observable<any> {
    return this.http.get<any>(`/api/user/two-factor-qr-code`, this.httpOptions)
  }
  //send multi factor authentificatio code
  @ServiceErrorHandler()
  public sendMultiFactorAuthentificationCode(code): Observable<any> {
    return this.http.post<any>(`/api/user/confirmed-two-factor-authentication`, code, this.httpOptions)
  }
  //gets new access and refresh token
  @ServiceErrorHandler()
  public handleRefreshToken(refresh_token:string, email:string) {
    return this.http.get(`/api/refresh/token?refresh_token=${refresh_token}&email=${email}`, this.httpOptions);
  }
  //send multi factor authentificatio code
  public sendMFACode(code): Observable<any> {
    const token: any = sessionStorage.getItem('SuperSecret')
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ token}`,
    });
    return this.http.post<any>(`/api/user/two-factor-challenge`, code, { headers: reqHeader })
  }
  //send Email for reset Code
  @ServiceErrorHandler()
  public sendMFACodeEmail(): Observable<any> {
    const token: any = sessionStorage.getItem('SuperSecret')
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ token}`,
    });
    return this.http.get<any>(`/api/user/reset-two-factor-authentication`, { headers: reqHeader })
  }
  //get recovery Code
  @ServiceErrorHandler()
  public getRecoveryCode(): Observable<any> {
    return this.http.get<any>(`/api/user/two-factor-recovery-codes`, this.httpOptions)
  }

  @ServiceErrorHandler()
  public trustDevice(isTrusted: boolean): Observable<any> {
    const token: any = sessionStorage.getItem('SuperSecret')
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ token}`,
    });
    return this.http.post<any>(`/api/admin/trusted-devices`, { is_trusted_device: isTrusted}, {headers: reqHeader})
  }

  //on logout break session
  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem('refresh_token_unparsed')
    localStorage.removeItem("email");
    localStorage.removeItem("user_id");
    localStorage.removeItem("customer_id");
    localStorage.setItem("is_sso", 'false');
    localStorage.removeItem("exp");
    window.location.href = '/login';
  }
  //set session
  public setSession(token, redirect = true): void {
    localStorage.setItem('token', token);
    this.updateLastLogin().subscribe(() => {
      if (redirect)
      window.location.href = '/';
    });
  }

  //checkSession to acquire new tokens
  public tokenExpiryValidation(token): boolean {
    if (token) {
      const jwtToken = JSON.parse(atob(token.split('.')[1]));
      this.expiresAt = new Date(jwtToken.exp * 1000);
      if (Date.now() > this.expiresAt) {
        return false;
      }
      return true;
    }
  }
  //check is the use is log in or not
  public isLoggedIn(): boolean {
    if (localStorage.getItem('token')) {
      if (this.tokenExpiryValidation(localStorage.getItem('token'))) {
        this.debounceTrigger$.next({});
        return true;
      }
      localStorage.removeItem('token');
      if (localStorage.getItem('is_sso') != 'true') {
        window.location.href = '/login';
      }
      return false;
    }
    return false;
  }
  //auth permission
  public userPermissions(): boolean {
    const permission: any = localStorage.getItem('permissions')
    if (permission == 'true') {
      return true
    }
    return false
  }

  //Check if sso user's accessToken is valid
  public getAccessTokenStatus():void{
    const is_sso = localStorage.getItem('is_sso') == 'true' ? true : false;
    if(is_sso) {
      const expiration_date = moment(localStorage.getItem('exp')).valueOf();
      if(expiration_date) {
        const current_date = moment().add(15, 'minutes').valueOf();
        if(current_date >= expiration_date){
          console.log('sso token is near to expiry...')
          const refresh_token = localStorage.getItem('refresh_token_unparsed');
          if(refresh_token) {
            console.log('unparsed refresh token present...')
            this.getRefreshTokenSSO()
            .pipe(
              tap((res: any) => {
                const jwtToken = JSON.parse(atob(res.access_token.split('.')[1]));
                const refresh_jwtToken = JSON.parse(atob(res.refresh_token.split('.')[1]));
                const expiresAt = refresh_jwtToken.expires_in;
                const refresh_token = refresh_jwtToken.refresh_token;
                const current_time = new Date();
                current_time.setSeconds(current_time.getSeconds() + expiresAt);
                localStorage.setItem("exp",current_time.toString());
                localStorage.setItem("refresh_token",refresh_token);
                localStorage.setItem("refresh_token_unparsed", res.refresh_token);
                localStorage.setItem("access_token",jwtToken.access_token);
                localStorage.setItem("token", res.access_token);
                console.log('Get refresh token successfully :)')
              }),
              catchError((err) => {
                this.logout();
                return throwError(() => err);
              })
            ).subscribe()
          } else {
            this.logout();
          }
        }
      }
    }
  }

  public getRefreshTokenSSO() {
    const token = localStorage.getItem("refresh_token_unparsed");
    const user_id = localStorage.getItem("user_id")? JSON.parse(localStorage.getItem("user_id")) : '';
    const customer_id = localStorage.getItem("customer_id")? JSON.parse(localStorage.getItem("customer_id")) : '';
    return this.http.get(`/api/get/token?token=${token}&customer_id=${customer_id}&user_id=${user_id}`, this.httpOptions);
  }
}



