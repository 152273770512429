/* eslint-disable no-empty-function */
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import posthog from 'posthog-js';
import { SimpleMatPaginationComponent } from 'src/app/shared/components/simple-mat-pagination/simple-mat-pagination.component';
import { ProjectConfigsQuery } from '../../../../akita/project-configs/state/project-configs.query';
import { Decision } from '../../../../akita/requirements/state/decision.model';
import { Permission } from '../../../../core/enum/permission';
import { ThemeType } from '../../../../core/enum/theme-type';
import { DecisionsService } from '../../../../core/service/api/decisions.service';
import { DocumentsService } from '../../../../core/service/api/documents.service';
import { LogsService } from '../../../../core/service/api/logs.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { WithParams } from '../../../../core/service/common/with-params';
import { GenericComponent } from '../../../../core/util/abstract/generic-component';
import { dateIso } from '../../../../core/util/dateIso';
import { ngModelCopy } from '../../../../core/util/ngModelCopy';
import { showFeedback, showFeedbackError, showFeedbackSaved } from '../../../../core/util/notification';
import { Allowed } from '../../../../decorator/allowed-decorator';
import { Globals } from '../../../../globals';
import { OpenlayersMapComponent } from '../../openlayers-map/openlayers-map.component';
import { LogEditComponent, LogEditData } from '../contact-moments-edit/log-edit.component';
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';

@Component({
  selector: 'app-decision-edit',
  templateUrl: './decision-edit.component.html',
  styleUrls: ['./decision-edit.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecisionEditComponent extends GenericComponent implements OnInit, OnDestroy {

  public isProcessing: boolean = false;

  decisionId: number | any;
  decision: any;
  public stakeholders: any[];
  shownStakeholders = [];
  shownLogs = [];
  shownIssues = [];
  shownRelatics = [];
  itemsPerPage = 5;
  themes = [];
  themeType = ThemeType.Decisions;
  selectedTheme: any;
  projectConfig: any;
  integrateWithRelatics = false;

  dateFilter = 'no-date';

  /** Map variables **/
  locationFilter = null;
  showMapReset = false;

  public showConnection: boolean = false;


  @ViewChild(OpenlayersMapComponent) openlayerMap: OpenlayersMapComponent;
  @ViewChild('pagination_decisionLogs') paginationLogs: SimpleMatPaginationComponent;
  @ViewChild('pagination_demands') paginationDemands: SimpleMatPaginationComponent;
  @ViewChild('pagination_relatics') paginationRelatics: SimpleMatPaginationComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {decision: Decision, showConnection: boolean}, public dialogRef: MatDialogRef<DecisionEditComponent>, private cdRef: ChangeDetectorRef,
              private _decisionsService: DecisionsService,
              private _documentsService: DocumentsService,
              private _logsService: LogsService,
              private cachedService: CachedService,
              public route: ActivatedRoute,
              public pubsub: PubsubService,
              public globals: Globals,
              private _projectConfigsQuery: ProjectConfigsQuery,
              public dialog: MatDialog,) {
    super();
    this.projectConfig = globals.projectConfig;

  }


  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    this.decisionId = this.data.decision.id;
    this.showConnection = this.data.showConnection;
    this._projectConfigsQuery.selectFirst()
      .pipe(untilDestroyed(this))
      .subscribe(
      config => {
        if(config){
          if (config['services.relatics.decisions.enabled'] && config['services.relatics.enabled']) {
            this.integrateWithRelatics = true;
          }
        }
      }
    );

    this.cachedService.getStakeholders([],true)
      .pipe(untilDestroyed(this))
      .subscribe(stakeholders => this.stakeholders = stakeholders );
    this.loadDecision();
    posthog.capture('customer_requirement-detail');
  }


  private parseDecision(decision: any) {
    this.decision = decision;

    if (this.paginationDemands) {
      this.paginationDemands.paginator.firstPage();
    }

    if (this.paginationRelatics) {
      this.paginationRelatics.paginator.firstPage();
    } else if (this.decision && this.decision.relatics.length === 0) {
      this.shownRelatics = [];
    }

    this.selectedTheme = this.decision.theme_requirements && this.decision.theme_requirements.length > 0 ?
      this.decision.theme_requirements[0]:
      null;

    this.decision.start_date = dateIso(this.decision.start_date, true, this.globals.timezone);
    this.decision.end_date = dateIso(this.decision.end_date, true, this.globals.timezone);

    if (this.decision.start_date && this.decision.end_date) {
      this.dateFilter = 'ranged-date';
    } else if (this.decision.start_date) {
      this.dateFilter = 'fixed-date';
    } else {
      this.dateFilter = 'no-date';
    }

    this.cdRef.detectChanges();
  }

  public loadDecision() {
    if (!this.decisionId) {
      return;
    }

    this._decisionsService.getDecision(this.decisionId).subscribe(
      (decision: any) => {
        this.parseDecision(decision);
      });
  }

  /**
   * If there is 1 coordinate only, it will be stored as 2 coordinates because of library's restriction
   * Thus we need to process it here to show the point(s) properly
   */
  private normalizeCoordinates(coordinates) {
    if (coordinates.length === 2 && coordinates[0][0] === coordinates[1][0] && coordinates[0][1] === coordinates[1][1]) {
      return [coordinates.shift()];
    }

    return coordinates;
  }

  @Allowed(Permission.DecisionsUpdate)
  public updateDecision(reload = false) {
    if (this.dateFilter === 'no-date') {
      this.decision.start_date = null;
      this.decision.end_date = null;
    } else if (this.dateFilter === 'fixed-date') {
      this.decision.end_date = null;
    } else {
      if (this.decision.start_date && this.decision.end_date && this.decision.start_date >= this.decision.end_date) {
        showFeedback('Einddatum mag niet eerder dan begindatum', 'error');
        return;
      }
    }

    this._decisionsService.updateDecision(this.decisionId, this.decision).subscribe(
      () => {
        showFeedbackSaved();
        if (reload) {
          this.loadDecision();
        }
      },
      (e: HttpErrorResponse) => {
        if (e.status !== 500 && e.error && e.error.message) {
          showFeedback(`Er is en error opgetreden (${e.error.message})`);
        } else {
          showFeedbackError(e);
        }
      }
    );
  }

  public showMapResetButton() {
    this.showMapReset = true;
  }

  public hideMapResetButton() {
    this.showMapReset = false;
    this.updateCoordinate();
  }

  @Allowed(Permission.DecisionsUpdate)
  public updateCoordinate() {
    this.updateDecision();
  }

  public updateStakeholdersLogs(update) {

    const id = update.id;
    const logs = update.logs;

    return this._decisionsService.updateDecision(id, logs)
      .subscribe( () => {
        showFeedbackSaved();
        this.loadDecision();
      });

  }

// UPDATE LOGS & STAKEHOLDERS

  public connectDecisionRelatics(relatics) {
    this.updateDecision(true);
  }

  @Allowed(Permission.DecisionsUpdate)
  public removeRelatics(instance) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: `Weet u het zeker dat u het ${instance.type} koppeling wilt verwijderen?`
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe( (remove: boolean) => {
      if (remove) {
        this.decision.relatics = this.decision.relatics.filter(r => r.id !== instance.id);
        this.updateDecision(true);
      }
    });
  }

  public setShownLogs(logs) {
    this.shownLogs = logs;
    this.globals.cdRef.detectChanges();
  }

  public setShownIssues(issues) {
    this.shownIssues = issues;
    this.globals.cdRef.detectChanges();
  }

  public setShownStakeholders(stakeholders) {
    this.shownStakeholders = stakeholders;
    this.globals.cdRef.detectChanges();
  }

  public editLog(log: any): void {

    this._logsService.getLog(log.id, new WithParams().with(
      ['series', 'stakeholders', 'users', 'employees', 'tasks', 'issues',
        'complaints', 'activities', 'requirements', 'documents']
    )).subscribe((editedLog: any) => {
      const data: LogEditData = {
        log: editedLog,
        connectStakeholders: true,
      };

      const editDialog = this.dialog.open(LogEditComponent, {
        data: data,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%'
      });

      editDialog.afterClosed().subscribe( (dialogData: {
        log: any,
        updated: boolean,
      }) => {
        if (dialogData && dialogData.updated) {
          ngModelCopy(log, dialogData.log);
        }
      });
    });
  }

  public updateSelectedTheme(theme) {
    this.decision.theme_requirements = [theme];
    this.updateDecision();
  }

  public close(): void {
    this.dialogRef.close();
  }

}
