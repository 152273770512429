import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AbstractService} from "../common/abstract.service";
import {ReportResponse} from "./report.service";
import {map} from "rxjs/operators";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {Location} from '../../../akita/locations/state/location.model';
import { Observable } from 'rxjs';
import { Notifications } from 'src/app/core/util/notification';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  readNotifications() {
    return this.http.get('/api/notifications/read', this.httpOptions);
  }

  clearNotifications() {
    return this.http.delete('/api/notifications/personal/delete', this.httpOptions);
  }

  updateUserConfigs(userConfigs) {
    return this.http.post('/api/my/userconfigs', userConfigs, this.httpOptions);
  }

  sendSupportMail(phone, message) {
    return this.http.post('/api/support', {
      phone: phone,
      message: message
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  getAddressCount(location: Location) {
    return this.http.post('/api/addresscount', location, this.httpOptions);
  }

  @ServiceErrorHandler()
  getAddresses(middelId, location: Location, format: string = 'csv') {
    return this.http.post('/api/getaddresses', {
      middelId: middelId,
      location: location,
      format: format,
    }, {
      responseType: `blob`,
      observe: 'response',
    }).pipe(
      map((response: HttpResponse<any>) => this.mapReportResponse(response))
    );
  }

  @ServiceErrorHandler()
  getEmailCount(location: Location) {
    return this.http.post('/api/emailcount', location, this.httpOptions);
  }

  heartbeat(redirectWhenRestricted = false) {
    const timer = 30000;

    setTimeout(() => {
      this.http.get('/api/heartbeat', this.httpOptions).subscribe(
        () => this.heartbeat(redirectWhenRestricted),
        (e: HttpErrorResponse) => {
          if (redirectWhenRestricted && e.status === 401) {
            window.location.href = '/';
          }
        });
    }, timer);
  }

  private mapReportResponse(response: HttpResponse<any>): ReportResponse {
    const disposition = response.headers.get('content-disposition');
    let filename = 'Report';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return <ReportResponse> {
      filename: filename,
      body: response.body
    };
  }
  updateNotification(data,action) {
    return this.http.post(`/api/integrations/bouwapp/notification?action=${action}`, data, this.httpOptions);
  }
  @ServiceErrorHandler()
  public readAllNotifications(): Observable<Notifications>{
    return this.http.get<Notifications>(`/api/notifications/personal/read`, this.httpOptions)
    .pipe(map(res => res));
  }
  @ServiceErrorHandler()
  public readSingleNotification(notification_id): Observable<Notifications>{
    return this.http.get<Notifications>(`/api/notifications/personal/${notification_id}/read`, this.httpOptions)
    .pipe(map(res => res));
  }
}
