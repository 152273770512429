import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { Permission } from 'src/app/core/enum/permission';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { DecisionsService } from '../../../../akita/requirements/state/decisions.service';
import { Entities } from '../../../../core/enum/entities';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { showFeedback } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../directive/new-entity.directive';
import { OpenlayersMapComponent } from '../../openlayers-map/openlayers-map.component';
import { MapService } from 'src/app/core/service/api/map.service';

@Component({
  selector: 'app-decision-new',
  templateUrl: './decision-new.component.html',
  styleUrls: ['./decision-new.component.sass']
})
export class DecisionNewComponent extends NewEntityModal implements OnInit {
  public responseLayers: any;
  declare public entity: any;

  public projectConfig: any;
  public stakeholders: any[];
  public selectedStakeholders: any[];
  themeType = 'themedecisions';
  public dateFilter = 'no-date';
  public locationFilter = null;
  public showMapReset = false;
  public users: any[];

  @ViewChild(OpenlayersMapComponent) public openlayerMap: OpenlayersMapComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<DecisionNewComponent>,
    public globals: Globals, private _decisionsService: DecisionsService, private _cachedService: CachedService,
    private pubsub: PubsubService, private _mapSerice: MapService ) {
    super(data, dialogRef, globals);
    this.projectConfig = globals.projectConfig;
    dialogRef.disableClose = true;
    this._mapSerice.setData(this.data.params);
    if (this.data.params && this.data.params.locations) {
      if (!this.data.params.locations.locatable_type || this.data.params.locations.locatable_type === 'undefined') {
        this.data.params.locations.locatable_type = 'requirements'
      }
    }
  }

  ngOnInit() {
    this._cachedService.getStakeholders().subscribe((stakeholders: any[]) => this.stakeholders = stakeholders);
    this._cachedService.getUsers().subscribe(users => this.users = users);
    super.ngOnInit();
  }

  protected initializeEntity(): void {
    this.entity = {
      title: null,
      description: null,
      start_date: null,
      end_date: null,
      points: null,
      logs: [],
      stakeholders: [],
      status: 'initial',
      issues: [],
      text_status: 'concept',
      users:[]
    };

  }

  public submit(): void {
    if (this.dateFilter === 'no-date') {
      this.entity.start_date = null;
      this.entity.end_date = null;
    } else if (this.dateFilter === 'fixed-date') {
      this.entity.end_date = null;
    } else {
      if (this.entity.start_date && this.entity.end_date && this.entity.start_date >= this.entity.end_date) {
        showFeedback('Einddatum mag niet eerder dan begindatum', 'error');
        return;
      }
    }
    this.isProcessing = true;
    this._decisionsService.create(this.entity)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:data,
                message: translate('error.stored'),
                type:'openItems',
                item:'klanteisen',
                url:'klanteisen'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.requirements, data);
        });
  }

  @Allowed(Permission.DecisionsCreate)
  public updateSelectedTheme(theme) {
    this.entity.theme_requirements = theme;
  }
}
