import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Globals } from "../../../../globals";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { FormGroup } from "@angular/forms";
import { showFeedbackSaved } from '../../../../core/util/notification';
import { ProjectConfigsService } from '../../../../akita/project-configs/state/project-configs.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { Router } from '@angular/router';
import { MessageConfirmComponent } from '../message-confirm/message-confirm.component';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { translate } from '@jsverse/transloco';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare let window: any;

@Component({
  selector: 'app-integrations-modal-sharepoint',
  templateUrl: './integrations-modal-sharepoint.component.html',
  styleUrls: ['./integrations-modal-sharepoint.component.css']
})
export class IntegrationsModalSharepointComponent extends IntegrationsModalComponent implements OnInit {

  private connectionTimeout = 3; // Checking of connecting to 3rd party service timeout after 5 minutes
  protected showPopupBlockedMessage = false;
  public sharepointConnected = false;
  public showFirstStepError = false;
  public showFirstStepSuccess = false;
  public redirectUrl = `${window.location.origin}/connect/sharepoint/`;
  public directories: any;
  public sharepointenable:boolean=false;
  public errorMessage:boolean=false;
  public migrationProcess: boolean = false;
  public migrationStatus: boolean = false;
  migrationCompleted: boolean = false
  public migrationMessage = '';
  public level:any='';
  public currentSharePointDirectory = '';
  public changeFolderProgressText = '';
  public isSharePointSecret = false;
  private secretDebouncer: Subject<any> = new Subject();
  @ViewChild('form_integration', { static: false }) form_integration: FormGroup;

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  location: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public globals: Globals, public _projectConfigsService: ProjectConfigsService, public dialogRef: MatDialogRef<any>,
  private _integrationsService:IntegrationsService,private _route: Router, public dialog: MatDialog, public pusher: PusherService) {
    super(globals, _projectConfigsService);
    this.currentSharePointDirectory = this.globals.projectConfigs['services.sharepoint.directory'];
  }

  ngOnInit() {
    if(this._route.url.includes('beheer')){
      this.level='company'
    }
    this.secretDebouncer.pipe(
      debounceTime(300)
    ).subscribe((value) => {
      if (value) {
        this.setSharePointSecret(value);
      } else {
        this.setSharePointSecret('');
      }
    });
    this.getConfig('sharepoint');
    this.errorMessage=this.globals.projectConfigs['services.sharepoint.connection_error'];
    this.sharepointenable=this.globals.projectConfigs['services.sharepoint.enabled'];
    if(this.sharepointenable && !this.errorMessage){
      this.checkConnectionStatus(true);
    }
    if(this._integrationsService.sharepoint==undefined){
      this._integrationsService.sharepoint=this._integrationsService.sharepointBroadcasting.subscribe(()=>{

        this._projectConfigsService.get().subscribe( res => {

          if(!res['services.sharepoint.migration.error']){
            this.migrationMessage = translate('settings.sharepoint.mirgationCompleted');
            this.migrationStatus = true;
            this.migrationCompleted = true;
          }else {
            this.migrationMessage = translate('settings.sharepoint.migrationFailed');
          }
        }, err => {
          this.migrationMessage = translate('settings.sharepoint.migrationFailed');
        });
        this._integrationsService.layers=undefined;
      });
    }
  }

  public connectService() {
    if(!this.sharepointenable){
      this._projectConfigsService.getOauhtRedirect('sharepoint').subscribe(
        (response: { url: string }) => {
          localStorage.setItem('redirectType', 'sharepoint');
          window.popup = window.open(response.url, '_blank');
          if (window.popup) {
            this.showPopupBlockedMessage = false;
          } else {
            this.showPopupBlockedMessage = true;
          }
        }
      );
    }else{
      this.globals.projectConfigs['services.sharepoint.connection_error']=false;
      this.updateConfig();
    }
  }

  private checkConnectionStatus(forceCheck: boolean = false) {
    if (!forceCheck && (!this.configuration['services.sharepoint.url'] || !this.configuration['services.sharepoint.client_id'] || !this.configuration['services.sharepoint.client_secret'])) {
      return;
    }
    this._projectConfigsService.getStatus('sharepoint').subscribe(
      (status: any) => {
        this.sharepointConnected = status.authorized;
        if (!status.authorized && !this.form_integration.invalid) {
          this.showFirstStepError = true;
        } else {
          this.showFirstStepError = false;
          this.showFirstStepSuccess = true;
          this.getDirectoryList();
        }
      });
  }

  private getDirectoryList() {
    this._projectConfigsService.fetchSharepointDirectories().subscribe(
      (directories: string[]) => {
        this.directories = Object.keys(directories).map((key) => {
          return directories[key];
        });
      });
  }

  public setSharepointConfig(): void {
    this.setConfig('sharepoint', () => {
      if (this.configuration['services.sharepoint.url']) {
        this._projectConfigsService.assignSharepointRealm(this.configuration['services.sharepoint.url']).subscribe();
      }
    });
  }

  public onSecretChange(event: any): void {
    this.secretDebouncer.next(event.target.value);
  }

  public setSharePointSecret(secret: string): void {
    // remove previous secret
    if (!this.isSharePointSecret && this.configuration['services.sharepoint.client_secret']) {
      this.isSharePointSecret = true;
      this.configuration['services.sharepoint.client_secret'] = '';
      this.setSharepointConfig();
    } else {
      // update new secret
      this.configuration['services.sharepoint.client_secret'] = secret;
      this.setSharepointConfig();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public setRealm(): void {
    if (this.configuration['services.sharepoint.url']) {
      this._projectConfigsService.assignSharepointRealm(this.configuration['services.sharepoint.url']).subscribe(
        () => {
          this.setSharepointConfig();
          this.checkConnectionStatus()
        }
      );
    }
  }
  protected updateConfig() {
    let getKey= this.globals.projectConfigs['services.sharepoint.enabled'];
    getKey=!getKey;
    this.globals.projectConfigs['services.sharepoint.enabled'] = getKey;
    this._projectConfigsService.update('sharepoint', this.globals.projectConfigs).subscribe(
      () => {
        this.ngOnInit();
        showFeedbackSaved();
        if(!this.globals.projectConfigs['services.sharepoint.enabled']){
          this.dialogRef.close();
        }
      });
  }
  public onClickSetSharePointDirectories(){
    this.migrationStatus = false;
    this.migrationMessage = '';
    this.migrationProcess = false;
    this.migrationCompleted = false;
    const data = {
      title: translate('settings.sharepoint.migrationConfirmationTitle'),
      body: translate('settings.sharepoint.migrationConfirmationBody'),
      confirmCheckText: translate('settings.sharepoint.mirgationConfirmationAcceptance'),
      confirmButton: false,
      type: ''
    }
    // if different path to migrate show confirmation modal
    if( this.currentSharePointDirectory && this.currentSharePointDirectory !== this.configuration['services.sharepoint.directory']) {
      const dialog = this.dialog.open(MessageConfirmComponent, {
        data: data,
        width: "50vw"
      });
      dialog.afterClosed().subscribe(( confirm: boolean) => {
        if(confirm) {
          this.migrationProcess = true;
          this.migrationMessage = translate('settings.sharepoint.migrationInProcess');
          this.setConfig('sharepoint', () => {
            this.currentSharePointDirectory = this.configuration['services.sharepoint.directory'];
            this._integrationsService.migrateDocuments(this.configuration['services.sharepoint.directory'])
            // eslint-disable-next-line no-empty-function
            .subscribe(res => {}
              ,(err) => {
              this.migrationMessage = translate('settings.sharepoint.migrationFailed');
            })
          });
          showFeedbackSaved();
        }
        if(!confirm) {
          this.setConfig('sharepoint', () => {
            this.currentSharePointDirectory = this.configuration['services.sharepoint.directory'];
          });
          showFeedbackSaved();
        }
      })
    } else {
      this.currentSharePointDirectory = this.configuration['services.sharepoint.directory'];
      this.setConfig('sharepoint', () => {
        showFeedbackSaved();
      })
    }
  }
}
