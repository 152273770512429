<form [formGroup]="usersForm">
    <mat-form-field class="modal-newlog-users_multiselect full-width">
      <mat-label>{{ placeholder }}</mat-label>
        <input matInput [matAutocomplete]="auto" [matChipInputFor]="usersList" (matChipInputTokenEnd)="addUser($event)" [disabled]="disabled" formControlName="text"  class="add-users"/>
        <mat-chip-grid #usersList>
            <mat-chip-row *ngFor="let user of selectedUsers" [removable]="!disabled" (removed)="removeUser(user)">
                {{user.name}}
                <span *ngIf="chipDisabler.length === 0">
                    <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
                </span>
                <span *ngFor="let chip of chipDisabler">
                    <mat-icon matChipRemove *ngIf="!disabled && chip.name !== user.name">cancel</mat-icon>
                </span>
            </mat-chip-row>
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addUser($event)">
            <mat-option *ngFor="let user of filteredUsers; let index = index" [value]="user" [ngClass]="{'disable_enitiy': user.is_permission}" [class]="'add-users-list-item-' + ( index+1 )">
                {{ user.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
