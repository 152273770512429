import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { VarMigrateResident } from "../../../features/main-app/communication/residents-list/residents-migraterow-popup/var-resident";
import { AbstractService } from "../common/abstract.service";
import { PaginatedResponse } from "../common/paginated-response";
import { PaginationParams } from "../common/pagination-params";

@Injectable({
  providedIn: 'root'
})

export class ResidentsService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public getResidents(pagination: PaginationParams): Observable<PaginatedResponse> {
    if (this.getGlobals().projectConfigs['residents.disabled']) {
      return of(new PaginatedResponse());
    }
      return <Observable<PaginatedResponse>> this.http.get('/api/residents', pagination.get());

  }

  @ServiceErrorHandler()
  public getResident(residentId: number,skipSubproject:boolean=false) {
    let params = new HttpParams();
    if(skipSubproject)
      params = params.append('skip_subproject','1')
    return this.http.get( `/api/residents/${residentId}`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  public submitResident(resident: any): Observable<any> {
    return this.http.post('/api/residents', resident, this.httpOptions);
  }

  @ServiceErrorHandler()
  public updateResident(resident) {
    return this.http.put(`/api/residents/${resident.id}`, resident, this.httpOptions);
  }

  // Remove recident
  @ServiceErrorHandler()
  public removeResident(resident) {
    return this.http.delete(`/api/residents/${resident.id}`, this.httpOptions);
  }

  // migrate resident by id
  @ServiceErrorHandler()
  public migrateIdResident (id: string, form: any): Observable<VarMigrateResident[]> {
    return this.http.post<VarMigrateResident[]>(`/api/residents/${id}/migrate`, form, this.httpOptions);
  }

  // migrate all resident
  @ServiceErrorHandler()
  public migrateAllResident (form: any): Observable<VarMigrateResident[]> {
    return this.http.post<VarMigrateResident[]>('/api/residents/migrate/all', form, this.httpOptions);
  }

  @ServiceErrorHandler()
  public createMultipleResidents(residents: any[]): Observable<any> {
    return this.http.post(`/api/batch/residents`, {
      residents: residents
    }, this.httpOptions);
  }
}
