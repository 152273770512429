<ul class="p-0 overflow-set-notification1">
    <ng-container *ngFor="let notifier of notification">
        <ng-container *ngIf="notifier && notifier.data">
            <ng-container *ngVar="notifier.data as notify_type">
                <ng-container *ngIf="notify_type && notify_type.data">
                    <ng-container *ngVar="notify_type.data as notify">
                        <!-- Item Assigned  -->
                        <li *ngIf="notify_type.type=='item_assigned'">
                            <ng-container *ngIf="notify.is_urgent">
                                <div class="col-xs-12 message d-cursor-hand" (click)="nav((notify.entity=='calendars' ? notify.metadata.entity : notify.entity),notify.metadata)">
                                    <div class="d-flex">
                                        <div class="a-self-center">
                                            <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                        </div>
                                        <div class="a-self-center ml-10">
                                            <span *ngIf="notify.entity=='calendars'">
                                                <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.item_assigned' | transloco}}<span>{{notify.metadata.entity+'.label' | transloco }} </span> {{'notifications.in_app_notifications.an_urgent' | transloco}} <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                            </span>
                                            <span *ngIf="notify.entity!='calendars'">
                                                <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.item_assigned' | transloco}}<span>{{notify.entity+'.label' | transloco }} </span> {{'notifications.in_app_notifications.an_urgent' | transloco}} <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                            </span>
                                            <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                        </div>
                                        <div class="a-self-center"><span><mat-icon>notification_important</mat-icon></span></div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!notify.is_urgent">
                                <div class="col-xs-12 message d-cursor-hand" (click)="nav((notify.entity=='calendars' ? notify.metadata.entity : notify.entity),notify.metadata)">
                                    <div class="d-flex">
                                        <div class="a-self-center">
                                            <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                        </div>
                                        <div class="a-self-center ml-10">
                                            <span *ngIf="notify.entity=='calendars'">
                                                <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.item_assigned' | transloco}}<span>{{notify.metadata.entity+'.label' | transloco }} </span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                            </span>
                                            <span *ngIf="notify.entity!='calendars'">
                                                <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.item_assigned' | transloco}}<span>{{notify.entity+'.label' | transloco }} </span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                            </span>
                                            <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </li>
                        <!-- Item Status change  -->
                        <li *ngIf="notify_type.type=='item_status_changed'">
                            <div class="col-xs-12 message d-cursor-hand" (click)="nav((notify.entity=='calendars' ? notify.metadata.entity : notify.entity),notify.metadata)">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                    </div>
                                    <div class="a-self-center ml-10">
                                        <span *ngIf="notify.entity=='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.item_status_changed' | transloco}}<span class="status">{{notify.status | transloco | capitaliz}}</span><span>{{'notifications.in_app_notifications.item_status_changed_adverb' | transloco }}</span><span>{{notify.entity+'.label' | transloco }}</span><a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <span *ngIf="notify.entity!='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.item_status_changed' | transloco}}<span class="status">{{ 'status.'+(notify.status === true ? 'closed': notify.status) | transloco | capitaliz }}</span><span>{{'notifications.in_app_notifications.item_status_changed_adverb' | transloco }}</span><span>{{notify.entity+'.label' | transloco }}</span><a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                          <!-- Item Delete  -->
                        <li *ngIf="notify_type.type=='item_deleted'">
                          <div class="col-xs-12 message d-cursor-hand" sharedModal="recycleBin" [data]="notify.entity === 'requirements' ? 'decisions' : notify.entity === 'contactpersons' ? 'employees' : notify.entity === 'contactmoments' ? 'logs' : notify.entity === 'tasks' ? 'actions' : notify.entity">
                              <div class="d-flex">
                                  <div class="a-self-center">
                                      <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                  </div>
                                  <div class="a-self-center ml-10">
                                      <!-- language based check for different structure -->
                                      <span *ngIf="language === 'nl'">
                                          <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.has' | transloco}}<span>{{ (notify.entity!=='calendars'? notify.entity : notify.metadata.entity)+'.label' | transloco }}</span><a><strong>{{notify.name | truncatie : 25}}</strong></a><span>{{'notifications.in_app_notifications.item_delete' | transloco}}</span></p>
                                      </span>
                                      <span *ngIf="language === 'en'">
                                        <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.has' | transloco}} {{'notifications.in_app_notifications.item_delete' | transloco}}<span>{{ notify.entity!=='calendars'? notify.entity : notify.metadata.entity+'.label' | transloco }}</span><a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                    </span>
                                      <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                  </div>
                                </div>
                            </div>
                        </li>
                        <!-- Comment Tagged  -->
                        <li *ngIf="notify_type.type=='comment_tagged'" >
                            <div class="col-xs-12 message d-cursor-hand" (click)="nav((notify.entity=='calendars' ? notify.metadata.entity : notify.entity),notify.metadata)">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                    </div>
                                    <div class="a-self-center ml-10">
                                        <span *ngIf="notify.entity=='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.comment_tagged' | transloco}}<span>{{notify.metadata.entity+'.label' | transloco }}</span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <span *ngIf="notify.entity!='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.comment_tagged' | transloco}}<span>{{notify.entity+'.label' | transloco }}</span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- Comment Like  -->
                        <li *ngIf="notify_type.type=='comment_liked'">
                            <div class="col-xs-12 message d-cursor-hand" (click)="nav((notify.entity=='calendars' ? notify.metadata.entity : notify.entity),notify.metadata)">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                    </div>
                                    <div class="a-self-center ml-10">
                                        <span *ngIf="notify.entity=='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.comment_liked' | transloco}}<span>{{notify.metadata.entity+'.label' | transloco }}</span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <span *ngIf="notify.entity!='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.comment_liked' | transloco}}<span>{{notify.entity+'.label' | transloco }}</span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- Comment Posted  -->
                        <li *ngIf="notify_type.type=='comment_posted'">
                            <div class="col-xs-12 message d-cursor-hand" (click)="nav((notify.entity=='calendars' ? notify.metadata.entity : notify.entity),notify.metadata)">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <ngx-avatars [name]="notify.notifier_name" initialsSize="2" size="30" textSizeRatio="2.5" borderColor="#ebebeb" bgColor="#e3e3e3" fgColor="#0f0f0f"></ngx-avatars>
                                    </div>
                                    <div class="a-self-center ml-10">
                                        <span *ngIf="notify.entity=='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.comment_posted' | transloco}}<span>{{notify.metadata.entity+'.label' | transloco }} </span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <span *ngIf="notify.entity!='calendars'">
                                            <p class="m-0">{{notify.notifier_name}} {{'notifications.in_app_notifications.comment_posted' | transloco}}<span>{{notify.entity+'.label' | transloco }} </span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        </span>
                                        <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- Bouwapp Notification  -->
                        <!-- <li *ngIf="notify_type.type=='bouwapp_date_changed' || notify_type.type=='bouwapp_update_deleted'">
                            <div class="col-xs-12 message">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <div class="bag_notification"><mat-icon>campaign</mat-icon></div>
                                </div>
                                    <div class="a-self-center ml-10" *ngIf="notify_type.type=='bouwapp_date_changed'">
                                        <p class="m-0">{{'notifications.in_app_notifications.bouwapp_change' | transloco}} <a  [routerLink]="middleRoute(notify_type.middel.calendars[0])"><strong>{{notify_type.middel.calendars[0].metadata.title | truncatie : 25}}</strong></a></p>
                                        <p class="m-0 notification-stamp">{{ notify_type.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                    <div class="a-self-center ml-10" *ngIf="notify_type.type=='bouwapp_update_deleted'">
                                        <p class="m-0">{{'notifications.in_app_notifications.bouwapp_delete' | transloco}} <a  [routerLink]="middleRoute(notify_type.middel.calendars[0])"><strong>{{notify_type.middel.calendars[0].metadata.title | truncatie : 25}}</strong></a></p>
                                        <p class="m-0 notification-stamp">{{ notify_type.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                                <div class="button-display-flex-select">
                                    <a class="mat-stroked-button mat-button-base padding-notification" (click)="updateBouwappMiddle(notify_type,'no')">
                                        <span class="mat-button-wrapper">{{ 'no' | transloco | capitaliz }}</span>
                                    </a>
                                    <a *ngIf="!notify_type.confirmation" class="mat-stroked-button mat-button-base padding-notification" (click)="bouwappNotificationConform(notify_type)">
                                        <span class="mat-button-wrapper">{{ 'yes' | transloco | capitaliz }}</span>
                                    </a>
                                    <a *ngIf="notify_type.confirmation" class="mat-stroked-button mat-button-base padding-notification danger-btn" (click)="updateBouwappMiddle(notify_type,'yes')">
                                        <span class="mat-button-wrapper d-flex"><mat-icon class="icon-align-center-font">delete</mat-icon> {{ 'yes' | transloco | capitaliz }}</span>
                                    </a>
                                </div>
                            </div>
                        </li> -->
                        <!-- Issues Urgency Increase  -->
                        <li *ngIf="notify_type.type=='issues_urgency_increase'">
                            <div class="col-xs-12 message d-cursor-hand" (click)="nav(notify.entity,notify.metadata)">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <div class="bag_notification uil uil-puzzle-piece unicon-icon"><mat-icon></mat-icon></div>
                                    </div>
                                    <div class="a-self-center ml-10">
                                        <p class="m-0">{{'notifications.in_app_notifications.issues_urgency_increase' | transloco}} <span class="status">{{notify.status | transloco | capitaliz}} </span>{{'notifications.in_app_notifications.deadline_issue' | transloco}}
                                            Issue <span>{{notify.entity+'.labels' | transloco | capitaliz}} </span> <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- Fresh Desk Notification -->
                        <li *ngIf="notify_type.type=='freshdesk_integration'">
                            <div  class="col-xs-12 message" (click)="nav('complaints',notify.metadata,true)">
                                <div class="d-flex">
                                    <div class="a-self-center">
                                        <div class=""><img class="freshdesk-logo" src="assets/png/integrations/freshdeskoverview.png"></div>
                                    </div>
                                    <div class="a-self-center ml-10">
                                        <p class="m-0">
                                            <span class="p-null" *ngIf="notify.status=='complaints_created' else msg">{{'notifications.in_app_notifications.freshdesk_ticket_create' | transloco}}</span>
                                            <ng-template #msg><span class="p-null">{{'notifications.in_app_notifications.freshdesk_message_add' | transloco}}</span></ng-template>
                                            <a><strong>{{notify.name | truncatie : 25}}</strong></a></p>
                                        <p class="m-0 notification-stamp">{{ notifier.created_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ul>
