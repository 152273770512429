import { collect } from 'collect.js';
import { Globals } from "../../../globals";
import { Permission } from "../../../core/enum/permission";
import { AppInjector } from "../../../../app-injector";
import { DocuploadType } from "../../../core/enum/docupload-type";
import { MatSnackBar } from '@angular/material/snack-bar';
import { setDefaultLanguage } from '../../../akita/multilingual/multilingual.model';

declare let $: any;

export class GenericComponent {

  Permission = Permission;
  DocuploadType = DocuploadType;
  public snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
  public globals: Globals = AppInjector ? AppInjector.get(Globals) : null;

  // eslint-disable-next-line no-empty-function
  constructor(public oldGlobals?: Globals) { }

  public removeOverlay(): void {
    const dismissButtons = $('button[data-dismiss="modal"]');
    for (let i = 0; i < dismissButtons.length; i++) {
      $(dismissButtons[i]).click();
    }

    const overlays = $('.modal-backdrop');
    for (let i = 0; i < overlays.length; i++) {
      $(overlays[i]).click();
    }
  }

  public hasRight(acl) {
    return this.globals.project[acl];
  }

  public hasPermission(permission: Permission): boolean {
    return this.globals.permissions.includes(permission && permission.toString());
  }

  public hasModule(moduleId): boolean {
    if (this.globals.modules) {
      return this.globals.modules.some(module => module.module_id === moduleId);
    }
    return false;
  }

  public hasSLA(): boolean {
    if (this.globals.project && this.globals.project.sla_enabled) {
      return true;
    }
    return false;
  }

  public hasKPI(): boolean {
    if (this.globals.project.complaints_kpi) {
      return true;
    }
    return false;
  }
  // role check
  public hasRole(role): boolean {
    let value = false;
    if (this.globals.user.scope) {
      this.globals.user.scope.data.forEach(el => {
        if (!el.is_subproject) {
          value = collect(el.roles).contains((value, key) => value.name == role);
        }
      });
    }
    return value;
  }
  //standing level
  public hasStandingLevel(router): boolean {
    if (!router.url.includes('sub')) {
      return true;
    }
    return false;
  }
  //personalization check
  public hasPersonalization(): boolean {
    if (this.globals.project_personalization || this.globals.customer_personalization) {
      return true;
    }
    return false;
  }
  //get language code
  public haslanguageCode(): string {
    return setDefaultLanguage(this.globals, 'user');
  }


  public integrationEnabled(integration: string): boolean {
    switch (integration) {
      case 'arcgis':
        return this.globals.projectConfigs['services.integration.arcgis.enabled'];
        break;
      case 'relatics':
        return this.globals.projectConfigs['services.relatics.enabled'];
        break;
      case 'sharepoint':
        return this.globals.projectConfigs['services.sharepoint.enabled'];
        break;
    }

    return false;
  }

  public trackByFn(index, item): any {
    if (!item) {
      return null;
    }

    if (!item.id && !item.email) {
      return JSON.stringify(item);
    }

    return item.id || item.email;
  }

  public idComparator(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }
  //personilization project logic set
  public projectPersonalizationSetup(type): any {
    switch (type) {
      case 'color':
        if (this.globals.project_personalization) {
          if (this.globals.project_personalization.metadata) {
            if (this.globals.project_personalization.metadata.report) {
              return `#${this.globals.project_personalization.metadata.report.color}`;
            }
          }
        }
        return this.companyPersonalizationSetup(type);
      case 'logo':
        if (this.globals.project_personalization) {
          if (this.globals.project_personalization.logo) {
            return this.globals.project_personalization.logo;
          }
        }
        return this.companyPersonalizationSetup(type);
      default:
        return '';
    }
  }
  //personilization company logic set
  public companyPersonalizationSetup(type): any {
    switch (type) {
      case 'color':
        if (this.globals.customer_personalization) {
          if (this.globals.customer_personalization.metadata) {
            if (this.globals.customer_personalization.metadata.report) {
              return `#${this.globals.customer_personalization.metadata.report.color}`;
            }
          }
        }
        return '#2800D2';
      case 'logo':
        if (this.globals.project_personalization) {
          if (this.globals.project_personalization.logo) {
            return this.globals.project_personalization.logo;
          }
        }
        return '';
      default:
        return '';
    }
  }
}
