
export const GlobalConfig = {
  DEFAULT_DROPZONE_CONFIG : {
    maxFilesize: 25,
    maxFiles: 100,
    maxThumbnailFilesize: 10,
    parallelUploads: 10,
    autoProcessQueue: true,
    addRemoveLinks: true,
    dictFileTooBig: 'Bestand is te groot ({{filesize}}MB). Max : {{maxFilesize}}MB.',
    dictInvalidFileType: 'Dit bestand type kan niet geupload worden.',
    dictResponseError: 'Server geeft een {{statusCode}} code.',
    dictCancelUpload: 'Annuleren',
    dictRemoveFile: 'Verwijderen',
    dictMaxFilesExceeded: 'U kunt niet meer bestanden tegelijk uploaden',
    dictUploadCanceled: 'Upload geannuleerd',
    dictFileSizeUnits: {
      tb: 'TB',
      gb: 'GB',
      mb: 'MB',
      kb: 'KB',
      b: 'b'
    }
  }
};
