import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { Warnings } from 'src/app/akita/warnings/state/warnings.model';
import { WarningsService } from 'src/app/akita/warnings/state/warnings.service';
import warnings from '../../../../core/constants/warnings.json';
import { arcgisTypeDefine, integrationError, integrationValidationArcGis } from '../../../../core/util/integration';
import { Globals } from '../../../../globals';
@Component({
  selector: 'app-in-app-info-window',
  templateUrl: './in-app-info-window.component.html',
  styleUrls: ['./in-app-info-window.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InAppInfoWindowComponent implements OnInit {
  public is_load: boolean=true;
  public warnings$:Observable<Warnings>;
  public info;
  public arcGisintegrationType:any;
  public integrationCheck: string;
  public errorIntegration: boolean=false;
  public errorIntegrationEnterprise: boolean=false;
  // eslint-disable-next-line no-empty-function
  constructor(private _warningsService:WarningsService,private globals: Globals,public dialogRef: MatDialogRef<InAppInfoWindowComponent>,public _projectConfigsService: ProjectConfigsService,) { }

  ngOnInit(): void {
    this.info=warnings;
    this.arcGisintegrationType = arcgisTypeDefine(this.globals);
    this.errorIntegration = integrationError(this.globals,'arcgis_enabled');
    this.errorIntegrationEnterprise = integrationError(this.globals,'arcgis_enterprise_enabled');
    this.integrationCheck = integrationValidationArcGis(this.globals,'arcgis','arcgis_enterprise','arcgis_enabled','arcgis_enterprise_enabled',this.errorIntegration,this.errorIntegrationEnterprise);
    this.loadWarnings();
  }
  //on load warnings
  public loadWarnings(): void{
    this.warnings$=this._warningsService.getInfoWindowsWarnings(this.globals.user.id)
    .pipe(
      finalize(()=>this.is_load=false),
      catchError((err)=>{
        this.is_load=false;
        return throwError(err);
      })
    );
  }
  //on close modal
  public close(): void{
    this.dialogRef.close();
  }
  //integration warnings redirection
  public integrationWarningRediect(warning): void{
    const expiration=-1;
    if(this.arcGisintegrationType=='arcgis_enterprise'){
      localStorage.setItem('redirectType', this.arcGisintegrationType);
      localStorage.setItem('state', this.globals.projectCode);
      this._projectConfigsService.update(this.arcGisintegrationType, this.globals.projectConfigs).subscribe();
      window.open(`${this.globals.projectConfigs['services.integration.arcgis_enterprise.url']}/portal/sharing/oauth2/authorize?client_id=${this.globals.projectConfigs['services.integration.arcgis_enterprise.client_id']}&response_type=code&expiration=${expiration}&redirect_uri=${window.location.protocol }//${ window.location.host }/instellingen/integraties`)
    }else{
      localStorage.setItem('redirectType', this.arcGisintegrationType);
      localStorage.setItem('state', this.globals.projectCode);
      this.arcgisCongigsLogs();
      this._projectConfigsService.update(this.arcGisintegrationType, this.globals.projectConfigs).subscribe((res)=>{
        window.open(`https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${this.globals.clientId}&response_type=code&expiration=${expiration}&redirect_uri=${window.location.protocol }//${ window.location.host }/instellingen/integraties`)
      });
      this._projectConfigsService.updateUserConfigs(this.globals.user.configs).subscribe();
    }
    localStorage.setItem('disabled','true');
  }
  //get days different
  public getDaysDifferents(warning): number{
    return moment(warning.warningable.refresh_token_expired_at).diff(moment(), 'days');
  }
  //set info window on snooze
  public onSnooze(warning): void{
    this._warningsService.setUserWarningsSnooze(warning.id,this.globals.user.id,{scheduled_snooze_time:moment().add(1, 'days').format("YYYY-MM-DD HH:mm:ss")})
    .pipe(
      finalize(()=>this.close())
    ).subscribe();
  }
  public arcgisCongigsLogs(){
    // Argis Setup
    if(!this.globals.projectConfigs['services.integration.arcgis.user'] || this.globals.projectConfigs['services.integration.arcgis.user'] === this.globals.user.global_id.toString()) {
      this.globals.projectConfigs['services.integration.arcgis.user']='0';
      this.globals.projectConfigs['services.integration.arcgis.configlevel']='open';
      this.globals.projectConfigs['services.integration.arcgis.enabled']=false;
    }
    this.globals.user.configs.arcgis_enabled=0;
    this.globals.projectConfigs['services.integration.arcgis.connection_error']=false;
    // Argis Setup End
    // Arcgis Enterprise Setup
    if(!this.globals.projectConfigs['services.integration.arcgis_enterprise.user'] || this.globals.projectConfigs['services.integration.arcgis_enterprise.user'] === this.globals.user.global_id) {
      this.globals.projectConfigs['services.integration.arcgis_enterprise.user']='0';
      this.globals.projectConfigs['services.integration.arcgis_enterprise.configlevel']='open';
    }
    this.globals.user.configs.arcgis_enterprise_enabled=0;
    this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled']=false;
    this.globals.projectConfigs['services.integration.arcgis_enterprise.connection_error']=false;
    // Arcgis Enterprise Setup End
  }
}
