import { Injectable } from '@angular/core';
import { AbstractService } from "../common/abstract.service";
import { HttpClient } from "@angular/common/http";
import { catchError, shareReplay, tap } from "rxjs/operators";
import { of } from "rxjs";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public getConfigs_deprecated() {
    return this.http.get('/api/configs', this.httpOptions);
  }

  //TODO: Use injected globals when permission branch is merged
  @ServiceErrorHandler()
  public updateLocalConfigs_deprecated() {
    return this.http.get('/api/configs', this.httpOptions).pipe(
      catchError(val => of(val)),
      tap(configs => this.getGlobals().projectConfigs = configs)
    );
  }

  @ServiceErrorHandler()
  public getConfig_deprecated(serviceGroup: string) {
    return this.http.get(`/api/configs?group=${serviceGroup}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public updateConfigSetting_deprecated(serviceGroup: string, service: any) {
    return this.http.put(`/api/configs?group=${serviceGroup}`, service, this.httpOptions);
  }
  @ServiceErrorHandler()
  public setLayerConection(layers) {
    return this.http.put(`/api/layers`, layers, this.httpOptions);
  }
  @ServiceErrorHandler()
  public batchLayerConection(layers) {
    return this.http.post(`/api/batch/layers`, layers, this.httpOptions);
  }
  public getLayerConection(layers) {
    return this.http.post(`/api/getlayers`, layers, this.httpOptions);
  }
  @ServiceErrorHandler()
  public setRelationConnection(layers) {
    return this.http.get(`/api/layers/${layers}`, this.httpOptions);
  }
  //get all project settings
  @ServiceErrorHandler()
  public getAllProjectsSettings() {
    return this.http.get(`/api/my/userconfigs/projects/all`, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  //attached project settings
  @ServiceErrorHandler()
  public attachedProjectsSettings(code,data) {
    return this.http.post(`/api/my/userconfigs/projects/${code}`, data, this.httpOptions);
  }

  public getUser(id) {
    return this.http.get(`/api/${id}/user/`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getConfigs(body) {
    return this.http.post(`/api/getConfigs`, body);
  }
}
