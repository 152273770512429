import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import posthog from 'posthog-js';
import { forkJoin, of } from 'rxjs';
import { Complaint } from 'src/app/akita/complaints/state/complaint.model';
import { Log } from 'src/app/akita/contact-moments/state/log.model';
import { Issue } from 'src/app/akita/issues/state/issue.model';
import { Decision } from 'src/app/akita/requirements/state/decision.model';
import { Action } from 'src/app/akita/tasks/state/action.model';
import { DocumentsService } from 'src/app/core/service/api/documents.service';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { showFeedbackRemoved, showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { DeletionModalComponent } from 'src/app/shared/components/modal/deletion-modal/deletion-modal.component';
import { Document } from '../../../../../akita/documents/state/document.model';

@Component({
  selector: 'app-documents-detail',
  templateUrl: './documents-detail.component.html',
  styleUrls: ['./documents-detail.component.sass'],
})
export class DocumentsDetailComponent extends GenericComponent {
  loaded = false;
  public document:any;
  public documentId:any;
  public name:any;
  logs: Log[] = [];
  stakeholders:any[]=[];
  decisions: Decision[] = [];
  issues: Issue[] = [];
  actions: Action[] = [];
  complaints: Complaint[] = [];
  documents:Document;
  projectConfig: any;
  entities: any
  constructor(
    public pubsub: PubsubService,
    public globals: Globals,
    private dialogRef: MatDialogRef<DocumentsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialog: MatDialog,
    private cachedService: CachedService,
    private _logsService: LogsService,
    public documentServices:DocumentsService
    ) {
      super(globals);
  }

  ngOnInit() {
    this.cachedService.getStakeholdersRelations().subscribe(
      stakeholders => {
        this.stakeholders = stakeholders;
      }
    );
    this.name = this.data.name;
    this.document=this.data;
    this.documentId=this.data.id;
    this.documentServices.getDocumentsByID(this.document.id).subscribe(((res:any)=>{
      this.documents=res;
      forkJoin([
        this.cachedService.getActionsRelations(),
        this.cachedService.getIssuesRelations(),
        this.cachedService.getDecisionsRelations(),
        this.cachedService.getLogsRelations(),
        this.cachedService.getComplaintsRelations(),
        this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
          ? this.cachedService.getPermitsRelations() 
          : of([])
      ]).subscribe(([tasks, issues, requirements, contactmoments, complaints, permits]) => {
        this.entities = { tasks, issues, requirements, complaints, contactmoments: contactmoments, permits: permits }
        this.actions = tasks;
        this.logs = contactmoments;
        this.loaded = true;
      });
    }))
    posthog.capture('document-detail');
  }

  public updateDocument(){
    this.documentServices.updateDocument(this.documentId,this.documents).subscribe(()=>{
      showFeedbackSaved();
    })
  }
  public deleteDocument() {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u het document wilt verwijderen?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.documentServices.deleteDocument(this.documents)
          .subscribe(
            () => {
              this.close();
              showFeedbackRemoved();
            }
          );
      }
    });
  }
  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }
  public updateEntities(event){
    this.documents[event.type] = event.selected;
    this.updateDocument();
  }
}
