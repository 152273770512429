import { Component, ChangeDetectionStrategy, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { SubprojectsService } from 'src/app/akita/subproject/state/subprojects.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { WhatsappService } from 'src/app/core/service/api/whatsapp.service';
import { FacebookService } from 'src/app/core/service/common/facebook.service';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { Globals } from 'src/app/globals';
import { WhatsappMfaComponent } from '../whatsapp-mfa/whatsapp-mfa.component';
import { EventBusService } from 'src/app/core/service/common/event-bus-service.service';


declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any; // Declare FB as any type
  }
}

declare global {
  interface Window {
    fbq: any; // Declare fbq as any type
  }
}
@Component({
  selector: 'app-whatsapp-integration-modal',
  templateUrl: './whatsapp-integration-modal.component.html',
  styleUrls: ['./whatsapp-integration-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappIntegrationModalComponent extends IntegrationsModalComponent implements OnInit, OnDestroy {
  private eventSubscription: Subscription;
  public whatappenable: boolean = true;
  public errorMessage: boolean = false;
  sessionInfoSubscription: Subscription;
  private serviceGroup: string = 'whatsapp';
  usersNumbers: any;
  selectedNumbers: any
  whatsappPhoneId: any
  whatsappPhoneName: any
  selectedSubprojects: any
  whatsappPhoneNumber: any
  enableSubProjects: boolean = true
  whatsappenable: boolean = false;
  modulesAvailable: boolean = false;
  programModule: boolean = false;
  whatsAppProjects: any
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _projectConfigsService: ProjectConfigsService,
    public globals: Globals,
    public pubsub: PubsubService,
    public dialogRef: MatDialogRef<any>,
    private fbService: FacebookService,
    private cd: ChangeDetectorRef,
    private whatsAppService: WhatsappService,
    public _subproject: SubprojectsService,private dialog: MatDialog, private eventBus: EventBusService,

  ) {
    super(globals, _projectConfigsService);

  }

  ngOnInit(): void {
    this.whatsappenable = this.globals.projectConfigs['services.integration.whatsapp.enabled'];
    this.errorMessage = this.globals.projectConfigs['services.integration.whatsapp.connection_error'];
    this.eventSubscription = this.eventBus.verifyDataConfigs$.subscribe((action: string) => {
      this.verifyDataConfigs(action);
    });
    if (this.whatsappenable) {
      this.getPhoneNumbers()
    }
    if (this.globals.modules.length > 0) {
      this.globals.modules.forEach(element => {
        if (element.module_id == 'communication') {
          this.modulesAvailable = true;
          this.cd.detectChanges();
        }
        if (element.module_id == 'subprojects') {
          this.programModule = true;
          this.cd.detectChanges();
        }
      });
    }
    if(this.globals.modules.length>0){
      this.globals.modules.forEach(element => {
        if (element.module_id == 'communication') {
          this.modulesAvailable = true;
          this.cd.detectChanges();
        }
        if(element.module_id=='subprojects'){
          this.programModule=true;
          this.cd.detectChanges();
        }
      });
    }

  }

  loginWithFacebook(): void {
    this.fbService.login().then((response) => {
      // Handle the response
    }).catch((error) => {
      this.verifyDataConfigs('disable')
    });
  }

  getPhoneNumbers() {
    this.whatsAppService.getPhoneNumbers().subscribe((numbers: any) => {
      if(numbers.platform_type !== 'CLOUD_API' || numbers.code_verification_status !== 'VERIFIED') {
         this.usersNumbers = [numbers]
        this.getWhatsAppProject()
        this.setMfaPin()
        this.cd.detectChanges();
      } else {
        this.usersNumbers = [numbers]
        this.getWhatsAppProject()
        this.cd.detectChanges();
      }

    })
  }

  public verifyDataConfigs(action: any) {

    if (action === 'verify') {
      this.globals.projectConfigs['services.integration.whatsapp.connection_error'] = false;
      this.globals.projectConfigs['services.integration.whatsapp.enabled'] = true
      this.globals.projectConfigs['services.integration.whatsapp.user'] = this.globals?.user?.global_id;

    } else {
      this.globals.projectConfigs['services.integration.whatsapp.connection_error'] = false;
      this.globals.projectConfigs['services.integration.whatsapp.enabled'] = false
      this.globals.projectConfigs['services.integration.whatsapp.user'] = this.globals?.user?.global_id;
      this.globals.projectConfigs['services.integration.whatsapp.enabled'] = false
    }
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        if (action === 'verify') {
          this.getPhoneNumbers()
          this.cd.detectChanges();
        } else {
          this.globals.projectConfigs['services.integration.whatsapp.enabled'] = false;
          this.configsSetup()
          this.cd.detectChanges();
        }

      });

  }

  public configsSetup() {
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        // showFeedbackSaved();
      });
  }


  setMfaPin() {
    const dialogRef = this.dialog.open(WhatsappMfaComponent, {
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response === true) {
        this.getPhoneNumbers()
      }
    });
  }

  public getWhatsAppProject() {
    this._subproject.get().subscribe((res: any) => {
      this.whatsAppProjects = res;
      for (const data of this.whatsAppProjects) {
        const whatsappPhoneId = data["whatsapp_phone_id"];
        const whatsappPhoneName = data["whatsapp_phone_name"];
        const whatsappPhoneNumber = data["whatsapp_phone_number"];

        if (whatsappPhoneId && whatsappPhoneName && whatsappPhoneNumber) {
          const selectedUser = this.usersNumbers.find(user => user.display_phone_number.trim() === whatsappPhoneNumber.trim());
          if (selectedUser) {
            this.selectedNumbers = selectedUser; // Set selectedNumbers if the user is found
            this.selectedSubprojects = data
            this.enableSubProjects = false
          }
          this.cd.detectChanges();
          break;

        }
      }

    });

  }


  selectNumber(event: any) {
    this.whatsappPhoneId = event.id;
    this.whatsappPhoneName = event.verified_name;
    this.whatsappPhoneNumber = event.display_phone_number;
    this.enableSubProjects = false;
  }

  updateSubprojects(event: any) {
    if (this.whatsappPhoneId !== undefined) {
      event.whatsapp_phone_id = +this.whatsappPhoneId;
      event.whatsapp_phone_name = this.whatsappPhoneName;
      event.whatsapp_phone_number = this.whatsappPhoneNumber;

      const subprojects = [event]

      this.addNumberSubProjects(subprojects)
    }

  }

  addNumberSubProjects(data) {
    this._projectConfigsService.putBouwAppProjects(data).subscribe(((res: any) => {
      // showFeedbackSaved();
    }));
  }


  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
