<div mat-dialog-title>
  <h4 class="inline-block">{{ 'projects.new_project' | transloco }}</h4>
  <button type="button" class="close" (click)="close()">
    <span aria-hidden="true">
      <mat-icon class="d-text-middle">close</mat-icon>
    </span>
    <span class="sr-only">Close</span>
  </button>
</div>

<div *transloco="let t">
  <form #form="ngForm" (submit)="submit()">
      <div class="row">
          <div class="col-xs-12 col-md-4">
            <mat-form-field class="full-width">
                <mat-label>{{'projects.name_field' | transloco}}</mat-label>
                <input matInput type="text" name="name" [(ngModel)]="project.name" required />
              </mat-form-field>
              <app-generic-multiselect-checkbox placeholder="Module(s)*" name="stakeholders" [(ngModel)]="project.modules" (ngModelChange)="onModuleChange($event)">
                <app-generic-multiselect-checkbox-option *ngFor="let module of modules" [value]="module">
                  {{ 'module.'+module.module_id | transloco | capitaliz }}
                </app-generic-multiselect-checkbox-option>
              </app-generic-multiselect-checkbox>
              <!-- later release -->
              <!-- <div>
                <app-generic-multiselect-checkbox [hideCancleIcon]="true" [multiple]="false" [placeholder]="t('settings.language')" name="multilingual"  [(ngModel)]="language" [required]="true">
                  <app-generic-multiselect-checkbox-option *ngFor="let lang of multilingual; trackBy: trackByFn" [value]="lang">
                      {{ lang.title | capitaliz }}
                  </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
              </div> -->
          </div>
          <div class="col-xs-12 col-md-8">
              <label class="material-style-label text-capitalize">
                  {{ t('projects.map_field') }}
                </label>
              <app-openlayers-map [(ngModel)]="project.polygons"
                                  name="polygons"
                                  [includeSidebar]="false"
                                  [defaultCoordinate]="globals.nederlandMap"
                                  [showMapReset]="true"
                                  [showPinTooltip]="false"
                                  [singleEntityType]="true"
                                  locatableType="projects"
                                  defaultDrawTool="area"
                                  clickStartDrawingMsg="Teken subprojectgebied"
                                  required
              >
              </app-openlayers-map>
          </div>
      </div>


    <button *ngIf="!processing" type="submit" mat-flat-button class="text-capitalize pull-right ml-10 mt-10" [disabled]="form.invalid">Toevoegen</button>
    <button *ngIf="!processing" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="close()">{{ t('close') | capitaliz }}</button>
    <span *ngIf="processing" class="mt-10 pull-right loader-span">
  <img class="pull-left group_svg spinner" src="/assets/png/spinner.png" alt="decisions" />
  {{ 'projects.creating' | transloco }}</span>
  </form>
</div>
