<form>
    <!-- this form tag doesn't seem to be closed anywhere-->
    <div mat-dialog-title>
        <h2 class="mb-10 inline-block">{{ 'settings.bouwapp.publish_modal_title' | transloco }} <img src='assets/png/integrations/bouwappchat.png' class="bouwapp-chat"></h2>
        <button type="button" class="close" (click)="close()">
    <span aria-hidden="true"><mat-icon>close</mat-icon></span>
    <span class="sr-only">Close</span>
    </button>
    </div>

    <!-- modal body -->
    <mat-dialog-content *transloco="let t">
        <div class="col-xs-12 col-md-6">
            <mat-form-field class="no-padding full-width">
                <input matInput name="date" [matDatepicker]="picker" placeholder="Datum" (focus)="picker.open()" [(ngModel)]="entity.publicationDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-6">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="full-width" [(ngModel)]="entity.sendPush" required>
                <mat-button-toggle [value]="true" class="full-width">{{ 'settings.bouwapp.push_notification' | transloco }}</mat-button-toggle>
                <mat-button-toggle [value]="false" class="full-width">{{ 'settings.bouwapp.non_push_notification' | transloco }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-xs-12 col-md-12">
            Update
            <div class="col-xs-12 mt-5 row contactmoment-notes-container modal-white-background">
                <editor [apiKey]="apiKey" [(ngModel)]="entity.content" name="content" [init]="{
                    plugins: 'lists table fullscreens autoresize',
                    branding: false,
                    menubar: false,
                    language: haslanguageCode() === 'en' ? haslanguageCode()+'_GB' : haslanguageCode(),
                    language_url: 'assets/js/tinymce.nl.js',
                    autoresize_on_init: true,
                    statusbar: false,
                    min_height: 200
                }" [toolbar]="['undo redo formatselect fontsizeselect table bold italic underline strikethrough lineheight alignleft aligncenter alignright indent numlist bullist removeformat fullscreen']">

                </editor>
            </div>
        </div>
        <div class="col-xs-12 col-md-12">
            <mat-progress-bar *ngIf="is_upload" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-xs-12 col-md-12" *ngIf="!is_upload">
            <app-generic-multiselect-checkbox [multiple]="true" [matTooltip]="documentAttachment ? t('settings.bouwapp.no_document') : null" [disabled]="documentAttachment" [placeholder]="t('settings.bouwapp.select_document')" name="attachments" [(ngModel)]="dialogAttachments"
                (ngModelChange)="documentAttached($event)">
                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                </app-generic-multiselect-checkbox-option-all>
                <app-generic-multiselect-checkbox-option *ngFor="let item of data.calender.documents; trackBy: trackByFn" [value]="item">
                    {{ item.name | capitaliz}} {{ item.fileName | capitaliz}}
                </app-generic-multiselect-checkbox-option>
            </app-generic-multiselect-checkbox>
        </div>
        <div class="col-xs-12 col-md-12">
            <div class="dropzone-previews"></div>
            <dropzone *ngIf="dropzoneConfig" #dropzone [config]="dropzoneConfig" [message]="dropzoneTemplate">
            </dropzone>
            <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf" #file class="file_upload" (change)="DocumentUpLoad($event)" multiple>
        </div>
    </mat-dialog-content>
    <!--end of modal body -->

    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
            <button type="button" mat-stroked-button (click)="close()">{{'close' | transloco | capitaliz}}</button>
            <button type="button" class='delete-button' *ngIf="bouwAppPublish" mat-stroked-button (click)="deleteP()">{{'delete' | transloco | capitaliz }}</button>
            <button type="submit" (click)="submit()" *ngIf="!bouwAppPublish" mat-flat-button>
                <span>
                    <mat-icon class="material-icons d-text-middle">save</mat-icon>
                        {{ 'settings.bouwapp.publish_update' | transloco | capitaliz }}
                </span>
            </button>
            <button type="button" (click)="update()" *ngIf="bouwAppPublish" mat-flat-button>
                <span>
                    <mat-icon class="material-icons d-text-middle">access_time</mat-icon>
                        {{ 'settings.bouwapp.publish_schedule' | transloco | capitaliz }}
                </span>
            </button>
        </div>
    </mat-dialog-actions>
</form>
