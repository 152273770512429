import { EventEmitter,Injectable } from '@angular/core';
import { ActionsStore } from './actions.store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from '../../..//core/service/common/abstract.service';
import { Action } from './action.model';
import { map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { PaginationParams } from '../../../core/service/common/pagination-params';
import { PaginatedResponse } from '../../../core/service/common/paginated-response';
import { ActionsQuery } from './actions.query';
import { deepCopy } from '../../../core/util/deepCopy';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';

export type ActionsPerItem = {
  issues?: (Action[])[],
  complaints?: (Action[])[],
  logs?: (Action[])[],
  decisions?: (Action[])[],
  middel?: (Action[])[]
};

const relations = ['issues', 'complaints', 'contactmoments', 'requirements', 'contents',];

@Injectable({ providedIn: 'root' })
export class ActionsService extends AbstractService {
invokeFunction=new EventEmitter();
invokeFunctionPre=new EventEmitter();
invokeFunctionArc=new EventEmitter();
invokeFunctionFilter=new EventEmitter();
invokeFunctionFilterTrust=new EventEmitter();
invokeFunctionPanelHide=new EventEmitter();
invokeFunctionOverPass=new EventEmitter();
invokeFunctionPublicFacing=new EventEmitter();
subVar:Subscription;
subVarPre:Subscription;
subVarArc:Subscription;
subVarFilter:Subscription;
subVarFilterTrust:Subscription;
subVarPanelHide:Subscription;
subOverPass:Subscription;
subPublicFacing:Subscription;
  constructor(private actionsStore: ActionsStore, private actionQuery: ActionsQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams): Observable<Action[]> {
    return this.http.get(`/api/actions`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Action>) => {
          this.actionsStore.set(response.data);
          if (response.total) {
            pagination.total = response.total;
          }
        }),
        map((response: PaginatedResponse<Action>) => response.data)
      );
  }

  public getGroupedByItem(paginationParams: PaginationParams, usersId?: number): Observable<ActionsPerItem> {

    paginationParams.extra('group_by', 'item');

    if (usersId) {
      paginationParams.extra('users_id', usersId);
    }

    return this.http.get(`/api/actions`, paginationParams.get())
      .pipe(
        tap((response: ActionsPerItem) => {
          relations.forEach(relation => {
            if (response[relation]) {
              for (const id in response[relation]) {
                response[relation][id].forEach(
                  action => this.actionsStore.add(action)
                );
              }
            }
          });
        }),
      );
  }

  @ServiceErrorHandler()
  getFilteredAction(pagination: PaginationParams, status?: string): Observable<PaginatedResponse> {
    pagination.extra('group_by', 'item');
    return <Observable<PaginatedResponse>>this.http.get('/api/actions', pagination.get(status ? new HttpParams().set('status', status) : null));
  }

  @ServiceErrorHandler()
  exportActions(options) {
    return this.http.get(`/file/export/action?`, {
      params: options,
      responseType: `blob`
    });
  }
  @ServiceErrorHandler()
  exportActionPerItem(options){
    return this.http.get(`/file/export/actions_per_item?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  public find(id: string, withs: string[] = [], skipSubproject: boolean = false): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('with', withs.join(','));
    }
    if (skipSubproject)
      params = params.append('skip_subproject', '0')
    const request = this.http.get(`/api/actions/${id}`, {
      headers: this.headers,
      params: params
    })
      .pipe(
        tap((action: Action) => {
          if (this.actionsStore.getValue().entities && Object.entries(this.actionsStore.getValue().entities).length > 0) {
            this.actionsStore.update(action);
          } else {
            this.actionsStore.set([deepCopy(action)]);
          }

          this.actionsStore.setActive(action.id);
        })
      );

    return request;
  }

  @ServiceErrorHandler()
  public update(action: Action): Observable<Action> {
    this.actionsStore.update(action.id, action);
    return this.http.put(`/api/actions/${action.id}`, action, this.httpOptions)
      .pipe(map((response: Action) => response));
  }

  @ServiceErrorHandler()
  public create(action: Action): Observable<Action> {
    return this.http.post('/api/actions', action, this.httpOptions)
      .pipe(tap((response: Action) => {
        this.actionsStore.add(action);
      }));
  }

  @ServiceErrorHandler()
  public delete(action: Action): Observable<any> {
    return this.http.delete(`/api/actions/${action.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.actionsStore.remove(action.id);
      }));
  }
  @ServiceErrorHandler()
  public getRelatic(){
    return this.http.get(`/api/relatics`, this.httpOptions);
  }
  onClickUndo(action){
    this.invokeFunction.emit(action);
  }
  onClickUndoPer(action){
    this.invokeFunctionPre.emit(action);
  }
  onClickUndoArc(action){
    this.invokeFunctionArc.emit(action);
  }
  onClickFilterDataSend(filter){
    this.invokeFunctionFilter.emit(filter);
  }
  onClickFilterDataSendTrust(filter){
    this.invokeFunctionFilterTrust.emit(filter);
  }
  onClickDocumentPanelHide(){
    this.invokeFunctionPanelHide.emit();
  }
  onClickOverPass(f){
    this.invokeFunctionOverPass.emit(f);
  }
  onClickPublicFacing(f){
    this.invokeFunctionPublicFacing.emit(f);
  }
}
