import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { BehaviorSubject, Subject } from "rxjs";
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { MessageConfirmComponent } from 'src/app/shared/components/modal/message-confirm/message-confirm.component';
import { Action } from '../../../akita/tasks/state/action.model';
import { Entities } from '../../../core/enum/entities';
type History = {
  component: any,
  modalSize: ModalEnum,
  data: any,
  title: string
}
type Navigation = {
  back: History,
  next: History,
  current: History,
  overview: any
}
@Injectable({
  providedIn: 'root'
})
export class PubsubService {

  private headerComponentSrc = new BehaviorSubject(null);
  headerComponent = this.headerComponentSrc.asObservable();

  private pageTitleSrc = new BehaviorSubject('');
  pageTitle = this.pageTitleSrc.asObservable();

  private pageSubTitleSrc = new BehaviorSubject('');
  pageSubTitle = this.pageSubTitleSrc.asObservable();

  private pageBackToOverviewSrc = new BehaviorSubject('');
  pageBackToOverview = this.pageBackToOverviewSrc.asObservable();

  private pageMainClassSrc = new BehaviorSubject('');
  pageMainClass = this.pageMainClassSrc.asObservable();

  private tabNameSrc = new BehaviorSubject(null);
  tabName = this.tabNameSrc.asObservable();

  private stakeholderTabCounterSrc = new BehaviorSubject(null);
  stakeholderTabCounter = this.stakeholderTabCounterSrc.asObservable();

  private newEntityPushSrc = new BehaviorSubject({ type: '', data: null });
  newEntitiyPush = this.newEntityPushSrc.asObservable();

  private actionMyFilterSrc = new BehaviorSubject(false);
  actionMyFilter = this.actionMyFilterSrc.asObservable();

  private sidebarOpenSrc = new BehaviorSubject(true);
  sidebarOpen = this.sidebarOpenSrc.asObservable();

  private actionEditedSrc = new BehaviorSubject(null);
  actionEdited = this.actionEditedSrc.asObservable();

  private disableSidebarSrc = new BehaviorSubject(false);
  disableSidebar = this.disableSidebarSrc.asObservable();

  public newLogEntity = new Subject<any>();
  currentDialog: MatDialogRef<any>;
  history: History[] = []
  navigation: Navigation = { back: null, next: null, current: null, overview: null }
  mapDetail:boolean=false;
  // eslint-disable-next-line no-empty-function
  constructor(private dialog: MatDialog, private _subProjectsQuery: SubprojectQuery,) { }

  setHeaderComponent(component: any) {
    this.headerComponentSrc.next(component);
  }

  setPageTitle(pageTitle: string) {
    if (pageTitle !== 'false') {
      this.pageTitleSrc.next(pageTitle)
    }
  }

  setPageSubTitle(pageSubTitle: string) {
    if (pageSubTitle !== 'false') {
      this.pageSubTitleSrc.next(pageSubTitle);
    }
  }

  get PageSubTitle() {
    return this.pageSubTitleSrc.getValue();
  }

  get PageTab() {
    return this.tabNameSrc.getValue();
  }

  setBackOverviewUrl(url: string) {
    this.pageBackToOverviewSrc.next(url);
  }

  setPageMainClass(mainClass: string) {
    this.pageMainClassSrc.next(mainClass);
  }

  setSidebarOpen(state: boolean) {
    this.sidebarOpenSrc.next(state);
  }

  updateTabName(tabName) {
    this.tabNameSrc.next(tabName);
  }

  updateStakeholderTabCounter() {
    this.stakeholderTabCounterSrc.next(null);
  }

  pushNewEntity(type: Entities, data: any) {
    this.newEntityPushSrc.next({
      type: type,
      data: data,
    });
  }

  setMyActionFilter(isMine: boolean): void {
    this.actionMyFilterSrc.next(isMine);
  }

  setActionEdited(action: Action): void {
    this.actionEditedSrc.next(action);
  }

  setDisableSidebar(disable: boolean): void {
    this.disableSidebarSrc.next(disable);
  }

  updateHistory(dialog: any, component: any, data: any, title: string = "", modalSize?: ModalEnum, reset: boolean = true) {
    document.body.classList.add("overflow-hidden");
    this.currentDialog = dialog;
    if (this.navigation.current && reset) {
      const i = this.history.indexOf(this.navigation.current)
      this.history.splice(i + 1)
    }
    this.navigation.current = { "component": component, "data": data, title: title, "modalSize": modalSize }
    this.history.push(this.navigation.current);
    this.updateNavigation();
    this.registerCloseEvents(dialog)
  }

  updateNavigation() {
    const i = this.history.indexOf(this.navigation.current)
    this.navigation = { back: null, next: null, current: this.navigation.current, overview: null }
    if (i == 0)
      this.navigation.overview = true;
    if (i != this.history.length - 1)
      this.navigation.next = this.history[i + 1];
    if (i > 0)
      this.navigation.back = this.history[i - 1];
  }

  nextPanel() {
    this.closeModal(this.currentDialog)
    document.body.classList.add("overflow-hidden");
    const modal = this.dialog.open(this.navigation.next.component, modalConfig({
      data: this.navigation.next.data,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
    }, this.navigation.next.modalSize || ModalEnum.SidebarLargeResponsive));
    this.currentDialog = modal;
    this.navigation.current = this.navigation.next
    this.updateNavigation();
    this.registerCloseEvents(modal)
  }

  prevPanel() {
    this.closeModal(this.currentDialog)
    document.body.classList.add("overflow-hidden");
    const modal = this.dialog.open(this.navigation.back.component, modalConfig({
      data: this.navigation.back.data,
      panelClass: ['animate__animated', 'animate__slideInRight'],
      disableClose: true,
    }, this.navigation.back.modalSize || ModalEnum.SidebarLargeResponsive));
    this.currentDialog = modal;
    this.navigation.current = this.navigation.back
    this.updateNavigation();
    this.registerCloseEvents(modal)
  }

  closeModal(dialog: any, data: any = false) {
    if (!dialog)
      return;
    this.resetOverflow()
    dialog.removePanelClass("animate__slideInRight")
    dialog.addPanelClass("animate__slideOutRight")
    setTimeout(() => {
      dialog.close(data)
    }, 400)
  }

  resetHistory() {
    this.history = [];
  }

  registerCloseEvents(dialog: any) {
    dialog?.backdropClick().subscribe((e) => {
      this.disableSidebarSrc.subscribe(res => {
        if (!res) {
          this.resetHistory();
          this.closeModal(dialog)
        }
      })
    });
    dialog?.afterClosed().subscribe((e) => {
      if (document.querySelector('.popup-container') == null) {
        this.resetOverflow()
      }

    });
  }

  updateSubprojects(self, method: string, subprojects) {
    const activeSubProject = this._subProjectsQuery.getActive();
    if (activeSubProject) {
      const currentProject = subprojects.filter(s => s.code == activeSubProject.code).length;
      if (!currentProject) {
        const modal = this.dialog.open(MessageConfirmComponent, {
          data: { title: translate("text.deselect_current_subproject"), body: translate("text.deselect_current_subproject_text"), type: 'remove_subprject'},
          width: "30vw"
        });
        modal.afterClosed().subscribe((res) => {
          if (res) {
            self[method]()
            setTimeout(() => {
              this.closeModal(this.currentDialog)
            }, 500)
          }
          else {
            if (self.ngOnInit)
              self.ngOnInit()
            if (method == 'updateStakeholder')
              self.load()
          }
        })

      }
      else {
        self[method]()
      }

    }
    else {
      self[method]()
    }

  }

  resetOverflow() {
    document.body.classList.remove("overflow-hidden");
  }
}
