<div mat-dialog-title>
  <h4>Selecteer projectniveau</h4>
</div>

<mat-list>
  <mat-list-item class="subproject-selector-list-item"> 
    <span class="project-selector-list-item-title" (click)="switchSubProject(null)">{{ globals.project.name }}</span>
    <mat-radio-group name="subproject-selector-modal-project-radio" class="pull-right flex-end pt-7">
      <mat-radio-button [value]="globals.project.name" (click)="switchSubProject(null)"></mat-radio-button>
    </mat-radio-group>
  </mat-list-item>
  <mat-list-item *ngFor="let subProject of subProjects$ | async"> 
    <ngx-avatars
      [style]="AvatarCustomStyle" 
      [name]="subProject.name"
      initialsSize="2"
      size="30"
      [borderColor]="subProject.color"
      fgColor="#000000"
      bgColor="#FFFFFF">
    </ngx-avatars>
    <span class="flex-start subproject-selector-list-item-title" (click)="switchSubProject(subProject.id)">{{ subProject.name }}</span>
    <mat-radio-group name="subproject-selector-modal-subproject-radio" class="pt-7">
      <mat-radio-button [value]="subProject.name" (click)="switchSubProject(subProject.id)"></mat-radio-button>
    </mat-radio-group>
  </mat-list-item>
 </mat-list>
 