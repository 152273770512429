import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { Entities } from '../../../../core/enum/entities';
import { MiddelsService } from '../../../../core/service/api/middels.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { deepCopy } from '../../../../core/util/deepCopy';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../../shared/directive/new-entity.directive';

@Component({
  selector: 'app-middel-new',
  templateUrl: './middel-new.component.html',
  styleUrls: ['./middel-new.component.sass']
})
export class MiddelNewComponent extends NewEntityModal {

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<MiddelNewComponent>,
    public globals: Globals, public _middelsService: MiddelsService, private pubsub: PubsubService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
  }

  protected initializeEntity(): void {
    this.entity = {};
  }

  protected submit(): void {
    this.isProcessing = true;

    const params = deepCopy(this.entity);

    if (params.calendars) {
      if (params.calendars.start) {
        params.calendars.start = params.calendars.start.toJSON();
      }

      if (params.calendars.end) {
        params.calendars.end = params.calendars.end.toJSON();
      }
    }

    this._middelsService.submitMiddel(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          if (snackbar) {
            snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data: data,
                message: translate('error.stored'),
                type: 'openItems',
                item: 'communicatiekalender',
                url: 'communicatiekalender/middel'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
            });
            this.dialogRef.close(data);
            this.pubsub.pushNewEntity(Entities.contents, data);
          }
        });
  }

  public setAndSubmit(middel: any): void {
    this.entity = Object.assign(this.entity, middel);
    this.submit();
  }

}
