export enum Permission {
        ProjectRead="project:read",
        ProjectCreate="project:create",
        ProjectUpdate="project:update",
        ProjectDelete="project:delete",
        UserCreate="user:create",
        UserRead="user:read",
        UserUpdate="user:update",
        UserDelete="user:delete",
        ProjectIntegrationsCreate="project:integrations:create",
        ProjectIntegrationsRead="project:integrations:read",
        ProjectIntegrationsUpdate="project:integrations:update",
        ProjectIntegrationsDelete="project:integrations:delete",
        PersonalIntegrationsCreate="personal:integrations:create",
        PersonalIntegrationsRead="personal:integrations:read",
        PersonalIntegrationsUpdate="personal:integrations:update",
        PersonalIntegrationsDelete="personal:integrations:delete",
        SubprojectCreate="subproject:create",
        SubprojectRead="subproject:read",
        SubprojectUpdate="subproject:update",
        SubprojectDelete="subproject:delete",
        PersonalisationRead="personalisation:read",
        PersonalisationUpdate="personalisation:update",
        DashboardWidgetsComplaintsRead="dashboard:widgets:complaints:read",
        DashboardWidgetsMyIssuesRead="dashboard:widgets:my_issues:read",
        DashboardWidgetsIssuesRead="dashboard:widgets:issues:read",
        DashboardWidgetsMyActionsRead="dashboard:widgets:my_actions:read",
        DashboardWidgetsComplaintsPerTypeRead="dashboard:widgets:complaints_per_type:read",
        DashboardWidgetsMyComplaintsRead="dashboard:widgets:my_complaints:read",
        ReportsProjectReportRead="reports:project_report:read",
        ReportsManagementReportRead="reports:management_report:read",
        ReportsDecisionsRead="reports:decisions:read",
        ReportsStakeholdersOverviewRead="reports:stakeholders_overview:read",
        ReportsStakeholdersReportRead="reports:stakeholders_report:read",
        ReportsDecisionsPerStakeholderRead="reports:decisions_per_stakeholder:read",
        ReportsComplaintsOverviewRead="reports:complaints_overview:read",
        ReportsIssuesOverviewRead="reports:issues_overview:read",
        ReportsLogsRead="reports:logs:read",
        ReportsCalendarRead="reports:calendar:read",
        ReportsActionsOverviewRead="reports:actions_overview:read",
        StakeholdersCreate="stakeholders:create",
        StakeholdersRead="stakeholders:read",
        StakeholdersUpdate="stakeholders:update",
        StakeholdersDelete="stakeholders:delete",
        StakeholdersRestore="stakeholders:restore",
        StakeholdersStrategyCreate="stakeholders:strategy:create",
        StakeholdersStrategyRead="stakeholders:strategy:read",
        StakeholdersStrategyUpdate="stakeholders:strategy:update",
        StakeholdersStrategyDelete="stakeholders:strategy:delete",
        StakeholdersUserCreate="stakeholders:user:create",
        StakeholdersUserRead="stakeholders:user:read",
        StakeholdersUserUpdate="stakeholders:user:update",
        StakeholdersUserDelete="stakeholders:user:delete",
        EmployeesCreate="employees:create",
        EmployeesRead="employees:read",
        EmployeesUpdate="employees:update",
        EmployeesDelete="employees:delete",
        EmployeesRestore="employees:restore",
        EmployeesPhoneCreate="employees:phone:create",
        EmployeesPhoneRead="employees:phone:read",
        EmployeesPhoneUpdate="employees:phone:update",
        EmployeesPhoneDelete="employees:phone:delete",
        EmployeesEmailCreate="employees:email:create",
        EmployeesEmailRead="employees:email:read",
        EmployeesEmailUpdate="employees:email:update",
        EmployeesEmailDelete="employees:email:delete",
        IssuesCreate="issues:create",
        IssuesRead="issues:read",
        IssuesUpdate="issues:update",
        IssuesDelete="issues:delete",
        IssuesRestore="issues:restore",
        IssuesStatusUpdate="issues:status:update",
        IssuesDeadlineCreate="issues:deadline:create",
        IssuesDeadlineRead="issues:deadline:read",
        IssuesDeadlineUpdate="issues:deadline:update",
        IssuesDeadlineDelete="issues:deadline:delete",
        IssuesImpactCreate="issues:impact:create",
        IssuesImpactRead="issues:impact:read",
        IssuesImpactUpdate="issues:impact:update",
        IssuesImpactDelete="issues:impact:delete",
        IssuesTypeCreate="issues:type:create",
        IssuesTypeRead="issues:type:read",
        IssuesTypeUpdate="issues:type:update",
        IssuesTypeDelete="issues:type:delete",
        DecisionsCreate="decisions:create",
        DecisionsRead="decisions:read",
        DecisionsUpdate="decisions:update",
        DecisionsDelete="decisions:delete",
        DecisionsRestore="decisions:restore",
        DecisionsTextCreate="decisions:text:create",
        DecisionsTextRead="decisions:text:read",
        DecisionsTextUpdate="decisions:text:update",
        DecisionsTextDelete="decisions:text:delete",
        DecisionsTextStatusCreate="decisions:text:status:create",
        DecisionsTextStatusRead="decisions:text:status:read",
        DecisionsTextStatusUpdate="decisions:text:status:update",
        DecisionsTextStatusDelete="decisions:text:status:delete",
        DecisionsStatusCreate="decisions:status:create",
        DecisionsStatusRead="decisions:status:read",
        DecisionsStatusUpdate="decisions:status:update",
        DecisionsStatusDelete="decisions:status:delete",
        ActionCreate="actions:create",
        ActionRead="actions:read",
        ActionUpdate="actions:update",
        ActionDelete="actions:delete",
        ActionRestore="actions:restore",
        LogsCreate="logs:create",
        LogsRead="logs:read",
        LogsUpdate="logs:update",
        LogsDelete="logs:delete",
        LogsRestore="logs:restore",
        ComplaintsCreate="complaints:create",
        ComplaintsRead="complaints:read",
        ComplaintsUpdate="complaints:update",
        ComplaintsDelete="complaints:delete",
        ComplaintsRestore="complaints:restore",
        ActivitiesCreate="activities:create",
        ActivitiesRead="activities:read",
        ActivitiesUpdate="activities:update",
        ActivitiesDelete="activities:delete",
        ActivitiesRestore="activities:restore",
        MiddelCreate="middel:create",
        MiddelRead="middel:read",
        MiddelUpdate="middel:update",
        MiddelDelete="middel:delete",
        MiddelRestore="middel:restore",
        DocumentsCreate="documents:create",
        DocumentsRead="documents:read",
        DocumentsUpdate="documents:update",
        DocumentsDelete="documents:delete",
        DocumentsRestore="documents:restore",
        RelationsUserRead="relations:user:read",
        RelationsUserUpdate="relations:user:update",

        PermitsCreate="permits:create",
        PermitsRead="permits:read",
        PermitsUpdate="permits:update",
        PermitsDelete="permits:delete",
        PermitsRestore="permits:restore",

        WorkflowsCreate="workflows:create",
        WorkflowsRead="workflows:read",
        WorkflowsUpdate="workflows:update",
        WorkflowsDelete="workflows:delete",
        WorkflowsRestore="workflows:restore",
        //theme and type crud
        StakeholdersTypeCRUD="stakeholders:types:crud",
        IssuesThemeCRUD="issues:themes:crud",
        DecisionsTypeCRUD="decisions:types:crud",
        ActivitiesTypeCRUD="activities:types:crud",
        DecisionsThemeCRUD="decisions:themes:crud",
        ComplaintsThemeCRUD="complaints:themes:crud",
        DecisionsPhasesCRUD="decisions:phases:crud",
        //testing
        read = 'read',
        write = 'write',
}
