import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { AbstractService } from '../common/abstract.service';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends AbstractService {

  constructor(
    private http:HttpClient
    ) {
    super();
  }

  @ServiceErrorHandler()
  getCalendar(start: string = '2019-01-01', end: string = '2019-12-31', fullCalendarformat: boolean = true, type: string = '', whereHas?: string[]) {
    let params = new HttpParams();
    params = params.append('format', fullCalendarformat ? '1' : '0');
    params = params.append('relation', '1');

    if (start) {
      params = params.append('start', start);
    }

    if (end) {
      params = params.append('end', end);
    }

    if (type) {
      params = params.append('type', type);
    }

    if (whereHas) {
      for (let i = 0; i < whereHas.length; i++) {
        params = params.append(`where_has[${i}]`, whereHas[i]);
      }
    }

    return this.http.get(`/api/calendars`, {
      params: params,
      headers: this.headers,
    });
  }

  showCalendar(id, fullCalendarformat: boolean = true,skipSubproject:boolean=false) {
    let params = new HttpParams();
    if(skipSubproject)
      params = params.append('skip_subproject','0')
    const format = fullCalendarformat ? 1 : 0;
    return this.http.get(`/api/calendars/${id}?format=${format}&relation=1`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  getUpcomingMiddel(fullCalendarformat: boolean = true) {
    const format = fullCalendarformat ? 1 : 0;
    return this.http.get(`/api/calendars/upcoming?format=${format}&relation=1`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getOverdueMiddel(fullCalendarformat: boolean = true) {
    const format = fullCalendarformat ? 1 : 0;
    return this.http.get(`/api/calendars/overdue?format=${format}&relation=1`, this.httpOptions);
  }
}
