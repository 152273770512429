import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationParams } from '../common/pagination-params';

@Injectable({
  providedIn: 'root'
})
export class OutlookService {
  private apiUrl = 'https://graph.microsoft.com/v1.0';
  constructor(private http: HttpClient) {
    // prevent lint of empty constructor
  }

  // Retrieve events
  getEvents(): Observable<any> {
    const url = `${this.apiUrl}/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location,isAllDay`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('outlook_accessToken')}`);

    return this.http.get(url, { headers });
  }

  getInboxMessages(paginator: PaginationParams, folderName: string): Observable<any> {
    let skip = paginator.pageIndex;
    skip = skip * 10;
    const orderby = 'receivedDateTime desc';
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('outlook_accessToken')}`
    });
    if (paginator._keyword && paginator._nextLink)
      return this.http.get<any>(paginator._nextLink, { headers: headers });
    if (paginator._keyword)
      return this.http.get<any>(`${this.apiUrl}/me/mailFolders/${folderName}/messages?$top=10&$count=true&$search=${paginator._keyword}`, { headers: headers });
    return this.http.get<any>(`${this.apiUrl}/me/mailFolders/${folderName}/messages?$top=10&$skip=${skip}&$count=true&$orderby=${orderby}`, { headers: headers });
  }

  // Step 2: Function to get attachments for a specific message
  getMessageAttachments(messageId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('outlook_accessToken')}`
    });

    const attachmentsUrl = `${this.apiUrl}/me/messages/${messageId}/attachments`;

    // Fetch attachments for the specified message ID
    return this.http.get<any>(attachmentsUrl, { headers: headers });
  }

  getSafeHtmlEmail(messageId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('outlook_accessToken')}`,
      'Prefer': 'outlook.body-content-type="text"'
    });
    const emailUrl = `${this.apiUrl}/me/messages/${messageId}`;
    return this.http.get<any>(emailUrl, { headers: headers });
  }
 
  // Download the attachment using Microsoft Graph API
  downloadAttachment(messageId: string, attachmentId: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('outlook_accessToken')}`
    });
    const url = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/attachments/${attachmentId}/$value`;
    return this.http.get(url, { responseType: 'blob', headers: headers });
  }

}
