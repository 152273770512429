<div *transloco="let t" class="modal-container">
  <h3 class="modal-title">
    {{ "privacy_modal.welcome_title" | transloco }}, {{ user.name }}!
  </h3>
  <hr />

  <mat-dialog-content>
    <p>{{ "privacy_modal.sub_title" | transloco }}</p>

    <p class="support mt-10">
      {{ "privacy_modal.check" | transloco }}
      <a
        href="https://support.getdialog.nl"
        target="_blank"
        class="text-white-50 fw-bold"
        >support.getdialog.nl</a
      >
      {{ "privacy_modal.content_p1" | transloco }}
    </p>

    <p class="support mt-10">
      {{ "privacy_modal.content_p2" | transloco }}
      <a href="mailto:support@getdialog.nl">{{ 'support@getdialog.nl' }}</a>
      {{ "privacy_modal.content_p2_middle" | transloco }}
      <mat-icon style="font-size: 16px">help_outline</mat-icon>
      {{ "privacy_modal.content_p2_end" | transloco }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions class="pull-right">
    <a
      class="mat-focus-indicator ml-8 mat-flat-button mat-button-base ng-star-inserted buttonOk"
      (click)="toggleChanged()"
      ><span class="mat-button-wrapper" style="padding: 15px"
        ><span>
          {{ "ok" | transloco | capitaliz }}</span
        ></span
      ><span matripple="" class="mat-ripple mat-button-ripple"></span
      ><span class="mat-button-focus-overlay"></span
    ></a>
  </mat-dialog-actions>

  <p class="support mt-10">
    {{ "privacy_modal.content_p3" | transloco }}
    <a
      [href]="'privacy_modal.privacyStatementUrl' | transloco"
      target="_blank"
      class="text-white-50 fw-bold"
      >privacy statement</a
    >
  </p>
</div>
