<div *transloco="let t;">
    <div class="item-details" *ngIf="current == 'stakeholder'">
        <div (click)="openDetailLayer('stakeholders',stakeholder)" class="item-title">{{stakeholder.name}}</div>
        <div class="item-content">
            <div class="item-field">
                <span class="item-label">{{ t('stakeholders.strategy') | titlecase }}</span>
                <span class="{{'item-value badge '+stakeholder.strategy}}">{{stakeholder.strategy | transloco}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Type</span>
                <span class="item-value">{{stakeholder.stakeholdertypes.length && stakeholder.stakeholdertypes[0].name}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('stakeholders.open_actions') | titlecase }}</span>
                <span class="item-value">{{stakeholder.open_actions}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('stakeholders.open_decisions') | titlecase }}</span>
                <span class="item-value">{{stakeholder.open_decisions}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('stakeholders.open_issues') | titlecase }}</span>
                <span class="item-value">{{stakeholder.open_issues}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('stakeholders.open_decisions') | titlecase }}</span>
                <span class="item-value">{{stakeholder.open_complaints}}</span>
            </div>
        </div>
    </div>
    

    <div class="item-details" *ngIf="current == 'issue'">
        <div (click)="openDetailLayer('issues',issue)" class="item-title">{{issue.name}}</div>
        <div class="item-content">
            <div class="item-field">
                <span class="item-label">{{ t('issues.urgency') | titlecase }}</span>
                <span class="{{'item-value issue badge '+issue.urgency}}">{{ 'issues.'+issue.urgency | transloco | capitaliz }}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('issues.description') | titlecase }}</span>
                <span class="item-value">{{issue.description}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Stakeholder(s)</span>

                <span class="item-value">
            <div class="value-list" *ngFor="let n of issue.stakeholders">
            -{{n.name}}
            </div>

        </span>
            </div>
        </div>
    </div>

    <div class="item-details" *ngIf="current == 'complaint'">
        <div (click)="openDetailLayer('meldingen',complaint)" class="item-title">Melding {{complaint.id}} {{complaint.date | formatdate: 'dd MM yyyy' : globals.timezone | capitaliz}} </div>
        <div class="item-content">
            <div class="item-field">
                <span class="item-label">Status</span>
                <span class="{{'item-value complaint badge '}}" [ngClass]="complaint.is_closed ? 'closed' : 'open'">{{complaint.is_closed?'gesloten':'open'}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('complaints.days_open') | titlecase }}</span>
                <span class="item-value">{{complaint.is_closed?'-':complaint.days}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Stakeholder(s)</span>

                <span class="item-value">
            {{complaint.stakeholders && complaint.stakeholders.name}}

        </span>
            </div>
            <div class="item-field">
                <span class="item-label">Type</span>

                <span class="item-value">
            {{complaint.complaintthemes && complaint.complaintthemes.length && complaint.complaintthemes[0].name}}

        </span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('complaints.text') | titlecase }}</span>
                <span class="item-value">{{complaint.text}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Contactpersoon</span>
                <span class="item-value">
            <ol class="ordered-list">
            <li class="value-list" *ngFor="let c of complaint.contacts">
                <div>{{c.name && '-'+c.name}}</div>
                <div>{{c.phone && '-'+c.phone}}</div>
                <div>{{c.email && '-'+c.email}}</div>
            </li>

            </ol>

        </span>
            </div>
            <div class="item-field">
                <span class="item-label">Verantwoordelijke(n)</span>
                <span class="item-value">
            <ol class="ordered-list">
            <li class="value-list" *ngFor="let c of complaint.users">
                <div>{{c.name && '-'+c.name}}</div>
            </li>

            </ol>

        </span>
            </div>
            <div class="item-field">
                <span class="item-label">Afhandeling</span>
                <span class="item-value">{{complaint.closed_description}}</span>
            </div>

        </div>
    </div>

    <div class="item-details" *ngIf="current == 'requirements'">
        <div (click)="openDetailLayer('klanteisen',decision)" class="item-title">{{decision.title || '-'}}</div>
        <div class="item-content">
            <div class="item-field">
                <span class="item-label">Status</span>
                <span *transloco="let t; read: 'decisions'" class="{{'item-value badge decision '+decision.status}}">{{ t(decision.status) | capitaliz }}</span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('decisions.description') | titlecase }}</span>
                <span class="item-value">{{decision.description}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Stakeholder(s)</span>

                <span class="item-value">
            <div class="value-list" *ngFor="let n of decision.stakeholders">
            -{{n.name}}
            </div>

        </span>
            </div>
            <div class="item-field">
                <span class="item-label">Thema</span>
                <span class="item-value">{{decision.theme_requirements ? decision.theme_requirements.length>0 ? decision.theme_requirements[0].name:'' : ''}}
        </span>
            </div>
            <div class="item-field">
                <span class="item-label">{{ t('decisions.guarantee') | titlecase }}</span>
                <span class="item-value">{{decision.guarantee }}</span>
            </div>
        </div>
    </div>

    <div class="item-details" *ngIf="current == 'activity'">
        <div (click)="openDetailLayer('communicatiekalender/activiteit',activity)" class="item-title">{{activity.name || '-'}}</div>
        <div class="item-content">
            <div class="item-field">
                <span class="item-label">{{ t('activities.date') | titlecase }}</span>
                <span class="{{'item-value'}}">{{activity.started_at?activity.started_at.split("T")[0]:'' }}-{{activity.ended_at?activity.ended_at.split("T")[0]:'' }}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Type</span>
                <span *ngIf="activity.type && activity.type.length" class="item-value">{{ activity.type[0].name}}</span>
            </div>
            <div class="item-field">
                <span class="item-label">Middelen</span>

                <span class="item-value">
            <div class="value-list" *ngFor="let n of activity.middel">
            -{{n.type}}
            </div>

        </span>
            </div>
        </div>
    </div>
    <div *ngIf="current == 'open_data'">
        <!-- <div class="item-title">{{activity.name || '-'}}</div> -->
        <div *hasPermission="[Permission.StakeholdersCreate] || [Permission.IssuesCreate] || [Permission.ComplaintsCreate] || [Permission.ActionCreate] || [Permission.DecisionsCreate]">
            <mat-progress-bar *ngIf="!listload" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="item-details" *ngIf="listload">
            <h3 style="margin-bottom: 0px;"  [matTooltip]="feature.naam? feature.naam : feature.Name? feature.Name: feature.title? feature.title : feature.FID">
                {{ (feature.naam? feature.naam : feature.Name? feature.Name: feature.title? feature.title : feature.FID) | truncatie:35 }}</h3>
            <p [matTooltip]="feature.layer | camelCaseToWords | transloco">{{feature.layer | camelCaseToWords | transloco | truncatie:35}}</p>
        </div>
        <!-- Items List  -->

        <!-- Open Data Features  -->
        <div class="item-details" *ngIf="listload">
          <div *ngIf="feature.layer!='Pontential_Stakeholder'">
            <div *ngIf="!noFeatureErrorMessage else no_feature">
                <h3>{{ 'maps.item' | transloco | capitaliz}} {{'maps.Data' | transloco }}</h3>
                <div class="item-content">
                    <app-show-more [expandText]="'expand' | transloco | capitaliz">
                        <!-- Summary Section -->
                        <div summary>
                          <div class="item-field" *ngFor="let item of feature | keyvalue; let i=index">
                            <ng-container>
                              <div *ngIf="item.key !== 'geometry' && pinItems && pinItems.length && pinItems.includes(item.key)" style="display: flex; align-items: center;">
                                <span class="{{ 'item-value' }}">{{ item.key }}</span>
                                <span class="item-label">{{ item.value }}</span>
                                <span *hasPermission="[Permission.ProjectUpdate]" class="item-pin uil uil-favorite pull-right" [matTooltip]="['maps.favorite.remove' | transloco]" (click)="updatePinItems(item.key)"></span>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <!-- Detailed Content Section -->
                        <div content>
                          <div class="item-field" *ngFor="let item of feature | keyvalue; let i=index">
                            <ng-container>
                              <div class="item" *ngIf="item.key !== 'geometry' && pinItems && !pinItems.includes(item.key)" style="display: flex; align-items: center;">
                                <span class="{{ 'item-value' }}">{{ item.key }}</span>
                                <span class="item-label">{{ item.value }}</span>
                                <span *hasPermission="[Permission.ProjectUpdate]" class="favorite-unselected item-pin uil uil-favorite pull-right" [matTooltip]="['maps.favorite.add' | transloco]" (click)="updatePinItems(item.key)"></span>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </app-show-more>

                </div>
            </div>
            <ng-template #no_feature>
                <img src="assets/png/empty-state-no-search-results.png" class="empty-state-image d-block m-auto">
                <h3 class="text-center">{{'maps.no_feature' | transloco}}</h3>
            </ng-template>
          </div>
        </div>
        <!-- Over Pass Api Data  -->
        <mat-progress-bar *ngIf="!listloadOverpass" mode="indeterminate"></mat-progress-bar>
        <div class="item-details" *ngIf="feature.layer=='Pontential_Stakeholder' && listloadOverpass">
            <a class="d-cursor-hand" *ngIf="feature.stakeholder" (click)="openDetailLayer('stakeholders',feature.stakeholder,true)">{{feature.naam}}</a>
            <div class="flex-seting">
                <h3>{{ 'maps.item' | transloco | capitaliz}} {{'maps.Data' | transloco }}</h3>
                <span *hasPermission="[Permission.StakeholdersCreate]">
                    <span *ngIf="!feature.exist" class="align-setting"><button class="btn btn-outline-primary color-btn-primary-outline-map-b" (click)="createStakeholder(feature)" [matTooltip]="t('maps.add_stakeholder')"><mat-icon class="d-text-middle mat-icon notranslate material-icons mat-icon-no-color">add_circle</mat-icon></button></span>
                    <span *ngIf="feature.exist" class="align-setting"><button disabled class="btn btn-outline-primary color-btn-primary-outline-map-b"><mat-icon class="d-text-middle mat-icon notranslate material-icons mat-icon-no-color" [matTooltip]="t('maps.already_created')">check_circle</mat-icon></button></span>
                </span>
            </div>
            <div class="item-content">
                <div class="item-field" *ngFor="let item of overPassFeature">
                    <span class="item-label">{{'maps.'+item.name | transloco }}</span>
                    <span class="{{'item-value'}}">{{item.value }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="item-details mt-16" *ngIf="['stakeholder', 'issue', 'complaint', 'requirements', 'activity'].includes(current) && relationLayers && relationLayers.length">
    <div >
        <span class="item-title">{{ 'maps.map_relation' | transloco }}</span>
        <span><mat-icon [ngClass]="showAllRelationLayers? 'uil uil-eye unicon-icon pull-right' : 'uil uil-eye-slash unicon-icon pull-right'" (click)="toggleAllLayers()"></mat-icon></span>
    </div>
    <div class="item-content" *ngFor="let layer of relationLayers; let i = index">
        <div class="row map-relation-items">
            <span class="col-md-5 map-item-label">{{ layer.data.layer_name }}</span>
            <span class="col-md-5 map-item-value" (mouseenter)="itemOpenlayerHover(layer)">{{ layer.data.title }}</span>
            <span class="col-md-2">
                <mat-icon [ngClass]="layer.show? 'uil uil-eye unicon-icon' : 'uil uil-eye-slash unicon-icon'" (click)="toggleLayer(layer)"></mat-icon>
            </span>
        </div>
    </div>
</div>

<app-add-connect-generic *ngIf="current == 'open_data' && listload" (onChange)="updateEntities($event)" parentType="layers" [parent]="layers" [data]="entities" [types]="['stakeholders','issues','requirements','complaints','activities','permits']"></app-add-connect-generic>

