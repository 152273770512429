import { translate } from "@jsverse/transloco";
import collect from "collect.js";
import moment from "moment";
import { dateIso } from "./dateIso";
import { moduleValidation } from "./deepCopy";

//set direct mail logic for complaint
export function directMail(entity, globals): boolean{
    if(entity.users.length){
        if(entity.users.length==1 && collect(entity.users).contains((value, key) => value.id == globals.user.id)){
            return false;
        }
            if(entity.priority=='urgent'){
                return true;
            }

    }
    return false;
}
export function addHours(date, globals, level) {
  if(globals.project.configs){
    if(globals.project.configs.metadata){
      if(globals.project.configs.metadata.sla){
        date.setTime(date.getTime() + globals.project.configs.metadata.sla.priorities[level] * 60 * 60 * 1000);
        return date;
      }
    }
  }
  return date;
}
//complaint deadline set according to kpi
export function deadlineWithKPI(globals, level, date){
    const deadline= new Date(date);
    if(moduleValidation('Communicatie',globals.modules)){
        if(globals.project.sla_enabled){
            return addHours(deadline, globals, level)
        }else if(globals.project.complaints_kpi){
            deadline.setDate(deadline.getDate() + globals.project.complaints_kpi);
            return deadline;
        }
            return null;

    }
    return null;
}
//set complaint priority colors
export function priorityColors(level){
    switch(level){
        case "low":
            return "#E0E1E3";
        case "medium":
            return "#F1C40F";
        case "high":
            return "#F7F050";
        case "urgent":
            return "#FF7669";
    }
}
//set kpi name and colors
export function setKPIStyle(type,value,globals){
    if(type=="color"){
        switch(true){
            case value.days_open < globals.project.complaints_kpi:
                return "complaint_isdue";
            case value.days_open == globals.project.complaints_kpi:
                return "complaint_istoday";
            case value.days_open > globals.project.complaints_kpi:
                return "complaint_islate";
        }
    }else{
        switch(true){
            case value.days_open < globals.project.complaints_kpi:
                return moment(dateIso(value.date, true, globals.timezone)).format('DD-MM-YYYY')
            case value.days_open == globals.project.complaints_kpi:
                return translate('complaints.today')
            case value.days_open > globals.project.complaints_kpi:
                return translate('complaints.overdue')
        }
    }
}
