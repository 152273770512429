import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
@Component({
  selector: 'app-map-details',
  templateUrl: './map-details.component.html',
  styleUrls: ['./map-details.component.sass'],
})
export class MapDetailsComponent {
  public isProcess = false;
  constructor(public pubsub: PubsubService,
  // eslint-disable-next-line no-empty-function
  @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<MapDetailsComponent>) { }

  public setLoder() {
    this.isProcess = true;
  }
  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }
}
