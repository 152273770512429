<div class="row mt-3">
  <div class="col-md-12 options">
    <i
      class="uil uil-arrow-circle-left"
      [matTooltip]="isToday? ('settings.outlook.calendar.no_earlier' | transloco | capitaliz) : null"
      [ngClass]="{ disabled: isToday || !isLoad }"
      (click)="filterEvents('previous')"
    ></i>
    <span>{{ displayDate }}</span>
    <i class="uil uil-arrow-circle-right" (click)="filterEvents('next')"
    [ngClass]="{ disabled: !isLoad }"
    ></i>
  </div>
</div>
<div *ngIf="isLoad; else onload">
  <ng-container *ngFor="let event of filteredEvents | sortEvents:'start.dateTime':'asc';">
    <div class="main full-width set-border-connection-relatic mb-10">
      <div class="item">
        <div class="timestamp">
          <span *ngIf="event.id" class="date">{{ event.start | timezoneFormat:'Europe/Amsterdam' }}</span>
          <span *ngIf="event.id" class="time">{{ event.end | timezoneFormat:'Europe/Amsterdam'}}</span>
          <span *ngIf="!event.id" class="date">{{ event.start.dateTime }}</span>
          <span *ngIf="!event.id" class="time">{{ event.end.dateTime  }}</span>
        </div>
        <div class="user">
          <span [ngClass]="event.addedAsLog? 'added-item' : ''" (click)="nav(event)" class="username">{{ event.subject }}</span>
          <span class="action">
            <span *ngIf="event?.emails?.unregistered.length > 0" style="color: blue; cursor: pointer;" (click)="createEmployees(event?.emails?.unregistered, event.id)">{{ event?.emails?.unregistered.length }} {{'reusable.unknown' | transloco}}</span>
            <span *ngFor="let employee of event?.emails?.registered; last as isLast"> {{employee.first_name}} {{employee.last_name}}<span *ngIf="!isLast">,</span></span>
          </span>
        </div>
      </div>
      <div class="data">
        <div *ngIf="!event.addedAsLog" class="icons">
          <i
            class="uil uil-plus-circle unicon-icon d-text-middle"
            matTooltip="{{
              'settings.outlook.calendar.add_moment' | transloco | capitaliz
            }}"
            (click)="createContactmoment(event, i)"
          ></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #onload>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
