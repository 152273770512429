import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';

@Component({
  selector: 'app-arc-gis-redirect',
  templateUrl: './arc-gis-redirect.component.html',
  styleUrls: ['./arc-gis-redirect.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArcGisRedirectComponent implements OnInit {

  // eslint-disable-next-line no-empty-function
  constructor(private _rout:Router,public globals: Globals,) { }

  ngOnInit() {
      const projectcode=localStorage.getItem('project_name');
      const accessToken=this._rout.url.match(/code=([^&]+)/);
        if(projectcode){
          if(projectcode.includes('beheer')){
            localStorage.setItem('arcgisRedirect', `/${projectcode}/integraties?code=${accessToken[1]}`);
            window.location.href=`/${projectcode}/integraties?code=${accessToken[1]}`;
          }else{
            localStorage.setItem('arcgisRedirect', `/${projectcode}/instellingen/integraties?code=${accessToken[1]}`);
            window.location.href=`/${projectcode}/instellingen/integraties?code=${accessToken[1]}`;
          }
        }else{
          localStorage.setItem('arcgisRedirect','/');
          window.location.href='/';
          bugsnagFactory().notify(JSON.stringify({
            message: 'Project Code is undefined',
          }));
        }
      }
}
