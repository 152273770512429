<div class="row popup-container" *transloco="let t;">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span><span class="sr-only">Close</span></button>
            <div *hasPermission="[Permission.ActivitiesDelete]">
                <mat-icon (click)="delete()" class="d-cursor-hand delete pull-right activity-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}">delete</mat-icon>
            </div>
            <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
              <button type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
                 <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
              </button>
          </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1><strong>{{ 'activities.label' | transloco | capitaliz }} </strong>{{name}}</h1>
        </div>

    </div>
    <mat-progress-bar *ngIf="!activityDetailsForm" mode="indeterminate"></mat-progress-bar>

    <div class="popup-body" appScroll *ngIf="activityDetailsForm">
        <app-workflow-generic *ngIf="activity && activity.workflows && activity.workflows.length && hasModule('workflow')" [margin]="false" parentType="activities" [deadline]="activity.calendars[0].ended_at" [workflows]="activity.workflows" [permitId]="activity.id"></app-workflow-generic>
        <form [formGroup]="activityDetailsForm">
            <div class="col-xs-12">
                <h4>{{ 'text.general' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border">
                    <div class="form-inline">

                        <div class="col-xs-12">
                            <mat-form-field class="full-width" [matTooltip]="reducer.name ? t('settings.relatic.disable_field') : null" [ngClass]="{'disable_enitiy': reducer.name || !hasPermission(Permission.ActivitiesUpdate)}">
                              <mat-label>{{ 'activities.label' | transloco }}</mat-label>
                                <input matInput formControlName="name" required (change)="updateActivityDetails()">
                            </mat-form-field>
                        </div>
                        <app-add-connect-themes [editButton]="true" [editable]="false" valueType="object" [matTooltip]="reducer.type ? t('settings.relatic.disable_field') : null" class="col-xs-11" [type]="themeType" [buttonText]="'activities.theme_button_text'" [textMultiple]="'types'"
                            [textSingle]="'type'" [selectedTheme]="selectedTheme" (onThemeSelected)="updateSelectedTheme($event)" [disabled]="reducer.type || !hasPermission(Permission.ActivitiesUpdate)"></app-add-connect-themes>
                        <div *ngIf="selectedTheme" class="selected-color" [ngStyle]="{ 'background-color': selectedTheme.color || '#4A9ED6' }"></div>

                    </div>

                    <div formGroupName="calendars" class="form-group form-inline gd-auto-horizontal">

                        <div class="col-xs-12 col-sm-12 col-md-5">
                            <mat-form-field class="full-width" [matTooltip]="reducer.date ? t('settings.relatic.disable_field') : null">
                                <mat-label>{{'reusable.begindatum' | transloco}}</mat-label>
                                <input matInput [matDatepicker]="activiteitDetailStart" formControlName="start" (change)="updateActivityDetails()" (dateChange)="updateActivityDetails()" (focus)="activiteitDetailStart.open()"
                                    [disabled]="this.reducer.date || !hasPermission(Permission.ActivitiesUpdate)" required>
                                <mat-datepicker-toggle matSuffix [for]="activiteitDetailStart"></mat-datepicker-toggle>
                                <mat-datepicker #activiteitDetailStart></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-5">
                            <mat-form-field class="full-width" [matTooltip]="reducer.end_date ? t('settings.relatic.disable_field') : null">
                                <mat-label>{{'reusable.einddatum' | transloco}}</mat-label>
                                <input matInput [min]="minDate" [matDatepicker]="activiteitDetailEnd" formControlName="end" (change)="updateActivityDetails()" (dateChange)="updateActivityDetails()" (focus)="activiteitDetailEnd.open()"
                                    [disabled]="this.reducer.end_date || !hasPermission(Permission.ActivitiesUpdate)">
                                <mat-datepicker-toggle matSuffix [for]="activiteitDetailEnd"></mat-datepicker-toggle>
                                <mat-datepicker #activiteitDetailEnd></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-xs-12" [ngClass]="{'disable_enitiy':!hasPermission(Permission.ActivitiesUpdate)}">
                            <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [disabled]="!hasPermission(Permission.ActivitiesUpdate) || globals.subprojects.length === 0" [parent]="activity" parentType="activities" class="subprojects" type="subprojects" *ngIf="hasModule('subprojects')" (ngModelChange)="updateSubprojects()"
                                [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco" name="subprojects" [(ngModel)]="activity.subprojects" [ngModelOptions]="{standalone: true}" [multiple]="true" [maxChipLength]="20" [uniqueId]="'sub-projects'">
                                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                                <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                                    {{ subproject.name }}
                                </app-generic-multiselect-checkbox-option>
                            </app-generic-multiselect-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <app-comment-box [users]="users" type="calendars" parentType="activity" [type_id]="calendarId"></app-comment-box>
                    </div>
                </div>
                <h4>{{ 'decisions.connections' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component pd-5">
                    <div>
                        <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                        <app-add-connect-generic parentType="activities" *ngIf="loaded" [data]="entities" (onChange)="updateEntities($event)" [parent]="activity" [types]="['tasks','contactmoments','contents','permits','layers']">
                        </app-add-connect-generic>
                    </div>
                </div>

                <h4 class="mb-15 text-capitalize">{{ 'location' | transloco }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border map-div" [matTooltip]="reducer.coordinates ? t('settings.relatic.disable_field') : null">
                    <div class="col-xs-12" [ngClass]="{'disable_enitiy': reducer.coordinates || !hasPermission(Permission.ActivitiesUpdate)}">
                        <app-openlayers-map [includeSidebar]="true" [collapseSidebar]="true" [sidebarButtons]="hasPermission(Permission.ActivitiesUpdate)?['length', 'point']:[]" [defaultCoordinate]="globals.projectConfig.default_map" [showMapReset]="true" [maxPoints]="1" [maxLines]="1"
                            type="activities" (ngModelChange)="updateCoordinate()" customStyle="height: 250px" formControlName="locations" name="locations" locatableType="calendars" [defaultDrawTool]="defaultDrawTool" clickStartDrawingMsg="Teken activiteitpunt"
                            [disabled]="reducer.coordinates || !hasPermission(Permission.ActivitiesUpdate)"></app-openlayers-map>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
