
<div class="modal-header d-flex justify-content-between p-0">
    <button class="close" type="button" mat-dialog-close>
        <span style="font-size: 35px">×</span>
        <span class="sr-only">Close</span>
    </button>
    <h3 class="modal-title">{{ 'report.labels' | transloco | capitaliz }}</h3>
</div>
<hr />

<form #form="ngForm" (submit)="generateExport()" *transloco="let t">
    <mat-dialog-content>
        <mat-form-field class="full-width">
            <mat-label>{{'Type rapportages'}}</mat-label>
            <mat-select [(value)]="selectedReportType" (selectionChange)="updateSelectedReport()" required style="margin-top: 5px;">
                <ng-container *ngFor="let report of reports">
                    <mat-option [value]="report.type" *ngIf="(report.type=='project' && hasPermission(Permission.ReportsProjectReportRead)) || (report.type=='stakeholder' && hasPermission(Permission.ReportsStakeholdersReportRead)) || (report.type=='management' && hasPermission(Permission.ReportsManagementReportRead)) || (report.type === 'permit_overview' && hasModule('permit') && hasPermission(Permission.PermitsRead)) || (report.type=='decision_overview' && hasPermission(Permission.ReportsDecisionsRead)) || (report.type=='decision_overview_stakeholders' && hasPermission(Permission.ReportsDecisionsPerStakeholderRead)) || (report.type=='stakeholder_overview' && hasPermission(Permission.ReportsStakeholdersOverviewRead)) || (report.type=='complaint_overview' || report.type=='complaint' && hasPermission(Permission.ReportsComplaintsOverviewRead)) || ((report.type=='issue_overview' || report.type=='issue') && hasPermission(Permission.ReportsIssuesOverviewRead)) || (report.type=='logbook' && hasPermission(Permission.ReportsLogsRead)) || (report.type=='calendar' && hasPermission(Permission.ReportsCalendarRead)) || (report.type=='action_overview' && hasPermission(Permission.ReportsActionsOverviewRead)) || (report.type=='analysis_overview' && hasPermission(Permission.ReportsActionsOverviewRead))">
                        {{ t('report.' + report.type ) }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <app-generic-multiselect-checkbox [multiple]="false" *ngIf="selectedReportType === 'issue'"  [placeholder]="'reusable.selecteer_issues' | transloco" name="issues" [(ngModel)]="data.issues" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('issues.labels' | transloco)+' ...'">
            <app-generic-multiselect-checkbox-option-all *ngIf="showAllSelectStakeholder">{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let issue of issues; trackBy: trackByFn" [value]="issue">
                {{ issue.name }}
            </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox>

        <!-- Dialog items for management and issue report -->
        <app-generic-multiselect-checkbox [multiple]="true" *ngIf="(selectedReportType === 'management' && hasPermission(Permission.ReportsManagementReportRead)) || selectedReportType === 'issue'" [placeholder]="'reusable.sections_to_export' | transloco" [(ngModel)]="data.selected_dialog_items" [required]="false" [maxChipItems]="4">
            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let dialog_item of dialog_items; trackBy: trackByFn" [value]="dialog_item">
                {{ dialog_item.label | capitaliz }}
            </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox>

        <!-- Subproject for management report -->
        <app-generic-multiselect-checkbox *ngIf="notSubprojectLevel && selectedReportType === 'management' && hasModule('subprojects') && hasPermission(Permission.ReportsManagementReportRead)" class="subprojects" type="subprojects" [maxChipItems]="3"
                        [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco" name="subprojects" [(ngModel)]="data.subprojects" [multiple]="true" [maxChipLength]="20" [uniqueId]="'sub-projects'">
            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                {{ subproject.name }}
            </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox>
        
        <!-- Filter Type -->
        <ng-container *ngIf="onChangeFilters && themeType[selectedReportType]">
          <app-add-connect-themes [type]="themeType[selectedReportType].type" [editable]="false" (onThemeSelected)="updateSelectedTheme($event)" [textMultiple]="themeType[selectedReportType].placeholder" [textSingle]="themeType[selectedReportType].placeholder"></app-add-connect-themes>
        </ng-container>
        <!-- Filter Theme for analysis -->
        <ng-container *ngIf="onChangeFilters && themeType[selectedReportType] && selectedReportType === 'analysis_overview'">
            <app-add-connect-themes [type]="themeType['analysis_overview_themes'].type" [editable]="false" (onThemeSelected)="updateSelectedTheme($event,'analysis_overview_themes')" [textMultiple]="themeType['analysis_overview_themes'].placeholder" [textSingle]="themeType['analysis_overview_themes'].placeholder"></app-add-connect-themes>
          </ng-container>
        <!-- End Filter Type -->
        <mat-form-field *ngIf="offsetRequired" class="full-width">
            <mat-label>{{'start_date' | transloco | capitaliz}}</mat-label>
            <input matInput [matDatepicker]="datePicker" (click)="datePicker.open()" [(ngModel)]="data.offset" name="offset" [value]="defaultDate">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <app-generic-multiselect-checkbox [multiple]="true" *ngIf="stakeholderRequired" #stakeholderMultiselect [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" [(ngModel)]="data.stakeholders" name="stakeholders" [required]="stakeholderRequired" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'">
            <app-generic-multiselect-checkbox-option-all *ngIf="showAllSelectStakeholder">{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders; trackBy: trackByFn" [value]="stakeholder">
                {{ stakeholder.name }}
            </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox>

        <app-generic-multiselect-checkbox [multiple]="false" *ngIf="selectedReportType === 'complaint'"  [placeholder]="'reusable.selecteer_complaint' | transloco" name="complaints" [(ngModel)]="data.complaints" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('issues.labels' | transloco)+' ...'">
          <app-generic-multiselect-checkbox-option-all *ngIf="showAllSelectStakeholder">{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
          <app-generic-multiselect-checkbox-option [innerHTML]="complaint.text | safeHtml" *ngFor="let complaint of complaints; trackBy: trackByFn" [value]="complaint">
          </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox>
        <div>
            <mat-checkbox *ngIf="notesToggle" [(ngModel)]="showNotes" name="notes">{{ t('report.include_notes') }}</mat-checkbox>
        </div>

        <mat-form-field class="full-width" *ngIf="selectedReport && selectedReport.type === 'action_overview'">
            <mat-select [(value)]="selectedAction" (selectionChange)="resetExtraField()" required>
                <mat-option value="action_all">{{ t('report.action_all') }}</mat-option>
                <mat-option value="action_per_item">{{ t('report.action_per_item') }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div>
            <mat-checkbox *ngIf="selectedActionReport" [(ngModel)]="closedTasks" name="closedTasks">{{'text.closed_tasks'| transloco | capitaliz}}</mat-checkbox>
        </div>

        <mat-button-toggle-group>
            <mat-button-toggle class="d-cursor-hand" *ngFor="let format of selectedReport.format; let first = first;" [value]="format" [checked]="first">
                <img [src]="'/assets/png/report/' + format + '-icon.png'" style="width: 50px; padding: 10px 10px 10px 0">{{ formatName[format] }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </mat-dialog-content>
    <mat-dialog-actions class="pull-right">
      <button *ngIf="!exporting" mat-dialog-close mat-stroked-button>{{ 'cancel' | transloco | capitaliz }}</button>
      <button [disabled]="exporting || form.invalid" color="primary" mat-flat-button style="margin-left: 13px" type="submit">
        <mat-icon class="d-text-middle uil uil-file-export unicon-icon" style="display: flex;"></mat-icon>
        <ng-container *ngIf="!exporting">
          {{ 'generate' | transloco | capitaliz }}
        </ng-container>
        <ng-container *ngIf="exporting">
          {{ 'generating' | transloco | capitaliz }}...
        </ng-container>
      </button>
    </mat-dialog-actions>
</form>
