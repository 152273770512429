<form #form="ngForm" (ngSubmit)="submit()">
    <div mat-dialog-title>
        <h4 class="mb-10 inline-block">{{ 'issues.new' | transloco }}</h4>
        <button type="button" class="close" (click)="close()">
    <span aria-hidden="true"><i class="uil uil-multiply unicon-icon"></i></span>
    <span class="sr-only">Close</span>
    </button>
    </div>

    <!-- modal body -->
    <mat-dialog-content *transloco="let t">
        <div class="col-xs-12 col-md-6">
            <mat-form-field class="hidden no-padding">
              <mat-label>Datum</mat-label>
                <input matInput name="date" [matDatepicker]="picker" [(ngModel)]="entity.issues_date" (focus)="picker.open()" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'Issue' }}</mat-label>
                    <input matInput type="text" name="name" [(ngModel)]="entity.name" required />
                </mat-form-field>
            </div>

            <app-add-connect-themes [type]="themeType" [editable]="false" (onThemeSelected)="updateSelectedTheme($event)" [uniqueId]="'add-themes'"></app-add-connect-themes>

            <div>
                <mat-form-field class="full-width">
                    <mat-label>{{ 'reusable.description' | transloco }}</mat-label>
                    <textarea matInput  name="description" [(ngModel)]="entity.description" rows="3" required></textarea>
                </mat-form-field>
            </div>

            <div class="mt-10">
                <app-generic-multiselect-checkbox [placeholder]="'reusable.select_user_change' | transloco" name="users" ngDefaultControl [(ngModel)]="entity.users" name="users" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' users ...'" [uniqueId]="'responsible-users'">
                    <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let user of users; trackBy: trackByFn" [value]="user">
                        {{ user.name }}
                    </app-generic-multiselect-checkbox-option>
                </app-generic-multiselect-checkbox>
            </div>

            <div class="full-width no-padding">
                <label>Impact</label>
                <mat-button-toggle-group name="impact" ngDefaultControl [(ngModel)]="entity.impact" [matTooltip]="t('issues.impact')" class="pull-right">
                    <mat-button-toggle value="negligible" class="text-capitalize">{{ t('issues.negligible') | capitaliz }}</mat-button-toggle>
                    <mat-button-toggle value="low" class="text-capitalize">{{ t('issues.low') | capitaliz }}</mat-button-toggle>
                    <mat-button-toggle value="middle" class="text-capitalize">{{ t('issues.middle') | capitaliz }}</mat-button-toggle>
                    <mat-button-toggle value="high" class="text-capitalize">{{ t('issues.high') | capitaliz }}</mat-button-toggle>
                    <mat-button-toggle value="catastrophic" class="text-capitalize">{{ t('issues.catastrophic') | capitaliz }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <mat-form-field class="full-width">
                <mat-label>{{ t('time.deadline') }}</mat-label>
                <input type="text" matInput name="deadline" matInput [matDatepicker]="issueDeadline" [disableControl]="!hasPermission(Permission.IssuesCreate)" [(ngModel)]="entity.deadline"
                    (focus)="issueDeadline.open()" required>
                <mat-datepicker-toggle matSuffix [for]="issueDeadline"></mat-datepicker-toggle>
                <mat-datepicker #issueDeadline></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-xs-12 col-md-6">
            <label class="mb-15">{{'reusable.location' | transloco }}</label>
            <div class="map-container">
                <app-openlayers-map [(ngModel)]="entity.locations" ngDefaultControl [includeSidebar]="false" [defaultCoordinate]="globals.projectConfig.default_map" [showMapReset]="true" [maxPoints]="1" name="locations" locatableType="issues" defaultDrawTool="point" clickStartDrawingMsg="Teken issuepunt"></app-openlayers-map>
            </div>
        </div>
    </mat-dialog-content>
    <!--end of modal body -->

    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
            <button type="button" mat-stroked-button [disabled]="isProcessing" (click)="close()">{{'cancel' | transloco | capitaliz}}</button>
            <button type="submit" mat-flat-button [disabled]="isProcessing || form.invalid">
        <span *ngIf="!isProcessing">
        <mat-icon class="material-icons d-text-middle">save</mat-icon>
        {{ 'add' | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
    </button>
        </div>
    </mat-dialog-actions>
    <!--end of modal footer -->

</form>
