import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { Entities } from '../../../../core/enum/entities';
import { ActivitiesService } from '../../../../core/service/api/activities.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../../shared/directive/new-entity.directive';
import { MapService } from 'src/app/core/service/api/map.service';

@Component({
  selector: 'app-activity-new',
  templateUrl: './activity-new.component.html',
  styleUrls: ['./activity-new.component.sass']
})
export class ActivityNewComponent extends NewEntityModal {
  public responseLayers: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<ActivityNewComponent>, public globals: Globals,
    private _activtiesService: ActivitiesService, private pubsub: PubsubService, private _mapService: MapService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
    if (this.data.params && this.data.params.locations) {
      if (!this.data.params.locations.locatable_type || this.data.params.locations.locatable_type === 'undefined') {
        this.data.params.locations.locatable_type = 'activities'
      }
    }
  }

  protected initializeEntity(): void {
    this.entity = {
    };
  }

  protected submit(): void {
    this.isProcessing = true;
    this._activtiesService.submitActivity(this.entity)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
            data: {
              data: data,
              message: translate('error.stored'),
              type: 'openItems',
              item: 'activiteit',
              url: 'communicatiekalender/activiteit'
            },
            duration: 4000,
            horizontalPosition: 'start',
            panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.activities, data);
        });
  }

  public setAndSubmit(activity): void {
    this.entity = Object.assign(this.entity, activity);
    this.submit();
  }

}
