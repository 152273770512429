import moment from "moment"

export type PublicSublayer = {
  id?:number,
  title?:string,
  name?:string,
  is_enabled:boolean,
  metadata?:null
}
export type PublicLayers = {
    url?:string
    name?:string,
    type?:string,
    version?: string,
    layers?:PublicSublayer[],
    metadata?:null,
    username?: string,
    password?: string
}
// open data map from the json responce
export function openDataURLXMLMap(jsonObject,publicLayers){
    const layers:PublicLayers=publicLayers;
    const sublayers=[];
    let key;
    switch(layers.type){
        case 'wfs':
            key = jsonObject['wfs:WFS_Capabilities'] ? 'wfs:WFS_Capabilities' : 'WFS_Capabilities';
            if(jsonObject[key]){
                layers.name = jsonObject[key]['ows:ServiceIdentification'] ? jsonObject[key]['ows:ServiceIdentification']['ows:Title'] ? jsonObject[key]['ows:ServiceIdentification']['ows:Title'] : jsonObject[key]['ows:ServiceProvider']['ows:ProviderName'] : jsonObject[key]['ows:ServiceProvider']['ows:ProviderName']
                layers.version = jsonObject[key]['ows:ServiceIdentification'] ? jsonObject[key]['ows:ServiceIdentification']['ows:ServiceTypeVersion'] ? jsonObject[key]['ows:ServiceIdentification']['ows:ServiceTypeVersion'] : jsonObject[key]['@attributes']['version'] : jsonObject[key]['@attributes']['version']
                if(Array.isArray(jsonObject[key].FeatureTypeList.FeatureType)){
                    jsonObject[key].FeatureTypeList.FeatureType.forEach(layer => {
                        sublayers.push({title:layer.Title,name:layer.Name,is_enabled:false,metadata:null});
                    });
                }else{
                    const singleLayer=jsonObject[key].FeatureTypeList.FeatureType;
                    sublayers.push({title:singleLayer.Title,name:singleLayer.Name,is_enabled:false,metadata:null});
                }
                layers.layers=sublayers;
            }
            break;
        case 'wms':
            key = jsonObject['wms:WMS_Capabilities'] ? 'wms:WMS_Capabilities' : 'WMS_Capabilities';
            if(jsonObject[key]){
                layers.name=jsonObject[key].Service.Title;
                layers.version=jsonObject[key]['@attributes'].version;
                if(Array.isArray(jsonObject[key].Capability.Layer.Layer)){
                    jsonObject[key].Capability.Layer.Layer.forEach(layer => {
                        sublayers.push({title:layer.Name,name:layer.Name,is_enabled:false,metadata:null});
                    });
                }else if(jsonObject[key].Capability.Layer.Layer){
                    const singleLayer=jsonObject[key].Capability.Layer.Layer;
                    sublayers.push({title:singleLayer.Name,name:singleLayer.Name,is_enabled:false,metadata:null});
                }else{
                    const singleLayer=jsonObject[key].Capability.Layer;
                    sublayers.push({title:singleLayer.Name,name:singleLayer.Name,is_enabled:false,metadata:null});
                }
                layers.layers=sublayers;
            }
            break;
        case 'public_arcgis':
            if(jsonObject.layers){
                let name;
                if(publicLayers.url.includes('FeatureServer')){
                    name = publicLayers.url.replace(/.*services\/+(.*)+\/FeatureServer.*/g, "$1");
                }
                if(publicLayers.url.includes('MapServer')){
                    name = publicLayers.url.replace(/.*services\/+(.*)+\/MapServer.*/g, "$1");
                }
                layers.name=name;
                layers.version=jsonObject.currentVersion;
                jsonObject.layers.forEach(layer => {
                    sublayers.push({id:layer.id,title:layer.name,name:layer.name,is_enabled:false,metadata:null});
                });
                layers.layers=sublayers;
            }
            break;
        default:
            break;
    }
    return layers;
}
//open data layer map
export function openDataAccordionMap(layers): any{
    layers.map(item => {
        item.type=item.type;
        item.active=false;
        item.color=item.type.metadata ? item.type.metadata.color : "#3399CC";
        item.zoom=20;
        item.transparency=1;
        const flag=[];
        item.layers.forEach(el => {
            if(el.is_enabled){
                el.type=item.type;
                el.is_active=false;
                el.color=el.metadata ? el.metadata.color : "#3399CC";
                el.zoom=20;
                el.transparency=1;
                // eslint-disable-next-line no-unused-expressions
                el.credentials = item.credentials ? true : false,
                flag.push(el);
                item.sublayers=flag;
            }
        });
    });
    return layers;
}
//wms url genrator
export function wmsURLSet(map,enableLayers,layer,evt){
   return enableLayers[layer.values_.source.params_.LAYERS].getSource().getFeatureInfoUrl(evt.coordinate, map.getView().getResolution(), map.getView().getProjection(),
        {
            'INFO_FORMAT': 'text/xml' ,
            'QUERY_LAYERS': layer.values_.source.params_.LAYERS,
            'LAYERS':  layer.values_.source.params_.LAYERS,
            'REQUEST': 'GetFeatureInfo'
        });
}
//wms feature parce
export function wmsFeatureget(featureParce,layer){
    let feature={};
    if(featureParce.FeatureInfoResponse){
        if(featureParce.FeatureInfoResponse.FIELDS){
            if(featureParce.FeatureInfoResponse.FIELDS['@attributes']){
                feature=featureParce.FeatureInfoResponse.FIELDS['@attributes'];
                Object.assign(feature, {layer:layer.values_.source.params_.LAYERS});
            }
        }
    }
    return feature;
}
//open data url parse validation
export function urlParseParames(url,parseUrl,type){
  if(parseUrl.has('version')){
    url.searchParams.delete('version');
  }
  if(!parseUrl.has('request')){
    url.searchParams.append('request', 'GetCapabilities');
  }
  if(!parseUrl.has('service')){
    url.searchParams.append('service', type.toUpperCase());
  }
  return url.toString();
}
//set opendata layer date formate
export function openDataLayerFormate(feature): any{
  for(const [key, value] of Object.entries(feature)) {
    switch(true){
      case key.includes('LaatsteUpd'):
        feature[key]=moment(value).format('DD/MM/YYYY hh:mm');
        break;
      case key.includes('date'):
        feature[key]=moment(value).format('DD/MM/YYYY hh:mm');
        break;
    }
  }
  return feature;
}
