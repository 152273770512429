import { ID } from "@datorama/akita"

export type Language = {
  id?: ID,
  title: string,
  code: string
}
// languages provider
export function multilingualProvider(): Language[]{
  return [
    {
      id: 1,
      title: 'english',
      code: 'en'
    },
    {
      id: 2,
      title: 'dutch',
      code: 'nl'
    }
  ]
}
//default notification values
const notification={
  daily_mail: false,
  weekly_mail: false,
  item_assigned: false,
  item_status_changed: false,
  comment_tagged: false,
  comment_posted: false,
  bouwapp_integration: false,
  freshdesk_integration: false
}
//user side logic
// default language according to company level
export function defaultmultilingualCompanyProvider(globals): Language[]{
  if(globals.customer_personalization){
    if(globals.customer_personalization.metadata){
      if(globals.customer_personalization.metadata.lang){
        return [globals.customer_personalization.metadata.lang];
      }
    }
  }
  return [
    {
      id: 2,
      title: 'dutch',
      code: 'nl'
    }
  ];
}
// default language according to project level
export function defaultmultilingualProjectProvider(globals): Language[]{
  if(globals.project_personalization){
    if(globals.project_personalization.metadata){
      if(globals.project_personalization.metadata.lang){
        return [globals.project_personalization.metadata.lang];
      }
    }
  }
  return defaultmultilingualCompanyProvider(globals);
}
// default languages provider
export function defaultmultilingualProvider(globals): Language[]{
  if(globals.user.personalization){
    if(globals.user.personalization.metadata){
      if(globals.user.personalization.metadata.lang){
        return [globals.user.personalization.metadata.lang]
      }
    }
  }
  return defaultmultilingualProjectProvider(globals);
}
//set default language according to company level
export function setDefaultCompanyLanguage(configs): string{
  if(configs.customer_personalization){
    if(configs.customer_personalization.metadata){
      if(configs.customer_personalization.metadata.lang){
        return configs.customer_personalization.metadata.lang.code;
      }
    }
  }
  return 'nl';
}
//set default language according to project level
export function setDefaultProjectLanguage(configs): string{
  if(configs.project_personalization){
    if(configs.project_personalization.metadata){
      if(configs.project_personalization.metadata.lang){
        return configs.project_personalization.metadata.lang.code;
      }
    }
  }
  return setDefaultCompanyLanguage(configs);
}
//set default language according to level
export function setDefaultLanguage(configs,key): string{
  if(configs[key] && configs[key].personalization){
    if(configs[key].personalization.metadata){
      if(configs[key].personalization.metadata.lang){
        document.documentElement.lang = configs[key].personalization.metadata.lang.code
        return configs[key].personalization.metadata.lang.code;
      }
    }
  }
  return setDefaultProjectLanguage(configs);
}
//create persionallsation metadata
export function createPersonalisationMetadata(key,data,persionallsation): any{
  const language={metadata:{}};
  if(persionallsation){
    if(persionallsation.metadata){
      if(typeof persionallsation.metadata=='string'){
        persionallsation.metadata={[key]: data};
      }else{
        Object.assign(persionallsation.metadata, {[key]: data});
      }
    }else{
      persionallsation.metadata={[key]: data};
    }
    return persionallsation;
  }
    Object.assign(language.metadata, {[key]: data});
    return language;
}

const englishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 of ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} of ${length}`;
}

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 van ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} van ${length}`;
}

//export language set for pagination
export function setLocalePagination(page: any,globels): any{
  if(setDefaultLanguage(globels,'user')=='nl'){
    if(page?.itemsPerPageLabel){
      page.itemsPerPageLabel = 'Items per pagina:';
      page.nextPageLabel = 'Volgende pagina';
      page.previousPageLabel = 'Vorige pagina';
      page.getRangeLabel = dutchRangeLabel;
    }
  }else{
    page.itemsPerPageLabel = 'Items per page:';
    page.nextPageLabel = 'Next Page';
    page.previousPageLabel = 'Previous Page';
    page.getRangeLabel = englishRangeLabel;
  }
  return page;
}

//notification handler
export function personalisationNotificationHandler(data): any{
  if(data){
    if(data.metadata){
      if(data.metadata.notifications){
        return data.metadata.notifications;
      }
    }
  }
  return false;
}
//notification handler
export function notificationsWithType(data,type): any{
  if(data){
    if(data[type]){
      return data[type];
    }
  }
  return false;
}
//configs notification if the data is null
export function configsNotification(data,type,event): any{
  data.config={notifications:notification};
  data.config.notifications[type]=event.checked;
  return data;
}
