export function objectKeysToLowerCase(origObj: any): any {
    return Object.keys(origObj).reduce((c, k) => (c[k.toLowerCase()] = origObj[k], c), {});
}
export function getNameField(mapItem): any {
  if (mapItem.naam)
      return "naam"
  for (const key in mapItem) {
    if (key.includes('naam') || key.includes('name')) {
      return key;
    }
  }
  return ""
}

export function getIdField(mapItem): any {
  if (mapItem.id)
      return "id"
  if (mapItem.FID)
      return "FID"
  for (const key in mapItem) {
    if (key.includes('id')) {
        return key;
    }
    if (key.includes('ID')) {
        return key;
    }
  }
}
export function parsemapItem(mapItem): any {
  const id_field = getIdField(mapItem);
  mapItem = objectKeysToLowerCase(mapItem);
  let title = "";
  const nameField = getNameField(mapItem);
  const layer_id = mapItem[getIdField(mapItem)] ? mapItem[getIdField(mapItem)] : '';
  if (nameField == "")
      title = layer_id ? `${layer_id }-${ mapItem.layer}` : mapItem.layer;
  else
      title = mapItem[getNameField(mapItem)];
  return { title: title, layer_id: layer_id, layer_name: mapItem.layer, id_field: id_field }
}
