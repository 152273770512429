import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { translate } from '@jsverse/transloco';
import collect from 'collect.js';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { MapService } from 'src/app/core/service/api/map.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { sublayerToLayers } from 'src/app/core/util/integration';
import { addStyleOnMainLayer, addStyleOnMainLayerToSave, arcGisIntegrationSymbology, arcGisIntegrationSymbologyDefault, openLayerstyleSavingResponce, setCustomeStyle, setStyleFromParent, setSymobologyStyleColor, styleSavingResponce } from 'src/app/core/util/mapModal';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import { DeletionModalComponent } from 'src/app/shared/components/modal/deletion-modal/deletion-modal.component';
import { AddOpenDataLayersComponent } from '../../../../../shared/components/modal/add-open-data-layers/add-open-data-layers.component';

@Component({
  selector: 'app-feature-layers-accordion',
  templateUrl: './feature-layers-accordion.component.html',
  styleUrls: ['./feature-layers-accordion.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureLayersAccordionComponent extends GenericComponent implements OnInit {
  @Input() layers: any[]=[];
  public layer: any;
  public arcGis_connectedItems=['Activiteiten','Meldingen','Klanteisen','Klantwensen','Issues','Middelen','Stakeholders'];
  public oldEnableLayers:any[]=[];
  public metadata = [];
  @Input() header: string='';
  @Input() type: string='';
  @Input() showToggles: boolean=false;
  @Input() showSubLayer: boolean=false;
  @Output() toggleOpenDataLayers: EventEmitter<any> = new EventEmitter();
  @Output() loadOpenData: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChangeLayerStyle: EventEmitter<any> = new EventEmitter();

  public layerZoomValue: number | undefined = 20;
  constructor(private cd: ChangeDetectorRef,private sanitizer: DomSanitizer,public _mapServices:MapService,public dialog: MatDialog,public globals: Globals,private overlayContainer: OverlayContainer) {
    super(globals);
   }

  ngOnInit(): void {
    this.onLoadSymbology()
  }

   // Getter and setter for the zoom level to simplify the ngModel binding
   getLayerZoom(layer: any): number {
    return layer.metadata && layer.metadata.zoom !== undefined ? layer.metadata.zoom : layer.zoom;
  }

  setLayerZoom(layer: any, value: number): void {
    if (layer.metadata && layer.metadata.zoom !== undefined) {
      layer.metadata.zoom = value;
    } else {
      layer.zoom = value;
    }
  }

  //on toggle select all
  public onSelectAll(name,event,type,id): void{
    this._mapServices.mainLayerId = id
    this.layers.forEach(layer => {
      if(layer.id==id){
        if(layer.sublayers){
          layer.sublayers.map(sublayers => {
            sublayers.is_active=event.checked;
            this.onSelectSingle(sublayers.credentials,(sublayers.layer_name ? `${sublayers.name}-${sublayers.layer_name}` : sublayers.name),event,type,false,sublayers.id);
        });
        }else{
          layer.active=event.checked;
          this.onSelectSingle(layer.credentials ? true : false,layer.name,event,type);
        }
      }
    });
  }
  //on change style All
  public onChangeAllStyle(name,event,type,id): void{
    this.layers.forEach((layer:any) => {
      if(layer.id==id){
        layer=addStyleOnMainLayer(layer,name,event,type)
        if(layer.type=='wfs' || layer.type=='wms' || layer.type=='public_arcgis')
          layer=addStyleOnMainLayerToSave(layer,name,event,type)
        if(layer.sublayers){
          layer.sublayers.map(sublayers => {
            sublayers=setStyleFromParent(sublayers,event,type)
            this.onChangeSingleStyle((sublayers.layer_name ? `${sublayers.name}-${sublayers.layer_name}` : sublayers.name),event,type,sublayers,layer)
          });
        }else{
          this.onChangeLayerStyle.emit(setCustomeStyle(layer.name,event,type))
        }
      }
    });
  }
  //on toggle select single
  public onSelectSingle(credentials: boolean, layername:string,event,type,mainEnable=false, id?: number): void{
    if(mainEnable){
      this.enableMainItem()
    }
    this.toggleOpenDataLayers.emit({credentials: credentials, name:layername,visibility:event.checked,type:type,id:id});
  }
  //on change style single
  public onChangeSingleStyle(layerName,event,type,item,layer): void{
    this.onChangeLayerStyle.emit(setCustomeStyle(layerName,event,type))
    if(type=='color' || type=='zoom')
      this.submit(item,event,type,layer);
  }
  //on submit
  public submit(item,event,type,layer): void{
    switch(item.type){
      case 'wfs':
        case 'wms':
          case 'public_arcgis':
            this.layer={...layer};
            this._mapServices.updateOpenData(openLayerstyleSavingResponce(this.layer,item,event,type))
            .pipe(
              // finalize(()=>this.loadOpenData.emit())
            ).subscribe();
            break;
      default:
        this.layer={...item};
        const mainLayer = {...layer};
        mainLayer.metadata = mainLayer.alldata;
        this.metadata = mainLayer.alldata;
        if(mainLayer.metadata) {
          if(mainLayer.metadata.length) {
            this.metadata.forEach(layer => {
              const layerIds = this.metadata.map(data => data.layerId);
              if(layerIds.includes(this.layer.layer_id)) {
                if(layer.layerId === this.layer.layer_id)
                  layer.metadata = styleSavingResponce(this.layer,event,type).metadata;
              }else {
                this.metadata.push({layerName: this.layer.layer_name, layerId: this.layer.layer_id, metadata: styleSavingResponce(this.layer,event,type).metadata});
              }
            });
          }else {
            this.metadata.push({layerName: this.layer.layer_name, layerId: this.layer.layer_id, metadata: styleSavingResponce(this.layer,event,type).metadata});
          }
        }else {
          this.metadata.push({layerName: this.layer.layer_name, layerId: this.layer.layer_id, metadata: styleSavingResponce(this.layer,event,type).metadata});
        }
        mainLayer.metadata = this.metadata;
        delete mainLayer.sublayers;
        this._mapServices.arcGisStyle(item.id,item.type,mainLayer)
        .pipe(
          tap((res:any)=>{
            layer.metadata = res.metadata;
            this.globals.enableLayers.forEach(item => {
              res.metadata.forEach(meta => {
                if(item.layer_name === meta.layerName && item.layer_id === meta.layerId){
                  item.metadata = meta.metadata;
                }
              });
            });
          })
        ).subscribe();
        break;
    }
  }
  //on edit modal
  public onEditOpenLayer(id): void{
    const dialog = this.dialog.open(AddOpenDataLayersComponent, modalConfig({
      data:collect(this.layers).where('id', id).all(),
      panelClass: ['animate__animated', 'animate__slideInUp'],
      disableClose: true,
      closeOnNavigation: true
    }, ModalEnum.ModalSmall));
    dialog.afterClosed().subscribe((response: any) => {
      if(response){
        this.loadOpenData.emit()
      }
    });
  }
  //on delete layer
  public onDeleteOpenLayer(id): void{
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: translate('maps.delete_layer_msg')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._mapServices.deleteOpenData(id)
        .pipe(
          finalize(()=>this.loadOpenData.emit())
        ).subscribe();
      }
    });
  }
  //enable the main item toggle
  public enableMainItem(){
    this.layers.forEach(item=>{
        item.active = collect(item.sublayers).contains((value, key) => value.is_active == true)
    });
  }
  //load symbology from layers
  public onLoadSymbology(){
    this.layers.forEach(layer => {
      switch(layer.type){
        case 'public_arcgis':
          layer.sublayers.forEach(item => {
            this.getSymobologyResponce(layer,item);
          });
          break;
        case 'arcgis':
          layer.sublayers.forEach(item => {
            if(!this.arcGis_connectedItems.includes(item.layer_name)){
              this.getSymobologyResponce(layer,item,this.globals.arcgisToken);
            }
          });
          break;
        default:
          break;
      }
    });
  }
  // load symbology color
  public setRBG(data){
    return `color: ${setSymobologyStyleColor(data)}`;
  }
  // make url safe
  public safeURL(url){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  public getSymobologyResponce(layer,item,token=''): void{
    this._mapServices.getSymobology(layer.url,(token ? item.layer_id : item.id),token)
    .pipe(
      tap((res:any)=>{
        if(token && !this.globals.projectConfigs["services.integration.arcgis_enterprise.enabled"]){
          this._mapServices.getSymobologyArcGis(item.layer_id,res.serviceItemId,token)
          .pipe(
            tap((response:any)=>{
              if(arcGisIntegrationSymbology(response)){
                if(response.layerDefinition.drawingInfo.renderer.uniqueValueInfos){
                  response.layerDefinition.drawingInfo.renderer.uniqueValueInfos = arcGisIntegrationSymbologyDefault(response);
                  if(layer.layer_name === item.layer_name)
                    layer.symobology=response.layerDefinition.drawingInfo.renderer.uniqueValueInfos;
                  item.symobology=response.layerDefinition.drawingInfo.renderer.uniqueValueInfos;
                }else{
                  if(layer.layer_name === item.layer_name)
                    layer.symobology=response.layerDefinition.drawingInfo.renderer;
                  item.symobology=response.layerDefinition.drawingInfo.renderer;
                }
              }else{
                if(layer.layer_name === item.layer_name) {
                  if(res){
                    if(res.drawingInfo){
                      if(res.drawingInfo.renderer){
                        layer.symobology=res.drawingInfo.renderer;
                      }
                    }
                  }
                }
                if(res){
                  if(res.drawingInfo){
                    if(res.drawingInfo.renderer){
                      item.symobology=res.drawingInfo.renderer;
                    }
                  }
                }
              }
            }),
            catchError((err)=>{
              this.cd.detectChanges();
              return throwError(err);
            })
          ).subscribe();
        }else{
          layer.symobology=true;
          item.symobology=res.drawingInfo.renderer;
        }
        this.cd.detectChanges();
      }),
      catchError((err)=>{
        this.cd.detectChanges();
        return throwError(err);
      })).subscribe();
  }
  //handel drag drop
  public drop(event: CdkDragDrop<string[]>): void{
    moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
    switch(this.type){
      case 'integrationconfigs':
        this.oldEnableLayers=sublayerToLayers(this.layers,this.globals.enableLayers);
        this.onChnageDrop(this.oldEnableLayers);
        break;
      case 'openlayers':
        this.onChnageDrop(this.layers);
        break;
      default:
        break
    }
  }
  //on change after drag drop
  public onChnageDrop(layers): void{
    this._mapServices.setSortedOpenData({data:layers},this.type)
    .pipe(
      tap((res:any)=>{
        if(this.type==='integrationconfigs'){
          this.globals.enableLayers=res;
        }
      }),
      finalize(()=>{
        showFeedbackSaved();
      })
    ).subscribe();
  }

}


