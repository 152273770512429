<div *ngIf="listingType=='all'">
    <div class="col-xs-12 no-padding tool-box">
        <div class="col-xs-4 pull-left email-send-button" *ngIf="toolbar === 'actions' && emailLink && selectionItems.length">
            <a matTooltip="Stuur email" class="btn mdl-button mdl-js-button mdl-button--primary d-cursor-hand d-pl-0" [href]="emailLink">
                <mat-icon class="pull-left d-cursor-hand">email</mat-icon>
                <span class="pl-6 set-font-email" style="position: relative; top: 3px;">{{'employees.email' | transloco | las}}</span>
            </a>
        </div>
        <div *hasPermission="[Permission.EmployeesCreate]" [ngClass]="listingType == 'stakeholder'?'in-panel':''" class="d-xs-mb15 d-mt8 pull-right add-btn add-employee-button">
            <div class="btn-group inline-block">
                <a [ngClass]="listingType == 'stakeholder'?'mat-stroked-button':'mat-flat-button'" newEntity="contactpersons" (employeesUpdated)="onNewEmployeeClosed($event)" [newEntityParams]="newEntityParams">
                    <i class="uil uil-plus-circle unicon-icon d-text-middle mr-5"></i>
                    <span class="hidden-xs">{{ addButtonText }}</span>
                </a>
            </div>
        </div>
    </div>
    <div class="col-xs-12 no-padding tool-box" style="margin-top: -42px">
        <div class="search-overview">
            <div class="panel search-holder col-xs-7">
              <i *ngIf="loading" class="uil uil-spinner-alt uil-icon spinner-animation"></i>
              <i *ngIf="!loading" class="uil uil-search uil-icon"></i>
                <input class="search-box" [(ngModel)]="keyword" placeholder="{{'text.search_table' | transloco}}" value="" (keyup)="applyFilter($event.target.value)">
                <mat-icon *ngIf="keyword" (click)="cancelSearch()">close</mat-icon>
            </div>
        </div>
        <div *ngIf="keyword && !employees.filteredData.length" class="search-create-item-overlay">
            <b>
                <span newEntity="contactpersons" [newEntityParams]="{name: keyword, type : 'contactpersons'}" class="truncate-ellipsis">{{ 'create_item' | transloco}} '{{keyword | truncatie: 40}}' {{ 'automatically' | transloco}}</span>
            </b>
        </div>
        <app-batch-overview-setting type="employees" [filters]="exportFilter" overviewType="contactperson" [paginator]="paginator" modal="overview" [items]="selectionItems" (onloadItem)="loadEmployees($event)"></app-batch-overview-setting>
    </div>
    <div class="col-xs-12 table-scrolling-set no-padding text-left box-shadow overview-margin-bottom-space mt-12">
        <table class="overview-panel set-border-firefox-table overview-filter-widget-overflow pb-25" mat-table [dataSource]="employees" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="select" [sticky]="stickyEnabled">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="d-cursor-hand th mr-9" (change)="masterSelected($event.checked)" [(ngModel)]="onSelectAll" *ngIf="batchLoad">
                </mat-checkbox>
                <span class="fa-spin loader" *ngIf="!batchLoad">
                </span>
              </th>
              <td mat-cell *matCellDef="let employee">
                <mat-checkbox (change)="isSelected($event,employee)" [(ngModel)]="employee.checked">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="col-md-1 ml-6">
                    <span class="d-cursor-hand th mr-6">ID</span>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-md-1" (click)="nav(employee)"><span>{{ employee.id}}</span></td>
            </ng-container>

            <ng-container matColumnDef="name" [sticky]="stickyEnabled">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['name']">
                    <div class="display-set-employee">
                        <span class="d-cursor-hand th mr-6" (click)="filterStrategies.toggle()">Naam</span>
                        <app-filter-sort #filterStrategies [paginator]="paginator" [sortField]="'first_name'" [hideSortLabels]="false" (onApply)="loadEmployees()" class="app-filter-sort">
                        </app-filter-sort>
                    </div>
                    <div class="resize-handle" (mousedown)="startResize($event, 'name')"></div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-sm-3 col-xs-6 col-md-6 ellipsis-2" (click)="editEmployee(employee)">
                    <a class="truncate-ellipsis">{{employeeName(employee)}}</a></td>
            </ng-container>

            <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['first_name']">
                    <div class="display-set-employee">
                        <span class="d-cursor-hand th mr-6" (click)="filterStrategies.toggle()">{{'reusable.first_name' | transloco}}</span>
                        <app-filter-sort #filterStrategies [paginator]="paginator" [sortField]="'first_name'" [hideSortLabels]="false" (onApply)="loadEmployees()" class="app-filter-sort">
                        </app-filter-sort>
                        <div class="resize-handle" (mousedown)="startResize($event, 'first_name')"></div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-sm-3 col-xs-6 col-md-6 ellipsis-2" (click)="editEmployee(employee)">
                    <a class="truncate-ellipsis">{{employee.first_name}}</a></td>
            </ng-container>

            <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['last_name']">
                    <div class="display-set-employee">
                        <span class="d-cursor-hand th mr-6" (click)="filterStrategies.toggle()">{{'reusable.last_name' | transloco}}</span>
                        <app-filter-sort #filterStrategies [paginator]="paginator" [sortField]="'last_name'" [hideSortLabels]="false" (onApply)="loadEmployees()" class="app-filter-sort">
                        </app-filter-sort>
                        <div class="resize-handle" (mousedown)="startResize($event, 'last_name')"></div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-sm-3 col-xs-6 col-md-6 ellipsis-2" (click)="editEmployee(employee)">
                    <a class="truncate-ellipsis">{{employee.last_name}}</a></td>
            </ng-container>

            <ng-container matColumnDef="relatics">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="d-cursor-hand th mr-6"></span>
                </th>
                <td mat-cell *matCellDef="let employee" class="icons" (click)="nav(employee)">
                    <ul class="list-intable">
                        <a class="td-link no-style" *ngIf="employee.relatics_uuid">
                            <ngx-avatars size="30" [matTooltip]="'Relatics'" textSizeRatio="2.5" src="../../../../assets/png/integrations/overviewrelatics.png">
                            </ngx-avatars>
                        </a>
                    </ul>
                </td>
            </ng-container>
            <ng-container *ngIf="showStakeholder" matColumnDef="stakeholders_name">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['stakeholders_name']">
                    <div class="display-set-employee">
                        <span class="d-cursor-hand th mr-6" [class.active]="paginator._filters['stakeholders.name'] && paginator._filters['stakeholders.name'].length" (click)="filterStrategies.toggle()">Stakeholders</span>
                        <app-filter-sort #filterStrategies [paginator]="paginator" [filters]="availableFilters" [filterFields]="['stakeholders.name']" [hideSortLabels]="false" (onApply)="loadEmployees()" class="app-filter-sort">
                        </app-filter-sort>
                        <div class="resize-handle" (mousedown)="startResize($event, 'stakeholders_name')"></div>
                    </div>
                </th>
                <td mat-cell class="d-cursor-hand" *matCellDef="let employee"><span class="d-cursor-hand truncate-ellipsis" (click)="editEmployee(employee)">{{ employee.stakeholders_name  }}</span></td>
            </ng-container>

            <ng-container matColumnDef="function">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['function']">
                    <div class="display-set-employee">
                        <span class="d-cursor-hand th mr-6" (click)="filterStrategies.toggle()">{{'reusable.function' | transloco}}</span>
                        <app-filter-sort #filterStrategies [paginator]="paginator" [sortField]="'function'" [hideSortLabels]="false" (onApply)="loadEmployees()" class="app-filter-sort">
                        </app-filter-sort>
                        <div class="resize-handle" (mousedown)="startResize($event, 'function')"></div>
                    </div>
                </th>
                <td mat-cell class="d-cursor-hand " *matCellDef="let employee" (click)="editEmployee(employee)">
                   <span class="truncate-ellipsis"> {{ employee.function}} </span></td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['email']">
                    <span class="d-cursor-hand th mr-6">{{'email' | transloco | capitaliz }}</span>
                    <div class="resize-handle" (mousedown)="startResize($event, 'email')"></div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-md-push-3">
                    <span class="truncate-ellipsis">{{employee.email}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['phone']">
                    <span class="d-cursor-hand th mr-6">{{'custom-columns.phone' | transloco | capitaliz }}</span>
                    <div class="resize-handle" (mousedown)="startResize($event, 'phone')"></div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-md-3">
                    <span class="truncate-ellipsis">{{employee.phone}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="personal_phone">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['personal_phone']">
                    <span class="d-cursor-hand th mr-6">{{'custom-columns.personal_phone' | transloco | capitaliz}}</span>
                    <div class="resize-handle" (mousedown)="startResize($event, 'personal_phone')"></div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-md-3">
                    <span class="truncate-ellipsis">{{employee.personal_phone}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef [style.min-width.px]="columnWidths['note']">
                    <span class="d-cursor-hand th mr-6">{{'notes' | transloco | capitaliz }}</span>
                    <div class="resize-handle" (mousedown)="startResize($event, 'note')"></div>
                </th>
                <td mat-cell *matCellDef="let employee" class="col-md-3">
                    <span class="truncate-ellipsis">{{employee.note}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="map_relations">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="d-cursor-hand th mr-6">{{'custom-columns.map_relations' | transloco | capitaliz}}</span>
                </th>
                <td mat-cell *matCellDef="let employee">
                    <span>
                        <span *ngIf="employee.layers.length" >{{employee.layers.length}}</span>
                    </span>
                </td>
            </ng-container>


            <ng-container matColumnDef="subprojects">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let employee" class="icons" (click)="editEmployee(employee)">
                  <a *ngIf="employee.subprojects && employee.subprojects.length > 1" [matTooltip]="''" class="td-link no-style">
                      <ngx-avatars *ngIf="hasModule('subprojects')" [value]="employee.subprojects.length.toString()" size="25" [style]="AvatarCustomStyle" initialsSize="2" textSizeRatio="2.5" borderColor="#e3e3e3" bgColor="#FFFFFF" fgColor="#0f0f0f">
                      </ngx-avatars>
                  </a>
                  <a *ngIf="employee.subprojects && employee.subprojects.length == 1" [matTooltip]="employee.subprojects[0].name" class="td-link no-style">
                      <ngx-avatars *ngIf="hasModule('subprojects')" [name]="employee.subprojects[0].name" size="25" [style]="AvatarCustomStyle" initialsSize="2" textSizeRatio="2.5" [borderColor]="employee.subprojects[0].color" fgColor="#000000" bgColor="#FFFFFF">
                      </ngx-avatars>
                  </a>
              </td>
          </ng-container>

            <ng-container matColumnDef="delete" [stickyEnd]="stickyEnabled">
                <th mat-header-cell *matHeaderCellDef class="d-cursor-hand text-center">
                  <ng-container *hasPermission="[Permission.EmployeesRestore]">
                    <mat-icon *ngIf="!loading" mat-icon-button [matMenuTriggerFor]="menu" (click)="menuToggle = !menuToggle" class="uil uil-ellipsis-v"></mat-icon>
                    <mat-menu #menu="matMenu" class="mat-menu">
                        <button mat-menu-item class="mat-menu-item" (click)="menuToggle = !menuToggle">
                            <app-recycle-bin-icon *ngIf="!loading" [icon]="true" item="employees" (onClosed)="loadEmployees()"></app-recycle-bin-icon>
                        </button>
                        <button mat-menu-item class="mat-menu-item" (click)="showColumnOptions = !showColumnOptions">
                            <span class="menu-option-title"><mat-icon class="uil uil-columns unicon-icon"></mat-icon>{{'custom_columns' | transloco}}</span>
                        </button>
                    </mat-menu>
                    <app-custom-columns *ngIf="showColumnOptions" [optionalColumns]="optionalColumns" [type]="'contactpersons'" (selectdColumns)="columnsUpdated($event)" (closeDropdown)="closeOptColModal($event)"></app-custom-columns>

                    <span class="fa-spin loader" *ngIf="loading"></span>
                  </ng-container>
                </th>
                <td mat-cell class="d-cursor-hand text-right" *matCellDef="let employee; let index = index" (click)="delete(employee)">
                    <span *hasPermission="[Permission.EmployeesDelete]" class="delete-button" matTooltip="{{'reusable.delete' | transloco}}">
                        <mat-icon class="mat-icon uil uil-trash" [class]="'contact-persons-delete-' + (index + 1) "></mat-icon>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="empty">
                <td colspan="50" mat-cell *matFooterCellDef>
                    <div *ngIf="!loading" class="empty-oc-height-set w-100 text-center empty-padding">
                        <div>
                            <img src="assets/png/empty-state-no-contactpersons.png" class="empty-state-image">
                            <h2>{{'dashboard.nodata.contactpersons_header' | transloco}}</h2>
                            <div *hasPermission="[Permission.EmployeesCreate]" [ngClass]="listingType == 'stakeholder'?'in-panel':''">
                                <p class="pb-10">{{'dashboard.nodata.contactpersons_description' | transloco}}</p>
                                <div class="btn-group inline-block">
                                    <a [ngClass]="listingType == 'stakeholder'?'mat-stroked-button':'mat-flat-button'" newEntity="contactpersons" [newEntityParams]="{name: keyword, type : 'contactpersons'}" (onClosed)="onNewEmployeeClosed($event)" [newEntityParams]="newEntityParams">
                                        <i class="uil uil-plus-circle unicon-icon d-text-middle mr-5"></i>
                                        <span class="hidden-xs">{{ 'employees.label' | transloco | capitaliz }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                <!--<td colspan="8" mat-cell *matFooterCellDef>
                    <i *ngIf="!loading">Er zijn wel contactpersonen</i>-->
                    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                </td>
            </ng-container>

            <ng-container matColumnDef="footer">
              <td colspan="50" mat-cell *matFooterCellDef class="pagination-top-border">
                <mat-paginator class="table-pagination-set list-view-paginator" [length]="paginator.total" [pageSize]="paginator.count" [pageSizeOptions]="pageSizeOptions" (page)="getServerPage($event)" [tagPressable]="{ id: 'table-paginator' }">
                </mat-paginator>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['empty']" [class]="employees.filteredData.length > 0 ? 'hidden' : ''"></tr>
            <tr mat-footer-row *matFooterRowDef="['footer'];sticky:true"></tr>
        </table>
    </div>
</div>
<div *ngIf="listingType!='all'">
    <div class="col-xs-12 no-padding tool-box">

        <div class="panel search-holder overview-panel col-xs-4 pull-left" *ngIf="toolbar === 'search'">
            <i matPrefix class="uil uil-search uil-icon"></i>
            <input class="search-box" placeholder="Doorzoek de tabel" value="" (keyup)="applyFilter($event.target.value)">
        </div>

        <div class="col-xs-4 pull-left" *ngIf="toolbar === 'actions' && emailLink">
            <a matTooltip="Stuur email" class="btn mdl-button mdl-js-button mdl-button--primary d-cursor-hand d-pl-0" [href]="emailLink">
                <mat-icon class="pull-left d-cursor-hand">email</mat-icon>
                <span class="pl-6" style="position: relative; top: 3px;">Stuur een e-mail</span>
            </a>
        </div>

        <div *hasPermission="[Permission.EmployeesCreate]" [ngClass]="listingType == 'stakeholder'?'in-panel':''" class="d-xs-mb15 d-mt8 pull-right add-btn">
            <div class="btn-group inline-block">
                <a [ngClass]="listingType == 'stakeholder'?'mat-stroked-button':'mat-flat-button'" newEntity="contactpersons" (onClosed)="onNewEmployeeClosed($event)" [newEntityParams]="newEntityParams">
                    <mat-icon class="d-text-middle mr-5">add_circle</mat-icon>
                    <span class="hidden-xs">{{ addButtonText }}</span>
                </a>
            </div>
        </div>
    </div>

    <div class="no-padding table-responsive col-xs-12 text-left box-shadow">

        <table class="set-border-firefox-table overview-filter-widget-overflow overview-panel table-border" mat-table [dataSource]="employees" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="masterSelectInner($event.checked)" [(ngModel)]="onSelectAll" *ngIf="batchLoad" ngDefaultControl>
                  </mat-checkbox>
                  <span class="fa-spin loader" *ngIf="!batchLoad">
                  </span>
                </th>
                <td mat-cell *matCellDef="let employee">
                  <mat-checkbox (change)="isSelected($event,employee)" [(ngModel)]="employee.checked" ngDefaultControl>
                  </mat-checkbox>
                </td>
              </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-sm-2 col-xs-6"> {{'reusable.name' | transloco}} </th>
                <td mat-cell *matCellDef="let employee" class="col-sm-2 col-xs-6" (click)="editEmployee(employee)">
                    <a class="ellipsis-2">{{ employee.name }}</a></td>
            </ng-container>

            <ng-container matColumnDef="function">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'reusable.job_title' | transloco}} </th>
                <td mat-cell class="d-cursor-hand " *matCellDef="let employee" (click)="editEmployee(employee)">
                   <span class="ellipsis-2"> {{ employee.function }} </span> </td>
            </ng-container>

            <ng-container *ngIf="showStakeholder" matColumnDef="stakeholders_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stakeholders </th>
                <td mat-cell class="d-cursor-hand" *matCellDef="let employee"><span class="d-cursor-hand ellipsis-2" (click)="editEmployee(employee)">{{ employee.stakeholders_name }}</span></td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef> {{'custom-columns.phone' | transloco | capitaliz}} </th>
                <td mat-cell *matCellDef="let employee"> <a [href]="'tel:'+employee.phone">{{ employee.phone }}</a> </td>
            </ng-container>

            <ng-container matColumnDef="personal_phone">
                <th mat-header-cell *matHeaderCellDef> {{'custom-columns.personal_phone' | transloco | capitaliz}} </th>
                <td mat-cell *matCellDef="let employee"> <a [href]="'tel:'+employee.personal_phone">{{ employee.personal_phone }}</a> </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
                <td mat-cell *matCellDef="let employee"> <a [href]="'mailto:' + employee.email">{{ employee.email }}</a></td>
            </ng-container>

            <ng-container matColumnDef="logs_count">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'menu.contactmoments' | transloco}} </th>
                <td mat-cell class="d-cursor-hand" *matCellDef="let employee" (click)="editEmployee(employee)">
                    {{ employee.logs_count }}</td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell class="d-cursor-hand" *matCellDef="let employee" (click)="delete(employee)"><span *hasPermission="[Permission.EmployeesDelete]" class="delete-button pull-right" matTooltip="{{'reusable.delete' | transloco}}">
            <mat-icon>delete</mat-icon>
          </span></td>
            </ng-container>

            <ng-container matColumnDef="empty">
                <td colspan="8" mat-cell *matFooterCellDef>
                    <i *ngIf="!loading">{{'reusable.no_contact' | transloco}}</i>
                    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['empty']" [class]="employees.filteredData.length > 0 ? 'hidden' : ''"></tr>
        </table>

    </div>
</div>
