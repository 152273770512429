export function levelValidation(scope){
    let role;
    switch(scope.level) {
        case 'subproject':
            role=scope.data[0].subproject;
          break;
        default:
          // code block
      }  
    return role;
}
export function extrectSubprojects(scope){
    const subprojects=[];
    switch(scope.level) {
        case 'subproject':
            scope.data.forEach(element => {
                subprojects.push(element.subproject)
            });
          break;
        default:
          // code block
      }  
    return subprojects;
}