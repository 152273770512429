<div *transloco="let t">
    <div>
        <div>
            <h3 class="modal-title"><strong>Integration </strong>{{ t('settings.pim.title') }}</h3>
        </div>
        <div class="col-xs-12 mb-5 z-index-set mb-10">
            <h5> {{ t('settings.connection') | titlecase}}</h5>
            <div class="set-border-connection">
                <span class="align-set" *ngIf="!pimenable && !errorMessage">{{ t('settings.not_connected')}}</span>
                <div class="align-set display-flex" *ngIf="errorMessage">
                    <mat-icon class="d-text-middle">error</mat-icon>
                    <span class="error-message align-set"> {{ t('error.arcgis_integration') | capitaliz}}</span>
                </div>
                <div class="align-set display-flex" *ngIf="pimenable && !errorMessage">
                    <mat-icon class="successfully-message d-text-middle">check_circle</mat-icon>
                    <span class="align-set"> {{ t('settings.successful_connection')}}</span>
                </div>
                <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate)" *ngIf="pimenable" class="btn btn-primary color-btn-primary-mapa align-set" type="button" (click)='updateConfig()'>
          <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
          {{ t('settings.disable') | titlecase}}
        </button>
            </div>
        </div>
        <form #form_integration="ngForm" (ngSubmit)="submit()">
            <div class="modal-body" *ngIf="configuration">
                <h5 class="control-label" for="url">{{ t('settings.pim.url') }}*:</h5>
                <mat-form-field class="full-width set-border-connection1 mb-10">
                    <input type="url" matInput class="" pattern="https?://.+" id="url" name="url" [(ngModel)]="configuration['services.integration.pim.url']" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" />
                </mat-form-field>
                <h5 class="control-label" for="url">{{ t('settings.pim.Secret_key') }}*:</h5>
                <mat-form-field class="full-width set-border-connection1 mb-10">
                    <input type="text" matInput class="" id="secret" name="secret" [ngModel]="configuration['services.integration.pim.client_secret']" (keyup)="onSecretChange($event)" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" />
                </mat-form-field>
                <h5 class="control-label" for="url">{{ t('settings.pim.pim_items') }}*:</h5>
                <div class="full-width set-border-connection1 mb-10">
                    <app-generic-multiselect-checkbox [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [placeholder]="t('settings.pim.pim_select_items')" name="pimSelection" [(ngModel)]="pimSelection" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' feature layers ...'" (ngModelChange)="update()">
                        <app-generic-multiselect-checkbox-option *ngFor="let item of connectionSelect; trackBy: trackByFn" [value]="item">
                            {{ item.value }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>
                </div>
            </div>
            <div class="footer-set">
                <button type="button" mat-stroked-button data-dismiss="modal" (click)='onNoClick()'>{{ t('close') | capitaliz }}</button>
                <button type="submit" mat-flat-button class="ml-10" [disabled]="form_integration.invalid || (level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate))">{{ t('save') | capitaliz }}</button>
            </div>
        </form>
    </div>
</div>
