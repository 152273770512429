<ng-container *ngIf="!typeCal">
    <div class="modal-header">
        <button (click)="close()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">
          <i class="uil uil-multiply unicon-icon"></i>
      </span>
    </button>
        <h4 class="modal-title">{{'reusable.new_calendar_item' | transloco}} </h4>
    </div>

    <!-- FORM SWITCH -->
    <div class="modal-body">
        <div class="gd-switch-event">
            <div class="col-xs-12 col-sm-12 col-md-6">
                <button type="button" class="btn btn-primary btn-lg gd-button-create gd-activity" (click)="switchEvent('activity')" [disabled]="!hasPermission(Permission.ActivitiesCreate)">{{ 'new_calender' | transloco | capitaliz }} {{'activities.label' | transloco }}</button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <button type="button" class="btn btn-primary btn-lg gd-button-create gd-middel" (click)="switchEvent('middel')" [disabled]="!hasPermission(Permission.MiddelCreate)">{{ 'new_e' | transloco | capitaliz }} {{'middel.label' | transloco }}</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="typeCal">
    <app-calendar-create-event-activity *ngIf="typeCal === 'activity'" (create)="createEvent($event)" (formReset)="resetForm()" [prefilledDate]="selectedDate">

    </app-calendar-create-event-activity>
    <app-calendar-create-event-middel *ngIf="typeCal === 'middel'" (create)="createEvent($event)" (formReset)="resetForm()" [prefilledDate]="selectedDate">

    </app-calendar-create-event-middel>
</ng-container>
