<form #form_newStakeholderAction="ngForm">
  <div mat-dialog-title>
    <h4 class="mb-10 inline-block">
      {{ "new" | transloco | capitaliz }} {{ "actions.label" | transloco }}
    </h4>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
      </span>
      <span class="sr-only">Close</span>
    </button>
  </div>

  <!-- modal body -->
  <mat-dialog-content>
    <!-- Action input field -->
    <div class="col-xs-12 col-sm-6 pl-0">
      <mat-form-field class="full-width">
        <mat-label>{{ "reusable.action" | transloco }}</mat-label>
        <input
          matInput
          autofocus
          name="new-action-text"
          [(ngModel)]="entity.text"
          required
        />
      </mat-form-field>
    </div>
    <!-- work name -->
    <div class="col-xs-12 col-sm-6 pl-0">
      <mat-form-field class="full-width">
        <mat-label>Workflow</mat-label>
        <input
          matInput
          autofocus
          name="new-workflow-text"
          [(ngModel)]="data.name"
          disabled="true"
        />
      </mat-form-field>
    </div>

    <div class="mt-10">
      <app-generic-multiselect-checkbox [selectReferUser]="selectReferUser" [chipDisabler]="chipDisablerUser" [placeholder]="'reusable.select_users' | transloco" name="users" ngDefaultControl  [(ngModel)]="entity.users" name="users" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' users ...'"
          (ngModelChange)="selectionChange()">
          <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
          <app-generic-multiselect-checkbox-option *ngFor="let user of users; trackBy: trackByFn" [value]="user">
              {{ user.name }}
          </app-generic-multiselect-checkbox-option>
      </app-generic-multiselect-checkbox>
      <mat-hint *ngIf="helperText">{{ 'text.all_user_select' | transloco }}</mat-hint>
  </div>

    <!-- Start date -->

    <div class="col-xs-12 col-sm-6 pl-0">
      <mat-form-field class="full-width">
        <mat-label>{{ "start_date" | transloco }}</mat-label>
        <input
          matInput
          name="startDate"
          [matDatepicker]="startDatePicker"
          [(ngModel)]="entity.start_date"
          (focus)="startDatePicker.open()"
          required
          (dateChange)="onStartDateChange($event)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- End date -->

    <div class="col-xs-12 col-sm-6 pr-0">
      <mat-form-field class="full-width">
        <mat-label>{{ "end_date" | transloco }}</mat-label>
        <input
          matInput
          name="endDate"
          [matDatepicker]="endDatePicker"
          [(ngModel)]="entity.timestamp"
          (focus)="endDatePicker.open()"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <!--end of modal body -->

  <mat-dialog-actions align="end">
    <div class="dialog-button-row">
      <button
        type="button"
        mat-stroked-button
        [disabled]="isProcessing"
        (click)="close()"
      >
        {{ "cancel" | transloco | capitaliz }}
      </button>
      <button
        type="submit"
        mat-flat-button
        [disabled]="!entity.text || !entity.users.length || isProcessing"
        (click)="submit()"
      >
        <span *ngIf="!isProcessing">
          <mat-icon class="material-icons d-text-middle">save</mat-icon>
          {{ "add" | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{
          "text.please_wait" | transloco | capitaliz
        }}</span>
      </button>
    </div>
  </mat-dialog-actions>
  <!--end of modal footer -->
</form>
