import { Observable } from "rxjs";
import { InAppChatService } from "src/app/core/service/api/in-app-chat.service";
import { InAppChat } from "src/app/core/util/inappmessages";
import { Globals } from "src/app/globals";

export class WhatsAppIntegrationChatModal {
  public messages$: Observable<InAppChat>;
  public responce$: Observable<any>;

  constructor(
    public _inAppChatService: InAppChatService,
    public globals: Globals,
    // eslint-disable-next-line no-empty-function
  ) { }

  //send messages
  public whatsappMessage(message) {
    return this.responce$ = this._inAppChatService.whatsappMessage(message);
  }
}
