import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import {MultiselectOptionType} from "../../../../../core/enum/multiselect-option-type";
import { MultiselectOption } from '../../../form/multiselect/multiselect-option';

@Component({
  selector: 'app-generic-multiselect-checkbox-option-all',
  templateUrl: './generic-multiselect-checkbox-option-all.component.html',
  styleUrls: ['./generic-multiselect-checkbox-option-all.component.sass']
})
export class GenericMultiselectCheckboxOptionAllComponent implements AfterViewInit, MultiselectOption {

  @Input() public value: any;
  public content: string = '';
  public checked: boolean = false;
  public optionType: MultiselectOptionType = MultiselectOptionType.all;

  // eslint-disable-next-line no-empty-function
  constructor(private ref:ElementRef) { }

  ngAfterViewInit(): void {
    this.content = this.ref.nativeElement.innerHTML.trim();
  }

}
