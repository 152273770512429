import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { translate } from '@jsverse/transloco';
import { saveAs } from 'file-saver';
import moment from "moment";
import posthog from 'posthog-js';
import { finalize, tap } from "rxjs/operators";
import { themeFilterSet } from 'src/app/akita/batch/batch';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { ReportResponse, ReportService } from "../../../../core/service/api/report.service";
import { StakeholdersService } from "../../../../core/service/api/stakeholders.service";
import { PaginatedResponse } from "../../../../core/service/common/paginated-response";
import { PaginationParams } from "../../../../core/service/common/pagination-params";
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { deepCopy } from '../../../../core/util/deepCopy';
import { Globals } from "../../../../globals";
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.sass']
})
export class ReportComponent extends GenericComponent implements OnInit {

  public reports = [
    {type: 'project', format: ['spreadsheet',],},
    {type: 'management', format: ['word', 'pdf',],},
    {type: 'decision_overview', format: ['word', 'pdf',],},
    {type: 'permit_overview', format: ['spreadsheet'],},
    {type: 'decision_overview_stakeholders', format: ['word', 'pdf',],},
    {type: 'stakeholder_overview', format: ['word', 'pdf','spreadsheet',],},
    {type: 'stakeholder', format: ['word', 'pdf', 'spreadsheet',],},
    {type: 'complaint', format: ['word', 'pdf'],},
    {type: 'complaint_overview', format: ['word', 'pdf', 'spreadsheet',],},
    {type: 'issue', format: ['word', 'pdf', 'spreadsheet',],},
    {type: 'issue_overview', format: ['word', 'pdf', 'spreadsheet',],},
    {type: 'logbook', format: ['word', 'pdf', 'spreadsheet',],},
    {type: 'calendar', format: ['spreadsheet',],},
    {type: 'action_overview', format: ['word', 'pdf']},
    {type: 'analysis_overview', format: ['spreadsheet']},
  ];

  public formatName = {
    spreadsheet: 'Excel',
    word: 'Word document',
    pdf: 'PDF',
  };

  public dialog_items: any[] = []
  public showAllSelectStakeholder:boolean=false;
  public options = {};
  public stakeholders = [];
  public issues = [];
  public complaints = [];
  public notSubprojectLevel: boolean = false;
  public exporting = false;
  public stakeholderRequired = false;
  public offsetRequired = false;
  public defaultDate: Date = moment().subtract(2, 'weeks').startOf('day').toDate();
  public data: any;
  public selectedActionReport:boolean=false;
  public selectedReport: any;
  public selectedAction: string = 'action_all';
  public selectPressableTag:any;
  public themes: any[]=[];
  public filterParam: any;
  public onChangeFilters: boolean = false;
  themeType: any = {};

  public showNotes = false;
  public notesToggle = false;
  public closedTasks=false;
  public taskPerItems:any[]=[
    {id:0, name:'logs'},
    {id:1, name:'issues'},
    {id:2, name:'decisions'},
    {id:3, name:'complaints'},
    {id:5, name:'middel'}
  ];
  @ViewChild(MatButtonToggleGroup) formatToggleGroup: MatButtonToggleGroup;
  constructor(public globals: Globals, @Inject(MAT_DIALOG_DATA) public selectedReportType: string, private _subProjectsQuery: SubprojectQuery,
              private dialogRef: MatDialogRef<ReportComponent>, private _reportService: ReportService,private cdRef: ChangeDetectorRef,
              private _stakeholdersService: StakeholdersService,private cachedService: CachedService) {
    super(globals);
  }

  public ngOnInit(): void {
    this.themeType = {
      decision_overview: {type: ThemeType.Decisions, placeholder: 'object'},
      //permit_overview: {type: ThemeType.Permits, placeholder: translate('type')},
      // decision_overview_stakeholders: {type: ThemeType.Decisions, placeholder: translate('theme')},
      complaint_overview: {type: ThemeType.Complaints, placeholder: 'type'},
      issue_overview: {type: ThemeType.Issues, placeholder: translate('theme')},
      stakeholder_overview: {type: ThemeType.Stakeholders, placeholder: 'type'},
      analysis_overview: {type: ThemeType.Stakeholders, placeholder: 'type'},
      analysis_overview_themes: {type: ThemeType.Issues, placeholder: translate('theme')}
    };

    if(!this.globals.modules.some(module => module.module_id === 'communication'))
      this.reports = this.reports.filter(c => c.type != 'complaint_overview' && c.type != 'calendar')

    this.data = {
      offset: this.defaultDate
    };

    // check the project level ...
    if (this._subProjectsQuery.getActive()) {
      this.notSubprojectLevel = false;
    } else {
      this.notSubprojectLevel = true;
    }
    this.onSelectedActionItemPer();
    this.updateSelectedReport();
    this.updateDialogItems();
  }

  public resetExtraField(): void {
    if(this.selectedAction=='action_per_item'){
      this.selectedActionReport=true;
      this.reportAPIAddSpreadsheet();
    }else{
      this.selectedActionReport=false;
      this.reportAPIAddSpreadsheet();
    }
    this.stakeholderRequired = false;
    this.showAllSelectStakeholder=false;
    this.offsetRequired = false;
    this.filterParam = null;

    if(this.data){
      if(this.data.stakeholders){
        this.data.stakeholders=[];
      }
      if(this.data.issues){
        this.data.issues=[];
      }
      if(this.data.complaints){
        this.data.complaints=[];
      }
    }
    this.cdRef.detectChanges();
  }

  updateDialogItems() {
    this.dialog_items = [
      {id: 1, key: 'stakeholders', label: 'Stakeholders', condition: false},
      {id: 2, key: 'comments', label: 'Updates', condition: !(this.selectedReportType === 'issue')},
      {id: 3, key: 'power_matrix', label: this.globals.projectConfigs['project.stakeholder_strategy.is_new'] ? translate('reusable.belang_matrix_strategic') : translate('reusable.belang_matrix'), condition: !(this.selectedReportType === 'management')},
      {id: 4, key: 'trust_matrix', label: this.globals.projectConfigs['project.stakeholder_strategy.is_new'] ? translate('reusable.forcefield_matrix_strategic') : translate('reusable.forcefield_matrix'), condition: !(this.selectedReportType === 'management')},
      {id: 5, key: 'issues', label: 'Issues', condition: !(this.selectedReportType === 'management')},
      {id: 6, key: 'tasks', label: translate('tasks.labels'), condition: false},
      {id: 7, key: 'contact_moments', label: translate('logs.labels'), condition: false},
      {id: 8, key: 'requirements', label: translate('requirements.labels'), condition: false},
      {id: 9, key: 'permits', label: translate('permits.labels'), condition: !this.hasModule('permit')},
      {id: 10, key: 'contents', label: translate('contents.labels'), condition: !(this.hasModule('communication') && this.selectedReportType === 'management')},
      {id: 11, key: 'activities', label: translate('activities.labels'), condition: !(this.hasModule('communication') && this.selectedReportType === 'management')},
      {id: 12, key: 'complaints', label: translate('complaints.labels'), condition: !this.hasModule('communication')},
      {id: 13, key: 'map_items', label: translate('maps.labels'), condition: !(this.selectedReportType === 'issue')},
      {id: 14, key: 'documents', label: translate('documents.labels'), condition: false},
    ]
    this.dialog_items = this.dialog_items.filter(item => !item.condition);
  }

  public updateSelectedReport(): void {
    this.onChangeFilters = false;
    this.selectedReport = this.reports.filter(r => r.type === this.selectedReportType)[0];
    if (this.selectedReport && (this.selectedReport.type === 'stakeholder' || this.selectedReport.type === 'logbook' || this.selectedReport.type === 'decision_overview_stakeholders') && this.stakeholders.length === 0) {
      this._stakeholdersService.getStakeholders(new PaginationParams()).subscribe(
        (response: PaginatedResponse) => this.stakeholders = response.data
      );
    }
    this.resetExtraField();
    this.updateDialogItems();
    this.notesToggle = false;
    if (this.selectedReportType === 'logbook') {
      this.stakeholderRequired = true;
      this.showAllSelectStakeholder=true;
      this.selectedActionReport=false;
      this.offsetRequired = true;
    } else if (this.selectedReportType === 'stakeholder') {
      this.stakeholderRequired = true;
      this.showAllSelectStakeholder=false;
      this.selectedActionReport=false;
    } else if (this.selectedReportType === 'management') {
      this.offsetRequired = true;
      this.selectedActionReport=false;
    } else if (this.selectedReportType === 'decision_overview_stakeholders') {
      this.stakeholderRequired = true;
      this.showAllSelectStakeholder=false;
      this.selectedActionReport=false;
      this.notesToggle = true;
    } else if (this.selectedReportType === 'decision_overview') {
      this.selectedActionReport=false;
      this.notesToggle = true;
    } else if (this.selectedReportType === 'issue') {
      this.cachedService.getIssuesRelations()
      .pipe(
        tap(res=>{
          this.issues = res;
        })
      ).subscribe();
    }else if (this.selectedReportType === 'complaint') {
      this.cachedService.getComplaintsRelations()
      .pipe(
        tap(res=>{
          this.complaints = res;
        })
      ).subscribe();
    }
    this.onChangeFilters = true;
  }
  public generateExport(): void {
    // Parse variables and set it to the right format
    this.populateDialogItems();

    const params = deepCopy(this.data);
    if (params.stakeholders) {
      params.stakeholders = params.stakeholders.map(stakeholder => stakeholder.code);
    }
    if (params.issues) {
      params.issues = params.issues.map(issue => issue.name);
    }
    if (params.complaints) {
      params.complaints = params.complaints.map(complaint => complaint.id);
    }
    let notes = 0;
    if (this.showNotes)
      notes = 1;
    this.exporting = true;
    this._reportService.download(this.selectedReportType === 'action_overview' ? this.selectedAction : this.selectedReportType, this.formatToggleGroup.value, params,notes,this.closedTasks,this.filterParam)
      .pipe(finalize(() => this.exporting = false))
      .subscribe(
        (report: ReportResponse) => {
          saveAs(report.body, report.filename);
          this.dialogRef.close();
        });
    posthog.capture('report-download', {
      eventType: this.selectedReportType,
      eventTypeFormat: this.formatToggleGroup.value
    });
  }

  public reportAPIAddSpreadsheet(): void{
    this.reports.map(element => {
      if(element.type=='action_overview'){
        if(this.selectedAction=='action_per_item'){
          element.format=['word', 'pdf','spreadsheet']
        }else{
          element.format=['word', 'pdf']
        }
      }
    });
  }
  public onSelectedActionItemPer(){
    if (this.selectedReportType === 'action_overview') {
      if (document.location.pathname.includes('item')) {
        this.selectedAction = 'action_per_item';
      }
    }
  }

  public populateDialogItems() {
    if ( this.selectedReportType === 'management' || this.selectedReportType === 'issue') {
      this.data.dialog_items = [];
      if (this.data.selected_dialog_items && this.data.selected_dialog_items.length) {
        this.data.selected_dialog_items.forEach(item => this.data.dialog_items.push(item.key));
      } else {
        this.dialog_items.forEach(item => this.data.dialog_items.push(item.key));
      }

      /*  add shift from matrix if power or trust is selected*/
      if (this.data.dialog_items.includes('power_matrix')) {
        this.data.dialog_items.push('power_matrix_shift_from');
      }
      if (this.data.dialog_items.includes('trust_matrix')) {
        this.data.dialog_items.push('trust_matrix_shift_from');
      }

      delete this.data.selected_dialog_items;

    } else if (this.data.dialog_items) {
      delete this.data.dialog_items;
    }
  }

  public updateSelectedTheme(theme, selectedType?) {
    this.themes = theme;
    const type = selectedType? this.themeType[selectedType].type :this.themeType[this.selectedReportType].type
    this.filterParam = themeFilterSet(theme,type);
  }
}
