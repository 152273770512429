import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {Subproject} from './subproject.model';
import {generateColor} from '../../../core/util/generateColor';

export interface SubprojectState extends EntityState<Subproject>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subprojects' })
export class SubprojectsStore extends EntityStore<SubprojectState, Subproject> {

  constructor() {
    super();
  }

  public updateWithColor(id: ID, subProject: Subproject): void {
    const color = generateColor(subProject.name);
    subProject = {
      ...subProject,
      color: color,
      locations: {
        ...subProject.locations,
        metadata: {
          ...subProject.locations.metadata,
          color: color,
        }
      }
    };

    this.update(id, subProject);
  }

}

