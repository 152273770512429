import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { WorkflowService } from 'src/app/core/service/api/workflow.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { NewEntityModal } from 'src/app/core/util/abstract/new-entity-modal';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-new-workflow',
  templateUrl: './new-workflow.component.html',
  styleUrls: ['./new-workflow.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewWorkflowComponent extends NewEntityModal implements OnInit {
  activeTab: string = 'new'; // Default active tab
  tasks: any[] = [];
  workflows: any[] = [];
  existingWorkflow: any;
  public selectedItemType: string = '';
  public dialogItems: any = [];
  public parentType: string = '';
  public entity: any = {
    name: '',
    issues: [],
    requirements: [],
    tasks: [],
    complaints: [],
    activities: [],
    contents: [],
    permits: [],
  };
  public reports = [
    {type: 'issues'},
    {type: 'requirements'},
    {type: 'tasks'},
    {type: 'complaints'},
    {type: 'activities'},
    {type: 'contents'},
  ];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NewWorkflowComponent>,
    public globals: Globals, private cd: ChangeDetectorRef, private _cachedService: CachedService, private _workflowService: WorkflowService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data && this.data.parent) {
      this.entity[this.data.parentType] = [this.data.parent];
      this.parentType = this.data.parentType;
    } else {
      this.parentType = 'overview'
    }
    this.loadTasks();
  }

  loadTasks(): void {
    forkJoin([
      this._cachedService.getActionsRelations(),
      this._cachedService.getWorkflows(),
      // Include any other observables if needed
    ]).subscribe(([tasks, workflows]) => {
      this.tasks = tasks.filter(x => !this.tasks.find(s => s.id === x.id));
      this.workflows = workflows.filter(x => !this.workflows.find(s => s.id === x.id));
      // Perform any other data processing if needed
    });
  }

  public close() {
    this.dialogRef.close();
  }

  // Check if the tab is active
  isTabActive(tab: string): boolean {
    return this.activeTab === tab;
  }

  // Handle tab change
  tabChanged(tab: string): void {
    this.activeTab = tab;
  }

  public initializeEntity(): void {
    this.entity = {
      workflow_item: [],
      name: '',
    }
  }

  public updateSelectedItem() {
    this.data.dialogItems = [];
    switch (this.selectedItemType) {
      case 'issues':
        this._cachedService.getIssuesRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'requirements':
        this._cachedService.getDecisionsRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'tasks':
        this._cachedService.getActionsRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'complaints':
        this._cachedService.getComplaintsRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'activities':
        this._cachedService.getActivitiesRelations().subscribe(
          (items: any) => this.dialogItems = items.data
        );
        break;
      case 'contents':
        this._cachedService.getMiddelRelations().subscribe(
          (items: any) => this.dialogItems = items.data
        );
        break;
      default:
        this.dialogItems = [];
        
    }
  }

  onChangeRequired() {
    console.log('on change...')
  }

  onSelectDialogItem(event: Event){
    // this.entity[this.selectedItemType] = [...this.data.dialogItems]
  }


  public submit(): void {
    this.isProcessing = true;
    // Construct the payload
    if (this.activeTab === 'new') {
      // Make the POST request
      this._workflowService.submitWorkflow(this.entity).subscribe({
        next: (response) => {
          this.isProcessing = false;
          showFeedbackSaved()
          this.dialogRef.close(response);
        },
        error: (error) => {
          console.error('Error creating workflow', error);
          this.isProcessing = false;
        }
      });
    } else {
      const id = this.existingWorkflow[0].id;
      // Make the POST request
      this._workflowService.replicateWorkflow(id, this.entity).subscribe({
        next: (response) => {
          this.isProcessing = false;
          showFeedbackSaved()
          this.dialogRef.close(response);
        },
        error: (error) => {
          console.error('Error creating workflow', error);
          this.isProcessing = false;
        }
      });
    }

  }

}
