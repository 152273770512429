import {Component, Inject, OnInit} from '@angular/core';
import {StakeholdersService} from "../../../../../core/service/api/stakeholders.service";
import {ResidentsService} from "../../../../../core/service/api/residents.service";
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {GenericComponent} from "../../../../../core/util/abstract/generic-component";
import {Globals} from "../../../../../globals";
import {Router} from "@angular/router";
import {finalize} from 'rxjs/operators';
import {ProjectConfigsService} from '../../../../../akita/project-configs/state/project-configs.service';

@Component({
  selector: 'app-residents-migrateid-popup',
  templateUrl: './residents-migrateid-popup.component.html',
  styleUrls: ['./residents-migrateid-popup.component.scss']
})

export class ResidentsMigrateidPopupComponent extends GenericComponent implements OnInit {

  DataTypesStakeholder: any;
  isLoadingResults = true;

  StakeHolderForm: FormGroup;
  submitted = false;

  protected type_id: string;
  protected id: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public resident: any,
    private formBuilder: FormBuilder,
    private residentsService: ResidentsService,
    private stakeholdersService: StakeholdersService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ResidentsMigrateidPopupComponent>,
    public globals: Globals,
    private _configsService: ProjectConfigsService,
    private router: Router
  ) {
    super(globals);
  }

  ngOnInit() {
    this.StakeHolderForm = this.formBuilder.group({
      type_id: ['', Validators.required]
    });

    this.getTypeStakeholders();
  }

  // Form Helper
  get f() {
    return this.StakeHolderForm.controls;
  }

  // Type Stakeholders
  getTypeStakeholders() {

    const result = this.stakeholdersService.getAllStakeholderType()
      .subscribe((res: any) => {
        this.DataTypesStakeholder = res.data;

      });

    return result;
  }

  // Migrate by ID
  migrateIDResidents(form: NgForm) {
    this.submitted = true;

    if (!this.StakeHolderForm.invalid) {
      this.isLoadingResults = true;
      this.residentsService.migrateIdResident(this.resident.id, form)
        .pipe(finalize(() => this.isLoadingResults = false))
        .subscribe(res => {
          this._configsService.get()
            .subscribe(() => {
              if (this.globals.projectConfigs['residents.disabled']) {
                this.router.navigate(['stakeholders']);
              }
            });
          this.StakeHolderForm.reset();
          this.dialogRef.close();
        });
    }
  }
}
