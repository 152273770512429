import {Directive, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl( condition : boolean ) {
    if(condition){
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }

  // eslint-disable-next-line no-empty-function
  constructor( private ngControl? : NgControl ) {}

}
