import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthService } from "../service/auth/auth.service";
import { AuthConstants } from "../../auth-constants";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  path: any
  // eslint-disable-next-line no-empty-function
  constructor(private _auth: AuthService, private _authConstant: AuthConstants, public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.path = next.routeConfig.path;
    console.log('auth guard...')
    if (this._auth.isLoggedIn()) {
      return true;
    } else if (localStorage.getItem('is_sso') == 'true' && localStorage.getItem('refresh_token_unparsed')) {
      return this._auth.getRefreshTokenSSO().pipe(
        map((res: any) => {
          if (res && res.access_token && res.refresh_token) {
            const jwtToken = JSON.parse(atob(res.access_token.split('.')[1]));
            const refresh_jwtToken = JSON.parse(atob(res.refresh_token.split('.')[1]));
            const expiresAt = refresh_jwtToken.expires_in;
            const refresh_token = refresh_jwtToken.refresh_token;
            const current_time = new Date();
            current_time.setSeconds(current_time.getSeconds() + expiresAt);
            localStorage.setItem("exp",current_time.toString());
            localStorage.setItem("refresh_token",refresh_token);
            localStorage.setItem("refresh_token_unparsed", res.refresh_token);
            localStorage.setItem("access_token",jwtToken.access_token);
            localStorage.setItem("token", res.access_token);
            window.location.reload();
            return true;
          }
          this._auth.logout();
          return false;
        }),
        catchError((error) => {
          this._auth.logout();
          return of(false);
        })
      );
    }
    this.router.navigate(['login']);
    this.router.navigateByUrl('/login');
    return false;
  }

}



