import { Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { EmployeesService } from 'src/app/core/service/api/employees.service';
import { StakeholdersService } from 'src/app/core/service/api/stakeholders.service';
import { PaginatedResponse } from 'src/app/core/service/common/paginated-response';
import { deadlineWithKPI, directMail } from 'src/app/core/util/complaintModal';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { SidebarEmployees } from 'src/app/pubsub/sidebarEmployees';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { environment } from 'src/environments/environment';
import { Employee } from '../../../../akita/contact-persons/state/employee.model';
import { Resident } from '../../../../akita/residents/state/resident.model';
import { Stakeholder } from '../../../../akita/stakeholders/state/stakeholder.model';
import { Entities } from '../../../../core/enum/entities';
import { Permission } from '../../../../core/enum/permission';
import { ComplaintsService } from '../../../../core/service/api/complaints.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { deepCopy, moduleValidation } from '../../../../core/util/deepCopy';
import { scrollToError } from '../../../../core/util/form';
import { Globals } from '../../../../globals';
import { GenericMultiselectCheckboxComponent } from '../../../../shared/components/other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { NewEntityData } from '../../../../shared/directive/new-entity.directive';
import { SidebarEmployeesComponent } from '../../sidebar-employees/sidebar-employees.component';
import { MapService } from 'src/app/core/service/api/map.service';

export interface ComplaintEditData {
  params?: any;
  options?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  };
};

@Component({
  selector: 'app-complaint-new',
  templateUrl: './complaint-new.component.html',
  styleUrls: ['./complaint-new.component.sass']
})
export class ComplaintNewComponent extends NewEntityModal implements OnInit {
  public responseLayers: any;
  declare public entity: any;
  public emailValidation:boolean=false;
  public emailNotifier:boolean=false;
  public complaintThemes: any[] = [];
  public users: any[] = [];
  public stakeholders: Stakeholder[] = [];
  public residents: Resident[] = [];
  public employees: Employee[] = [];
  public apiKey: string = environment.tinymce.apiKey;
  public connectStakeholderText: string = 'text.complaints_connected_to_stakeholder';
  public contactConnection: string = 'connect';
  public sidebarEmployees: SidebarEmployees = new SidebarEmployees();
  themeType = ThemeType.Complaints;
  @ViewChild(NgForm) private form: NgForm;
  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;
  @ViewChildren(GenericMultiselectCheckboxComponent) private multiselects: QueryList<GenericMultiselectCheckboxComponent>;
  public showMapReset: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<ComplaintNewComponent>,
    public globals: Globals, private _complaintsService: ComplaintsService, private _cachedService: CachedService,
    private pubsub: PubsubService, private _employeesService: EmployeesService,
    private _stakeholdersService: StakeholdersService,
    @Inject(MAT_DIALOG_DATA) public complaintData: ComplaintEditData, private dialog: MatDialog, private _mapService: MapService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
    this._mapService.setData(this.data.params);
    if(this.data.params && this.data.params.locations) {
      if (!this.data.params.locations.locatable_type || this.data.params.locations.locatable_type === 'undefined') {
        this.data.params.locations.locatable_type = 'complaints'
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._cachedService.getStakeholdersRelations().subscribe(
      stakeholders => {
        this.stakeholders = [...stakeholders];
        this.sidebarEmployees.stakeholders = stakeholders;
      }
    );
    this._cachedService.getComplaintThemes().subscribe(themes => this.complaintThemes = themes);
    this._cachedService.getUsers().subscribe(users => this.users = users);
    if (this.complaintData && this.complaintData.params) {
      this.entity.text = this.complaintData.params.description ? this.complaintData.params.description : '';
      this.entity.date = this.complaintData.params.contact_moment_timestamp ? this.complaintData.params.contact_moment_timestamp : new Date();
      this.emailValidation=true;
    }
    if (this.complaintData.options && this.complaintData.options.droppedEmails && this.complaintData.options.droppedEmails.length > 0) {
      const addresses: string[] = this.complaintData.options.droppedEmails
        .map(data => data.address);

      let foundEmployeeEmails: any[] = [];
      forkJoin([this._stakeholdersService.findStakeholdersByEmployees(addresses), this._employeesService.findEmployees(addresses)])
        .subscribe((response: [PaginatedResponse, PaginatedResponse]) => {
          this.entity.stakeholders = []
          this.entity.contactpersons = []
          if (response[0].data?.length)
            this.entity.stakeholders = response[0].data;
          if (response[1].data?.length) {
            this.entity.contactpersons = response[1].data[0];
            this.entity.contactpersons.selected = this.entity.contactpersons.stakeholders.filter(x => this.entity.stakeholders.find(s => s.id == x.id));

          }
          foundEmployeeEmails = response[0].data
            .map(stakeholder => stakeholder.contactpersons)
            .reduce((accumulator, contactpersons) => accumulator.concat(contactpersons), [])
            .map(contactpersons => contactpersons.email ? contactpersons.email.toLowerCase() : null);
          foundEmployeeEmails = foundEmployeeEmails.concat(response[1].data
            .map(employee => employee.email ? employee.email.toLowerCase() : null));

          this.complaintData.options.droppedEmails.forEach(email => {
            if (foundEmployeeEmails.filter(e => e === email.address).length === 0) { // Check if email is not part of already assigned stakeholders
              this.sidebarEmployees.emails.unregistered.push(email);
            }
          });
        });
    }


    this._cachedService.getEmployees().subscribe(
      employees => this.employees = employees
    );

    if ( this.globals.projectConfigs && !this.globals.projectConfigs['residents.disabled']) {
      this.connectStakeholderText = 'text.complaints_connected_to_stakeholder_resident';
      this._cachedService.getResidents().subscribe(
        residents => this.residents = residents
      )
    }
  }

  public submit() {
    /**
     * Hacky way to show error that the custom multiselect is required
     * Todo: use proper mat-form-field implementation of the generic multiselect checkbox component.
     * Check "experimental_mat_form_field" branch
     */
    this.multiselects.forEach(select => select.onTouched(true));

    if (this.form.invalid) {
      scrollToError();
      return;
    }

    this.isProcessing = true;
    if(!this.emailValidation && this.entity.text){
      const result = this.entity.text.replace(/(<([^>]+)>)/ig, "");
      this.entity.text=result;
    }
    const params = deepCopy(this.entity);
    params.date = this.entity.date.toJSON();
    // set is closed to false for the relation component to show in status column
    params.is_closed = 0;
    this._complaintsService.submitComplaint(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:data,
                message: translate('error.stored'),
                type:'openItems',
                item:'meldingen',
                url:'meldingen'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.complaints, data);
        });
  }

  public showEmployeeSidebar(): void {
    const modal = this.dialog.open(SidebarEmployeesComponent, {
      data: this.sidebarEmployees,
      width: '303px',
      height: '100vh',
      maxHeight: '100vh',
      position: {
        right: '0px'
      }
    });

    modal.afterClosed().subscribe((response: { sidebar: SidebarEmployees, stakeholders: any[], employees: any[] }) => {
      if (response) {
        this.employees = this.employees.concat(response.employees);
        this.stakeholders = this.stakeholders.concat(response.stakeholders);
        this.entity.contactpersons = response.employees.length > 0 ? response.employees[0] : [];
        this.entity.stakeholders = this.entity.stakeholders.concat(response.stakeholders);
      }
    });
  }

  protected initializeEntity(): void {
    this.entity = {
      date: new Date(),
      deadline: deadlineWithKPI(this.globals, 'low', new Date()),
      text: '',
      residents: null,
      stakeholders: null,
      contactpersons: null,
      users: [],
      logs: [],
      complainant_name: null,
      complainant_email: null,
      complainant_phone: null,
      locations: null,
      direct_mail:false,
      priority: 'low'
    };

  }

  public selectContactPreference(): void {
    this.entity.complainant_name = null;
    this.entity.complainant_email = null;
    this.entity.complainant_phone = null;
    this.entity.stakeholders = null;
    this.entity.residents = null;
    this.entity.contactpersons = null;
  }

  public employeeSet(): void {
    if (this.entity.contactpersons) {
      if (!this.entity.stakeholders)
        this.entity.stakeholders = []
      this.entity.contactpersons.selected = this.entity.contactpersons.stakeholders.filter(x => this.entity.stakeholders.find(s => s.id == x.id));
      if (this.entity.contactpersons.stakeholders && this.entity.contactpersons.stakeholders.length == 1) {
        this.entity.stakeholders = this.entity.contactpersons.stakeholders;
        if (!this.stakeholders.some(s => this.entity.stakeholders[0].id === s.id)) {
          this.stakeholders.push(this.entity.stakeholders[0]);
        }
      }
      this.entity.residents = null;
    }
  }

  onStakeholdersUpdated(event) {
    if (!this.entity.stakeholders)
      this.entity.stakeholders = []
    if (event.action == "add") {
      this.entity.stakeholders.push(event.data);
      this.stakeholderMultiselect.writeValue(this.entity.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    else {
      this.entity.stakeholders = this.entity.stakeholders.filter(s => s.id != event.data.id);
      this.stakeholderMultiselect.writeValue(this.entity.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
  }

  public residentSet(): void {
    if (this.entity.residents) {
      this.entity.stakeholders = null;
      this.entity.contactpersons = null;
    }
  }

  public stakeholderSet(): void {
    if (this.entity.stakeholders) {
      this.entity.residents = null;
    }
  }

  @Allowed(Permission.ComplaintsCreate)
  public updateSelectedTheme(theme) {
    if(theme){
      this.entity.complaintthemes = theme;
    }
  }
  public onUserSelectNotify(): void{
    if(moduleValidation('Communicatie',this.globals.modules)){
      if(this.globals.project.sla_enabled || this.globals.project.complaints_kpi){
        this.entity.deadline=deadlineWithKPI(this.globals, this.entity.priority, this.entity.date);
      }
    }
    this.emailNotifier=directMail(this.entity,this.globals)
  }
  public onDeadlineChnage(): void{
    if(moduleValidation('Communicatie',this.globals.modules)){
      if(this.globals.project.sla_enabled || this.globals.project.complaints_kpi){
        this.entity.deadline=deadlineWithKPI(this.globals, this.entity.priority, this.entity.date);
      }
    }
  }
}
