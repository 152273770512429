import { Injectable } from '@angular/core';
import { AbstractService } from "../common/abstract.service";
import { HttpClient } from "@angular/common/http";
import { Typeables } from "../../../core/enum/typeables";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class TypesService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getTypes(type: Typeables) {
    return this.http.get(`/api/types?group=${type}`, this.httpOptions);
  }
  searchTypes(type: Typeables, keyword: string) {
    return this.http.get(`/api/types?group=${type}&keyword=${keyword}`, this.httpOptions);
  }
}
