<div class="box box-primary direct-chat direct-chat-primary">
    <div class="box-header with-border">
        <div class="d-flex">
            <img [src]="data.inAppIcon" class="chat-header-icon">
            <h3 class="box-title a-self-center">{{ 'settings.'+data.integrationType+'.chat_header' | transloco}}</h3>
        </div>
        <div class="box-tools pull-right">
            <button *ngIf="!data.expand" (click)='modalExpand(false)' type="button" class="btn btn-box-tool"><mat-icon class="d-text-middle">close_fullscreen</mat-icon></button>
            <button *ngIf="data.expand" (click)='modalExpand(true)' type="button" class="btn btn-box-tool"><mat-icon class="d-text-middle">open_in_full</mat-icon></button>
            <button (click)='close()' type="button" class="btn btn-box-tool" data-widget="remove"><mat-icon class="d-text-middle">clear</mat-icon></button>
        </div>
    </div>
    <!-- /.box-header -->
    <div class="box-body" [ngClass]="!data.expand ? 'modal-expand-height' : ''">
        <!-- Conversations are loaded here -->
        <div #scrollMe class="direct-chat-messages">
            <!-- load first message  -->
            <div class="direct-chat-msg" *ngIf="data.firstMessage.length">
                <div class="direct-chat-text set-left-content">
                    <span class="inner-p-tag-mb-0" [innerHtml]="data.firstMessage[0].content | safeHtml"></span>
                </div>
                <div class="direct-chat-info clearfix set-left-date-time">
                    <span class="direct-chat-timestamp pull-right" *ngIf="integrationType=='freshdesk' && data.firstMessage[0].type">{{'settings.freshdesk.ticket_description' | transloco}}</span>
                    <span class="direct-chat-timestamp">{{data.firstMessage[0].createdAt | formatdate: 'dd MMM h:mm a' : globals.timezone}}</span>
                </div>
            </div>
            <!-- Message. Default to the left -->
            <ng-container *ngIf="(messages$ | async) as messages">
                <div *ngIf="emptyState">
                    <div *ngFor="let item of messages">
                        <div class="direct-chat-msg" *ngIf="!item.is_sent">
                            <ngx-avatars *ngIf="item.fullName && integrationType!=='whatsapp'" class="pull-left" [matTooltip]="item.fullName" [name]="item.fullName" size="40" textSizeRatio="2.5" borderColor="#F2F2F2" bgColor="#F2F2F2" fgColor="#0f0f0f">
                            </ngx-avatars>
                            <ngx-avatars *ngIf="item.full_name && integrationType ==='whatsapp'" class="pull-left" [matTooltip]="item.full_name" [name]="item.full_name" size="40" textSizeRatio="2.5" borderColor="#F2F2F2" bgColor="#F2F2F2" fgColor="#0f0f0f">
                            </ngx-avatars>
                            <div class="direct-chat-text set-left-content">
                                <!-- attachment for bouwapp -->
                                <div *ngIf="integrationType=='bouwapp'">
                                    <a class="d-cursor-hand" (click)='lightboxOpen(item)'>
                                        <img *ngIf="item.attachment" [src]="item.attachmentThumbnails.full" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                    </a>
                                </div>
                                <!-- attachment for freshdesk -->
                                <div *ngIf="integrationType=='freshdesk'">
                                    <div *ngFor="let doc of item.attachment">
                                        <a class="d-cursor-hand" (click)='lightboxOpen(doc)' *ngIf="(doc.name.includes('.png') || doc.name.includes('.gif') || doc.name.includes('.jpeg')  || doc.name.includes('.jpg')) else attached_document">
                                            <img [src]="doc.attachment_url" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                        </a>
                                        <ng-template #attached_document>
                                            <a class="d-cursor-hand" [href]="doc.attachment_url">
                                                <p class="image-attachment d-flex add-attachment-style" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}"><mat-icon class="mr-10">insert_drive_file</mat-icon><span class="a-self-center">{{doc.name | truncatie : 25 }}</span></p>
                                            </a>
                                        </ng-template>
                                    </div>
                                </div>

                                <div *ngIf="integrationType=='whatsapp'">
                                  <a class="d-cursor-hand" (click)='lightboxOpen(item)'>
                                      <img  style="width: 20%;" *ngIf="item.content.type === 'image'" src="/assets/png/image.png" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                      <img style="width: 20%;" *ngIf="item.content.type === 'document'" src="/assets/png/document (1).png" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}" />
                                  </a>
                              </div>

                                <span class="inner-p-tag-mb-0" *ngIf="item.content && item.content.type !== 'image' && item.content.type !== 'document'"  [innerHtml]="item.content | safeHtml"></span>
                            </div>
                            <div class="direct-chat-info clearfix set-left-date-time">
                                <span class="direct-chat-timestamp pull-right" *ngIf="integrationType=='freshdesk' && item.type && item.source">{{'settings.freshdesk.private_note' | transloco}}</span>
                                <span class="direct-chat-timestamp pull-right" *ngIf="integrationType=='freshdesk' && !item.type && item.source">{{'settings.freshdesk.public_note' | transloco}}</span>
                                <span class="direct-chat-timestamp pull-right" *ngIf="integrationType=='freshdesk' && !item.type && !item.source">{{'settings.freshdesk.reply' | transloco}}</span>
                                <span class="direct-chat-timestamp">{{item.createdAt | formatdate: 'dd MMM h:mm a' : globals.timezone}}</span>
                            </div>
                        </div>
                        <!-- Message to the right -->
                        <div class="direct-chat-msg right" *ngIf="item.is_sent">
                            <ngx-avatars *ngIf="item.fullName && integrationType !== 'whatsapp'" class="pull-right" [matTooltip]="item.fullName" [name]="item.fullName" size="40" textSizeRatio="2.5" borderColor="#E7F4FD" bgColor="#E7F4FD" fgColor="#0f0f0f">
                            </ngx-avatars>
                            <ngx-avatars *ngIf="integrationType === 'whatsapp'" class="pull-right" [matTooltip]="item.full_name" [name]="item.full_name" size="40" textSizeRatio="2.5" borderColor="#E7F4FD" bgColor="#E7F4FD" fgColor="#0f0f0f">
                            </ngx-avatars>
                            <div class="direct-chat-text" >
                                <!-- attachment for bouwapp -->
                                <div *ngIf="integrationType=='bouwapp'">
                                    <a class="d-cursor-hand" (click)='lightboxOpen(item)'>
                                        <img *ngIf="item.attachment" [src]="item.attachmentThumbnails.full" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                    </a>
                                </div>
                                <!-- attachment for freshdesk -->
                                <div *ngIf="integrationType=='freshdesk'">
                                    <div *ngFor="let doc of item.attachment">
                                        <div *ngIf="(doc.name.includes('.png') || doc.name.includes('.gif') || doc.name.includes('.jpeg')  || doc.name.includes('.jpg') || doc.name.includes('.pdf')) else attached_document">
                                            <a class="d-cursor-hand" (click)='onPDFView(doc)' *ngIf="doc.name.includes('.pdf') else other_attachments">
                                                <p class="image-attachment d-flex add-attachment-style" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}"><mat-icon class="mr-10">insert_drive_file</mat-icon><span class="a-self-center">{{doc.name | truncatie : 25 }}</span></p>
                                            </a>
                                            <ng-template #other_attachments>
                                                <a class="d-cursor-hand" (click)='lightboxOpen(doc)'>
                                                    <img [src]="doc.attachment_url" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                                </a>
                                            </ng-template>
                                        </div>
                                        <ng-template #attached_document>
                                            <a class="d-cursor-hand" [href]="doc.attachment_url">
                                                <p class="image-attachment d-flex add-attachment-style" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}"><mat-icon class="mr-10">insert_drive_file</mat-icon><span class="a-self-center">{{doc.name | truncatie : 25 }}</span></p>
                                            </a>
                                        </ng-template>
                                    </div>
                                </div>
                                <div *ngIf="integrationType=='whatsapp'">
                                  <a class="d-cursor-hand" (click)='lightboxOpen(item)'>
                                      <img style="width: 20%;" *ngIf="item.content.type === 'image'" src="/assets/png/image.png" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                      <img style="width: 20%;" *ngIf="item.content.type === 'document'"  src="/assets/png/document (1).png" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}"/>
                                  </a>
                              </div>

                                <span class="inner-p-tag-mb-0" *ngIf="item.content && item.content.type !== 'image' && item.content.type !== 'document'"  [innerHtml]="item.content | safeHtml"></span>
                            </div>
                            <div class="direct-chat-info clearfix">
                                <span class="direct-chat-timestamp" [ngClass]="!data.expand ? 'modal-expand-right' : 'ml-38'" *ngIf="integrationType=='freshdesk' && item.type && item.source">{{'settings.freshdesk.private_note' | transloco}}</span>
                                <span class="direct-chat-timestamp" [ngClass]="!data.expand ? 'modal-expand-right' : 'ml-38'" *ngIf="integrationType=='freshdesk' && !item.type && item.source">{{'settings.freshdesk.public_note' | transloco}}</span>
                                <span class="direct-chat-timestamp" [ngClass]="!data.expand ? 'modal-expand-right' : 'ml-38'" *ngIf="integrationType=='freshdesk' && !item.type && !item.source">{{'settings.freshdesk.reply' | transloco}}</span>
                                <span class="direct-chat-timestamp pull-right resever-time">{{item.createdAt | formatdate: 'dd MMM h:mm a' : globals.timezone}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty-state-notify-set" *ngIf="data.firstMessage.length==0 && !emptyState">
                    <div>{{'settings.bouwapp.chat_empty_state' | transloco}}</div>
                </div>
            </ng-container>
            <!-- /.direct-chat-msg -->

            <!-- Message to the right -->
            <div class="direct-chat-msg right" *ngFor="let item of senderMessages">
                <ngx-avatars *ngIf="integrationType=='bouwapp'" class="pull-right" [matTooltip]="item.projectUser.firstName+' '+item.projectUser.lastName" [name]="item.projectUser.firstName+' '+item.projectUser.lastName" size="40" textSizeRatio="2.5" borderColor="#E7F4FD" bgColor="#E7F4FD" fgColor="#0f0f0f">
                </ngx-avatars>
                <ngx-avatars *ngIf="integrationType=='freshdesk'" class="pull-right" [matTooltip]="item.fullName" [name]="item.fullName" size="40" textSizeRatio="2.5" borderColor="#E7F4FD" bgColor="#E7F4FD" fgColor="#0f0f0f">
                </ngx-avatars>
                <ngx-avatars *ngIf="integrationType=='whatsapp'" class="pull-right" [matTooltip]="item.full_name" [name]="item.full_name" size="40" textSizeRatio="2.5" borderColor="#E7F4FD" bgColor="#E7F4FD" fgColor="#0f0f0f">
                </ngx-avatars>
                <div class="direct-chat-text" [ngClass]="!data.expand ? 'modal-expand-right' : ''">
                    <!-- attachment for bouwapp -->
                    <div *ngIf="integrationType=='bouwapp'">
                        <a class="d-cursor-hand" (click)='lightboxOpen(item)'>
                            <img *ngIf="item.attachment" [src]="item.attachmentThumbnails.full" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                        </a>
                    </div>
                    <!-- attachment for freshdesk -->
                    <div *ngIf="integrationType=='freshdesk'">
                        <div *ngFor="let doc of item.attachment">
                            <div *ngIf="(doc.name.includes('.png') || doc.name.includes('.gif') || doc.name.includes('.jpeg')  || doc.name.includes('.jpg') || doc.name.includes('.pdf')) else attached_document">
                                <a class="d-cursor-hand" (click)='onPDFView(doc)' *ngIf="doc.name.includes('.pdf') else other_attachments">
                                    <p class="image-attachment d-flex add-attachment-style" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}"><mat-icon class="mr-10">insert_drive_file</mat-icon><span class="a-self-center">{{doc.name | truncatie : 25 }}</span></p>
                                </a>
                                <ng-template #other_attachments>
                                    <a class="d-cursor-hand" (click)='lightboxOpen(doc)'>
                                        <img [src]="doc.attachment_url" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                                    </a>
                                </ng-template>
                            </div>
                            <ng-template #attached_document>
                                <a class="d-cursor-hand" [href]="doc.attachment_url">
                                    <p class="image-attachment d-flex add-attachment-style" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}"><mat-icon class="mr-10">insert_drive_file</mat-icon><span class="a-self-center">{{doc.name | truncatie : 25 }}</span></p>
                                </a>
                            </ng-template>
                        </div>
                    </div>

                    <!-- attachment for bouwapp -->
                    <div *ngIf="integrationType=='whatsapp'">
                      <a class="d-cursor-hand" (click)='lightboxOpen(item)'>
                          <img *ngIf="item.attachment" [src]="item.attachmentThumbnails.full" class="image-attachment" [ngStyle]="{'margin-bottom': item.content ? '20px' : '2px'}">
                      </a>
                  </div>
                    <span class="" [innerHtml]="item.content | safeHtml"></span>
                </div>
                <div class="direct-chat-info clearfix">
                    <span class="direct-chat-timestamp" [ngClass]="!data.expand ? 'modal-expand-right' : 'ml-38'" *ngIf="integrationType=='freshdesk'">{{'settings.freshdesk.private_note' | transloco}}</span>
                    <span class="direct-chat-timestamp pull-right resever-time">{{item.createdAt | formatdate: 'dd MMM h:mm a' : globals.timezone}}</span>
                </div>
            </div>
            <!-- Custome Send -->
            <!-- <div class="direct-chat-msg right" *ngIf="loader">
                <img src="assets/png/integrations/waiting.gif" class="chat-gif-set">
            </div> -->
            <div class="direct-chat-msg float-right" *ngIf="closebutton && integrationType!=='freshdesk' && integrationType!=='whatsapp'">
                <button type="button" class="btn btn-outline-primary status-close" (click)='statusCloses()'>{{'settings.close_chat'| transloco | capitaliz}}</button>
            </div>
            <div *ngIf="chatdisable" class="close-buttonstatus">
                <span>{{'settings.chat_close' | transloco | capitaliz}}</span>
            </div>
            <!-- /.direct-chat-msg -->
        </div>
        <!--/.direct-chat-messages-->
    </div>
    <!-- /.box-body -->
    <!-- .box-footer-attachment-->
    <div class="box-footer-attachment">
        <div class="attachment-notifi" *ngIf="attachment">
            <div *ngIf="integrationType=='bouwapp'">
                <span class="display-attachment" [matTooltip]="files">
                    <mat-icon class="m-20-attachment d-cursor-hand">insert_drive_file</mat-icon>
                    {{files | truncatie : 25 }}
                    <mat-icon class="m-20-attachment ml-5 d-cursor-hand" (click)="removeFile()">delete</mat-icon>
                </span>
            </div>
            <div *ngIf="integrationType=='freshdesk'">
                <div class="row">
                    <ng-container *ngFor="let doc of files">
                            <div class="col-xs-6">
                                <span class="display-attachment" [matTooltip]="doc.name">
                                    <mat-icon class="m-20-attachment d-cursor-hand">insert_drive_file</mat-icon>
                                     <span class="">{{doc.name | truncatie : 20 }}</span>
                                     <mat-icon class="m-20-attachment ml-5 d-cursor-hand" (click)="removeFilePerIndex(doc.name)">delete</mat-icon>
                                </span>
                            </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- /.box-footer-attachment -->
    <!-- .box-footer-->
    <div class="box-footer">
        <form (submit)='submit()' class="w-100">
            <div class="input-group width-80">
                <textarea [rows]="numbersOfRows"[disabled]='chatdisable' #message name="message" [placeholder]="('settings.'+data.integrationType+'.placeholder') | transloco" class="form-control" [(ngModel)]="usermessage" (keyup)='onKey(usermessage)'
                    (keyup.enter)="onEnter(usermessage)"></textarea>
            </div>
        </form>
        <ng-container *ngIf="type!='smart_reporting'">
            <span class="input-group-btn display-footer-set-attachment" *ngIf="!attachment">
                <button [disabled]='chatdisable'  type="submit" (click)="attachmentUpload()" class="btn btn-secondary btn-flat"><mat-icon class="d-text-middle">attachment</mat-icon></button>
            </span>
        </ng-container>
        <span class="input-group-btn display-footer-set">
            <button [ngClass]="{'ontypetext': (swapsendButton || attachment)}" [disabled]='(chatdisable || (!swapsendButton && !attachment))' type="submit" (click)="submit()" class="btn btn-secondary btn-flat" ><mat-icon class="d-text-middle">send</mat-icon></button>
        </span>
    </div>
    <!-- /.box-footer-->
</div>
<!--/.direct-chat -->
