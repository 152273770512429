import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AbstractService} from "../common/abstract.service";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {ID} from '@datorama/akita';
import { PaginationParams } from '../common/pagination-params';

@Injectable({
  providedIn: 'root'
})
export class MatrixService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public getLabels() {
    return this.http.get('/json/matrixlabels.json', this.httpOptions);
  }

  @ServiceErrorHandler()
  getIssueInterest(stakeholdersId: any, issuesId: any, interestId: any) {
    const url = interestId === ''
      ? `/api/issuesinterestsstakeholders?stakeholders_id=${stakeholdersId}&issues_id=${issuesId}`
      : `/api/issuesinterestsstakeholders?stakeholders_id=${stakeholdersId}&interests_id=${interestId}`;

    return this.http.get(url, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getIssuesStakeholderMatrices(pagination: PaginationParams) {
    return this.http.get(`/api/issuesstakeholdersmatrices`, pagination.get());
  }

  @ServiceErrorHandler()
  public getIssuesStakeholderMatricesByIssue(issueId: number|ID) {
    return this.http.get(`/api/issuesstakeholdersmatrices/issues/${issueId}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getIssuesStakeholderMatricesByStakeholder(stakeholderId: number|ID) {
    return this.http.get(`/api/issuesstakeholdersmatrices/stakeholders/${stakeholderId}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public updateIssuesStakeholderMatrices(stakeholderId: number|ID, issuesId: number|ID, value: any) {
    return this.http.post(`/api/issuesstakeholdersmatrices`, {
      stakeholders_id: stakeholderId,
      issues_id: issuesId,
      ...value
    }, this.httpOptions);
  }

  @ServiceErrorHandler()
  storeMatrices(stakeholderCode, matrixObject) {
    return this.http.post(`/api/stakeholders/${stakeholderCode}/matrices`,matrixObject, this.httpOptions);
  }

  getLabel(ranges, currValue) {
    currValue = (currValue) ? currValue : 0;
    for (const item in ranges) {
      const range = ranges[item];
      if (currValue >= range.min && currValue < range.max) {
        return range;
      }
    }
  }

  getTypeLabel(ranges, x, y) {
    x = (x) ? x : 0;
    y = (y) ? y : 0;
    for (const item in ranges) {
     const range = ranges[item];
      if (x >= range.min_x && x <= range.max_x &&
        y >= range.min_y && y <= range.max_y) {
          if (range.id === 'inform' && x <= 20 && y <= 20){
            return {id: "reactive", name: "Reactief", min_x: 0, min_y: 0, max_x: 20, max_y: 20};
        }
        if (range.id === 'co-produce' && x >= 80 && y >= 80){
          return {id: "co-decide", name: "Meebeslissen", min_x: 80, min_y: 80, max_x: 100, max_y: 100}
        }

        return range;
      }
    }
    return ranges[0];
  }

}
