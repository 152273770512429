import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { translate } from '@jsverse/transloco';
import { makePressableContext, trackPressEvent } from "@objectiv/tracker-browser";
import { collect } from 'collect.js';
import { saveAs } from 'file-saver';
import moment from 'moment';
import posthog from 'posthog-js';
import { Subject, forkJoin, of } from 'rxjs';
import { debounceTime, finalize, takeLast, tap } from 'rxjs/operators';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { ReportResponse, ReportService } from 'src/app/core/service/api/report.service';
import { WithParams } from 'src/app/core/service/common/with-params';
import { dateIso } from 'src/app/core/util/dateIso';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { environment } from 'src/environments/environment';
import { ActionListingType } from '../../../../core/enum/action-listing-type';
import { Permission } from '../../../../core/enum/permission';
import { ActionsService } from '../../../../core/service/api/actions.service';
import { EmployeesService } from '../../../../core/service/api/employees.service';
import { LogsService } from '../../../../core/service/api/logs.service';
import { StakeholdersService } from '../../../../core/service/api/stakeholders.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { PaginatedResponse } from '../../../../core/service/common/paginated-response';
import { GenericComponent } from '../../../../core/util/abstract/generic-component';
import { deepCopy } from '../../../../core/util/deepCopy';
import { ngModelCopy } from '../../../../core/util/ngModelCopy';
import { showFeedbackRemoved, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { SidebarEmployees } from '../../../../pubsub/sidebarEmployees';
import { GenericMultiselectCheckboxComponent } from '../../other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { SidebarEmployeesComponent } from '../../sidebar-employees/sidebar-employees.component';
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';
import { ActionEditComponent } from '../tasks-edit/action-edit.component';
import { scrollToError } from 'src/app/core/util/form';
import { RequiredMsgModalComponent } from '../required-msg-modal/required-msg-modal.component';

export interface LogEditData {
  log: any;
  connectStakeholders?: boolean;
  stakehoderMandatory?: boolean,
  params?: any;
  options?: {
    droppedEmails: {
      name: string;
      address: string;
    }[]
  };
};

@Component({
  selector: 'app-log-edit',
  templateUrl: './log-edit.component.html',
  styleUrls: ['./log-edit.component.sass']
})
export class LogEditComponent extends GenericComponent implements OnInit {

  public ActionListingType = ActionListingType;

  public connectStakeholders: boolean = false;
  public stakeholderMandatory: boolean = true;
  public saving: boolean = false;
  public log: any;
  private originalLog: any;
  public upload: any;
  public series: any[];
  public contactpersons: any[];
  public stakeholders: any[];
  public residents: any[];
  public newEntityParams: any;
  private isNewLog: boolean = false;
  public sidebarEmployees: SidebarEmployees = new SidebarEmployees();
  name: string;
  // Entities that can be connected
  public openedTab: string = 'actions';
  public actions: any[];
  public issues: any[];
  public complaints: any[];
  public activities: any[];
  public decisions: any[];
  public users: any[];
  private searchActionTimeout: any;
  entities: any
  enterType = 10
  loaded: Boolean = false;
  public apiKey: string = environment.tinymce.apiKey;
  subprojectsSet: any[] = [];
  chipDisabler: any[] = [];
  private modelKeyUpSubject = new Subject<boolean>();
  private destroy$ = new Subject<void>();

  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;
  @ViewChild('form') public form: NgForm;


  constructor(public globals: Globals, private zone: NgZone, private _cachedService: CachedService,
    private _actionsService: ActionsService,
    private _reportsService: ReportService,
    private _employeesService: EmployeesService,
    private pubsub: PubsubService,
    private cdRef: ChangeDetectorRef,
    private _stakeholdersService: StakeholdersService, @Inject(MAT_DIALOG_DATA) public logData: LogEditData, private dialogRef: MatDialogRef<LogEditComponent>, private _logsService: LogsService,
    private dialog: MatDialog) {
    super(globals);
    dialogRef.backdropClick().subscribe(() => {
      if (this.form.invalid) {
        this.pubsub.setDisableSidebar(true);
        this.dialog.open(RequiredMsgModalComponent, {
          data: { title: translate("decisions.stakeholder_required"), body: translate("decisions.stakeholder_required_msg") },
          width: "30vw"
        });
      } else {
        this.pubsub.setDisableSidebar(false);
      }
    });
    this.modelKeyUpSubject
      .pipe(
        debounceTime(5000),
        tap(res => res === true ? this.updateLog(false, true) : ''),
        takeLast(1),
      )
      .subscribe();
  }

  ngOnInit() {
    this.subprojectSetup();
    if (this.logData["id"]) {
      this.logData.log = this.logData;
      this.logData.connectStakeholders = true;
    }
    this._cachedService.getUsers().subscribe(
      (users) => {
        this.users = users;
      }
    );
    posthog.capture('contact_moment-detail');
    this.isNewLog = !this.logData.log;
    if (!this.isNewLog) {
      this.name = this.logData.log.title ? this.logData.log.title : 'Contactmoment'
      this._logsService.getLog(this.logData.log.id, new WithParams().with(
        ['series', 'stakeholders', 'users', 'contactpersons', 'tasks', 'issues',
          'complaints', 'activities', 'requirements', 'documents', 'permits', 'subprojects', 'tasks.contactpersonsContactmoments.pivot.contactmoments']
      )).subscribe((log) => {

        this.logData.log = log;
        this.logData.log.contactpersons = this.logData.log.contactpersons.map(employee => {
          employee.selected = employee.stakeholders.filter(x => this.logData.log.stakeholders.find(s => s.id == x.id));
          return employee;
        });
        this.toLocalDate(this.logData.log)
        this.setData();
      })
    }
    else {
      this.setData();
    }
  }




  setData() {
    this.log = this.logData.log || {
      users_id: this.globals.user.configs.users_id,
      title: '',
      description: '',
      contact_moment_timestamp: new Date(),
      contact_moment_type: 'email',
      series: null,
      issues: [],
      complaints: [],
      actions: [],
      stakeholders: [],
      contactpersons: [],
      activities: [],
      decisions: [],
      documents: [],
      permits: [],
      residents: [],
    };
    if (!this.log.title) {
      if (this.log.series.length == 0) {
        this.log.title = 'Contactmoment zonder titel';
      } else {
        this.log.title = this.log.series[0].name;
      }
    }
    if (!this.log.contact_moment_type) {
      this.log.contact_moment_type = 'email';
    }

    this.newEntityParams = {
      users: [this.globals.user],
    };

    if (this.log.id) {
      this.newEntityParams.logs = [{
        id: this.log.id
      }];
    }

    //Extra parameters for adding logs in detail view
    if (this.isNewLog && this.logData.params) {
      Object.keys(this.logData.params).forEach((key: string) => this.log[key] = this.logData.params[key]);
    }

    if (this.isNewLog && this.logData.options && this.logData.options.droppedEmails && this.logData.options.droppedEmails.length > 0) {
      const addresses: string[] = this.logData.options.droppedEmails
        .map(data => data.address);

      let foundEmployeeEmails: any[] = [];
      forkJoin([this._stakeholdersService.findStakeholdersByEmployees(addresses),
      this._employeesService.findEmployees(addresses)])
        .subscribe((response: [PaginatedResponse, PaginatedResponse]) => {
          this.log.stakeholders = [...this.log.stakeholders, ...response[0].data];
          this.log.contactpersons = [...this.log.contactpersons, ...response[1].data];
          this.originalLog.stakeholders = [...this.log.stakeholders, ...response[0].data];
          foundEmployeeEmails = response[0].data
            .map(stakeholder => stakeholder.contactpersons)
            .reduce((accumulator, employee) => accumulator.concat(employee), [])
            .map(employee => employee.email ? employee.email.toLowerCase() : null);
          foundEmployeeEmails = foundEmployeeEmails.concat(response[1].data
            .map(employee => employee.email ? employee.email.toLowerCase() : null));

          this.logData.options.droppedEmails.forEach(email => {
            if (foundEmployeeEmails.filter(e => e === email.address).length === 0) { // Check if email is not already registered
              this.sidebarEmployees.emails.unregistered.push(email);
            }
          });
        });
    }
    forkJoin([
      this._cachedService.getActionsRelations(),
      this._cachedService.getIssuesRelations(),
      this._cachedService.getDecisionsRelations(),
      this._cachedService.getComplaintsRelations(),
      this._cachedService.getActivities(),
      this._cachedService.getDocumentsRelations(),
       // Check if 'permit' exists in the modules, otherwise skip the API call
       this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
       ? this._cachedService.getPermitsRelations() 
       : of([])

    ]
    ).subscribe(([tasks, issues, requirements, complaints, activities, documents, permits]) => {
      this.entities = { tasks, issues, requirements, complaints, activities, documents, permits }
      this.loaded = true;
      this.setLodedSubproject();
    });

    this.log.tasks = this.log.tasks.map(action => this.parseActions(action));

    // Store original log for restoring value
    this.originalLog = deepCopy(this.log);

    if (this.logData && this.logData.connectStakeholders) {
      this.connectStakeholders = this.logData.connectStakeholders;
      this.stakeholderMandatory = this.logData.stakehoderMandatory;

      this._cachedService.getStakeholdersRelations().subscribe(
        stakeholders => {
          this.stakeholders = stakeholders;
          this.sidebarEmployees.stakeholders = stakeholders;
        }
      );
      this._cachedService.getEmployees().subscribe(
        contactpersons => this.contactpersons = contactpersons
      );
    }

    if (!this.globals.projectConfigs['residents.disabled']) {
      this._cachedService.getResidents().subscribe(
        residents => this.residents = residents
      );
    }

    this._cachedService.getSeries().subscribe(
      series => this.series = series
    );

    this.loadActions();
  }

  public changeType(change: MatButtonToggleChange) {
    if (change.value !== 'meeting') {
      this.log.series = null;
    }
    this.updateLog()
  }

  public employeeSelected(): void {
    this.log.contactpersons = this.log.contactpersons.map(employee => {
      employee.selected = employee.stakeholders.filter(x => this.logData.log.stakeholders.find(s => s.id == x.id));
      return employee;
    });
    if (!this.log.contactpersons || this.log.contactpersons.length === 0) {
      return;
    }

    this.log.contactpersons.forEach((e: any) => {
      if (!e.stakeholders || e.stakeholders.length != 1) {
        return;
      }

      if (!this.log.stakeholders || this.log.stakeholders.length === 0) {
        this.log.stakeholders.push(e.stakeholders[0]);
        this.stakeholderMultiselect.writeValue(this.log.stakeholders);
        this.stakeholderMultiselect.onValidatorChange();
        return;
      }

      const found = this.log.stakeholders.find(s => s.id === e.stakeholders[0].id);
      if (!found) {
        this.log.stakeholders.push(e.stakeholders[0]);
        this.stakeholderMultiselect.writeValue(this.log.stakeholders);
        this.stakeholderMultiselect.onValidatorChange();
      }
    });

    this.updateLog()

  }

  updateStakeholders() {
    setTimeout(() => {
      this.updateLog()
    }, 500);
  }

  onStakeholdersUpdated(event) {
    if (event.action == "add") {
      this.log.stakeholders.push(event.data);
      this.stakeholderMultiselect.writeValue(this.log.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    else {
      this.log.stakeholders = this.log.stakeholders.filter(s => s.id != event.data.id);
      this.stakeholderMultiselect.writeValue(this.log.stakeholders);
      this.stakeholderMultiselect.onValidatorChange();
    }
    this.updateLog()
  }

  contactpersonsUpdated(log) {
    this.log.contactpersons = log.contactpersons ? log.contactpersons : [];
    this.log.contactpersons.forEach(selected => {
      const person = collect(this.contactpersons).contains((item) => item.id === selected.id);
      if (!person) { this.contactpersons.push(selected) }
    })
  }

  public handleAction(action: any): void {
    this._actionsService.updateActionById(action.id, action).subscribe(() => {
      if (action.handled) {
        showFeedbackSaved('Actie afgehandeld en gearchiveerd');
      } else {
        showFeedbackSaved('Actie is teruggezet');
      }
    });
  }

  public close(deleted: boolean = false): void {
    if (this.form.valid) {
      // Revert values
      Object.keys(this.originalLog).forEach((key: string) => {
        this.log[key] = this.originalLog[key];
      });
      this.pubsub.closeModal(this.dialogRef, {
        log: this.originalLog,
        updated: false,
        deleted: deleted
      });
      this.pubsub.resetHistory();
    } else {
      scrollToError(this.form);
    }
  }
  @Allowed(Permission.LogsUpdate)
  public updateLog(closeModal: boolean = false, action: boolean = false): void {
    this.modelKeyUpSubject.next(false);
    if (this.globals.permissions.includes(this.Permission.LogsUpdate)) {
      // if (this.form.invalid) {
      //   const dialog = this.dialog.open(RequiredMsgModalComponent, {
      //     data: { title: translate("decisions.stakeholder_required"), body: translate("decisions.stakeholder_required_msg") },
      //     width: "30vw"
      //   });
      //   dialog.afterClosed().subscribe();
      //   scrollToError(this.form);
      //   return;
      // }
      if (this.form.invalid) {

        scrollToError(this.form);
        return;
      }

      this.saving = true;

      // TODO: refactor this
      const params = deepCopy(this.log);

      if (!this.isNewLog) {
        params.contact_moment_timestamp = new Date(moment(dateIso(params.contact_moment_timestamp, true, this.globals.timezone)).format('YYYY-MM-DD'))
        this._logsService.updateLog(params.id, params)
          .pipe(finalize(() => this.saving = false))
          .subscribe(
            (log: any) => {
              if (action) {
                this.logsObjectiveEditor(log);
              }
              showFeedbackSaved();
              this.toLocalDate(log)
              this.contactpersonsUpdated(log);
              this.log.contact_moment_timestamp = log.contact_moment_timestamp;
              this.updateSeries(log);
              if (closeModal) {
                this.dialogRef.close({
                  log: this.log,
                  updated: true,
                });
              }
            }
          );
      } else {
        params.contact_moment_timestamp = new Date(moment(dateIso(params.contact_moment_timestamp, true, this.globals.timezone)).format('YYYY-MM-DD'))
        this._logsService.submitLog(params)
          .pipe(finalize(() => this.saving = false))
          .subscribe(
            (log: any) => {
              if (action) {
                this.logsObjectiveEditor(log);
              }
              showFeedbackSaved();
              ngModelCopy(this.log, log);
              if (closeModal) {
                this.dialogRef.close({
                  log: this.log,
                  updated: true,
                });
              }
            }
          );
      }
    }
  }

  public updateSeries(log: any) {
    // this._cachedService.getSeries().subscribe(series =>{
    //   this.log.series = log.series;
    //   this.series = [...series];
    //   this.cdRef.detectChanges();
    // });
  }

  public editAction(action: any): void {
    const editModal = this.dialog.open(ActionEditComponent, {
      width: '60vw',
      data: action
    });

    editModal.afterClosed().subscribe((updatedAction: any) => {
      ngModelCopy(action, updatedAction);
      let originalAction = this.originalLog.actions.find(a => a.id == action.id);
      if (originalAction) {
        originalAction = deepCopy(updatedAction);
      }
    })
  }


  public removeConnection(entityName: string, entity: any): void {
    let entityMessage: string;
    switch (entityName) {
      case 'actions':
        entityMessage = 'de actie';
        break;
      case 'issues':
        entityMessage = 'het issue';
        break;
      case 'complaints':
        entityMessage = 'het melding';
        break;
      case 'decisions':
        entityMessage = 'het klanteis';
        break;
    }

    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: `Weet u het zeker dat u ${entityMessage} wilt verwijderen?`
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.log[entityName] = this.log[entityName].filter((e: any) => e.id != entity.id);
        this.originalLog[entityName] = this.originalLog[entityName].filter((e: any) => e.id != entity.id);

        if (!this.isNewLog) {
          this.updateLog(false);
        }
      }
    });
  }

  // New entity triggers
  public onNewActionClosed(newAction: any): void {
    if (newAction) {
      const action = this.parseActions(newAction);
      this.log.tasks.unshift(action);
      this.originalLog.actions.unshift(action);
      this.actions.unshift(newAction);
    }
  }

  public onNewIssueClosed(newIssue: any): void {
    if (newIssue) {
      this.log.issues.unshift(newIssue);
      this.originalLog.issues.unshift(newIssue);
      this.issues.unshift(newIssue);
    }
  }

  public onNewComplaintClosed(newComplaint: any): void {
    if (newComplaint) {
      this.log.complaints.unshift(newComplaint);
      this.originalLog.complaints.unshift(newComplaint);
      this.complaints.unshift(newComplaint);
    }
  }

  public onNewActivityClosed(newActivity: any): void {
    if (newActivity) {
      this.log.activities.unshift(newActivity);
      this.originalLog.activities.unshift(newActivity);
      this.activities.unshift(newActivity);
    }
  }

  public onNewDecisionClosed(newDecision: any): void {
    if (newDecision) {
      this.log.requirements.unshift(newDecision);
      this.originalLog.decisions.unshift(newDecision);
      this.decisions.unshift(newDecision);
    }
  }

  public onDocumentSidebarClosed(): void {
    this.originalLog.documents = deepCopy(this.log.documents);
  }

  public onDocumentRemoved(documentId: number): void {
    if (documentId) {
      this.log.documents = this.log.documents.filter(document => document.id !== documentId);
      this.originalLog.documents = this.originalLog.documents.filter(document => document.id !== documentId);
    }
  }

  public showEmployeeSidebar(): void {
    const modal = this.dialog.open(SidebarEmployeesComponent, {
      data: this.sidebarEmployees,
      width: '303px',
      height: '100vh',
      maxHeight: '100vh',
      position: {
        right: '0px'
      }
    });

    modal.afterClosed().subscribe((response: { sidebar: SidebarEmployees, stakeholders: any[], contactpersons: any[] }) => {
      if (response) {
        this.contactpersons = this.contactpersons.concat(response.contactpersons);
        this.stakeholders = this.stakeholders.concat(response.stakeholders);
        this.log.stakeholders = this.log.stakeholders.concat(response.stakeholders);
        this.log.contactpersons = this.log.contactpersons.concat(response.contactpersons);
      }
    });
  }

  public fetchActionsPerSeries(): void {
    clearTimeout(this.searchActionTimeout);
    this.searchActionTimeout = setTimeout(() => {
      if (!this.log.series) {
        this.log.tasks = this.log.tasks.filter(action => !action.inherited);
        return;
      }
      const seriesName = this.log.series.length == 0 ? this.log.series.name : this.log.series[0].name;
      this._logsService.getConnectionsPerSeries(seriesName).subscribe(
        (connections: {
          tasks: any[], stakeholders: any[], contactpersons: any[],
        }) => {
          if (this.log.tasks && this.log.tasks.length > 0) {
            this.log.tasks = this.log.tasks.filter(action => !action.inherited);
          }

          if (this.log.stakeholders && this.log.stakeholders.length > 0) {
            this.log.stakeholders = this.log.stakeholders.filter(stakeholder => !stakeholder.inherited);
          }

          if (this.log.contactpersons && this.log.contactpersons.length > 0) {
            this.log.contactpersons = this.log.contactpersons.filter(employee => !employee.inherited);
          }

          connections.tasks.forEach(action => {
            action.inherited = true;
            if (!this.log.tasks || this.log.tasks.length === 0 || !this.log.tasks.some(a => action.id === a.id)) {
              this.log.tasks.push(action);
            }
          });

          const stakeholders: any[] = [];
          connections.stakeholders.forEach(stakeholder => {
            stakeholder.inherited = true;
            if (!this.log.stakeholders || this.log.stakeholders.length === 0 || !this.log.stakeholders.some(s => stakeholder.id === s.id)) {
              stakeholders.push(stakeholder);
            }
          });
          this.log.stakeholders = this.log.stakeholders.concat(stakeholders);

          const contactpersons: any[] = [];
          connections.contactpersons.forEach(employee => {
            employee.inherited = true;
            if (!this.log.contactpersons || this.log.contactpersons.length === 0 || !this.log.contactpersons.some(e => employee.id === e.id)) {
              contactpersons.push(employee);
            }
          });
          this.log.contactpersons = this.log.contactpersons.concat(contactpersons);
          this.updateLog();
        }
      );
    }, 500);
  }

  private parseActions(action): any {
    action.late = false;
    if (moment(action.timestamp).isBefore(new Date(), 'day')) {
      action.late = true;
    }

    return action;
  }

  // Load entities
  public loadEntity(event: MatTabChangeEvent): void {
    this.openedTab = event.tab.textLabel;
    switch (this.openedTab) {
      default:
      case 'actions':
        this.loadActions();
        break;
      case 'issues':
        this._cachedService.getIssues().subscribe(issues => this.issues = issues);
        break;
      case 'complaints':
        this._cachedService.getComplaints().subscribe(complaints => this.complaints = complaints);
        break;
      case 'activities':
        this._cachedService.getActivities().subscribe(activities => this.activities = activities);
        break;
      case 'decisions':
        this._cachedService.getDecisions().subscribe(decisions => this.decisions = decisions);
        break;
    }
  }

  updateEntities(event: any) {
    this.log[event.type] = event.selected;
    this.updateLog();
  }

  public deleteLog(log): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: translate('text.delete.log')
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._logsService.removeLog(log.id).subscribe(
          () => {
            showFeedbackRemoved();
            this.close(true);
          });
      }
    });
  }

  private loadActions(): void {
    this._cachedService.getActions().subscribe(actions => this.actions = actions);
  }

  private toLocalDate(log: any) {
    if (log.contact_moment_timestamp) {
      log.contact_moment_timestamp = dateIso(log.contact_moment_timestamp, true, this.globals.timezone);
    }

    return log;
  }

  public downloadLog(logId: number): void {
    this._reportsService.download('log', 'word', {
      id: logId
    }).subscribe(
      (report: ReportResponse) => {
        saveAs(report.body, report.filename);
      });
  }
  updateSubprojects() {
    this.pubsub.updateSubprojects(this, "updateLog", this.log.subprojects)
  }

  public subprojectSetup() {
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects, 'logs:update')
  }
  public setLodedSubproject() {
    this.chipDisabler = [];
    this.chipDisabler = selectedSubprojectSaprator(this.subprojectsSet, this.log.subprojects)
  }
  public logsObjectiveEditor(event) {
    const button = event;
    const locationFragment = [
      makePressableContext({ id: 'notes' })
    ];
    trackPressEvent({
      element: button,
      locationStack: [...locationFragment]
    });
  }
  public onSetStable(type: any) {
    // Emit the value to the subject whenever the model changes
    this.modelKeyUpSubject.next(true);
  }

}
