<div class="row popup-container" *transloco="let t">
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
      <h1>
        <strong>{{ t("admin_page.integration") | titlecase }} </strong
        ><span class="">{{ data }}</span>
      </h1>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon> </span
        ><span class="sr-only">Close</span>
      </button>
    </div>
  </div>
  <div class="popup-body" appScroll>
    <div class="col-xs-12 mb-5">
      <h5>{{ t("settings.connection") | titlecase }}</h5>
      <div class="set-border-connection">
        <span class="align-set" *ngIf="!this.globals.projectConfigs['services.integration.whatsapp.enabled'] && !errorMessage">{{
          t("settings.whatsapp.whatsapp_integration") | capitaliz
        }}</span>
        <div class="align-set display-flex" *ngIf="errorMessage">
          <mat-icon class="d-text-middle">error</mat-icon>
          <span class="error-message align-set">
            {{ t("error.whatsapp_integration") | capitaliz }}</span
          >
        </div>
        <div
          class="align-set display-flex"
          *ngIf="this.globals.projectConfigs['services.integration.whatsapp.enabled'] && !errorMessage"
        >
          <mat-icon class="successfully-message d-text-middle"
            >check_circle</mat-icon
          >
          <span class="align-set">
            {{ t("success.whatsapp_integration") | capitaliz }}</span
          >
        </div>
        <button
          *ngIf="this.globals.projectConfigs['services.integration.whatsapp.enabled']"
          class="btn btn-primary color-btn-primary-mapa align-set"
          type="button"
          (click)="verifyDataConfigs('disable')"
        >
          <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
          {{ t("settings.disable") | titlecase }}
        </button>
        <button
          *ngIf="!this.globals.projectConfigs['services.integration.whatsapp.enabled']"
          class="btn btn-primary color-btn-primary-map align-set"
          type="button"
          (click)="loginWithFacebook()"
        >
          <mat-icon class="d-text-middle icon-connect">add_circle</mat-icon>
          {{ t("settings.connect_me") | titlecase }}
        </button>
      </div>
    </div>

    <div class="col-xs-12 mb-5" *ngIf="this.globals.projectConfigs['services.integration.whatsapp.enabled'] && programModule">
      <h5>{{ t("settings.whatsapp.whatsapp_info") }}</h5>

      <div class="full-width set-border-connection-relatic mb-10">
        <div class="row">
          <div class="col-md-12 display-grid">
            <div class="subtext">
              {{ t("settings.whatsapp.whatsapp_info_subtext") }}
            </div>
            <div class="row display-flex-reposive mt-10">
              <div class="col-md-6 col-sm-6 col-xs-6 display-grid align-set">
                <mat-form-field class="full-width">
                  <mat-select
                    [(ngModel)]="selectedNumbers"
                    (ngModelChange)="selectNumber($event)"
                    [placeholder]="'settings.whatsapp.telephone' | transloco"
                  >
                    <mat-option
                      *ngFor="let user of usersNumbers"

                      [value]="user"
                    >
                      {{ user.display_phone_number }}
                      <span class="icon-container" [ngClass]="{ 'verified': user.code_verification_status === 'VERIFIED', 'not-verified': user.code_verification_status !== 'VERIFIED' }">
                        {{user.code_verification_status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED'}}
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-sm-10 col-xs-10 display-grid align-set">
                <mat-form-field class="full-width">
                  <mat-select
                    [(ngModel)]="selectedSubprojects"
                    (ngModelChange)="updateSubprojects($event)"
                    [placeholder]="'settings.whatsapp.subproject' | transloco"
                    [disabled]="enableSubProjects"
                  >
                    <mat-option
                      *ngFor="let subproject of whatsAppProjects"
                      [value]="subproject"
                    >
                      {{ subproject.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
