
<button class="close modal-header" type="button" mat-dialog-close>
    <span style="font-size: 35px">×</span>
    <span class="sr-only">Close</span>
</button>
<h3 class="modal-title">{{ 'batch_dialog_items.title' | transloco | capitaliz }}</h3>

<form #form="ngForm" (submit)="submit()" *transloco="let t">
    <p style="font-weight: bold;">{{ 'batch_dialog_items.select_dialog_item' | transloco | capitaliz }}</p>
    <mat-dialog-content>
        <mat-form-field class="full-width">
            <mat-select [placeholder]="'batch_dialog_items.dialog_item_type' | transloco" [(value)]="selectedItemType" (selectionChange)="updateSelectedItem()" required>
                <ng-container *ngFor="let report of reports">
                    <mat-option [value]="report.type">
                        {{ (report.type + '.labels' | transloco) | capitaliz }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        

        <app-generic-multiselect-checkbox *ngIf="selectedItemType && !isLoadingLayers" [multiple]="true" #stakeholderMultiselect [placeholder]="'batch_dialog_items.search_dialog_item' | transloco" name="stakeholders" [(ngModel)]="data.dialogItems" [name]="dialogItems" [required]="stakeholderRequired" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'">
            <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let item of dialogItems; trackBy: trackByFn" [value]="item">
                {{ item.name? item.name : item.title? item.title : item.text? item.text : '' }}
            </app-generic-multiselect-checkbox-option>
            <app-generic-multiselect-checkbox-option-new-action [type]="selectedItemType">
            </app-generic-multiselect-checkbox-option-new-action>
        </app-generic-multiselect-checkbox>
        <mat-progress-bar *ngIf="selectedItemType && isLoadingLayers" mode="buffer"></mat-progress-bar>

        <div>
            <p style="font-weight: bold;">{{ 'batch_dialog_items.connecting_items' | transloco | capitaliz }}</p>
            <div *ngFor="let item of data.layers">
                <p style="margin-bottom: 3px; color: #2800D2;">{{item.title}}</p>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class="pull-right">
      <button *ngIf="!isProcessing" mat-dialog-close mat-stroked-button>{{ 'cancel' | transloco | capitaliz }}</button>
      <button [disabled]="isProcessing || form.invalid || !selectedItemType || (data.dialogItems && data.dialogItems.length < 1)" color="primary" mat-flat-button style="margin-left: 13px" type="submit">
        <ng-container *ngIf="!isProcessing">
          {{ 'batch_dialog_items.attach' | transloco }}
        </ng-container>
        <ng-container *ngIf="isProcessing">
          {{ 'batch_dialog_items.attach' | transloco }}...
        </ng-container>
      </button>
    </mat-dialog-actions>
</form>
