export const environment = {
  //TODO: modify this when build is done on server (or there's other way to deploy)
  production: false,
  bugsnagKey: '4686590e4a2c0ff1160ab7711b1fcc55',
  env: document.location.hostname === 'app.getdialog.nl' ? 'Production' : (document.location.hostname === 'getdialog.test' || document.location.hostname === 'localhost' ? 'Local' : 'Staging'),
  objective_domain: document.location.hostname === 'app.getdialog.nl' ? 'https://collector.getdialog.nl' : (document.location.hostname === 'getdialog.test' || document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' ? 'https://collector.getdialog.dev' : 'https://collector.ipcondialog.nl'),
  posthogKey: document.location.hostname === 'app.getdialog.nl' ? 'phc_MWNB8JWKuTjCpUlgs2yjDkDyuFDNCwhIJ6vmh5ZUPSZ' : (document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test' || document.location.hostname === 'getdialog.local' || document.location.hostname === 'localhost' ? 'phc_8pHY5yFEUJtGhH7E498ngkIZ4S2dRzFnMDJ9edjMY8m' : 'phc_qXuf5fYjMFxod4BOwIJWqzRJz2cfpm70lGViQw72zDu'),
  auth0: {
    clientId: (document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.local' || document.location.hostname === 'getdialog.test') ? 'b8EkonhSkfg72z2RAq2rNRLxM2jRoAsc' : 'A7detWmjVPybNFlf093dyOoPECg0SvHT',
    domain: (document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.local' || document.location.hostname === 'getdialog.test') ? 'getdialog-dev.eu.auth0.com' : 'login.getdialog.nl',
    redirectURI: 'authenticated',
    connection: document.location.hostname === 'app.getdialog.nl' ? 'Production' : (document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test' || document.location.hostname === 'getdialog.local' || document.location.hostname === 'localhost' ? 'Development' : 'Staging'),
    audience: (document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.local' || document.location.hostname === 'getdialog.test') ? 'login.getdialog.dev' : 'https://login.getdialog.nl',
  },
  tinymce: {
    apiKey: document.location.hostname === 'app.getdialog.nl' ? '8d14jeos9mw5wi3skttmyefesztohc8r4yuzp1zr7b3c47u2' : 'k414vqthpp6efatu0s0nikgde9mqj3sl99wz8qu8ywm24wye',
  },
  auth_public_facing:{
    domain: (document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test') ? 'getdialog-dev.eu.auth0.com' : 'getdialog.eu.auth0.com',
    client_id: (document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test') ? 'Sfs0Kpp00YHgrTsLjMwyEaZOFbFzvCfu' : '2mgw1K2qYVZTSvcpQw4sulKJk8edZgrH',
    redirect_uri: 'publicfacing',
    connection: document.location.hostname === 'app.getdialog.nl' ? 'Public-facing-PROD' : (document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test' || document.location.hostname === 'localhost' ? 'Public-Facing' : 'Public-facing-ACC'),
    audience: (document.location.hostname === 'localhost' || document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test') ? 'login.getdialog.dev' : 'https://app.getdialog.nl/api',
  },
  pusher: {
    key: document.location.hostname === 'app.getdialog.nl' ? '780e90e897308ffaa4ba' : (document.location.hostname === 'app.getdialog.dev' || document.location.hostname === 'getdialog.test' || document.location.hostname === 'localhost' ? '8f18e7f9f24b8d9b8656' : '4c3e47616a3a3741df85'),
    cluster: 'eu',
  },
  APP_ID: '781812423810505',
};
