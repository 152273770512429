<div *transloco="let t">

  <div>
    <h4>{{ 'text.cant_delete_user' | transloco }}</h4>
  </div>
  <div class="popup-body">
    {{ 'text.cant_delete_user_message' | transloco }}
  </div>
  
  <div class="popup-footer">
    <div class="dialog-button-row">
      <button type="button" class="btn mdl-button mat-flat-button mdl-js-button mdl-button--raised" (click)="submit(false)">{{'confirm' | transloco | capitaliz}}</button>
      
    </div>  
  </div>
  
</div>