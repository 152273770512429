<div class="row popup-container" *transloco="let t">
    <div class="popup-header row no-margin ">
        <div class="col-xs-12 mb-5">
            <h1><strong>{{ t('admin_page.integration') | titlecase}} </strong><span class="">{{data}}</span></h1>
            <button type="button" class="close " (click)="close()"><span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span><span class="sr-only">Close</span></button>
        </div>
    </div>
    <mat-progress-bar *ngIf="!isProcess" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="isProcess">
        <div class="col-xs-12 mb-5">
            <h5> {{ t('settings.connection') | titlecase}}</h5>
            <div class="full-width set-border-connection-relatic mb-10">
                <div class="row mt-10">
                    <div class="col-md-12 col-sm-12 col-xs-12 display-flex">
                        <mat-form-field>
                            <input type="text" matInput class="" name="Environment" [(ngModel)]="relatic.workspace" [placeholder]="('settings.relatic.environment') | transloco" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" />
                        </mat-form-field>
                        <span class="ml-10 align-set">.relaticsonline.com</span>
                        <button *ngIf="relaticsenable && !errorMessage" class="connection-button-position-set btn btn-primary color-btn-primary-mapa align-set" type="button" (click)='updateConfigs()'>
                                <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
                                {{ t('settings.disable') | titlecase}}
                        </button>
                        <button [disabled]="level == 'company' ? false :  !hasPermission(Permission.ProjectIntegrationsCreate)" *ngIf="relaticsenable && errorMessage" class="connection-button-position-set btn btn-primary color-btn-primary-map-error align-set" (click)='updateConfigs()' type="button">
                            <mat-icon class="d-text-middle icon-connect">error</mat-icon>{{ t('settings.connect_error') | titlecase}}
                        </button>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 display-grid w-100">
                        <mat-form-field>
                            <input type="text" matInput class="full-width" name="Workspace" [(ngModel)]="relatic.workspaceid" [placeholder]="('settings.relatic.workspace') | transloco" required [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- Export Element  -->

        <div class="col-xs-12 mb-5">
            <h5> {{ t('settings.relatic.share_info') }}</h5>
            <div class="subtext">{{ t('settings.relatic.share_info_subtext')}}</div>
            <div class="full-width set-border-connection-relatic mb-10">
                <div class="row">
                    <div class="col-md-12 display-grid">
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('stakeholders.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.stakeholders.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                    />
                                </mat-form-field>
                                <div *ngIf="dialogKey.stakeholders.haserror">
                                    <p class="error-message error-message-set" *ngIf="dialogKey.stakeholders.error" [matTooltip]="errorHendle(dialogKey.stakeholders.error)">{{errorHendle(dialogKey.stakeholders.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.stakeholders.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required
                                    />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.stakeholders.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.stakeholders.haserror && dialogKey.stakeholders.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('issues.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.issues.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                    />
                                </mat-form-field>
                                <div *ngIf="dialogKey.issues.haserror">
                                    <p class="error-message error-message-set" *ngIf="dialogKey.issues.error" [matTooltip]="errorHendle(dialogKey.issues.error)">{{errorHendle(dialogKey.issues.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.issues.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.issues.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.issues.haserror && dialogKey.issues.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('decisions.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.requirements.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                    />
                                </mat-form-field>
                                <div *ngIf="dialogKey.requirements.haserror">
                                    <p class="error-message error-message-set" *ngIf="dialogKey.requirements.error" [matTooltip]="errorHendle(dialogKey.requirements.error)">{{errorHendle(dialogKey.requirements.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.requirements.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.requirements.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.requirements.haserror  && dialogKey.requirements.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div *ngIf="hideCommunication">
                            <div class="row display-flex-reposive mt-10">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                    <span>{{ t('activities.labels') | titlecase}}</span>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.activities.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                        />
                                    </mat-form-field>
                                    <div *ngIf="dialogKey.activities.haserror">
                                        <p class="error-message error-message-set" *ngIf="dialogKey.activities.error" [matTooltip]="errorHendle(dialogKey.activities.error)">{{errorHendle(dialogKey.activities.error)}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                    <mat-form-field>
                                        <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.activities.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required
                                        />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.activities.haserror">
                                    <mat-icon class="error-message">block</mat-icon>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.activities.haserror  && dialogKey.activities.validation">
                                    <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                        <div *ngIf="hideCommunication">
                            <div class="row display-flex-reposive mt-10">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                    <span>{{ t('complaints.labels') | titlecase}}</span>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.complaints.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                        />
                                    </mat-form-field>
                                    <div *ngIf="dialogKey.complaints.haserror">
                                        <p class="error-message error-message-set" *ngIf="dialogKey.complaints.error" [matTooltip]="errorHendle(dialogKey.complaints.error)">{{errorHendle(dialogKey.complaints.error)}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                    <mat-form-field>
                                        <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.complaints.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required
                                        />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.complaints.haserror">
                                    <mat-icon class="error-message">block</mat-icon>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.complaints.haserror && dialogKey.complaints.validation">
                                    <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('actions.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.tasks.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                    />
                                </mat-form-field>
                                <div *ngIf="dialogKey.tasks.haserror">
                                    <p class="error-message error-message-set" *ngIf="dialogKey.tasks.error" [matTooltip]="errorHendle(dialogKey.tasks.error)">{{errorHendle(dialogKey.tasks.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.tasks.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.tasks.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.tasks.haserror && dialogKey.tasks.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('employees.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim type="text" [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.contactpersons.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                    />
                                </mat-form-field>
                                <div *ngIf="dialogKey.contactpersons.haserror">
                                    <p class="error-message error-message-set" *ngIf="dialogKey.contactpersons.error" [matTooltip]="errorHendle(dialogKey.contactpersons.error)">{{errorHendle(dialogKey.contactpersons.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.contactpersons.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.contactpersons.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.contactpersons.haserror && dialogKey.contactpersons.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <div *ngIf="hideCommunication">
                            <mat-divider></mat-divider>
                            <div class="row display-flex-reposive mt-10">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                    <span>{{ t('middel.labels') | titlecase}}</span>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="dialogKey.contents.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                        />
                                    </mat-form-field>
                                    <div *ngIf="dialogKey.contents.haserror">
                                        <p class="error-message error-message-set" *ngIf="dialogKey.contents.error" [matTooltip]="errorHendle(dialogKey.contents.error)">{{errorHendle(dialogKey.contents.error)}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                    <mat-form-field>
                                        <input appTrim [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="dialogKey.contents.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="dialogKey.contents.haserror">
                                    <mat-icon class="error-message">block</mat-icon>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!dialogKey.contents.haserror  && dialogKey.contents.validation">
                                    <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Sync -->

        <div class="col-xs-12 mb-5" *ngIf="columnProcess">
            <h5> {{ t('settings.relatic.sync_elements')}}</h5>
            <div class="subtext"> {{ t('settings.relatic.sync_elements_subtext')}}</div>
            <div class="full-width set-border-connection-relatic mb-10">
                <div class="row">
                    <div class="col-md-12 display-grid">
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('stakeholders.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.stakeholders.operation" [placeholder]="('settings.relatic.operationname') | transloco" required
                                    />
                                </mat-form-field>
                                <div *ngIf="syncelement.stakeholders.haserror">
                                    <p class="error-message error-message-set" *ngIf="syncelement.stakeholders.error" [matTooltip]="errorHendle(syncelement.stakeholders.error)">{{errorHendle(syncelement.stakeholders.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.stakeholders.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.stakeholders.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.stakeholders.haserror && syncelement.stakeholders.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <div class="row" *ngFor="let item of syncelement.stakeholders.columns; let i=index">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.stakeholders.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                        required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.stakeholders">
                                <mat-form-field>
                                    <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                    <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.stakeholders.columns[i].dialogcolumn" required>
                                        <mat-option *ngFor="let column of tableColumns.stakeholders" [value]="column">
                                            {{column}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('stakeholders',i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('stakeholders')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('issues.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.issues.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                </mat-form-field>
                                <div *ngIf="syncelement.issues.haserror">
                                    <p class="error-message error-message-set" *ngIf="syncelement.issues.error" [matTooltip]="errorHendle(syncelement.issues.error)">{{errorHendle(syncelement.issues.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.issues.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.issues.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.issues.haserror && syncelement.issues.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <div class="row" *ngFor="let item of syncelement.issues.columns; let i=index">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.issues.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                        required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.issues">
                                <mat-form-field>
                                    <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                    <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.issues.columns[i].dialogcolumn">
                                        <mat-option *ngFor="let column of tableColumns.issues" [value]="column">
                                            {{column}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('issues',i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('issues')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('decisions.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.requirements.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                </mat-form-field>
                                <div *ngIf="syncelement.requirements.haserror">
                                    <p class="error-message error-message-set" *ngIf="syncelement.requirements.error" [matTooltip]="errorHendle(syncelement.requirements.error)">{{errorHendle(syncelement.requirements.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.requirements.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.requirements.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.requirements.haserror && syncelement.requirements.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <div class="row" *ngFor="let item of syncelement.requirements.columns; let i=index">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.requirements.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                        required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.requirements">
                                <mat-form-field>
                                    <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                    <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.requirements.columns[i].dialogcolumn">
                                        <mat-option *ngFor="let column of tableColumns.requirements" [value]="column">
                                            {{column}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('requirements',i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('requirements')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                        </div>
                        <mat-divider></mat-divider>
                        <div *ngIf="hideCommunication">
                            <div class="row display-flex-reposive mt-10">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                    <span>{{ t('activities.labels') | titlecase}}</span>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.activities.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                    </mat-form-field>
                                    <div *ngIf="syncelement.activities.haserror">
                                        <p class="error-message error-message-set" *ngIf="syncelement.activities.error" [matTooltip]="errorHendle(syncelement.activities.error)">{{errorHendle(syncelement.activities.error)}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.activities.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.activities.haserror">
                                    <mat-icon class="error-message">block</mat-icon>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.activities.haserror && syncelement.activities.validation">
                                    <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                                </div>
                            </div>
                            <div class="row" *ngFor="let item of syncelement.activities.columns; let i=index">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.activities.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                            required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.activities">
                                    <mat-form-field>
                                        <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                        <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.activities.columns[i].dialogcolumn">
                                            <mat-option *ngFor="let column of tableColumns.activities" [value]="column">
                                                {{column}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                    <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('activities',i)">delete</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('activities')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                        <div *ngIf="hideCommunication">
                            <div class="row display-flex-reposive mt-10">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                    <span>{{ t('complaints.labels') | titlecase}}</span>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.complaints.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                    </mat-form-field>
                                    <div *ngIf="syncelement.complaints.haserror">
                                        <p class="error-message error-message-set" *ngIf="syncelement.complaints.error" [matTooltip]="errorHendle(syncelement.complaints.error)">{{errorHendle(syncelement.complaints.error)}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.complaints.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.complaints.haserror">
                                    <mat-icon class="error-message">block</mat-icon>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.complaints.haserror && syncelement.complaints.validation">
                                    <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                                </div>
                            </div>
                            <div class="row" *ngFor="let item of syncelement.complaints.columns; let i=index">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.complaints.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                            required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.complaints">
                                    <mat-form-field>
                                        <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                        <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.complaints.columns[i].dialogcolumn">
                                            <mat-option *ngFor="let column of tableColumns.complaints" [value]="column">
                                                {{column}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                    <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('complaints',i)">delete</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('complaints')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('actions.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.tasks.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                </mat-form-field>
                                <div *ngIf="syncelement.tasks.haserror">
                                    <p class="error-message error-message-set" *ngIf="syncelement.tasks.error" [matTooltip]="errorHendle(syncelement.tasks.error)">{{errorHendle(syncelement.tasks.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.tasks.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.tasks.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.tasks.haserror  && syncelement.tasks.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <div class="row" *ngFor="let item of syncelement.tasks.columns; let i=index">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.tasks.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                        required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.tasks">
                                <mat-form-field>
                                    <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                    <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.tasks.columns[i].dialogcolumn">
                                        <mat-option *ngFor="let column of tableColumns.tasks" [value]="column">
                                            {{column}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('tasks',i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('tasks')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row display-flex-reposive mt-10">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                <span>{{ t('employees.labels') | titlecase}}</span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.contactpersons.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                </mat-form-field>
                                <div *ngIf="syncelement.contactpersons.haserror">
                                    <p class="error-message error-message-set" *ngIf="syncelement.contactpersons.error" [matTooltip]="errorHendle(syncelement.contactpersons.error)">{{errorHendle(syncelement.contactpersons.error)}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.contactpersons.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.contactpersons.haserror">
                                <mat-icon class="error-message">block</mat-icon>
                            </div>
                            <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.contactpersons.haserror && syncelement.contactpersons.validation">
                                <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                            </div>
                        </div>
                        <div class="row" *ngFor="let item of syncelement.contactpersons.columns; let i=index">
                            <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                <mat-form-field>
                                    <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.contactpersons.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                        required />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.contactpersons">
                                <mat-form-field>
                                    <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                    <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.contactpersons.columns[i].dialogcolumn">
                                        <mat-option *ngFor="let column of tableColumns.contactpersons" [value]="column">
                                            {{column}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('contactpersons',i)">delete</mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('contactpersons')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                        </div>
                        <div *ngIf="hideCommunication">
                            <mat-divider></mat-divider>
                            <div class="row display-flex-reposive mt-10">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">
                                    <span>{{ t('middel.labels') | titlecase}}</span>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="operation" [(ngModel)]="syncelement.contents.operation" [placeholder]="('settings.relatic.operationname') | transloco" required />
                                    </mat-form-field>
                                    <div *ngIf="syncelement.contents.haserror">
                                        <p class="error-message error-message-set" *ngIf="syncelement.contents.error" [matTooltip]="errorHendle(syncelement.contents.error)">{{errorHendle(syncelement.contents.error)}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="entrycode" [(ngModel)]="syncelement.contents.entrycode" [placeholder]="('settings.relatic.entrycode') | transloco" required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="syncelement.contents.haserror">
                                    <mat-icon class="error-message">block</mat-icon>
                                </div>
                                <div class="col-md-1 col-sm-2 col-xs-2 align-set" *ngIf="!syncelement.contents.haserror && syncelement.contents.validation">
                                    <mat-icon class="successfully-message">check_circle_outline</mat-icon>
                                </div>
                            </div>
                            <div class="row" *ngFor="let item of syncelement.contents.columns; let i=index">
                                <div class="col-md-3 col-sm-4 col-xs-4 align-set">

                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 display-grid align-set">
                                    <mat-form-field>
                                        <input [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" type="text" matInput class="full-width" name="relaticscolumn" [(ngModel)]="syncelement.contents.columns[i].relaticscolumn" [placeholder]="('settings.relatic.relatics_columns') | transloco"
                                            required />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-sm-10 col-xs-10 display-grid align-set" *ngIf="tableColumns.contents">
                                    <mat-form-field>
                                        <mat-label>{{ t('settings.relatic.dialog_columns') | titlecase}}</mat-label>
                                        <mat-select [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [(ngModel)]="syncelement.contents.columns[i].dialogcolumn">
                                            <mat-option *ngFor="let column of tableColumns.contents" [value]="column">
                                                {{column}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1 col-sm-1 col-xs-1 text-center align-set">
                                    <mat-icon class="delete-icon-color d-cursor-hand" (click)="deletesyncElement('contents',i)">delete</mat-icon>
                                </div>
                            </div>
                            <div class="row">
                                <button *ngIf="level == 'company' ? true : hasPermission(Permission.ProjectIntegrationsUpdate)" class="btn btn-primary color-btn-primary-mapa align-set mb-10" type="button" (click)="addSyncFiled('contents')">
                                <mat-icon class="icon-connect d-text-middle">add_circle</mat-icon>
                                {{ t('settings.relatic.column') | titlecase}}
                              </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="popup-footer" *ngIf="isProcess">
        <button type="button" mat-stroked-button data-dismiss="modal" (click)='onNoClick()'>{{ t('close') | capitaliz }}</button>
        <button type="submit" [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" mat-flat-button class="ml-10" (click)="submit()">{{ t('save') | capitaliz }}</button>
    </div>
</div>
