import { Directive, Input } from "@angular/core";
import { ProjectConfigsService } from '../../../akita/project-configs/state/project-configs.service';
import { GenericComponent } from "../../../core/util/abstract/generic-component";
import { Globals } from "../../../globals";
@Directive({
})

export class IntegrationsModalComponent extends GenericComponent {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('modal_integration') protected modal;
  public configuration = {};

  constructor(public globals: Globals, public _projectConfigsService: ProjectConfigsService) {
    super(globals);
  }

  protected getConfig(serviceGroup: string, callback?: any) {
    this._projectConfigsService.get().subscribe(
      config => {
        this.configuration = config;
        if (callback && typeof callback === 'function') {
          callback();
        }
      }
    );
  }

  public setConfig(serviceGroup: string, callback?: any) {
    this._projectConfigsService.update(serviceGroup, this.globals.projectConfigs).subscribe(
      (res) => {
        if (callback && typeof callback === 'function') {
          callback(res);
        }
      }
    );
  }
}
