import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-middel-submit',
  templateUrl: './middel-submit.component.html',
  styleUrls: ['./middel-submit.component.sass']
})
export class MiddelSubmitComponent {

  options: any[] = [{
    title: 'Dit middel',
    value: 'self',
    checked: true,
  }, {
    title: 'Deze en volgende middelen',
    value: 'self_future',
    checked: false,
  }, {
    title: 'Alle middelen',
    value: 'all',
    checked: false,
  }];


  selectedOption = 'self';

  constructor(
    public dialogRef: MatDialogRef<MiddelSubmitComponent>
  // eslint-disable-next-line no-empty-function
  ) { }

  cancel() {
    this.dialogRef.close(null);
  }

  updateOption(value: string) {
    this.selectedOption = value;
  }

  submit() {
    this.dialogRef.close(this.selectedOption);
  }
}
