<div class="row popup-container">
  <div class="popup-header row no-margin">
      <div class="col-xs-12 mb-5">
          <app-breadcrumb></app-breadcrumb>
          <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
        <mat-icon class="d-text-middle uil uil-multiply"></mat-icon>
      </span><span class="sr-only">Close</span></button>
          <div *hasPermission="[Permission.IssuesDelete]">
              <mat-icon *ngIf="issue" (click)="deleteIssue(issue)" class="d-cursor-hand uil uil-trash delete pull-right issues-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}"></mat-icon>
          </div>
          <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
              <button  *ngIf="issue" type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
                  <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
              </button>
          </div>
      </div>
      <div class="col-xs-12 popup-header-title">
          <h1><strong>{{ 'issues.label' | transloco | capitaliz }} </strong>{{name}}</h1>
      </div>


  </div>
  <mat-progress-bar *ngIf="!issue" mode="indeterminate"></mat-progress-bar>
  <div class="popup-body" appScroll *ngIf="issue">
    <app-workflow-generic *ngIf="issue && issue.workflows && issue.workflows.length && hasModule('workflow')" [margin]="false" parentType="issues" [deadline]="issue.deadline" [workflows]="issue.workflows" [permitId]="issue.id"></app-workflow-generic>
    <form name="issueForm" #form="ngForm" (submit)="updateIssue()" *ngIf="issue">
      <!-- left col -->
      <div class="col-md-7 col-xs-12">
        <h3>{{ 'decisions.connections' | transloco | capitaliz }}</h3>
        <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component">
            <div>
              <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                <app-add-connect-generic *ngIf="loaded" parentType="issues" (onChange)="updateEntities($event)" [parent]="issue" [data]="entities" [types]="['contactmoments','requirements','tasks','complaints','documents','permits','layers']"></app-add-connect-generic>
            </div>
        </div>

        <!-- Stakeholders-->
        <h3 class="mb-15">
          {{ "issues.stakeholders_and_belangen" | transloco }}
        </h3>
        <div class="col-xs-12 panel z-depth-2 panel-border map-div">
          <div class="row">
            <div class="col-md-10">
              <app-generic-multiselect-checkbox
                [placeholder]="
                  'reusable.select_involve_stakeholder' | transloco
                "
                type="stakeholders"
                name="stakeholders"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="issue.stakeholders"
                ngDefaultControl
                [maxChipMessage]="
                  '+ {count} ' +
                  ('reusable.more' | transloco) +
                  ' ' +
                  ('stakeholders.labels' | transloco) +
                  ' ...'
                "
                class="col-md-11 full-width"
                (ngModelChange)="updateIssue(true)"
                [disabled]="!hasPermission(Permission.IssuesUpdate)"
                [uniqueId]="'select-stakeholder'"
              >
                <app-generic-multiselect-checkbox-option-all
                  >{{ "filter_select_all" | transloco }}
                </app-generic-multiselect-checkbox-option-all>
                <app-generic-multiselect-checkbox-option
                  *ngFor="
                    let stakeholder of stakeholders$ | async;
                    trackBy: trackByFn
                  "
                  [value]="stakeholder"
                >
                  {{ stakeholder.name }}
                </app-generic-multiselect-checkbox-option>
                <app-generic-multiselect-checkbox-option-new-action
                  type="stakeholder"
                >
                </app-generic-multiselect-checkbox-option-new-action>
                <app-generic-multiselect-search-project
                  entity="stakeholders"
                ></app-generic-multiselect-search-project>
              </app-generic-multiselect-checkbox>
            </div>
            <div
              *ngIf="issue && issue.stakeholders.length > 0"
              class="col-md-2"
              style="height: 100px; top: 60px"
            >
              <div
                style="
                  display: flex;
                  align-items: flex-end;
                  justify-content: space-evenly;
                  width: 100px;
                "
              >
                <mat-icon class="uil uil-chart-pie-alt"></mat-icon>
                <mat-icon class="uil uil-temperature-half"></mat-icon>
              </div>
            </div>
          </div>
          <!-- TODO: Gave up on using reactive form. To fix later on to use proper reactive form implementation -->
          <div
            *ngIf="issue && issue.stakeholders.length > 0"
            class="row pb-55 pd-10"
            class="issue-interest-panel"
          >
            <div
              class="pt-8"
              *ngFor="let stakeholder of issue.stakeholders; trackBy: trackByFn"
              style="border-bottom: 1px solid #e0e1e3"
            >
              <div class="row">
                <div class="col-md-4">
                  <span class="pt-8 name-overflow pl-8">
                    {{ stakeholder.name }}
                  </span>
                  <div
                    class="pl-8 pt-8"
                    style="display: flex; justify-content: space-between"
                  >
                    <span *ngIf="stakeholder.matrices">{{
                      "reusable.stakeholder." + stakeholder.matrices.strategie
                        | transloco
                        | truncatie : 12
                    }}</span>
                    <span *ngIf="!stakeholder.matrices">{{
                      "reusable.stakeholder." + "Ongecategoriseerd"
                        | transloco
                        | truncatie : 12
                    }}</span>
                    <span
                      *ngIf="
                        stakeholder.matrices &&
                        this.globals.projectConfigs &&
                        this.globals.projectConfigs[
                          'project.stakeholder_strategy.is_new'
                        ]
                      "
                      [matTooltip]="
                        'reusable.stakeholder.' +
                          stakeholder.matrices.approach_strategy | transloco
                      "
                      >{{
                        "reusable.stakeholder." +
                          stakeholder.matrices.approach_strategy
                          | transloco
                          | truncatie : 12
                      }}</span
                    >
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4">
                  <div class="pt-8 name-overflow pl-8">
                    <span *ngIf="isArray(stakeholder.issues_stakeholders_interests); else notArray">
                      <span
                      *ngFor="
                        let interests of stakeholder.issues_stakeholders_interests;
                        last as isLast
                      "
                    >
                      {{ interests.description
}}<span *ngIf="!isLast">,</span></span
                    >
                    </span>
                    <ng-template #notArray>
                      <!-- Handle the case when issues_stakeholders_interests is an object -->
                      <span *ngFor="let key of getObjectKeys(stakeholder.issues_stakeholders_interests); last as isLast">
                        {{ stakeholder.issues_stakeholders_interests[key].description? 
                          stakeholder.issues_stakeholders_interests[key].description: ''}}<span *ngIf="!isLast">,</span>
                      </span>
                    </ng-template>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <ng-container
                    *ngTemplateOutlet="
                      matrices && matrices[stakeholder.id]
                        ? connected
                        : notConnected;
                      context: {
                        $implicit: { stakeholder: stakeholder, issue: issue }
                      }
                    "
                  >
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <ng-template #notConnected let-value>
            <div
              class="issue-interest-new"
              style="display: flex; justify-content: space-around; width: 100px"
            >
              <div
                [className]="'color-x'"
                [popper]="popperInterest"
                [popperHideOnClickOutside]="false"
                [popperHideOnScroll]="false"
                popperTrigger="click"
                popperPlacement="right"
                popperApplyClass="matrix-popper-dialog"
                (click)="edit($event, value.issue, value.stakeholder); popperInterest.hide()"
              ></div>
              <div
                [className]="'color-x'"
                [popper]="popperTension"
                [popperHideOnClickOutside]="true"
                [popperHideOnScroll]="true"
                popperTrigger="click"
                popperPlacement="right"
                popperApplyClass="matrix-popper-dialog"
                (click)="edit($event, value.issue, value.stakeholder); popperTension.hide()"
              ></div>
            </div>
          </ng-template>

          <ng-template #connected let-value>
            <div
              class="issue-interest-new"
              style="display: flex; justify-content: space-around; width: 100px"
            >
              <div
                [className]="
                  'colorInterest-' + matrices[value.stakeholder.id].interest
                "
                [popper]="popperInterest"
                [popperHideOnClickOutside]="false"
                [popperHideOnScroll]="false"
                popperTrigger="click"
                popperPlacement="right"
                popperApplyClass="matrix-popper-dialog"
                (click)="
                  edit(
                    $event,
                    value.issue,
                    value.stakeholder,
                    matrices[value.stakeholder.id].interest
                  ); popperInterest.hide()
                "
              ></div>
              <div
                [className]="
                  'colorTension-' +
                  (matrices[value.stakeholder.id].tension === null
                    ? null
                    : 4 - matrices[value.stakeholder.id].tension)
                "
                [popper]="popperTension"
                [popperHideOnClickOutside]="true"
                [popperHideOnScroll]="true"
                popperTrigger="click"
                popperPlacement="right"
                popperApplyClass="matrix-popper-dialog"
                (click)="
                  edit(
                    $event,
                    value.issue,
                    value.stakeholder,
                    matrices[value.stakeholder.id].tension,
                    'tension'
                  ); popperTension.hide()
                "
              ></div>
            </div>
          </ng-template>

          <popper-content #popperInterest>
            <ng-container *transloco="let t; read: 'issue_interest'">
              <div class="popper" style="padding: 20px">
                <div style="display: flex; align-items: center;">
                  <h4>{{ "reusable.interest" | transloco }}</h4>
                  <span style="position: absolute; right: 0; padding-right: 10px"><i class="uil uil-multiply unicon-icon" style="cursor: pointer;" (click)="popperInterest.hide()"></i></span>
                </div>
                <div class="pr-10">
                  <div style="display: flex">
                    <div
                      class="mr-20"
                      style="display: inline-block; text-align: center"
                    >
                      <mat-icon
                        class="uil uil-chart-pie-alt"
                        style="display: inline-block; vertical-align: center"
                      ></mat-icon>
                      <span style="display: block">{{
                        "reusable.interest" | transloco
                      }}</span>
                    </div>
                    <div *ngIf="editedMatrix">
                      <mat-button-toggle-group
                        [(ngModel)]="editedMatrix.currentValue"
                        name="currentValue"
                      >
                        <mat-button-toggle
                          mat-flat-button
                          [value]="i - 1"
                          class="block"
                          *ngFor="
                            let interest of interest_labels;
                            let i = index
                          "
                          [style.background-color]="interest.color"
                          (click)="applyEdit(i - 1, 'interest')"
                        ></mat-button-toggle>
                      </mat-button-toggle-group>
                      <div style="display: flex">
                        <div
                          class="block"
                          *ngFor="let interest of interest_labels"
                        >
                          {{
                            interest.label
                              ? ("reusable." + interest.label | transloco)
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" style="padding: 0px">
                    <div class="w-80">
                      <app-add-connect-themes
                        [mulitple]="true"
                        [editButton]="false"
                        [editable]="false"
                        class=""
                        [textMultiple]="'interests' | transloco"
                        [textSingle]="'interests' | transloco"
                        [type]="'interests'"
                        [selectedTheme]="editedMatrix ? editedMatrix.interests: []"
                        (onThemeSelected)="
                          onSelectStakeholderInterests(
                            editedMatrix.stakeholder,
                            $event
                          )
                        "
                        [uniqueId]="'interests'"
                      ></app-add-connect-themes>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </popper-content>

          <popper-content #popperTension>
            <ng-container *transloco="let t; read: 'issue_interest'">
              <div class="popper" style="padding: 20px">
                <div>
                  <h4>
                    {{
                      newStrategie
                        ? ("reusable.tension" | transloco)
                        : ("reusable.type_of_interest" | transloco)
                    }}
                  </h4>
                </div>
                <div class="pr-10">
                  <div style="display: flex">
                    <div
                      class="mr-20"
                      style="display: inline-block; text-align: center"
                    >
                      <mat-icon
                        class="uil uil-temperature-half"
                        style="display: inline-block; vertical-align: center"
                      ></mat-icon>
                      <span style="display: block">{{
                        newStrategie
                          ? ("reusable.tension" | transloco)
                          : ("reusable.type_of_interest" | transloco)
                      }}</span>
                    </div>
                    <div *ngIf="editedMatrix">
                      <mat-button-toggle-group
                        [(ngModel)]="editedMatrix.currentValue"
                        name="currentValue"
                      >
                        <mat-button-toggle
                          mat-flat-button
                          [value]="i - 1"
                          class="block"
                          *ngFor="let tension of tension_labels; let i = index"
                          [style.background-color]="tension.color"
                          (click)="applyEdit(i - 1, 'tension')"
                        ></mat-button-toggle>
                      </mat-button-toggle-group>
                      <div style="display: flex">
                        <div
                          class="block"
                          *ngFor="let tension of tension_labels"
                        >
                          {{
                            newStrategie
                              ? tension.label
                                ? ("reusable." + tension.label | transloco)
                                : ""
                              : tension.label_old
                              ? ("reusable." + tension.label_old | transloco)
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </popper-content>
        </div>
      </div>

      <!-- right col -->
      <div class="col-md-5 col-xs-12">
        <!-- urgency-->
        <h3 class="text-capitalize">
          {{ "issues.urgency" | transloco | capitaliz }}
        </h3>
        <div class="col-xs-12 panel z-depth-2 panel-border map-div">
          <div class="col-xs-6 mt-10">
            {{ "issues.urgency" | transloco | capitaliz }}
          </div>
          <div class="col-xs-6 issue-status">
            <span
              [class]="
                issue.issues_status + ' issue-status-label ' + issue.urgency
              "
              (click)="showStatusField = !showStatusField"
              *transloco="let t; read: 'issues'"
            >
              <app-urgency-status
                *ngIf="issue.issues_status == 'open'"
                [issue$]="issue$"
                name="urgency"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="issue.urgency"
                ngDefaultControl
                (change)="updateIssue()"
                class="pb-15"
              ></app-urgency-status>
              <div
                *ngIf="issue.issues_status == 'gesloten'"
                class="closed-status"
              >
                <p class="gesloten">
                  {{ t("gesloten") }}
                </p>
              </div>
              <i
                *ngIf="!showStatusField"
                class="d-text-middle uil uil-arrow-down unicon-icon mat-icon"
              ></i>
              <i
                *ngIf="showStatusField"
                class="d-text-middle uil uil-arrow-up unicon-icon mat-icon"
              ></i>
            </span>
          </div>
          <div style="margin-bottom: 20px">
            <div class="d-block w-100">
              <mat-slider
                #slider
                [min]="0"
                [max]="364"
                formatLabel>
                <input matSliderThumb [(ngModel)]="sliderValue"   name="sliderValue" style="pointer-events: none;"
                        (input)="updateSlider()"
                      thumbLabel>
              </mat-slider>
            </div>
            <div class="vertical-line-container">
              <div
                class="vertical-line"
                *ngFor="let value of colorSpanPercentage; let i = index"
                [style.right.%]="(value / max) * 100"
              >
                {{ "|" }}
              </div>
            </div>
            <div class="deadline-scale">
              <div style="text-align: left; font-size: 10px">
                {{
                  sliderEndDate | formatdate : "dd-MM-yyyy" : globals.timezone
                }}
              </div>
              <div
                class="each-deadline"
                *ngFor="let date of calculatedDates; let i = index"
                [style.right.%]="(colorSpanPercentage[i] / max) * 100"
                [style.margin-right.px]="
                  colorSpanPercentage[i] < 5 && colorSpanPercentage[i] !== 0
                    ? 20
                    : 0
                "
              >
                {{ date | formatdate : "dd-MM-yyyy" : globals.timezone }}
              </div>
            </div>
          </div>

          <div *ngIf="showStatusField">
            <mat-progress-bar
              *ngIf="!reducer"
              mode="indeterminate"
            ></mat-progress-bar>
            <div *ngIf="reducer">
              <div class="col-md-12"></div>
              <div class="col-xs-12 mt-10 mb-15">
                *{{ "issues.update_status_text" | transloco | capitaliz }}
              </div>
              <mat-form-field
                style="margin-top: 14px;"
                *transloco="let t"
                class="col-xs-6"
                id="status"
                [matTooltip]="
                  reducer.impact ? t('settings.relatic.disable_field') : null
                "
              >
                <mat-select
                  [placeholder]="t('issues.impact') | capitaliz"
                  [(value)]="issue.impact"
                  (valueChange)="updateChart(true)"
                  [disabled]="
                    !hasPermission(Permission.IssuesImpactUpdate) ||
                    issue.issues_status == 'gesloten' ||
                    reducer.impact
                  "
                >
                  <mat-option value="negligible">{{
                    t("issues.negligible") | capitaliz
                  }}</mat-option>
                  <mat-option value="low">{{
                    t("issues.low") | capitaliz
                  }}</mat-option>
                  <mat-option value="middle">{{
                    t("issues.middle") | capitaliz
                  }}</mat-option>
                  <mat-option value="high">{{
                    t("issues.high") | capitaliz
                  }}</mat-option>
                  <mat-option value="catastrophic">{{
                    t("issues.catastrophic") | capitaliz
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                style="margin-top: 14px;"
                class="col-xs-6"
                [matTooltip]="
                  reducer.deadline ? t('settings.relatic.disable_field') : null
                "

              >
                <input
                  type="text"
                  matInput
                  placeholder="Deadline"
                  name="deadline"
                  matInput
                  [matDatepicker]="issueDeadline"
                  (dateChange)="updateChart()"
                  [disabled]="
                    !hasPermission(Permission.IssuesDeadlineUpdate) ||
                    issue.issues_status == 'gesloten' ||
                    reducer.deadline
                  "
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="issue.deadline"
                  (focus)="issueDeadline.open()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="issueDeadline"
                ></mat-datepicker-toggle>
                <mat-datepicker #issueDeadline></mat-datepicker>
              </mat-form-field>

              <div class="col-xs-12 flex">
                <mat-form-field class="close-button">
                  <textarea
                    matInput
                    type="text"
                    id="closedDescription"
                    name="closed description"
                    [placeholder]="'reusable.remark' | transloco"
                    [(ngModel)]="issue.closed_description"
                    [disabled]="
                      !hasPermission(Permission.IssuesUpdate) ||
                      issue.issues_status == 'gesloten'
                    "
                  ></textarea>
                </mat-form-field>
                <button
                  *ngIf="issue.issues_status === 'open'"
                  class="status-button"
                  mat-stroked-button
                  [disabled]="!issue.closed_description"
                  value="close"
                  (click)="closeIssue('gesloten')"
                >
                  {{ "issues.close" | transloco | capitaliz }}
                </button>
                <button
                  *ngIf="issue.issues_status === 'gesloten'"
                  class="status-button"
                  mat-stroked-button
                  value="close"
                  (click)="closeIssue('open')"
                >
                  {{ "issues.reopen" | transloco | capitaliz }}
                </button>
              </div>
            </div>
          </div>
          <span
            class="col-xs-12 pt-10"
            *ngIf="issue.issues_status == 'gesloten' && issue.closed_by"
            ><span class="closer-hint">{{
              issue.closed_date
                | formatdate : "dd MMMM YYYY " : globals.timezone
                | capitaliz
            }}</span
            ><strong>
              {{ issue.closed_by.name }}
              {{ "reusable.closed_this" | transloco }}
              {{ "issues.label" | transloco }}</strong
            ></span
          >
          <span
            class="col-xs-12 closer-hint"
            *ngIf="
              issue.issues_status == 'gesloten' &&
              issue.closed_by &&
              !showStatusField
            "
            >{{ issue.closed_description }}</span
          >
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-comment-box
              [users]="users"
              type="issues"
              [type_id]="issue.id"
            ></app-comment-box>
          </div>
        </div>

        <h3>{{ "text.general" | transloco | capitaliz }}</h3>
        <mat-progress-bar
          *ngIf="!reducer"
          mode="indeterminate"
        ></mat-progress-bar>
        <div *ngIf="reducer">
          <div
            *transloco="let t"
            class="col-xs-12 panel z-depth-2 panel-border"
          >
            <app-show-more [text]="t('issues.show_more_text') | capitaliz">
              <div summary>
                <mat-form-field
                  class="col-xs-12 full-width"
                  [matTooltip]="
                    reducer.name ? t('settings.relatic.disable_field') : null
                  "
                >
                  <mat-label>{{ "reusable.name" | transloco }}</mat-label>
                  <input
                    matInput
                    type="text"
                    id="name"
                    name="name"
                    (change)="updateIssue()"
                    [(ngModel)]="issue.name"
                    [disableControl]="
                      !hasPermission(Permission.IssuesUpdate) || reducer.name
                    "
                    required
                  />
                </mat-form-field>

                <mat-form-field
                  class="col-xs-12 full-width"
                  [matTooltip]="
                    reducer.description
                      ? t('settings.relatic.disable_field')
                      : null
                  "
                >
                  <mat-label>{{
                    "reusable.description" | transloco
                  }}</mat-label>
                  <textarea
                    matInput
                    name="description"
                    id="description"
                    [placeholder]="'reusable.description' | transloco"
                    (change)="updateIssue()"
                    cols="30"
                    rows="4"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="issue.description"
                    [disableControl]="
                      !hasPermission(Permission.IssuesUpdate) ||
                      reducer.description
                    "
                  ></textarea>
                </mat-form-field>
                <div
                  [ngClass]="{
                    disable_enitiy: !hasPermission(Permission.IssuesUpdate)
                  }"
                >
                  <app-generic-multiselect-checkbox
                    [chipDisabler]="chipDisabler"
                    [disabled]="
                      !hasPermission(Permission.IssuesUpdate) ||
                      globals.subprojects.length === 0
                    "
                    [parent]="issue"
                    parentType="issues"
                    class="subprojects col-xs-12"
                    type="subprojects"
                    [maxChipItems]="3"
                    *ngIf="hasModule('subprojects')"
                    [placeholder]="
                      this.globals.projectConfigs[
                        'project.labels.subprojects'
                      ] || 'subprojects.label' | transloco
                    "
                    name="subprojects"
                    [(ngModel)]="issue.subprojects"
                    [ngModelOptions]="{ standalone: true }"
                    [multiple]="true"
                    [maxChipLength]="20"
                    (ngModelChange)="updateSubprojects()"
                    [uniqueId]="'sub-projects'"
                  >
                  <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option
                      *ngFor="let subproject of globals.subprojects"
                      [value]="subproject"
                    >
                      {{ subproject.name }}
                    </app-generic-multiselect-checkbox-option>
                  </app-generic-multiselect-checkbox>
                </div>
              </div>
              <div content>
                <mat-form-field
                  class="col-xs-12 full-width"
                  [matTooltip]="
                    reducer.rationale
                      ? t('settings.relatic.disable_field')
                      : null
                  "
                >
                  <mat-label>{{ "reusable.reasoning" | transloco }}</mat-label>
                  <textarea
                    matInput
                    name="rationale"
                    id="rationale"
                    cols="30"
                    rows="4"
                    (change)="updateIssue()"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="issue.rationale"
                    [ngModelOptions]="{ standalone: true }"
                    [disableControl]="
                      !hasPermission(Permission.IssuesUpdate) ||
                      reducer.rationale
                    "
                  ></textarea>
                </mat-form-field>

                <div
                  class="col-xs-12"
                  *ngIf="users && users.length > 0"
                  [ngClass]="{
                    disable_enitiy: !hasPermission(Permission.IssuesUpdate)
                  }"
                >
                  <app-multiselect-users
                    [chipDisabler]="chipDisablerUser"
                    [disabled]="!hasPermission(Permission.ComplaintsUpdate)"
                    *ngIf="users"
                    [placeholder]="'reusable.responsible' | transloco"
                    [multipleUsers]="true"
                    [selectedUsers]="issue.users"
                    [users]="users"
                    (onSelectedUsersUpdated)="updateUsers($event)"
                  >
                  </app-multiselect-users>
                </div>

                <div class="col-xs-12">
                  <mat-form-field
                    class="full-width"
                    [matTooltip]="
                      reducer.issues_date
                        ? t('settings.relatic.disable_field')
                        : null
                    "
                  >
                    <mat-label>{{ "reusable.date" | transloco }}</mat-label>
                    <input
                      type="text"
                      matInput
                      name="issues_date"
                      matInput
                      [matDatepicker]="issueDatePicker"
                      (dateChange)="updateIssue()"
                      [disabled]="
                        !hasPermission(Permission.IssuesUpdate) ||
                        reducer.issues_date
                      "
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="issue.issues_date"
                      [ngModelOptions]="{ standalone: true }"
                      (focus)="issueDatePicker.open()"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="issueDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #issueDatePicker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div
                  class="col-xs-12"
                  [matTooltip]="
                    reducer.theme ? t('settings.relatic.disable_field') : null
                  "
                >
                  <app-add-connect-themes
                    [editButton]="true"
                    [editable]="false"
                    valueType="object"
                    [type]="themeType"
                    [selectedTheme]="selectedTheme"
                    (onThemeSelected)="updateSelectedTheme($event)"
                    [disabled]="
                      reducer.theme || !hasPermission(Permission.IssuesUpdate)
                    "
                    [uniqueId]="'add-themes'"
                  ></app-add-connect-themes>
                </div>
              </div>
            </app-show-more>
          </div>
        </div>
        <!-- -->
        <h3 class="mb-15 text-capitalize">{{ "location" | transloco }}</h3>
        <div class="col-xs-12 panel z-depth-2 panel-border map-div">
          <mat-progress-bar
            *ngIf="!reducer"
            mode="indeterminate"
          ></mat-progress-bar>
          <div *ngIf="reducer">
            <div
              [matTooltip]="
                reducer.coordinates ? t('settings.relatic.disable_field') : null
              "
            >
              <div
                class="col-xs-12"
                [ngClass]="{
                  disable_enitiy:
                    reducer.coordinates ||
                    !hasPermission(Permission.IssuesUpdate)
                }"
              >
                <app-openlayers-map
                  [includeSidebar]="false"
                  [defaultCoordinate]="globals.projectConfig.default_map"
                  (ngModelChange)="updateCoordinate()"
                  [(ngModel)]="issue.locations"
                  [ngModelOptions]="{ standalone: true }"
                  [showMapReset]="true"
                  [maxPoints]="1"
                  locatableType="issues"
                  defaultDrawTool="point"
                  clickStartDrawingMsg="Teken issuepunt"
                  name="locations"
                  ngDefaultControl
                  [disabled]="
                    reducer.coordinates ||
                    !hasPermission(Permission.IssuesUpdate)
                  "
                ></app-openlayers-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
