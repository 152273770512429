import {
  AfterViewInit,
  Directive, ElementRef
} from '@angular/core';

@Directive({
  selector: '[appCollapsetext]',
  exportAs: 'appCollapsetext'
})
export class CollapsetextDirective implements AfterViewInit {

  // eslint-disable-next-line no-empty-function
  constructor (private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.el.nativeElement.offsetHeight > 50) {
      this.el.nativeElement.className = 'text-overflow-collapse';
      this.el.nativeElement['showCollapse'] = 'yes';
    } else {
      this.el.nativeElement.className = '';
      this.el.nativeElement['showCollapse'] = 'no';
    }
  }

}
