import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortEvents'
})
export class SortPipe implements PipeTransform {

  transform(events: any[], sortField: string = 'start.dateTime', order: 'asc' | 'desc' = 'asc'): any[] {
    if (!events || events.length === 0) {
      return [];
    }

    return events.sort((a, b) => {
      const dateA = new Date(a[sortField.split('.')[0]][sortField.split('.')[1]]);
      const dateB = new Date(b[sortField.split('.')[0]][sortField.split('.')[1]]);

      // If ascending order, sort by earlier date
      if (order === 'asc') {
        return dateA.getTime() - dateB.getTime();
      }

      // If descending order, sort by later date
      return dateB.getTime() - dateA.getTime();
    });
  }
}
