<div class="row popup-container" *transloco="let t">
    <div class="popup-header row no-margin ">
        <div class="col-xs-12 mb-5">
            <h1><strong>{{ t('admin_page.integration') | titlecase}} </strong><span class="">{{data}}</span></h1>
            <button type="button" class="close " (click)="close()"><span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span><span class="sr-only">Close</span></button>
        </div>
    </div>

    <div class="popup-body" appScroll>
        <div class="col-xs-12 mb-5">
            <h5> {{ t('settings.connection') | titlecase}}</h5>
            <div class="set-border-connection">
                <span class="align-set" *ngIf="!bouwappenable && !errorMessage">{{ t('settings.bouwapp.bouwapp_integration') | capitaliz}}</span>
                <div class="align-set display-flex" *ngIf="errorMessage">
                    <mat-icon class="d-text-middle">error</mat-icon>
                    <span class="error-message align-set"> {{ t('error.bouwapp_integration') | capitaliz}}</span>
                </div>
                <div class="align-set display-flex" *ngIf="bouwappenable && !errorMessage">
                    <mat-icon class="successfully-message d-text-middle">check_circle</mat-icon>
                    <span class="align-set"> {{ t('success.bouwapp_integration') | capitaliz}}</span>
                </div>
                <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate)" *ngIf="bouwappenable" class="btn btn-primary color-btn-primary-mapa align-set" type="button" (click)="redirectURL()">
                    <mat-icon class="icon-connect d-text-middle">remove_circle</mat-icon>
                        {{ t('settings.disable') | titlecase}}
                </button>
                <button [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsCreate)" *ngIf="!bouwappenable" class="btn btn-primary color-btn-primary-map align-set" type="button" (click)="redirectURL()">
                    <mat-icon class="d-text-middle icon-connect">add_circle</mat-icon>
                        {{ t('settings.connect_me') }}
                </button>
            </div>
        </div>
        <div class="col-xs-12 mb-5">
            <mat-progress-bar *ngIf="isProcess" mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="!isProcess && bouwappenable && !errorMessage">
            <div class="col-xs-12 mb-5">
                <h5> {{ t('settings.bouwapp.setting') | titlecase}}</h5>
                <div class="subtext mb-5">{{ t('settings.bouwapp.setting_subtext')}}</div>
                <div class="set-border-connection">
                    <div class="col-xs-12">
                        <h5><strong> {{ t('settings.bouwapp.link_projects') | titlecase}}</strong></h5>
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th class="w-40 vertical-align-middle">
                                        <p>{{ level == 'company' ? projectname : globals.project.name | truncatie }}</p>
                                    </th>
                                    <td>
                                        <app-generic-multiselect-checkbox [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [multiple]="false" [placeholder]="t('settings.bouwapp.bouwapp_project')" name="mainProject" [(ngModel)]="mainProject" (ngModelChange)="mainProjectCall()">
                                            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                            </app-generic-multiselect-checkbox-option-all>
                                            <app-generic-multiselect-checkbox-option *ngFor="let item of bouwappProjects; trackBy: trackByFn" [value]="item">
                                                {{ item.name }}
                                            </app-generic-multiselect-checkbox-option>
                                        </app-generic-multiselect-checkbox>
                                    </td>
                                </tr>
                                <tr *ngFor="let td of dialogProjects; let i=index">
                                    <th class="w-40 vertical-align-middle">
                                        <p>{{td.name}}</p>
                                    </th>
                                    <td>
                                        <app-generic-multiselect-checkbox [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [multiple]="false" [placeholder]="t('settings.bouwapp.bouwapp_project')" name="bouwappprojects" [(ngModel)]="bouwsubprojects[i]" (ngModelChange)="projectSet(td.id,$event)" [uniqueId]="'sub-projects'">
                                            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                            </app-generic-multiselect-checkbox-option-all>
                                            <app-generic-multiselect-checkbox-option *ngFor="let item of bouwappProjects; trackBy: trackByFn" [value]="item">
                                                {{ item.name }}
                                            </app-generic-multiselect-checkbox-option>
                                        </app-generic-multiselect-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 mb-5">
                <h5 class="mt-15"> {{ t('settings.bouwapp.sync_bouwapp') | titlecase}}</h5>
                <div class="subtext mb-5">{{ t('settings.bouwapp.sync_bouwapp_subtext')}}</div>
                <div class="set-border-connection">
                    <div class="col-xs-12">
                        <app-generic-multiselect-checkbox [disabled]="level == 'company' ? false : !hasPermission(Permission.ProjectIntegrationsUpdate)" [multiple]="true" [placeholder]="t('settings.bouwapp.bouwApp_items')" name="bouwappsync" [(ngModel)]="bouwappsyncSelection" (ngModelChange)="syncBouwAppProject($event)">
                            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                            </app-generic-multiselect-checkbox-option-all>
                            <app-generic-multiselect-checkbox-option *ngFor="let item of connectionSelect; trackBy: trackByFn" [value]="item">
                                {{ item.value | capitaliz}}
                            </app-generic-multiselect-checkbox-option>
                        </app-generic-multiselect-checkbox>
                    </div>
                </div>
                <div *ngIf="smartReportingEnabled">
                    <div class="subtext mb-5">{{ 'settings.bouwapp.smart_reporting_enabled_as' | transloco}}</div>
                    <div>
                        <mat-button-toggle-group [(ngModel)]="smartReportingAs" (ngModelChange)="updateSyncBouwappProject()">
                            <mat-button-toggle mat-flat-button value="requirements">{{'requirements.label' | transloco | capitaliz}}</mat-button-toggle>
                            <mat-button-toggle mat-flat-button value="complaints">{{'complaints.label' | transloco | capitaliz}}</mat-button-toggle>
                        </mat-button-toggle-group>
                        <br>
                        <br>
                    </div>
                </div>
            </div>
            <div class="col-xs-12">
            </div>
        </div>
    </div>
</div>
