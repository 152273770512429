import { translate } from '@jsverse/transloco';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ThemeType } from "../../../core/enum/theme-type";
import { ThemesService } from "../../../core/service/api/themes.service";
import { GenericComponent } from "../../../core/util/abstract/generic-component";
import { Globals } from "../../../globals";
import { DeletionModalComponent } from '../../../shared/components/modal/deletion-modal/deletion-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { showFeedback, showFeedbackSaved } from '../../../core/util/notification';
// import { $ } from 'protractor';
import { ConfirmationDialogComponent } from '../modal/confirmation-dialog/confirmation-dialog.component';
import { StakeholdersService } from 'src/app/core/service/api/stakeholders.service';
import { modalConfig, ModalEnum } from 'src/app/core/util/modalConfig';


@Component({
  selector: 'app-add-connect-themes',
  templateUrl: './add-connect-themes.component.html',
  styleUrls: ['./add-connect-themes.component.sass']
})
export class AddConnectThemesComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() uniqueId: string = '';
  @Input() type: ThemeType;
  @Input() buttonText = "edit_theme";
  @Input() textSingle = translate("theme");;
  @Input() textMultiple = translate("themes");
  @Input() selectedTheme:any[] = [];
  @Input() issuesStakeholdersInterests: any[] = [];
  @Input() interestTotal: boolean = false
  @Input() editable = true;
  @Input() disabled = false;
  @Input() valueType = 'array';
  @Input() editButton: boolean = false;
  @Input() mulitple: boolean = true;
  @ViewChild('mySelect') mySelect: any;
  @ViewChild('modalbody') private modalbody: ElementRef;
  chipDisabler: string[] = [];
  cannotRemoveItems = false;
  issueInterest: any;
  missingIssues: any;
  selectedItem: any= 'hello'
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onThemeSelected: EventEmitter<any> = new EventEmitter();
  themes = [];
  new = [];
  deleted = [];
  original = [];
  loaded = false;
  Theme_types: any;
  filteredThemes: any[] = [];
  public randomId = Math.random().toString(36).substring(7);
  newThemeName: string;
  disabledTooltipText: string = 'Neew contact met admin om thema bij te werken';

  constructor(private _themesService: ThemesService, public globals: Globals, private dialog: MatDialog, private cdRef: ChangeDetectorRef, private _stakeholderService: StakeholdersService) {
    super();
  }

  public ngOnInit(): void {
    if (!this.editable && this.globals.headUser && this.globals.headUser.name) {
      this.disabledTooltipText = `Neem contact met ${this.globals.headUser.name} om thema bij te werken`;
    }
    // Apparently matTooltipDisabled doesn't work in issue detail, hence remove the text via typescript
    if (this.editable) {
      this.disabledTooltipText = '';
    }
    // this.textSingle = translate("theme");
    // this.textMultiple = translate("themes");

    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interestTotal) {
      this.updateSelectedTheme()
    }
  }

  public load() {
    this._themesService.getThemes(this.type).subscribe(
      (themes: any) => {
        this.Theme_types = this.type;
        this.themes = themes.data ? themes.data : themes ? themes : [];
        this.original = [...this.themes];


        this.updateSelectedTheme()
        this.loaded = true;
        if(this.textSingle === 'object' && this.selectedTheme) {
          const object: any = this.selectedTheme;
          this.selectedTheme = this.themes.find(theme => theme.name === object.name);
        }
        this.cdRef.detectChanges();
      }
    );
  }

  updateSelectedTheme() {
    this.filteredThemes = [];
    this.selectedItem = this.selectedTheme
    if (this.selectedTheme?.length && this.themes) {
      this.selectedTheme.forEach(item => {
        if (this.themes.filter(theme => theme.id === item.id)) {
          this.filteredThemes.push(item)
        }
      })
      if (this.filteredThemes.length) {
        this.selectedTheme = this.filteredThemes;

      }
    }
    if (this.issuesStakeholdersInterests?.length) {
      this.issuesStakeholdersInterests.forEach(item => {
        if (this.selectedTheme.filter(theme => theme.id === item.id)) {
          this.selectedTheme.push(item);
        }
      })
    }
  }

  updateTheme(theme: any) {
    if (!this.selectedTheme) {
      return;
    }
    // Ensure themes in issuesStakeholdersInterests are not removed from selectedTheme
    if (Array.isArray(this.selectedTheme)) {

      const updatedSelectedTheme = [...this.selectedTheme];
      this.issuesStakeholdersInterests.forEach(issue => {
        if (!updatedSelectedTheme.some(theme => theme.id === issue.id)) {
          updatedSelectedTheme.push(issue);
        }
      });

      // Check if any item from issuesStakeholdersInterests is missing from updatedSelectedTheme
      this.missingIssues = this.issuesStakeholdersInterests.filter(issue =>
        !this.selectedTheme.some(theme => theme.id === issue.id)
      );
      // Update the selectedTheme with the updated list
      // this.selectedTheme = updatedSelectedTheme;

      if (this.missingIssues.length > 0) {
        this.openConfirmationDialog(this.missingIssues, this.selectedTheme)
      }
    }

    // Emit the updated selectedTheme if no issues are
    if (!this.missingIssues?.length) {
      if (this.editable) {
        this.onThemeSelected.emit(this.selectedTheme);
      } else {
        if (this.editButton) {
          this.onThemeSelected.emit(this.selectedTheme);
        } else {
          this.onThemeSelected.emit(this.selectedTheme);
        }
      }
    }
  }

  openConfirmationDialog(missingIssues, selectedTheme) {
    const dialog = this.dialog.open(ConfirmationDialogComponent, modalConfig({
      data: {
        stakeholderId: missingIssues[0].pivot.stakeholders_id,
        interestId: missingIssues[0].pivot.interests_id
      },
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.handleIssueInterest(missingIssues, result.issuesStakeholdersInterests.map(item => ({ id: item.id })));
      } else {
        this.load();
        this.cdRef.detectChanges();
      }
    });
  }

  private handleIssueInterest(missingIssues: any, issuesId: any) {
    this.issueInterest = this.buildIssueInterestPayload(missingIssues, issuesId);
    this._stakeholderService.submitIssueInterest(this.issueInterest).subscribe(() => {
      this.onThemeSelected.emit(this.selectedTheme);
    });
  }

  private buildIssueInterestPayload(missingIssues: any, issuesId: any) {
    return {
      "stakeholders_id": missingIssues[0].pivot.stakeholders_id,
      "issues": issuesId,
      "interests": missingIssues.map(item => ({ id: item.id }))
    };
  }


  submitNewTheme() {
    this._themesService.submitTheme(this.type, this.newThemeName).subscribe(
      () => {

        this.load();
        showFeedbackSaved();
        this.reset();
      }
    );
  }

  updateExistingTheme(theme) {
    if (!theme || !theme.name || !theme.description) {
      showFeedback('Naam is verplicht', 'error');
    }
    this._themesService.updateTheme(this.type, theme).subscribe(
      () => showFeedbackSaved()
    );
  }

  deleteExistingTheme(theme) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u het thema wilt verwijderen?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this.deleted.push(theme.id)
        const index = this.themes.indexOf(theme);
        if (index > -1) {
          this.themes.splice(index, 1);
        }
        this.cdRef.detectChanges();
      }
    });
  }

  reset() {
    this.new = [];
    this.deleted = [];
    if (!this.editButton) {
      if (this.mySelect) {
        this.mySelect.close();
      }
    }

  }

  newTheme() {
    if (this.type == 'interests') {
      this.new.push({ description: "", color: "#4A9ED6" });
    } else {
      this.new.push({ name: "", color: "#4A9ED6" });
    }

    this.scrollToBottom();
  }

  onNameChange($event, i) {
    this.new[i] = $event.target.value;
  }


  deleteNewTheme(theme) {
    const index = this.new.indexOf(theme);
    if (index > -1) {
      this.new.splice(index, 1);
    }
  }

  close(event?: any) {
    this.reset();
    this.themes = [...this.original];
    const element: any = document.querySelector(`.modal-themes-${this.randomId}`);
    element.style.display = "none";
  }
  openModal(event?: any) {
    this.reset();
    this.load();
    const element: any = document.querySelector(`.modal-themes-${this.randomId}`);
    element.style.display = "block";
  }

  changeColor(theme, color) {
    theme.color = color;
  }


  submit() {
    const element: any = document.querySelector(`.modal-themes-${this.randomId}`);
    element.style.display = "none";
    const newThemes = this.new.filter(e => this.type == 'interests' ? e.description : e.name).map((theme) => {
      if (this.type == 'interests' ? theme.description : theme.name)
        return theme
    })
    if (this.selectedTheme)
      this.onThemeSelected.emit(this.selectedTheme);

    this._themesService.updateTheme(this.type, { save: newThemes, update: this.themes, delete: this.deleted })
      .subscribe(
        () => {
          this.load();
          showFeedbackSaved();
        });
  }

  public scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.modalbody.nativeElement.scrollTop = this.modalbody.nativeElement.scrollHeight;
      }, 100);
      // eslint-disable-next-line no-empty
    } catch (err) { }
  }

}
