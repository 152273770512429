import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Resident } from './resident.model';

export interface ResidentState extends EntityState<Resident>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'residents' })
export class ResidentsStore extends EntityStore<ResidentState, Resident> {

  constructor() {
    super();
  }

}

