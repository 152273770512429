<form [formGroup]="form" *transloco="let t">
  <mat-form-field class="full-width">
    <mat-label>{{ placeholder  }}</mat-label>
    <input matInput [matAutocomplete]="auto" [matChipInputFor]="list"
      formControlName="text" />
    <mat-chip-grid [multiple]="multiple" #list [class]="showChips ? '' : 'hidden'">
      <ng-container *ngIf="valueType == 'array'">
        <mat-chip-row class="name-overflow" *ngFor="let option of value;let i = index;" (click)="openSelect(option,i)"
          [ngClass]="{'chip-custom d-cursor-hand hand':(option.stakeholders && option.stakeholders.length>1 && option.selected && option.selected.length) || ((option.stakeholders && option.stakeholders.length==1) || !option.stakeholders),
        'chip-multiple d-cursor-hand':option.stakeholders && option.stakeholders.length>1 && (!option.selected || option.selected && !option.selected.length)}"
          [removable]="!isDisabled && option.optionType !== MultiselectOptionType.moremarker"
          (removed)="delete(option)">
          <span class="name-chip-overflow">
            {{ option.first_name }} {{ option.last_name }}
          </span>
          <mat-select #select [(ngModel)]="option.selected" [ngModelOptions]="{standalone: true}"
            [ngClass]="option.stakeholders.length>1?'':'hidden'" multiple>
            <span class="select-text">{{ 'text.select_stakholders' | transloco | capitaliz }}
              {{option.first_name || '' + option.last_name || ''}}</span>
            <mat-option *ngFor="let s of option.stakeholders" (click)="clickStakeholder(s,option)" [value]="s">
              {{s.name}}</mat-option>
          </mat-select>
          <mat-icon *ngIf="!isDisabled && option.optionType !== MultiselectOptionType.moremarker" matChipRemove>cancel
          </mat-icon>
        </mat-chip-row>
      </ng-container>
      <ng-container *ngIf="value && valueType == 'object'">
        <mat-chip-row class="name-overflow" (click)="openSelect(value,0)"
          [ngClass]="{'chip-custom d-cursor-hand hand':(value.stakeholders && value.stakeholders.length>1 && value.selected && value.selected.length) || ((value.stakeholders && value.stakeholders.length==1) || !value.stakeholders),
        'chip-multiple d-cursor-hand':value.stakeholders && value.stakeholders.length>1 && (!value.selected || value.selected && !value.selected.length) }"
          [removable]="!isDisabled && value.optionType !== MultiselectOptionType.moremarker" (removed)="delete(value)"
          *ngIf="value.first_name || value.last_name">
          <span class="name-chip-overflow">
            {{ value.first_name }} {{ value.last_name }}
          </span>
          <mat-select #select [(ngModel)]="value.selected" [ngModelOptions]="{standalone: true}"
            *ngIf='value.stakeholders && value.stakeholders.length>1' multiple>
            <span class="select-text">{{ 'text.select_stakholders' | transloco | capitaliz }}
              {{value.first_name || '' + value.last_name || ''}}</span>
            <mat-option *ngFor="let s of value.stakeholders" (click)="clickStakeholder(s,value)" [value]="s">{{s.name}}
            </mat-option>
          </mat-select>
          <mat-icon *ngIf="!isDisabled && value.optionType !== MultiselectOptionType.moremarker" matChipRemove>cancel
          </mat-icon>
        </mat-chip-row>
      </ng-container>
    </mat-chip-grid>
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" (click)="$event.preventDefault(); $event.stopPropagation()"
    [class]="!multiple ? 'generic-multiselect-checkbox--single' : ''" (closed)="resetInput()">
    <mat-option *ngIf="multiple && showSelectAll" [value]="selectAllOption"
      (click)="toggleOption(selectAllOption,$event)">
      <mat-checkbox [checked]="selectAllOption.checked" (click)="$event.preventDefault()">{{ selectAllOption.content }}
      </mat-checkbox>
      <mat-divider></mat-divider>
    </mat-option>
    <div *ngIf="!showAddAction && type === 'contactpersons'">
        <input type="text"  class="mat-option full-width" style="border:none" [placeholder]="'reusable.search_stakeholder' | transloco" formControlName="keyword"  (ngModelChange)="searchFilter($event)"/>
    </div>
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      <div (click)="toggleOption(option,$event)">
        <mat-checkbox [checked]="option.checked">{{ option.content }}</mat-checkbox>
      </div>
    </mat-option>
    <mat-option *ngIf="showAddAction && type !== 'contactpersons'" [value]="addActionOption" [innerHTML]="addActionOption.content"
      (click)="toggleOption(addActionOption)"></mat-option>
      <mat-option *ngIf="showAddAction && type === 'contactpersons'" [value]="addActionOption" [innerHTML]="addActionOption.content"
      (click)="addContactPerson($event)"></mat-option>
    <ng-container *ngIf="showSearchProject && searchResponse$ | async as searchResponse">
      <mat-divider></mat-divider>
      <mat-option>{{ searchResponse.length }} {{ searchType }} {{ t('text.found_project_level') }}</mat-option>
      <mat-option *ngFor="let result of searchResponse" [value]="result" (click)="selectSearchedOption(result)">
        <mat-checkbox [checked]="result.checked" (click)="$event.preventDefault()">{{ result.content }}</mat-checkbox>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <span class="message">{{message}}</span>
</form>
