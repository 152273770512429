<app-openlayers-sidebar-v2
  *ngIf="includeSidebar"
  #sidebar
  [type]="type"
  (onLayerChange)="writeValue($event)"
  (selectedToolChange)="setDrawingMode($event)"
  (purposeToolChange)="onPurposeToolChange($event)"
  (polygonSelected)="onPolygonSelected.emit($event)"
  [buttons]="sidebarButtons"
  [collapse]="collapseSidebar"
  [defaultSelectedTool]="defaultDrawTool"
>
</app-openlayers-sidebar-v2>

<div id="eventListner">
  <div class="map-search mb-5 mt-2" *ngIf="showContent">
    <div class="input-container" >
      <input
        type="text"
        [(ngModel)]="keyword"
        class="form-control universal-search ng-pristine ng-valid ng-touched"
        placeholder="{{'maps.search_address' | transloco}}"
        (input)="search($event.target.value)"
      />
      <div class="ng-star-inserted">
        <i
          *ngIf="loading"
          class="uil uil-spinner-alt uil-icon spinner-animation form-control-map-spinner"
        ></i>
        <!-- <i  class="uil uil-search uil-icon"></i> -->
        <span
          *ngIf="!loading"
          class="uil uil-search form-control-map-search"
        ></span>
      </div>
    </div>
    <!-- Display Search Results -->
    <div *ngIf="showResultsContainer" class="search-results-container">
      <ul style="padding: 5px">
        <ng-container *ngIf="searchResults.length > 0; else notFound">
          <li
            *ngFor="let result of searchResults; let i = index"
            class="search-result-item"
            [class.selected]="i === selectedResultIndex"
            (click)="panToLocation(result); onItemClick(i)"
          >
            {{ result.display_name }}
          </li>
        </ng-container>
        <ng-template #notFound>
          <li class="not-found-message">Not found</li>
        </ng-template>
      </ul>
    </div>
  </div>
  <div
    [id]="'map-' + randomId"
    [class]="isSidenav ? 'sidenav-map eventlistner' : 'eventlistner'"
    #mapContainer
  >
    <div [id]="'popup-' + randomId"></div>
    <ng-container
      *ngIf="{
        purposeTool: purposeTool$ | async,
        locations: locations$ | async
      } as data"
    >
      <button
        *ngIf="
          data.purposeTool === 'add-stakeholder' && data.locations.length > 0
        "
        type="button"
        class="btn add-stakeholder-btn"
        (click)="gatherStakeholders()"
      >
        Stakeholders creëren
      </button>
    </ng-container>
    <div *ngIf="showPanel" class="panel panel-primary panel-default-map-view">
      <div class="panel-heading">Control</div>
      <div class="panel-body">
        <div>
          <button
            *ngIf="includeSaveCoordinate"
            type="button"
            class="btn btn-primary"
            (click)="updateProjectCoordinate()"
          >
            Set project default map view
          </button>
        </div>
        <div>
          <span *ngIf="showUpdateStatus && includeSaveCoordinate">Saved!</span>
        </div>
      </div>
    </div>


  </div>
</div>

<ng-container *ngIf="showResetButton$ | async as showResetButton">
  <button
    type="button"
    class="btn map-reset-btn"
    *ngIf="showResetButton"
    (click)="resetMap()"
    [disabled]="disabled"
  >
    {{ "reusable.delete_location" | transloco }}
  </button>
</ng-container>
<ng-container *ngIf="type == 'middel'">
  <mat-button-toggle-group
    [ngClass]="
      locationsSbj.getValue().length > 0 || bookmarkaddShow
        ? 'map-toggles-set-bookmark'
        : 'map-toggles-set-bookmark-add'
    "
    [(ngModel)]="bookmarkToggle"
    (ngModelChange)="bookmarkOption()"
  >
    <mat-button-toggle
      value="savebookmark"
      *ngIf="
        bookmarkOptions.includes('save') &&
        (showResetButton$ | async) as showResetButton
      "
    >
      <span class="material-icons">bookmark_add</span>
    </mat-button-toggle>
    <mat-button-toggle
      value="addbookmark"
      *ngIf="bookmarkOptions.includes('add')"
    >
      <span class="material-icons">bookmarks</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div class="set-bookmark-popup-add" *ngIf="bookmarkAddPopupShow">
    <h5 class="mb-0 control-label">
      {{ "middel.bookmark-new-header" | transloco | capitaliz }}
    </h5>
    <mat-form-field class="full-width set-border-connection1 mb-10">
      <input
        type="text"
        matInput
        class=""
        name="template"
        [placeholder]="
          'middel.bookmark-new-placeholder' | transloco | capitaliz
        "
        [(ngModel)]="bookmarkName"
        required
      />
    </mat-form-field>
    <div class="fa-pull-right">
      <button type="button" mat-stroked-button (click)="reset()">
        {{ "close" | transloco | capitaliz }}
      </button>
      <button
        type="submit"
        mat-flat-button
        class="ml-10"
        [disabled]="!bookmarkName"
        (click)="saveBookmark()"
      >
        {{ "save" | transloco | capitaliz }}
      </button>
    </div>
  </div>
  <div
    class=""
    [ngClass]="
      locationsSbj.getValue().length > 0 || bookmarkaddShow
        ? 'set-bookmark-popup'
        : 'set-bookmark-popup-nopoly'
    "
    *ngIf="bookmarkPopupShow"
  >
    <h5 class="mb-0 control-label">
      {{ "middel.bookmark-existing-header" | transloco | capitaliz }}
    </h5>
    <app-generic-multiselect-checkbox
      [multiple]="false"
      [placeholder]="
        'middel.bookmark-existing-placeholder' | transloco | capitaliz
      "
      name="bookmark"
      [(ngModel)]="bookmarkSelection"
    >
      <app-generic-multiselect-checkbox-option-all>{{
        "middel.bookmark-existing-placeholder" | transloco | capitaliz
      }}</app-generic-multiselect-checkbox-option-all>
      <app-generic-multiselect-checkbox-option
        *ngFor="let bm of bookmark; trackBy: trackByFn"
        [value]="bm"
      >
        {{ bm.name }}
      </app-generic-multiselect-checkbox-option>
    </app-generic-multiselect-checkbox>
    <div class="fa-pull-right">
      <button type="button" mat-stroked-button (click)="reset()">
        {{ "close" | transloco | capitaliz }}
      </button>
      <button
        type="submit"
        mat-flat-button
        class="ml-10"
        [disabled]="bookmarkSelection.length == 0"
        (click)="onSelectionBookmark()"
      >
        {{ "use" | transloco | capitaliz }}
      </button>
    </div>
  </div>
</ng-container>
