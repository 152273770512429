import { AfterViewInit, Directive } from "@angular/core";
import { Globals } from "../../../globals";
import { PubsubService } from "../../service/api/pubsub.service";
import { Page } from "./page";
@Directive({
})

export abstract class ChildTabbedPage extends Page implements AfterViewInit{

  abstract tabName: string;

  constructor(public pubsub: PubsubService, public globals: Globals) {
    super(pubsub, globals);
  }

  ngAfterViewInit() {
    this.pubsub.updateTabName(this.tabName);
  }
}
