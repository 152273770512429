
<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div cdkDrag *ngFor="let layer of layers; let i=index">
    <ng-container *transloco="let t">
        <mat-expansion-panel class="mat-elevation-z0 layers-accordion" [disabled]="(layer.name=='Subproject' && layer.sublayers.length==0)" [matTooltip]="(layer.name=='Subproject' && layer.sublayers.length==0) ? t('maps.no_subproject') : ''">
            <mat-expansion-panel-header>
              <mat-panel-title class="a-self-center">
                <span class="truncate-label d-flex" *ngIf="layer.type!='subproject'; else subprojectname"><mat-icon>dehaze</mat-icon> <span class="a-self-center ml-5" [matTooltip]="layer.name">{{layer.name}}</span></span>
                <ng-template #subprojectname class="truncate-label">{{this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco}}</ng-template>
              </mat-panel-title>
              <mat-panel-description class="a-self-center">
                <div class="pull-right">
                  <mat-slide-toggle [disabled]="!showToggles || (layer.name=='Subproject' && layer.sublayers.length==0)" [(ngModel)]="layer.active" class="m-null" (change)="onSelectAll(layer.name, $event,layer.type,layer.id)"></mat-slide-toggle>
                  <a class="option-icon-style" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="layer.type!='subproject' && layer.type!='wms' && !layer.symobology">
                    <mat-icon>more_vert</mat-icon>
                  </a>
                  <mat-menu #menu="matMenu">
                    <ng-container *ngTemplateOutlet="options; context: {layer: layer}"></ng-container>
                  </mat-menu>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- layers settings -->
            <!-- transparency -->
            <ng-template  #options let-action="layer">
              <div class="p-custom-menu">
                <div class="d-flex j-space-between" (click)="$event.stopPropagation();">
                  <span class="a-self-center f-12 d-flex"><mat-icon class="mr-5">visibility</mat-icon> <span class="a-self-center">{{'maps.transparency' | transloco}}</span></span>
                  <mat-slider class="custom-slider" min="0" max="1" step="0.1" discrete formatLabel >
                    <input matSliderThumb [(ngModel)]="layer.transparency"
                           (ngModelChange)="onChangeAllStyle(layer.name, $event, 'transparency', layer.id)"
                           thumbLabel>
                  </mat-slider>
                  <!-- <mat-slider class="a-self-center"  min="0" max="1" step="0.1" [value]="layer.transparency" thumbLabel (change)="onChangeAllStyle(layer.name,$event,'transparency',layer.id)"></mat-slider> -->
                </div>
                <!-- color -->
                <div class="d-flex j-space-between mb-10" (click)="$event.stopPropagation();">
                    <span class="a-self-center f-12 d-flex"><mat-icon class="mr-5">palette</mat-icon> <span class="a-self-center">{{'maps.color' | transloco}}</span></span>
                    <input *ngIf="layer.metadata && layer.metadata.color" type="color" [disabled]="!hasRole('Project Admin')" class="color-input" [(ngModel)]="layer.metadata.color" (change)="onChangeAllStyle(layer.name,$event,'color',layer.id)"/>
                    <input *ngIf="!layer.metadata || !layer.metadata.color" type="color" [disabled]="!hasRole('Project Admin')" class="color-input" [(ngModel)]="layer.color" (change)="onChangeAllStyle(layer.name,$event,'color',layer.id)"/>
                </div>
                <!-- Zoom Level -->
                <div class="d-flex j-space-between" (click)="$event.stopPropagation();">
                    <span class="a-self-center f-12 d-flex"><mat-icon class="mr-5">center_focus_strong</mat-icon> <span class="a-self-center">{{'maps.zoom_level' | transloco}}</span></span>
                    <mat-slider class="custom-slider" min="1" max="20" step="1" discrete formatLabel>
                      <input matSliderThumb [(ngModel)]="layerZoomValue"
                              (ngModelChange)="onChangeAllStyle(layer.name,$event,'zoom',layer.id)"
                             thumbLabel>
                    </mat-slider>
                    <!-- <mat-slider class="a-self-center" [disabled]="!hasRole('Project Admin')"  min="1" max="20" step="1" [value]="layer.metadata ? layer.metadata.zoom ? layer.metadata.zoom : layer.zoom : layer.zoom" thumbLabel (change)="onChangeAllStyle(layer.name,$event,'zoom',layer.id)"></mat-slider> -->
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="showSubLayer">
              <div *ngFor="let item of layer.sublayers; let i=index">
                <mat-expansion-panel class="mat-elevation-z0 layers-accordion" [disabled]="!item.symobology">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="a-self-center">
                      <span class="truncate-label text-black" [matTooltip]="item.name"><mat-icon class="sub-layer-icon-set">fiber_manual_record</mat-icon> {{ item.name }}<span *ngIf="item.layer_name">-{{item.layer_name}}</span></span>
                    </mat-panel-title>
                    <mat-panel-description class="a-self-center">
                      <div class="pull-right">
                        <mat-slide-toggle [disabled]="!showToggles" [(ngModel)]="item.is_active" class="m-null" (change)="onSelectSingle(item.credentials,(item.layer_name ? item.name+'-'+item.layer_name : item.name), $event, item.type,true, item.id)"></mat-slide-toggle>
                        <a class="option-icon-style" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="layer.type!='wms' && layer.type!='subproject' && !item.symobology">
                          <mat-icon>more_vert</mat-icon>
                        </a>
                        <mat-menu #menu="matMenu">
                          <ng-container *ngTemplateOutlet="suboptions; context: {item: item}"></ng-container>
                        </mat-menu>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <!-- layers settings -->
                  <!-- symobology data  -->
                  <ng-container *ngIf="item.symobology">
                    <div *ngIf="item.symobology.length; else local">
                      <div *ngFor="let icon of item.symobology">
                        <div *ngIf="icon.symbol.style === 'esriSMSCircle'">
                          <mat-list role="list">
                            <mat-list-item role="listitem" class="d-flex f-14"><mat-icon class="f-14 wh-14" [style]="setRBG(icon)">circle</mat-icon> <span class="ml-5 a-self-center">{{icon.label}}</span></mat-list-item>
                          </mat-list>
                        </div>
                        <div *ngIf="icon.symbol.style === 'esriSFSSolid'">
                          <mat-list role="list">
                            <mat-list-item role="listitem" class="d-flex f-14"><mat-icon class="f-14 wh-14" [style]="setRBG(icon)">folder</mat-icon> <span class="ml-5 a-self-center">{{icon.label ? icon.label : item.name}}</span></mat-list-item>
                          </mat-list>
                        </div>
                      </div>
                    </div>
                    <ng-template #local>
                      <ng-container *ngIf="item.symobology.type">
                        <ng-container [ngSwitch]="item.symobology.type">
                          <ng-container *ngSwitchCase="'simple'">
                            <div *ngIf="item.symobology.symbol.style === 'esriSMSCircle'; else nonStyle">
                              <mat-list role="list">
                                <mat-list-item role="listitem" class="d-flex f-14"><mat-icon class="f-14 wh-14" [style]="setRBG(item.symobology)">circle</mat-icon> <span class="ml-5 a-self-center">{{item.name}}</span></mat-list-item>
                              </mat-list>
                            </div>
                            <ng-template #nonStyle>
                              <div *ngIf="item.symobology.symbol.color">
                                <mat-list role="list">
                                  <mat-list-item role="listitem" class="d-flex"><mat-icon [style]="setRBG(item.symobology)">folder</mat-icon> <span class="ml-5 a-self-center">{{item.name}}</span></mat-list-item>
                                </mat-list>
                              </div>
                              <div *ngIf="item.symobology.symbol.imageData">
                                <mat-list role="list">
                                  <mat-list-item role="listitem" class="d-flex"><span>Symbool:</span> <img class="ml-5 a-self-center w-25p" [src]="safeURL('data:image/png;base64,'+item.symobology.symbol.imageData)"></mat-list-item>
                                </mat-list>
                              </div>
                            </ng-template>
                          </ng-container>
                          <ng-container *ngSwitchCase="'uniqueValue'">
                            <div *ngIf="item.symobology.uniqueValueInfos;">
                              <mat-list role="list" *ngFor="let symb of item.symobology.uniqueValueInfos">
                                <div *ngIf="symb.symbol">
                                  <mat-list-item role="listitem" class="d-flex"><mat-icon [style]="setRBG(symb)">folder</mat-icon> <span class="ml-5 a-self-center">{{symb.label ? symb.label:item.name}}</span></mat-list-item>
                                </div>
                              </mat-list>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <div *ngIf="item.symobology;">
                              <mat-list role="list" *ngFor="let symb of item.symobology">
                                <div *ngIf="symb.symbol">
                                  <mat-list-item role="listitem" class="d-flex"><mat-icon [style]="setRBG(symb)">folder</mat-icon> <span class="ml-5 a-self-center">{{symb.label ? symb.label:item.name}}</span></mat-list-item>
                                </div>
                              </mat-list>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                  <!-- transparency -->
                  <ng-template  #suboptions let-action="item">
                    <div class="p-custom-menu">
                      <div class="d-flex j-space-between" (click)="$event.stopPropagation();">
                        <span class="a-self-center f-12 d-flex"><mat-icon class="mr-5">visibility</mat-icon> <span class="a-self-center">{{'maps.transparency' | transloco}}</span></span>
                        <mat-slider min="0" max="1" step="0.1" discrete formatLabel>
                          <input matSliderThumb [(ngModel)]="layer.transparency"
                                 (ngModelChange)="onChangeSingleStyle((item.layer_name ? item.name+'-'+item.layer_name : item.name),$event,'transparency',item,layer)"
                                 thumbLabel>
                        </mat-slider>
                        <!-- <mat-slider #sliderInput [disabled]="!item.is_active" class="a-self-center" min="0" max="1" step="0.1" [value]="item.transparency" thumbLabel (change)="onChangeSingleStyle((item.layer_name ? item.name+'-'+item.layer_name : item.name),$event,'transparency',item,layer)"></mat-slider> -->
                      </div>
                      <!-- color -->
                      <div class="d-flex j-space-between" (click)="$event.stopPropagation();">
                          <span class="a-self-center f-12 d-flex"><mat-icon class="mr-5">palette</mat-icon> <span class="a-self-center">{{'maps.color' | transloco}}</span></span>
                          <input *ngIf="item.metadata && item.metadata.color" [disabled]="!(item.is_active && hasRole('Project Admin'))" type="color" class="color-input" [(ngModel)]="item.metadata.color" (change)="onChangeSingleStyle((item.layer_name ? item.name+'-'+item.layer_name : item.name),$event,'color',item,layer)"/>
                          <input *ngIf="!item.metadata || !item.metadata.color" [disabled]="!(item.is_active && hasRole('Project Admin'))" type="color" class="color-input" [(ngModel)]="item.color" (change)="onChangeSingleStyle((item.layer_name ? item.name+'-'+item.layer_name : item.name),$event,'color',item,layer)"/>

                      </div>
                      <!-- Zoom Level -->
                      <div class="d-flex j-space-between" (click)="$event.stopPropagation();">
                          <span class="a-self-center f-12 d-flex"><mat-icon class="mr-5">center_focus_strong</mat-icon> <span class="a-self-center">{{'maps.zoom_level' | transloco}}</span></span>
                          <!-- <mat-slider [disabled]="!(item.is_active && hasRole('Project Admin'))" class="a-self-center"  min="1" max="20" step="1" [value]="item.metadata ? item.metadata.zoom ? item.metadata.zoom : item.zoom : item.zoom" thumbLabel (change)="onChangeSingleStyle((item.layer_name ? item.name+'-'+item.layer_name : item.name),$event,'zoom',item,layer)"></mat-slider> -->
                          <mat-slider min="1" max="20" step="1" discrete formatLabel>
                            <input matSliderThumb [(ngModel)]="layerZoomValue"
                                    (ngModelChange)="onChangeSingleStyle((item.layer_name ? item.name+'-'+item.layer_name : item.name),$event,'zoom',item,layer)"
                                   thumbLabel>
                          </mat-slider>
                      </div>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
                <mat-divider class="z-99" *ngIf="i < layer.sublayers.length-1"></mat-divider>
              </div>
            </ng-container>
            <div *ngIf="hasRole('Project Admin')" class="row">
              <div class="col-md-6 col-xs-12">
                <button *ngIf="layer.type=='public_arcgis'|| layer.type=='wfs'|| layer.type=='wms'" class="default-button-style capital" mat-stroked-button (click)="onEditOpenLayer(layer.id)">
                  <span>{{'update' | transloco }}</span>
                </button>
              </div>
              <div class="col-md-6 col-xs-12">
                <button *ngIf="layer.type=='public_arcgis'|| layer.type=='wfs'|| layer.type=='wms'" class="default-button-style" mat-stroked-button (click)="onDeleteOpenLayer(layer.id)">
                  <span>{{'maps.remove_layer' | transloco}}</span>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
      <mat-divider class="z-99" *ngIf="i < layers.length-1"></mat-divider>
    </ng-container>
  </div>
</div>

