import collect from "collect.js";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import MultiPolygon from "ol/geom/MultiPolygon";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import TileWMS from 'ol/source/TileWMS';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from "ol/style";
import OverPassGroups from '../constants/overpass-groups-types.json';
import OverPass from '../constants/overpass-groups.json';
import EsriJSON from 'ol/format/EsriJSON.js';
import {createXYZ} from 'ol/tilegrid.js';
import {tile as tileStrategy} from 'ol/loadingstrategy.js';
import { isExtent } from "src/app/features/main-app/map/page/map/map.component";
declare let ol: any;
export function openLayerFill(color,hash=true){
  if(hash){
    // eslint-disable-next-line no-use-before-define
    return new Fill({color: hashtorgb(color)});
  }
  return new Fill({color: color});
}
export function openLayerStroke(color){
    return new Stroke({color: color,width: 1.25,});
}
export function openStyle(color="#3399CC"){
    return [ new Style(
        {
            image: new CircleStyle({fill: openLayerFill(color), stroke: openLayerStroke(color), radius: 5,}),
            fill: openLayerFill(color),
            stroke: openLayerStroke(color),
        }),
    ];
}
//Sub Layer Maping
export function subLayersAccordionMap(layers,unique,type): any{
    const uniqueLayerName: any = collect(layers).unique(unique).all();
    uniqueLayerName.map(unique => {
        const flag=[];
        unique.active = false;
        layers.forEach(el => {
            if(unique.name == el.name){
              el.type=type;
              el.is_active=false;
              el.color=el.metadata ? el.metadata.color : "#3399CC";
              el.zoom=20;
              el.transparency=1;
              // eslint-disable-next-line no-unused-expressions
              el.error = false,
              flag.push(el);
              unique.sublayers=flag;
            }
        });
    });
    return uniqueLayerName;
}
//subproject layer map
export function subprojectLayerMap(subProject,type){
    const subproject=[{type:'subproject',name:'Subproject',is_active:false,sublayers:subProject}]
    subproject.forEach(element => {
        element.sublayers.forEach(el => {
            el.type=type;
            el.is_active=false;
            el.zoom=20;
            el.transparency=1;
        });
    });
    return subproject;
}
//open layer custome styling set
export function setCustomeStyle(layerName,event,type): any{
    switch(type){
        case 'color':
            return {type:type,name:layerName,color:event.target.value}
            break;
        case 'transparency':
            return {type:type,name:layerName,transparency:event}
            break;
        case 'zoom':
            return {type:type,name:layerName,zoom:event}
            break;
        default:

    }
}
//open layer custome styling set
export function setFeatureStyle(data,layer): any{
    switch(data.type){
        case 'color':
            layer.setStyle(openStyle(data.color))
            break;
        case 'transparency':
            layer.setOpacity(data.transparency);
            break;
        case 'zoom':
            layer.setMaxZoom(data.zoom);
            break;
        default:

    }
}
//set style on child items
export function setStyleFromParent(layer,event,type): any{
    switch(type){
        case 'color':
            layer.color=event.target.value;
            break;
        case 'transparency':
            layer.transparency=event.value;
            break;
        case 'zoom':
            layer.zoom=event.value;
            break;
        default:
            return;
    }
    return layer;
}
//hasht to rgb  "#3399CC"
export function hashtorgb(color){
    if(!color.length){
        color='#3399CC';
    }
    color=color.replace('#','')
    const aRgbHex = color.match(/.{1,2}/g);
    return `rgba(${parseInt(aRgbHex[0], 16)}, ${parseInt(aRgbHex[1], 16)}, ${parseInt(aRgbHex[2], 16)}, 0.5)`
}
//layer style saving for responce
export function styleSavingResponce(item,event,type){
    delete item.sublayers
    const metadata={};
    switch(type){
        case 'color':
            Object.assign(metadata, {color: event.target.value});
            if(item.metadata && item.metadata.zoom){
                Object.assign(metadata, {zoom: item.metadata.zoom});
            }
            break;
        case 'zoom':
            Object.assign(metadata, {zoom: event.value});
            if(item.metadata && item.metadata.color){
                Object.assign(metadata, {color: item.metadata.color});
            }
            break;
        default:
            return item;
    }
    item.metadata=metadata;
    return item;
}
//add style on main layer
export function addStyleOnMainLayer(layer,item,event,type){
    switch(type){
        case 'color':
            layer.color=event.target.value;
            break;
        case 'transparency':
            layer.transparency=event.value;
            break;
        case 'zoom':
            layer.zoom=event.value;
            break;
        default:
            return layer;
    }
    return layer;
}
//add style on main layer
export function addStyleOnMainLayerToSave(layer,item,event,type){
    const metadata={};
    switch(type){
        case 'color':
            Object.assign(metadata, {color: event.target.value});
            if(layer.metadata && layer.metadata.zoom){
                Object.assign(metadata, {zoom: layer.metadata.zoom});
            }
            break;
        case 'zoom':
            Object.assign(metadata, {zoom: event.value});
            if(layer.metadata && layer.metadata.color){
                Object.assign(metadata, {color: layer.metadata.color});
            }
            break;
        default:
            return layer;
    }
    layer.metadata=metadata;
    return layer;
}
//layer style saving for responce for open data
export function openLayerstyleSavingResponce(layer,item,event,type){
    const metadata={};
    delete layer.sublayers
    layer.layers.forEach(el => {
        if(el.name===item.name){
            switch(type){
                case 'color':
                    Object.assign(metadata, {color: event.target.value});
                    if(el.metadata && el.metadata.zoom){
                        Object.assign(metadata, {zoom: el.metadata.zoom});
                    }
                    break;
                case 'zoom':
                    Object.assign(metadata, {zoom: event.value});
                    if(el.metadata && el.metadata.color){
                        Object.assign(metadata, {color: el.metadata.color});
                    }
                    break;
                default:
                    return layer;
            }
            el.metadata=metadata;
        }
    });
    return layer;
}
//regular expration get the right URL
export function getRegExpURL(url,srtext){
    const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    url=url.replace(new RegExp(regEscape(srtext), "ig"),'');
    url=url.replace(new RegExp(regEscape('request=GetCapabilities'), "ig"),'');
    url=url.replace(new RegExp(regEscape('&'), "ig"),'');
    return url;
}
//set symobology styling
export function setSymobologyStyleColor(symobology,outline=false){
  if(outline){
    if(symobology.symbol.outline){
      return `rgba(${symobology.symbol.outline.color[0]},${symobology.symbol.outline.color[1]},${symobology.symbol.outline.color[2]},${ symobology.symbol.outline.color[3] ? symobology.symbol.outline.color[3] :+'255'})`;
    }
    return `rgba(${symobology.symbol.color[0]},${symobology.symbol.color[1]},${symobology.symbol.color[2]},${symobology.symbol.color[3] ? symobology.symbol.color[3] :+'255'})`;
  }
  if (symobology.symbol.color) {
    return `rgba(${symobology.symbol.color[0]},${symobology.symbol.color[1]},${symobology.symbol.color[2]},${symobology.symbol.color[3] ? symobology.symbol.color[3] :+'255'})`;
  }
  if (symobology.symbol.imageData) {
    return `rgba(255,255,0,0)`
  }
}
//set multiple symobology styling

//overpass api set groups
export function overpassApiSetGroups(features): any{
  if(features.length){
    features.map(feature=>{
      OverPass.forEach(res => {
        if(feature.values){
          if(feature.values.relevant){
            if(feature.values.tag){
              if(feature.values.tag.amenity){
                if(feature.values.tag.amenity==res.element){
                  feature.group_type=res.group_type;
                  feature.group_label=res.group_label;
                }
              }else{
                if(feature.values.tag.shop){
                  feature.group_type='retail';
                  feature.group_label='retail';
                }else if(feature.values.tag.population){
                  feature.group_type='boundaries';
                  feature.group_label='boundaries';
                }else{
                  feature.group_type='';
                  feature.group_label='';
                }
              }
            }
          }else{
            feature.group_type='point_of_interest';
            feature.group_label='point_of_interest';
          }
        }
      });
    })
  }
  return features;
}
export function overpassDefaultcount(): any{
  OverPassGroups.forEach(res => {
    res.count=0;
  });
}
//overpass api set groups count info
export function overpassApiSetGroupsTypes(features): any{
  overpassDefaultcount();
  if(features.length){
    features.map(feature=>{
      OverPassGroups.forEach(res => {
        if(feature.group_type==res.type){
          res.count+=1;
        }
      });
    })
  }
  return OverPassGroups;
}
//set function to set icons on map
export function setItemsIcons(feature, isStrategicMethod=false): string{
  if (feature.get("urgency")) {
    const urgency = feature.get("urgency");
    if (urgency == "very-high") return "-very-high.png";
    if (urgency == "high") return "-high.png";
    if (urgency == "middle") return "-medium.png";
    if (urgency == "low") return "-low.png";
  }
  if (feature.get("strategy")) {
    const strategy = feature.get("strategy");
    // stakeholder Power/Interest method
    if (strategy == "Ongecategoriseerd" && !isStrategicMethod) return ".png";
    if (strategy == "Informeren" && !isStrategicMethod) return "-inform.png";
    if (strategy == "Tevreden houden") return "-keepsatisfied.png";
    if (strategy == "Samenwerken") return "-cooperate.png";
    if (strategy == "Monitoren") return "-monitor.png";
    // stakeholder Strategic method
    if (strategy == "Ongecategoriseerd" && isStrategicMethod) return "-none.png";
    if (strategy == "Informeren" && isStrategicMethod) return "-inform-strategic.png";
    if (strategy == "Reactief") return "-reactive.png";
    if (strategy == "Adviseren") return "-advice.png";
    if (strategy == "Raadplegen") return "-consult.png";
    if (strategy == "Coproduceren") return "-coproduce.png";
    if (strategy == "Meebeslissen") return "-codecide.png";
  }
  if (feature.get("status")) {
    const status = feature.get("status");
    if (status == "initial") return "-open.png";
    if (status == "gehonoreerd") return "-honoured.png";
    if (status == "expired") return "-expired.png";
    if (status == "next_phase") return "-next_phase.png";
    if (status == "rejected" || status == "afgewezen") return "-rejected.png";
  }
  if (feature.get("is_closed") == 0 || feature.get("is_closed") == 1) {
    const status = feature.get("is_closed");
    if (status == true) return "-closed.png";
    if (status == false) return "-open.png";
  }
  if (feature.get("types") && feature.get("types").length) {
    let color = feature.get("types")[0].color;
    if (color) color = color.replace("#", "");
    return `-${color || "4A9ED6"}.png`;
  }
  return "";
}
//get coardinates from multipolygon
export function getProjectCords(multiPolygon): any{
  let coord = multiPolygon.coordinates[0][0];
  coord = coord[0]
  return ol.proj.transform(coord,'EPSG:4326', 'EPSG:3857');
}
//on arcgis integration symbology
export function arcGisIntegrationSymbology(response): boolean{
  if(response){
    if(response.layerDefinition){
      if(response.layerDefinition.drawingInfo){
        if(response.layerDefinition.drawingInfo.renderer){
          return true;
        }
      }
    }
  }
  return false;
}
//on arcgis integration symbology default
export function arcGisIntegrationSymbologyDefault(response): any{
  if(response.layerDefinition.drawingInfo.renderer.defaultSymbol){
    response.layerDefinition.drawingInfo.renderer.uniqueValueInfos.push({
      label:response.layerDefinition.drawingInfo.renderer.defaultLabel,
      symbol:response.layerDefinition.drawingInfo.renderer.defaultSymbol
    });
  }
 return collect(response.layerDefinition.drawingInfo.renderer.uniqueValueInfos).unique((item=>item.label)).all();
}
export function setTransprantFeatures(): any{
  return new Style({
    stroke: new Stroke({ color: 'rgba(255,255,0,0)' }),
    fill: new Fill({ color: 'rgba(255,255,0,0)' }),
  });
}

export function openLayerSymobology(symobology,integration=false){
  if(symobology.symbol){
    if(symobology.symbol.color){
      return [ new Style(
        {
            image: new CircleStyle({fill: openLayerFill(setSymobologyStyleColor(symobology),false), stroke: openLayerStroke(setSymobologyStyleColor(symobology,true)), radius: 5,}),
            fill: openLayerFill(setSymobologyStyleColor(symobology),false),
            stroke: openLayerStroke(setSymobologyStyleColor(symobology,true)),
        }),
      ];
    }else if(symobology.symbol.imageData){
      return [ new Style(
        {
          image: new Icon({
            imgSize: integration ? [50, 50] : [symobology.symbol.width, symobology.symbol.height],
            offset:  symobology.symbol.xoffset ? [symobology.symbol.xoffset, symobology.symbol.yoffset] : [0,0],
            scale: 0.4,
            opacity: 1.0,
            src: `data:image/png;base64,${symobology.symbol.imageData}`
          })
        }),
      ];
    }
       return openStyle();

  }
}
/*
  Check if the Value Exist in the Feature
*/
export function containsValue(obj, targetValue) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (value === targetValue) {
        return true;
      }

      if (typeof value === 'object' && containsValue(value, targetValue)) {
        return true;
      }
    }
  }

  return false;
}
export function setMultipleSymobologyStyleColor(feature,symbology,type){
  for (let index = 0; index < symbology.length; index++) {
    if(feature.values_){
      if(type === 'arcgis'){
        // for Layer Lamburg 15 for bel_rech00 items
        if(containsValue(feature.values_, symbology[index].label)){
          feature.setStyle(openLayerSymobology(symbology[index]));
          break;
        }else{
          if('Other' === symbology[index].label){
            feature.setStyle(openLayerSymobology(symbology[index]));
          }
        }
      }else{
        if(feature.values_.SOORT==symbology[index].label){
          feature.setStyle(openLayerSymobology(symbology[index]));
        }
      }
    }
  }
}
export function createMultiPolygon(layer){
    const geom = new MultiPolygon(layer.polygons.coordinates);
    // @ts-ignore
    const preloadedCoordinates = geom.transform("EPSG:4326", "EPSG:3857").getCoordinates();
    const feature = new Feature({geometry: new MultiPolygon(preloadedCoordinates)});
    return feature;
}
//layers source map
export function openDataSource(type,layer,sublayer_name='',arcGisToken='',geojsonArcGisType='',layer_id=0,bbox=[], _ref?:any){
    let url
    switch(type){
      case 'arcgis':
        // eslint-disable-next-line no-empty
        if (isExtent) {
          return new VectorSource({
            url: function (extent, resolution, projection) {
              const srid = projection.getCode().split(/:(?=\d+$)/).pop();
              // eslint-disable-next-line no-multi-spaces
              const url = `${layer.url}/${layer.layer_id}/query?where=1%3D1&outFields=*&resultType=standard&returnGeometry=true&f=json&token=${arcGisToken}&geometry=${encodeURIComponent(`{"xmin":${ extent[0]  },"ymin":${  extent[1]  },"xmax":${  extent[2]  },"ymax":${  extent[3]  },"spatialReference":{"wkid":${  srid  }}}`)  }&spatialRel=esriSpatialRelIntersects&geometryType=esriGeometryEnvelope&inSR=${  srid  }&outSR=${  srid}`;
              return url
            },
            format: new EsriJSON(),
            strategy: tileStrategy(
              createXYZ({
                tileSize: 256,
              })
            ),
          })
        }
        if (!isExtent) {
          return new VectorSource({
            url: `${layer.url }/${ layer.layer_id }/query?where=1%3D1&outFields=*&resultType=standard&returnGeometry=true&f=geojson&token=${ arcGisToken}`,
            format: new GeoJSON(),
          })
        }
        case 'public_arcgis':
            return new VectorSource({
                url: `${layer.url }/${ layer_id }/query?where=1%3D1&outFields=*&resultType=standard&returnGeometry=true&f=geojson`,
                format: new GeoJSON(),
            })
        case 'subproject':
            return new VectorSource({
                features:[createMultiPolygon(layer.locations)],
                format: new GeoJSON(),
            })
        case 'wfs':
          url=getRegExpURL(layer.url,'service=wfs');
          return new VectorSource({
            url: function(extent) {
                return `${url}service=WFS&` +
                    `version=${layer.version}&request=GetFeature&typename=${sublayer_name}&` +
                    `outputFormat=application/json&srsname=EPSG:3857&` +
                    `bbox=${bbox},EPSG:3857`;
              },
            format: new GeoJSON(),
          })
        case 'wms':
          url=getRegExpURL(layer.url,'service=wms');
            return new TileWMS({
                url: url,
                params: {
                    'LAYERS':sublayer_name,
                },
                crossOrigin: 'anonymous',
            })
        default:

    }
}
export function openLayerPrivateMaping(type,enableLayers,layer,sublayer_name='',arcGisToken='',geojsonArcGisType='',metadata:any={},bbox=[], features: any): any{
  switch(type){
    case 'wms':
        enableLayers[sublayer_name] = new TileLayer({
            extent: bbox,
            name: layer.name,
            layer_type:type,
            sublayer_name: sublayer_name,
            // @ts-ignore
            source: openDataSource(type,layer,sublayer_name,arcGisToken,geojsonArcGisType),
            style: (metadata ? (metadata.color ? openStyle(metadata.color) : openStyle()) : (layer.metadata ? openStyle(layer.metadata.color) : openStyle())),
            maxZoom: (metadata ? (metadata.zoom ? metadata.zoom : 20) : (layer.metadata ? layer.metadata.zoom : 20)),
            visible: false,
            credential: true,
        });
        return enableLayers[sublayer_name];
    case 'wfs':
        enableLayers[sublayer_name] = new VectorLayer({
            // @ts-ignore
            name: layer.name,
            layer_type:type,
            sublayer_name: sublayer_name,
            source: new VectorSource({
              features: new GeoJSON().readFeatures(features)
            }),
            style: (metadata ? (metadata.color ? openStyle(metadata.color) : openStyle()) : (layer.metadata ? (layer.metadata.color ? openStyle(layer.metadata.color) : openStyle()) : openStyle())),
            maxZoom: (metadata ? (metadata.zoom ? metadata.zoom : 20) : (layer.metadata ? (layer.metadata.zoom ? layer.metadata.zoom : 20) : 20)),
            visible: false,
            credential: true,
        });
        return enableLayers[sublayer_name];
    default:
        return [];
 }
}
//Private ArcGis Layers Mapping

function convertToObject(inputArray) {
  const resultObject = {};

  if(inputArray && inputArray.length) {
    inputArray.forEach(item => {
      const label = item.label;
      const color = item.symbol.color;
      const outlineColor = item.symbol?.outline?.color;

      // Assuming label is unique and corresponds to the key in fillColors
      resultObject[label] = {
        color: color,
        outline: outlineColor
      };
    });
  }


  return resultObject;
}

export function containsValue1(feature, symbology) {
  for (const key in feature) {
    const value = feature[key]
    if (symbology.hasOwnProperty(value)){
      return value;
    }

    // if (typeof value === 'object' && containsValue1(value, symbology)) {
    //   return value
    // }
  }
  return 'Other';
}

export function openLayerMaping(type,enableLayers,layer,sublayer_name='',arcGisToken='',geojsonArcGisType='',layer_id=0,metadata:any={},bbox=[],symbology:any={}, _ref?: any): any{
    switch(type){
        case 'arcgis':
          const fillColorsSymbology = convertToObject(symbology);
          const style = new Style({
            image: new CircleStyle({fill: new Fill(), stroke: new Stroke({color: [0,0,0,1], width: 0.5}), radius: 5}),
            fill: new Fill(),
            stroke: new Stroke({
              color: [0, 0, 0, 1],
              width: 0.5,
            }),

          });

          enableLayers[sublayer_name] = new VectorLayer({
              name: layer.name,
              layer_type:type,
              sublayer_name: sublayer_name,
              // @ts-ignore
              source: openDataSource(type,layer,sublayer_name,arcGisToken,geojsonArcGisType,layer_id,bbox),
              style:

              symbology.length ?

              function (feature) {
                // @ts-ignore
                const classify = containsValue1(feature.values_, fillColorsSymbology);

                const color = fillColorsSymbology[classify] || {color: symbology? symbology.symbol?.color : [165, 83, 183, 255], outline: symbology? symbology.symbol?.outline : [165, 83, 183, 255]};
                const newImage = new CircleStyle({
                  fill: new Fill({
                    color: color.color
                  }),
                  stroke: new Stroke({ color: color.color, width: 0.5 }),
                  radius: 5
                });
                style.setImage(newImage);

                style.getFill().setColor(color.color);
                style.getStroke().setColor(color.outline);
                return style;
              } :
              Object.keys(symbology).length ? openLayerSymobology(symbology,true) :
              (layer.metadata ? (layer.metadata.color ? openStyle(layer.metadata.color) : openStyle()) : openStyle()),

              maxZoom: (layer.metadata ? (layer.metadata.zoom ? layer.metadata.zoom : 20) : 20),
              visible: false,
              opacity: 0.6
          });
          return enableLayers[sublayer_name];
        case 'subproject':
            enableLayers[layer.name] = new VectorLayer({
                name: layer.project,
                layer_type:type,
                sublayer_name: layer.name,
                // @ts-ignore
                source: openDataSource(type,layer,sublayer_name,arcGisToken,geojsonArcGisType),
                style: openStyle(layer.color),
                visible: false,
            });
            return enableLayers[layer.name];
        case 'wms':
            enableLayers[sublayer_name] = new TileLayer({
                extent: bbox,
                name: layer.name,
                layer_type:type,
                sublayer_name: sublayer_name,
                // @ts-ignore
                source: openDataSource(type,layer,sublayer_name,arcGisToken,geojsonArcGisType),
                style: (metadata ? (metadata.color ? openStyle(metadata.color) : openStyle()) : (layer.metadata ? openStyle(layer.metadata.color) : openStyle())),
                maxZoom: (metadata ? (metadata.zoom ? metadata.zoom : 20) : (layer.metadata ? layer.metadata.zoom : 20)),
                visible: false,
            });
            return enableLayers[sublayer_name];
        case 'wfs':
            enableLayers[sublayer_name] = new VectorLayer({
                name: layer.name,
                layer_type:type,
                sublayer_name: sublayer_name,
                // @ts-ignore
                source: openDataSource(type,layer,sublayer_name,arcGisToken,geojsonArcGisType,layer_id,bbox, _ref),
                style: (metadata ? (metadata.color ? openStyle(metadata.color) : openStyle()) : (layer.metadata ? (layer.metadata.color ? openStyle(layer.metadata.color) : openStyle()) : openStyle())),
                maxZoom: (metadata ? (metadata.zoom ? metadata.zoom : 20) : (layer.metadata ? (layer.metadata.zoom ? layer.metadata.zoom : 20) : 20)),
                visible: false,
            });
            return enableLayers[sublayer_name];
        case 'public_arcgis':
            enableLayers[sublayer_name] = new VectorLayer({
                name: layer.name,
                layer_type:type,
                sublayer_name: sublayer_name,
                // @ts-ignore
                source: openDataSource(type,layer,sublayer_name,arcGisToken,geojsonArcGisType,layer_id,bbox),
                style: openLayerSymobology(symbology),
                maxZoom: (metadata ? (metadata.zoom ? metadata.zoom : 20) : (layer.metadata ? (layer.metadata.zoom ? layer.metadata.zoom : 20) : 20)),
                visible: false,
                symbology: symbology
            });
            return enableLayers[sublayer_name];
        default:
            return [];
    }
}
