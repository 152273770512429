import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Action } from './action.model';

export interface ActionState extends EntityState<Action>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'actions' })
export class ActionsStore extends EntityStore<ActionState, Action> {

  constructor() {
    super();
  }

}

