import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageConfirmComponent } from '../message-confirm/message-confirm.component';

@Component({
  selector: 'app-required-msg-modal',
  templateUrl: './required-msg-modal.component.html',
  styleUrls: ['./required-msg-modal.component.sass'],
})
export class RequiredMsgModalComponent {

  constructor(public dialogRef: MatDialogRef<MessageConfirmComponent>,
    // eslint-disable-next-line no-empty-function
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  close() {
    this.dialogRef.close();
  }
}