<div class="row" *transloco="let t">
    <div class="col-xs-12">
        <div *ngIf="!editable" class="d-flex">
            <div class="full-width selection" id="theme">
              <app-generic-multiselect-checkbox name="theme" [multiple]="mulitple" [placeholder]="textSingle | capitaliz" type="themes" [(ngModel)]="selectedTheme" (ngModelChange)="updateTheme()" [maxChipItems]="4" [disabled]="disabled" [valueType]="valueType" [maxChipLength]="20" [uniqueId]="uniqueId">
                  <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                    <app-generic-multiselect-checkbox-option *ngFor="let theme of themes" [value]="theme">
                        {{ type == 'interests' ? theme.description : theme.name }}
                    </app-generic-multiselect-checkbox-option>
                    <app-generic-multiselect-checkbox-option-new-action [type]="type" type="type" *ngIf="((type== 'permitstypes')|| (type== 'permitsrequesters')|| (type== 'permitsclusters')|| (type== 'permitsvalidities')|| (type== 'permitsfillingrequirements')|| (hasPermission(Permission.StakeholdersUpdate) && (type=='stakeholdertypes' || type == 'interests')) || (hasPermission(Permission.DecisionsPhasesCRUD) && type=='phases') || (hasPermission(Permission.IssuesUpdate) && type=='themeissues') || (hasPermission(Permission.DecisionsUpdate) && type == 'themedecisions') || (hasPermission(Permission.ComplaintsUpdate) && type == 'complaintthemes') || (hasPermission(Permission.MiddelUpdate) && type == 'themetype') || (hasPermission(Permission.ActivitiesUpdate) && type == 'types?group=activities')) && !disabled && hasPermission(Permission.StakeholdersTypeCRUD)"></app-generic-multiselect-checkbox-option-new-action>
                    <app-generic-multiselect-search-project entity="type"></app-generic-multiselect-search-project>
                </app-generic-multiselect-checkbox>
            </div>
            <div class="d-flex" *ngIf="((hasPermission(Permission.StakeholdersUpdate) && (type=='stakeholdertypes' || type == 'interests')) || (hasPermission(Permission.DecisionsPhasesCRUD) && type=='phases') || (hasPermission(Permission.IssuesUpdate) && type=='themeissues') || (hasPermission(Permission.DecisionsUpdate) && type == 'themedecisions') || (hasPermission(Permission.ComplaintsUpdate) && type == 'complaintthemes') || (hasPermission(Permission.MiddelUpdate) && type == 'themetype') || (hasPermission(Permission.ActivitiesUpdate) && type == 'types?group=activities')) && !disabled && hasPermission(Permission.StakeholdersTypeCRUD)">
                <a *ngIf="editButton" [matTooltip]="t(buttonText | transloco | capitaliz)" class="edit-themes-latest d-cursor-hand" id="edit-theme-button" data-target="#modal-new-theme" (click)="openModal()">
                    <mat-icon class="mat-flat-button-icon-margin edit-themes-icon">edit</mat-icon>
                </a>
            </div>
        </div>
        <div *ngIf="editable">
          <mat-form-field *ngIf="loaded" class="full-width" id="theme">
            <mat-label>{{ textSingle | capitaliz }}</mat-label>

            <!-- Mat select for selecting the theme -->
            <mat-select #mySelect [(value)]="selectedTheme" (selectionChange)="updateTheme()" [disabled]="disabled">
              <mat-option *ngIf="!themes.length" [value]=""></mat-option>
              <mat-option *ngFor="let theme of themes" [value]="theme">
                <span class="theme-name">{{ type == 'interests' ? theme.description : theme.name }}</span>
                <mat-icon *ngIf="selectedTheme && theme.id === selectedTheme.id && mySelect.panelOpen" class="radio-button">
                  radio_button_checked
                </mat-icon>
                <mat-icon *ngIf="!selectedTheme || theme.id !== selectedTheme.id" class="radio-button">
                  radio_button_unchecked
                </mat-icon>
              </mat-option>

              <!-- Edit themes button -->
              <button *ngIf="editable" mat-stroked-button type="button" class="mat-button-dialog edit-themes" id="edit-theme-button" data-target="#modal-new-theme" (click)="openModal()" [disabled]="!editable">
                <mat-icon class="mat-flat-button-icon-margin edit-themes-icon">edit</mat-icon>
                {{ buttonText | transloco | capitaliz }}
              </button>
            </mat-select>
          </mat-form-field>


        </div>
    </div>
</div>

<!-- Demands modal -->
<div class="modal-themes fade" [ngClass]="'modal-themes-'+randomId" data-backdrop="static" id="modal-new-theme" tabindex="-1" *transloco="let t" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog themes-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close($event)">
          <mat-icon class="uil uil-multiply"></mat-icon>
          <span class="sr-only">Sluit</span>
        </button>
                <h3 class="modal-title">{{textMultiple | capitaliz}}</h3>
            </div>

            <div #modalbody class="modal-body modal-down-rapportage themes-modal modal-dialog-scrollable">
                <div class="form-group w-100">
                    <span *ngIf="themes.length === 0">
              {{'text.no'  | transloco}} {{textSingle}} {{'found_it' | transloco}}.
            </span>
            <data *ngFor="let theme of themes" class="col-xs-12 pd-0">
              <app-color-select [selectedColor]="theme.color || '#4A9ED6'" (onSelect)="changeColor(theme,$event)" class="col-xs-1" *ngIf="type=='types?group=activities'">

              </app-color-select>
                <mat-form-field class="col-xs-10">
                  <mat-label>{{textSingle | capitaliz}}</mat-label>
                  <ng-container *ngIf="type == 'interests'; else name">
                    <input matInput  [(ngModel)]="theme.description" [disabled]="theme.source=='api'" [matTooltip]="theme.source=='api' ? t('settings.bouwapp_theme') : ''">
                  </ng-container>
                  <ng-template #name>
                    <input matInput  [(ngModel)]="theme.name" [disabled]="theme.source=='api'" [matTooltip]="theme.source=='api' ? t('settings.bouwapp_theme') : ''">
                  </ng-template>
                </mat-form-field>
                <a class="btn col-xs-1 del-btn">
                  <mat-icon class="material-icons d-cursor-hand uil uil-trash" *ngIf="theme.source!='api'" (click)="deleteExistingTheme(theme)" [matTooltip]="t('delete_this')+' '+textSingle"></mat-icon>
                </a>
            </data>
            <data *ngFor="let theme of new;let index = index" class="col-xs-12 pd-0">
              <app-color-select [selectedColor]="theme.color || '#4A9ED6'" (onSelect)="changeColor(theme,$event)" class="col-xs-1" *ngIf="type=='types?group=activities'">
              </app-color-select>
                <mat-form-field class=" col-xs-10">
                <mat-label>{{textSingle | capitaliz}}</mat-label>
                <ng-container *ngIf="type == 'interests'; else nameAdd">
                  <input matInput [(ngModel)]="theme.description">
                </ng-container>
                <ng-template #nameAdd>
                  <input matInput [(ngModel)]="theme.name">
                </ng-template>
              </mat-form-field>
              <a class="btn col-xs-1 del-btn" *transloco="let t;">
                <mat-icon class="material-icons d-cursor-hand uil uil-trash" (click)="deleteNewTheme(theme)" [matTooltip]="t('delete_this')+' '+textSingle"></mat-icon>
              </a>
            </data>
                </div>
            </div>
            <div class="modal-footer" *transloco="let t;">
                <a [matTooltip]="t('add') +' '+textSingle+' '" class="new-theme pull-start" (click)="newTheme()" style="margin-top: 7px">
                    <mat-icon class="mat-flat-button-icon-margin add_circle">add_circle</mat-icon>
                    <span style="margin-left: 10px;" class="new">{{'add' | transloco | capitaliz}} {{textSingle}}</span>
                </a>
                <button mat-stroked-button class="text-capitalize close-btn" (click)="close()">{{ t('cancel') }}</button>
                <button class="text-capitalize" type="button" mat-flat-button (click)="submit()">{{ t('save') }}</button>
            </div>
        </div>
    </div>
</div>
