import { translate } from '@jsverse/transloco';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Globals } from "../../../../../globals";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatSelectChange } from "@angular/material/select";
import { dateIso } from "../../../../../core/util/dateIso";
import moment from "moment";
import { GeneralService } from "../../../../../core/service/api/general.service";
import { GenericComponent } from "../../../../../core/util/abstract/generic-component";
import { UsersService } from "../../../../../core/service/api/users.service";
import { ActivitiesService } from "../../../../../core/service/api/activities.service";
import { showFeedback, showFeedbackError } from '../../../../../core/util/notification';
import { Location } from '../../../../../akita/locations/state/location.model';
import { MultiselectUsersComponent } from '../../../form/multiselect/multiselect-users/multiselect-users.component';
import { RRule } from 'rrule';

@Component({
  selector: 'app-calendar-create-event-middel',
  templateUrl: './calendar-create-event-middel.component.html',
  styleUrls: ['./calendar-create-event-middel.component.sass']
})
export class CalendarCreateEventMiddelComponent extends GenericComponent implements OnInit, OnChanges {

  @Input() prefilledDate: any;
  @Input() showPreviousStep: boolean = true;
  @Input() activities: any;
  @Output() formReset: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  @ViewChild(MultiselectUsersComponent) multiselectUsers: MultiselectUsersComponent;
  public today = new Date();
  public location: Location;
  middleId: any
  ruleForm: FormGroup;
  middelForm: FormGroup;

  sendOptions: any[] = [
    {
      title: translate('reusable.fixed_date'),
      value: 'fixed',
      selected: true,
    },
    {
      title: translate('middel.repeated'),
      value: 'recurring',
      selected: false,
    }
  ];

  activityBasedDateOptions: any[] = [
    { title: translate('reusable.day_advance_activity'), value: -1, },
    { title: translate('reusable.week_advance_activity'), value: -7, },
    { title: translate('reusable.month_advance_activity'), value: -14, }
  ];

  frequencyList: any[] = [
    { title: translate('reusable.day'), set: 3 },
    { title: translate('reusable.week'), set: 2 },
    { title: translate('reusable.month'), set: 1 },
    { title: translate('reusable.year'), set: 0 }
  ];

  weekdayList: any[] = [
    { title: translate('monday'), value: 0 },
    { title: translate('tuesday'), value: 1 },
    { title: translate('wednesday'), value: 2 },
    { title: translate('thursday'), value: 3 },
    { title: translate('friday'), value: 4 },
    { title: translate('saturday'), value: 5 },
    { title: translate('sunday'), value: 6 }
  ];

  types = new FormControl('', Validators.required);

  activityList: any[];
  end: any;
  starts: any;
  users: any[] = [];
  checkType: string = 'fixed';
  summaryDate: number = -1;
  selectedActivity: any;
  middelDateShow: any;
  selectedActivityDate: any;
  selectedAddressCount: number = 0;
  fixedDateMiddel: any;
  dateMinRule: any;
  rule: any[];
  activityBasedOnDate: any;

  year: string;
  month: string;
  days: string;
  hours: any;
  minutes: string;
  seconds: string;

  constructor(public globals: Globals, private formBuilder: FormBuilder, private _generalService: GeneralService,
    private _usersService: UsersService, private _activitiesService: ActivitiesService, private cd: ChangeDetectorRef) {
    super(globals);
  }

  ngOnInit() {
    this.middelForm = this.formBuilder.group({
      type: ['', Validators.required],
      group: ['', Validators.required],
      activities: [''],
      person: [''],
      area: this.formBuilder.group({
        type: 'Multipolygon',
        coordinates: this.formBuilder.array([])
      }),
      sent_date: [''],
      calendars: [''],
      form: this.formBuilder.group({
        date_type: [''],
        value: ['']
      }),
      activity_id: [''],
      locations: [''],
    });

    this.ruleForm = this.formBuilder.group({
      interval: [1],
      freq: [1],
      dtstart: [new Date()],
      byweekday: [],
      until: [moment(new Date()).add(1, 'months').toDate()],
      tzid: [this.globals.timezone]
    });

    this.getUsers();
    this.getActivity();
    this.showCreateEvent();
    this.getRuleDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.activities && this.activities.length > 0) {
      this.middelForm.get('activities').setValue(this.activities);
      this.selectActivity({value: this.activities})
      this.cd.detectChanges();
    }
  }

  showCreateEvent() {
    if (!this.prefilledDate || (!this.prefilledDate.start && !this.prefilledDate.date)) {
      this.fixedDateMiddel = new Date();
      this.ruleForm.get('dtstart').setValue(this.fixedDateMiddel);
      return;
    }

    this.ruleForm.get('interval').setValue(1);
    this.ruleForm.get('freq').setValue(3);

    this.checkType = 'fixed';
    this.fixedDateMiddel = this.prefilledDate.start || this.prefilledDate.date;
    this.ruleForm.get('dtstart').setValue(this.fixedDateMiddel);
    if (this.prefilledDate.end) {
      this.checkType = 'recurring';
      const endDate = this.prefilledDate.end ? moment(this.prefilledDate.end).subtract(1, 'days').toDate() : '';
      this.ruleForm.get('until').setValue(endDate);
    }
  }

  selectActivity(event: any) {
    this.selectedActivity = event.value;
    if (!this.selectedActivity) {
      return;
    }
    if (this.selectedActivity && this.selectedActivity.length) {
      // prepopullate
      const earliestObject = this.selectedActivity.reduce((earliest, current) => {
        return current.calendar.started_at < earliest.calendar.started_at ? current : earliest;
      }, this.selectedActivity[0]);
      this.activityBasedOnDate = earliestObject;
      this.middelForm.get('activity_id').setValue(earliestObject.id);
      this.cd.detectChanges();
    }
    this.selectedActivityDate = dateIso(this.activityBasedOnDate.calendar.started_at, true, this.globals.timezone);
    this.generateMiddelDate();
  }

  getActivityData(event: MatSelectChange) {
    this.middleId = event.value.id;
    this.selectedActivityDate = dateIso(this.activityBasedOnDate.calendar.started_at, true, this.globals.timezone);
    this.generateMiddelDate();
    this.middelForm.get('activity_id').setValue(event.value.id);
  }


  selectUser(selected: any[]) {
    this.middelForm.setControl('users', this.formBuilder.array(selected));
  }

  generateMiddelDate(offset: number = -1) {
    this.middelDateShow = new Date();
    if (!this.selectedActivityDate) {
      return;
    }

    this.middelDateShow = moment(this.selectedActivityDate).add(offset, 'days').toDate();
  }

  getRuleDate() {
    this.starts = this.dateMinRule = this.ruleForm.controls['dtstart'].value || new Date();

    const until = this.ruleForm.controls['until'].value;
    if (!until) {
      return;
    }

    const entry = {
      interval: this.ruleForm.controls['interval'].value,
      freq: this.ruleForm.controls['freq'].value,
      byweekday: this.ruleForm.controls['byweekday'].value,
      dtstart: this.starts,
      until: moment(until).add(12, 'hours').toDate(), // Hack for adjusting timezone. Todo: Find better fix
    };

    try {
      const rule = new RRule(entry);
      this.rule = rule.all();
    } catch (e) {
      showFeedbackError(e, 'Er is een error opgetreden', true, entry);
    }
  }

  deleteCalendar(date) {
    this.rule = this.rule.filter(d => date !== d);
  }

  getUsers() {
    if (!this.users || this.users.length === 0) {
      this._usersService.getUsers().subscribe(
        (response: any) => {
          this.users = response.data;

          // Just ensure that users are loaded
          if (this.multiselectUsers) {
            this.multiselectUsers.setUsers(this.users);
          }
        }
      );
    }
  }

  // Activity List
  getActivity() {
    this._activitiesService.getAllActivities().subscribe(
      (res: any) => {
        if ( res.data && res.data.length ) {
          this.activityList = res.data.map((activity: any) => {
            if (activity.calendar && activity.calendar.started_at) {
              activity.calendar.started_at = dateIso(activity.calendar.started_at, true, this.globals.timezone);
            }

            return activity;
          });
        }
      });
  }

  // MIDDEL PARTS
  public addEvent(event: MatDatepickerInputEvent<Date>) {
    this.fixedDateMiddel = event.value;
  }

  // Map Contents
  public updateSelectedCoordinates(location: Location): void {
    this.middelForm.get('locations').setValue(location);
    this._generalService.getAddressCount(location).subscribe(
      (count: number) => {
        this.selectedAddressCount = count
      }
    );
  }

  public showMaxAreaError() {
    showFeedback('Geselecteerde oppervlak te groot', 'error', null, false);
  }

  public createEvent() {
    Object.keys(this.middelForm.controls).forEach(key => {
      this.middelForm.get(key).markAsTouched();
    });

    if (!this.middelForm.valid) {
      return;
    }
    let calendars = [];
    const title = this.middelForm.controls['activities'].value.name

    if (this.checkType === 'fixed') {
      calendars = [{
        title: title,
        type: this.checkType,
        start: this.fixedDateMiddel,
      }];
    } else if (this.checkType === 'recurring') {
      this.rule.forEach(date => {
        calendars.push({
          title: title,
          type: this.checkType,
          start: date,
        });
      });
    } else {
      calendars = [{
        title: title,
        type: this.checkType,
        start: this.fixedDateMiddel,
        days_from_activity: this.summaryDate,
      }];
    }

    this.middelForm.get('calendars').setValue(calendars);
    this.middelForm.get('sent_date').setValue(calendars[0].start);
    this.middelForm.get('form.date_type').setValue(this.checkType);
    this.ruleForm.value.calendars = calendars;
    this.middelForm.get('form.value').setValue(this.ruleForm.value);

    this.create.emit(this.middelForm.value);
    // this.resetForm();
  }

  resetForm() {
    this.middelForm.reset();
    this.ruleForm.reset();
    this.formReset.emit();
  }
  compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.id == o2.id);
   }
}
