import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import * as turf from '@turf/turf';
import OlMap from 'ol/Map';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { MapService } from 'src/app/core/service/api/map.service';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Coordinate, Location } from '../../../akita/locations/state/location.model';
import { hexToRgb } from '../../../core/util/color';
import { OpenlayersSidebarV2Component } from '../../../features/main-app/map/openlayers-sidebar-v2/openlayers-sidebar-v2.component';
import { Globals } from '../../../globals';
import { OpenlayersDrawing } from './openlayers-drawing';
import { OpenlayersTooltip } from './openlayers-tooltip';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from 'ol/proj';

import { Vector as VectorSource } from "ol/source";

import { MapDataService } from 'src/app/core/service/api/map-data.service';

declare let ol: any;
declare let $: any;

@Component({
  selector: 'app-openlayers-map',
  templateUrl: './openlayers-map.component.html',
  styleUrls: ['./openlayers-map.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: OpenlayersMapComponent,
    multi: true
  }]
})
export class OpenlayersMapComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  public map: OlMap;
  private showUpdateStatus: boolean;
  public showPanel: boolean;
  public coordinate;
  public randomId = Math.random().toString(36).substring(7);
  public bookmarkName: string;
  getCoordinate: any
  selectedResultIndex: number | null = null;
  // bookmark
  public bookmark: any = [];
  public feature: any
  public circleVectorSource: any
  public bookmarkSelection: any = [];
  public bookmarkPopupShow: boolean = false;
  public bookmarkAddPopupShow: boolean = false;
  public bookmarkToggle = '';
  public mainCoordinate: Location;
  public bookmarkMultiPlygon = [];
  public bookmarkaddShow = false;
  @Input() actionSelect: string;
  @Input() includeSaveCoordinate: boolean;
  @Input() includeSidebar: boolean;
  @Input() integrationTabMap: boolean = true;
  @Input() collapseSidebar: boolean;
  @Input() sidebarButtons = ['length', 'point'];
  @Input() zoomControlClass: string;
  @Input() customStyle: string;
  @Input() showMapReset: boolean = false;
  @Input() showPinTooltip: boolean = false;
  @Input() isSidenav = false;
  @Input() defaultCoordinate: any;
  @Input() defaultDrawTool: string;
  @Input() singleEntityType: boolean = true;
  @Input() locatableType: string;
  @Input() disabled = false;
  @Input() type: string;
  @Input() maxArea: number;
  @Input() maxPolygons: number;
  @Input() maxPoints: number;
  @Input() maxLines: number;
  @Input() clickStartDrawingMsg = 'Klik om te meten';
  @Input() bookmarkOptions = [];
  @Input() addFeature: any;
  @Input() typeAction!: string;
  @Input() featureSelectType!: string;

  @Output() public saveCoordinate: EventEmitter<any> = new EventEmitter();
  @Output() public mapRendered: EventEmitter<any> = new EventEmitter();
  @Output() public mapReset: EventEmitter<any> = new EventEmitter();
  @Output() public stakeholdersGathered: EventEmitter<any> = new EventEmitter<any>();

  @Output() public areaDrawEnd: EventEmitter<any> = new EventEmitter();
  @Output() public lineDrawEnd: EventEmitter<any> = new EventEmitter();
  @Output() public pointDrawEnd: EventEmitter<any> = new EventEmitter();
  @Output() public maxAreaExceeded: EventEmitter<any> = new EventEmitter();
  @Output() public maxPointsExceeded: EventEmitter<any> = new EventEmitter();
  @Output() public maxLinesExceeded: EventEmitter<any> = new EventEmitter();
  @Output() public polygonSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() public extentChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(OpenlayersSidebarV2Component) public sidebar: OpenlayersSidebarV2Component;
  @ViewChild('mapContainer') private mapContainer: ElementRef;
  searchResults: any[] = [];
  private allCoordinates = [];
  private allLayers = [];
  private popupBeingShown = false;
  private popupTimeout: any;
  private searchSubject = new Subject<string>();
  private selectedToolSbj = new BehaviorSubject(null);
  private selectedTool$: Observable<string> = this.selectedToolSbj.asObservable();
  private purposeToolSbj = new BehaviorSubject(null);
  public purposeTool$: Observable<string> = this.purposeToolSbj.asObservable();
  private pinTooltipOverlay: any;
  private drawing: OpenlayersDrawing;
  vectorLayer: VectorLayer<any>;
  vectorSource: VectorSource;
  private showResetButtonSrc: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showResetButton$: Observable<boolean> = this.showResetButtonSrc.asObservable();
  private delayedRendering: any[] = [];
  keyword: any
  private basicColor: string = '#2800D2';
  showResultsContainer: boolean = false;
  polygonSearch: boolean = false;
  private locationsSbj: BehaviorSubject<Location[]> = new BehaviorSubject<Location[]>([]);
  public locations$: Observable<Location[]> = this.locationsSbj.asObservable();
  private subscriptions: Subscription[];
  public previousFeature: any = null;
  public previousLayer: any = null;
  public onRemoval: boolean = false;
  public loading: boolean = false;
  showContent = true;
  private dataSubscription: Subscription;
  // eslint-disable-next-line no-empty-function
  private onChange: (value: any) => void = value => { };
  // eslint-disable-next-line no-empty-function
  private onTouched: (touched: boolean) => void = touched => { };

  // eslint-disable-next-line no-empty-function
  constructor(private _map_service: MapService, public _mapDataService: MapDataService, private cd: ChangeDetectorRef, private globals: Globals, private router: Router, private _projectConfigsService: ProjectConfigsService) {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(res => {
        let stringUsingToString
        if (this.globals.projectConfig.polygons) {
          const feature = turf.polygon([this.globals.projectConfig.polygons.coordinates[0][0]]);
          // Calculate bounding box using Turf.js
          const bbox = turf.bbox(feature);
          // Bounding Box coordinates
          const boundingBox = `${bbox[0]}, ${bbox[1]}, ${bbox[2]}, ${bbox[3]}`;
          // Using toString method
          stringUsingToString = boundingBox.toString();
        }
        this._projectConfigsService.getMapAddress(res, stringUsingToString).subscribe((results: any[]) => {
          this.loading = false
          this.showResultsContainer = true;
          this.searchResults = results;

          this.cd.detectChanges(); // Manually trigger change detection

          // Remove existing markers

        });
      })

    this.dataSubscription = this._map_service.data$.subscribe((data) => {
      // Adjust this logic based on your actual use case
      if (data === null) {
        // Data is null
        this.showContent = false;
      } else if (data === undefined || data.points === null) {
        // Data is undefined or points are null
        this.showContent = true;
      } else {
        // Data is not null, not undefined, and points are not null
        this.showContent = false;
      }

    });

  }

  ngOnInit(): void {
    this.showUpdateStatus = false;
    this.showPanel = this.includeSaveCoordinate;
    this.coordinate = [6.661594, 50.433237];
    if (this.type == 'middel') {
      this.loadBookMarks();
    }
  }

  ngAfterViewInit(): void {
    const defaultControl: any = {
      attribution: false
    };

    if (this.zoomControlClass) {
      defaultControl.zoomOptions = {
        className: this.zoomControlClass
      }
    }

    // Setting this in template doesn't seem to work, hence jQuery set attribute
    if (this.customStyle) {
      $(`#map-${this.randomId}`).attr('style', this.customStyle);
    }

    // Detects if the map is rendered, and emit the data to subscribers
    const observer = new MutationObserver((mutations) => {
      if (!this.mapContainer || !this.mapContainer.nativeElement) {
        return;
      }

      if (!this.mapContainer.nativeElement.querySelector('canvas.ol-unselectable')) {
        return;
      }

      if (this.defaultDrawTool) {
        switch (this.defaultDrawTool) {
          case 'length':
          case 'area':
          case 'point':
            if (this.sidebar) {
              this.sidebar.purposeTool = this.defaultDrawTool;
              this.sidebar.selectedTool = this.defaultDrawTool;
            }

            if (this.isAllowedDrawing(this.locationsSbj.getValue())) {
              this.setDrawingMode(this.defaultDrawTool);
            } else {
              this.setDrawingMode(null);
            }

            break;
        }
      }

      if (this.delayedRendering) {
        this.delayedRendering.forEach(fn => fn());
      }

      this.mapRendered.emit();
      observer.disconnect();
    });

    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });

    const osm = new ol.source.OSM();

    // Use our own cloudfront to speedup map download.
    // Only available in NL and IND only!
    // Check https://console.aws.amazon.com/cloudfront/home?region=eu-central-1#distribution-settings:EUKSH2TVLZGI9 to configure
    osm.setTileLoadFunction((tile, src: string) => {
      const cdnSrc = src.replace('a.tile.openstreetmap.org', 'd2d6jclemytp5s.cloudfront.net')
        .replace('b.tile.openstreetmap.org', 'd2d6jclemytp5s.cloudfront.net')
        .replace('c.tile.openstreetmap.org', 'd2d6jclemytp5s.cloudfront.net');
      tile.getImage().src = cdnSrc;
    });

    this.map = new ol.Map({
      target: `map-${this.randomId}`,
      controls: ol.control.defaults(defaultControl).extend([
        new ol.control.FullScreen()
      ]),
      layers: [
        new ol.layer.Tile({
          source: osm
        })
      ],
      view: new ol.View({
        center: this.coordinate,
        zoom: 4
      })
    });


    this.map.on('moveend', (e) => {
      // this.extentChanged.emit();
    });

    if (this.defaultCoordinate) {
      let coord = this.defaultCoordinate.split(',');
      coord = coord.map(c => parseFloat(c));

      if (this.map.getSize() && this.integrationTabMap) {
        setTimeout(() => {
          const currentLocations = this.locationsSbj.getValue();
          if (currentLocations.length != 0) {
            currentLocations.forEach((location: Location) => {
              if (location.points) {
                this.map.getView().setCenter(ol.proj.transform(location.points.coordinates[0], 'EPSG:4326', 'EPSG:3857'));
                this.map.getView().setZoom(12);
                this.showContent = false
                this.cd.detectChanges();
              }
              else if (location.polygons) {
                location.polygons.coordinates.forEach((coordinate: any) => {
                  const first = coordinate[0]
                  const features = turf.points(first);
                  const center = turf.center(features);
                  this.map.getView().setCenter(ol.proj.transform(center.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'));
                  this.map.getView().setZoom(12);
                  this.cd.detectChanges();
                });
              } else {
                location.lines.coordinates.forEach((coordinate: any) => {
                  const features = turf.points(coordinate);
                  const center = turf.center(features);
                  this.map.getView().setCenter(ol.proj.transform(center.geometry.coordinates, 'EPSG:4326', 'EPSG:3857'));
                  this.map.getView().setZoom(12);
                  this.cd.detectChanges();
                });
              }
            });
          } else {
            // @ts-ignore
            this.map.getView().fitExtent(coord, this.map.getSize());
            this.cd.detectChanges();
          }
        }, 50);

      } else {
        if (this.map.getSize() && !this.integrationTabMap) {
          // @ts-ignore
          this.map.getView().fitExtent(coord, this.map.getSize());
        }
        this.cd.detectChanges();
      }
    }

    if (this.includeSidebar) {
      const sidebar = new ol.control.Sidebar({ element: 'sidebar', position: 'left' });
      this.map.addControl(sidebar);
    }

    const tooltip = new OpenlayersTooltip(this.map, this.clickStartDrawingMsg, this.selectedTool$, this.purposeTool$);
    this.drawing = new OpenlayersDrawing(this.map, this.locationsSbj.getValue(), this.selectedTool$, tooltip, {
      maxPoints: this.maxPoints,
      maxLines: this.maxLines,
      maxArea: this.maxArea,
    });

    const pointSubscription = this.drawing.onPointDrawEnd.subscribe((coordinate: Coordinate) => {

      this.drawingSubscription('points', {
        points: {
          type: 'MultiPoint',
          coordinates: [],
        },
        locatable_type: this.locatableType,
      }, coordinate, this.pointDrawEnd);
    });

    const lineSubscription = this.drawing.onLineDrawEnd.subscribe((coordinate: Coordinate) => {
      this.drawingSubscription('lines', {
        lines: {
          type: 'MultiLineString',
          coordinates: [],
        },
        locatable_type: this.locatableType,
      }, coordinate, this.lineDrawEnd);
    });

    const areaSubscription = this.drawing.onAreaDrawEnd.subscribe((coordinate: Coordinate) => {
      this.drawingSubscription('polygons', {
        polygons: {
          type: 'MultiPolygon',
          coordinates: [],
        },
        locatable_type: this.locatableType,
      }, coordinate, this.areaDrawEnd);
    });

    const maxPointSubscription = this.drawing.onMaxPointsExceeded.subscribe(() => this.maxPointsExceeded.emit());
    const maxLineSubscription = this.drawing.onMaxLinesExceeded.subscribe(() => this.maxLinesExceeded.emit());
    const maxAreaSubscription = this.drawing.onMaxAreaExceeded.subscribe(() => this.maxAreaExceeded.emit());

    const locationsSubscription = this.locations$.subscribe((locations: Location[]) => {
      if (!this.map) {
        return;
      }

      if (this.isAllowedDrawing(locations)) {
        this.setDrawingMode(this.defaultDrawTool);
      } else {
        this.setDrawingMode(null);
      }
    });
    this.subscriptions = [
      pointSubscription,
      lineSubscription,
      areaSubscription,
      maxPointSubscription,
      maxLineSubscription,
      maxAreaSubscription,
      locationsSubscription,
    ];


  }

  search(term: string): void {
    if (term.trim() !== '') {
      this.loading = true
      this.searchSubject.next(term);
    } else {
      this.loading = false
      this.showResultsContainer = false;
    }

  }

  private panToLocation(result: any): void {
    try {
      this.keyword = result.display_name;
      const lonLat = [parseFloat(result.lon), parseFloat(result.lat)];

      const geometry = new ol.geom.Point(fromLonLat(lonLat));

      this._map_service.searchFeature = new ol.Feature({
        geometry: geometry,
      });

      const circleStyle = new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 1],
          src: `assets/png/${this.locatableType}.png`, // Replace with the path to your pin icon
          scale: 0.2, // Adjust the scale as needed1
        }),
      });


      this._map_service.searchFeature.setStyle(circleStyle);

      this._map_service.circleVectorSource = new ol.source.Vector({
        features: [this._map_service.searchFeature],
      });

      const vectorLayer = new ol.layer.Vector({
        source: this._map_service.circleVectorSource,
      });

      this.map.addLayer(vectorLayer);

      this.map.getView().setCenter(fromLonLat(lonLat));
      this.resetMap()
      // Optionally, you can also set a new zoom level
      this.map.getView().setZoom(12);
      this.drawingSubscription('points', {
        points: {
          type: 'MultiPoint',
          coordinates: [],
        },
        locatable_type: this.locatableType,
      }, lonLat, this.pointDrawEnd);
      this.showResultsContainer = false;
      this.showContent = false
    } catch (error) {
      console.error('Error in panToLocation:', error);
    }




  }


  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {

    const clickedElement = event.target as HTMLElement;

    // Check if the clicked element is outside the search results container
    if (!clickedElement.classList.contains('search-result-item')) {
      this.showResultsContainer = false;
    }
  }

  onItemClick(index: number) {
    this.selectedResultIndex = index;
  }

  getCenterOfExtent(Extent) {
    const X = Extent[0] + (Extent[2] - Extent[0]) / 2;
    const Y = Extent[1] + (Extent[3] - Extent[1]) / 2;
    return [X, Y];
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.resetMap(false);
    if (obj) {
      if (this.singleEntityType && (obj.points || obj.lines || obj.polygons)) {
        this.locationsSbj.next([obj]);
        this.applyPinLayer();

        if (this.showMapReset) {
          this.showResetButtonSrc.next(true);
        }
      } else if (Array.isArray(obj) && obj.length > 0) {
        this.locationsSbj.next(obj);
        this.applyPinLayer();

        if (this.showMapReset) {
          this.showResetButtonSrc.next(true);
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.drawing.destroy();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private drawingSubscription(type: string, newLocation: Location, coordinate: Coordinate, eventEmitter: EventEmitter<any>) {
    this.showContent = false
    if (this.purposeToolSbj.getValue() !== 'add-stakeholder' && (!coordinate || !this.locatableType)) {
      return;
    }

    const currentLocations = this.locationsSbj.getValue();
    if (currentLocations && currentLocations.length > 0) {
      newLocation = currentLocations[0];
    }

    newLocation = {
      ...newLocation,
      [type]: {
        ...newLocation[type],
        coordinates: [...newLocation[type].coordinates, coordinate],
      },
    };
    currentLocations[0] = newLocation;
    this.locationsSbj.next(currentLocations);
    eventEmitter.emit();
    this.emitValueChange();
  }

  private onAddFeatures(type: string, newLocation: Location, coordinate: Coordinate) {
    if (this.purposeToolSbj.getValue() !== 'add-stakeholder' && (!coordinate || !this.locatableType)) {
      return;
    }
    const currentLocations = this.locationsSbj.getValue();
    if (currentLocations && currentLocations.length > 0) {
      newLocation = currentLocations[0];
    }
    //Adding to the multipolygon
    if (this.onRemoval) {
      const coord = [];
      coordinate.forEach(element => {
        coord.push([element]);
      });
      newLocation = {
        ...newLocation,
        [type]: {
          ...newLocation[type],
          coordinates: coord,
        },
      };
      this.onRemoval = false;
    } else {
      newLocation = {
        ...newLocation,
        [type]: {
          ...newLocation[type],
          coordinates: [...newLocation[type].coordinates, coordinate],
        },
      };
    }

    currentLocations[0] = newLocation;
    this.locationsSbj.next(currentLocations);
    this.emitValueChange();
  }

  public removeLayers(): void {
    this.allLayers.forEach(layer => this.map.removeLayer(layer));
  }
  /**
   * Used in admin to save the current coordinate. Unused in angular-app. Keep it for reference in the future
   */
  public updateProjectCoordinate(): void {
    this.showUpdateStatus = true;
    setTimeout(() => {
      this.showUpdateStatus = false;
    }, 3000);

    const coord = this.map.getView().calculateExtent(this.map.getSize()).toString();
    this.saveCoordinate.emit(coord);
  }

  public resetMap(notify: boolean = true, bookmarkSelection = false): void {

    if (!this.drawing) {
      return;
    }
    if (this.sidebar) {
      this.sidebar.purposeTool = "point"
    }
    if (!bookmarkSelection) {
      this.locationsSbj.next([]);
      if (notify) {
        this.onResetButton();
      }
    }
    this.drawing.removeDrawing(notify);
    this.removeLayers();

    if (this.showMapReset) {
      this.showResetButtonSrc.next(false);
    }

    if (notify) {
      this.mapReset.emit();
      this.emitValueChange();
    }
    this.bookmarkaddShow = false;
  }

  private emitValueChange(selection = false): void {

    let currentLocations;
    if (selection) {
      currentLocations = this.bookmarkSelection;
      this.locationsSbj.next(this.bookmarkSelection);
    } else {
      currentLocations = this.locationsSbj.getValue();
    }
    if (this.singleEntityType) {
      if (!currentLocations || currentLocations.length === 0) {
        this.onChange(null);
      } else {
        this.onChange(currentLocations[0]);
        this.applyPinLayer(selection);
        if (this.showMapReset) {
          this.showResetButtonSrc.next(true);
        }
      }
    } else {
      this.onChange(currentLocations);
      if (currentLocations) {
        this.applyPinLayer(selection);

        if (this.showMapReset) {
          this.showResetButtonSrc.next(true);
        }
      }
    }
  }

  private applyPinLayer(bookmarkSelection = false): void {
    let currentLocations;
    if (!this.map) {
      this.delayedRendering.push(() => this.applyPinLayer());
      return;
    }
    if (bookmarkSelection) {
      currentLocations = this.bookmarkSelection;
    } else {
      currentLocations = this.locationsSbj.getValue();
    }
    currentLocations.forEach((location: Location) => {
      if (!bookmarkSelection && !location.locatable_type) {
        return;
      }
      if (location.points && location.points.coordinates && location.points.coordinates.length > 0) {

        if(location.locatable_type === 'activities') {
          this.allLayers.push(this.applyPin(this.getIconStyle(`${'calendars'}.png`), location.name, location.link, location.points.coordinates));
        } else {
          this.allLayers.push(this.applyPin(this.getIconStyle(`${this.locatableType}.png`), location.name, location.link, location.points.coordinates));

        }
      } else if (location.polygons && location.polygons.coordinates && location.polygons.coordinates.length > 0) {
        this.mainCoordinate = location;
        this.allLayers.push(this.loadPolygons(location.polygons.coordinates, location.metadata ? location.metadata : null));
      } else if (location.lines && location.lines.coordinates && location.lines.coordinates.length > 0) {
        this.allLayers.push(this.loadLines(location.lines.coordinates, location.metadata ? location.metadata.color : null));
        // We set to type "any" to prevent ts complaining about the type
        location.lines.coordinates.forEach((coordinate: any) => {
          const firstCoordinate = coordinate[0];
          const lastCoordinate = coordinate[coordinate.length - 1];
          this.allLayers.push(this.applyPin(this.getIconStyle(`${location.locatable_type}-lines.png`, [0.5, 0.5,]), location.name, location.link, [firstCoordinate, lastCoordinate,]));
        });
      }
    });

    if (this.showPinTooltip) {
      this.applyPinTooltip();
    }
  }

  private getIconStyle(iconFileName: string = 'issues.png', anchor: number[] = [0.5, 1]): any {
    return new ol.style.Style({
      image: new ol.style.Icon({
        anchor: anchor,
        scale: 0.2,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: `/assets/png/${iconFileName}`
      })
    });
  }

  private applyPin(iconStyle: any, name: string, link: string, coordinates: Coordinate[]): any {
    const iconFeatures = [];

    coordinates.forEach(coordinate => {
      const geometry = new ol.geom.Point(coordinate).transform('EPSG:4326', 'EPSG:3857').getCoordinates()

      const feature = new ol.Feature({
        geometry: new ol.geom.Point(geometry),
        name: name,
        link: link
      });

      feature.setStyle(iconStyle);
      iconFeatures.push(feature);
    });

    const vectorSource = new ol.source.Vector({
      features: iconFeatures
    });

    const vectorLayer = new ol.layer.Vector({
      source: vectorSource
    });

    this.map.addLayer(vectorLayer);

    return vectorLayer;
  }

  private loadPolygons(coordinates: Coordinate[], metadata?: any): any {
    this.polygonSearch = true
    if (this.actionSelect) {
      if (this.drawing.onDraw || this.actionSelect === 'add') {
        if (this.previousLayer != null) {
          this.map.removeLayer(this.previousLayer);
          this.drawing.onDraw = false;
        }
      }
    }
    if (!coordinates) {
      return;
    }
    const geom = new ol.geom.MultiPolygon(coordinates);
    const preloadedCoordinates = geom.transform('EPSG:4326', 'EPSG:3857').getCoordinates();
    const preloadedPolygonFeature = new ol.Feature({
      geometry: new ol.geom.MultiPolygon(preloadedCoordinates),
      metadata: metadata,
    });
    const vectorSource = new ol.source.Vector({//create empty vector
    });
    vectorSource.addFeature(preloadedPolygonFeature);
    const vectorLayer = new ol.layer.Vector({
      source: vectorSource,
      style: this.getStyle(metadata ? metadata.color : null),
    });
    vectorLayer.id = 'preloadedPolygons';
    this.previousLayer = vectorLayer;
    this.map.addLayer(vectorLayer);
    const extent = vectorSource.getExtent();
    // @ts-ignore
    this.map.getView().fitExtent(extent, this.map.getSize(), { maxZoom: 12 });
    return vectorLayer;
  }

  public loadLines(coordinates: Coordinate[], color?: string): any {
    if (!coordinates) {
      return;
    }

    const geom = new ol.geom.MultiLineString(coordinates);
    const preloadedCoordinates = geom.transform('EPSG:4326', 'EPSG:3857').getCoordinates();
    const preloadedPointsFeature = new ol.Feature({
      geometry: new ol.geom.MultiLineString(preloadedCoordinates),
    });

    const vectorSource = new ol.source.Vector({//create empty vector
    });
    vectorSource.addFeature(preloadedPointsFeature);
    const vectorLayer = new ol.layer.Vector({
      source: vectorSource,
      style: this.getStyle(color),
    });

    vectorLayer.id = 'preloadedLines';
    this.map.addLayer(vectorLayer);

    return vectorLayer;
  }

  private applyPinTooltip(): void {
    if (this.pinTooltipOverlay) {
      return;
    }

    const element = document.getElementById(`popup-${this.randomId}`);

    this.pinTooltipOverlay = new ol.Overlay({
      element: element,
      positioning: 'top-center',
      stopEvent: false,
      offset: [0, -45]
    });
    this.map.addOverlay(this.pinTooltipOverlay);

    const bumpX = 0.1;
    const bumpY = -5;
    // display popup on click
    const target = this.map.getTarget();
    const cursorTarget = typeof target === 'string' ? $(`#${target}`) : $(target);
    this.map.on('pointermove', (evt) => {
      if (evt.dragging) {
        return;
      }

      const feature = this.map.forEachFeatureAtPixel([evt.pixel[0] + bumpX, evt.pixel[1] + bumpY],
        (feature) => {
          return feature;
        }
      );

      // We set timeout for showing popover to prevent error on quick switching popover
      // which caused by jquery being late on destroying and showing popover
      clearTimeout(this.popupTimeout);

      if (!feature) {
        $('.popover').hide()
        this.popupBeingShown = false;
        cursorTarget.css('cursor', '');
        return;
      }

      this.popupTimeout = setTimeout(() => {
        if (feature.get('name') && feature.get('link')) {
          // @ts-ignore
          const coordinates = feature.getGeometry().getCoordinates();


          if (element && $(element) && (this.popupBeingShown[0] != coordinates[0])) {
            this.popupBeingShown = coordinates;
            this.pinTooltipOverlay.setPosition(coordinates);
            $(element).popover({
              'placement': 'top',
              'html': true,
              'content': feature.get('name')
            });
            $(element).attr('data-content', feature.get('name'));
            $(element).popover('show');
          }

          cursorTarget.css('cursor', 'pointer');
        } else {
          if (this.popupBeingShown) {
            $(element).popover('destroy');
            this.popupBeingShown = false;
          }

          cursorTarget.css('cursor', '');
        }
      }, 50);

      const metadata: any = feature.get('metadata');
      if (metadata && metadata.onmouseover) {
        metadata.onmouseover();
      }
    });

    // Click behaviour
    this.map.on('click', (evt) => {
      const feature = this.map.forEachFeatureAtPixel([evt.pixel[0] + bumpX, evt.pixel[1] + bumpY],
        (feature) => {
          return feature;
        }
      );

      // Navigate to entity detail
      if (feature) {
        const link = feature.get('link');
        if (link && link !== '') {
          const path = link.split('/').filter(p => p);
          path.unshift('/');

          this.router.navigate(path);
        }

        const metadata: any = feature.get('metadata');
        if (metadata && metadata.onclick) {
          metadata.onclick();
        }
      }
    });
  }

  private fitMap() {
    let tempCoords = [];
    for (const type in this.allCoordinates) {
      tempCoords = tempCoords.concat(this.allCoordinates[type]);
    }

    const coords = [];
    tempCoords.forEach(coord => coords.push(coord.coordinate));

    if (coords && coords.length <= 1) { // We don't auto fit 1 only coordinate because the UX would be weird
      return;
    }

    const source = new ol.source.GeoJSON({
      projection: 'EPSG:3857',
      object: this.transformCoordinatesToGeojson(coords)
    });

    const feature = source.getFeatures()[0];
    const polygon = feature.getGeometry();
    const size = this.map.getSize();
    const view = this.map.getView();
    // @ts-ignore
    view.fitGeometry(
      polygon,
      size,
      {
        padding: [100, 50, 30, 100],
        constrainResolution: false
      }
    );
    this.map.updateSize();
  }

  private transformCoordinatesToGeojson(coordinates: any[]): any {
    const geoJson = [];
    const tempExtraCoordinate = [];
    coordinates.forEach(coordinate => {
      geoJson.push(coordinate);
    });

    if (geoJson.length === 0) {
      return null;
    }

    // Only 1 coordinate found, let's add temp coordinate
    if (geoJson.length === 1) {
      geoJson.push(tempExtraCoordinate);
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          id: 'CMP',
          properties: {
            name: 'Complaints'
          },
          geometry: {
            type: 'Polygon',
            coordinates: [geoJson]
          }
        }
      ]
    };
  };

  public setDrawingMode(type: string): void {
    if (type === 'area') {
      this.defaultDrawTool = 'area';
    }

    if (!this.map) {
      this.delayedRendering.push(() => this.setDrawingMode(type));
      return;
    }

    if (!this.isAllowedDrawing(this.locationsSbj.getValue())) {
      type = null;
    }

    this.selectedToolSbj.next(type);
    // this.map.updateSize();
  }

  public onPurposeToolChange(type: string): void {
    this.purposeToolSbj.next(type);
  }

  public gatherStakeholders(): void {
    this.stakeholdersGathered.emit(this.locationsSbj.getValue());
  }

  private isAllowedDrawing(locations: Location[]): boolean {
    if (!this.maxPolygons && !this.maxLines && !this.maxPoints) {
      return true;
    }

    let pointsCount = 0;
    let linesCount = 0;
    let polygonsCount = 0;


    const disallowDrawing = locations.some((location: Location) => {
      if (location.points && location.points.coordinates) {
        pointsCount += location.points.coordinates.length;
      }

      if (location.lines && location.lines.coordinates) {
        linesCount += location.lines.coordinates.length;
      }

      if (location.polygons && location.polygons.coordinates) {
        polygonsCount += location.polygons.coordinates.length;
      }

      if ((this.maxPoints && this.maxPoints <= pointsCount) || (this.maxLines && this.maxLines <= linesCount) || (this.maxPolygons && this.maxPolygons <= polygonsCount)) {
        return true;
      }

      return false;
    });

    return !disallowDrawing;
  }

  private getStyle(color?: string): any {
    const rgb = hexToRgb(color || this.basicColor);
    const alphaColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`;
    const solidColor = color || this.basicColor;
    return new ol.style.Style({
      fill: new ol.style.Fill({
        color: alphaColor,
      }),
      stroke: new ol.style.Stroke({
        color: solidColor,
        width: 5,
      }),
      image: new ol.style.Circle({
        radius: 2,
        stroke: new ol.style.Stroke({
          color: solidColor,
        }),
        fill: new ol.style.Fill({
          color: alphaColor,
        })
      })
    })
  }

  public bookmarkOption() {
    if (this.bookmarkToggle == 'addbookmark') {
      this.bookmarkPopupShow = true;
      this.bookmarkAddPopupShow = false;
    } else {
      this.bookmarkPopupShow = false;
      this.bookmarkAddPopupShow = true;
    }
  }

  public loadBookMarks() {
    this._map_service.getBookmarks().subscribe(res => {
      this.bookmark = res;
    })
  }

  public saveBookmark() {
    const location = { name: this.bookmarkName, locations: this.mainCoordinate }
    this._map_service.saveBookmarks(location).subscribe(res => {
      this.bookmarkAddPopupShow = false;
      this.loadBookMarks();
      showFeedbackSaved();
      this.reset();
      this.cd.detectChanges();
    })
  }

  public onSelectionBookmark() {
    this.multiPlygonMerge();
    this.bookmarkaddShow = true;
  }

  public reset() {
    this.bookmarkToggle = '';
    this.bookmarkPopupShow = false;
    this.bookmarkAddPopupShow = false;
    this.bookmarkName = '';
    this.bookmarkSelection = [];
    if (this.locationsSbj.getValue().length > 0) {
      this.bookmarkaddShow = false;
    }
  }

  public multiPlygonMerge() {
    const currentCoordinates = [];
    if (this.locationsSbj.getValue().length > 0) {
      this.bookmarkSelection.forEach((loc: Location) => {
        loc.polygons.coordinates.forEach(element => {
          currentCoordinates.push(element);
        });
      });
      this.locationsSbj.getValue().forEach((location: Location) => {
        currentCoordinates.forEach(el => {
          location.polygons.coordinates.push(el);
        })
      });
      this.resetMap(true, true);
      this.emitValueChange();
      this.reset();
    } else {
      this.resetMap(true, true);
      this.emitValueChange(true);
      this.reset();
    }
  }
  /*
    Add Feature Dynamically
  */
  public addFeatureDynamically(): void {
    if (this.addFeature) {
      if (this.actionSelect) {
        if (this.actionSelect == 'remove') {
          if (this.previousFeature != null) {
            this.onRemovePreviousFeature(this.locationsSbj.getValue());
          }
        } else {
          const features = this.addSelectedFeatures(true);
          if (features.length) {
            this.onAddFeatures('polygons', {
              polygons: {
                type: 'MultiPolygon',
                coordinates: [],
              },
              locatable_type: this.locatableType,
            }, features);
          }
        }
      }
      this.previousFeature = this.addFeature;
    }
  }
  /*
    Add Selected Features
  */
  public addSelectedFeatures(unique: boolean = false): any {
    const features = [];
    this.addFeature.forEach((feature: any) => {
      if (feature.geometery) {
        switch (feature.geomType) {
          case 'Polygon': {
            if (this.addFeature.length > 1) {
              if (this.isExistPolygon(feature.geometery, this.locationsSbj.getValue())) {
                if (unique) {
                  if (feature.credentials) {
                    features.push(...feature.geometery);
                  } else {
                    features.push([...feature.geometery]);
                  }
                } else {
                  features.push([...feature.geometery]);
                }
              }
            } else {
              if (unique) {
                if (feature.credentials) {
                  features.push(...feature.geometery);
                } else {
                  features.push([...feature.geometery]);
                }
              } else {
                features.push([...feature.geometery]);
              }
            }
            break;
          }
          case 'MultiPolygon': {
            feature.geometery.forEach(polygon => {
              if (this.addFeature.length > 1) {
                if (this.isExistPolygon(polygon, this.locationsSbj.getValue())) {
                  if (unique) {
                    features.push(...polygon);
                  } else {
                    features.push([...polygon]);
                  }
                }
              } else {
                if (unique) {
                  features.push(...polygon);
                } else {
                  features.push([...polygon]);
                }
              }
            });
            break;
          }
        }

      }
    });
    return features;
  }
  /*
    Remove The Previous Feature
  */
  public onRemovePreviousFeature(multipolygon: any): void {
    const coordinates = multipolygon[0]['polygons'].coordinates;
    let extraCoordinates = JSON.parse(JSON.stringify(coordinates));
    const matchingPolygonIndex = [];
    this.previousFeature.forEach((feature: any) => {
      if (feature.geometery) {
        switch (feature.geomType) {
          case 'Polygon': {
            for (let i = 0; i < coordinates.length; i++) {
              if (coordinates[i].length === 1) {
                if (this.arePolygonsEqual(coordinates[i], feature.geometery)) {
                  matchingPolygonIndex.push(i);
                }
              } else {
                for (let j = 0; j < coordinates[i].length; j++) {
                  if (this.arePolygonsEqual(coordinates[i][j], feature.geometery)) {
                    matchingPolygonIndex.push(i);
                  }
                }
              }
            }
            break;
          }
          case 'MultiPolygon': {
            feature.geometery.forEach(polygon => {
              for (let i = 0; i < coordinates.length; i++) {
                if (coordinates[i].length === 1) {
                  if (this.arePolygonsEqual(coordinates[i], polygon[0])) {
                    matchingPolygonIndex.push(i);
                  }
                } else {
                  for (let j = 0; j < coordinates[i].length; j++) {
                    if (this.arePolygonsEqual([coordinates[i][j]], polygon)) {
                      matchingPolygonIndex.push(i);
                    }
                  }
                }
              }
            });
            break;
          }
        }

      }
    });
    if (matchingPolygonIndex.length) {
      extraCoordinates = this.removeAllByIndices(matchingPolygonIndex, extraCoordinates)
    }
    this.rerenderMainMultiPolygon(extraCoordinates)
  }
  /*
    Rerender Main MultiPolygon
  */
  public rerenderMainMultiPolygon(latest: any): void {
    this.locationsSbj.next([]);
    if (this.actionSelect) {
      if (this.actionSelect == 'remove') {
        if (this.previousLayer != null) {
          this.map.removeLayer(this.previousLayer);
        }
      }
    }
    const features = this.addSelectedFeatures();
    latest.forEach(draw => {
      features.push([...draw]);
    });
    const newLocation: Location = {
      polygons: {
        type: 'MultiPolygon',
        coordinates: features,
      },
      locatable_type: this.locatableType,
    }
    this.locationsSbj.next([newLocation]);
    this.emitValueChange();
  }
  /*
    Polygon all ready exist Multipolygon
  */
  public isExistPolygon(givenPolygon: any, multipolygon: any): boolean {
    let isPolygonExists = true;
    if (multipolygon && multipolygon.length > 0) {
      const coordinates = multipolygon[0]['polygons'].coordinates;
      for (let i = 0; i < coordinates.length; i++) {
        if (this.arePolygonsEqual(coordinates[i], givenPolygon)) {
          isPolygonExists = false;
          break;
        }
      }
    }
    return isPolygonExists;
  }
  /*
    Polygon Are Equal
  */
  public arePolygonsEqual(polygon1: any[], polygon2: any[]): boolean {
    if (polygon1.length !== polygon2.length) {
      return false;
    }
    for (let i = 0; i < polygon1.length; i++) {
      if (polygon1[i].length !== polygon2[i].length) {
        return false;
      }
    }
    bugsnagFactory().notify(JSON.stringify({
      message: turf.polygon(polygon1),
    }));
    bugsnagFactory().notify(JSON.stringify({
      message: turf.polygon(polygon2),
    }));
    const p1 = turf.polygon(polygon1);
    const p2 = turf.polygon(polygon2);
    if (turf.intersect(p1, p2)) {
      return true;
    }
    return true;
  }
  /*
    Polygon Are Equal
  */
  public areMultiPolygonsEqual(multiPolygon1: any[], multiPolygon2: any[]): boolean {
    if (multiPolygon1.length !== multiPolygon2.length) {
      return false;
    }

    for (let i = 0; i < multiPolygon1.length; i++) {
      const polygon1 = multiPolygon1[i];
      const polygon2 = multiPolygon2[i];

      if (!this.arePolygonsEqual(polygon1, polygon2)) {
        return false;
      }
    }

    return true;
  }
  /*
    Validate If the Coord are Polygon or Multipolygon
  */
  public getGeometryType(coords) {
    if (!Array.isArray(coords)) {
      return null;
    }

    if (coords.length === 0) {
      return null;
    }

    if (Array.isArray(coords[0]) && Array.isArray(coords[0][0])) {
      // It's a MultiPolygon
      return 'MultiPolygon';
    } else if (Array.isArray(coords[0])) {
      // It's a Polygon
      return 'Polygon';
    }

    return null;
  }
  /*
    Validate And Fix Polygon
  */
  public validateAndFixPolygon(coordinates: any[]): any[] {
    if (!Array.isArray(coordinates) || coordinates.length < 3) {
      throw new Error('Invalid polygon coordinates. A polygon should have at least 3 points.');
    }
    // Ensure the coordinates array starts and ends with the same point (forming a closed shape)
    if (
      coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
      coordinates[0][1] !== coordinates[coordinates.length - 1][1]
    ) {
      coordinates.push([...coordinates[0]]);
    }
    return coordinates;
  }
  /*
   Remove All By Index
  */
  public removeAllByIndices(indices: number[], coords: any): any[] {
    return coords.filter((_, index) => !indices.includes(index));
  }
  /*
   Only For Subproject Logic
  */
  public onResetButton(): void {
    if(this.polygonSearch) {
      this.showContent = false
    } else {
      this.showContent = true
    }

    if (this._map_service.circleVectorSource && this._map_service.circleVectorSource.getFeatures().length > 0) {
      this._map_service.circleVectorSource.removeFeature(this._map_service.searchFeature);

    }
    if (this.actionSelect) {
      const newLocation: Location = {
        polygons: {
          type: 'MultiPolygon',
          coordinates: [],
        },
        locatable_type: this.locatableType,
      }
      this.actionSelect = 'add';
      this.locationsSbj.next([newLocation]);
      this.onRemoval = true;

      this.addFeatureDynamically();
    }
    this.keyword = ''
  }
}
