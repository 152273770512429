<div class="row popup-container">
  <div class="popup-header row no-margin ">
    <div class="col-xs-12 mb-5">
      <app-breadcrumb></app-breadcrumb>
      <button type="button" class="close no-margin" (click)="close()"><span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span><span class="sr-only">Close</span></button>
    </div>
    <div class="col-xs-10 popup-header-title set-diaplay-flex-property">
      <h1><strong>
          <mat-icon class="d-text-middle col-xs-1 icon-size-set entity-icon">map</mat-icon>
        </strong> <span class="ml-20">{{data.title}}</span></h1>
    </div>
  </div>
  <div class="popup-body" appScroll>
    <mat-progress-bar *ngIf="!isProcess" mode="indeterminate"></mat-progress-bar>
    <div>
      <!-- extent set false to load all features at once -->
      <app-map (openlayerralationLoader)="setLoder()" [hideSidePanel]='true' [headerFalse]="'false'" [isExtent]="false" [mapData]="data">
      </app-map>
    </div>
  </div>
</div>
