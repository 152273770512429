import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subproject, createSubproject} from '../../../../akita/subproject/state/subproject.model';
import {Globals} from '../../../../globals';
import {MatDialogRef} from '@angular/material/dialog';
import {CachedService} from '../../../../core/service/common/cached.service';
import {Stakeholder} from '../../../../akita/stakeholders/state/stakeholder.model';
import {MatStepper} from '@angular/material/stepper';
import {NgForm} from '@angular/forms';
import {scrollToError} from '../../../../core/util/form';
import {Issue} from '../../../../akita/issues/state/issue.model';
import {Decision} from '../../../../akita/requirements/state/decision.model';
import {Activity} from '../../../../akita/activities/state/activity.model';
import {Middel} from '../../../../akita/content/state/middel.model';
import {Action} from '../../../../akita/tasks/state/action.model';
import {SubprojectsService} from '../../../../akita/subproject/state/subprojects.service';
import {finalize} from 'rxjs/operators';
import {showFeedbackError, showFeedbackSaved} from '../../../../core/util/notification';
import {Complaint} from '../../../../akita/complaints/state/complaint.model';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import posthog from 'posthog-js';
import { arcgisTypeDefine, integrationError, integrationValidationArcGis } from 'src/app/core/util/integration';
import collect from 'collect.js';
import { OpenlayersMapComponent } from '../../openlayers-map/openlayers-map.component';
import { MapService } from 'src/app/core/service/api/map.service';

@Component({
  selector: 'app-subproject-new',
  templateUrl: './subproject-new.component.html',
  styleUrls: ['./subproject-new.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubprojectNewComponent implements OnInit, OnDestroy {

  public stakeholders: Stakeholder[];
  public issues: Issue[];
  public decisions: Decision[];
  public complaints: Complaint[];
  public activities: Activity[];
  public middel: Middel[];
  public actions: Action[];
  public roles:any=[];
  public isLoader=false;
  public users:any=[];
  public selectedSubprojectRoles:any=[];
  public subprojectRoles:any=[];
  public subprojectMapFeatures = {
    arcgis: [],
    opendata: []
  };
  public arcGisLayers = {
    layers: [],
    sublayers: []
  }
  public featureSelectedType!: string;
  public previousLayer:any = null;
  public selectType:string = 'add';
  public integrationEnable: boolean=false;
  public activeArcGis:string='arcgis';
  public errorIntegration: boolean=false;
  public errorIntegrationEnterprise: boolean=false;
  public integrationCheck: string;
  public nameExists: boolean = false;
  @ViewChild('form1', {static: false}) form1: NgForm;
  @ViewChild('form2', {static: false}) form2: NgForm;
  @ViewChild(MatStepper, {static: false}) stepper: MatStepper;
  @ViewChild(OpenlayersMapComponent, {static: false}) map: OpenlayersMapComponent;

  public subproject: Subproject = createSubproject(null, null);
  public subProjectCopy: Subproject = createSubproject('Copy', null);

  public processing: boolean = false;
  constructor(private projectsService: ProjectsService,public globals: Globals, public dialogRef: MatDialogRef<any>, private _cachedService: CachedService,
              // eslint-disable-next-line no-empty-function
              private _subprojectsService: SubprojectsService,private cd: ChangeDetectorRef, public _mapService: MapService) { }

  public ngOnInit(): void {
    this._mapService.setData('null');
    this.activeArcGis=arcgisTypeDefine(this.globals);
    this.errorIntegration=integrationError(this.globals,'arcgis_enabled');
    this.errorIntegrationEnterprise=integrationError(this.globals,'arcgis_enterprise_enabled');
    this.integrationCheck=integrationValidationArcGis(this.globals,'arcgis','arcgis_enterprise','arcgis_enabled','arcgis_enterprise_enabled',this.errorIntegration,this.errorIntegrationEnterprise);
    if(this.integrationCheck==='project'){
      this.integrationEnable=true;
    }
    if(this.integrationCheck==='personal'){
      this.integrationEnable=true;
    }
    this.subproject.all=false;
    this.getRoles();
    this._cachedService.ignoreSubProject = true;
    this.getSubprojectUsers();
    this._cachedService.getStakeholders().subscribe(
      stakeholders => this.stakeholders = stakeholders
    );

    this._cachedService.getIssues().subscribe(
      issues => this.issues = issues
    );

    this._cachedService.getDecisions().subscribe(
      decisions => this.decisions = decisions
    );

    this._cachedService.getComplaints().subscribe(
      complaints => this.complaints = complaints
    );

    this._cachedService.getActivities().subscribe(
      activities => this.activities = activities
    );

    this._cachedService.getMiddel().subscribe(
      middel => this.middel = middel.map(m => {
        if (m.activities) {
          m.name = `${m.type} voor activiteit ${m.activities.name}`;
        } else {
          m.name = m.type;
        }

        return m;
      })
    );

    this._cachedService.getActions().subscribe(
      actions => this.actions = actions,
    );
  }

  public ngOnDestroy(): void {
    this._cachedService.ignoreSubProject = false;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public submitStep1(): void {
    if (this.form1.invalid || this.nameExists) {
      scrollToError();
      return;
    }

    this.stepper.next();
  }

  public submitStep2(): void {
    if (this.form2.invalid) {
      scrollToError();
      return;
    }
    this.processing = true;
    this.subproject.users=this.users;
    if(this.subproject.features){
      if (this.subproject.features.length) {
        this.subproject.features.forEach((layer:any) => {
          this.subproject.layers.push({
            type: layer.type,
            id_field: layer.id_field,
            layer_id: layer.layer_id,
            layer_name: layer.layer_name,
            title: layer.title,
            main_layer_id: layer.main_layer_id,
            sub_layer_id: layer.sub_layer_id,
            sub_layer_name: layer.sub_layer_name
          })
        })
        delete this.subproject.features;
      }
    }
    this._subprojectsService.create(this.subproject)
      .pipe(finalize(() => this.processing = false))
      .subscribe(
        (response: Subproject) => {
          this.loderIntoGlobal();
          showFeedbackSaved();
          this.dialogRef.close(response);
        },(error)=>{
          showFeedbackError(error);
          this.dialogRef.close();
        }
    );
    posthog.capture('subproject-create', {
      //eventType: this.value
    });
  }

  public subprojectExists() {
    this.nameExists = false;
    const name = this.subproject.name;
    this.nameExists = this.globals.subprojects.some(project => project.name.toLowerCase() === name.toLowerCase());
  }
  public toggleChange(copyAll: boolean): void {
    const copiedList = ['stakeholders', 'activities', 'complaints', 'decisions', 'issues', 'logs', 'middel', 'residents', 'actions',];
    if (copyAll) {
      copiedList.forEach(v => {
        if (this.subproject[v]) {
          this.subProjectCopy[v] = this.subproject[v];
          this.subproject[v] = null;
        }
      });
    } else {
      copiedList.forEach(v => {
        if (this.subProjectCopy[v]) {
          this.subproject[v] = this.subProjectCopy[v];
        }
      });
    }
  }

  public subprojectRolesSelect(id,i){
    if(this.users.length>0){
      this.users.forEach(element => {
        if(element.id==id){
          element.roles=this.selectedSubprojectRoles[i];
        }
      });
    }
  }

  public getSubprojectUsers(){
    this._subprojectsService.getSubprojectUsers().subscribe((res:any)=>{
      this.users=res.data;
      this.isLoader=true;
      this.cd.detectChanges();
    })
  }

  public getRoles(){
    this.projectsService.getUserRoles().subscribe(res=>{
      this.roles=res;
      this.roles.forEach(element => {
        if(element.name!=='Project Admin'){
          this.subprojectRoles.push(element);
        }
      });
    })
  }

  public loderIntoGlobal(){
    this._subprojectsService.get(true).subscribe(res=>{
      this.globals.subprojects=res;
    })
  }
  /*
    On Select Integration Feature Add Add to Map
  */
  public onSelectIntegrationFeautes(features: any) {
    this.subprojectMapFeatures.arcgis = features;
    this.featureSelectedType = 'arcgis';
    this.onSetAllFeatureIntoOne();
  }
  /*
    On Select Open Data Feature Add Add to Map
  */
  public onSelectOpenDataFeautes(features: any) {
    this.subprojectMapFeatures.opendata = features;
    this.featureSelectedType = 'opendata';
    this.onSetAllFeatureIntoOne();
  }
  /*
    Re-Arrange All Features Into One
  */
  public onSetAllFeatureIntoOne(): void{
    this.subproject.features = [...this.subprojectMapFeatures.arcgis, ...this.subprojectMapFeatures.opendata];
    this.subproject.features = collect(this.subproject.features).map((obj, index) => { return { ...obj, id: index };}).all();
    if(this.previousLayer != null){
      if(this.previousLayer.length > this.subproject.features.length){
        this.selectType = 'remove';
      }else{
        this.selectType = 'add';
      }
    }
    setTimeout(() => {
      this.map.addFeatureDynamically();
    }, 500);
    this.previousLayer = this.subproject.features;
  }
}
