import { Globals } from '../../globals';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { dateIso } from '../../core/util/dateIso';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  // eslint-disable-next-line no-empty-function
  constructor(private readonly translocoService: TranslocoService,private globals: Globals) { }

  transform(value: Date, format = 'dd MMM yyyy'): string {
    value=dateIso(value, true, this.globals.timezone)
    const datePipe = new DatePipe(`${this.translocoService.getActiveLang()}-${this.translocoService.getActiveLang().toUpperCase()}`);
    return datePipe.transform(value, format);
  }
}
