
export enum ThemeType {
  Decisions = 'types?group=decisions',
  RequirementsPhases = 'phases',
  Issues = 'themeissues',
  CalendarType = 'themetype',
  Stakeholders = 'stakeholdertypes',
  StakeholdersInterests = 'interests',
  Interests = 'interests',
  Complaints = 'complaintthemes',
  Activities = 'types?group=activities',
  ActivitiesType = 'types',
  Permits = 'permitstypes',
  Requester = 'permitsrequesters',
  Cluster = 'permitsclusters',
  Validity = 'permitsvalidities',
  PermitFiling = 'permitsfillingrequirements'
}
