import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { Globals } from 'src/app/globals';
import { showFeedbackSaved } from 'src/app/core/util/notification';

@Component({
  selector: 'app-outlook-integration-modal',
  templateUrl: './outlook-integration-modal.component.html',
  styleUrls: ['./outlook-integration-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutlookIntegrationModalComponent extends IntegrationsModalComponent implements OnInit {
  public errorMessage: boolean = false;
  public emailsAddedAs: any;
  private serviceGroup: string = 'microsoft';
  sub: any
  selectedSubprojects: any
  public isProcess: boolean = false;
  public outlookenable: boolean = false;
  modulesAvailable: boolean = false;
  programModule: boolean = false;
  public bouwappsyncSelection: any = [];
  public previousBouwappsyncSelection: any = [];
  constructor(
    public _projectConfigsService: ProjectConfigsService,
    public globals: Globals,
    private projectsService: ProjectsService,
    public pubsub: PubsubService,
    public dialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(globals, _projectConfigsService);
  }

  ngOnInit(): void {
    this.outlookenable = this.globals.projectConfigs['services.integration.microsoft.enabled'];
    this.errorMessage = this.globals.projectConfigs['services.integration.microsoft.connection_error'];
    if(this.globals.projectConfigs['services.integration.microsoft.subprojects']) {
      this.selectedSubprojects = this.globals.projectConfigs['services.integration.microsoft.subprojects'].split(',').map(item => item.trim());

    }
    if(!this.globals.projectConfigs['services.integration.microsoft.contact_moments.enabled']) {
      this.emailsAddedAs = 'complaints'
    } else {
      this.emailsAddedAs = 'contact_moments'
    }


    if(this.globals.modules.length>0){
      this.globals.modules.forEach(element => {
        if (element.module_id == 'communication') {
          this.modulesAvailable = true;
          this.cd.detectChanges();
        }
        if(element.module_id=='subprojects'){
          this.programModule=true;
          this.cd.detectChanges();
        }
      });
    }
    if(!this.globals.projectConfigs['services.integration.microsoft.contact_moments.enabled'] && this.modulesAvailable) {
      this.emailsAddedAs = 'complaints'
    } else {
      this.emailsAddedAs = 'contact_moments'
    }
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.dialogRef.close(true);
    this.pubsub.resetHistory();
  }

  outlookConfig(action) {
    if (action === 'enable') {
      this.outlookenable = this.globals.projectConfigs['services.integration.microsoft.enabled'] = true;
    } else {
      this.outlookenable = this.globals.projectConfigs['services.integration.microsoft.enabled'] = false;
    }

    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        this.cd.detectChanges();
        if (this.globals.projectConfigs['services.integration.microsoft.enabled']) {
          this.getRedirctLink()
        }
        showFeedbackSaved()
      });
  }

  getRedirctLink() {
    if (this.globals.projectConfigs['services.integration.microsoft.enabled']) {
      localStorage.removeItem("outlook_code");
      // eslint-disable-next-line prefer-template
      this.outlookenable = this.globals.projectConfigs['services.integration.microsoft.enabled'] = false;
      // eslint-disable-next-line prefer-template
      this.globals.projectConfigs['services.integration.microsoft.user_id'] = '0';
      // eslint-disable-next-line prefer-template
      // this.globals.projectConfigs['services.integration.microsoft.connection_error'];
      // eslint-disable-next-line prefer-template
      this.configsSetup()
    } else {
      localStorage.setItem('redirectType', 'microsoft');
      this._projectConfigsService.getRedirectLink('microsoft').subscribe((res: any) => {
        if (res && res.data){
          localStorage.setItem('outlookIntegrationFor', 'admin')
          // this is done for localhost integration; change redirect_uri
          if (window.location.host === 'localhost:4200') {
            const redirect_uri = 'http://localhost:4200/microsoft/instellingen/integraties';
            const url = new URL(res.data.login_link);
            url.searchParams.set('redirect_uri', redirect_uri);
            const modifiedUrl = url.toString();
            window.open(modifiedUrl);
          } else {
            window.open(res.data.login_link)
          }
        }
      })
    }

  }

  updateSync(selection) {
    if(selection === 'complaints') {
      this.globals.projectConfigs['services.integration.microsoft.contact_moments.enabled'] = false;
      this.emailsAddedAs = 'complaints'
    } else {
      this.globals.projectConfigs['services.integration.microsoft.contact_moments.enabled'] = true;
      this.emailsAddedAs = 'contact_moments'
    }

    this.configsSetup();
  }

  public configsSetup() {
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs).subscribe(
      () => {
        showFeedbackSaved();
      });
  }




  updateSubprojects(event) {
    this.globals.projectConfigs['services.integration.microsoft.subprojects'] = event.join(', ');
    this.configsSetup();
  }

}
