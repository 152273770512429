import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { catchError, debounceTime, finalize, tap } from 'rxjs/operators';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { SsoService } from 'src/app/core/service/api/sso.service';
import { SSO } from 'src/app/core/util/auth/auth.modal';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { Globals } from 'src/app/globals';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sso-new',
  templateUrl: './sso-new.component.html',
  styleUrls: ['./sso-new.component.sass'],
})
export class SsoNewComponent implements OnInit {
  public entity: SSO;
  public ssoValidation: boolean = false;
  public ssoVerifyCredentials: boolean = false;
  public domainValidation: any = {
    is_exists: false,
    is_user_email_matched: true
  };
  public userDomain: boolean=true;
  public processing: boolean=false;
  private secretDebouncer: Subject<any> = new Subject();
  public isSSOSecret = false;
  // eslint-disable-next-line no-empty-function
  constructor(private _projectService: ProjectsService,@Inject(MAT_DIALOG_DATA) public data: any,public globals: Globals, public dialogRef: MatDialogRef<SsoNewComponent>, private _ssoServices: SsoService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.entity = {
      platform: 'azure',
      domain: this.data.sso ? this.data.sso.domain ? this.data.sso.domain : null : null,
      client_id: this.data.sso ? this.data.sso.client_id ? this.data.sso.client_id : null : null,
      client_secret: this.data.sso ? this.data.sso.client_secret ? this.data.sso.client_secret : null : null,
      tenant_id: this.data.sso ? this.data.sso.tenant_id ? this.data.sso.tenant_id : null : null,
      identity_provider: this.data.sso ? this.data.sso.identity_provider ? this.data.sso.identity_provider : null : null,
    }
    this.secretDebouncer.pipe(
      debounceTime(300)
    ).subscribe((value) => {
      if (value) {
        this.setSSOSecret(value);
      } else {
        this.setSSOSecret('');
      }
    });
  }

  public setSSOSecret(secret: string): void {
    // remove previous secret
    if (!this.isSSOSecret && this.entity.client_secret) {
      this.isSSOSecret = true;
      this.entity.client_secret = '';
      // this.setSharepointConfig();
    } else {
      // update new secret
      this.entity.client_secret = secret;
      // this.setSharepointConfig();
    }
  }

  //on close
  public onClose(): void {
    this.dialogRef.close();
  }
  //on submit
  public submit(): void{
    this._ssoServices.update(this.data.id,this.entity)
    .pipe(
      finalize(()=>{
        this.dialogRef.close(this.entity);
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //on delete
  public deleteConnection(): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: { title: translate('settings.sso.delete_sso'), text: translate('settings.sso.delete_info'), delete_confirm: translate('settings.sso.delete_confirm'), showCheckbox: true },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._ssoServices.delete(this.data.id, this.data.sso.id).subscribe();
        this.dialogRef.close("delete");
      }
    });
  }
  //sso credentials validation
  public ssoCredentialsValidation(): void{
    this.resetLogic();
    this._ssoServices.validation(this.data.id,this.entity)
    .pipe(
      tap((res)=> {
        this.ssoValidation = true;
        this.processing=false;
      }),
      catchError(async (err) => {
        this.ssoVerifyCredentials = true;
        this.processing=false;
      })
    ).subscribe();
  }
  //domain validation check
  public onChangeDomainValidation(): void{
    this.resetLogic();
    this._ssoServices.domainExistValidation(this.data.id,this.entity)
    .pipe(
      tap(res=>{
        this.domainValidation=res;
        this.processing=false;
        this.onChangeUserDomainCheck(true);
      }),
      catchError(async (err) => {
        this.processing=false;
      })
    ).subscribe();
  }
  //user domain validation
  public onChangeUserDomainCheck(action=false): void{
    this.userDomain=false;
    if(!action)
      this.onChangeDomainValidation();
    if(!this.domainValidation.is_exists && !this.domainValidation.is_user_email_matched){
      this._projectService.getProfileId()
      .pipe(
        tap((res:any)=>{
          if(res){
            if(res.email.match(/@([^&]+)/)[1] === this.entity.identity_provider){
              this.userDomain=true;
            }
          }
        })
      ).subscribe();
    }
  }

  public onSecretChange(event: any): void {
    this.secretDebouncer.next(event.target.value);
  }

  //reset all logics
  public resetLogic(): void{
    this.processing=true;
    this.ssoVerifyCredentials=false;
    this.ssoValidation = false;
    this.domainValidation={
      is_exists: false,
      is_user_email_matched: true
    }
  }
}
