import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { get } from 'scriptjs';
import { AppInjector } from 'src/app-injector';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { Entities } from '../../../../core/enum/entities';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { ResidentsService } from '../../../../core/service/api/residents.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { deepCopy } from '../../../../core/util/deepCopy';
import { googleMapAddressParse } from '../../../../core/util/googleMapAddressParse';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../../shared/directive/new-entity.directive';

@Component({
  selector: 'app-resident-new',
  templateUrl: './resident-new.component.html',
  styleUrls: ['./resident-new.component.sass']
})
export class ResidentNewComponent extends NewEntityModal implements OnInit {

  declare public entity: any;
  public enableManualAddress: boolean = false;
  public googleMapOptions = {
    types: [],
  };

  @ViewChild('form') form: NgForm;

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<ResidentNewComponent>,
    public globals: Globals, private _residentsService: ResidentsService, private pubsub: PubsubService) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // if (!this.globals.googleMapLibLoaded) {
    //   get(`https://maps.googleapis.com/maps/api/js?key=${ this.globals.googleMapApiKey }&libraries=places&sensor=false&language=nl`, () => {
    //     this.globals.googleMapLibLoaded = true;
    //   });
    // }
  }

  public submit() {
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;

    const params = deepCopy(this.entity);

    this._residentsService.submitResident(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:data,
                message: 'Opgeslagen',
                type:'openItems',
                item:'omwonenden',
                url:'omwonenden'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.residents, data);
        });
  }

  protected initializeEntity(): void {
    this.entity = {
      name: null,
      phone: null,
      email: null,
      type: 'Omwonende',
      address: null,
      postcode: null,
      city: null,
      country: 'Nederland',
      locations: null,
    };
  }

  public updateAddress(): void {
    this.entity.locations = null;
  }

  protected handleAddressChange(event): void {
    const googleAddress = googleMapAddressParse(event);
    this.entity.address = googleAddress.address;
    this.entity.postcode = googleAddress.postcode;
    this.entity.city = googleAddress.city;
    if (googleAddress.latitude && googleAddress.longitude) {
      this.entity.locations = {
        points: {
          type: 'MultiPoint',
          coordinates: [
            [parseFloat(googleAddress.longitude), parseFloat(googleAddress.latitude)],
          ]
        },
        locatable_type: 'stakeholders',
      };
    }
  }

}
