<ng-container class="p-relative" *ngIf="modal === 'overview'; else restore">
  <section class="batch-p-set">
    <ng-container *ngIf="filters">
      <button [ngClass]="items.length ? 'mr-10' : ''" type="button" mat-stroked-button (click)="onExport()"><mat-icon class="d-text-middle uil uil-file-export unicon-icon" style="margin-bottom: 5px;"></mat-icon> Export</button>
    </ng-container>
    <ng-container *ngIf="items.length">
      <mat-hint >{{ items.length }} {{'selected' | transloco}}: </mat-hint>
      <mat-form-field class="ml-10">
          <mat-select value="batch">
              <mat-option value="batch">
                {{'batch' | transloco}}
              </mat-option>
              <mat-option *ngIf="type === 'stakeholders' && hasPermission(Permission.ActionCreate)" value="create" (click)="openActionModal()">
                {{'batch_tasks_create' | transloco}}
              </mat-option>
              <mat-option *ngIf="items.length > 1 && type !== 'documents' && (
                (hasPermission(Permission.StakeholdersUpdate) && hasPermission(Permission.StakeholdersDelete)) ||
                (hasPermission(Permission.EmployeesUpdate) && hasPermission(Permission.EmployeesDelete)) ||
                (hasPermission(Permission.IssuesUpdate) && hasPermission(Permission.IssuesDelete)) ||
                (hasPermission(Permission.DecisionsUpdate) && hasPermission(Permission.DecisionsDelete)) ||
                (hasPermission(Permission.ActionUpdate) && hasPermission(Permission.ActionDelete)) ||
                (hasPermission(Permission.ComplaintsUpdate) && hasPermission(Permission.ComplaintsDelete)))"
                value="merge" sharedModal="batchMerge" [data]="{type:type,items:items}" (onClosed)="onloadItem.emit(true)">
                {{'merge_items' | transloco}}
              </mat-option>
              <mat-option *ngIf="type !== 'documents'" value="export" (click)="onExport(true)">
                {{'batch_export' | transloco}}
              </mat-option>
              <mat-option *ngIf="type === 'decisions' && hasPermission(Permission.DecisionsUpdate)" (click)="onUpgradeDowngradeComplaint()" [disabled]="hasNonSmartReportingEntry">
                {{  'downgrade_decisions' | transloco }}
              </mat-option>
              <mat-option *ngIf="type === 'complaints' && hasPermission(Permission.ComplaintsUpdate)" (click)="onUpgradeDowngradeComplaint()" [disabled]="hasNonSmartReportingEntry">
                {{  'upgrade_complaints' | transloco }}
              </mat-option>
              <mat-option value="delete" class="error" (click)="onDelete()" *hasPermission="[Permission.StakeholdersDelete] || [Permission.EmployeesDelete] ||  [Permission.IssuesDelete] || [Permission.DecisionsDelete] || [Permission.ActionDelete] || [Permission.ComplaintsDelete] || [Permission.MiddelDelete] || [Permission.ActivitiesDelete] || [Permission.DocumentsDelete]">
                {{('delete' | transloco | capitaliz) + ' ' +  (type+'.labels' | transloco)  }}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </ng-container>
  </section>
</ng-container>
<!-- Restore -->
<ng-template #restore>
  <button *ngIf="modal !== 'layers'" [disabled]="!items.length" type="button" mat-flat-button data-dismiss="modal" (click)="onRestore()">{{ 'restore_items' | transloco | capitaliz }}</button>
</ng-template>

<ng-container class="p-relative" *ngIf="modal === 'layers';">
  <section class="batch-p-set">
    <ng-container *ngIf="items.length">
      <!-- <mat-hint >{{ items.length }} {{'selected' | transloco}}: </mat-hint> -->
      <mat-form-field class="ml-10">
        <mat-label style="position: relative; top: -5px;">{{'batch' | transloco}}</mat-label>
        <mat-select>
            <mat-option (click)="createBatchDialogItems()">
              {{'create_dialog_item' | transloco}}
            </mat-option>
            <mat-option (click)="openBatchLayersModal()">
              {{'attach_to_dialog_item' | transloco}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </section>
</ng-container>
