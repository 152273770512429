import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ComplaintState, ComplaintsStore } from './complaints.store';
import { Complaint } from './complaint.model';

@Injectable({ providedIn: 'root' })
export class ComplaintsQuery extends QueryEntity<ComplaintState, Complaint> {
  constructor(protected store: ComplaintsStore) {
    super(store);
  }

}
