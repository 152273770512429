import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IssueState, IssuesStore } from './issues.store';
import { Issue } from './issue.model';

@Injectable({ providedIn: 'root' })
export class IssuesQuery extends QueryEntity<IssueState, Issue> {
  constructor(protected store: IssuesStore) {
    super(store);
  }

  public update(obj: object): void {
    this.store.updateActive(obj);
  }

}
