<form #form_newStakeholderAction="ngForm" (ngSubmit)="submit()">
    <div mat-dialog-title>
      <h4 class="mb-10 inline-block">
        {{ "Update Workflow" | transloco }}
      </h4>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">
          <i class="d-text-middle uil uil-multiply unicon-icon"></i>
        </span>
        <span class="sr-only"> Close </span>
      </button>
    </div>
  
    <div class="newWorkflowForm">
      <div>
          <!-- modal body -->
          <mat-dialog-content>
            <div class="mt-10">
                <mat-form-field class="full-width">
                    <mat-select [placeholder]="'Select Workflow' | transloco" [(value)]="selectedItem" (selectionChange)="selectedWorkflow()" required>
                        <ng-container *ngFor="let workflow of data.workflows">
                            <mat-option [value]="workflow">
                                {{ workflow.name }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
    
            <app-generic-multiselect-checkbox  *ngIf="selectedItem" [multiple]="true" #stakeholderMultiselect [placeholder]="'Select tasks' | transloco" name="stakeholders" [(ngModel)]="selectedItem.tasks" [name]="data" (ngModelChange)="onSelectDialogItem($event)" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'">
                <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                <app-generic-multiselect-checkbox-option *ngFor="let item of tasks" [value]="item">
                    {{ item.name? item.name : item.title? item.title : item.text? item.text : '' }}
                </app-generic-multiselect-checkbox-option>
            </app-generic-multiselect-checkbox>

            <app-all-search-select  *ngIf="selectedItem && data.parentType ==='overview'" [required]="false" class="full-width" [models]="['issues', 'complaints', 'requirements', 'contents']" [(ngModel)]="selectedItem" name="act" ngDefaultControl (ngModelChange)="onChangeRequired()"></app-all-search-select>

            </div>
          </mat-dialog-content>
          <!--end of modal body -->
      </div>
    </div>
  
    <mat-dialog-actions align="end">
      <div class="dialog-button-row">
        <button
          type="button"
          mat-stroked-button
          data-dismiss="modal"
          [disabled]="isProcessing"
          (click)="close()"
        >
          {{ "cancel" | transloco | capitaliz }}
        </button>
        <button type="submit" mat-flat-button>
          <span *ngIf="!isProcessing">
            <mat-icon class="material-icons d-text-middle">save</mat-icon>
            {{
              (relationActionContactPerson ? "save" : "add")
                | transloco
                | capitaliz
            }}
          </span>
          <span *ngIf="isProcessing">{{
            "text.please_wait" | transloco | capitaliz
          }}</span>
        </button>
      </div>
    </mat-dialog-actions>
    <!--end of modal footer -->
  </form>
  