import { Injectable } from '@angular/core';
import {AbstractService} from "../common/abstract.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Allowed} from "../../../decorator/allowed-decorator";
import {Permission} from "../../../core/enum/permission";
import {PaginationParams} from "../common/pagination-params";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../common/paginated-response";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {Stakeholder} from '../../../akita/stakeholders/state/stakeholder.model';
import {Decision} from '../../../akita/requirements/state/decision.model';
import {Interest} from '../../../akita/interests/state/interest.model';

@Injectable({
  providedIn: 'root'
})
export class IssuesService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  getUnfilteredIssues(pagination: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/issues`, pagination.get());
  }

  getUnfilteredIssuesRelation(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get(`/api/issues?dropdown=1`, this.httpOptions);
  }
  @ServiceErrorHandler()
  getIssues(pagination: PaginationParams, status = 'open', myIssues: boolean = false, urgency: string = null, direction: string = null, extra: string = null) {
    let params = new HttpParams();
    params = params.append('is_mine', myIssues ? 'true' : 'false');
    if (status) {
      params = params.append('issues_status', status);
    }
    if(extra){
      params = params.append('with',extra);
    }
    if (urgency) {
      params = params.append('urgency', urgency);
    }
    return this.http.get(`/api/issues`, pagination.get(params));
  }

  @ServiceErrorHandler()
  exportDecisions(options) {
    return this.http.get(`/file/export/issue?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  getIssue(code, withs = []) {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }

    return this.http.get(`/api/issues/${code}`, {
      headers: this.headers,
      params: params
    });
  }

  @Allowed(Permission.IssuesUpdate, true)
  updateIssue(issue) {
    return this.http.put(`/api/issues/${ issue.code}`, issue, this.httpOptions);
  }

  @Allowed(Permission.IssuesUpdate, true)
  @ServiceErrorHandler()
  submitIssue(issue) {
    return this.http.post('/api/issues', issue, this.httpOptions);
  }

  getIssuedialog(issueCode) {
    return this.http.get(`/api/issues/${ issueCode }/issuedialogs`, this.httpOptions);
  }

  @Allowed(Permission.IssuesDelete, true)
  removeIssueByCode(issuedialog) {
    return this.http.delete(`/api/issues/${ issuedialog.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  assignDecision(data: {decisions: Decision[], stakeholder: Stakeholder}, code: string): Observable<any> {
    return this.http.post(`/api/issues/${code}/decisions`, data, this.httpOptions);
  }

  @ServiceErrorHandler()
  assignInterest(interests: Interest[], code: string): Observable<any> {
    return this.http.post(`/api/issues/${code}/interests`, {
      interests: interests,
    }, this.httpOptions);
  }

}
