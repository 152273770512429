import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {AbstractService} from "../common/abstract.service";
import { map } from 'rxjs';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { Permission } from '../../enum/permission';
import { s } from '@fullcalendar/core/internal-common';
import moment from 'moment';
import { showFeedbackSaved } from '../../util/notification';

@Injectable({
  providedIn: 'root'
})
export class BatchMapItemsService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  createItem(text: string, type: string) {
    type = type === 'requirements' ? 'decisions' : type;
    const entity = this.createEntity(text, type);
    return this.http.post(`/api/${type}`, entity, this.httpOptions).pipe(
      map(response => [response])
    );
  }

  createEntity(text: string, type: string) {
    switch (type) {
      case 'stakeholders':
        return { name: text };
      case 'issues':
        return {
          name: text,
          issues_date: new Date(),
          urgency: 'middle',
          issues_status: 'open',
          impact: 'low',
          deadline: moment(new Date()).add(1, 'months'),
          issues_score: 41.66
        };
      case 'activities':
        return {
          calendars: {
            start: new Date(),
            title: text
          },
          name: text
        };
      case 'complaints':
        return {
          text: text,
          date: new Date()
        };
      case 'decisions':
        return {
          title: text
        };
    }
  }
}
