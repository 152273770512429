import { Injectable } from '@angular/core';
import { DecisionsStore } from './decisions.store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from '../../../core/service/common/abstract.service';
import { Decision } from './decision.model';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaginationParams } from '../../../core/service/common/pagination-params';
import { PaginatedResponse } from '../../../core/service/common/paginated-response';
import { DecisionsQuery } from './decisions.query';
import { deepCopy } from '../../../core/util/deepCopy';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';

@Injectable({ providedIn: 'root' })
export class DecisionsService extends AbstractService {

  constructor(private decisionsStore: DecisionsStore, private decisionQuery: DecisionsQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams): Observable<Decision[]> {
    return this.http.get(`/api/decisions`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Decision>) => this.decisionsStore.set(response.data)),
        map((response: PaginatedResponse<Decision>) => response.data)
      );
  }

  @ServiceErrorHandler()
  public getSummary(id): Observable<any> {
    return this.http.get(`/api/map/summary/decisions/${id}`)
      .pipe(
        map((response: Decision) => response)
      );
  }

  @ServiceErrorHandler()
  public find(id: string, withs: string[] = []): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }

    const request = this.http.get(`/api/decisions/${id}`, {
      headers: this.headers,
      params: params
    })
      .pipe(
        tap((decision: Decision) => {
          if (this.decisionsStore.getValue().entities && Object.entries(this.decisionsStore.getValue().entities).length > 0) {
            this.decisionsStore.update(decision);
          } else {
            this.decisionsStore.set([deepCopy(decision)]);
          }

          this.decisionsStore.setActive(decision.id);
        })
      );

    return request;
  }

  @ServiceErrorHandler()
  public update(decision: Decision): Observable<Decision> {
    this.decisionsStore.update(decision.id, decision);
    return this.http.put(`/api/decisions/${decision.id}`, decision, this.httpOptions)
      .pipe(map((response: Decision) => response));
  }

  @ServiceErrorHandler()
  public create(decision: Decision): Observable<Decision> {
    return this.http.post('/api/decisions', decision, this.httpOptions)
      .pipe(tap((response: Decision) => {
        this.decisionsStore.add(decision);
      }));
  }

  @ServiceErrorHandler()
  public delete(decision: Decision): Observable<any> {
    return this.http.delete(`/api/decisions/${decision.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.decisionsStore.remove(decision.id);
      }));
  }
  @ServiceErrorHandler()
  getDecisionsWidget(pagination: PaginationParams): Observable<PaginatedResponse> {
    let params = new HttpParams();
    params = params.append('count','1000');
    params = params.append('order_by','id');
    params = params.append('direction','asc');
    params = params.append('is_mine','true');
    params = params.append('filter_array[status][]','initial');
    return <Observable<PaginatedResponse>> this.http.get(`/api/decisions`, pagination.get(params));
  }
}
