import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocuploadType} from "../../../../core/enum/docupload-type";
import {DocumentsService} from "../../../../core/service/api/documents.service";
import {GenericComponent} from "../../../../core/util/abstract/generic-component";
import {DeletionModalComponent} from "../../../../shared/components/modal/deletion-modal/deletion-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { Lightbox } from 'ngx-lightbox';
import { PdfViewComponent } from '../../modal/pdf-view/pdf-view.component';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-documents-overview',
  templateUrl: './documents-overview.component.html',
  styleUrls: ['./documents-overview.component.sass']
})
export class DocumentsOverviewComponent extends GenericComponent implements OnInit {

  @Input() documents: any[];
  @Input() type: DocuploadType;
  @Input() id: number;
  @Input() connectedToExistingEntity: boolean = true;
  @Input() entities: any = [];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDocumentRemoved: EventEmitter<number> = new EventEmitter();
  public keyword: string = '';
  public displayedColumns: string[] = ['id','name','action'];
  public tableData = new MatTableDataSource([]);;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private _lightbox: Lightbox,private _documentsService: DocumentsService, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.entities = new MatTableDataSource(this.entities);
      this.tableData = this.entities;
      this.validateComponent();
    }, 2000);
  }
  //on apply filter item
  public applyFilter(event): void{
    this.entities.filter = event.trim().toLowerCase();
  }

  public removeDoc(document) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u het document wilt verwijderen?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe( (remove: boolean) => {
      if (remove) {
        if (this.connectedToExistingEntity) {
          this._documentsService.removeDocument(this.type, this.id, document.id)
            .subscribe(() => this.onDocumentRemoved.emit(document.id));
        } else {
          this.onDocumentRemoved.emit(document.id);
        }
      }
    });
  }

  private validateComponent() {
    // if (!this.type) {
    //   throw new TypeError('Attribute "type" is mandatory');
    // }

    // if (this.connectedToExistingEntity && !this.id) {
    //   throw new TypeError('Attribute "id" is mandatory');
    // }
  }

  public lightboxOpen(document){
    const albums = [];
    albums.push({
      src: (document.type=='freshdesk' || document.type=='bouwapp') ? document.path :document.public_link,
      caption: document.name,
      thumb: document.path,
    });
    this._lightbox.open(albums);
  }
  //pdf viewer modal
  public onPDFView(data){
    data.attachment_url=data.path;
    this.dialog.open(PdfViewComponent, modalConfig({
      data:data,
      panelClass: ['animate__animated', 'animate__slideInUp','transparent_bg'],
    }, ModalEnum.ModalDefault));
  }
  cancelSearch(){
    this.keyword = '';
    this.entities.filter = this.keyword;
  }
}
