import { GlobalErrorHandlerService } from './core/service/util/global-error-handler.service';
// eslint-disable-next-line no-use-before-define
export function bootstrapServiceFactory(bootstrapService: BootstrapService): Function {
  return () => bootstrapService.load();
}
import {
  APP_INITIALIZER, ApplicationRef, Compiler,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
  enableProdMode,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ChildrenOutletContexts, ROUTES, Router, RouterModule, UrlSerializer } from '@angular/router';
import localeNL from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { Globals } from './globals';
import { TranslatePaginator } from './core/util/extend/translatePaginator';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { Location, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './routes.module';
import { MATERIALS } from './app.material';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { setAppInjector } from '../app-injector';
import { NgxSelectModule } from 'ngx-select-ex';
import { BootstrapService } from "./core/service/util/bootstrap.service";
import { CallbackComponent } from './features/callback/components/callback/callback.component';
import { AuthConstants } from "./auth-constants";
import { AuthInterceptorService } from "./core/interceptor/auth-interceptor.service";
import { ProjectConfigCallbackComponent } from './features/main-app/project-config/page/project-config/project-config-callback/project-config-callback.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { provideTransloco, TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule } from '@jsverse/transloco';
import { RequestInterceptorService } from './core/interceptor/request-interceptor.service';
import { enableAkitaProdMode } from '@datorama/akita';
import { projectAwareRouterFactory } from './core/util/projectAwareRouterFactory';
import { SubprojectQuery } from './akita/subproject/state/subprojects.query';
import { ClosableSnackbarComponent } from './shared/components/material/closable-snackbar/closable-snackbar.component';
import { UndoSnackbarComponent } from './shared/components/material/undo-snackbar/undo-snackbar.component';
import { ProjectsOverviewModalComponent } from './features/projects-overview/component/projects-overview-modal/projects-overview-modal.component';
import { SharedModule } from './shared/shared.module';
import { GlobalConfig } from './core/util/config/global-config';
import { ArcGisRedirectComponent } from './features/callback/components/arc-gis-redirect/arc-gis-redirect.component';
import { CallbackPublicfacingApiComponent } from './features/callback/components/callback-publicfacing-api/callback-publicfacing-api.component';
import { ProjectUnauthorizedModalComponent } from './features/projects-overview/component/project-unauthorized-modal/project-unauthorized-modal.component';
import { DateTimeAdapter } from 'ng-pick-datetime';
import {MatIconModule} from '@angular/material/icon';
import { OutlookRedirectComponent } from './features/callback/components/outlook-redirect/outlook-redirect.component';
import { FacebookService } from './core/service/common/facebook.service';
import { HttpLoader } from './transloco.loader';

registerLocaleData(localeNL, 'nl-NL', localeNlExtra);
enableProdMode();
enableAkitaProdMode();
@NgModule({
  declarations: [
    AppComponent,
    ArcGisRedirectComponent,
    OutlookRedirectComponent,
    CallbackComponent,
    CallbackPublicfacingApiComponent,
    ClosableSnackbarComponent,
    ProjectsOverviewModalComponent,
    UndoSnackbarComponent,
    ProjectConfigCallbackComponent,
    ProjectUnauthorizedModalComponent
  ],
  imports: [
    AppRoutingModule,
    NgxSelectModule,
    ...MATERIALS,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    FormsModule,
    MatIconModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    TranslocoModule,
    SharedModule,
  ],
  providers: [
    {
      provide: ErrorHandler, useClass: GlobalErrorHandlerService
    },
    Globals,
    AuthConstants,
    BootstrapService,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: bootstrapServiceFactory,
      deps: [BootstrapService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: MatPaginatorIntl, useClass: TranslatePaginator },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: DateTimeAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: DROPZONE_CONFIG, useValue: GlobalConfig.DEFAULT_DROPZONE_CONFIG },
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } },
    provideTransloco({
      config: {
          availableLangs: ['en', 'nl'],
          defaultLang: 'nl',
          reRenderOnLangChange: true,
          prodMode: environment.production,
      },
      loader: HttpLoader
  }),
  {
    provide: Router,
    useFactory: projectAwareRouterFactory,
    deps: [ApplicationRef, UrlSerializer, ChildrenOutletContexts, Location, Injector, Compiler, ROUTES, SubprojectQuery]
  },
    FacebookService
  ],
  bootstrap: [AppComponent],
  // entryComponents: [ClosableSnackbarComponent, ProjectsOverviewModalComponent,UndoSnackbarComponent]
})
export class AppModule {
  router: Router;

  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
