<div class="row popup-container" *transloco="let t">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
          <mat-icon class="d-text-middle ui uil-multiply"></mat-icon>
        </span><span class="sr-only">Close</span></button>
            <div *hasPermission="[Permission.ComplaintsDelete]">
                <mat-icon *ngIf="complaint" (click)="deleteComplaint(complaint)" class="d-cursor-hand uil uil-trash delete pull-right complaints-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}"></mat-icon>
            </div>
            <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
                <button  *ngIf="complaint" type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
                   <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
                </button>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1>
                <strong>{{ 'complaints.label' | transloco | capitaliz }} </strong>
            </h1>
            <h1 [innerHTML]="name | safeHtml" class="html-content"></h1>
        </div>
    </div>
    <mat-progress-bar *ngIf="!complaint && !reducer" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="complaint && reducer">
        <app-workflow-generic *ngIf="complaint && complaint.workflows && complaint.workflows.length && hasModule('workflow')" [margin]="false" parentType="complaints" [deadline]="complaint.deadline" [workflows]="complaint.workflows" [permitId]="complaint.id"></app-workflow-generic>
        <!-- left col -->
        <div class="col-md-7 col-xs-12">
            <h3>{{ 'decisions.connections' | transloco | capitaliz }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component">
                <div>
                    <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                    <app-add-connect-generic parentType="complaints" (onChange)="updateEntities($event)" *ngIf="loaded" [parent]="complaint" [data]="entities" [types]="['tasks','issues', 'requirements', 'contactmoments','documents','layers']">
                    </app-add-connect-generic>

                </div>
            </div>

            <!-- Contact -->
            <div class="col-xs-12 pd-0">
                <h3>{{ 'contact' | transloco }}</h3>
            </div>

            <div class="col-xs-12 panel z-depth-2 panel-border">
                <div class="col-xs-12">
                    <app-multiselect-checkbox #employeeMultiselect type="contactpersons" [placeholder]="'reusable.selecteer_contactpersoon' | transloco" name="employees" [(ngModel)]="complaint.contactpersons" (onStakeholdersUpdated)="onStakeholdersUpdated($event)" valueType="object" [multiple]="false" (ngModelChange)="employeeSet()">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                            <app-generic-multiselect-checkbox-option *ngFor="let employee of employees" [value]="employee">
                            {{ employee.first_name }} {{ employee.last_name }}
                            </app-generic-multiselect-checkbox-option>
                        <app-generic-multiselect-search-project entity="contactpersons"></app-generic-multiselect-search-project>
                    </app-multiselect-checkbox>
                </div>
                <div class="col-xs-12">
                    <app-generic-multiselect-checkbox type="stakeholders" [ngClass]="{'disable_enitiy': reducer.stakeholder}" #stakeholderMultiselect [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" [(ngModel)]="complaint.stakeholders" [multiple]="true"
                        (ngModelChange)="stakeholderSet()"  [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [matTooltip]="reducer.stakeholder ? t('settings.relatic.disable_field') : null" [uniqueId]="'select-stakeholders'">
                        <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                            {{ stakeholder.name }}
                        </app-generic-multiselect-checkbox-option>
                        <app-generic-multiselect-checkbox-option-new-action type="stakeholder"></app-generic-multiselect-checkbox-option-new-action>
                        <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
                    </app-generic-multiselect-checkbox>
                </div>
                <div class="col-xs-12">
                    <ng-container *ngIf="!globals.projectConfigs['residents.disabled']">
                        <div class="col-md-5">
                            <mat-divider class="pull-right width-50"></mat-divider>
                        </div>
                        <div class="col-md-2 text-center"> of</div>
                        <div class="col-md-5">
                            <mat-divider class="width-50"></mat-divider>
                        </div>
                    </ng-container>
                        <app-generic-multiselect-checkbox *ngIf="!globals.projectConfigs['residents.disabled']" placeholder="Selecteer omwonende" name="residents" valueType="object" [(ngModel)]="complaint.residents" [multiple]="false" [disabled]="complaint.stakeholders" (ngModelChange)="residentSet()">
                            <app-generic-multiselect-checkbox-option-all>{{ 'filter_select_all' | transloco | capitaliz }}</app-generic-multiselect-checkbox-option-all>
                            <app-generic-multiselect-checkbox-option *ngFor="let resident of residents" [value]="resident">
                                {{ resident.name }}
                            </app-generic-multiselect-checkbox-option>
                            <app-generic-multiselect-checkbox-option-new-action type="resident"></app-generic-multiselect-checkbox-option-new-action>
                            <app-generic-multiselect-search-project entity="residents"></app-generic-multiselect-search-project>
                        </app-generic-multiselect-checkbox>
                </div>
                <div>
                    <div *ngIf="complaint.contactpersons">
                        <h4 class="col-xs-12">Contactpersoon
                             <ng-container *ngIf="complaint.stakeholders && complaint.stakeholders.name">
                                ({{ complaint.stakeholders.name }})
                            </ng-container>
                        </h4>
                        <div class="contact-row">
                            <div (click)="editEmployee(complaint.contactpersons)" class="col-xs-4 inline-flex d-cursor-hand">
                                <mat-icon class="ashgrey">person_pin</mat-icon>
                                <span class="ml-5">{{ complaint.contactpersons.first_name }}
                                    {{ complaint.contactpersons.last_name }}
                                </span>
                            </div>
                            <ng-container>
                                <div class="col-xs-4 inline-flex">
                                    <mat-icon class="ashgrey">email</mat-icon>
                                    <span class="ml-5" *ngIf="complaint.contactpersons.email">
                                        <a [href]="generateMailtoLink(complaint, complaint.contactpersons.email)" matTooltip="{{'reusable.reply_to_notification' | transloco}}">
                                            {{ complaint.contactpersons.email }}
                                        </a>
                                    </span>
                                    <span class="ml-5" *ngIf="!complaint.contactpersons.email"><i>{{'reusable.unknown' | transloco}}</i></span>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="col-xs-4 inline-flex">
                                    <mat-icon class="ashgrey">phone</mat-icon>
                                    <span class="ml-5" *ngIf="complaint.contactpersons.phone">
                                        <a [href]="'tel:' + complaint.contactpersons.phone">
                                            {{ complaint.contactpersons.phone }}
                                        </a>
                                    </span>
                                    <span class="ml-5" *ngIf="!complaint.contactpersons.phone"><i>{{'reusable.unknown' | transloco}}</i></span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="!complaint.contactpersons && complaint.stakeholders && complaint.stakeholders.length">
                        <div *ngFor="let stakeholder of complaint.stakeholders">
                            <div *ngIf="stakeholder.contactpersons && stakeholder.contactpersons.length > 0">
                                <h4 class="col-xs-12 inline-block mb-20">
                                    Contactpersonen
                                    <ng-container *ngIf="complaint.stakeholders && complaint.stakeholders.name"> ({{ stakeholder.name }})
                                    </ng-container>
                                </h4>

                                <div *ngFor="let employee of stakeholder.contactpersons; trackBy: trackByFn">
                                    <div class="contact-row-contactpersonen">
                                        <div class="col-xs-4 inline-flex d-cursor-hand" (click)="editEmployee(employee)">
                                            <mat-icon class="ashgrey">person_pin</mat-icon><span class="ml-5">{{ employee.first_name }}
                        {{ employee.last_name }}</span>
                                        </div>
                                        <ng-container>
                                            <div class="col-xs-4 inline-flex">
                                                <mat-icon class="ashgrey">email</mat-icon>
                                                <span class="ml-5" *ngIf="employee.email"><a
                            [href]="generateMailtoLink(complaint, employee.email)"
                            matTooltip="{{'reusable.reply_to_notification' | transloco}}">{{ employee.email }}</a></span>
                                                <span class="ml-5" *ngIf="!employee.email"><i>{{'reusable.unknown' | transloco}}</i></span>
                                            </div>
                                        </ng-container>
                                        <ng-container>
                                            <div class="col-xs-4 inline-flex">
                                                <mat-icon class="ashgrey">phone</mat-icon>
                                                <span class="ml-5" *ngIf="employee.phone"><a
                            [href]="'tel:' + employee.phone">{{ employee.phone }}</a></span>
                                                <span class="ml-5" *ngIf="!employee.phone"><i>{{'reusable.unknown' | transloco}}</i></span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="!complaint.contactpersons && complaint.stakeholders && (!complaint.stakeholders.employees || complaint.stakeholders.employees.length === 0)">
                        <div *ngFor="let stakeholder of complaint.stakeholders">
                            <h4 class="col-xs-12 inline-block">Stakeholder</h4>
                            <div class="contact-row">
                                <div class="col-xs-8 inline-flex">
                                    <mat-icon class="ashgrey">person_pin</mat-icon><span class="ml-5">{{ stakeholder.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="complaint.residents && complaint.residents.name">
                        <h4 class="col-xs-12 inline-block">Omwonende</h4>
                        <div class="contact-row">
                            <div class="col-xs-4 inline-flex">
                                <mat-icon class="ashgrey">person_pin</mat-icon><span class="ml-5">{{ complaint.residents.name }}</span>
                            </div>
                            <ng-container>
                                <div class="col-xs-4 inline-flex">
                                    <mat-icon class="ashgrey">email</mat-icon>
                                    <span class="ml-5" *ngIf="complaint.residents.email"><a
                      [href]="generateMailtoLink(complaint, complaint.residents.email)"
                      matTooltip="{{'reusable.reply_to_notification' | transloco}}">{{ complaint.residents.email }}</a></span>
                                    <span class="ml-5" *ngIf="!complaint.residents.email"><i>{{'reusable.unknown' | transloco}}</i></span>
                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="col-xs-4 inline-flex">
                                    <mat-icon class="ashgrey">phone</mat-icon>
                                    <span class="ml-5" *ngIf="complaint.residents.phone"><a
                      [href]="'tel:' + complaint.residents.phone">{{ complaint.residents.phone }}</a></span>
                                    <span class="ml-5" *ngIf="!complaint.residents.phone"><i>{{'reusable.unknown' | transloco}}</i></span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- <div class="col-xs-6 mt-10" *hasPermission="[Permission.EmployeesCreate]">
                        <a mat-stroked-button *ngIf="complaint.contactpersons || complaint.stakeholders || complaint.residents || complaint.complainant_name" class="d-cursor-hand" (click)="editContact()">
                          {{'reusable.change_contact_details' | transloco}}
                        </a>
                        <div class="col-xs-12 pd-0" *ngIf="!complaint.contactpersons && !complaint.stakeholders && !complaint.residents && !complaint.complainant_name">
                            <a mat-stroked-button class="mb-5 d-cursor-hand edit-contact-info" (click)="editContact()">
                              {{'reusable.change_contact_details' | transloco}}
                            </a>
                        </div>
                    </div> -->
                    <div *ngIf="complaint.complainant_name">
                        <h4 class="col-xs-12">{{'reusable.entered_contact_details' | transloco}}</h4>
                        <div class="contact-row">
                            <div class="col-xs-3 inline-flex">
                                <mat-icon class="ashgrey">person_pin</mat-icon><span class="ml-5">{{ complaint.complainant_name }}</span>
                            </div>
                            <div class="col-xs-4 inline-flex">
                                <mat-icon class="ashgrey">email</mat-icon>
                                <span *ngIf="complaint.complainant_email" class="ml-5"><a
                    [href]="generateMailtoLink(complaint, complaint.complainant_email)"
                    *ngIf="complaint.complainant_email"
                    matTooltip="{{'reusable.reply_to_notification' | transloco}}">{{ complaint.complainant_email }}</a></span>
                                <span *ngIf="!complaint.complainant_email" class="ml-5"><i>{{'reusable.unknown' | transloco}}</i></span>
                            </div>
                            <div class="col-xs-3 inline-flex">
                                <mat-icon class="ashgrey">phone</mat-icon>
                                <span *ngIf="complaint.complainant_phone" class="ml-5"><a
                    [href]="'tel:' + complaint.complainant_phone">{{ complaint.complainant_phone }}</a></span>
                                <span *ngIf="!complaint.complainant_phone" class="ml-5"><i>{{'reusable.unknown' | transloco}}</i></span>
                            </div>
                            <div class="col-xs-2 inline-flex" *hasPermission="[Permission.EmployeesCreate]" style="justify-content:center;">
                                <i class="uil uil-pen d-flex mr-1" (click)="editContact()" style="font-size:22px !important; color: blue; cursor: pointer"></i>
                                <i class="uil uil-plus-circle d-flex" (click)="addManualInformationAsContactperson()" style="font-size:22px !important; color: blue; cursor: pointer"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- map-->
            <br>
            <h3 class="mb-15 map-heading text-capitalize">{{ 'location' | transloco }}</h3>
            <div class="col-xs-12 panel z-depth-2 panel-border map-div">
                <div class="col-xs-12" [matTooltip]="reducer.coordinates ? t('settings.relatic.disable_field') : null">
                    <app-openlayers-map [ngClass]="{'disable_enitiy': (reducer.coordinates || !hasPermission(Permission.ComplaintsUpdate))}" *ngIf="complaint" [(ngModel)]="complaint.locations" [includeSidebar]="false" [defaultCoordinate]="globals.projectConfig.default_map"
                        [showMapReset]="true" [maxPoints]="1" (ngModelChange)="updateCoordinate()" name="locations" locatableType="complaints" defaultDrawTool="point" clickStartDrawingMsg="{{'reusable.sign_notifi_point' | transloco}}" [disabled]="reducer.coordinates || !hasPermission(Permission.ComplaintsUpdate)"></app-openlayers-map>
                </div>
            </div>

        </div>

        <!-- right col -->
        <div class="col-md-5 col-xs-12">


            <!-- Afhandeling-->

                <h3 class="text-capitalize">{{ 'reusable.handling' | transloco | capitaliz }}</h3>
                <div class="col-xs-12 panel z-depth-2 panel-border map-div">
                    <mat-progress-bar *ngIf="!is_load" mode="indeterminate"></mat-progress-bar>
                    <div *ngIf="is_load" >
                        <div class="col-xs-12 col-sm-3 col-md-3 mt-6 responsive-set">{{ 'reusable.handling' | transloco | capitaliz }}</div>
                        <div class="col-xs-12 col-sm-9 col-md-9 responsive-set-mt complaint-status-div display-flex-end">
                          <ng-container *ngIf="sentimentEnable">
                            <div class="sentiment-icon" *ngIf="this.complaint.sentiments && this.complaint.sentiments.length >= 1">
                              <mat-icon mat-icon-button [matMenuTriggerFor]="menu" (click)="menuToggle = !menuToggle" [ngClass]="selectedIcon.icon" style="font-size: 29px !important"></mat-icon>
                              <mat-menu #menu="matMenu" class="sentiment-menu" xPosition="before">
                                  <p class="pb-0 menu-label" style="font-size: 13px !important; font-family: work_sans_regular !important;">Sentiment</p>
                                  <div class="menu-items">
                                      <mat-option *ngFor="let o of sentiments" [value]="o" [ngClass]="{ 'selected': o !== selectedIcon }" (click)="sentimentChange(o)">
                                          <mat-icon [ngClass]="o.icon" style="font-size: 29px !important"></mat-icon>
                                      </mat-option>
                                  </div>
                              </mat-menu>
                          </div>
                          </ng-container>
                            <div *ngIf="integrationType.chatShow">
                                <div *ngIf="!integrationsEnabled && complaint.source !== 'whatsapp'" class="disableDiv p-relative" [matTooltip]="t('settings.'+integrationType.type+'.not_access')">
                                    <button disabled=true (click)="openChat()" type="button" class="span-bouwapp"><img [src]='integrationType.chatIcon' class="integration-chat">{{'settings.open_chat'| transloco}}</button>
                                    <span [class]="complaint.is_closed ? 'off badge status text-black r-0' : 'on badge status r-0'"><i [class]="complaint.is_closed ? 'uil uil-check' : 'uil uil-comment-dots'"></i></span>
                                </div>
                                <div *ngIf="integrationsEnabled && complaint.source !== 'whatsapp'" class="p-relative">
                                    <button (click)="openChat()" type="button" class="span-bouwapp"><img [src]='integrationType.chatIcon' class="integration-chat">{{'settings.open_chat'| transloco}}</button>
                                    <span [class]="complaint.is_closed ? 'off badge status text-black r-0' : 'on badge status r-0'"><i [class]="complaint.is_closed ? 'uil uil-check' : 'uil uil-comment-dots'"></i></span>
                                </div>
                                <div *ngIf="!integrationsEnabled && complaint.source === 'whatsapp'" class="disableDiv p-relative" [matTooltip]="t('settings.'+integrationType.type+'.not_access')">
                                  <button disabled=true (click)="openChat()" type="button" class="span-bouwapp"><img [src]='integrationType.chatIcon' class="integration-chat">{{'settings.open_chat'| transloco}}</button>
                                  <span [class]="complaint.is_closed ? 'off badge status text-black r-0' : 'on badge status r-0'"><i [class]="complaint.is_closed ? 'uil uil-check' : 'uil uil-comment-dots'"></i></span>
                              </div>
                                <div class="p-relative" *ngIf="integrationsEnabled && complaint.source === 'whatsapp'">
                                  <button (click)="openChat()" type="button" class="span-bouwapp"><img [src]='integrationType.chatIcon' class="integration-chat">{{'settings.open_chat'| transloco}}</button>
                                  <span [class]="complaint.is_closed ? 'off badge status text-black r-0' : 'on badge status r-0'"><i [class]="complaint.is_closed ? 'uil uil-check' : 'uil uil-comment-dots'"></i></span>
                              </div>
                            </div>
                            <div [ngClass]="{'disableDiv': (integrationType.chatShow && !integrationsEnabled)}" [matTooltip]="(integrationType.chatShow && !integrationsEnabled) ? t('settings.'+integrationType.type+'.not_access') : null">
                                <span [class]="complaint.is_closed ? 'alert alert-succes complaint-status' : kpiSet ? 'complaint-status alert '+loadKPIcolorSet('color',complaint) : 'alert alert-danger complaint-status'" (click)="showStatusField = !showStatusField">
                              <span *ngIf="complaint.is_closed">{{ 'complaints.status_closed' | transloco | capitaliz }}</span>
                                <span *ngIf="!complaint.is_closed">{{ kpiSet ? loadKPIcolorSet('text',complaint) : ('complaints.open' | transloco)}}</span>
                                <i *ngIf="!showStatusField" class="d-text-middle uil uil-arrow-down unicon-icon"></i>
                                <i *ngIf="showStatusField" class="d-text-middle uil uil-arrow-up unicon-icon"></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="showStatusField" class="col-xs-12">
                            <form (submit)="toggleHandleComplaint()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-form-field class="full-width" [matTooltip]="reducer.closed_date ? t('settings.relatic.disable_field') : null">
                                          <mat-label>{{ 'reusable.date_of_settlement' | transloco }}</mat-label>
                                            <input matInput [matDatepicker]="closed_date" name="closed_date" [(ngModel)]="complaint.closed_date" (focus)="closed_date.open()"
                                                [disabled]="complaint.is_closed || reducer.closed_date || !hasPermission(Permission.ComplaintsUpdate) || integrationType.type=='freshdesk'" (mousedown)="closedDateLoaded()">
                                            <mat-datepicker-toggle matSuffix [for]="closed_date"></mat-datepicker-toggle>
                                            <mat-datepicker #closed_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-form-field class="full-width" [matTooltip]="reducer.closed_description ? t('settings.relatic.disable_field') : null">
                                          <mat-label>{{ 'reusable.handling' | transloco | capitaliz}}</mat-label>
                                            <input matInput name="closed_description" [(ngModel)]="complaint.closed_description" [disabled]="complaint.is_closed  || reducer.closed_description || !hasPermission(Permission.ComplaintsUpdate) || integrationType.type=='freshdesk'"
                                                required>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button *ngIf="!complaint.is_closed" type="submit" mat-flat-button color="primary" class="pull-right" [disabled]="!complaint.closed_date || !complaint.closed_description || complaintStatusButton">{{'reusable.handling' | transloco | capitaliz}}</button>
                                <button [disabled]='integrationType.chatClosed || complaintStatusButton' *ngIf="complaint.is_closed" type="submit" mat-stroked-button class="pull-right">{{'reusable.to_process' | transloco | capitaliz}}</button>
                            </form>
                        </div>
                        <span class="col-xs-12 pt-10" *ngIf="complaint.is_closed && complaint.closed_by"><span class="closer-hint">{{complaint.closed_date | formatdate: 'dd MMMM YYYY' : globals.timezone | capitaliz}}</span><strong> {{complaint.closed_by.name}} {{'reusable.closed_it' | transloco }} {{'complaints.label' | transloco }}</strong></span>
                        <span class="col-xs-12 closer-hint" *ngIf="complaint.is_closed && complaint.closed_by && !showStatusField">{{complaint.closed_description}}</span>
                    </div>
                </div>
            <div class="row">
                <div class="col-md-12">
                    <app-comment-box [users]="users" type="complaints" [type_id]="complaint.id"></app-comment-box>
                </div>
            </div>
            <h3>{{ 'text.general' | transloco | capitaliz }}</h3>

            <div class="col-xs-12 panel z-depth-2 panel-border">
                <div class="col-xs-12 mt-10" *ngIf="integrationType.type=='freshdesk'">
                    <mat-form-field class="full-width">
                      <mat-label>FreshDesk ID</mat-label>
                        <input matInput name="freshdesk_id" [(ngModel)]="complaint.ticket_id" disabled>
                    </mat-form-field>
                </div>
                <div class="col-xs-12 mt-10" [matTooltip]="reducer.date ? t('settings.relatic.disable_field') : null">
                    <mat-form-field class="full-width" [matTooltip]="integrationType.chatShow  ? t('settings.'+integrationType.type+'.not_access') : null">
                      <mat-label>{{ 'reusable.date' | transloco }}</mat-label>
                        <input [ngClass]="{'disableDate': integrationType.chatShow || !hasPermission(Permission.ComplaintsUpdate)}" [disabled]='integrationType.chatShow || reducer.date || !hasPermission(Permission.ComplaintsUpdate)' matInput [matDatepicker]="date" (change)="updateComplaint()"
                            name="date" [(ngModel)]="complaint.date" (focus)="date.open()" (dateChange)="onDeadlineChange()">
                        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-xs-12" *ngIf="hasModule('communication') && hasSLA()">
                    <mat-form-field *transloco="let t" class="w-100">
                      <mat-label>{{ t('complaints.priority')}}</mat-label>
                        <mat-select  name="priority" [(value)]="complaint.priority" (valueChange)="onDeadlineChange()" required [disabled]="integrationType.chatShow || !hasPermission(Permission.ComplaintsUpdate)">
                            <mat-option value="low">{{ "complaints.low" | transloco | capitaliz }}</mat-option>
                            <mat-option value="medium">{{ "complaints.medium" | transloco | capitaliz }}</mat-option>
                            <mat-option value="high">{{ "complaints.high" | transloco | capitaliz }}</mat-option>
                            <mat-option value="urgent">{{ "complaints.urgent" | transloco | capitaliz }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="col-xs-12">
                  <app-mat-datepicker-time-header
                    [tooltip]="hasSLA() ? t('projects.sla_disabled') : null"
                    [placeholder]="'complaints.duedate' | transloco | capitaliz"
                    [isDisabled]="hasSLA() || integrationType.chatShow || !hasPermission(Permission.ComplaintsUpdate)"
                    [(selectedDateTime)]="complaint.deadline"  (selectedDateTimeChange)="onDateTimeChange($event)" [matFormFieldWidth]="'100%'" >
                  ></app-mat-datepicker-time-header>
                </div>
                <div class="col-xs-12" [matTooltip]="reducer.theme ? t('settings.relatic.disable_field') : null">
                    <app-add-connect-themes [matTooltip]="integration_themeDisable ? t('settings.'+integrationType.type+'.not_access') : null" [editButton]="true" [editable]="false" valueType="object" [type]="themeType" [buttonText]="'complaints.theme_button_text'" [textMultiple]="'type melding'" [textSingle]="'type melding'" [selectedTheme]="selectedTheme" (onThemeSelected)="updateSelectedTheme($event)"
                        [disabled]="reducer.theme || !hasPermission(Permission.ComplaintsUpdate) || integration_themeDisable" [uniqueId]="'add-themes'"></app-add-connect-themes>
                </div>
                <div [matTooltip]="reducer.text ? t('settings.relatic.disable_field') : null">
                    <div class="col-xs-12 full-width" [ngClass]="{'disable_enitiy': reducer.text || !hasPermission(Permission.ComplaintsUpdate)}">
                        <div>{{ 'complaints.label' | transloco | capitaliz }}</div>
                        <editor class="font-family-set " [matTooltip]="integrationType.chatShow ? t('settings.'+integrationType.type+'.not_access') : null" [disabled]="integrationType.chatShow" (onBlur)="updateComplaint()" placeholder="Melding" [apiKey]="apiKey" [(ngModel)]="complaint.text" name="text" [init]="{
                        plugins: 'lists',
                        branding: false,
                        menubar: false,
                        language: haslanguageCode() === 'en' ? haslanguageCode()+'_GB' : haslanguageCode(),
                        language_url: 'assets/js/tinymce.nl.js',
                        statusbar: false,
                        resize:true,
                        min_height: 300,
                        toolbar:false,
                        block_unsupported_drop: false,
                        paste_block_drop: false,
                        paste_data_images: true,
                        paste_as_text: true,
                        font_formats: 'Work Sans, sans-serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,times new roman,times,serif;Verdana=verdana,geneva,sans-serif',
                        content_style:'@font-face{font-family: Work Sans;  src: url(../../../../../../../assets/fonts/WorkSans-Regular.ttf)} body { font-family: Work Sans; font-size: 13px; } .mce-content-body{ line-height: 1.2 !important; font-family: Work Sans; font-size: 13px;} p{margin-block-start: 0px !important; margin-block-end: 0px !important}'
                        }">
                        </editor>
                    </div>
                </div>
                <div class="col-xs-12">
                    <app-multiselect-users [chipDisabler]="chipDisablerUser" [disabled]="!hasPermission(Permission.ComplaintsUpdate)" *ngIf="users" [placeholder]="'reusable.responsible' | transloco" [multipleUsers]="true" [selectedUsers]="complaint.users" [users]="users" (onSelectedUsersUpdated)="updateUser($event)">
                    </app-multiselect-users>
                </div>

                <div class="col-xs-12" [ngClass]="{'disable_enitiy': !hasPermission(Permission.ComplaintsUpdate)}">
                    <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [disabled]="!hasPermission(Permission.ComplaintsUpdate) || globals.subprojects.length === 0" [parent]="complaint" parentType="complaints" class="subprojects" type="subprojects" [maxChipItems]="3" *ngIf="hasModule('subprojects')"
                        (ngModelChange)="updateSubprojects()" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco" name="subprojects" [(ngModel)]="complaint.subprojects" [multiple]="true" [maxChipLength]="20" [uniqueId]="'sub-projects'">
                        <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                        <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                            {{ subproject.name }}
                        </app-generic-multiselect-checkbox-option>
                    </app-generic-multiselect-checkbox>
                </div>
            </div>



        </div>
    </div>
</div>
