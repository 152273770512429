<ng-container *transloco="let t">
  <div class="popup-header row no-margin ">
    <div class="col-xs-12 mb-5 d-flex j-space-between">
      <ng-container *ngIf="!data.email">
      <h2><span *ngIf="!data.status">{{'settings.personal.enable' | transloco}}</span><span *ngIf="data.status">{{'settings.personal.disable' | transloco}}</span> {{'settings.personal.two_factor_authentication' | transloco}}</h2>
      </ng-container>
        <span *ngIf="data?.email" style="color: blue; font-size: 20px;">{{'settings.personal.two_factor_authentication_required' | transloco}}</span>
        <button type="button" class="close a-self-start" (click)="onClose()">
          <span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon>
          </span>
          <span *ngIf="!data.email" class="sr-only">{{ t('close') | capitaliz }}</span>
        </button>
    </div>
  </div>
  <form #form="ngForm">
    <section *ngIf="!verify; else verifycode">
      <div class="popup-body" appScroll>
        <div class="">
          <div class="col-md-12 mb-14 margin-zero">
            <span *ngIf="data?.email">{{'settings.personal.two_factor_project' | transloco}}</span><br>
            <span>{{'settings.personal.two_factor_confirm' | transloco}}</span>
          </div>
          <div class="col-md-12 margin-zero">
            <mat-form-field class="full-width">
              <input [type]="passwordToggle ? 'text' : 'password'" matInput class="" [(ngModel)]="password" name="password" [placeholder]="('password') | transloco | capitaliz" #validation="ngModel" (ngModelChange)="onPasswordChange()" required/>
              <mat-icon class="d-cursor-hand" matSuffix (click)="passwordToggle=!passwordToggle">remove_red_eye</mat-icon>
            </mat-form-field>
            <div *ngIf="validation.touched">
              <mat-hint *ngIf="!invalidHint && !validation.errors?.required">{{'settings.personal.incorrect_password' | transloco}}</mat-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <button type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">
          <span *ngIf="!data?.email">{{ t('close') | capitaliz }}</span>
          <span *ngIf="data?.email">{{ t('logout') | capitaliz }}</span>
        </button>
        <button type="submit" mat-flat-button class="ml-10" [disabled]="form.invalid" (click)="checkValidPassword(data.status)">
          <span *ngIf="!data?.email">{{ t('save') | capitaliz }}</span>
          <span *ngIf="data?.email">{{ t('confirm') | capitaliz }}</span>
        </button>
      </div>
    </section>
    <ng-template #verifycode>
      <div class="popup-body" appScroll>
        <div class="">
          <div class="col-md-12 margin-zero">
            <ol class="p-null">
              <li [innerHTML]="translatedText"></li>
              <li style="margin-bottom: 0">{{'settings.personal.qr_code' | transloco}}</li>
              <div style="margin-bottom: 12px" *ngIf="QRCode"  class="text-center" [innerHtml]=" QRCode | safeHtml"></div>
              <mat-spinner *ngIf="!QRCode" class="m-auto"></mat-spinner>
              <li>{{'settings.personal.enter_code' | transloco}}</li>
              <ng-container>
              <li>{{'settings.personal.recovery_code' | transloco}}</li>
              <ng-container *ngFor="let tag of recoveryCodes; let i=index">
                <div class="recovery-code">{{tag}}</div>
              </ng-container>
              </ng-container>
                <!-- <spn>{{recoveryCodes}}</spn> -->
            </ol>
          </div>
          <div class="col-md-12 margin-zero">
            <mat-form-field class="full-width">
              <input type="text" matInput class="" [(ngModel)]="code" name="code" [placeholder]="('settings.sso.one_time_password') | transloco" required/>
            </mat-form-field>
            <div>
              <mat-hint class="error" *ngIf="showError">{{'settings.personal.oneTime_code' | transloco}}</mat-hint>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <button type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">
          <span *ngIf="!data?.email">{{ t('close') | capitaliz }}</span>
          <span *ngIf="data?.email">{{ t('logout') | capitaliz }}</span>
        </button>
        <button type="submit" mat-flat-button class="ml-10" [disabled]="form.invalid" (click)="onSaveCode()">
          <span *ngIf="!data?.email">{{ t('save') | capitaliz }}</span>
          <span *ngIf="data?.email">{{ t('settings.personal.enable') | capitaliz }}</span>
        </button>
      </div>
    </ng-template>
  </form>
</ng-container>
