import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ResidentState, ResidentsStore } from './residents.store';
import { Resident } from './resident.model';

@Injectable({ providedIn: 'root' })
export class ResidentsQuery extends QueryEntity<ResidentState, Resident> {
  constructor(protected store: ResidentsStore) {
    super(store);
  }

}
