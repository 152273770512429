import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { setDefaultCompanyLanguage } from 'src/app/akita/multilingual/multilingual.model';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { ConfigsService } from 'src/app/core/service/api/configs.service';

@Component({
  selector: 'app-projects-overview-modal',
  templateUrl: './projects-overview-modal.component.html',
  styleUrls: ['./projects-overview-modal.component.sass']
})
export class ProjectsOverviewModalComponent implements OnInit {
  company: any;
  constructor(public globals: Globals,@Inject(MAT_DIALOG_DATA) public projects: any,
    private dialogRef: MatDialogRef<ProjectsOverviewModalComponent>,
    // eslint-disable-next-line no-empty-function
    private projectsService: ProjectsService,public auth: AuthService,private readonly translocoService: TranslocoService, public configService: ConfigsService) { }
  ngOnInit() {
    const email = localStorage.getItem('email')
    if(this.projects != 'no-project'){
      this.projectsService.getCompanyData().subscribe((response) => {
        if (response[0]) {
          this.company = response[0];
          this.globals.customer_personalization=response[0].personalization;
          this.translocoService.setActiveLang(setDefaultCompanyLanguage(this.globals));
        }
      });

      this.configService.getUser(email).subscribe((response: any) => {
        if (response && response.personalization && response.personalization.metadata) {
          document.documentElement.lang = response.personalization.metadata.lang.code
        }
      })
    }
  }
  public close(): void {
    this.dialogRef.close();
  }
}
