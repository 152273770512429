import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse, HttpParams} from "@angular/common/http";
import {ProjectConfigsService} from '../../../../../../akita/project-configs/state/project-configs.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';

@Component({
  selector: 'app-project-config-callback',
  templateUrl: './project-config-callback.component.html',
  styleUrls: ['./project-config-callback.component.sass']
})
export class ProjectConfigCallbackComponent implements OnInit {

  // eslint-disable-next-line no-empty-function
  constructor(private route: ActivatedRoute, private _configsService: ProjectConfigsService, private _integrationServices:IntegrationsService) { }

  ngOnInit() {
    const driver = this.route.snapshot.paramMap.get('driver');
    let httpParams = new HttpParams();

    const queryStrings = this.route.snapshot.queryParamMap;
    queryStrings.keys.forEach((key: string) => {
      httpParams = httpParams.append(key, queryStrings.get(key));
    });

    this._configsService.processOauthCallback(driver, httpParams).subscribe(
      () => {
        // if(driver=='sharepoint'){
        //   this._integrationServices.afterPopupsSuccess();
        // }
        window.opener.closeModal();
      }, (e: HttpErrorResponse) => {
        alert('Error in authenticating the connection. See console .log');
        console.error(e);
        if(driver=='sharepoint'){
          this._integrationServices.afterPopupsError();
        }
      }
    );
  }

}
