import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatrixService } from 'src/app/core/service/api/matrix.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent implements OnInit {
  issuesList: any;
  // eslint-disable-next-line no-empty-function
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _matrixService: MatrixService,) { }


  ngOnInit(): void {
    this._matrixService.getIssueInterest(this.data.stakeholderId, '', this.data.interestId).subscribe(
      (issuesList: any) => {
        this.issuesList = issuesList
      },
      error => {
        console.error('There was an error!', error);
      })
  }


  submit(d) {
    if(d) {
      this.dialogRef.close(this.issuesList);
    } else {
      this.dialogRef.close();

    }
  }
}
