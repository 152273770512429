import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyModalComponent extends GenericComponent implements OnInit {
  checkPrivacy: boolean = false
  isChecked: boolean = false;
  currentDate: string = '';
  user: any;
  constructor(public globals: Globals, public dialogRef: MatDialogRef<PrivacyPolicyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public userService: ProjectConfigsService) {
    super(globals);
    this.user = data;
  }

  ngOnInit(): void {
    console.log('privacy')
  }

  close() {
    this.dialogRef.close();
  }

  toggleChanged() {
    this.currentDate = new Date().toISOString();
    this.user.privacy_policy_timestamp = this.currentDate // You can format the date according to your preference
    this.updateUser()

  }

  updateUser() {
    this.userService.attachUserPersonalisation(this.user, this.user.id).subscribe(() => {
      this.dialogRef.close();
    }, error => {
      console.error('Failed to update privacy policy timestamp', error);
    });
  }


}
