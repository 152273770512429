<form #form_newStakeholderAction="ngForm">
    <div mat-dialog-title>
        <h4 class="mb-10 inline-block">{{ 'new' | transloco | capitaliz }} {{ 'actions.label' | transloco }}</h4>
        <button type="button" class="close" (click)="close()">
    <span aria-hidden="true">
        <i class="d-text-middle uil uil-multiply unicon-icon"></i>
    </span>
    <span class="sr-only">
        Close
    </span>
    </button>
    </div>

    <!-- modal body -->
    <mat-dialog-content>
        <div class="col-xs-12 col-sm-8 pl-0">
            <mat-form-field class="full-width">
              <mat-label>{{ 'reusable.action' | transloco }}</mat-label>
                <input matInput autofocus name="new-action-text" [disabled]="relationActionContactPerson" [(ngModel)]="entity.text" required>
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-4 pr-0">
            <mat-form-field class="full-width">
               <mat-label>{{ 'reusable.notification_date' | transloco }}</mat-label>
                <input matInput name="new_action_date" [matDatepicker]="datePicker" [disabled]="relationActionContactPerson" [(ngModel)]="entity.timestamp" (focus)="datePicker.open()">
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="mt-10">
            <app-generic-multiselect-checkbox [selectReferUser]="selectReferUser" [chipDisabler]="chipDisablerUser" [placeholder]="'reusable.select_users' | transloco" name="users" ngDefaultControl [disabled]="relationActionContactPerson" [(ngModel)]="entity.users" name="users" [required]="true" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' users ...'"
            (ngModelChange)="selectionChange()" [uniqueId]="'add-users'">
                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                <app-generic-multiselect-checkbox-option *ngFor="let user of users; trackBy: trackByFn" [value]="user">
                    {{ user.name }}
                </app-generic-multiselect-checkbox-option>
            </app-generic-multiselect-checkbox>
            <mat-hint *ngIf="helperText">{{ 'text.all_user_select' | transloco }}</mat-hint>
        </div>
        <div *ngIf="emailNotifier">
            <div class="col-xs-12 col-sm-8 p-0">
                <span class="">{{'complaints.direct_email' | transloco}}</span>
                <mat-slide-toggle class="pull-right" name="direct_mail" [(ngModel)]="entity.direct_mail">
                </mat-slide-toggle>
            </div>
            <div class="col-xs-12 col-sm-12 p-0">
                <mat-hint class="mat-hint">{{'complaints.direct_email_helper' | transloco}}</mat-hint>
            </div>
        </div>

        <div *ngIf="isContactPersonExist">
          <app-generic-multiselect-checkbox [placeholder]="'reusable.selecteer_contactpersoon' | transloco" name="employees" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('employees.labels' | transloco)+' ...'"
           [(ngModel)]="selectedContactPerson">
            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option *ngFor="let employee of contactPerson" [value]="employee">
              {{ employee.first_name }} {{ employee.last_name }}
            </app-generic-multiselect-checkbox-option>
            <!-- <app-generic-multiselect-checkbox-option-new-action type="contactpersons"></app-generic-multiselect-checkbox-option-new-action> -->
            <app-generic-multiselect-search-project entity="contactpersons.stakeholders">
            </app-generic-multiselect-search-project>
          </app-generic-multiselect-checkbox>
        </div>

        <mat-progress-bar *ngIf="!stakeholderLoad" mode="indeterminate"></mat-progress-bar>
        <div *ngIf="stakeholderLoad">
            <app-generic-multiselect-checkbox [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" ngDefaultControl [disabled]="relationActionContactPerson" [(ngModel)]="entity.stakeholders" name="stakeholders" [required]="false" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'">
                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders; trackBy: trackByFn" [value]="stakeholder">
                    {{ stakeholder.name }}
                </app-generic-multiselect-checkbox-option>
                <app-generic-multiselect-checkbox-option-new-action type="stakeholder"></app-generic-multiselect-checkbox-option-new-action>
            </app-generic-multiselect-checkbox>
        </div>

        <app-all-search-select *ngIf="!relationActionContactPerson" class="full-width" [models]="['issues', 'complaints', 'requirements', 'contactmoments', 'contents']" [(ngModel)]="entity" name="act" ngDefaultControl (ngModelChange)="onChangeRequired()"></app-all-search-select>

    </mat-dialog-content>
    <!--end of modal body -->

    <mat-dialog-actions align="end">
        <div class="dialog-button-row">
            <button type="button" mat-stroked-button data-dismiss="modal" [disabled]="isProcessing" (click)="close()">{{'cancel' | transloco | capitaliz}}</button>
            <button type="submit" mat-flat-button [disabled]="isProcessing || form_newStakeholderAction.invalid" (click)="!relationActionContactPerson ? submit() : connectContactPerson(entity)">
        <span *ngIf="!isProcessing">
        <mat-icon class="material-icons d-text-middle">save</mat-icon>
        {{ (relationActionContactPerson ? 'save':'add') | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">{{ 'text.please_wait' | transloco | capitaliz }}</span>
    </button>
        </div>
    </mat-dialog-actions>
    <!--end of modal footer -->

</form>

