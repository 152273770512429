import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchService } from '../../../../../core/service/api/search.service';
import { GenericMultiselectOption } from '../generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { MultiselectOptionType } from '../../../../../core/enum/multiselect-option-type';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { Issue } from '../../../../../akita/issues/state/issue.model';
import { Stakeholder } from '../../../../../akita/stakeholders/state/stakeholder.model';
import { Resident } from '../../../../../akita/residents/state/resident.model';
import { Middel } from '../../../../../akita/content/state/middel.model';
import { Action } from '../../../../../akita/tasks/state/action.model';
import { Activity } from '../../../../../akita/activities/state/activity.model';
import { Decision } from '../../../../../akita/requirements/state/decision.model';
import { TranslocoService } from '@jsverse/transloco';
import { SubprojectQuery } from '../../../../../akita/subproject/state/subprojects.query';
import { Employee } from '../../../../../akita/contact-persons/state/employee.model';
import { Complaint } from '../../../../../akita/complaints/state/complaint.model';
import { Globals } from 'src/app/globals';
import { StakeholdersService } from 'src/app/core/service/api/stakeholders.service';

@Component({
  selector: 'app-generic-multiselect-search-project',
  templateUrl: './generic-multiselect-search-project.component.html',
  styleUrls: ['./generic-multiselect-search-project.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericMultiselectSearchProjectComponent implements OnInit {

  @Input() entity: string;
  public entityText: string;
  public number: number;
  private selectedOptions: GenericMultiselectOption[] = [];

  // eslint-disable-next-line no-empty-function
  constructor(private searchService: SearchService, private _transloco: TranslocoService, private globals: Globals, private _stakeholdersService: StakeholdersService, private _subProjectQuery: SubprojectQuery, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    if (!this.entity) {
      throw new TypeError('Entity is required');
    }

    if (this.entity.includes('.')) {
      this.entity = this.entity.substr(0, this.entity.indexOf('.'));
    }

    this.entityText = this._transloco.translate(`${this.entity}.labels`);


  }

  public search(keyword: String): Observable<GenericMultiselectOption[]> {

    return this._subProjectQuery.selectActive().pipe(
      filter(subProject => !!subProject),
      mergeMap(() => this.searchService.getSearch(keyword, [this.entity], true)
        .pipe(
          debounceTime(350),
          filter(response => response[this.entity]),
          map(response => response[this.entity].map(result => {
            return {
              content: this.getContent(result.searchable),
              value: result.searchable,
              checked: this.selectedOptions.some(o => o.value.id === result.searchable.id),
              optionType: MultiselectOptionType.project_search,
            }
          }))
        ))
    );


  }

  public getContent(response: any | Issue | Stakeholder | Employee | Resident | Middel | Activity | Action | Complaint | Decision): String {
    switch (this.entity) {
      case 'issues':
        return `${response.code} ${response.name}`;
        break;
      case 'stakeholders':
        return response.name;
        break;
      case 'residents':
        return response.name;
        break;
      case 'middel':
        if (response.activities) {
          return `${response.type} ${this._transloco.translate('middel.for_activity')} ${response.activities.name}`;
        }
        return response.type;
        break;
      case 'activities':
        return response.name;
        break;
      case 'actions':
        return response.text;
        break;
      case 'complaints':
        return response.text;
        break;
      case 'decisions':
        return response.title;
        break;
      case 'contactpersons':
        if (response.first_name) {
          return `${response.first_name} ${response.last_name}`.trim();
        }
        return response.last_name.trim();
        break;
    }

    return '';
  }

  public selectOption(option: GenericMultiselectOption): boolean {
    const exist = this.selectedOptions.some(o => o.value.id === option.value.id);
    if (exist) {
      this.selectedOptions = this.selectedOptions.filter(o => o.value.id !== option.value.id);
      return false;
    }

    this.selectedOptions.push(option);
    // add the item in subproject before attaching it to other dialog item
    this.updateEntitySubproject(option);
    return true;
  }

  public updateEntitySubproject(option: GenericMultiselectOption) {
    if (this.entity == 'stakeholders') {
      const stakeholder: any = option.value;
      const activeSubProject = this._subProjectQuery.getActive();
      const currentProject = this.globals.subprojects.filter(s => s.code == activeSubProject.code)[0];
      // Check if currentProject already exists in stakeholder.subprojects
      const isAlreadyAdded = stakeholder.subprojects.some(subproject => subproject.code === currentProject.code);

      if (!isAlreadyAdded) {
        stakeholder.subprojects.push(currentProject);
        this._stakeholdersService.updateStakeholder(stakeholder.code, stakeholder).subscribe(
          () => {
    
            console.log('stakeholder added in subproject...')
          });
      }
    }
  }

  public userProjectLevelRoles(): any[] {
    // add only Project Admin or Editor
    // this is for to search items from subproject level
    const userProjectLevelRoles = [];
    if (this.globals && this.globals.user) {
      const user = this.globals.user;
      if(user.scope && user.scope.data && user.scope.data.length > 0){
        user.scope.data.forEach(el => {
          if(el.roles.length > 0){
            if(!el.is_subproject){
              el.roles.forEach(ele => {
                if(ele.name==='Project Admin' || ele.name === 'Editor'){
                  userProjectLevelRoles.push(ele.name);
                }
              });
            }
          }
        });
      }
    }
    return userProjectLevelRoles;
  }
}
