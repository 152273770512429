import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from "../common/abstract.service";
import { PaginationParams } from "../common/pagination-params";
import { WithParams } from "../common/with-params";
import { Observable } from "rxjs";
import { PaginatedResponse } from "../common/paginated-response";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class LogsService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  public getLog(logId: number, withParam?: WithParams) {
    return this.http.get(`/api/logs/${logId}?skip_subproject=0`, withParam.get());
  }

  @ServiceErrorHandler()
  public getLogs(paginationParams: PaginationParams, extraParams?: HttpParams) {
    return this.http.get('/api/logs', paginationParams.get(extraParams));
  }

  @ServiceErrorHandler()
  exportLogs(options) {
    return this.http.get(`/file/export/contactmoment?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  public getLogsRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/logs?dropdown=1', this.httpOptions);
  }
  @ServiceErrorHandler()
  public getOutlookLogs(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/logs?outlookDropdown', this.httpOptions);
  }
  public getSeries(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/series');
  }

  @ServiceErrorHandler()
  public removeLog(id: number) {
    return this.http.delete(`/api/logs/${id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  public updateLog(id: number, log) {
    return this.http.put(`/api/logs/${id}`, log, this.httpOptions);
  }

  @ServiceErrorHandler()
  public submitLog(log) {
    return this.http.post('/api/logs', log, this.httpOptions);
  }

  @ServiceErrorHandler()
  public getConnectionsPerSeries(series) {
    return this.http.get(`/api/series/${series}/connections`, this.httpOptions);
  }
}
