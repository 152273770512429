<form (ngSubmit)="submit()" #form="ngForm">
  <div mat-dialog-title>
    <h4 class="row mb-10 inline-block">{{'add' | transloco | capitaliz}} {{'contactpersons.labels' | transloco}}</h4>
    <button type="button" class="close mt-7" (click)="close()">
        <span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span>
      <span class="sr-only">Close</span>
    </button>
  </div>

  <mat-dialog-content>

    <div *ngFor="let email of temporaryData.emails.unregistered; trackBy: trackByFn" class="container-unregistered">
      <p class="employee-title">
        <b>{{'contactpersons.label' | transloco | capitaliz}}</b>
      </p>
      <div>
        <mat-form-field class="full-width">
          <input matInput [name]="'email-first-' + email.address" [ngModel]="email.first_name" (change)="updateFirstName($event, email)" placeholder="{{'reusable.first_name' | transloco}}" />
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput [name]="'email-last-' + email.address" [ngModel]="email.name" (change)="updateLastName($event, email)" required placeholder="{{'reusable.last_name' | transloco}}" />
        </mat-form-field>

        <label class="material-style-label">Email address</label>
        <label class="employee-label">{{ email.address }}</label>
        <app-generic-multiselect-checkbox type="stakeholders" [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" (ngModel)="email.stakeholder"
                                          [multiple]="true"  (ngModelChange)="setEmailStakeholder($event, email)">
          <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of data.stakeholders" [value]="stakeholder">
            {{ stakeholder.name }}
          </app-generic-multiselect-checkbox-option>
          <app-generic-multiselect-checkbox-option-new-action type="stakeholder"></app-generic-multiselect-checkbox-option-new-action>
        </app-generic-multiselect-checkbox>
      </div>
    </div>

  </mat-dialog-content><!-- modal- -->

  <mat-dialog-actions align="end">
    <div class="dialog-button-row">
      <button type="submit"  mat-flat-button class="pull-right" [disabled]="processing || form.invalid">
        {{'reusable.save_doc' | transloco}}
      </button>
      <button type="button" mat-stroked-button class="pull-right" (click)="close()">{{'close' | transloco | capitaliz}}</button>
    </div>
  </mat-dialog-actions>
</form>
