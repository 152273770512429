import { MapService } from 'src/app/core/service/api/map.service';
import { Injectable, Injector } from '@angular/core';
import { Globals } from "../../../globals";
import { AuthService } from "../auth/auth.service";
import { Event, NavigationStart, Router } from '@angular/router';
import { Constants } from '../../../core/enum/constants';
import { ProjectsService } from '../../../akita/project/state/projects.service';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { showFeedbackError } from 'src/app/core/util/notification';
import { AuthConstants } from "../../../auth-constants";
import { IntegrationsService } from '../integrations.service';
import { catchError, tap } from 'rxjs/operators';
import { SearchService } from '../api/search.service';
import { getProjectCords } from '../../util/mapModal';
import { combineLatest, throwError } from 'rxjs';
import { GeneralService } from '../api/general.service';
@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  private projectCode: string;
  public isAdmin: boolean = false;
  public arcGis = 'arcgis';
  public bouwapp = 'bouwapp';
  public relatics = 'relatics';
  public freshdesk = 'freshdesk';
  public whatsapp = 'whatsapp';
  public activeArcGis: string = 'arcgis'
  public arcGisLayers: any[] = [];
  public arcGisToken: string;
  // RouteInterceptorService is injected so that it records the route from beginning
  constructor(private _integrationsServices: IntegrationsService, public constant: AuthConstants, private globals: Globals, private _auth: AuthService,
    private injector: Injector, private _projectsService: ProjectsService, private projectConfigs: ProjectConfigsService, private searchService: SearchService,
    private mapService: MapService, private _generalService: GeneralService,
    // eslint-disable-next-line no-empty-function
    private _authService: AuthService) {}

  public load(): Promise<any> {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if(localStorage.getItem('token')){
          this._authService.tokenExpiryValidation(localStorage.getItem('token'))
        }
        if (localStorage.getItem(Constants.LOCAL_STORAGE_PROJECT_CODE_KEY)) {
          this.globals.projectCode = localStorage.getItem(Constants.LOCAL_STORAGE_PROJECT_CODE_KEY);
          localStorage.removeItem(Constants.LOCAL_STORAGE_PROJECT_CODE_KEY);
        } else {
          const routes: string[] = event.url.split('/')
            .filter(url => !decodeURIComponent(url).includes('?')); // Weed out url with query string

          if (routes.length >= 2 && routes[1]) {
            if (routes[1].startsWith('authenticated')) {
              return;
            }
            this.globals["adminPage"] = false;
            // match using upperCase to verify if the route is a project code or not and if not then redirect to home page
            this.globals.projectCode = routes[1] === routes[1].toUpperCase()? routes[1] : localStorage.getItem('project_name') ? localStorage.getItem('project_name') : '';
            if (routes[1] == 'beheer') {
              this.globals["adminPage"] = true;
              this._projectsService.getCompanyData().subscribe((response) => {
                if (response[0]) {
                  this.globals["company"] = response[0].name;
                }

              }
              );
            }
            if (this.projectCode !== this.globals.projectCode && routes[1] != 'beheer' && this._authService.isLoggedIn() && this.globals.projectCode !== 'login') {
              if (!this.globals.clientId) {
                this.projectConfigs.getClientId(this.arcGis).subscribe((response: any) => {
                  this.globals.clientId = response.client_id;
                })
              }
              if (!this.globals.clientId) {
                this.projectConfigs.getClientId(this.bouwapp).subscribe((response: any) => {
                  this.globals.clientIdBouwapp = response.client_id;
                  this.globals.clientsecretBouwapp = response.client_secret;
                  this.globals.bouwapp_url = response.url;
                })
              }
              this.projectConfigs.getClientId(this.whatsapp).subscribe((response:any)=>{
                this.globals.whatsapppConfigId=response.config_id;
                this.globals.whatsapppAppId=response.client_id;

              })
              this._projectsService.getProjectData(this.globals.projectCode, this.globals).subscribe({next: ()=> {
                this.isAdmin = this.globals.user ? this.globals.user.isAdmin ? this.globals.user.isAdmin : false : false;
                // outlook
                this._projectsService.setActivityLogs("user_login_succeeded").subscribe()

                if(this.globals.user.configs.microsoft_enabled) {
                  this.projectConfigs.getFreshUserToken(this.globals.user.id, 'microsoft').subscribe((res: any) => {
                    if(res && res.token) {
                      const token = res.token;
                      if (token) {
                        const jwtToken = JSON.parse(atob(token.split('.')[1]));
                        const expiresAt = new Date(jwtToken.exp * 1000);
                        if (Date.now() > expiresAt.getTime()) {
                          localStorage.removeItem('outlook_accessToken');
                          this.globals.user.configs.microsoft_enabled=0;
                          this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
                        } else {
                          localStorage.setItem('outlook_accessToken', res.token);
                          this.globals.user.configs.config.connection_error.microsoft = false;
                          this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
                        }
                      }
                    } else {
                      this.globals.user.configs.microsoft_enabled=0;
                      this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
                    }
                  })
                }

                //sharepoint error checker
                this.projectConfigs.getConfigsErrorMessage('sharepoint').subscribe();
                if(this.globals.projectConfigs){
                  //bouwapp error checker
                  if(this.globals.projectConfigs['services.integration.bouwapp.enabled']){
                    if (this.globals.projectConfigs['services.integration.bouwapp.enabled'] && !this.globals.projectConfigs['services.integration.bouwapp.connection_error']) {
                      this.projectConfigs.getConfigsErrorMessage('bouwapp').subscribe((res: any) => {
                        if (res.enabled && res.authorized) {
                          this.projectConfigs.integrationSync(this.bouwapp).subscribe();
                        }
                      });
                    }
                  }
                 //relatics sync
                  if (this.globals.projectConfigs['services.relatics.enabled']) {
                    this.projectConfigs.integrationSync(this.relatics).subscribe();
                  }
                  //freshdesk error checker
                  if(this.globals.projectConfigs['services.integration.freshdesk.enabled']){
                    if (this.globals.projectConfigs['services.integration.freshdesk.enabled'] && !this.globals.projectConfigs['services.integration.freshdesk.connection_error']) {
                      this.projectConfigs.getConfigsErrorMessage('freshdesk').subscribe((res: any) => {
                        if (res.enabled && res.authorized) {
                          this._integrationsServices.getUserProfiles()
                            .pipe(
                              tap((res: any) => {
                                this.globals.freshdesk_users = res;
                              })
                            ).subscribe();
                          this.projectConfigs.integrationSync(this.freshdesk).subscribe();
                        }
                      });
                    }
                  }
                  //arcgis_enterprise error checker
                  if (this.globals.projectConfigs["services.integration.arcgis_enterprise.enabled"]) {
                    this.activeArcGis = 'arcgis_enterprise';
                  }
                  //aecgis error checker
                  if(this.globals.projectConfigs["services.integration.arcgis.enabled"]) {
                    if ((this.globals.projectConfigs["services.integration.arcgis.enabled"] || this.globals.projectConfigs["services.integration.arcgis_enterprise.enabled"]) && (this.globals.projectConfigs['services.integration.arcgis.connection_error'] == false || this.globals.projectConfigs['services.integration.arcgis_enterprise.connection_error'] == false)) {
                      if ((this.globals.projectConfigs["services.integration.arcgis.user"] == this.globals.user.global_id) || this.globals.projectConfigs["services.integration.arcgis_enterprise.user"] == this.globals.user.global_id) {
                        combineLatest([
                          this.projectConfigs.getEnableFeatureLayers(this.activeArcGis),
                          this.projectConfigs.getFreshToken(this.activeArcGis)
                        ])
                        .pipe(
                          tap(async ([layers, arcgis]) => {
                            this.setArcGisSubLayers(layers, arcgis);
                          }),
                          catchError((err) => {
                            this.globals.projectConfigs['services.integration.arcgis.enabled'] = false;
                            this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled'] = false;
                            showFeedbackError(err);
                            return throwError(err);
                          }),
                        ).subscribe();
                        this.projectConfigs.integrationSync(this.activeArcGis).subscribe();
                      } else {
                        if ((this.globals.user.configs.arcgis_enabled == 1 || this.globals.user.configs.arcgis_enterprise_enabled == 1) && (!this.globals.user.configs.config || (this.globals.user.configs.config.connection_error.arcgis == false || this.globals.user.configs.config.connection_error.arcgis_enterprise == false) || !this.globals.user.configs)) {
                          combineLatest([
                            this.projectConfigs.getEnableFeatureUserLayers(this.globals.user.id, this.activeArcGis),
                            this.projectConfigs.getFreshUserToken(this.globals.user.id, this.activeArcGis)
                          ])
                          .pipe(
                            tap(async ([layers, arcgis]) => {
                              this.setArcGisSubLayers(layers, arcgis);
                            }),
                            catchError((err) => {
                              this.globals.user.configs.arcgis_enabled = 0;
                              this.globals.user.configs.arcgis_enterprise_enabled = 0;
                              this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
                              showFeedbackError(err);
                              return throwError(err);
                            }),
                          ).subscribe();
                          this.projectConfigs.integrationSync(this.activeArcGis).subscribe();
                        }
                      }
                    }
                  }
                }
                this.projectConfigs.getRelatic().subscribe((res: any) => {
                  if (Object.keys(res).length !== 0) {
                    this.globals.relaticsColumn = res.data.syncitems;
                  } else {
                    this.globals.relaticsColumn = {};
                  }
                });
                if(this.globals.projectConfig.polygons){
                  this.searchService.transFormCoder(getProjectCords(this.globals.projectConfig.polygons))
                  .pipe(
                    tap((responce:any)=>{
                      this.searchService.getGeoCoords(responce.results)
                      .pipe(
                        tap((res:any)=>{
                          if(res.address){
                            if(res.address.country_code){
                              this.globals.countryCode = res.address.country_code;
                            }
                          }
                        })
                      ).subscribe();
                    })
                  ).subscribe();
                }
              },
              error: () => this._projectsService.setActivityLogs("user_login_failed").subscribe()});
            }

          }
        }
        this.projectCode = this.globals.projectCode;
      }
    });

    const auth = this._auth.localAuthSetup();
    return auth.toPromise();
  }

  public get router(): Router { //this creates router property on your service.
    return this.injector.get(Router);
  }
  /*
    ArcGis Layers Into Sub Layers
  */
  public async setArcGisSubLayers(layers: any, arcgis: any): Promise<void> {
    this.arcGisLayers = [];
    layers.forEach(layer => {
      this.mapService.getArcGisSubLayers(layer.url, arcgis.token)
      .pipe(
        tap(async (res: any) => {
          res.forEach(item => {
            let foundObject = null;
            if(layer.metadata){
              if(layer.metadata.length) {
                foundObject = layer.metadata.find(data => data.layerId === item.id);
              }
            }
            this.arcGisLayers.push({
              name: layer.name,
              main_layer_id: layer.id,
              layer_name: item.name,
              layer_id: item.id,
              type: item.type,
              geometry_type: item.geometryType,
              url: layer.url,
              owner: layer.owner,
              enabled: layer.enabled,
              metadata: foundObject ? foundObject.metadata : null,
              alldata: layer.metadata ? layer.metadata : null,
            });
          });
          this.arcGisToken = arcgis.token;
          this.globals.enableLayers = this.arcGisLayers;
          this.globals.arcgisToken = this.arcGisToken;
        })
      ).subscribe();
    });
  }
}
