import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-action-handler-modal',
  templateUrl: './action-handler-modal.component.html',
  styleUrls: ['./action-handler-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionHandlerModalComponent {

  // eslint-disable-next-line no-empty-function
  constructor() { }
}
