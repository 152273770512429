<form [formGroup]="form" *transloco="let t">
  <mat-form-field class="full-width">
    <mat-label>{{ placeholder  }}</mat-label>
    <input
      #mySelect
      matInput
      [matAutocomplete]="auto"
      [matChipInputFor]="list"
      formControlName="text"
      disabled="{{ disabled }}"
      [matTooltip]="disabled && type=='subprojects' ? ('error.no_projects' | transloco) : ''"
      [class]="uniqueId"
    />
    <mat-chip-grid
      [ngClass]="{ disable_enitiy: disabled }"
      [multiple]="false"
      #list
      [class]="showChips ? '' : 'hidden'"
      [disabled]="disabled"
    >
      <mat-chip-row
        class="name-overflow"
        *ngFor="let option of selectedOptions"
        [ngClass]="type == 'stakeholders' ? 'chip-custom d-cursor-hand' : ''"
        [removable]="
          !isDisabled && option.optionType !== MultiselectOptionType.moremarker
        "
        (removed)="removeOption(option)"
        [matTooltip]="option.content"
        matTooltipClass="tooltip-list"
      >
        <ngx-avatars
          class="ml--6"
          *ngIf="type == 'subprojects' && option.value"
          [style]="AvatarCustomStyle"
          [name]="option.content"
          initialsSize="2"
          size="25"
          [borderColor]="option.value.color"
          [fgColor]="option.value.color"
          bgColor="#FFFFFF"
        >
        </ngx-avatars>
        <span class="name-chip-overflow" (click)="clickOption(option.value)">
          {{
            maxChipLength
              ? (option.content | truncatie : maxChipLength)
              : option.content
          }}
        </span>
        <span *ngIf="chipDisabler.includes(option.content)">
          <!-- <mat-icon *ngIf="!isDisabled && option.optionType !== MultiselectOptionType.moremarker" matChipRemove>cancel</mat-icon> -->
        </span>
        <span *ngIf="!chipDisabler.includes(option.content) && !hideCancleIcon">
          <mat-icon
            *ngIf="
              !isDisabled &&
              option.optionType !== MultiselectOptionType.moremarker
            "
            matChipRemove
            >cancel</mat-icon
          >
        </span>
      </mat-chip-row>

    </mat-chip-grid>
    <ng-template #template>
      <div>
        <div *ngFor="let option of remainingOptions">
          {{ option.content }}
        </div>
      </div>
    </ng-template>
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    (click)="$event.preventDefault(); $event.stopPropagation()"
    [class]="!multiple ? 'generic-multiselect-checkbox--single' : ''"
    (closed)="resetInput()"
  >
    <mat-option
      [applyTaggingAttributes]="multiSelectOverlay"
      *ngIf="multiple && showSelectAll"
      [value]="selectAllOption"
      (click)="toggleOption(selectAllOption, $event)"
      [class]="uniqueId + '-list-item-0'"
    >
      <mat-checkbox
        [checked]="selectAllOption.checked"
        (click)="$event.preventDefault()"
        >{{ selectAllOption.content }}</mat-checkbox
      >
      <mat-divider *ngIf="!selectReferUser"></mat-divider>
    </mat-option>
    <mat-option
      [applyTaggingAttributes]="multiSelectOverlay"
      *ngFor="let option of filteredOptions; let index = index"
      [value]="option"
      [class]="uniqueId + '-list-item-' + (index + 1)"
    >
      <div *ngIf="option.content == 'Selecteer stakeholder(s) accounthouder'">
        <div
          (click)="toggleOption(option, $event)"
          [ngClass]="{
            disable_enitiy:
              chipDisabler.includes(option.content) ||
              option.value.is_permission
          }"
        >
          <mat-checkbox [checked]="option.checked">{{
            option.content
          }}</mat-checkbox>
        </div>
      </div>
      <!--Todo: tagging of option is causing collisio-->
      <div *ngIf="option.content !== 'Selecteer stakeholder(s) accounthouder'">
        <div
          (click)="toggleOption(option, $event)"
          [ngClass]="{
            disable_enitiy:
              chipDisabler.includes(option.content) ||
              option.value.is_permission
          }"
        >
          <mat-checkbox [checked]="option.checked">{{
            option.content
          }}</mat-checkbox>
        </div>
      </div>
      <mat-divider
        *ngIf="option.content == 'Selecteer stakeholder(s) accounthouder'"
      ></mat-divider>
    </mat-option>
    <mat-option
      [applyTaggingAttributes]="multiSelectOverlay"
      *ngIf="showAddAction"
      [value]="addActionOption"
      [innerHTML]="addActionOption.content"
      (click)="toggleOption(addActionOption)"
    ></mat-option>
    <ng-container
      *ngIf="showSearchProject && searchResponse$ | async as searchResponse"
    >
      <mat-divider></mat-divider>
      <mat-option [applyTaggingAttributes]="multiSelectOverlay"
        >{{ searchResponse.length }} {{ searchType }}
        {{ t("text.found_project_level") }}</mat-option
      >
      <mat-option
        [applyTaggingAttributes]="multiSelectOverlay"
        *ngFor="let result of searchResponse"
        [value]="result"
        (click)="selectSearchedOption(result)"
      >
        <mat-checkbox
          [checked]="result.checked"
          (click)="$event.preventDefault()"
          >{{ result.content }}</mat-checkbox
        >
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</form>
