import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';
import { VarVerantwoordelijken } from "../../../features/main-app/stakeholders/page/stakeholders-detail/tab-stakeholder-detail-general/general-verantwoordelijken/variable-verantwoordelijken";
import { AbstractService } from "../common/abstract.service";

@Injectable({
  providedIn: 'root'
})
export class StakeholdersVerantwoordelijkeService extends AbstractService {

  constructor(
    private http:HttpClient
  ) {
    super();
  }

  // GET ALL USER
  @ServiceErrorHandler()
  getUser(): Observable<VarVerantwoordelijken[]> {
    return this.http.get<VarVerantwoordelijken[]>(`/api/users`, this.httpOptions);
  }
  // GET
  @ServiceErrorHandler()
  getHolder (stakeholderCode: string): Observable<VarVerantwoordelijken[]> {
    return this.http.get<VarVerantwoordelijken[]>(`/api/stakeholders/${stakeholderCode}/users/holder`, this.httpOptions)
  }
  // POST
  @ServiceErrorHandler()
  postHolderVerantwoordelijken (stakeholderCode: string, form: any): Observable<VarVerantwoordelijken[]> {
    return this.http.post<VarVerantwoordelijken[]>(`/api/stakeholders/${stakeholderCode}/users/holder`, form, this.httpOptions)
  }
  // GET USER
  @ServiceErrorHandler()
  getUsers (stakeholderCode: string): Observable<VarVerantwoordelijken[]> {
    return this.http.get<VarVerantwoordelijken[]>(`/api/stakeholders/${stakeholderCode}/users`, this.httpOptions)
  }
  // POST
  @ServiceErrorHandler()
  postUsers (stakeholderCode: string, users: any): Observable<VarVerantwoordelijken[]> {
    return this.http.post<VarVerantwoordelijken[]>(`/api/stakeholders/${stakeholderCode}/users`, {
      users: users
    }, this.httpOptions);
  }
}
