import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Language, createPersonalisationMetadata, defaultmultilingualCompanyProvider, multilingualProvider } from 'src/app/akita/multilingual/multilingual.model';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { AuthConstants } from 'src/app/auth-constants';
import { Project, createProject } from '../../../../akita/project/state/project.model';
import { ProjectsService } from '../../../../akita/project/state/projects.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { scrollToError } from '../../../../core/util/form';
import { showFeedbackError, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { OpenlayersMapComponent } from '../../openlayers-map/openlayers-map.component';
declare let $: any;
@Component({
  selector: 'app-project-new',
  templateUrl: './project-new.component.html',
  styleUrls: ['./project-new.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectNewComponent implements OnInit {

  public modules: any;
  @ViewChild('form') form: NgForm;
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild(OpenlayersMapComponent) openlayerMap: OpenlayersMapComponent;
  public language: Language[];
  public multilingual : Language[];
  public project: Project = createProject("");
  company: any;
  public processing: boolean = false;
  private prevSelectedModules = [];
  constructor(public globals: Globals, public dialogRef: MatDialogRef<any>, private _cachedService: CachedService,private _configsService: ProjectConfigsService,
              // eslint-disable-next-line no-empty-function
              private projectsService: ProjectsService,public constant: AuthConstants,private route: Router, private cd: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.loadMultilingual();
    this.projectsService.getModules().subscribe((response) => {
      this.modules = response;
    });
    this.projectsService.getCompanyData().subscribe((response) => {
      if (response[0]) {
        this.company = response[0]
      }
    });
  }

  public onModuleChange(modules) {

    /* 
      if permit is selected auto select workflow
    */
    if (modules && modules.length) {
      const workflowSelected = modules.some(m => m.module_id === 'workflow');
      const prevPermitSelected = this.prevSelectedModules.some(m => m.module_id === 'permit');
      const isPermitSelected = modules.some(m => m.module_id === 'permit');

      if (!workflowSelected && !prevPermitSelected && isPermitSelected) {
        const workflow = this.modules.filter(m => m.module_id === 'workflow')[0];
        if (workflow) {
          this.project.modules = [...this.project.modules, workflow];
          this.cd.detectChanges();
        }
      }
      this.prevSelectedModules = [...this.project.modules]
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
// Submit form data to server
  public submit(): void {
    if (this.form.invalid) {
      scrollToError();
      return;
    }
    const newProject = {...this.project}
    newProject.polygons =JSON.stringify({"polygons":this.project.polygons}) ;
    this.processing = true;
    newProject.modules = newProject.modules.map(m => m.module_id);
    newProject.default_map = this.openlayerMap.map.getView().calculateExtent(this.openlayerMap.map.getSize()).join()
    let apiUrl = window.location.origin;
    if (apiUrl.includes("localhost"))
      apiUrl = "http://localhost:8282"
    const self = this;
    const settings = {
      "url": `${apiUrl}/api/admin/projects/${this.company.name}`,
      "method": "POST",
      "headers": {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": newProject,
      success: function (response) {
        showFeedbackSaved();
        self.createPersonalisation(response.code);
        self.dialogRef.close(response); },
      error: function (jqXHR, exception) {
       console.error(jqXHR.responseText);
       showFeedbackError(exception);
    },
    };
    $.ajax(settings)
  }
  //load languages
  public loadMultilingual(): void{
    this.language=defaultmultilingualCompanyProvider(this.globals);
    this.multilingual=multilingualProvider();
  }
  //create personalisation
  public createPersonalisation(projectCode): void{
    this._configsService.getPersonalisation(createPersonalisationMetadata('lang',this.language[0],false))
    .pipe(
      tap(res=>{
        this._configsService.attachProjectPersonalisation({'personalization_id':res.id},projectCode).subscribe();
      })
    ).subscribe();
  }
}
