import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlider } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { PopperContent } from 'ngx-popper';
import { untilDestroyed } from 'ngx-take-until-destroy';
import posthog from 'posthog-js';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { dateIso } from 'src/app/core/util/dateIso';
import { deleteConfirmationResponce, impactScoring, issueSliderScoreLogic, issuesScoreLogic } from 'src/app/core/util/deepCopy';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { deletedUsersForClosedTickets, globalSubprojectUserSaprator, selectedSubprojectUsersSaprator, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { OpenlayersMapComponent } from 'src/app/shared/components/openlayers-map/openlayers-map.component';
import { Interest } from '../../../../../../akita/interests/state/interest.model';
import { Issue, calculateUrgency, createIssue } from '../../../../../../akita/issues/state/issue.model';
import { IssuesQuery } from '../../../../../../akita/issues/state/issues.query';
import { IssuesService } from '../../../../../../akita/issues/state/issues.service';
import { ProjectConfigsQuery } from '../../../../../../akita/project-configs/state/project-configs.query';
import { Decision } from '../../../../../../akita/requirements/state/decision.model';
import { DecisionsQuery } from '../../../../../../akita/requirements/state/decisions.query';
import { DecisionsService } from '../../../../../../akita/requirements/state/decisions.service';
import { Stakeholder } from '../../../../../../akita/stakeholders/state/stakeholder.model';
import { StakeholdersQuery } from '../../../../../../akita/stakeholders/state/stakeholders.query';
import { StakeholdersService } from '../../../../../../akita/stakeholders/state/stakeholders.service';
import { Permission } from '../../../../../../core/enum/permission';
import { ThemeType } from '../../../../../../core/enum/theme-type';
import { DocumentsService } from '../../../../../../core/service/api/documents.service';
import { InterestsService } from '../../../../../../core/service/api/interests.service';
import { IssuesService as IssuesApiService } from '../../../../../../core/service/api/issues.service';
import { MatrixService } from '../../../../../../core/service/api/matrix.service';
import { PubsubService } from '../../../../../../core/service/api/pubsub.service';
import { StakeholdersService as StakeholdersApiService } from '../../../../../../core/service/api/stakeholders.service';
import { UsersService } from '../../../../../../core/service/api/users.service';
import { PaginatedResponse } from '../../../../../../core/service/common/paginated-response';
import { PaginationParams } from '../../../../../../core/service/common/pagination-params';
import { ChildTabbedPage } from '../../../../../../core/util/abstract/child-tabbed-page';
import { ModalEnum, modalConfig } from '../../../../../../core/util/modalConfig';
import {
  showFeedback,
  showFeedbackError,
  showFeedbackRemoved,
  showFeedbackSaved
} from '../../../../../../core/util/notification';
import { Allowed } from '../../../../../../decorator/allowed-decorator';
import { Globals } from '../../../../../../globals';
import { DeletionModalComponent } from '../../../../../../shared/components/modal/deletion-modal/deletion-modal.component';
import { DecisionEditComponent } from '../../../../../../shared/components/modal/requirement-edit/decision-edit.component';
import { MapService } from 'src/app/core/service/api/map.service';
import { NewWorkflowComponent } from 'src/app/shared/components/modal/new-workflow/new-workflow.component';

@Component({
  selector: 'app-tab-issue-detail-general',
  templateUrl: './tab-issue-detail-general.component.html',
  styleUrls: ['./tab-issue-detail-general.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabIssueDetailGeneralComponent extends ChildTabbedPage {

  @ViewChild('issueDatePicker') issueDatePicker;
  @ViewChild(OpenlayersMapComponent) openlayerMap: OpenlayersMapComponent;
  @ViewChild('form') public form: NgForm;
  @ViewChild('popperInterest') private popperInterest: PopperContent;
  @ViewChild('popperTension') private popperTension: PopperContent;
  // @ViewChild(MatSlider) slider: MatSlider;
  @ViewChild('slider') slider: ElementRef;

  isSliderDisabled = true;
  public reducer:any=true;
  private issueSubject = new BehaviorSubject(createIssue('', new Date()));
  public issue$: Observable<Issue> = this.issueSubject.asObservable();
  public stakeholders$: Observable<Stakeholder[]>;
  public decisions$: Observable<Decision[]>;
  private chart = new BehaviorSubject({ x: 0, y: 4 });
  public urgencyChart$: Observable<{
    x: number, y: number
  }> = this.chart.asObservable();
  public issue: Issue;
  public matrices: any[];
  private editedMatrix: {
    stakeholder: Stakeholder,
    issue: Issue,
    currentValue: number,
    interests: any
  };
  integrateWithRelatics = false;

  pageSubTitle: string;
  pageTitle: string;
  tabName = '';

  users = [];
  issueCode: string;
  name: string;
  themes = [];
  interests: any[];
  themeType = ThemeType.Issues;
  selectedTheme: any[]=[];
  entities: any
  loaded: Boolean = false;
  showInterestForm: Boolean = false;
  showStatusField: Boolean = false;
  subprojectsSet:any[]=[];
  chipDisabler:any[]=[];
  chipDisablerUser:any[]=[];
  userSelection:any[]=[];
  sliderValue: number = 0;
  calculatedDates = [];
  colorSpanPercentage: number[]=[];
  max: any;
  public newStrategie = false;
  sliderEndDate: any;
  interest_labels: { label: string, color: string }[] = [
    { label: 'unknown', color: '#ffffff' },
    { label: 'none', color: '#e0e0e0' },
    { label: 'low', color: '#98dbea' },
    { label: '', color: '#58b5db' },
    { label: '', color: '#178ecc' },
    { label: 'high', color: '#004f90' }
  ];
  tension_labels: { label: string, label_old: string,color: string }[] = [
    { label: 'unknown', label_old: 'unknown', color: '#ffffff' },
    { label: 'none', label_old: 'similar', color: '#92e8A9' },
    { label: 'low', label_old: '', color: '#ffdad3' },
    { label: '', label_old: 'neutral', color: '#ffb8aa' },
    { label: '', label_old: '', color: '#fe8f80' },
    { label: 'high', label_old: 'opposite', color: '#ff4331' },
  ];


  /** Map variables **/
  public showMapReset = false;

  constructor(private _issuesService: IssuesService, private _issuesApiService: IssuesApiService, private issueQuery: IssuesQuery, private stakeholderQuery: StakeholdersQuery, private decisionQuery: DecisionsQuery,
    private _stakeholdersApiService: StakeholdersApiService,
    private _usersService: UsersService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Issue,
    private dialogRef: MatDialogRef<TabIssueDetailGeneralComponent>,
    private renderer: Renderer2,
    private el: ElementRef,
    private _stakeholderService: StakeholdersService,
    private _logsService: LogsService,
    private subProjectQuery: SubprojectQuery,
    private cachedService: CachedService,
    private _decisionsService: DecisionsService,
    private _documentsService: DocumentsService,
    public route: ActivatedRoute, private _interestsService: InterestsService,
    private cdRef: ChangeDetectorRef,
    public router: Router,
    public pubsub: PubsubService, public globals: Globals,
    private _pusherService: PusherService,
    private _projectConfigsQuery: ProjectConfigsQuery, private _matrixService: MatrixService, private _mapService: MapService, private elRef: ElementRef) {
    super(pubsub, globals);
  }

  ngOnInit(): void {
    this.subprojectSetup();
    if (this.data.issues_status == 'gesloten')
      this.tabName = 'gesloten'
    this.stakeholders$ = this.stakeholderQuery.selectAll();
    this.decisions$ = this.decisionQuery.selectAll();
    this.name = this.data.name;
    this._usersService.getUsers()
      .pipe(
        finalize(() => this.cdRef.detectChanges()),
        untilDestroyed(this)
      )
      .subscribe(
        (data: any) => {this.users = data.data; this.usersSetup();}
      );
    posthog.capture('issue-detail')
    this._interestsService.getInterests(new PaginationParams())
      .pipe(
        finalize(() => this.cdRef.detectChanges()),
        untilDestroyed(this)
      )
      .subscribe(
        (response: PaginatedResponse) => this.interests = response.data
      );
    if (this.globals && this.globals.projectConfigs) {
      this.newStrategie = this.globals.projectConfigs['project.stakeholder_strategy.is_new'];
    }
    this._stakeholderService.get(new PaginationParams().with(['requirements', 'interests']))
      .pipe(
        finalize(() => this.cdRef.detectChanges()),
        untilDestroyed(this)
      )
      .subscribe((stakeholders: Stakeholder[]) => {
        this.reloadStakeholderAccordion();
      });

    this._projectConfigsQuery.selectFirst()
      .pipe(
        finalize(() => this.cdRef.detectChanges()),
        untilDestroyed(this)
      )
      .subscribe(
        config => {
          if(config){
            if (config['services.relatics.issues.enabled'] && config['services.relatics.enabled']) {
              this.integrateWithRelatics = true;
            }
          }
        });
    this._issuesService.find(this.data.code, ['documents', 'users', 'relatics', 'interests', 'requirements', 'stakeholders','stakeholders.matrices','stakeholders.interests', 'stakeholders.issuesStakeholdersInterests', 'locations', 'subprojects', 'tasks', 'complaints', 'layers', 'workflows'], true)
      .subscribe((issue) => {
        this._mapService.setData(issue.locations);
        this.issue = issue;
        this.issueSubject.next(this.issue);
        this.issueCode = this.issue.code;
        this.applyChartLogic();
        this.updateSlider();
        this.applyUiLogic(this.issue);
        this.reloadStakeholderAccordion();
        this.countOpenDecisions();
        forkJoin([
          this.cachedService.getActionsRelations(),
          this.cachedService.getDecisionsRelations(),
          this.cachedService.getLogsRelations(),
          this.cachedService.getComplaintsRelations(),
          this.cachedService.getDocumentsRelations(),
          this.globals.modules && this.globals.modules.some(module => module.module_id === 'permit') 
          ? this.cachedService.getPermitsRelations() 
          : of([])
        ]
        ).subscribe(([tasks, requirements, contactmoments, complaints, documents, permits]) => {
          this.entities = { requirements, tasks, complaints, contactmoments: contactmoments, documents: documents, permits: permits };
          this.loaded = true;
          this.cdRef.detectChanges();
          this.relaticsValidation();
          this.setLodedSubprojectUsers();
          this.setLodedSubproject();
        });
      })
  }

  @Allowed(Permission.IssuesUpdate)
  private updateIssue(reload: boolean = false, triggerShowFeedback: boolean = true) {
    this.issueSubject.next(this.issue);
    if (this.issueDatePicker?.opened || this.form?.invalid) {
      return;
    }
    this._issuesService.update(this.issue)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: any) => {
          if (this._mapService.circleVectorSource && this._mapService.circleVectorSource.getFeatures().length > 0) {
            this._mapService.circleVectorSource.removeFeature(this._mapService.searchFeature);
          }
          // this.issue.locations = res.locations
          if (triggerShowFeedback) {
            // this._pusherService.onCangeStatus();
            showFeedbackSaved();
          }

          if (reload) {
            this.loadIssue(true);
          }
        },
        (e: HttpErrorResponse) => {
          if (e.status !== 500 && e.error && e.error.message) {
            showFeedback(`Er is en error opgetreden (${e.error.message})`, 'error');
          } else {
            showFeedbackError(e);
          }
        }
      );
  }
  public updateUsers(users) {
    this.issue.users = users;
    this.updateIssue();
  }

  @Allowed(Permission.IssuesUpdate)
  public closeIssue(status: string){
    this.issue.closed_date = new Date(Date.now());
    this.issue.issues_status = status;
    this.updateIssue(true)
  }

  @Allowed(Permission.IssuesUpdate)
  public updateSelectedTheme(theme) {
    this.issue.themeissues = theme;
    this.updateIssue();
  }

  @Allowed(Permission.IssuesUpdate)
  public removeDoc(doc) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker dat u het document wilt verwijderen?'
    });
    dialogRef.disableClose = true;

    dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((remove: boolean) => {
        if (remove) {
          this._documentsService.removeDocument('issues', this.issueCode, doc.id)
            .subscribe(
              () => this.loadIssue()
            );
        }
      });

  }

  public loadIssue(skip = false) {
    const getStakeholders = this._stakeholderService.get(new PaginationParams().with(['requirements', 'interests']));
    const getIssue = this._issuesService.find(this.issueCode, ['documents', 'users', 'relatics', 'interests', 'requirements', 'stakeholders', 'locations', 'subprojects', 'tasks','layers', 'stakeholders.matrices', 'stakeholders.issuesStakeholdersInterests'], true);

    forkJoin([getStakeholders, getIssue])
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.reloadStakeholderAccordion();
        this.issue = response[1];
        this.applyChartLogic();
        this.updateSlider();
        this.setLodedSubprojectUsers();
        this.applyUiLogic(response[1]);
        this.countOpenDecisions();
        this.cdRef.detectChanges();
      });
  }

  public connectIssueRelatics() {
    this.updateIssue(true);
  }

  public removeRelatics(instance) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: `Weet u het zeker dat u het ${instance.type} koppeling wilt verwijderen?`,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((remove: boolean) => {
        if (remove) {
          this.issue.relatics = this.issue.relatics.filter(r => r.id !== instance.id);
        }
      });
  }

  public updateCoordinate() {
    this.updateIssue();
  }

  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'issues', parent: this.issue},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.issue.workflows.push(response);
        this.loadIssue();
      }
    });
  }

  private applyUiLogic(issue: Issue): void {
    this.selectedTheme = issue.themeissues && issue.themeissues.length > 0 ? issue.themeissues : null;
    this._matrixService.getIssuesStakeholderMatricesByIssue(issue.id).subscribe(
      (response: any[]) => {
        this.matrices = response;
        this.cdRef.detectChanges();
      }
    );
  }

  private applyChartLogic(): void {
    let x = 0;
    switch (this.issue.impact) {
      case 'low':
        x = 1;
        break;
      case 'middle':
        x = 2;
        break;
      case 'high':
        x = 3;
        break;
      case 'catastrophic':
        x = 4;
        break;
    }

    let y = 4;
    if (moment(this.issue.deadline).isBetween(moment().add(1, 'months'), moment().add(3, 'months'))) {
      y = 3;
    } else if (moment(this.issue.deadline).isBetween(moment().add(3, 'months'), moment().add(6, 'months'))) {
      y = 2;
    } else if (moment(this.issue.deadline).isBetween(moment().add(6, 'months'), moment().add(12, 'months'))) {
      y = 1;
    } else if (moment(this.issue.deadline).isAfter(moment().add(12, 'months'))) {
      y = 0;
    }

    this.chart.next({
      x: x, y: y,
    });
    this.cdRef.detectChanges();
  }

  public isArray(value: any): boolean {
    return Array.isArray(value);
  }

  getObjectKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  public deleteDecision(decision: Decision, stakeholder: Stakeholder): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: `Weet u het zeker dat u de klanteis koppeling met stakeholder en issue wilt verwijderen?`,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        const decisions = stakeholder.requirements.filter((d: Decision) => d.id !== decision.id);
        this._issuesApiService.assignDecision({
          decisions: decisions,
          stakeholder: stakeholder,
        }, this.issue.code).subscribe(
          () => {
            showFeedbackRemoved();
            this.loadIssue();
          }
        );
      }
    });
  }

  public editDecision(decision: Decision): void {
    const dialogRef = this.dialog.open(DecisionEditComponent, modalConfig({
      data: {
        decision: decision,
        showConnection: false,
      }
    }, ModalEnum.SidebarLarge));

    dialogRef.afterClosed().subscribe(() => {
      this.loadIssue();
    });
  }

  public addInterest(stakeholder: Stakeholder, input: HTMLInputElement): void {
    if (!input.value || !stakeholder) {
      return;
    }

    this._stakeholdersApiService.addInterestsOfStakeholder(stakeholder.code, input.value).subscribe(
      (interest: Interest) => {
        this.loadIssue();
        input.value = '';
        input.blur();
      }
    );
  }

  public onNewDecisionClosed(newDecision: Decision): void {
    if (!newDecision) {
      return;
    }

    this.loadIssue();
  }

  public updateDecisionConnection(stakeholder: Stakeholder): void {
    this._issuesApiService.assignDecision({
      decisions: stakeholder.requirements,
      stakeholder: stakeholder
    }, this.issue.code).subscribe();
  }

  private reloadStakeholderAccordion(): void {
    if (this.issue && this.issue.stakeholders && this.issue.stakeholders.length > 0 && this.stakeholderQuery.hasEntity() && this.decisionQuery.hasEntity()) {
      this.cdRef.detectChanges();
    }
  }

  public compare(c1: { id: number }, c2: { id: number }): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  public updateInterest(): void {
    let interests = [];
    this.issue.stakeholders.forEach((stakeholder: Stakeholder) => {
      interests = interests.concat(stakeholder.interests);
    });

    this._issuesApiService.assignInterest(interests, this.issue.code).subscribe(
      () => this.loadIssue()
    );
  }

  public updateChart(tracker=false): void {
    this.issue.deadline = new Date(moment(dateIso(this.issue.deadline, true, this.globals.timezone)).format('YYYY-MM-DD'))
    this.issue.issues_score=issuesScoreLogic(this.issue.deadline,impactScoring(this.issue.impact))
    this.issueQuery.update(this.issue);
    this.updateIssue();
    this.applyChartLogic();
    this.updateSlider();
  }

  private countOpenDecisions(): void {
    this.issue.stakeholders = this.issue.stakeholders.map((stakeholder: Stakeholder) => {
      return {
        ...stakeholder,
        open_decisions: stakeholder.requirements ? stakeholder.requirements.filter(decision => decision.status === 'open').length : 0
      };
    });
  }

  public edit(event: MouseEvent, issue: Issue, stakeholder: Stakeholder, currentValue: number,type?): void {
    event.stopPropagation();
    if(currentValue !== null)
      currentValue = type === 'tension'? (4 - currentValue) : currentValue;
    this.editedMatrix = {
      issue: issue,
      stakeholder: stakeholder,
      currentValue: (currentValue !== null && currentValue !== undefined && currentValue >= 0) ? currentValue : -1,
      interests: []
    };
    this._matrixService.getIssueInterest(stakeholder.id, issue.id, '').subscribe(
      (data: any) => {
        this.editedMatrix = {
          issue: issue,
          stakeholder: stakeholder,
          currentValue: (currentValue !== null && currentValue !== undefined && currentValue >= 0) ? currentValue : -1,
          interests: data.issuesStakeholdersInterests
        };
        this.cdRef.detectChanges();
      },
      error => {
        console.error('There was an error!', error);
      }
    );
    this.cdRef.detectChanges()
  }

  public applyEdit(matrixValue: number, type: string): void {
    matrixValue = (type === 'tension' && matrixValue !== -1)? (4-matrixValue) : matrixValue;
    matrixValue = matrixValue === -1? null: matrixValue;

    this.matrices[this.editedMatrix.stakeholder.id] = {
      interest: type === 'interest' ? matrixValue : (this.matrices[this.editedMatrix.stakeholder.id]?.interest >=0 ? this.matrices[this.editedMatrix.stakeholder.id]?.interest : null),
      tension: type === 'tension' ? matrixValue : (this.matrices[this.editedMatrix.stakeholder.id]?.tension >=0? this.matrices[this.editedMatrix.stakeholder.id]?.tension : null)
    };

    const matrixCellsObj = this.matrices[this.editedMatrix.stakeholder.id]

    this._matrixService.updateIssuesStakeholderMatrices(this.editedMatrix.stakeholder.id, this.editedMatrix.issue.id, matrixCellsObj)
      .subscribe(() => {
        this.matrices[this.editedMatrix.stakeholder.id] = matrixCellsObj;
        showFeedbackSaved();
        this.cdRef.detectChanges();
      });
    this.popperInterest.hide();
    this.popperTension.hide();
  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  public deleteIssue(issue): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(issue,this.router,'issues')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._issuesApiService.removeIssueByCode(issue).subscribe(
          () => {
            showFeedbackRemoved();
            this.close();
          });
      }
    });
  }

  public deleteInterest(id, stakeholder: Stakeholder) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: 'Weet u het zeker, hiermee verwijdert u ook e.v.t. belangen uit het issue?'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._stakeholdersApiService.removeInterestOfStakeholder(stakeholder.code, id)
          .subscribe(
            () => {
              this.loadIssue();
              showFeedbackSaved();
            }

          );
      }
    });
  }

  public updateInterestDescription(event: MouseEvent, id, description, stakeholder: Stakeholder): void {
    event.stopPropagation();
    this._stakeholdersApiService.updateInterest(stakeholder.code, id, description)
      .subscribe(
        () => {
          this.loadIssue()
          showFeedbackSaved();
        },
      );
  }

  updateSubprojects() {
    this.pubsub.updateSubprojects(this, "updateIssue", this.issue.subprojects)
  }

  updateEntities(event: any) {
    this.issue[event.type] = event.selected;
    this.updateIssue();
  }
  public relaticsValidation(){
    if(this.issue.relatics_uuid){
      if(this.globals.relaticsColumn.issues){
        let columns;
        const dialogcolumns=[];
        // eslint-disable-next-line prefer-const
        columns=this.globals.relaticsColumn.issues.columns;
        columns.map((element)=>{
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer=dialogcolumns.reduce((ac,a) => ({...ac,[a]:a}),{});
        this.cdRef.detectChanges();
      }
    }
  }
  public subprojectSetup(){
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects,'issues:update')
  }
  public setLodedSubproject(){
    this.chipDisabler=[];
    this.chipDisabler=selectedSubprojectSaprator(this.subprojectsSet,this.issue.subprojects)
  }
  public usersSetup(){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
      this.users=globalSubprojectUserSaprator(this.users,activeSubProject)
    }
  }
  public setLodedSubprojectUsers(){
    this.chipDisablerUser=[];
    if(this.users){
    const activeSubProject = this.subProjectQuery.getActive();
    if(activeSubProject && userRoleFinder(this.globals)){
    this.chipDisablerUser=selectedSubprojectUsersSaprator(this.users,this.issue.users);
    }
    const deletedUsers = deletedUsersForClosedTickets(this.issue.closed_by, this.issue.users);
    this.chipDisablerUser = [...this.chipDisablerUser, ...deletedUsers]
  }
  }

  updateSlider() {
    const urgency_status = [];
    const colorStops = [];
    this.calculatedDates = [];
    let score = 0;
    let time = 0;
    let urgency;

    const daysDiffFromDeadline = moment(this.issue.deadline).diff(moment(), 'days');
    const deadlineForArray = new Date(this.issue.deadline);
    // to show on right side of slider
    this.sliderEndDate = deadlineForArray.setDate(deadlineForArray.getDate() - 365);
    // slider ball position shows current date
    this.sliderValue = 365 - daysDiffFromDeadline

    if (daysDiffFromDeadline < 0 || daysDiffFromDeadline > 365) {
      colorStops.push(0)
      time = daysDiffFromDeadline < 0? (4-0/30) : 0
      score=(((1*time)+(2*impactScoring(this.issue.impact)))/((1*4)+(2*4)))*100;
      urgency = calculateUrgency(this.issue,score);
      urgency_status.push(urgency);
      this.calculatedDates.push(this.issue.deadline);
    }
    // if in between the year calculate urgency on each point frm 0 - 365
    else if (daysDiffFromDeadline >= 0 && daysDiffFromDeadline <=365){
      for (let i = 0; i<=365; i++) {
        // (slider) right to left decrease 1 date and calculate urgency
        const deadline = new Date(this.issue.deadline);
        deadline.setDate(deadline.getDate() - i)
        score=issueSliderScoreLogic(deadline,impactScoring(this.issue.impact), i);
        urgency = calculateUrgency(this.issue,score);
        // console.log('score ', score, 'i ', i, deadline);
        const deadlineForArray = new Date(this.issue.deadline);
        // if urgency changes found move it to the statuses array
        if (!urgency_status.includes(urgency)) {
          urgency_status.push(urgency);
          this.calculatedDates.push(deadlineForArray.setDate(deadlineForArray.getDate() - i));
          colorStops.push(i);
        }
      }
    }

    // add 365 to colorStops to calculate the last urgency color % on slider
    colorStops.push(365);
    const colorPercentage = this.calculateColorPercentage(colorStops);
    const linearGradinet = this.createLinearGradient(colorPercentage, urgency_status);
    if (this.slider) {
      // Use Renderer2 to find the .mat-slider-wrapper
      const slideWrapper = this.renderer.selectRootElement('.mat-mdc-slider .mdc-slider__track--active_fill', true);
      if (slideWrapper) {
        // Apply styles to mat-slide-wrapper using Renderer
        this.renderer.setStyle(slideWrapper, 'border-image', linearGradinet);
      }
    }
  }

  createLinearGradient(colorPercentage, urgency_status: string[]) {
    const urgencyColorCodes = {
      "very-high": '#FF7669',
      "high": '#FF9F95',
      "middle": '#F7F050',
      "low": '#E0E1E3'
    }
    let gradientString = "linear-gradient(to left";
    for (let i = 0; i < urgency_status.length; i++ ) {
      gradientString += `, ${urgencyColorCodes[urgency_status[i]]} ${colorPercentage[i]}% ${colorPercentage[i+1]}%`
    }
    gradientString += ") 1";

    // const status = urgency_status[0];
    return gradientString;
  }

  calculateColorPercentage(colorStops: any) {
    this.colorSpanPercentage = [0];
    for (let i = 0; i < colorStops.length -1; i++ ) {
      const percentage = ((colorStops[i+1] - colorStops[i])/365)*100;
      this.colorSpanPercentage.push(percentage)
    }
    this.colorSpanPercentage[this.colorSpanPercentage.length -1] =100;
    this.max = Math.max(...this.colorSpanPercentage); // Find the maximum value in the array
    return this.colorSpanPercentage;
  }

  public onSelectStakeholderInterests(stakeholder,interest: any) {
    if (this.editedMatrix !== undefined) {
      const issueInterest = this.buildIssueInterestPayload(stakeholder, interest);
      this._stakeholdersApiService.submitIssueInterest(issueInterest).subscribe(() => {
        console.log('Issue comb updated...')
        this.loadIssue();
      });
    }
  }

  private buildIssueInterestPayload(stakeholder, interest: any) {
    return {
      "stakeholders_id": stakeholder.id,
      "issues_id": this.editedMatrix.issue.id,
      "interests": interest.map(item => ({ id: item.id }))
    };
  }
}
