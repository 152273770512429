import { EventEmitter, Injectable } from '@angular/core';
import {AbstractService} from './common/abstract.service';
import {HttpClient} from '@angular/common/http';
import { ServiceErrorHandler } from '../../decorator/service-error-handler-decorator';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService extends AbstractService {
  layerBroadcasting=new EventEmitter();
  layers:Subscription;
  sharepointBroadcasting=new EventEmitter();
  sharepoint:Subscription;
  sharepointPopupsSuccess=new EventEmitter();
  PopupsSuccess:Subscription;
  sharepointPopupsError=new EventEmitter();
  PopupsError:Subscription;
  onChatExpandClosed=new EventEmitter();
  chatExpandClosed:Subscription;
  constructor(private http:HttpClient) {
    super();
  }

  public sync(entity: string, platform: string) {
    return this.http.post(`/api/integrations/sync/${entity}/${platform}`, {}, this.httpOptions);
  }

  public create(platform: string, form: any) {
    return this.http.post(`/api/integrations/create/${platform}`, form, this.httpOptions);
  }
  //FreshDeskApis
  @ServiceErrorHandler()
  public setProfile(user_id=''){
    return this.http.get(`/api/integrations/freshdesk/profile/set${user_id}`, this.httpOptions);
  }
  @ServiceErrorHandler()
  public getUserProfiles(){
    return this.http.get(`/api/integrations/freshdesk/users`, this.httpOptions);
  }
  @ServiceErrorHandler()
  public getDomainValidation(domain){
    return this.http.get(`/api/integrations/freshdesk/verify?domain=${domain}`, this.httpOptions);
  }
  public afterBroadcast(action){
    this.layerBroadcasting.emit(action);
  }
  public sharepointBackendProcess(action){
    this.sharepointBroadcasting.emit(action);
  }
  public afterPopupsSuccess(){
    this.sharepointPopupsSuccess.emit();
  }
  public afterPopupsError(){
    this.sharepointPopupsError.emit();
  }
  public afterLargeChatClosed(action){
    this.onChatExpandClosed.emit(action);
  }
  public migrateDocuments(path: string){
    return this.http.post('/api/documents/migrate', {destination_path: path});
  }
  public upgradeDowngradeComplaints(type, ids) {
    return this.http.post(`/api/${type}/migrate`, ids);
  }
}
