import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.sass']
})
export class DeleteModalComponent {
  confirm: boolean;
  // eslint-disable-next-line no-empty-function
  constructor(public dialogRef: MatDialogRef<DeleteModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  submit(d) {
    this.dialogRef.close(d);
  }
}
