<div class="gd-verantwoordelijken col-xs-12 text-left box-shadow" *ngIf="!isLoadingResults">

    <!-- PRIMARY USER -->
    <div class="form-group">
        <mat-form-field>
            <mat-select (selectionChange)="postHolderpostHolderVerantwoordelijken()" id="holder" placeholder="Accounthouder" [(ngModel)]="selectedHolderId">
                <mat-option *ngFor="let item of users; trackBy: trackByFn" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>