<div class="gd-modal">
  <h1 mat-dialog-title>Omwonenden upgraden naar stakeholders</h1>
  <div mat-dialog-content>
    <p>Je staat op het punt om alle omwonenden/bedrijven te upgraden naar stakeholders. Alle gegevens van deze items worden daarmee verplaatst naar het stakeholderoverzicht.</p>
    <p>Na het voltooien van de upgrade zijn de omwonenden niet meer zichtbaar in Dialog. Deze handeling is niet omkeerbaar.</p>
    <p>Selecteer welk type de omwonenden als stakeholder moeten krijgen.</p>
    <label for="stakeholder-type">Type*</label>

    <form [formGroup]="StakeHolderForm" (ngSubmit)="migrateAllResidents(StakeHolderForm.value)">
      <div class="gd-migrate-form">
        <div mat-form-field>
          <mat-select placeholder="Select" id="stakeholder-type" name="id" formControlName="type_id" [ngClass]="{ 'is-invalid': submitted && f.type_id.errors }">
            <mat-option *ngFor="let type of DataTypes; trackBy: trackByFn" [value]="type.id">{{ type.name }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="submitted && f.type_id.errors" class="invalid-feedback">
        <p class="d-error help-block">Selecteer welk type de omwonende als stakeholder moeten krijgen</p>
      </div>

      <mat-dialog-actions align="end">
        <button color="primary" type="submit" mat-button>
          <p style="margin: 0 10px 0 10px">
              Upgrade alle omwonenden
          </p>
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
