import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";
import {dateIso} from "../../core/util/dateIso";


@Pipe({
  name: 'formatdate'
})
export class FormatdatePipe extends DatePipe implements PipeTransform {

  transform(value: any, format?: string, timezone?: string, locale?: string): any {
    if (!value) {
      return '';
    }

    let dateObj: Date = null;

    if (typeof value === 'number' || typeof value === 'string') {
      if (timezone) {
        dateObj = dateIso(value, true, timezone);
      } else {
        dateObj = dateIso(value);
      }
    }

    if (Object.prototype.toString.call(value) === "[object Date]") {
      dateObj = value;
    }

    if (Object.prototype.toString.call(dateObj) === '[object Date]' && !isNaN(dateObj.getTime())) {
      return super.transform(dateObj, format, null, locale);
    }

    return '';
  }

}
