import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { Activity } from './activity.model';

export interface ActivityState extends EntityState<Activity>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activities' })
export class ActivitiesStore extends EntityStore<ActivityState, Activity> {

  constructor() {
    super();
  }

}

