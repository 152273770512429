import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.sass']
})
export class ColorSelectComponent {
  expanded = false;
  @Input() public selectedColor: string = '#4A9ED6';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onSelect: EventEmitter<any> = new EventEmitter();
  show:boolean = false;
  // eslint-disable-next-line no-empty-function
  constructor() {
  }

  selectColor(color:string){
    this.selectedColor = color;
    this.onSelect.emit(color)
    this.show = false;
  }

}
