import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import { ActiveLocation } from './active-loacations.model';

export interface ActiveLocationState extends EntityState<ActiveLocation>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'complaints' })
export class ActiveLocationsStore extends EntityStore<ActiveLocationState, ActiveLocation> {

  constructor() {
    super();
  }

}

