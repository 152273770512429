<div mat-dialog-title>
    <div class="row">
        <div class="col-md-9">
            <h4>{{'maps.add_layer_title' | transloco}}</h4>
        </div>
        <div class="col-md-3">
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
                <mat-icon class="d-text-middle">close</mat-icon>
              </span><span class="sr-only">Close</span></button>
        </div>
    </div>
</div>
<form #form="ngForm" (submit)="data ? update(): submit()" class="w-100">
    <div mat-dialog-content>
        <mat-button-toggle-group [(ngModel)]="publicLayers.type" name="type" aria-label="type">
            <mat-button-toggle value="wfs" [disabled]="editMode">WFS</mat-button-toggle>
            <mat-button-toggle value="wms" [disabled]="editMode">WMS</mat-button-toggle>
            <mat-button-toggle value="public_arcgis" [disabled]="editMode">ArcGIS</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field class="full-width mt-10" *ngIf="noLayerNameValidation">
            <mat-label>{{'Layer Name'}}</mat-label>
            <input matInput type="text" name="name" [(ngModel)]="layerName" (change)="getLayerName()"required/>
        </mat-form-field>
        <mat-form-field class="full-width mt-10">
            <mat-label>{{'Service URL'}}</mat-label>
            <input matInput type="url" name="url" pattern="https?://.+" [(ngModel)]="publicLayers.url" (change)="onChange()" required/>
        </mat-form-field>
        <mat-spinner class="m-auto small-spinner" *ngIf="is_load"></mat-spinner>
        <label class="text-red" *ngIf="errorMessage">{{'maps.invalid_url' | transloco}}</label>
        <div class="row mt-10" *ngIf="publicLayers.layers.length && !is_load">
            <div class="col-md-12">
                <label>{{publicLayers.type | capitaliz}} {{'maps.add_layer_info' | transloco}}</label>
                <mat-list role="list" class="mt-10">
                    <mat-list-item role="listitem" *ngFor="let item of publicLayers.layers"><mat-checkbox class="example-margin" [checked]="item.is_enabled" (change)="checkboxChanged($event,item)" name="list">{{item.title | capitaliz}}</mat-checkbox></mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="pull-right">
        <button type="button" mat-stroked-button class="text-capitalize pull-right mt-10" (click)="close()">{{ 'close' | transloco | capitaliz}}</button>
        <button type="submit" [disabled]="form.invalid" mat-flat-button class="text-capitalize pull-right ml-10 mt-10">{{ 'save' | transloco | capitaliz }}</button>
    </div>
</form>
