import {Injectable} from '@angular/core';
import {ActivitiesStore} from './activities.store';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from '../../../core/service/common/abstract.service';
import {Activity} from './activity.model';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PaginationParams} from '../../../core/service/common/pagination-params';
import {PaginatedResponse} from '../../../core/service/common/paginated-response';
import {ActivitiesQuery} from './activities.query';
import {deepCopy} from '../../../core/util/deepCopy';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({ providedIn: 'root' })
export class ActivitiesService extends AbstractService {

  constructor(private activitiesStore: ActivitiesStore, private activitieQuery: ActivitiesQuery, private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  public get(pagination: PaginationParams): Observable<Activity[]> {
    return this.http.get(`/api/activities`, pagination.get())
      .pipe(
        tap((response: PaginatedResponse<Activity>) => this.activitiesStore.set(response.data)),
        map((response: PaginatedResponse<Activity>) => response.data)
      );
  }

  @ServiceErrorHandler()
  public getSummary(id): Observable<any> {
    return this.http.get(`/api/map/summary/activities/${id}`)
      .pipe(
        map((response:Activity) => response)
      );
  }

  @ServiceErrorHandler()
  public find(id: string, withs: string[] = []): Observable<any> {
    let params = new HttpParams();
    if (withs.length > 0) {
      params = params.append('withs', withs.join(','));
    }

    const request = this.http.get(`/api/activities/${id}`, {
      headers: this.headers,
      params: params
    })
      .pipe(
        tap ((activitie: Activity) => {
          if (this.activitiesStore.getValue().entities && Object.entries(this.activitiesStore.getValue().entities).length > 0) {
            this.activitiesStore.update(activitie);
          } else {
            this.activitiesStore.set([deepCopy(activitie)]);
          }

          this.activitiesStore.setActive(activitie.id);
        })
    );

    return request;
  }

  @ServiceErrorHandler()
  public update(activitie: Activity): Observable<Activity> {
    this.activitiesStore.update(activitie.id, activitie);
    return this.http.put(`/api/activities/${activitie.id}`, activitie, this.httpOptions)
      .pipe(map((response: Activity) => response) );
  }

  @ServiceErrorHandler()
  public create(activitie: Activity): Observable<Activity> {
    return this.http.post('/api/activities', activitie, this.httpOptions)
      .pipe(tap((response: Activity) => {
        this.activitiesStore.add(activitie);
      }));
  }

  @ServiceErrorHandler()
  public delete(activitie: Activity): Observable<any> {
    return this.http.delete(`/api/activities/${activitie.id}`, this.httpOptions)
      .pipe(tap(() => {
        this.activitiesStore.remove(activitie.id);
      }));
  }
}
