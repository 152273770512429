import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { translate } from '@jsverse/transloco';
import posthog from 'posthog-js';
import { forkJoin } from 'rxjs';
import { phoneCode } from 'src/app/akita/contact-moments/state/log.model';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { LogListingType } from "../../../../core/enum/log-listing-type";
import { EmployeesService } from "../../../../core/service/api/employees.service";
import { CachedService } from "../../../../core/service/common/cached.service";
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { deepCopy } from "../../../../core/util/deepCopy";
import { scrollToError } from '../../../../core/util/form';
import { ngModelCopy } from "../../../../core/util/ngModelCopy";
import { showFeedbackError, showFeedbackRemoved, showFeedbackSaved } from '../../../../core/util/notification';
import { Globals } from "../../../../globals";
import { DeletionModalComponent } from '../deletion-modal/deletion-modal.component';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.sass'],
})
export class EmployeeEditComponent extends GenericComponent implements OnInit {
  public stakeholders: any[];
  public employeeCopy: any;
  public name: string;
  public code: string;
  public initialCountryCode: string;
  public codePe: string;
  public business: string = '';
  public personel: string = '';
  public logListingType = LogListingType.EmployeeDetail;
  public logsPerPage: number = 15;
  entities: any
  public users: any[];
  loaded: Boolean = false;
  public reducer: any = true;
  public getCodeRejex = /\+(.*?)\ /g;

  @ViewChild(NgForm) form: NgForm;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFormValidated: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('phone', { read: ElementRef }) phoneInputElement: ElementRef;
  @ViewChild('personalPhone', { read: ElementRef }) phonePersonalInputElement: ElementRef;

  constructor(public globals: Globals, private _cachedService: CachedService,
    @Inject(MAT_DIALOG_DATA) public employee: any,
    public dialog: MatDialog,
    private pubsub: PubsubService,
    private _logsService: LogsService,
    private dialogRef: MatDialogRef<EmployeeEditComponent>,
    private cd: ChangeDetectorRef,
    private _employeesService: EmployeesService) {
    super(globals);
    this.initialCountryCode = this.globals.countryCode;
  }

  ngOnInit() {
    this.name = `${this.employee.first_name || ""} ${this.employee.last_name || ""}`
    this._cachedService.getStakeholdersRelations().subscribe(stakeholders => this.stakeholders = stakeholders);
    this._cachedService.getUsers().subscribe(
      (users) => {
        this.users = users;
      }
    );
    this.employeeCopy = deepCopy(this.employee);
    forkJoin([
      this._cachedService.getLogsRelations()]
    ).subscribe(([contactmoments]) => {
      this.entities = { contactmoments: contactmoments }
      this.loaded = true;
      this.relaticsValidation();
    });
    posthog.capture('contact_person-detail');

  }

  ngAfterViewInit(): void {
    // Set the placeholder directly
    const inputElement = this.phoneInputElement.nativeElement.querySelector('input');
    const inputElementPersonal = this.phonePersonalInputElement.nativeElement.querySelector('input');
    if (inputElement && inputElementPersonal) {
      inputElement.placeholder = translate('reusable.b_phone_number');
      inputElementPersonal.placeholder = translate('reusable.p_b_phone_number');
    }
    this.cd.detectChanges(); // Ensure the view updates immediately after setting the country
  }

  preventScroll(event: FocusEvent): void {
    event.preventDefault(); // Prevents default focus scrolling behavior
    const target = event.target as HTMLElement;
    const y = window.scrollY; // Get current scroll position
    target.blur(); // Temporarily remove focus
    window.scrollTo(0, y); // Scroll back to previous position
    target.focus({ preventScroll: true }); // Refocus the element without scrolling
  }

  trigger(event) {
    const inputValue = event.target.value;
    const result = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.employeeCopy.first_name = result;
  }
  trigger1(event) {
    this.employeeCopy.last_name = event.target.value;
  }
  public close(): void {
    if (this.form.valid) {
      this.pubsub.closeModal(this.dialogRef);
      this.pubsub.resetHistory();
    } else {
      scrollToError(this.form);
    }
  }

  public phoneChange(phone) {
    if (phone && phone.value)
      this.employeeCopy.phone = phone.value
    this.submit();
  }

  public personalPhoneChange(phone) {
    if (phone && phone.value)
      this.employeeCopy.personal_phone = phone.value
    this.submit();
  }

  public submit(): void {
    const y = window.scrollY; // Get current scroll position
    window.scrollTo(0, y); // Scroll back to previous position
    this.onFormValidated.emit(this.form.valid);
    if (this.form.invalid) {
      scrollToError(this.form);
      return;
    }

    this.onFormValidated.emit(this.form.valid);
    if (this.form.invalid) {
      scrollToError(this.form);
      return;
    }

    this.employeeCopy.stakeholders = this.employeeCopy.stakeholders;
    // add check if no number added
    ngModelCopy(this.employee, this.employeeCopy);
      this._employeesService.updateEmployee(this.employeeCopy.id, this.employeeCopy).subscribe(
        () => {
          showFeedbackSaved();
          // this.business = ''
          // this.personel = ''
        }, (e: HttpErrorResponse) => {
          if (e.status === 409 && this.form.controls && this.form.controls.email) {
            const emailControl: FormControl = <FormControl>this.form.controls.email;
            emailControl.setErrors({
              duplicate: true,
            });
            showFeedbackError(e, 'Dit email adres bestaat al', false);
          } else {
            showFeedbackError(e);
          }
        }
      );
  }

  public deleteEmployee(employee): void {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: translate('employees.delete_text')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._employeesService.removeEmployee(employee.id).subscribe(
          () => {
            showFeedbackRemoved();
            this.close();
          });
      }
    });
  }

  updateEntities(event: any) {
    this.employeeCopy[event.type] = event.selected;
    this.submit();
  }
  updateSubprojects() {
    this.pubsub.updateSubprojects(this, "submit", this.employeeCopy.subprojects)
  }
  public relaticsValidation() {
    if (this.employeeCopy.relatics_uuid) {
      if (this.globals.relaticsColumn.employees) {
        let columns;
        const dialogcolumns = [];
        // eslint-disable-next-line prefer-const
        columns = this.globals.relaticsColumn.employees.columns;
        columns.map((element) => {
          dialogcolumns.push(element.dialogcolumn);
        });
        this.reducer = dialogcolumns.reduce((ac, a) => ({ ...ac, [a]: a }), {});
        this.cd.detectChanges();
      }
    }
  }
  /*
    ng phone input logic
    on change the phone number from dropdown
  */
  public onChangeCountryPhone(country): void {
    this.employeeCopy.phone_code = `+${country.dialCode}`;
  }
  public onChangeCountryPersonal(country): void {
    this.employeeCopy.personal_phone_code = `+${country.dialCode}`;
  }
  /*
    on start change the phone number from dropdown
  */
  public onChangeTelPhone(country): void {
    if (!this.employeeCopy.phone_code)
      this.employeeCopy.phone_code = `+${country.s.dialCode}`;
    if (this.employeeCopy.phone) {
      country.setNumber(this.employeeCopy.phone_code);
      this.business = phoneCode(this.employeeCopy.phone, this.employeeCopy.phone_code).phone;
    } else {
      this.employeeCopy.phone_code = `+${country.s.dialCode}`;
    }
  }
  public onChangeTelPersonal(country): void {
    if (!this.employeeCopy.personal_phone_code)
      this.employeeCopy.personal_phone_code = `+${country.s.dialCode}`;
    if (this.employeeCopy.personal_phone) {
      country.setNumber(this.employeeCopy.personal_phone_code);
      this.personel = phoneCode(this.employeeCopy.personal_phone, this.employeeCopy.personal_phone_code).phone;
    } else {
      this.employeeCopy.personal_phone_code = `+${country.s.dialCode}`;
    }
  }
  /*
    ng phone input logic end
  */
}
