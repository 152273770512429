import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AbstractService} from "../common/abstract.service";
import {PaginationParams} from "../common/pagination-params";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../common/paginated-response";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class InterestsService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getInterests(paginationParams: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/interests', paginationParams.get());
  }

  updateInterests(interest) {
    return this.http.put(`/api/interests/${interest.id}`, interest, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitInterest(interest) {
    return this.http.post('/api/interests', interest, this.httpOptions);
  }

  getStakeholderInterests(stakeholderCode) {
    return this.http.get(`/api/stakeholders/${stakeholderCode}/interests`);
  }
}
