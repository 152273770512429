<div #filterSort class="filter-selector">
  <div class="d-cursor-hand select-filter" (click)="show = !show">
    <i *ngIf="sortField || analysisSort || totalSortField || analysisIssueSort" class="uil-arrow-down arrow_drop_down"></i>
    <i *ngIf="filters && !hideIcons" [class.active]="filters && filterFields && paginator._filters[filterFields[0]] && paginator._filters[filterFields[0]].length  ||  paginator._filters[filterFields[1]] && paginator._filters[filterFields[1]].length ||  paginator._filters[filterFields[2]] && paginator._filters[filterFields[2]].length ||  paginator._filters[filterFields[3]] && paginator._filters[filterFields[3]].length ||  paginator._filters[filterFields[4]] && paginator._filters[filterFields[4]].length" class="uil uil-filter filter_alt"></i>
  </div>
  <div *ngIf="show" class="filter-dropdown animate__animated animate__fadeIn">
    <i (click)="toggle()" class="uil uil-times d-text-middle pull-right close"></i>
    <!-- Sorting -->
    <div *ngIf="sortField" class="mb-10">
      <div class="header mb-10">
        {{ 'sort' | transloco | capitaliz }}
      </div>
      <div class="sort-field">
        <span (click)="changeSort(sortField,'asc')" class="sort asc"[class.active]="paginator._direction == 'asc' && paginator._orderBy == sortField" [matTooltip]="'sort_ascending' | transloco | capitaliz">
          <span *ngIf="!hideSortLabels">
            A<i class="uil uil-arrow-right arrow_right"></i>Z
          </span>
          <span *ngIf="hideSortLabels">
            <i class="uil uil-arrow-up arrow_right"></i>
          </span>
        </span>
        <span (click)="changeSort(sortField,'desc')" class="sort dsc" [class.active]="paginator._direction == 'desc' && paginator._orderBy == sortField" [matTooltip]="'sort_descending' | transloco | capitaliz">
          <span *ngIf="!hideSortLabels">
            Z<i class="uil uil-arrow-right arrow_right"></i>A
          </span>
          <span *ngIf="hideSortLabels">
            <i class="uil uil-arrow-down arrow_right"></i>
          </span>
        </span>
      </div>
    </div>
    <!-- Filtering -->
    <div class="filter-lists mt-17" *ngIf="filters">
      <div class="header mb-10">
        {{ 'filter' | transloco | capitaliz }}
      </div>
      <input [(ngModel)]="filterText" (keyup)="onFilterChange()" class="filter-input" />

       <!-- Input field for filtering options -->
      <div *ngFor="let f of filters" [class.hidden]="!filterFields.includes(f.field)">

        <div class="header mb-10" *ngIf="f.label && f.options && filterLabel">{{f.label}}</div>
        <strong *ngIf="f.options" class="d-cursor-hand" (click)="toggleAllSelection(f)">{{'filter_select_all' | transloco | capitaliz}}</strong>
        <mat-selection-list class="mat-selection-list" [(ngModel)]="selectedFilters[f.field]" ngDefaultControl style="max-height: 200px; overflow-y: auto;">
          <mat-list-option *ngFor="let o of f.options; index as i" checkboxPosition="before" [value]="o">
             <span *ngIf="!f.entity">{{o}}</span>
             <span *ngIf="f.entity" >
              <span *transloco="let t; read: f.entity">{{ t(o) | transloco | capitaliz}}</span>
              </span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!-- Analysis Sort -->
    <div *ngIf="analysisSort" class="mb-10">

      <div class="header mb-10">
        {{ 'sort' | transloco | capitaliz }}
      </div>
      <div class="sort-field">
        <span (click)="changeAnalysisSort('stakeholders.name','asc')" class="sort asc" [class.active]="paginator._analysisSort['stakeholders.name'] === 'asc'" [matTooltip]="'sort_ascending' | transloco | capitaliz">
          <span *ngIf="!hideSortLabels">
            A<i class="uil uil-arrow-right arrow_right"></i>Z
          </span>
          <span *ngIf="hideSortLabels">
            <i class="uil uil-arrow-up arrow_right"></i>
          </span>
        </span>
        <span (click)="changeAnalysisSort('stakeholders.name','desc')" class="sort dsc" [class.active]="paginator._analysisSort['stakeholders.name'] === 'desc'" [matTooltip]="'sort_descending' | transloco | capitaliz">
          <span *ngIf="!hideSortLabels">
            Z<i class="uil uil-arrow-right arrow_right"></i>A
          </span>
          <span *ngIf="hideSortLabels">
            <i class="uil uil-arrow-down arrow_right"></i>
          </span>
        </span>
      </div>

      <div class="header mt-10">
        {{ 'Score' | transloco | capitaliz }}
      </div>

      <div class="sort-field mt-10">
        <span (click)="changeAnalysisSort('stakeholders.score','asc')" class="sort asc"[class.active]="paginator._analysisSort['stakeholders.score'] === 'asc'" [matTooltip]="'sort_ascending' | transloco | capitaliz">
          <span><i class="uil uil-arrow-up arrow_right"></i></span>
        </span>
        <span (click)="changeAnalysisSort('stakeholders.score','desc')" class="sort dsc" [class.active]="paginator._analysisSort['stakeholders.score'] === 'desc'" [matTooltip]="'sort_descending' | transloco | capitaliz">
          <span ><i class="uil uil-arrow-down arrow_right"></i></span>
        </span>
      </div>

    </div>
    <div *ngIf="analysisIssueSort" class="mb-10">

      <div class="header mb-10">
        {{ 'sort' | transloco | capitaliz }}
      </div>
      <div class="sort-field">
        <span (click)="changeAnalysisSort('issues.name','asc')" class="sort asc" [class.active]="paginator._analysisSort['issues.name'] === 'asc'" [matTooltip]="'sort_ascending' | transloco | capitaliz">
          <span *ngIf="!hideSortLabels">
            A<i class="uil uil-arrow-right arrow_right"></i>Z
          </span>
          <span *ngIf="hideSortLabels">
            <i class="uil uil-arrow-up arrow_right"></i>
          </span>
        </span>
        <span (click)="changeAnalysisSort('issues.name','desc')" class="sort dsc" [class.active]="paginator._analysisSort['issues.name'] === 'desc'" [matTooltip]="'sort_descending' | transloco | capitaliz">
          <span *ngIf="!hideSortLabels">
            Z<i class="uil uil-arrow-right arrow_right"></i>A
          </span>
          <span *ngIf="hideSortLabels">
            <i class="uil uil-arrow-down arrow_right"></i>
          </span>
        </span>
      </div>

      <div class="header mt-10">
        {{ 'Score' | transloco | capitaliz }}
      </div>

      <div class="sort-field mt-10">
        <span (click)="changeAnalysisSort('issues.issues_score','asc')" class="sort asc"[class.active]="paginator._analysisSort['issues.issues_score'] === 'asc'" [matTooltip]="'sort_ascending' | transloco | capitaliz">
          <span><i class="uil uil-arrow-up arrow_right"></i></span>
        </span>
        <span (click)="changeAnalysisSort('issues.issues_score','desc')" class="sort dsc" [class.active]="paginator._analysisSort['issues.issues_score'] === 'desc'" [matTooltip]="'sort_descending' | transloco | capitaliz">
          <span ><i class="uil uil-arrow-down arrow_right"></i></span>
        </span>
      </div>

    </div>
    <div *ngIf="totalSortField" class="mb-10">
      <div class="header mb-10">
        {{ 'sort' | transloco | capitaliz }}
      </div>
      <div class="sort-field">
        <span (click)="changeAnalysisSort(totalSortField,'asc')" class="sort asc"[class.active]="paginator._analysisSort[totalSortField] === 'asc'" [matTooltip]="'sort_ascending' | transloco | capitaliz">
          <span>
            <i class="uil uil-arrow-up arrow_right"></i>
          </span>
        </span>
        <span (click)="changeAnalysisSort(totalSortField,'desc')" class="sort dsc" [class.active]="paginator._analysisSort[totalSortField] === 'desc'" [matTooltip]="'sort_descending' | transloco | capitaliz">
          <span>
            <i class="uil uil-arrow-down arrow_right"></i>
          </span>
        </span>
      </div>
    </div>

    <div class="action-btns">
      <button *ngIf="filters" [disabled]="disabled()" (click)="cancel()" mat-stroked-button class="half-width mt-10">{{ 'clear' | transloco | capitaliz }} filter</button>
      <button (click)="apply()" [class.half-width]="filters" mat-flat-button class=" mt-10">{{ 'apply' | transloco | capitaliz }} </button>

    </div>
  </div>
</div>
