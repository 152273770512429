<div *ngFor="let items of layer; let i=index">
    <div *ngIf="items.sublayerExist else no_sublayer">
      <mat-expansion-panel class="mat-elevation-z0 layers-accordion items-layers">
        <mat-expansion-panel-header>
          <mat-panel-title class="a-self-center">
            <img class="pull-left openlayer-sidebar_svg" [src]="items.icon"/>
            <label class="pull-left items-label">{{'maps.'+items.name | transloco}}</label>
          </mat-panel-title>
          <mat-panel-description class="a-self-center">
            <mat-slide-toggle [disabled]="!showToggles" class="pull-right m-null" [(ngModel)]="items.is_active" (change)="ontoggleChangeAllItem($event,items.name)"></mat-slide-toggle>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <mat-list>
            <ng-container *ngFor="let item of items.status; let i=index">
              <mat-list-item  class="d-block">
                <div class="d-flex j-space-between mtb-5">
                  <div class="d-flex">
                    <mat-icon class="sub-layer-icon-set">fiber_manual_record</mat-icon>
                    <img class="pull-left openlayer-sidebar_svg" [src]="item.icon"/>
                    <label class="pull-left items-label sub-label a-self-center f-12 m-null">{{ item.name | transloco}}</label>
                  </div>
                  <mat-slide-toggle [disabled]="!showToggles" class="custom-slide-toggle pull-right m-null a-self-center" [(ngModel)]="item.is_active" (change)="ontoggleChangeItem($event,items.name,false,true)"></mat-slide-toggle>
                </div>
              </mat-list-item>
              <mat-divider class="z-99 p-inherit" *ngIf="i < items.status.length-1"></mat-divider>
            </ng-container>
          </mat-list>
      </mat-expansion-panel>
    </div>
    <ng-template #no_sublayer>
      <mat-expansion-panel class="mat-elevation-z0 layers-accordion items-layers" disabled>
        <mat-expansion-panel-header>
          <mat-panel-title class="a-self-center">
            <img class="pull-left openlayer-sidebar_svg" [src]="items.icon" />
            <label class="pull-left items-label text-black">{{'maps.'+items.name | transloco}}</label>
          </mat-panel-title>
          <mat-panel-description class="a-self-center">
            <mat-slide-toggle [disabled]="!showToggles" class="pull-right m-null" [(ngModel)]="items.is_active" (change)="ontoggleChangeAllItem($event,items.name,false)"></mat-slide-toggle>
          </mat-panel-description>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-template>
    <mat-divider class="z-99" *ngIf="i < layer.length-1"></mat-divider>
</div>
