<div *ngIf="pageSubTitle || pageTitle" class="row">
    <div class="col-xs-12 text-left ">
        <a *ngIf="routeInterceptor.previousUrl && !globals.adminPage" class="d-cursor-hand" (click)="back()">
            <!-- <mat-icon class="material-icons d-text-middle inline-block">keyboard_arrow_left</mat-icon> -->
            <!-- <h5 class="mb-0 inline-block">Terug</h5> -->
        </a>
        <a *ngIf="globals.adminPage && pubsub.PageSubTitle !='disable'" class="d-cursor-hand back-link" [routerLink]="['/beheer']">
            <mat-icon class="material-icons d-text-middle inline-block">keyboard_arrow_left</mat-icon>
            <h5 class="mb-0 inline-block">{{ 'text.back_to_projects' | transloco }}</h5>
        </a>
    </div>

    <div *ngIf="pageTitle" class="col-xs-12 text-left">
        <h1 class="pull-left">{{ pageTitle | transloco | capitaliz}}</h1>
    </div>
</div>
