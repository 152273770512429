<ng-container>
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5 d-flex j-space-between">
      <h5>{{'settings.personal.change_password' | transloco}}</h5>
    </div>
  </div>
  <div class="popup-body" appScroll>
    <div class="row">
      <div class="col-md-12">
        <span>{{'settings.personal.login_mess' | transloco}}</span>
      </div>
    </div>
  </div>
  <div class="popup-footer" >

    <button type="submit"  mat-flat-button class="ml-10" (click)="logout()">
      Ok
    </button>
  </div>
</ng-container>
