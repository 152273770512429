import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { GeneralService } from 'src/app/core/service/api/general.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { ChildTabbedPage } from 'src/app/core/util/abstract/child-tabbed-page';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { globalUserNotificationSetup, integrationError, integrationValidation, userConfigsSetting } from 'src/app/core/util/integration';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-notification-tab',
  templateUrl: './notification-tab.component.html',
  styleUrls: ['./notification-tab.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationTabComponent extends ChildTabbedPage implements OnInit {
  tabName: string;
  pageSubTitle: string;
  pageTitle: string;
  public projects:any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectIndicidualProject: EventEmitter<any> = new EventEmitter();

  public integrations=[
    {
      enableKey:'bouwapp',
      enableUser:'bouwapp_enabled',
    },
    {
      enableKey:'freshdesk',
      enableUser:'freshdesk_enabled',
    }
  ]
  public notifications;
  public integrationLevel;
  userConfig: any;
  dashboard: any;
  public integrationCheck:any[]=[];
  constructor(public pubsub: PubsubService,public globals: Globals,private _configsService: ProjectConfigsService, private _generalService: GeneralService,private dialog: MatDialog,) {
    super(pubsub, globals);
    this.projects = this.globals.projects;
   }

  ngOnInit(): void {
    this.loadNotifications();
    this.loadConfigs();
    this.integrations.forEach(element => {
      this.integrationNotificationEnable(element.enableKey,element.enableUser)
    });
  }
  //load notification
  public loadNotifications(): void{
    this.notifications={
      daily_mail:globalUserNotificationSetup(this.globals,'daily_mail'),
      weekly_mail:globalUserNotificationSetup(this.globals,'weekly_mail'),
      item_assigned:globalUserNotificationSetup(this.globals,'item_assigned'),
      item_status_changed:globalUserNotificationSetup(this.globals,'item_status_changed'),
      comment_tagged:globalUserNotificationSetup(this.globals,'comment_tagged'),
      comment_posted:globalUserNotificationSetup(this.globals,'comment_posted'),
      bouwapp_integration:globalUserNotificationSetup(this.globals,'bouwapp_integration'),
      freshdesk_integration:globalUserNotificationSetup(this.globals,'freshdesk_integration'),
    }
  }
  //load configs
  public loadConfigs(): void{
    this._configsService.getUserConfigs()
    .pipe(
      tap(res=>{
        this.globals.user.configs=res;
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //load check integration connection
  public integrationNotificationEnable(type,userType): void{
    this.integrationLevel=integrationValidation(this.globals,type,userType,integrationError(this.globals,type));
    if(this.integrationLevel==='project' || this.integrationLevel==='personal'){
        this.integrationCheck[type]=true;
    }
  }
  //update notification
  public updateEmailNotification(): void {
    // Save the config
    this.globals.user.configs=userConfigsSetting(this.globals,this.notifications,'notification')
    this._generalService.updateUserConfigs(this.globals.user.configs).subscribe((res:any)=>{
      this.notifications=res.config.notifications;
      showFeedbackSaved();
    });
  }
  //on click apply notification setting on all projects
  public applyOnIndividualProjects(): void{
    this.onSelectIndicidualProject.emit();
  }
  //on update configs
  public onUpdateConfigs(): void{
    this._generalService.updateUserConfigs(this.globals.user.configs).subscribe((res:any)=>{
      showFeedbackSaved();
    });
  }
  //update all notification according to settings
  public applyOnAllProjects(): void{
    this.globals.user.configs.toggle=true;
    this.onUpdateConfigs();
  }
}
