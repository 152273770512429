import {Component} from '@angular/core';
import {PubsubService} from "../../../../core/service/api/pubsub.service";
import {HeaderGeneralComponent} from "../header-general.component";
import {Globals} from "../../../../globals";
import {Router} from '@angular/router';
import {RouteInterceptorService} from '../../../../core/service/util/route-interceptor.service';

@Component({
  selector: 'app-header-empty',
  templateUrl: './header-empty.component.html',
  styleUrls: ['./header-empty.component.css']
})
export class HeaderEmptyComponent extends HeaderGeneralComponent {

  project: any;
  constructor(public pubsub: PubsubService, public globals: Globals,
              public router: Router, public routeInterceptor: RouteInterceptorService) {
    super(pubsub, globals, router, routeInterceptor);
  }
}
