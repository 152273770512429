<div *transloco="let t">
    <div *ngIf="data.message.includes('Opgeslagen') || data.message.includes('Stored'); else undo">
        <div *ngIf="data.type=='matrix'" class="flex">
            <div class="font-set">{{ data?.message }}</div>
            <a class="set-undo-button" (click)='addToChart()'>{{ t('stakeholders.add_to_chart')}}</a>
        </div>
        <div *ngIf="data.type=='openItems'" class="flex">
            <div class="font-set">{{ data?.message }}</div>
            <a class="set-undo-button" (click)='openDetailPanel()'>{{ t('snackbar.open_item')}}</a>
        </div>
    </div>
    <ng-template #undo>
        <div class="flex">
            <div class="font-set">{{ data?.message }}</div>
            <a class="set-undo-button" (click)='Undo()'>{{ t('actions.undo')}}</a>
        </div>
    </ng-template>
</div>
