import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import {DocuploadType} from "../../core/enum/docupload-type";
import {ModalEnum, modalConfig} from "../../core/util/modalConfig";
import { Docupload2Component } from '../components/docupload2/docupload2.component';

export interface DocumentSidebarData {
  type: DocuploadType;
  documents: any[];
  id: number;
  storeTemporarily: boolean;
  disableSuccessMessage: boolean;
  skipValidation: boolean;
  showlist: boolean
}

@Directive({
  selector: '[documentSidebar]'
})
export class DocumentSidebarDirective {

  @Input() documentSidebar:DocuploadType;
  @Input() documentSidebarDocuments: any[];
  @Input() documentSidebarId: number;
  @Input() documentSidebarTemporary: boolean;
  @Input() documentSidebarHideSuccessMessage: boolean;
  @Input() documentSidebarSkipValidation: boolean;
  @Input() listUpload: boolean;

  @Output() closed:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() documentRemoved:EventEmitter<number> = new EventEmitter<number>();
  @Output() documentUploaded:EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line no-empty-function
  constructor(private el?: ElementRef, public dialog?: MatDialog) { }

  ngAfterViewInit(): void {
    if (!this.documentSidebar) {
      throw new TypeError('Attribute "documentSidebar" is mandatory and should have DocuploadType type value');
    }

    const data: DocumentSidebarData = {
      type: this.documentSidebar,
      documents: this.documentSidebarDocuments || [],
      id: this.documentSidebarId || null,
      storeTemporarily: this.documentSidebarTemporary || false,
      disableSuccessMessage: this.documentSidebarHideSuccessMessage || false,
      skipValidation: this.documentSidebarSkipValidation || false,
      showlist:this.listUpload || false,
    };

    this.el.nativeElement.onclick = (event: MouseEvent) => {
      event.stopPropagation();
      const dialog = this.dialog.open(Docupload2Component, modalConfig({
        data: data
      }, ModalEnum.ModalDefault));

      dialog.componentInstance.documentRemoved.subscribe(
        (docId: number) => this.documentRemoved.emit(docId)
      );

      dialog.componentInstance.documentUploaded.subscribe(
        response => this.documentUploaded.emit(response)
      );

      dialog.afterClosed().subscribe( (response: any) => {
        this.closed.emit(response);
      });
    };
  }

}
