import { ID } from "@datorama/akita";
import { BouwAppIntegrationChatModal } from "../../shared/components/modal/in-app-chat/in-app-chat-integrations/bouwapp-integration-chat-modal";
import { FreshdeskIntegrationChatModal } from "../../shared/components/modal/in-app-chat/in-app-chat-integrations/freshdesk-integration-chat-modal";
import { WhatsAppIntegrationChatModal } from "../../shared/components/modal/in-app-chat/in-app-chat-integrations/whatsapp-integration-chat-modal";
export type InAppChat= {
id?: ID,
createdAt: Date,
name?: string,
content?: string,
attachment?: any,
projectUser?: any,
// @id?: any,
is_sent?: boolean,
fullName?: string
};

// Define the interfaces
interface Contact {
  wa_id: string;
  profile?: {
      name: string;
  };
}

interface Message {
  id: string;
  from: string;
  text: {
      body: string;
  };
  type: string;
  timestamp: string;
}

interface Metadata {
  phone_number_id: string;
  display_phone_number: string;
}

interface ChatMessage {
  contacts: Contact[];
  messages: Message[];
  metadata: Metadata;
  messaging_product: string;
}

export interface Complaint {
  id: number;
  sid: string;
  chatable_type: string;
  chatable_id: number;
  platform: string;
  message: string | ChatMessage;
  created_at: string;
  updated_at: string;
  source: string;
}


export function sendMessageFormate(attachment,content,id,type){
if(type=='smart_reporting'){
  return{message:content,notice:id}
}
  return{attachment:attachment ? attachment.length?attachment:null:null,message:content,ticket:id}

}
export function sendResponseFormate(message,type){
if(type=='smart_reporting'){
  return{createdAt:new Date(),isResponse:message.isResponse,id:message.id,notice:message.notice,projectUser:message.projectUser,content:message.message,responses:message.responses}
}
  return message

}

export function sendResponseFormateWhatsApp(id, text, isSent, name){
    return{createdAt:new Date(),id:id,content:text, is_sent: isSent, full_name: name}
  }

//set reference
export function setReference(type,services,globals){
let reference;
switch(type){
  case 'freshdesk':
  reference= new FreshdeskIntegrationChatModal(services,globals)
  break;
  case 'bouwapp':
  reference= new BouwAppIntegrationChatModal(services,globals)
  break;
  case 'whatsapp':
  reference= new WhatsAppIntegrationChatModal(services,globals)
  break;
}
return reference;
}
export function freshdeskNameFinder(ticket,globals){
  let userName='';
  if(globals.freshdesk_users){
    globals.freshdesk_users.forEach(element => {
      if(element.freshdesk_id){
        if(ticket.user_id==element.freshdesk_id){
          userName=element.name
        }
      }
    });
  }
  return userName
}
//freshdesk integration Data Maping
export function freshdeskIntegrationDataMap(ticket,globals,level): any{
const messages=[];
if(ticket.length){
    ticket.forEach(msg => {
        messages.push({
          id:msg.id,
          createdAt:msg.created_at,
          content:msg.body,
          attachment:msg.attachments,
          is_sent:level ? (msg.user_id==globals.projectConfigs['services.integration.freshdesk.id'] ? true : false) : (msg.user_id==globals.user.configs.config.services.integration.freshdesk.id ? true : false),
          fullName:level ? (msg.user_id==globals.projectConfigs['services.integration.freshdesk.id'] ? globals.user.name : freshdeskNameFinder(msg,globals)) : (msg.user_id==globals.user.configs.config.services.integration.freshdesk.id ? globals.user.name : freshdeskNameFinder(msg,globals)),
          type:msg.private,
          source:msg.source
        })
    });
}
return messages;
}
//integration Data Maping inertial message
export function integrationDataMapInertial(ticket): any{
  const messages=[];
  messages.push({
    id:ticket.id,
    createdAt:ticket.created_at,
    content:ticket.description,
    attachment:[],
    is_sent:false,
    fullName:'',
    type:true,
    source:2
  })
  return messages;
}
//freshdesk integration Data Maping
export function freshdeskIntegrationSendDataResponceMap(ticket,globals): any{
  let messages;
  if(ticket){
      messages={
        id:ticket.id,
        createdAt:ticket.created_at,
        content:ticket.body,
        attachment:ticket.attachments,
        is_sent:true,
        fullName:globals.user.name,
        type:true,
        source:2
      };
  }
  return messages;
}
//integration lightbox open in app chats
export function integrationLightBoxOpen(type,document){
  const albums = [];
  switch(type){
    case "freshdesk":
      albums.push({
        src: document.attachment_url,
        caption: document.name,
        thumb: document.attachment_urlt,
      });
      break;
    case "bouwapp":
      albums.push({
        src: document.attachmentThumbnails.full,
        caption: document.attachment,
        thumb: document.attachmentThumbnails.fullt,
      });
      break;
  }
  return albums;
}
