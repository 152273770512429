<div mat-dialog-title>
    <h4 class="row mb-10 inline-block">{{'documents.upload_doc' | transloco}}</h4>
    <button type="button" class="close" (click)="close()">
    <span aria-hidden="true">
      <mat-icon class="d-text-middle">close</mat-icon>
    </span>
    <span class="sr-only">Close</span>
  </button>
</div>

<div class="modal-body no-padding">

    <!-- Don't remove this div. This is meant to hide the preview of dropzone -->
    <div class="dropzone-previews"></div>

    <ng-container *ngIf="!isReadOnly">

        <!-- label link sharepoint/existing -->
        <div class="row">
            <label class="col-md-12 col-xs-12" *ngIf="globals.projectConfigs['services.sharepoint.enabled']">{{'reusable.link_Sharepoint' | transloco}}</label>
            <label class="col-md-12 col-xs-12" *ngIf="!globals.projectConfigs['services.sharepoint.enabled']">{{'reusable.link_existing' | transloco}}</label>
        </div>
        <!-- search document -->
        <div class="row">
            <mat-form-field class="col-md-12 col-xs-12">
              <mat-label>{{ globals.projectConfigs['services.sharepoint.enabled'] ? 'Filter op document titel' : 'Filter op document titel of datum' }}</mat-label>
                <input matInput name="new-instance-string" #multiSelectInput [(ngModel)]="searchKeyword" (keyup)="searchDocument()"
                />
            </mat-form-field>
        </div>
        <!-- search result -->
        <div class="row search-result-container">
            <mat-list class="col-md-12 col-xs-12">
                <div *ngFor="let result of search.data; trackBy: trackByFn" mat-list-item class="search-result" (click)="connectDocument(result, multiSelectInput)">
                    <label class="d-cursor-hand">{{ result.filename_simple }}</label>
                    <div>
                        <span *ngFor="let author of result.authors; let last = last; trackBy: trackByFn">
                            {{ author }}<ng-container *ngIf="!last">, </ng-container>
                            <span> ({{ result.size | bytes }})</span>
                        </span>
                    </div>
                </div>
                <mat-paginator *ngIf="search.total > 0" class="borderless no-background" [length]="search.total" [pageSize]="search.size" (page)="updateSearchPage($event)"></mat-paginator>
                <i *ngIf="search.total === 0">{{'reusable.no_documents_found' | transloco}}.</i>
            </mat-list>
        </div>

        <!-- Dropzone drag/drop documents -->
        <div class="row">
            <div class="form-group col-xs-12">
                <label *ngIf="!globals.projectConfigs['services.sharepoint.enabled']">{{'reusable.upload_document' | transloco}}</label>
                <label *ngIf="globals.projectConfigs['services.sharepoint.enabled']">{{'reusable.upload_document_sharepoint' | transloco}}</label>
                <dropzone *ngIf="dropzoneConfig" #dropzone [config]="dropzoneConfig" [message]="dropzoneTemplate" (error)="onUploadDropzoneError($event)" (success)="onUploadFileSuccess($event)">
                </dropzone>
                <mat-progress-bar *ngIf="upload" mode="determinate" [value]="upload.upload.progress"></mat-progress-bar>
            </div>
        </div>

        <!-- differentiator 'or' -->
        <div class="row" style="display: flex;">
            <div class="col-md-6 col-xs-6 border-horizontal"></div>
            <label>{{ 'documents.or' | transloco}}</label>
            <div class="col-md-6 col-xs-6 border-horizontal"></div>

        </div>

        <!-- document link save -->
        <form #linkForm="ngForm" (ngSubmit)="addDocument()">
            <div class="row" style="margin-top: 15px">
                <label class="col-md-12 col-xs-12">{{'documents.store_link' | transloco}}</label>
                <mat-form-field class="col-md-4 col-xs-4">
                  <mat-label>{{'documents.document_name' | transloco }}</mat-label>
                    <input matInput name="name" [(ngModel)]="documentName"/>
                </mat-form-field>
                <mat-form-field class="col-md-4 col-xs-4">
                  <mat-label>{{'documents.document_link' | transloco }}</mat-label>
                    <input matInput name="url" type="url" pattern="https?://.+" [(ngModel)]="documentLink"/>
                </mat-form-field>
                <button [disabled]="linkForm.invalid || !documentName || !documentLink" type="submit" mat-button mat-stroked-button class="project-data">{{'documents.add' | transloco}}</button>
            </div>
        </form>

    </ng-container>
    <div class="row m-0" *ngIf="notExistsDocuments && notExistsDocuments.length > 0">
        <label class="mt-2 mb-2">{{'documents.added_documents' | transloco}}</label>
        <ul class="list-group">
            <li class="list-group-item docupload-core-listitem" *ngFor="let doc of notExistsDocuments;let i=index">
                <span class="col-xs-10 col-md-10 text-left">
                    {{doc.name | truncatie:60}}
                </span>
                <span class="col-xs-2 col-md-2">
                    <mat-icon (click)="removeDoc(doc, true)" class="pull-right d-cursor-hand">delete</mat-icon>
                </span>
            </li>
        </ul>
    </div>

    <div class="row" *ngIf="!docSidebarData.showlist">
        <div class="col-xs-12">
            <label>{{'reusable.linked_documents' | transloco}}</label>

            <ul class="list-group" *ngIf="!docSidebarData.documents || docSidebarData.documents.length === 0">
                <li class="list-group-item">
                    <span>{{'reusable.no_documents' | transloco}}</span>
                </li>
            </ul>
            <ul class="list-group" *ngIf="docSidebarData.documents && docSidebarData.documents.length > 0">
                <li class="list-group-item docupload-core-listitem" *ngFor="let doc of docSidebarData.documents; trackBy: trackByFn">
                    <span *ngIf="doc.link" class="col-xs-10 col-md-10 text-left">
                        <a [versiondownload]="doc" class="d-cursor-hand" *ngIf="doc.name.length > 25">{{ doc.name | truncatie : 25 }}</a>
                        <a *ngIf="doc.name.length <= 25" class="d-cursor-hand" [versiondownload]="doc">{{ doc.name }}</a>
                    </span>

                    <!-- Read only document cannot be removed -->
                    <span *ngIf="!doc.link" class="col-xs-10 col-md-10 text-left">
                        <a class="non-link" *ngIf="doc.name.length > 25">{{ doc.name | truncatie : 25 }}</a>
                        <a class="non-link" *ngIf="doc.name.length <= 25">{{ doc.name }}</a>
                    </span>
                    <span class="col-xs-2 col-md-2">
                        <mat-icon *ngIf="!doc.read_only" (click)="removeDoc(doc)" class="pull-right d-cursor-hand">delete</mat-icon>
                    </span>
                </li>
            </ul>

            <div *ngIf="isError" class="alert alert-danger alert-dismissable">
                <a class="close" (click)="removeAlert()">&times;</a> {{message}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <label *ngIf="alreadyExistsDocuments.length > 0">{{'documents.already_exists' | transloco | capitaliz }}</label>
            <ul class="list-group" *ngIf="alreadyExistsDocuments.length > 0">
                <li class="list-group-item docupload-core-listitem" *ngFor="let doc of alreadyExistsDocuments; trackBy: trackByFn">
                    <span class="col-xs-10 col-md-10 text-left">
                        <a class="non-link isexist">{{ doc.name | truncatie : 60 }} ({{ dateNow | formatdate: 'dd-MM-yyyy HH:mm' : globals.timezone }})</a>
                    </span>
                    <!-- <span class="col-xs-2 col-md-2">
                        <mat-icon *ngIf="!doc.read_only" (click)="removeDoc(doc)" class="pull-right d-cursor-hand">delete</mat-icon>
                    </span> -->
                </li>
            </ul>
        </div>
    </div>
</div>

<div style="margin-top: 15px">
    <div class="row pull-right">
        <button (click)="close()" mat-flat-button>{{'reusable.close_doc' | transloco}}</button>
    </div>
</div>
