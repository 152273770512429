import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActivitiesStore, ActivityState } from './activities.store';
import { Activity } from './activity.model';

@Injectable({ providedIn: 'root' })
export class ActivitiesQuery extends QueryEntity<ActivityState, Activity> {
  constructor(protected store: ActivitiesStore) {
    super(store);
  }

  add(activitie: Activity): void {
    this.store.add(activitie);
  }
}
