<div class="row popup-container" *transloco="let t;">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
          <mat-icon class="d-text-middle">close</mat-icon>
        </span><span class="sr-only">Close</span></button>
            <div *hasPermission="[Permission.MiddelDelete]">
                <mat-icon (click)="delete()" class="d-cursor-hand delete pull-right content-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}">delete</mat-icon>
            </div>
            <div *ngIf="hasModule('workflow') && hasPermission(Permission.WorkflowsCreate)">
                <button  *ngIf="calendarsFilter" type="button" mat-stroked-button (click)="newWorkflow()" class="pull-right" style="margin-right: 20px;" >
                   <i class="uil uil-plus-circle d-text-middle"></i>  Workflow
                </button>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1><strong>{{ 'middel.label' | transloco | capitaliz }} </strong>{{name}}</h1>
        </div>

    </div>
    <mat-progress-bar *ngIf="!middelForm" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="middelForm">
        <app-workflow-generic *ngIf="calendarsFilter.contents && calendarsFilter.contents.workflows && calendarsFilter.contents.workflows.length && hasModule('workflow')" [margin]="false" parentType="contents" [deadline]="reducer.sent_date" [workflows]="calendarsFilter.contents.workflows" [permitId]="calendarsFilter.contents.id"></app-workflow-generic>
        <form [formGroup]="middelForm">
            <div class="col-xs-12">
                <h4>{{ 'Status' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border">
                    <div class="col-xs-12">
                        <div class="col-xs-6 mt-10">
                            {{ 'Status' | transloco | capitaliz }}
                        </div>
                        <div class="col-xs-6">
                            <label class="switch col-xs-6 pull-right" [matTooltip]="reducer.is_sent ? t('settings.relatic.disable_field') : null" [ngClass]="{'disable_enitiy': reducer.is_sent || !hasPermission(Permission.MiddelUpdate)}">
                                <input type="checkbox" (change)="updateMiddelDetail()" formControlName="is_sent">
                                <span class="slider">
                                <div class="sent-txt">{{'reusable.sent' | transloco | capitaliz}}</div>
                                <div class="to-be-sent-txt">{{'reusable.to_be_send' | transloco | capitaliz}}</div>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <h4>{{ 'text.general' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border">
                    <div class="col-xs-12">
                        <mat-form-field class="full-width" [matTooltip]="reducer.type ? t('settings.relatic.disable_field') : null" [ngClass]="{'disable_enitiy': reducer.type || !hasPermission(Permission.MiddelUpdate)}">
                          <mat-label>{{ 'reusable.middel_type' | transloco }}</mat-label>
                            <input matInput (change)="updateMiddelDetail()" formControlName="type" required>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12">
                        <mat-form-field class="full-width" [matTooltip]="reducer.group ? t('settings.relatic.disable_field') : null" [ngClass]="{'disable_enitiy': reducer.group || !hasPermission(Permission.MiddelUpdate)}">
                          <mat-label>{{ 'reusable.target_group' | transloco }}</mat-label>
                            <input matInput  (change)="updateMiddelDetail()" formControlName="group">
                        </mat-form-field>
                    </div>

                    <div class="col-xs-12">
                        <app-multiselect-users [chipDisabler]="chipDisablerUser" [disabled]="!hasPermission(Permission.MiddelUpdate)" id="middel_user" [multipleUsers]="false" [selectedUsers]="middelPerson" [users]="users" [placeholder]="'reusable.select_users' | transloco" (onSelectedUsersUpdated)="selectUser($event)">
                        </app-multiselect-users>
                    </div>

                    <div class="col-xs-12" formGroupName="calendars">
                        <mat-form-field class="full-width" [matTooltip]="reducer.sent_date ? t('settings.relatic.disable_field') : null">
                          <mat-label>{{ 'reusable.date_to_be_sent' | transloco }}</mat-label>
                            <input matInput [matDatepicker]="middelDate" (change)="updateMiddelDetail()" (dateChange)="updateMiddelDetail()" formControlName="started_at" [disabled]="reducer.sent_date || !hasPermission(Permission.MiddelUpdate)">
                            <mat-datepicker-toggle matSuffix [for]="middelDate"></mat-datepicker-toggle>
                            <mat-datepicker #middelDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-xs-12" [ngClass]="{'disable_enitiy':!hasPermission(Permission.MiddelUpdate)}">
                        <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [disabled]="!hasPermission(Permission.MiddelUpdate) || globals.subprojects.length === 0" [parent]="calendarsFilter" parentType="middel" class="subprojects" type="subprojects" [maxChipItems]="3" *ngIf="hasModule('subprojects')"
                            (ngModelChange)="updateSubprojects()" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco" name="subprojects" [(ngModel)]="calendarsFilter.subprojects" [ngModelOptions]="{standalone: true}" [multiple]="true" [maxChipLength]="20" [uniqueId]="'sub-projects'">
                            <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                            <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                                {{ subproject.name }}
                            </app-generic-multiselect-checkbox-option>
                        </app-generic-multiselect-checkbox>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-12">
                        <app-comment-box [users]="users" parentType="contents" type="calendars" [type_id]="calendarId"></app-comment-box>
                    </div>
                </div>
                <h4>{{ 'decisions.connections' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component">
                    <div>
                        <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                        <app-add-connect-generic parentType="contents" (onChange)="updateEntities($event)" *ngIf="loaded" [parent]="calendarsFilter" [data]="entities" [types]="['tasks','activities','documents']">
                        </app-add-connect-generic>
                    </div>
                </div>

                <h4 class="mb-15 text-capitalize">{{ 'location' | transloco }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border map-div" [matTooltip]="reducer.area ? t('settings.relatic.disable_field') : null">
                    <div class="col-xs-12" [ngClass]="{'disable_enitiy': reducer.area || !hasPermission(Permission.MiddelUpdate)}">
                        <app-openlayers-map [includeSidebar]="false" [collapseSidebar]="true" [defaultCoordinate]="globals.projectConfig.default_map" [showMapReset]="true" [maxArea]="25" (ngModelChange)="updateSelectedCoordinates()" (maxAreaExceeded)="showMaxAreaError()" formControlName="locations"
                            name="locations" locatableType="calendars" type="middel" [bookmarkOptions]="['save', 'add']" defaultDrawTool="area" clickStartDrawingMsg="Teken verzendgebied(en)" [disabled]="reducer.area || !hasPermission(Permission.MiddelUpdate)"></app-openlayers-map>
                    </div>
                </div>
                <h4 class="mb-15 text-capitalize">{{ 'Channels' | transloco }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border map-div">
                    <mat-progress-bar *ngIf="isProcsing" mode="buffer"></mat-progress-bar>
                    <table class="table table-borderless" *ngIf="!isProcsing">
                        <tbody>
                            <tr>
                                <td scope="row" class="col-md-1">
                                    <mat-icon>home</mat-icon>
                                </td>
                                <td class="col-md-2">{{ 'offical_media' | transloco }}</td>
                                <td class="col-md-6"> <span class="address-count" *ngIf="selectedAddressCount > 1">{{ selectedAddressCount }}</span>
                                    <span class="address-count" *ngIf="selectedAddressCount === 1">{{ selectedAddressCount }}</span>
                                    <span class="address-count" *ngIf="selectedAddressCount === 0">{{'reusable.no_address' | transloco}}</span></td>
                                <td class="col-md-3">
                                    <span matTooltip="Selecteer eerst een verzendgebied" [matTooltipDisabled]="isExporting || selectedAddressCount > 0">
                                        <button type="button" mat-stroked-button (click)="exportAddresses()" class="w-50"
                                          [disabled]="isExporting || selectedAddressCount === 0">
                                          <span *ngIf="isExporting">Laden...</span>
                                    <span *ngIf="!isExporting">Export adressen</span>
                                    </button>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" class="col-md-1">
                                    <mat-icon>email</mat-icon>
                                </td>
                                <td class="col-md-2">{{ 'digital_newsletter' | transloco }}</td>
                                <td class="col-md-6">
                                    <span class="address-count" *ngIf="selectedAddressCount > 1">{{ selectedEmailCount }}</span>
                                    <span class="address-count" *ngIf="selectedAddressCount === 1">{{ selectedEmailCount }}</span>
                                    <span class="address-count" *ngIf="selectedAddressCount === 0">{{'reusable.no_address' | transloco}}</span>
                                </td>
                                <td class="col-md-3">
                                    <span matTooltip="Geen e-mail adressen gevonden" [matTooltipDisabled]="isExportingEmail || selectedEmailCount > 0">
                                        <button type="button" mat-stroked-button class="w-50" (click)="exportEmail()"
                                          [disabled]="isExportingEmail || selectedEmailCount === 0">
                                          <span *ngIf="isExportingEmail">Laden...</span>
                                    <span *ngIf="!isExportingEmail">Export e-mail</span>
                                    </button>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="bouwappEnable">
                                <td scope="row" class="col-md-1">
                                    <img src='assets/png/integrations/bouwappchat.png' class="bouwapp-chat">
                                </td>
                                <td class="col-md-2">{{ 'settings.bouwapp.bouwapp_update' | transloco }}</td>
                                <td class="col-md-6">
                                    <span *ngIf="!bouwappData">{{ 'settings.bouwapp.update_status' | transloco }}</span>
                                    <span *ngIf="bouwappData">{{ 'settings.bouwapp.scheduled_publish' | transloco }} {{bouwappData.publicationDate | formatdate: 'dd MMMM yyyy' : globals.timezone | capitaliz}}</span></td>
                                <td class="col-md-3">
                                    <div *ngIf="!bouwappData">
                                        <button type="button" [disabled]="!bouwappEnableError || !hasPermission(Permission.MiddelUpdate)" mat-stroked-button class="w-50" [ngClass]="{'error-set': !bouwappEnableError}" (click)="bouwAppPublishModal()">
                                            <span *ngIf="bouwappEnableError">{{ 'settings.bouwapp.create_update' | transloco | capitaliz}}</span>
                                            <span *ngIf="!bouwappEnableError">{{ 'error.bouwapp_integration' | transloco | capitaliz}}</span>
                                        </button>
                                    </div>
                                    <div *ngIf="bouwappData">
                                        <button type="button" [disabled]="!bouwappEnableError || !hasPermission(Permission.MiddelUpdate)" mat-stroked-button class="w-50" [ngClass]="{'error-set': !bouwappEnableError}" (click)="bouwAppPublishModal()">
                                            <span *ngIf="bouwappEnableError">Wijzig</span>
                                            <span *ngIf="!bouwappEnableError">{{ 'error.bouwapp_integration' | transloco | capitaliz}}</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>
        </form>
    </div>



</div>
