import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../globals';
import { AuthService } from '../service/auth/auth.service';

@Injectable()
export class RequestInterceptorService {

  // eslint-disable-next-line no-empty-function
  constructor(private globals: Globals, private auth: AuthService,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.globals.lastRequest = request;
    if (request.url.includes('api/login/identify')
      || request.url.includes('api/verify/token')
      || request.url.includes('api/reset/password/send')
      || request.url.includes('api/reset/password')
      || request.url.includes('api/user/two-factor-challenge')
      || request.url.includes('api/user/reset-two-factor-authentication')
    ) {
      request = request.clone({
      // url: `https://app.getdialog.dev${request.url}`,
      });
    }
    return next.handle(request);
  }
}
