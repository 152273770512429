import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { Globals } from 'src/app/globals';
import { GeneralService } from 'src/app/core/service/api/general.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { freshdeskPersonalIntegration, integrationError, integrationsNotificationToggle, userConfigsSetting } from 'src/app/core/util/integration';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { IntegrationsModalComponent } from 'src/app/core/util/integration/integrations-modal';
import { ProjectsService } from 'src/app/akita/project/state/projects.service';

@Component({
  selector: 'app-integration-modal-freshdesk-project',
  templateUrl: './integration-modal-freshdesk-project.component.html',
  styleUrls: ['./integration-modal-freshdesk-project.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationModalFreshdeskProjectComponent extends IntegrationsModalComponent implements OnInit {
  private serviceGroup: string = 'freshdesk';
  isProcess:boolean=false;
  domainValidate:boolean=false;
  validationTextShow:boolean=false;
  public integrationData={
    domain:'services.integration.freshdesk.domain',
    key:'services.integration.freshdesk.apikey'
  }
  userIdCompanyLevel:any;
  public personalData;
  freshdeskenable: any;
  errorMessage: any;
  freshdeskpersonalenable;
  personalerrorMessage;
  constructor(
    public projectDataGet:ProjectsService,
    private cd: ChangeDetectorRef,
    public pubsub: PubsubService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    public globals: Globals,
    public _projectConfigsService: ProjectConfigsService,
    private _generalService: GeneralService,
    private _integrationsServices:IntegrationsService) {
    super(globals,_projectConfigsService);
    this.freshdeskenable=this.globals.projectConfigs['services.integration.freshdesk.enabled'];
    this.errorMessage=this.globals.projectConfigs['services.integration.freshdesk.connection_error'];
  }

  ngOnInit(): void {
    switch(this.data.type){
      case 'personal':
        this.freshdeskpersonalenable=this.globals.user.configs ? this.globals.user.configs.freshdesk_enabled : 0;
        this.personalData=freshdeskPersonalIntegration(this.globals,'freshdesk');
        this.personalerrorMessage=integrationError(this.globals,'freshdesk');
        break;
      case 'company':
        this.projectDataGet.getProfileId().subscribe((response:any) => {
          this.userIdCompanyLevel=response.id.toString();
        });
        break;
      default:
        break;
    }
  }
  //close modal
  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }
  //submit configs
  public submit(): void{
    if(this.data.type=='project' || this.data.type=='company'){
      this.updateConfigProject();
    }else{
      this.updateConfigPersonal();
    }
  }
  //update configs for project level
  public updateConfigProject(): void{
    let enabled=this.freshdeskenable;
    enabled=!enabled;
    this.globals.projectConfigs['services.integration.freshdesk.enabled']=enabled;
    if(enabled){
      this.globals.projectConfigs['services.integration.freshdesk.user']=this.data.type == 'company' ? this.userIdCompanyLevel : this.globals.user.global_id.toString();
    }else{
      this.globals.projectConfigs['services.integration.freshdesk.user']='0';
      this.errorHandler();
    }
    this._projectConfigsService.update(this.serviceGroup, this.globals.projectConfigs)
    .pipe(
      finalize(()=>{
        if(enabled){
          this._projectConfigsService.getConfigsErrorMessage('freshdesk')
          .pipe(
            tap((res:any)=>{
              if(res.enabled && res.authorized) {
                this.errorHandler();
                this._integrationsServices.setProfile()
                .pipe(
                  finalize(()=>this.syncApi())
                ).subscribe()
              }
            })
          ).subscribe()
        }
        showFeedbackSaved();
        this.close();
      })).subscribe();
  }
  //update configs for personal level
  public updateConfigPersonal(): void{
    let enabled=this.freshdeskpersonalenable;
    enabled=!enabled;
    this.globals.user.configs.freshdesk_enabled=Number(enabled);
    this.globals.user.configs=userConfigsSetting(this.globals,this.personalData,'integration');
    if(this.globals.user.configs.config){
      if(this.globals.user.configs.config.connection_error){
        this.globals.user.configs.config.connection_error['freshdesk']=false;
      }
    }
    this.globals.user.configs=userConfigsSetting(this.globals,integrationsNotificationToggle(this.globals,'freshdesk_integration'),'notification');
    this._generalService.updateUserConfigs(this.globals.user.configs)
    .pipe(
      tap(()=>{
        this._integrationsServices.setProfile(`?user_id=${this.globals.user.id}`).subscribe();
      }),
      finalize(()=>this.close())
    )
    .subscribe();
    this.cd.detectChanges();
    showFeedbackSaved();
  }
  //error handler if error is resolved
  public errorHandler(): void{
    if(this.globals.projectConfigs['services.integration.freshdesk.connection_error']){
      this.globals.projectConfigs['services.integration.freshdesk.connection_error']=false;
    }
  }
  //Sync Api calling function
  public syncApi(): void{
    this._projectConfigsService.integrationSync(this.serviceGroup,true).subscribe();
    this.globals.user.configs=userConfigsSetting(this.globals,integrationsNotificationToggle(this.globals,'freshdesk_integration'),'notification');
    this._generalService.updateUserConfigs(this.globals.user.configs).subscribe();
  }
  //domain validation check
  public domainValidation(){
    this.domainValidate=false;
    this.validationTextShow=false;
    if(this.globals.projectConfigs[this.integrationData.domain]){
      this._integrationsServices.getDomainValidation(this.globals.projectConfigs[this.integrationData.domain])
      .pipe(
        tap((res:any)=>{
          if(!res.ok){
            this.domainValidate=true;
            this.validationTextShow=true;
            this.cd.detectChanges();
          }
        }),
        catchError((err)=>{
          this.domainValidate=true;
          this.validationTextShow=true;
          this.cd.detectChanges();
          return throwError(err);
        })
      ).subscribe();
    }
  }
}
