import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {CallbackComponent} from './features/callback/components/callback/callback.component';
import {AuthGuard} from './core/guards/auth.guard';
import { ArcGisRedirectComponent } from './features/callback/components/arc-gis-redirect/arc-gis-redirect.component';
import { CallbackPublicfacingApiComponent } from './features/callback/components/callback-publicfacing-api/callback-publicfacing-api.component';
import { OutlookRedirectComponent } from './features/callback/components/outlook-redirect/outlook-redirect.component';
import {AuthMfaGuard} from "./core/guards/auth-mfa.guard";


// Note: Order is important here!
const appRoutes: Routes = [

  { path: 'authenticated', component: CallbackComponent},
  { path: 'publicfacing', component: CallbackPublicfacingApiComponent},
  { path: 'instellingen/integraties', component: ArcGisRedirectComponent},
  { path: 'microsoft/instellingen/integraties', component: OutlookRedirectComponent},
  { path: 'connect', children: [
    { path: ':driver', component: ArcGisRedirectComponent },
  ]},
  // { path: 'connect/:driver', component: ProjectConfigCallbackComponent, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./features/main-app/auth/auth.module').then(m => m.AuthModule)},
  { path: 'beheer', loadChildren: () => import('./features/company-admin/company-admin.module').then(m => m.CompanyAdminModule), canActivate: [AuthGuard, AuthMfaGuard]},
  { path: 'admin', redirectTo: '/', pathMatch: 'full' },
  { path: 'app', redirectTo: '/', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./features/projects-overview/projects-overview.module').then(m => m.ProjectsOverviewModule), canActivate: [AuthGuard, AuthMfaGuard] },
  { path: ':code', loadChildren: () => import('./features/main-app/main-app.module').then(m => m.MainAppModule), canActivate: [AuthGuard, AuthMfaGuard]},
  { path: ':code/sub/:subproject', loadChildren: () => import('./features/main-app/main-app.module').then(m => m.MainAppModule), canActivate: [AuthGuard, AuthMfaGuard], data: {
      subProject: true,
    }},
  { path: '**', pathMatch: 'full',  redirectTo: '/' },

];


const config: ExtraOptions = {
  enableTracing: false,
};

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
