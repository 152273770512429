import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timezoneFormat'
})
export class TimezoneFormatPipe implements PipeTransform {

  // Accepts a dateTime object with its original timeZone and converts to the targetZone
  transform(value: { dateTime: string, timeZone: string }, targetZone: string, format: string = 'HH:mm'): string {
  // Ensure value has both dateTime and timeZone properties
  // Check if value, dateTime, and timeZone exist
  if (!value) {
    console.log('Value is missing');
    return '';
  }

  if (!value.dateTime) {
    console.log('dateTime is missing');
    return '';
  }

  if (!value.timeZone) {
    console.log('timeZone is missing');
    return '';
  }
  // Convert the ISO date to Luxon's DateTime using the source timeZone
  const date = DateTime.fromISO(value.dateTime, { zone: value.timeZone });

  // Convert the date to the target timezone (e.g., Europe/Amsterdam) and format it
  return date.setZone(targetZone).toFormat(format);
  }
}
