import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SidebarEmployees } from "../../../pubsub/sidebarEmployees";
import { GenericComponent } from "../../../core/util/abstract/generic-component";
import { EmployeesService } from "../../../core/service/api/employees.service";
import { StakeholdersService } from "../../../core/service/api/stakeholders.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ngModelCopy } from "../../../core/util/ngModelCopy";
import { Stakeholder } from '../../../akita/stakeholders/state/stakeholder.model';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { scrollToError } from '../../../core/util/form';


@Component({
  selector: 'app-sidebar-employees',
  templateUrl: './sidebar-employees.component.html',
  styleUrls: ['./sidebar-employees.component.sass']
})
export class SidebarEmployeesComponent extends GenericComponent implements OnInit {

  public temporaryData: SidebarEmployees;
  public processing: boolean = false;
  public setStakeholders = {};
  @ViewChild(NgForm) form: NgForm;

  constructor(private _employeesService: EmployeesService, private _stakeholdersService: StakeholdersService,
    @Inject(MAT_DIALOG_DATA) public data: SidebarEmployees, private dialogRef: MatDialogRef<SidebarEmployeesComponent>) {
    super();
  }

  ngOnInit(): void {
    this.data.emails.registered = this.data.emails.registered.map(this.inserLastName);
    this.data.emails.unregistered = this.data.emails.unregistered.map(this.inserLastName);
    this.temporaryData = this.data.clone();
  }

  private inserLastName(e: any): any {
    if (e && e.name) {
      e.last_name = e.name;
    }

    return e;
  }


  public submit(): void {
    this.temporaryData.emails.unregistered = this.temporaryData.emails.unregistered.map(email => {
      if (this.setStakeholders[email.address]) {
        email.stakeholder = this.setStakeholders[email.address];
      }

      return email;
    });

    // Validate the form, check if mandatory last_name is filled in
    const invalidEmployees = this.temporaryData.emails.unregistered
      .filter(email => email.stakeholder && (!email.last_name || email.last_name.length === 0));

    if (invalidEmployees && invalidEmployees.length > 0) {
      scrollToError(this.form);
      return;
    }

    this.assignEmployeeStakeholders();
  }

  public setEmailStakeholder(stakeholder: Stakeholder[], email: any): void {
    if (stakeholder) {
      this.setStakeholders[email.address] = stakeholder;
      // this.processing = false;
    } else {
      delete this.setStakeholders[email];
      // this.processing = true;
    }
  }

  private assignEmployeeStakeholders(): void {

    const employees = this.temporaryData.emails.unregistered
      .map(employee => {
        return {
          last_name: employee.last_name,
          first_name: employee.first_name,
          email: employee.address,
          stakeholder: this.setStakeholders[employee.address]? this.setStakeholders[employee.address]: []
        }
      });
    // Don't submit if no employees to be submitted
    if (employees.length === 0) {
      this.close(false);
      return;
    }

    this.processing = true;
    this._employeesService.submitEmployees(employees)
      .pipe(finalize(() => this.processing = false))
      .subscribe(
        (employees: any[]) => {
          // Move showEmployeeSidebar employees that have stakeholders assigned to registered
          this.temporaryData.emails.registered = this.temporaryData.emails.unregistered;
          this.temporaryData.emails.unregistered = [];
          employees.forEach(e => {
            e.selected = e.stakeholders;
          });
          // Update the main ngModel variable
          ngModelCopy(this.data, this.temporaryData);
          this.close(true, employees.filter(employee => !!employee.stakeholders));
        });
  }

  public close(isUpdated: boolean = false, responseEmployeeCreation: any[] = []): void {
    if (!isUpdated) {
      this.dialogRef.close();
    } else {
      const stakeholders = []
      this.temporaryData.emails.registered.forEach(e => {
        if (e.stakeholder && e.stakeholder.length > 0){
          e.stakeholder.forEach(s => {
            stakeholders.push(s)
          });
        }
      });
      this.dialogRef.close({
        sidebar: this.temporaryData,
        stakeholders: stakeholders,
        employees: responseEmployeeCreation
      });
    }
  }

  public updateLastName(event: Event, email): void {
    const target: any = event.currentTarget;
    const foundEmail = this.temporaryData.emails.unregistered.find(e => email.address === e.address);
    if (foundEmail) {
      foundEmail.name = target.value;
      foundEmail.last_name = target.value;
    }
  }

  public updateFirstName(event: Event, email): void {
    const target: any = event.currentTarget;
    const foundEmail = this.temporaryData.emails.unregistered.find(e => email.address === e.address);
    if (foundEmail) {
      foundEmail.first_name = target.value;
    }
  }

}
