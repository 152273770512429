import {Directive, Input, OnDestroy} from '@angular/core';
import {RouterLinkWithHref} from '@angular/router';
import {AppInjector} from '../../../app-injector';
import {Globals} from '../../globals';
import {SubprojectQuery} from '../../akita/subproject/state/subprojects.query';
import {untilDestroyed} from 'ngx-take-until-destroy';

@Directive({
  selector: 'a[projectRouterLink],area[projectRouterLink]'
})
export class ProjectRouterLinkDirective extends RouterLinkWithHref implements OnDestroy {
  @Input() declare queryParams;

  private globals: Globals = AppInjector ? AppInjector.get(Globals) : null;
  private subProjectsQuery: SubprojectQuery = AppInjector ? AppInjector.get(SubprojectQuery) : null;

  @Input()
  set projectRouterLink(commands: any[]|string) {
    this.subProjectsQuery.selectActive()
      .pipe(untilDestroyed(this))
      .subscribe(activeSubProject => {
      let passedCommand;
      if (activeSubProject) {
        if (Array.isArray(commands)) {
          passedCommand = ['/', this.globals.projectCode, 'sub', activeSubProject.code, ...commands];
        } else {
          passedCommand = `/${this.globals.projectCode}/sub/${activeSubProject.code}/${commands}`;
        }
      } else {
        if (Array.isArray(commands)) {
          passedCommand = ['/', this.globals.projectCode, ...commands];
        } else {
          passedCommand = `/${this.globals.projectCode}/${commands}`;
        }
      }

      this.routerLink = passedCommand;
    });
  }
}
