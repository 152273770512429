
<button class="close modal-header" type="button" mat-dialog-close>
    <span style="font-size: 35px">×</span>
    <span class="sr-only">Close</span>
</button>
<h3 class="modal-title">{{ 'create_dialog_items.title' | transloco | capitaliz }}</h3>

<form #form="ngForm" (submit)="create()" *transloco="let t">
    <p style="font-weight: bold;">{{ 'create_dialog_items.sub_title' | transloco | capitaliz }}</p>
    <mat-dialog-content>
        <mat-form-field class="full-width">
            <mat-select [placeholder]="'create_dialog_items.dialog_item_type' | transloco" [(value)]="selectedItemType" (selectionChange)="updateSelectedItem()" required>
                <ng-container *ngFor="let report of reports">
                    <mat-option [value]="report.type">
                        {{ (report.type + '.labels' | transloco) | capitaliz }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <div>
            <p *ngIf="attachInfo" style="font-weight: bold;">{{ attachInfo }}</p>
            <div *ngFor="let item of data.layers">
                <p style="margin-bottom: 3px; color: #2800D2;">{{item.title}}</p>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class="pull-right">
      <button *ngIf="!isProcessing" mat-dialog-close mat-stroked-button>{{ 'cancel' | transloco | capitaliz }}</button>
      <button [disabled]="isProcessing || form.invalid || !selectedItemType" color="primary" mat-flat-button style="margin-left: 13px" type="submit">
        <ng-container *ngIf="!isProcessing">
          {{ 'create_dialog_items.create' | transloco }}
        </ng-container>
        <ng-container *ngIf="isProcessing">
          {{ 'create_dialog_items.create' | transloco }}...
        </ng-container>
      </button>
    </mat-dialog-actions>
</form>
