<ng-container *ngIf="{subProject: subProject$ | async, subProjects: subProjects$ | async} as data">
    <ul class="nav navbar-nav gd-desktop-screen">
        <li role="presentation" class="dropdown">
            <div class="selector-dropdown d-cursor-hand pd-7" matTooltipClass="custom-tooltip" data-toggle="dropdown" id="subprojects" matTooltip="{{'subprojects.select' | transloco | capitaliz}}" aria-haspopup="true" aria-expanded="true">
                <span class="selector-label ml-15">{{ 'text.select_project' | transloco }}</span>
                <ng-container *ngIf="data.subProject">
                    <!--<mat-icon [style.color]="data.subProject.color">texture</mat-icon> <span class="current-sub-project ml-5">{{ data.subProject.name }}</span>-->
                    <ngx-avatars class="sub-project-avatar" [style]="AvatarCustomStyleSelector" [name]="data.subProject.name" initialsSize="2" size="20" [borderColor]="data.subProject.color" fgColor="#000000" bgColor="#FFFFFF">
                    </ngx-avatars>
                    <span class="current-sub-project ml-15 mt-4">{{ data.subProject.name | truncatie }}</span>
                </ng-container>
                <ng-container *ngIf="!data.subProject && globals.project">
                    <span class="current-sub-project ml-15 mt-4">{{ globals.project.name | truncatie }}</span>
                </ng-container>
                <span class="uil uil-arrow-down pull-right d-text-middle icon-style"></span>
            </div>
            <ul class="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="subprojects">
                <li>
                    <span class="pull-left d-cursor">
            <strong>{{ 'text.select_project' | transloco }}</strong>
          </span>
                </li>
                <li class="subproject-selector-list-item" *ngIf="globals.user.scope.level!='subproject'">
                    <span class="project-selector-list-item-title" matTooltipClass="custom-tooltip" (click)="switchSubProject(null)" [matTooltip]="globals.project.name">{{ globals.project.name  | truncatie }}</span>
                    <mat-radio-group name="subproject-selector-dropdown-project-radio" class="pull-right flex-end pt-7">
                        <span *ngIf="!data.subProject && globals.project" matTooltip="{{ 'subprojects.current' | transloco | capitaliz }}">
              <mat-icon class="radio-button checked d-cursor-hand" (click)="switchSubProject(null)">radio_button_checked
              </mat-icon>
            </span>
                        <span *ngIf="data.subProject && globals.project" matTooltip="{{ 'subprojects.select' | transloco | capitaliz }}">
              <mat-icon class="radio-button d-cursor-hand" (click)="switchSubProject(null)">radio_button_unchecked
              </mat-icon>
            </span>
                    </mat-radio-group>
                </li>
                <!-- <div *ngIf="globals.user.scope.level!='subproject'">

                </div> -->
                <li class="subproject-selector-list-item" *ngFor="let subProject of data.subProjects">
                    <ngx-avatars [style]="AvatarCustomStyle" [name]="subProject.name" initialsSize="2" size="30" [borderColor]="subProject.color" fgColor="#000000" bgColor="#FFFFFF">
                    </ngx-avatars>
                    <span class="flex-start subproject-selector-list-item-title" (click)="switchSubProject(subProject.id)" [matTooltip]="subProject.name">{{ subProject.name | truncatie: 20 }}</span>
                    <mat-icon *hasPermission="[Permission.SubprojectUpdate]" class="d-cursor-hand sub-project-edit flex-end pt-7 mr-10" (click)="edit(subProject)" matTooltip="{{ ('subprojects.edit' | transloco) + ' ' + (this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label_small' | transloco) }}">edit</mat-icon>
                    <mat-radio-group name="subproject-selector-dropdown-subproject-radio" class="pt-7">
                        <span *ngIf="subProject === data.subProject" matTooltip="{{ 'subprojects.current' | transloco | capitaliz }}">
              <mat-icon class="radio-button checked d-cursor-hand" (click)="switchSubProject(subProject.id)">
                radio_button_checked</mat-icon>
            </span>
                        <span *ngIf="subProject != data.subProject" matTooltip="{{ 'subprojects.select' | transloco | capitaliz }}">
              <mat-icon class="radio-button d-cursor-hand" (click)="switchSubProject(subProject.id)">
                radio_button_unchecked</mat-icon>
            </span>
                    </mat-radio-group>
                </li>
                <li>
                    <button *hasPermission="[Permission.SubprojectCreate]" class="new-subproject new-subproject-button mat-stroked-button mat-button-base" (click)="create()">
            <mat-icon class="d-text-middle uil uil-plus-circle unicon-icon notranslate material-icons new-subproject-icon"></mat-icon>
            <span>{{ this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label-1' | transloco }}</span>
          </button>
                </li>
            </ul>
        </li>
    </ul>
</ng-container>
