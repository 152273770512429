import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.sass']
})
export class MessageModalComponent {
  confirm: boolean;
  constructor(public dialogRef: MatDialogRef<MessageModalComponent>,
              // eslint-disable-next-line no-empty-function
              @Inject(MAT_DIALOG_DATA) public text: string) { }

  submit(d) {
    this.dialogRef.close(d);
  }
}
