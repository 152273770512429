import { Injectable } from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Project} from './project.model';

export interface ProjectState extends EntityState<Project>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'projects' })
export class ProjectsStore extends EntityStore<ProjectState, Project> {

  constructor() {
    super();
  }

  public set(projects: Project[]): void {
    projects = projects.map(
      project => {
        return {
          ...project,
          id: project.code,
        };
      }
    );
    super.set(projects);
  }

  public add(projects: Project[]): void {
    projects = projects.map(
      project => {
        return {
          ...project,
          id: project.code,
        };
      }
    );
    super.add(projects);
  }

}

