import { Component, ChangeDetectionStrategy, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { BatchOverviewSettingComponent } from '../batch-overview-setting/batch-overview-setting.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { MapService } from 'src/app/core/service/api/map.service';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { catchError, tap } from "rxjs/operators";
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-batch-attach-items',
  templateUrl: './batch-attach-items.component.html',
  styleUrls: ['./batch-attach-items.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchAttachItemsComponent implements OnInit {

  public reports = [
    {type: 'stakeholders'},
    {type: 'issues'},
    {type: 'activities'},
    {type: 'complaints'},
    {type: 'requirements'},
  ];

  public isProcessing: boolean = false;
  public stakeholders: any;
  public dialogItems: any = [];
  public selectedItemType: string = '';
  public isLoadingLayers: boolean = false;

  constructor(public dialogRef: MatDialogRef<BatchOverviewSettingComponent>,
    // eslint-disable-next-line no-empty-function
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cachedService: CachedService,
    private _mapService: MapService,
    private _configsService: ConfigsService,
    private layerConnection: ConfigsService,
    private cd: ChangeDetectorRef
    ) {
      this.cachedService.getStakeholdersRelations().subscribe(
        stakeholders => this.stakeholders = stakeholders
      );
  }

  ngOnInit() {
    if (this.data && this.data.layers) {
      this.isLoadingLayers = true; // Set flag to true before requests start
    
      const requests = this.data.layers.map((item, index) => {
        const layer = { layers: item };
        return this.layerConnection.getLayerConection(layer).pipe(
          tap((response: any) => {
            if (!response.message){
              this.data.layers[index] = response;
            } else {
              this.data.layers[index] = {...item, stakeholders: [], requirements: [], issues: [], complaints: [], activities:[], permits: []}
            }
          }),
          catchError((err) => {
            this.cd.detectChanges();
            throw err; // Ensure error propagates
          })
        );
      });
    
      // Execute all requests and wait for them to complete
      forkJoin(requests).subscribe({
        next: () => {
          this.isLoadingLayers = false; // Set flag to false after all requests complete
          this.cd.detectChanges(); // Update UI if necessary
        },
        error: () => {
          this.isLoadingLayers = false; // Ensure flag is reset even if an error occurs
        }
      });
    }
  }


  public updateSelectedItem() {

    this.data.dialogItems = [];
    switch (this.selectedItemType) {
      case 'stakeholders':
        this.cachedService.getStakeholdersRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'requirements':
        this.cachedService.getDecisionsRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'complaints':
        this.cachedService.getComplaintsRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'issues':
        this.cachedService.getIssuesRelations().subscribe(
          items => this.dialogItems = items
        );
        break;
      case 'activities':
        this.cachedService.getActivitiesRelations().subscribe(
          (items: any) => this.dialogItems = items.data
        );
        break;
      default:
        this.dialogItems = [];
        
    }
  }

  public submit(){
    this.isProcessing = true;
    this.data.layers.forEach(item => {
      item['type'] = this._mapService.mainLayers.values_.layer_type;
      item['main_layer_id'] = this._mapService.mainLayerId ;
      item['sub_layer_id'] = 0;
      item['sub_layer_name'] = this._mapService.mainLayers.values_.name;
    })
    this.data[this.selectedItemType] = [...this.data.dialogItems];
    delete this.data.dialogItems;

    this._configsService.batchLayerConection(this.data).pipe(
      tap(() => {
        this.isProcessing = false;
        showFeedbackSaved();
        this.cd.detectChanges();
      }),
      catchError((err) => {
        this.isProcessing = false;
        this.cd.detectChanges();
        throw err;
      })
    ).subscribe(() => {
      this.dialogRef.close();
    });
    
  }

}
