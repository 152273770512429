<div mat-dialog-title>
  <h4 class="mb-10 inline-block">{{ 'new_e' | transloco | capitaliz }} {{'middel.label' | transloco }}</h4>
  <button (click)="resetForm()" aria-label="Close" class="close modal-close-button" type="button">
    <span aria-hidden="true"><i class="d-text-middle uil uil-multiply unicon-icon"></i></span><span class="sr-only"></span>
  </button>
</div>

<mat-dialog-content *ngIf="middelForm && ruleForm">
  <div class="col-xs-12 col-sm-12 col-md-6">
    <form class="form-inline" [formGroup]="middelForm" (ngSubmit)="createEvent()">
      <div class="col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>{{ 'reusable.middel_type' | transloco }}</mat-label>
          <input matInput formControlName="type" required ngDefaultControl>
        </mat-form-field>
      </div>
      <div class="col-xs-12">
        <mat-form-field class="full-width">
          <mat-label>{{ 'reusable.target_group' | transloco }}</mat-label>
          <input matInput formControlName="group" required ngDefaultControl>
        </mat-form-field>
      </div>

      <div class="col-xs-12">
        <mat-form-field class="full-width" required>
          <mat-label>{{'reusable.associate_asset_activity' | transloco}}</mat-label>
          <mat-select [compareWith]="compareFunction" formControlName="activities" (selectionChange)="selectActivity($event)" ngDefaultControl multiple>
            <mat-option class="associate-activity" *ngFor="let list of activityList" [value]="list">
              {{ list.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-xs-12">
        <app-multiselect-users
          (onSelectedUsersUpdated)="selectUser($event)"
          [multipleUsers]="false"
          [placeholder]="'reusable.select_users' | transloco"
          [users]="users">
        </app-multiselect-users>
      </div>

    </form>

    <label>{{'reusable.dispatch_date' | transloco}}</label>
    <mat-radio-group [(ngModel)]="checkType" [ngModelOptions]="{standalone: true}" class="gd-auto-horizontal mt-10 mb-5" ngDefaultControl>
      <mat-radio-button *ngFor="let option of sendOptions" [value]="option.value" class="mr-5">
        {{ option.title | capitaliz}}
      </mat-radio-button>
      <mat-radio-button [disabled]="!selectedActivity" [matTooltipDisabled]="selectedActivity" matTooltip="Koppel eerst een activiteit" value="activity">
        {{'reusable.based_activity_date' | transloco}}
      </mat-radio-button>
    </mat-radio-group>



  <div *ngIf="checkType === 'fixed'">

      <div class="panel panel-default">
        <div class="panel-body">

          <div class="form-group gd-auto-horizontal">

            <mat-form-field class="full-width">
              <mat-label>{{ 'reusable.date' | transloco }}</mat-label>
              <input (click)="middelFixedDate.open()"
                    (dateChange)="addEvent($event)"
                    [(ngModel)]="fixedDateMiddel"
                    [matDatepicker]="middelFixedDate"
                    [min]="today"
                    [ngModelOptions]="{standalone: true}"
                    matInput
                    ngDefaultControl>
              <mat-datepicker-toggle [for]="middelFixedDate" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #middelFixedDate></mat-datepicker>
            </mat-form-field>

          </div>

        </div>
      </div>

    </div>

    <div *ngIf="checkType === 'recurring'">

      <div class="panel panel-default">
        <div class="panel-body">
          <form [formGroup]="ruleForm">

            <div class="form-group gd-auto-horizontal">

              <mat-form-field class="col-xs-12">
                <mat-label class="gd-label">{{'reusable.every' | transloco}}</mat-label>
                <input (input)="getRuleDate()" formControlName="interval" matInput min="1" type="number"ngDefaultControl>
              </mat-form-field>

              <mat-form-field class="col-xs-12">
                <mat-label>{{'reusable.ferequency' | transloco}}</mat-label>
                <mat-select (selectionChange)="getRuleDate()" formControlName="freq" ngDefualtControl>
                  <mat-option *ngFor="let freqList of frequencyList" [value]="freqList.set">
                    {{ freqList.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <mat-form-field class="col-xs-12">
              <mat-label>{{'reusable.on_weekday' | transloco | capitaliz}}</mat-label>
              <mat-select (selectionChange)="getRuleDate()" formControlName="byweekday" multiple>
                <mat-option *ngFor="let weekday of weekdayList" [value]="weekday.value">
                  {{ weekday.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="form-group gd-auto-horizontal">

              <mat-form-field class="col-xs-6">
                <mat-label>{{'reusable.startend_on' | transloco | capitaliz}}</mat-label>
                <input (click)="pickerStart.open()" (dateChange)="getRuleDate()" [matDatepicker]="pickerStart" [min]="today" formControlName="dtstart" matInput>
                <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="col-xs-6">
                <mat-label>{{'reusable.ending_on' | transloco | capitaliz}}</mat-label>
                <input (click)="pickerStart.open()" (dateChange)="getRuleDate()" [matDatepicker]="pickerEnd" [min]="dateMinRule" formControlName="until" matInput>
                <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>

            </div>

          </form>

          <div class="mat-form-field-infix full-width">
            <mat-chip-grid aria-orientation="horizontal" class="mat-chip-list full-width" role="listbox">
              <div *ngFor="let list of rule" class="mat-chip-list-wrapper" class="gd-chips-container">
                <mat-chip-row (removed)="deleteCalendar(list)">
                  {{ list | formatdate: 'dd-MM-yyyy':globals.timezone }}
                  <mat-icon matChipRemove>
                    cancel
                  </mat-icon>
                </mat-chip-row>
              </div>
            </mat-chip-grid>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="checkType === 'activity' && selectedActivity" >

      <div class="panel panel-default">

        <div class="panel-body">
          <div>
            <mat-form-field class="full-width" required>
              <mat-label>{{'reusable.associate_activity_based_on_date' | transloco}}</mat-label>
              <mat-select [compareWith]="compareFunction" [(ngModel)]="activityBasedOnDate" (selectionChange)="getActivityData($event)" ngDefaultControl>

                <mat-option *ngFor="let list of selectedActivity; trackBy: trackByFn" [value]="list">
                  {{ list.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="full-width">
              <mat-label>Wanneer wordt het middel verzonden</mat-label>
              <mat-select (selectionChange)="generateMiddelDate($event.value)" [(value)]="summaryDate" value="1">
                <mat-option *ngFor="let option of activityBasedDateOptions" [value]="option.value">
                  {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <p>
              Activiteit <i>"{{ activityBasedOnDate.calendar.metadata.title }}"</i> &nbsp;begint op <b>{{ selectedActivityDate | formatdate : 'dd-MM-yyyy' }}</b>.
            </p>
            <p>
              Het nieuwe middel wordt op <b>{{ middelDateShow | formatdate : 'dd-MM-yyyy' }}</b> verzonden.
            </p>
          </div>

        </div>

      </div>

    </div>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-6">
    <div>
      <label>Verzendgebied</label>
      <app-openlayers-map
        [includeSidebar]="false"
        [collapseSidebar]="true"
        [defaultCoordinate]="globals.projectConfig.default_map"
        [showMapReset]="true"
        [maxArea]="25"
        (ngModelChange)="updateSelectedCoordinates($event)"
        (maxAreaExceeded)="showMaxAreaError()"
        [(ngModel)]="location"
        name="locations"
        locatableType="calendars"
        defaultDrawTool="area"
        clickStartDrawingMsg="Teken verzendgebied(en)"
        type="middel"
        [bookmarkOptions]="['add']"
        ngDefaultControl
      ></app-openlayers-map>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-6">
    <div>
      <span *ngIf="selectedAddressCount === 0">{{'reusable.no_address' | transloco}}.</span>
      <span *ngIf="selectedAddressCount > 0">U heeft {{ selectedAddressCount }} adress(en) geselecteerd.</span>
    </div>
  </div>
</mat-dialog-content><!--end of modal body-->

<mat-dialog-actions align="end">
  <div class="dialog-button-row">
    <button *ngIf="showPreviousStep" (click)="resetForm()" mat-stroked-button type="button">
      {{'previous_step' | transloco}}
    </button>
    <button type="button" mat-stroked-button data-dismiss="modal" (click)="resetForm()">{{'close' | transloco | capitaliz}}</button>
    <button (click)="createEvent()" mat-flat-button type="button" [disabled]="!middelForm.valid">
      <mat-icon class="material-icons d-text-middle">save</mat-icon>
      {{ 'add' | transloco | capitaliz }}
    </button>
  </div>
</mat-dialog-actions>
