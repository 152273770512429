import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mat-datepicker-time-header',
  templateUrl: './mat-datepicker-time-header.component.html',
  styleUrls: ['./mat-datepicker-time-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatDatepickerTimeHeaderComponent {
  @Input() tooltip: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;
  @Input() matFormFieldWidth: string;
  private _selectedDateTime: Date;

  @Input()
  get selectedDateTime(): Date {
    return this._selectedDateTime;
  }
  set selectedDateTime(value: Date) {
    this._selectedDateTime = value;
  }

  @Output() selectedDateTimeChange = new EventEmitter<Date>();

  onDateTimeChange(event: any): void {
    this.selectedDateTime = event.value;
    this.selectedDateTimeChange.emit(this.selectedDateTime);
  }
}
