import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Language, createPersonalisationMetadata, defaultmultilingualProvider, multilingualProvider } from 'src/app/akita/multilingual/multilingual.model';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-profile-tab',
  templateUrl: './profile-tab.component.html',
  styleUrls: ['./profile-tab.component.sass'],
})
export class ProfileTabComponent implements OnInit {
  @Input() public twoFactorStatus: boolean = false;
  @Output() public towFactorAuthenticationModal: EventEmitter<any> = new EventEmitter();
  @Output() public changePasswordModal: EventEmitter<any> = new EventEmitter();
  public name:string;
  public randomNumber:string='';
  public language: Language[];
  public multilingual : Language[];

  trustedBrowsers = [];
  isProcessing: boolean = false;

  // Define columns to display
  displayedColumns: string[] = ['browser', 'timestamp', 'action'];
  // eslint-disable-next-line no-empty-function
  constructor(public globals: Globals,private readonly translocoService: TranslocoService,private _configsService: ProjectConfigsService,private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.getUserInfo();
    this.loadMultilingual();
    this.getTrustedBrowsers();
  }
  // get trusted browsers
  public getTrustedBrowsers() {
    this.isProcessing = true;
    this._configsService.getTrustedBrowsers()
    .pipe(
      tap((res: any[]) => {
        this.trustedBrowsers = res;
        this.isProcessing = false;
        this.cd.detectChanges();
      }),
      catchError(async (err) => {
        this.isProcessing = false;
        this.cd.detectChanges();
        console.error(err);
      })
    ).subscribe();
  }
  
  //get user info
  public getUserInfo(): void{
    this._configsService.getUserName(this.globals.user.global_id)
    .pipe(
      tap((res:any)=>{
        this.name=res.name;
        this.randomNumber=res.apikey;
        this.cd.detectChanges();
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //load languages
  public loadMultilingual(): void{
    this.language=defaultmultilingualProvider(this.globals);
    this.multilingual=multilingualProvider();
  }
  //on change the language
  public onChangeMultilingual(): void{
    if(this.language.length){
      if(this.globals.user.personalization){
        this.submitLanguage();
      }else{
        this.getUserPersonalisationID();
      }
      this.translocoService.setActiveLang(this.language[0].code);
    }
  }
  //get personalisation id
  public getUserPersonalisationID(): void{
    this._configsService.getPersonalisation(createPersonalisationMetadata('lang',this.language[0],this.globals.user.personalization))
    .pipe(
      tap(res=>{
        this._configsService.attachUserPersonalisation({'personalization_id':res.id},this.globals.user.global_id).subscribe();
        showFeedbackSaved();
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //set language
  public submitLanguage(): void{
    this._configsService.savePersonalisation(createPersonalisationMetadata('lang',this.language[0],this.globals.user.personalization),this.globals.user.personalization.id)
    .pipe(
      tap(res=>{
        this.globals.user.personalization=res;
        showFeedbackSaved();
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //on click tw0 factor authentication modal
  public onClickTowFactorAuthenticationModal(): void{
    this.towFactorAuthenticationModal.emit();
  }
  //on click change password modal
  public onClickChangePasswordModal(): void{
    this.changePasswordModal.emit();
  }
  //on submit user email
  public submit(): void{
    this._configsService.attachUserPersonalisation({name:this.name, apikey: this.randomNumber},this.globals.user.global_id)
    .pipe(
      tap(res=>{
        showFeedbackSaved();
        this.globals.user.name=this.name;
      }),
      catchError(async (err) => bugsnagFactory().notify(err))
    ).subscribe();
  }
  //generate random key
  public generateRandomNumber():void{
    const length = 32;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.randomNumber=retVal;
    this.submit();
  }

  removeBrowser(browser: any): void {
    this._configsService.deleteTrustedBrowser(browser.id)
    .pipe(
      tap((res: any[]) => {
        this.trustedBrowsers = this.trustedBrowsers.filter(
          (item) => item !== browser
        );
        this.cd.detectChanges();
      }),
      catchError(async (err) => {
        this.cd.detectChanges();
        console.error(err);
      })
    ).subscribe();
    console.log(`Removed: ${browser.identification}`);
  }
}
