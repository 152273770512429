import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DecisionState, DecisionsStore } from './decisions.store';
import { Decision } from './decision.model';

@Injectable({ providedIn: 'root' })
export class DecisionsQuery extends QueryEntity<DecisionState, Decision> {
  constructor(protected store: DecisionsStore) {
    super(store);
  }

  add(decision: Decision): void {
    this.store.add(decision);
  }
}
