<ng-container *ngIf="!typeCal">
    <div class="modal-header">
        <button (click)="close()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">
          <i class="uil uil-multiply unicon-icon"></i>
      </span>
    </button>
        <h4 class="modal-title">{{ 'new_e' | transloco | capitaliz }} {{'menu.analysis' | transloco | lowercase}} item</h4>
    </div>
    
    <!-- FORM SWITCH -->
    <div class="modal-body">
        <div class="gd-switch-event">
            <div class="col-xs-12 col-sm-12 col-md-7">
                <button type="button" class="btn btn-primary btn-lg gd-button-create gd-activity" (click)="switchEvent('stakeholder')" [disabled]="!hasPermission(Permission.StakeholdersCreate)">{{ 'new' | transloco | capitaliz }} {{ 'Stakeholder' | transloco | capitaliz}}</button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-5">
                <button type="button" class="btn btn-primary btn-lg gd-button-create gd-middel" (click)="switchEvent('issue')" [disabled]="!hasPermission(Permission.IssuesCreate)">{{ 'new' | transloco | capitaliz }} {{ 'Issue' | transloco | capitaliz}}</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="typeCal">
    <app-stakeholder-new *ngIf="typeCal === 'stakeholder'" (create)="createEvent($event)" (formReset)="resetForm()">

    </app-stakeholder-new>
    <app-issue-new *ngIf="typeCal === 'issue'" (create)="createEvent($event)" (formReset)="resetForm()">

    </app-issue-new>
</ng-container>