import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import collect from 'collect.js';
import Map from "ol/Map";
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { setSubLayersResponseParameters, setSubprojectsFeatureResponse } from 'src/app/akita/subproject/state/subproject.model';
import { MapService } from 'src/app/core/service/api/map.service';
import { ArcGis, arcgisTypeDefine } from 'src/app/core/util/integration';
import { createMultiPolygon, getRegExpURL } from "src/app/core/util/mapModal";
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-select-map-features',
  templateUrl: './select-map-features.component.html',
  styleUrls: ['./select-map-features.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMapFeaturesComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() new: boolean = false;
  @Input() selectedFeature: any = [];
  @Input() featureType!:string;
  @Input() integrationType!:string;
  @Output() selectFeatures = new EventEmitter<any>();
  @Output() layersFeatures = new EventEmitter<any>();
  @Output() selectLayers = new EventEmitter<any>();
  mapFeatures = [];
  selectedFeatures: any = [];
  public map: Map;
  public arcGisLayers: any[] = [];
  public activeArcGis: string = 'arcgis';
  public enableLayers: any[] = [];
  public geojsonArcGisType:string='pgeojson';
  public layerLoadInterval:any[]=[];
  public isLoader: boolean = true;
  public isFeatureLoader: boolean = true;
  public layers: any = {
    arcGis: [],
    openData: []
  }
  public arcGisToken: string;
  public arcGis: ArcGis = {
    mainLayers: [],
    subLayers: [],
    features: [],
  };
  public arcGisSelect: ArcGis = {
    mainLayers: [],
    subLayers: [],
    features: [],
  };
  public BBOX:any[]=[];
  public features: any[] = [];
  // eslint-disable-next-line no-empty-function
  constructor(public _projectConfigsService: ProjectConfigsService, public globals: Globals, public cd: ChangeDetectorRef, private _mapServices: MapService) { }

  ngOnInit(): void {
    if(this.globals.projectConfig.polygons){
      this.BBOX=createMultiPolygon(this.globals.projectConfig).getGeometry().getExtent();
    }
    this.activeArcGis=arcgisTypeDefine(this.globals);
    this.onLoadLayers();
  }
  /*
    On Load Features Layers
  */
  public onLoadLayers(): void {
    this.arcGis.mainLayers = [];
    switch(this.featureType){
      case 'arcgis': {
        combineLatest([
          this._mapServices.getIntegrationLayers(this.activeArcGis),
          this._projectConfigsService.getFreshToken(this.activeArcGis, this.integrationType === 'project' ? false : true),
        ])
        .pipe(
          tap(async ([arcGis, token]: any) => {
            this.arcGisToken = token.token;
            await this.checkLayerType(arcGis);
            if(this.selectedFeature.length) {
              const commonObjects = arcGis.filter(obj1 => this.selectedFeature.some(obj2 => obj1.id === obj2.main_layer_id));
              this.arcGisSelect.mainLayers = commonObjects;
              this.getArcGisSubLayers();
            }
            this.isLoader = false;
            this.cd.detectChanges();
          })
        ).subscribe();
        break;
      }
      case 'opendata': {
        combineLatest([
          this._mapServices.getOpenDataEnableLayers()
        ])
        .pipe(
          tap(async ([openData]: any) => {
            this.layers.openData = openData;
            this.isLoader = false;
            await this.getOpenDataLayersFeatures();
            this.cd.detectChanges();
          })
        ).subscribe();
        break;
      }
    }
  }
  /*
    ArcGis Layers Into Sub Layers
  */
  public async getArcGisSubLayers(): Promise<void> {
    this.arcGis.subLayers = [];
    if(this.arcGisSelect.mainLayers.length){
      this.arcGisSelect.mainLayers.forEach(layer => {
        this._mapServices.getArcGisSubLayers(layer.url, this.arcGisToken)
        .pipe(
          tap(async (res: any) => {
            await this.arcGis.subLayers.push(...setSubLayersResponseParameters(layer, res));
            if(this.arcGisSelect.subLayers.length) {
              setTimeout(() => {
                const commonObjects = this.arcGis.subLayers.filter(obj1 => this.arcGisSelect.subLayers.some(obj2 => obj1.layer_id === obj2.layer_id && obj1.layer_name === obj2.layer_name));
                this.arcGisSelect.subLayers = commonObjects;
                this.cd.detectChanges();
              }, 1000);
            }
            if(this.selectedFeature.length) {
              const commonObjects = this.arcGis.subLayers.filter(obj1 => this.selectedFeature.some(obj2 => obj1.layer_id === obj2.sub_layer_id));
              this.arcGisSelect.subLayers = commonObjects;
              this.getSubLayersFeatures();
            }
            this.cd.detectChanges();
          })
        ).subscribe();
      });
    }
  }
  /*
    Get Features From ArcGis Sub-Layers
  */
  public async getSubLayersFeatures(): Promise<void>{
    let count = 0;
    this.isFeatureLoader = false;
    this.arcGis.features = [];
    if(this.arcGisSelect.subLayers.length){
      this.arcGisSelect.subLayers.forEach((key) => {
        const layername = `${key.name }-${ key.layer_name.replace(/\s/g, '')}`;
        this._mapServices.getIntegrationFeaturesLayers(key.url, key.layer_id, this.arcGisToken)
        .pipe(
          tap((features: any) => {
            if (features.geometryType === 'esriGeometryPolygon' && features.features.length) {
              this.arcGis.features.push(...setSubprojectsFeatureResponse('arcGis', features.features, layername, false, key));
              this.arcGis.features = collect(this.arcGis.features).map((obj, index) => { return { ...obj, id: index };}).all();
              this.arcGis.features = collect(this.arcGis.features).unique('title').all();
              this.isFeatureLoader = true;
              if(this.selectedFeature.length) {
                setTimeout(() => {
                  const commonObjects = this.arcGis.features.filter(obj1 => this.selectedFeature.some(obj2 => obj1.title === obj2.title));
                  this.arcGisSelect.features = commonObjects;
                  if(!count)
                    this.onSelect();
                  count++;
                }, 2000);
              }
              this.cd.detectChanges();
            }else {
              this.isFeatureLoader = true;
              this.cd.detectChanges();
            }
          })
        ).subscribe();
      });
    }
  }
  /*
    Get Features From the Open Data Layers
  */
  public async getOpenDataLayersFeatures(): Promise<void>{
    let count = 0;
    if(this.layers.openData.length){
      this.layers.openData.forEach((open: any) => {
        if(open.layers.length){
          open.layers.forEach((layer,index) => {
            count = index;
            if(open.type === 'wfs'){
              const url = getRegExpURL(open.url,'service=wfs');
              const link = `${url }service=WFS&` + `version=${ open.version }&request=GetFeature&typename=${ layer.name }&` + `outputFormat=application/json&srsname=EPSG:4326&` + `bbox=${ this.BBOX },EPSG:3857`;
              this._mapServices.getPrivateFeatures(open,link)
              .pipe(
                tap((features: any) => {
                  this.features.push(...setSubprojectsFeatureResponse('openData', features.features, layer.name, open));
                  this.features = collect(this.features).map((obj, index) => { return { ...obj, id: index };}).all();
                  this.features = collect(this.features).unique('title').all();
                  if(count === open.layers.length - 1){
                    setTimeout(() => {
                      if(this.selectedFeature.length){
                        this.selectedFeatures = collect(this.features).filter(obj1 => {return collect(this.selectedFeature).some(obj2 => obj2.title === obj1.title)}).all();
                        this.onSelect();
                        this.isLoader = false;
                        this.cd.detectChanges();
                      }
                    }, 3000);
                    if(!this.selectedFeature.length){
                      this.isLoader = false;
                      this.cd.detectChanges();
                    }
                  }
                })
              ).subscribe();
            }
          });
        }
      });
    }
    else {
      this.isLoader = false;
      this.cd.detectChanges();
    }
  }
  /*
    Check The Layers Type
  */
  public async checkLayerType(layers: any): Promise<void>{
    layers.forEach(layer => {
      this._mapServices.getArcGisSubLayers(layer.url, this.arcGisToken)
      .pipe(
        tap(async (res: any) => {
          if(res.length) {
            if(collect(res).contains(item => item.geometryType === 'esriGeometryPolygon' || item.geometryType === 'esriGeometryMultiPolygon')) {
              this.arcGis.mainLayers.push(layer);
            }
            this.cd.detectChanges();
          }
        })
      ).subscribe();
    });
  }
  /*
    On Select Feature
  */
  public onSelect(): void{
    this.selectFeatures.emit(this.featureType === 'arcgis' ? this.arcGisSelect.features : this.selectFeatures);
  }
}
