import { Injectable } from '@angular/core';
import {AbstractService} from "../common/abstract.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {PaginationParams} from '../common/pagination-params';
import {Observable} from 'rxjs';
import {PaginatedResponse} from '../common/paginated-response';

@Injectable({
  providedIn: 'root'
})
export class MiddelsService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }

  public getMiddels(params: PaginationParams): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>> this.http.get('/api/middel', params.get());
  }

  getMiddelsLegacy(count, page, search = null, withs = []) {
    let params = new HttpParams()
      .set('count', count)
      .set('page', page);

    if (search) {
      params = params.append('search', search);
    }

    if (withs && withs.length > 0) {
      params = params.append('with', withs.join(','));
    }

    return this.http.get('/api/middel', {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  getMiddelsByResident(residentId, count, page, withs = []) {
    let params = new HttpParams()
      .set('count', count)
      .set('page', page);

    if (withs && withs.length > 0) {
      params = params.append('with', withs.join(','));
    }

    return this.http.get(`/api/residents/${residentId}/middel`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  getMiddelsByStakeholder(stakeholderCode, count, page, withs = []) {
    let params = new HttpParams()
      .set('count', count)
      .set('page', page);

    if (withs && withs.length > 0) {
      params = params.append('with', withs.join(','));
    }

    return this.http.get(`/api/stakeholders/${stakeholderCode}/middel`, {
      headers: this.headers,
      params: params
    });
  }

  getMiddel(id, withs?) {
    let params;
    if (typeof withs !== 'undefined') {
      params = new HttpParams()
        .set('with', withs);
    }

    return this.http.get(`/api/middel/${id}`, {
      headers: this.headers,
      params: params
    });
  }

  @ServiceErrorHandler()
  updateMiddel(id: number, middel: any) {
    return this.http.put(`/api/middel/${id}?calendar=${middel.calendars.id}`, middel, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitMiddel(middel) {
    return this.http.post('/api/middel', middel, this.httpOptions);
  }

  deleteMiddel(middelId: number, calendarId: number, deleteRelated: string = 'self') {
    return this.http.delete(`/api/middel/${middelId}?calendar=${calendarId}&delete_related=${deleteRelated}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  getBouwappUpdate(id) {
    return this.http.get(`/api/integrations/bouwapp/updates?id=${id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  createPublish(id,data) {
    return this.http.post(`/api/integrations/bouwapp/updates/${id}`,data, this.httpOptions);
  }
  @ServiceErrorHandler()
  publishDelete(id) {
    return this.http.delete(`/api/integrations/bouwapp/updates/${id}`, this.httpOptions);
  }
  @ServiceErrorHandler()
  publishUpdate(id,data) {
    return this.http.put(`/api/integrations/bouwapp/updates/${id}`,data, this.httpOptions);
  }
}

