import {
  AfterViewInit, ChangeDetectorRef,
  Directive,
  ElementRef, HostBinding, Inject,
  Input, NgZone, Optional,
  ViewContainerRef
} from '@angular/core';
import {Globals} from "../../globals";
import { MatDialog } from "@angular/material/dialog";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_SCROLL_STRATEGY, MatTooltip, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import {DownloadSharepointVersionComponent} from "../../shared/components/modal/download-sharepoint-version/download-sharepoint-version.component";
import {Overlay, ScrollDispatcher} from "@angular/cdk/overlay";
import {Platform} from "@angular/cdk/platform";
import {AriaDescriber, FocusMonitor} from "@angular/cdk/a11y";
import {Directionality} from "@angular/cdk/bidi";
import {DocumentsService} from '../../core/service/api/documents.service';
import { Lightbox } from 'ngx-lightbox';
import { Router } from '@angular/router';

@Directive({
  selector: '[versiondownload]'
})
export class VersiondownloadDirective implements AfterViewInit {

  @Input() versiondownload: any;
  @Input() lightBox: boolean=false;
  _document:any;
  @HostBinding('attr.matTooltip') mattTooltip: MatTooltip;

  constructor(private el: ElementRef, private globals: Globals, public dialog: MatDialog,
     private cdRef: ChangeDetectorRef, private _documentsService: DocumentsService,
              private _overlay: Overlay, private _scrollDispatcher: ScrollDispatcher,
              private _viewContainerRef: ViewContainerRef, private _ngZone: NgZone,
               private _platform: Platform, private _ariaDescriber: AriaDescriber,
               private router: Router,
               private _lightbox: Lightbox,
                private _focusMonitor: FocusMonitor,
              // eslint-disable-next-line no-empty-function
              @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) private _scrollStrategy: any, @Optional() private _dir: Directionality, @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) private _defaultOptions: MatTooltipDefaultOptions) { }

  ngAfterViewInit(): void {
    if(this.versiondownload){
      switch(true){
        case this.versiondownload.type === 'sharepoint':
          if (this.globals.projectConfigs['services.sharepoint.enabled']) {
            this.handlerSharepointEnabled();
          } else {
            this.handlerSharepointDisabled();
          }
        break;
        case this.versiondownload.type === 's3':
          this.handlerSharepointEnabled();
        break;
        case this.versiondownload.type === 'link':
          this.openLinkDocument();
          break;
        default:
          this.applyDefaultDownloadDocument();
        break;
      }
    }
  }

  private openLinkDocument() {
    this.el.nativeElement.onclick = () => {
      if(this.versiondownload.path) {
        if (this.isValidURL(this.versiondownload.path)) {
          window.open(this.versiondownload.path, '_blank');
        } else {
          console.error('Invalid URL:', this.versiondownload.path);
        }
      }
    }
  }
  isValidURL(url: string): boolean {
    // Regular expression for a basic URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  }

  private handlerSharepointEnabled(): void {
    this.el.nativeElement.removeAttribute('href');
    this.el.nativeElement.onclick = () => {
      if (!this.versiondownload) {
        return;
      }

      const dialogRef = this.dialog.open(DownloadSharepointVersionComponent, {
        data: this.versiondownload
      });

      dialogRef.afterClosed().subscribe(
        (result: any) => {
          if (result && result.error) {
            const parentNode = this.el.nativeElement.parentNode;
            const replacementNode = document.createElement('span');

            replacementNode.innerText = `(Verwijderd) ${ this.versiondownload.name}`;
            if (this.el.nativeElement.hasAttribute('versiondownloadstatic')) {
              replacementNode.innerText = this.el.nativeElement.innerText;
            }

            parentNode.removeChild(this.el.nativeElement);
            parentNode.appendChild(replacementNode);
          }
        }
      );
    };
  }

  private handlerSharepointDisabled(): void {
    this.el.nativeElement.removeAttribute('href');
    this.el.nativeElement.setAttribute('class', 'non-link');
    this.mattTooltip = new MatTooltip(this._overlay, this.el, this._scrollDispatcher, this._viewContainerRef, this._ngZone, this._platform, this._ariaDescriber, this._focusMonitor, this._scrollStrategy, this._dir, this._defaultOptions,this._document);
    this.mattTooltip.message = 'SharePoint niet geconfigureerd';
    this.cdRef.detectChanges();
  }

  private applyDefaultDownloadDocument(): void {
    const albums = []
    if (this.lightBox && this.versiondownload.name && this.versiondownload.name.match(/png|jpg|jpeg/) && this.versiondownload.base64) {
      this.el.nativeElement.onclick = () =>{
        let base64 = this.versiondownload.base64.split("base64")[1]
        base64 = `data:image/png;base64${base64}`
        albums.push({
          src: base64,
          caption: this.versiondownload.name,
          thumb: this.versiondownload.link,
        });
        this._lightbox.open(albums);
      };
      return;
    }
    this.el.nativeElement.onclick = () => this._documentsService.downloadDocument(this.versiondownload.id,(this.router.url.includes('sub') ? true : false)).subscribe();
  }
}
