import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { AbstractService } from 'src/app/core/service/common/abstract.service';
import { Observable } from 'rxjs';
import { Warnings } from './warnings.model';

@Injectable({
  providedIn: 'root'
})
export class WarningsService extends AbstractService {

  constructor(private http:HttpClient) {
    super();
  }
  //info window API's
  //get list of warnings
  @ServiceErrorHandler()
  getInfoWindowsWarnings(id): Observable<Warnings> {
    return this.http.get<Warnings>(`/api/${id}/warnings`, this.httpOptions)
    .pipe(
      map((res=>res.data)),
      shareReplay()
    );
  }
  //check warnings status
  @ServiceErrorHandler()
  userWarningsStatus(id) {
    return this.http.get(`/api/${id}/warnings/active`, this.httpOptions)
    .pipe(
      map((res:any)=>res),
      shareReplay()
    );
  }
  //set snooze
  @ServiceErrorHandler()
  setUserWarningsSnooze(id,user_id,data): Observable<Warnings> {
    return this.http.put<Warnings>(`/api/${user_id}/warnings/${id}`,data, this.httpOptions)
    .pipe(
      map((res:any)=>res.data),
      shareReplay()
    );
  }
}
