import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { AppInjector } from 'src/app-injector';
import { Permission } from 'src/app/core/enum/permission';
import { NewEntityModal } from 'src/app/core/util/abstract/new-entity-modal';
import { dateIso } from 'src/app/core/util/dateIso';
import { deepCopy } from 'src/app/core/util/deepCopy';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { Globals } from 'src/app/globals';
import { NewEntityData } from 'src/app/shared/directive/new-entity.directive';
import { UndoSnackbarComponent } from '../../material/undo-snackbar/undo-snackbar.component';
import { translate } from '@jsverse/transloco';
import { finalize } from 'rxjs/operators';
import { Entities } from 'src/app/core/enum/entities';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { PermitsService } from 'src/app/core/service/api/permits.service';
import { CachedService } from 'src/app/core/service/common/cached.service';

@Component({
  selector: 'app-permit-new',
  templateUrl: './permit-new.component.html',
  styleUrls: ['./permit-new.component.sass'],
})

export class PermitNewComponent extends NewEntityModal implements OnInit {
  declare public entity: any;
  themeType = 'permitstypes';
  public workflows: any[];
  declare public isProcessing: boolean;
  public users: any[];

  protected initializeEntity(): void {
    this.entity = {
      name: null,
      description: null,
      status: 'open',
      deadline: null,
      types: [],
      users: [],
      workflows: [],
    };

  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<PermitNewComponent>,
    private _cachedService: CachedService,
    public globals: Globals, private pubsub: PubsubService, private permitService: PermitsService) {
    super(data, dialogRef, globals);

  }

  ngOnInit(): void {
    this._cachedService.getUsers().subscribe(users => this.users = users);
    this._cachedService.getWorkflows().subscribe(res => this.workflows = res);
    super.ngOnInit()
  }

  public submit(): void {
    this.isProcessing = true;
    const params = deepCopy(this.entity);
    params.deadline = new Date(moment(dateIso(params.deadline, true, this.globals.timezone)).format('YYYY-MM-DD'))

    this.permitService.submitPermit(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          if (snackbar) {
            snackbar.openFromComponent(UndoSnackbarComponent, {
              data: {
                data:data,
                message: translate('error.stored'),
                type:'openItems',
                item:'permits',
                url:'permits'
              },
              duration: 4000,
              horizontalPosition: 'start',
              panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close(data);
          this.pubsub.pushNewEntity(Entities.permits, data);
          }
        });
  }

  @Allowed(Permission.PermitsCreate)
  public updateSelectedTheme(theme) {
    if(theme){
      this.entity.permitstypes = theme;
    }
  }
}
