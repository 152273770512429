<form #form="ngForm" (ngSubmit)="submit()">
  <div mat-dialog-title>
    <h4 class="mb-10 inline-block">
      {{ "new" | transloco | capitaliz }}
      {{ "complaints.label" | transloco | capitaliz }}
    </h4>
    <button type="button" class="close modal-close-button" (click)="close()">
      <span aria-hidden="true"
        ><i class="d-text-middle uil uil-multiply unicon-icon"></i
      ></span>
      <span class="sr-only">Close</span>
    </button>
  </div>
  <!-- modal body -->
  <mat-dialog-content>
    <div class="col-xs-6">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <mat-form-field class="full-width no-padding">
            <mat-label>{{ 'reusable.date' | transloco }}</mat-label>
            <input
              matInput
              name="date"
              [matDatepicker]="picker"
              [(ngModel)]="entity.date"
              (focus)="picker.open()"
              (ngModelChange)="onDeadlineChnage()"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-12">
          <app-add-connect-themes
            [type]="themeType"
            [buttonText]="'complaints.theme_button_text'"
            [textMultiple]="'reusable.complaint_type' | transloco"
            [textSingle]="'reusable.complaint_type' | transloco"
            [editable]="false"
            (onThemeSelected)="updateSelectedTheme($event)"
          ></app-add-connect-themes>
        </div>
        <div
          class="col-xs-12 col-sm-12"
          *ngIf="hasModule('communication') && hasSLA()"
        >
          <mat-form-field *transloco="let t" class="w-100">
            <mat-select
              [placeholder]="t('complaints.priority')"
              name="priority"
              [(value)]="entity.priority"
              (valueChange)="onUserSelectNotify()"
              required
            >
              <mat-option value="low">{{
                "complaints.low" | transloco | capitaliz
              }}</mat-option>
              <mat-option value="medium">{{
                "complaints.medium" | transloco | capitaliz
              }}</mat-option>
              <mat-option value="high">{{
                "complaints.high" | transloco | capitaliz
              }}</mat-option>
              <mat-option value="urgent">{{
                "complaints.urgent" | transloco | capitaliz
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-12" *transloco="let t">
          <app-mat-datepicker-time-header
            [tooltip]="hasSLA() ? t('projects.sla_disabled') : null"
            [placeholder]="'complaints.duedate' | transloco | capitaliz"
            [isDisabled]="hasSLA()"
            [(selectedDateTime)]="entity.deadline"
            [matFormFieldWidth]="'100%'"
          ></app-mat-datepicker-time-header>
        </div>
      </div>
      <div class="pb-12em">
        <editor
          class="custome-tiny-style"
          [apiKey]="apiKey"
          [(ngModel)]="entity.text"
          name="description"
          ngDefaultControl
          [init]="{
            plugins: 'lists autoresize',
            branding: false,
            menubar: false,
            placeholder: 'reusable.message_decription' | transloco,
            font_formats:
              'Work Sans, sans-serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,times new roman,times,serif;Verdana=verdana,geneva,sans-serif',
            content_style:
              '@font-face{font-family: Work Sans;  src: url(../../../../../../../assets/fonts/WorkSans-Regular.ttf)} .mce-content-body{font-family: Work Sans; line-height: 1.2 !important; margin: 0 auto !important; font-size: 13px; overflow-y: auto !important;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{color: #000 !important;} .mce-content-body[data-mce-placeholder] {font-size: 13px; font-family: Work Sans} p{margin-block-start: 0px !important; margin-block-end: 0px !important}',
            language:
              haslanguageCode() === 'en'
                ? haslanguageCode() + '_GB'
                : haslanguageCode(),
            language_url: 'assets/js/tinymce.nl.js',
            autoresize_on_init: true,
            statusbar: false,
            min_height: 30,
            block_unsupported_drop: false,
            paste_block_drop: false,
            paste_data_images: true,
            paste_as_text: true,
            toolbar: false
          }"
        >
        </editor>
      </div>
      <div>
        <app-generic-multiselect-checkbox
          [placeholder]="'reusable.responsible_person' | transloco"
          name="users"
          [(ngModel)]="entity.users"
          ngDefaultControl
          (ngModelChange)="onUserSelectNotify()"
          [uniqueId]="'responsible-users'"
        >
          <app-generic-multiselect-checkbox-option
            *ngFor="let user of users"
            [value]="user"
          >
            {{ user.name }}
          </app-generic-multiselect-checkbox-option>
        </app-generic-multiselect-checkbox>
      </div>
      <div *ngIf="emailNotifier">
        <div class="col-xs-12 col-sm-8 p-0">
          <span class="">{{ "complaints.direct_email" | transloco }}</span>
          <mat-slide-toggle
            class="pull-right"
            name="direct_mmail"
            [(ngModel)]="entity.direct_mail"
          >
          </mat-slide-toggle>
        </div>
        <div class="col-xs-12 col-sm-12 p-0">
          <mat-hint class="mat-hint">{{
            "complaints.direct_email_helper" | transloco
          }}</mat-hint>
        </div>
      </div>
      <div>
        <div>
          <label class="text-capitalize pb-5 mt-10">{{
            "reusable.contact_detail" | transloco
          }}</label>
        </div>
        <mat-radio-group
          (change)="selectContactPreference()"
          name="contactConnection"
          ngDefaultControl
          [(ngModel)]="contactConnection"
        >
          <mat-radio-button value="connect" checked>{{
            connectStakeholderText | transloco
          }}</mat-radio-button>
          <mat-radio-button value="manual">{{
            "text.complaint_connected_to_manual" | transloco
          }}</mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf="contactConnection === 'connect'">
          <div class="mt-15">
            <app-multiselect-checkbox
              [placeholder]="'reusable.selecteer_contactpersoon' | transloco"
              name="employees"
              ngDefaultControl
              [(ngModel)]="entity.contactpersons"
              (onStakeholdersUpdated)="onStakeholdersUpdated($event)"
              valueType="object"
              [multiple]="false"
              [disabled]="entity.residents"
              (ngModelChange)="employeeSet()"
            >
              <app-generic-multiselect-checkbox-option-all>{{
                "filter_select_all" | transloco
              }}</app-generic-multiselect-checkbox-option-all>
              <app-generic-multiselect-checkbox-option
                *ngFor="let employee of employees"
                [value]="employee"
              >
                {{ employee.first_name }} {{ employee.last_name }}
              </app-generic-multiselect-checkbox-option>
              <app-generic-multiselect-search-project
                entity="contactpersons.stakeholders"
              ></app-generic-multiselect-search-project>
            </app-multiselect-checkbox>

            <app-generic-multiselect-checkbox
              #stakeholderMultiselect
              [placeholder]="'reusable.selecteer_stakeholder' | transloco"
              name="stakeholders"
              ngDefaultControl
              [(ngModel)]="entity.stakeholders"
              [multiple]="true"
              (ngModelChange)="stakeholderSet()"
              [required]="!entity.residents"
              [uniqueId]="'select-stakeholders'"
            >
              <app-generic-multiselect-checkbox-option-all>{{
                "filter_select_all" | transloco
              }}</app-generic-multiselect-checkbox-option-all>
              <app-generic-multiselect-checkbox-option
                *ngFor="let stakeholder of stakeholders"
                [value]="stakeholder"
              >
                {{ stakeholder.name }}
              </app-generic-multiselect-checkbox-option>
              <app-generic-multiselect-checkbox-option-new-action
                type="stakeholder"
              ></app-generic-multiselect-checkbox-option-new-action>
              <app-generic-multiselect-search-project
                entity="stakeholders"
              ></app-generic-multiselect-search-project>
            </app-generic-multiselect-checkbox>
            <a
              *ngIf="sidebarEmployees.emails.unregistered.length > 0"
              class="d-cursor-hand"
              (click)="showEmployeeSidebar()"
            >
              {{ sidebarEmployees.emails.unregistered.length }} 
              {{ "reusable.email_address" | transloco }}
              {{ "reusable.unknown" | transloco | lowercase }}
            </a>
          </div>

          <ng-container
            *ngIf="
              globals.projectConfigs &&
              !globals.projectConfigs['residents.disabled']
            "
          >
            <div class="col-md-5">
              <mat-divider class="pull-right width-50"></mat-divider>
            </div>
            <div class="col-md-2 text-center">of</div>
            <div class="col-md-5">
              <mat-divider class="width-50"></mat-divider>
            </div>
          </ng-container>

          <app-generic-multiselect-checkbox
            *ngIf="
              globals.projectConfigs &&
              !globals.projectConfigs['residents.disabled']
            "
            placeholder="Selecteer omwonende(n)"
            name="residents"
            valueType="object"
            [(ngModel)]="entity.residents"
            [multiple]="false"
            [disabled]="entity.stakeholders"
            (ngModelChange)="residentSet()"
            [required]="!entity.stakeholders"
          >
            <app-generic-multiselect-checkbox-option-all>{{
              "filter_select_all" | transloco
            }}</app-generic-multiselect-checkbox-option-all>
            <app-generic-multiselect-checkbox-option
              *ngFor="let resident of residents"
              [value]="resident"
            >
              {{ resident.name }}
            </app-generic-multiselect-checkbox-option>
            <app-generic-multiselect-checkbox-option-new-action
              type="resident"
            ></app-generic-multiselect-checkbox-option-new-action>
          </app-generic-multiselect-checkbox>
        </ng-container>
        <ng-container *ngIf="contactConnection === 'manual'">
          <p class="mt-15 pd-10 manual-description">
            {{
              "text.complaint_connected_to_manual_description_1" | transloco
            }}
            <br />
            {{ "text.complaint_connected_to_manual_description_2" | transloco }}
          </p>
          <mat-form-field class="full-width">
            <input
              matInput
              [placeholder]="'reusable.name' | transloco"
              name="name"
              [(ngModel)]="entity.complainant_name"
              required
            />
          </mat-form-field>
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Email"
              name="email"
              [(ngModel)]="entity.complainant_email"
            />
          </mat-form-field>
          <international-phone-number
            placeholder="Telefoonnummer"
            [maxlength]="20"
            defaultCountry="nl"
            #phoneNumber="ngModel"
            [allowedCountries]="['nl', 'be']"
            name="phone_number"
            [(ngModel)]="entity.complainant_phone"
          ></international-phone-number>
        </ng-container>
      </div>
    </div>

    <div class="col-xs-6">
      <div>
        <label class="text-capitalize">{{ "location" | transloco }}</label>
        <app-openlayers-map
          [(ngModel)]="entity.locations"
          ngDefaultControl
          [includeSidebar]="false"
          [defaultCoordinate]="globals.projectConfig.default_map"
          [showMapReset]="true"
          [maxPoints]="1"
          name="locations"
          locatableType="complaints"
          defaultDrawTool="point"
          clickStartDrawingMsg="Teken meldingpunt"
        ></app-openlayers-map>
      </div>
    </div> </mat-dialog-content
  ><!--end of modal body-->

  <mat-dialog-actions align="end">
    <div class="dialog-button-row">
      <button
        type="button"
        mat-stroked-button
        data-dismiss="modal"
        [disabled]="isProcessing"
        (click)="close()"
      >
        {{ "cancel" | transloco | capitaliz }}
      </button>
      <button
        type="submit"
        mat-flat-button
        [disabled]="entity.text && isProcessing"
      >
        <span *ngIf="!isProcessing">
          <mat-icon class="material-icons d-text-middle">save</mat-icon>
          {{ "add" | transloco | capitaliz }}
        </span>
        <span *ngIf="isProcessing">
          {{ "text.please_wait" | transloco | capitaliz }}</span
        >
      </button>
    </div>
    <!--end of modal footer -->
  </mat-dialog-actions>
</form>
