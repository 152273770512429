import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenlayersRightPanelComponent } from './openlayers-rightpanel.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import {NouisliderModule} from "ng2-nouislider";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatCardModule,
    MatButtonToggleModule,

    NouisliderModule,
  ],
  declarations: [OpenlayersRightPanelComponent],
  exports: [OpenlayersRightPanelComponent]
})
export class OpenlayersRightPanelModule {
  // eslint-disable-next-line no-empty-function
  constructor() {}
}
