import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globals } from '../../../../globals';
@Component({
  selector: 'app-all-search-select-generic',
  templateUrl: './all-search-select-generic.component.html',
  styleUrls: ['./all-search-select-generic.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line no-use-before-define
    useExisting: AllSearchSelectGenericComponent,
    multi: true
  }]
})
export class AllSearchSelectGenericComponent implements OnInit,OnDestroy {
  private preLoaderSbj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public relatics:any[]=[];
  public keywords:string;
  public preLoader$: Observable<boolean> = this.preLoaderSbj.asObservable();
  @Input() models: any={};
  @Output() onselectionRelatic: EventEmitter<any> = new EventEmitter();
  filteredOptions: Observable<string[]>;
  saveRes:any[];
  constructor(
    public globals: Globals,
    private cd: ChangeDetectorRef,
  // eslint-disable-next-line no-empty-function
  ) { }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    setTimeout(() => {
      this.saveRes=this.models;
    }, 2000);
  }

  public selectItem(date): void {
    this.keywords='';
    this.models=this.saveRes;
    this.onselectionRelatic.emit(date);
    this.cd.detectChanges();
  }

  public searchInput() {
    const inputRes=[];
    this.models=this.saveRes;
    const filterValue = this.keywords.toLowerCase();
    if(filterValue){
      this.models.filter(option => {
        inputRes.push({oprationname:option.oprationname,enities:option.enities.filter(element => {
          if(element.name!=null){
            if(element.name.toLowerCase().includes(filterValue)){
              return element;
            }
          }
        })});
      });
      this.models=inputRes;
    }
    this.cd.detectChanges();
  }
}
