import { Component, ChangeDetectionStrategy, ViewChild, OnInit, ChangeDetectorRef, Inject, LOCALE_ID } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { Issue } from 'src/app/akita/issues/state/issue.model';
import { ProjectConfigsService } from 'src/app/akita/project-configs/state/project-configs.service';
import { SubprojectQuery } from 'src/app/akita/subproject/state/subprojects.query';
import { LogListingType } from 'src/app/core/enum/log-listing-type';
import { ThemeType } from 'src/app/core/enum/theme-type';
import { ConfigsService } from 'src/app/core/service/api/configs.service';
import { LogsService } from 'src/app/core/service/api/logs.service';
import { MapService } from 'src/app/core/service/api/map.service';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';
import { PusherService } from 'src/app/core/service/api/pusher.service';
import { UsersService } from 'src/app/core/service/api/users.service';
import { CachedService } from 'src/app/core/service/common/cached.service';
import { IntegrationsService } from 'src/app/core/service/integrations.service';
import { GenericComponent } from 'src/app/core/util/abstract/generic-component';
import { Globals } from 'src/app/globals';
import { GenericMultiselectCheckboxComponent } from 'src/app/shared/components/other/generic-multiselect/generic-multiselect-checkbox/generic-multiselect-checkbox.component';
import { environment } from 'src/environments/environment';
import posthog from 'posthog-js';
import { PermitsService } from 'src/app/core/service/api/permits.service';
import { forkJoin } from 'rxjs';
import { dateIso } from 'src/app/core/util/dateIso';
import {showFeedbackRemoved, showFeedbackSaved} from 'src/app/core/util/notification';
import { Log } from 'src/app/akita/contact-moments/state/log.model';
import { globalSubprojectSaprator, selectedSubprojectSaprator } from 'src/app/core/util/subprojectDropdownPermission';
import { deletedUsersForClosedTickets, userRoleFinder } from 'src/app/core/util/userDropDownPermission';
import { Permit } from 'src/app/akita/permits/state/permit.model';
import { setKPIStyle } from 'src/app/core/util/complaintModal';
import { Allowed } from 'src/app/decorator/allowed-decorator';
import { Permission } from 'src/app/core/enum/permission';
import { NewWorkflowComponent } from 'src/app/shared/components/modal/new-workflow/new-workflow.component';
import { ModalEnum, modalConfig } from 'src/app/core/util/modalConfig';
import { Action } from 'src/app/akita/tasks/state/action.model';
import {DeletionModalComponent} from "../../../../../shared/components/modal/deletion-modal/deletion-modal.component";
import {deleteConfirmationResponce} from "../../../../../core/util/deepCopy";

@Component({
  selector: 'app-permit-detail',
  templateUrl: './permit-detail.component.html',
  styleUrls: ['./permit-detail.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermitDetailComponent extends GenericComponent implements OnInit {
  // Component properties and variables
  pageSubTitle: string;
  pageTitle = 'Permits';

  @ViewChild('closed_date') closedDatePicker: MatDatepicker<Date>;
  @ViewChild('stakeholderMultiselect') private stakeholderMultiselect: GenericMultiselectCheckboxComponent;
  @ViewChild('log') _logs: any; // Set to any instead of LogsComponent to prevent circular dependency error
  public permitId: any;
  public reducer: any = true;
  public permit: Permit;
  public selectedComplainee: any;
  public users: any;
  private updateComplaintTimeout: any;
  public logListingType: LogListingType = LogListingType.ComplaintDetail;
  public disableLog: boolean = false;
  public apiKey: string = environment.tinymce.apiKey;
  public integrationType: any = { type: '', error_type: '', chatShow: false, chatClosed: 0, chatIcon: '' };
  public integrationsEnabled: boolean = false;
  public stakeholders: any[];
  errorMessage_userleve: boolean = false;
  public residents: any[];
  public employees: any[];
  public firstMessage: any[] = [];
  themeType = ThemeType.Permits;
  requesterType=ThemeType.Requester;
  clusterType=ThemeType.Cluster;
  validityType=ThemeType.Validity;
  permitFilinyType=ThemeType.PermitFiling;
  selectedTheme: any[] = [];
  showStatusField = false;
  name: string;
  logs: Log[] = [];
  actions: Action[] = [];
  issues: Issue[] = [];
  entities: any
  loaded: Boolean = false;
  is_load: Boolean = true;
  subprojectUpdate: boolean = false;
  disanleString: string;
  public integrationDocuments: any[] = [];
  complaintMessagesParams = {
    ticket_id: 0,
    type: ''
  }
  firstload: boolean = false;
  integration_themeDisable: boolean = false;
  statusclosebutton: boolean = false;
  kpiSet: boolean = false;
  subprojectsSet: any[] = [];
  chipDisabler: any[] = [];
  chipDisablerUser: any[] = [];
  userSelection: any[] = [];
  sentiments = [{ value: 1, icon: 'uil uil-frown' },
  { value: 3, icon: 'uil uil-meh' },
  { value: 5, icon: 'uil uil-smile' }];
  selectedIcon = { value: 1, icon: 'uil uil-meh' };
  menuToggle = false;
  public sentimentEnable = false;
  permitStatusButton: boolean = false;

  constructor(public pubsub: PubsubService, public globals: Globals,
    private _permitsService: PermitsService,
    private cachedService: CachedService,
    private _usersService: UsersService,
    private _logsService: LogsService,
    private configs: ConfigsService,
    private _lightbox: Lightbox, private _sanitizer: DomSanitizer,
    private subProjectQuery: SubprojectQuery,
    public dialog: MatDialog,
    public router: Router,
    private cd: ChangeDetectorRef,
    private _integrationService: IntegrationsService,
    public _projectConfigsService: ProjectConfigsService,
    private _pusherService: PusherService,
    @Inject(LOCALE_ID) private locale: string, private _mapService: MapService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PermitDetailComponent>) {
    super();
  }

  ngOnInit(): void {
    this.permitId = this.data.id;
    this.name = this.data.name;

    this.subprojectSetup();

    this._usersService.getUsers().subscribe(
      (users: any) => {
        this.users = users.data;
      }
    );
    this.cachedService.getStakeholdersRelations().subscribe(
      stakeholders => this.stakeholders = stakeholders
    );

    this.cachedService.getEmployees().subscribe(
      employees => this.employees = employees
    );

    if (!this.globals.projectConfigs['residents.disabled']) {
      this.cachedService.getResidents().subscribe(
        residents => this.residents = residents
      );
    }

    this.load(this.permitId);
    posthog.capture('permit-detail');
  }

  private load(permitId): void {
    this.permitId = permitId;
    this._permitsService.getPermit(this.permitId, true).subscribe(
      (permit: Permit) => {
        // Ensure this.permit is assigned correctly
        this.permit = permit;
        this.permit.issues = this.permit.issues || [];
        this.permit.requirements = this.permit.requirements || [];
        this.permit.contactmoments = this.permit.contactmoments || [];


        this.parseComplaintResponse(permit);
        forkJoin([
          this.cachedService.getIssuesRelations(),
          this.cachedService.getDecisionsRelations(),
          this.cachedService.getLogsRelations(),
          this.cachedService.getActionsRelations(),
          this.cachedService.getActivities(),
          this.cachedService.getDocumentsRelations(),
        ]
        ).subscribe(([issues, requirements, contactmoments,tasks,activities, documents]) => {
          this.entities = { issues, requirements, contactmoments: contactmoments, tasks,activities, documents }
          this.loaded = true;
          this.cd.detectChanges();
        });
        //date parse
        permit.deadline = this.parseDate(permit.deadline.toString())

        this.setLodedSubproject();
        this.usersSetup();
        this.setLodedSubprojectUsers();
        this.permitStatusButton = false;
      });
  }

  updateEntities(event: any) {
    this.permit[event.type] = event.selected;
    this.updatePermit();
  }

  updateSubprojects() {
    setTimeout(() => {
      this.pubsub.updateSubprojects(this, "updatePermit", this.permit.subprojects)
    }, 500);
  }

  public parseDate(dateString: string): Date {
    if (dateString) {
      return dateIso(dateString, true, this.globals.timezone)
    }
    return null;
  }

  private parseComplaintResponse(permit) {
    if (permit.date) {
      permit.date = dateIso(permit.date, true, this.globals.timezone);
    }

    if (permit.closed_date) {
      permit.closed_date = dateIso(permit.closed_date, true, this.globals.timezone);
    }

    permit.is_closed = permit.is_closed === 1 || permit.is_closed == true;
    if (this.permit) {
      permit.logs = this.permit.contactmoments

    }
    this.permit = permit;

    if (this.permit.contactpersons)
      this.permit.contactpersons.selected = this.permit.contactpersons.stakeholders.filter(x => this.permit.stakeholders.find(s => s.id == x.id));
  }

  public closedDateLoaded() {
    // Make sure that the mat-datepicker is disabled when permit.is_closed = true after refresh
    if (this.closedDatePicker) {
      this.closedDatePicker.disabled = this.permit.is_closed;
    }
  }

  public updateComplaint(showFeedback = true, parseUpdateResponse = true): void {
    if (this.globals.permissions.includes(this.Permission.PermitsUpdate)) {
      clearTimeout(this.updateComplaintTimeout);
    }
  }

  newWorkflow() {
    const component = NewWorkflowComponent;
    const dialog = this.dialog.open(component, modalConfig({
      data: {parentType: 'permits', parent: this.permit},
      panelClass: 'customized-dialog-container',
    }, ModalEnum.ModalSmall));

    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.permit.workflows.push(response);
        this.load(this.permitId);
      }
    });
  }

  public toggleHandlePermit() {
    this.permitStatusButton=true;
    this.permit.is_closed = !this.permit.is_closed;
    if(this.permit.is_closed && this.integrationType)
    this.updateComplaint();
  }

  private updatePermit(showFeedback: boolean = true): void {
    this._permitsService.updatePermit(this.permitId, this.permit).subscribe(
      permit => {
        showFeedbackSaved();
        if (!this.subprojectUpdate) {
          this.load(this.permitId);
        }
        this.subprojectUpdate = false;
      }
    );
  }


  public setLodedSubprojectUsers() {
    this.chipDisablerUser = [];
    if (this.users) {
      const activeSubProject = this.subProjectQuery.getActive();
      if (activeSubProject && userRoleFinder(this.globals)) {
        // this.chipDisablerUser = selectedSubprojectUsersSaprator(this.users, this.permit.users);
      }
      const deletedUsers = deletedUsersForClosedTickets(this.permit.is_closed, this.permit.users);
      this.chipDisablerUser = [...this.chipDisablerUser, ...deletedUsers]
    }
  }

  public loadKPIcolorSet(type,value){
    return setKPIStyle(type,value,this.globals);
}

  public subprojectSetup() {
    this.subprojectsSet = globalSubprojectSaprator(this.globals.subprojects, 'permits:update')
  }
  public setLodedSubproject() {
    this.chipDisabler = [];
    this.chipDisabler = selectedSubprojectSaprator(this.subprojectsSet, this.permit.subprojects)
  }
  public usersSetup() {
    const activeSubProject = this.subProjectQuery.getActive();
    if (activeSubProject && userRoleFinder(this.globals)) {
      // this.users = globalSubprojectUserSaprator(this.users, activeSubProject)
    }
  }

  public stakeholderSet(): void {
    // not to laod the whole complaint on stakeholder update
    this.subprojectUpdate = true;
    this.updatePermit();
  }

  @Allowed(Permission.PermitsCreate)
  public updateSelectedTypes(types) {
    if(types){
      this.permit.permitstypes = types;
    }
    this.updatePermit();
  }

  public updateSelectedRequester(types) {
    if(types){
      this.permit.permitsrequesters = types;
    }
    this.updatePermit();
  }

  public updateSelectedValidity(types) {
    if(types){
      this.permit.permitsvalidities = types;
    }
    this.updatePermit();
  }

  public updateSelectedCluseter(types) {
    if(types){
      this.permit.permitsclusters = types;
    }
    this.updatePermit();
  }

  public updateSelectedFilingReq(types) {
    if(types){
      this.permit.permitsfillingrequirements = types;
    }
    this.updatePermit();
  }

  public getStatusClass(status) {
    if (status === 'open') {
      return 'alert alert-primary';
    } else if (status === 'in_progress') {
      return 'alert alert-progress';
    } else if (status === 'rejected') {
      return 'alert alert-expired';
    } else if (status === 'approved') {
      return 'alert alert-success';
    }

  }

  public close(): void {
    this.pubsub.closeModal(this.dialogRef);
    this.pubsub.resetHistory();
  }

  deletePermit(permit: Permit) {
    const dialogRef = this.dialog.open(DeletionModalComponent, {
      data: deleteConfirmationResponce(permit, this.router, 'permits')
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((remove: boolean) => {
      if (remove) {
        this._permitsService.deletePermits(permit).subscribe(
          () => {
            showFeedbackRemoved();
            this.close();
          });
      }
    });
  }
}
