import {Component, OnDestroy, OnInit} from '@angular/core';
import {PubsubService} from "../../../core/service/api/pubsub.service";
import {Globals} from "../../../globals";
import {RouteInterceptorService} from '../../../core/service/util/route-interceptor.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-header-general',
  templateUrl: './header-general.component.html',
  styleUrls: ['./header-general.component.css']
})
export class HeaderGeneralComponent implements OnInit, OnDestroy {

  pageTitle: string;
  pageSubTitle: string;
  pageBackToOverview: any;

  private titleSubscription: Subscription;
  private subtitleSubscription: Subscription;

  constructor(public pubsub: PubsubService, public globals: Globals,
              // eslint-disable-next-line no-empty-function
              public router: Router, public routeInterceptor: RouteInterceptorService) { }

  ngOnInit() {
    this.titleSubscription = this.pubsub.pageTitle.subscribe(title => {
      this.pageTitle = title;
    });
    this.subtitleSubscription = this.pubsub.pageSubTitle.subscribe(subTitle => {
      this.pageSubTitle = subTitle;
    });
  }

  public back(): void {
    if (this.routeInterceptor.previousUrl) {
      const urls = this.routeInterceptor.previousUrl.split('?');
      let queryParams = {};
      if (urls[1]) {
        queryParams = JSON.parse(`{"${ urls[1].replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') }"}`);
      }

      this.router.navigate([urls[0]], {
        queryParams: queryParams,
        state: {
          directNavigate: true,
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
    this.subtitleSubscription.unsubscribe();
  }
}
