import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, delay, finalize, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/service/auth/auth.service';
import { bugsnagFactory } from 'src/app/core/util/bugsnagFactory';
import { showFeedbackSaved } from 'src/app/core/util/notification';
import { Globals } from 'src/app/globals';
import {TranslocoService} from "@jsverse/transloco";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import { ProjectsService } from 'src/app/akita/project/state/projects.service';
@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.sass'],
})
export class TwoFactorAuthenticationComponent implements OnInit {
  public password: string;
  public showError: boolean = false
  public code: string;
  public verify: boolean = false;
  public invalidHint: boolean = false;
  public QRCode: any;
  public passwordToggle: boolean = false;
  public recoveryCodes: any
  public email!: string;
  translatedText: SafeHtml = ''; // SafeHtml type to work with sanitized HTML
  // eslint-disable-next-line no-empty-function
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<TwoFactorAuthenticationComponent>, private _authService: AuthService, public globals: Globals, private translocoService: TranslocoService, private projectsService: ProjectsService, private cd: ChangeDetectorRef) {
    this.applyColorToTranslatedText();
  }

  ngOnInit() {
    if (this.globals?.user?.email){
      this.email = this.globals.user.email;
    }
    else {
      this.email = this.data.email;
    }
  }

  //on close modal
  public onClose(): void {
    this.dialogRef.close();
  }
  //on password field change
  public onPasswordChange(): void {
    this.verify = false;
    this.invalidHint = true;
  }
  //check valid password
  public checkValidPassword(status): void {
    console.log(status)
    this._authService.getAuthToken(this.email, this.password)
      .pipe(
        tap(res => {
          if (status) {
            this.disableTwoFactorAuthentication();
            return;
          }
          this.verify = true;
          this.invalidHint = true;
          this.twoFactorAuthentication();
        }),
        catchError(async (err) => {
          this.errorHandler();
          bugsnagFactory().notify(err);
        })
      ).subscribe();
  }
  //two factor authentification logic
  public twoFactorAuthentication(): void {
    this._authService.enableMultiFactorAuthentification()
      .pipe(
        delay(2000),
        finalize(() => {
          this._authService.getMultiFactorAuthentificationQR()
            .pipe(
              tap(res => {
                this.QRCode = res.svg.replace('<svg ', `<svg height="115px" `);
                // when via login method call log api results 401 bcz of unknown project
                if (this.data.viaSettings) {
                  this.projectsService.setActivityLogs('user_two_factor_auth_enabled').subscribe();
                }
                this.getRecoveryCodes()
              }),
              catchError(async (err) => {
                this.errorHandler();
                bugsnagFactory().notify(err);
              })
            ).subscribe();
        }),
        catchError(async (err) => {
          this.errorHandler();
          bugsnagFactory().notify(err);
        })
      ).subscribe();
  }
  //any api cause error
  public errorHandler(): void {
    this.verify = false;
    this.invalidHint = false;
  }
  //on save code
  public onSaveCode(): void {
    this.showError =false
    this._authService.sendMultiFactorAuthentificationCode({ code: this.code })
      .pipe(
        finalize(() => {
          if (!this.showError) {
            this.dialogRef.close({ status: true });
            if (this.globals) {
              this.globals.user.is_mfa_enabled = true;
            }
            showFeedbackSaved();
          }
        }),
        catchError(async (err) => {
          this.showError = true
          bugsnagFactory().notify(err)
        })
      ).subscribe();
  }
  //disable two factor authentification
  public disableTwoFactorAuthentication(): void {
    this._authService.disableMultiFactorAuthentification()
      .pipe(
        finalize(() => {
          this.dialogRef.close({ status: false });
          if (this.globals)
          {
            this.globals.user.is_mfa_enabled = false;
          }
          this.projectsService.setActivityLogs('user_two_factor_auth_disabled').subscribe();
          showFeedbackSaved();
        }),
        catchError(async (err) => bugsnagFactory().notify(err))
      ).subscribe();
  }

  public getRecoveryCodes() {
    this._authService.getRecoveryCode()
      .pipe(
        tap(res => {
          this.recoveryCodes = res;
          this.cd.detectChanges();
        }),
        catchError(async (err) => {
          this.errorHandler();
          bugsnagFactory().notify(err);
        })
      ).subscribe();
  }

  applyColorToTranslatedText(): void {
    this.translocoService.selectTranslate('settings.personal.favorite_two-factor_authentication')
      .subscribe((translatedString: string) => {
        const highlightedText = this.highlightKeywords(translatedString);
        this.translatedText = this.sanitizer.bypassSecurityTrustHtml(highlightedText); // Bypass sanitization
      });
  }

  highlightKeywords(text: string): string {
    const keywords = ['Microsoft Authenticator', 'Google Authenticator'];

    keywords.forEach(keyword => {
      const regex = new RegExp(`(${keyword})`, 'gi');
      text = text.replace(regex, `<span style="color:blue;">$1</span>`);
    });

    return text;
  }


}
