import * as DataStream from './dataStream.js';

export class MSGReader {

  private ds: any;
  private fileData: any;
  private CONST = {
    FILE_HEADER: this.uInt2int([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1]),
    MSG: {
      UNUSED_BLOCK: -1,
      END_OF_CHAIN: -2,

      S_BIG_BLOCK_SIZE: 0x0200,
      S_BIG_BLOCK_MARK: 9,

      L_BIG_BLOCK_SIZE: 0x1000,
      L_BIG_BLOCK_MARK: 12,

      SMALL_BLOCK_SIZE: 0x0040,
      BIG_BLOCK_MIN_DOC_SIZE: 0x1000,
      HEADER: {
        PROPERTY_START_OFFSET: 0x30,

        BAT_START_OFFSET: 0x4c,
        BAT_COUNT_OFFSET: 0x2C,

        SBAT_START_OFFSET: 0x3C,
        SBAT_COUNT_OFFSET: 0x40,

        XBAT_START_OFFSET: 0x44,
        XBAT_COUNT_OFFSET: 0x48
      },
      PROP: {
        NO_INDEX: -1,
        PROPERTY_SIZE: 0x0080,

        NAME_SIZE_OFFSET: 0x40,
        MAX_NAME_LENGTH: (/*NAME_SIZE_OFFSET*/0x40 / 2) - 1,
        TYPE_OFFSET: 0x42,
        PREVIOUS_PROPERTY_OFFSET: 0x44,
        NEXT_PROPERTY_OFFSET: 0x48,
        CHILD_PROPERTY_OFFSET: 0x4C,
        START_BLOCK_OFFSET: 0x74,
        SIZE_OFFSET: 0x78,
        TYPE_ENUM: {
          DIRECTORY: 1,
          DOCUMENT: 2,
          ROOT: 5
        }
      },
      FIELD: {
        PREFIX: {
          ATTACHMENT: '__attach_version1.0',
          RECIPIENT: '__recip_version1.0',
          DOCUMENT: '__substg1.'
        },
        // example (use fields as needed)
        NAME_MAPPING: {
          // email specific
          '0037': 'subject',
          '0c1a': 'senderName',
          '5d02': 'senderEmail',
          '1000': 'body',
          '1013': 'bodyHTML',
          '007d': 'headers',
          // attachment specific
          '3703': 'extension',
          '3704': 'fileNameShort',
          '3707': 'fileName',
          '3712': 'pidContentId',
          '370e': 'mimeType',
          // recipient specific
          '3001': 'name',
          '39fe': 'email'
        },
        CLASS_MAPPING: {
          ATTACHMENT_DATA: '3701'
        },
        TYPE_MAPPING: {
          '001e': 'string',
          '001f': 'unicode',
          '0102': 'binary'
        },
        DIR_TYPE: {
          INNER_MSG: '000d'
        }
      }
    }
  };

  // MSG Reader
  constructor (arrayBuffer?) {
    this.ds = new DataStream(arrayBuffer, 0, DataStream.LITTLE_ENDIAN);
  }


  // unit utils
  private arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  private uInt2int(data) {
    const result = new Array(data.length);
    for (let i = 0; i < data.length; i++) {
      result[i] = data[i] << 24 >> 24;
    }
    return result;
  }

  // MSG Reader implementation

  // check MSG file header
  private isMSGFile(ds) {
    ds.seek(0);
    return this.arraysEqual(this.CONST.FILE_HEADER, ds.readInt8Array(this.CONST.FILE_HEADER.length));
  }

  // FAT utils
  private getBlockOffsetAt(msgData, offset) {
    return (offset + 1) * msgData.bigBlockSize;
  }

  private getBlockAt(ds, msgData, offset) {
    const startOffset = this.getBlockOffsetAt(msgData, offset);
    ds.seek(startOffset);
    return ds.readInt32Array(msgData.bigBlockLength);
  }

  private getNextBlockInner(ds, msgData, offset, blockOffsetData) {
    const currentBlock = Math.floor(offset / msgData.bigBlockLength);
    const currentBlockIndex = offset % msgData.bigBlockLength;

    const startBlockOffset = blockOffsetData[currentBlock];

    return this.getBlockAt(ds, msgData, startBlockOffset)[currentBlockIndex];
  }

  private getNextBlock(ds, msgData, offset) {
    return this.getNextBlockInner(ds, msgData, offset, msgData.batData);
  }

  private getNextBlockSmall(ds, msgData, offset) {
    return this.getNextBlockInner(ds, msgData, offset, msgData.sbatData);
  }

  // convert binary data to dictionary
  private parseMsgData(ds) {
    const msgData = this.headerData(ds);
    msgData.batData = this.batData(ds, msgData);
    msgData.sbatData = this.sbatData(ds, msgData);
    if (msgData.xbatCount > 0) {
      this.xbatData(ds, msgData);
    }
    msgData.propertyData = this.propertyData(ds, msgData);
    msgData.fieldsData = this.fieldsData(ds, msgData);

    return msgData;
  }

  // extract header data
  private headerData(ds) {
    const headerData: any = {};

    // system data
    headerData.bigBlockSize =
      ds.readByte(/*const position*/30) == this.CONST.MSG.L_BIG_BLOCK_MARK ? this.CONST.MSG.L_BIG_BLOCK_SIZE : this.CONST.MSG.S_BIG_BLOCK_SIZE;
    headerData.bigBlockLength = headerData.bigBlockSize / 4;
    headerData.xBlockLength = headerData.bigBlockLength - 1;

    // header data
    headerData.batCount = ds.readInt(this.CONST.MSG.HEADER.BAT_COUNT_OFFSET);
    headerData.propertyStart = ds.readInt(this.CONST.MSG.HEADER.PROPERTY_START_OFFSET);
    headerData.sbatStart = ds.readInt(this.CONST.MSG.HEADER.SBAT_START_OFFSET);
    headerData.sbatCount = ds.readInt(this.CONST.MSG.HEADER.SBAT_COUNT_OFFSET);
    headerData.xbatStart = ds.readInt(this.CONST.MSG.HEADER.XBAT_START_OFFSET);
    headerData.xbatCount = ds.readInt(this.CONST.MSG.HEADER.XBAT_COUNT_OFFSET);

    return headerData;
  }

  private batCountInHeader(msgData) {
    const maxBatsInHeader = (this.CONST.MSG.S_BIG_BLOCK_SIZE - this.CONST.MSG.HEADER.BAT_START_OFFSET) / 4;
    return Math.min(msgData.batCount, maxBatsInHeader);
  }

  private batData(ds, msgData) {
    const result = new Array(this.batCountInHeader(msgData));
    ds.seek(this.CONST.MSG.HEADER.BAT_START_OFFSET);
    for (let i = 0; i < result.length; i++) {
      result[i] = ds.readInt32()
    }
    return result;
  }

  private sbatData(ds, msgData) {
    const result = [];
    let startIndex = msgData.sbatStart;

    for (let i = 0; i < msgData.sbatCount && startIndex != this.CONST.MSG.END_OF_CHAIN; i++) {
      result.push(startIndex);
      startIndex = this.getNextBlock(ds, msgData, startIndex);
    }
    return result;
  }

  private xbatData(ds, msgData) {
    const batCount = this.batCountInHeader(msgData);
    const batCountTotal = msgData.batCount;
    let remainingBlocks = batCountTotal - batCount;

    let nextBlockAt = msgData.xbatStart;
    for (let i = 0; i < msgData.xbatCount; i++) {
      const xBatBlock = this.getBlockAt(ds, msgData, nextBlockAt);
      nextBlockAt = xBatBlock[msgData.xBlockLength];

      const blocksToProcess = Math.min(remainingBlocks, msgData.xBlockLength);
      for (let j = 0; j < blocksToProcess; j++) {
        const blockStartAt = xBatBlock[j];
        if (blockStartAt == this.CONST.MSG.UNUSED_BLOCK || blockStartAt == this.CONST.MSG.END_OF_CHAIN) {
          break;
        }
        msgData.batData.push(blockStartAt);
      }
      remainingBlocks -= blocksToProcess;
    }
  }

  // extract property data and property hierarchy
  private propertyData(ds, msgData) {
    const props = [];

    let currentOffset = msgData.propertyStart;

    while (currentOffset != this.CONST.MSG.END_OF_CHAIN) {
      this.convertBlockToProperties(ds, msgData, currentOffset, props);
      currentOffset = this.getNextBlock(ds, msgData, currentOffset);
    }
    this.createPropertyHierarchy(props, /*property with index 0 (zero) always as root*/props[0]);
    return props;
  }

  private convertName(ds, offset) {
    const nameLength = ds.readShort(offset + this.CONST.MSG.PROP.NAME_SIZE_OFFSET);
    if (nameLength < 1) {
      return '';
    } 
      return ds.readStringAt(offset, nameLength / 2);
    
  }

  private convertProperty(ds, index, offset) {
    return {
      index: index,
      type: ds.readByte(offset + this.CONST.MSG.PROP.TYPE_OFFSET),
      name: this.convertName(ds, offset),
      // hierarchy
      previousProperty: ds.readInt(offset + this.CONST.MSG.PROP.PREVIOUS_PROPERTY_OFFSET),
      nextProperty: ds.readInt(offset + this.CONST.MSG.PROP.NEXT_PROPERTY_OFFSET),
      childProperty: ds.readInt(offset + this.CONST.MSG.PROP.CHILD_PROPERTY_OFFSET),
      // data offset
      startBlock: ds.readInt(offset + this.CONST.MSG.PROP.START_BLOCK_OFFSET),
      sizeBlock: ds.readInt(offset + this.CONST.MSG.PROP.SIZE_OFFSET)
    };
  }

  private convertBlockToProperties(ds, msgData, propertyBlockOffset, props) {

    const propertyCount = msgData.bigBlockSize / this.CONST.MSG.PROP.PROPERTY_SIZE;
    let propertyOffset = this.getBlockOffsetAt(msgData, propertyBlockOffset);

    for (let i = 0; i < propertyCount; i++) {
      const propertyType = ds.readByte(propertyOffset + this.CONST.MSG.PROP.TYPE_OFFSET);
      switch (propertyType) {
        case this.CONST.MSG.PROP.TYPE_ENUM.ROOT:
        case this.CONST.MSG.PROP.TYPE_ENUM.DIRECTORY:
        case this.CONST.MSG.PROP.TYPE_ENUM.DOCUMENT:
          props.push(this.convertProperty(ds, props.length, propertyOffset));
          break;
        default:
          /* unknown property types */
          props.push(null);
      }

      propertyOffset += this.CONST.MSG.PROP.PROPERTY_SIZE;
    }
  }

  private createPropertyHierarchy(props, nodeProperty) {

    if (nodeProperty.childProperty == this.CONST.MSG.PROP.NO_INDEX) {
      return;
    }
    nodeProperty.children = [];

    const children = [nodeProperty.childProperty];
    while (children.length != 0) {
      const currentIndex = children.shift();
      const current = props[currentIndex];
      if (current == null) {
        continue;
      }
      nodeProperty.children.push(currentIndex);

      if (current.type == this.CONST.MSG.PROP.TYPE_ENUM.DIRECTORY) {
        this.createPropertyHierarchy(props, current);
      }
      if (current.previousProperty != this.CONST.MSG.PROP.NO_INDEX) {
        children.push(current.previousProperty);
      }
      if (current.nextProperty != this.CONST.MSG.PROP.NO_INDEX) {
        children.push(current.nextProperty);
      }
    }
  }

  // extract real fields
  private fieldsData(ds, msgData) {
    const fields = {
      attachments: [],
      recipients: []
    };
    this.fieldsDataDir(ds, msgData, msgData.propertyData[0], fields);
    return fields;
  }

  private fieldsDataDir(ds, msgData, dirProperty, fields) {

    if (dirProperty.children && dirProperty.children.length > 0) {
      for (let i = 0; i < dirProperty.children.length; i++) {
        const childProperty = msgData.propertyData[dirProperty.children[i]];

        if (childProperty.type == this.CONST.MSG.PROP.TYPE_ENUM.DIRECTORY) {
          this.fieldsDataDirInner(ds, msgData, childProperty, fields)
        } else if (childProperty.type == this.CONST.MSG.PROP.TYPE_ENUM.DOCUMENT
          && childProperty.name.indexOf(this.CONST.MSG.FIELD.PREFIX.DOCUMENT) == 0) {
          this.fieldsDataDocument(ds, msgData, childProperty, fields);
        }
      }
    }
  }

  private fieldsDataDirInner(ds, msgData, dirProperty, fields) {
    if (dirProperty.name.indexOf(this.CONST.MSG.FIELD.PREFIX.ATTACHMENT) == 0) {

      // attachment
      const attachmentField = {};
      fields.attachments.push(attachmentField);
      this.fieldsDataDir(ds, msgData, dirProperty, attachmentField);
    } else if (dirProperty.name.indexOf(this.CONST.MSG.FIELD.PREFIX.RECIPIENT) == 0) {

      // recipient
      const recipientField = {};
      fields.recipients.push(recipientField);
      this.fieldsDataDir(ds, msgData, dirProperty, recipientField);
    } else {

      // other dir
      const childFieldType = this.getFieldType(dirProperty);
      if (childFieldType != this.CONST.MSG.FIELD.DIR_TYPE.INNER_MSG) {
        this.fieldsDataDir(ds, msgData, dirProperty, fields);
      } else {
        // MSG as attachment currently isn't supported
        fields.innerMsgContent = true;
      }
    }
  }

  private isAddPropertyValue(fieldName, fieldTypeMapped) {
    return fieldName !== 'body' || fieldTypeMapped !== 'binary';
  }

  private fieldsDataDocument(ds, msgData, documentProperty, fields) {
    const value = documentProperty.name.substring(12).toLowerCase();
    const fieldClass = value.substring(0, 4);
    const fieldType = value.substring(4, 8);

    const fieldName = this.CONST.MSG.FIELD.NAME_MAPPING[fieldClass];
    const fieldTypeMapped = this.CONST.MSG.FIELD.TYPE_MAPPING[fieldType];

    if (fieldName) {
      const fieldValue = this.getFieldValue(ds, msgData, documentProperty, fieldTypeMapped);

      if (this.isAddPropertyValue(fieldName, fieldTypeMapped)) {
        fields[fieldName] = this.applyValueConverter(fieldName, fieldTypeMapped, fieldValue);
      }
    }
    if (fieldClass == this.CONST.MSG.FIELD.CLASS_MAPPING.ATTACHMENT_DATA) {

      // attachment specific info
      fields['dataId'] = documentProperty.index;
      fields['contentLength'] = documentProperty.sizeBlock;
    }
  }

  // todo: html body test
  private applyValueConverter(fieldName, fieldTypeMapped, fieldValue) {
    if (fieldTypeMapped === 'binary' && fieldName === 'bodyHTML') {
      return this.convertUint8ArrayToString(fieldValue);
    }
    return fieldValue
  }

  private getFieldType(fieldProperty) {
    const value = fieldProperty.name.substring(12).toLowerCase();
    return value.substring(4, 8);
  }

  // extractor structure to manage bat/sbat block types and different data types
  private extractorFieldValue = {
    sbat: {
      'extractor': (ds, msgData, fieldProperty, dataTypeExtractor) => {
        const chain = this.getChainByBlockSmall(ds, msgData, fieldProperty);
        if (chain.length == 1) {
          return this.readDataByBlockSmall(ds, msgData, fieldProperty.startBlock, fieldProperty.sizeBlock, dataTypeExtractor);
        } else if (chain.length > 1) {
          return this.readChainDataByBlockSmall(ds, msgData, fieldProperty, chain, dataTypeExtractor);
        }
        return null;
      },
      dataType: {
        'string': (ds, msgData, blockStartOffset, bigBlockOffset, blockSize) => {
          ds.seek(blockStartOffset + bigBlockOffset);
          return ds.readString(blockSize);
        },
        'unicode': (ds, msgData, blockStartOffset, bigBlockOffset, blockSize) => {
          ds.seek(blockStartOffset + bigBlockOffset);
          return ds.readUCS2String(blockSize / 2);
        },
        'binary': (ds, msgData, blockStartOffset, bigBlockOffset, blockSize) => {
          ds.seek(blockStartOffset + bigBlockOffset);
          return ds.readUint8Array(blockSize);
        }
      }
    },
    bat: {
      'extractor': (ds, msgData, fieldProperty, dataTypeExtractor) => {
        const offset = this.getBlockOffsetAt(msgData, fieldProperty.startBlock);
        ds.seek(offset);
        return dataTypeExtractor(ds, fieldProperty);
      },
      dataType: {
        'string': (ds, fieldProperty) => {
          return ds.readString(fieldProperty.sizeBlock);
        },
        'unicode': (ds, fieldProperty) => {
          return ds.readUCS2String(fieldProperty.sizeBlock / 2);
        },
        'binary': (ds, fieldProperty) => {
          return ds.readUint8Array(fieldProperty.sizeBlock);
        }
      }
    }
  };

  private readDataByBlockSmall(ds, msgData, startBlock, blockSize, dataTypeExtractor) {
    const byteOffset = startBlock * this.CONST.MSG.SMALL_BLOCK_SIZE;
    const bigBlockNumber = Math.floor(byteOffset / msgData.bigBlockSize);
    const bigBlockOffset = byteOffset % msgData.bigBlockSize;

    const rootProp = msgData.propertyData[0];

    let nextBlock = rootProp.startBlock;
    for (let i = 0; i < bigBlockNumber; i++) {
      nextBlock = this.getNextBlock(ds, msgData, nextBlock);
    }
    const blockStartOffset = this.getBlockOffsetAt(msgData, nextBlock);

    return dataTypeExtractor(ds, msgData, blockStartOffset, bigBlockOffset, blockSize);
  }

  private readChainDataByBlockSmall(ds, msgData, fieldProperty, chain, dataTypeExtractor) {
    const resultData = new Int8Array(fieldProperty.sizeBlock);

    for (let i = 0, idx = 0; i < chain.length; i++) {
      const data = this.readDataByBlockSmall(ds, msgData, chain[i], this.CONST.MSG.SMALL_BLOCK_SIZE, this.extractorFieldValue.sbat.dataType.binary);
      for (let j = 0; j < data.length; j++) {
        resultData[idx++] = data[j];
      }
    }

    const localDs = new DataStream(resultData, 0, DataStream.LITTLE_ENDIAN);
    return dataTypeExtractor(localDs, msgData, 0, 0, fieldProperty.sizeBlock);
  }

  private getChainByBlockSmall(ds, msgData, fieldProperty) {
    const blockChain = [];
    let nextBlockSmall = fieldProperty.startBlock;
    while (nextBlockSmall != this.CONST.MSG.END_OF_CHAIN) {
      blockChain.push(nextBlockSmall);
      nextBlockSmall = this.getNextBlockSmall(ds, msgData, nextBlockSmall);
    }
    return blockChain;
  }

  private getFieldValue(ds, msgData, fieldProperty, typeMapped) {
    let value = null;

    const valueExtractor =
      fieldProperty.sizeBlock < this.CONST.MSG.BIG_BLOCK_MIN_DOC_SIZE ? this.extractorFieldValue.sbat : this.extractorFieldValue.bat;
    const dataTypeExtractor = valueExtractor.dataType[typeMapped];

    if (dataTypeExtractor) {
      value = valueExtractor.extractor(ds, msgData, fieldProperty, dataTypeExtractor);
    }
    return value;
  }

  private convertUint8ArrayToString(uint8ArraValue) {
    return new TextDecoder("utf-8").decode(uint8ArraValue);
  }


    /**
     Converts bytes to fields information
     
     @returns {object} The fields data for MSG file
     */
    public getFileData() {
      if (!this.isMSGFile(this.ds)) {
        return {error: 'Unsupported file type!'};
      }
      if (this.fileData == null) {
        this.fileData = this.parseMsgData(this.ds);
      }
      return this.fileData.fieldsData;
    }
    /**
     Reads an attachment content by key/ID
     
     @returns {object} The attachment for specific attachment key
     */
    public getAttachment(attach) {
      const attachData = typeof attach === 'number' ? this.fileData.fieldsData.attachments[attach] : attach;
      const fieldProperty = this.fileData.propertyData[attachData.dataId];
      const fieldTypeMapped = this.CONST.MSG.FIELD.TYPE_MAPPING[this.getFieldType(fieldProperty)];
      const fieldData = this.getFieldValue(this.ds, this.fileData, fieldProperty, fieldTypeMapped);

      return {fileName: attachData.fileName, content: fieldData};
    }
}
