export function containsUppercase(str): boolean {
  return /[A-Z]/.test(str);
}
export function containLowerCase(str): boolean {
  return (/[a-z]/.test(str));
}
export function containNumber(str): boolean {
  return (/[0-9]/.test(str));
}
export function containsSpecialChars(str): boolean {
  return (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str));
}
// Get password validation logic
export function getPasswordValidationLogic(validation, input, password): any {
  if (input.length >= 8) {
    validation.minimum = true;
  } else {
    validation.minimum = false;
  }
  if (containsUppercase(input) && containLowerCase(input) && containNumber(input)) {
    validation.lowercase = true;
  } else {
    validation.lowercase = false;
  }
  if (containsSpecialChars(input)) {
    validation.specialcharacters = true;
  } else {
    validation.specialcharacters = false;
  }
  if(password){
    if (input === password) {
      validation.passwordMatch = true
    } else {
      validation.passwordMatch = false
    }
  }
  return validation;
}
