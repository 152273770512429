<div class="row popup-container">
    <div class="popup-header row no-margin">
        <div class="col-xs-12 mb-5">
            <app-breadcrumb></app-breadcrumb>
            <button type="button" class="close no-margin" (click)="close()" matTooltip="{{ 'close' | transloco | capitaliz }}"><span aria-hidden="true">
          <mat-icon class="d-text-middle uil uil-multiply"></mat-icon>
        </span><span class="sr-only">Close</span></button>
            <mat-icon *ngIf="log" matTooltip="{{ 'report.contact_moment_notes' | transloco | capitaliz }}" (click)="downloadLog(log.id)" class="export-log-btn uil uil-file-export pull-right d-cursor-hand"></mat-icon>
            <div *hasPermission="[Permission.LogsDelete]">
                <mat-icon *ngIf="log" (click)="deleteLog(log)" class="d-cursor-hand uil uil-trash delete pull-right contact-moments-delete" matTooltip="{{ 'delete' | transloco | capitaliz }}"></mat-icon>
            </div>
        </div>
        <div class="col-xs-12 popup-header-title">
            <h1 *ngIf="logData.log"><strong>{{ 'logs.label' | transloco | capitaliz }}
        </strong>{{ logData.log.contact_moment_timestamp | formatdate : 'dd-MM-yyyy' : globals.timezone }} {{ logData.log.title ? ': ' + logData.log.title : ':(Contactmoment zonder titel)' }}
            </h1>
        </div>


    </div>
    <mat-progress-bar *ngIf="!log" mode="indeterminate"></mat-progress-bar>
    <div class="popup-body" appScroll *ngIf="log">
        <form #form="ngForm" (ngSubmit)="updateLog()" class="log-edit" *transloco="let t;">
            <!-- left col -->
            <div class="col-md-7 col-xs-12">
                <h4 class="mb-15">{{ 'decisions.connections' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 panel-border panel-border-relation-component mb-40">
                    <div>
                        <mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>
                        <app-add-connect-generic (onChange)="updateEntities($event)" parentType="contactmoments" *ngIf="loaded" [parent]="log" [data]="entities" [types]="['issues','requirements','tasks','complaints','activities','documents', 'permits']">
                        </app-add-connect-generic>

                    </div>
                </div>

                <h4 class="mb-15">{{ 'text.general' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel panel-border z-depth-2">
                    <div class="contactmoment-notes-container height-100vh">
                        <div class="col-xs-6">
                            <mat-form-field class="full-width modal-newlog-date">
                              <mat-label>{{ 'reusable.choose_date' | transloco }}</mat-label>
                                <input name="new_log_timestamp" matInput [matDatepicker]="logDatePicker" (click)="logDatePicker.open()" required (dateChange)="updateLog()" [(ngModel)]="log.contact_moment_timestamp" [disabled]="!hasPermission(Permission.LogsUpdate)">
                                <mat-datepicker-toggle matSuffix [for]="logDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #logDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 text-right">
                            <mat-button-toggle-group name="type" aria-label="Font Style" [(ngModel)]="log.contact_moment_type" ngDefaultControl (change)="changeType($event)" [disabled]="!hasPermission(Permission.LogsUpdate)">

                                <mat-button-toggle value="email" ariaLabelledby="Email" [matTooltip]="'Email'" checked="true">
                                    <mat-icon aria-label="Email">email</mat-icon>
                                </mat-button-toggle>

                                <mat-button-toggle value="phone" [matTooltip]="'reusable.telephone' | transloco">
                                    <mat-icon aria-label="Telefoon">phone</mat-icon>
                                </mat-button-toggle>

                                <mat-button-toggle value="meeting" [matTooltip]="'Meeting'">
                                    <mat-icon aria-label="Meeting">people_outline</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                        <div class="col-xs-12">
                            <mat-form-field class="full-width">
                              <mat-label>{{ 'reusable.title' | transloco }}</mat-label>
                                <input name="title" (change)="updateLog()" matInput [(ngModel)]="log.title" [disableControl]="!hasPermission(Permission.LogsUpdate)" />
                            </mat-form-field>
                        </div>


                        <div class="col-xs-12" *ngIf="connectStakeholders">
                            <app-multiselect-checkbox [disabled]="!hasPermission(Permission.LogsUpdate)" type="contactpersons" [placeholder]="'reusable.selecteer_contactpersoon' | transloco" name="employees" [(ngModel)]="log.contactpersons" [log]="log" [logData]="logData" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('employees.labels' | transloco)+' ...'" (onStakeholdersUpdated)="onStakeholdersUpdated($event)"
                                (ngModelChange)="employeeSelected()">
                                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                </app-generic-multiselect-checkbox-option-all>
                                <app-generic-multiselect-checkbox-option *ngFor="let employee of contactpersons" [value]="employee">
                                    {{ employee.first_name }} {{ employee.last_name }}
                                </app-generic-multiselect-checkbox-option>
                                <app-generic-multiselect-checkbox-option-new-action type="contactpersons"></app-generic-multiselect-checkbox-option-new-action>
                                <app-generic-multiselect-search-project entity="contactpersons.stakeholders">
                                </app-generic-multiselect-search-project>
                            </app-multiselect-checkbox>
                        </div>

                        <div class="col-xs-12" *ngIf="connectStakeholders">
                            <app-generic-multiselect-checkbox [disabled]="!hasPermission(Permission.LogsUpdate)" type="stakeholders" #stakeholderMultiselect [placeholder]="'reusable.selecteer_stakeholder' | transloco" name="stakeholders" [maxChipItems]="4" [maxChipLength]="20" (ngModelChange)="updateStakeholders()" [(ngModel)]="log.stakeholders"
                            [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('stakeholders.labels' | transloco)+' ...'" [uniqueId]="'select-stakeholders'">
                                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                </app-generic-multiselect-checkbox-option-all>
                                <app-generic-multiselect-checkbox-option *ngFor="let stakeholder of stakeholders" [value]="stakeholder">
                                    {{ stakeholder.name }}
                                </app-generic-multiselect-checkbox-option>
                                <app-generic-multiselect-checkbox-option-new-action type="stakeholder">
                                </app-generic-multiselect-checkbox-option-new-action>
                                <app-generic-multiselect-search-project entity="stakeholders"></app-generic-multiselect-search-project>
                            </app-generic-multiselect-checkbox>
                            <a *ngIf="sidebarEmployees.emails.unregistered.length > 0" class="d-cursor-hand" (click)="showEmployeeSidebar()">
                                {{ sidebarEmployees.emails.unregistered.length }} {{'reusable.email_address' | transloco}} {{'reusable.unknown' | transloco | lowercase }}
                            </a>
                            <!-- <mat-hint *ngIf="!log.stakeholders.length" class="text-red mat-int-custome-position">{{'decisions.stakeholder_required' | transloco}}</mat-hint> -->
                        </div>

                        <div class="col-xs-12">
                            <span matTooltip="Selecteer eerst meeting als type contactmoment" [matTooltipDisabled]="log.contact_moment_type === 'meeting'">
                <app-generic-multiselect-checkbox [placeholder]="'reusable.select_meeting_series' | transloco" name="series"
                  [(ngModel)]="log.series" [multiple]="false" (ngModelChange)="fetchActionsPerSeries()"
                  [disabled]="log.contact_moment_type !== 'meeting' || !hasPermission(Permission.LogsUpdate)" [uniqueId]="'meeting-series'">
                  <app-generic-multiselect-checkbox-option *ngFor="let serie of series; trackBy: trackByFn"
                    [value]="serie" [accessKey]="'id'">
                    {{ serie.name }}
                  </app-generic-multiselect-checkbox-option>
                  <app-generic-multiselect-checkbox-option-new-action type="generic_type">
                  </app-generic-multiselect-checkbox-option-new-action>
                </app-generic-multiselect-checkbox>
              </span>
                        </div>

                        <div class="col-xs-12" *ngIf="!globals.projectConfigs['residents.disabled']">
                            <app-generic-multiselect-checkbox [disabled]="!hasPermission(Permission.LogsUpdate)" placeholder="Selecteer omwonende(n)" (ngModelChange)="updateLog()" name="residents" [(ngModel)]="log.residents" [maxChipMessage]="'+ {count} '+('reusable.more' | transloco)+' '+('reusable.local_residents' | transloco)+' ...'">
                                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}
                                </app-generic-multiselect-checkbox-option-all>
                                <app-generic-multiselect-checkbox-option *ngFor="let resident of residents" [value]="resident">
                                    {{ resident.name }}
                                </app-generic-multiselect-checkbox-option>
                                <app-generic-multiselect-search-project entity="residents"></app-generic-multiselect-search-project>
                            </app-generic-multiselect-checkbox>
                        </div>
                        <div class="col-xs-12" [ngClass]="{'disable_enitiy': (!hasPermission(Permission.LogsUpdate))}">
                            <app-generic-multiselect-checkbox [chipDisabler]="chipDisabler" [disabled]="!hasPermission(Permission.LogsUpdate) || globals.subprojects.length === 0" [parent]="log" parentType="log" class="subprojects" type="subprojects" [maxChipItems]="3" *ngIf="hasModule('subprojects')" [placeholder]="this.globals.projectConfigs['project.labels.subprojects'] || 'subprojects.label' | transloco"
                                name="subprojects" [(ngModel)]="log.subprojects" [multiple]="true" [maxChipLength]="20" (ngModelChange)="updateSubprojects()" [uniqueId]="'sub-projects'">
                                <app-generic-multiselect-checkbox-option-all>{{'filter_select_all' | transloco}}</app-generic-multiselect-checkbox-option-all>
                                <app-generic-multiselect-checkbox-option *ngFor="let subproject of globals.subprojects" [value]="subproject">
                                    {{ subproject.name }}
                                </app-generic-multiselect-checkbox-option>
                            </app-generic-multiselect-checkbox>
                        </div>
                    </div>
                </div>


            </div>

            <!-- right col -->
            <div class="col-md-5 col-xs-12">
                <div class="row">
                    <div class="col-md-12">
                        <app-comment-box [users]="users" type="contactmoments" [type_id]="log.id"></app-comment-box>
                    </div>
                </div>
                <h4 class="mb-15">{{ 'notes' | transloco | capitaliz }}</h4>
                <div class="col-xs-12 panel z-depth-2 pd-0 mb-40">
                    <div class="contactmoment-notes-container modal-white-background" [ngClass]="{'disable_enitiy': !hasPermission(Permission.LogsUpdate)}">
                        <editor [apiKey]="apiKey" [(ngModel)]="log.description" (onBlur)="updateLog(false,true)" (onKeyUp)="onSetStable()" name="description" [init]="{
                        plugins: 'lists table fullscreen autoresize',
                        branding: false,
                        menubar: false,
                        language: haslanguageCode() === 'en' ? haslanguageCode()+'_GB' : haslanguageCode(),
                        language_url: 'assets/js/tinymce.nl.js',
                        autoresize_on_init: true,
                        statusbar: false,
                        min_height: 900,
                        block_unsupported_drop: false,
                        paste_block_drop: false,
                        paste_data_images: true,
                        paste_as_text: true,
                        fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
                        font_formats: 'Work Sans, sans-serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,times new roman,times,serif;Verdana=verdana,geneva,sans-serif',
                        content_style:'@font-face{font-family: Work Sans;  src: url(../../../../../../../assets/fonts/WorkSans-Regular.ttf)} body { font-family: Work Sans; font-size: 10pt; } .mce-content-body{ line-height: 1.2 !important; margin-bottom: 0 !important; margin-right: 30px; !important; max-width: 100%; overflow-x: hidden; word-break: break-word;} p{margin-block-start: 0px !important; margin-block-end: 0px !important} .mce-content-body table {max-width: 100%;}'
                        }" [toolbar]="['undo redo blocks fontsize table bold italic underline strikethrough lineheight alignleft aligncenter alignright indent numlist bullist removeformat fullscreen']">

                        </editor>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
