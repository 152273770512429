<section>
  <div class="popup-header row no-margin">
    <div class="col-xs-12 mb-5">
        <h1>{{'recycle_bin' | transloco}}</h1>
        <button type="button" class="close" (click)="onClose()">
          <span aria-hidden="true"><mat-icon class="d-text-middle">close</mat-icon>
          </span>
          <span class="sr-only">Close</span>
        </button>
    </div>
    <div class="col-xs-12">
      <p>{{'recycle_bin_info' | transloco}}</p>
    </div>
  </div>
  <mat-dialog-content class="popup-body" appScroll>
    <div class="no-padding overview-margin-bottom-space col-xs-12 text-left box-shadow">
      <div class="table-responsive">
        <table mat-table [dataSource]="entities" class="overview-panel set-border-firefox-table overview-filter-widget-overflow" matSort matSortDisableClear matSortDirection="asc">
          <!-- Batch Selection -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <mat-checkbox (change)="masterSelected($event.checked)" [(ngModel)]="onSelectAll" *ngIf="batchLoad">
              </mat-checkbox>
              <span class="fa-spin loader" *ngIf="!batchLoad">
              </span>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              <mat-checkbox (change)="isSelected($event,entity)" [(ngModel)]="entity.checked">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Id -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">
                {{'ID' | transloco}}
              </div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{entity.id}}
            </td>
          </ng-container>
          <!-- Calendarable Type -->
          <ng-container matColumnDef="calendarable_type" *ngIf="data === 'calendars'">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">
                {{'maps.type' | transloco | capitaliz}}
              </div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{'reusable.'+entity.calendarable_type | transloco | capitaliz}}
            </td>
          </ng-container>
          <!-- Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">
                {{'reusable.name' | transloco}}
              </div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{
                entity.metadata ? entity.metadata.title : 
                entity.name ? entity.name : 
                entity.title ? entity.title :
                (entity.first_name || entity.last_name) ? (entity.first_name ? entity.first_name  : '' + entity.last_name? entity.last_name : '') :
                entity.text
              }}
            </td>
          </ng-container>
          <!-- Deleted At -->
          <ng-container matColumnDef="deleted_at">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">
                {{'reusable.deleted_at' | transloco}}
              </div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{ entity.deleted_at | formatdate : 'dd-MM-yyyy' : globals.timezone }}
            </td>
          </ng-container>
          <!-- By -->
          <ng-container matColumnDef="by">
            <th mat-header-cell *matHeaderCellDef class="col-sm-1 col-xs-1 col-lg-1">
              <div class="display-flex-set">
                {{'reusable.by' | transloco}}
              </div>
            </th>
            <td mat-cell *matCellDef="let entity" class="col-sm-1 col-xs-1 col-lg-1">
              {{ entity.deleted_by ? entity.deleted_by.name ? entity.deleted_by.name : '' : '' }}
            </td>
          </ng-container>
          <!-- Empty -->
          <ng-container matColumnDef="empty">
            <td colspan="10" mat-cell *matFooterCellDef>
              <div *ngIf="!loading" class="empty-oc-height-set w-100 text-center empty-padding">
                <p class="pb-10"></p>
              </div>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </td>
          </ng-container>
          <!-- footer section -->
          <ng-container matColumnDef="footer">
            <td colspan="10" mat-cell *matFooterCellDef class="pagination-top-border">
              <mat-paginator class="table-pagination-set list-view-paginator" [length]="paginator.total" [pageSize]="paginator.count" [pageSizeOptions]="pageSizeOptions" (page)="getServerPage($event)">
              </mat-paginator>
            </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="clickable-row delete-on-hover-tr"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable-row delete-on-hover-tr d-cursor-hand"></tr>
        <tr mat-footer-row *matFooterRowDef="['empty']" [class]="entities.data.length > 0 ? 'hidden' : ''"></tr>
        <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true;"></tr>
      </table>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="popup-footer pull-right">
    <button class="mr-5" type="button" mat-stroked-button data-dismiss="modal" (click)="onClose()">{{ 'close' | transloco | capitaliz }}</button>
    <app-batch-overview-setting [type]="data" modal="restore" [items]="selectionItems" (onloadItem)="onLoadEntity($event)"></app-batch-overview-setting>
  </mat-dialog-actions>
</section>
