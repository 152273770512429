import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from "../common/abstract.service";
import { PaginationParams } from "../common/pagination-params";
import { Observable } from "rxjs";
import { PaginatedResponse } from "../common/paginated-response";
import { ServiceErrorHandler } from '../../../decorator/service-error-handler-decorator';

@Injectable({
  providedIn: 'root'
})
export class DecisionsService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  @ServiceErrorHandler()
  getFilteredDecisions(pagination: PaginationParams, status?: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/decisions', pagination.get(status ? new HttpParams().set('status', status) : null));
  }

  @ServiceErrorHandler()
  exportDecisions(options) {
    return this.http.get(`/file/export/decision?`, {
      params: options,
      responseType: `blob`
    });
  }

  @ServiceErrorHandler()
  getFilteredDecisionsRelations(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get('/api/decisions?dropdown=1', this.httpOptions);
  }

  @ServiceErrorHandler()
  removeDecision(decision) {
    return this.http.delete(`/api/decisions/${ decision.id}`, this.httpOptions);
  }

  @ServiceErrorHandler()
  submitDecision(decision) {
    return this.http.post('/api/decisions', decision, this.httpOptions);
  }

  @ServiceErrorHandler()
  updateDecision(id: number, decision: any) {
    return this.http.put(`/api/decisions/${id}`, decision, this.httpOptions);
  }

  @ServiceErrorHandler()
  getDecision(id, withs?, skipSubproject: boolean = false) {
    let params;
    if (typeof withs !== 'undefined') {
      params = new HttpParams()
        .set('with', withs.join(','));
    }
    if (skipSubproject)
      params = params.append('skip_subproject', '0')

    return this.http.get(`/api/decisions/${id}`, {
      headers: this.headers,
      params: params
    });
  }

  syncRelatics(id, relatics) {
    return this.http.post(`/api/decisions/${id}/relatics`, {
      headers: this.headers,
      params: new HttpParams()
        .set('relatics', relatics)
    })
  }

  detachRelatics(id, relatics) {
    return this.http.delete(`/api/decisions/${id}/detach`, {
      headers: this.headers,
      params: new HttpParams()
        .set('relatics', relatics)
    })
  }
}
