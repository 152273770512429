import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, ViewChild, ChangeDetectionStrategy, VERSION, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { finalize } from 'rxjs/operators';
import { AppInjector } from 'src/app-injector';
import { UndoSnackbarComponent } from 'src/app/shared/components/material/undo-snackbar/undo-snackbar.component';
import { Entities } from '../../../../core/enum/entities';
import { EmployeesService } from '../../../../core/service/api/employees.service';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { CachedService } from '../../../../core/service/common/cached.service';
import { NewEntityModal } from '../../../../core/util/abstract/new-entity-modal';
import { deepCopy } from '../../../../core/util/deepCopy';
import { showFeedbackError } from '../../../../core/util/notification';
import { Globals } from '../../../../globals';
import { NewEntityData } from '../../../directive/new-entity.directive';
@Component({
  selector: 'app-employee-new',
  templateUrl: './employee-new.component.html',
  styleUrls: ['./employee-new.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EmployeeNewComponent extends NewEntityModal implements OnInit, AfterViewInit {
  // Add a property for the options
  public stakeholders: any[];
  declare public entity: any;
  public initialCountryCode: string;
  @ViewChild('form') form: NgForm;
  @ViewChild('phone', { read: ElementRef }) phoneInputElement: ElementRef;
  @ViewChild('personalPhone', { read: ElementRef }) phonePersonalInputElement: ElementRef;
  constructor(@Inject(MAT_DIALOG_DATA) public data: NewEntityData, public dialogRef: MatDialogRef<EmployeeNewComponent>,
    public globals: Globals, private _employeesService: EmployeesService, private _cachedService: CachedService, private cd: ChangeDetectorRef,
    private pubsub: PubsubService, private fb: FormBuilder) {
    super(data, dialogRef, globals);
    dialogRef.disableClose = true;
    this.initialCountryCode = this.globals.countryCode;
  }

  public ngOnInit(): void {

    this._cachedService.getStakeholdersRelations().subscribe(stakeholders => this.stakeholders = stakeholders);
    super.ngOnInit();
    // eslint-disable-next-line no-unused-expressions
    this.data.params ? this.data.params.type ? this.data.params.type === 'contactpersons' ?
      (this.entity.first_name = this.data.params.name.split(' ')[0], this.data.params.name.split(' ')[1] ?
        this.entity.last_name = this.data.params.name.split(' ')[1] : '') : '' : '' : '';

    // crate contactperson from decisions maula_contact_information
    if (this.data.params && this.data.params.type && (this.data.params.type === 'decisions' || this.data.params.type === 'complaints')) {
      this.entity.first_name = this.data.params.name.split(' ')[0];
      if (this.data.params.name.split(' ')[1]) {
        this.entity.last_name = this.data.params.name.split(' ')[1]
      }
      this.entity.email = this.data.params.email ? this.data.params.email : '';
      this.entity.phone = this.data.params.telephone ? this.data.params.telephone : '';
    }
  }

  ngAfterViewInit(): void {
    // Set the placeholder directly
    const inputElement = this.phoneInputElement.nativeElement.querySelector('input');
    const inputElementPersonal = this.phonePersonalInputElement.nativeElement.querySelector('input');
    if (inputElement && inputElementPersonal) {
      inputElement.placeholder = translate('reusable.b_phone_number');
      inputElementPersonal.placeholder = translate('reusable.p_b_phone_number');
    }
    this.cd.detectChanges(); // Ensure the view updates immediately after setting the country
  }

  trigger(event) {
    const inputValue = event.target.value;
    const result = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.entity.first_name = result;
  }

  trigger1(event) {
    this.entity.last_name = event.target.value;;
  }

  public submit() {
    if (this.form.invalid) {
      return;
    }
    this.isProcessing = true;
    this.entity.stakeholder = this.entity.stakeholders;
    this.entity.stakeholders_id = this.entity.stakeholders.id;
    const params = deepCopy(this.entity);
    this._employeesService.submitEmployee(params)
      .pipe(finalize(() => this.isProcessing = false))
      .subscribe(
        data => {
          const snackbar: MatSnackBar = AppInjector ? AppInjector.get(MatSnackBar) : null;
          snackbar.openFromComponent(UndoSnackbarComponent, {
            data: {
              data: data,
              message: translate('error.stored'),
              type: 'openItems',
              item: 'contactpersonen',
              url: 'stakeholders/contactpersonen?employee='
            },
            duration: 4000,
            horizontalPosition: 'start',
            panelClass: 'snackbar-background-green',
          });
          this.dialogRef.close({
            employee: data,
            updated: true,
          });
          this.pubsub.pushNewEntity(Entities.contactpersons, data);
        },
        (e: HttpErrorResponse) => {
          if (e.status === 409 && this.form.controls && this.form.controls.email) {
            const emailControl: FormControl = <FormControl>this.form.controls.email;
            emailControl.setErrors({
              duplicate: true,
            });
            showFeedbackError(e, 'Dit email adres bestaat al', false);
          } else {
            showFeedbackError(e);
          }
        });
  }
  /*
    ng phone input logic
    on change the phone number from dropdown
  */
  public onChangeCountryPhone(country): void {
    // console.log(country)
    // this.entity.phone_code = `+${country.dialCode}`;
  }
  public onChangeCountryPersonal(country): void {
    // console.log(country)
    // this.entity.personal_phone_code = `+${country.dialCode}`;
  }
  /*
    on start change the phone number from dropdown
  */
  public onChangeTelPhone(country): void {
    // this.entity.phone_code = `+${country.s.dialCode}`;
  }
  public onChangeTelPersonal(country): void {
    this.entity.personal_phone_code = `+${country.s.dialCode}`;
  }
  /*
    ng phone input logic end
  */

  public phoneChange(phone) {
    if (phone && phone.value)
      this.entity.phone = phone.value;
  }
  public personalPhoneChange(phone) {
    if (phone && phone.value)
      this.entity.personal_phone = phone.value;
  }
  
  protected initializeEntity(): void {
    this.entity = {
      title: '',
      first_name: null,
      last_name: '',
      department: '',
      function: null,
      phone: '',
      phone_code: '',
      personal_phone: '',
      personal_phone_code: '',
      email: null,
      note: null,
      stakeholders_id: null,
      stakeholders: null,
    };
  }
}
