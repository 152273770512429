import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ServiceErrorHandler } from 'src/app/decorator/service-error-handler-decorator';
import { SearchResponse } from '../../../akita/search/state/search.model';
import { AbstractService } from "../common/abstract.service";
import { PaginatedResponse } from '../common/paginated-response';
import { PaginationParams } from '../common/pagination-params';

@Injectable({
  providedIn: 'root'
})

export class SearchService extends AbstractService {
  constructor(
    private http:HttpClient
  ) {
    super();
  }

  public getSearch (keywords: any, models?: string[], skipSubProject?: boolean): Observable<SearchResponse> {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('keyword', keywords)
      .append('grouped', 'true');
    if (models && models.length > 0) {
      if(!models.includes('type')){
          httpParams = httpParams.append('models', models.join(','));
      }
    }
    if (skipSubProject) {
      httpParams = httpParams.append('skip_subproject', '1');
    }

    return this.http.get<SearchResponse>(`/api/search`, {
      headers: this.headers,
      params: httpParams,
    });
  }
  @ServiceErrorHandler()
  batchDeletion(type,list) {
    return this.http.delete(`/api/${type}/${list}`,this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  batchRestore(type,list) {
    return this.http.put(`/api/${type}/${list}/restore`,this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  getRecycleBinPagination(pagination: PaginationParams, type?: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http.get(`/api/${type}`, pagination.get())
    .pipe(
      shareReplay()
    );
  }
  @ServiceErrorHandler()
  batchMerge(type,data) {
    return this.http.put(`/api/${type}/merge`, data, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  //get country code from the location
  getGeoCoords(points) {
    return this.http.get(`https://nominatim.openstreetmap.org/reverse?format=json&lon=${points[0].x}&lat=${points[0].y}`, this.httpOptions)
    .pipe(
      shareReplay()
    );
  }
  transFormCoder(points) {
    return this.http.get(`https://epsg.io/srs/transform/${points[0]},${points[1]}.json?key=default&s_srs=3857&t_srs=4326`)
    .pipe(
      shareReplay()
    );
  }
  //overview filter export
  @ServiceErrorHandler()
  exportFilterOverview(options, overview) {
    return this.http.get(`/file/export/${overview}`, {params: options, responseType: `blob`})
    .pipe(
      shareReplay()
    );
  }
}
