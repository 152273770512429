import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PubsubService } from '../../../../core/service/api/pubsub.service';
import { RouteInterceptorService } from '../../../../core/service/util/route-interceptor.service';
import { Globals } from '../../../../globals';
import { HeaderGeneralComponent } from '../header-general.component';

@Component({
  selector: 'app-header-fixed',
  templateUrl: './header-fixed.component.html',
  styleUrls: ['./header-fixed.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderFixedComponent extends HeaderGeneralComponent {

  project: any;
  constructor(public pubsub: PubsubService, public globals: Globals,
              public router: Router, public routeInterceptor: RouteInterceptorService) {
    super(pubsub, globals, router, routeInterceptor);
  }

}
