
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { DropzoneComponent } from 'ngx-dropzone-wrapper';
import { Lightbox } from 'ngx-lightbox';
import { Globals } from 'src/app/globals';
import { InAppChatService } from 'src/app/core/service/api/in-app-chat.service';
import { attachmentSplice } from 'src/app/core/util/integration';
import { showFeedbackError, showFeedbackSaved } from 'src/app/core/util/notification';

@Component({
  selector: 'app-chatdocumentupload',
  templateUrl: './chatdocumentupload.component.html',
  styleUrls: ['./chatdocumentupload.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatdocumentuploadComponent implements OnInit {
  @ViewChild('dropzone') dropzone: DropzoneComponent;
  upload: any;
  public file: File = null;
  public responseMessage:any;
  isProcess:boolean=false;
  public dropzoneTemplate = `<div class="mt-10">${translate('reusable.drop_email')})<br><i class="material-icons">cloud_upload</i></div>`;
  public dropzoneConfig: any;
  public attachments:any[]=[];
  public formData:FormData;
  errorMessage_userleve:boolean=false;
  public ticketID:any;
  public integrationType:any;
  public type='ticket-message-attachment'
  constructor(
    private dialogRef: MatDialogRef<ChatdocumentuploadComponent>,
    public globals: Globals,
    public _InAppChatServices:InAppChatService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef,
    private _lightbox: Lightbox
    // eslint-disable-next-line no-empty-function
    ) { }

  ngOnInit() {
    this.ticketID=this.data.id;
    this.integrationType=this.data.type;
    this.dropzoneConfig = {
      url: `${window.location.protocol }//${ window.location.host}`,
      uploadMultiple: this.integrationType=='freshdesk' ? true : false,
      previewsContainer: '.dropzone-previews'
    };
  }
  public close(): void {
    this.dialogRef.close(this.attachments);
  }
  public submit(){
    this.dialogRef.close(this.attachments);
  }
  public DocumentUpLoad(files){
    this.isProcess=true;
    switch(this.integrationType){
      case 'freshdesk':
        this.freshdeskChatDocumentUpload(files)
        break;
      case 'bouwapp':
        this.bouwappChatDocumentUpload(files);
        break;
      case 'whatsapp':
        this.whatsAppChatDocumentUpload(files);
        break;
    }
  }
  public removeDocument(){
    this.attachments=null;
    this.cd.detectChanges();
  }
  //remove file on the base of index
  public removeDocumentPerIndex(value){
    this.attachments=attachmentSplice(this.attachments,value);
    this.cd.detectChanges();
  }

  public bouwappChatDocumentUpload(files){
    this.file = files.target.files[0];
    this._InAppChatServices.attachmentUpload(this.file,this.type).subscribe((res:any)=>{
      this.attachments=res.file;
      this.isProcess=false;
      this.cd.detectChanges();
    },(error)=>{
      this.isProcess=false;
      showFeedbackError(error);
      this.cd.detectChanges();
    });
  }

  public whatsAppChatDocumentUpload(files){
    let docType: any
    this.file = files.target.files[0];
    if(this.file.type.startsWith('image/')) {
      docType= 'image'
    } else if(this.file.type.startsWith('application/') || this.file.type === 'text/plain') {
      docType = 'document'
    }
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    formData.append('type', docType);
    formData.append('messaging_product', 'whatsapp');
    formData.append('recipient_type', 'individual');
    formData.append('to', this.data.to);
    formData.append('complaint_id', this.data.complaintId);

    this._InAppChatServices.attachmentUploadWhatsApp(formData).subscribe((res:any)=>{
      showFeedbackSaved();
      this.attachments=res;
      this.isProcess=false;
      this.cd.detectChanges();
    },(error)=>{
      this.isProcess=false;
      showFeedbackError(error);
      this.cd.detectChanges();
    });
  }

  public freshdeskChatDocumentUpload(files){
    this.formData = new FormData();
    this.attachments= files.target.files;
    this.isProcess=false;
    this.cd.detectChanges();
  }
}
