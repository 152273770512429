import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject, Subscription, combineLatest, throwError } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ProjectConfigsService } from "src/app/akita/project-configs/state/project-configs.service";
import { MapService } from "src/app/core/service/api/map.service";
import { bugsnagFactory } from "src/app/core/util/bugsnagFactory";
import { arcgisTypeDefine, integrationError, integrationValidationArcGis } from "src/app/core/util/integration";
import { Items } from "src/app/core/util/itemsStatus";
import { subLayersAccordionMap, subprojectLayerMap } from "src/app/core/util/mapModal";
import { ModalEnum, modalConfig } from "src/app/core/util/modalConfig";
import { showFeedbackError } from "src/app/core/util/notification";
import { openDataAccordionMap } from "src/app/core/util/openDataModals";
import { ActiveLocationsStore } from "../../../../akita/active-locations/state/active-loacations.store";
import { Complaint } from "../../../../akita/complaints/state/complaint.model";
import { Issue } from "../../../../akita/issues/state/issue.model";
import { Decision } from "../../../../akita/requirements/state/decision.model";
import { SubprojectQuery } from "../../../../akita/subproject/state/subprojects.query";
import { GenericComponent } from "../../../../core/util/abstract/generic-component";
import { dateIso } from "../../../../core/util/dateIso";
import { Globals } from "../../../../globals";
import { AddOpenDataLayersComponent } from "../../../../shared/components/modal/add-open-data-layers/add-open-data-layers.component";
import { ItemLayersAccordionComponent } from "./item-layers-accordion/item-layers-accordion.component";
import * as turf from '@turf/turf';
declare let ol: any;


@Component({
  selector: "app-openlayers-sidebar-v2",
  templateUrl: "./openlayers-sidebar-v2.component.html",
  styleUrls: ["./openlayers-sidebar-v2.component.sass"],
})
export class OpenlayersSidebarV2Component extends GenericComponent implements OnInit, OnDestroy {
  @Output() layerChange: EventEmitter<any> = new EventEmitter();
  @Output() selectedToolChange: EventEmitter<any> = new EventEmitter();
  @Output() purposeToolChange: EventEmitter<any> = new EventEmitter();
  @Output() polygonSelected: EventEmitter<any> = new EventEmitter();
  @Output() ontoggleOverpassLayer: EventEmitter<any> = new EventEmitter();
  @Output() ontoggleOpenDataLayers: EventEmitter<any> = new EventEmitter();
  @Output() newCreationOpenData: EventEmitter<any> = new EventEmitter();
  @Output() changeLayerStyle: EventEmitter<any> = new EventEmitter();
  @Output() loadPublicLayers: EventEmitter<any> = new EventEmitter();
  @Input() buttons: string[] = ["layer", "length", "area"];
  @Input() collapse: boolean = false;
  @Input() displayProgressSpinner: boolean = false;
  @Input() defaultSelectedTool: string;
  @ViewChild("closeLayer") closeLayer: ElementRef;
  @ViewChild(ItemLayersAccordionComponent, { static: false }) public ItemLayersAccordion: ItemLayersAccordionComponent;
  searchResults: any[] = [];
  showResultsContainer: boolean = false;
  private searchSubject = new Subject<string>();
  selectedResultIndex: number | null = null;
  private element: any;
  keyword: any
  private _tabitems: any;
  private _options: any;
  private _container: any;
  private _panes = [];
  private _closeButtons = [];
  public randomId = Math.random().toString(36).substring(7);
  public issues: Issue[] = [];
  public complaints: Complaint[] = [];
  public decisions: Decision[] = [];
  public activities: any = [];
  public integrationEnable: boolean = false;
  private aggregateTimeout: any;
  public createItem = false;
  public showLayers = true;
  public subProjectLayers = {};
  public itemsLocations = {
    stakeholder: [],
    issue: [],
    complaints: [],
    requirements: [],
    activity: [],
  }
  public itemsFilter = {
    stakeholder: [],
    issue: [],
    complaints: [],
    requirements: [],
    activity: [],
  }
  public enableItemsLayer: Items = {
    stakeholder: false,
    issue: false,
    complaints: false,
    requirements: false,
    activity: false,
  };
  public enableLayerResidents = false;
  public enableLayerStakeholders = false;
  public enableLayerIssues = false;
  public enableLayerComplaints = false;
  public enableLayerDecisions = false;
  public enableLayerActivities = false;
  public enableStakeholdersPolygons = false;
  public potentialStakeholderToggle = false;
  public showToggles: boolean = true;
  public showContent: boolean = true;
  public issueRange: any;
  public issueMin: number;
  public issueMax: number;
  public complaintRange: any;
  public complaintMin: number;
  public complaintMax: number;
  public decisionRange: any;
  public decisionMin: number;
  public decisionMax: number;
  public layers = {
    arcgis: [],
    subproject: [],
    openData: []
  };
  public integrationCheck: string;
  public enableLayers = [];
  public selectedTool = "";
  public purposeTool = "";
  public tool = "";
  private subscriptions: Subscription[] = [];
  public loading: boolean = true;
  public loadingSearch: boolean = false;
  public is_loadOpenData: boolean = false;
  public desableShow: boolean = false;
  public activeArcGis: string = 'arcgis';
  public togglerActivites: string;
  public errorIntegration: boolean = false;
  public errorIntegrationEnterprise: boolean = false;
  public arcGisLayers: any[] = [];
  public BBOX: any[] = [];
  @Input() type: string;
  constructor(
    private cd: ChangeDetectorRef,
    public globals: Globals,
    public _projectConfigsService: ProjectConfigsService,
    private subProjectQuery: SubprojectQuery,
    private activeLocationsStore: ActiveLocationsStore,
    public dialog: MatDialog,
    public _mapServices: MapService,
    public router: Router,
    private elementRef: ElementRef
  ) {
    super(globals);

    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(res => {
        let stringUsingToString
        if (this.globals.projectConfig.polygons) {
          const feature = turf.polygon([this.globals.projectConfig.polygons.coordinates[0][0]]);
          // Calculate bounding box using Turf.js
          const bbox = turf.bbox(feature);
          // Bounding Box coordinates
          const boundingBox = `${bbox[0]}, ${bbox[1]}, ${bbox[2]}, ${bbox[3]}`;
          // Using toString method
          stringUsingToString = boundingBox.toString();
        }
        this._projectConfigsService.getMapAddress(res, stringUsingToString).subscribe((results: any[]) => {
          this.loadingSearch = false
          this.showResultsContainer = true;
          this.searchResults = results;

          this.cd.detectChanges(); // Manually trigger change detection

          // Remove existing markers

        });
      })


  }



  ngOnInit() {
    this.activeArcGis = arcgisTypeDefine(this.globals);
    this.errorIntegration = integrationError(this.globals, 'arcgis_enabled');
    this.errorIntegrationEnterprise = integrationError(this.globals, 'arcgis_enterprise_enabled');
    if ((document.location.hostname === "app.getdialog.dev" || document.location.hostname === "app.getdialog.nl") && this.globals.project.customers && this.globals.project.customers.name && (this.globals.project.customers.name.toLowerCase().includes("van gelder") || this.globals.project.customers.name.toLowerCase().includes("company"))) {
      // enable the bulk adding feature
      this.enableStakeholdersPolygons = true;
    }
    this.integrationCheck = integrationValidationArcGis(this.globals, 'arcgis', 'arcgis_enterprise', 'arcgis_enabled', 'arcgis_enterprise_enabled', this.errorIntegration, this.errorIntegrationEnterprise);
    if (this.integrationCheck === 'project') {
      this.integrationEnable = true;
      this.initIntegrationLayers();
    }
    if (this.integrationCheck === 'personal') {
      this.integrationEnable = true;
      this.initIntegrationLayers(true);
    }

    // Hide Sidepanel in mobile Screen
    if (window.innerWidth <= 480) {
      document.querySelector("#sidebar").classList.add("collapsed");
      this.showContent = false;
    }


    this.togglerActivites = this.purposeTool;
    this.initControlSidebar();
    this.initSidebarClickEvents();
    this.initSidebarOpen();
    this.initSidebarClose();
    this.initSidebarSelectedTool();
    this.initSubProjectLayers();
    this.loadOpenData();
  }

  search(term: string): void {
    if (term.trim() !== '') {
      this.loadingSearch = true
      this.searchSubject.next(term);
    } else {
      this.loadingSearch = false
      this.showResultsContainer = false; // Hide container when input is empty
      this._mapServices.vectorSource.clear();
      this._mapServices.popupOverlay.setPosition(undefined);
    }
  }

  panToLocation(result: any): void {
    this._mapServices.keyword = result.display_name;
    this._mapServices.vectorSource.clear();
    this._mapServices.setPanLocation(result)
  }

  onItemClick(index: number) {
    this.selectedResultIndex = index;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    // Check if the clicked element is outside the search results container
    if (!clickedElement.classList.contains('search-result-item')) {
      this.showResultsContainer = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  // on toggle all items
  public ontoggleChangeAllItem(event, type, action = false) {
    this.ItemLayersAccordion.ontoggleChangeAllItem(event, type, action);
  }

  public updateComplaintFilter(data): void {
    this.itemsLocations['complaints'] = this.itemsFilter['complaints']
      .filter((complaint: Complaint) => {
        const time = dateIso(complaint.date, true, this.globals.timezone).getTime();
        const splitsStart = data[0].split("-");
        const splitsEnd = data[1].split("-");
        const startTime = new Date(parseInt(splitsStart[2], 10), parseInt(splitsStart[1], 10) - 1, parseInt(splitsStart[0], 10));
        startTime.setHours(0, 0, 0, 0);
        const endTime = new Date(parseInt(splitsEnd[2], 10), parseInt(splitsEnd[1], 10) - 1, parseInt(splitsEnd[0], 10));
        endTime.setHours(23, 59, 59, 999);
        return time >= startTime.getTime() && time <= endTime.getTime();
      }).map((complaint) => complaint);
    clearTimeout(this.aggregateTimeout);
    this.aggregateTimeout = setTimeout(() => this.aggregateData(false), 300);
  }

  public updateIssueFilter(data): void {
    this.itemsLocations['issue'] = this.itemsFilter['issue']
      .filter((issue: any) => {
        const time = dateIso(issue.date, true, this.globals.timezone
        ).getTime();
        const splitsStart = data[0].split("-");
        const splitsEnd = data[1].split("-");
        const startTime = new Date(
          parseInt(splitsStart[2], 10),
          parseInt(splitsStart[1], 10) - 1,
          parseInt(splitsStart[0], 10)
        );
        startTime.setHours(0, 0, 0, 0);
        const endTime = new Date(
          parseInt(splitsEnd[2], 10),
          parseInt(splitsEnd[1], 10) - 1,
          parseInt(splitsEnd[0], 10)
        );
        endTime.setHours(23, 59, 59, 999);
        return time >= startTime.getTime() && time <= endTime.getTime();
      })
      .map((issue) => issue);

    clearTimeout(this.aggregateTimeout);
    this.aggregateTimeout = setTimeout(() => this.aggregateData(false), 300);
  }

  public formatFilter() {
    return {
      to: (value) => {
        if (value && typeof value === "number") {
          // We format the date by our self
          const dateObj = new Date(value);
          const date = (`0${dateObj.getDate()}`).slice(-2);
          const month = (`0${dateObj.getMonth() + 1}`).slice(-2);
          const year = dateObj.getFullYear();

          return `${date}-${month}-${year}`;
        }

        return value;
      },
      from: (value) => {
        return value;
      },
    };
  }

  public updateDecisionFilter(data): void {
    this.itemsLocations['requirements'] = this.itemsFilter['requirements']
      .filter((decision: any) => {
        if (!decision.date) {
          return false;
        }
        const decisionStart = dateIso(decision.date, true, this.globals.timezone);
        const decisionEnd = dateIso(decision.end_date, true, this.globals.timezone);
        const splitsStart = data[0].split("-");
        const splitsEnd = data[1].split("-");
        const startTime = new Date(
          parseInt(splitsStart[2], 10),
          parseInt(splitsStart[1], 10) - 1,
          parseInt(splitsStart[0], 10)
        );
        startTime.setHours(0, 0, 0, 0);
        const endTime = new Date(
          parseInt(splitsEnd[2], 10),
          parseInt(splitsEnd[1], 10) - 1,
          parseInt(splitsEnd[0], 10)
        );
        endTime.setHours(23, 59, 59, 999);
        if (!decisionEnd) {
          return decisionStart >= startTime && decisionStart <= endTime;
        }
        // Check if decision is within range of the filter
        // Done by negation of "outside range" situation
        return !(
          (decisionStart < startTime && decisionEnd < startTime) ||
          (decisionStart > endTime && decisionEnd > endTime)
        );

      })
      .map((decision) => decision);

    clearTimeout(this.aggregateTimeout);
    this.aggregateTimeout = setTimeout(() => this.aggregateData(false), 300);
  }

  public updateActivityFilter(data): void {
    this.itemsLocations['activity'] = this.itemsFilter['activity']
      .filter((activity: any) => {
        if (!activity.date) {
          return true;
        }
        const activityStart = dateIso(activity.date, true, this.globals.timezone);
        const activityEnd = dateIso(activity.end_date, true, this.globals.timezone);
        const splitsStart = data[0].split("-");
        const splitsEnd = data[1].split("-");
        const startTime = new Date(
          parseInt(splitsStart[2], 10),
          parseInt(splitsStart[1], 10) - 1,
          parseInt(splitsStart[0], 10)
        );
        startTime.setHours(0, 0, 0, 0);
        const endTime = new Date(
          parseInt(splitsEnd[2], 10),
          parseInt(splitsEnd[1], 10) - 1,
          parseInt(splitsEnd[0], 10)
        );
        endTime.setHours(23, 59, 59, 999);
        if (!activityEnd) {
          return activityStart >= startTime && activityStart <= endTime;
        }
        // Check if activity is within range of the filter
        // Done by negation of "outside range" situation
        return !(
          (activityStart < startTime && activityEnd < startTime) ||
          (activityStart > endTime && activityEnd > endTime)
        );

      })
      .map((activity: any) => activity);

    clearTimeout(this.aggregateTimeout);
    this.aggregateTimeout = setTimeout(() => this.aggregateData(false), 300);
  }
  //triger result
  private aggregateData(resetFilter: boolean = true, layer: any = false, toggle: boolean = true): void {
    const data = this.itemsLocations['stakeholder']
      .concat(this.itemsLocations['issue'])
      .concat(this.itemsLocations['complaints'])
      .concat(this.itemsLocations['requirements'])
      .concat(this.itemsLocations['activity'])
    this.activeLocationsStore.set(data);
    this.layerChange.emit({ data, resetFilter, layer, toggle });
    this.showToggles = true;
  }

  private initControlSidebar(): void {
    const self = this;
    ol.control.Sidebar = function (settings) {
      const defaults = {
        element: null,
        position: "left",
      };

      self._options = Object.assign({}, defaults, settings);

      self.element = document.querySelector(`.sidebar-${self.randomId}`);
      ol.control.Control.call(this, {
        element: self.element,
        target: self._options.target,
      });

      // Attach .sidebar-left/right class
      self.element.classList.add(`sidebar-${self._options.position}`);

      // Find sidebar > div.sidebar-content
      for (let i = self.element.children.length - 1; i >= 0; i--) {
        const child = self.element.children[i];
        if (
          child.tagName === "DIV" &&
          child.classList.contains("sidebar-content")
        ) {
          self._container = child;
        }
      }

      // Find sidebar ul.sidebar-tabs > li, sidebar .sidebar-tabs > ul > li
      self._tabitems = self.element.querySelectorAll(
        "ul.sidebar-tabs > li, .sidebar-tabs > ul > li"
      );
      for (let i = self._tabitems.length - 1; i >= 0; i--) {
        self._tabitems[i]._sidebar = this;
      }

      // Find sidebar > div.sidebar-content > div.sidebar-pane
      if (self._container) {
        for (let i = self._container.children.length - 1; i >= 0; i--) {
          const child = self._container.children[i];
          if (
            child.tagName == "DIV" &&
            child.classList.contains("sidebar-pane")
          ) {
            self._panes.push(child);

            const closeButtons = child.querySelectorAll(".sidebar-close");
            for (let j = 0, len = closeButtons.length; j < len; j++) {
              self._closeButtons.push(closeButtons[j]);
            }
          }
        }
      }


      for (let i = self._tabitems.length - 1; i >= 0; i--) {
        const child = self._tabitems[i];
        const sub = child.querySelector("a");
        if (
          sub.hasAttribute("href") &&
          sub.getAttribute("href").slice(0, 1) == "#"
        ) {
          sub.onclick = this._onClick.bind(child);
        }
      }

      for (let i = self._closeButtons.length - 1; i >= 0; i--) {
        const child = self._closeButtons[i];
        child.onclick = this._onCloseClick.bind(this);
      }
    };

    ol.inherits(ol.control.Sidebar, ol.control.Control);
  }

  private initSidebarClickEvents(): void {
    ol.control.Sidebar.prototype._onClick = function (evt) {
      evt.preventDefault();
      if (this.classList.contains("active")) {
        this._sidebar.close();
      } else if (!this.classList.contains("disabled")) {
        this._sidebar.open(this.querySelector("a").hash.slice(1));
      }
    };

    ol.control.Sidebar.prototype._onCloseClick = function () {
      this.close();
    };
  }

  private initSidebarOpen(): void {
    const self = this;
    ol.control.Sidebar.prototype.open = function (id) {
      let i, child;

      // hide old active contents and show new content
      for (i = self._panes.length - 1; i >= 0; i--) {
        child = self._panes[i];
        if (child.id == id) child.classList.add("active");
        else if (child.classList.contains("active"))
          child.classList.remove("active");
      }

      // remove old active highlights and set new highlight
      for (i = self._tabitems.length - 1; i >= 0; i--) {
        child = self._tabitems[i];
        if (child.querySelector("a").hash == `#${id}`)
          child.classList.add("active");
        else if (child.classList.contains("active"))
          child.classList.remove("active");
      }

      // open sidebar (if necessary)
      if (self.element.classList.contains("collapsed")) {
        self.element.classList.remove("collapsed");
      }

      return this;
    };
  }

  private initSidebarClose(): void {
    const self = this;
    ol.control.Sidebar.prototype.close = function () {
      // remove old active highlights
      for (let i = self._tabitems.length - 1; i >= 0; i--) {
        const child = self._tabitems[i];
        if (child.classList.contains("active"))
          child.classList.remove("active");
      }

      // close sidebar
      if (!self.element.classList.contains("collapsed")) {
        self.element.classList.add("collapsed");
      }

      return this;
    };
  }

  private initSidebarSelectedTool(): void {
    if (
      this.buttons.length > 0 &&
      this.defaultSelectedTool &&
      this.buttons.includes(this.defaultSelectedTool)
    ) {
      this.setSelectedTool(this.defaultSelectedTool);
    }
  }

  private initSubProjectLayers(): void {
    this.layers['subproject'] = subprojectLayerMap(this.subProjectQuery.getAll(), 'subproject');
  }

  sidebarClose() {
    document.querySelector("#sidebar").classList.add("collapsed");
    this.showContent = false;
  }

  public setSelectedTool(type: string): void {
    if(type === 'point') {
      this._mapServices.setData(undefined)
    }else {
      this._mapServices.setData(null)
    }
    // this.onLayerChange.emit([]);
    if (type !== "layer" && this.closeLayer) {
      // this.closeLayer.nativeElement.click();
    }

    this.selectedTool = type;
    this.purposeTool = type;
    this.togglerActivites = this.purposeTool;
    if (type === "add-stakeholder") {
      this.purposeTool = "add-stakeholder";
      type = "area";
    }
    if (type === "layer" || type === "draw") {
      this.createItem = !this.createItem;
      this.showLayers = !this.showLayers;
    }

    this.purposeToolChange.emit(this.purposeTool);

    switch (type) {
      case "point":
      case "area":
      case "length":
        this.selectedToolChange.emit(type);
        break;
      default:
        this.selectedToolChange.emit(null);
        break;
    }
  }

  public setTool(type: string): void {
    switch (type) {
      default:
        if (this.tool == type) {
          this.selectedToolChange.emit(null);
          this.tool = "";
        } else {
          this.selectedToolChange.emit(null);
          this.tool = "";
          this.selectedToolChange.emit({ type });
          this.tool = type;
        }
        break;
    }
  }
  //Overpass Layer load on map
  public toggleOverpassLayer(layer) {
    this.potentialStakeholderToggle = layer.checked;
    this.ontoggleOverpassLayer.emit(layer);
    this.cd.detectChanges();
  }

  public toggleOpenDataLayers(layersName) {
    this.ontoggleOpenDataLayers.emit(layersName);
  }

  public resetSelectedTool(): void {
    this.selectedTool = "";
  }
  /*
    ArcGis Integration Layers Setup
  */
  public async initIntegrationLayers(is_user = false) {
    this.desableShow = true;
    this.loading = false;
    // if (this.globals.enableLayers) {
    //   this.arcGisLayers = this.globals.enableLayers;
    //   await this.sleep(3000);
    //   this.setLayersResponce(this.arcGisLayers, 'name', 'arcgis');
    //   this.loading = true;
    //   this.cd.detectChanges();
    //   return true;
    // }
    combineLatest([
      this._projectConfigsService.getEnableFeatureLayers(this.activeArcGis, is_user),
      this._projectConfigsService.getFreshToken(this.activeArcGis, is_user)
    ])
      .pipe(
        tap(async ([layers, arcgis]) => {
          this.setArcGisSubLayers(layers, arcgis);
        }),
        catchError((err) => {
          bugsnagFactory().notify(JSON.stringify({
            message: 'ArcGis Account Is Not Verified',
            data: err,
          }));
          this.globals.projectConfigs['services.integration.arcgis.enabled'] = false;
          this.globals.projectConfigs['services.integration.arcgis_enterprise.enabled'] = false;
          showFeedbackError(err);
          return throwError(err);
        }),
      ).subscribe();
  }
  /*
    ArcGis Layers Into Sub Layers
  */
  public setArcGisSubLayers(layers: any, arcgis: any) {
    this.arcGisLayers = [];
    if (layers && layers.length) {
      layers.forEach(layer => {
        this._mapServices.getArcGisSubLayers(layer.url, arcgis.token)
          .pipe(
            tap(async (res: any) => {
              res.forEach(item => {
                let foundObject = null;
                if (layer.metadata) {
                  if (layer.metadata.length) {
                    foundObject = layer.metadata.find(data => data.layerId === item.id);
                  }
                }
                this.arcGisLayers.push({
                  id: layer.id,
                  name: layer.name,
                  main_layer_id: layer.id,
                  layer_name: item.name,
                  layer_id: item.id,
                  type: item.type,
                  geometry_type: item.geometryType,
                  url: layer.url,
                  owner: layer.owner,
                  enabled: layer.enabled,
                  metadata: foundObject ? foundObject.metadata : null,
                  alldata: layer.metadata ? layer.metadata : null,
                });
              });
              await this.sleep(3000);
              this.setLayersResponce(this.arcGisLayers, 'name', 'arcgis');
              this.globals.enableLayers = this.arcGisLayers;
              this.loading = true;
              this.cd.detectChanges();
            })
          ).subscribe();
      });
    } else {
      this.globals.enableLayers = this.arcGisLayers;
      this.loading = true;
      this.cd.detectChanges();
    }
  }
  //set layer responce
  public setLayersResponce(response, unique, type) {
    this.layers[type] = subLayersAccordionMap(response, unique, type);
  }
  //on change style
  public onChangeStyle(layer) {
    this.changeLayerStyle.emit(layer)
  }
  //on items select
  public toggleitemsLayers(map) {
    this.enableItemsLayer[map.type] = map.item.status;
    this.itemsLocations[map.type] = map.location;
    this.itemsFilter[map.type] = map.location;
    this.aggregateData(true, map.item, map.toggle);

    this._mapServices.setOverlayPopup(map.location)
    if (map.action)
      this.newCreationOpenData.emit();
  }
  //add open data layers modal
  public addOpenDataLayers() {
    const dialog = this.dialog.open(AddOpenDataLayersComponent, modalConfig({
      panelClass: ['animate__animated', 'animate__slideInUp'],
      disableClose: true,
      closeOnNavigation: true
    }, ModalEnum.ModalSmall));
    dialog.afterClosed().subscribe((response: any) => {
      if (response) {
        this.loadOpenData();
      }
    });
  }
  //load all open data function
  public loadOpenData() {
    this.is_loadOpenData = true;
    this.cd.detectChanges()
    this._mapServices.getOpenData()
      .pipe(
        tap(res => {
          this.layers['openData'] = openDataAccordionMap(res);
          this.loadPublicLayers.emit();
          this.is_loadOpenData = false;
          this.cd.detectChanges()
        })
      ).subscribe();
  }
  /*
    Sleep function for map loadding
  */
  public sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
