import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, from, throwError} from "rxjs";
import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import {catchError, concatMap, shareReplay, tap} from 'rxjs/operators';

import {Globals} from './globals';
import {environment} from '../environments/environment';

@Injectable()
export class AuthConstants {
  public token: string;

  // eslint-disable-next-line no-empty-function
  constructor(private globals: Globals) {}

  // Create an observable of Auth0 instance of client
  public auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/${environment.auth0.redirectURI}`,
        ui_locales: 'nl',
        audience: environment.auth0.audience,
      },
      leeway: 604800,
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  public isAuthenticated$: Observable<boolean> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  public handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  public userProfileSubject$ = new BehaviorSubject<any>(null);

  public userProfile$ = this.userProfileSubject$.asObservable();

  // Create a local property for login status
  public loggedIn: boolean = null;

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  public getUser$: (options?) => Observable<any> = (options?) => {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      tap(user => this.userProfileSubject$.next(user))
    );
  }
}
