import { Component } from '@angular/core';
import { PubsubService } from 'src/app/core/service/api/pubsub.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BreadCrumb {

  constructor(
    public pubsub: PubsubService
  // eslint-disable-next-line no-empty-function
  ) {
  }

  backToOverview() {
    if (this.pubsub.currentDialog) {
      this.pubsub.closeModal(this.pubsub.currentDialog)
      this.pubsub.resetHistory();
    }
  }

  back() {
    this.pubsub.prevPanel()
  }

  next() {
    this.pubsub.nextPanel()
  }
  //remove html tags
  public removeHTMLTags(text): void{
    return text.replace(/<[^>]*>/g, '');
  }
}
