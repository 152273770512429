import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AbstractService} from '../../../core/service/common/abstract.service';
import {Observable, forkJoin, of} from 'rxjs';
import {ServiceErrorHandler} from '../../../decorator/service-error-handler-decorator';
import {Project} from './project.model';
import {ProjectsStore} from './projects.store';
import {ProjectQuery} from './projects.query';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {WithParams} from '../../../core/service/common/with-params';
import {ID} from '@datorama/akita';
import {Globals} from '../../../globals';
import {Subproject} from '../../subproject/state/subproject.model';
import {SubprojectsService} from '../../subproject/state/subprojects.service';
import {ProjectConfigsStore} from '../../project-configs/state/project-configs.store';
import { User } from '../../users/state/user.model';
import { SubprojectQuery } from '../../subproject/state/subprojects.query';
import { TranslocoService } from '@jsverse/transloco';
import { setDefaultLanguage } from '../../multilingual/multilingual.model';
import { PaginationParams } from 'src/app/core/service/common/pagination-params';
import { PaginatedResponse } from 'src/app/core/service/common/paginated-response';

@Injectable({ providedIn: 'root' })
export class ProjectsService extends AbstractService {

  constructor(private projectsStore: ProjectsStore, private projectQuery: ProjectQuery, private projectConfigsStore: ProjectConfigsStore,
    private subProjectQuery: SubprojectQuery,private _subprojectsService: SubprojectsService, private http: HttpClient,private readonly translocoService: TranslocoService) {
    super();
  }

  @ServiceErrorHandler()
  public get(): Observable<Project[]> {
    return <Observable<Project[]>> this.http.get(`/api/projects`, this.httpOptions)
      .pipe(
        tap((response: Project[]) => {
          this.projectsStore.set(response);
        }),
        catchError(() => []),
      );
  }

  public getProjectData(projectCode: string, globals: Globals): Observable<any[]> {
    const projectConfigs = this.http.get(`/api/projects/${projectCode}`, this.httpOptions).pipe(
      catchError( val => of(val))
    );

    const subProjects = this._subprojectsService.get(true).pipe(
      catchError( val => of(val))
    );

    return forkJoin([projectConfigs, subProjects]).pipe(
      tap((response: [any, Subproject[]]) => {
        const configs = response[0];
        if (configs instanceof HttpErrorResponse) {
          return;
        }

        globals.project = configs.project;
        globals.projects = configs.projects;
        globals.subprojects = response[1];

        const activeProject = this.projectQuery.getActive();

        if (!activeProject || (activeProject && activeProject.code !== projectCode)) {
          this._subprojectsService.set(null);
        }
        this.projectsStore.set(configs.projects);
        this.projectsStore.setActive(projectCode);
        this.projectConfigsStore.set(configs.project_config);
        this.projectConfigsStore.setActive(1);

        globals.modules = configs.modules;
        globals.fbNotificationBasePath = configs.fb_notification_base_path;
        globals.user = {
          id: configs.users.id,
          name: configs.users.name,
          email: configs.users.email,
          global_id:configs.users.global_id,
          configs: configs.users.configs,
          permissions: configs.users.permissions,
          isAdmin: configs.users.is_admin,
          scope: configs.users.scope,
          personalization:configs.users.personalization,
          is_mfa_enabled: configs.users.is_mfa_enabled,
          is_sso_enabled: configs.users.is_sso_enabled
        };
        globals.headUser = configs.head_user;
        if(configs.project_config){
          globals.projectConfig = configs.project_config;
        }else{
          globals.projectConfig = {"id":1,"projects_id":1,"default_map":"355212.0265278738,6540361.73207577,978326.6811086306,6906036.475392054"};
        }
        globals.projectConfigs = configs.project_configs;
        globals.personalization = configs.personalization;
        globals.customer_personalization = configs.customer_personalization;
        globals.project_personalization = configs.project_personalization;
        globals.relaticsMenus = configs.relatics_menus;
        globals.relaticsBlueprints = configs.relatics_blueprint;
        globals.googleMapApiKey = configs.google_api_key;
        globals.permissions=configs.users.permissions;
        //set default language
        this.translocoService.setActiveLang(setDefaultLanguage(configs,'users'));
        // Combine the object of project object from the array because it contains acl_head_user and head_communication key
        globals.project = {...globals.project, ...globals.projects.find(p => p.code === globals.project.code)};
        globals.initialized.next(globals);
        window.localStorage.setItem('project_name',configs.project.code);
      })
    );
  }

  @ServiceErrorHandler()
  public toggleCompanyMfaStatus(customerId: number, status: boolean){
    return this.http.put<boolean>(`/api/admin/customers/${customerId}`, {"is_mfa_enabled": status}, this.httpOptions)
  }

  @ServiceErrorHandler()
  public toggleSessionEnforcementSSO(customerId: number, status: boolean) {
    return this.http.put<boolean>(`/api/admin/customers/${customerId}`, {"is_enforcement_enabled": status}, this.httpOptions)
  }

  @ServiceErrorHandler()
  public getCompanyData(): Observable<any[]> {
    return <Observable<any[]>> this.http.get(`/api/admin/customers?with=projects,archiveprojects,personalization,users,sso,modules&withCount=modules,users`, this.httpOptions)
  }

  @ServiceErrorHandler()
  public getCompanyUsers(company): Observable<any[]> {
    return <Observable<any[]>> this.http.get(`/api/admin/projects/users/${company}`, this.httpOptions)

  }

  @ServiceErrorHandler()
  public getProjectByCode(projectCode: string): Observable<any[]> {
    return <Observable<any[]>> this.http.get(`/api/admin/projects/${projectCode}?with=personalization,modules,users,configs`, this.httpOptions)

  }

  @ServiceErrorHandler()
  public create(project: Project,company:string): Observable<Project> {
    return <Observable<Project>> this.http.post(`/api/admin/projects/${company}`, project, this.httpOptions)
      .pipe(
        tap((response: Project) => project = response),
        catchError((err) => project = null)
      );
  }


  @ServiceErrorHandler()
  public removeprojectByCode(name:string): Observable<User> {
    return <Observable<User>> this.http.delete(`/api/admin/projects/${name}`, this.httpOptions)

  }
  @ServiceErrorHandler()
  public update(project: any): Observable<Project> {
    return <Observable<Project>> this.http.put(`/api/admin/projects/${project.code}?with=personalization`, project, this.httpOptions)
      .pipe(
        shareReplay()
      )

  }

  @ServiceErrorHandler()
  public getModules(): Observable<any[]> {
    return <Observable<any[]>> this.http.get('/api/admin/modules', this.httpOptions)

  }


  @ServiceErrorHandler()
  public addUser(user: User,project:string): Observable<User> {
    return <Observable<User>> this.http.post(`/api/admin/projects/${project}/users`, user, this.httpOptions)

  }

  @ServiceErrorHandler()
  public updateUser(user: User,project:string): Observable<User> {
    return <Observable<User>> this.http.put(`/api/admin/projects/${project}/users/update`, user, this.httpOptions)

  }

  @ServiceErrorHandler()
  public removeUser(user: User,project:string): Observable<User> {
    return <Observable<User>> this.http.delete(`/api/admin/projects/${project}/users/${user.id}`, this.httpOptions)

  }

  public select(projectCode: ID, withParams?: WithParams): Observable<Project> {
    return <Observable<Project>> this.http.get(`/api/projects/${projectCode}`, withParams ? withParams.get() : this.httpOptions)
      .pipe(
        tap((response: Project) => {
          this.projectsStore.update(response.code, response);
        })
      );
  }

  public set(project: Project): void {
    if (project) {
      this.projectsStore.setActive(project.code);
    } else {
      this.projectsStore.setActive(null);
    }
  }
  @ServiceErrorHandler()
  public projectArchive(name:string,projectCode:string): Observable<any[]> {
    const code={'code':projectCode}
    return <Observable<any[]>> this.http.post(`/api/admin/archive/${name}`,code, this.httpOptions)

  }
  @ServiceErrorHandler()
  public getProfileId(): Observable<any[]> {
    return <Observable<any[]>> this.http.get('/api/admin/profile', this.httpOptions)
  }
  @ServiceErrorHandler()
  public getUserRoles() {
    return this.http.get('/api/admin/rbac', this.httpOptions)
  }
  @ServiceErrorHandler()
  public transferUser(data) {
    return this.http.post('/api/admin/rbac/migrate',data, this.httpOptions)
  }
  itemsSearch(keyword) {
    return this.http.get(`/api/search?keyword=${keyword}&grouped=true&models=complaints,requirements,issues,stakeholders,contactmoments,activities,contents`,this.httpOptions);
  }
  @ServiceErrorHandler()
  getAllMessages(type,id,page) {
    return this.http.get(`/api/comments?type=${type}&type_id=${id}&page=${page}&count=4`,this.httpOptions);
  }
  @ServiceErrorHandler()
  sendMessages(type,id,page,data) {
    return this.http.post(`/api/comments?type=${type}&type_id=${id}&page=${page}&count=4`,data,this.httpOptions);
  }
  @ServiceErrorHandler()
  getAllCollaborators(type,id) {
    return this.http.get(`/api/collaborators?type=${type}&type_id=${id}`,this.httpOptions);
  }
  @ServiceErrorHandler()
  saveCollaborators(type,id,data) {
    return this.http.post(`/api/collaborators?type=${type}&type_id=${id}`,data,this.httpOptions);
  }
  @ServiceErrorHandler()
  updateLike(id,data) {
    return this.http.post(`/api/comments/${id}/like`,data,this.httpOptions);
  }
  @ServiceErrorHandler()
  deleteMessage(id) {
    return this.http.delete(`/api/comments/${id}`,this.httpOptions);
  }
  @ServiceErrorHandler()
  updateMessage(id,data) {
    return this.http.put(`/api/comments/${id}`,data,this.httpOptions);
  }

  @ServiceErrorHandler()
  getActivityLogs(pagination: PaginationParams): Observable<PaginatedResponse>{
    return <Observable<PaginatedResponse>>this.http.get('/api/activity-logs', pagination.get());
  }

  @ServiceErrorHandler()
  setActivityLogs(action: any): Observable<any>{
    return this.http.get(`/api/log-user-action?action=${action}`, this.httpOptions);
  }
}
