import { translate } from '@jsverse/transloco';
export function relaticError(origObj: any): any {
    let data=[];
    if(!Array.isArray(origObj)){
        return origObj;
    }
    if(origObj){
        if(origObj.length>0){
            if(origObj[0].element){
                data=origObj.map(element => {
                    return `${element.property} ${translate('settings.relatic.column_error')}`
                });
                return data.join(', ');
            }
                return `${origObj.join(', ')} ${translate('settings.relatic.column_error')}`;
            
        }
    }

    return "";
}