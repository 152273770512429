export class PaginatedResponse<T = any> {
  current_page: number = 0;
  data: T[] = [];
  first_page_url: string = '';
  from: number = 0;
  last_page: number = 0;
  last_page_url: string = '';
  next_page_url: string = '';
  path: string = '';
  per_page: number = 0;
  prev_page_url: string = '';
  to: number = 0;
  total: number = 0;
}


