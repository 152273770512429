<div
  id="sidebar"
  [ngClass]="collapse ? 'collapsed' : ''"
  class="{{ 'sidebar sidebar-' + randomId }}"
>
  <!-- Nav tabs -->
  <div class="sidebar-tabs" *ngIf="type != 'activities'">
    <ul role="tablist">
      <li [ngClass]="{ active: showContent }" *ngIf="buttons.includes('layer')">
        <a
          (click)="setSelectedTool('layer')"
          [class]="
            purposeTool === 'layer'
              ? 'ol-control-active d-cursor-hand'
              : 'd-cursor-hand'
          "
          href="#layers"
          role="tab"
        >
          <mat-icon>layers</mat-icon>
        </a>
      </li>
      <li
        *ngIf="
          buttons.includes('draw') &&
          (hasPermission(Permission.StakeholdersCreate) ||
            hasPermission(Permission.IssuesCreate) ||
            hasPermission(Permission.ComplaintsCreate) ||
            hasPermission(Permission.ActionCreate) ||
            hasPermission(Permission.DecisionsCreate))
        "
      >
        <a
          (click)="setSelectedTool('draw')"
          [class]="
            purposeTool === 'draw'
              ? 'ol-control-active d-cursor-hand'
              : 'd-cursor-hand'
          "
          href="#draw"
          role="tab"
        >
          <mat-icon class="uil uil-plus-circle"></mat-icon>
        </a>
      </li>
      <li
        [ngClass]="purposeTool === 'point' ? 'active' : ''"
        *ngIf="buttons.includes('point')"
      >
        <a
          (click)="setSelectedTool('point')"
          [class]="
            purposeTool === 'point'
              ? 'ol-control-active d-cursor-hand'
              : 'd-cursor-hand'
          "
        >
          <mat-icon>adjust</mat-icon>
        </a>
      </li>
      <li
        [ngClass]="purposeTool === 'length' ? 'active' : ''"
        *ngIf="buttons.includes('length')"
      >
        <a
          (click)="setSelectedTool('length')"
          [class]="
            purposeTool === 'length'
              ? 'ol-control-active d-cursor-hand'
              : 'd-cursor-hand'
          "
        >
          <mat-icon>gesture</mat-icon>
        </a>
      </li>
      <li *ngIf="buttons.includes('area')">
        <a
          (click)="setSelectedTool('area')"
          [class]="
            purposeTool === 'area'
              ? 'ol-control-active d-cursor-hand'
              : 'd-cursor-hand'
          "
        >
          <mat-icon>crop_landscape</mat-icon>
        </a>
      </li>
      <li *ngIf="buttons.includes('add-stakeholder')">
        <a
          (click)="setSelectedTool('add-stakeholder')"
          [class]="
            purposeTool === 'add-stakeholder'
              ? 'ol-control-active d-cursor-hand'
              : 'd-cursor-hand'
          "
        >
          <mat-icon>person_add</mat-icon>
        </a>
      </li>
    </ul>
  </div>
  <mat-button-toggle-group
    class="polygon-edit-toggle"
    *ngIf="type == 'activities'"
    [(ngModel)]="togglerActivites"
  >
    <mat-button-toggle value="point" (click)="setSelectedTool('point')">
      <mat-icon class="mat-icon-height-set">adjust</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="length" (click)="setSelectedTool('length')">
      <mat-icon class="mat-icon-height-set">gesture</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <!-- Tab pane Layers -->
  <div class="sidebar-content" *transloco="let t">
    <div class="sidebar-pane active" *ngIf="showLayers">
      <h1 class="sidebar-header">
        <span class="sidebar-close" #closeLayer><<</span>
      </h1>
      <!-- Search  -->
      <h3 style="margin-bottom: 3px; margin-top: 3px; margin-left: 3px;">{{'maps.search_address' | transloco}}</h3>
      <div class="map-search mb-5 mt-2">
        <input
          type="text"
          [(ngModel)]="_mapServices.keyword"
          class="form-control universal-search ng-pristine ng-valid ng-touched"
          placeholder="{{'maps.search_address' | transloco}}"
          (input)="search($event.target.value)"
        />
        <div class="ng-star-inserted">
          <i *ngIf="loadingSearch" class="uil uil-spinner-alt uil-icon spinner-animation form-control-map-spinner"></i>
            <!-- <i  class="uil uil-search uil-icon"></i> -->
          <span *ngIf="!loadingSearch" class="uil uil-search form-control-map-search"></span>
        </div>
        <!-- Display Search Results -->
        <div *ngIf="showResultsContainer"  class="search-results-container">
          <ul style="padding: 5px;">
            <ng-container *ngIf="searchResults.length > 0; else notFound">
              <li
                *ngFor="let result of searchResults; let i = index"
                class="search-result-item"
                [class.selected]="i === selectedResultIndex"
                (click)="panToLocation(result); onItemClick(i)"
              >
                {{ result.display_name }}
              </li>
            </ng-container>
            <ng-template #notFound>
              <li class="not-found-message">Not found</li>
            </ng-template>
          </ul>
        </div>
      </div>

      <!-- items layers  -->
      <mat-card class="p-10 mb-10">
        <h3 class="layers-header-title">Items</h3>
        <mat-accordion>
          <app-item-layers-accordion
            [type]="[
              'stakeholder',
              'issue',
              'complaints',
              'requirements',
              'activity'
            ]"
            [showToggles]="showToggles"
            (toggleChangeItem)="toggleitemsLayers($event)"
          ></app-item-layers-accordion>
        </mat-accordion>
      </mat-card>
      <!-- subproject Layers  -->
      <mat-card
        class="p-10 mb-10"
        *ngIf="hasModule('subprojects') && hasStandingLevel(router)"
      >
        <h3 class="layers-header-title">
          {{
            this.globals.projectConfigs["project.labels.subprojects"] ||
              "subprojects.label" | transloco
          }}
        </h3>
        <mat-accordion>
          <app-feature-layers-accordion
            (toggleOpenDataLayers)="toggleOpenDataLayers($event)"
            (onChangeLayerStyle)="onChangeStyle($event)"
            [layers]="layers['subproject']"
            [showToggles]="showToggles"
            [showSubLayer]="true"
          ></app-feature-layers-accordion>
        </mat-accordion>
      </mat-card>
      <!-- open data  -->
      <mat-card class="p-10 mb-10">
        <div class="d-flex j-space-between">
          <h3 class="a-self-center layers-header-title">Open Data</h3>
          <span *ngIf="hasRole('Project Admin')"
            ><a
              class="a-self-center d-cursor-hand mr-10"
              (click)="addOpenDataLayers()"
              matTooltip="{{ 'maps.add_layer' | transloco }}"
              ><mat-icon class="uil uil-plus-circle"></mat-icon></a
          ></span>
        </div>
        <div
          *ngIf="is_loadOpenData"
          class="w-100 load-openlayers d-flex j-space-center"
        >
          <mat-spinner></mat-spinner>
        </div>
        <mat-accordion>
          <app-feature-layers-accordion
            *ngIf="!is_loadOpenData"
            (toggleOpenDataLayers)="toggleOpenDataLayers($event)"
            (onChangeLayerStyle)="onChangeStyle($event)"
            [layers]="layers['openData']"
            [type]="'openlayers'"
            [showToggles]="showToggles"
            [showSubLayer]="true"
            (loadOpenData)="loadOpenData()"
          ></app-feature-layers-accordion>
        </mat-accordion>
        <mat-divider class="z-99"></mat-divider>
        <!-- Overpass Layer Data -->
        <div class="mbt-25">
          <div>
            <label class="pull-left openlayer-sidebar_label">{{
              "potential_stakeholder" | transloco
            }}</label>
            <mat-slide-toggle
              *ngIf="!displayProgressSpinner"
              [disabled]="!showToggles"
              [(ngModel)]="potentialStakeholderToggle"
              class="pull-right openlayer-sidebar_slider-toggle"
              (change)="toggleOverpassLayer($event)"
            ></mat-slide-toggle>
            <span *ngIf="displayProgressSpinner" class="pull-right loader-span">
              <img
                class="pull-left group_svg spinner"
                src="/assets/png/spinner.png"
                alt="decisions"
              />
            </span>
          </div>
        </div>
      </mat-card>
      <!--integration Layers -->
      <mat-card class="p-10 mb-10" *ngIf="integrationEnable">
        <h3 class="layers-header-title">ArcGIS</h3>
        <div
          *ngIf="!loading"
          class="w-100 load-openlayers d-flex j-space-center"
        >
          <mat-spinner></mat-spinner>
        </div>
        <h3 *ngIf="!layers['arcgis'].length && loading" class="font-size-sta">
          <mat-icon class="d-text-middle">info</mat-icon
          ><span class="ml-5"
            >{{ 'maps.no_layer_found' | transloco}}</span
          >
        </h3>
        <div *ngIf="layers['arcgis'].length && loading">
          <mat-accordion>
            <app-feature-layers-accordion
              (toggleOpenDataLayers)="toggleOpenDataLayers($event)"
              (onChangeLayerStyle)="onChangeStyle($event)"
              [layers]="layers['arcgis']"
              [type]="'integrationconfigs'"
              [showToggles]="showToggles"
              [showSubLayer]="true"
            ></app-feature-layers-accordion>
          </mat-accordion>
        </div>
      </mat-card>
    </div>
    <!-- Side Tab -->
    <div class="sidebar-pane active" *ngIf="createItem">
      <h1 class="sidebar-header">
        {{ "settings.create_item" | transloco }}
        <span class="sidebar-close" #closeLayer><<</span>
      </h1>
      <ng-container *transloco="let t">
        <mat-card style="flex-direction: inherit !important; align-items: baseline !important;"
          [ngClass]="
            tool == 'stakeholders' || tool == 'stakeholders_polygons'
              ? 'active'
              : ''
          "
          *ngIf="hasPermission(Permission.StakeholdersCreate)"
        >
          <img
            style="bottom: 0px !important"
            class="pull-left openlayer-sidebar_svg"
            src="/assets/png/stakeholders.png"
            alt="Stakeholders"
          />
          <label class="pull-left openlayer-sidebar_label">{{
            t("stakeholders.labels") | titlecase
          }}</label>
          <span class="activity-options">
            <mat-icon
              *ngIf="enableStakeholdersPolygons"
              [ngClass]="tool == 'stakeholders_polygons' ? 'active' : ''"
              (click)="setTool('stakeholders_polygons')"
              >crop_landscape</mat-icon
            >
            <mat-icon
              [ngClass]="tool == 'stakeholders' ? 'active' : ''"
              (click)="setTool('stakeholders')"
            >
              radio_button_checked</mat-icon
            >
          </span>
        </mat-card>
        <mat-card style="flex-direction: inherit !important; align-items: baseline !important;"
          [ngClass]="tool == 'issues' ? 'active' : ''"
          (click)="setTool('issues')"
          *ngIf="hasPermission(Permission.IssuesCreate)"
        >
          <img   style="bottom: 0px !important"
            class="pull-left openlayer-sidebar_svg"
            src="/assets/png/issues.png"
            alt="Issues"
          />
          <label class="pull-left openlayer-sidebar_label">{{
            t("issues.labels") | titlecase
          }}</label>
        </mat-card>

        <mat-card  style="flex-direction: inherit !important; align-items: baseline !important;"
          [ngClass]="tool == 'complaints' ? 'active' : ''"
          (click)="setTool('complaints')"
          *ngIf="hasPermission(Permission.ComplaintsCreate)"
        >
          <img   style="bottom: 0px !important"
            class="pull-left openlayer-sidebar_svg"
            src="/assets/png/complaints.png"
            alt="Meldingen"
          />
          <label class="pull-left openlayer-sidebar_label">{{
            t("complaints.labels") | titlecase
          }}</label>
        </mat-card>

        <mat-card  style="flex-direction: inherit !important; align-items: baseline !important;"
          [ngClass]="tool == 'requirements' ? 'active' : ''"
          (click)="setTool('requirements')"
          *ngIf="hasPermission(Permission.DecisionsCreate)"
        >
          <img   style="bottom: 0px !important"
            class="pull-left openlayer-sidebar_svg"
            src="/assets/png/requirements.png"
            alt="klanteisen"
          />
          <label class="pull-left openlayer-sidebar_label">{{
            t("decisions.labels") | titlecase
          }}</label>
        </mat-card>

        <mat-card  style="flex-direction: inherit !important; align-items: baseline !important;"
          [ngClass]="
            tool == 'calendars' || tool == 'calendars_lines' ? 'active' : ''
          "
          *ngIf="hasPermission(Permission.ActivitiesCreate)"
        >
          <img   style="bottom: 0px !important"
            class="pull-left openlayer-sidebar_svg"
            src="/assets/png/calendars.png"
            alt="Activiteiten"
          />
          <label class="pull-left openlayer-sidebar_label">{{
            t("activities.labels") | titlecase
          }}</label>
          <span class="activity-options">
            <mat-icon
              [ngClass]="tool == 'calendars_lines' ? 'active' : ''"
              (click)="setTool('calendars_lines')"
            >
              timeline</mat-icon
            >
            <mat-icon
              [ngClass]="tool == 'calendars' ? 'active' : ''"
              (click)="setTool('calendars')"
            >
              radio_button_checked</mat-icon
            >
          </span>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
